import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer } from '../AgGrid/MultiSelectTracker';
import { AgGridModule } from 'ag-grid-angular';
import { FilingBatchService } from './filingBatch.service'
import { FilingBatchListComponent } from './Filing-Batch-List/filingBatchList.component';
import { FilingBatchListGridActionCellRendererComponent } from './Filing-Batch-List/agGridActionCellRenderer.component';
import { FilingBatchDetailsComponent } from './Filing-Batch-Details/filingBatchDetails.component'
import { FilingBatchInfoComponent } from './Filing-Batch-Details/Filing-Batch-Info/filingBatchInfo.component'
import { FilingBatchAccountsComponent } from './Filing-Batch-Details/Filing-Batch-Accounts/filingBatchAccounts.component'
import { FilingBatchAccountsGridActionCellRendererComponent } from './Filing-Batch-Details/Filing-Batch-Accounts/filingBatchAccountsAgGridActionCellRenderer.component'
import { FilingBatchAddAccountsComponent } from './Filing-Batch-Details/Filing-Batch-Add-Accounts/filingBatchAddAccounts.component';
import { AgGridLinkCellRendererComponent } from '../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { FilingDetailsComponent } from './Filing-Batch-Details/Filing-Details/filingDetails.component';
import { StepProgressIndicatorModule } from '../../Common/Step-Progress-Indicator/stepProgressIndicator.module';
import { FilingBatchProgressComponent } from './Filing-Batch-Progress/filingBatchProgress.component';
import { FilingBatchProgressCellRendererComponent } from './Filing-Batch-List/agGridProgressCellRenderer.component';
import { FilingBatchMoveModalComponent } from './Filing-Batch-Details/Filing-Batch-Move-Modal/filingBatchMoveModal.component';
import { FilingBatchMoveResultModalComponent } from './Filing-Batch-Details/Filing-Batch-Move-Result-Modal/filingBatchMoveResultModal';
import { AmendReturnsComponent } from './Filing-Batch-Details/Amend-Returns-Modal/amendReturns.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        TypeaheadModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        StepProgressIndicatorModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer,
            // filing batch list
            FilingBatchListGridActionCellRendererComponent,
            // filing batch accounts
            FilingBatchAccountsGridActionCellRendererComponent,
            AgGridLinkCellRendererComponent,
            FilingBatchProgressCellRendererComponent
        ])
    ],
    declarations: [
        FilingBatchListComponent,
        FilingBatchListGridActionCellRendererComponent,
        FilingBatchDetailsComponent,
        FilingBatchInfoComponent,
        FilingBatchAccountsComponent,
        FilingBatchAddAccountsComponent,
        FilingBatchAccountsGridActionCellRendererComponent,
        FilingDetailsComponent,
        FilingBatchProgressComponent,
        FilingBatchMoveModalComponent,
        FilingBatchMoveResultModalComponent,
        FilingBatchProgressCellRendererComponent,
        AmendReturnsComponent
    ],
    providers: [
        FilingBatchService
    ],
    exports: [
        FilingBatchProgressComponent
    ]
})
export class FilingBatchModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('filingBatchList', FilingBatchListComponent);
        hybridAdapterUtility.downgradeNg2Component('filingBatchDetails', FilingBatchDetailsComponent);
    }
}
