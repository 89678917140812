import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription,  BehaviorSubject } from 'rxjs';

export interface FilingBatchAccountsGridActionCellRendererParams extends ICellRendererParams {
    editMode$: BehaviorSubject<boolean>;
    canEdit(params: FilingBatchAccountsGridActionCellRendererParams): boolean;
    remove: (params: FilingBatchAccountsGridActionCellRendererParams) => void;
    undoRemove: (params: FilingBatchAccountsGridActionCellRendererParams) => void;
    canRemove(params: FilingBatchAccountsGridActionCellRendererParams): boolean;
    canUndoRemove(params: FilingBatchAccountsGridActionCellRendererParams): boolean;
    edit: (params: FilingBatchAccountsGridActionCellRendererParams) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
    `
    <button type="button"
        class="ace-btn has-icon btn-primary grid-action-button"
        *ngIf="params.canEdit(params)"
        (click)="onEditClicked()"
        helpTooltip
        helpContentId="filing-batch-accounts.edit-filing"
        position="bottom">
        <i class="fa fa-pencil"></i>
     </button>
    <button
        *ngIf="params.canRemove(params)"
        type="button"
        class="ace-btn has-icon btn-primary btn-danger grid-action-button"
        (click)="onRemoveClicked()"
        helpTooltip
        helpContentId="app.remove"
        position="bottom">
        <i class="fa fa-times"></i>
    </button>
    <button
        *ngIf="params.canUndoRemove(params)"
        type="button"
        class="ace-btn has-icon success-button grid-action-button"
        (click)="onUndoRemoveClicked()"
        helpTooltip
        helpContentId="app.undo-remove"
        position="bottom">
        <i class="fa fa-undo"></i>
    </button>
    `
})
export class FilingBatchAccountsGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: FilingBatchAccountsGridActionCellRendererParams;
    private _editModeSub: Subscription;

    editMode: boolean;

    agInit(params: FilingBatchAccountsGridActionCellRendererParams): void {
        this.params = params;
        this._editModeSub = this.params.editMode$.subscribe(x => this.editMode = x);
    }

    ngOnDestroy(): void {
        this._editModeSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onRemoveClicked(): void {
        this.params.remove(this.params);
    }

    onUndoRemoveClicked(): void {
        this.params.undoRemove(this.params);
    }

    onEditClicked(): void {
        this.params.edit(this.params);
    }
}
