<ws-modal-wrapper [headerLabel]="title"
                  [disableSave]="!user?.userID"
                  (save)="save()"
                  (cancel)="cancel()">
    <user-team-picker [userId]="user?.userID"
                      [teamId]="user?.teamID"
                      [entityIdsMultiselectScope]="entityIdsMultiselect"
                      [entityTypeScope]="entityType"
                      (userChange)="userChanged($event)">
    </user-team-picker>
</ws-modal-wrapper>
