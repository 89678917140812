import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetLienDate, LienDateItem } from '../Asset-Lien-Date/assetLienDate.component';
import * as _ from 'lodash';
import { LienDateTypes } from '../Models/enums';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { ASSET_LIEN_DATE_HELP } from '../Asset-Lien-Date/assetLienDate.component.help';

export interface AssetLienDateDetailsParams {
    lienDate: AssetLienDate;
    lienDateInfo: Compliance.AssetLienDateModel;
    lienDateItem: LienDateItem;
    lienDateItems: LienDateItem[];
    stateLienDates: Compliance.StateLienDateModel[];
    utcStateLienDates: Compliance.StateLienDateModel[];
    allowNonStandardLienDate: boolean;
}

@Component({
    selector: 'asset-lien-date-details',
    templateUrl: './assetLienDateDetails.component.html'
})
export class AssetLienDateDetailsComponent implements OnInit, IWeissmanModalComponent<AssetLienDateDetailsParams, AssetLienDate> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService
    ) { }

    params: AssetLienDateDetailsParams;
    result: AssetLienDate;

    lienDate: AssetLienDate;
    lienDateTypes = LienDateTypes;

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(ASSET_LIEN_DATE_HELP);

        this.lienDate = this.params.lienDate;

        if (this.params.allowNonStandardLienDate) {

            // ensure custom option is available
            let customLienDate = this._findCustomLienDate(this.params.lienDateItems);

            if (!customLienDate) {
                customLienDate = {
                    isCustom: true,
                    isCurrent: false,
                    date: this.params.lienDateItem.date
                };

                this.params.lienDateItems.unshift(customLienDate);
            }
        }
    }

    async save(): Promise<void> {
        this.result = this.lienDate;

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    lienDateOptionChanged(): void {
        this.lienDate.date = this.params.lienDateItem.date;
    }

    standardLienDateChanged(lienDateItem: AssetLienDate | LienDateItem): void {
        this.lienDate.date = lienDateItem.date;

        if (!this.params.lienDateItem.isCustom) {
            const customLienDate = this._findCustomLienDate(this.params.lienDateItems);
            if (customLienDate) {
                customLienDate.date = lienDateItem.date;
            }
        }
    }

    customLienDateChanged(closeObject: any): void {
        const holder: any = closeObject ? closeObject.obj : null;

        if (holder) {
            holder.input.blur();

            this.lienDate.date = new Date(Date.UTC(holder.currentYear, holder.currentMonth, holder.currentDay));

            const standardLienDateItem = this._findStandardLienDate(this.params.lienDateItems, this.lienDate.date);

            const customLienDate = this._findCustomLienDate(this.params.lienDateItems);
            if (customLienDate) {
                customLienDate.date = this.lienDate.date;
            }

            this.params.lienDateItem = standardLienDateItem || customLienDate;
            this.standardLienDateChanged(this.params.lienDateItem);
        }
    }

    private _sortByDate = (a: LienDateItem, b: LienDateItem) => (a.date < b.date || b.isCustom) ? 1 : ((b.date < a.date) ? -1 : 0);

    get isLienDateCustom(): boolean {
        if (!this.params.lienDateItem || !this.params.lienDateItem.isCustom) {
            return false;
        }

        const standardLienDate = this._findStandardLienDate(this.params.lienDateItems, this.params.lienDate.date);
        return !!standardLienDate;
    }

    get stateLienDateHelpContentId(): string {
        if(this.params.lienDateItem.isCustom) {
            return 'asset-lien-date.standard-lien-date-disabled';
        }

        return 'asset-lien-date.standard-lien-date';
    }

    private _findStandardLienDate(lienDateItems: LienDateItem[], date: Date): LienDateItem {
        const lienDate = lienDateItems.find(x => (!x.isCustom) && x.date.getTime() === date.getTime());
        if (lienDate) {
            return lienDate;
        }
        const hasMonthDay = this.params.utcStateLienDates.findIndex(x => x.month === (date.getMonth() + 1) && x.day === date.getDate());
        if (hasMonthDay !== -1) {
            const addLienDate: LienDateItem = {
                isCustom: false,
                isCurrent: false,
                date: this._createDateFromStateLienDate(date.getFullYear(), this.params.stateLienDates[hasMonthDay])
            };
            lienDateItems.push(addLienDate);
            lienDateItems.sort(this._sortByDate);
            return addLienDate;
        }
    }

    private _findCustomLienDate(lienDateItems: LienDateItem[]): LienDateItem {
        return _.find(lienDateItems, x => x.isCustom);
    }

    private _createDateFromStateLienDate(year: number, lienDate: Compliance.StateLienDateModel): Date {
        return new Date(Date.UTC(year, lienDate.month - 1, lienDate.day));
    }
}
