<div class="weissman-picklist">

    <ws-select [options]="availableOptions"
               [labelProperty]="labelProperty"
               [returnEntireOption]="true"
               [placeholder]="placeholder"
               [formControl]="listSelection">
    </ws-select>

    <div class="picklist-container"
         [@picklistVisible]="picklist.length ? 'show' : 'hide'"
         clickOutside
         (clickOutside)="unsetSelected()">
        <ul class="picklist" [class.open]="animationComplete">
            <li *ngFor="let option of picklist; let i = index; let last = last"
                (click)="setSelected(option, i)"
                [class.clickable]="canReorganize"
                [class.selected]="option === selectedOption && canReorganize">
                <div class="list-label">
                    <span>{{option[labelProperty]}}</span>
                    <i *ngIf="canRemove && !canReorganize && !(mustHaveOne && picklist.length === 1)"
                       class="fa fa-times remove"
                       (click)="removeOption(option)">
                    </i>
                </div>
            </li>
        </ul>

        <div *ngIf="canReorganize" class="picklist-controls">
            <button class="direction picklist-up"
                    (click)="shiftOptionUp()"
                    [disabled]="!selectedOption || selectedOptionIndex === 0">
                <i class="fa fa-arrow-up"></i>
            </button>
            <button *ngIf="canRemove"
                    class="remove"
                    (click)="removeOption()"
                    [disabled]="!selectedOption || (mustHaveOne && picklist.length === 1)">
                <i class="fa fa-times"></i>
            </button>
            <button class="direction picklist-down"
                    (click)="shiftOptionDown()"
                    [disabled]="!selectedOption || selectedOptionIndex === (picklist.length - 1)">
                <i class="fa fa-arrow-down"></i>
            </button>
        </div>

    </div>
</div>
