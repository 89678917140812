import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportDetail, SummaryReportCriteria, FormatOutputOptionsBudget } from '../report.manager.model';
import { ForecastingBudgetingService } from '../../../Budget/forecasting.budgeting.service';
import { ReportManagerService } from '../report.manager.service';
import * as moment from 'moment';
import { CompanyBudgetBasisEnum } from '../../../Budget/forecasting.budgeting.model';
import { EntityTypeIds } from '../../../constants.new';

@Component({
    selector: 'format-output-panel-budget',
    templateUrl: './format.output.panel.budget.component.html'
})
export class FormatOutputPanelBudgetComponent implements OnInit {
    constructor(
        private forecastingBudgetingService: ForecastingBudgetingService,
        private reportManagerService: ReportManagerService,
    ) {}

    @Input() report: ReportDetail;
    outputFormatOptions: string[];
    summaryCashBasisPeriodOptions: string[];
    showPercentChangeOptions: string[];
    detailLevelOptions: string[];

    CompanyBudgetBasisEnum = CompanyBudgetBasisEnum;

    budgetYear: number;
    availableBudgets: Core.AvailableCompanyBudget[];
    availableBasisBudgets: Core.AvailableCompanyBudget[];


    async ngOnInit() {
        this.reportManagerService.topLevelCompanies$.subscribe(async topLevelCompaniesObj => {
            if(topLevelCompaniesObj.topLevelCompanyIds.length == 1) {
                this.report.criteria.budgetBasis = await this.forecastingBudgetingService.getCompanyBudgetBasis(EntityTypeIds.COMPANY, topLevelCompaniesObj.topLevelCompanyIds[0]);
                this.availableBudgets = await this.forecastingBudgetingService.getAvailableCompanyBudgets(topLevelCompaniesObj.topLevelCompanyIds[0]);
                this.budgetBasisChanged(topLevelCompaniesObj.initFromSavedReport);
            }
        });

        this._setDefaultsBudget();
    }

    get budgetYearSources(): Core.AvailableCompanyBudget[] {
        return _.filter(this.availableBasisBudgets, budget => moment.utc(budget.fiscalPeriodBegin).year() == this.report.criteria.budgetYear);
    }

    get priorYearSources(): Core.AvailableCompanyBudget[] {
        return _.filter(this.availableBasisBudgets, budget => moment.utc(budget.fiscalPeriodBegin).year() == this.report.criteria.budgetYear -1);
    }

    budgetBasisChanged(initFromSavedReport?: boolean) {
        this.availableBasisBudgets = _.filter(this.availableBudgets, budget => {
            switch(budget.budgetBasis) {
                case Core.CompanyBudgetBasisEnum.AccrualAndCash:
                    return true;
                case Core.CompanyBudgetBasisEnum.Accrual:
                    return this.report.criteria.budgetBasis == Core.CompanyBudgetBasisEnum.Accrual;
                case Core.CompanyBudgetBasisEnum.Cash:
                    return this.report.criteria.budgetBasis == Core.CompanyBudgetBasisEnum.Cash;
            }
        });

        if(initFromSavedReport) {
            return;
        }

        if(this.budgetYearSources.length) {
            if(!_.some(this.budgetYearSources, {companyBudgetId: this.report.criteria.currCompanyBudgetID})) {
                this.report.criteria.currCompanyBudgetID = this.budgetYearSources[0].companyBudgetId;
            }
        } else {
            this.report.criteria.currCompanyBudgetID = 0;
        }

        if(!this.priorYearSources.length || !_.some(this.priorYearSources, {companyBudgetId: this.report.criteria.priorCompanyBudgetID})) {
            this.report.criteria.priorCompanyBudgetID = 0;
        }
    }

    private _setDefaultsBudget(): void {
        _.assign(this, new FormatOutputOptionsBudget());
        this.report.criteria.outputFormat = this.report.criteria.outputFormat || 0;

        this.report.criteria.summaryReportOptions = this.report.criteria.summaryReportOptions || new SummaryReportCriteria();
        this.report.criteria.currCompanyBudgetID = this.report.criteria.currCompanyBudgetID || 0;
        this.report.criteria.priorCompanyBudgetID = this.report.criteria.priorCompanyBudgetID || 0;
    }


}