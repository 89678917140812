import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusyIndicatorService } from '../Busy-Indicator';
import { MessageModalService } from '../UI-Lib/Message-Box/messageModal.service';
import { lastValueFrom, Subject } from 'rxjs';

@Injectable(
    { providedIn: 'root' }
)
export class UserGroupsService {
    constructor(private readonly _http: HttpClient,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _messageModalService: MessageModalService) {}

    private readonly _baseUrl = 'api/group';
    private _panelEditSub: Subject<boolean> = new Subject();

    panelEditChange$ = this._panelEditSub.asObservable();

    setPanelEdit(isEdit: boolean): void {
        this._panelEditSub.next(isEdit);
    }

    getList(instanceId: number, groupSearchModel: Core.GroupSearchModel): Promise<Core.GroupModel[]> {
        return lastValueFrom(this._http.post(`${this._baseUrl}/instance/${instanceId}`, groupSearchModel)) as Promise<Core.GroupModel[]>;
    }

    getGroup(groupId: number): Promise<Core.GroupModel> {
        return lastValueFrom(this._http.get(`${this._baseUrl}/${groupId}`)) as Promise<Core.GroupModel>;
    }

    create(userGroup: Core.GroupModel): Promise<Core.GroupModel> {
        return lastValueFrom(this._http.post(`${this._baseUrl}`, userGroup)) as Promise<Core.GroupModel>;
    }

    update(userGroup: Core.GroupModel): Promise<Core.GroupModel> {
        return lastValueFrom(this._http.put(`${this._baseUrl}/${userGroup.groupID}`, userGroup)) as Promise<Core.GroupModel>;
    }

    delete(groupId: number, force: boolean): Promise<void> {
        const params = new HttpParams().set('force', force.toString());

        return lastValueFrom(this._http.delete(`${this._baseUrl}/${groupId}`, { params: params })) as Promise<any>;
    }

    async deleteWithConfirm(userGroup: Core.GroupModel): Promise<boolean> {
        try {
            await this._messageModalService.confirm('Are you sure you wish to delete the user group?', 'Confirm Delete');
        }
        catch (e) {
            return Promise.resolve(false);
        }

        let busyRef = this._busyIndicatorService.show({ message: 'Deleting' });

        try {
            await this.delete(userGroup.groupID, false);
        }
        catch (e2) {
            // service returns a 422 and a message if user confirmation needed to force delete
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
            busyRef.hide();

            try {
                await this._messageModalService.confirm(e2.error.message, 'Confirm Delete');
            }
            catch (e3) {
                return Promise.resolve(false);
            }

            busyRef = this._busyIndicatorService.show({ message: 'Deleting' });

            await this.delete(userGroup.groupID, true);
        }
        finally {
            busyRef.hide();
        }

        return Promise.resolve(true);
    }
}
