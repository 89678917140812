import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
    selector: '[line-limit]'
})
export class LineLimitDirective {
    constructor(private readonly _element: ElementRef) {}

    @Input() lineLimitIsOver: boolean;
    @Input() lineLimitNumber: number;
    @Input()
    set lineLimitModel(model: string) {
        const computedStyle = getComputedStyle(this._element.nativeElement);
        const lineCount = parseFloat(computedStyle.height) / parseFloat(computedStyle.lineHeight);
        let lineLimit = 3;
        if (this.lineLimitNumber) {
            lineLimit = this.lineLimitNumber;
        }
        this.lineLimitIsOver = lineCount > lineLimit;
    }

    @Output() lineLimitIsOverChange: EventEmitter<boolean> = new EventEmitter<boolean>();
}
