import { NgModule } from '@angular/core';
import { AssessorCollectorService } from './assessor.collector.service';
import { AssessorCollectorRepository } from '../Core-Repositories';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { AssessorModule } from './Assessor/assessor.module';
import { CollectorModule } from './Collector/collector.module';
import { AssessorListComponent } from './List/assessorList.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { AddAssessorModalComponent } from './List/addAssessorModal.component';
import { TaxRatesPanelModule } from './Tax-Rates/taxRatesPanel.module';
import { TaxingDistrictModule } from './Taxing-District/taxingDistrict.module';
import { AddCollectorModalComponent } from './Add/Collector/addCollectorModal.component';
import { CollectorListComponent } from './List/collectorList.component';
import { AlphabetizedListComponent } from './alphabetizedList.component';

@NgModule({
    imports: [
        AssessorModule,
        CollectorModule,
        TaxingDistrictModule,
        WeissmanSharedModule,
    ],
    declarations: [
        AssessorListComponent,
        AddAssessorModalComponent,
        CollectorListComponent,
        AddCollectorModalComponent,
        AlphabetizedListComponent
    ],
    providers: [
        AssessorCollectorService,
        AssessorCollectorRepository
    ],
    exports: []
})
export class AssessorCollectorModule {
    static setupModule(): void {
        AssessorModule.setupModule();
        CollectorModule.setupModule();
        TaxingDistrictModule.setupModule();
        TaxRatesPanelModule.setupModule();

        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('sd.Assessor.Collector.Service', AssessorCollectorService);
        hybridAdapterUtility.downgradeNg2Component('assessorList', AssessorListComponent);
        hybridAdapterUtility.downgradeNg2Component('collectorList', CollectorListComponent);
    }
}
