import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForRentRollGridAction extends ICellRendererParams {
    canDelete: (params: ICellRendererParamsForRentRollGridAction) => boolean;
    delete: (params: ICellRendererParamsForRentRollGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button" class="ace-btn has-icon btn-primary btn-danger grid-action-button" title="Delete" (click)="onDeleteClicked()">
            <i class="fa fa-trash"></i>
        </button>`
})
export class RentRollGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForRentRollGridAction;
    canDelete: boolean;

    agInit(params: ICellRendererParamsForRentRollGridAction): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onDeleteClicked(): void {
        this.params.delete(this.params);
    }
}
