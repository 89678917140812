import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { InstanceRepository } from './instance.repository';

@Component({
    selector: 'manage-invitations',
    templateUrl: './manageInvitations.component.html',
    styleUrls: ['./manageInvitations.component.scss']
})
export class ManageInvitationsComponent implements OnInit {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _instanceRepository: InstanceRepository,
        private _toastrService: ToastrService,
        private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler) {
    }

    params: any;
    result: any;
    statuses: Core.ContactStatusDTO[] = [];
    changesMade: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        this.statuses = (await this._instanceRepository.getCurrentUsersContactInviteStatuses()).filter(s => s.contactId !== null);

        //only has one instance (must have arrived via direct link or url) : send user home
        if(this.statuses.length === 1) {
            this.upgradeNavigationServiceHandler.go('home', {});
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    getHomeIndicator(status: Core.ContactStatusDTO):string {
        return status.isHomeInstance ? ' <i class=\'fa fa-home\' title=\'This is your home instance\'></i>' : '';
    }

    getStatus(status: Core.ContactStatusDTO):string {
        if(status.active === false) {
            return 'Inactive';
        }
        else if(status.invite === false) {
            return 'Invite Pending';
        }
        else {
            if(status.hidden === false) {
                return 'Invite Accepted';
            }
            else {
                return 'Hidden';
            }
        }
    }

    async acceptInvitation(contactId: number): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Accepting Invitation' });
        try {
            const success = await this._instanceRepository.acceptInvite(contactId);
            if(success)
                this._toastrService.success('Invitation accepted.');
                this.statuses = await this._instanceRepository.getCurrentUsersContactInviteStatuses();
                this.changesMade = true;
        } finally {
            busyRef.hide();
        }
    }

    async rejectInvitation(contactId: number): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Rejecting Invitation' });
        try {
            const success = await this._instanceRepository.rejectInvite(contactId);
            if(success)
                this._toastrService.success('Invitation was rejected.');
                this.statuses = await this._instanceRepository.getCurrentUsersContactInviteStatuses();
                this.changesMade = true;
        } finally {
            busyRef.hide();
        }
    }

    async toggleVisibility(contactId: number): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Toggling Visibility' });
        try {
            const success = await this._instanceRepository.toggleInstance(contactId);
            if(success)
                this._toastrService.success('Instance visibility toggled.');
                this.statuses = await this._instanceRepository.getCurrentUsersContactInviteStatuses();
                this.changesMade = true;
        } finally {
            busyRef.hide();
        }
    }
}
