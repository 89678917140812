import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommentModalData } from '../../../Comments/comments.service';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { ASSET_LIST_HELP } from './assetList.component.help';

export interface ICellRendererParamsForAssetListGridAction extends ICellRendererParams {
    viewDetails: (params: ICellRendererParamsForAssetListGridAction) => void;
    canViewRyanPrivateItems: boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button class="ace-btn btn-primary has-icon grid-action-button" helpTooltip helpContentId="app.view" position="bottom" (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>
        <span class="icon-indicator">
            <i class="fa" [ngClass]="{'fa-commenting':hasComments, 'fa-fw':!(hasComments)}"></i>
        </span>
        <entity-comments [(hasComments)]="hasComments"
                            buttonClass="ace-btn btn-primary has-icon grid-action-button"
                            [modalData]="commentModalData"
                            helpTooltip helpContentId="app.comments" position="bottom">
        </entity-comments>
        `
})
export class AssetListGridActionCellRendererComponent implements ICellRendererAngularComp {
    constructor(
        private readonly _helpService: HelpService
    ) { }

    assetModel: Compliance.AssetModel;
    commentModalData: CommentModalData;
    hasComments: boolean;

    private _params: ICellRendererParamsForAssetListGridAction;

    agInit(params: ICellRendererParamsForAssetListGridAction): void {
        this._helpService.setContent(ASSET_LIST_HELP);
        this._params = params;
        this.assetModel = params.data && params.data.getModel() as Compliance.AssetModel;
        if (!this.assetModel) {
            return;
        }

        this.hasComments = this.assetModel.hasNonRyanInternalComments || (this.assetModel.hasRyanInternalComments && params.canViewRyanPrivateItems);

        this.commentModalData = {
            entityID: this.assetModel.assetId,
            entityTypeID: Core.EntityTypes.Asset,
            parcelAcctNum: null,
            canEdit: true,
            description: null,
            parcelID: null,
            year: null
        };
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
