import {
    Component,
    ViewChild,
    OnDestroy,
    ElementRef,
    OnInit,
    forwardRef,
    Input,
    Output, EventEmitter, AfterContentInit
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

declare let $: any;
declare let moment: any;

@Component({
    selector: 'date-filter-picker',
    template: `
        <div class="text-input ws-flex-container-horizontal">
            <input #picker
                   [id]="calendarUID"
                   class="form-control"
                   type="text"
                   [numbersOnly]="true"
                   [forDateInput]="true"
                   [formControl]="dateValue"
                   [class.has-reset]="showReset"
                   [class.invalid]="!isValid"
                   (focusin)="showCalendar()"/>
            <span class="reset-value clickable"
                  [class.showReset]="dateValue.value"
                  helpTooltip
                  tooltipText="Clear the field"
                  position="right"
                  (click)="resetInputValue()">
                <i class="fa fa-times"></i>
            </span>
        </div>
        <div [hidden]="!isCalendarVisible">
            <div #calendar></div>
        </div>
    `,
    styleUrls: ['../agGridFilters.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateFilterPickerInstanceComponent),
            multi: true
        }
    ],
})
export class DateFilterPickerInstanceComponent implements ControlValueAccessor, OnInit, AfterContentInit, OnDestroy {
    @Input() canReset: boolean = false;
    @Input() pickerIndex: number;

    @Output() resetValue: EventEmitter<void> = new EventEmitter();

    @ViewChild('picker', { static: true }) picker: ElementRef;
    @ViewChild('calendar', { static: true }) calendar: ElementRef;

    initialValue: string;
    disabled: boolean = false;
    isValid: boolean = true;
    isCalendarVisible: boolean = false;
    validationMessage: string;
    dateValue: UntypedFormControl = new UntypedFormControl();
    calendarUID: string = '';

    onChange: (val: any[]) => void;
    onTouched: () => void;

    private _destroy$: Subject<void> = new Subject();

    get showReset(): boolean {
        return this.canReset && ((this.dateValue && !this.dateValue.value));
    }

    ngOnInit(): void {
        this.calendarUID = `#calendar-alt-${this.pickerIndex}`;
        this.dateValue.valueChanges
            .pipe(takeUntil(this._destroy$))
            .pipe(distinctUntilChanged())
            .subscribe(async (date: string) => {
                this.isValid = this.validateDateInput(date);
                if (this.isValid && this._isUnique(date)) {
                    $(this.calendar.nativeElement).datepicker('setDate', date);
                    this.initialValue = date;
                    await this.next();
                }
            });
    }

    ngAfterContentInit(): void {
        this.picker.nativeElement.focus();
        this.picker.nativeElement.select();
        $(this.calendar.nativeElement).datepicker({
            altField: this.calendarUID,
            dateFormat: 'mm/dd/yy',
            inline: true,
            setDate: this.initialValue,
            onSelect: (dateText) => {
                this.dateChanged(dateText);
                this.isCalendarVisible = false;
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    writeValue(value: string): void {
        this.dateValue.setValue(value);
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    next(): void {
        if (!this.onChange) { return; }
        this.onChange(this.dateValue.value);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    resetInputValue(): void {
        this.resetValue.emit();
        this.dateValue.setValue(null);
    }

    showCalendar(): void {
        this.isCalendarVisible = true;
    }

    dateChanged(value: string): void {
        this.dateValue.setValue(value);
    }

    validateDateInput(inputValue: string): boolean {
        return !inputValue ||
            moment(inputValue, 'MM-DD-YYYY', true).isValid() ||
            moment(inputValue, 'MM/DD/YYYY', true).isValid() ||
            moment(inputValue, 'M-D-YYYY', true).isValid() ||
            moment(inputValue, 'M/D/YYYY', true).isValid();
    }

    private _isUnique(date: string): boolean {
        return !(date === this.initialValue);
    }
}
