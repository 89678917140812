import { Address } from '../../Models/common.model';

export class EntityAddress {
    constructor(entityID: number, entityTypeID: number) {
        this.entityID = entityID;
        this.entityTypeID = entityTypeID;
        this.entityAddressCorrespondences = [];
        this.address = new Address();
    }

    address: Address;
    entityAddressCorrespondences: EntityAddressCorrespondence[];
    entityAddressID: number;
    entityTypeID: number;
    entityID: number;
    addressID: number;
    remark: string;
    isDefault: boolean;
    efAction: string;
    isEditMode: boolean;
    correspondenceTypes: CorrespondenceType[];
    addressDeliverabilityDecision: Core.EntityAddressDeliverabilityDecisionDTO;
}

export class EntityAddressCorrespondence {
    correspondenceType: CorrespondenceType;
    entityAddressCorrespondenceID: number;
    entityAddressID: number;
    correspondenceTypeID: number;
    efAction: string;
}

export class CorrespondenceType {
    correspondenceTypeID: number;
    name: string;
    sequence: number;
    efAction: string;
}
