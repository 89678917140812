import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { ClientServiceResponsibilityService } from '../../clientServiceResponsibility.service';
import { FeedSpecification, DeliveryDetailFeedSpecification } from '../../clientServices.model';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { TransmittalOutputType } from 'src/app/Processing/Transmittal/transmittal.models';

export interface DeliveryDetailsConfigureParams {
    editMode: boolean;
    feedSpecifications: DeliveryDetailFeedSpecification[];
    clientAbbreviation: string;
    entity: CompanyEntity;
    transmittalOutputTypeID: number;

}

export interface DeliveryDetailsConfigureResult {
    clientAbbreviation: string;
    feedSpecifications: DeliveryDetailFeedSpecification[];
}

@Component({
    selector: 'delivery-details-configure-modal',
    templateUrl: './deliveryDetailsConfigureModal.component.html'
})
export class DeliveryDetailsConfigureModalComponent implements OnInit, IWeissmanModalComponent<DeliveryDetailsConfigureParams, DeliveryDetailsConfigureResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _clientServicesService: ClientServiceResponsibilityService
    ) { }

    params: DeliveryDetailsConfigureParams;
    result: DeliveryDetailsConfigureResult;

    availableFeedSpecs: FeedSpecification[];
    hoveredSpecId: number;
    chosenSpec: FeedSpecification;
    disableSave: boolean = false;
    isBillPay: boolean = false;
    loading: boolean = false;

    async ngOnInit() {
        this.loading = true;

        try {
            const availableFeedSpecs = await this._clientServicesService.getAvailableFeedSpecs(this.params.entity.typeId, this.params.entity.id);
            this.availableFeedSpecs = _.reject(availableFeedSpecs, spec => {
                return _.some(this.params.feedSpecifications, { feedSpecificationID: spec.feedSpecificationID });
            });

            this._sortAvailableFeedSpecs();

         if (this.params.transmittalOutputTypeID  == TransmittalOutputType.BillPay)
          {
            this.isBillPay = true;
            if (this.params.feedSpecifications.length < 1)
              this.disableSave = true;
          }

        } finally {
            this.loading = false;
        }
    }

    addSpec(): void {
        const specToadd = {
            feedSpecification: _.cloneDeep(this.chosenSpec),
            feedSpecificationID: this.chosenSpec.feedSpecificationID,
            includedInEmail: false,
        } as DeliveryDetailFeedSpecification;

        this.params.feedSpecifications.push(specToadd);
        _.remove(this.availableFeedSpecs, this.chosenSpec);
        this.disableSave = false;

        setTimeout(() => {
            this.chosenSpec = undefined;
        });
    }

    removeSpec(spec: DeliveryDetailFeedSpecification) : void{
        this.availableFeedSpecs.push(spec.feedSpecification);
        this._sortAvailableFeedSpecs();

        _.remove(this.params.feedSpecifications, spec);

        if (this.params.transmittalOutputTypeID  == TransmittalOutputType.BillPay)
          {
            if (this.params.feedSpecifications.length < 1)
               this.disableSave = true;
          }
    }

    save() {
        this.result = {
            feedSpecifications: this.params.feedSpecifications,
            clientAbbreviation: this.params.clientAbbreviation
        };

        this.close();
    }

    close() {
        this._bsModalRef.hide();
    }

    private _sortAvailableFeedSpecs() {
        this.availableFeedSpecs = _.sortBy(this.availableFeedSpecs, 'name');
    }
}