import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { AccountService } from '../../User/account.service';
import { RestrictService } from '../../Common/Permissions/restrict.service';

@Component({
    selector: 'instance-rights-list',
    templateUrl: './instanceRightsList.component.html',
    styleUrls: ['./instanceRightsList.component.scss']

})
export class InstanceRightsListComponent {
    constructor(private accountService: AccountService, private _restrictService: RestrictService) {
    }

    @Input() availableInstanceRights: Core.InstanceRightModel[];
    @Input() engagement: Core.ConsultingEngagementResponse;     // current permissions hangs off this
    @Output() editModeEvent = new EventEmitter<boolean>();

    editMode: boolean = false;
    permissionToAdd: {screenTypeID: number, permission: number} = {screenTypeID: null, permission: null};
    screenTypePermissions = [];

    onUpdateEditMode() {
        this.editModeEvent.emit(this.editMode);
    }

    //get remaining screen type names that can be assigned
    getUniqueAvailableScreenTypes():Core.InstanceRightModel[] {
        const unique=  _.uniqBy(this.availableInstanceRights.filter(p => this.isPermissionUsable(p)), 'screenTypeID');
        return _.sortBy(unique, (p) => {return p.screenType;});
    }

    //add permission button handler
    selectPermissionToAdd(): void {
        this.permissionToAdd = {screenTypeID: null, permission: null};
        this.editMode = true;
        this.onUpdateEditMode();
    }

    //cancel add operation
    closeAddPermission(): void {
        this.permissionToAdd = {screenTypeID: null, permission: null};
        this.editMode = false;
        this.onUpdateEditMode();
    }

    //new permisison added
    addPermission(): void{
        this.engagement.permissions.push(this.lookupPermission(this.permissionToAdd.screenTypeID, this.permissionToAdd.permission));
        this.closeAddPermission();
    }

    //screen type selected in the add permission section
    screenTypeSelected(): void {
        this.screenTypePermissions = this.availablePermissionRights(this.permissionToAdd.screenTypeID);
        this.permissionToAdd.permission = _.minBy(this.screenTypePermissions, 'rightsID').rightsID;
    }

    //right is seleled in the add permission section
    rightSelected(permission): void {
        const p = this.availableInstanceRights.find(r => r.instanceRightID == permission.instanceRightID);
        permission.rights = p.rights;
        permission.rightsID = p.rightsID;
    }

    //get current users rights for a screen type
    availablePermissionRights(screenTypeId) {
        if (this.availableInstanceRights === undefined || this.availableInstanceRights.length === 0) {
            return;
        }

        return _.filter(this.availableInstanceRights, (ap) => {
            return ap.screenTypeID == screenTypeId;
        });
    }

    //remove permission from engagement
    deletePermission(permission):void {
        _.remove(this.engagement.permissions, permission);
    }

    //checks to see if user has the permission right
    userHasInstanceRight(permission):boolean {
        return this._restrictService.hasInstanceRight(permission.name, this.engagement.companyInstanceId);
    }

    //look up permission
    private lookupPermission(screenTypeID:number, rightsId:number) {
        const perm =  this.availableInstanceRights.filter(ir => ir.rightsID === rightsId && ir.screenTypeID === screenTypeID)[0] || null;
        if(perm !== null) return perm;
        throw 'Permission cannot be assigned';
    }

    //check to see if a screen type is not already assigned and if user has rights to it in this instance
    private isPermissionUsable(permission):boolean {

        const rightHasAlreadyAssigned = !_.some(this.engagement.permissions, { screenTypeID: permission.screenTypeID});
        let userInstanceRights = [];

        const engagementInstanceRights = this.accountService.userData.instanceRights.find(i => i.instanceId == this.engagement.companyInstanceId);
        if(engagementInstanceRights) userInstanceRights = engagementInstanceRights.rights.map(r => r.name);

        const  rightsWithSameScreenType = this.availableInstanceRights.filter(ap => ap.screenTypeID == permission.screenTypeID);
        const userHasRightWithScreenTypeInThisInstance = userInstanceRights.some(r => rightsWithSameScreenType.some(s => s.name === r));
        return rightHasAlreadyAssigned && userHasRightWithScreenTypeInThisInstance;
    }

}
