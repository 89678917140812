import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {
    GetPropCharValuesRequest, TopLevelCompany, SelectedEntity, GenericEntity,
    ReportDetail, EntitySelection, GetEntitiesRequest
} from './report.manager.model';
import { ReportManagerSelectEntitiesService } from './select.entities.service';
import {InstanceRights, RestrictService} from '../../Common/Permissions/restrict.service';
import { ReportManagerService } from './report.manager.service';
import { ReportType } from './report-type';
import { ToastrService } from 'ngx-toastr';
import { EntityTypeIds } from '../../constants.new';
import { WeissmanDateFormatPipe } from '../../UI-Lib/Pipes';

declare const _: any;

@Component({
    selector: 'report-manager-select-entities',
    templateUrl: './select.entities.component.html'
})
export class ReportManagerSelectEntitiesComponent implements OnInit {
    constructor(private selectEntitiesService: ReportManagerSelectEntitiesService,
                private restrictService: RestrictService,
                private readonly _toastr: ToastrService,
                private reportManagerService: ReportManagerService,
                private readonly _datePipe: WeissmanDateFormatPipe) {
        this.reportFavoriteChange = new EventEmitter<boolean>();
        this.entitySelectionChanged = new EventEmitter<boolean>();
        this.excludeInactiveChanged = new EventEmitter<boolean>();
    }

    @Input() entitySelection: EntitySelection;
    @Input() dataLoaded: boolean = true;
    @Input() report: ReportDetail;
    @Input() hideTopLevelCompanyPicker: boolean = false;
    @Input() showSupportInstancePicker: boolean = false;
    @Output() reportFavoriteChange: EventEmitter<boolean>;
    @Output() entitySelectionChanged: EventEmitter<boolean>;
    @Output() excludeInactiveChanged: EventEmitter<boolean>;

    additionalSelections: SelectedEntity[];
    loadingTopLevelCompanies: boolean;
    hasInstancePrivatePermission: boolean;
    reportTypes = ReportType;
    topLevelCompanies: TopLevelCompany[];
    loadingSupportInstances: boolean;
    supportInstances: Core.SupportInstanceInfoModel[];
    headerText: string;
    hasInactiveEntities: boolean;
    showMoreSelections: boolean;

    async ngOnInit() : Promise<void> {
        this.headerText = this.report.reportTypeId !== ReportType.SupportInstanceEscalationActivity
            ? 'Select Your Companies/Sites/Parcels'
            : 'Select Your Companies/Users';
        this.hasInactiveEntities = this.report.reportTypeId !== ReportType.SupportInstanceEscalationActivity;

        this.topLevelCompanies = [];
        this.supportInstances = [];
        this.hasInstancePrivatePermission = this.restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW)
            || this.restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT);

        if(!this.hasInstancePrivatePermission) {
            this.report.criteria.frequentlyUsed = false;
        }

        const initedEntities: SelectedEntity[] = this.selectEntitiesService.getSelectedEntities();
        this.entitySelection.selectedEntities = _.map(this.entitySelection.selectedEntities, (selectedEntity) => {
            const initedEntity = _.find(initedEntities, x => {
                if(selectedEntity.isAppealResponsibility && x.isAppealResponsibility) {  // Appeal Responsibiliy and Users (not contacts) have the same ID
                    return true;
                }

                return x.id === selectedEntity.id && !selectedEntity.isAppealResponsibility && !x.isAppealResponsibility;
            });

            return _.defaults(selectedEntity, initedEntity);
        });

        this.configureAdditionalSelections();

        if (!this.hideTopLevelCompanyPicker) {
            await this.getTopLevelCompanies();
        }

        if (this.showSupportInstancePicker) {
            await this.loadInstances();
            await this.maybeGetSubentities(-1);
        }
    }

    async getTopLevelCompanies(): Promise<any> {
        this.loadingTopLevelCompanies = true;

        try {
            this.reportFavoriteChange.emit(this.report.criteria.frequentlyUsed);

            const topLevelCompanies = await this.selectEntitiesService.getTopLevelCompanies(this.report.criteria.frequentlyUsed, this.entitySelection.excludeInactive);
            this.topLevelCompanies = _.sortBy(topLevelCompanies, item => item.companyName.toLowerCase());
        } finally {
            this.loadingTopLevelCompanies = false;
        }

        const topLevelCompanyIDs: number[] = _.map(this.topLevelCompanies, 'companyID') as number[];
        this.entitySelection.selectedTopLevelCompanyIDs = _.intersection(this.entitySelection.selectedTopLevelCompanyIDs, topLevelCompanyIDs) as number[];
        this.reportManagerService.topLevelCompanies$.next({
            topLevelCompanyIds: this.entitySelection.selectedTopLevelCompanyIDs,
            initFromSavedReport: this.report.isOwnedByUser
        });

        if (this.topLevelCompanies.length === 0 || this.entitySelection.selectedTopLevelCompanyIDs.length === 0) {
            this.removeEmptyEntities(0);
            return Promise.resolve();
        }

        return this.maybeGetSubentities(-1);
    }

    async selectionButtonClicked(entityId: number): Promise<void> {
        const targetEntity: SelectedEntity = _.find(this.additionalSelections, { id: entityId });

        targetEntity.loading = true;

        try {
            await this.getSelectionData(entityId, targetEntity);
            this.configureAdditionalSelections();
        } finally {
            targetEntity.loading = false;
        }
    }

    async propCharChanged(entity: SelectedEntity, i: number): Promise<void> {
        const payload: GetPropCharValuesRequest = new GetPropCharValuesRequest();
        Object.assign(payload, this.selectEntitiesService.getPayloadSourceEntityData(this.entitySelection, i, true));
        payload.selectedPropCharName = entity.selectedDropdown;

        entity.loading = true;

        const type = entity.id === 999 ? 'site' : 'parcel';
        try {
            const propCharValues = await this.selectEntitiesService.getPropCharValues(type, payload);
            entity.list = propCharValues
                .map((val: any) => {
                    let tempValue = val;

                    if (tempValue instanceof Date && !isNaN(tempValue.getTime())) {
                        tempValue = this._datePipe.transform(val, true);
                    }
                    return {
                        id: null,
                        name: tempValue
                    };
                });
            entity.list.sort((x, y) => x.name.toLowerCase().localeCompare(y.name.toLowerCase()));

            this.removeEmptyEntities(i);
        } finally {
            entity.loading = false;
        }
    }

    selectAllTopLevelCompanies(): void {
        this.entitySelection.selectedTopLevelCompanyIDs = _.map(this.topLevelCompanies, 'companyID') as number[];

        this.maybeGetSubentities(-1);
    }

    apply(): void {
        this.entitySelectionChanged.emit(true);
    }

    unselectAllTopLevelCompanies(): void {
        this.entitySelection.selectedTopLevelCompanyIDs = [];

        this.maybeGetSubentities(-1);
    }

    selectAll(entity: SelectedEntity, i: number): void {
        if (entity.id == 999 || entity.id == 998) {
            entity.selectedPropCharVals = _.map(entity.list, 'name') as string[];
        } else {
            entity.selectedIDs = _.map(entity.list, 'id');
        }

        this.maybeGetSubentities(i);
    }

    unselectAll(entity: SelectedEntity, i: number): void {
        if (entity.id == 999 || entity.id == 998) {
            entity.selectedPropCharVals = [];
        } else {
            entity.selectedIDs = [];
        }

        this.maybeGetSubentities(i);
    }

    areAllSelected(entity: SelectedEntity): boolean {
        if (entity.id == 999 || entity.id == 998) {
            return entity.selectedPropCharVals.length == entity.list.length;
        } else {
            return entity.selectedIDs.length == entity.list.length;
        }
    }

    removeLast(): void {
        const last: SelectedEntity = _.last(this.entitySelection.selectedEntities);
        if(last && (last.id === 999 || last.id === 998)) {
            this.reportManagerService.setNewPropChars([]);
        }

        this.entitySelection.selectedEntities.pop();

        this.configureAdditionalSelections();
     }

    selectedTopLevelCompaniesChanged(e): void {
        if(e.target.selectedOptions.length > 1 && this._reportDisallowsMultipleTLCs()) {
            const multiSelect: any = document.getElementById('top-level');

            for (let i = 0; i < multiSelect.options.length; i++) {
                if (multiSelect.options[i].value !== this.entitySelection.selectedTopLevelCompanyIDs[0].toString()) {
                    multiSelect.options[i].selected = false;
                }
            }

            this._toastr.error('Limit to only one Top Level Company for this report');

            return;
        }

        this.entitySelection.selectedTopLevelCompanyIDs = _.map(e.target.selectedOptions, option => Number(option.value)) as number[];

        this.reportManagerService.topLevelCompanies$.next({
            topLevelCompanyIds: this.entitySelection.selectedTopLevelCompanyIDs,
            initFromSavedReport: false
        });

        this.configureAdditionalSelections();
        this.maybeGetSubentities(-1);
    }

    selectedSubEntitiesChanged(eventTarget: EventTarget, entity: SelectedEntity, i: number): void {
        const selectedOptions = (eventTarget as HTMLSelectElement).selectedOptions;

        if (entity.id === 999 || entity.id == 998) {
            entity.selectedPropCharVals = _.map(selectedOptions, option => option.value) as string[];
        } else {
            entity.selectedIDs = _.map(selectedOptions, option => {
                return entity.id === EntityTypeIds.USER ? option.value : Number(option.value);
            });
        }

        this.maybeGetSubentities(i);
     }

    showAdditionalSelections(): boolean {
        return this.additionalSelections.length &&
            (!this.entitySelection.selectedEntities.length || _.last(this.entitySelection.selectedEntities).list.length);
    }

    noItemsSelected(): boolean {
        if (this.entitySelection.selectedEntities.length) {
            const lastEntity: SelectedEntity = _.last(this.entitySelection.selectedEntities);

            return lastEntity.selectedIDs.length == 0 && lastEntity.selectedPropCharVals.length == 0;
        } else {
            return this.entitySelection.selectedTopLevelCompanyIDs.length == 0 && !this.hideTopLevelCompanyPicker;
        }
    }

    isAppealResponsibilitySelected() {
        return this.entitySelection && _.some(this.entitySelection.selectedEntities, 'isAppealResponsibility');
    }

    selectedInstanceChanged(e): void {
        this.entitySelection.selectedSupportInstanceIds = _.map(e.target.selectedOptions, option => Number(option.value)) as number[];
    }

    async loadInstances(): Promise<void> {
        this.loadingSupportInstances = true;

        try {
            this.reportFavoriteChange.emit(this.report.criteria.frequentlyUsed);

            const instances = await this.selectEntitiesService.getSupportInstances();
            this.supportInstances = _.sortBy(instances, item => item.instanceName.toLowerCase());
        } finally {
            this.loadingSupportInstances = false;
        }

        const instanceIds: number[] = _.map(this.supportInstances, 'instanceId') as number[];
        this.entitySelection.selectedSupportInstanceIds = _.intersection(this.entitySelection.selectedSupportInstanceIds, instanceIds) as number[];
    }

    selectAllSupportInstances(): void {
        this.entitySelection.selectedSupportInstanceIds = _.map(this.supportInstances, 'instanceId') as number[];
    }

    unselectAllSupportInstances(): void {
        this.entitySelection.selectedSupportInstanceIds = [];
    }

    //
    // Private Helper Functions
    //

    private async maybeGetSubentities(i: number): Promise<any> {
        if (i === (this.entitySelection.selectedEntities.length - 1)) {
            return Promise.resolve();
        }

        return this.getNextEntities(i + 1);
    }

    private async getSelectionData(entityId: number, targetEntity: SelectedEntity): Promise<void> {
        const i = this.entitySelection.selectedEntities.length - 1;
        const payload: GetEntitiesRequest = this.selectEntitiesService.getPayloadSourceEntityData(this.entitySelection, i);

        this.entitySelection.selectedEntities.push(targetEntity);

        if (entityId === 999 || entityId === 998) {
            const type = entityId === 999 ? 'site' : 'parcel';
            const parentPropChars = await this.selectEntitiesService.getPropCharsFromParent(type, payload);
            targetEntity.dropdownList = _.chain(parentPropChars)
                .map('name')
                .sortBy(parentPropChars, item => item.toLowerCase())
                .value() as string[];

            this.reportManagerService.setNewPropChars(parentPropChars);
        }
        else {
            payload.targetEntityTypeID = entityId;
            const parentEntities = await this.getEntitiesFromParent(payload);

            targetEntity.list = _.sortBy(parentEntities, item => item.name.toLowerCase()) as GenericEntity[];
        }
    }

    private async getEntitiesFromParent(payload: GetEntitiesRequest): Promise<GenericEntity[]> {
        if(payload.targetEntityTypeID === EntityTypeIds.USER && this.report.reportTypeId === this.reportTypes.UserAccess) {
            return await this.selectEntitiesService.getUsersWithAccessToCertainCompanies(payload, this.report.criteria.includeInternalUsers);
        }
        else if(payload.targetEntityTypeID === EntityTypeIds.CLIENTSERVICE) {
            return await this.selectEntitiesService.getClientServices(payload);
        }
        else if (payload.targetEntityTypeID === EntityTypeIds.PROPERTYTYPE) {
            return await this.selectEntitiesService.getPropertyTypes(payload);
        }
        else
        {
            return await this.selectEntitiesService.getEntitiesFromParent(payload);
        }
    }

    private removeEmptyEntities(i: number): void {
        this.entitySelection.selectedEntities = _.take(this.entitySelection.selectedEntities, i + 1) as SelectedEntity[];

        this.configureAdditionalSelections();
    }

    private configureAdditionalSelections(): void {
        const selectedEntities: SelectedEntity[] = this.selectEntitiesService.getSelectedEntities();

        this.additionalSelections = _.filter(selectedEntities, (entity: SelectedEntity): boolean => {
            const selectedEntitiesIDs: number[] = _.map(this.entitySelection.selectedEntities, 'id');

            // This is turning into something really messy....
            const preReqsSatisfied: boolean = _.intersection(selectedEntitiesIDs, entity.prerequisites).length === entity.prerequisites.length;
            const notBlocked: boolean = _.intersection(selectedEntitiesIDs, entity.blockers).length === 0;

            const maybeAppealResp = !entity.isAppealResponsibility || (this.entitySelection.selectedTopLevelCompanyIDs.length < 2 && (this.report.reportTypeId === this.reportTypes.Site));
            const maybeContacts = entity.id !== EntityTypeIds.CONTACT || (this.entitySelection.selectedTopLevelCompanyIDs.length < 2 && this.report.reportTypeId === this.reportTypes.UserActivity);
            const maybeUsers = entity.id !== EntityTypeIds.USER || entity.isAppealResponsibility || this.report.reportTypeId === this.reportTypes.UserAccess;
            const maybeSupportUsers = entity.id !== EntityTypeIds.SUPPORTUSER
                || this.report.reportTypeId === this.reportTypes.UserAccess
                || this.report.reportTypeId === this.reportTypes.UserActivity
                || this.report.reportTypeId === this.reportTypes.SupportInstanceEscalationActivity;

            const maybeSubsidiaries = entity.id != EntityTypeIds.COMPANY || _.intersection(selectedEntitiesIDs, [EntityTypeIds.USER]).length === 0 || this.report.reportTypeId === this.reportTypes.Site;

            const maybeUserActivityReport = this.report.reportTypeId != this.reportTypes.UserActivity || entity.id === EntityTypeIds.CONTACT || entity.id === EntityTypeIds.COMPANY;
            const maybeUserAccessReport = this.report.reportTypeId != this.reportTypes.UserAccess || entity.id === EntityTypeIds.USER || entity.id === EntityTypeIds.COMPANY;

            //condition is true if CsrCommandCenterReport OR not one of these 3 entity type (filters)
            const maybeCsrCommandCenterReport = this.report.reportTypeId === this.reportTypes.CsrCommandCenter || !(entity.id === EntityTypeIds.CLIENTSERVICE || entity.id === EntityTypeIds.PROPERTYTYPE || entity.id === 998);

            return preReqsSatisfied && notBlocked && maybeAppealResp && maybeContacts && maybeUserActivityReport && maybeUserAccessReport && maybeUsers && maybeSupportUsers && maybeSubsidiaries && maybeCsrCommandCenterReport;
        }) as SelectedEntity[];
    }

    private async getNextEntities(i: number): Promise<any> {
        const entity: SelectedEntity = this.entitySelection.selectedEntities[i];
        const payload: any = this.selectEntitiesService.getPayloadSourceEntityData(this.entitySelection, i - 1);

        entity.loading = true;

        try {
            if (entity.id === 999 || entity.id === 998) {
                const type = entity.id === 999 ? 'site' : 'parcel';
                const parentPropChars = await this.selectEntitiesService.getPropCharsFromParent(type, payload);
                return this.processPropChars(entity, i, parentPropChars);
            } else {
                payload.targetEntityTypeID = entity.id;

                const parentEntities = await this.getEntitiesFromParent(payload);
                return this.processEntity(entity, i, parentEntities);
            }
        } finally {
            entity.loading = false;
        }
    }

    private async processPropChars(entity: SelectedEntity, i: number, propChars: GenericEntity[]): Promise<any> {
        entity.dropdownList = _.map(propChars, 'name');

        if (!_.includes(entity.dropdownList, entity.selectedDropdown)) {
            entity.list = [];
            entity.selectedDropdown = '';
            this.removeEmptyEntities(i);

            return Promise.resolve();
        }

        await this.propCharChanged(entity, i);

        i++;
        if (i < this.entitySelection.selectedEntities.length) {
            return this.getNextEntities(i);
        }
        else {
            return Promise.resolve();
        }
    }

    private processEntity(entity: SelectedEntity, i: number, entityList: GenericEntity[]): Promise<any> {
        entity.selectedIDs = _.intersection(entity.selectedIDs, _.map(entityList, 'id'));
        entity.list = _.sortBy(entityList, item => item.name.toLowerCase()) as GenericEntity[];

        if (entity.list.length === 0 || entity.selectedIDs.length === 0) {
            this.removeEmptyEntities(i);

            return Promise.resolve();
        }

        i++;

        if (i < this.entitySelection.selectedEntities.length) {
            return this.getNextEntities(i);
        } else {
            return Promise.resolve();
        }
    }

    private _reportDisallowsMultipleTLCs() {
        return this.report.reportTypeId == ReportType.Budget
            || this.report.reportTypeId == ReportType.BudgetVariance
            || this.report.reportTypeId == ReportType.Site;
    }
}
