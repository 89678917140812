import { Component, Input, ViewChild, ElementRef, EventEmitter, Output, OnInit } from '@angular/core';
import { PortfolioAnalysisWidgetModel } from './portfolio.analysis.widget.model';
import { ActivityService } from '../../../Common/Activity/activity.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeMixin } from '../../../UI-Lib/Mixins/unsubscribe.mixin';
import { DashboardDataService } from '../../dashboard.data.service';
import { TimerService } from '../../../UI-Lib/Utilities';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import Exporting from 'highcharts/modules/exporting';

import * as _ from 'lodash';
declare const Highcharts: any;
Exporting(Highcharts);

@Component({
    selector: 'portfolio-analysis-chart-table',
    templateUrl: './portfolio.analysis.chart.table.component.html'
})
export class PortfolioAnalysisChartTableComponent extends UnsubscribeMixin() implements OnInit {
    constructor(
        private readonly _activityService: ActivityService,
        private readonly _dashboardDataService: DashboardDataService,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {
        super();
    }

    @ViewChild('chart', { static: true }) chart: ElementRef;
    @Input() data: PortfolioAnalysisWidgetModel;
    @Input() resize$: Observable<void>;
    @Output() showAllYearsClicked: EventEmitter<void> = new EventEmitter();
    @Output() graphSelected: EventEmitter<void> = new EventEmitter();

    highChart: any;
    config: any;
    activityMode: 'graph' | 'table' = 'graph';
    // The values of this object are attribute names in the totalValues array objects
    // populated in this.config by ActivityService.
    TotalValueNames: any = {
        TOTALFMV: 'totalFMV',
        FMVPERSQFT: 'fmvPerSqFt',
        FMVPERUNIT: 'fmvPerUnit',
        LANDFMV: 'landFMV',
        LANDFMVPERSQFT: 'landFMVPerSqFt',
        LANDFMVPERACRE: 'landFMVPerAcre',
        TOTALTAX: 'totalTax',
    };
    // The keys of this object align with the values of the object above.
    TotalValueAttributes: any = {
        'totalFMV': { title: 'Total FMV',     precision: '1.0-0' },
        'fmvPerSqFt': { title: '$/SqFt',        precision: '1.2-2' },
        'fmvPerUnit': { title: '$/Unit',        precision: '1.2-2' },
        'landFMV': { title: 'Land FMV',      precision: '1.0-0' },
        'landFMVPerSqFt': { title: 'Land $/SqFt',   precision: '1.2-2' },
        'landFMVPerAcre': { title: 'Land $/Acre',   precision: '1.2-2' },
        'totalTax': { title: 'Total Tax',     precision: '1.2-2' },
    };

    ngOnInit() {
        if (this.resize$) {
            this.resize$.pipe(takeUntil(this.destroy$)).subscribe(r => {
                this._timer.setTimeout(() => {
                    if (this.highChart) {
                        this.highChart.setSize();
                    }
                }, 0);
            });
        }
    }

    updateChartType(chartType: string, data: PortfolioAnalysisWidgetModel): void {
        const afterPrintCb = () => this._dashboardDataService.resizeGrid$.next(); // WK-9467 Need to resize the grid after chart is printed due to weirdness
        this.config = this._activityService.createGraphConfigForDashboard(data, chartType, afterPrintCb);

        this.highChart = Highcharts.chart(this.chart.nativeElement, this.config);
    }

    reverseIt(categories: any[]) {
        return _.reverse(_.cloneDeep(categories));
    }

    getCellValue(seriesItem, year:number): string {
        const xAxisCategoryIndex = this.config.xAxis.categories.indexOf(year);
        return seriesItem.data[xAxisCategoryIndex].y;
    }

    getTotalValueForYear(year: number, totalValueName: string): number {
        let result = 0;
        const yearIndex: number = this.config.totalValues.findIndex(entry => entry.year === year);
        const yearTotals = yearIndex >= 0 && yearIndex < this.config.totalValues.length ? this.config.totalValues[yearIndex] : null;
        if ( yearTotals && totalValueName && yearTotals[totalValueName] > 0 )
        {
            result = yearTotals[totalValueName];
        }
        return result;
    }

    showColumn(totalValueName: string, arrayOfAllowedModes: string[]): boolean {
        if (arrayOfAllowedModes.indexOf(this.config.chartType) < 0)
            return false;

        if (totalValueName === '')
            return true;

        // do we have any value?
        return _.some(this.config.totalValues, entry => entry[totalValueName] > 0);
    }

    getColumnTitle(totalValueName: string): string {
        return this.TotalValueAttributes[totalValueName] ? this.TotalValueAttributes[totalValueName].title : 'error!';
    }

    getPrecision(totalValueName: string): string {
        return this.TotalValueAttributes[totalValueName] ? this.TotalValueAttributes[totalValueName].precision : '1.0-0';
    }

    sortByPropType(series): any {
        return _.orderBy(series, 'propertyTypeSequence');
    }

    chartTypeSelected(type: string): void {
        if (type === 'graph') {
            this.graphSelected.emit();
        }
        this._productAnalyticsService.logEvent('click-portfolio-widget', {
            format: type
        });
    }

    reflowChart() {
        return null;
    }
}
