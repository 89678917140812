import { OnInit, Input, Component, OnChanges, SimpleChanges } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent, ColDef, ValueGetterParams } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams, AgGridDropdownCellEditor } from '../../../Compliance/AgGrid';
import { DropdownCellEditorParams } from '../../../Compliance/AgGrid/CellEditors/agGridDropdownCellEditor.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { UserInstanceService } from '../../../User/userInstance.service';
import { PermissionService } from '../../permission.service';
import { CompanyPermissionAddComponent, CompanyPermissionAddParams } from '../Add/companyPermissionAdd.component';
import { CompanyPermissionAgGridDataSource, CompanyPermissionDataSourceParams } from './agGridDataSource';
import { CompanyPermissionOptions } from './CompanyPermissionOptions';
import { CompanyPermissionsGridActionCellRendererComponent, ICellRendererParamsForCompanyPermissionsGridAction } from './agGridActionCellRenderer.component';
import * as _ from 'lodash';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { USER_GROUP_DETAIL_HELP } from '../../../User-Group/User-Group-Detail/userGroupDetail.component.help';
import { CompanySitePermissionsParams, CompanySitePermissionsComponent } from '../Site/companySitePermissions.component';

@Component({
    templateUrl: './companyPermissionUserPanel.component.html',
    selector: 'company-permission-user-panel',
    styles: [`
        :host {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
        }
    `]
})
export class CompanyPermissionUserPanelComponent implements OnInit, OnChanges {
    constructor(
        private readonly _userInstanceService: UserInstanceService,
        private readonly _permissionService: PermissionService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService) { }

    @Input() userToEdit: {permissions: Core.PermissionModel[]};
    @Input() editMode: boolean;
    @Input() userId: System.Guid;

    addedCompanies: Core.PermissionModel[] = [];
    currentInstance: Core.InstanceViewModel;
    agGridReady: boolean;
    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            rowClassRules: {
                'grid-row-deleted': params => params.data && _.some(this.userToEdit.permissions, x => {
                    return x.efAction == 'delete' && x.permissionID === params.data.permissionID;
                })
            },
            singleClickEdit: true,
            suppressHorizontalScroll: true
        })
        .withColumnPinning()
        .buildDefault(this);

    private _gridApi: GridApi;
    private _gridDataSource: CompanyPermissionAgGridDataSource;

    ngOnInit(): void {
        this.userToEdit.permissions = [];
        this.currentInstance = this._userInstanceService.getSelectedInstance();
        this._helpService.setContent(USER_GROUP_DETAIL_HELP);
    }

    ngOnChanges(changes: SimpleChanges) {
        if(changes.editMode && (!changes.editMode.currentValue && changes.editMode.previousValue)) {
            this.userToEdit.permissions = [];
            this.refresh();
        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Company',
                field: 'entityName',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth
            },
            {
                headerName: 'Top Level Company',
                field: 'topLevelCompany',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth
            },
            {
                headerName: 'Access Level',
                field: 'rightsDropdown',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (params) => {
                    const permissionRow = params.data as Core.PermissionModel;
                    if (!permissionRow) {
                        return false;
                    }

                    return this.editMode && !_.some(this.userToEdit.permissions, x => {
                        return x.efAction == 'delete' && x.permissionID === permissionRow.permissionID;
                    });
                },
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => [
                        { name: CompanyPermissionOptions.ViewAllSites, value: CompanyPermissionOptions.ViewAllSites },
                        { name: CompanyPermissionOptions.ViewEditSomeSites, value: CompanyPermissionOptions.ViewEditSomeSites },
                        { name: CompanyPermissionOptions.EditAllSites, value: CompanyPermissionOptions.EditAllSites }
                    ],
                    cellChanged: (params: DropdownCellEditorParams, newValue: string) => {
                        const permission = params.data as Core.PermissionModel;
                        this._setPermissionFlags(permission, newValue);

                        const alreadyUpdatedIdx = _.findIndex(this.userToEdit.permissions, x => {
                            return x.efAction === 'update' && x.permissionID === permission.permissionID;
                        });

                        if(alreadyUpdatedIdx >= 0) {
                            this.userToEdit.permissions[alreadyUpdatedIdx] = permission;
                        } else {
                            permission.efAction = 'update';
                            this.userToEdit.permissions.push(permission);
                        }
                    }
                },
                valueGetter: this._getDropownPermission.bind(this)
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: CompanyPermissionsGridActionCellRendererComponent,
                cellRendererParams: {
                    editMode: params => this.editMode,
                    canLaunchSitesModal: (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permissionOption = this._getDropownPermission(params);
                        return permissionOption === CompanyPermissionOptions.ViewEditSomeSites;
                    },
                    launchSitesModal: async (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permission = params.data as Core.PermissionModel;
                        const saveRequest: Core.PermissionSaveRequest = {
                            adds: [],
                            deletes: [],
                            updates: []
                        };

                        _.forEach(this.userToEdit.permissions, x => {
                            if(x.efAction === 'add') {
                                saveRequest.adds.push(x);
                            } else if(x.efAction === 'update') {
                                saveRequest.updates.push(x);
                            } else if(x.efAction === 'delete') {
                                saveRequest.deletes.push(x);
                            }
                        });

                        const modalParams: CompanySitePermissionsParams = {
                            companyId: permission.entityID,
                            userId: this.userId,
                            editMode: this.editMode,
                            saveRequest: _.cloneDeep(saveRequest)
                        };

                        const result = await this._modalService.showAsync(CompanySitePermissionsComponent, modalParams, 'modal-lg');

                        document.getElementsByTagName('body')[0].classList.add('modal-open');

                        if(!result) {
                            return Promise.resolve();
                        }

                        result.adds = _.map(result.adds, x => {
                            x.efAction = 'add';
                            return x;
                        });
                        result.updates = _.map(result.updates, x => {
                            x.efAction = 'update';
                            return x;
                        });
                        result.deletes = _.map(result.deletes, x => {
                            x.efAction = 'delete';
                            return x;
                        });

                        this.userToEdit.permissions = [...result.adds, ...result.updates, ...result.deletes];
                    },
                    canRemove: params => {
                        const permissionRow = params.data as Core.PermissionModel;
                        if (!permissionRow) {
                            return false;
                        }

                        return this.editMode && !_.some(this.userToEdit.permissions, x => {
                            return x.efAction == 'delete' && x.permissionID === permissionRow.permissionID;
                        });
                    },
                    remove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permissionRow = params.data as Core.PermissionModel;

                        if(params.node.rowPinned) {
                            this.userToEdit.permissions = _.reject(this.userToEdit.permissions, {entityID: permissionRow.entityID});
                            this.addedCompanies = _.reject(this.addedCompanies, {entityID: permissionRow.entityID});
                        } else {
                            permissionRow.efAction = 'delete';
                            this.userToEdit.permissions.push(permissionRow);
                            this._gridApi.redrawRows();
                        }
                    },
                    canUndoRemove: params => {
                        const permissionRow = params.data as Core.PermissionModel;
                        if (!permissionRow) {
                            return false;
                        }

                        return this.editMode && _.some(this.userToEdit.permissions, x => {
                            return x.efAction == 'delete' && x.permissionID === permissionRow.permissionID;
                        });
                    },
                    undoRemove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permissionRow = params.data as Core.PermissionModel;
                        this.userToEdit.permissions = _.reject(this.userToEdit.permissions, {permissionID: permissionRow.permissionID});

                        this._gridApi.redrawRows();
                    },
                } as ICellRendererParamsForCompanyPermissionsGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'entityName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    async addNewCompanyPermission() {
        // Add new permission modal
        const dataSourceParams: CompanyPermissionAddParams = {
                instanceId: this.currentInstance.instanceId,
                userId: this.userId,
                excludeCompanyIds: _.map(this.addedCompanies, 'entityID')
            };

        const result = await this._modalService.showAsync(CompanyPermissionAddComponent, dataSourceParams, 'modal-md');

        document.getElementsByTagName('body')[0].classList.add('modal-open');

        if (!result) {
            return Promise.resolve();
        }

        result.selectedRows = _.map(result.selectedRows, x => {
            this._setPermissionFlags(x, result.permission);
            x.efAction = 'add';
            return x;
        });

        this.userToEdit.permissions = [...this.userToEdit.permissions, ...result.selectedRows];
        this.addedCompanies = [...this.addedCompanies, ...result.selectedRows];
    }

    refresh(): void {
        this.addedCompanies = [];
        this._refreshDataSource();
    }

    private _getDropownPermission(params: ValueGetterParams): string {
        const dbPermission = params.data as Core.PermissionModel;

        if (!dbPermission) {
            return '';
        }

        const p = _.find(this.userToEdit.permissions, {permissionID: dbPermission.permissionID}) || dbPermission;

        if (p.writeAllowed) {
            if (p.includeAllChildRecords) {
                return CompanyPermissionOptions.EditAllSites;
            } else {
                return CompanyPermissionOptions.ViewEditSomeSites;
            }
        }

        if (p.readAllowed) {
            if (p.includeAllChildRecords) {
                return CompanyPermissionOptions.ViewAllSites;
            } else {
                return CompanyPermissionOptions.ViewEditSomeSites;
            }
        }
    }

    private _setPermissionFlags(permission: Core.PermissionModel, rightsString: string): void {
        permission.readAllowed = true;
        permission.writeAllowed = rightsString === CompanyPermissionOptions.EditAllSites;
        permission.includeAllChildRecords = rightsString === CompanyPermissionOptions.ViewAllSites || rightsString === CompanyPermissionOptions.EditAllSites;
    }

    private _refreshDataSource() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): CompanyPermissionDataSourceParams => {
            return {
                instanceId: this.currentInstance.instanceId,
                userId: this.userId as number
            };
        };

        this._gridDataSource = new CompanyPermissionAgGridDataSource(this._gridApi, this._permissionService, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }
}
