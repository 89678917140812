import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { StateService } from '../../Common/States/States.Service';
import { AssessorCollectorService } from '../assessor.collector.service';
import { ItemsByLetter } from '../alphabetizedList.component';
import { Assessor } from '../assessor.model';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AddAssessorModalComponent, AddAssessorParams } from './addAssessorModal.component';
import { RestrictService, Roles } from 'src/app/Common/Permissions/restrict.service';

@Component({
    selector: 'assessor-list',
    template: `
    <div class="ws-flex-container-vertical ws-flex-auto ws-stretch" *ngIf="state">
        <div class="ws-flex-container-horizontal ws-flex-none">
            <div class="ws-flex-auto ws-truncate">
                <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Assessors"></ws-bread-crumbs>
            </div>
        </div>

        <div class="ws-section ws-flex-none">
            <div class="ws-section__header">
                <h4 class="ws-section__header__title">{{state.fullName}} Assessors</h4>
                <div class="ws-section__header__actions">
                    <div class="ws-section__header__actions__item">
                        <div class="action-button-wrapper">
                            <button type="button"
                                    *ngIf="canAdd"
                                    class="ace-btn btn-sm btn-secondary has-icon"
                                    (click)="launchAddModal()"
                                    [disabled]="false">
                                <i class="fa fa-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <alphabetized-list *ngIf="assessorsByLetters"
            (letterSelected)="onLetterSelected($event)"
            [startingLetter]="startingLetter"
            [itemsByLetters]="assessorsByLetters">
        </alphabetized-list>
    </div>
    `
})
export class AssessorListComponent implements OnInit {
    constructor(private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _restrictService: RestrictService,
        private readonly _modalService: WeissmanModalService,
        private readonly _stateService: StateService) { }

    state: Weissman.Model.Misc.State;
    breadcrumbs: BreadCrumb[] = [];
    assessorsByLetters: ItemsByLetter[];
    startingLetter: string;
    canAdd: boolean = false;

    async ngOnInit() {
        this.canAdd = this._restrictService.isInRole(Roles.ASSESSOREDIT);
        this.startingLetter = this._upgradeNavigationServiceHandler.getQuerystringParam('letter');
        await this._loadData();
    }

    onLetterSelected(letter: string): void {
        const params = {stateId: this.state.stateID, letter};
        this._upgradeNavigationServiceHandler.go('assessors.letter', params);
    }

    async launchAddModal(): Promise<void> {
        const params: AddAssessorParams = {
            state: this.state
        };

        await this._modalService.showAsync(AddAssessorModalComponent, params, 'modal-md');
    }

    private async _loadData() {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        try {
            const stateId = +this._upgradeNavigationServiceHandler.getQuerystringParam('stateId');
            this.state = await this._stateService.getById(stateId);

            this.breadcrumbs = [
                {
                    name: 'States',
                    target: 'states',
                    options: { }
                },
                {
                    name: this.state.fullName,
                    target: 'state',
                    options: { stateId: this.state.stateID }
                }
            ];

            const [assessorList, assessorActiveParcelCounts] = await Promise.all([
                this._assessorCollectorService.getAssessorList(stateId),
                this._assessorCollectorService.getAssessorActiveParcelCounts(stateId)
            ]);

            this.assessorsByLetters = _.chain(assessorList)
                .groupBy(x => {
                    const firstChar = x.abbr.charAt(0);
                    return isNaN(+firstChar) ? firstChar.toUpperCase() : '#';
                })
                .map((assessors, letter) => {
                    const items = _.map(assessors, (x: Assessor) => {
                        return {
                            name: `${x.abbr} ${x.municipalityName}`,
                            badge: _.find(assessorActiveParcelCounts, {assessorID: x.assessorID})?.activeParcelCount,
                            sref: {target: 'assessor', options: {stateId, id: x.assessorID}}
                        };
                    });

                    return { letter, items };
                })
                .sortBy('letter')
                .value();
        }
        finally {
            busyRef.hide();
        }
    }

}

