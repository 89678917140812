import { Component, Input, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { lastValueFrom } from 'rxjs';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { WeissmanDatetimeComponent } from '../../UI-Lib/Weiss-Datepicker/weissman-datetime.component';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailsNavigationEventService } from '../annual-details-event.service';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AddFilingModalComponent } from '../Modals/add-filing.component';
import { Filing, FilingType } from './compliance.model';
import { ComplianceService, ComplianceViewModel } from './compliance.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { ComplianceReturnAddComponent, ComplianceReturnAddOutputParameters } from './Return-Add/return-add.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { FilingBatchService } from '../../Compliance/Filing-Batch/filingBatch.service';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { ClientServiceResponsibilityService } from '../../Client-Services/clientServiceResponsibility.service';
import { InstanceRights, RestrictService } from '../../Common/Permissions/restrict.service';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';

import * as _ from 'lodash';

interface ComponentChanges extends SimpleChanges {
    viewModel: SimpleChange;
    filingTypes: SimpleChange;
}

@Component({
    selector: 'compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnChanges {
    constructor(
        public readonly toastr: ToastrService,
        private readonly _complianceService: ComplianceService,
        private readonly _annualDetailsService: AnnualDetailsService,
        private readonly _taskService: TaskService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _navigationEvent: AnnualDetailsNavigationEventService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _modalService: BsModalService,
        private readonly _wsModalService: WeissmanModalService,
        private readonly _clientResponsibilityService: ClientServiceResponsibilityService,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _restrictService: RestrictService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    @Input() filingTypes: FilingType[];
    @Input() viewModel: ComplianceViewModel;
    @Input() currentYear: AnnualDetailYear;
    @Input() editState: AnnualDetailEditState;
    @Input() taskReadonly: boolean = false;
    @Input() hideDelete: boolean = false;
    @Input() companyId: number;
    @Input() topLevelCompanyId: number;
    @Input() returnPreparationAllowed: boolean;
    @Input() isDocumentProcessing: boolean = false;
    @ViewChild('filingTabs') filingTabs: TabsetComponent;
    @ViewChild('AddFilingModal') addFilingModal: AddFilingModalComponent;
    @ViewChild('dueDate') dueDateElement: WeissmanDatetimeComponent;
    @ViewChild('filingTaskValidation') filingTaskValidation: TooltipDirective;

    isLoading: boolean = false;
    filingEntityTypeId: number = Core.EntityTypes.Filing;

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    private _hasAssignee: boolean;
    private _hasComplianceFeaturesView: boolean;

    get canCreateFilingBatch(): boolean {
        return (!this.editState.editMode) && // not in edit mode
               this._hasComplianceFeaturesView &&
               this._hasAssignee && // Compliance return responsibility is not N/A
               this.viewModel &&
               this.viewModel.currentFiling &&
               this.viewModel.currentFiling.filingTypeId === Core.FilingTypes.PPReturn && // for now, only PP returns get batches
               this.returnPreparationAllowed && // prepare pp returns on
               (this.viewModel.consolidatedParcelId === this.viewModel.parcelID || this.viewModel.consolidatingTypeId === Core.ConsolidatingTypeEnum.None || (!this.viewModel.consolidatedParcelId)) && // only consolidated parcel, opt out parcel or no consolidation can create batch
               (!this.viewModel.currentFiling.isFromConsolidatedParcel) && // phantom filing from consolidated parcel
               (!this.viewModel.currentFiling.filingBatchId) && // already in a batch
               this.viewModel.currentTaskSummary && // make sure there is a current task summary status
               !this.viewModel.currentTaskSummary.SeriesIsComplete; // already filed (outside the system)

    }

    get isMergedParcel(): boolean {
        return !this.viewModel.consolidatingTypeId && !!this.viewModel.consolidatedParcelId;
    }

    async ngOnChanges(changes: ComponentChanges): Promise<void> {
        if (this.viewModel) {
            const responsibility = await this._clientResponsibilityService.getByEntity({
                id: this.viewModel.parcelID,
                type: 'parcel'
            });
            if (responsibility.entityClientServices && responsibility.entityClientServices.length) {
                this._hasAssignee = responsibility.entityClientServices.some(x => {
                    return x.clientServiceID === 1 && !x.responsibilities.some(y => y.workflowUserTypeID === 0);
                });
            }

            this.editState.showEditControls = this.viewModel && this.viewModel.model.length > 0;

            this.viewModel.hasWritePermission = this.editState.hasWritePermission;
            this.viewModel.updateModalData();
            this.viewModel.resetDateTimeHandler = () => {
                if (this.dueDateElement) {
                    this.dueDateElement.resetValidation();
                }
            };

            if (this.viewModel.defaultFilingId) {
                this._complianceService.setCurrentFiling(this.viewModel, this.viewModel.defaultFilingId);
            }

            const instanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId('company', this.companyId));
            this._hasComplianceFeaturesView = this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, instanceId);
        }
    }

    getReturnStatusCode(filing: Filing): string {
        return this._complianceService.getReturnStatusCode(filing);
    }

    async createFilingBatch(filing: Filing): Promise<void> {
        try {
            await this._annualDetailsService.preNavigateWarn(this.editState);
        } catch (e) {
            return;
        }

        let result: ComplianceReturnAddOutputParameters;

        if (filing.filingBatchId) {
            result = {
                filingBatchId: filing.filingBatchId,
                companyId: this.topLevelCompanyId
            };
        } else {
            const showModalFn = (): BsModalRef => {
                return this._modalService.show(ComplianceReturnAddComponent, {
                    class: 'modal-md',
                    initialState: {
                        filingId: filing.filingId
                    }
                });
            };

            const bsModalRef = await this._wsModalService.show(showModalFn.bind(this)).whenClosed;
            result = (bsModalRef.content as ComplianceReturnAddComponent).result;
        }

        if (!result) {
            return;
        }

        this._upgradeNavigationServiceHandler.go('returnFilingBatchPage',
            {
                'filingBatchId': result.filingBatchId
            });
    }

    isFinalized(filing: Filing): boolean {
        if (!filing.filingBatch) {
            return false;
        }

        return this._filingBatchService.isFinalized(filing.filingBatch.processStatus);
    }

    getFilingHeading(filing: Filing): string {
        return `${filing.description} ${filing.filingType.name}`;
    }

    getFilingBatchPrepareLabel(filing: Filing): string {
        if (this._filingBatchService.isReadOnly(filing.filingBatch.processStatus)) {
            return 'View Returns';
        }

        if (this._filingBatchService.isStarted(filing.filingBatch.processStatus)) {
            return 'Continue Preparation';
        }

        return 'Begin Preparation';
    }

    launchTaskModal(filing: Filing): void {
        if (filing.isFromConsolidatedParcel || filing.filingBatch || (!this.editState.editMode) || (!this.editState.getDirty()) || this.taskReadonly) {
            const entityId = this._complianceService.getTaskEntityId(filing);
            const entityType = this._complianceService.getTaskEntityType(filing);

            this._taskService.launchTaskModal(entityId, entityType, filing.isFromConsolidatedParcel || filing.filingBatch ? true : false || !this.editState.editMode || this.taskReadonly)
                .then((shouldReload) => {
                    this._afterTaskModalClose(shouldReload);
                });
        // This branch will never execute based on the above conditions.
        // Leaving in for posterity
        // } else if (this.editState.editMode && !this.editState.getDirty()) {
        //     this._taskService.launchTaskModal(filing.filingId, Core.EntityTypes.Filing, false)
        //         .then((shouldReload) => {
        //             this._afterTaskModalClose(shouldReload);
        //         });
        } else {
            this.editState.validationHandler((isValid, errorMessage) => {
                if (!isValid) {
                    this.editState.validationMessage = errorMessage;

                    // HACK need setTimeout for some reason as when the 'show'
                    // is called the validationMessage is not set unless we have the timeout
                    setTimeout(() => {
                        // TODO this validation tooltip is not available in the
                        // annual details service. Need to figure out how to set it
                        //
                        // editState.aDValidation.show();
                        //
                        // For now, console.warn:
                        this.toastr.error(errorMessage);
                    });
                    return;
                }

                this.editState.saveHandler().then(() => {
                    this.toastr.info('Filing changes autosaved');
                    this.editState.setDirty(false);
                    this.viewModel.resetEdit(true);

                    this._taskService.launchTaskModal(filing.filingId, Core.EntityTypes.Filing, false)
                        .then((shouldReload) => {
                            this._afterTaskModalClose(shouldReload);
                        });
                });
            });
        }
    }

    async openAddFiling(): Promise<void> {
        try {
            await this._annualDetailsService.preNavigateWarn(this.editState);

            this.addFilingModal.show();
        } catch (e) {
            return Promise.resolve();
        }
    }

    async addFiling(newFiling: any): Promise<void> {
        newFiling = newFiling as Filing;

        this.isLoading = true;

        try {
            await this._complianceService.addNewFilingToViewModel(this.viewModel, newFiling);

            this.editState.refreshGrid = true;
            this._navigationEvent.refreshActivityData();

            // If we're adding the first appeal for this year, showEditControls will be false
            // when the component is loaded; reset to true now that we have an appeal to edit
            this.editState.showEditControls = true;

            if (!this.editState.editMode) {
                // We normally would be worried about isDirty here, but the preNavigationWarn call
                // on the button event should ensure that we can't get here from a dirty form
                this.editState.setEditMode(true);
            }
        } finally {
            this.isLoading = false;
        }
    }

    async switchTabs(i: number): Promise<void> {
        // For posterity; here's how you'd "cancel" selecting a tab:
        /*
            // Allow tab control to "breathe" before cancelling the navigate event and going
            // back to the previously selected tab
            setTimeout(() => {
                this.filingTabs.tabs.forEach((t, index) => {
                    t.active = index === this.viewModel.currentFilingIndex;
                });
                this.newAppeal();
            }, 1);
        */

        if (i !== this.viewModel.currentFilingIndex) {
            try {
                await this._annualDetailsService.preNavigateWarn(this.editState);
            } catch (e) {
                this.filingTabs.tabs.forEach((t, index) => {
                    t.active = index === this.viewModel.currentFilingIndex;
                });

                return;
            }

            this.isLoading = true;
            try {
                await this._complianceService.setFilingIndex(this.viewModel, i);
            } finally {
                this.isLoading = false;
            }
        }
    }

    onDueDateValidationChange(validationMessage): void {
        this.viewModel.dueDateValid = !validationMessage;
    }

    setDirty(): void {
        this.editState.setDirty(true);
        this.editState.refreshGrid = true;
    }

    onTaskRelevantFieldChanged(): void {
        this.editState.setDirty(true);
        this.editState.refreshActivity = true;
    }

    dueDateOverridden(filing): boolean {
        if (!filing.dueDate || !filing.filingDeadline) {
            return false;
        }

        return new Date(filing.dueDate).getTime() !== new Date(filing.filingDeadline).getTime();
    }

    revertDateCheck(filing): void {
        if (!filing.dueDate) {
            filing.dueDate = filing.filingDeadline;
        }
    }

    resetDueDate(filing): void {
        filing.dueDate = filing.filingDeadline;
        this.onTaskRelevantFieldChanged();
        this.setDirty();
    }

    async deleteFiling(filing: Filing): Promise<void> {
        try {
            await this._messageBoxService.confirm(`Are you sure you want to delete ${this.getFilingHeading(filing)}?`);
        } catch (e) {
            return Promise.resolve();
        }

        this.isLoading = true;

        try {
            await this._complianceService.deleteFiling(filing.filingId);

            _.remove(this.viewModel.model, filing);

            this.editState.refreshGrid = true;

            // stay in edit mode after deleting a filing
            this.editState.setDirty(false);
            this.editState.refreshActivity = false;

            this._navigationEvent.refreshActivityData();

            if (this.viewModel.model.length < 1) {
                this.editState.showEditControls = false;
                this.editState.setEditMode(false);
            } else {
                this.viewModel.resetEdit();
                this._complianceService.setFilingIndex(this.viewModel, 0, true);
            }
        } finally {
            this.isLoading = false;
        }
    }

    goToFiling(filing: Filing): void {
        this._upgradeNavigationServiceHandler.go('filingRedirect',
            {
                'entityID': filing.filingId
            });
    }

    goToFilingBatchDetails(filing: Filing): void {
        this._upgradeNavigationServiceHandler.go('filingBatchDetails',
            {
                'companyId': filing.filingBatch.companyId,
                'filingBatchId': filing.filingBatch.filingBatchId
            });
    }

    goToReturnFilingBatch(filing: Filing): void {
        this._productAnalyticsService.logEvent('access-filing-batch', {
            returnAccessPath: 'parcel prepare'
        });
        this._upgradeNavigationServiceHandler.go('returnFilingBatchPage',
            {
                'filingBatchId': filing.filingBatch.filingBatchId
            });
    }

    private async _afterTaskModalClose(shouldReload): Promise<void> {
        if (!shouldReload) {
            return;
        }

        this._navigationEvent.refreshActivityData();

        this.isLoading = true;
        try {
            await this._complianceService.loadTaskSummary(this.viewModel);
        } finally {
            this.isLoading = false;
        }

        this.editState.refreshGrid = true;
    }
}
