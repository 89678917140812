<div class="card panel-flat-color secondary-panel" *ngIf="enableAllocations">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()" [ngClass]="{'pointer': !isMaximized}">
        <panel-chevron [showBody]="showBody" *ngIf="!isMaximized"></panel-chevron>
        <h3>Responsible Entities</h3>
        <div class="float-end header-button-container">
            <button type="button"
                    class="ace-btn has-icon btn-text btn-secondary"
                    style="margin-right: -1px;" *ngIf="showBody && !isMaximized"
                    (click)="maximize($event)">
                <i class="fa fa-arrows-alt"></i>
            </button>
            <button type="button" class="ace-btn has-icon btn-text btn-secondary"
                    style="margin-right: -1px;" *ngIf="showBody && isMaximized"
                    (click)="unmaximize($event)">
                <i class="fa fa-compress"></i>
            </button>
        </div>
    </div>
    <div class="card-body" *ngIf="showBody" style="padding-top: 0;height: 490px;">
        <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div [hidden]="loading" class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
            <responsible-entity-list [companyId]="companyId" [siteId]="siteId" [parcelId]="parcelId" (loading)="onLoadingChanged($event)"></responsible-entity-list>
        </div>
    </div>
</div>
