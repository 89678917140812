<div class="d-flex flex-column ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Filing Batches"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Filing Batches
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button type="button"
                            class="ace-btn btn-sm btn-primary"
                            (click)="addNew()"
                            helpTooltip
                            helpContentId="filing-batch-list.new-filing-batch"
                            position="bottom">
                        New Filing Batch
                    </button>
                </div>
                <div class="ws-section__header__actions__item">
                    <div helpTooltip
                         [helpContentId]="reportExportHelp"
                         position="bottom">
                        <button type="button"
                                class="ace-btn btn-sm btn-primary has-icon"
                                [disabled]="!rowsSelected"
                                (click)="exportReconciliationReport()">
                            <i class="fa fa-file-text-o"></i>
                        </button>
                    </div>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="ace-btn btn-sm btn-secondary has-icon"
                            (click)="refresh()"
                            [disabled]="refreshing"
                            helpTooltip
                            helpContentId="app.refresh"
                            position="bottom">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column ws-flex-auto">
        <div class="d-flex filter-row">
            <div class="d-flex filter-group align-items-center">
                <label class="ws-flex-none small ws-font-weight-normal"
                       helpTooltip
                       helpContentId="filing-batch-list.completed"
                       position="bottom">
                    <input type="checkbox" [(ngModel)]="includeCompleted" (change)="onIncludeCompletedChange()"> Include Completed
                </label>
            </div>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [companyId]="companyId"
                            [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>
