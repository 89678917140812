import { Component, OnDestroy } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UntypedFormControl } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { takeUntil, debounce, distinctUntilChanged } from 'rxjs/operators';
import { CellEditorValidationResult } from './validator.interface';

export interface NumberCellEditorParams extends ICellEditorParams {
    cellChanged?: (initialRowValue: any, changedNumber: number) => Promise<void>;
    cellFocusLost?: (initialRowValue: any, changedNumber: number) => Promise<void>;
    validator?: (value: number) => CellEditorValidationResult;
    allowNull?: boolean;
}

@Component({
    selector: 'editor-cell',
    template: `
        <input type="number"
               [formControl]="numberValue"
               [class.invalid]="!isValid"
               helpTooltip
               [tooltipText]="validationMessage"
               [helpDisabled]="isValid"/>
    `,
    styles: [
        'input.invalid { background-color: var(--ace-danger-ghosted); }'
    ]
})
export class AgGridNumberCellEditorComponent implements ICellEditorAngularComp, OnDestroy {

    initialValue: string;
    isValid: boolean = true;
    validationMessage: string;
    numberValue: UntypedFormControl = new UntypedFormControl();
    allowNull: boolean = false;

    private _params: NumberCellEditorParams;
    private destroy$: Subject<void> = new Subject();

    agInit(params: NumberCellEditorParams): void {
        this._params = params;
        this.initialValue = params.value;
        this.allowNull = params.allowNull;
        this.numberValue.setValue(this.initialValue);

        this.numberValue.valueChanges
            .pipe(takeUntil(this.destroy$))
            .pipe(debounce(() => timer(400)))
            .pipe(distinctUntilChanged())
            .subscribe(async (value: string) => {
                const isValid = /(^$)|^[-+]?\d+(\.)?(\d+)?$/g.test(value);
                if (!isValid && (!this.allowNull || [null, undefined].indexOf(value) === -1)) {
                    this.numberValue.setValue('0');
                    return;
                }

                const numberEntered = (value || +value === 0) ? +value : null;
                if (this._params.validator) {
                    const validation = this._params.validator(numberEntered);
                    this.isValid = validation.isValid;
                    this.validationMessage = validation.validationMessage;
                }
                if (this._params.cellChanged) {
                    await this._params.cellChanged(this._params, numberEntered);
                }
            });
    }

    async ngOnDestroy(): Promise<void> {
        if (!this.isValid) {
            this.numberValue.setValue(this.initialValue);
        }
        const value: string = this.numberValue.value;
        if (this._params.cellFocusLost && (/(^$)|^[-+]?\d+(\.)?(\d+)?$/g.test(value) || this.allowNull && [null, undefined].indexOf(value) !== -1)) {
            const number = (value || value === '0') ? +value : null;
            await this._params.cellFocusLost(this._params, number);
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    getValue(): boolean {
        return this.numberValue.value;
    }

    isPopup(): boolean {
        return false;
    }
}
