import { ChangeDetectionStrategy, Component, HostBinding, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';

export interface AgGridLinkCellRendererParams extends ICellRendererParams {
    getLink?: (params: AgGridLinkCellRendererParams) => string;
    getLinkAsync?: (params: AgGridLinkCellRendererParams) => Promise<{ stateName: string, params: any }>;
    getButtonLink?: (params: AgGridLinkCellRendererParams) => string;
    onClick?: (params: AgGridLinkCellRendererParams) => void;
    onClickAsync?: (params: AgGridLinkCellRendererParams) => Promise<void>;
    getText?: (params: AgGridLinkCellRendererParams) => string;
    isDisabled?: (params: AgGridLinkCellRendererParams) => boolean;
    getHelpContentId?: (params: AgGridLinkCellRendererParams) => string;
    newWindow?: boolean;
    isHelpDisabled?: boolean;
    getStyle?: (params: AgGridLinkCellRendererParams) => object;
}

@Component({
    selector: 'ag-grid-link-cell',
    template: `
        <a *ngIf="showLink && textContainsHTML"
           class="grid-link-cell"
           [innerHTML]="text"
           [ngClass]="{ 'grid-cell-readonly': isDisabled }"
           [href]="link"
           [attr.target]="params.newWindow ? '_blank' : null"
           rel="noopener noreferrer"
           helpTooltip
           [helpDisabled]="params.isHelpDisabled"
           [helpContentId]="params.getHelpContentId && params.getHelpContentId(params)"
           position="bottom">
        </a>
        <a *ngIf="showLink && !textContainsHTML"
            class="grid-link-cell"
            [ngClass]="{ 'grid-cell-readonly': isDisabled }"
            [href]="link"
            [attr.target]="params.newWindow ? '_blank' : null"
            rel="noopener noreferrer"
            helpTooltip
            [helpDisabled]="params.isHelpDisabled"
            [helpContentId]="params.getHelpContentId && params.getHelpContentId(params)"
            position="bottom">
            {{text}}
        </a>
        <span *ngIf="showAsyncLink && textContainsHTML"
              class="anchor-style"
              [innerHTML]="text"
              (click)="onAsyncClick()">
        </span>
        <span *ngIf="showAsyncLink && !textContainsHTML"
              class="anchor-style"
              (click)="onAsyncClick()">
            {{text}}
        </span>
        <span *ngIf="showButtonLink"
              class="anchor-style"
              (click)="onClick()">
            {{params.getButtonLink(params)}}
        </span>
        <span *ngIf="((!showLink && !showAsyncLink && !showButtonLink) || isDisabled) && textContainsHTML"
              class="grid-link-cell"
              [ngClass]="{ 'grid-cell-readonly': isDisabled, 'anchor-style': !isDisabled }"
              [innerHTML]="text"
              (click)="onClick()"
              helpTooltip
              [helpDisabled]="params.isHelpDisabled"
              [helpContentId]="params.getHelpContentId && params.getHelpContentId(params)"
              position="bottom">
        </span>
        <span *ngIf="((!showLink && !showAsyncLink && !showButtonLink) || isDisabled) && !textContainsHTML"
              class="grid-link-cell"
              [ngClass]="{ 'grid-cell-readonly': isDisabled, 'anchor-style': !isDisabled }"
              (click)="onClick()"
              helpTooltip
              [helpDisabled]="params.isHelpDisabled"
              [helpContentId]="params.getHelpContentId && params.getHelpContentId(params)"
              position="bottom">
            {{text}}
        </span>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgGridLinkCellRendererComponent implements ICellRendererAngularComp {
    constructor(
        private sanitizer: DomSanitizer,
        private _navigationService: UpgradeNavigationServiceHandler
    ) {}

    @HostBinding('style') hostStyle;
    params: AgGridLinkCellRendererParams;

    textContainsHTML: boolean;
    private _link: string;

    get link(): string {
        return this._link || (this.params.getLink && this.params.getLink(this.params));
    }

    get text(): SafeHtml {
        let text: string;
        if (this.params.getText) {
            text = this.params.getText(this.params);
        } else if (this.params.valueFormatted) {
            text = this.params.valueFormatted;
        } else {
            text = this.params.value;
        }

        if (this.textContainsHTML) {
            return this.sanitizer.sanitize(SecurityContext.HTML, text);
        }
        return text || '';
    }

    get showLink(): boolean {
        if (this.isDisabled) {
            return false;
        }

        return this.params.getLink && !!this.link;
    }

    get showAsyncLink(): boolean {
        if (this.isDisabled) {
            return false;
        }

        return !!this.params.getLinkAsync;
    }

    get showButtonLink() {
        if (this.isDisabled) {
            return false;
        }

        return !!this.params.getButtonLink;
    }

    get isDisabled(): boolean {
        return this.params.isDisabled && this.params.isDisabled(this.params);
    }

    agInit(params: AgGridLinkCellRendererParams): void {
        this.params = params;
        this._link = this.params.getLink && this.params.getLink(this.params);

        this.hostStyle = this.params && this.params.getStyle && this.params.getStyle(params);
        this._testTextContainsHTML(this.text as string);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onClick(): void {
        if (this.isDisabled) {
            return;
        }

        if(this.params.onClick) {
            this.params.onClick(this.params);
        } else if(this.params.onClickAsync) {
            this.params.onClickAsync(this.params);
        }
    }

    async onAsyncClick(): Promise<void> {
        const nav = await this.params.getLinkAsync(this.params);
        if(!nav) {
            return;
        }

        this._navigationService.go(nav.stateName, nav.params);
        this.onClick();
    }

    private _testTextContainsHTML(text: string): void {
        // use regex to determine if text has angle brackets, if so, sanitize it
        const regex = /<[^>]*>/;
        this.textContainsHTML = regex.test(text);
    }

}
