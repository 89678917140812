<div class="modal fade" bsModal #FinalizePaymentPackageModal="bs-modal" [config]="{backdrop: 'static'}"
    tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Finalize Payment Package</h4>
            </div>
            <div>
                <div class="modal-body" style="text-align: center;">
                    <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
                        <i class="fa fa-spinner fa-pulse fa-5x"></i>
                    </div>
                    <div *ngIf="!loading">
                        <label style="text-align: center;" *ngIf="!tooManyOutputsError">
                            Confirm output type:
                            <select name="outputType" [(ngModel)]="selectedOutputType" (ngModelChange)="selectedOutputChanged($event)" >
                                <option *ngFor="let option of outputTypeOptions" [value]="option.outputType">
                                    {{ option.label }}
                                </option>
                            </select>
                        </label>
                        <div *ngIf="tooManyOutputsError">
                            <h3 style="text-align: center">
                                Too many output types selected
                            </h3>
                            <p style="text-align: center; margin-top: 5px;">
                                Please select payment packages with only one output type
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="ace-btn btn-primary" [disabled]="loading || tooManyOutputsError" (click)="save()">{{addButtonLabel}}</button>
                    <button class="ace-btn btn-secondary" [disabled]="loading" (click)="close()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>
