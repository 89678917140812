import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { AgGridExtensionsModule } from '../AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer } from '../AgGrid/MultiSelectTracker';
import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { AllocationDetailsComponent } from './Allocation-Details/allocationDetails.component';
import { AllocationListComponent } from './Allocation-List/allocationList.component';
import { AllocationListGridActionCellRendererComponent } from './Allocation-List/agGridActionCellRenderer.component';
import { AllocationRepository } from '../Repositories';
import { AllocationService } from './allocation.service';
import { AddAllocationModalComponent } from './Allocation-List/Add-Allocation/addAllocationModal.component';
import { AllocationDetailRepository } from '../Repositories/allocationDetail.repository';
import { ComplianceParcelModule } from '../Parcel/parcel.module';
import { AllocationDetailDragAndDropSourceComponent } from './Allocation-Details/Drag-And-Drop/allocationDetailDragAndDropSource.component';
import { AllocationDetailDragAndDropTargetComponent } from './Allocation-Details/Drag-And-Drop/allocationDetailDragAndDropTarget.component';
import { DragulaModule } from 'ng2-dragula';
import { AllocationDetailDragAndDropService } from './Allocation-Details/Drag-And-Drop/allocationDetailDragAndDrop.service';
import { AllocationDetailsService } from './Allocation-Details/allocationDetails.service';
import { AddTaxAuthorityComponent } from './Allocation-Details/Add-Tax-Authority/addTaxAuthority.component';
import { AllocationDetailBulkUpdateActionComponent } from './Allocation-Details/Allocation-Detail-Bulk-Update/allocationDetailBulkUpdateAction.component';
import { AllocationDetailBulkUpdateComponent } from './Allocation-Details/Allocation-Detail-Bulk-Update/allocationDetailBulkUpdate.component';
import { AllocationAssessmentSummaryComponentComponent } from './Allocation-Details/Allocation-Assessment-Summary/allocationAssessmentSummaryComponent.component';
import { CreateNewMergedParcelsComponent } from './Allocation-Details/Create-New-Merged-Parcels/create-new-merged-parcels.component';
import { AllocationDetailListGridActionCellRendererComponent } from './Allocation-Details/agGridActionCellRenderer.component';
import { AllocationDetailDragAndDropCellRendererComponent } from './Allocation-Details/Drag-And-Drop/allocationDetailDragAndDropCellRenderer.component';
import { AgGridAssessmentSummaryActionCellRendererComponent } from './Allocation-Details/agGridAssessmentSummaryActionCellRenderer.component';
import { AgGridAssessmentSummaryActionHeaderRendererComponent } from './Allocation-Details/agGridAssessmentSummaryActionHeaderRenderer.component';
import { CommentsModule } from '../../Comments/comments.module';
import { RetrieveAssetsComponent } from './Allocation-Details/Retrieve-Assets/retrieveAssets.component';
import {
    AssetsInAnotherAllocationsComponent
} from './Allocation-Details/Assets-In-Another-Allocations/assetsInAnotherAllocations.component';
import { SelectTaxAuthorityComponent } from './Allocation-Details/Select-Tax-Authority/selectTaxAuthority.component';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        TypeaheadModule,
        TooltipModule,
        BsDropdownModule,
        WeissmanSharedModule,
        SharedPipesModule,
        CurrencyMaskModule,
        ComplianceParcelModule,
        BusyIndicatorModule.forRoot(),
        DragulaModule,
        ScrollingModule,
        AgGridExtensionsModule,
        CommentsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            AgGridMultiSelectedHeaderRenderer,
            AgGridMultiSelectedCellRenderer
        ])
    ],
    declarations: [
        AllocationDetailsComponent,
        AllocationListComponent,
        AllocationListGridActionCellRendererComponent,
        AgGridAssessmentSummaryActionCellRendererComponent,
        AgGridAssessmentSummaryActionHeaderRendererComponent,
        AddAllocationModalComponent,
        AllocationDetailDragAndDropSourceComponent,
        AllocationDetailDragAndDropTargetComponent,
        AllocationDetailDragAndDropCellRendererComponent,
        AddTaxAuthorityComponent,
        AllocationDetailBulkUpdateActionComponent,
        AllocationDetailBulkUpdateComponent,
        AllocationAssessmentSummaryComponentComponent,
        CreateNewMergedParcelsComponent,
        AllocationDetailListGridActionCellRendererComponent,
        RetrieveAssetsComponent,
        AssetsInAnotherAllocationsComponent,
        SelectTaxAuthorityComponent
    ],
    providers: [
        AllocationService,
        AllocationRepository,
        AllocationDetailRepository,
        AllocationDetailDragAndDropService,
        AllocationDetailsService
    ]
})

export class AllocationModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('allocationDetails', AllocationDetailsComponent);
        hybridAdapterUtility.downgradeNg2Component('allocationList', AllocationListComponent);
    }
}
