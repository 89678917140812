import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { AgGridColumnModel, AgGridLayoutSpecificationModel } from '../models';
import { GridOptions, Column } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { AG_GRID_HELP } from '../../agGrid.help';
import { AgGridPersistenceService } from '../../agGridPersistence.service';

import { filter, forEach } from 'lodash/fp';
import * as _ from 'lodash';

export enum SortDirection {
    None,
    Ascending,
    Descending
}

interface ColumnNode {
    groupName: string;
    columns: AgGridColumnModel[];
    isVisible: boolean;
    groups?: ColumnNode[];
    headerColor?: string;
    isCollapsed?: boolean;
}

@Component({
    selector: 'ag-grid-tool-panel-columns',
    templateUrl: './agGridToolPanelColumns.component.html',
    styleUrls: ['./agGridToolPanelColumns.component.scss']
})
export class AgGridToolPanelColumnsComponent implements OnInit, OnDestroy {
    constructor(private readonly _fb: UntypedFormBuilder,
                private readonly _helpService: HelpService,
                private readonly _agGridPersistenceService: AgGridPersistenceService
    ) { }

    @Input() gridId: string | System.Guid;
    @Input() displayedColumnsChanged$: Subject<void>;
    @Input() defaultColorizeHeaders: boolean;

    @Input()
    set gridOptions(gridOptions: GridOptions) {
        this._gridOptions = gridOptions;
        this._refreshColumns();
    }

    @Input()
    set specification(specification: AgGridLayoutSpecificationModel) {
        if (specification) {
            this._specification = specification;
            this._refreshColumns(true);
        }
    }

    columnSettings: UntypedFormGroup;

    columns: ColumnNode;
    sortDirection = SortDirection;
    selectedSort: SortDirection = SortDirection.None;
    showSettings: boolean;
    showGrouped: boolean;
    hasFilterApplied: boolean;
    hasGroupings: boolean;
    hasColumnChanges: boolean;
    canUserPin: boolean;

    private _gridOptions: GridOptions  & { api?: {appliedFilter?: any}};
    private _specification: AgGridLayoutSpecificationModel;
    private _columns: AgGridColumnModel[] = [];
    private _initialColumns: AgGridColumnModel[] = [];
    private _filterValue: string = '';
    private _columnsInitialized: boolean;
    private _destroy$: Subject<void> = new Subject;

    ngOnInit(): void {
        this._helpService.setContent(AG_GRID_HELP);

        this.columnSettings = this._fb.group({
            groupBy: [false],
            showHeaders: [this.defaultColorizeHeaders],
            filter: ['']
        });

        // Disable colors for tables that have group headers
        const colGroupNames = this._gridOptions.columnApi.getColumnGroupState().map(x => x.groupId);
        const colGroups = colGroupNames.map(x => this._gridOptions.columnApi.getColumnGroup(x)).filter(x => x);
        if (colGroups.length) {
            this.columnSettings.get('showHeaders').disable();
        }

        this.columnSettings.get('groupBy').valueChanges
            .pipe(takeUntil(this._destroy$))
            .subscribe((grouped: boolean) => {
                this.showGrouped = grouped;
                this.hasFilterApplied = !!this._filterValue || this.selectedSort !== SortDirection.None || this.showGrouped;
            });

        this.columnSettings.get('filter').valueChanges
            .pipe(takeUntil(this._destroy$), debounceTime(200), distinctUntilChanged())
            .subscribe(x => {
                this._filterValue = x;
                this.columns = this._applySettings();
            });

        this.columnSettings.get('showHeaders').valueChanges
            .pipe(takeUntil(this._destroy$), distinctUntilChanged())
            .subscribe(show => {
                this._showGroupedHeaders(show);
            });

        this.displayedColumnsChanged$
            .pipe(takeUntil(this._destroy$), debounceTime(200))
            .subscribe(x => this._refreshColumns());

        // Initialize the colors when columns are first set
        this.displayedColumnsChanged$
            .pipe(takeUntil(this._destroy$), take(1))
            .subscribe(x => this._showGroupedHeaders(this.columnSettings.get('showHeaders').value));

        this._applySettings();
        this._showGroupedHeaders(this.defaultColorizeHeaders);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    toggleIsVisible(isVisible: boolean, column: AgGridColumnModel): void {
        if (!column.canChangeIsVisible) { return; }

        column.isVisible = isVisible;
        this._gridOptions.columnApi.setColumnVisible(column.id, column.isVisible);
        this.hasColumnChanges = true;

        if (!column.isVisible) {
            const filterInstance = this._gridOptions.api.getFilterInstance(column.id);

            if (filterInstance && filterInstance.isFilterActive()) {
                filterInstance.setModel(null);
                delete this._gridOptions.api.appliedFilter[column.id];
                this._gridOptions.api.onFilterChanged();
            }
        }
    }

    setColumnPinned(isPinned: boolean, column: AgGridColumnModel): void {
        if (!column.canChangeIsPinned) { return; }

        column.isPinned = isPinned;
        const pinned = (column.isPinned) ? 'left' : '';

        this._gridOptions.columnApi.setColumnPinned(column.id, pinned);
        this.hasColumnChanges = true;
    }

    trackByColumnId(column) {
        return column.id;
    }

    trackByGroupName(column) {
        return column.groupName;
    }

    toggleSort(currentSort: SortDirection): void {
        switch (currentSort) {
            case SortDirection.None:
                this.selectedSort = SortDirection.Ascending;
                break;
            case SortDirection.Ascending:
                this.selectedSort = SortDirection.Descending;
                break;
            case SortDirection.Descending:
                this.selectedSort = SortDirection.None;
                break;
        }
        this.columns = this._applySettings();
    }

    toggleSettings(): void {
        this.showSettings = !this.showSettings;
    }

    toggleGroup(isVisible: boolean, group: ColumnNode): void {
        const columnIds = group.columns.reduce((acc, x) => {
            if (x.canChangeIsVisible) {
                acc.push(x.id);
            }
            return acc;
        }, []);

        this._gridOptions.columnApi.setColumnsVisible(columnIds, isVisible);
        group.isVisible = isVisible;
        this.hasColumnChanges = true;

        // If columns with filters are being hidden, reset their filter
        if (!isVisible) {
            let filterChanged = false;
            group.columns.forEach(x => {
                const filterInstance = this._gridOptions.api.getFilterInstance(x.id);

                if (filterInstance && filterInstance.isFilterActive()) {
                    filterInstance.setModel(null);
                    if (this._gridOptions.api.appliedFilter[x.id]) {
                        delete this._gridOptions.api.appliedFilter[x.id];
                    }
                    filterChanged = true;
                }
            });
            if (filterChanged) {
                this._gridOptions.api.onFilterChanged();
            }
        }
    }

    toggleGroupLevelCollapse(group: ColumnNode): void {
        group.isCollapsed = !group.isCollapsed;
    }

    undoChanges(): void {
        this._columns = this._initialColumns.map(x => ({ ...x }));
        this._columns.forEach(x => {
            this.toggleIsVisible(x.isVisible, x);
            this.setColumnPinned(x.isPinned, x);
        });
        this.columns = this._applySettings();
        this.hasColumnChanges = false;
    }

    private _applySettings(): ColumnNode {
        this.hasFilterApplied = !!this._filterValue || this.selectedSort !== SortDirection.None || this.showGrouped;
        if (this.columnSettings) {
            if (this.hasGroupings) {
                this.columnSettings.get('groupBy').enable();
                this.columnSettings.get('showHeaders').enable();
            } else {
                this.columnSettings.get('groupBy').disable();
                this.columnSettings.get('showHeaders').disable();
            }
        }

        const filtered = this._filter([...this._columns]);
        const sorted = this._sort(filtered);
        const columns = {
            groupName: null,
            columns: sorted,
            groups: [],
            isVisible: this._groupColumnsState(sorted)
        };
        return (this.hasGroupings) ? this._group(columns) : columns;
    }

    private _refreshColumns(fromSpecificationChange: boolean = false): void {
        if (!(this._gridOptions && this._gridOptions.columnApi && this._gridOptions.columnApi.getAllColumns())) {
            return;
        }

        this._columns = [];
        if (!this._columnsInitialized || fromSpecificationChange) {
            this._initialColumns = [];
        }
        this.hasGroupings = false;
        this.canUserPin = false;

        const gridColumns = this._gridOptions.columnApi.getAllColumns();

        // get column state (so that we can process them in order)
        const columnState = this._gridOptions.columnApi.getColumnState();

        _.flow([
            filter(x => {
                const gridColumn: Column = gridColumns.find(y => y.getColId() === x.colId);

                // column must not be suppressed and have a header
                return (gridColumn && !gridColumn.getColDef().suppressToolPanel) && (!gridColumn.getColDef().suppressColumnsToolPanel) && gridColumn.getColDef().headerName;
            }),
            forEach(x => {
                const gridColumn: Column = gridColumns.find(y => y.getColId() === x.colId);
                const colDef = gridColumn.getColDef();
                const column: () => AgGridColumnModel = () => {
                    const isVisible = gridColumn.isVisible();
                    if (!this.canUserPin && !colDef.lockPinned) {
                        this.canUserPin = true;
                    }
                    return {
                        id: x.colId,
                        name: colDef.headerName,
                        isVisible,
                        canChangeIsVisible: !colDef.lockVisible,
                        isPinned: !!x.pinned,
                        pinned: x.pinned,
                        canChangeIsPinned: !colDef.lockPinned,
                        groupName: <string>colDef.toolPanelClass
                    };
                };

                if (colDef.lockVisible) {
                    this._columns.unshift(column());
                } else {
                    this._columns.push(column());
                }

                if (!this._columnsInitialized || fromSpecificationChange) {
                    this._initialColumns.push(column());
                }

                if (colDef.toolPanelClass && !this.hasGroupings) {
                    this.hasGroupings = true;
                }
            })
        ])(columnState);

        this.columns = this._applySettings();

        if (!this._columnsInitialized || fromSpecificationChange) {
            this.hasColumnChanges = false;
        }
        this._columnsInitialized = !!this._initialColumns.length;

        if (fromSpecificationChange && this._specification) {
            this.columnSettings.patchValue({ showHeaders: this._specification.colorize });
        }
    }

    private _group(node: ColumnNode): ColumnNode {
        const grouped = node.columns.reduce((acc, x) => {
            if (!x.groupName) { x.groupName = 'General'; }
            if (!acc[x.groupName]) {
                acc[x.groupName] = [];
            }
            acc[x.groupName].push(x);
            return acc;
        }, {});
        node.groups = Object.keys(grouped)
                            .sort()
                            .map(x => {
                                return {
                                    groupName: x,
                                    columns: grouped[x],
                                    headerColor: this._agGridPersistenceService.getColorClassForGroupType(x),
                                    isCollapsed: false,
                                    isVisible: this._groupColumnsState(grouped[x])
                                };
                            });
        return node;
    }

    private _filter(columns: AgGridColumnModel[]): AgGridColumnModel[] {
        return (this._filterValue) ? columns.filter(x => x.name.toLowerCase().includes(this._filterValue.toLowerCase())) : columns;
    }

    private _sort(columns: AgGridColumnModel[]): AgGridColumnModel[] {
        switch (this.selectedSort) {
            case SortDirection.Ascending:
                columns.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case SortDirection.Descending:
                columns.sort((a, b) => b.name.localeCompare(a.name));
                break;
        }
        return columns;
    }

    private _groupColumnsState(columns: AgGridColumnModel[]): boolean {
        if (columns.every(x => x.isVisible)) {
            return true;
        } else if (columns.some(x => x.isVisible)) {
            return null;
        } else {
            return false;
        }
    }

    private _showGroupedHeaders(show: boolean): void {
        const columnState = this._gridOptions.columnApi.getColumnState();
        const colDefs = columnState.map(x => this._gridOptions.columnApi.getColumn(x.colId).getColDef());
        colDefs.forEach(x => x.headerClass = (show) ? this._agGridPersistenceService.getColorClassForGroupType(x.toolPanelClass as string) : '');
        this._gridOptions.columnApi.setColumnState(this._gridOptions.columnApi.getColumnState());
        this._agGridPersistenceService.toggleHeaderGroupColor(this.gridId as string, show);
    }
}
