import { Injectable } from '@angular/core';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { TaxAuthorityPayload, TaxRateAreaPayload, PanelProjectionsPayload } from './tax.rate.model';

@Injectable(
    { providedIn: 'root'}
)
export class TaxRateService {

    constructor(private sdHttp: SDHttpService) {}

    getTaxRateArea(taxRateAreaId: number): Promise<TaxRateAreaPayload> {
        return this.sdHttp.get(`/api/TaxRateArea/Panel/${taxRateAreaId}`);
    }

    updateTaxRateArea(taxRateAreaPayload: TaxRateAreaPayload): Promise<TaxRateAreaPayload> {
        return this.sdHttp.put('/api/TaxRateArea/Panel/', taxRateAreaPayload);
    }

    createTaxRateArea(taxRateArea: TaxRateAreaPayload): Promise<TaxRateAreaPayload> {
        return this.sdHttp.post('/api/TaxRateArea/Panel/', taxRateArea);
    }

    deleteTaxRateArea(taxRateAreaId: number): Promise<number> {
        return this.sdHttp.delete(`/api/TaxRateArea/Panel/${taxRateAreaId}`);
    }

    getTaxAuthority(taxAuthorityId: number): Promise<TaxAuthorityPayload> {
        return this.sdHttp.get(`/api/TaxAuthority/${taxAuthorityId}`);
    }

    updateTaxAuthority(taxAuthorityPayload: TaxAuthorityPayload): Promise<TaxAuthorityPayload> {
        return this.sdHttp.put('/api/TaxAuthority/', taxAuthorityPayload);
    }

    createTaxAuthority(TaxAuthority: TaxAuthorityPayload): Promise<TaxAuthorityPayload> {
        return this.sdHttp.post('/api/TaxAuthority/', TaxAuthority);
    }

    deleteTaxAuthority(taxAuthorityId: number): Promise<number> {
        return this.sdHttp.delete(`/api/TaxAuthority/${taxAuthorityId}`);
    }

    getPanelProjections(panelProjectionsPayload: PanelProjectionsPayload): Promise<PanelProjectionsPayload> {
        return this.sdHttp.post('/api/TaxRateArea/PanelProjections', panelProjectionsPayload);
    }

    cloneTaxRateArea(taxRateAreaId: number): Promise<TaxRateAreaPayload> {
        return this.sdHttp.post(`/api/TaxRateArea/Duplicate/${taxRateAreaId}`);
    }

}
