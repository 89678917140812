import { Component, Input, forwardRef, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TimerService } from '../Utilities/timer.service';

import * as _ from 'lodash';

/**
 * Picklist
 *
 * Allows the user to select multiple items from the dropdown
 * The values selected can be reorganized after selection
 *
 * Tied into ControlValueAccessor for integration with native Angular FormControls and ngModel
 *
 * The input options can be any model. The properties to use as the selectors for each option in the dropdown are set using
 * labelProperty and valueProperty.
 *
 * Example usage:
 * <ws-picklist labelProperty="displayName"
 *               valueProperty="property"
 *               placeholder="Please select a value..."
 *               [options]="options"
 *               [formControl]="picklistFormControl">
 * </ws-picklist>
 *
 */
@Component({
    selector: 'ws-picklist',
    templateUrl: './picklist.component.html',
    styleUrls: ['./picklist.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PicklistComponent),
            multi: true
        }
    ],
    animations: [
        trigger('picklistVisible', [
            state('show', style({
                height: '*',
                opacity: 1
            })),
            state('hide', style({
                height: '0',
                opacity: 0
            })),
            transition('show => hide', animate('200ms ease')),
            transition('hide => show', animate('200ms ease'))
        ])
    ]
})
export class PicklistComponent implements OnInit, OnDestroy, ControlValueAccessor {
    constructor(private readonly _timer: TimerService) {}

    @Input() labelProperty: string = 'label';
    @Input() valueProperty: string = 'value';
    @Input() placeholder: string;
    @Input() canReorganize: boolean = true;
    @Input() returnEntireOption: boolean = false;
    @Input() canRemove: boolean = true;
    @Input() mustHaveOne: boolean = false;

    @Input()
    set options(options: any[]) {
        this.availableOptions = [...options];
        this._allOptions = [...options];
    }

    @Output() picklistChanged: EventEmitter<any> = new EventEmitter();
    @Output() picklistItemAdded: EventEmitter<any> = new EventEmitter();
    @Output() picklistItemRemoved: EventEmitter<any> = new EventEmitter();

    value: any[] = [];
    disabled: boolean;
    animationComplete: boolean;
    availableOptions: any[];
    picklist: any[] = [];
    selectedOption: any;
    selectedOptionIndex: number;
    listSelection: UntypedFormControl = new UntypedFormControl(null);

    private _allOptions: any[] = [];
    private _destroy$: Subject<void> = new Subject();

    onChange: (val: any[]) => void = (val) => { return null; };
    onTouched: () => void = () => { return null; };

    // Angular Form methods
    writeValue(value: any[]) {
        this.value = value;
        if (this.value && this.value.length) {
            this.value.forEach(v => {
                const option = this.availableOptions.find(o => (this.returnEntireOption) ? _.isEqual(o, v) : o[this.valueProperty] === v);
                if (option) {
                    this.picklist.push(option);
                    const optionIndex = this.availableOptions.indexOf(option);
                    this.availableOptions.splice(optionIndex, 1);
                } else {
                    this.picklist.push(v);
                }
            });
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
        if (this.disabled) {
            this._disableDropdown();
        } else {
            this._enableDropdown();
        }
    }

    next(): void {
        this.onChange(this.value = this.picklist.map(p => (this.returnEntireOption) ? p : p[this.valueProperty]));
        this.picklistChanged.emit(this.picklist.map(p => (this.returnEntireOption) ? p : p[this.valueProperty]));
        this.onTouched();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    // Custom methods

    ngOnInit(): void {
        this.listSelection.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(option => {
            if (option) {
                this.addOption(option);
            }
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    addOption(option: any): void {
        if (this.picklist.indexOf(option) === -1) {
            this.picklist.push(option);
            const optionIndex = this.availableOptions.indexOf(option);
            this.availableOptions.splice(optionIndex, 1);
            this.listSelection.setValue(null);
            this.unsetSelected();
            this.next();
            this.picklistItemAdded.emit(option);

            if (this.picklist.length) {
                this._timer.setTimeout(() => this.animationComplete = true, 200);
            }

            if (!this.availableOptions.length) {
                this._disableDropdown();
            }
        }
    }

    removeOption(option?: any): void {
        const selected = (option) ? option : this.selectedOption;
        const index = this.picklist.indexOf(selected);
        if (index !== -1) {
            this.picklist.splice(index, 1);
            const optionIndex = this._allOptions.indexOf(selected);
            this.availableOptions.push(this._allOptions[optionIndex]);
            this.unsetSelected();
            this.next();
            this.picklistItemRemoved.emit(option);

            if (!this.picklist.length) {
                this.animationComplete = false;
            }

            if (this.availableOptions.length) {
                this._enableDropdown();
            }
        }
    }

    shiftOptionUp(): void {
        const index = this.picklist.indexOf(this.selectedOption);
        const newIndex = index - 1;
        if (index !== -1 && newIndex > -1) {
            this.picklist.splice(index, 1);
            this.picklist.splice(newIndex, 0, this.selectedOption);
            this.selectedOptionIndex = newIndex;
            this.next();
        }
    }

    shiftOptionDown(): void {
        const index = this.picklist.indexOf(this.selectedOption);
        const newIndex = index + 1;
        if (index !== -1 && newIndex !== this.picklist.length) {
            this.picklist.splice(index, 1);
            this.picklist.splice(newIndex, 0, this.selectedOption);
            this.selectedOptionIndex = newIndex;
            this.next();
        }
    }

    setSelected(option: any, index: number): void {
        this.selectedOption = option;
        this.selectedOptionIndex = index;
    }

    unsetSelected(): void {
        this.selectedOption = null;
        this.selectedOptionIndex = null;
    }

    private _disableDropdown(): void {
        this.listSelection.disable();
    }

    private _enableDropdown(): void {
        this.listSelection.enable();
    }
}
