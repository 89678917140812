<div style="display: flex; justify-content: space-between;">
    <div class="container">
        <div class="row upper-data">
            <div class="col col-6 company-info company-word-wrap">
                <img *ngIf="!companyIsActive"
                     [src]="inactiveUrl"
                     alt="Inactive"
                     class="status-img img-responsive float-end"
                     triggers="mouseenter:mouseleave"
                     [popover]="activatedByPopover"
                     placement="auto"
                     container="body">
                <h3 (mouseenter)="addressPopoverOpened = true"
                    (mouseleave)="addressPopoverOpened = false">
                        <span [popover]="companyAddressPopover"
                              triggers=""
                              [isOpen]="addressPopoverOpened"
                              placement="bottom left">
                                {{ company.companyName }}
                        </span>
                </h3>
                <h4 style="margin-top: 0;">{{subHeader}}</h4>
                <h5 [class.invisible]="!company.companyCode">
                    <span class="company-label">Company Code:</span> {{company.companyCode}}
                </h5>
            </div>
            <div class="col col-6">
                <div>
                    <h4>Additional Information:</h4>
                    <div *ngIf="company.completedPaymentPackageCount > 0">
                        <a anchor-route
                           [sref]="{ target: 'paymentPackagesForCompany', options: { companyID: company.companyID } }"
                           (click)="analyticsEvent('click-transmittal-link')">Payment Packages</a>
                    </div>
                    <div *ngIf="shouldDisplayPaymentBatches">
                        <a class="pointer" (click)="goToPBCC()">
                           Payment Batches</a>
                    </div>
                    <div *ngIf="hasInstancePrivateViewPermission">
                        <a anchor-route
                           [sref]="{ target: 'forecastingBudgeting', options: { companyId: company.companyID } }"
                           (click)="analyticsEvent('click-budgeting-link')">
                            Forecasting<span *ngIf="company.budgetsEnabled">/Budgeting</span>
                        </a>
                    </div>
                    <div *ngIf="accrualsEnabled && hasInstancePrivateViewPermission && company.accrualsEnabled">
                        <a anchor-route
                           [sref]="{ target: 'accruals', options: { companyId: company.companyID } }"
                           (click)="analyticsEvent('click-accrual-link')">Accruals</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col col-6">
                <div *ngIf="isTopLevel">
                    <h4>Subsidiary Companies:</h4>
                    <ul *ngIf="subsidiaries.length"
                        class="list-unstyled subsidiary-list">
                        <li *ngFor="let subsidiary of subsidiaries">
                            <a anchor-route
                               [sref]="{ target: 'company', options: { companyId: subsidiary.companyID } }"
                               (click)="analyticsEvent('click-subsidiary-link')">
                                {{ subsidiary.companyCode ? subsidiary.companyCode + ' - ' : '' }}
                                {{ subsidiary.companyName }}
                                ({{ subsidiary.siteCount }}&nbsp;sites)&nbsp;&nbsp;
                                <span *ngIf="subsidiary.activityStatusID === 0">[INACTIVE]</span>
                            </a>
                        </li>
                    </ul>
                    <p *ngIf="subsidiaries.length === 0">No subsidiaries.</p>
                    <div style="margin-left: 10px;">
                        <a class="pointer" *ngIf="numInactiveSubsidiaries > 0 && !showInactiveSubsidiaries"
                           (click)="showInactiveSubsidiariesClicked()">Show inactive subsidiaries
                            ({{ numInactiveSubsidiaries }})</a>
                    </div>
                </div>
                <div *ngIf="!isTopLevel && parentCompany">
                    <h4>Top Level (Parent) Company:</h4>
                    <a anchor-route [sref]="{ target: 'company', options: { companyId: parentCompany.companyID } }"
                       style="font-size: large;"
                       [class.disabled]="parentCompany.noPermission">
                        {{ parentCompany.companyCode ? parentCompany.companyCode + ' - ' : '' }}
                        {{ parentCompany.companyName }}
                    </a>
                </div>
            </div>
            <div class="col col-6">
                <div *ngIf="company.crmNumber || company.iamTenantId">
                    <h4>External Connections</h4>
                    <h5 *ngIf="hasInstancePrivateViewPermission && company.crmNumber">
                        <span class="field-header">CRM Number:</span> {{company.crmNumber}}
                    </h5>
                    <h5 [class.invisible]="!(hasInstancePrivateViewPermission && company.iamTenantId)" *ngIf="isIAMEnabled">
                        <span class="field-header">tax.com Tenant:</span> {{company.iamTenantName}}
                    </h5>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div *ngIf="isTopLevel && hasInstancePrivateViewPermission">
            <button class="ace-btn has-icon btn-lg btn-primary"
                    helpTooltip
                    helpContentId="company-info-panel.favorite"
                    position="left"
                    (click)="toggleFavorite()">
					<span *ngIf="!isFavoriting">
						<i class="fa fa-star-o" *ngIf="!company.isFavorite"></i>
						<i class="fa fa-star" *ngIf="company.isFavorite"></i>
					</span>
                <i *ngIf="isFavoriting" class="fa fa-spinner fa-pulse"></i>
            </button>
        </div>
        <div>
            <entity-attachments buttonClass="has-icon btn-lg"
                                helpTooltip
                                helpContentId="company-info-panel.attachments"
                                position="left"
                                [analyticsCategory]="analyticsCategory"
                                [isNestedModal]="false"
                                [(hasAttachments)]="company.hasAttachments"
                                [modalData]="attachmentsModel">
            </entity-attachments>
        </div>
        <div *ngIf="company.hasImages">
            <entity-attachments buttonClass="has-icon btn-lg"
                                helpTooltip
                                helpContentId="company-info-panel.images"
                                position="left"
                                [analyticsCategory]="analyticsCategory"
                                [isNestedModal]="false"
                                [forImages]="true" (hasImagesChange)="company.hasImages = $event"
                                [modalData]="attachmentsModel">
            </entity-attachments>
        </div>
        <div>
            <entity-comments [(hasComments)]="company.hasComments"
                             helpTooltip
                             helpContentId="company-info-panel.comments"
                             position="left"
                             buttonClass="has-icon btn-lg"
                             [analyticsCategory]="analyticsCategory"
                             [modalData]="commentsModel">
            </entity-comments>
        </div>
    </div>
</div>

<ng-template #companyAddressPopover>
    <div style="word-break: break-all;">
        <address-view-edit [address]="defaultAddress" [isEditMode]="false"></address-view-edit>
        <div><a href="{{company.address.url}}" target="_blank">{{company.address.url}}</a></div>
        <div style="margin-top: 10px;">
            <a class="pointer" (click)="launchAddressModal()">
                Address Details <span *ngIf="company.entityAddresses.length > 1">({{company.entityAddresses.length}}
                )</span>
            </a>
        </div>
    </div>
</ng-template>
