<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-flex-auto ws-truncate">
            <span>Company Permissions</span>
            <ag-grid-row-count [gridOptions]="gridOptions" [useWhiteText]="true"></ag-grid-row-count>
        </h3>
        <div class="ws-flex-none header-button-container">
                <button helpTooltip
                        helpContentId="user-group-detail.new-company-permission"
                        type="button"
                        class="ace-btn has-icon btn-text btn-primary"
                        (click)="addNewCompanyPermission()"
                        *ngIf="editMode">
                    <i class="fa fa-plus-circle fa-align"></i>
                </button>
                <ag-grid-filter-toggle [gridOptions]="gridOptions"></ag-grid-filter-toggle>
            </div>
    </div>
    <div class="panel-flat-body ws-flex-container-vertical">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [pinnedTopRowData]="addedCompanies">
        </ws-ag-grid-angular>
    </div>
</div>
