<ws-modal-wrapper headerLabel="Complete File Appeal Tasks"
                  acceptLabel="Process"
                  [disableSave]="loading"
                  [disableCancel]="loading"
                  (save)="process()"
                  (cancel)="cancel()">
    <loading-spinner [show]="loading" [size]="5"></loading-spinner>
    <div *ngIf="!loading" style="height: 70px;">
        <label>
            <span>Filing Reference #:</span>
            <input type="text" class="ms-2" [(ngModel)]="payload.filingReference" maxlength="50"/>
        </label>
    </div>
</ws-modal-wrapper>
