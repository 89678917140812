import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { CompanyModule } from '../Company/company.module';
import { AddInstanceModalComponent } from './Add/addInstanceModal.component';
import { InstanceInfoPanelComponent } from './Info/instanceInfoPanel.component';
import { InstanceRepository } from './instance.repository';
import { ManageInvitationsComponent } from './manageInvitations.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
    imports: [
        WeissmanSharedModule,
        CompanyModule,
        TypeaheadModule
    ],
    declarations: [
        AddInstanceModalComponent,
        ManageInvitationsComponent,
        InstanceInfoPanelComponent,
    ],
    providers: [
        InstanceRepository
    ]
})

export class InstanceModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('manageInvitations', ManageInvitationsComponent);
        hybridAdapterUtility.downgradeNg2Component('instanceInfoPanel', InstanceInfoPanelComponent);
    }
}
