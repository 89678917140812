<div style="display: flex; gap: 10px; align-items: center; margin-bottom: 10px;">
	<span style="font-weight: bold;">Prepare PP Returns:</span>
    <ws-switch *ngIf="entity.typeId === EntityTypeIds.COMPANY"
                size="small"
                [(ngModel)]="entity.ppReturnPreparationAllowed"
                [disabled]="!entity.isTopLevel || !editMode || !hasCompliancePermission || isConsultantPerspective"
                (selectedOptionChanged)="maybePopulateBatchUsers()">
    </ws-switch>
    <ws-switch *ngIf="entity.typeId !== EntityTypeIds.COMPANY"
                size="small"
                [(ngModel)]="currentData.ppReturnPreparationAllowed"
                [disabled]="!entity.isTopLevel || !editMode || !hasCompliancePermission || isConsultantPerspective"
                (selectedOptionChanged)="maybePopulateBatchUsers()">
    </ws-switch>
</div>
<div *ngIf="entity.typeId === EntityTypeIds.COMPANY ? entity.ppReturnPreparationAllowed : currentData.ppReturnPreparationAllowed">
	<div *ngFor="let userType of DR_USERS" style="display: flex; align-items: center;">
		<div style="min-width: 150px;">{{userType.name}}:</div>
        <div *ngIf="editMode && entity.isTopLevel && users" style="display: flex; align-items: center; gap: 5px;">
            <user-team-picker
                style="width: 300px;"
                [userId]="currentData[userType.prefix + 'UserId']"
                [teamId]="currentData[userType.prefix + 'TeamId']"
                [entityIdScope]="entity.id"
                [entityTypeScope]="entity.type"
                [instanceIdScope]="userSearchInstanceId"
                (userChange)="userChanged($event, userType.prefix)"
                [required]="userType.required"
                [parentUsers]="users"
                [disabled]="!editMode"
            ></user-team-picker>
            <button *ngIf="!userType.required"
                class="ace-btn has-icon btn-primary btn-danger"
                style="margin:0;"
                (click)="userChanged(undefined, userType.prefix)">
                    <i class="fa fa-trash"></i>
            </button>
        </div>
        <div *ngIf="currentData[userType.prefix + 'User'] && (!editMode || !entity.isTopLevel)">
            {{getDisplayName(currentData[userType.prefix + 'User'], currentData[userType.prefix + 'Team'])}}
        </div>
	</div>
</div>
