import { Injectable } from '@angular/core';
import { ReplaySubject, Subject, fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export class AuthData {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    login_date: string;
    set_no_relogin: boolean;
    weissman2_token: string;
    migration_exception: boolean;
}

export enum StorageTokenKeys {
    refreshTokenKey = 'weissman_refresh_token',
    lastActivityKey = 'weissman_last_activity',
    selectedInstances = 'weissman_selected_instances',
    pauseOnIAM = 'weissman_pause_on_iam',
    rememberedRoute = 'weissman_remembered_route',
    idleTimeoutStatus = 'weissman_idle_timeout_state'
}

@Injectable(
    { providedIn: 'root' }
)
export class AuthStorageService {
    constructor() {
        this.token$ = new ReplaySubject<string>();
        this.logoutMessage$ = new ReplaySubject<string>();
        this.showIAMLogoutButton = false;
        this.resetPathOnLogout = false;
        this.authInterceptEnabled = false;
        this.setNoRelogin = false;
        this.activity$ = null;
        this.isMigrationLogin = false;
    }

    activityRefreshNeeded$: Subject<number>;
    resetPathOnLogout: boolean;
    token$: ReplaySubject<string>;
    logoutMessage$: ReplaySubject<string>;
    showIAMLogoutButton: boolean;
    authInterceptEnabled: boolean;
    setNoRelogin: boolean;
    isMigrationLogin: boolean;

    private _authData: AuthData;
    private activity$: Subject<number>;

    setAuthData(authData: AuthData) {
        this._authData = authData;
        // set_no_relogin might be null, undefined, false, or true; use !! to ensure it's just true or false
        this.setNoRelogin = !!authData.set_no_relogin;
        this.token$.next(authData.access_token);
    }

    getAuthData() {
        return this._authData;
    }

    clearAuthData() {
        delete localStorage[StorageTokenKeys.refreshTokenKey];
        localStorage.removeItem('agAutoUpdate');
        this._authData = null;
    }

    setActivityRecordingMode(mode: boolean) {
        if (mode) {
            this.activity$ = new Subject<number>();
            fromEvent(document, 'click').subscribe(() => { this.recordActivity('click'); });
            fromEvent(document, 'keyup').subscribe(() => { this.recordActivity('keyup'); });
            // Throttle to 1 second (no need to write to local storage more often than that)
            this.activity$.pipe(throttleTime(1000)).subscribe(t => {
                localStorage[StorageTokenKeys.lastActivityKey] = `${t  }`;
            });
        }
        else {
            this.activity$ = null;
        }

        return this.activity$;
    }

    recordActivity(desc: string) {
        //console.log(moment().format('YYYY-MM-DD hh:mm:ss') + ' Recording ' + desc);
        if (this.activity$ !== null) {
            this.activity$.next(+new Date());
        }
    }
}
