import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface AgGridTooltipCellRendererParams extends ICellRendererParams {
    getTooltipText: (params: AgGridTooltipCellRendererParams) => string;
}

@Component({
    selector: 'ag-grid-tooltip-cell-renderer',
    template: `
        <div helpTooltip [tooltipText]="tooltipText" [tooltipDelay]="0" [helpDisabled]="!tooltipText">
            <span *ngIf="cellText">{{cellText}}</span>
            <span *ngIf="!cellText" style="visibility: hidden;">x</span>
        </div>
    `
})
export class AgGridTooltipCellRendererComponent implements ICellRendererAngularComp {

    params: AgGridTooltipCellRendererParams;
    tooltipText: string;
    cellText: string;

    agInit(params: AgGridTooltipCellRendererParams): void {
        this.params = params;
        this.tooltipText = params.getTooltipText(params);
        this.cellText = params.valueFormatted || params.value;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
