<div class="card panel-flat-color primary-panel">
    <div class="panel-flat-header">
        <h3>Details</h3>
    </div>
    <div class="panel-flat-body">
        <table class="table table-condensed table-striped" style="margin-bottom: 0;">
            <tbody>
                <tr>
                    <td>Name:</td>
                    <td>{{state.fullName}}</td>
                </tr>
                <tr>
                    <td>Abbr:</td>
                    <td style="text-transform: uppercase;">{{state.abbr}}</td>
                </tr>
                <tr ng-if="state.country">
                    <td>Country:</td>
                    <td>{{ state.country.name }}</td>
                </tr>
                <tr>
                    <td>Website:</td>
                    <td><a [href]="state.website | wsAbsoluteUrl" target="_blank">{{state.website}}</a></td>
                </tr>
                <tr>
                    <td>Personal Property Taxable:</td>
                    <td><input type="checkbox" disabled [(ngModel)]="state.personalPropTaxable"></td>
                </tr>
                <tr>
                    <td>Benchmark PP Tax Rate:</td>
                    <td>{{state.benchmarkPPTaxRate | percent: '1.2'}}</td>
                </tr>
                <tr>
                    <td>Supplemental Tax Bills:</td>
                    <td><input type="checkbox" disabled [(ngModel)]="state.supplementalTaxBills"></td>
                </tr>
                <tr>
                    <td>Land Comp FMV Fixed on Change:</td>
                    <td><input type="checkbox" disabled [(ngModel)]="state.forecastLandFMVFixed"></td>
                </tr>
            </tbody>
        </table>
        <div style="display: flex; justify-content: space-between; align-items: end; margin-top: 10px;">
            <div>
                <div>
                    <a anchor-route [sref]="{ target: 'assessors', options: { stateId: state.stateID } }">
                        Assessors ({{state.assessorCount}})
                    </a>
                </div>
                <div>
                    <a anchor-route [sref]="{ target: 'collectors', options: { stateId: state.stateID } }">
                        Collectors ({{state.collectorCount}})
                    </a>
                </div>
            </div>
            <div class="ws-button-row">
                <entity-attachments buttonClass="ace-btn has-icon btn-lg btn-primary"
                    [isNestedModal]="false"
                    [(hasAttachments)]="state.hasAttachments"
                    [modalData]="attachmentsModel">
                </entity-attachments>
                <entity-comments [(hasComments)]="state.hasComments"
                    [isNestedModal]="false"
                    buttonClass="ace-btn has-icon btn-lg btn-primary"
                    [modalData]="commentsModel">
                </entity-comments>
            </div>
        </div>
    </div>
</div>
