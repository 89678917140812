<div class="ws-flex-none ws-flex-container-horizontal login-manager-page-title" style="border-bottom:1px solid var(--ace-neutral-800);padding-bottom: 4px;">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="pageTitle"></ws-bread-crumbs>
</div>
<div class="ws-flex-container-horizontal login-manager-page-row ws-flex-none">
    <div class="ws-flex-container-vertical login-manager-page-flex-column-left">
        <div class="card panel-flat-color primary-panel flex-panel">
            <div class="panel-flat-header ws-flex-container-horizontal">
                <h3 class="ws-flex-auto ws-truncate">
                    User
                </h3>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical">
                <div *ngIf="user">
                    <table class="table table-condensed">
                        <tbody>
                            <tr>
                                <td class="p-1">UserName:</td>
                                <td class="p-1">{{user.userName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Email:</td>
                                <td class="p-1">{{user.email}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">First Name:</td>
                                <td class="p-1">{{user.firstName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Last Name:</td>
                                <td class="p-1">{{user.lastName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">User Id:</td>
                                <td class="p-1">{{user.userId}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Active:</td>
                                <td class="p-1">{{user.active}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Home Instance:</td>
                                <td class="p-1">{{homeInstanceName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">IAM Id:</td>
                                <td class="p-1">{{user.iamId}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">IAM Migrated:</td>
                                <td class="p-1">{{user.iamIsMigrated}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">IAM Tenant Id:</td>
                                <td class="p-1">{{user.iamTenantId}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Created:</td>
                                <td class="p-1">{{user.createDate | weissmanDateTimeFormat: true: 'Central'}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Lockout End Date:</td>
                                <td class="p-1">{{user.lockoutEndDateUtc | weissmanDateTimeFormat: true: 'Central'}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Lockout Enabled:</td>
                                <td class="p-1">{{user.lockoutEnabled}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Access Failed Count:</td>
                                <td class="p-1">{{user.accessFailedCount}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <p>Note: All times in Central Time</p>
                </div>
                <div *ngIf="userActionState==='None'">
                    <button type="button" class="ace-btn btn-sm btn-primary" (click)="startManageIAMUser()">Manage IAM User</button>
                    <button type="button" class="ace-btn btn-sm btn-primary" (click)="startManageIAMTenant()">Manage IAM Tenant</button>
                    <button type="button" class="ace-btn btn-sm btn-primary" (click)="startUpdateLockedFields()">Update Locked Fields</button>
                </div>
                <div *ngIf="userActionState!=='None'">
                    <button type="button" class="ace-btn btn-sm btn-secondary" (click)="cancelUserAction()">Back</button>
                </div>
                <div *ngIf="userActionState==='ManageIAMUser'">
                    <div *ngIf="!updatingUserIAMId">
                        IAM Id:
                        <span *ngIf="!user.iamId">(None)</span>
                        <span *ngIf="user.iamId">{{user.iamId}}</span>
                        <button *ngIf="user.iamId" class="ace-btn btn-sm btn-primary login-manager-inline-button" (click)="getIAMUserData()">Get Data</button>
                        <button class="ace-btn btn-sm btn-primary login-manager-inline-button" (click)="startUpdateUserIAMId()">Update</button>
                        <button *ngIf="user.iamId" class="ace-btn btn-sm btn-primary login-manager-inline-button" (click)="unMigrateUser()">Un-Migrate</button>
                    </div>
                    <div *ngIf="updatingUserIAMId">
                        <div>
                            IAM Id:
                            <input type="text" class="iam-id" [(ngModel)]="newIAMId" />
                            <button type="button" class="ace-btn has-icon login-manager-inline-button" (click)="saveUserIAMId()">
                                <i class="fa fa-save"></i>
                            </button>
                            <button type="button" class="ace-btn has-icon btn-primary btn-danger" (click)="cancelUpdateUserIAMId()">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-condensed" *ngIf="iamUserData">
                        <tbody>
                            <tr>
                                <td class="p-1">Tenant Id:</td>
                                <td class="p-1">{{iamUserData.tenantId}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">UserName:</td>
                                <td class="p-1">{{iamUserData.username}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Display Name:</td>
                                <td class="p-1">{{iamUserData.displayName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">First Name:</td>
                                <td class="p-1">{{iamUserData.firstName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Last Name:</td>
                                <td class="p-1">{{iamUserData.lastName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Tenant Admin:</td>
                                <td class="p-1">{{iamUserData.isTenantAdmin}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Job Title:</td>
                                <td class="p-1">{{iamUserData.jobTitle}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Phone:</td>
                                <td class="p-1">{{iamUserData.phone}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Status:</td>
                                <td class="p-1">{{iamUserData.userStatus}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="userActionState==='ManageIAMTenant'">
                    <div *ngIf="!updatingUserTenantId">
                        Tenant Id:
                        <span *ngIf="!user.iamTenantId">(None)</span>
                        <span *ngIf="user.iamTenantId">{{user.iamId}}</span>
                        <button *ngIf="user.iamTenantId" class="ace-btn btn-sm btn-primary login-manager-inline-button" (click)="getIAMTenantData()">Get Data</button>
                        <button class="ace-btn btn-sm btn-primary login-manager-inline-button" (click)="startUpdateUserIAMTenantId()">Update</button>
                        <button *ngIf="user.iamTenantId" class="ace-btn btn-sm btn-primary login-manager-inline-button" (click)="removeUserIAMTenant()">Remove</button>
                    </div>
                    <div *ngIf="updatingUserTenantId">
                        <div>
                            Tenant Id:
                            <input type="text" class="iam-id" [(ngModel)]="newIAMTenantId" />
                            <button type="button" class="ace-btn has-icon login-manager-inline-button" (click)="saveUserIAMTenantId()">
                                <i class="fa fa-save"></i>
                            </button>
                            <button type="button" class="ace-btn has-icon btn-primary btn-danger" (click)="cancelUpdateUserIAMTenantId()">
                                <i class="fa fa-close"></i>
                            </button>
                        </div>
                    </div>
                    <table class="table table-condensed" *ngIf="iamTenantData">
                        <tbody>
                            <tr>
                                <td class="p-1">Tenant Id:</td>
                                <td class="p-1">{{iamTenantData.id}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Name:</td>
                                <td class="p-1">{{iamTenantData.name}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Status:</td>
                                <td class="p-1">{{iamTenantData.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <hr />
                    <p>
                        Note: Normally it is not necessary for a user to have an IAM Tenant Id. The Tenant Id usually is inferred based on
                        the company for the user's home instance contact. You only need to include an IAM Tenant Id for a user if it differs
                        from their company's Tenant Id or if there is no such Tenant Id configured on the company. Also, the Tenant Id is
                        only used during the migration process and is not necessary for normal logins.
                    </p>
                </div>
                <div *ngIf="userActionState==='UpdateLockedFields'">
                    <table>
                        <tbody>
                            <tr>
                                <td class="p-1">First Name:</td>
                                <td class="p-1">
                                    <input type="text" class="form-control" [(ngModel)]="newFirstName" />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-1">Last Name:</td>
                                <td class="p-1">
                                    <input type="text" class="form-control" [(ngModel)]="newLastName" />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-1">Email:</td>
                                <td class="p-1">
                                    <input type="text" class="form-control" [(ngModel)]="newEmail" />
                                </td>
                            </tr>
                            <tr>
                                <td class="p-1" (click)="toggleNewIsMigrated()" class="pointer">IAM Migrated:</td>
                                <td class="p-1">
                                        <input type="checkbox" [(ngModel)]="newIsMigrated" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="ace-btn btn-sm btn-primary" (click)="saveLockedFields()">Save</button>
                    <button type="button" class="ace-btn btn-sm btn-primary btn-danger" (click)="cancelUserAction()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-flex-container-vertical login-manager-page-flex-column-right">
        <div class="card panel-flat-color primary-panel flex-panel">
            <div class="panel-flat-header ws-flex-container-horizontal">
                <h3 class="ws-flex-auto ws-truncate">
                    Contact
                </h3>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical">
                <div *ngIf="contact">
                    <ws-select
                        style="display:inline-block"
                        labelProperty="name"
                        [returnEntireOption]="true"
                        placeholder="Nothing selected"
                        [canDeselect]="false"
                        [(ngModel)]="selectedInstance"
                        (ngModelChange)="selectedInstanceChanged()"
                        [options]="instances">
                    </ws-select>
                    <table class="table table-condensed">
                        <tbody>
                            <tr>
                                <td class="p-1">Contact Id:</td>
                                <td class="p-1">{{contact.contactID}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Prefix:</td>
                                <td class="p-1">{{contact.prefix}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">First Name:</td>
                                <td class="p-1">{{contact.firstName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Last Name:</td>
                                <td class="p-1">{{contact.lastName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Title:</td>
                                <td class="p-1">{{contact.title}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Company Id:</td>
                                <td class="p-1">{{contact.companyID}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Company:</td>
                                <td class="p-1">{{contact.companyName}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Company IAM Tenant Id:</td>
                                <td class="p-1">{{contact.companyIAMTenantId}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Active:</td>
                                <td class="p-1">{{contact.active}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Email:</td>
                                <td class="p-1">{{contact.email}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Accepted Invite:</td>
                                <td class="p-1">{{contact.invite}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Hidden:</td>
                                <td class="p-1">{{contact.hidden}}</td>
                            </tr>
                            <tr>
                                <td class="p-1">Instance Id:</td>
                                <td class="p-1">{{contact.instanceId}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
