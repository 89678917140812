import { NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { PaymentPackagesComponent } from './payment-packages.component';
import { TransmittalDetailsComponent } from './transmittal-details.component';
import { FinalizePaymentPackageModalComponent } from './Modals/payment-packages-finalize.modal';
import { ReviewPaymentPackageModalComponent } from './Modals/payment-packages-review.modal';
import { PaymentPackageAttachmentSummaryComponent } from './payment-package-attachment-summary.component';
import { TransmittalService } from './transmittal.service';
import { RecipientTypePipe } from './transmittal.pipes';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { CommonModule } from '@angular/common';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        QuillModule,
        BusyIndicatorModule
    ],
    declarations: [
        PaymentPackagesComponent,
        TransmittalDetailsComponent,
        FinalizePaymentPackageModalComponent,
        ReviewPaymentPackageModalComponent,
        PaymentPackageAttachmentSummaryComponent,
        RecipientTypePipe
    ],
    providers: [
        TransmittalService
    ],
    exports: [
        PaymentPackagesComponent,
        PaymentPackageAttachmentSummaryComponent,
        ReviewPaymentPackageModalComponent
    ]
})
export class TransmittalModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('paymentPackages', PaymentPackagesComponent);
        hybridAdapterUtility.downgradeNg2Component('paymentPackageAttachmentSummary', PaymentPackageAttachmentSummaryComponent);
    }
}
