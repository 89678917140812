import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { ErrorLogRepository } from '../errorLog.repository';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { lastValueFrom } from 'rxjs';

export interface ErrorLogDetailsParams {
    errorLogId: number;
    errorLogGuid: string;
}

@Component({
    selector: 'error-log-details',
    templateUrl: './errorLogDetails.component.html',
    styleUrls: ['./errorLogDetails.component.scss']
})
export class ErrorLogDetailsComponent implements OnInit, IWeissmanModalComponent<ErrorLogDetailsParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _errorLogRepository: ErrorLogRepository) { }

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    params: ErrorLogDetailsParams;
    result: void;

    errorLog: Core.ErrorLogModel;

    async ngOnInit(): Promise<void> {

        const busyMsgId = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgId);

        try {
            if (this.params.errorLogId) {
                this.errorLog = await lastValueFrom(this._errorLogRepository.get(this.params.errorLogId));
            }
            else {
                this.errorLog = await lastValueFrom(this._errorLogRepository.getViaGuid(this.params.errorLogGuid));
            }
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
