import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CacheManager, CacheManagerService } from '../../Common/Routing/cache-manager.service';
import { SiteClassRepository } from '../../Core-Repositories';
import { SiteClassPrimary } from '../../Core-Repositories/siteClass.repository';

// TODO export from back end
export interface SiteClassSecondary {
    siteClassSecondaryID: number;
    siteClassDesc: string;
    siteClassPrimaryID: number;
}

@Injectable(
    { providedIn: 'root' }
)
export class SiteClassService {
    constructor(
        private readonly _cacheManagerService: CacheManagerService,
        private readonly _siteClassRepository: SiteClassRepository
    ) {
        this.cacheManager = _cacheManagerService.createCacheManager();
        this.cacheManager.registerKeyedStaticCache('siteClassSecondary', {
            cacheKey: 'siteclasssecondary',
            endpoint: '/api/siteclasssecondary',
            cacheDisabled: false
        });
    }

    cacheManager: CacheManager;

    getSecondaries(): Promise<SiteClassSecondary[]> {
        return this.cacheManager.keyedStaticGet('siteClassSecondary');
    }

    getPrimaries(): Promise<SiteClassPrimary[]> {
        return lastValueFrom(this._siteClassRepository.getPrimaries());
    }
}
