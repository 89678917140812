import { Component, OnInit } from '@angular/core';
import { ProgressIndicator } from '../Models/progressIndicator.model';
import { SnackBarService, SnackSize } from './snackBar.service';
import { WebsocketListenerService } from '../../Compliance/websocketListener.service';
import { lastValueFrom } from 'rxjs';
import { LongRunningProcessRepository } from '../../Compliance/Repositories';

@Component({
    selector: 'snack-bar',
    templateUrl: './snackBar.component.html',
    styleUrls: ['./snackBar.component.scss']
})
export class SnackBarComponent implements OnInit {
    constructor(private readonly _snackBarService: SnackBarService,
                private readonly _websocketListenerService: WebsocketListenerService,
                private readonly _longRunningProcessRepository: LongRunningProcessRepository
    ) { }

    size: SnackSize;
    snacks: ProgressIndicator[] = [];
    snackSize = SnackSize;

    signalRDisconnected: boolean;
    manualUpdateLoading: boolean;

    ngOnInit(): void {
        this.signalRDisconnected = this._websocketListenerService.signalRDisconnected;

        this._websocketListenerService.longRunningProcessDisconnected$.subscribe(x => {
            this.signalRDisconnected = true;
        });

        this._websocketListenerService.longRunningProcessReconnected$.subscribe(x => {
            this.signalRDisconnected = false;
            this.refreshFromLRPServer();
        });

        this._snackBarService.longRunningProcesses.subscribe((snacks) => {
            this.snacks = snacks;
        });

        this._snackBarService.displayState.subscribe((state) => {
            if (state === SnackSize.Large || state === SnackSize.Hidden) {
                this.size = state;
            }
        });

        this._snackBarService.start();
    }

    /**
     * Hide the snack bar into the navigation
     */
    hideSnackBar(): void {
        this._snackBarService.hideSnackBar();
    }

    /**
     * Tracker for ngFor
     * @param index
     * @param item
     */
    processIdTracker(index: number, item: ProgressIndicator): string {
        return item.identifier;
    }

    /**
     * If SignalR is disconnected the user can manually check for updates
     */
    async refreshFromLRPServer(): Promise<void> {
        if (this.manualUpdateLoading) {
            return;
        }
        this.manualUpdateLoading = true;
        const searchModel: Compliance.LongRunningProcessSearchModel = {
            notAcknowledged: true,
            allUsers: false
        };
        const lrp$ = this._longRunningProcessRepository.getList(searchModel);
        const lrps = await lastValueFrom(lrp$);
        lrps.data.forEach(x => {
            this._websocketListenerService.updateLRPManually(x);
        });
        this.manualUpdateLoading = false;
    }

}
