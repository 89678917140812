import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { EntityTypeIds } from '../../../../constants.new';

@Component({
    selector: 'budget-descriptors-modal',
    templateUrl: './budget.descriptors.modal.component.html',
})
export class BudgetDescriptorsModalComponent implements OnInit {
    constructor(private bsModalRef: BsModalRef) { }

    //initialState
    companyId: number;
    budgetDescriptors: Core.CompanyFeatureDescriptorModel[];
    canEdit: boolean;
    flatInUseDescriptors: Core.CompanyFeatureDescriptorModel[];
    onClose: (budgetDescriptors?: Core.CompanyFeatureDescriptorModel[]) => void;

    loading: boolean = false;
    inUseDescriptors: _.Dictionary<Core.CompanyFeatureDescriptorModel[]>;
    EntityTypeIds = EntityTypeIds;


    async ngOnInit() {
        this.budgetDescriptors = _.sortBy(this.budgetDescriptors, ['entityTypeId', 'descriptorName']) || [];
        this.updateInUseDescriptors();
    }

    descriptorSelected(descriptor: any): void {
        descriptor = descriptor as Core.CompanyFeatureDescriptorModel;

        this.budgetDescriptors.push(descriptor);

        this.updateInUseDescriptors();
    }

    removeDescriptor(descriptor: Core.CompanyFeatureDescriptorModel): void {
        _.remove(this.budgetDescriptors, descriptor);

        this.updateInUseDescriptors();
    }

    updateInUseDescriptors(): void {
        this.inUseDescriptors = _.chain(this.flatInUseDescriptors)
            .reject(x => _.some(this.budgetDescriptors, {descriptorId: x.descriptorId, entityTypeId: x.entityTypeId}))
            .sortBy('descriptorName')
            .groupBy(x => x.entityTypeId == EntityTypeIds.SITE ? 'site' : 'parcel')
            .value();
    }

    cancel(): void {
        this.bsModalRef.hide();
    }

    save(): void {
        this.bsModalRef.hide();
        this.onClose(this.budgetDescriptors);
    }

}
