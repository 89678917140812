import {
    Component,
    OnInit,
    OnDestroy,
    ElementRef,
    ViewChild,
    HostListener,
    AfterViewInit,
    TemplateRef
} from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { RestrictService, Roles } from '../../../../../Common/Permissions/restrict.service';
import { ReturnFormRevisionReportRepository } from '../../../../Repositories';
import { WeissmanModalService } from '../../../../WeissmanModalService';
import { ReturnService, ReturnServiceSharedState } from '../../../return.service';
import { ReturnUpdateLogicService } from '../../../returnUpdateLogic.service';
import { ReturnPreviewService, ReturnFormModel } from '../returnPreview.service';
import { ReturnPreviewFormAssociationsParams, ReturnPreviewFormAssociationsComponent } from '../Form-Associations/returnPreviewFormAssociations.component';
import { ReturnPreviewFormDownloadParams, ReturnPreviewFormDownloadComponent } from '../Form-Download/returnPreviewFormDownload.component';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { BusyIndicatorService } from '../../../../../Busy-Indicator';
import { IMutexServiceHandler, WeissmanMutexService } from '../../../../WeissmanMutexService';
import { UpgradeNavigationServiceHandler } from '../../../../../Common/Routing/upgrade-navigation-handler.service';
import { takeUntil } from 'rxjs/operators';
import { HelpService } from '../../../../../UI-Lib/Help-Tooltip';
import { RETURN_PREVIEW_FORM_LIST_HELP } from './returnPreviewFormList.component.help';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

import { sortBy, reduce, forEach } from 'lodash/fp';
import * as _ from 'lodash';
import { ProductAnalyticsService } from '../../../../../Common/Amplitude/productAnalytics.service';

enum ReturnTemplateTypes {
    Return,
    Form,
    Report,
    Merged
}

interface ReturnInfo {
    template: ReturnTemplateTypes;
    returnId: number;
    parcelId: number;
    hasOverride?: boolean;
    selected?: boolean;
    formsLength?: number;
    parcelAcctNum?: string;
    parcelAcctNumDisplay?: string;
    form?: ReturnForm;
    report?: Compliance.ReturnFormRevisionReportModel;
    priorReturnUsedAnotherForm: boolean;
    hasElectronicFileType: boolean;
}

interface ReturnInfoTree extends ReturnInfo {
    forms?: ReturnInfoTree[];
    merged?: ReturnInfoTree[];
    reports?: ReturnInfo[];
}

interface ReturnForm extends Compliance.ReturnFormRevisionModel {
    parcelId: number;
}

@Component({
    selector: 'return-preview-form-list',
    templateUrl: './returnPreviewFormList.component.html',
    styleUrls: ['./returnPreviewFormList.component.scss'],
    animations: [
        trigger('reportEnter', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-10px)' }),
                animate(60, style({ opacity: 1, transform: 'translateY(0)' }))
            ]),
            transition(':leave', [
                animate(60, style({ opacity: 0, transform: 'translateY(-10px)' }))
            ])
        ])
    ]
})
export class ReturnPreviewFormListComponent implements OnInit, AfterViewInit, OnDestroy, IReturnPartComponent, IMutexServiceHandler {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _returnService: ReturnService,
        private readonly _returnPreviewService: ReturnPreviewService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _returnFormRevisionReportRepository: ReturnFormRevisionReportRepository,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _helpService: HelpService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _restrictService: RestrictService,
        private readonly _returnUpdateLogicService: ReturnUpdateLogicService
    ) { }

    @ViewChild('returnTemplate', { static: true }) returnTemplate: TemplateRef<any>;
    @ViewChild('formTemplate', { static: true }) formTemplate: TemplateRef<any>;
    @ViewChild('reportTemplate', { static: true }) reportTemplate: TemplateRef<any>;
    @ViewChild('mergedTemplate', { static: true }) mergedTemplate: TemplateRef<any>;

    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    flatReturns: ReturnInfo[] = [];
    returnTemplateTypes = ReturnTemplateTypes;
    selectedReturnId: number;
    selectedReturnFormRevisionId: number;
    selectedReturnFormRevisionReportId: number;
    selectedFormRevisionId: number;
    selectedReportId: number; // saved search ID
    selectedMergeParcelId: number;
    selectedIsExcel: boolean;

    editMode: boolean = false;
    isBusy: boolean = false;
    showList = true;
    hasLongName = true;

    showOnlyFrequentlyUsedReports: boolean = true;
    applySelectionsToAllReports: boolean = false;
    isReturnInReadOnlyMode: boolean;
    filingBatchId: number;

    returnNotDefaultMap: { [returnId: number]: boolean; };

    private _localReturnServiceSharedState: ReturnServiceSharedState;

    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _returnsPreEditModeCopy: ReturnInfo[];

    private _loadingFormRevisions: boolean = false;
    private _tabChangedReload: boolean = true;

    private _destroyTabSubs$: Subject<void> = new Subject<void>();

    private _returns: ReturnInfoTree[] = [];
    private _templateMap: Map<ReturnTemplateTypes, TemplateRef<any>>;
    private _parcelsChangeDate: Date = null;

    private _isFormOverridden =
        (x: ReturnInfo, y: ReturnInfo) =>
            (z: Compliance.ReturnFormRevisionModel) => z.returnId === x.returnId && z.formRevisionId === y.form.formRevisionId && (!z.mergedParcelId || z.mergedParcelId === y.parcelId);

    private _isReportOverridden =
        (x: ReturnInfo, y: ReturnInfo) =>
            (z: Compliance.ReturnFormRevisionModel) => z.returnId === x.returnId && (!z.mergedParcelId || z.mergedParcelId === y.parcelId);

    get taxYear(): number {
        return this._returnService.taxYear;
    }

    get editReportsHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        if (!this._returns.length) {
            return 'return-preview-form-list.edit-reports-no-returns';
        }

        return 'return-preview-form-list.edit-reports';
    }

    get formsAndReportsHelpContentId(): string {
        if (this.editMode) {
            return 'return-preview-form-list.forms-reports-edit';
        }

        if (this._returns.length) {
            return 'return-preview-form-list.forms-reports';
        } else {
            return 'return-preview-form-list.forms-reports-no-returns';
        }
    }

    get canEdit(): boolean {
        return !this._returnService.isReturnInReadOnlyMode && this._returnService.canEditCompany;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._returnService.editGroup);
    }

    get hasEditComplianceSetupsPermission(): boolean {
        return this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT);
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(RETURN_PREVIEW_FORM_LIST_HELP);

        const filingBatchIdParam = this._routerService.getQuerystringParam('filingBatchId');
        this.filingBatchId = parseInt(filingBatchIdParam);

        this._templateMap = new Map([
            [ReturnTemplateTypes.Return, this.returnTemplate],
            [ReturnTemplateTypes.Form, this.formTemplate],
            [ReturnTemplateTypes.Report, this.reportTemplate],
            [ReturnTemplateTypes.Merged, this.mergedTemplate]
        ]);

        this._returnPreviewService.subscribeToServiceActivationCycle(this);
        this._createComparisonMap(this._returnService.compareFormsResult);
        this._returnService.compareForms$.pipe(takeUntil(this._destroyTabSubs$))
            .subscribe((comparison: Compliance.ReturnCompareFormsResultModel) => {
                if(comparison) {
                    this._createComparisonMap(comparison);
                }
            });
        this._returnPreviewService.openPreviewByOverrides$.pipe(takeUntil(this._destroyTabSubs$))
            .subscribe((overrideModel: Compliance.ReturnFormOverrideDetailModel) => {
                this._openPreviewByOverrideClick(overrideModel);
            });
    }

    ngOnDestroy(): void {
        this._destroyTabSubs$.next();
        this._destroyTabSubs$.complete();
        this._returnPreviewService.unsubscribeFromServiceActivationCycle(this);
        this._mutexService.release(this, this._returnService.editGroup);
    }

    ngAfterViewInit(): void {
        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    async onReturnPartServiceActivated(): Promise<void> {
        this._returnService.returns$.pipe(takeUntil(this._destroyTabSubs$))
            .subscribe((returns) => {
                if (returns) {
                    this._refreshFormRevisions();
                    this._tabChangedReload = false;
                    this.showList = true;
                }
            });

        this._returnService.isReturnInReadOnlyMode$.pipe(takeUntil(this._destroyTabSubs$))
            .subscribe(async (isReadonly) => {
                if (this.isReturnInReadOnlyMode === isReadonly) { return; }
                this.isReturnInReadOnlyMode = isReadonly;
                await this._refreshFormRevisions(true); // force reload of forms and reports
            });

        this._returnPreviewService.formOverrides$.pipe(takeUntil(this._destroyTabSubs$))
            .subscribe(overrides => {
                this._returns.forEach(x => {
                    x.forms.forEach(y => y.hasOverride = overrides.some(this._isFormOverridden(x, y)));
                    x.merged.forEach(y => {
                        y.hasOverride = overrides.some(this._isReportOverridden(x, y));
                        y.forms.forEach(z => z.hasOverride = overrides.some(this._isFormOverridden(x, z)));
                    });
                });
            });

        this._returnPreviewService.returnForm$.pipe(takeUntil(this._destroyTabSubs$))
            .subscribe(x => {
                this.selectedReturnId = x && x.returnId;
                this.selectedReturnFormRevisionId = x && x.returnFormRevisionId;
                this.selectedReturnFormRevisionReportId = x && x.returnFormRevisionReportId;
                this.selectedFormRevisionId = x && x.formRevisionId;
                this.selectedReportId = x && x.reportId;
                this.selectedMergeParcelId = x && x.mergeParcelId;
                this.selectedIsExcel = x && x.isExcel;
            });

        this._editModeSubject.asObservable().pipe(takeUntil(this._destroyTabSubs$))
            .subscribe(x => {
                this.editMode = x;
                if (!this.editMode) {
                    this._mutexService.release(this, this._returnService.editGroup);
                }
            });

        this._returnService.parcelsChanged$.pipe(takeUntil(this._destroyTabSubs$)).subscribe((x) => {
            if (x) {
                if (this._parcelsChangeDate !== x && this.viewport) {
                    this._parcelsChangeDate = x;
                    this._refreshFormRevisions(true);
                }
            }
        });

        if (this.viewport) {
            this.viewport.checkViewportSize();
        }
    }

    onReturnPartServiceDeactivated(): void {
        this._destroyTabSubs$.next();
        this._tabChangedReload = true;
        this.showList = false;
    }

    getTemplate(template: ReturnTemplateTypes): TemplateRef<any> {
        return this._templateMap.get(template) as TemplateRef<any>;
    }

    getReportName(report: Compliance.ReturnFormRevisionReportModel): string {
        return this._returnService.getReturnReportName(report);
    }

    getReturnFormRevisionDisplayName(returnFormRevision: Compliance.ReturnFormRevisionModel): string {
        return this._returnService.getReturnFormRevisionDisplayName(returnFormRevision);
    }

    getReportSource(report: Compliance.ReturnFormRevisionReportModel): string {
        return report.source === Compliance.ReturnFormRevisionSourceEnum.FilingBatchReport
            ? ' Source: Filing Batch Additional Reports'
            : ' Source: Default Form Setup';
    }

    async downloadReturnDocument(returnId: number): Promise<void> {
        const returnInfo = this._returns
            .find(x => x.returnId === returnId);

        const forms = returnInfo.forms
            .map(x => x.form);

        const reports = returnInfo.forms
            .map(x => x.reports)
            .reduce((prev, cur) => prev.concat(cur))
            .map(x => x.report);

        const params: ReturnPreviewFormDownloadParams = {
            returnId: returnId,
            returnFormRevisions: forms,
            returnFormRevisionReports: reports
        };

        await this._modalService.showAsync(ReturnPreviewFormDownloadComponent, params, 'modal-md');
    }

    async manageReturnFormRevisions(r: ReturnInfo): Promise<void> {
        const params: ReturnPreviewFormAssociationsParams = {
            returnId: r.returnId,
            parcelId: r.parcelId
        };

        const modalOptions = {
            class: 'modal-md',
            initialState: { params },
            ignoreBackdropClick: true,
            keyboard: false,
            providers: [{ provide: ReturnUpdateLogicService, useValue: this._returnUpdateLogicService }]
        } as ModalOptions;

        const result = await this._modalService.showAsync(ReturnPreviewFormAssociationsComponent, params, 'modal-md', modalOptions);

        if (!result) {
            return Promise.resolve();
        }

        await this._refreshFormRevisions();
        this._returnService.validateForms(this.filingBatchId);
    }

    showPreview(returnId: number, returnFormRevisionId: number, formRevisionId: number, returnFormRevisionReportId: number, reportId: number, mergedParcelId: number, isExcel: boolean, navigateToOverride: Compliance.ReturnFormOverrideDetailModel = null): void {
        this.selectedReturnId = returnId;
        this.selectedReturnFormRevisionId = returnFormRevisionId;
        this.selectedReturnFormRevisionReportId = returnFormRevisionReportId;
        this.selectedFormRevisionId = formRevisionId;
        this.selectedReportId = reportId;
        this.selectedMergeParcelId = mergedParcelId;
        this.selectedIsExcel = isExcel;

        this._onSelectedFormListItemChanged(navigateToOverride);
    }

    selectReport(report: ReturnInfo, form: ReturnInfo) {
        report.selected = !report.selected;

        if (this.applySelectionsToAllReports) {
            const savedSearchId = report.report.savedSearchId;

            const forms = this._returns.reduce((acc, r) => [...acc, ...r.forms], []);
            const reports = forms.reduce((acc, formInfo: ReturnInfoTree) => [...acc, ...formInfo.reports], [])
                .filter((returnFormReportInfo: ReturnInfoTree) => returnFormReportInfo.report.savedSearchId === savedSearchId);

            reports.forEach((returnFormInfo: ReturnInfoTree) => {
                returnFormInfo.selected = report.selected;
            });
        }
    }

    enterEditMode(): void {
        this._mutexService.acquire(this, this._returnService.editGroup);
        this._editModeSubject.next(true);
        this._returnsPreEditModeCopy = _.cloneDeep(this._returns);
        this._setFlatReturns();
    }

    cancelEditMode(): void {
        this._returns = this._returnsPreEditModeCopy;
        this._editModeSubject.next(false);
        this._setFlatReturns();
    }

    async saveFormReports(): Promise<void> {
        this.isBusy = true;
        const modelsToPersist: Compliance.ReturnFormRevisionReportModel[] = [];
        this._returns.forEach(x => {
            x.forms.forEach(y => {
                y.reports.forEach(({ selected, report }) => {
                    if ((selected && report.returnFormRevisionReportId === 0) || (!selected && report.returnFormRevisionReportId !== 0)) {
                        modelsToPersist.push(report);
                    }
                });
            });
        });

        try {
            await lastValueFrom(this._returnFormRevisionReportRepository.update(this.filingBatchId, modelsToPersist));
            this._editModeSubject.next(false);
            this._mutexService.release(this, this._returnService.editGroup);

            await this._loadFormRevisions();
            this._returnService.validateForms(this.filingBatchId);
            this._setFlatReturns();
            this._productAnalyticsService.logEvent('click-edit-reports-save-changes', {
                updateReturnReports: modelsToPersist.map(x => x.name + (x.variantName ? (` - ${  x.variantName}`) : ''))
            });
        } finally {
            this.isBusy = false;
        }
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private _openPreviewByOverrideClick = (overrideModel: Compliance.ReturnFormOverrideDetailModel) => {
        if (this._returns) {
            const returnInfo = this._returns.find(x => x.returnId === overrideModel.returnId);
            const formInfo = returnInfo && returnInfo.forms.find(({ form }) => form.formRevisionId === overrideModel.formRevisionId);

            if (returnInfo && formInfo) {
                this.showPreview(returnInfo.returnId, formInfo.form.returnFormRevisionId, formInfo.form.formRevisionId, null, null, overrideModel.parcelId, false, overrideModel);
            }
        }
    };

    private _checkForReportChanges(currentReports: Compliance.FilingBatchReportModel[]): boolean {
        let hasUpdates: boolean;
        const lastUsedReports = this._localReturnServiceSharedState.reports;
        if (lastUsedReports.length !== currentReports.length) {
            hasUpdates = true;
        } else {
            hasUpdates = !currentReports.every((currentReport: Compliance.FilingBatchReportModel) => {
                return (lastUsedReports.some((prevReport: Compliance.FilingBatchReportModel) => {
                    return (prevReport.changeDate === currentReport.changeDate &&
                        prevReport.filingBatchReportId === currentReport.filingBatchReportId);
                }));
            });
        }
        return hasUpdates;
    }

    private async _refreshFormRevisions(force: boolean = false): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();

        // get input parameters for comparison
        const localReturnIds = (this._localReturnServiceSharedState && this._localReturnServiceSharedState.returns && this._localReturnServiceSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReturnFormRevisionsUpdatedTimestamp = this._localReturnServiceSharedState && this._localReturnServiceSharedState.returnFormRevisionsUpdatedTimestamp;
        const sharedReturnFormRevisionsUpdatedTimestamp = returnServiceSharedState.returnFormRevisionsUpdatedTimestamp;

        // check to see if the input parameters are available
        if (!sharedReturnIds) {
            return;
        }

        // check to see if the input parameters have changed
        if (force ||
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReturnFormRevisionsUpdatedTimestamp, sharedReturnFormRevisionsUpdatedTimestamp) ||
            this._checkForReportChanges(returnServiceSharedState.reports)
        ) {
            if (this._loadingFormRevisions) { return; }
            await this._loadFormRevisions(!_.isEqual(localReturnIds, sharedReturnIds));
        }

        // auto-select the return/returnForm/returnFormReport
        this.selectedReturnId = null;
        this.selectedReturnFormRevisionId = null;
        this.selectedReturnFormRevisionReportId = null;
        this.selectedFormRevisionId = null;
        this.selectedMergeParcelId = null;

        const returnForm = this._returnPreviewService.sharedState.returnForm;
        const currentReturn = returnForm ? this.flatReturns.find(x => x.returnId === returnForm.returnId && x.parcelId === returnForm.mergeParcelId) : null;

        if (currentReturn) {
            this.selectedReturnId = returnForm.returnId;
            this.selectedReturnFormRevisionId = returnForm.returnFormRevisionId;
            this.selectedReturnFormRevisionReportId = returnForm.returnFormRevisionReportId;
            this.selectedFormRevisionId = returnForm.formRevisionId;
            this.selectedMergeParcelId = (!this.selectedReturnFormRevisionReportId && returnForm.mergeParcelId) || null;
        }

        this._onSelectedFormListItemChanged();
    }

    private async _loadFormRevisions(updateFormRevisions?: boolean): Promise<void> {
        this._loadingFormRevisions = true;
        // persist local state for future checks
        this._localReturnServiceSharedState = this._returnService.getSharedStateClone();

        const busyRef = this._busyIndicatorService.show({ message: 'Loading Returns' });

        try {
            // consists of a list of accounts and forms for each account to display in sequential order
            // on the UI, if an item in this list does not have a form revision ID then it is the account label and it will show differently
            // if it does have a form revision ID then a click event is made available and we will get notified when that item is selected for (rendering) preview
            const [reportsForReturns, reportsAvailable]: [Compliance.ReturnFormRevisionReportModel[], Compliance.ReturnFormRevisionReportModel[], void] = await Promise.all([
                lastValueFrom(this._returnPreviewService.getAssociatedReportsForReturns()),
                lastValueFrom(this._returnPreviewService.getNotAssociatedReportsForReturns()),
                updateFormRevisions ? this._returnService.loadReturnFormRevisions() : Promise.resolve()
            ]);

            const reportsAvailableForReturns = reportsAvailable.reduce((acc, x) => {
                if (!acc.find(y => y.savedSearchId === x.savedSearchId && y.returnFormRevisionId === x.returnFormRevisionId)) {
                    acc.push(x);
                }
                return acc;
            }, [] as Compliance.ReturnFormRevisionReportModel[]);

            this._returns = [];

            // Create the consolidated parcel groupings first, then add the forms and reports
            this._returnService.sharedState.returns.reduce((acc, x) => {
                const returnId = (x.returnId !== null && x.returnId);
                const returnInfo = (acc.has(returnId))
                    ? acc.get(returnId)
                    : <ReturnInfoTree>{
                        template: ReturnTemplateTypes.Return,
                        level: 0,
                        returnId: returnId,
                        parcelId: null,
                        parcelAcctNum: null,
                        parcelAcctNumDisplay: null,
                        returnFormRevisionId: null,
                        forms: [],
                        merged: [],
                        priorReturnUsedAnotherForm: false,
                        hasElectronicFileType: false
                    };

                if (!x.isMergedParcel) {
                    returnInfo.parcelId = x.parcelId;
                    returnInfo.parcelAcctNum = x.parcelAcctNumber;
                    returnInfo.parcelAcctNumDisplay = x.parcelAcctNumberDisplay;
                } else {
                    returnInfo.merged.push({
                        template: ReturnTemplateTypes.Merged,
                        returnId: returnId,
                        parcelId: x.parcelId,
                        parcelAcctNum: x.parcelAcctNumber,
                        parcelAcctNumDisplay: x.parcelAcctNumberDisplay,
                        hasOverride: false,
                        forms: [],
                        priorReturnUsedAnotherForm: false,
                        hasElectronicFileType: false
                    });
                }

                // If any parcel has a long name, make the panel a bit wider
                if (returnInfo.parcelAcctNumDisplay && returnInfo.parcelAcctNumDisplay.length > 43) {
                    this.hasLongName = true;
                }

                acc.set(returnId, returnInfo);
                return acc;
            }, new Map<number, ReturnInfoTree>()).forEach(x => {
                this._returnService
                    .getAssociatedReturnFormRevisions()
                    .filter(item => item.returnId === x.returnId)
                    .forEach((y: Compliance.ReturnFormRevisionModel, i, ar) => {
                        const form = { ...y, parcelId: x.parcelId };
                        const returnFormInfo: ReturnInfoTree = {
                            template: ReturnTemplateTypes.Form,
                            returnId: x.returnId,
                            parcelId: x.parcelId,
                            form,
                            formsLength: ar.length,
                            hasOverride: false,
                            reports: [],
                            priorReturnUsedAnotherForm: y.priorReturnUsedAnotherForm,
                            hasElectronicFileType: y.hasElectronicFileType
                        };

                        // add in-use form revision reports to return form revision
                        reportsForReturns
                            .filter(x => x.returnFormRevisionId === y.returnFormRevisionId)
                            .forEach(z => {
                                const returnFormReportInfo: ReturnInfo = {
                                    template: ReturnTemplateTypes.Report,
                                    returnId: x.returnId,
                                    parcelId: null,
                                    selected: true,
                                    form,
                                    report: z,
                                    priorReturnUsedAnotherForm: false,
                                    hasElectronicFileType: false
                                };
                                returnFormInfo.reports.push(returnFormReportInfo);
                            });

                        // add available form revision reports to return form revisions
                        reportsAvailableForReturns
                            .filter(x => x.returnFormRevisionId === y.returnFormRevisionId)
                            .forEach(z => {
                                const returnFormReportInfo: ReturnInfo = {
                                    template: ReturnTemplateTypes.Report,
                                    returnId: x.returnId,
                                    parcelId: null,
                                    selected: false,
                                    form,
                                    report: z,
                                    priorReturnUsedAnotherForm: false,
                                    hasElectronicFileType: false
                                };
                                returnFormInfo.reports.push(returnFormReportInfo);
                            });

                        returnFormInfo.reports = _.orderBy(returnFormInfo.reports,
                            [({ report }) => report.sequence, ({ report }) => report.name, ({ report }) => report.variantName]);

                        x.forms.push(returnFormInfo);
                        x.merged.forEach(z => {
                            if (y.isFormConsolidated) {
                                const mergedForm = { ...returnFormInfo, isMergedForm: true, parcelId: z.parcelId };
                                z.forms.push(mergedForm);
                            }
                        });
                    });

                //sort forms: form revision first, then its supplemental forms next
                const sortedForms = [];
                const parentFormRevisionAssociations = x.forms
                    .filter(y => y.form.parentFormRevisionId === null)
                    .sort((a, b) => a.form.formRevisionName.localeCompare(b.form.formRevisionName));
                const childFormRevisionAssociations = x.forms
                    .filter(y => y.form.parentFormRevisionId !== null)
                    .sort((a, b) => a.form.formRevisionName.localeCompare(b.form.formRevisionName));
                const childDict: { [id: string]: ReturnInfoTree[]; } = {};

                childFormRevisionAssociations.forEach(child => {
                    if (!childDict[child.form.parentFormRevisionId]) {
                        childDict[child.form.parentFormRevisionId] = [];
                    }
                    childDict[child.form.parentFormRevisionId].push(child);
                });

                parentFormRevisionAssociations.forEach(parent => {
                    sortedForms.push(parent);
                    if (childDict[parent.form.formRevisionId] != null) {
                        sortedForms.push(...childDict[parent.form.formRevisionId]);
                    }
                });

                x.forms = sortedForms;

                // If a merged parcel has no forms remove it
                if (x.merged.length) {
                    x.merged = x.merged.reduce((acc, y) => {
                        if (y.forms.length) {
                            acc.push(y);
                        }
                        return acc;
                    }, []);
                }

                this._returns.push(x);
            });

            this._setFlatReturns();
            this.viewport.checkViewportSize();

            // load the overrides then filter the list of all items
            await this._returnPreviewService.loadFormOverrides();
        } finally {
            this._loadingFormRevisions = false;
            busyRef.hide();
        }
    }

    private _setFlatReturns(): void {
        this.flatReturns = this._returns.reduce((acc, r) => {
                if (r.parcelAcctNumDisplay) {
                    acc.push(r);
                    r.forms.forEach(x => {
                        acc.push(x);
                        x.reports.forEach(y => {
                            if ((this.editMode && (y.report.isFrequentlyUsed || !this.showOnlyFrequentlyUsedReports)) ||
                                ((!this.isReturnInReadOnlyMode && y.selected) || (this.isReturnInReadOnlyMode && y.selected && y.report.hasReturnFile))) {
                                acc.push(y);
                            }
                        });
                    });
                }
                _.flow([
                    sortBy(['parcelAcctNumDisplay']),
                    forEach(x => {
                        acc.push(x);
                        x.forms.forEach(y => acc.push(y));
                    })
                ])(r.merged);
                return acc;
            }, []);
    }

    private _onSelectedFormListItemChanged(navigateToOverride: Compliance.ReturnFormOverrideDetailModel = null): void {
        if (this.selectedReturnId && (this.selectedReturnFormRevisionId || this.selectedReturnFormRevisionReportId)) {
            this._returnPreviewService.setReturnForm({
                mergeParcelId: this.selectedMergeParcelId,
                returnId: this.selectedReturnId,
                returnFormRevisionId: this.selectedReturnFormRevisionId,
                returnFormRevisionReportId: this.selectedReturnFormRevisionReportId,
                formRevisionId: this.selectedFormRevisionId,
                reportId: this.selectedReportId,
                isExcel: this.selectedIsExcel
            } as ReturnFormModel, navigateToOverride);
        } else this._returnPreviewService.setReturnForm(null);
    }

    private _createComparisonMap(comparison: Compliance.ReturnCompareFormsResultModel): void {
        this.returnNotDefaultMap = comparison.customReturns.reduce((acc, r) => { acc[r.returnId] = true; return acc; }, {});
    }
}
