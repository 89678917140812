import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';


@Component({
    selector: 'accruals-panel',
    templateUrl: './accrualsPanel.component.html'
})
export class AccrualsPanelComponent {
    constructor(public featureFlagService: FeatureFlagsService) {}

    @Input() parcelId: number;
    @Input() hasEditPermission: boolean;

    showBody: boolean = false;
    showBalance: boolean = false;
    adjustmentsEditMode: boolean = false;

    expandOrCollapse(): void {
        this.showBody = !this.showBody;
    }
}
