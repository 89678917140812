import { Injectable } from '@angular/core';
import { SDHttpService } from '../Routing/sd-http.service';
import { HistoryResultWrapper, HistoryOperation } from './change-history.model';

@Injectable(
    { providedIn: 'root' }
)
export class ChangeHistoryService {

    constructor(private http: SDHttpService) { }

    getChangeHistoryByEntity(id: number, entityTypeId: number, subType: string, startDateTime: Date, endDateTime: Date): Promise<HistoryOperation[]> {
        const config = {
            params: {
                startDateTime: startDateTime,
                endDateTime: endDateTime
            }
        };

        let subTypeArg = '';
        if (subType) {
            subTypeArg = `/${  subType}`;
        }

        return this.http.get(`/api/history/entityType/${  entityTypeId  }${subTypeArg  }/entity/${  id}`, config).then((h: HistoryResultWrapper) => {
            return h.results;
        });
    }
}
