import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';

export interface ICellRendererParamsForFormScheduleListGridAction extends ICellRendererParams {
    canDelete(params: ICellRendererParamsForFormScheduleListGridAction): boolean;
    canUndoDelete(params: ICellRendererParamsForFormScheduleListGridAction): boolean;
    delete: (params: ICellRendererParamsForFormScheduleListGridAction) => void;
    undoDelete: (params: ICellRendererParamsForFormScheduleListGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button *ngIf="params.canDelete(params)" class="ace-btn has-icon btn-primary btn-danger grid-action-button" title="Delete" (click)="params.delete(params)">
        <i class="fa fa-trash"></i>
    </button>
    <button *ngIf="params.canUndoDelete(params)" class="ace-btn has-icon success-button grid-action-button" title="Undo Delete" (click)="params.undoDelete(params)">
        <i class="fa fa-undo"></i>
    </button>
    `
})
export class FormScheduleListGridActionCellRendererComponent implements ICellRendererAngularComp {
    private _editModeSub: Subscription;

    params: ICellRendererParamsForFormScheduleListGridAction;

    agInit(params: ICellRendererParamsForFormScheduleListGridAction): void {
        this.params = params;
    }

    ngOnDestroy(): void {
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
