// Note that only the functions that happen to be needed in TypeScript are
import { DocumentExceptionActions } from '../constants.new';
// included here; the processing service in JavaScript has many more functions
// that can be included here as needed
import { ProcessAppealWarrantedForm } from './Process-Appeal-Warranted-Modal/processAppealWarranted.component';
import { ProcessNoAppealForm } from './Process-No-Appeal-Modal/processNoAppeal.component';

export abstract class ProcessingService {
    abstract changeDueDate(taskIDs: number[], dueDate: Date, searchTimeStamp: Date): Promise<any>;
    abstract getEntityTree(entityTypeId: number, entityId : number, extended: boolean): Promise<Core.EntityTree>;
    abstract getResultTypesByDocumentType(documentType: string): any[];
    abstract processAppealWarranted(payload: ProcessAppealWarrantedForm): Promise<Core.BulkOperationResult[]>;
    abstract processNoAppealWarranted(payload: ProcessNoAppealForm): Promise<Core.BulkOperationResult[]>;
    abstract getAppealLevels(taskIDs: number[]): Promise<any>;
    abstract processPaymentReviewed(payload: { taskIDs: number[], searchTimestamp: Date }): Promise<any>;
    abstract createDuplicateIntakeItemBulk(duplicate: any): Promise<any>;
    abstract createDuplicateIntakeItem(duplicate: any): Promise<any>;
    abstract completeFileAppeal(payload: any): Promise<any>;
    abstract getBulkDocumentExceptionData(taskIDs: number[]): Promise<any>;
    abstract processDocumentBulkException(payload: any): Promise<any>;
    abstract performOCR(taskIDs: number[]): Promise<any>;
    abstract processDocumentException(documentIntakeID: number, taskID: number, selectedEntity: any,
    actionType: DocumentExceptionActions, getNewAssignment, commentBody: string, newDocumentTypeID: number,
    dataEntryJSON: string, rowVersion, parcelChanges): Promise<any>;
}
