<div class="field-overrides"
     *ngIf="canPreview && (overrideHolder.overrides.length || (unsaved.length))">
    <div *ngIf="unsaved.length">
        <div class="ws-flex-container-horizontal ws-stretch-width override-controls">
            <button class="ace-btn btn-secondary has-icon"
                    helpTooltip
                    helpContentId="app.reset"
                    (click)="resetClicked.emit()">
                <i class="fa fa-undo"></i>
            </button>
            <button class="ace-btn btn-secondary has-icon"
                    helpTooltip
                    helpContentId="app.save"
                    (click)="saveClicked.emit()">
                <i class="fa fa-save"></i>
            </button>
        </div>
        <div class="unsaved-overrides">
            <h4>Unsaved Overrides ({{unsaved.length}})</h4>
            <div class="override-list"
                 helpTooltip
                 helpContentId="return-preview-form-viewer.existing-override"
                 [helpDisabled]="!override.isExisting"
                 *ngFor="let override of unsaved; index as i"
                 (click)="scrollToElement.emit(override.formElements[0])">
                <span class="number" [class.existing]="override.isExisting">{{override.index}}</span> -
                <span [class.existing]="override.isExisting">{{ (override.overrideValue ? override.overrideValue : '(value cleared)') }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="overrideHolder.overrides.length" class="saved-overrides">
        <h4>Form Overrides ({{overrideHolder.overrides.length}})</h4>
        <div class="override-list"
             *ngFor="let override of overrideHolder.overrides; index as i"
             (click)="scrollToElement.emit(override.formElements[0])">
            <span class="number">{{i + 1}}</span> - {{ (override.overrideValue ? override.overrideValue : '(value cleared)') }}
        </div>
    </div>
</div>
