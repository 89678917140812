import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../AgGrid';
import { AllocationRepository } from '../../../Repositories';

export interface RetrieveAssetsDataSourceParams {
    allocationId: number;
}

export class RetrieveAssetsAgGridDataSource extends AgGridDataSourceBase<RetrieveAssetsDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _allocationRepository: AllocationRepository,
        protected _dataSourceParamsFn: () => RetrieveAssetsDataSourceParams
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.AllocationCompanyAssetPropertyEnum>[] = [
        { name: 'assetNumber', value: Compliance.AllocationCompanyAssetPropertyEnum.AssetNumber },
        { name: 'siteName', value: Compliance.AllocationCompanyAssetPropertyEnum.SiteName },
        { name: 'siteNumber', value: Compliance.AllocationCompanyAssetPropertyEnum.SiteNumber },
        { name: 'parcel', value: Compliance.AllocationCompanyAssetPropertyEnum.Parcel },
        { name: 'description', value: Compliance.AllocationCompanyAssetPropertyEnum.Description },
        { name: 'classification', value: Compliance.AllocationCompanyAssetPropertyEnum.Classification },
        { name: 'acqDate', value: Compliance.AllocationCompanyAssetPropertyEnum.AcqDate },
        { name: 'reportableCost', value: Compliance.AllocationCompanyAssetPropertyEnum.ReportableCost },
        { name: 'assessor', value: Compliance.AllocationCompanyAssetPropertyEnum.Assessor },
        { name: 'state', value: Compliance.AllocationCompanyAssetPropertyEnum.State },
        { name: 'allocated', value: Compliance.AllocationCompanyAssetPropertyEnum.Allocated },
        { name: 'lastReturn', value: Compliance.AllocationCompanyAssetPropertyEnum.LastReturn }
    ];

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.SearchModel<Compliance.AllocationCompanyAssetPropertyEnum> = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        return await lastValueFrom(this._allocationRepository.getCompanyAssetIds(this._dataSourceParams.allocationId, searchParams));
    }

    protected canGetRows(): boolean {
        return !!this._dataSourceParams.allocationId;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.SearchModel<Compliance.AllocationCompanyAssetPropertyEnum> = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._allocationRepository.getCompanyAssets(this._dataSourceParams.allocationId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }


    destroy?(): void { return null; }
}
