<ws-modal-wrapper [headerLabel]="'Taxing Districts'"
                  [cancelLabel]="(editMode) ? 'Cancel' : 'Close'"
                  [hideSave]="!editMode"
                  [cancelClass]="editMode ? 'btn-primary btn-danger' : 'btn-primary'"
                  [disableSave]="!isValid"
                  [suppressKeyboardEvents]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <div class="ws-flex-container-horizontal ws-flex-align-items-center">
        <ag-grid-row-count [gridOptions]="gridOptions" [prefix]="null" [suffix]="null" class="ws-flex-auto"></ag-grid-row-count>
        <button type="button"
                *ngIf="editMode"
                class="ace-btn btn-sm btn-primary ws-flex-none"
                (click)="add()"
                helpTooltip
                helpContentId="app.add"
                position="top">
            Add Taxing District
        </button>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</ws-modal-wrapper>
