import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TaxRatesService {
    constructor (private http: HttpClient) {
    }

    private isGroupByEnabled: boolean;

    setIsGroupByEnabled(isGroupByEnabled: boolean) {
        this.isGroupByEnabled = isGroupByEnabled;
    }

    getIsGroupByEnabled() {
        return this.isGroupByEnabled;
    }
}
