<form [formGroup]="instanceForm">
    <ws-modal-wrapper headerLabel="New Instance"
                      acceptLabel="Add"
                      (save)="addInstance()"
                      [suppressKeyboardEvents]="true"
                      (cancel)="cancel()"
                      [disableSave]="instanceForm.invalid"
                      [tabindex]="6">

        <div class="mb-4">
            <label>Instance Name:</label>
            <input name="instanceName" type="text" formControlName="instanceName" class="form-control" placeholder="Enter Name" maxlength="50" tabindex="1">
            <p *ngIf="instanceForm.controls.instanceName.errors && !instanceForm.controls.instanceName.pristine" class="help-block">
                Instance name is required
            </p>
        </div>
        <div class="mb-4">
            <label>Instance Type:</label>
            <select name="instanceType" class="form-select form-control" formControlName="instanceType" tabindex="1">
                <option *ngFor="let type of instanceTypes" [ngValue]="type.instanceTypeId">{{type.instanceTypeName}}</option>
            </select>
            <p *ngIf="instanceForm.controls.instanceType.errors && !instanceForm.controls.instanceType.pristine" class="help-block">
                Instance type is required
            </p>
        </div>
        <div class="mb-4">
            <label>Company Name:</label>
            <input name="companyName" type="text" formControlName="companyName" class="form-control" placeholder="Enter Company Name" maxlength="50" tabindex="1">
            <p *ngIf="instanceForm.controls.companyName.errors && !instanceForm.controls.companyName.pristine" class="help-block">
                Company name is required
            </p>
        </div>
        <div class="mb-4">
            <label>Company Code:</label>
            <input name="companyCode" type="text" formControlName="companyCode" class="form-control" placeholder="Enter Company Code" maxlength="50" tabindex="1">
            <p *ngIf="instanceForm.controls.companyCode.errors && !instanceForm.controls.companyCode.pristine" class="help-block">
                Company code is required
            </p>
        </div>
        <div class="mb-4">
            <label>Team Name:</label>
            <input name="teamName" type="text" formControlName="teamName" class="form-control" placeholder="Enter Team Name" maxlength="50" tabindex="1">
            <p *ngIf="instanceForm.controls.teamName.errors && !instanceForm.controls.teamName.pristine" class="help-block">
                Team name is required
            </p>
        </div>
    </ws-modal-wrapper>
</form>
