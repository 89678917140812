import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForLinkedSitesListGridAction extends ICellRendererParams {
    canRemove: (params: ICellRendererParamsForLinkedSitesListGridAction) => boolean;
    remove: (params: ICellRendererParamsForLinkedSitesListGridAction) => void;
    toggleMaster: (params: ICellRendererParamsForLinkedSitesListGridAction) => void;
    getMasterTitle: (params: ICellRendererParamsForLinkedSitesListGridAction) => string;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button class="ace-btn has-icon btn-primary grid-action-button"
        [title]="params.getMasterTitle(params)"
        (click)="params.toggleMaster(params)">
        <i class="fa fa-star"></i>
    </button>
    <button class="ace-btn has-icon btn-primary btn-danger grid-action-button"
        title="Remove Link"
        [disabled]="!params.canRemove(params)"
        (click)="params.remove(params)">
        <i class="fa fa-times"></i>
    </button>
    `
})
export class LinkedSitesListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForLinkedSitesListGridAction;

    agInit(params: ICellRendererParamsForLinkedSitesListGridAction): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
