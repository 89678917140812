import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChangeHistoryService } from './change-history.service';
import { HistoryOperation, HistoryFieldType, OperationType, CSRChangeValueProperties, TaxAuthorityChangeProperties, HistoryChange } from './change-history.model';
import { EntityType, EmptyGuid } from '../../constants.new';
// We tried importing this from bower_components, but there are two problems with that:
// 1. This bloats the client library loading (since it has to load moment in two bundles)
// 2. Moment-timezone isn't available (it doesn't have TypeScript definitions built in)
// TODO: Import moment and moment-timezone from TypeScript and export it as a global for JavaScript to use
//import * as moment from '../../../../bower_components/moment/moment';
declare const moment: any;

const momentDateFormat: string = 'MM/DD/YYYY h:mm a';

@Component({
    selector: 'change-history-modal',
    templateUrl: './change-history-modal.component.html'
})
export class ChangeHistoryModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef,
        private changeHistoryService: ChangeHistoryService) { }

    operations: HistoryOperation[];
    loading: boolean = false;
    NullUserId: string = EmptyGuid;
    HistoryFieldType = HistoryFieldType;
    ActivityStatus: string[] = ['Inactive', 'Active Pending', 'Active'];
    PropertyType: string[] = [null, 'Real Estate', 'Personal', 'Mineral', 'CentrallyAssessed'];
    id: number;
    entityTypeId: number;
    subType: string;
    title: string;
    startDateTime: Date = null; //moment().subtract(1, 'month').toDate();
    endDateTime: Date = null; //moment().toDate();
    OperationType = OperationType;
    originalTitle: string;
    originalTitlePresent: boolean;

    ngOnInit() {
        this.getHistory();
    }

    async getHistory(): Promise<void> {
        this.loading = true;

        try {
            const result = await this.changeHistoryService.getChangeHistoryByEntity(this.id, this.entityTypeId, this.subType, this.startDateTime, this.endDateTime);

            this.operations = result.map(r => {
                r.changes = r.changes.map(c => {
                    if (c.historyFieldType === HistoryFieldType.TaxAuthority) {
                        c.oldValue = this.deserializeTaxAuthorityValue(c.oldValue);
                        c.newValue = this.deserializeTaxAuthorityValue(c.newValue);
                    }
                    else if(c.fieldName === 'Site Name' || c.fieldName === 'Parcel Acct Num') {
                         this.originalTitlePresent = true;
                        if(c.oldValue === null && c.newValue !== null) {
                            this.originalTitle = c.newValue;
                        }
                    }

                    return c;
                });
                return r;
            });
            if(this.originalTitlePresent && !this.originalTitle) {
              for(let i=result.length-1; i>=0; i--) {
                const tmpResult = result[i].changes.filter(r => (r.fieldName === 'Site Name' || r.fieldName === 'Parcel Acct Num')
                && (r.oldValue !==null && r.newValue !== null ));
                if(tmpResult && tmpResult.length > 0){
                    this.originalTitle = tmpResult[0].oldValue;
                    break;
                }
              }
            }
            if(!this.originalTitle) {
                this.originalTitle = this.title;
            }
            // HACK: Make a digest cycle fire (no idea why this isn't always happening anyway; see WK-6030)
            (window as any).$('.fa-spinner:visible').click();
        } finally {
            this.loading = false;
        }

    }

    calendarDisplayDate(date: string): string {
        // Central time
        return moment(date).tz('America/Chicago').calendar(null, {
            sameElse: momentDateFormat
        });
    }

    displayDate(date: string): string {
        return moment(date).tz('America/Chicago').format(momentDateFormat);
    }

    isCalendarDisplay(date: string): boolean {
        // Check if the calendarDisplayDate function produces the format string or something else (like "moments ago")
        return !moment(this.calendarDisplayDate(date), momentDateFormat).isValid();
    }

    getDisplayValue(value: any, historyFieldTypeId: number): string {
        switch(historyFieldTypeId) {
            case HistoryFieldType.ActivityStatus:
                return this.ActivityStatus[value];
            case HistoryFieldType.PropertyType:
                return this.PropertyType[value];
            case HistoryFieldType.TaxAuthority:
                const valueLength = (<any[]>value).length;
                return valueLength === 1 ? '1 Tax Authority' : (`${valueLength  } Tax Authorities`);
            default:
                return value;
        }
    }

    getExpandedDisplayValue(value: any, historyFieldTypeId: number): string {
        switch(historyFieldTypeId) {
            case HistoryFieldType.TaxAuthority:
                return (<TaxAuthorityChangeProperties[]>value).map(v => {
                    if (v.taxAuthorityCode) {
                        return `${v.taxAuthorityName} (${v.taxAuthorityCode})`;
                    }
                    return v.taxAuthorityName;
                }).join('\n');
            default:
                return value;
        }
    }

    resolveCSRRelationship(csrProperties: CSRChangeValueProperties): string {
        if (csrProperties.relatedEntityType === EntityType.Site) {
            return 'site';
        }
        else if (csrProperties.relatedEntityType == EntityType.Company) {
            return csrProperties.isTopLevel ? 'top-level company' : 'company';
        }
    }

    closeModal(): void {
        this.bsModalRef.hide();
    }

    private deserializeTaxAuthorityValue(value: string): TaxAuthorityChangeProperties[] {
        if (value === null || value === undefined) {
            return null;
        }

        const parsed = <{
            TaxAuthorityId: number,
            TaxAuthorityName: string,
            TaxAuthorityCode: string
        }[]>JSON.parse(value);

        if (parsed.length === 0) {
            return null;
        }

        return parsed.map(p => {
            return {
                taxAuthorityId: p.TaxAuthorityId,
                taxAuthorityName: p.TaxAuthorityName,
                taxAuthorityCode: p.TaxAuthorityCode,
            };
        });
    }
}