import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { Address } from '../Common/Models/common.model';

@Component({
    selector: 'site-map-info-window',
    template: `
        <div class="text-center" *ngIf="!siteMarkerInfoArr.length" style="padding: 15px;">
            <i class="fa fa-spinner fa-pulse fa-4x"></i>
        </div>
        <div *ngIf="siteMarkerInfoArr.length">
            <select *ngIf="siteMarkerInfoArr.length > 1" class="form-select-sm" [(ngModel)]="selectedMarkerInfo">
                <option *ngFor="let site of siteMarkerInfoArr" [ngValue]="site">{{site.siteName}}</option>
            </select>
            <h5>{{selectedMarkerInfo.companyName}}</h5>
            <h4><a class="pointer" (click)="navigateToSite()">{{selectedMarkerInfo.siteName}}</a></h4>
            <table class="site-map-info">
                <tr>
                    <td>
                        <div><span *ngFor="let type of getAbbrevs(selectedMarkerInfo.propertyTypeAbbrevs)" class="label label-primary property-type-{{type}}" style="margin-left: 3px;">{{type}}</span></div>
                        <div style="margin-top: 5px;"><em>{{selectedMarkerInfo.siteClassPrimaryDesc}}</em></div>
                    </td>
                    <td>
                        <address-view-edit class="map-site-address" [address]="address"></address-view-edit> <br>
                    </td>
                </tr>
                <tr>
                    <td>
                        lat: {{selectedMarkerInfo.address.latitude}} <br>
                        lng: {{selectedMarkerInfo.address.longitude}}
                    </td>
                    <td>
                        SqFt: {{selectedMarkerInfo.totalSqFt | number:'1.0-0'}} <br>
                        Units: {{selectedMarkerInfo.totalUnits | number:'1.0-0'}}
                    </td>
                </tr>
            </table>
        </div>
    `,
    styleUrls: ['./site.map.component.scss']
})
export class SiteMapInfoWindowComponent implements OnChanges {
    constructor(private _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler){}

    @Input() siteMarkerInfoArr: Core.SiteMarkerInfo[];
    selectedMarkerInfo: Core.SiteMarkerInfo;

    get address() {
        return this.selectedMarkerInfo.address as unknown as Address;
    }

    ngOnChanges() {
        if(this.siteMarkerInfoArr.length) {
            this.selectedMarkerInfo = this.siteMarkerInfoArr[0];
        }
    }

    getAbbrevs(propertyTypeAbbrevs: string): string[] {
        return _.words(propertyTypeAbbrevs);
    }

    navigateToSite(): void {
        const url = this._upgradeNavigationServiceHandler.getHrefFromState('siteRedirect', {entityID: this.selectedMarkerInfo.siteId});

        window.open(url, '_blank');
    }

}
