<div *ngIf="!viewModel.model" class="text-center">
    <div *ngIf="filteredCompanyBudgets.length > 0">
        <div class="lead">There are presently no budgets for {{this.currentYear.annualYear1}}</div>
        <div style="display: inline-block; vertical-align: middle; margin-right: 10px;">
            <select class="form-select form-select-sm" [(ngModel)]="selectedBudgetToAdd" style="width: auto; display: inline-block; margin-left: 5px;">
                <option disabled [ngValue]="undefined">----- select -----</option>
                <option *ngFor="let cbudget of overlappingCompanyBudgets" [ngValue]="cbudget">{{cbudget.budgetName}}</option>
                <option *ngIf="overlappingCompanyBudgets.length > 0 && nonOverlappingCompanyBudgets.length > 0" disabled [ngValue]="undefined">------------------</option>
                <option *ngFor="let cbudget of nonOverlappingCompanyBudgets" [ngValue]="cbudget">{{cbudget.budgetName}}</option>
        </select>
        </div>
        <button class="ace-btn btn-primary" *ngIf="editState.hasWritePermission" [disabled]="!selectedBudgetToAdd" (click)="addNewBudget()">New Budget</button>
    </div>
    <div *ngIf="filteredCompanyBudgets.length === 0">
        <div class="lead">There are presently no budgets available to add</div>
    </div>
</div>
<div class="budget-details" *ngIf="viewModel.model">
    <div style="display: flex; justify-content: space-between;">
        <div>
            <label>Budget:</label>
            <select class="form-select form-select-sm" [(ngModel)]="selectedBudget" style="width: auto; display: inline-block; margin-left: 5px;" (change)="selectedBudgetChanged()" [disabled]="editState.editMode">
                <option *ngFor="let budget of availableBudgets" [ngValue]="budget">{{budget.budgetName}}</option>
            </select>
            <span *ngIf="selectedBudget.isFrozen" style="margin-left: 10px; color:blue; font-size:18px; font-weight:bold;">FROZEN</span>
        </div>
        <div *ngIf="filteredCompanyBudgets.length > 0">
            <div style="display: inline-block; vertical-align: middle; margin-right: 5px;">
                <select [hidden]="!editState.editMode" class="form-select form-select-sm" [(ngModel)]="selectedBudgetToAdd" style="width: auto; display: inline-block; margin-left: 5px;">
                    <option disabled [ngValue]="undefined">----- select -----</option>
                    <option *ngFor="let cbudget of overlappingCompanyBudgets" [ngValue]="cbudget">{{cbudget.budgetName}}</option>
                    <option *ngIf="overlappingCompanyBudgets.length > 0 && nonOverlappingCompanyBudgets.length > 0" disabled [ngValue]="undefined">------------------</option>
                    <option *ngFor="let cbudget of nonOverlappingCompanyBudgets" [ngValue]="cbudget">{{cbudget.budgetName}}</option>
                    </select>
            </div>
            <button [hidden]="!editState.editMode" class="ace-btn btn-sm btn-primary" style="margin-left: 5px;" [disabled]="!selectedBudgetToAdd" (click)="addNewBudget()">New Budget</button>
        </div>
        <div style="display: flex; align-items: flex-start;">
            <button [hidden]="!editState.editMode || selectedBudget.isFrozen" class="ace-btn btn-sm btn-primary btn-danger" (click)="deleteBudget()">
                <i class="fa fa-trash"></i>
            </button>
            <entity-attachments buttonClass="ace-btn btn-sm btn-primary"
                [(hasAttachments)]="viewModel.model.hasAttachments"
                [modalData]="viewModel.attachmentModalData">
            </entity-attachments>
            <entity-comments buttonClass="ace-btn btn-sm btn-primary"
                [(hasComments)]="viewModel.model.hasComments"
                [modalData]="viewModel.commentModalData" >
            </entity-comments>
        </div>
    </div>

    <div class="budget-first-row">
        <div class="card panel-flat-color secondary-panel small-panel" style="margin-bottom: 0px;">
            <div class="panel-flat-header small-header">
                <h3>Budget Assessment</h3>
            </div>
            <div class="card-body">
                <table class="table table-condensed budget-assessment-table">
                    <thead>
                        <th style="text-align: left;">Component</th>
                        <th>AV</th>
                        <th>Ratio</th>
                        <th>FMV</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let component of standardAssessmentComponents">
                           <td style="text-align: left;">{{component.assessmentComponentName}}</td>
                           <td>{{component.assessedValue | number:'1.0-0'}}</td>
                           <td>{{component.ratio | percent:'1.3-3'}}</td>
                           <td>{{getFairMarketValue(component) | number:"1.0-0"}}</td>
                        </tr>
                        <tr class="budget-total-row">
                            <td style="text-align: left;">Total:</td>
                            <td>{{budgetAssessmentTotals.av | number:'1.0-0'}}</td>
                            <td></td>
                            <td>{{budgetAssessmentTotals.fmv | number:'1.0-0'}}</td>
                        </tr>
                        <tr *ngIf="alternativeAssessment" class="budget-alternate-row">
                            <td style="text-align: left;">{{alternativeAssessment.assessmentComponentName}}</td>
                            <td>{{alternativeAssessment.assessedValue | number:'1.0-0'}}</td>
                            <td>{{alternativeAssessment.ratio | percent:'1.3-3'}}</td>
                            <td>{{getFairMarketValue(alternativeAssessment) | number:"1.0-0"}}</td>
                         </tr>
                     </tbody>
                </table>
            </div>
        </div>
        <div style="padding-left: 25px;">
            <div>
                <label>Description:</label>
                <p *ngIf="!editState.editMode">{{viewModel.model.description}}</p>
                <textarea *ngIf="editState.editMode" maxlength="255" [(ngModel)]="viewModel.model.description" class="form-control" rows="4" cols="50"></textarea>
            </div>
        </div>
    </div>
    <div class="card panel-flat-color secondary-panel small-panel">
        <div class="panel-flat-header small-header" style="display: flex; justify-content: space-between;">
            <h3>Budget Taxes</h3>
            <div class="ws-flex-container-horizontal ws-flex-align-items-center tax-rates-form-items">
                <label class="ws-flex-auto" style="margin-bottom: 0;">View:</label>
                <div class="radio">
                    <label>
                        <input type="radio" value="summary" [(ngModel)]="selectedBudgetTaxesView"> Summary
                    </label>
                </div>
                <div class="radio" style="margin-top: 10px;">
                    <label>
                        <input type="radio" value="detail" [(ngModel)]="selectedBudgetTaxesView"> Payment Detail
                    </label>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table *ngIf="selectedBudgetTaxesView === 'summary'" class="table table-condensed budget-assessment-table">
                <thead>
                    <th style="text-align: left;">Tax Collector</th>
                    <th>Eff. Rate</th>
                    <th>Total</th>
                    <th *ngFor="let dueDate of paymentDueDates"><weissman-datetime [readOnly]="true" [(inputDate)]="dueDate.date" [dateOnly]="true"></weissman-datetime></th>
                </thead>
                <tbody>
                    <tr *ngFor="let collector of viewModel.model.taxCollectors">
                       <td style="text-align: left;">{{collector.collectorName}}</td>
                       <td>{{collector.effectiveTaxRate | percent:'1.6-6'}}</td>
                       <td>{{collector.totalPayment | currency: 'USD':'symbol-narrow'}}</td>
                       <td *ngFor="let dueDate of paymentDueDates">{{getCollectorPaymentAmount(collector.taxPayments, dueDate.date) | currency: 'USD':'symbol-narrow'}}</td>
                    </tr>
                    <tr class="budget-total-row">
                        <td style="text-align: left;">Total:</td>
                        <td></td>
                        <td>{{totalPaymentAmount | currency: 'USD':'symbol-narrow'}}</td>
                        <td *ngFor="let dueDate of paymentDueDates">{{dueDate.total | currency: 'USD':'symbol-narrow'}}</td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="selectedBudgetTaxesView === 'detail'" class="table table-condensed budget-assessment-table">
                <thead>
                    <th style="text-align: left;">Tax Collector</th>
                    <th style="text-align: center;">Payment Due</th>
                    <th>Payment Amount</th>
                    <th style="text-align: center;">Tax Period Begin</th>
                    <th style="text-align: center;">Tax Period End</th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let collector of viewModel.model.taxCollectors">
                        <tr *ngFor="let payment of collector.taxPayments; let i=index;">
                           <td style="text-align: left;">{{collector.collectorName}} <span *ngIf="collector.taxPayments.length > 1">- Payment {{i+1}} of {{collector.taxPayments.length}}</span></td>
                           <td style="text-align: center;"><weissman-datetime [readOnly]="true" [(inputDate)]="payment.dueDate" [dateOnly]="true"></weissman-datetime></td>
                           <td>{{payment.paymentAmount | currency: 'USD':'symbol-narrow'}}</td>
                           <td style="text-align: center;"><weissman-datetime [readOnly]="true" [(inputDate)]="payment.taxPeriodBegin" [dateOnly]="true"></weissman-datetime></td>
                           <td style="text-align: center;"><weissman-datetime [readOnly]="true" [(inputDate)]="payment.taxPeriodEnd" [dateOnly]="true"></weissman-datetime></td>
                        </tr>
                    </ng-container>
                    <tr class="budget-total-row">
                        <td style="text-align: left;">Total:</td>
                        <td></td>
                        <td>{{totalPaymentAmount | currency: 'USD':'symbol-narrow'}}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
