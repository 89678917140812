import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './Click-Outside-Directive/clickOutside.directive';
import { DraggableDirective } from './Draggable/draggable.directive';
import { AnchorRouteDirective } from './Anchor-Route/anchorRoute.directive';
import { NumbersOnlyDirective } from './Numbers-Only/numbersOnly.directive';
import { InputMaskDirective } from './Input-Mask/inputMask.directive';
import { ResizableDirective } from './Resizable/resizable.directive';
import { ToPercentDirective } from './To-Percent/toPercent.directive';
import { LineLimitDirective } from './Line-Limit/lineLimit.directive';
import { DecimalNumberDirective } from './Decimal-Number-Input/decimal-number-input.directive';
import { NumberDirective } from './Number-Input/number-input.directive';
import { DragAndDropListModule } from './Drag-And-Drop-List/dragAndDropList.module';

@NgModule({
    imports: [
        CommonModule,
        DragAndDropListModule
    ],
    declarations: [
        ClickOutsideDirective,
        DraggableDirective,
        AnchorRouteDirective,
        NumbersOnlyDirective,
        InputMaskDirective,
        LineLimitDirective,
        ResizableDirective,
        ToPercentDirective,
        DecimalNumberDirective,
        NumberDirective
    ],
    exports: [
        ClickOutsideDirective,
        DraggableDirective,
        AnchorRouteDirective,
        NumbersOnlyDirective,
        InputMaskDirective,
        LineLimitDirective,
        ResizableDirective,
        ToPercentDirective,
        DecimalNumberDirective,
        NumberDirective
    ]
})
export class DirectivesModule { }
