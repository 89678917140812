<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3 *ngIf="!assessorId">Tax Rates</h3>
        <h3 *ngIf="assessorId">Tax Rate Areas</h3>
        <div class="float-end header-button-container" *ngIf="showBody" (click)="$event.stopPropagation()">
            <!-- <ag-grid-filter-toggle [gridOptions]="gridOptions"></ag-grid-filter-toggle> -->
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isMaximized" (click)="toggleMaximize(true)">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isMaximized" (click)="toggleMaximize(false)">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body ws-flex-container-vertical" [ngClass]="{'tax-rates-maximized': isMaximized}" *ngIf="showBody">
        <div class="ws-flex-container-horizontal" style="justify-content: space-around;">
            <div *ngIf="!assessorId" class="ws-flex-container-horizontal ws-flex-align-items-center tax-rates-form-items">
                <label class="ws-flex-auto" style="margin-bottom: 0;">Show:</label>
                <div class="radio">
                    <label>
                        <input type="radio" name="taxAuthorityOrRateAreaRadioButton" value="authorities" [(ngModel)]="selectedGridSourceOption" (ngModelChange)="selectedGridSourceChanged()"> Tax Authorities
                    </label>
                </div>
                <div class="radio" style="margin-top: 10px;">
                    <label>
                        <input type="radio" name="taxAuthorityOrRateAreaRadioButton" value="areas" [(ngModel)]="selectedGridSourceOption" (ngModelChange)="selectedGridSourceChanged()"> Tax Rate Areas
                    </label>
                </div>
            </div>
            <show-tax-year [(taxYearBegin)]="taxRatesPanelService.taxYearBegin" [(taxYearsPrior)]="taxRatesPanelService.taxYearsPrior" (showTaxYearChanged)="showTaxYearChanged()"></show-tax-year>
        </div>
        <div class="tax-rates-grid">
            <ws-ag-grid-angular [gridOptions]="gridOptions"
                                (gridReady)="onAgGridReady($event)">
            </ws-ag-grid-angular>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-align-items-center" style="justify-content: space-between; margin-top: 20px;">
            <div *ngIf="hasTaxRatePermission">
                <button *ngIf="selectedGridSourceOption === 'areas'" type="button" class="ace-btn btn-primary" (click)="addTaxRateArea()">Add New Tax Rate Area</button>
                <button *ngIf="selectedGridSourceOption === 'authorities'" type="button" class="ace-btn btn-primary" (click)="addTaxAuthority()">Add New Tax Authority</button>
            </div>
            <div *ngIf="hasTaxRatePermission">
                <a class="pointer" (click)="navigateToCommandCenter()">Tax Rate Command Center</a>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" [(ngModel)]="showDisabled" (ngModelChange)="showDisabledChanged()"> Show Disabled
                </label>
            </div>
        </div>
    </div>
</div>
