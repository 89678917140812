import { Component, forwardRef, Input, Renderer2, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectSearch } from '../Select/select.interface';
import { TimerService } from '../Utilities';

const noop = (values: any[], searchValue: string) => [];

/**
 * Multi-select dropdown
 *
 * Allows the user to select multiple items from the dropdown and displays a summary of the selections
 *
 * Tied into ControlValueAccessor for integration with native Angular FormControls and ngModel
 *
 * The input options can be any model. The properties to use as the selectors for each option in the dropdown are set using
 * labelProperty and valueProperty.
 *
 * Example usage:
 * <ws-multi-select labelProperty="displayName"
 *               valueProperty="property"
 *               placeholder="Please select a value..."
 *               [options]="options"
 *               [formControl]="additionalSelect.formControl">
 * </ws-multi-select>
 *
 */
@Component({
    selector: 'ws-multi-select',
    templateUrl: './multiSelect.component.html',
    styleUrls: ['./multiSelect.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectComponent),
            multi: true
        }
    ],
})
export class MultiSelectComponent implements ControlValueAccessor {
    constructor(private _timerService: TimerService,
                private _renderer2: Renderer2) {
    }

    @Input() placeholder: string;
    @Input() labelProperty: string = 'label';
    @Input() valueProperty: string = 'value';
    @Input() isPrimitive: boolean;
    @Input() objectIsValue: boolean;
    @Input() useSemicolons: boolean;
    @Input() isDropup: boolean;
    @Input() canSearch: boolean;
    @Input() searchType: 'exact' | 'fuzzy' | 'substring' | 'state' | 'custom' = 'fuzzy';
    @Input() set options(options: any[]) {
        this.dropdownOptions = options;
        this.searchableOptions = [...options];
        this._updateValue(this.value);
    }
    @Input() hideSelectAll: boolean = false;
    @Input() hasDeselectAll: boolean = false;

    @Output() selectedOptionsChanged: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('searchInput') searchInput: ElementRef;
    @ViewChild('dropdown') dropdown: ElementRef;

    value: any[];
    disabled: boolean;
    open: boolean;
    selectedSummary: string;
    dropdownOptions: any[] = [];
    searchableOptions: any[] = [];
    selectedOptions: any[] = [];

    searchValue: string;

    private _search: SelectSearch = {
        exact: (values: any[], searchValue: string) => values.filter(o => {
            const value = (`${(this.isPrimitive) ? o : o[this.labelProperty]}`).toLowerCase();
            return (value) ? value.substring(0, searchValue.length) === searchValue.toLowerCase() : false;
        }),
        fuzzy: (values: any[], searchValue: string) => {
            const pattern = searchValue.split('').reduce((a, b) => (`${a  }[^${  b  }]*${  b}`));
            const regex = new RegExp(pattern);
            return values.filter(o => {
                const value = (this.isPrimitive) ? o : o[this.labelProperty];
                return (value) ? regex.test((`${value}`).toLowerCase()) : false;
            }).sort((a, b) => {
                // Sort by most exact match first
                const valueA = (`${(this.isPrimitive) ? a : a[this.labelProperty]}`).toLowerCase();
                const valueB = (`${(this.isPrimitive) ? b : b[this.labelProperty]}`).toLowerCase();
                const aIncludes = valueA.includes(searchValue.toLowerCase());
                const bIncludes = valueB.includes(searchValue.toLowerCase());
                if (!aIncludes && bIncludes) {
                    return 1;
                } else if (aIncludes && !bIncludes) {
                    return -1;
                }
                return valueA.localeCompare(valueB);
            });
        },
        substring: (values: any[], searchValue: string) => values.filter(o => {
            const value = (`${(this.isPrimitive) ? o : o[this.labelProperty]}`).toLowerCase();
            return (value) ? value.includes(searchValue.toLowerCase()) : false;
        }),
        state: (values: { value: Core.StateModel }[], searchValue: string) => {
            const pattern = searchValue.split('').reduce((a, b) => (`${a  }[^${  b  }]*${  b}`));
            const regex = new RegExp(pattern);
            return values.filter(o => {
                return (o.value.fullName && regex.test(o.value.fullName.toLowerCase())) || (o.value.abbr && regex.test(o.value.abbr.toLowerCase()));
            }).sort((a, b) => {
                // Sort by state abbreviation exact match first
                const aMatch = a.value.abbr.toLowerCase() === searchValue.toLowerCase();
                const bMatch = b.value.abbr.toLowerCase() === searchValue.toLowerCase();
                if (!aMatch && bMatch) {
                    return 1;
                } else if (aMatch && !bMatch) {
                    return -1;
                }
                return a.value.fullName.localeCompare(b.value.fullName);
            });
        },
        custom: noop
    };

    onChange: (val: any[]) => void = () => { /* noop */};
    onTouched: () => void = () => { /* noop */};

    // Angular Form methods

    writeValue(value: any[]) {
        this._updateValue(value);
        this.value = value;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    next() {
        this.onChange(this.value = this.selectedOptions.map(o => this.isPrimitive || this.objectIsValue ? o : o[this.valueProperty]));
        this.selectedOptionsChanged.emit(this.value);
        this.onTouched();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    // Custom methods

    /**
     * Search the data
     */
    search(): boolean {
        const searchValue = (this.searchValue && this.searchValue.length) ? this.searchValue.trim().toLowerCase() : null;

        const options =  [...this.dropdownOptions];

        if (searchValue) {
            this.searchableOptions = (searchValue.length === 1)
                ? this._search.exact(options, searchValue)
                : this._search[this.searchType](options, searchValue);
        } else {
            this.searchableOptions = options;
        }

        return false;
    }

    selectAll(): boolean {
        if (this.disabled) {
            return;
        }
        this.selectedOptions = (this.selectedOptions.length === this.dropdownOptions.length) ? [] : [...this.dropdownOptions];
        this._setSummary();
        this.next();
        return false;
    }

    deselectAll(): boolean {
        this.selectedOptions = [];
        this._setSummary();
        this.next();
        return false;
    }

    optionSelected(option: any): boolean {
        if (this.disabled) {
            return;
        }
        if (!this.multiSelectOptionActive(option)) {
            const selectedOptionsEmpty = !this.selectedOptions.length;
            this.selectedOptions.push(option);
            if (selectedOptionsEmpty) {
                let scrollTop = this.dropdown.nativeElement.scrollTop <= 31 ? 1 : this.dropdown.nativeElement.scrollTop;
                if (this.dropdownOptions[0] === option) {
                    scrollTop = this.dropdown.nativeElement.scrollTop <= 31 ? 0 : this.dropdown.nativeElement.scrollTop;
                }
                this._renderer2.setProperty(this.dropdown.nativeElement, 'scrollTop', scrollTop);
            }
        } else {
            const i = this.selectedOptions.indexOf(option);
            this.selectedOptions.splice(i, 1);
        }
        this._setSummary();
        this.next();
        return false;
    }

    multiSelectOptionActive(option: any): boolean {
        return this.selectedOptions.indexOf(option) > -1;
    }

    toggleDropdown(): void {
        if (this.disabled) {
            return;
        }
        this.open = !this.open;
        if (this.open && this.canSearch) {
            this._timerService.setTimeout(() => {
                this.searchInput.nativeElement.focus();
                this._renderer2.setProperty(this.dropdown.nativeElement, 'scrollTop', 0);
            }, 0);
        }
    }

    closeDropdown(): void {
        this.open = false;
    }

    private _updateValue(value: any[]): void {
        if (!value) { return; }
        this.selectedOptions = this.dropdownOptions.filter(o => {
            return value.indexOf(this.isPrimitive || this.objectIsValue ? o : o[this.valueProperty]) > -1;
        });
        this._setSummary();
    }

    private _setSummary() {
        if (!this.selectedOptions || !this.selectedOptions.length) {
            this.selectedSummary = null;
            return;
        }

        const delimiter = this.useSemicolons ? '; ' : ', ';

        if (this.selectedOptions.length === 1) {
            const selection = this.isPrimitive && !this.objectIsValue ? this.selectedOptions[0] : this.selectedOptions[0][this.labelProperty];
            this.selectedSummary = `${selection}`;
        } else if (this.selectedOptions.length === 2) {
            const selected = this.selectedOptions.map(o => this.isPrimitive && !this.objectIsValue ? o : o[this.labelProperty]).join(delimiter);
            this.selectedSummary = `${this.selectedOptions.length} items (${selected})`;
        } else {
            this.selectedSummary = `${this.selectedOptions.length} items selected`;
        }
    }

}
