<div class="contact-list-page ws-flex-container-vertical ws-flex-auto">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                {{header}}
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div *ngIf="!(dialogMode || recipientMode)" class="ws-section__header__actions__item">
                    <label class="checkbox float-end">
                        <input type="checkbox"
                               [(ngModel)]="excludeInactive"
                               (change)="refresh()"> Exclude Inactive
                    </label>
                </div>
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="app.refresh"
                         position="bottom">
                        <button type="button" class="ace-btn btn-sm btn-secondary has-icon"
                                [disabled]="(!canGetRows)" (click)="refresh()"><i class="fa fa-refresh"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [gridId]="gridId"
                        [defaultSizeToFit]="true"
                        [exportOptions]="exportOptions"
                        [defaultSizeToContent]="false">
    </ws-ag-grid-angular>
</div>
