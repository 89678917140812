import { Component, Input } from '@angular/core';

export interface StateIcon {
    selector: Compliance.AssetChangeStatusEnum;
    iconClass: string;
    active: boolean;
    tooltipTitle: string;
}

@Component({
    selector: 'asset-change-icon',
    styleUrls: ['./assetChangeIcon.component.scss'],
    template: `
        <div class="asset-change-icon">
          <ul class="state-icons">
            <li *ngFor="let icon of activeIcons" helpTooltip [tooltipText]="icon.tooltipTitle">
                <i *ngIf="icon.active" class="state-icon fa" [ngClass]="icon.iconClass"></i>
            </li>
          </ul>
        </div>
    `
})
export class AssetChangeIconComponent {
    @Input()
    set icons(icons: StateIcon[]) {
        this.activeIcons = icons.filter(i => i.active);
    }

    activeIcons: StateIcon[] = [];
}
