<ws-modal-wrapper [headerLabel]="headerLabel" (cancel)="close()" acceptLabel="Save" (save)="save()">
    <loading-spinner [show]="loading" [size]="4" [hasOverlay]="true"></loading-spinner>
    <div class="row">
        <div class="col-lg-6">
            <div class="mb-3">
                <label>Classification</label>
                <input type="text" class="form-control" [(ngModel)]="ac.name" (ngModelChange)="updateEfAction(ac)" [disabled]="readOnly">
            </div>
        </div>
        <div class="col-lg-6">
            <div class="mb-3">
                <label>Notes</label>
                <textarea class="form-control" [(ngModel)]="ac.description" (ngModelChange)="updateEfAction(ac)" [disabled]="readOnly"></textarea>
            </div>
        </div>
    </div>
    <div class="card panel-flat-color primary-panel">
        <div class="panel-flat-header">
            <h3>Use this Assessment Class as Default for these Property Type(s)</h3>
        </div>
        <div class="panel-flat-body">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>Parcel Prop Type</th>
                        <th>Default</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let default of availableDefaults">
                        <td>{{default.propertyType.propTypeAbbr}}</td>
                        <td>
                            <input type="checkbox" [(ngModel)]="default.isDefault" (ngModelChange)="toggleDefault(default)" [disabled]="readOnly">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card panel-flat-color primary-panel">
        <div class="panel-flat-header">
            <h3>Components Available by Property Class</h3>
        </div>
        <div class="panel-flat-body">
            <div style="margin-bottom: 20px;">
                <ws-select [hidden]="readOnly"
                            placeholder="Add Component"
                            [(ngModel)]="chosenComponent"
                            (selectedOptionChanged)="addComponent()"
                            [options]="availableCombos"
                            labelProperty="name"
                            [returnEntireOption]="true">
                </ws-select>
            </div>
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th>Parcel Prop Type</th>
                        <th style="text-align: left;">Component</th>
                        <th>Enabled</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let comp of undeletedComponents">
                        <td>{{comp.propertyType.propTypeAbbr}}</td>
                        <td style="text-align: left;">{{comp.assessmentComponent.componentName}}</td>
                        <td>
                            <input type="checkbox" [(ngModel)]="comp.enabled" (ngModelChange)="onEnabledChange(comp)" [disabled]="readOnly">
                        </td>
                        <td>
                            <button class="ace-btn has-icon btn-primary btn-danger" style="margin: 0;" (click)="deleteComponent(comp)" [hidden]="readOnly">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="card panel-flat-color primary-panel">
        <div class="panel-flat-header">
            <h3>Ratios</h3>
        </div>
        <div class="panel-flat-body">
            <div class="checkbox">
                <label>
                    <input type="checkbox"
                        [checked]="!ac.ratioSetAtAssessor"
                        (change)="onStateWideRatioChange()"
                        [disabled]="readOnly">
                            Same ratio state-wide
                </label>
                <p class="help-block">(doesn't vary by individual assessor)</p>
            </div>
            <div *ngIf="!ac.ratioSetAtAssessor || params.assessor">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="ac.fixedRatio" (ngModelChange)="onFixedRatioChange()" [disabled]="readOnlyRatio || !ac.allowToDeleteRatios">Fixed Ratio
                    </label>
                    <p class="help-block">(doesn't change annually)</p>
                </div>
                <div *ngIf="ac.fixedRatio">
                    <div *ngFor="let ratio of undeletedRatios">
                        <label>Ratio (%):</label>
                        <input type="text"
                            style="display: inline-block; width: 100px;"
                            class="form-control"
                            toPercentage
                            [precision]="3"
                            [(ngModel)]="ratio.assessmentRatio"
                            (ngModelChange)="updateEfAction(ratio)"
                            [disabled]="readOnlyRatio">
                    </div>
                </div>
                <div *ngIf="!ac.fixedRatio">
                    <table class="table table-condensed">
                        <thead>
                            <tr>
                                <th>Tax Year</th>
                                <th>Ratio (%)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ratio of undeletedRatios">
                                <td>{{ratio.taxYear}}</td>
                                <td>
                                    <input type="text"
                                        toPercentage
                                        [precision]="3"
                                        style="display: inline-block; width: 100px;"
                                        class="form-control"
                                        [(ngModel)]="ratio.assessmentRatio"
                                        (ngModelChange)="updateEfAction(ratio)"
                                        [disabled]="readOnlyRatio">
                                </td>
                                <td>
                                    <button class="ace-btn has-icon btn-primary btn-danger"
                                        style="margin: 0;"
                                        (click)="removeEntity(ac.assessmentClassRatios, ratio)"
                                        *ngIf="!readOnlyRatio">
                                            <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="!readOnlyRatio" style="display: flex; margin-top: 15px;">
                        <ws-select placeholder="Add Year"
                            [(ngModel)]="yearToAdd"
                            [options]="availableYears"
                            (selectedOptionChanged)="addRatio()" [isPrimitive]="true" [canSearch]="false">
                        </ws-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
