import { Input, Output, EventEmitter, Component, SimpleChanges } from '@angular/core';

@Component({
    selector: 'assessment-input',
    templateUrl: './assessment-input.component.html'
})
export class FmvInputComponent {
    constructor() {
        this.isOverriddenChange = new EventEmitter<boolean>();
        this.valueChange = new EventEmitter<number>();
        this.internalValue = '';
        this.internalIsOverridden = false;
        this.modelChanging = false;
        this.resetIt = new EventEmitter<void>();
    }

    @Input() isOverridden: boolean;
    @Output() isOverriddenChange: EventEmitter<boolean>;
    @Input() value: number;
    @Input() componentName: string;
    @Output() valueChange: EventEmitter<number>;
    @Input() filterFunction: (input: string, hash: boolean, negative: boolean) => string;
    @Output() resetIt: EventEmitter<void>;
    @Input() allowNegatives: boolean = false;
    @Input() allowOverrides: boolean = false;
    internalValue: string;
    internalIsOverridden: boolean;
    modelChanging: boolean;

    // find the location where commas need to be inserted so that they can be replaced with ','
    readonly ADD_COMMAS_REGEX = new RegExp('\\B(?=(\\d{3})+(?!\\d))', 'g');

    // find the commas so that they can be replaced with ''
    readonly REMOVE_COMMAS_REGEX = new RegExp(',', 'g');

    /**
     * The 'internalValueFormatted' property is setup as a wrapper around the 'internalValue' property and it only provided comma formatting.
     * Because such input box is setup to handle the '#' as a special character we cannot directly apply the 'currencyMask' directive.
     * Notes:
     *   In the future, consider a UI flag for turning on/off the 'overrride' mode. That will not require us to support the '#' character
     *   and allow us to use the 'currencyMask' directive.
     */
    get internalValueFormatted(): string {
        if (this.internalValue !== null && this.internalValue !== undefined) {
            // if the user is overwriting with the '#' character keep the '#' and format the rest with commas otherwise just format with commas
            return this.internalValue.toString().charAt(0) === '#'
                ? `#${this.internalValue.toString().slice(1).replace(this.ADD_COMMAS_REGEX, ',')}`
                : this.internalValue.toString().replace(this.ADD_COMMAS_REGEX, ',');
        }
        return this.internalValue;
    }
    set internalValueFormatted(value: string) {
        // remove commas and set internal value
        this.internalValue = value.toString().replace(this.REMOVE_COMMAS_REGEX, '');
        this._processInternalValueChanged();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['value'] && !this.modelChanging) {
            this.internalValue = changes['value'].currentValue && changes['value'].currentValue.toString();
        }
        if (changes['isOverridden']) {
            this.internalIsOverridden = changes['isOverridden'].currentValue;
        }
    }

    onBlur() {
        //this.internalValue = event.target.value as string;
        const originallyOverridden = this.internalIsOverridden;

        if (this.internalValue[0] === '#') {
            this.internalValue = this.internalValue.substr(1);
            //event.target.value = this.internalValue; // two-way binding of the "internalValueFormatted" will take care of this
            this.internalIsOverridden = true;
        }

        if (this.internalValue === '') {
            if (this.internalIsOverridden) {
                this.resetIt.emit();
            }
            else {
                this.valueChange.emit(null);
            }
            this.internalIsOverridden = false;
        }
        else {
            this.valueChange.emit(parseInt(this.internalValue, 10));
        }

        if (originallyOverridden !== this.internalIsOverridden) {
            this.isOverriddenChange.emit(this.internalIsOverridden);
        }
    }

    private _processInternalValueChanged() {
        if (this.modelChanging) return;

        setTimeout(() => {
            this.modelChanging = true;
            const originallyOverridden = this.internalIsOverridden;

            //Remove cents in the case we've copy/pasted
            const periodInput: number = this.internalValue.indexOf('.');
            let tempValue: string;
            if(periodInput >= 0) {
                 tempValue = this.internalValue.substr(0, periodInput);
            } else {
                tempValue = this.internalValue;
            }

            const newValue = this.filterFunction(tempValue, this.allowOverrides, this.allowNegatives);
            this.internalValue = newValue;

            if (!newValue) {
                this.valueChange.emit(0);
            }
            else if (newValue === '#' && this.allowOverrides) {
                this.internalIsOverridden = true;
            }
            else if (newValue[0] === '#' && this.allowOverrides) {
                this.valueChange.emit(parseInt(newValue.substr(1), 10));
                this.internalIsOverridden = true;
            }
            else {
                this.valueChange.emit(parseInt(newValue, 10));
            }

            if (originallyOverridden !== this.internalIsOverridden) {
                this.isOverriddenChange.emit(this.internalIsOverridden);
            }

            setTimeout(() => {
                this.modelChanging = false;
            }, 1);
        }, 1);
    }
}