import { Component } from '@angular/core';
import { HelpContentComponent, HelpContentRendererValue } from '../../../UI-Lib/Help-Tooltip';

export interface AgGridHeaderLegendTooltipParams extends HelpContentRendererValue<any> {
    groupColor: string;
    groupName: string;
}

@Component({
    selector: 'ws-header-group-tooltip-legend',
    template: `
        <div class="ws-flex-container-horizontal ws-flex-align-items-center ws-flex-justify-content-center">
            <div class="color-block colorize" [ngClass]="params.groupColor"></div>
            <p class="tooltip-group-name">{{params.groupName}}</p>
        </div>
    `,
    styleUrls: ['./agGridHeaderLegendTooltip.component.scss']
})
export class AgGridHeaderLegendTooltipComponent implements HelpContentComponent<AgGridHeaderLegendTooltipParams> {
    params: AgGridHeaderLegendTooltipParams;

    helpInit(params: AgGridHeaderLegendTooltipParams): void { return null; }
}
