import { HttpClient, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EntityTypeIds } from '../../constants.new';
import { CsrCommandCenterRepository } from './csrCommandCenter.repository';

@Injectable()
export class CsrCommandCenterService {
    constructor(private readonly _http: HttpClient, private readonly csrRepo : CsrCommandCenterRepository) {}

    entityCharacteristics: Core.ClientServiceResponsibilityCommandCenterDescriptorInfoModel = null;

    private readonly _baseUrl = 'api/clientserviceresponsibility/cc/';

    search(groupSearchModel: Core.ClientServiceResponsibilityCommandCenterSearchModel): Promise<Compliance.QueryResultModel<Core.ClientServiceResponsibilityCommandCenterModel>> {
       return lastValueFrom(this._http.post(`${this._baseUrl}search/`, groupSearchModel)) as Promise<Compliance.QueryResultModel<Core.ClientServiceResponsibilityCommandCenterModel>>;
    }

    searchRowIds(groupSearchModel: Core.ClientServiceResponsibilityCommandCenterSearchModel): Promise<Compliance.QueryResultModel<number>> {
        return lastValueFrom(this._http.post(`${this._baseUrl}search/rowids`, groupSearchModel)) as Promise<Compliance.QueryResultModel<number>>;
    }

    removeExceptions(model: Core.ClientServiceExceptionRemoveRequest): Promise<boolean> {
        return lastValueFrom(this._http.put(`${this._baseUrl}exception/remove`, model)) as Promise<boolean>;
    }

    async exportList(exportModel: Core.ClientServiceResponsibilityCommandCenterExportModel): Promise<number> {
        return await lastValueFrom(this.csrRepo.exportList(exportModel));
    }

    async getExport(companyId:number, longRunningProcessId: number): Promise<HttpResponse<Blob>> {
        return await lastValueFrom(this.csrRepo.getExport(companyId, longRunningProcessId));
    }

    async getEntityDescriptors(entityId: number, entityTypeId: EntityTypeIds): Promise<Core.ClientServiceResponsibilityCommandCenterDescriptorInfoModel> {
        if(entityTypeId === EntityTypeIds.COMPANY || entityTypeId === EntityTypeIds.SITE || entityTypeId === EntityTypeIds.PARCEL) {
        this.entityCharacteristics =  await (lastValueFrom(this._http.get(`${this._baseUrl}descriptorInfo/${entityId}/${entityTypeId}`)) as Promise<Core.ClientServiceResponsibilityCommandCenterDescriptorInfoModel>);
        return this.entityCharacteristics;
        }
        else {
        throw(entityTypeId);
        }
    }
}
