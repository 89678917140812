import { DecimalPipe } from '@angular/common';
import {
    AgGridColumns,
    AgGridCurrencyCellEditor,
    AgGridDatepickerCellEditor, AgGridDropdownCellEditor,
    AgGridFilterParams, AgGridNumberCellEditor, AgGridTextCellEditor
} from './index';
import { AgGridTooltipCellRendererComponent } from './CellRenderers/agGridTooltipCellRenderer.component';
import { CellEditorValidationResult } from './CellEditors/validator.interface';
import { WeissmanDateFormat } from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import {
    AgGridYesNoFloatingFilterComponent
} from './FloatingFilters/agGridYesNoFloatingFilter.component';
import { ColDef } from 'ag-grid-community';

export interface DescriptorColumnDefinitions {
    [key: number]: (
        descriptor: Core.DescriptorInfoModel,
        getDescriptorValue: (model: Compliance.AssessorCommandCenterModel, descriptor: Core.DescriptorInfoModel) => any,
        pickList?: Core.DescriptorPicklistModel[]) => ColDef;
}


export function DESCRIPTOR_COLUMN_DEFINITIONS(decimalPipe: DecimalPipe): DescriptorColumnDefinitions {
    return {
        [Core.DescriptorFieldTypes.Currency]: (descriptor, getDescriptorValue) => {
            const getDescriptor = () => descriptor; // create closure for later access
            return {
                colId: `d.${descriptor.descriptorId}`,
                headerName: descriptor.name,
                field: `d.${descriptor.descriptorId}`,
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridTooltipCellRendererComponent,
                cellRendererParams: {},
                cellEditorFramework: AgGridCurrencyCellEditor,
                cellEditorParams: {
                    cellFocusLost: () => { return null; },
                    validator: (value: number): CellEditorValidationResult => {
                        const validation = getDescriptor().validation;
                        if (validation) {
                            const isValid = validation
                                && (validation.minValue ? value >= validation.minValue : true)
                                && (validation.maxValue ? value <= validation.maxValue : true);
                            let message = 'Value must be ';
                            message += (validation.minValue) && `> ${validation.minValue - 1}`;
                            message += (validation.minValue && validation.maxValue) && ' and ';
                            message += (validation.maxValue) && `< ${validation.maxValue + 1}`;
                            return {isValid, validationMessage: message};
                        } else {
                            return {isValid: true, validationMessage: ''};
                        }
                    }
                },
                hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
                valueFormatter: (params) =>
                {
                    if ([null, undefined].indexOf(params.value) !== -1) {
                        return '';
                    }

                    let result: string;
                    switch (descriptor.format) {
                        case '(#,###)':
                            result = decimalPipe.transform(params.value, '1.0-0');
                            break;
                        case '(#,###.00)':
                            result = decimalPipe.transform(params.value, '1.2-2');
                            break;
                        case '(#,###.000)':
                            result = decimalPipe.transform(params.value, '1.3-3');
                            break;
                        case '(0000)':
                        default:
                            result = decimalPipe.transform(params.value, '1.2-2');
                            break;
                    }

                    return `$${  result}`;
                },
                cellClass: () => '',
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            };
        },
        [Core.DescriptorFieldTypes.Date]: (descriptor, getDescriptorValue) => {
            const getDescriptor = () => descriptor; // create closure for later access
            return {
                colId: `d.${descriptor.descriptorId}`,
                headerName: descriptor.name,
                field: `d.${descriptor.descriptorId}`,
                width: AgGridColumns.dateColumnWidth,
                cellRendererFramework: AgGridTooltipCellRendererComponent,
                cellRendererParams: {},
                cellEditorFramework: AgGridDatepickerCellEditor,
                cellEditorParams: {
                    cellFocusLost: () => { return null; },
                    validator: (value: Date): CellEditorValidationResult => {
                        const year = value.getFullYear();
                        const validation = getDescriptor().validation;

                        if (validation) {
                            const isValid = validation
                                && (validation.minValue ? year >= validation.minValue : true)
                                && (validation.maxValue ? year <= validation.maxValue : true);
                            let message = 'Year must be ';
                            message += (validation.minValue) && `> ${validation.minValue - 1}`;
                            message += (validation.minValue && validation.maxValue) && ' and ';
                            message += (validation.maxValue) && `< ${validation.maxValue + 1}`;
                            return {isValid, validationMessage: message};
                        } else {
                            return {isValid: true, validationMessage: ''};
                        }
                    }
                },
                hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => WeissmanDateFormat(params.value, true),
                valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
                cellClass: () => '',
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            };
        },
        [Core.DescriptorFieldTypes.Number]: (descriptor, getDescriptorValue) => {
            const getDescriptor = () => descriptor; // create closure for later access
            return {
                colId: `d.${descriptor.descriptorId}`,
                headerName: descriptor.name,
                field: `d.${descriptor.descriptorId}`,
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridTooltipCellRendererComponent,
                cellRendererParams: {},
                cellEditorFramework: AgGridNumberCellEditor,
                cellEditorParams: {
                    cellFocusLost: () => { return null; },
                    validator: (value: number): CellEditorValidationResult => {
                        const validation = getDescriptor().validation;

                        if (validation) {
                            const isValid = validation
                                && (validation.minValue ? value >= validation.minValue : true)
                                && (validation.maxValue ? value <= validation.maxValue : true);
                            let message = 'Value must be ';
                            message += (validation.minValue) && `> ${validation.minValue - 1}`;
                            message += (validation.minValue && validation.maxValue) && ' and ';
                            message += (validation.maxValue) && `< ${validation.maxValue + 1}`;
                            return {isValid, validationMessage: message};
                        } else {
                            return {isValid: true, validationMessage: ''};
                        }
                    }
                },
                hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
                valueFormatter: (params) =>
                {
                    if ([null, undefined].indexOf(params.value) !== -1) {
                        return '';
                    }

                    let result: string;
                    switch (descriptor.format) {
                        case '(#,###)':
                            result = decimalPipe.transform(params.value, '1.0-0');
                            break;
                        case '(#,###.00)':
                            result = decimalPipe.transform(params.value, '1.2-2');
                            break;
                        case '(#,###.000)':
                            result = decimalPipe.transform(params.value, '1.3-3');
                            break;
                        case '(0000)':
                        default:
                            result = params.value;
                            break;
                    }

                    return result;
                },
                cellClass: () => '',
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            };
        },
        [Core.DescriptorFieldTypes.Picklist]: (descriptor, getDescriptorValue, picklist) => {
            const getDescriptor = () => descriptor; // create closure for later access
            return {
                colId: `d.${descriptor.descriptorId}`,
                headerName: descriptor.name,
                field: `d.${descriptor.descriptorId}`,
                width: AgGridColumns.selectionColumnWidth,
                cellRendererFramework: AgGridTooltipCellRendererComponent,
                cellRendererParams: {},
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => {
                        return picklist.reduce((acc, x) => {
                            if (x.groupId === getDescriptor().picklistGroupId) {
                                acc.push({ name: x.name, value: x.name });
                            }
                            return acc;
                        }, []);
                    },
                    cellFocusLost: () => { return null; }
                },
                hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
                cellClass: () => '',
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            };
        },
        [Core.DescriptorFieldTypes.Text]: (descriptor, getDescriptorValue) => {
            const getDescriptor = () => descriptor; // create closure for later access
            return {
                colId: `d.${descriptor.descriptorId}`,
                headerName: descriptor.name,
                field: `d.${descriptor.descriptorId}`,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridTooltipCellRendererComponent,
                cellRendererParams: {},
                cellEditorFramework: AgGridTextCellEditor,
                cellEditorParams: {
                    cellFocusLost: () => { return null; },
                    validator: (value: string): CellEditorValidationResult => {
                        const validation = getDescriptor().validation;

                        if (validation) {
                            const isValid = validation
                                && (validation.maxLength ? value.length <= validation.maxLength : true);
                            const message = `Max characters: ${validation.maxLength}`;
                            return {isValid, validationMessage: message};
                        } else {
                            return {isValid: true, validationMessage: ''};
                        }
                    },
                    maxLength: descriptor.validation && descriptor.validation.maxLength
                },
                hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
                cellClass: () => '',
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            };
        },
        [Core.DescriptorFieldTypes.YesNo]: (descriptor, getDescriptorValue) => {
            const getDescriptor = () => descriptor; // create closure for later access
            return {
                colId: `d.${descriptor.descriptorId}`,
                headerName: descriptor.name,
                field: `d.${descriptor.descriptorId}`,
                width: AgGridColumns.checkboxColumnMinWidth,
                cellRendererFramework: AgGridTooltipCellRendererComponent,
                cellRendererParams: {},
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => [{ name: 'Yes', value: true }, {name: 'No', value: false}],
                    cellFocusLost: () => { return null; }
                },
                hide: !(descriptor.assessorUsage === Core.DescriptorUsageEnum.Frequent),
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.yesNoFilterWithBlankOptionsParams,
                valueGetter: (params) => getDescriptorValue(params.data, getDescriptor()),
                valueFormatter: (params) => {
                    if ([null, undefined].indexOf(params.value) !== -1) {
                        return '';
                    }

                    if (params.value || params.value === false) {
                        return (params.value) ? 'Yes' : 'No';
                    } else {
                        return '';
                    }
                },
                cellClass: () => '',
                comparator: (valueA, valueB, nodeA, nodeB, isDescending) => 0
            };
        }
    };
}
