<div class="ws-flex-container-vertical ws-stretch">
    <div class="ws-flex-container-horizontal ws-flex-auto">
        <div class="ws-flex-auto">
            Returns
        </div>
        <div class="ws-flex-none">
            <button type="button"
                    class="ace-btn has-icon btn-secondary"
                    (click)="refresh()"
                    [disabled]="refreshing"
                    helpTooltip
                    helpContentId="app.refresh"
                    position="bottom">
                <i class="fa fa-refresh"></i>
            </button>
        </div>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)">
    </ws-ag-grid-angular>
</div>
