import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wsTimeSpanPipe' })
export class WeissmanTimeSpanPipe implements PipeTransform {
    transform(milliseconds: number, precision: number = 0, unit = 'auto'): string {
        const seconds = (milliseconds / 1000);
        const minutes = (milliseconds / (1000 * 60));
        const hours = (milliseconds / (1000 * 60 * 60));
        const days = (milliseconds / (1000 * 60 * 60 * 24));

        let result: number;
        let label: string;

        if (unit === 'auto') {
            if (milliseconds < 1000) {
                result = milliseconds;
                label = 'ms';
            } else if (seconds < 60) {
                result = seconds;
                label = 's';
            } else if (minutes < 60) {
                result = minutes;
                label = 'm';
            } else if (hours < 24) {
                result = hours;
                label = 'h';
            } else {
                result = days;
                label = 'd';
            }
        } else {
            switch (unit) {
                case 'ms':
                    result = milliseconds;
                    label = 'ms';
                    break;
                case 's':
                    result = seconds;
                    label = 's';
                    break;
                case 'm':
                    result = minutes;
                    label = 'm';
                    break;
                case 'h':
                    result = hours;
                    label = 'h';
                    break;
                case 'd':
                    result = hours;
                    label = 'h';
                    break;
                default:
                    result = seconds;
                    label = 's';
                    break;
            }
        }

        return `${result.toFixed(precision)}${label}`;
    }
}
