<ws-modal-wrapper headerLabel="Budget Site/Parcel Characteristics" [hideSave]="!canEdit" (cancel)="cancel()" (save)="save()" [cancelLabel]="canEdit ? 'Cancel' : 'Close'" [suppressKeyboardEvents]="true">
    <div class="text-center loading-all" *ngIf="!inUseDescriptors">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!budgetDescriptors.length && !inUseDescriptors.site && !inUseDescriptors.parcel" class="text-center">
        <em>Company does not have any characteristics in use.</em>
    </div>
    <div *ngIf="budgetDescriptors.length" style="max-height: 400px; overflow: auto;">
        <table class="table table-striped table-condensed">
            <tr>
                <th>Name</th>
                <th>Category</th>
                <th>Entity</th>
                <th *ngIf="canEdit"></th>
            </tr>
            <tr *ngFor="let item of budgetDescriptors">
                <td>{{item.descriptorName}}</td>
                <td>{{item.categoryName}}</td>
                <td>
                    <span *ngIf="item.entityTypeId === EntityTypeIds.SITE">Site</span>
                    <span *ngIf="item.entityTypeId === EntityTypeIds.PARCEL">Parcel</span>
                </td>
                <td *ngIf="canEdit" style="width: 1px;">
                    <button class="ace-btn has-icon btn-text btn-primary btn-danger" (click)="removeDescriptor(item)">
                        <i class="fa fa-trash"></i>
                    </button>
                </td>
            </tr>
        </table>
    </div>
    <div *ngIf="!budgetDescriptors.length" class="text-center">
        <em>List Empty</em>
    </div>
    <div *ngIf="canEdit" class="company-in-use-characteristics">
        <label>Add Characteristic:</label>
        <div class="mb-4">
            <budget-descriptor-selector
                placeholder="Site"
                [inUseDescriptors]="inUseDescriptors.site"
                [disabled]="!inUseDescriptors.site"
                (onDescriptorSelected)="descriptorSelected($event)">
            </budget-descriptor-selector>
        </div>
        <div class="mb-4">
            <budget-descriptor-selector
                placeholder="Parcel"
                [inUseDescriptors]="inUseDescriptors.parcel"
                [disabled]="!inUseDescriptors.parcel"
                (onDescriptorSelected)="descriptorSelected($event)">
            </budget-descriptor-selector>
        </div>
    </div>
</ws-modal-wrapper>
