import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { CsrCommandCenterService } from './csrCommandCenter.service';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../Compliance/AgGrid';
import { has } from 'lodash';

export interface CsrListDataSourceParams {
    exceptionsOnly: boolean;
    clientServiceIds: number[];
    entityFilters: Weissman.Model.EntityFilter[];
    sitePropCharFilters: Weissman.Model.NameValuePair[];
    parcelPropCharFilters: Weissman.Model.NameValuePair[];
    propertyTypeIds: number[];
    rootEntityId: number;
    rootEntityType: Core.EntityTypes;
    sourceEntityIDs: number[];
    sourceEntityIsTopLevelCompany: boolean;
    sourceEntityTypeID: number;
    excludeInactiveCompanies: boolean;
}

export class CsrListAgGridDataSource extends AgGridDataSourceBase<CsrListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _csrCommandCenterService: CsrCommandCenterService,
        protected _dataSourceParamsFn: () => CsrListDataSourceParams,
    ) { super(_gridApi); }

    public descriptorIds: number[] = [];

    private readonly _propertyMap: Compliance.NameValuePair<Core.ClientServiceResponsibilityCommandCenterPropertyEnum>[] = [
        { name: 'clientServiceName', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.ClientServiceName },
        { name: 'companyName', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.CompanyName },
        { name: 'companyCode', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.CompanyCode },
        { name: 'parcelAccountNumber', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.ParcelAccountNumber },
        { name: 'propertyTypeName', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.PropertyTypeName },
        { name: 'responsibility', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.Responsibility },
        { name: 'siteName', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.SiteName },
        { name: 'siteNumber', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.SiteNumber },
        { name: 'state', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.State },
        { name: 'topLevelCompanyName', value: Core.ClientServiceResponsibilityCommandCenterPropertyEnum.TopLevelCompanyName },
    ];

    private readonly _filterSortMap: Map<number, Compliance.AssessorCommandCenterModelPropertyEnum> = new Map([
        [Core.DescriptorFieldTypes.Number, Compliance.AssessorCommandCenterModelPropertyEnum.NumberDescriptor],
        [Core.DescriptorFieldTypes.Currency, Compliance.AssessorCommandCenterModelPropertyEnum.NumberDescriptor],
        [Core.DescriptorFieldTypes.Date, Compliance.AssessorCommandCenterModelPropertyEnum.DateDescriptor],
        [Core.DescriptorFieldTypes.YesNo, Compliance.AssessorCommandCenterModelPropertyEnum.BooleanDescriptor],
        [Core.DescriptorFieldTypes.Picklist, Compliance.AssessorCommandCenterModelPropertyEnum.StringDescriptor],
        [Core.DescriptorFieldTypes.Text, Compliance.AssessorCommandCenterModelPropertyEnum.StringDescriptor]
    ]);

    getSearchParamsWithoutPagination() : Core.ClientServiceResponsibilityCommandCenterSearchModel{
        this.refreshDataSourceParams();
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            rootEntityId: this._dataSourceParams.rootEntityId,
            rootEntityType: this._dataSourceParams.rootEntityType,
            clientServiceIds: this._dataSourceParams.clientServiceIds,
            propertyTypeIds: this._dataSourceParams.propertyTypeIds,
            exceptionsOnly: this._dataSourceParams.exceptionsOnly,
            entityFilters: this._dataSourceParams.entityFilters,
            sitePropCharFilters: this._dataSourceParams.sitePropCharFilters,
            parcelPropCharFilters: this._dataSourceParams.parcelPropCharFilters,
            sourceEntityIDs: this._dataSourceParams.sourceEntityIDs,
            sourceEntityIsTopLevelCompany: this._dataSourceParams.sourceEntityIsTopLevelCompany,
            sourceEntityTypeID: this._dataSourceParams.sourceEntityTypeID,
            descriptorIds: this.descriptorIds,
            includeInactive: !this._dataSourceParams.excludeInactiveCompanies,
        };
    }


    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.ClientServiceResponsibilityCommandCenterSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            rootEntityId: this._dataSourceParams.rootEntityId,
            rootEntityType: this._dataSourceParams.rootEntityType,
            clientServiceIds: this._dataSourceParams.clientServiceIds,
            propertyTypeIds: this._dataSourceParams.propertyTypeIds,
            exceptionsOnly: this._dataSourceParams.exceptionsOnly,
            entityFilters: this._dataSourceParams.entityFilters,
            sitePropCharFilters: this._dataSourceParams.sitePropCharFilters,
            parcelPropCharFilters: this._dataSourceParams.parcelPropCharFilters,
            sourceEntityIDs: this._dataSourceParams.sourceEntityIDs,
            sourceEntityIsTopLevelCompany: this._dataSourceParams.sourceEntityIsTopLevelCompany,
            sourceEntityTypeID: this._dataSourceParams.sourceEntityTypeID,
            descriptorIds: this.descriptorIds,
            includeInactive: !this._dataSourceParams.excludeInactiveCompanies,
        };

        return await this._csrCommandCenterService.searchRowIds(searchParams);
    }

    protected canGetRows(): boolean {
        return true;
    }

    protected getSortColumns<T>(propertyMap: Compliance.NameValuePair<T>[]):  Core.SortModel<any>[] {
        const columns: Core.SortModel<any>[] = [];

        const sortModel = this.gridApi.getSortModel();

        if (sortModel) {
            sortModel.forEach(x => {
                const property = this.getProperty(x.colId, propertyMap);
                if (property !== null) {
                    columns.push({
                        sortProperty: property,
                        sortDirection: this.getSortDirection(x.sort)
                    });
                }
            });
        }

        return columns;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams = this.getSearchParamsWithoutPagination();

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await this._csrCommandCenterService.search(searchParams);
        const dataSourceResult: AgGridDataSourceResult = {
            rows: result.data,
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };

        return dataSourceResult;
    }

    protected getColumnFilters<T>(propertyMap: Compliance.NameValuePair<T>[]): Core.FilterModel<any>[] {
        const columns: Core.FilterModel<any>[] = [];
        const filterModel = this.gridApi.getFilterModel();

        if (filterModel) {
            for (const prop in filterModel) {
                if (has(filterModel, prop)) {
                    const property = this.getProperty(prop, propertyMap);
                    if (property !== null) {
                        columns.push({
                            filterProperty: property,
                            filterConditionType: filterModel[prop] && filterModel[prop].filterConditionType,
                            filterValues: filterModel[prop] && filterModel[prop].filterValues.map(x => {
                                return {
                                    filterType: this.getFilterType(x.filterType.displayKey),
                                    filterValue: this.getFilterValue(x),
                                    filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                                };
                            })
                        });
                    } else {
                        const descriptor =  this._csrCommandCenterService.entityCharacteristics.descriptors.find(d => `d.${d.descriptorId}` === prop);
                        if (descriptor) {
                            columns.push({
                                filterProperty: this._filterSortMap.get(descriptor.fieldType),
                                filterConditionType: filterModel[prop] && filterModel[prop].filterConditionType,
                                filterValues: filterModel[prop] && filterModel[prop].filterValues.map(x => {
                                    return {
                                        filterParameter: prop.split('.')[1],
                                        filterType: this.getFilterType(x.filterType.displayKey),
                                        filterValue: this.getFilterValue(x),
                                        filterToValue: this.getFilterType(x.filterType.displayKey) === Core.FilterTypeEnum.InRange ? x.filterToValue : null
                                    };
                                })
                            });
                        }
                    }
                }
            }
        }
        return columns;
    }
}
