import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'splitList' })
export class SplitListPipe implements PipeTransform {
    transform(items: any[], columns: number): any[][] {
        const result = [];
        let itemsPerColumn = Math.floor(items.length / columns);
        if (itemsPerColumn < 1) {
            itemsPerColumn = 1;
            columns = (items.length < columns) ? items.length: columns;
        }
        for (let i = 0; i < columns; i++) {
            const start = i ? (itemsPerColumn * i) : 0;
            const end = (start + itemsPerColumn) > items.length ? items.length : (start + itemsPerColumn);
            result.push(items.slice(start, end));
        }
        return result;
    }
}
