<div class="container">
    <div class="col-md-6 offset-sm-3">
        <div class="page-header">
            <h1>Change Password</h1>
        </div>
        <form name="form" class="form-horizontal">
            <new-password [showCurrent]="true"
                          [(passwords)]="password"
                          [(passwordsValid)]="passwordsValid"
                          [alreadyUsed]="alreadyUsed">
            </new-password>
            <div class="mb-4">
                <div class="offset-sm-2 col-md-10">
                    <button type="submit" class="ace-btn btn-primary"
                            title="Change"
                            (click)="changePassword()"
                            [disabled]="!passwordsValid || disableSave">
                        Save
                    </button>
                    <button type="button" class="ace-btn warning-button" title="Cancel" (click)="goToHome()">
                        Cancel
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
