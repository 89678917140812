<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
                   *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
<div class="support-instance-list ws-flex-container-vertical ws-flex-auto">
    <div class="ws-section">
        <div class="ws-section__header">
            <h1 class="ws-section__header__title page-title">
                Support Instances
            </h1>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <label class="checkbox float-end">
                        <input type="checkbox"
                               [(ngModel)]="excludeInactive"
                               (change)="refresh()"> Exclude Inactive
                    </label>
                </div>
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="app.refresh"
                         position="bottom">
                        <button type="button" 
                                class="ace-btn has-icon btn-sm btn-secondary"
                                (click)="refresh()">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <table class="table table-hover support-instance-list-table">
            <thead>
            <tr>
                <th>Instance</th>
                <th>Company</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let instance of instances">
                <td>
                    {{ instance.instanceName }}
                </td>
                <td>
                    {{ instance.topLevelCompanyName }}
                </td>
                <td>
                    <div (mouseenter)="updateStatusPopoverOpened(instance, true)"
                         (mouseleave)="updateStatusPopoverOpened(instance, false)">
                        <span [popover]="statusPopoverTemplate"
                              triggers=""
                              [isOpen]="instance.statusPopoverOpened"
                              placement="bottom left">
                                 <ng-template #statusPopoverTemplate>
                                    <p>Deactivated by {{ instance.deactivationConfirmUserName }}
                                        on {{ instance.deactivationConfirmDate.toLocaleString() }}</p>
                                    <p>{{ instance.deactivationReason }}</p>
                                 </ng-template>
                            {{ getStatus(instance) }}
                        </span>
                    </div>
                </td>
                <td>
                    <button *ngIf="instance.accessGrantedDate"
                            (click)="terminateAccess(instance)"
                            [attr.disabled]="instance.isAccessTerminating"
                            class="ace-btn btn-sm ace-btn btn-primary btn-danger ms-0">Terminate Access
                    </button>
                    <button *ngIf="showAccessInstanceButton(instance)"
                            (click)="requestAccess(instance)"
                            class="ace-btn btn-sm ace-btn btn-primary m-0">Access Instance
                    </button>
                    <button *ngIf="showDeactivateInstanceButton(instance)"
                            (click)="deactivateInstance(instance)"
                            class="ace-btn btn-sm ace-btn btn-primary text-nowrap me-0">Deactivate Instance
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

