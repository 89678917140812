import { Component, ViewEncapsulation } from '@angular/core';
import { ReturnService } from '../../return.service';

import * as _ from 'lodash';

@Component({
    selector: 'return-settings-tab',
    templateUrl: './returnSettingsTab.component.html',
    styleUrls: ['./returnSettingsTab.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReturnSettingsTabComponent {
    constructor(private _returnService: ReturnService) { }

    get showGeneralReturnSettings(): boolean {
        return this._returnService.filingBatch &&
            (!!_.find(this._returnService.getAssociatedReturnFormRevisions(), i => i.supportsNBVReporting || i.supportsEstFMVReporting) ||
                this._returnService.isReturnInReadOnlyMode && (this._returnService.filingBatch.reportNBVInsteadOfFactoredCost ||
                    this._returnService.filingBatch.returnCostReportingDisplayId !== Compliance.ReturnCostReportingDisplayEnum.Both));
    }
}
