import { Injectable } from '@angular/core';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { AddPaymentsToBatchModalComponent, AddPaymentsToBatchModalParams } from './addPaymentsToBatchModal.component';

@Injectable()
export class AddPaymentsToBatchModalService {
    constructor(private _modalService: WeissmanModalService) { }

    async launchModal(topLevelCompanyId: number, taskIds: number[]) {

        const params: AddPaymentsToBatchModalParams = { topLevelCompanyId, taskIds };

        return this._modalService.showAsync(AddPaymentsToBatchModalComponent, params, 'modal-lg');
    }
}
