import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import * as _ from 'lodash';
import { reject, map, sortBy } from 'lodash/fp';
import { AssessorCollectorService } from 'src/app/Assessor-Collector/assessor.collector.service';
import { BillingScenarioService } from 'src/app/Assessor-Collector/Collector/Billing-Scenario/billingScenario.service';

class ParcelCollector {
    constructor(parcelCollector) {
        _.assign(this, parcelCollector);
        this.collectorBillInfo = _.map(this.collectorBillInfo, x => new CollectorBillInfo(x));
    }

    collectorAbbr: string;
    collectorBillInfo: CollectorBillInfo[];
    collectorBillScenarioID: number;
    collectorBillScenarioName: string;
    collectorID: number;
    name?: string;

    get title() {
        if(this.name) {
            return this.name;
        } else {
            let str = this.collectorAbbr;

            if(this.collectorBillScenarioName) {
                str += ` - ${this.collectorBillScenarioName}`;
            }
            return str;
        }
    }
}

class CollectorBillInfo {
    constructor(collectorBillInfo) {
        _.assign(this, collectorBillInfo);
    }

    collectorBillID: number;
    collectorBillName: string;
    collectorBillScenarioID: number;
    collectorPaymentOptionInfo: CollectorPaymentOptionInfo[];

    get displayPaymentOptions() {
        return _.filter(this.collectorPaymentOptionInfo, 'collectorPaymentOptionName');
    }
}

interface CollectorPaymentOptionInfo {
    collectorPaymentOptionID: number;
    collectorPaymentOptionName: string;
    isDefault: boolean;
}
export interface AddParcelCollectorModalParams {
    collectorList: ParcelCollector[];
    addedCollectorIds: number[];
    hasCompanyCollectors: boolean;
    parcelId: number;
}

export interface AddParcelCollectorModalResult {
    selectedCollector: ParcelCollector;
    applyTo: string;
    year?: number;
    parcelCollectorPaymentOptions: CollectorPaymentOptionInfo[];
}

@Component({
    selector: 'add-parcel-collector-modal',
    templateUrl: 'addParcelCollectorModal.component.html',
    styleUrls: ['addParcelCollectorModal.component.scss']
})
export class AddParcelCollectorModalComponent implements OnInit, IWeissmanModalComponent<AddParcelCollectorModalParams, AddParcelCollectorModalResult> {
    constructor(private readonly _bsModalRef: BsModalRef,
        private readonly _changeDetRef: ChangeDetectorRef,
        private readonly _billingScenarioService: BillingScenarioService,
        private readonly _assessorCollectorService: AssessorCollectorService) { }

    params: AddParcelCollectorModalParams;
    result: AddParcelCollectorModalResult;

    serverAction: boolean = false;
    isStateCollector: boolean = true;
    stateCollectors: ParcelCollector[];
    companyCollectors: ParcelCollector[];
    selectedCollector: ParcelCollector;
    selectedPaymentOptions: CollectorPaymentOptionInfo[] = [];
    applyTo: 'all'|'year' = 'all';
    taxYears: number[] =  _.range(2000, 2050);
    taxYear: number = new Date().getFullYear();


    ngOnInit(): void {
        this.stateCollectors = this._prepareCollectorList(this.params.collectorList);
        this.selectedCollector = this.stateCollectors[0];
    }

    get collectors(): ParcelCollector[] {
        return this.isStateCollector ? this.stateCollectors : this.companyCollectors;
    }

    governmentalCollectorsSelected(): void {
        this.selectedCollector = this.stateCollectors[0];
        this.collectorChanged();
        this._changeDetRef.detectChanges();
    }

    async companyCollectorsSelected(): Promise<void> {
        if(!this.companyCollectors) {
            this.serverAction = true;

            try {
                const companyCollectors = await this._assessorCollectorService.getCompanyCollectorsByParcel(this.params.parcelId);
                this.companyCollectors = this._prepareCollectorList(companyCollectors);
            } finally {
                this.serverAction = false;
            }
        }

        this.selectedCollector = this.companyCollectors[0];
        this.collectorChanged();
    }

    // This is a hack until the parcel info panel is upgraded
    // For some reason this modal will not detect the change automatically
    // My theory is that it's launched from angularjs, whereas a modal that's launched
    // from modern angular doesn't have this issue.
    applyToChange(e): void {
        this.applyTo = e.target.value;
        this._changeDetRef.detectChanges();
    }

    async collectorChanged(): Promise<void> {
        if(!this.selectedCollector?.collectorBillScenarioID) {
            return;
        }

        this.serverAction = true;
        try {
            const billScenario: Weissman.Model.Collectors.CollectorBillScenario = await this._billingScenarioService.getById(this.selectedCollector.collectorBillScenarioID);

            this.selectedCollector.collectorBillInfo = _.flow([
                map((bill: Weissman.Model.Collectors.CollectorBill) => {
                    const collectorPaymentOptionInfo = _.map(bill.collectorPaymentOptions, option => {
                        return {
                            collectorPaymentOptionID: option.collectorPaymentOptionID,
                            collectorPaymentOptionName: option.name,
                            isDefault: option.isDefault
                        };
                    });

                    return new CollectorBillInfo({
                        collectorBillID: bill.collectorBillID,
                        collectorBillName: bill.name,
                        collectorBillScenarioID: bill.collectorBillScenarioID,
                        collectorPaymentOptionInfo
                    });
                }),
                sortBy('collectorBillName')
            ])(billScenario.collectorBills || []);

            this.selectedPaymentOptions = _.fill(Array(this.selectedCollector.collectorBillInfo.length), undefined);
            _.forEach(this.selectedPaymentOptions, (item, index) => {
                const defaultPaymentOption = _.find(this.selectedCollector.collectorBillInfo[index].collectorPaymentOptionInfo, {isDefault: true});
                if(defaultPaymentOption) {
                    this.selectedPaymentOptions[index] = defaultPaymentOption;
                }
            });

        } finally {
            this.serverAction = false;
        }
    }

    add(): void {
        this.result = {
            selectedCollector: this.selectedCollector,
            applyTo: this.applyTo,
            parcelCollectorPaymentOptions: this.selectedPaymentOptions
        };

        if(this.applyTo === 'year') {
            this.result.year = this.taxYear;
        }

        if(this.result.parcelCollectorPaymentOptions.length < this.selectedCollector.collectorBillInfo.length) {
            _.forEach(this.selectedCollector.collectorBillInfo, collectorBill => {
                if(collectorBill.collectorPaymentOptionInfo.length > 0) {
                    const selectedPaymentOptionIDs = _.map(this.result.parcelCollectorPaymentOptions, 'collectorPaymentOptionID');
                    const collectorPaymentOptionIDs = _.map(collectorBill.collectorPaymentOptionInfo, 'collectorPaymentOptionID');
                    if(!_.intersection(selectedPaymentOptionIDs, collectorPaymentOptionIDs).length) {
                        this.result.parcelCollectorPaymentOptions.push(collectorBill.collectorPaymentOptionInfo[0]);
                    }
                }
            });
        }

        this.close();
    }

    close(): void {
        this._bsModalRef.hide();
    }

    private _prepareCollectorList(collectors): ParcelCollector[] {
        return _.flow([
            reject(x => _.includes(this.params.addedCollectorIds, x.collectorID)),
            map(x => new ParcelCollector(x)),
            sortBy(x => x.title.toLowerCase())
        ])(collectors);
    }
}