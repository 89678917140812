import { Injectable } from '@angular/core';
import { BaseRepository } from '../../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DatabaseRepository extends BaseRepository{
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private _baseUrl: string = '/api/database/';

    getRunningProcesses(): Observable<Core.DatabaseProcessModel[]> {
        return this.httpGet(`${this._baseUrl}getRunningProcesses`);
    }

    killProcesses(sessionIds: number[]): Observable<void> {
        return this.httpPost(`${this._baseUrl}killProcesses`, sessionIds);
    }

    clearCache(): Observable<void> {
        return this.httpPost(`${this._baseUrl}clearCache`, {});
    }

    clearCacheEntries(planHandles: string[]): Observable<void> {
        return this.httpPost(`${this._baseUrl}clearCacheEntries`, planHandles);
    }

    searchCachePlan(searchString: string): Observable<Core.PlanCacheModel[]> {
        return this.httpGet(`${this._baseUrl}plans/${encodeURIComponent(searchString)}`);
    }
}
