import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectConfig, WsSelectValueFormatter } from './select.interface';
import { AceSelectService } from './select.service';

export enum KEY_CODE {
    UP_ARROW = 38,
    DOWN_ARROW = 40
}

/**
 * Select dropdown
 *
 * Allows the user to select multiple items from the dropdown and displays a summary of the selections
 *
 * Tied into ControlValueAccessor for integration with native Angular FormControls and ngModel
 *
 * The input options can be any model. The properties to use as the selectors for each option in the dropdown are set using
 * labelProperty and valueProperty. To have the whole object returned as the value set returnEntireOption to true. If it is an array
 * of primitive values, set isPrimitive to true and returnEntireOption to true.
 *
 * Example usage:
 * <ws-select labelProperty="displayName"
 *             valueProperty="property"
 *             placeholder="Please select a value..."
 *             [options]="options"
 *             [formControl]="additionalSelect.formControl">
 * </ws-select>
 *
 */
@Component({
    selector: 'ws-ace-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AceSelectComponent),
            multi: true
        }
    ],
    encapsulation: ViewEncapsulation.None
})
export class AceSelectComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
    constructor(
        private readonly _selectService: AceSelectService,
        private readonly _elementRef: ElementRef,
        private readonly _ngZone: NgZone
    ) { }

    @Input() placeholder: string;
    @Input() labelProperty: string;
    @Input() valueProperty: string;
    @Input() disabledProperty: string;
    @Input() returnEntireOption: boolean;
    @Input() isPrimitive: boolean;
    @Input() canSearch: boolean;
    @Input() canDeselect: boolean;
    @Input() deselectLabel: string;
    @Input() deselectValue: any;
    @Input() includeSearchAfterN: number;
    @Input() size: string;
    @Input() searchType: string;
    @Input() maxListSize: number;
    @Input() maxTextLength: number;
    @Input() isLoading: boolean;
    @Input() isShowMoreFromParent: boolean;
    @Input() alignOptionText: string;
    @Input() set tabindex(tabindex: number) { this.tabIndex = tabindex; }

    @Input()
    set valueFormatter(formatter: WsSelectValueFormatter) {
        this._formatter = formatter;
        this._setOptions(this._options);
    }

    @Input()
    set options(options: any[]) {
        if (!options) { return; }
        this._options = options;
        this._setOptions(options);
    }

    @Input() optionStyleClass: (optionValue: any) => string;
    @Input() customSearch: (values: any[], searchValue: string) => any[];

    // Output whole selected option
    @Output() selectedOptionChanged = new EventEmitter<any>();
    // Output selected value only
    @Output() selectedValueChanged = new EventEmitter<any>();

    @Output() showMoreClicked = new EventEmitter<number>();

    @ViewChild('wsSelectDropdown', { static: true }) selectDropdown: ElementRef;

    @HostBinding('attr.tabindex') tabIndex = 0;

    value: any;
    disabled: boolean;
    widestOption: any;
    selectedOption: any;

    selectConfig: SelectConfig = {
        options: [],
        searchableOptions: [],
        selectedOption: null,
        placeholder: null,
        labelProperty: 'label',
        valueProperty: 'value',
        disabledProperty: 'disabled',
        returnEntireOption: false,
        isPrimitive: false,
        canSearch: true,
        canDeselect: false,
        deselectLabel: 'None',
        deselectValue: null,
        includeSearchAfterN: 10,
        size: 'md',
        tabindex: null,
        maxListSize: null,
        maxTextLength: 50,
        searchType: 'fuzzy',
        alignOptionText: 'left',
        isShowMoreFromParent: false,
        customSearch: null,
        optionStyleClass: null,
        selectedOptionChanged: (option) => this.optionSelected(option),
        showMoreClicked: (count: number) => this.showMoreClicked.emit(count)
    };

    private _options: any[] = [];
    private _formatter: WsSelectValueFormatter;

    @HostListener('window:resize') onResize(): void {
        this._selectService.updatePosition(this.selectConfig, this._elementRef.nativeElement.getBoundingClientRect());
    }

    @HostListener('keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === KEY_CODE.UP_ARROW) {
            this.keyPrevious();
        }
        if (event.keyCode === KEY_CODE.DOWN_ARROW) {
            this.keyNext();
        }
        event.stopPropagation();
    }

    get isOpen(): boolean {
        return this._selectService.dropdownOpen;
    }

    // Angular Form methods

    onChange: (val: any[]) => void;
    onTouched: () => void;

    writeValue(value: any): void {
        this.selectedOption = (this.returnEntireOption || this.selectConfig.isPrimitive)
            ? value : this._options.find(o => o[this.selectConfig.valueProperty] === value);
        if (this._formatter && this.selectConfig.options) {
            this.selectedOption = this.selectConfig.options.find(x => x[this.selectConfig.valueProperty] === value);
        }
        this.value = value;
        this.selectConfig.selectedOption = this.selectedOption;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    next(): void {
        this.value = ((this.returnEntireOption && !this._formatter)
            || this.selectConfig.isPrimitive
            || (this.selectConfig.canDeselect && this.selectedOption === this.selectConfig.deselectValue))
            ? this.selectedOption
            : this.selectedOption[this.selectConfig.valueProperty];
        this.onChange(this.value);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Custom methods

    ngOnInit(): void {
        this._ngZone.runOutsideAngular(() => {
            window.addEventListener('scroll', this._scroll, true);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        Object.keys(changes).forEach(key => {
            this.selectConfig[key] = changes[key].currentValue;
            if (key === 'options') {
                this._setOptions(this._options);
            }
        });
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this._scroll, true);
        if (this.isOpen) {
            this._selectService.closeDropdown();
        }
    }

    optionSelected(option: any): boolean {
        if (this.disabled || (option && option[this.selectConfig.disabledProperty])) {
            return false;
        }

        if (this.selectedOption !== option) {
            this.selectedOption = option;
            this.selectConfig.selectedOption = option;
        }

        this.next();
        this.selectedOptionChanged.emit(this.selectedOption);
        this.selectedValueChanged.emit(this.value);
        // prevent event propagation
        return false;
    }

    openDropdown(event): boolean {
        if (this.disabled || !this.selectConfig.searchableOptions.length) {
            return false;
        }

        this._selectService.openDropdown(this.selectConfig, this._elementRef.nativeElement.getBoundingClientRect());

        event.stopPropagation();
        // prevent event propagation
        return false;
    }

    keyPrevious(): void {
        if (!this.selectedOption) {
            return;
        }
        const index = this.selectConfig.searchableOptions.indexOf(this.selectedOption);
        if (index > 0) {
            this.optionSelected(this.selectConfig.searchableOptions[index - 1]);
        }
    }

    keyNext(): void {
        if (!this.selectedOption) {
            this.optionSelected(this.selectConfig.searchableOptions[0]);
            return;
        }
        const index = this.selectConfig.searchableOptions.indexOf(this.selectedOption);
        if (index !== -1 && index < (this.selectConfig.searchableOptions.length - 1)) {
            this.optionSelected(this.selectConfig.searchableOptions[index + 1]);
        }
    }

    private _scroll = () => this._selectService.updatePosition(this.selectConfig, this._elementRef.nativeElement.getBoundingClientRect());

    private _setOptions(options: any[]): void {
        if (options && options.length) {
            const dropdownOptions = this._applyValueFormatter(options);
            this.widestOption = dropdownOptions.reduce((acc, o) => {
                const value = (this.selectConfig.isPrimitive) ? o : o[this.selectConfig.labelProperty];
                return (value && value.length > acc.length) ? value : acc;
            }, '');
            this.widestOption = (this.selectConfig.placeholder && this.selectConfig.placeholder.length > this.widestOption.length)
                ? this.selectConfig.placeholder
                : this.widestOption;
            this.widestOption = (this.selectConfig.deselectLabel && this.selectConfig.deselectLabel.length > this.widestOption.length)
                ? this.selectConfig.deselectLabel
                : this.widestOption;
            this.selectConfig.options = dropdownOptions;
            this.selectConfig.searchableOptions = [...dropdownOptions];
            if (this.value !== undefined) {
                this.writeValue(this.value);
            }
            this._selectService.optionsChanged();
        }
    }

    private _applyValueFormatter(options: any[]): any[] {
        if (!this._formatter || this.selectConfig.isPrimitive) {
            return options;
        }
        return options
            .map(this._formatter)
            .map((o, i) => {
                const option = options[i];
                return {
                    [this.selectConfig.labelProperty]: o,
                    [this.selectConfig.valueProperty]: (this.returnEntireOption) ? option : option[this.selectConfig.valueProperty]
                };
            });
    }

}
