import { Injectable } from '@angular/core';
import { has } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

interface GroupSubjects {
    [any: string]: BehaviorSubject<boolean>;
}

interface LayoutSubjects {
    [any: string]: BehaviorSubject<string>;
}

@Injectable()
export class AgGridPersistenceService {
    constructor() {
        const autoUpdateTables = localStorage.getItem(this.AUTO_UPDATE_STORAGE_KEY);
        this._convertFromSessionStore(autoUpdateTables, (id, value) => this.toggleTotalsRowAutomaticUpdate(id, value));
    }

    private readonly AUTO_UPDATE_STORAGE_KEY = 'agAutoUpdate';

    private _isHeaderGroupColorActiveSubjects: GroupSubjects = {};
    private _totalsRowAutomaticUpdateSubjects: GroupSubjects = {};
    private _currentGridLayoutAppliedSubjects: LayoutSubjects = {};

    currentGridLayoutApplied$(tableId: string): Observable<string> {
        if (!has(this._currentGridLayoutAppliedSubjects, tableId)) {
            this._currentGridLayoutAppliedSubjects[tableId] = new BehaviorSubject<string>('Default');
        }
        return this._currentGridLayoutAppliedSubjects[tableId].asObservable();
    }

    setCurrentGridLayoutApplied(tableId: string, layoutName: string): void {
        if (has(this._currentGridLayoutAppliedSubjects, tableId)) {
            this._currentGridLayoutAppliedSubjects[tableId].next(layoutName);
        } else {
            this._currentGridLayoutAppliedSubjects[tableId] = new BehaviorSubject<string>(layoutName);
        }
    }

    isHeaderGroupColorActive$(tableId: string): Observable<boolean> {
        if (!has(this._isHeaderGroupColorActiveSubjects, tableId)) {
            this._isHeaderGroupColorActiveSubjects[tableId] = new BehaviorSubject<boolean>(false);
        }
        return this._isHeaderGroupColorActiveSubjects[tableId].asObservable();
    }

    toggleHeaderGroupColor(tableId: string, show: boolean): void {
        if (has(this._isHeaderGroupColorActiveSubjects, tableId)) {
            this._isHeaderGroupColorActiveSubjects[tableId].next(show);
        } else {
            this._isHeaderGroupColorActiveSubjects[tableId] = new BehaviorSubject<boolean>(show);
        }
    }

    totalsRowAutomaticUpdate$(tableId: string): Observable<boolean> {
        if (!has(this._totalsRowAutomaticUpdateSubjects, tableId)) {
            this._totalsRowAutomaticUpdateSubjects[tableId] = new BehaviorSubject<boolean>(false);
        }
        return this._totalsRowAutomaticUpdateSubjects[tableId].asObservable();
    }

    toggleTotalsRowAutomaticUpdate(tableId: string, show: boolean): void {
        if (has(this._totalsRowAutomaticUpdateSubjects, tableId)) {
            this._totalsRowAutomaticUpdateSubjects[tableId].next(show);
        } else {
            this._totalsRowAutomaticUpdateSubjects[tableId] = new BehaviorSubject<boolean>(show);
        }

        localStorage.removeItem(this.AUTO_UPDATE_STORAGE_KEY);
        localStorage.setItem(this.AUTO_UPDATE_STORAGE_KEY, this._convertToSessionStore(this._totalsRowAutomaticUpdateSubjects));
    }

    /**
     * Add group colors here. Each group has one color so that it is a consistent color across the app.
     * IE: Assets will have the same color in asset list, return asset list, allocation assets, etc.
     * If you add a new group, make sure to create a color for it in styles/compliance/aggrid-extensions.scss
     * @param groupType
     */
    getColorClassForGroupType(groupType: string) {
        switch (groupType) {
            case 'Asset':
                return 'header-group-color-0';
            case 'Work Papers':
                return 'header-group-color-1';
            case 'Leasing':
                return 'header-group-color-2';
            case 'Inventory':
                return 'header-group-color-3';
            case 'Source Values':
                return 'header-group-color-4';
            case 'Source Leasing':
                return 'header-group-color-5';
            case 'Source Inventory':
                return 'header-group-color-6';
            case 'Characteristics':
                return 'header-group-color-7';
            case 'Open Tax Obligation':
                return 'header-group-color-8';
            case 'Period Contributions':
                return 'header-group-color-9';
            case 'Period Accrual Balance':
                return 'header-group-color-10';
            case 'Period Respective Journal Impact':
                return 'header-group-color-11';
            case 'Period Ending Respective Balance':
                return 'header-group-color-12';
            default:
                return '';
        }
    }

    private _convertToSessionStore(value: GroupSubjects): string {
        const data = Object.keys(value).map((x) => [x, value[x].getValue()]);
        return JSON.stringify(data);
    }

    private _convertFromSessionStore(value: string, actionCallback: (id: string, value: boolean) => void): void {
        if (!value) {
            return;
        }
        const data = JSON.parse(value);
        data.forEach(x => actionCallback(x[0], x[1]));
    }
}
