<div class="card panel-flat-color secondary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-truncate ws-flex-auto">
            Batch Summary
        </h3>
        <div class="ws-flex-none header-button-container">
            <button *ngIf="!editMode && (workflowStatus?.canModifyFundedAmount || workflowStatus?.canModifyDescription)"
                    title="Edit"
                    type="button"
                    class="ace-btn has-icon btn-text btn-primary"
                    (click)="edit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
            <button title="Save"
                    type="button"
                    class="ace-btn has-icon btn-text btn-primary"
                    *ngIf="editMode"
                    (click)="save()">
                <i class="fa fa-save fa-align"></i>
            </button>
            <button title="Cancel"
                    type="button"
                    class="ace-btn has-icon btn-text btn-primary"
                    *ngIf="editMode"
                    (click)="cancel()">
                <i class="fa fa-times fa-align"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body">
        <div class="row payment-detail-header">
            <div class="col-md-5">
                <table style="width: 100%;">
                    <tr>
                        <td>Top Level Company:</td>
                        <td>{{paymentBatch.topLevelCompanyName}}</td>
                    </tr>
                    <tr>
                        <td>Company:</td>
                        <td>{{paymentBatch.company}}</td>
                    </tr>
                    <tr>
                        <td style="vertical-align: top;">Description:</td>
                        <td>
                            <span *ngIf="!canModifyDescription">{{paymentBatch.description}}</span>
                            <textarea *ngIf="canModifyDescription"
                                type="text"
                                rows="3"
                                maxlength="500"
                                class="form-control"
                                [(ngModel)]="paymentBatch.description">
                            </textarea>
                        </td>
                    </tr>
                    <tr>
                        <td>Batch #:</td>
                        <td>{{paymentBatch.batchNo}}</td>
                    </tr>
                    <tr>
                        <td>Batch Type:</td>
                        <td *ngIf="isTransmittal">{{paymentBatch.batchTypeName}}</td>
                        <td *ngIf="!isTransmittal">{{paymentBatch.batchTypeName}}&nbsp;&nbsp;&nbsp;[{{paymentBatch.workflowName}}]</td>
                    </tr>
                </table>
            </div>
            <div class="col-md-4">
                <table>
                    <tr>
                        <td>Total Payments:</td>
                        <td>{{paymentBatch.paymentCount}}</td>
                    </tr>
                    <tr>
                        <td>Total Payment Amount:</td>
                        <td>{{paymentBatch.paymentAmount | currency}}</td>
                    </tr>
                    <tr *ngIf="!isTransmittal">
                        <td [style.vertical-align]="canModifyFundedAmount ? 'middle' : 'top'">Total Funded Amount:</td>
                        <td>
                            <span *ngIf="!canModifyFundedAmount">{{paymentBatch.fundedAmount | currency}}</span>
                            <ws-currency-input *ngIf="canModifyFundedAmount"
                                [hasMargin]="false"
                                [(ngModel)]="paymentBatch.fundedAmount">
                            </ws-currency-input>
                        </td>
                    </tr>
                    <tr>
                        <td>Earliest Due Date:</td>
                        <td>
                            <div *ngIf="paymentBatch.earliestDueDate">
                                {{paymentBatch.earliestDueDate | weissmanDateFormat}}
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="!isTransmittal">
                        <td>Funding Due Date:</td>
                        <td>
                            <div *ngIf="paymentBatch.fundingDueDate">
                                {{paymentBatch.fundingDueDate | weissmanDateFormat}}
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="col-md-3" style="display: flex; flex-direction: column; align-items: start; justify-content: end; gap: 20px;">
                <div style="color: var(--ace-danger)"
                    *ngIf="paymentBatch.paymentAmount === 0">
                    Payment Batch cannot be advanced; total payment amount of Batch is $0.
                </div>
                <div style="color: var(--ace-danger)"
                    *ngIf="paymentBatch.isCheckFeedDirty">
                    A change was made to Payments of the Batch that may have invalidated the AP and Check Feeds. Regeneration of the feeds is recommended.
                </div>
                <div style="color: var(--ace-danger)"
                    *ngIf="paymentBatch.isAPFeedDirty && !paymentBatch.isCheckFeedDirty">
                    A change was made to Payments of the Batch that may have invalidated the AP Feed. Regeneration of the feed is recommended.
                </div>
                <div>
                    <button class="ace-btn btn-sm btn-primary"
                        *ngIf="isPaymentGridActive"
                        (click)="launchAddPaymentsModal()"
                        [disabled]="!workflowStatus?.canAddDeletePayments"
                        helpTooltip helpContentId="payment-batch-details.add-payments-button">
                            Add Payment
                    </button>
                    <div style="display: inline-block">
                        <button type="button"
                                *ngIf="workflowStatus?.canImportChecks"
                                (click)="importChecks()"
                                class="ace-btn btn-sm btn-primary">
                                Import Checks
                        </button>
                        <button type="button"
                                *ngIf="workflowStatus?.canFinalizeCheckImport"
                                (click)="finalizeCheckImport()"
                                class="ace-btn btn-sm btn-primary">
                                Finalize Check Import
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
