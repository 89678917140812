import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wsAbsoluteUrl' })
export class WeissmanAbsoluteUrlPipe implements PipeTransform {
    transform(url: string): string {
        const startingUrl = 'http://';
        const httpsStartingUrl = 'https://';
        if(url && (url.indexOf(startingUrl) === 0 || url.indexOf(httpsStartingUrl) === 0)){
            return url;
        }
        else {
            return startingUrl + url;
        }
    }
}