import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ChangeHistoryModalComponent } from './change-history-modal.component';

@Injectable(
    { providedIn: 'root' }
)
export class ChangeHistoryModalLaunchService {
    constructor(private modalService: BsModalService) { }

    customTemplateModalRef: BsModalRef;

    openChangeHistoryModal(title: string, id: number, entityTypeId: number, subType: string) {
        const initialState = {
            title: title,
            id: id,
            entityTypeId: entityTypeId,
            subType: subType
        };

        this.modalService.show(ChangeHistoryModalComponent, {initialState, class: 'modal-change-history'});
    }
}
