<div>
    <loading-spinner [show]="loading" [size]="5"></loading-spinner>
    <form [formGroup]="attachmentForm" *ngIf="!loading">
        <div class="mb-4">
            <label for="type">Type</label>
            <ws-select name="type"
                       id="type"
                       formControlName="type"
                       labelProperty="displayName"
                       [returnEntireOption]="true"
                       [options]="attachmentTypes | sortBy:'asc':'displayName'"
                       [disabled]="disableAttachmentType">
            </ws-select>
            <span *ngIf="(type.dirty && (type.errors && type.errors.required))" class="text-danger">Type is required.</span>
        </div>
        <div class="mb-4">
            <label for="taxYear">Year</label>
            <ws-select id="taxYear"
                       name="taxYear"
                       labelProperty="name"
                       formControlName="year"
                       [options]="years">
            </ws-select>
            <span *ngIf="(year.errors && year.errors.required)" class="text-danger">Year is required for the selected type.</span>
        </div>
        <div class="mb-4">
            <label for="attachmentDescription">Description</label>
            <textarea id="attachmentDescription"
                      rows="3"
                      class="form-control"
                      formControlName="description"
                      maxlength="255">
            </textarea>
        </div>
        <div class="mb-4" *ngIf="!attachment?.attachmentID">
            <label>File Upload</label>
            <ws-file-picker (picked)="attachmentFilePicked($event)"></ws-file-picker>
        </div>
        <div class="mb-4" *ngIf="attachment?.attachmentID">
            <button class="ace-btn btn-sm btn-primary btn-danger"
                    (click)="deleteAttachment()"
                    [disabled]="isSaving">
                <span *ngIf="!isDeleting">Delete Attachment</span><span *ngIf="isDeleting">Deleting...</span>
            </button>
        </div>
        <p *ngIf="attachment?.type?.isRyanInternal" title="Attachment visibility is determined by type">
            Only visible to Ryan personnel
        </p>
        <div class="ws-button-row ace-button-container">
            <button type="submit"
                    class="ace-btn btn-primary"
                    (click)="save()"
                    [disabled]="attachmentForm.invalid || isSaving || isDeleting || (!attachment?.attachmentID && !file.value)">
                {{submitButtonLabel}}
            </button>
            <button class="ace-btn btn-secondary"
                    (click)="cancel()"
                    [disabled]="isSaving || isDeleting">Cancel
            </button>
        </div>
    </form>
</div>
