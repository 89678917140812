<div class="tax-bills" *ngIf="viewModel && viewModel.model.length > 0" [style.overflow]="isDocumentProcessing ? 'auto' : 'initial'">
  <table class="labels">
    <thead>
      <tr>
        <th style="height: 36px; position: relative;">
          <i class="fa fa-plus-circle" style="position: absolute; cursor: pointer; right: 0; top: 4px; z-index: 3;" [hidden]="showExtraFields.bill" (click)="showExtraFields.bill = true"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngIf="viewModel.hasAvailableTaxSetup" (click)="openTaxRateSetup()">
            <span
                [class]="viewModel.processingTaxSetupResult ? 'overridden-anchor-style' : 'anchor-style'"
                [tooltip]="showFirstEncounterIndicator ? 'First encounter with tax authority detected' : null"
                container="body"
            >
                <i *ngIf="showFirstEncounterIndicator" class="fa fa-warning"></i>
                Bill Amt:
            </span>
        </td>
        <td *ngIf="!(viewModel.hasAvailableTaxSetup)">Bill Amt:</td>
      </tr>
      <tr *ngIf="showExtraFields.bill">
        <td>Direct Asmt:</td>
      </tr>
      <tr *ngIf="showAssocRevision()">
        <td>Assoc. Revision:</td>
      </tr>
      <tr *ngIf="showSupplemental()">
        <td></td>
      </tr>
      <tr *ngIf="showProration()">
        <td>Proration %:</td>
      </tr>
      <tr>
        <td>Payment Option:</td>
      </tr>
      <tr *ngIf="showActualRow">
        <td></td>
      </tr>
      <tr *ngIf="showAutoCalculateCheckboxRow()">
        <td></td>
      </tr>
      <tr>
        <td style="position: relative;">
          <i class="fa fa-plus-circle" [hidden]="showExtraFields.payment" style="position: absolute; right: 0; top: 9px; cursor: pointer;" (click)="showExtraFields.payment = true" ngbTooltip="Expand" placement="top"></i>
        </td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Base Payment Amt:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Discount Amt:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Interest:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Penalty:</td>
      </tr>
      <tr>
        <td>Payment Amt:</td>
      </tr>
      <tr>
        <td [style.padding-top]="paymentDuePadding">Payment Due:</td>
      </tr>
      <tr *ngIf="hasExemptions || showExtraFields.payment || editState.editMode">
          <td>Exemption Amt:</td>
      </tr>
      <tr *ngIf="showExcludeFromAccruals(null)">
        <td></td><!--Exclude from Accruals-->
      </tr>
      <tr>
        <td style="height: 46px;">Status:</td>
      </tr>
      <tr>
        <td style="height: 70px;"></td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Check #:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Check Date:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Pmt Post Date:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td>Tax Period Begin:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
          <td>Tax Period End:</td>
      </tr>
      <tr *ngIf="showExtraFields.payment">
        <td></td>
      </tr>
      <tr *ngIf="editState.editMode">
        <td></td>
      </tr>
    </tbody>
  </table>
  <table class="tax-bill" [ngClass]="{'estimated-bill': !bill.status}" *ngFor="let bill of viewModel.model">
    <thead>
      <tr>
        <th [colSpan]="bill.payments.length" style="position: relative">
          {{bill.name || "Bill"}}
          <div class="float-end">
            <entity-in-progress-indicator *ngIf="bill.hasInProgressIntakeItems && !isDocumentProcessing" [entityId]="bill.billID" [entityTypeId]="taxBillEntityTypeId" [isDocumentProcessing]="isDocumentProcessing"></entity-in-progress-indicator>
            <entity-attachments
              [(hasAttachments)]="bill.hasAttachments"
              buttonClass="has-icon"
              [analyticsCategory]="analyticsCategory"
              [modalData]="getAttachmentModalData(bill)">
            </entity-attachments>
            <entity-comments
              [(hasComments)]="bill.hasComments"
              buttonClass="has-icon"
              [analyticsCategory]="analyticsCategory"
              [modalData]="getCommentModalData(bill)">
            </entity-comments>
          </div>
        </th>
      </tr>
    </thead>
    <tbody (change)="setDirty()">
      <!-- Gross Bill Amount -->
      <tr>
        <td [colSpan]="bill.payments.length" style="text-align: center">
          <input
            type="text"
            class="form-control text-end"
            style="max-width: 200px; margin: 0 auto;"
            [hidden]="!billPropertyIsEditable(bill)"
            [(ngModel)]="bill.billAmount"
            (ngModelChange)="setDirty()"
            (focus)="oldBillAmount = bill.billAmount"
            (blur)="billAmountBlurred(oldBillAmount, bill)"
            required
            [min]="0"
            currencyMask
            [options]="{ allowNegative: false, precision: 2 }">
          <span [hidden]="billPropertyIsEditable(bill)" style="margin: 0 auto;">{{bill.billAmount | currency:'USD'}}</span>
        </td>
      </tr>
      <!-- Direct Asmt -->
      <tr *ngIf="showExtraFields.bill">
        <td [colSpan]="bill.payments.length" style="text-align: center">
          <input
            type="text"
            class="form-control text-end"
            style="max-width: 200px; margin: 0 auto;"
            [hidden]="!billPropertyIsEditable(bill)"
            [(ngModel)]="bill.directAsmt"
            (ngModelChange)="setDirty()"
            (focus)="captureVal(bill, 'directAsmt')"
            (blur)="revertDirectAsmtIfInvalid(bill)"
            required
            currencyMask
            [options]="{ allowNegative: false, precision: 2 }">
          <span [hidden]="billPropertyIsEditable(bill)" style="margin: 0 auto;">{{bill.directAsmt || 0 | currency:'USD'}}</span>
        </td>
      </tr>
      <!-- Assoc. Revision -->
      <tr *ngIf="showAssocRevision()">
        <td style="text-align: center;" [colSpan]="bill.payments.length">
            <select class="form-select form-select-sm" *ngIf="viewModel.yearRevisions.length && assocRevisionIsEditable(bill)" [(ngModel)]="bill.annualAssessmentID" (change)="assocRevisionChanged(bill)" [disabled]="disableAssocRevisionDropdown(bill)" style="max-width: 200px; margin: 0 auto;">
              <option *ngIf="!isDocumentProcessing" [ngValue]="null"></option>
              <option *ngFor="let option of viewModel.yearRevisions" [ngValue]="option.annualAssessmentID">{{option.revisionDesc}}</option>
            </select>
            <span *ngIf="bill.annualAssessmentID && !assocRevisionIsEditable(bill)">{{getAssocRevisionDesc(bill)}}</span>
        </td>
      </tr>
      <!-- Supplemental Tax Bill -->
      <tr *ngIf="showSupplemental()">
        <td [colSpan]="bill.payments.length" style="text-align: center;">
          <div class="checkbox" style="margin-left: 7px;">
            <label>
              <input type="checkbox" [disabled]="!billPropertyIsEditable(bill)" [(ngModel)]="bill.isSupplemental" (ngModelChange)="setDirty()">Supplemental
            </label>
          </div>
        </td>
      </tr>
      <tr *ngIf="showProration()">
        <td [colSpan]="bill.payments.length" style="text-align: center;">
          <input
            type="text"
            class="form-control"
            currencyMask
            (ngModelChange)="prorationChange(bill, $event)"
            [hidden]="!billPropertyIsEditable(bill)"
            [options]="{ allowNegative: false, precision: 4, prefix: '', suffix: '%' }"
            [(ngModel)]="bill.displayProrationPct">
            <span [hidden]="billPropertyIsEditable(bill)" style="margin: 0 auto;">{{bill.displayProrationPct | number:"1.4-4"}}%</span>
        </td>
      </tr>
      <!-- Payment Option -->
      <tr>
        <td style="text-align: center;" [colSpan]="bill.payments.length">
          <div *ngIf="bill.collectorPaymentOptions">
            <select
                [hidden]="!paymentOptionIsEditable(bill)"
                class="form-select form-select-sm"
                [(ngModel)]="bill.collectorPaymentOptionID"
                [disabled]="disablePaymentOptionDropdown(bill)"
                (ngModelChange)="setDirty(); paymentOptionChanged(bill)"
                style="max-width: 200px; margin: 0 auto;">

              <option *ngFor="let option of bill.collectorPaymentOptions" [ngValue]="option.collectorPaymentOptionID">{{option.name}}</option>
            </select>
            <span [hidden]="paymentOptionIsEditable(bill)">{{getBillPaymentOptionName(bill)}}</span>
          </div>
        </td>
      </tr>
      <!-- Actual checkbox -->
      <tr *ngIf="showActualRow">
        <td [colSpan]="bill.payments.length" style="text-align: center;">
          <div class="checkbox" style="margin-left: 7px;" *ngIf="bill.showActualCheckbox">
            <label>
              <input type="checkbox" [disabled]="bill.calcProjected || !editState.editMode" [checked]="bill.status === 1" (change)="changeActual(bill)">Actual
            </label>
          </div>
        </td>
      </tr>
      <!--  Auto Calculate -->
      <tr *ngIf="showAutoCalculateCheckboxRow()">
        <td [colSpan]="bill.payments.length" style="text-align: center;">
          <div class="checkbox" *ngIf="showAutoCalculateCheckbox(bill)">
            <label>
              <input type="checkbox" [disabled]="!editState.editMode || (isDocumentProcessing && !bill.calcProjected)" [(ngModel)]="bill.calcProjected" (ngModelChange)="calcProjectedChanged(bill)">Auto Calculate
            </label>
          </div>
        </td>
      </tr>
      <!-- Payment Name -->
      <tr>
        <td class="payment-cell" *ngFor="let payment of bill.payments" style="font-weight: bold; text-align:center; border-top: 1px dashed lightgray;">{{payment.name}} <i [hidden]="!showClose(bill, payment)" (click)="deletePayment(bill, payment)" class="fa fa-close float-end" style="color: var(--ace-danger); cursor: pointer;"></i>
        </td>
      </tr>
      <!-- Base Payment Amount -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <input
            type="text"
            class="form-control text-end"
            [hidden]="!paymentAmountIsEditable(bill, payment)"
            [(ngModel)]="payment.grossPayment"
            (ngModelChange)="setDirty(); paymentPropChanged(payment)"
            (focus)="captureVal(payment, 'grossPayment')"
            (blur)="grossPaymentChanged(bill, payment)"
            [min]="0"
            currencyMask
            [options]="{ allowNegative: false, precision: 2 }">
          <span [hidden]="paymentAmountIsEditable(bill, payment)">{{payment.grossPayment | currency:'USD'}}</span>
        </td>
      </tr>
      <!-- Discount Amount -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <input
            type="text"
            class="form-control text-end"
            [hidden]="!paymentPropertyIsEditable(bill, payment)"
            [(ngModel)]="payment.discountAmount"
            (ngModelChange)="setDirty();"
            (focus)="captureVal(payment, 'discountAmount')"
            (blur)="makeDiscountNegative(payment); revertIfNegative(payment, 'discountAmount')"
            currencyMask
            [options]="{ allowNegative: true, precision: 2 }">
          <span [hidden]="paymentPropertyIsEditable(bill, payment)">{{payment.discountAmount | currency:'USD'}}</span>
        </td>
      </tr>
      <!-- Interest Amount -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <div>
            <input
              type="text"
              class="form-control text-end"
              [hidden]="!paymentPropertyIsEditable(bill, payment)"
              [(ngModel)]="payment.interestAmount"
              (ngModelChange)="setDirty(); paymentPropChanged(payment)"
              (focus)="captureVal(payment, 'interestAmount')"
              (blur)="penaltyOrInterestAmountChanged(payment, 'interestAmount')"
              [min]="0"
              currencyMask
              [options]="{ allowNegative: false, precision: 2 }">
            <span [hidden]="paymentPropertyIsEditable(bill, payment)">{{payment.interestAmount | currency:'USD'}}</span>
          </div>
        </td>
      </tr>
      <!-- Penalty Amount -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <div style="border-bottom: 1px solid black;">
            <input
              type="text"
              class="form-control text-end"
              [hidden]="!paymentPropertyIsEditable(bill, payment)"
              [(ngModel)]="payment.penaltyAmount"
              (ngModelChange)="setDirty(); paymentPropChanged(payment)"
              (focus)="captureVal(payment, 'penaltyAmount')"
              (blur)="penaltyOrInterestAmountChanged(payment, 'penaltyAmount')"
              [min]="0"
              currencyMask
              [options]="{ allowNegative: false, precision: 2 }">
            <span [hidden]="paymentPropertyIsEditable(bill, payment)">{{payment.penaltyAmount | currency:'USD'}}</span>
          </div>
        </td>
      </tr>
      <!-- Payment Amount -->
      <tr>
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <input
            type="text"
            class="form-control text-end"
            [hidden]="showExtraFields.payment || !paymentAmountIsEditable(bill, payment)"
            [(ngModel)]="payment.paymentAmount"
            (ngModelChange)="setDirty(); paymentAmountChanged()"
            (blur)="paymentAmountBlurred(bill, payment)"
            (focus)="captureVal(payment, 'grossPayment')"
            [min]="0"
            currencyMask
            [options]="{ allowNegative: false, precision: 2 }">
          <span [hidden]="!showExtraFields.payment && paymentAmountIsEditable(bill, payment)">{{payment.paymentAmount | currency:'USD'}}</span>
        </td>
      </tr>
      <!-- Due Date -->
      <tr>
        <td class="payment-cell" *ngFor="let payment of bill.payments" [ngClass]="{'red': dateOverridden(payment, 'dueDate')}">
          <div *ngIf="paymentPropertyIsEditable(bill, payment) && payment.dueDateChanged" style="color: var(--ace-danger); font-size: 75%; text-align: left;">
            <div *ngIf="dueDateIsInPast(payment.dueDate)">WARNING! Payment Due date is in the past</div>
            <div *ngIf="dueDateIsLaterThanOriginal(payment)">WARNING! The date entered is later <br> than the system default date</div>
          </div>
          <div  style="position: relative;">
            <weissman-datetime [(inputDate)]="payment.dueDate"
              (change)="setDirty(); editState.refreshActivity = true; payment.dueDateChanged = true;"
              [dateOnly]="true"
              [readOnly]="!paymentPropertyIsEditable(bill, payment)"
              (inputDateBlur)="maybeRevertToOriginalDate(payment, 'dueDate')">
            </weissman-datetime>
            <button class="ace-btn has-icon btn-primary btn-danger"
                style="margin: 0; position:absolute; top: 3px; right: 0;"
                *ngIf="paymentPropertyIsEditable(bill, payment) && dateOverridden(payment, 'dueDate')"
                (click)="undoDate(payment, 'dueDate')">
                    <i class="fa fa-undo"></i>
            </button>
          </div>
        </td>
      </tr>
      <!-- ExemptionAmount -->
      <tr *ngIf="hasExemptions || showExtraFields.payment || editState.editMode">
          <td class="payment-cell" *ngFor="let payment of bill.payments">
              <input
                  type="text"
                  class="form-control text-end"
                  [hidden]="!paymentPropertyIsEditable(bill, payment)"
                  [(ngModel)]="payment.exemptionAmount"
                  (ngModelChange)="setDirty();"
                  (focus)="captureVal(payment, 'exemptionAmount')"
                  (blur)="makeExemptionNegative(payment); revertIfNegative(payment, 'exemptionAmount')"
                  currencyMask
                  [options]="{ allowNegative: true, precision: 2 }">
              <span [hidden]="paymentPropertyIsEditable(bill, payment)">{{payment.exemptionAmount | currency:'USD'}}</span>
          </td>
      </tr>
      <!-- Exclude from Accruals -->
      <tr *ngIf="showExcludeFromAccruals(bill)">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <div *ngIf="payment.accrualsExclude.parentExcludedFromAccruals">
              <span style="margin-left: 2px; font-weight: bold;">Parent Excluded From Accruals</span>
          </div>
          <div class="checkbox" *ngIf="!payment.accrualsExclude.parentExcludedFromAccruals">
              <label>
                  <input type="checkbox"
                      [(ngModel)]="payment.accrualsExclude.excludeFromAccruals"
                      (ngModelChange)="excludeFromAccrualsChanged()"
                      [disabled]="!editState.editMode">
                  <span style="margin-left: 2px; font-weight: bold;">Exclude From Accruals</span>
              </label>
          </div>
        </td>
      </tr>
      <!-- Processed Date / Status -->
      <tr>
        <td class="payment-cell" *ngFor="let payment of bill.payments" style="white-space: normal; height: 46px;">
          <i class="fa fa-check" [hidden]="!payment.taskSummary || !payment.taskSummary.SeriesIsComplete" style="color: green;float: left; font-size: 16px;"></i>
          <span *ngIf="payment.taskSummary">
            <a class="clickable" [hidden]="!payment.taskSummary.Status" (click)="launchTaskModalBill(bill)">{{ payment.taskSummary.Status }}  <span [hidden]="!payment.taskSummary.CompletedDateTime">{{ payment.taskSummary.CompletedDateTime | weissmanDateFormat: true: "Central"}}</span></a>
            <span [hidden]="payment.taskSummary.Status">N/A - No Responsibilities</span>
          </span>
          <span [hidden]="payment.paymentID">Save To view Status</span>
          <i *ngIf="payment.taskSummary" class="fa fa-chain-broken float-end" [hidden]="payment.taskSummary.Status" aria-hidden="true"></i>
        </td>
      </tr>
      <tr>
        <td class="payment-cell" *ngFor="let payment of bill.payments" style="height: 70px;">
            <div *ngIf="payment.belongsToPaymentBatchId && payment.belongsToPaymentBatchNo">
                <a anchor-route [sref]="getPaymentBatchDetailsSref(payment)" [isTargetBlank]="true">
                    Payment Batch {{payment.belongsToPaymentBatchNo}}
                </a>
            </div>
            <div *ngIf="enableTaxPaymentAddressLink">
              <a class="clickable"
                 (click)="openParcelCollectorAddressModal(bill.billClusterID, payment)">
                  Tax Payment Address
              </a>
            </div>
            <div *ngIf="payment.completedPaymentPackageCount > 0">
                <a class="clickable"
                   (click)="openTransmittalDetailsModal(payment)">
                    Transmittal
                </a>
            </div>
            <div *ngIf="payment.taskSummary && payment.taskSummary.TaxBillReceived && !payment.taskSummary.ReviewPaymentComplete">
                <a class="clickable"
                   (click)="openPaymentStubModal(bill.billID, payment)"
                   [style.text-decoration]="payment.attachmentID ? 'initial' : 'line-through'">
                    Bill Image
                </a>
            </div>
        </td>
      </tr>
      <!-- check number -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <input type="text" [(ngModel)]="payment.checkNumber" (ngModelChange)="setDirty()" class="form-control" [hidden]="!editState.editMode || bill.calcProjected">
          <span [hidden]="editState.editMode && !bill.calcProjected">{{payment.checkNumber}}</span>
        </td>
      </tr>
      <!-- check date -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <weissman-datetime [(inputDate)]="payment.checkDate" (change)="setDirty()" [dateOnly]="true" [readOnly]="!editState.editMode || bill.calcProjected"></weissman-datetime>
        </td>
      </tr>
      <!-- Pmt Post Date -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <weissman-datetime [(inputDate)]="payment.postDate" (change)="setDirty()" [dateOnly]="true" [readOnly]="!editState.editMode || bill.calcProjected"></weissman-datetime>
        </td>
      </tr>
      <!-- accrual begin -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments" [ngClass]="{'red': dateOverridden(payment, 'accrualBegin')}" style="position: relative;">
          <weissman-datetime [(inputDate)]="payment.accrualBegin" (change)="setDirty()" [dateOnly]="true" [readOnly]="!paymentPropertyIsEditable(bill, payment)" (inputDateBlur)="maybeRevertToOriginalDate(payment, 'accrualBegin')"></weissman-datetime>
           <button class="ace-btn has-icon btn-primary btn-danger"
              style="margin: 0; position:absolute; top: 3px; right: 0;"
              *ngIf="paymentPropertyIsEditable(bill, payment) && dateOverridden(payment, 'accrualBegin')"
              (click)="undoDate(payment, 'accrualBegin')">
                  <i class="fa fa-undo"></i>
          </button>
        </td>
      </tr>
      <!-- accrual end -->
      <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments" [ngClass]="{'red': dateOverridden(payment, 'accrualEnd')}" style="position: relative;">
          <weissman-datetime [(inputDate)]="payment.accrualEnd" (change)="setDirty()" [dateOnly]="true" [readOnly]="!paymentPropertyIsEditable(bill, payment)" (inputDateBlur)="maybeRevertToOriginalDate(payment, 'accrualEnd')"></weissman-datetime>
           <button class="ace-btn has-icon btn-primary btn-danger"
              style="margin: 0; position:absolute; top: 3px; right: 0;"
              *ngIf="paymentPropertyIsEditable(bill, payment) && dateOverridden(payment, 'accrualEnd')"
              (click)="undoDate(payment, 'accrualEnd')">
                  <i class="fa fa-undo"></i>
          </button>
        </td>
      </tr>
      <!-- previously paid -->
      <!-- <tr *ngIf="showExtraFields.payment">
        <td class="payment-cell" *ngFor="let payment of bill.payments">
          <div class="checkbox">
            <label>
              <input type="checkbox" [(ngModel)]="payment.previouslyPaid" [disabled]="!billPropertyIsEditable(bill)"> Previously Paid
            </label>
          </div>
        </td>
      </tr> -->
      <!-- Add Payment Button -->
      <tr *ngIf="editState.editMode">
        <td [colSpan]="bill.payments.length" [class.no-height]="!showAdd(bill)">
          <button [hidden]="!showAdd(bill)" class="ace-btn btn-sm btn-primary" (click)="addPayment(bill)"><i class="fa fa-plus"></i> Add Pmt</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<!-- Using Angular 1.x version. Keeping this here for upgrade at a later date -->
<!-- <payment-stub-modal #PaymentStubModal [parameters]="paymentStubParams" (onSaved)="updatePaymentAttachmentID($event)"></payment-stub-modal> -->

<payment-packages-modal #PaymentPackageModal>
</payment-packages-modal>

