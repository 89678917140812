<div class="input-group scrollable-typeahead">
    <input class="form-control"
        placeholder="Search Columns..."
        [(ngModel)]="typeaheadColumnToAdd"
        [typeahead]="filterColumns()"
        typeaheadOptionField="typeAheadName"
        [typeaheadOptionsLimit]="100"
        [typeaheadMinLength]="2"
        [disabled]="!columnsLoaded"
        container="body"
        (typeaheadOnSelect)="columnSelected($event)">
    <button type="button"
        class="ace-btn has-icon btn-secondary"
        (click)="openBrowseModal()"
        [disabled]="!columnsLoaded">
            <i class="fa fa-binoculars"></i>
    </button>
</div>
