<div class="ws-bread-crumbs" *ngIf="breadcrumbs && breadcrumbs.length">
    <ol class="breadcrumb float-start">
        <li class="breadcrumb-item" *ngFor="let crumb of breadcrumbs; let last = last">
            <a class="ace-btn btn-link pointer"
               anchor-route
               [sref]="crumb">{{ crumb.name }}</a>
        </li>
        <li class="breadcrumb-item active"><span class="current-crumb">{{ currentPageTitle }}</span></li>
    </ol>
</div>
