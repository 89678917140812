import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'warning-message',
    styleUrls: ['./warningMessage.component.scss'],
    template: `
        <div *ngIf="show" class="warning-message" clickOutside (clickOutside)="closeDetails()">
            <div class="warning-message-content">
                <div class="warning-message-bar" [class.clickable]="hasDetails" (click)="toggleDetails()">
                    <div class="pop-icon">
                        <i class="fa fa-warning"></i>
                    </div>
                    <div class="text-container">
                        <span class=stretch-text>
                            {{message}}
                            <i *ngIf="hasDetails" class="fa fa-caret-right show-details" [class.open]="showDetails"></i>
                        </span>
                    </div>
                </div>
                <div #details *ngIf="hasDetails" class="warning-details"
                     [class.open]="showDetails"
                     [ngStyle]="{ 'transform': 'translateX(' + edgeOffsetPercent + '%)' }">
                    <ng-content></ng-content>
                    <div class="close-details" (click)="closeDetails()">
                        <i class="fa fa-caret-up"></i>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class WarningMessageComponent {

    @Input() message: string;
    @Input() show: boolean;
    @Input() hasDetails: boolean;
    @Input() showDetails: boolean = false;
    @Output() showDetailsChange: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('details') warningDetails: ElementRef;

    edgeOffsetPercent: number = -50;

    /**
     * Toggle the message details open and closed
     */
    toggleDetails(): void {
        this.showDetails = !this.showDetails;
        this.showDetailsChange.emit(this.showDetails);
        if (this.showDetails) {
            this._calculateEdgeDistance();
        }
    }

    /**
     * Close the message details
     */
    closeDetails(): void {
        this.showDetails = false;
        this.showDetailsChange.emit(this.showDetails);
    }

    private _calculateEdgeDistance(): void {
        const position: DOMRect = this.warningDetails.nativeElement.getBoundingClientRect();
        const isOutsideEdgeBuffer = position.x < 40;
        if (isOutsideEdgeBuffer) {
            this.edgeOffsetPercent = -50 + (((40 - position.x) / position.width) * 100);
        }
    }
}
