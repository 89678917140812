<form [formGroup]="engagementForm">
    <ws-modal-wrapper headerLabel="Consulting Engagement"
                      [acceptLabel]="getSaveLabel()"
                      (save)="saveEngagement()"
                      [suppressKeyboardEvents]="true"
                      [disableSave]="!assignedUserTeam
                        || !assignedUserTeam.userID
                        || !assignedGroups.length"
                      (cancel)="cancel()"
                      [tabindex]="6">
        <div class="mb-4">
            <label>Company Name:</label>
            <div>{{params.engagement.name}}</div>
        </div>
        <div class="mb-4">
            <label>Account Handler:</label>
			<user-team-picker
            name="accountHandler"
            [user]="assignedUserTeam"
            (userChange)="setNewUser($event)"
            [entityIdScope]="instanceId"
            [entityTypeScope]="'Instance'"></user-team-picker>
        </div>
        <div class="mb-4">
            <label>Assigned User Groups:</label>
            <ul class="list-group" style="max-height: 180px; overflow-y: auto;">
                <li class="list-group-item" *ngFor="let group of assignedGroups">
                    <div class="d-flex justify-content-between align-items-center">
                        <a anchor-route [sref]="{target: 'userGroupDetail', options: {userGroupId: group.groupID}}" [isTargetBlank]="true" class="ace-btn btn-link float-start">{{group.name}}</a>
                        <button class="ace-btn has-icon btn-primary btn-danger m-0" (click)="deleteUserGroup(group)"><i class="fa fa-trash"></i></button>
                    </div>
                </li>
            </ul>
        </div>
        <div class="panel-flat-body">
            <div [title]="!availableGroups.length ? 'No consultant user groups are available to assign': ''">
                <button class="ace-btn btn-sm btn-primary btn-success" (click)="selectUserGroupToAdd()" [disabled]="!availableGroups.length ? true : null"  *ngIf="!addingNewGroup">Add User Group</button>
            </div>
            <div *ngIf="addingNewGroup && availableGroups.length">
                <select class="form-select form-select-sm" style="width:auto; display: inline-block;" [(ngModel)]="selectedGroupId" [ngModelOptions]="{standalone: true}">
                    <option *ngFor="let group of availableGroups" value="{{group.groupID}}">{{group.name}}</option>
                </select>
                <button class="ace-btn has-icon btn-primary btn-success" style="margin-left: 10px;" type="button" (click)="addUserGroup()" [attr.disabled]="!selectedGroupId ? true : null"><i class="fa fa-save"></i></button>
                <button class="ace-btn has-icon btn-primary btn-danger" (click)="cancelAddUserGroup()">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
        <div *ngIf="csrs.length > 0">
            <div style="margin-bottom: 20px;">
                <div style="margin-bottom:5px;"><strong>Existing CSRs Assigned</strong></div>
                <div *ngFor="let csr of csrs">
                    <!-- property type abbreviation colored square-->
                    <div class="property-type" style="margin-bottom:5px;">
                        <span class="label label-primary property-type-{{getPropTypeAbbr(csr)}}" style="margin-right:10px;">
                            {{getPropTypeAbbr(csr)}}
                        </span> {{csr.name}}
                    </div>
                </div>
            </div>
            <div *ngIf="accountHandlerChanged" class="alert alert-warning">
                <i class="fa fa-info-circle fa-sm"></i> Changing the Account Handler will reassign the above CSRs from {{params.engagement.accountHandler.firstName}} {{params.engagement.accountHandler.lastName}} ({{params.engagement.accountHandler.teamName}}) to your new selection
            </div>
        </div>
    </ws-modal-wrapper>
</form>
