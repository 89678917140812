<div *ngIf="canZoom" class="zoom-controls ws-flex-container-horizontal ws-flex-align-items-center">
    <div class="zoom-percentage">
        <span>Zoom:</span>
        <i *ngIf="zoomScaleUpdating" class="fa fa-spinner fa-pulse"></i>
        <input type="number"
               [ngModel]="zoomPercentage"
               (ngModelChange)="setZoomValue($event)"
               (blur)="submitZoomValue()"
               [attr.min]="min"
               [attr.max]="max"
               step="1"
               [disabled]="zoomScaleUpdating">
        <span class="percent-sign">%</span>
    </div>
    <div class="zoom-buttons ws-flex-container-horizontal">
        <button class="ace-btn btn-secondary has-icon"
                (click)="zoomOut()"
                [disabled]="zoomScaleUpdating || zoomPercentage === min">
            <i class="fa fa-minus"></i>
        </button>
        <button class="ace-btn btn-secondary has-icon"
                (click)="zoomIn()"
                [disabled]="zoomScaleUpdating || zoomPercentage === max">
            <i class="fa fa-plus"></i>
        </button>
    </div>

</div>
