import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import * as _ from 'lodash';

declare const moment: any;

enum UserAccessReportLimitByLoginDateEnum {
    Period = Core.UserAccessReportLimitByLoginDateEnum.Period as number,
    DateRange = Core.UserAccessReportLimitByLoginDateEnum.DateRange as number,
    AnyValidDate = Core.UserAccessReportLimitByLoginDateEnum.AnyValidDate as number
}

@Component({
    selector: 'further-limit-panel-user-activity',
    template: `
        <div class="row">
            <div class="col col-md-6">
                <div class="form-inline">
                    <input type="radio" name="limit-by-login-date" [(ngModel)]="report.criteria.limitByLoginDate" [value]="UserAccessReportLimitByLoginDateEnum.Period" (change)="loginDateChoiceChanged()">
                    <span>Login Period </span>

                    <select class="form-select-sm" [(ngModel)]="report.criteria.loginDatePeriod" [disabled]="report.criteria.limitByLoginDate !== UserAccessReportLimitByLoginDateEnum.Period" (change)="loginDateChoiceChanged()">
                        <option *ngFor="let option of periodOptions" [ngValue]="option.val">{{option.label}}</option>
                    </select>
                </div>
                <div class="form-inline" style="margin-top: 10px;">
                    <input type="radio" name="limit-by-login-date" [(ngModel)]="report.criteria.limitByLoginDate" [value]="UserAccessReportLimitByLoginDateEnum.DateRange" (change)="loginDateChoiceChanged()">
                    <span>Login Date Range </span>
                    <weissman-datetime [(inputDate)]="loginDateBeginUI"
                                       [dateOnly]="true"
                                       [timeZone]="'Central'"
                                       [isDisabled]="report.criteria.limitByLoginDate !== UserAccessReportLimitByLoginDateEnum.DateRange"
                                       (change)="loginDateChoiceChanged()">
                    </weissman-datetime>
                    to
                    <weissman-datetime [(inputDate)]="loginDateEndUI"
                                       [dateOnly]="true"
                                       [timeZone]="'Central'"
                                       [rangeEnd]="true"
                                       [isDisabled]="report.criteria.limitByLoginDate !== UserAccessReportLimitByLoginDateEnum.DateRange"
                                       (change)="loginDateChoiceChanged()">
                    </weissman-datetime>
                </div>
                <div class="form-inline" style="margin-top: 10px;">
                    <input type="radio" name="limit-by-login-date" [(ngModel)]="report.criteria.limitByLoginDate" [value]="UserAccessReportLimitByLoginDateEnum.AnyValidDate" (change)="loginDateChoiceChanged()">
                    <span>Any Valid Login Date</span>
                </div>
            </div>
            <div class="col col-md-6">
                <div>
                    <label>
                        <ws-switch [(ngModel)]="report.criteria.onlyActiveUsers" style="margin-left:30px; margin-right: 10px;"></ws-switch> Only Active Users
                    </label>
                </div>
                <div>
                    <label>
                        <ws-switch [(ngModel)]="report.criteria.includeUsersWithoutLogin" style="margin-left:30px; margin-right: 10px;"></ws-switch> Include Users Without Login Date
                    </label>
                </div>
                <div>
                    <label>
                        <ws-switch [(ngModel)]="report.criteria.includeInternalUsers" style="margin-left:30px; margin-right: 10px;"></ws-switch> Include Internal Users
                    </label>
                </div>
            </div>
        </div>

    `
})
export class FurtherLimitPanelUserActivityComponent implements OnInit {
    @Input() report: ReportDetail;
    taxYearBeginUI: number;
    propertyTypes: any[];
    loginDateBeginUI: Date;
    loginDateEndUI: Date;
    periodOptions: {val: number, label: string}[] = [{
        val: 90,
        label: '90  Days'
    }, {
        val: 60,
        label: '60  Days'
    }, {
        val: 30,
        label: '30  Days'
    }];

    UserAccessReportLimitByLoginDateEnum = UserAccessReportLimitByLoginDateEnum;

    ngOnInit() {
        if(_.isUndefined(this.report.criteria.limitByLoginDate)) {
            this.report.criteria.limitByLoginDate = Core.UserAccessReportLimitByLoginDateEnum.Period;
        }
        this.report.criteria.loginDatePeriod = this.report.criteria.loginDatePeriod || 90;
        this.loginDateBeginUI = this.report.criteria.loginDateBegin;
        this.loginDateEndUI = this.report.criteria.loginDateEnd;
        this.loginDateChoiceChanged();

        this.report.criteria.onlyActiveUsers = this.report.criteria.onlyActiveUsers || false;
        this.report.criteria.includeUsersWithoutLogin = this.report.criteria.includeUsersWithoutLogin || false;
        this.report.criteria.includeInternalUsers = this.report.criteria.includeInternalUsers || false;
    }

    loginDateChoiceChanged(): void {
        switch(this.report.criteria.limitByLoginDate) {
            case Core.UserAccessReportLimitByLoginDateEnum.Period:
                this.report.criteria.loginDateBegin = moment().tz('America/Chicago').subtract(this.report.criteria.loginDatePeriod, 'days').toDate();
                this.report.criteria.loginDateEnd = moment().tz('America/Chicago').toDate();
                break;
            case Core.UserAccessReportLimitByLoginDateEnum.DateRange:
                this.report.criteria.loginDateBegin = this.loginDateBeginUI;
                this.report.criteria.loginDateEnd = this.loginDateEndUI;
                break;
        }
    }
}
