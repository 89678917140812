<div class="tax-bills" *ngIf="viewModel && viewModel.model" (change)="setDirty()">
  <table class="labels">
    <tr>
      <th style="height: 40px;"></th>
    </tr>
    <tr>
      <td>Refund Amt:</td>
    </tr>
    <tr>
      <td>Expected Date:</td>
    </tr>
    <tr>
      <td>Revision:</td>
    </tr>

    <tr>
      <td>Check #:</td>
    </tr>
    <tr>
      <td style="height: 46px;">Status:</td>
    </tr>
    <tr>
        <td>Tax Period Begin:</td>
    </tr>
    <tr>
        <td>Tax Period End:</td>
    </tr>
  </table>
  <table class="tax-bill">
    <tr>
      <th style="padding-bottom: 5px;">
        Refund
        <div class="float-end">
          <entity-in-progress-indicator *ngIf="viewModel.model.hasInProgressIntakeItems && !isDocumentProcessing" [entityId]="viewModel.model.refundID" [entityTypeId]="refundEntityTypeId" [isDocumentProcessing]="isDocumentProcessing"></entity-in-progress-indicator>
          <entity-attachments [(hasAttachments)]="viewModel.model.hasAttachments" buttonClass="has-icon" [modalData]="getAttachmentModalData()">
          </entity-attachments>
          <entity-comments [(hasComments)]="viewModel.model.hasComments" buttonClass="has-icon" [modalData]="getCommentModalData()">
          </entity-comments>
          <div style="display: inline-block;" [hidden]="isDocumentProcessing || !editState.editMode"><i (click)="deleteRefund()" class="fa fa-close" style="color: var(--ace-danger); cursor: pointer;"></i></div>
        </div>
      </th>
    </tr>
    <tr>
      <td>
        <input
          type="text"
          class="form-control text-end"
          [hidden]="!editState.editMode || viewModel.model.taskSummary.TrackDataEnterRefundComplete"
          (ngModelChange)="refundAmountChanged()"
          (blur)="makeRefundNegative()"
          [(ngModel)]="viewModel.model.refundAmount"
          currencyMask
          [options]="{ allowNegative: true, precision: 2 }">
        <span [hidden]="editState.editMode && !viewModel.model.taskSummary.TrackDataEnterRefundComplete">{{viewModel.model.refundAmount | currency:'USD'}}</span>
      </td>
    </tr>
    <tr>
      <td>
        <weissman-datetime [(inputDate)]="viewModel.model.expectedDate" (change)="expectedDateChanged()" [dateOnly]="true" [readOnly]="!editState.editMode || viewModel.model.taskSummary.TrackDataEnterRefundComplete"></weissman-datetime>
      </td>
    </tr>
    <tr>
      <td>
        <select class="form-select form-select-sm"
                style="width: auto"
                [hidden]="!editState.editMode"
                [(ngModel)]="viewModel.model.annualAssessmentID">
               <option *ngFor="let option of annualAssessments" [ngValue]="option.annualAssessmentID"> {{option.revisionDesc}}</option>
        </select>
        <span [hidden]="editState.editMode">{{getRevisionDesc()}}</span>
      </td>
    </tr>
    <!-- check number -->
    <tr>
      <td>
        <input type="text" [(ngModel)]="viewModel.model.checkNumber" (ngModelChange)="setDirty()" class="form-control" [hidden]="!editState.editMode">
        <span [hidden]="editState.editMode">{{viewModel.model.checkNumber}}</span>
      </td>
    </tr>
    <tr>
      <td style="white-space: normal; height: 46px;">
        <i class="fa fa-check" [hidden]="!viewModel.model.taskSummary || !viewModel.model.taskSummary.SeriesIsComplete" style="color: green;float: left; font-size: 16px;"></i>
        <span *ngIf="viewModel.model.taskSummary">
          <a class="clickable" [hidden]="!viewModel.model.taskSummary.Status" (click)="launchTaskModal()">{{ viewModel.model.taskSummary.Status }} <span [hidden]="!viewModel.model.taskSummary.CompletedDateTime">{{ viewModel.model.taskSummary.CompletedDateTime | weissmanDateFormat: true: "Central" || ''}}</span></a>
          <span [hidden]="viewModel.model.taskSummary">N/A - No Responsibilities</span>
        </span>
      </td>
    </tr>
    <tr>
      <td [ngClass]="{'red': dateOverridden('accrualBegin')}" style="position: relative;">
        <weissman-datetime [(inputDate)]="viewModel.model.accrualBegin" (change)="setDirty()" [dateOnly]="true" [readOnly]="!editState.editMode" (inputDateBlur)="maybeRevertToOriginalDate('accrualBegin')"></weissman-datetime>
        <button class="ace-btn has-icon btn-primary btn-danger"
            style="margin: 0; position:absolute; top: 3px; right: 0;"
            *ngIf="editState.editMode && dateOverridden('accrualBegin')"
            (click)="undoDate('accrualBegin')">
                <i class="fa fa-undo"></i>
        </button>
      </td>
    </tr>
    <tr>
      <td [ngClass]="{'red': dateOverridden('accrualEnd')}" style="position: relative;">
        <weissman-datetime [(inputDate)]="viewModel.model.accrualEnd" (change)="setDirty()" [dateOnly]="true" [readOnly]="!editState.editMode" (inputDateBlur)="maybeRevertToOriginalDate('accrualEnd')"></weissman-datetime>
        <button class="ace-btn has-icon btn-primary btn-danger"
            style="margin: 0; position:absolute; top: 3px; right: 0;"
            *ngIf="editState.editMode && dateOverridden('accrualEnd')"
            (click)="undoDate('accrualEnd')">
                <i class="fa fa-undo"></i>
        </button>
      </td>
    </tr>
  </table>
</div>
