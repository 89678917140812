import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { WeissmanDatetimeComponent } from '../../UI-Lib/Weiss-Datepicker/weissman-datetime.component';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailsNavigationEventService } from '../annual-details-event.service';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailAssessment, AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AddAppealModalComponent } from '../Modals/add-appeal.component';
import { Appeal, AppealStatus, AppealStatusID } from './appeal.model';
import { AppealService, AppealViewModel } from './appeal.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { AppealApplicationService } from '../../Appeal-Application/appeal.application.service';
import { TaskType } from '../../constants.new';
import { RestrictService, Roles } from 'src/app/Common/Permissions/restrict.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';

declare const _: any;

// TODO: Create a solution for dealing with consts in Angular 2+ and move this there
const APPEAL_ENTITY_TYPE_ID = 8;

@Component({
    selector: 'appeal',
    templateUrl: './appeal.component.html'
})
export class AppealComponent implements OnInit, OnChanges {
    constructor(private appealService: AppealService,
                private annualDetailsService: AnnualDetailsService,
                private taskService: TaskService,
                public toastr: ToastrService,
                private messageBoxService: MessageBoxService,
                private navigationEvent: AnnualDetailsNavigationEventService,
                private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
                private restrictService: RestrictService,
                private appealApplicationService: AppealApplicationService,
                private readonly _userInstanceService: UserInstanceService,
                public readonly featureFlagsService: FeatureFlagsService
            ) {
        this.appealStatuses = _.sortBy(AppealStatus.appealStatuses, '');
        this.skipTaskAppealStatuses = [AppealStatusID.Withdrawn, AppealStatusID.AddInError];
    }

    @Input() viewModel: AppealViewModel;
    // IMPORTANT: The currentYear input is not present when loading the appeal component from document processing.
    // This property is used in the markup to represent the year in the year panel on the left; if code needs access
    // to a property on the annual year, viewModel.annualYearModel is more suitable than currentYear.
    @Input() currentYear: AnnualDetailYear;
    @Input() currentRevision: AnnualDetailAssessment;
    @Input() assessmentNavigateHandler: (year: AnnualDetailYear, revision: AnnualDetailAssessment) => any;
    @Input() editState: AnnualDetailEditState;
    @Input() taskReadonly: boolean = false;
    @Input() hideDelete: boolean = false;
    @Input() isDocumentProcessing: boolean = false;
    @ViewChild('appealTabs') appealTabs: TabsetComponent;
    @ViewChild('AddAppealModal') addAppealModal: AddAppealModalComponent;
    @ViewChild('submitEvidenceDateTimeLocal') submitEvidenceDateElement: WeissmanDatetimeComponent;
    @ViewChild('informalHearingDateTimeLocal') informalHearingDateElement: WeissmanDatetimeComponent;
    @ViewChild('hearingDateTimeLocal') hearingDateElement: WeissmanDatetimeComponent;
    @ViewChild('appealTaskValidation') appealTaskValidation: TooltipDirective;

    appealStatuses: AppealStatus[];
    appealPreviousStatus: number;
    skipTaskAppealStatuses: number[];
    appealEntityTypeId: number = APPEAL_ENTITY_TYPE_ID;
    isCalculatingSavings: boolean = false;
    hasRyanPrivateEdit: boolean = false;
    hasRyanPrivateView: boolean = false;
    isRyanInstance: boolean = false;
    isRyanLegalServiceEnabled: boolean = false;
    enableAppealLitigation: boolean = false;

    ngOnInit(): void {
        this.viewModelChanged();

        this.hasRyanPrivateEdit = this.restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
        this.hasRyanPrivateView = this.restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
        this.isRyanInstance = this._userInstanceService.RyanInstanceId == this._userInstanceService.getSelectedInstance().instanceId;
        this.isRyanLegalServiceEnabled = this.featureFlagsService.featureFlags.enableInvoiceRLS;
        this.enableAppealLitigation = this.featureFlagsService.featureFlags.enableAppealLitigation;
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Look for changes to the viewModel input property
        if (Object.keys(changes).indexOf('viewModel') >= 0) {
            this.viewModelChanged();
        }
    }

    viewModelChanged(): void {
        this.checkDefaultAppealID();
        this.editState.showEditControls = this.viewModel && this.viewModel.model.length > 0;
        if (this.viewModel) {
            this.viewModel.hasWritePermission = this.editState.hasWritePermission;
            this.viewModel.updateModalData();
            this.viewModel.resetDateTimeHandler = () => {
                if (this.submitEvidenceDateElement) {
                    this.submitEvidenceDateElement.resetValidation();
                }
                if (this.informalHearingDateElement) {
                    this.informalHearingDateElement.resetValidation();
                }
                if (this.hearingDateElement) {
                    this.hearingDateElement.resetValidation();
                }
            };

            if (this.viewModel.currentAppeal){
                this.appealPreviousStatus = this.viewModel.currentAppeal.appealStatusID;
            }
        }
    }

    checkDefaultAppealID(): void {
        if (this.viewModel && this.viewModel.defaultAppealID) {
            this.appealService.setCurrentAppeal(this.viewModel, this.viewModel.defaultAppealID);
        }
    }

    appealHeading(appealIndex: number) {
        return this.viewModel.appealLevelAbbrDisplay(this.viewModel.model[appealIndex].appealLevelID);
    }

    getAssessmentDescription(assessmentID: number) {
        const assessment = _.find(this.viewModel.annualYearModel.annualGridDetails, (x: AnnualDetailAssessment) => {
            return x.annualAssessmentID === assessmentID;
        }) as AnnualDetailAssessment;

        return assessment.revisionDesc;
    }

    getAppealStatus(appeal: Appeal): string {
        if (appeal.appealStatusID) {
            return AppealStatus.getByID(appeal.appealStatusID).status;
        }
        else {
            return 'Not set';
        }
    }

    canPrepareApplication(appeal: Appeal): boolean {
            // WK-6708 There is no read-only appeal application page at the moment, so restrict this to users who have
            // write permission on the appeal for now
        return this.editState.hasWritePermission &&
            _.some(this.viewModel.currentTaskSummary.ReadyTaskTypes, (t: TaskType) => t === TaskType.PrintApplication) &&
            appeal.canCreateApplicationForm;
    }

    getAppealOutcomeRevision(appeal: Appeal): string {
        const revision: AnnualDetailAssessment = _.find(this.viewModel.annualYearModel.annualGridDetails, { annualAssessmentID: appeal.outcomeAnnualAssessmentID });
        return revision && revision.revisionDesc;
    }

    setDefaultOutcomeRevision(appeal: Appeal): void {
        // Default to the next revision (or current revision if no next revision) unless the Status (Resolution) is set to Added-in-Error, then do nothing.
        if (appeal.appealStatusID === AppealStatusID.AddInError) {
            appeal.outcomeAnnualAssessmentID = AppealStatusID.Null;
            return;
        }

        const appealedRevision = _.find(this.viewModel.annualYearModel.annualGridDetails, {
            annualAssessmentID: this.viewModel.currentAppeal.annualAssessmentID
        });
        const nextRevision = _.find(this.viewModel.annualYearModel.annualGridDetails, { revisionNum: appealedRevision.revisionNum + 1 });
        appeal.outcomeAnnualAssessmentID = (nextRevision && nextRevision.annualAssessmentID) || appealedRevision.annualAssessmentID;
    }

    appealOutcomeRevisionChanged(appeal: Appeal): void {
        this.calculateSavings(appeal);
    }

    appealStatusChanged(appeal: Appeal, newAppealStatusID: number) {
        // track old status and update the new one
        this.appealPreviousStatus = appeal.appealStatusID;
        appeal.appealStatusID = newAppealStatusID;

        const newStatusInTheList = _.indexOf(this.skipTaskAppealStatuses, this.viewModel.currentAppeal.appealStatusID);
        const previousStatusInTheList = _.indexOf(this.skipTaskAppealStatuses, this.appealPreviousStatus);

        if (newStatusInTheList != -1 && previousStatusInTheList === -1){
            this.messageBoxService.open({
                message: `Changing resolution to ${  this.getAppealStatus(appeal)  } will cause all open appeal tasks to be skipped when the appeal is saved. Proceed?`,
                buttons: MessageBoxButtons.OKCancel
            }).then((result: MessageBoxResult) => {
                if (result === MessageBoxResult.OK) {
                    this.changeStatus(appeal);
                }
                else {
                    appeal.appealStatusID = this.appealPreviousStatus;
                }
            });
        }
        else{
            this.changeStatus(appeal);
        }
    }

    changeStatus(appeal: Appeal) : void {
        // default the Outcome Revision when the option is shown the first time
        // this would typically be when the status changes from NULL to a closed status
        if (this.appealPreviousStatus === AppealStatusID.Null && this.isClosed(appeal)) {
            this.setDefaultOutcomeRevision(appeal);
        }

        //// Feature removed through WK-7290
        // Default savings date to today
        // if (appeal.appealStatusID !== AppealStatusID.Null && !appeal.savingsDate) {
        //     appeal.savingsDate = WeissmanDatetime.getMidnightMoment(new Date(), false, "Central").format();
        // }
        ////

        // Savings Date is not valid unless we have an appeal status
        if (appeal.appealStatusID !== AppealStatusID.Reduction) {
            appeal.savingsDate = null;
        }
        this.appealPreviousStatus = appeal.appealStatusID;

        this.setSavings(appeal)
            .then(() => {
                this.editState.staleAppealStatus = true;
                this.editState.refreshGrid = true;
            });
    }

    // isEditable and editMode do subtly different things; if the user is currently editing the appeal, editMode is true.
    // However, if the appeal status indicates that it is closed only the status should be editable, so the isEditable
    // function checks both the editMode and the appeal status.
    isEditable(appeal: Appeal): boolean {
        return this.editState.editMode && !this.isClosed(appeal);
    }

    isClosed(appeal: Appeal):boolean {
        if (!appeal.appealStatusID) {
            return false;
        }
        else {
            return AppealStatus.getByID(appeal.appealStatusID).isClosedStatus;
        }
    }

    showSubmitEvidence(): boolean {
        return this.viewModel.currentTaskSummary &&
            ((!this.viewModel.currentTaskSummary.Status && !this.viewModel.loadingTaskSummary) ||
                this.viewModel.currentTaskSummary.HasSubmitEvidence);
    }

    showConfirmHearing(): boolean {
        return this.viewModel.currentTaskSummary &&
            ((!this.viewModel.currentTaskSummary.Status && !this.viewModel.loadingTaskSummary) ||
                this.viewModel.currentTaskSummary.HasConfirmHearing);
    }

    showInformalHearing(): boolean {
        return this.viewModel.currentTaskSummary &&
            ((!this.viewModel.currentTaskSummary.Status && !this.viewModel.loadingTaskSummary) ||
                this.viewModel.currentTaskSummary.HasInformalHearing);
    }

    showHearing(): boolean {
        return this.viewModel.currentTaskSummary &&
            ((!this.viewModel.currentTaskSummary.Status && !this.viewModel.loadingTaskSummary) ||
                this.viewModel.currentTaskSummary.HasFormalHearing);
    }

    isStatusADate(): boolean {
        // I sloppily ported this from our old JavaScript solution; there's probably a better way to do this. We're just checking
        // if the string appealStatus property on the current revision is actually a date
        if (this.currentRevision) {
            const d = new Date(this.currentRevision.appealStatus);
            if (d.getDate()) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    launchTaskModal(appeal: Appeal): void {
        const openCallback = () => {
            this.taskService.launchTaskModal(appeal.appealID, APPEAL_ENTITY_TYPE_ID, this.taskReadonly || !this.editState.editMode).then(shouldReload => {
                if (shouldReload) {
                    this.navigationEvent.refreshActivityData();
                    this.appealService.loadTaskSummary(this.viewModel);
                    this.editState.refreshGrid = true;
                }
            });
        };

        if (this.editState.editMode && !this.taskReadonly && this.editState.getDirty()) {
            this.editState.validationHandler((isValid, errorMessage) => {
                if (!isValid) {
                    this.editState.validationMessage = errorMessage;
                    setTimeout(() => {
                        this.appealTaskValidation.show();
                    }, 1);
                }
                else {
                    this.editState.validationMessage = '';
                    this.editState.saveHandler().then((toastMessage) => {
                        this.toastr.info('Appeal changes autosaved');
                        this.editState.setDirty(false);
                        this.viewModel.resetEdit(true);

                        if (toastMessage){
                            this.toastr.info(toastMessage);
                        }
                        openCallback();
                    });
                }
            });
        }
        else {
            openCallback();
        }
    }

    calculateSavings(appeal: Appeal): void {
        this.setSavings(appeal)
            .then(() => {
                this.editState.staleAppealSavings = true;
                this.editState.refreshGrid = true;
            });
    }

    savingsChanged(appeal: Appeal): void {
        this.setDirty();
        appeal.savingsOverridden = true;
        this.editState.staleAppealSavings = true;
        this.editState.refreshGrid = true;
    }

    setSavings(appeal: Appeal): Promise<void> {
        this.isCalculatingSavings = true;
        return this.appealService.setSavings(appeal, this.viewModel.annualYearModel)
            .then(() => {
                this.isCalculatingSavings = false;
            });
    }

    savingsBlur(appeal: Appeal): void {
        if (appeal.savings !== 0 && !appeal.savings) {
            appeal.savingsOverridden = false;
            this.calculateSavings(appeal);
            this.editState.staleAppealSavings = true;
            this.editState.refreshGrid = true;
        }
    }

    revertToOriginalSavings(appeal: Appeal): void {
        appeal.savingsOverridden = false;
        this.calculateSavings(appeal);
        this.editState.staleAppealSavings = true;
        this.editState.refreshGrid = true;
    }

    openAddAppeal(): void {
        this.annualDetailsService.preNavigateWarn(this.editState).then(() => {
            this.addAppealModal.show();
        });
    }

    addAppeal(newAppeal: any): void {
        newAppeal = newAppeal as Appeal;
        this.appealService.addNewAppealToViewModel(this.viewModel, newAppeal).then(appeal => {
            this.editState.staleAppealStatus = true;
            this.editState.refreshGrid = true;
            this.appealPreviousStatus = appeal.appealStatusID;
            this.navigationEvent.refreshActivityData();
            // If we're adding the first appeal for this year, showEditControls will be false
            // when the component is loaded; reset to true now that we have an appeal to edit
            this.editState.showEditControls = true;
            if (!this.editState.editMode) {
                // We normally would be worried about isDirty here, but the preNavigationWarn call
                // on the button event should ensure that we can't get here from a dirty form
                this.editState.setEditMode(true);
            }
        });
    }

    switchTabs(i: number) {
        // For posterity; here's how you'd "cancel" selecting a tab:
        /*
            // Allow tab control to "breathe" before cancelling the navigate event and going
            // back to the previously selected tab
            setTimeout(() => {
                this.appealTabs.tabs.forEach((t, index) => {
                    t.active = index === this.viewModel.currentAppealIndex;
                });
                this.newAppeal();
            }, 1);
        */

        if (i !== this.viewModel.currentAppealIndex) {
            this.annualDetailsService.preNavigateWarn(this.editState).then(() => {
                this.appealService.setAppealIndex(this.viewModel, i);
            }, () => {
                this.appealTabs.tabs.forEach((t, index) => {
                    t.active = index === this.viewModel.currentAppealIndex;
                });
            });
        }
    }

    onSubmitEvidenceValidationChange(validationMessage): void {
        this.viewModel.submitEvidenceValid = !validationMessage;
    }

    onInformalHearingValidationChange(validationMessage): void {
        this.viewModel.informalHearingValid = !validationMessage;
    }

    onHearingValidationChange(validationMessage): void {
        this.viewModel.hearingValid = !validationMessage;
    }

    showBrokenChain(value: Date): boolean {
        return (!this.viewModel.currentTaskSummary || (!this.viewModel.currentTaskSummary.status && this.viewModel.currentTaskSummary.loaded)) && (value !== undefined && value !== null);
    }

    setDirty(): void {
        this.editState.setDirty(true);
        this.editState.refreshGrid = true;
    }

    onTaskRelevantFieldChanged(): void {
        this.editState.setDirty(true);
        this.editState.refreshActivity = true;
    }

    deleteAppeal(appeal: Appeal): void {
        this.messageBoxService.confirm(`Are you sure you want to delete ${
                 this.viewModel.appealLevelAbbrDisplay(appeal.appealLevelID)  }?`).then(() => {
            this.appealService.deleteAppeal(appeal.appealID).then(() => {
                this.editState.staleAppealStatus = true;
                this.editState.refreshGrid = true;
                _.remove(this.viewModel.model, appeal);
                // Stay in edit mode after deleting an appeal
                this.editState.setDirty(false);
                this.editState.refreshActivity = false;

                this.navigationEvent.refreshActivityData();

                if (this.viewModel.model.length < 1) {
                    this.editState.showEditControls = false;
                    this.editState.setEditMode(false);
                }
                else {
                    this.viewModel.resetEdit();
                    this.appealService.setAppealIndex(this.viewModel, 0);
                }
            });
        });
    }

  changeAppealLevelObj(event, appeal: Appeal): void{
      appeal.appealLevel = _.find(this.viewModel.stateAppealLevels, {
        appealLevelID: appeal.appealLevelID
      });
  }

  async navigateToAppealApplication(appeal: Appeal): Promise<void> {
    let cancelled = false;
    await (this.annualDetailsService.preNavigateWarn(this.editState).then(() => null, () => { cancelled = true; }));
    if (cancelled) { return; }
    console.log(appeal.hasApplicationForm);

    if(!appeal.hasApplicationForm) {
        await this.appealApplicationService.createNewApplication(appeal.appealID);
    }

    this.upgradeNavigationServiceHandler.go('appealApplication', {appealId: appeal.appealID});
  }
}
