import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { IDoesFilterPassParams, IFilterOptionDef } from 'ag-grid-community';
import { FilterUIModel, FilterValueModel } from '../filterUI.model';
import { AgGridFilterBase, WSTextFilterParams } from '../agGridFilterBase';

import * as moment from 'moment';

@Component({
    selector: 'ag-grid-date-filter',
    templateUrl: './agGridDateFilter.component.html',
    styleUrls: ['../agGridFilters.scss']
})
export class AgGridDateFilterComponent extends AgGridFilterBase<WSTextFilterParams> {
    constructor() {
        super();
    }

    @ViewChild('applyButton', { read: ViewContainerRef }) applyButton;

    selectFilterOptions: IFilterOptionDef[] = [];
    filterOptions: Array<string | IFilterOptionDef> = [];
    filterConditionType: Core.FilterConditionTypeEnum = Core.FilterConditionTypeEnum.None;
    filterValues: FilterValueModel[] = [];

    agInit(params: WSTextFilterParams): void {
        this._params = params;
        this._valueGetter = params.valueGetter;

        this._searchTypeMap = new Map([
            ['equals', (fv, cv) => cv === fv],
            ['notEqual', (fv, cv) => cv !== fv],
            ['greaterThan', (fv, cv) => moment(fv).isBefore(cv)],
            ['lessThan', (fv, cv) => moment(fv).isAfter(cv)]
        ]);
        this._defaultFilterOptions = [
            'equals',
            'notEqual',
            'greaterThan',
            'lessThan'
        ];

        this.filterOptions = (params.filterOptions) ? params.filterOptions : this._defaultFilterOptions;
        this.selectFilterOptions = this.filterOptions.map(this._formatOption.bind(this));

        this.filterValues = [{ filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions) }];
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        if (!this.filterValues[0].filterType) { return true; }

        const filterTest = x => {
            let value = '';
            if (this._filterValueGetter) {
                const filterParams = { ...params, getValue: this._valueGetter, valueGetterParams: {...this._params, ...params} } as any;
                value = (typeof this._filterValueGetter === 'string') ? this._filterValueGetter : this._filterValueGetter(filterParams);
            } else {
                value = this._valueGetter(params.node);
            }

            const formattedCellValue = value ? moment(new Date(value)).format('MM/DD/YYYY') : null;
            const formattedFilterValue = moment(x.filterValue, 'MM/DD/YYYY').format('MM/DD/YYYY');
            if (this._params.textCustomComparator) {
                return this._params.textCustomComparator(x.filterType.displayKey, formattedCellValue, formattedFilterValue, params.node.data);
            } else {
                return x.filterType.test(formattedFilterValue, formattedCellValue);
            }
        };

        return this.filterConditionType === Core.FilterConditionTypeEnum.Or
            ? this.filterValues.some(filterTest) : this.filterValues.every(filterTest);
    }

    getModel() {
        if (this.filterValues.length > 1) {
            this.filterValues = this.filterValues.some(x => !!(x.filterValue || x.filterType.hideFilterInput))
                ? this.filterValues.filter(x => !!(x.filterValue || x.filterType.hideFilterInput))
                : [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
        }
        this.filterConditionType = this.filterValues.length > 1 ? this.filterConditionType : Core.FilterConditionTypeEnum.None;
        return ((this.filterValues.length && this.filterValues.some(x => !!x.filterValue)) || (this.filterValues[0].filterType && this.filterValues[0].filterType.hideFilterInput))
            ? {
                filterType: 'number',
                filterConditionType: this.filterValues.length > 1 ? this.filterConditionType : Core.FilterConditionTypeEnum.None,
                filterValues: this.filterValues
            }
            : null;
    }

    setModel(model: FilterUIModel): void {
        this._appliedModel = model;

        this.filterValues = [{filterValue: '', filterType: this._getDefaultFilterType(this.selectFilterOptions)}];
        this.filterConditionType = model ? model.filterConditionType : Core.FilterConditionTypeEnum.None;
        if (model && model.filterValues) {
            this.filterValues = model.filterValues.map(x => ({filterValue: x.filterValue, filterType: this.selectFilterOptions.find(y => y.displayKey === x.filterType.displayKey)}));
        }
    }
}
