import { Component, Input, OnInit } from '@angular/core';
import { HelpContentComponentConfig } from '../../UI-Lib/Help-Tooltip';
import { StampTooltipComponent, StampTooltipComponentParams } from './stampTooltip.component';

@Component({
    selector: 'ws-stamp',
    templateUrl: './stamp.component.html',
    styleUrls: ['./stamp.component.scss']
})
export class StampComponent implements OnInit {
    @Input() visible: boolean;
    @Input() activityStatusId: Core.ActivityStatuses;
    @Input() transaction: any;
    @Input() offsetFrom: string = 'top';
    @Input() rightOffset: number = 85;
    @Input() set dataValue(dataValue: any) {
        this._dataValue = dataValue;
        this._createHelpComponent();
    }
    get dataValue(): any {
        return this._dataValue;
    }

    helpContentComponent: HelpContentComponentConfig<StampTooltipComponent, StampTooltipComponentParams>;

    private _dataValue: any;


    get inactiveOffset(): string {
        return (this.offsetFrom === 'bottom' && !this.visible && this.activityStatusId !== 2) ? '120px' : null;
    }

    get soldOffset(): string {
        return (this.offsetFrom === 'top' && !this.visible && this.activityStatusId !== 2) ? '140px' : null;
    }

    ngOnInit(): void {
        this._createHelpComponent();
    }

    getInactiveURL(): string {
        return `/images/${(this.activityStatusId === 0) ? 'inactive' : 'active-pending'}.jpg`;
    }

    private _createHelpComponent(): void {
        if (this.dataValue && (this.dataValue.activatedBy
                               || this.dataValue.inactivatedBy
                               || this.dataValue.inactivatedDateTime
                               || (this.dataValue.activatedDateTime && this.dataValue.inactivatedDateTime && this.dataValue.activatedDateTime <= this.dataValue.inactivatedDateTime))) {
            this.helpContentComponent = {
                component: StampTooltipComponent,
                componentParams: {}
            };
        }
    }
}
