import { Component, OnInit } from '@angular/core';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../Compliance/AgGrid';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridActionCellRendererComponent, AgGridActionCellRendererParams } from '../../Compliance/AgGrid/CellRenderers/agGridActionCellRenderer.component';
import { ValuationTemplateService } from './valuationTemplate.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AddEditValuationTemplateComponent } from './Add-Edit-Tempalate/addEditValuationTemplate.component';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { VALUATION_TEMPLATE_HELP } from './valuationTemplate.help';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';

@Component({
    selector: 'valuation-templates',
    templateUrl: './valuationTemplates.component.html',
    styleUrls: ['./valuationTemplates.component.scss']
})
export class ValuationTemplatesComponent implements OnInit {
    constructor(
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _messageModalService: MessageModalService,
        private readonly _routerService: UpgradeNavigationServiceHandler
    ) {}

    gridId: string = 'e34cb223-dd10-4ee8-890b-2caa2b83ef75';
    gridOptions = new AgGridOptionsBuilder()
        .withColumnPinning()
        .withSort()
        .withMultipleColumnSort()
        .withLoadingOverlay()
        .withColumnResize()
        .withContext(this)
        .build();

    private _gridApi: GridApi;
    private _gridValues: Core.ValuationTemplateModel[] = [];

    ngOnInit(): void {
        this._helpService.setContent(VALUATION_TEMPLATE_HELP);
        this.refreshGridData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns = [
            {
                headerName: 'Template Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                editable: false,
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Template Description',
                field: 'description',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'States',
                field: 'states',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    const template = params.data as Core.ValuationTemplateModel;
                    if (template.states.length) {
                        return template.states.map(x => x.stateAbbr).join(', ');
                    } else {
                        return 'National';
                    }
                }
            },
            {
                headerName: 'Assessors',
                field: 'assessors',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    const template = params.data as Core.ValuationTemplateModel;
                    if (template.states.length === 1) {
                        return params.data.assessors.map(x => x.assessorName).join(', ');
                    } else {
                        return 'Using All Assessors';
                    }
                }
            },
            {
                headerName: 'Site Classes',
                field: 'siteClassPrimary',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsAndCommaParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    return params.data.siteClassPrimaries.map(x => x.siteClassPrimaryDesc).join(', ');
                }
            },
            {
                colId: 'grid-column-actions',
                headerName: '',
                field: 'actions',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                pinned: 'right',
                lockVisible: true,
                sortable: false,
                cellRendererFramework: AgGridActionCellRendererComponent,
                cellRendererParams: {
                    buttonConfigs: [
                        {
                            iconClass: 'fa-pencil',
                            isShown: () => true,
                            onClick: this.editTemplate.bind(this)
                        },
                        {
                            iconClass: 'fa-trash',
                            buttonClass: 'btn-primary btn-danger',
                            isShown: () => true,
                            onClick: this.deleteTemplate.bind(this)
                        }
                    ]
                } as AgGridActionCellRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setDataSource();
    }

    async createNewTemplate(): Promise<void> {
        const result = await this._modalService.showAsync(AddEditValuationTemplateComponent, {}, 'modal-md');

        if (!result) {
            return;
        }
        this._routerService.go('valuationTemplateDetail', { templateId: result.valuationTemplateId });
    }

    editTemplate(gridRow: AgGridActionCellRendererParams): void {
        const rowData = gridRow.data as Core.ValuationTemplateModel;
        this._routerService.go('valuationTemplateDetail', { templateId: rowData.valuationTemplateId });
    }

    async deleteTemplate(gridRow: AgGridActionCellRendererParams): Promise<void> {
        const rowData = gridRow.data as Core.ValuationTemplateModel;

        try {
            await this._messageModalService.confirm('Are you sure you want to delete this valuation template?');
        } catch(err) {
            return;
        }

        try {
            await this._valuationTemplateService.deleteTemplate(rowData.valuationTemplateId);
        } finally {
            await this.refreshGridData();
        }
    }

    async refreshGridData(): Promise<void> {
        if (this._gridApi) {
            this._gridApi.showLoadingOverlay();
        }
        try {
            this._gridValues = await this._valuationTemplateService.getTemplateList();
        } finally {
            this._setDataSource();
        }
    }

    private _setDataSource(): void {
        if (!this._gridApi) {
            return;
        }
        this._gridApi.setRowData(this._gridValues);
        this._gridApi.hideOverlay();
    }
}
