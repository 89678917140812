<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Error Log"
                  cancelHelpContentId="app.cancel"
                  cancelClass="btn-primary"
                  [hideSave]="true"
                  (cancel)="cancel()">
    <div class="error-log-contents">
        <div class="d-flex flex-column w-100 h-100" *ngIf="errorLog">
            <div class="d-flex flex-column">
                <label>JSON:</label>
                <div class="d-flex flex-column">
                    {{ errorLog.json }}
                </div>
            </div>
            <div class="d-flex flex-column ws-flex-auto">
                <label>Exception:</label>
                <div class="d-flex flex-column" style="white-space: pre">
                    {{ errorLog.exception }}
                </div>
            </div>
        </div>
        <div class="d-flex flex-column w-100 h-100" *ngIf="!errorLog">
            <span>No data.</span>
        </div>
    </div>
</ws-modal-wrapper>
