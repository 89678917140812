<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-truncate ws-flex-auto">Return Settings Override</h3>
        <div class="ws-flex-none header-button-container">
            <button *ngIf="!editMode && canEdit"
                    title="Edit"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    [disabled]="!canEnterEditMode"
                    (click)="edit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
            <button title="Save"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    *ngIf="editMode"
                    [disabled]="!hasChanges"
                    (click)="save()">
                <i class="fa fa-save fa-align"></i>
            </button>
            <button title="Cancel"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    *ngIf="editMode"
                    (click)="cancel()">
                <i class="fa fa-times fa-align"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body">
        <return-settings [entityId]="returnId" [entityType]="ReturnSettingsEntityTypeEnum.Return"
                         (onInitialized)="onReturnSettingsInitialized($event)" *ngIf="returnId"
                         (changed)="onReturnSettingsChanged($event)"></return-settings>
    </div>
</div>
