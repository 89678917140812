import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForFormAssessorsPanelGridAction extends ICellRendererParams {
    canEdit: (params: ICellRendererParamsForFormAssessorsPanelGridAction) => boolean;
    canEnterEditMode: () => boolean;
    canRemove: (params: ICellRendererParamsForFormAssessorsPanelGridAction) => boolean;
    remove: (params: ICellRendererParamsForFormAssessorsPanelGridAction) => void;
    edit: (params: ICellRendererParamsForFormAssessorsPanelGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button *ngIf="params.data && params.canRemove(params)" [disabled]="!params.canEnterEditMode()" class="ace-btn has-icon btn-primary btn-danger grid-action-button" title="Remove" (click)="onRemoveClicked()">
        <i class="fa fa-times"></i>
    </button>
    `
})
export class FormAssessorsPanelGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForFormAssessorsPanelGridAction;

    agInit(params: ICellRendererParamsForFormAssessorsPanelGridAction): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onRemoveClicked(): void {
        this.params.remove(this.params);
    }
}
