import { Injectable } from '@angular/core';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { ContactsUpgradeService } from '../Contact/contacts.service.upgrade';
import { InstancePermissionService } from '../Permission/Instance/Instance.Permission.Service';
import { UserGroupService } from '../User-Group/user-group-service.upgrade';
import { CompanyPermissionsService } from '../Permission/Company/companyPermissions.service';
import { ApplicationPermissionService } from '../Permission/Application/applicationPermission.service';

@Injectable()
export class UserSetupService  {
    constructor(private http: SDHttpService,
        private contactsService: ContactsUpgradeService,
        private instancePermissionService: InstancePermissionService,
        private userGroupService: UserGroupService,
        private companyPermissionService: CompanyPermissionsService,
        private applicationPermissionService: ApplicationPermissionService) {}

    getUserData(userId: string, contactId: number): Promise<any> {
        const user = this.contactsService.getForSetup(contactId);
        const applicationPermissions = this.applicationPermissionService.getByContactId(contactId);
        const instancePermissions = this.instancePermissionService.getByContactId(contactId);
        const userGroups = this.userGroupService.getForSetup(contactId);
        const companyPermissions = this.companyPermissionService.getForUser(userId);

        const promises = [user, applicationPermissions, companyPermissions, userGroups, instancePermissions];
        return this._getData(promises);
    }

    getUserDataScoped(userId: string, contactId: number): Promise<any> {
        const user = this.contactsService.getForSetupScoped(contactId);
        const applicationPermissions = this.applicationPermissionService.getByContactIdScoped(contactId);
        const instancePermissions = this.instancePermissionService.getByContactId(contactId);
        const userGroups = this.userGroupService.getForSetup(contactId);
        const companyPermissions = this.companyPermissionService.getForUser(userId);
        const promises = [user, applicationPermissions, companyPermissions, userGroups, instancePermissions];

        return this._getData(promises);
    }


    getSSODomains(contactID: number): Promise<any> {
        return this.http.get(`/api/sso-integration/domains/${contactID}`);
    }

    isIAMAdmin(): Promise<any> {
        return this.http.get('/api/permission/IAMAdminCheck');
    }

    private async _getData(promises: Promise<any>[]): Promise<any> {
        return Promise.all(promises)
            .then(([user, roles, permissions, userToGroup, instancePermissions]) => {
                user.roles = roles;
                user.permissions = permissions;
                user.userToGroup = userToGroup;
                user.instancePermissions = instancePermissions;
                return user;
            });
    }
}
