<ws-modal-wrapper headerLabel="Change Due Dates"
                  [disableSave]="loading || !dueDate"
                  [disableCancel]="loading"
                  (save)="save()"
                  (cancel)="cancel()">
    <loading-spinner [show]="loading" [size]="5"></loading-spinner>
    <div *ngIf="!loading" class="modal-body exclude-modal-styling">
        <label class="change-due-dates-date">
            <span>{{dueDateText}}</span>
            <weissman-datetime [(inputDate)]="dueDate"
                               [readOnly]="false"
                               [dateOnly]="dateOnly">
            </weissman-datetime>
        </label>
    </div>
</ws-modal-wrapper>
