import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class BaseRepository {
    protected constructor(protected httpClient: HttpClient) { }

    protected httpGet<T>(url: string, options?: { [key: string]: any }): Observable<T> {
        return this.httpClient.get<T>(url, this._getOptions(options));
    }

    protected httpPost<T, Tbody>(url: string, body: Tbody, options?: { [key: string]: any }): Observable<T> {
        return this.httpClient.post<T>(url, body, this._getOptions(options));
    }

    protected httpPut<T, Tbody>(url: string, body: Tbody, options?: { [key: string]: any }): Observable<T> {
        return this.httpClient.put<T>(url, body, this._getOptions(options));
    }

    protected httpPatch<T, Tbody>(url: string, body: Tbody): Observable<T> {
        return this.httpClient.patch<T>(url, body, this._getOptions());
    }

    protected httpDelete<T>(url: string, options?: { [key: string]: any }): Observable<T> {
        return this.httpClient.delete<T>(url, this._getOptions(options));
    }

    private _getOptions(options?: any): { [key: string]: any } {
        const defaultOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        if (options) {
            // get all keys specified in options except for headers; headers will be dealt with separately
            const { headers, ...optionsWithoutHeaders } = options;

            // use those keys to override the default options
            Object.assign(defaultOptions, optionsWithoutHeaders);

            // copy headers now; for each header that was specified
            //   if a key is present then check the value for that key; if the value is "undefined" then treat it as a removal from default headers
            //   if a key is not present then append it
            if (headers) {
                headers.keys().forEach(key => {
                    const value = headers.get(key);

                    if (defaultOptions.headers.has(key)) {
                        if (value === undefined) {
                            defaultOptions.headers = defaultOptions.headers.delete(key);
                        } else {
                            defaultOptions.headers = defaultOptions.headers.set(key, value);
                        }
                    } else {
                        defaultOptions.headers = defaultOptions.headers.append(key, value);
                    }
                });
            }
        }

        return defaultOptions;
    }
}
