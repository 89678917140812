import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class PermissionService {
    constructor(private readonly _http: HttpClient) { }

    getGroupInstanceRights(groupId: number): Promise<Core.InstanceRightModel[]> {
        return lastValueFrom(this._http.get(`api/instanceright/group/${groupId}`)) as Promise<Core.InstanceRightModel[]>;
    }

    getAvailableGroupInstanceRights(groupId: number): Promise<Core.InstanceRightModel[]> {
        return lastValueFrom(this._http.get(`api/instanceright/group/${groupId}/available`)) as Promise<Core.InstanceRightModel[]>;
    }

    updateGroupInstanceRights(groupId: number, instanceRightsIds: number[]): Promise<Core.InstanceRightModel[]> {
        return lastValueFrom(this._http.put(`api/instanceright/group/${groupId}`, instanceRightsIds)) as Promise<Core.InstanceRightModel[]>;
    }

    getGroupApplicationRights(groupId: number): Promise<Core.RoleModel[]> {
        return lastValueFrom(this._http.get(`api/user/role/group/${groupId}`)) as Promise<Core.RoleModel[]>;
    }

    getAvailableGroupApplicationRights(groupId: number): Promise<Core.RoleModel[]> {
        return lastValueFrom(this._http.get(`api/user/role/group/${groupId}/available`)) as Promise<Core.RoleModel[]>;
    }

    updateGroupApplicationRights(groupId: number, roleIds: System.Guid[]): Promise<Core.RoleModel[]> {
        return lastValueFrom(this._http.put(`api/user/role/group/${groupId}`, roleIds)) as Promise<Core.RoleModel[]>;
    }

    getUserOrGroupEntityPermissions(instanceId: number, searchModel: Core.PermissionSearchModel): Promise<Compliance.QueryResultModel<Core.PermissionModel>> {
        return lastValueFrom(this._http.post(`api/permission/instance/${instanceId}`, searchModel)) as Promise<Compliance.QueryResultModel<Core.PermissionModel>>;
    }

    getCompanyRowIds(instanceId: number, searchModel: Core.PermissionSearchModel): Promise<Compliance.QueryResultModel<number>> {
        return lastValueFrom(this._http.post(`api/permission/instance/${instanceId}/rowids`, searchModel)) as Promise<Compliance.QueryResultModel<number>>;
    }

    getAvailableSitePermissions(companyId: number, searchModel: Core.PermissionSearchModel): Promise<Compliance.QueryResultModel<Core.PermissionModel>> {
        return lastValueFrom(this._http.post(`api/permission/company/${companyId}/sitesavailable`, searchModel)) as Promise<Compliance.QueryResultModel<Core.PermissionModel>>;
    }

    getCompanySiteRowIds(companyId: number, searchModel: Core.PermissionSearchModel): Promise<Compliance.QueryResultModel<number>> {
        return lastValueFrom(this._http.post(`api/permission/company/${companyId}/rowids`, searchModel)) as Promise<Compliance.QueryResultModel<number>>;
    }

    getAvailableCompanyPermissionsForGroup(instanceId: number, searchModel: Core.PermissionSearchModel) {
        return lastValueFrom(this._http.post(`api/permission/instance/${instanceId}/available`, searchModel)) as Promise<Compliance.QueryResultModel<Core.PermissionModel>>;
    }

    getAvailableCompanyPermissionsRowIds(instanceId: number, searchModel: Core.PermissionSearchModel) {
        return lastValueFrom(this._http.post(`api/permission/instance/${instanceId}/available/rowids`, searchModel)) as Promise<Compliance.QueryResultModel<number>>;
    }

    updateEntityPermissions(permissionSaveRequest: Core.PermissionSaveRequest): Promise<Compliance.QueryResultModel<Core.PermissionModel>> {
        return lastValueFrom(this._http.put('api/permission', permissionSaveRequest)) as Promise<Compliance.QueryResultModel<Core.PermissionModel>>;
    }
}
