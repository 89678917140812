<!--<sd-loading-spinner ng-hide="active.revision"></sd-loading-spinner>-->
<div class="row assessments" [class.assessment-padding]="compactMode" *ngIf="viewModel?.model">
    <div [ngClass]="{'col-xxl-9': !compactMode}">
        <div class="assess">
            <div class="assess-heading text-start" style="display: flex;">
                <div style="flex: none; order: 1;">
                    <div class="assess-title">{{ viewModel.model.revisionDesc }}</div>
                    <div class="btn-group btn-group-xs buttongroup-first buttongroup-last"
                         style="padding-left: 4px;"
                         *ngIf="editState.editMode && editState.hasWritePermission">
                        <button title="Rename"
                                type="button"
                                class="ace-btn btn-sm btn-secondary btn-action"
                                [popover]="renameTemplate"
                                [isOpen]="showPopover"
                                [placement]="popoverDirection"
                                (click)="originalRevisionName = viewModel.model.revisionDesc"
                                (onHidden)="validateRevisionName()"
                                #renamePopover="bs-popover"
                                [outsideClick]="true">
                            <i class="fa fa-pencil"></i> Rename
                        </button>
                        <button title="Reorder"
                                type="button"
                                class="ace-btn btn-sm btn-secondary btn-action"
                                *ngIf="currentYear && currentYear.annualGridDetails && currentYear.annualGridDetails.length > 1"
                                (click)="launchRevisionReorder()">
                            <i class="fa fa-sort-numeric-asc"></i> Reorder
                        </button>
                    </div>
                </div>

                <div style="flex: none; order: 3;  text-align: right;">
                    <entity-in-progress-indicator
                        *ngIf="viewModel.model.hasInProgressIntakeItems && !isDocumentProcessing"
                        [entityId]="viewModel.model.annualAssessmentID"
                        [entityTypeId]="AssessmentEntityTypeId"
                        [isDocumentProcessing]="isDocumentProcessing">
                    </entity-in-progress-indicator>
                    <entity-attachments [(hasAttachments)]="viewModel.model.hasAttachments"
                                        buttonClass="has-icon"
                                        [modalData]="viewModel.attachmentModalData">
                    </entity-attachments>
                    <entity-comments [(hasComments)]="viewModel.model.hasComments"
                                     buttonClass="has-icon"
                                     [modalData]="viewModel.commentModalData"
                                     [disabled]="!taskSummariesLoaded">
                    </entity-comments>
                </div>
                <div style="flex: 1 1 auto; order: 2; text-align: center;">
                    <img src="/images/estimated-horizontal.png"
                         *ngIf="!viewModel.isActual()"
                         alt
                         style="height: 35px; margin-top:-3px;">
                </div>
            </div>
            <table class="assess-table" [class.table-striped]="readOnly()" (change)="setDirty()">
                <thead>
                <tr>
                    <th>
                        <span>Component</span>
                    </th>
                    <th>
                        <span>AV</span>
                    </th>
                    <th>
                        <span>Ratio (%)</span>
                    </th>
                    <th style="padding: 0;" *ngIf="canEditTable()">
                        <button class="ace-btn has-icon btn-primary btn-danger invisible" style="margin: 0;">
                            <i class="fa fa-undo"></i></button>
                    </th>
                    <th>
                        <span>FMV</span>
                    </th>
                    <th style="padding: 0;" *ngIf="canEditTable()">
                        <button class="ace-btn has-icon btn-primary btn-danger invisible" style="margin: 0;">
                            <i class="fa fa-undo"></i></button>
                    </th>
                    <th *ngIf="!readOnly()"></th>
                </tr>
                </thead>
                <tbody>
                <ng-container
                    *ngFor="let annualDetail of viewModel.model.annualAssessmentDetails | componentFilter: ComponentFilter.exempt">
                    <tr class="annual-assessment-row-{{annualDetailsService.getComponentName(annualDetail)}}">
                        <td>{{ annualDetailsService.getComponentName(annualDetail, viewModel.components) }}</td>
                        <td class="assessed-value-{{annualDetailsService.getComponentName(annualDetail)}}">
                            <assessment-input *ngIf="canEditTable()"
                                              [value]="annualDetail.assessedValue"
                                              (valueChange)="calculate('av', annualDetail, $event)"
                                              [componentName]="annualDetailsService.getComponentName(annualDetail)"
                                              [filterFunction]="filterNumber"
                                              (resetIt)="reset(annualDetail)">
                            </assessment-input>

                            <span *ngIf="!canEditTable()">{{ annualDetail.assessedValue | number:"1.0-0" }}</span>
                        </td>
                        <td style="max-width: 130px;"
                            class="assessment-ratio-{{annualDetailsService.getComponentName(annualDetail)}}">
                            <input
                                type="text"
                                class="form-control"
                                [class.overridden]="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                *ngIf="canEditTable()"
                                currencyMask
                                [options]="{ allowNegative: false, precision: 3, prefix: '' }"
                                [(ngModel)]="annualDetail.displayRatio"
                                (ngModelChange)="calculate('ratio', annualDetail)"
                                (blur)="maybeRevertToCalcRatio(annualDetail)"
                                data-sd-assessment-component-ratio
                                [attr.data-sd-assessment-component-name]="annualDetailsService.getComponentName(annualDetail)">

                            <span
                                [class.overridden]="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                *ngIf="!canEditTable()">{{ annualDetail.displayRatio | number:"1.3-3" }}%</span>
                        </td>
                        <td *ngIf="canEditTable()" style="padding: 0; text-align: center;">
                            <button class="ace-btn has-icon btn-primary btn-danger"
                                    style="margin: 0;"
                                    *ngIf="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                    (click)="undoCalcRatio_Click(annualDetail)">
                                <i class="fa fa-undo"></i>
                            </button>
                        </td>
                        <td>
                            <assessment-input *ngIf="canEditTable()"
                                              [(isOverridden)]="annualDetail.fmvOverridden"
                                              [value]="annualDetail.fairMarketValue"
                                              (valueChange)="calculate('fairMarketValue', annualDetail, $event)"
                                              [componentName]="annualDetailsService.getComponentName(annualDetail)"
                                              [filterFunction]="filterNumber"
                                              [allowOverrides]="true"
                                              (resetIt)="reset(annualDetail)">
                            </assessment-input>
                            <span *ngIf="!canEditTable()"
                                  [class.overridden]="annualDetail.fmvOverridden">{{ getFairMarketValue(annualDetail) | number:"1.0-0" }}</span>
                        </td>
                        <td *ngIf="canEditTable()" style="padding: 0; text-align: center;">
                            <button class="ace-btn has-icon btn-primary btn-danger"
                                    style="margin: 0;"
                                    *ngIf="annualDetail.fmvOverridden"
                                    (click)="reset(annualDetail)">
                                <i class="fa fa-undo"></i>
                            </button>
                        </td>
                        <td *ngIf="canEditTable()">
                            <button
                                class="ace-btn has-icon btn-primary btn-danger"
                                style="margin:0;"
                                (click)="deleteComponent(annualDetail)"
                                [attr.data-sd-assessment-component-name]="annualDetailsService.getComponentName(annualDetail)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                <tr class="total"
                    *ngIf="(viewModel.model.annualAssessmentDetails | componentFilter: ComponentFilter.exempt).length > 0">
                    <td>
                        <span *ngIf="someExem()" style="margin-right: 3px;">Pre Adj</span>Total
                    </td>
                    <td>{{ getTotal('assessedValue', [2, 3]) | number: '1.0-0' }}</td>
                    <td></td>
                    <td *ngIf="canEditTable()"></td>
                    <td>{{ getTotal('fairMarketValue', [2, 3]) | number: '1.0-0' }}</td>
                    <td *ngIf="canEditTable()"></td>
                    <td *ngIf="!readOnly()"></td>
                </tr>

                <ng-container
                    *ngFor="let annualDetail of viewModel.model.annualAssessmentDetails | componentFilter: ComponentFilter.nonexempt">
                    <tr class="annual-assessment-row-{{annualDetailsService.getComponentName(annualDetail)}}">
                        <td>{{ annualDetailsService.getComponentName(annualDetail) }}</td>
                        <td class="assessed-value-{{annualDetailsService.getComponentName(annualDetail)}}">
                            <assessment-input *ngIf="canEditTable()"
                                              [value]="annualDetail.assessedValue"
                                              (valueChange)="calculate('av', annualDetail, $event)"
                                              [componentName]="annualDetailsService.getComponentName(annualDetail)"
                                              [filterFunction]="filterNumber"
                                              [allowNegatives]="true"
                                              (resetIt)="reset(annualDetail)">
                            </assessment-input>
                            <!--<input type="text"
                                   class="form-control"
                                   *ngIf="canEditTable()"
                                   [(ngModel)]="component.assessedValue"
                                   (ngModelChange)="calculate('av', component)">
                            valid-number negative="true"
                            data-sd-assessment-component-av
                            data-sd-assessment-component-name="{{getCompName(component)}}">-->

                            <span *ngIf="!canEditTable()">{{ annualDetail.assessedValue | number:"1.0-0" }}</span>
                        </td>
                        <td style="max-width: 130px;"
                            class="assessment-ratio-{{annualDetailsService.getComponentName(annualDetail)}}">
                            <input
                                type="number"
                                class="form-control"
                                [class.overridden]="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                *ngIf="canEditTable()"
                                [(ngModel)]="annualDetail.displayRatio"
                                (ngModelChange)="calculate('ratio', annualDetail)"
                                (blur)="maybeRevertToCalcRatio(annualDetail)">
                            <!--data-sd-assessment-component-ratio
                            data-sd-assessment-component-name="{{getCompName(component)}}"
                            percent>-->

                            <span
                                [class.overridden]="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                *ngIf="!canEditTable()">{{ annualDetail.ratio * 100.000 | number:"1.3-3" }}%</span>
                        </td>
                        <td *ngIf="canEditTable()" style="padding: 0; text-align: center;">
                            <button class="ace-btn has-icon btn-primary btn-danger"
                                    style="margin: 0;"
                                    *ngIf="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                    (click)="undoCalcRatio_Click(annualDetail)">
                                <i class="fa fa-undo"></i>
                            </button>
                        </td>
                        <td>
                            <assessment-input *ngIf="canEditTable()"
                                              [(isOverridden)]="annualDetail.fmvOverridden"
                                              [value]="annualDetail.fairMarketValue"
                                              (valueChange)="calculate('fairMarketValue', annualDetail, $event)"
                                              [componentName]="annualDetailsService.getComponentName(annualDetail)"
                                              [filterFunction]="filterNumber"
                                              [allowNegatives]="true"
                                              [allowOverrides]="true"
                                              (resetIt)="reset(annualDetail)">
                            </assessment-input>
                            <!--<input type="text" class="form-control"
                                   [class.overridden]="component.fmvOverridden"
                                   *ngIf="canEditTable()"
                                   (ngChangeModel)="calculate('fairMarketValue', component)"
                                   (blur)="makeOverridden(component)"
                                   [(ngModel)]="component.fairMarketValue"
                                   [attr.data-sd-assessment-component-name]="annualDetailsService.getComponentName(component)" />-->
                            <!--valid-number hash="true" negative="true">-->
                            <span *ngIf="!canEditTable()"
                                  [class.overridden]="annualDetail.fmvOverridden">{{ getFairMarketValue(annualDetail) | number:"1.0-0" }}</span>
                        </td>
                        <td *ngIf="canEditTable()" style="padding: 0; text-align: center;">
                            <button class="ace-btn has-icon btn-primary btn-danger"
                                    style="margin: 0;"
                                    *ngIf="annualDetail.fmvOverridden"
                                    (click)="reset(annualDetail)">
                                <i class="fa fa-undo"></i>
                            </button>
                        </td>
                        <td *ngIf="canEditTable()">
                            <button
                                class="ace-btn has-icon btn-primary btn-danger"
                                (click)="deleteComponent(annualDetail)"
                                [attr.data-sd-assessment-component-name]="annualDetailsService.getComponentName(annualDetail)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                <tr class="total"
                    *ngIf="(viewModel.model.annualAssessmentDetails | componentFilter: ComponentFilter.nonexempt).length > 0">
                    <td>Total</td>
                    <td>{{ getTotal('assessedValue', [3]) | number:'1.0-0' }}</td>
                    <td></td>
                    <td *ngIf="canEditTable()"></td>
                    <td>{{ getTotal('fairMarketValue', [3]) | number:'1.0-0' }}</td>
                    <td *ngIf="canEditTable()"></td>
                    <td *ngIf="!readOnly()"></td>
                </tr>

                <ng-container
                    *ngFor="let annualDetail of viewModel.model.annualAssessmentDetails | componentFilter: ComponentFilter.alternative">
                    <tr class="cap annual-assessment-row-{{annualDetailsService.getComponentName(annualDetail)}}">
                        <td>{{ annualDetailsService.getComponentName(annualDetail) }}</td>
                        <td class="assessed-value-{{annualDetailsService.getComponentName(annualDetail)}}">
                            <assessment-input *ngIf="canEditTable()"
                                              [value]="annualDetail.assessedValue"
                                              (valueChange)="calculate('av', annualDetail, $event)"
                                              [componentName]="annualDetailsService.getComponentName(annualDetail)"
                                              [filterFunction]="filterNumber"
                                              (resetIt)="reset(annualDetail)">
                            </assessment-input>

                            <span *ngIf="!canEditTable()">{{ annualDetail.assessedValue | number:"1.0-0" }}</span>
                        </td>
                        <td style="max-width: 130px;"
                            class="assessment-ratio-{{annualDetailsService.getComponentName(annualDetail)}}">
                            <input
                                type="number"
                                class="form-control"
                                [class.overridden]="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                *ngIf="canEditTable()"
                                [(ngModel)]="annualDetail.displayRatio"
                                (ngModelChange)="calculate('ratio', annualDetail)"
                                (blur)="maybeRevertToCalcRatio(annualDetail)">

                            <span
                                [class.overridden]="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                *ngIf="!canEditTable()">{{ annualDetail.ratio * 100.000 | number:"1.3-3" }}%</span>
                        </td>
                        <td *ngIf="canEditTable()" style="padding: 0; text-align: center;">
                            <button class="ace-btn has-icon btn-primary btn-danger"
                                    style="margin: 0;"
                                    *ngIf="annualDetail.ratio !== annualDetail.assessmentClassRatio.assessmentRatio"
                                    (click)="undoCalcRatio_Click(annualDetail)">
                                <i class="fa fa-undo"></i>
                            </button>
                        </td>
                        <td>
                            <assessment-input *ngIf="canEditTable()"
                                              [(isOverridden)]="annualDetail.fmvOverridden"
                                              [value]="annualDetail.fairMarketValue"
                                              (valueChange)="calculate('fairMarketValue', annualDetail, $event)"
                                              [componentName]="annualDetailsService.getComponentName(annualDetail)"
                                              [filterFunction]="filterNumber"
                                              [allowOverrides]="true"
                                              (resetIt)="reset(annualDetail)">
                            </assessment-input>
                            <span *ngIf="!canEditTable()"
                                  [class.overridden]="annualDetail.fmvOverridden">{{ getFairMarketValue(annualDetail) | number:"1.0-0" }}</span>
                        </td>
                        <td *ngIf="canEditTable()" style="padding: 0; text-align: center;">
                            <button class="ace-btn has-icon btn-primary btn-danger"
                                    style="margin: 0;"
                                    *ngIf="annualDetail.fmvOverridden"
                                    (click)="reset(annualDetail)">
                                <i class="fa fa-undo"></i>
                            </button>
                        </td>
                        <td *ngIf="canEditTable()">
                            <button
                                class="ace-btn has-icon btn-primary btn-danger"
                                (click)="deleteComponent(annualDetail)"
                                [attr.data-sd-assessment-component-name]="annualDetailsService.getComponentName(annualDetail)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>

            <div class="small-control-panel"
                 style="border: solid 1px var(--ace-neutral-300); box-shadow: 3px 3px 6px var(--ace-neutral-300); padding: 5px 5px; background-color: white; border-radius: 8px; margin: 0 20px 30px 20px;"
                 *ngIf="compactMode && editState.editMode && !viewModel.isActual()">
                <span style="display:inline-block;"
                      *ngIf="showComponentDropdown()"
                      class="btn-group"
                      dropdown
                      container="body">
                    <button type="button"
                            class="ace-btn btn-sm btn-primary dropdown-toggle"
                            dropdownToggle>
                        Add Component
                        <span class="caret"></span>
                    </button>
                    <ul *dropdownMenu role="menu" class="dropdown-menu">
                        <li role="menuitem"
                            *ngFor="let type of getUnusedComponentTypes(viewModel.model.annualAssessmentDetails)"
                            [attr.data-sd-assessment-component-name]="type.componentName">
                            <a (click)="addRow(type)"
                               class="dropdown-item"
                               style="cursor: pointer;">{{ type.componentName }}</a>
                        </li>
                    </ul>
                </span>
                <span [class]="calcDisabled()"
                      style="padding-left: 5px; display: inline-block;"
                      *ngIf="!viewModel.isActual() && viewModel.currentYear.annualGridDetails.length <= 1">
                    <div class="checkbox">
                        <label class="display-type">
                            <input type="checkbox"
                                   [(ngModel)]="viewModel.currentYear.calcProjected"
                                   (change)="autoCalculateRefresh()"
                                   [disabled]="!editState.editMode || !viewModel.currentYear.calcProjected">Auto Calculate
                        </label>
                    </div>
                </span>
            </div>
            <div style="float: right;" class="assessment-target-value">
                <div *ngIf="editState.editMode && targetValueChanged" style="color: var(--ace-danger); margin-bottom: 10px;">
                    <div *ngIf="isTargetValueGreaterThanTotalFmv">WARNING! Target Value exceeds FMV</div>
                </div>
                <!-- I happen to know that compactMode is only set on document processing; if we have other cases where compactMode
                    is used, we may need to revisit this. The idea is to hide the Target Value field while on document processing. -->
                <div *ngIf="!compactMode && viewModel.isLastAssessment" class="mb-3">
                    <div
                        *ngIf="viewModel.currentYear.targetValue && (!editState.hasWritePermission || !editState.editMode)"
                        style="font-weight:bold;margin-right:5px;">
                        <span>Target Value: </span><span>{{ viewModel.currentYear.targetValue | number:'1.0-0' }}</span>
                    </div>
                    <div *ngIf="editState.hasWritePermission && editState.editMode">
                        <label>
                            <span style="font-weight:bold;">Target Value: </span>
                            <input
                                type="text"
                                class="form-control"
                                style="font-weight:normal; color:black; display:inline-block; width: 100px; text-align: right;"
                                [(ngModel)]="viewModel.currentYear.targetValue"
                                (ngModelChange)="setDirty()"
                                (blur)="targetValueChanged()"
                                currencyMask
                                [options]="{ allowNegative: true, precision: 0, prefix: '' }"/>
                        </label>
                    </div>
                </div>
            </div>
            <div *ngIf="editState.editMode && appealByChanged" style="color: var(--ace-danger); margin-bottom: 10px;">
                <div *ngIf="appealByIsInPast">WARNING! Appeal By date is in the past</div>
                <div *ngIf="!appealByIsInPast && appealByIsLaterThanOriginal">WARNING! The date entered is later than
                    the system default date
                </div>
            </div>
            <div style="float: left;">
                <div class="mb-3 appeal-by-date"
                     style="margin-bottom: 5px;"
                     [class.appeal-deadline-overridden]="isDeadlineOverridden()">
                    <div style="position: relative; height: 26px;">
                        <span style="font-weight: bold; margin-right: 4px;">Appeal By: </span>
                        <div style="width: 140px; display: inline-flex; position: absolute; top: -6px; left: 72px;"
                             *ngIf="editState.hasWritePermission && editState.editMode && !viewModel.isActual(); else appealByLabel">
                            <!--" && !taskSummaries?.CompletedDateTime">-->
                            <weissman-datetime [class.date-time-override]="!areDeadlinesEqual()"
                                               (change)="onAppealDeadlineChanged()"
                                               [(inputDate)]="viewModel.model.appealDeadline"
                                               (inputDateBlur)="revertDateCheck()"
                                               [dateOnly]="true"></weissman-datetime>
                        </div>
                        <button class="ace-btn has-icon btn-primary btn-danger"
                                style="margin: 0; position: absolute; right: -31px; top: -3px;"
                                *ngIf="editState.hasWritePermission && editState.editMode && !viewModel.isActual() && isDeadlineOverridden()"
                                (click)="resetAppealDeadline_click()">
                            <i class="fa fa-undo"></i>
                        </button>
                        <i class="fa fa-chain-broken"
                           style="position: absolute; font-size: 18px; margin-top: 8px;margin-left: 6px;"
                           aria-hidden="true"
                           *ngIf="!taskSummaries && taskSummariesLoaded"></i>
                        <ng-template #appealByLabel>
                            <span class="deadline-read-only"
                                  style="margin-right: 6px;">{{ viewModel.model.appealDeadline | weissmanDateFormat: true }}</span>
                        </ng-template>
                    </div>

                    <div style="height: 26px; display: flex; gap: 3px;" *ngIf="taskSummaries">
                        <span style="font-weight: bold; margin-right: 5px;">Status:</span>
                        <i class="fa fa-check" *ngIf="taskSummaries?.CompletedDateTime"
                           style="color: var(--ace-success); font-size: 18px;"></i>
                        <a (click)="launchTaskModal()" style="cursor: pointer;"
                           class="anchor-color">{{ taskSummaries?.Status }}
                            <span
                                *ngIf="taskSummaries.CompletedDateTime">{{ taskSummaries.CompletedDateTime | weissmanDateFormat: true: "Central" }}</span>
                        </a>
                    </div>
                    <div style="height: 26px;" *ngIf="!taskSummaries && taskSummariesLoaded">
                        <span style="font-weight: bold; margin-right: 4px;">Status: </span>N/A - No Responsibility <br/>
                        <div class="display-type checkbox"
                             *ngIf="editState.hasWritePermission">
                            <label>
                                <input type="checkbox"
                                       [checked]="viewModel.isActual()"
                                       [disabled]="viewModel.currentYear.calcProjected || !editState.editMode"
                                       (change)="changeActual()">Actual
                            </label>
                        </div>
                    </div>
                    <div style="height: 26px;"
                         *ngIf="hasAllocationFeature && isPPParcel && allocationTaskSummaries && allocationTaskSummariesLoaded">
                        <div>
                            <span style="font-weight: bold; margin-right: 8px;">Allocation Status:</span>
                            <a (click)="launchAllocationTaskModal()"
                               style="cursor:pointer">{{ allocationTaskSummaries.Status }}</a>
                        </div>
                        <div>
                            <button type="button"
                                    class="ace-btn btn-sm btn-primary"
                                    (click)="goToAllocation()">
                                {{ hasAllocation ? 'Continue' : 'Begin' }} Allocation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
    <div class="col-xxl-3 ps-0" style="padding-right: 20px;" *ngIf="!compactMode">
        <div *ngIf="editState.editMode || (!viewModel.isActual() && currentYear.annualGridDetails.length <= 1)"
             style="border: solid 1px var(--ace-neutral-300); box-shadow: var(--ace-shadow); padding: 5px 5px; background-color: white; border-radius: 8px; width:fit-content;">
            <!-- Action Buttons -->
            <div class="d-flex">
                <button class="ace-btn btn-sm btn-primary btn-danger w-100"
                        (click)="deleteAssessment()"
                        *ngIf="editState.hasWritePermission && !readOnly()">Delete Asmt
                </button>
            </div>

            <!-- Add component button -->
            <div *ngIf="showComponentDropdown()" class="btn-group buttongroup-first buttongroup-last" dropdown container="body">
                <button type="button"
                        class="ace-btn btn-sm btn-primary dropdown-toggle"
                        dropdownToggle>
                    Add Component
                    <span class="caret"></span>
                </button>
                <ul *dropdownMenu role="menu" class="dropdown-menu">
                    <li role="menuitem"
                        *ngFor="let type of getUnusedComponentTypes(viewModel.model.annualAssessmentDetails)"
                        [attr.data-sd-assessment-component-name]="type.componentName">
                        <a (click)="addRow(type)"
                           class="dropdown-item"
                           style="cursor:pointer;">{{ type.componentName }}</a>
                    </li>
                </ul>
            </div>

            <!-- Checkboxes -->
            <div [class]="calcDisabled()"
                 style="padding-left: 5px;"
                 *ngIf="!viewModel.isActual() && currentYear.annualGridDetails.length <= 1">
                <div class="checkbox">
                    <label class="display-type">
                        <input type="checkbox"
                               [(ngModel)]="viewModel.currentYear.calcProjected"
                               (change)="autoCalculateRefresh()"
                               [disabled]="!editState.editMode">Auto Calculate
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #renameTemplate>
    <div class="radio" [class.disabled]="viewModel.isActual()">
        <label>
            <input type="radio"
                   [(ngModel)]="revisionName"
                   (change)="setRevisionName(true)"
                   name="optionsRadios"
                   value="Forecast"
                   [disabled]="viewModel.isActual()">Forecast
        </label>
    </div>
    <div class="radio" style="display:block">
        <label>
            <input type="radio"
                   [(ngModel)]="revisionName"
                   (change)="setRevisionName(true)"
                   name="optionsRadios"
                   value="Original">Original
        </label>
    </div>
    <div class="radio" style="display:block">
        <label>
            <input type="radio"
                   [(ngModel)]="revisionName"
                   (change)="setRevisionName(true)"
                   name="optionsRadios"
                   value="Final">Final
        </label>
    </div>
    <div class="radio" style="display:block">
        <label>
            <input type="radio"
                   [(ngModel)]="revisionName"
                   (change)="setRevisionName(false)"
                   name="optionsRadios"
                   value="Other">Other
            <input type="text"
                   [(ngModel)]="revisionOtherName"
                   (blur)="renamePopover.hide()"
                   (keyup.enter)="blurPopover($event)"
                   (change)="setRevisionName()"
                   name="revisionDesc"
                   maxlength="10"
                   *ngIf="revisionName === 'Other'">
        </label>
    </div>
</ng-template>
