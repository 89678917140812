import { Injectable } from '@angular/core';
import { Subject,  Observable } from 'rxjs';
import { SDHttpService } from '../Routing/sd-http.service';
import {
    ContractTermSaveEvent,
    ContractTerm,
    ContractTermData,
    Engagement,
    InvoiceRecipient,
    SiteContractTerm,
    FeeEscalatorUpdateDTO
} from '../Contracts-Invoices/contract-invoices.model';
import { EntityType } from '../../constants.new';

@Injectable(
    { providedIn: 'root' }
)
export class ContractsInvoicesService {
    constructor(private http: SDHttpService) {
        this.contractTermsUpdateSubject = new Subject<ContractTermSaveEvent>();
        this.contractTermsUpdateObservable = this.contractTermsUpdateSubject.asObservable();
    }

    public contractTermsUpdateObservable: Observable<ContractTermSaveEvent>;
    private contractTermsUpdateSubject: Subject<ContractTermSaveEvent>;


    getContractTermsByCompany(companyId: number): Promise<ContractTermData> {
        return this.http.get(`/api/company/${  companyId  }/contractTerms/`);
    }

    getContractTermsBySite(siteId: number): Promise<SiteContractTerm[]> {
        return this.http.get(`/api/site/${  siteId  }/contractTerms/`);
    }

    shouldRequireConfirmationDialog(companyId: number, terms: any): Promise<boolean> {
        return this.http.post(`/api/company/${  companyId  }/contractterms/confirmationrequired`, terms);
    }

    saveCompanyContractTerms(companyId: number, terms: any): Promise<ContractTermData> {
        return this.http.post(`/api/company/${  companyId  }/contractterms`, terms).then(result => {
            this.contractTermsUpdateSubject.next({
                entityId: companyId,
                entityType: EntityType.Company
            });
            return result;
        });
    }

    saveSiteContractTerms(siteId: number, terms: any): Promise<SiteContractTerm[]> {
        return this.http.post(`/api/site/${  siteId  }/contractterms`, terms).then(result => {
            this.contractTermsUpdateSubject.next({
                entityId: siteId,
                entityType: EntityType.Site
            });
            return result;
        });
    }

    rollForwardYear(companyId: number, sourceYear: number, targetYear: number, rollForwardSiteOverrides: boolean): Promise<ContractTerm[]> {
        return this.http.post(`/api/company/${  companyId  }/contractterms/rollforwardyear`, {
            sourceYear: sourceYear,
            targetYear: targetYear,
            rollForwardSiteOverrides: rollForwardSiteOverrides
        });
    }

    searchEngagements(searchTerm: string): Promise<Engagement[]> {
        return this.http.get(`/api/invoice/engagements?search=${  searchTerm}`);
    }

    getInvoiceRecipientsByEngagement(engagementId: string): Promise<InvoiceRecipient[]> {
        return this.http.get(`/api/invoice/billingcontacts?engagementId=${  engagementId}`);
    }

    saveFeeEscalator(companyId: number, request: FeeEscalatorUpdateDTO): Promise<void> {
        return this.http.put(`/api/company/${companyId}/ContractTerms/FeeEscalator`, request);
    }
}
