<div class="container">
	<div class="single-column-layout">
		<h1>External API Key</h1>
        <div [style.display]="loading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <p *ngIf="errorMessage">{{errorMessage}}</p>
        <div *ngIf="!errorMessage && !loading && requestCode">
            <p>Your public key is:</p>
            <pre>{{keyTransmittalPublicData.publicKey}}</pre>
            <div *ngIf="!privateKey">
                <div><button (click)="getPrivateKey()" class="btn-primary btn">Get Private Key</button></div>
                <p>
                    Note: once you get the private key this request will expire; if you lose the key you will have to
                    request a new API key email
                </p>
            </div>
            <div *ngIf="privateKey">
                <p>Your private key is:</p>
                <pre>{{privateKey}}</pre>
                <p>
                    Please record this information in a secure location; if you lose this key you will have to request a
                    new API key email
                </p>
            </div>
        </div>
        <div *ngIf="!loading">
            <p>
                <span class="anchor-style" (click)="backToHomepage_Click()">(back to home page)</span>
            </p>
        </div>
    </div>
</div>
