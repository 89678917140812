import { Component, Input } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { AgGridMultiSelectTracker } from '../MultiSelectTracker';

@Component({
    selector: 'ag-grid-row-count',
    templateUrl: './agGridRowCount.component.html',
    styleUrls: ['./agGridRowCount.component.scss']
})
export class AgGridRowCountComponent {
    @Input() gridTracker: AgGridMultiSelectTracker;
    @Input() gridOptions: GridOptions;
    @Input() prefix: string = '(';
    @Input() suffix: string = ')';
    @Input() cssClass: string = 'ag-grid-row-count-text';
    @Input() useWhiteText: boolean;

    get supportsSelect(): boolean {
        return this.gridTracker ||
            (this.gridOptions && (this.gridOptions.rowSelection === 'single' || this.gridOptions.rowSelection === 'multiple')) ? true : false;
    }

    get selectedRowCount(): number {
        if (this.gridTracker) {
            return this.gridTracker.getSelectedRowsCount();
        }

        if (this.gridOptions && this.gridOptions.api) {
            return this.gridOptions.api.getSelectedRows().length;
        }

        return 0;
    }

    get totalRowCount(): number {
        if (!(this.gridOptions && this.gridOptions.api)) {
            return 0;
        }

        if (this.gridOptions.rowModelType === 'infinite') {
            return this.gridOptions.api.isMaxRowFound() ? this.gridOptions.api.getInfiniteRowCount() : 0;
        } else {
            return this.gridOptions.api.getDisplayedRowCount();
        }
    }
}
