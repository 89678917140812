import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

export interface ObtainPaymentReceiptTasksRequest {
    taskIDs: number[],
    searchTimestamp: Date
}

@Injectable({providedIn: 'root'})
export class ActionViewRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    getTaskContextOptions(itemPayload): Observable<any> {
        return this.httpPost('/api/TaskActionView/TaskContextOptions', itemPayload);
    }

    addObtainPaymentreceiptTasks(taskIds: number[]) {
        return this.httpPut('api/tasks/AddObtainPaymentReceiptTasks', taskIds);
    }

    addObtainWorkpapersTasks(taskIds: number[]): Observable<any> {
        return this.httpPost('api/tasks/AddObtainWorkpapersTasks', taskIds);
    }

    completeObtainPaymentReceiptTasks(payload: ObtainPaymentReceiptTasksRequest): Observable<any> {
        return this.httpPut('api/tasks/completeobtainpaymentreceipt', payload);
    }
}
