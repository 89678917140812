<ws-modal-wrapper [headerLabel]="assetTitle"
                  [hideFooter]="!createNewAsset"
                  acceptLabel="Save and Continue"
                  (save)="saveNewUserCreatedAsset()"
                  [disableSave]="!(this.currentAsset && this.currentAsset.reportedAcqDate && !(this.currentAsset.reportedCost === null || this.currentAsset.reportedCost === undefined) && this.currentAsset.reportedAssetNumber && this.currentAsset.reportedSite && (!this.currentAsset.isLeasedAsset || this.currentAsset.reportedLeaseTypeId))"
                  (cancel)="closeWithDirtyCheck()">
        <ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

        <div class="card panel-flat-color primary-panel" [hidden]="isMaximizedReturnFilingsPanel || isMaximizedAssetActivityPanel">
            <div *ngIf="!createNewAsset" class="panel-flat-header" (click)="toggleIsExpandedForAssetInfoPanel()" [ngClass]="{'pointer': !isMaximizedAssetInfoPanel }">
                <div class="float-start">
                    <div class="header-icon">
                        <panel-chevron [showBody]="isExpandedAssetInfoPanel"></panel-chevron>
                    </div>
                    <h3>Asset Info <span *ngIf="isFromLockedReturn || isFromLockedAllocation">(Displayed values may differ from locked values shown in {{isFromLockedReturn ? 'return' : 'allocation'}})</span></h3>
                </div>

                <div class="float-end header-button-container" (click)="$event.stopPropagation()">
                    <entity-comments [(hasComments)]="hasComments"
                                     (commentCountChange)="commentCountChange($event)"
                                     [isNestedModal]="true"
                                     buttonClass="ace-btn btn-text has-icon grid-action-button"
                                     [modalData]="commentModalData">
                    </entity-comments>
                    <button title="Edit"
                            type="button"
                            class="ace-btn btn-text has-icon btn-primary"
                            *ngIf="canEdit && isReadyAssetInfoComponent && !isAssetInfoComponentInEditState"
                            [disabled]="!(isExpandedAssetInfoPanel || isMaximizedAssetInfoPanel)"
                            (click)="enterAssetInfoEdit()">
                        <i class="fa fa-pencil fa-align"></i>
                    </button>
                    <button title="Save"
                            type="button"
                            class="ace-btn btn-text has-icon btn-primary"
                            *ngIf="isReadyAssetInfoComponent && isAssetInfoComponentInEditState"
                            [disabled]="!(isExpandedAssetInfoPanel || isMaximizedAssetInfoPanel)"
                            (click)="saveAssetInfoEdit()">
                        <i class="fa fa-save fa-align"></i>
                    </button>
                    <button title="Cancel"
                            type="button"
                            class="ace-btn btn-text has-icon btn-primary"
                            *ngIf="isReadyAssetInfoComponent && isAssetInfoComponentInEditState"
                            [disabled]="!(isExpandedAssetInfoPanel || isMaximizedAssetInfoPanel)"
                            (click)="cancelAssetInfoEdit()">
                        <i class="fa fa-times fa-align"></i>
                    </button>
                    <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isMaximizedAssetInfoPanel" (click)="toggleIsMaximizedForAssetInfoPanel()">
                        <i class="fa fa-arrows-alt" title="Maximize"></i>
                    </button>
                    <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isMaximizedAssetInfoPanel" (click)="toggleIsMaximizedForAssetInfoPanel()">
                        <i class="fa fa-compress" title="Restore"></i>
                    </button>
                </div>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical" [hidden]="!isExpandedAssetInfoPanel">
                <div *ngIf="isInitialized" class="ws-flex-container-horizontal ws-flex-align-items-center ws-stretch-width top-options">
                    <div *ngIf="lienDateChanged" class="text-warning" style="padding-right: 10px;">The date selection was adjusted based on the saved asset info</div>
                    <label>View as of:</label>
                    <asset-lien-date id="assetLienDate"
                                     [useSavedLienDate]="false"
                                     [saveLienDate]="false"
                                     [showFilters]="false"
                                     [selectedLienDate]="lienDate"
                                     [companyId]="topLevelCompanyId"
                                     [markedDates]="changeDates"
                                     [minDate]="minLienDate"
                                     [maxDate]="maxLienDate"
                                     [isDisabled]="isLoading"
                                     (dateChange)="onLienDateChanged($event)"
                                     style="margin-left: 8px">
                    </asset-lien-date>
                </div>
                <asset-info #assetInfo
                            [ngClass]="isMaximizedAssetInfoPanel ? 'ws-flex-auto' : 'ws-flex-none'"
                            [assetDetailModel]="assetDetailModel"
                            [createNewAsset]="createNewAsset"
                            [newAssetCompanyId]="newAssetCompanyId"
                            [topLevelCompanyId]="topLevelCompanyId"
                            [reportingAssetId]="reportingAssetId"
                            [lienDate]="lienDate"
                            [assets]="assets"
                            [currentAsset]="currentAsset"
                            [assetDescriptors]="assetDescriptors"
                            [states]="states"
                            [isEditMode]="isAssetInfoComponentInEditState"
                            (selectedDescriptorsChanged)="updateAssetDescriptors($event)"
                            (currentAssetChanged)="setCurrentAssetModel($event)"
                            (assetInfoReady)="assetInfoIsReady($event)"
                            *ngIf="isInitialized">
                </asset-info>
            </div>
        </div>

        <div class="card panel-flat-color secondary-panel" *ngIf="!createNewAsset" [hidden]="isMaximizedAssetInfoPanel || isMaximizedAssetActivityPanel">
            <div class="panel-flat-header" (click)="toggleIsExpandedReturnFilingsPanel()" [ngClass]="{'pointer': !isMaximizedReturnFilingsPanel}">
                <div class="float-start">
                    <div class="header-icon">
                        <panel-chevron [showBody]="isExpandedReturnFilingsPanel"></panel-chevron>
                    </div>
                    <h3>Return Filings</h3>
                </div>
                <div class="float-end header-button-container" (click)="$event.stopPropagation()">
                    <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isMaximizedReturnFilingsPanel" (click)="toggleIsMaximizedReturnFilingsPanel()">
                        <i class="fa fa-arrows-alt" title="Maximize"></i>
                    </button>
                    <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isMaximizedReturnFilingsPanel" (click)="toggleIsMaximizedReturnFilingsPanel()">
                        <i class="fa fa-compress" title="Restore"></i>
                    </button>
                </div>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical" *ngIf="isExpandedReturnFilingsPanel">
                <asset-return-filings class="ws-flex-auto"
                                      [ngClass]="{'asset-details-maximized':isMaximizedReturnFilingsPanel}"
                                      [assetId]="assetId"
                                      [lienDate]="lienDate"
                                      [companyId]="assetDetailModel.companyId"></asset-return-filings>
            </div>
        </div>

        <div class="card panel-flat-color secondary-panel" *ngIf="!createNewAsset" [hidden]="isMaximizedAssetInfoPanel || isMaximizedReturnFilingsPanel">
            <div class="panel-flat-header" (click)="toggleIsExpandedForAssetActivityPanel()" [ngClass]="{'pointer': !isMaximizedAssetActivityPanel}">
                <div class="float-start">
                    <div class="header-icon">
                        <panel-chevron [showBody]="isExpandedAssetActivityPanel"></panel-chevron>
                    </div>
                    <h3>Source Value Activity Log</h3>
                </div>
                <div class="float-end header-button-container" (click)="$event.stopPropagation()">
                    <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isMaximizedAssetActivityPanel" (click)="toggleIsMaximizedForAssetActivityPanel()">
                        <i class="fa fa-arrows-alt" title="Maximize"></i>
                    </button>
                    <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isMaximizedAssetActivityPanel" (click)="toggleIsMaximizedForAssetActivityPanel()">
                        <i class="fa fa-compress" title="Restore"></i>
                    </button>
                </div>
            </div>
            <div class="panel-flat-body ws-flex-container-vertical" *ngIf="isExpandedAssetActivityPanel">
                <asset-activity-log class="ws-flex-auto" [ngClass]="{'asset-details-maximized':isMaximizedAssetActivityPanel}" [reportingAssetId]="reportingAssetId"></asset-activity-log>
            </div>
        </div>
    </ws-modal-wrapper>
