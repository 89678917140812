<div class="card panel-flat-color" [ngClass]="panelClass">
        <div class="panel-flat-header">
            <div class="float-start">
                <div class="header-icon" *ngIf="panelIconClass">
                    <i [ngClass]="panelIconClass"></i>
                </div>
                <h3>{{ panelTitle }}</h3>
            </div>
            <div class="float-end header-button-container">
                <button
                    title="Edit"
                    type="button"
                    class="ace-btn btn-text btn-sm has-icon btn-primary"
                    *ngIf="supportsMultipleViews && view === 'DISPLAY'"
                    (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
                <button
                    title="Save"
                    type="button"
                    class="ace-btn has-icon btn-primary"
                    *ngIf="view === 'EDIT'"
                    (click)="save()">
                    <i class="fa fa-save fa-align"></i>
                </button>
                <button
                    title="Cancel"
                    type="button"
                    class="ace-btn has-icon btn-primary"
                    *ngIf="view === 'EDIT'"
                    (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
                <button
                    title="Expand"
                    type="button"
                    class="ace-btn has-icon btn-primary"
                    *ngIf="canExpand && !isExpanded"
                    (click)="toggleIsExpanded()">
                    <i class="fa fa-plus fa-align"></i>
                </button>
                <button
                    title="Collapse"
                    type="button"
                    class="ace-btn has-icon btn-primary"
                    *ngIf="canCollapse && isExpanded"
                    (click)="toggleIsExpanded()">
                    <i class="fa fa-minus fa-align"></i>
                </button>
            </div>
        </div>
        <div class="card-body" *ngIf="isExpanded">
            <ng-content *ngIf="view === 'DISPLAY'" select="[display-view]"></ng-content>
            <ng-content *ngIf="view === 'EDIT'" select="[edit-view]"></ng-content>
        </div>
        <!-- TODO: Add footer functionality if necessary. Consider differentiating between 'EDIT' and 'DISPLAY' footers. -->
        <!-- <div class="panel-footer" *ngIf="isExpanded">footers</div> -->
    </div>
