
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UtilitiesService } from '../../../UI-Lib/Utilities/Utilities.Service.upgrade';

/**
 * Looks at a response and converts all Date strings into JavaScript Date objects.
 */
@Injectable()
export class DateConvertInterceptor implements HttpInterceptor {
    constructor(private _utilities: UtilitiesService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next
            .handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    this._utilities.convertDateStringsToDates(event.body);
                }
                return event;
            }));
    }
}
