import { Directive, Input, OnInit, Renderer2, ElementRef, HostListener } from '@angular/core';
import { UIRouter } from '@uirouter/angular';

export interface AnchorSREF {
    target: string;
    options: {[option: string]: any}
}

/**
 *
 * Anchor Route Directive
 *
 * Provides a way to use anchor tags with the router so that right click and Ctrl click works
 * to open in new tabs.
 *
 * example usage:
 *
 * <a anchor-route [sref]="{ target: 'company', options: { companyId: companyId } }"> Go to Company </a>"
 *
 */
@Directive({
    selector: '[anchor-route]'
})
export class AnchorRouteDirective implements OnInit {
    constructor(
        private readonly _routerService: UIRouter,
        private readonly _renderer: Renderer2,
        private readonly _elementRef: ElementRef
    ) { }

    @Input() isTargetBlank: boolean;
    @Input()
    set sref(sref: AnchorSREF) {
        this._sref = sref;
        if (this._initialized) {
            this._createHREF();
        }
    }

    private _href: string;
    private _sref: AnchorSREF;
    private _initialized: boolean;

    /**
     * Listen for click to route to new page
     *
     * @param e
     */
    @HostListener('click', ['$event'])
    click(e: MouseEvent) {

        // Prevent the href from doing any navigation on click
        e.preventDefault();

        if (!this._sref) { return; }

        if (e.ctrlKey || this.isTargetBlank) {
            // Ctrl click opens in new tab
            window.open(this._href, '_blank');
        } else {
            this._routerService.stateService.transitionTo(this._sref.target, this._sref.options);
        }
    }

    ngOnInit(): void {
        this._createHREF();
        this._initialized = true;
    }

    private _createHREF(): void {
        if (this._sref) {
            // Create the href dynamically for right-click menu to open in new tab
            this._href = this._routerService.stateService.href(this._sref.target, this._sref.options, { absolute: true });
            this._renderer.setAttribute(this._elementRef.nativeElement, 'href', this._href);
        }
    }
}
