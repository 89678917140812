<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
                   *ngIf="busyIndicatorMessageManager.count() > 0">
</ws-busy-indicator>

<ws-modal-wrapper [headerLabel]="((params.displayMode === DisplayMode.Add ? 'New ' : '') + 'G/L Account')"
                  (save)="save()"
                  (cancel)="cancel()"
                  [cancelClass]="(params.displayMode === DisplayMode.View) ? 'btn-primary': 'btn-primary btn-danger'"
                  [hideSave]="params.displayMode === DisplayMode.View"
                  [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3"
                     [ngClass]="{ 'has-error': form.controls.accountNumber.touched && form.controls.accountNumber.errors }">
                    <label for="name">G/L Account Number</label>
                    <div *ngIf="params.displayMode === DisplayMode.View || params.foundInAccruals">{{ form.controls.accountNumber.value }}</div>
                    <input *ngIf="params.displayMode !== DisplayMode.View && !params.foundInAccruals"
                           type="text"
                           class="form-control"
                           formControlName="accountNumber"
                           autocomplete="off"/>
                    <div *ngIf="form.controls.accountNumber.touched && form.controls.accountNumber.errors"
                         class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.accountNumber.errors.required">G/L Account Number is required.</div>
                        <div class="text-danger small" *ngIf="form.controls.accountNumber.errors.maxlength">G/L Account Number max length is 100 characters.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3"
                     [ngClass]="{ 'has-error': form.controls.accountName.touched && form.controls.accountName.errors }">
                    <label for="name">G/L Account Name</label>
                    <div *ngIf="params.displayMode === DisplayMode.View">{{ form.controls.accountName.value }}</div>
                    <input *ngIf="params.displayMode !== DisplayMode.View"
                           type="text"
                           class="form-control"
                           formControlName="accountName"
                           autocomplete="off"/>
                    <div *ngIf="form.controls.accountName.touched && form.controls.accountName.errors"
                         class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.accountName.errors.required">G/L Account Name is required.</div>
                        <div class="text-danger small" *ngIf="form.controls.accountName.errors.maxlength">G/L Account Name max length is 100 characters.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3"
                     [ngClass]="{ 'has-error': form.controls.description.touched && form.controls.description.errors }">
                    <label for="name">Description</label>
                    <div *ngIf="params.displayMode === DisplayMode.View">{{ form.controls.description.value }}</div>
                    <input *ngIf="params.displayMode !== DisplayMode.View"
                           type="text"
                           class="form-control"
                           formControlName="description"
                           autocomplete="off"/>
                    <div *ngIf="form.controls.description.touched && form.controls.description.errors"
                         class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.description.errors.maxlength">G/L Account Description max length is 500 characters.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3">
                    <label for="name">Account Type</label>
                    <div *ngIf="params.displayMode === DisplayMode.View || params.foundInAccruals">{{ currAccountType?.name }}</div>
                    <div *ngIf="params.displayMode !== DisplayMode.View && !params.foundInAccruals">
                        <select id="accountType"
                                name="accountType"
                                class="form-select form-select-sm"
                                formControlName="selectedAccountType"
                                (change)="accountTypeChanged($event)">
                            <option *ngFor="let at of accountTypes" [ngValue]="at">{{ at.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" [hidden]="!accrualsFeatureEnabled || !currAccountType.validForAccrual">
            <div class="col-lg-12">
                <div class="mb-3">
                    <label for="applicToAccruals" class="ws-font-weight-normal">
                        <input type="checkbox"
                               id="applicToAccruals"
                               formControlName="applicableToAccruals"
                               (change)="switchApplicableToAccruals($event)">
                        Applicable to accruals
                    </label>
                    <div style="width: auto; display: inline-block; margin-left: 15px"
                         *ngIf="(params.displayMode === DisplayMode.View && form.controls.selectedAccountAccrualType.value) || params.foundInAccruals"
                         [hidden]="!form.controls.applicableToAccruals.value">{{ form.controls.selectedAccountAccrualType.value?.name }}</div>
                    <div style="width: auto; display: inline-block; margin-left: 15px;"
                         *ngIf="params.displayMode !== DisplayMode.View && !params.foundInAccruals"
                         [hidden]="!form.controls.applicableToAccruals.value">
                        <select id="accountAccrualType"
                                name="accountAccrualType"
                                class="form-select form-select-sm"
                                formControlName="selectedAccountAccrualType"
                                (change)="accountAccrualTypeChanged()">
                            <option *ngFor="let aat of currAccountAccrualTypes" [ngValue]="aat">{{ aat.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="mb-3"
                     [hidden]="!form.controls.applicableToAccruals.value || form.controls.selectedAccountAccrualType.value?.glAccountAccrualTypeId != PropertyTaxPayableType">
                    <label for="includesPrepaid" class="ws-font-weight-normal">
                        <input type="checkbox" id="includesPrepaid" formControlName="payableIncludesPrepaid">
                        Includes Prepaid
                    </label>
                </div>
            </div>
        </div>

        <div class="card panel-flat-color secondary-panel"
             [hidden]="!accrualsFeatureEnabled || !form.controls.applicableToAccruals.value">
            <div class="panel-flat-header" style="margin-bottom: 0;">
                <div class="header-icon">
                    <i class="fa fa-filter"></i>
                </div>
                <h3>Accrual Scope</h3>
            </div>
            <div class="panel-flat-body">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div style="display: inline-block; vertical-align: top;">
                            <label>Property Types:</label>
                        </div>
                        <div style="display: inline-block; margin-left: 10px;">
                            <div *ngFor="let propTypeControl of fPropTypes.controls; let ptIndex = index"
                                 formArrayName="forPropertyTypes"
                                 style="display: block;">
                                <label>
                                    <input type="checkbox" [formControl]="$any(propTypeControl)">
                                    {{propertyTypes[ptIndex].name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="mb-3">
                        <div style="display: inline-block; vertical-align: top;">
                            <label>Payment Types:</label>
                        </div>
                        <div style="display: inline-block; margin-left: 10px;">
                            <div *ngFor="let pmtTypeControl of fPmtTypes.controls; let pmtIndex = index"
                                 formArrayName="forPaymentTypes"
                                 style="display: block;">
                                <label>
                                    <input type="checkbox" [formControl]="$any(pmtTypeControl)">
                                    {{paymentTypes[pmtIndex].name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isTopLevelCompany" class="col-lg-12">
                    <div class="mb-3">
                        <div style="display: inline-block; vertical-align: top; margin-top: 0;">
                            <label>Companies:</label>
                        </div>
                        <div style="display: inline-block; vertical-align: top; margin-left: 20px;">
                            <div class="row radio" style="margin-top: 0;">
                                <label>
                                    <input type="radio"
                                           name="forAllCompanies"
                                           [value]="true"
                                           formControlName="forAllCompanies">
                                    Top level and all subsidiaries
                                </label>
                            </div>
                            <div class="row radio" style="margin-top: 10px;">
                                <label>
                                    <input type="radio"
                                           name="forAllCompanies"
                                           [value]="false"
                                           formControlName="forAllCompanies">
                                    Specific list of companies
                                </label>
                            </div>
                        </div>
                        <div *ngIf="!form.controls.forAllCompanies.value && accrualScopeCompanies.length"
                             style="display: inline-block; vertical-align: top; margin-left: 20px;">
                            <select multiple class="filter-select" formControlName="forCompanies">
                                <option *ngFor="let company of accrualScopeCompanies" [ngValue]="company.id">
                                    {{company.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row"
             [hidden]="accrualsFeatureEnabled && form.controls.applicableToAccruals.value"
             *ngIf="showAssetClassification">
            <div class="col-lg-12">
                <div class="mb-3">
                    <label for="name">Asset Class</label>
                    <div *ngIf="params.displayMode === DisplayMode.View || params.foundInAccruals">{{ form.controls.assetClassificationName.value }}</div>
                    <div *ngIf="params.displayMode !== DisplayMode.View && !params.foundInAccruals">
                        <asset-class-selector [isRequiredField]="false"
                                              formControlName="assetClassificationId"
                                              (assetClassificationChange)="onAssetClassificationChanged($event)">
                        </asset-class-selector>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showIncomeStatementCategory">
            <div class="col-lg-12">
                <div class="mb-3">
                    <label for="name">Income Statement Category</label>
                    <div *ngIf="params.displayMode === DisplayMode.View || params.foundInAccruals">{{ form.controls.incomeStatementCategoryName.value }}</div>
                    <div *ngIf="params.displayMode !== DisplayMode.View && !params.foundInAccruals">
                        <income-statement-category-selector [isRequiredField]="false"
                                                            [glAccountTypeId]="currAccountType.glAccountTypeId"
                                                            formControlName="incomeStatementCategoryId"
                                                            (incomeStatementCategoryChange)="onIncomeStatementCategoryChanged($event)">
                        </income-statement-category-selector>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="showIncomeStatementCategory && glAccount.glAccountId">
            <label>
                <input type="checkbox" style="margin-right: 5px;"
                       formControlName="isValidated">
                Is Validated
            </label>
        </div>
    </form>
</ws-modal-wrapper>
