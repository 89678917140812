import { Component, Input } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { has } from 'lodash';

@Component({
    selector: 'ag-grid-filter-toggle',
    templateUrl: './agGridFilterToggle.component.html'
})
export class AgGridFilterToggleComponent  {
    @Input() gridOptions: GridOptions  & { api?: {appliedFilter?: any}};

    get isFilterVisible(): boolean {
        return this.gridOptions && this.gridOptions.floatingFilter;
    }

    onFilterClick() {
        this.gridOptions.floatingFilter = !this.gridOptions.floatingFilter;
        this.gridOptions.api.refreshHeader();

        // clear filter when hiding
        if (!this.gridOptions.floatingFilter && this._isFiltered()) {
            this.gridOptions.api.setFilterModel(null);
            this.gridOptions.api.appliedFilter = null;
            this.gridOptions.api.onFilterChanged();
        }
    }

    private _isFiltered(): boolean {
        const filterModel = this.gridOptions.api.getFilterModel();
        if (!filterModel) {
            return false;
        }

        // filter model is an empty object when not filtered
        for (const key in filterModel) {
            if (has(filterModel, key)) {
                return true;
            }
        }

        return false;
    }
}