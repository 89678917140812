<div [ngClass]="placementClasses"
     clickOutside
     (clickOutside)="setOpenState(false, 'outside')"
     #dropdownHost>
    <div (click)="setOpenState(!isOpen)">
        <slot>
            <ws-ace-button *ngIf="icon || content"
                           buttonStyle="text"
                           state="action"
                           [size]="size"
                           [content]="content"
                           [icon]="icon"
                           [iconSlot]="(content ? 'icon-right' : 'icon')"
                           [isDisabled]="isDisabled">
            </ws-ace-button>
        </slot>
    </div>
    <div [ngClass]="positionClasses"
         [class.show]="isOpen"
         [ngStyle]="ddPosition"
         (click)="setOpenState(false, 'inside')"
         #dropdownMenu>
        <slot name="dropdown-content">
            <div slot="dropdown-content"
                  class="dropdown-content">
                <ng-content></ng-content>
            </div>
        </slot>
    </div>
</div>
