<div class="card panel-flat-color secondary-panel" *ngIf="hasViewPermission || hasEditPermission">
        <div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !isEditMode}">
            <panel-chevron [showBody]="showBody"></panel-chevron>
            <h3>Contract Terms</h3>

            <div class="float-end header-button-container" (click)="$event.stopPropagation()">
                <div *ngIf="showBody" style="display: inline-block; vertical-align: middle; margin-top: -5px; margin-right: 30px;">
                    <span style="margin-right: 5px;">Tax Year:</span>
                    <select (ngModelChange)="yearChanged($event)" [(ngModel)]="selectedYear" class="form-select form-select-sm" style="display: inline-block; width: auto;">
                        <option value="0">All</option>
                        <option *ngFor="let year of contractTermYears" [value]="year">{{year}}</option>
                    </select>
                </div>
                <button class="ace-btn btn-text has-icon btn-primary" *ngIf="!isEditMode && showBody && hasEditPermission && entity.hasWritePermission" (click)="goToEditMode()">
                    <i class="fa fa-pencil"></i>
                </button>
                <div *ngIf="isEditMode && showBody" style="display: inline-block; vertical-align: middle;">
                    <button class="ace-btn btn-text has-icon btn-primary btn-success" (click)="savePanel()" [disabled]="isLoading">
                        <i class="fa fa-save"></i>
                    </button>
                    <button class="ace-btn btn-text has-icon btn-primary btn-danger" (click)="cancelEdit()">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="showBody" style="padding-top: 0;">
            <div class="text-center" style="margin-top: 50px;" *ngIf="isLoading">
                <i class="fa fa-spinner fa-pulse fa-5x"></i>
            </div>
            <div *ngIf="!isLoading">
                <table class="table table-condensed contract-terms-site">
                    <thead>
                        <tr>
                            <th>Service</th>
                            <th>Property Type</th>
                            <th class="text-center">Tax Year</th>
                            <th>Terms</th>
                            <th class="text-center">Engagement #</th>
                        </tr>
                    </thead>
                    <tbody class="contract-terms-view">
                        <tr *ngFor="let term of contractTerms">
                            <td>{{term.service}}</td>
                            <td>{{term.propertyType}}</td>
                            <td class="text-center">{{term.taxYear}}</td>
                            <td>
                                <div *ngIf="!isEditMode && !term.currentTermItem" [class.not-site-default]="isTermNotSiteDefault(term)">N/A</div>
                                <div *ngIf="!isEditMode && term.currentTermItem" [class.not-site-default]="isTermNotSiteDefault(term)">
                                    <term-rates-and-fees [term]="term.currentTermItem" [usePercent]="true"></term-rates-and-fees>
                                    <button
                                            *ngIf="term.currentTermItem && term.currentTermItem.remarks"
                                            class="ace-btn has-icon btn-primary"
                                            (click)="showComments(term)"
                                            style="margin-left:0.5em;"
                                            [title]="term.currentTermItem.remarks">
                                        <i class="fa fa-commenting"></i>
                                    </button>
                                </div>
                                <select *ngIf="isEditMode" [(ngModel)]="term.currentTermItem" class="form-select form-select-sm" [class.not-site-default]="isTermNotSiteDefault(term)">
                                    <option [ngValue]="null" [ngStyle]="{'color': term.companySiteDefaultTermId ? 'red' : 'black'}">N/A</option>
                                    <option *ngFor="let item of term.availableTermItems" [ngValue]="item" [ngStyle]="{'color': item.termDetailId === term.companySiteDefaultTermId ? 'black' : 'red'}">
                                        <term-rates-and-fees [term]="item" [usePercent]="true"></term-rates-and-fees>
                                    </option>
                                </select>
                                <button
                                        *ngIf="isEditMode && term.currentTermItem && term.currentTermItem.remarks"
                                        class="ace-btn has-icon btn-primary"
                                        (click)="showComments(term)"
                                        style="margin-left:0.5em;"
                                        [title]="term.currentTermItem.remarks">
                                    <i class="fa fa-commenting"></i>
                                </button>
                            </td>
                            <td class="text-center">
                                <span *ngIf="term.currentTermItem">{{term.currentTermItem.engagement}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
