<ws-modal-wrapper [headerLabel]="title"
                  [hideSave]="!(hasOK || hasYes || hasContinue)"
                  (save)="save()"
                  [hideCancel]="!(hasNo || hasCancel)"
                  (cancel)="cancel()">
    <p *ngIf="message" style="white-space: pre-line;">{{ message }}</p>
    <div *ngIf="messageList.length">
        <p *ngIf="subtitle"><strong>{{ subtitle }}</strong></p>
        <ul [class.bulk-error-list]="isErrorState">
            <li *ngFor="let message of messageList" class="has-error" style="padding: 4px 0;">{{ message }}</li>
        </ul>
    </div>
</ws-modal-wrapper>
