<div *ngIf="descriptor.fieldTypeID == FieldTypes.Date">
    <div style="margin-top: 5px;">
        <label>Min Year:</label>
        <input type="number"
            [(ngModel)]="descriptor.validation.minValue"
            class="form-control"
            style="width: 50px; margin-left: 6px; display: inline-block;">
    </div>
    <div style="margin-top: 5px;">
        <label>Max Year:</label>
        <input type="number"
            [(ngModel)]="descriptor.validation.maxValue"
            class="form-control"
            style="width: 50px; margin-left: 3px; display: inline-block;">
    </div>
</div>

<div *ngIf="descriptor.fieldTypeID == FieldTypes.Number">
    <div style="margin-top: 5px;">
        <label>Min:</label>
        <input type="number"
            [(ngModel)]="descriptor.validation.minValue"
            class="form-control"
            style="width: 50px; margin-left: 3px; display: inline-block;">
        <label>Max:</label>
        <input type="number"
            [(ngModel)]="descriptor.validation.maxValue"
            class="form-control"
            style="width: 50px; margin-left: 3px; display: inline-block;">
    </div>
    <div style="margin-top: 5px;">
        <label>Format:</label>
        <select [(ngModel)]="descriptor.formatID" class="form-select form-select-sm" style="display: inline-block; width: auto;">
            <option *ngFor="let format of formats" [ngValue]="format.descriptorFormatID">{{format.format}}</option>
        </select>
    </div>
</div>

<div *ngIf="descriptor.fieldTypeID == FieldTypes.Picklist">
    <div class="checkbox">
        <label>
            <input type="checkbox" [(ngModel)]="descriptor.validation.limitToList" [disabled]="!canLimitToList"> Limit Entries to List
        </label>
    </div>
    <div *ngIf="picklistLoading">
        <i class="fa fa-spinner"></i>
    </div>
    <ul class="list-group" style="max-height: 250px; overflow: auto; overflow-x: hidden; margin-bottom: 2px;">
        <li class="list-group-item"
            style="padding: 2px 5px; display: flex; align-items: center; justify-content: space-between;"
            *ngFor="let item of picklist"
            [ngClass]="{'invisible': picklistSaving}">
                <div class="checkbox" style="margin: 0; display: inline-block;">
                    <label>
                        <input type="checkbox"
                            [(ngModel)]="item.disabled"
                            (change)="savePicklistItem(item)"
                            tooltip="Disabled" placement="right">
                        <span [ngClass]="{'text-muted': item.disabled}">{{item.name}}</span>
                    </label>
                </div>
                <button class="ace-btn has-icon btn-primary btn-danger" style="margin: 0;" (click)="deletePicklistItem(item)">
                    <i class="fa fa-trash"></i>
                </button>
        </li>
    </ul>
    <input type="text"
        #itemToAdd
        class="form-control picklist-item-to-add"
        [(ngModel)]="itemToAddText"
        *ngIf="addingItem"
        (keyup.enter)="addPicklistItem()">
    <button class="ace-btn btn-sm btn-primary float-end"
        (click)="showPicklistItemInput()"
        [hidden]="picklistLoading || picklistSaving || addingItem">Add</button>
</div>

<div *ngIf="descriptor.fieldTypeID == FieldTypes.Text" style="margin-top: 5px;">
    <label>Max Characters:</label>
    <input type="text" [(ngModel)]="descriptor.validation.maxLength" class="form-control" style="display: inline-block; width: 50px; margin-left: 3px;">
</div>
