import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { DeadlineListPanelComponent } from '../AppealDeadline/Panel/deadlineListPanel.component';
import { AssessmentClassListPanelComponent } from '../Assessment-Class/List/assessmentClassListPanel.component';
import { AssessmentClassModalComponent } from '../Assessment-Class/View/assessmentClassModal.component';
import { ProtocolListPanelComponent } from '../Protocols/protocolListPanel.component';
import { AssessorExceptionsModalComponent } from '../AppealDeadline/Assessor-Exceptions/assessorExceptionsModal.component';
import { DeadlineService } from '../AppealDeadline/deadline.service';
import { AssessmentClassService } from '../Assessment-Class/assessmentClass.service';
import { ProtocolService } from '../Protocols/protocol.service';
import { StateAssessorExceptionsModalComponent } from '../Protocols/stateAssessorExceptionsModal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
    imports: [
        WeissmanSharedModule,
        BsDropdownModule
    ],
    declarations: [
        DeadlineListPanelComponent,
        AssessmentClassListPanelComponent,
        AssessmentClassModalComponent,
        ProtocolListPanelComponent,
        AssessorExceptionsModalComponent,
        StateAssessorExceptionsModalComponent
    ],
    providers: [
        DeadlineService,
        AssessmentClassService,
        ProtocolService
    ],
    exports: [
        DeadlineListPanelComponent,
        AssessmentClassListPanelComponent,
        AssessmentClassModalComponent,
        ProtocolListPanelComponent,
        AssessorExceptionsModalComponent,
        StateAssessorExceptionsModalComponent
    ]
})
export class StateAssessorSharedModule {}
