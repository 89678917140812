import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserInstanceService } from '../../../User/userInstance.service';

@Injectable()
export class InstanceHeaderInterceptor implements HttpInterceptor {
    constructor(private userInstanceService : UserInstanceService) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const method = request.method.toLowerCase();
        const url = request.url.toLowerCase();
        // If making one of these requests, we may be too early in the pipeline to clean the filtered instance
        // list, so skip the instance header entirely (this happens on initial login; we don't have the list
        // of instances until after GetUserInfo is called).
        const skipInstanceHeaderCases = [{ method: 'get', url: 'api/account/getuserinfo' }];

        if (!skipInstanceHeaderCases.some(c => method === c.method && url.indexOf(c.url) >= 0)) {
            //Add Instance selections to header - headers are immutable, hence the clone
            request = request.clone({
                setHeaders: {
                    Instances: this.userInstanceService.getInstancesForHeader()
                }
            });
        }

        return next.handle(request);
    }
}
