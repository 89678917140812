import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TransmittalService } from './transmittal.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import {
    PaymentPackage, PaymentPackageRecipient, PaymentTransmittal, PaymentTransmittalItem,
    RecipientType
} from './transmittal.models';
import { WeissmanDatetimeComponent } from '../../UI-Lib/Weiss-Datepicker/weissman-datetime.component';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { Constants, EntityTypeIds } from '../../constants.new';
import { FinalizePaymentPackageModalComponent } from './Modals/payment-packages-finalize.modal';
import { ToastrService } from 'ngx-toastr';
import { CompanyService } from '../../Entity/Company/company.service';
import {
    ContactListModalComponent,
    ContactListModalParams
} from '../../Contact/Panel/List/Associate-Contact/contactListModal.component';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import * as _ from 'lodash';

@Component({
    selector: 'payment-packages',
    templateUrl: './payment-packages.component.html'
})
export class PaymentPackagesComponent implements OnInit, OnChanges {
    constructor(private transmittalService: TransmittalService,
                public upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
                private constants: Constants,
                private messageBox: MessageBoxService,
                private companyService: CompanyService,
                private toastr: ToastrService,
                private readonly _weissmanModalService: WeissmanModalService,
                private readonly _transmittalService: TransmittalService) {
    }

    @Input() paymentID: number;
    @Input() readOnly: boolean = true;
    @Input() isModal: boolean = false;
    @ViewChild('FinalizePaymentPackageModal', { static: true }) finalizePaymentPackagesModal: FinalizePaymentPackageModalComponent;

    selectedTransmittalID: number = null;
    selectedPackage: PaymentPackage = undefined;
    paymentPackages: PaymentPackage[];
    showSaveButton: boolean = false;
    selectedCount: number = 0;
    draftIDs: number[];
    draftIDParam: string;
    companyID: string;
    startDate: Date;
    endDate: Date;
    state: any;
    loading: boolean = false;
    finalizing: boolean = false;
    hoveredRecipient: PaymentPackageRecipient;
    showDates: boolean = true;
    setFinalizeLoading: (loading: boolean, message: string, hideCancelHyperlink?: boolean) => void;
    finalizeLoadingMessage: string = null;
    processingCancelled: boolean = false;
    hideCancelHyperlink: boolean = false;
    companyName: string;
    paymentBatchId: number;
    taskId: number;
    isPrelimApFeed: boolean;
    isDisabled: boolean = false;
    returnButtonText: string;
    saveButtonText: string ='Finalize and Release Selected Transmittals';

    //This is to get the enum usable in the view for an if condition
    RecipientType = RecipientType;

    ngOnChanges(changes: SimpleChanges) {
        if (Object.keys(changes).indexOf('paymentID') >= 0) {
            this.refreshList();
        }
    }

    async ngOnInit() {
        this.startDate = WeissmanDatetimeComponent.getMidnight(new Date(), false, 'Central');
        this.endDate = WeissmanDatetimeComponent.getMidnight(new Date(), true, 'Central');
        this.state = this.upgradeNavigationServiceHandler.getCurrentState();
        if (this.upgradeNavigationServiceHandler.getQuerystringParam('paymentBatchId')) {
            this.paymentBatchId = JSON.parse(this.upgradeNavigationServiceHandler.getQuerystringParam('paymentBatchId'));
        }
        if (this.upgradeNavigationServiceHandler.getQuerystringParam('taskId')) {
            this.taskId = JSON.parse(this.upgradeNavigationServiceHandler.getQuerystringParam('taskId'));
        }
        this.isPrelimApFeed = !!this.upgradeNavigationServiceHandler.getQuerystringParam('isPrelimApFeed');

        if (this.isFromPaymentBatch) {
            this.returnButtonText= 'Return to Payment Batch';
            if(this.isPrelimApFeed) {
                this.saveButtonText = 'Release all Preliminary Transmittals';
            }
        }
        else{
            this.returnButtonText= 'Return to Action View';
        }

        this.setFinalizeLoading = (loading: boolean, message: string, hideCancelHyperlink?: boolean) => {
            this.finalizeLoadingMessage = message;
            this.finalizing = loading;
            this.hideCancelHyperlink = hideCancelHyperlink;

            if (!loading) {
                this.processingCancelled = false;
            }
        };

        if (this.state.name === 'paymentPackagesDraft') {
            this.readOnly = false;
            await this.getPaymentPackagesByDraftID();
        } else if (this.state.name === 'paymentPackagesForCompany') {
            this.readOnly = true;
            this.getPaymentPackagesByCompanyID();
        } else if (this.state.name === 'paymentPackageDetail') {
            this.readOnly = true;
            this.showDates = false;
            this.companyID = this.upgradeNavigationServiceHandler.getQuerystringParam('companyID');
            const paymentPackageID = Number(this.upgradeNavigationServiceHandler.getQuerystringParam('paymentPackageID'));
            this.getPaymentPackageDetails(paymentPackageID);
        } else {
            if (this.paymentID) {
                this.getPaymentPackagesByPaymentID();
            }
        }

    }

    get isFromPaymentBatch() {
        return !!this.paymentBatchId;
    }

    closeChildModals(): void {
        this.finalizePaymentPackagesModal.close();
    }

    async getPaymentPackageDetails(paymentPackageID: number): Promise<void> {
        this.loading = true;

        try {
            const result = await this.transmittalService.GetPaymentPackageByID(paymentPackageID);
            this.paymentPackages = [result];
            this.preparePaymentPackages();

            if (result.paymentTransmittals.length > 0) {
                this.showDetails(result.paymentTransmittals[0], result);
            }
        } finally {
            this.loading = false;
        }
    }

    async getPaymentPackagesByCompanyID(): Promise<void> {
        this.companyID = this.upgradeNavigationServiceHandler.getQuerystringParam('companyID');
        if (this.companyID) {
            this.loading = true;

            try {
                this.paymentPackages = await this.transmittalService.GetPaymentPackagesByCompanyID(this.companyID, this.startDate, this.endDate);
                this.preparePaymentPackages();

                this.companyName = await this.companyService.getCompanyName(Number(this.companyID));
            } finally {
                this.loading = false;
            }
        }
    }

    async getPaymentPackagesByPaymentID(): Promise<void> {
        if (this.paymentID) {
            this.loading = true;

            try {
                this.paymentPackages = await this.transmittalService.GetPaymentPackagesByPaymentID(this.paymentID);
                this.preparePaymentPackages();
                let newTransmittalID: number;
                let selectedPackage: PaymentPackage;
                //1. search paymentTransmittals
                //1a. Find paymentID inside of paymentTransmittalItems
                //2. Set selectedTransmittalID to that transmittalID

                _.forEach(this.paymentPackages, (packageItem: PaymentPackage) => {
                    _.forEach(packageItem.paymentTransmittals, (paymentTransmittal: PaymentTransmittal) => {
                        _.forEach(paymentTransmittal.paymentTransmittalItems, (transmittalItem: PaymentTransmittalItem) => {
                            if (transmittalItem.paymentID === this.paymentID) {
                                newTransmittalID = paymentTransmittal.paymentTransmittalID;
                                selectedPackage = packageItem;

                            }
                        });
                    });
                });

                if (newTransmittalID && selectedPackage) {
                    this.selectedTransmittalID = newTransmittalID;
                    this.selectedPackage = selectedPackage;
                }
            } finally {
                this.loading = false;
            }
        }
    }

    async getPaymentPackagesByDraftID(): Promise<void> {
        // TODO: Re-arrange logic so this doesn't have to be duplicated between the finalize modal and the main component
        const successMessage = 'Payment package(s) finalized.';
        const warningMessaage = 'One or more payment packages could not be processed. Please try again.';

        this.draftIDParam = this.upgradeNavigationServiceHandler.getQuerystringParam('draftID');
        if (this.draftIDParam) {
            const serializedDraftIDs: string = sessionStorage[`PaymentPackageDrafts${this.draftIDParam}`];
            if (serializedDraftIDs) {
                this.draftIDs = JSON.parse(serializedDraftIDs);
                const packages = await this.transmittalService.GetPaymentPackages(this.draftIDs);

                this.paymentPackages = packages;
                this.preparePaymentPackages();
                this.loading = false;
                if(this.isFromPaymentBatch) {
                    this.toggleSelectAll();
                    this.isDisabled = true;
                }

                // Some payment packages might have a long-running process still going, which affects how the page loads. First find packages
                // that ever had an LR request.
                const packagesWithRequests = packages.filter(p => p.paymentPackageFinalizeRequestId);

                // If there are any, check with the API if the LR processes are still active.
                if (packagesWithRequests.length > 0) {
                    const packageIdsWithRequests = packagesWithRequests.map(p => p.paymentPackageID);
                    const checkResult = await this.transmittalService.CheckFinalizeLongRunningProcess(packageIdsWithRequests);

                    if (checkResult.length > 0) {
                        this.loading = true;

                        const alreadyProcessedLrIds = [];

                        for (let i = 0; i < checkResult.length; i++) {
                            const { longRunningProcessId } = checkResult[i];
                            if (alreadyProcessedLrIds.indexOf(longRunningProcessId) < 0) {
                                alreadyProcessedLrIds.push(longRunningProcessId);
                                const packageIds = checkResult
                                    .filter(r => r.longRunningProcessId == longRunningProcessId)
                                    .map(r => r.paymentPackageId);

                                for (let paymentPackageIndex = 0; paymentPackageIndex < this.paymentPackages.length; paymentPackageIndex++) {
                                    const packageItem = this.paymentPackages[paymentPackageIndex];
                                    packageItem.isSelected = packageIds.indexOf(packageItem.paymentPackageID) >= 0;
                                }

                                // HACK: As noted in the modal code, the responsibility for showing loading indicators has moved several times, so
                                // we've got a few layers of fix here. Ideally we should untangle this responsibility and put all loading logic
                                // in PaymentPackagesComponent and make FinalizePaymentPackagesModal a simple modal to retrieve user input. Until
                                // that's fixed, set up the modal so that subscribeLR will work.
                                this.finalizePaymentPackagesModal.setupModal(false);

                                const plural = packageIds.length > 1;
                                await new Promise<void>(r => this.finalizePaymentPackagesModal.subscribeLR(longRunningProcessId, plural, r));
                            }
                        }

                        this.loading = false;
                        this.setFinalizeLoading(false, null);

                        if(this.finalizePaymentPackagesModal.finalizeHasErrors) {
                            this.toastr.warning(warningMessaage);
                        } else {
                            this.toastr.success(successMessage);
                        }

                        this.packageFinalized();
                    }
                }
            } else {
                // TODO: Handle this case somehow (we got a draft ID string, but sessionStorage didn't have it)
            }
        }

    }

    preparePaymentPackages(): void {
        this.paymentPackages = _.chain(this.paymentPackages)
            .map((packageItem: PaymentPackage) => {
                packageItem.transmittalType = this.constants.TransmittalOutputTypeAbbr[packageItem.transmittalOutputType];
                packageItem.isSelected = false;

                return packageItem;
            })
            .sortBy((packageItem: PaymentPackage) => {
                return _.parseInt(packageItem.packageNumber);
            })
            .value();
    }

    async openContactModal(event: any, packageItem: PaymentPackage): Promise<void> {
        const params = {
            recipientMode: true,
            entityInfo: {
                id: packageItem.paymentPackageID,
                type: EntityTypeIds.PAYMENTPACKAGE
            }
        } as ContactListModalParams;

        const recipientObject = await this._weissmanModalService.showAsync(ContactListModalComponent, params, 'modal-lg');

        if (recipientObject) {
            _.forEach(recipientObject.recipients, (recipient: any) => {
                if (!recipient.email) {
                    this.toastr.error(`Contact ${recipient.firstName} ${recipient.lastName} is missing email address!`, 'Missing email address');
                    return;
                }

                let companyName: string = '';
                if (recipient.companyName) {
                    companyName = recipientObject.companyName;
                } else if (recipient.entityName) {
                    companyName = recipient.entityName;
                }

                const tempRecipient: PaymentPackageRecipient = new PaymentPackageRecipient(recipientObject.recipientType,
                    recipient.contactID,
                    `${recipient.firstName} ${recipient.lastName}`,
                    recipient.title,
                    recipient.address,
                    recipient.email,
                    companyName);

                packageItem.paymentPackageRecipients.push(tempRecipient);
            });
        }
    }

    openReviewModal(event: any, packageItem: PaymentPackage): void {
        this._transmittalService.showPaymentPackageReviewModal(packageItem);
    }

    selectRow(event: any, packageItem: PaymentPackage): void {
        if (!this.isFromPaymentBatch && !packageItem.completedDateTime && !/no-bubble/.test(event.target.className)) {
            packageItem.isSelected = !packageItem.isSelected;
            this.selectedCount = _.filter(this.paymentPackages, item => item.isSelected).length;

            this.showSaveButton = this.selectedCount !== 0;
        }
    }

    removeRecipient(event: any, recipient: PaymentPackageRecipient, packageItem: PaymentPackage): void {
        packageItem.paymentPackageRecipients = _.reject(packageItem.paymentPackageRecipients, (recipientItem: PaymentPackageRecipient) => {
            return recipientItem.contactID === recipient.contactID;
        });
    }

    removeTransmittal(event: any, packageItem: PaymentPackage, transmittalIndex: number): void {
        if (packageItem.paymentTransmittals.length === 1) {
            this.paymentPackages = _.reject(this.paymentPackages, (item: PaymentPackage) => {
                return item.paymentPackageID === packageItem.paymentPackageID;
            });
        } else {
            packageItem.paymentTransmittals.splice(transmittalIndex, 1);
        }
    }

    getRecipientCount(recipientList: PaymentPackageRecipient[]): number {
        return _.filter(recipientList, recipient =>
            recipient.recipientType === RecipientType.Bcc || recipient.recipientType === RecipientType.Cc
        ).length;
    }

    getCompletePackageCount(): number {
        let count: number = 0;

        count = _.filter(this.paymentPackages, packageItem => !!packageItem.completedDateTime).length;

        return count;
    }

    setHoveredRecipient(recipient: PaymentPackageRecipient): void {
        this.hoveredRecipient = recipient;
    }

    navigateToActionView(): void {
        sessionStorage.removeItem(`PaymentPackageDrafts${this.draftIDParam}`);
        if (this.isFromPaymentBatch) {
            this.upgradeNavigationServiceHandler.transitionTo('paymentBatchDetails', {paymentBatchId: this.paymentBatchId});}
        else{
        this.upgradeNavigationServiceHandler.transitionTo('actionview', {});
        }
    }

    cancelPackages(): void {
        if (this.draftIDs.length != 0) {
            this.messageBox.confirm('Are you sure you want to cancel?').then(() => {
                this.transmittalService.CancelPaymentPackages(this.draftIDs).then(() => {
                    this.navigateToActionView();
                });
            });
        } else {
            this.navigateToActionView();
        }
    }

    returnToActionView(): void {
       //this.upgradeNavigationServiceHandler.transitionTo('actionview', {});
        if (this.isFromPaymentBatch) {
            this.upgradeNavigationServiceHandler.transitionTo('paymentBatchDetails', {paymentBatchId: this.paymentBatchId});}
        else{
            this.upgradeNavigationServiceHandler.transitionTo('actionview', {});
        }
      }

    finalizePackages(): void {
        this.finalizePaymentPackagesModal.show();
    }

    goToDetails(event: any, selectedTransmittal: PaymentTransmittal, selectedPackage: PaymentPackage): void {
        this.showDetails(selectedTransmittal, selectedPackage);
    }

    showDetails(selectedTransmittal: PaymentTransmittal, selectedPackage: PaymentPackage): void {
        this.selectedTransmittalID = selectedTransmittal.paymentTransmittalID;
        this.selectedPackage = selectedPackage;
    }

    refreshList(): void {
        this.selectedPackage = null;
        this.selectedTransmittalID = null;

        if (this.state && this.state.name === 'paymentPackagesForCompany') {
            this.getPaymentPackagesByCompanyID();
        } else if (this.paymentID) {
            this.getPaymentPackagesByPaymentID();
        }
    }

    toggleSelectAll(): void {
        const updateValue = this.selectedCount !== this.paymentPackages.length;

        this.paymentPackages = _.map(this.paymentPackages, (paymentItem: PaymentPackage) => {
            paymentItem.isSelected = updateValue && !paymentItem.completedDateTime;
            return paymentItem;
        });

        this.selectedCount = _.filter(this.paymentPackages, item => item.isSelected).length;
        this.showSaveButton = this.selectedCount !== 0;
    }

    recallPackage(packageItem: PaymentPackage): void {
        this.messageBox.confirm(`Are you sure you want to recall package #${packageItem.packageNumber}?`).then(() => {
            this.loading = true;
            this.transmittalService.RecallPaymentPackage(packageItem).then(() => {
                // Remove the recalled package from the list, set it's "isRecalled" flag, and add it back to the end
                const removedPackages: PaymentPackage[] = _.remove(this.paymentPackages, {
                    paymentPackageID: packageItem.paymentPackageID
                });

                removedPackages.forEach((paymentPackage) => {
                    paymentPackage.isRecalled = true;
                    this.paymentPackages.push(paymentPackage);
                });

                this.paymentPackages = _.sortBy(this.paymentPackages, (x: PaymentPackage) => {
                    return _.parseInt(x.packageNumber);
                });

                this.loading = false;
            }).catch((result) => {
                if (result.status === 400) {
                    this.toastr.error(result.data);
                }
                this.loading = false;
            });
        });
    }

    packageFinalized() {
        this.selectedCount = _.filter(this.paymentPackages, item => item.isSelected).length;
        this.showSaveButton = this.selectedCount !== 0;

        const finalizedPackages: PaymentPackage[] = _.filter(this.paymentPackages, { isFinalized: true });

        _.forEach(finalizedPackages, (item: PaymentPackage) => {
            _.remove(this.draftIDs, (draftId: number) => {
                return draftId === item.paymentPackageID;
            });
        });
    }

    unlockPackage(event: any, packageItem: PaymentPackage) {
        this.messageBox.confirm('Unlocking this package will undo your changes to the package email. Are you sure?').then(() => {
            packageItem.emailSubjectTemplate = packageItem.originalEmailSubjectTemplate;
            packageItem.emailTemplate = packageItem.originalEmailTextTemplate;
            packageItem.lockedForOverrideEmail = false;
        });
    }

    navigateToCompany(): void {
        this.upgradeNavigationServiceHandler.go('company', { companyId: this.companyID });
    }

    backClicked() {
        if(this.isFromPaymentBatch) {
            this.upgradeNavigationServiceHandler.transitionTo('paymentBatchDetails', {paymentBatchId: this.paymentBatchId});
        } else {
            this.selectedTransmittalID = null;
        }
    }
}
