import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { QuillModules } from 'ngx-quill';
import { ContentChange } from 'ngx-quill/lib/quill-editor.component';
import { ToastrService } from 'ngx-toastr';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import {
    PaymentPackage, RecipientType, InvoiceAttachmentInfo,
    AttachmentType, InvoicePreviewData
} from '../transmittal.models';
import { TransmittalService } from '../transmittal.service';
import { Attachment, AttachmentInfo } from '../../../Attachment/attachment.model';
import { CustomeEmailTemplateService } from '../../../Common/Custom-Email-Template/custom-email-template.service';
import * as _ from 'lodash';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { firstValueFrom } from 'rxjs';

declare const $: any;

export interface TemplateField {
    name: string;
    validInSubject: boolean;
    isLateBound: boolean;
}

export interface TemplateFields {
    subject: TemplateField[];
    body: TemplateField[];
}

@Component({
    selector: 'review-payment-package-modal',
    templateUrl: './payment-packages-review.modal.html'
})
export class ReviewPaymentPackageModalComponent implements OnInit, IWeissmanModalComponent<PaymentPackage, void> {
    constructor(private readonly _attachmentService: AttachmentService,
                private readonly _transmittalService: TransmittalService,
                private readonly _customEmailTemplateService: CustomeEmailTemplateService,
                private readonly _toastr: ToastrService,
                private readonly _bsModalRef: BsModalRef,
                private readonly _messageBoxService: MessageBoxService) {
    }

    params: PaymentPackage;
    result: void;

    package: PaymentPackage;
    loading: boolean = true;

    attachment: Attachment;
    attachmentUrl: string;
    availableAttachments: InvoiceAttachmentInfo[];
    selectedAttachment: InvoiceAttachmentInfo;
    previewMode: boolean;
    emailSubjectTemplate: string;
    backupSubjectTemplate: string;
    emailSubject: string;
    emailTextTemplate: string;
    backupTextTemplate: string;
    emailText: string;
    editingEmail: boolean;
    emailTemplateFields: TemplateFields;
    editorInstance: QuillModules;
    attachmentSizeWarn: boolean;
    downloading: boolean;

    RecipientType = RecipientType;


    async ngOnInit(): Promise<void> {
        this.downloading = false;

        this.package = this.params;
        this.availableAttachments = [];
        this.previewMode = !this.package.completedByUserID;
        this.emailText = this.package.emailText;
        this.emailSubject = this.package.emailSubject;
        this.editingEmail = false;

        if (!this.previewMode) {
            if (this.package.attachments.length > 0) {
                _.forEach(this.package.attachments, attachment => {
                    this.availableAttachments.push({
                        fileExtension: attachment.fileExtension,
                        fileName: attachment.fileName,
                        Attachment: attachment,
                        attachmentType: AttachmentType.APFeed,
                        id: attachment.attachmentID
                    });
                });
            }
        }

        await this.getReviewData();

        if (this.package.apFeedAttachments.length > 0) {
            _.forEach(this.package.apFeedAttachments, attachment => {
                this.availableAttachments.push({
                    fileExtension: attachment.fileExtension,
                    fileName: attachment.fileName,
                    Attachment: attachment,
                    attachmentType: AttachmentType.APFeed
                });
            });
        }
    }

    async close(): Promise<void> {
        const subjectHasChanged = this.backupSubjectTemplate && JSON.stringify(this.backupSubjectTemplate) !== JSON.stringify(this.emailSubjectTemplate);
        const emailHasChanged = this.backupTextTemplate && JSON.stringify(this.backupTextTemplate) !== JSON.stringify(this.emailText);

        if (subjectHasChanged || emailHasChanged) {
            const response = await this._messageBoxService.open({
                message: 'Changes will be lost. Are you sure you would like to close before downloading or sending?',
                buttons: MessageBoxButtons.YesNo
            });
            if (response === MessageBoxResult.Yes) {
                this._bsModalRef.hide();
            }
        } else {
            this._bsModalRef.hide();
        }
    }

    blobCallback(result: ArrayBuffer): void {
        const attachmentBlob = new Blob([result], {
            type: 'application/pdf'
        });
        this.attachmentUrl = URL.createObjectURL(attachmentBlob);
    }

    async previewAnyway(): Promise<void> {
        this.attachmentSizeWarn = false;
        await this.loadAttachment(true);
    }

    async getReviewData(): Promise<void> {
        this.attachmentSizeWarn = false;
        if (this.previewMode) {
            this.loading = true;
            try {
                const allResult = await Promise.all([this._transmittalService.GetPreviewData(this.package), this._customEmailTemplateService.getEmailTemplateFields()]);
                const previewData: InvoicePreviewData = allResult[0];
                // TODO: Make a model for this
                const templateFieldResult = allResult[1];
                this.emailTemplateFields = {
                    subject: _.filter(templateFieldResult, r => r.isLateBound && r.validInSubject),
                    body: _.filter(templateFieldResult, r => r.isLateBound)
                };

                this.emailSubjectTemplate = previewData.emailSubjectTemplate;
                this.emailSubject = previewData.emailSubject;
                this.emailTextTemplate = previewData.emailTextTemplate;
                this.emailText = previewData.emailText;
                this.availableAttachments = previewData.attachments;
                this.selectedAttachment = this.availableAttachments[0];
                const template = await this._transmittalService.GetPreviewAttachment(this.package, this.selectedAttachment);
                this.blobCallback(template.data);
            } finally {
                this.loading = false;
            }
        } else {
            this.selectedAttachment = this.availableAttachments[0];
            await this.loadAttachment(false);
        }
    }

    async downloadAttachment(): Promise<void> {
        this.downloading = true;

        try {
            if (this.previewMode) {
                let result;
                try {
                    result = await this._transmittalService.GetPreviewAttachment(this.package, this.selectedAttachment);
                } catch (err) {
                    // HACK: We specifically asked for an ArrayBuffer in the result, but if there's an error we need a string. Meaningful
                    // messages are possible from this endpoint, so catch errors and convert them to strings.
                    // This is an obscure circumstance so a hack probably isn't a huge deal here, but ideally this'd be handled in our general
                    // http code instead of right here in the logic.
                    if (err.status === 400) {
                        const errResult: {
                            message: string
                        } = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(err.data)));
                        this._toastr.error(errResult.message);
                        return;
                    } else {
                        throw err;
                    }
                }
                this._attachmentService.performDownload(result.data, this.selectedAttachment.fileName, this.selectedAttachment.fileExtension);
            } else {
                const attachment = this.selectedAttachment.Attachment;
                const attachmentInfo = new AttachmentInfo(attachment.entityTypeID,
                    attachment.attachmentID,
                    attachment.entityID,
                    attachment.fileName,
                    attachment.fileExtension,
                    attachment.size);
                await this._attachmentService.downloadAttachmentFile(attachmentInfo, false);
            }
        } finally {
            this.downloading = false;
        }
    }

    async onAttachmentChange(attachmentInfo: InvoiceAttachmentInfo): Promise<void> {
        this.attachmentSizeWarn = false;
        this.selectedAttachment = attachmentInfo;

        if (this.previewMode) {
            if (attachmentInfo.fileExtension === 'pdf') {
                this.loading = true;

                try {
                    const result = await this._transmittalService.GetPreviewAttachment(this.package, this.selectedAttachment);
                    this.blobCallback(result.data);
                } finally {
                    this.loading = false;
                }
            }
        } else {
            await this.loadAttachment(false);
        }
    }

    beginEditEmail(): void {
        this.editingEmail = true;
        this.backupSubjectTemplate = this.emailSubjectTemplate;
        this.backupTextTemplate = this.emailTextTemplate;
    }

    cancelEditEmail(): void {
        this.emailSubjectTemplate = this.backupSubjectTemplate;
        this.emailTextTemplate = this.backupTextTemplate;
        this.editingEmail = false;
    }

    async saveEditEmail(): Promise<void> {
        if (!this.package.originalEmailSubjectTemplate) {
            this.package.originalEmailSubjectTemplate = this.package.emailSubjectTemplate;
        }
        if (!this.package.originalEmailTextTemplate) {
            this.package.originalEmailTextTemplate = this.package.emailTemplate;
        }
        this.package.emailSubjectTemplate = this.emailSubjectTemplate;
        this.package.emailTemplate = this.emailTextTemplate;

        await this.getReviewData();
        this.editingEmail = false;
        this.package.lockedForOverrideEmail = true;
    }

    addBindingCreated(quillInstance: QuillModules): void {
        this.editorInstance = quillInstance;
    }

    insertFieldInBody(field: TemplateField): void {
        const cursorPosition: number = this.editorInstance.getSelection(true).index;
        const fieldName: string = `{{ ${field.name} }}`;

        this.editorInstance.insertText(cursorPosition, fieldName);
    }

    // Inserting field does not trigger binding change event, so we need to manually update the model
    contentChange(change: ContentChange): void {
        this.emailTextTemplate = change.html;
    }

    // http://jsfiddle.net/4abr7jc5/2/
    insertFieldInSubject(field: TemplateField): void {
        const templateSubject = $('#template-subject');
        const cursorPosStart = templateSubject.prop('selectionStart');
        const cursorPosEnd = templateSubject.prop('selectionEnd');
        const v = templateSubject.val();
        const textBefore = v.substring(0, cursorPosStart);
        const textAfter = v.substring(cursorPosEnd, v.length);
        const fieldName = `{{ ${field.name} }}`;

        templateSubject.val(textBefore + fieldName + textAfter);
        this.emailSubjectTemplate = templateSubject.val();

        templateSubject.focus();
    }

    private async loadAttachment(skipSizeWarning: boolean): Promise<void> {
        const attachment = this.selectedAttachment.Attachment;
        if (attachment.fileExtension !== 'pdf') {
            this.attachmentUrl = null;
            this.loading = false;
            return Promise.resolve();
        }
            // Don't show the preview if the attachment is over 5 MB (maybe this value should be
        // configurable somehow?)
        else if (!skipSizeWarning && attachment.size > 5242880) {
            this.attachmentUrl = null;
            this.attachmentSizeWarn = true;
            this.loading = false;
            return Promise.resolve();
        } else {
            this.loading = true;

            try {
                const attachmentInfo: AttachmentInfo = new AttachmentInfo(attachment.entityTypeID,
                    attachment.attachmentID,
                    attachment.entityID,
                    attachment.fileName,
                    attachment.fileExtension,
                    attachment.size);

                const result = await firstValueFrom(this._attachmentService.getSingleAttachmentBody(attachmentInfo));
                this.blobCallback(result.body);
            } finally {
                this.loading = false;
            }
        }
    }
}
