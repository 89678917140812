export class StateTransitionOptions {
    reload: boolean;
}

export abstract class UpgradeNavigationServiceHandler {
    abstract transitionTo(newState: string, params: any, options?: StateTransitionOptions): void;
    abstract go(stateName: string, params: any, closeModal?: boolean): void;
    abstract goWithReload(stateName: string, params: any): void;
    abstract getCurrentState(): any;
    abstract getQuerystringParam(parameterName: string): string;
    abstract goToAnnualDetailsAssessment(companyID: number, siteID: number, parcelID: number, annualYearID: number, assessmentID: number) : void;
    abstract goToAnnualDetailsAppeal(companyID: number, siteID: number, parcelID: number, annualYearID: number, assessmentID: number, appealID: number) : void;
    abstract goToAnnualDetailsTax(companyID: number, siteID: number, parcelID: number, annualYearID: number, billClusterID: number) : void;
    abstract goToAnnualDetailsRefund(companyID: number, siteID: number, parcelID: number, annualYearID: number, refundID: number) : void;
    abstract goToAnnualDetailsFiling(companyID: number, siteID: number, parcelID: number, annualYearID: number, filingId: number) : void;
    abstract goToFilingBatch(filingBatchId: number): void;
    abstract getHrefFromState(stateName: string, options: any): string;
}
