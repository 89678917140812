import { Address } from '../Common/Models/common.model';

export class Agent {
    constructor() {
        this.address = new Address();
    }

    agentID: number;
    name: string;
    company: string;
    addressID: number;
    address: Address;
    hover: boolean;
}
