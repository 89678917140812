import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { NavigationState } from '../annual-details-navigation.service';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AnnualAssessmentDetail, Assessment } from '../Assessments/assessment.model';
import { AssessmentService } from '../Assessments/assessment.service';

import * as _ from 'lodash';

export interface IAddRevisionConfig {
    currentAssessment: Assessment;
    currentYear: AnnualDetailYear;
    navigationState: NavigationState;
}

@Component({
    selector: 'add-revision-modal',
    templateUrl: './add-revision.component.html'
})
export class AddRevisionModalComponent implements OnDestroy {
    constructor(
        private http: SDHttpService,
        private assessmentService: AssessmentService,
        public modalRef: BsModalRef
    ) { }

    assessmentCreatedEventEmitter: EventEmitter<Assessment> = new EventEmitter();
    config: IAddRevisionConfig;
    isBusy: boolean = false;
    newAssessment: Assessment;
    other: string;

    ngOnDestroy(): void {
        this.assessmentCreatedEventEmitter.complete();
    }

    initialize(config): void {
        this.config = config;

        const revisionNumber =  _.maxBy(this.config.currentYear.annualGridDetails, 'revisionNum').revisionNum + 1;

        // Bug fix - if you use annual year component to navigate, current year isn't set yet, so we need to grab
        // it from the navigation state view model
        if(!this.config.currentAssessment) {
            this.config.currentAssessment = this.config.navigationState.currentAssessmentVM.model;
        }

        const newAnnualAssessmentDetails = this.config.currentAssessment.annualAssessmentDetails.map((comp) => {
            return _.omit(comp, 'annualAssessmentID', 'annualAssessmentDetailID') as AnnualAssessmentDetail;
        });
        this.newAssessment = new Assessment(this.config.currentYear.annualYearID, revisionNumber, 0, 'add', newAnnualAssessmentDetails);

        //This is to set up the radio button properly since the 3rd item is "misc".
        if(this.newAssessment.revisionDesc !== 'Final' && this.newAssessment.revisionDesc !== 'Original' && this.newAssessment.revisionDesc !== 'Forecast') {
            this.other = this.newAssessment.revisionDesc;
            this.newAssessment.revisionDesc = 'other';
        }
    }

    checkForEnter(e: any): void {
        if (e.which === 13) {
            this.save();
        }
    }

    disableSave(): boolean {
        let userInput: string = this.other;
        if(userInput) {
            userInput = userInput.trim();
        }
        return (this.newAssessment.revisionDesc === 'other' && !userInput) || this.isBusy;
    }

    async save(): Promise<void> {
        if (this.newAssessment.revisionDesc === 'other') {
            if (this.other) {
                this.newAssessment.revisionDesc = this.other;
            } else {
                return;
            }
        }

        this.isBusy = true;

        try {
            const assessment =  this.assessmentService.prepareAnnualAssessmentForCreate(this.newAssessment);
            const response = await this.http.post('/api/annualassessment/', assessment);

            this.assessmentCreatedEventEmitter.emit(response);
            this.modalRef.hide();
        } finally {
            this.isBusy = false;
        }
    }
}
