<!-- When downloads are streamed, errors often aren't reported on the UI or console. This page is a way to troubleshoot downloads, notably
 letting you perform a streamed download that will be logged in the dev tools network tab. -->

<div class="container">
	<div class="single-column-layout">
        <h1>ATTACHMENT DOWNLOAD TOOL</h1>
        <form>
            <div *ngIf="!downloading">
                <label for="attachmentId">Attachment Id:</label>
                <input type="text" id="attachmentId" name="attachmentId" [(ngModel)]="attachmentId" />
            </div>
            <div *ngIf="!downloading">
                <button type="button" class="ace-btn btn-sm btn-primary btn-action" (click)="getInfo()">Get Info</button>
                <button type="button" class="ace-btn btn-sm btn-primary btn-action" (click)="bufferedDownload()">Download (Buffered)</button>
                <button type="button" class="ace-btn btn-sm btn-primary btn-action" (click)="streamedDownload()">Download (Streamed)</button>
                <button type="button" class="ace-btn btn-sm btn-secondary btn-action" (click)="simulateDownload()">Simulate Streamed Download</button>
            </div>
            <div *ngIf="byteCount">
                <span *ngIf="downloading" class="m-1">Downloading</span><span *ngIf="!downloading" class="m-1">Downloaded</span>
                <span class="monospace">{{ currentByte / 1048576 | number : '1.0-0' }}</span>
                of
                <span class="monospace">{{ byteCount / 1048576 | number : '1.0-0'}}</span>
                MB
                <button *ngIf="downloading" type="button" class="ace-btn btn-sm btn-secondary btn-action" (click)="cancel()">Cancel</button>
            </div>
            <h2 *ngIf="attachmentInfo">Attachment Info</h2>
            <pre *ngIf="attachmentInfo">{{attachmentInfo}}</pre>
        </form>
    </div>
</div>
