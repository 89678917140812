import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AttachmentModalData } from '../../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../../Comments/comments.service';

export interface ICellRendererParamsForAllocationListGridAction extends ICellRendererParams {
    canEdit: boolean;
    viewDetails: (params: ICellRendererParamsForAllocationListGridAction) => void;
    delete: (params: ICellRendererParamsForAllocationListGridAction) => void;
    canDelete: (params: ICellRendererParamsForAllocationListGridAction) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button type="button"
            *ngIf="allocation"
            helpTooltip
            helpContentId="app.view"
            position="bottom"
            class="ace-btn btn-primary has-icon grid-action-button"
            (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>
        <span class="icon-indicator" *ngIf="allocation">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="allocation && allocation.hasComments">
            <i class="fa fa-commenting"></i>
        </span>
        <span class="icon-indicator" *ngIf="allocation && !allocation.hasComments">
            <i class="fa fa-fw"></i>
        </span>
        <span class="icon-indicator" *ngIf="allocation && allocation.hasAttachments">
            <i class="fa fa-files-o"></i>
        </span>
        <entity-comments *ngIf="allocation"
            [hasComments]="allocation && allocation.hasComments"
            buttonClass="ace-btn btn-primary has-icon grid-action-button"
            [modalData]="commentModalData"
            helpTooltip
            helpContentId="app.comments"
            position="bottom">
        </entity-comments>
        <entity-attachments *ngIf="allocation" [hasAttachments]="allocation && allocation.hasAttachments"
            buttonClass="ace-btn btn-primary has-icon grid-action-button"
            [modalData]="attachmentModalData"
            helpTooltip
            helpContentId="app.attachments"
            position="bottom">
        </entity-attachments>
        <button type="button"
            *ngIf="allocation && params.canDelete(params)"
            class="ace-btn has-icon btn-primary btn-danger grid-action-button"
            (click)="onDeleteClicked()"
            helpTooltip
            helpContentId="app.delete"
            position="bottom">
            <i class="fa fa-trash"></i>
        </button>
`
})
export class AllocationListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForAllocationListGridAction;
    allocation: Compliance.AllocationModel;
    attachmentModalData: AttachmentModalData;
    commentModalData: CommentModalData;

    agInit(params: ICellRendererParamsForAllocationListGridAction): void {
        this.params = params;
        this.allocation = params.data as Compliance.AllocationModel;

        if (!this.allocation) {
            return;
        }

        this.attachmentModalData = {
            entityType: 'Allocation', //todo: make this a constant generated typescript value
            entityName: this.allocation.description,
            year: this.allocation.taxYear,
            disableYears: true,
            singleYear: true,
            entityData: {
                typeId: Core.EntityTypes.Allocation,
                id: this.allocation.allocationId,
                name: this.allocation.description
            },
            readOnly: !this.params.canEdit
        } as AttachmentModalData;

        this.commentModalData = {
            entityID: this.allocation.allocationId,
            entityTypeID: Core.EntityTypes.Allocation,
            entityName: this.allocation.description,
            parcelAcctNum: null,
            canEdit: this.params.canEdit,
            description: null,
            parcelID: null,
            year: this.allocation.taxYear.toString()
        } as CommentModalData;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this.params.viewDetails(this.params);
    }

    onDeleteClicked(): void {
        this.params.delete(this.params);
    }
}
