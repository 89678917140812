import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {Invoice, InvoiceAppeal, OverrideStatus} from './invoice.model';
import {InvoiceService} from './invoice.service';
import {UpgradeNavigationServiceHandler} from '../../Common/Routing/upgrade-navigation-handler.service';
import {DecimalPipe} from '@angular/common';
import {Constants} from '../../constants.new';

declare const _: any;

@Component({
    selector: 'invoice-details',
    templateUrl: './invoice-details.component.html'
})
export class InvoiceDetailsComponent implements OnChanges {
    constructor(private invoiceService: InvoiceService,
                private navigationService: UpgradeNavigationServiceHandler,
                private numberPipe: DecimalPipe,
                public constants: Constants) {}

    @Input() currentInvoice: Invoice;
    @Input() cachedInvoice: Invoice;
    @Input() editMode: boolean;
    @Input() loading: boolean;

    CalculatedFields = CalculatedFields;
    Constant = Constants;

    calculatedFieldChanged(fieldChanged: number): void {
        switch(fieldChanged) {
            case CalculatedFields.PreviousFMV:
                if(!this.currentInvoice.previousFMV) {
                    this.revertPreviousFMV(false);
                }

                break;
            case CalculatedFields.InvoiceFMV:
                if(!this.currentInvoice.invoiceFMV) {
                    this.revertInvoiceFMV(false);
                } else if(this.currentInvoice.feeAmountStatus === OverrideStatus.Default) {
                    this.currentInvoice.feeAmountStatus = OverrideStatus.Recalculated;
                }

                break;
            case CalculatedFields.FeeAmount:
                if(!this.currentInvoice.feeAmount && this.currentInvoice.feeAmount !== 0) {
                    this.currentInvoice.feeAmount = this.currentInvoice.calculatedFeeAmount;
                }

                if(this.currentInvoice.feeAmount === this.currentInvoice.calculatedFeeAmount) {
                    this.currentInvoice.feeAmountStatus = OverrideStatus.Default;
                } else {
                    this.currentInvoice.feeAmountStatus = OverrideStatus.Overridden;
                }


                break;
            case CalculatedFields.Savings:

                //if savings is removed, set overridden to false and revert value
                if(!this.currentInvoice.savings) {
                    this.currentInvoice.isSavingsOverridden = false;
                    this.currentInvoice.savingsStatus = OverrideStatus.Default;
                    this.currentInvoice.savings = this.currentInvoice.calculatedSavings;
                    if (this.currentInvoice.taxRateStatus === OverrideStatus.Recalculated ||
                            this.currentInvoice.taxRateStatus === OverrideStatus.Default) {
                        if (this.currentInvoice.fmvChangeStatus === OverrideStatus.Recalculated) {
                            this.currentInvoice.taxRate = this.invoiceService.calculateInitialTaxRate(this.currentInvoice);
                        }
                        else {
                            this.currentInvoice.taxRate =
                                parseFloat(this.numberPipe.transform(this.currentInvoice.calculatedTaxRate, '1.8-8'));
                        }
                    }
                } else if (this.currentInvoice.savings !== this.currentInvoice.calculatedSavings) {
                    //else we're overridden
                    this.currentInvoice.isSavingsOverridden = true;
                    this.currentInvoice.savingsStatus = OverrideStatus.Overridden;

                    if(this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {
                        this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
                        this.currentInvoice.feeAmountStatus = OverrideStatus.Overridden;
                    }
                }

                if(this.currentInvoice.contingencyPctStatus === OverrideStatus.Default &&
                    this.currentInvoice.contingencyCapStatus === OverrideStatus.Default &&
                    this.currentInvoice.savingsStatus === OverrideStatus.Default &&
                    this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {

                        this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
                }

                break;
            case CalculatedFields.TaxRate:
                if(!this.currentInvoice.displayTaxRate) {
                   this.revertTaxRate(false);
                } else {
                    const newTaxRate = this.invoiceService.getRealPercentFromDisplay(this.currentInvoice.displayTaxRate);
                    if (newTaxRate !== this.currentInvoice.taxRate) {
                        this.currentInvoice.taxRate = newTaxRate;
                        this.currentInvoice.taxRateStatus = OverrideStatus.Overridden;
                        this.currentInvoice.isSavingsOverridden = false;
                        // Since we know we're overriding the tax status, go redo the savings and set its status
                        // to recalculated (that's the only possible outcome of this operation at this point)
                        this.currentInvoice.savingsStatus = OverrideStatus.Recalculated;
                        this.currentInvoice.savings = this.invoiceService.calculateSavings(this.currentInvoice);
                        if(this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {
                            this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
                            this.currentInvoice.feeAmountStatus = OverrideStatus.Overridden;
                        }
                    }
                }

                break;
            case CalculatedFields.ContingencyPct:
                const clearContingencyPct = !this.currentInvoice.displayContingencyPct && this.currentInvoice.displayContingencyPct !== 0;
                // Get the real data from the display data
                if(!clearContingencyPct) {
                    this.currentInvoice.contingencyPct = this.invoiceService.getRealPercentFromDisplay(this.currentInvoice.displayContingencyPct);
                }

                // Revert to original values if empty
                if(clearContingencyPct) {
                    this.currentInvoice.contingencyPct = this.currentInvoice.contingencyPctOriginal;
                    this.currentInvoice.displayContingencyPct = this.invoiceService.getDisplayPercent(this.currentInvoice.contingencyPct);
                }

                // Set override status
                if(this.currentInvoice.contingencyPct === this.currentInvoice.contingencyPctOriginal) {
                    this.currentInvoice.contingencyPctStatus = OverrideStatus.Default;

                    //If this AND cap are reverted, then also set the fee amount
                    if(this.currentInvoice.contingencyCapStatus === OverrideStatus.Default &&
                            this.currentInvoice.savingsStatus === OverrideStatus.Default &&
                            this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {
                        this.currentInvoice.feeAmountStatus = OverrideStatus.Default;
                        this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
                    }
                } else {
                    this.currentInvoice.contingencyPctStatus = OverrideStatus.Overridden;
                }

                break;
            case CalculatedFields.ContingencyCap:
                if(!this.currentInvoice.contingencyCap) {
                    this.currentInvoice.contingencyCap = this.currentInvoice.contingencyCapOriginal;
                }

                if(this.currentInvoice.contingencyCap === this.currentInvoice.contingencyCapOriginal) {
                    this.currentInvoice.contingencyCapStatus = OverrideStatus.Default;
                    //If this, savings, AND cap are reverted, then also set the fee amount
                    if(this.currentInvoice.contingencyPctStatus === OverrideStatus.Default &&
                        this.currentInvoice.savingsStatus === OverrideStatus.Default &&
                        this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {

                        this.currentInvoice.feeAmountStatus = OverrideStatus.Default;
                        this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
                    }
                } else {
                    this.currentInvoice.contingencyCapStatus = OverrideStatus.Overridden;
                }
                break;
            case this.CalculatedFields.ExpenseAmount:
                if(!this.currentInvoice.expenseAmount) {
                    this.currentInvoice.expenseAmount = 0;
                }
            case this.CalculatedFields.FixedFee:
                if (!this.currentInvoice.fixedFee && this.currentInvoice.fixedFee !== 0) {
                    this.currentInvoice.fixedFee = this.currentInvoice.fixedFeeOriginal;
                }

                if (this.currentInvoice.fixedFee === this.currentInvoice.fixedFeeOriginal) {
                    this.currentInvoice.fixedFeeStatus = OverrideStatus.Default;
                }
                else {
                    this.currentInvoice.fixedFeeStatus = OverrideStatus.Overridden;
                }
            default:
                break;
        }

        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
    }

    revertPreviousFMV(shouldCalcInvoiceData: boolean): void {
        if (this.currentInvoice.previousFMVStatus === OverrideStatus.Overridden &&
            this.currentInvoice.invoiceFMVStatus === OverrideStatus.Default) {
            if (this.currentInvoice.taxRateStatus === OverrideStatus.Recalculated &&
                    this.currentInvoice.savingsStatus === OverrideStatus.Recalculated) {
                // The basis for the tax rate/savings calculations changes when we switch from overriden FMV change
                // back to default; if the tax rate and savings are already both in a recalculated state, we want
                // to restore to a "pristine" state, so restore the savings here
                this.currentInvoice = this.invoiceService.resetTotalSavings(this.currentInvoice);
            }
        }

        this.currentInvoice.previousFMV = this.currentInvoice.calculatedPreviousFMV;

        if(shouldCalcInvoiceData) {
            this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
        }
    }

    revertInvoiceFMV(shouldCalcInvoiceData: boolean): void {
        if (this.currentInvoice.invoiceFMVStatus === OverrideStatus.Overridden &&
            this.currentInvoice.previousFMVStatus === OverrideStatus.Default) {
            if (this.currentInvoice.taxRateStatus === OverrideStatus.Recalculated &&
                    this.currentInvoice.savingsStatus === OverrideStatus.Recalculated) {
                // (same as above)
                this.currentInvoice = this.invoiceService.resetTotalSavings(this.currentInvoice);
            }
        }

        this.currentInvoice.invoiceFMV = this.currentInvoice.calculatedInvoiceFMV;

        if(shouldCalcInvoiceData) {
            this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
        }

    }

    revertFeeAmount(): void {
        this.currentInvoice.feeAmount = this.currentInvoice.calculatedFeeAmount;
        this.currentInvoice.feeAmountStatus = OverrideStatus.Default;

        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
    }

    revertSavings(): void {
        this.currentInvoice.isSavingsOverridden = false;
        this.currentInvoice.savingsStatus = OverrideStatus.Default;
        this.currentInvoice.savings = this.currentInvoice.calculatedSavings;
        if (this.currentInvoice.taxRateStatus === OverrideStatus.Recalculated ||
                this.currentInvoice.taxRateStatus === OverrideStatus.Default) {
            if (this.currentInvoice.fmvChangeStatus === OverrideStatus.Recalculated) {
                this.currentInvoice.taxRate = this.invoiceService.calculateInitialTaxRate(this.currentInvoice);
            }
            else {
                this.currentInvoice.taxRate =
                    parseFloat(this.numberPipe.transform(this.currentInvoice.calculatedTaxRate, '1.8-8'));
            }
        }

        if(this.currentInvoice.contingencyPctStatus === OverrideStatus.Default &&
            this.currentInvoice.contingencyCapStatus === OverrideStatus.Default &&
            this.currentInvoice.savingsStatus === OverrideStatus.Default &&
            this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {

                this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
        }

        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
    }

    revertTaxRate(shouldCalcInvoiceData: boolean): void {
        this.currentInvoice.taxRate = parseFloat(this.numberPipe.transform(this.currentInvoice.calculatedTaxRate, '1.8-8'));
        this.currentInvoice.displayTaxRate = this.invoiceService.getDisplayPercent(this.currentInvoice.taxRate);
        if (this.currentInvoice.savingsStatus === OverrideStatus.Default ||
                this.currentInvoice.savingsStatus === OverrideStatus.Recalculated) {
            this.currentInvoice = this.invoiceService.resetTotalSavings(this.currentInvoice);
        }

        if(shouldCalcInvoiceData) {
            this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
        }
    }

    revertContingencyPct(): void {
        this.currentInvoice.contingencyPct = this.currentInvoice.contingencyPctOriginal;
        this.currentInvoice.displayContingencyPct = this.invoiceService.getDisplayPercent(this.currentInvoice.contingencyPct);

        this.currentInvoice.contingencyPctStatus = OverrideStatus.Default;

        //If this AND cap are reverted, then also set the fee amount
        if(this.currentInvoice.contingencyCapStatus === OverrideStatus.Default &&
                this.currentInvoice.savingsStatus === OverrideStatus.Default &&
                this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {
            this.currentInvoice.feeAmountStatus = OverrideStatus.Default;
            this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
        }

        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
    }

    revertContingencyCap(): void {
        this.currentInvoice.contingencyCap = this.currentInvoice.contingencyCapOriginal;

        this.currentInvoice.contingencyCapStatus = OverrideStatus.Default;
        //If this, savings, AND cap are reverted, then also set the fee amount
        if(this.currentInvoice.contingencyPctStatus === OverrideStatus.Default &&
            this.currentInvoice.savingsStatus === OverrideStatus.Default &&
            this.currentInvoice.feeAmountStatus !== OverrideStatus.Overridden) {

            this.currentInvoice.feeAmountStatus = OverrideStatus.Default;
            this.currentInvoice.feeAmount = this.invoiceService.calculateFeeAmount(this.currentInvoice);
        }

        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
    }

    revertFixedFee(): void {
        this.currentInvoice.fixedFee = this.currentInvoice.fixedFeeOriginal;
        this.currentInvoice.fixedFeeStatus = OverrideStatus.Default;

        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
    }

    includeOtherParcelChange(): void {
        this.currentInvoice = this.invoiceService.resetFromAppealData(this.currentInvoice, true);
    }

    async refreshCurrentInvoice(): Promise<void> {
        this.loading = true;

        try {
            this.currentInvoice = await this.invoiceService.refreshInvoice(this.currentInvoice);
        } finally {
            this.loading = false;
        }
    }

    automatedNoteChange(): void {
        if(this.currentInvoice.automaticallyUpdateNotes) {
            this.currentInvoice.notes = this.invoiceService.generateNotes(this.currentInvoice);
        }
    }

    removeAppeal(appealIndex: number): void {
        this.currentInvoice = this.invoiceService.removeAppeal(this.currentInvoice, appealIndex);
    }

    navigateToParcel(appeal: InvoiceAppeal): void {
        const parcelHref = this.navigationService.getHrefFromState('parcel', { parcelId: appeal.parcelID, siteId: this.currentInvoice.siteId, companyId: this.currentInvoice.companyId});
        window.open(parcelHref, '_blank');
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentInvoice) {
            this.currentInvoice = changes.currentInvoice.currentValue;
            this.automatedNoteChange();
        }
    }
}


export enum CalculatedFields {
    PreviousFMV = 1,
    InvoiceFMV = 2,
    Savings = 3,
    TaxRate = 4,
    FeeAmount = 5,
    ExpenseAmount = 6,
    ContingencyPct = 7,
    ContingencyCap = 8,
    FixedFee = 9
}
