import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridColumns } from '../../../AgGrid/agGridColumns';
import { DecimalPipe } from '@angular/common';
import { AssetReturnFilingStatusCellRendererComponent } from './agGridStatusCellRenderer.component';
import { AgGridFilterParams } from '../../../AgGrid/agGridFilterParams';
import { AgGridOptionsBuilder } from '../../../AgGrid/index';
import { AssetRepository } from '../../../Repositories';
import { AgGridLinkCellRendererComponent, AgGridLinkCellRendererParams } from '../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';

@Component({
    selector: 'asset-return-filings',
    templateUrl: './assetReturnFilings.component.html'
})
export class AssetReturnFilingsComponent implements OnInit {
    constructor(
        private readonly _assetRepository: AssetRepository,
        private readonly _decimalPipe: DecimalPipe) {
    }

    @Input() assetId: number;
    @Input() lienDate: Date;
    @Input() companyId: number;

    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    private _gridApi: GridApi;
    private _assetReturns: Compliance.AssetReturnModel[];


    async ngOnInit(): Promise<void> {
        this._assetReturns = await lastValueFrom(this._assetRepository.getReturns(this.assetId));

        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Tax Year',
                field: 'taxYear',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Asset Number',
                field: 'assetNumber',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Filing',
                field: 'filingDescription',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as Compliance.AssetReturnModel;
                        if (!filingControl) {
                            return '';
                        }
                        return `#/filing/${filingControl.filingId}`;
                    },
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Filing Batch',
                field: 'filingBatchDescription',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as Compliance.AssetReturnModel;
                        if (!filingControl) {
                            return '';
                        }
                        return `#/returnBatch/${filingControl.filingBatchId}`;
                    },
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Form',
                field: 'formName',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Parcel Acct Num',
                field: 'parcelAccountNumber',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as Compliance.AssetReturnModel;
                        if (!filingControl) {
                            return '';
                        }
                        return `#/parcel/${filingControl.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Schedule',
                field: 'scheduleName',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Factor Table',
                field: 'factorTableName',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Cost',
                field: 'cost',
                suppressSizeToFit: true,
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => {
                    return this._decimalPipe.transform(params.value, '1.2-2');
                },
                filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Reported Value',
                field: 'reportedValue',
                suppressSizeToFit: true,
                width: AgGridColumns.numericColumnWidth,
                valueFormatter: (params) => {
                    return this._decimalPipe.transform(params.value, '1.2-2');
                },
                filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Status',
                field: 'status',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnSmallWidth,
                cellClass: 'status-text',
                cellRendererFramework: AssetReturnFilingStatusCellRendererComponent
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._assetReturns)) {
            return;
        }

        this._gridApi.setRowData(this._assetReturns);
        this._gridApi.sizeColumnsToFit();
    }
}
