import { IPortalBrandingConfig } from '@ace/shared';
import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InstanceRights, RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AccountService } from '../../User/account.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { NavOnboardingComponent } from '../Onboarding-Modal/navOnboarding.component';
import { OnboardingBannerComponent } from '../Onboarding-Modal/onboardingBanner.component';

export const branding: IPortalBrandingConfig = {
    logoTitle: 'PropertyPoint\u00AE',
    footerPortalTitle: 'Tax.com Portal',
    footerPortalHref: 'https://tax.com',
    footerText: '\u00A92024 Tax.com, a division of Ryan, LLC'
};

export interface TopNavRoles {
    hasAddNewCompanyRole: boolean;
    hasAddNewInstanceRole: boolean;
    hasContactRoles: boolean;
    hasPropCharsRoles: boolean;
    hasContactRoleRoles: boolean;
    hasUserGroupViewOrEdit: boolean;
    hasUserGroupEdit: boolean;
    hasTeamViewOrEdit: boolean;
    hasEditRyanPrivatePermissions: boolean;
    hasViewRyanPrivatePermissions: boolean;
    hasComplianceSetupsView: boolean;
    hasComplianceSetupsEdit: boolean;
    hasValuationTemplateEdit: boolean;
    hasAdminOperationsEdit: boolean;
    hasAdminOperationsView: boolean;
    hasAllowDataImports: boolean;
    hasManageAPIKeys: boolean;
    hasAgentInfo: boolean;
    hasManageConsultingEngagements: boolean;
    hasLicensedDocumentServices: boolean;
    hasSupportRole: boolean;
    isSingleInstanceSelected: boolean;
    hasAdminRole: boolean;
}

export interface BannerMessage<T = string> {
    message: T;
    onDismiss: () => void;
}

@Injectable({
    providedIn: 'root'
})
export class TopNavService {
    constructor(private readonly _restrictService: RestrictService,
                private readonly _userInstanceService: UserInstanceService,
                private readonly _weissmanModalService: WeissmanModalService,
                private readonly _accountService: AccountService) {
    }

    private readonly _sideNavOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _sideNavOpen$ = this._sideNavOpen.asObservable();

    private readonly _bannerMessage: Subject<BannerMessage<string | ComponentType<any>>> = new Subject();
    private readonly _bannerMessage$: Observable<BannerMessage<string | ComponentType<any>>> = this._bannerMessage.asObservable();

    get sideNavOpen$() {
        return this._sideNavOpen$;
    }

    set sideNavOpen(value: boolean) {
        this._sideNavOpen.next(value);
    }

    set bannerMessage(message: BannerMessage<string | ComponentType<any>>) {
        this._bannerMessage.next(message);
    }

    get bannerMessage$(): Observable<BannerMessage<string | ComponentType<any>>> {
        return this._bannerMessage$;
    }

    roles(): TopNavRoles {
        const hasManageAPIKeys = this._restrictService.isInRole(Roles.MANAGEAPIKEYS);
        const hasAdminOperationsView = this._restrictService.isInRole(Roles.ADMINOPERATIONSVIEW);
        const hasComplianceSetupsView = this._restrictService.isInRole(Roles.COMPLIANCESETUPSVIEW);
        const hasContactRoleRoles = !this._restrictService.isNotInRoles([Roles.CONTACTROLEVIEW]);
        const hasViewRyanPrivatePermissions = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
        const hasUserGroupViewOrEdit = this._restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP);
        const hasPropCharsRoles = !this._restrictService.isNotInRoles([Roles.PROPCHARVIEW, Roles.PROPCHAREDIT]);
        const hasValuationTemplateEdit = this._restrictService.hasInstanceRight(InstanceRights.VALUATIONTEMPLATEEDIT);
        const hasComplianceFeatureView = this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW);
        const hasCompanyDataImportsView = this._restrictService.hasInstanceRight(InstanceRights.COMPANYDATAIMPORTSVIEW);
        const hasManageConsultingEngagements = this._restrictService.hasInstanceRight(InstanceRights.MANAGECONSULTINGENGAGEMENT);
        const hasContactRoles = !this._restrictService.hasNoInstanceRights([InstanceRights.CONTACTVIEW, InstanceRights.CONTACTEDIT]);
        const hasAgentInfo = !this._restrictService.hasNoInstanceRights([InstanceRights.AGENTINFORMATIONVIEW, InstanceRights.AGENTINFORMATIONEDIT]);
        const hasTeamViewOrEdit = this._restrictService.hasInstanceRight(InstanceRights.TEAMSETUPSVIEW)
            || this._restrictService.hasInstanceRight(InstanceRights.TEAMSETUPSEDIT);
        const hasAllowDataImports = this._userInstanceService.isSingleInstanceSelected()
        && (this._restrictService.isInRole(Roles.ALLOWDATAIMPORTS)
            || this._restrictService.hasInstanceRight(InstanceRights.INSTANCEDATAIMPORTS));

        return {
            hasAddNewCompanyRole: this._restrictService.hasInstanceRight(InstanceRights.ADDNEWCOMPANY),
            hasAddNewInstanceRole: this._restrictService.isInRole(Roles.INSTANCEADMIN),
            hasContactRoles,
            hasPropCharsRoles,
            hasContactRoleRoles,
            hasUserGroupViewOrEdit,
            hasUserGroupEdit: this._restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP),
            hasTeamViewOrEdit,
            hasEditRyanPrivatePermissions: this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT),
            hasViewRyanPrivatePermissions,
            hasComplianceSetupsView,
            hasComplianceSetupsEdit: this._restrictService.isInRole(Roles.COMPLIANCESETUPSEDIT),
            hasValuationTemplateEdit,
            hasAdminOperationsEdit: this._restrictService.isInRole(Roles.ADMINOPERATIONSEDIT),
            hasAdminOperationsView,
            hasAllowDataImports,
            hasManageAPIKeys,
            hasAgentInfo,
            hasManageConsultingEngagements,
            hasLicensedDocumentServices: this._restrictService.isInRole(Roles.LICENSEDDOCUMENTSERVICES),
            hasSupportRole: this._restrictService.isInRole(Roles.SUPPORT),
            isSingleInstanceSelected: this._userInstanceService.isSingleInstanceSelected(),

            hasAdminRole: hasContactRoles ||
                hasPropCharsRoles ||
                hasContactRoleRoles ||
                hasUserGroupViewOrEdit ||
                hasTeamViewOrEdit ||
                hasAllowDataImports ||
                hasComplianceFeatureView ||
                hasComplianceSetupsView ||
                hasValuationTemplateEdit ||
                hasCompanyDataImportsView ||
                hasAgentInfo ||
                hasAdminOperationsView ||
                hasViewRyanPrivatePermissions ||
                hasManageAPIKeys ||
                hasManageConsultingEngagements
        };
    }

    async showOnboardingModal(): Promise<void> {
        return await this._weissmanModalService.showAsync(NavOnboardingComponent, null, 'modal-lg');
    }

    showOnboardingBanner(): void {
        this.bannerMessage = {
            message: OnboardingBannerComponent,
            onDismiss: async () => {
                await this._accountService.updateOnboardingStatus(Core.UserOnboardingStatusEnum.Dismissed);
            }
        } as BannerMessage<ComponentType<OnboardingBannerComponent>>;
    }
}
