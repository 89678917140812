import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BillClusterService } from '../Taxes/bill-cluster.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';

export enum AccrualRowActionEnum {
    AddRowBefore = 1,
    AddRowAfter = 2,
    Override = 3,
    NoAction = 4
}

interface BillClusterAccrualDetailDtoUI extends Core.BillClusterAccrualDetailDTO {
    accrualDetailRows: AccrualDetailRowUI[];
}

export interface AccrualDetailRowUI extends Core.AccrualDetailRow {
    isHovered: boolean;
    isEditAdjustment: boolean;
    isAddAdjustment: boolean;
    isEditOverridden: boolean;
    durationText: string;
}

@Component({
    selector: 'accrual-detail-modal',
    templateUrl: './accrual-detail.component.html',
    styleUrls: ['./accrual-detail.component.scss']
})
export class AccrualDetailModalComponent implements OnInit  {
    constructor(
        public modalRef: BsModalRef,
        private readonly _billClusterService: BillClusterService,
        private readonly _messageBoxService: MessageBoxService,
    ) { }

    // From initialState
    editMode: boolean;
    billClusterId: number;

    accrualDetail: BillClusterAccrualDetailDtoUI;
    loading: boolean = false;
    originalRow: AccrualDetailRowUI;
    rowMenuItems: Compliance.NameValuePair<number>[] = [];
    currentlyEditing = false;
    showBalances: boolean;
    AccrualRowActionEnum = AccrualRowActionEnum;

    async ngOnInit() {
        this.loading = true;
        this.showBalances = false;

        try {
            const accrualDetail = await this._billClusterService.getAccrualDetails(this.billClusterId);
            this.accrualDetail = this._mapUIRows(accrualDetail) as BillClusterAccrualDetailDtoUI;
        } finally {
            this.loading = false;
        }
    }

    setupRowMenu(row: AccrualDetailRowUI, i: number): void {
        this.rowMenuItems = [];
        const subsequentRows = _.drop(this.accrualDetail.accrualDetailRows, i + 1);
        const precedingRow = this.accrualDetail.accrualDetailRows[i - 1];
        const followingRow = this.accrualDetail.accrualDetailRows[i + 1];

        if(row.isProjected) {
            if (!precedingRow || precedingRow.accountingPeriodId != row.accountingPeriodId) {
                this.rowMenuItems.push(this._getAddBeforeItem());
            }

            if(_.some(subsequentRows, 'isProjected')) {
                if (followingRow && followingRow.accountingPeriodId != row.accountingPeriodId) {
                    this.rowMenuItems.push(this._getAddAfterItem());
                }

                this.rowMenuItems.push(this._getOverrideItem());
            }
        } else {
            if (_.every(subsequentRows, 'isProjected') && followingRow && followingRow.accountingPeriodId != row.accountingPeriodId) {
                this.rowMenuItems.push(this._getAddAfterItem());
            }
        }

        if (!this.rowMenuItems.length) {
            this.rowMenuItems.push({ value: AccrualRowActionEnum.NoAction, name: 'No action can be taken' });
        }
    }

    performRowAction(row: AccrualDetailRowUI, item: Compliance.NameValuePair<number>, i: number): void {
        if(item.value == AccrualRowActionEnum.AddRowBefore || item.value == AccrualRowActionEnum.AddRowAfter) {
            const newRow: AccrualDetailRowUI = _.cloneDeep(row);
            newRow.isAddAdjustment = true;
            newRow.isReconcileRow = true;
            newRow.isProjected = false;

            const idxToInsert = item.value == AccrualRowActionEnum.AddRowBefore ? i : i + 1;
            this.accrualDetail.accrualDetailRows.splice(idxToInsert, 0, newRow);
        } else {
            this.originalRow = _.cloneDeep(row);
            row.isEditOverridden = true;
        }

        this.currentlyEditing = true;
    }

    async updateAccrual(): Promise<void> {
        const updateRequest: Core.BillClusterAccrualUpdateRequest = {
            accrualAdjustmentMethod: this.accrualDetail.accrualAdjustmentMethod,
            billClusterAccrualSetupRowVersion: this.accrualDetail.billClusterAccrualSetupRowVersion,
            billClusterId: this.billClusterId,
            fullyExpenseNext: this.accrualDetail.fullyExpenseNext,
            billClusterRowVersion: this.accrualDetail.billClusterRowVersion
        };

        this.loading = true;

        try {
            const accrualDetail = await this._billClusterService.updateAccrualDetails(this.billClusterId, updateRequest);
            this.accrualDetail = this._mapUIRows(accrualDetail) as BillClusterAccrualDetailDtoUI;
        } finally {
            this.loading = false;
        }
    }

    updateShowBalances(): void {
        this.modalRef.setClass('modal-xl');
    }

    saveAdjustment(row: AccrualDetailRowUI, i: number): void {
        if (row.isEditOverridden) {
            this._saveOverride(row, false);
        } else {
            this._saveAdjustment(row, i);
        }
    }

    async revertTrueUp(row: AccrualDetailRowUI): Promise<void> {
        const msgBoxResult = await this._messageBoxService.open({
            title: 'Confirm',
            message: 'Are you sure you want to revert the True Up?',
            buttons: MessageBoxButtons.YesNo
        });

        if (msgBoxResult === MessageBoxResult.Yes) {
            this._saveOverride(row, true);
        }
    }

    editAdjustment(row: AccrualDetailRowUI): void {
        this.originalRow = _.cloneDeep(row);
        row.isEditAdjustment = true;
        this.currentlyEditing = true;
    }

    cancelAdjustment(row: AccrualDetailRowUI): void {
        if (row.isEditAdjustment || row.isEditOverridden) {
            _.assign(row, this.originalRow);
        } else {
            this.accrualDetail.accrualDetailRows = _.reject(this.accrualDetail.accrualDetailRows, 'isAddAdjustment');
        }

        this.currentlyEditing = false;
    }

    async deleteAdjustment(row: AccrualDetailRowUI, i: number): Promise<void> {
        const msgBoxResult = await this._messageBoxService.open({
            title: 'Confirm',
            message: 'Are you sure you want to delete the adjustment?',
            buttons: MessageBoxButtons.YesNo
        });

        if (msgBoxResult === MessageBoxResult.No) {
            return;
        }

        const removeRequest: Core.BillClusterAccrualAdjustmentRemoveRequest = {
            accountingPeriodId: row.accountingPeriodId,
            billClusterRowVersion: this.accrualDetail.billClusterRowVersion,
            billClusterAccrualSetupRowVersion: this.accrualDetail.billClusterAccrualSetupRowVersion,
            billClusterId: this.billClusterId,
            isPrior: this._getIsPrior(row, i)
        };

        this.loading = true;

        try {
            const accrualDetail = await this._billClusterService.deleteAccrualAdjustment(this.billClusterId, removeRequest);
            this.accrualDetail = this._mapUIRows(accrualDetail) as BillClusterAccrualDetailDtoUI;
        } finally {
            this.loading = false;
            this.currentlyEditing = false;
        }
    }

    getDateRange(row: AccrualDetailRowUI): string {
        return `${moment(row.beginDate).utc().format('M/D/Y')} - ${moment(row.endDate).utc().format('M/D/Y')}`;
    }

    close(): void {
        this.modalRef.hide();
    }

    private _mapUIRows(accrualDetail: Core.BillClusterAccrualDetailDTO): Core.BillClusterAccrualDetailDTO {
        accrualDetail.accrualDetailRows = _.map(accrualDetail.accrualDetailRows, (row: AccrualDetailRowUI) => {
            row.isAddAdjustment = row.isEditAdjustment = row.isEditOverridden = row.isHovered = false;
            row.durationText = this._getDurationText(row);
            return row;
        });

        return accrualDetail;
    }

    private _getDurationText(row: AccrualDetailRowUI): string {
        switch (row.accountingDurationType) {
            case Core.AccountingDurationTypeEnum.Monthly:
                return '1 Mo';
            case Core.AccountingDurationTypeEnum.Quarterly:
                return '1 Qtr';
            default:
                return `${moment(row.endDate).diff(row.beginDate, 'days') + 1} days`;
        }
    }

    private _getAddBeforeItem(): Compliance.NameValuePair<number> {
        return { value: AccrualRowActionEnum.AddRowBefore, name: 'Add Row Before' };
    }

    private _getAddAfterItem(): Compliance.NameValuePair<number> {
        return { value: AccrualRowActionEnum.AddRowAfter, name: 'Add Row After' };
    }

    private _getOverrideItem(): Compliance.NameValuePair<number> {
        return { value: AccrualRowActionEnum.Override, name: 'Override' };
    }

    private async _saveOverride(row: AccrualDetailRowUI, shouldRevert: boolean): Promise<void> {
        const overrideRequest: Core.BillClusterAccrualOverrideRequest = {
            accountingPeriodId: row.accountingPeriodId,
            billClusterRowVersion: this.accrualDetail.billClusterRowVersion,
            billClusterAccrualSetupRowVersion: this.accrualDetail.billClusterAccrualSetupRowVersion,
            billClusterId: this.billClusterId,
            revert: shouldRevert,
            trueUp: row.trueUp
        };

        this.loading = true;

        try {
            const accrualDetail = await this._billClusterService.overrideAccrualTrueUp(this.billClusterId, overrideRequest);
            this.accrualDetail = this._mapUIRows(accrualDetail) as BillClusterAccrualDetailDtoUI;
        } catch (e) {
            this.cancelAdjustment(row);
        } finally {
            this.loading = false;
            this.currentlyEditing = false;
        }
    }

    private async _saveAdjustment(row: AccrualDetailRowUI, i: number): Promise<void> {
        const adjustmentRequest: Core.BillClusterAccrualAdjustmentRequest = {
            accountingPeriodId: row.accountingPeriodId,
            billClusterRowVersion: this.accrualDetail.billClusterRowVersion,
            billClusterAccrualSetupRowVersion: this.accrualDetail.billClusterAccrualSetupRowVersion,
            billClusterId: this.billClusterId,
            isPrior: this._getIsPrior(row, i),
            reconcileDescription: row.reconcileDescription,
            trueUp: row.trueUp
        };

        this.loading = true;

        try {
            const accrualDetail = row.isEditAdjustment
                ? await this._billClusterService.updateAccrualAdjustment(this.billClusterId, adjustmentRequest)
                : await this._billClusterService.addAccrualAdjustment(this.billClusterId, adjustmentRequest);

            this.accrualDetail = this._mapUIRows(accrualDetail) as BillClusterAccrualDetailDtoUI;
        } catch (e) {
            this.cancelAdjustment(row);
        } finally {
            this.loading = false;
            this.currentlyEditing = false;
        }
    }

    private _getIsPrior(row: AccrualDetailRowUI, i: number): boolean {
        const nextRow = this.accrualDetail.accrualDetailRows[i + 1];
        return nextRow && nextRow.accountingPeriodId == row.accountingPeriodId;
    }
}
