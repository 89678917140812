import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../Compliance/WeissmanModalService';
import { ProcessingService } from './processing.service.upgrade';

export interface ChangeDueDateModalParams {
    taskIDs: number[];
    optionID: number;
    searchTimestamp: Date;
    parentCallsService?: boolean
}

@Component({
    selector: 'change-due-date-modal',
    templateUrl: './change-due-date.modal.component.html'
})
export class ChangeDueDateModalComponent implements OnInit, IWeissmanModalComponent<ChangeDueDateModalParams, any> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _processingService: ProcessingService) {
    }

    params: ChangeDueDateModalParams;
    result: any;

    loading: boolean;
    dueDateText: string;
    dueDateTextOptions = {
        9: 'Appeal Deadline',
        10: 'Appeal Deadline',
        11: 'Submit Evidence Date',
        12: 'Informal Hearing Date',
        13: 'Formal Hearing Date',
        14: 'Payment Due Date',
        15: 'Intake Item Due Date',
        16: 'Filing Due Date',
        25: 'Invoice Due Date',
        35: 'Confirm Hearing Date'
    };
    dateOnly: boolean;
    dueDate: Date;
    taskIDs: number[];
    searchTimestamp: Date;
    parentCallsService: boolean;

    ngOnInit(): void {
        const optionID = this.params.optionID;
        this.parentCallsService = this.params.parentCallsService;
        this.dueDateText = this.dueDateTextOptions[optionID] || 'Due Date';
        this.dateOnly = optionID != 11 && optionID != 12 && optionID != 13;
        this.taskIDs = this.params.taskIDs;
        this.searchTimestamp = this.params.searchTimestamp;
    }

    showModalInit(): void {
        this.loading = false;
    }

    async save(): Promise<void> {
        if (this.parentCallsService) {
            this.result = async () => await this._processingService.changeDueDate(this.taskIDs, this.dueDate, this.searchTimestamp);
            this.cancel();
        }
        else {
            this.loading = true;

            try {
                this.result = await this._processingService.changeDueDate(this.taskIDs, this.dueDate, this.searchTimestamp);
                this.cancel();
            } finally {
                this.loading = false;
            }
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
