import { OnInit, Component } from '@angular/core';
import { StateService } from '../../Common/States/States.Service';
import { StateSummary } from '../../Common/States/state.model';
import { AssessorService } from '../../Assessor-Collector/Assessor/assessor-service';
import { Assessor } from '../../Assessor-Collector/assessor.model';

import * as _ from 'lodash';
import { BulkImportAssessmentsModel } from '../bulk.import.model';
import { BulkImportService } from '../bulk.import.service';

@Component({
    selector: 'bulk-import-assessments',
    templateUrl: './bulk.import.assessments.component.html',
    styleUrls: ['./bulk.import.assessments.component.scss']
})
export class BulkImportAssessmentsComponent implements OnInit {
    constructor(
        private stateService: StateService,
        private assessorService: AssessorService,
        private bulkImportService: BulkImportService,
    ) {
    }

    states: StateSummary[];
    assessors: Assessor[];
    years: number[];
    isImporting: boolean;
    initIndex: number;
    userCancelled: boolean;
    successfulImportCount: number;

    payload: BulkImportAssessmentsModel;
    fileLines: string[];

    ngOnInit() {
        this.stateService.getSummary()
            .then((states: StateSummary[]) => {
                this.states = states;
            });

        const currentYear: number = new Date().getFullYear();

        const years: number[] = _.range(currentYear - 5, currentYear + 10) as number[];
        this.years = years.reverse();

        this.initValues();
    }

    initValues(): void {
        this.payload = new BulkImportAssessmentsModel();
        this.payload.annualYear = new Date().getFullYear();
        this.payload.stateID = null;
        this.payload.assessorID = null;

        this.isImporting = false;
        this.userCancelled = false;
        this.initIndex = 1;
        this.fileLines = undefined;
        this.successfulImportCount = 0;
    }

    stateSelected(): void {
        this.assessorService.getByStateId(this.payload.stateID)
            .then((assessors: Assessor[]) => {
                this.assessors = _.orderBy(assessors, ['abbr'], ['asc']);

                this.payload.assessorID = null;
            });
    }

    fileChanged(e): void {
        const file: File = e.target.files[0];

        if (!file) {
            this.fileLines = undefined;
            return;
        }

        const fileReader: FileReader = new FileReader();

        fileReader.onload = () => {
            this.fileLines = (fileReader.result as string).split('\n');
        };

        fileReader.readAsText(file);
    }

    startImporting(): void {
        if (this.userCancelled) {
            this.userCancelled = false;
            this.initIndex = 1;
            this.successfulImportCount = 0;
            this.fileLines = undefined;

            return;
        }

        if (this.initIndex >= this.fileLines.length) {
            return;
        }

        this.isImporting = true;
        this.payload.data = `${this.fileLines[0]  }\n`;

        for (let i = this.initIndex; i < (10 + this.initIndex) && i < this.fileLines.length; i++) {
            this.payload.data += `${this.fileLines[i]  }\n`;
        }

        this.bulkImportService.importAssessmentData(this.payload)
            .then((successfullyImported) => {
                this.successfulImportCount += successfullyImported;

                this.initIndex = this.fileLines.length;

                this.startImporting();
            })
            .catch(() => {
                this.cancelImport();

                this.startImporting();
            });
    }

    cancelImport(): void {
        this.isImporting = false;
        this.userCancelled = true;
    }
}
