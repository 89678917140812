import { ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ContactRoleService } from './contact.role.service';
@Component({
    selector: 'roles-list',
    templateUrl: './roles.list.component.html',
    styleUrls: ['./roles.list.component.scss']
})
export class RolesListComponent implements OnInit {
    constructor(private readonly _roleService: ContactRoleService) { }

    @ViewChildren('formRow') rows: QueryList<ElementRef>;
    readonly entityTypes: string[] = ['company', 'site', 'parcel', 'state', 'assessor', 'collector'];
    dataLoading: boolean = false;
    roles: Weissman.Model.Contacts.ContactRole[];

    async ngOnInit() {
        this.dataLoading = true;
        const roles = await this._roleService.get();
        this.roles = _.orderBy(roles, ['new', 'roleDesc'], ['desc', 'asc']);
        this.dataLoading = false;
    }
}
