import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../Layout/navigation.service';
import { UserInstanceService } from '../../User/userInstance.service';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';

/**
 * Instance Filter Single-selection dropdown
 * loads the list of a user's instance memberships from UserInstanceService
 * works in conjunction with the InstanceHeaderInterceptor.
 * NOTE: When the instance is changed, the user is redirected to the homepage
 *  to ensure any navigation/views will respect the change
 * Example usage:
 * <ws-instance-filter></ws-instance-filter>
 *
 */
@Component({
    selector: 'ws-instance-filter',
    templateUrl: './instanceFilter.component.html',
    styleUrls: ['./instanceFilter.component.scss']
})
export class InstanceFilterComponent implements OnInit, OnDestroy {
    constructor(
        @Inject('$rootScope') private _rootScope: any,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _navigationService: NavigationService
    ) {
        _rootScope.$on('global.edit.mode.activated', () => {
            this.isDisabled = true;
        });
        _rootScope.$on('global.edit.mode.deactivated', () => {
            this.isDisabled = false;
        });
    }

    instances: Array<Core.InstanceViewModel> = [];
    selectedInstance: Core.InstanceViewModel;
    isDisabled: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        // get all the instances the current user has access to
        this.instances = [this._userInstanceService.allInstancesItem].concat(this._userInstanceService.getUserInstanceMembership(false));

        // set initial value
        this.selectedInstance = this._userInstanceService.getSelectedInstance();

        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$))
            .subscribe((editMode: boolean) => {
                this.isDisabled = editMode;
            });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    instanceChanged(instance: Core.InstanceViewModel): void {
        this.selectedInstance = instance;
        this._userInstanceService.saveSelectedInstance(instance);

        //go home and reload page so that all the permissions are checked - especially on the top menus which do not reload on navigation
        window.location.replace(`${window.location.origin}/#/home`);
        window.location.reload();
    }

}
