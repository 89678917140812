import { Component, OnDestroy, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../AgGrid/MultiSelectTracker';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../AgGrid';
import { RetrieveAssetsAgGridDataSource, RetrieveAssetsDataSourceParams } from './agGridDataSource';
import { AllocationRepository } from '../../../Repositories';
import { RETRIEVE_ASSETS_HELP } from './retrieveAssets.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { lastValueFrom, Subscription } from 'rxjs';
import { WeissmanDateFormatPipe } from '../../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { DecimalPipe } from '@angular/common';

export interface RetrieveAssetsParams {
    allocationId: number
}

@Component({
    selector: 'retrieve-assets-component',
    templateUrl: './retrieveAssets.component.html'
})
export class RetrieveAssetsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<RetrieveAssetsParams, boolean> {
    constructor(private readonly _modalRef: BsModalRef,
                private readonly _allocationRepository: AllocationRepository,
                private readonly _helpService: HelpService,
                private readonly _datePipe: WeissmanDateFormatPipe,
                private _decimalPipe: DecimalPipe) {
    }

    params: RetrieveAssetsParams;
    result: boolean;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    gridTracker: AgGridMultiSelectTracker;
    disableSave: boolean = false;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.FilingModel).filingId)
        }
    })
        .withContext(this)
        .withInfiniteScroll()
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    private _gridApi: GridApi;
    private _gridDataSource: RetrieveAssetsAgGridDataSource;
    private _selectedRowsSubscription: Subscription;


    async ngOnInit(): Promise<void> {
        this._helpService.setContent(RETRIEVE_ASSETS_HELP);
    }

    async ngOnDestroy(): Promise<void> {
        this._selectedRowsSubscription && this._selectedRowsSubscription.unsubscribe();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSubscription = this.gridTracker.selectedRows$.subscribe(x =>
            this.disableSave = !x.selectAllRows && x.selectedRows.length === 0);

        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'assetId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams
            },
            {
                field: 'assetNumber',
                headerName: 'Asset Number',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'siteName',
                headerName: 'Site Name',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'siteNumber',
                headerName: 'Site Number',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'parcel',
                headerName: 'Parcel',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'description',
                headerName: 'Description',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'classification',
                headerName: 'Classification',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'acqDate',
                headerName: 'Aqc. Date',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: x => this._datePipe.transform(x.value, true),
            },
            {
                field: 'reportableCost',
                headerName: 'Reportable Cost',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: x => this._decimalPipe.transform(x.value, '1.2-2')
            },
            {
                field: 'assessor',
                headerName: 'Assessor',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'state',
                headerName: 'State',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'allocated',
                headerName: 'Allocated',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                field: 'lastReturn',
                headerName: 'Last Return',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: `${Compliance.AllocationCompanyAssetPropertyEnum.AssetNumber}`,
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Adding Assets', busyMsgId);

        try {
            const model = this.gridTracker.getSelectedRowsModel();
            await lastValueFrom(this._allocationRepository.addCompanyAssets(this.params.allocationId, model));

            this.result = true;

            this._modalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._modalRef.hide();
    }

    private _setDataSource(): void {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear();

        const dataSourceParams = (): RetrieveAssetsDataSourceParams => {
            return {
                allocationId: this.params.allocationId
            };
        };

        this._gridDataSource = new RetrieveAssetsAgGridDataSource(this._gridApi, this._allocationRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }
}
