<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Rent Rolls" *ngIf="!isEmbededMode"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                <span *ngIf="!isEmbededMode">Rent Rolls</span> <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <!-- <div class="ws-section__header__actions__item" *ngIf="canEdit && !isEmbededMode">
                    <button type="button" class="ace-btn btn-primary btn-sm" (click)="addAsset()" helpTooltip helpContentId="asset-list.new-asset" position="bottom" [disabled]="!lienDate">New Asset</button>
                </div> -->
                <div class="ws-section__header__actions__item" *ngIf="isEmbededMode">
                    <a [href]="filterHref">View Full Rent Rolls</a>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" (click)="refresh()" helpTooltip helpContentId="app.refresh" position="bottom" [disabled]="refreshing"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
        <div class="ws-section__filters">
            <div class="ws-section__filters__filter-group">
                <label>End Date:</label>
                <select class="form-select form-select-sm"
                        [(ngModel)]="selectedEndDate"
                        (change)="onEndDateChange()">
                    <option *ngFor="let date of endDates" [ngValue]="date">{{ date | weissmanDateFormat }}</option>
                </select>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [companyId]="companyId"
                        [gridId]="gridId"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                        [exportOptions]="exportOptions"
                        (bulkDelete)="bulkDelete()"
                        (bulkUpdate)="bulkUpdate()">
    </ws-ag-grid-angular>
    <div class="totals">
        <div class="totals item">
            OCCUPANCY SF:<span>{{rentRollGridTotalsRow?.occupancySqFt | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            VACANCY SF:<span>{{rentRollGridTotalsRow?.vacancySqFt | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            TOTAL SF:<span>{{rentRollGridTotalsRow?.totalSqFt | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            OCCUPIED UNITS:<span>{{rentRollGridTotalsRow?.occupiedUnits | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            VACANT UNITS:<span>{{rentRollGridTotalsRow?.vacantUnits | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            TOTAL UNITS:<span>{{rentRollGridTotalsRow?.totalUnits | number:'1.0-0'}}</span>
        </div>
    </div>
</div>
