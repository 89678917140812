<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Income Statements" *ngIf="!isEmbededMode"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                <span *ngIf="!isEmbededMode">Income Statements</span>  <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <!-- <div class="ws-section__header__actions__item" *ngIf="canEdit && !isEmbededMode">
                    <button type="button" class="ace-btn btn-primary btn-sm" (click)="addAsset()" helpTooltip helpContentId="asset-list.new-asset" position="bottom" [disabled]="!lienDate">New Asset</button>
                </div> -->
                <div class="ws-section__header__actions__item" *ngIf="isEmbededMode">
                    <a [href]="filterHref">View Full Income Statements</a>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" (click)="refresh()" helpTooltip helpContentId="app.refresh" position="bottom" [disabled]="refreshing"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
        <div class="ws-section__filters">
            <div class="ws-section__filters__filter-group">
                <label>End Date:</label>
                <select class="form-select form-select-sm"
                        [(ngModel)]="selectedEndDate"
                        (change)="onEndDateChange()">
                    <option *ngFor="let date of endDates" [ngValue]="date">{{ date | weissmanDateFormat }}</option>
                </select>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [companyId]="companyId"
                        [gridId]="gridId"
                        [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                        [exportOptions]="exportOptions"
                        (bulkDelete)="bulkDelete()"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        (bulkUpdate)="bulkUpdate()">
    </ws-ag-grid-angular>
    <div class="totals">
        <div class="totals item">
            INCOME:<span>${{currentTotals?.income | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            OPERATING EXPENSE:<span>${{currentTotals?.operatingExpense | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            OPERATING EXPENSE RATIO:<span>{{currentTotals?.operatingExpenseRatio | percent:'1.2-2'}}</span>
        </div>
        <div class="totals item">
            NOI:<span>${{currentTotals?.noi | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            <hr/>
        </div>
        <div class="totals item">
            OPEX LESS TAX:<span>${{currentTotals?.opexLessTax | number:'1.0-0'}}</span>
        </div>
        <div class="totals item">
            OPEX RATIO LESS TAX:<span>{{currentTotals?.opexRatioLessTax | percent:'1.2-2'}}</span>
        </div>
        <div class="totals item">
            NOI LESS TAX:<span>${{currentTotals?.noiLessTax | number:'1.0-0'}}</span>
        </div>
    </div>
</div>
