import { IAuthSettings } from '@ace/shared';
import { IPortalBrandingConfig } from '@ace/shared/src/portal/config';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { AceAuthOidcWrapperService, IAuthComponentApi } from './aceAuthOidcWrapper.service';

@Component({
    selector: 'ace-auth-oidc-wrapper',
    template: `
        <ace-auth-oidc
            #authElement
            [authSettings]="authSettings"
            [branding]="branding">
        </ace-auth-oidc>
    `
})
export class AceAuthOidcWrapperComponent implements OnInit, AfterViewInit {
    constructor(
        private _featureFlagsService: FeatureFlagsService,
        private _aceAuthOidcWrapperService: AceAuthOidcWrapperService
    ) { }

    @ViewChild('authElement') private _authElement: ElementRef<IAuthComponentApi>;

    authSettings: IAuthSettings;

    branding: IPortalBrandingConfig;

    ngOnInit(): void {
        let endpoint = this._featureFlagsService.featureFlags.iamEndpoint;
        if (endpoint.endsWith('/')) {
            endpoint = endpoint.substring(0, endpoint.length - 1);
        }

        // Given something like https://identity.tax.com/identitytaxcom.onmicrosoft.com/b2c_1a_v1_signup_signin/v2.0,
        // use https://identity.tax.com for knownAuthorities and
        // https://identity.tax.com/identitytaxcom.onmicrosoft.com/b2c_1a_v1_signup_signin/v2.0 for authority. This
        // isn't necessary for b2clogin.com domains but for some reason is required for others.
        const endpointMatch = /^(https:\/\/[^\/]+)(\/.*)(\/v2.0)$/g.exec(endpoint);

        this.authSettings = {
            authApi: `${endpoint}/.well-known/openid-configuration`,
            clientId: this._featureFlagsService.featureFlags.iamAudience,
            // window.location.origin is something like http://localhost:9006 or https://propertypoint.tax
            redirectUri: window.location.origin,
            homeRealmDiscovery: true,
            postLogoutRedirectUri: window.location.origin,
            immediatelyStartAuthentication: this._aceAuthOidcWrapperService.autoStartLogin,
            overrideAuthResponse: true,
            authority: `${endpointMatch[1]}${endpointMatch[2]}`,
            knownAuthorities: [endpointMatch[1]]
        };

        this.branding = {
            logoUrl: 'https://www.ryan.com/globalassets/ryan-global-logo.png',
            logoTitle: 'Ryan',
            logoHeight: 45
        };
    }

    ngAfterViewInit(): void {
        this._aceAuthOidcWrapperService.authElement$.next(this._authElement.nativeElement);
    }
}
