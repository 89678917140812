import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetLimitOption } from '../Asset-Limit/assetLimit.component';
import { AssetPropertyInfo } from '../../Models/assetPropertyMap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { ASSET_LIMIT_HELP } from '../Asset-Limit/assetLimit.component.help';

export interface AssetLimitDetailsParams {
    limitOptions: Compliance.NameValuePair<AssetLimitOption>[];
    selectedOption: UntypedFormControl;
    assetDescriptors: AssetPropertyInfo[];
}

export interface AssetOverrideProperty extends AssetPropertyInfo {
    isSelected: boolean;
}

@Component({
    selector: 'asset-limit-details',
    templateUrl: './assetLimitDetails.component.html'
})
export class AssetLimitDetailsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AssetLimitDetailsParams, AssetLimitOption> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService
    ) { }

    selectedOption: UntypedFormControl;
    invalidCostAllocations: UntypedFormControl = new UntypedFormControl(false);
    noReportingParcel: UntypedFormControl = new UntypedFormControl(false);
    selectedOverrides: UntypedFormControl = new UntypedFormControl([]);
    furtherLimitForm: UntypedFormGroup;

    params: AssetLimitDetailsParams;
    result: AssetLimitOption;

    limitOptions: Compliance.NameValuePair<AssetLimitOption>[];
    overrideProperties: AssetPropertyInfo[];

    private _dropdownOnOpen: Compliance.NameValuePair<Compliance.AdvancedAssetFilters>;
    private destroy$: Subject<boolean> = new Subject();

    ngOnInit(): void {
        this._helpService.setContent(ASSET_LIMIT_HELP);
        this.limitOptions = this.params.limitOptions;
        this.selectedOption = this.params.selectedOption;
        this.overrideProperties = this.params.assetDescriptors;
        this._dropdownOnOpen = this.selectedOption.value;

        this._buildForm();
        this.applyFromDropdown();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    /**
     * Save the form values
     */
    save(): void {
        this.result = this.furtherLimitForm.value;
        this._bsModalRef.hide();
    }

    /**
     * Cancel and reset to initial state
     */
    cancel(): void {
        this.selectedOption.setValue(this._dropdownOnOpen);
        this.applyFromDropdown();
        this._bsModalRef.hide();
    }

    /**
     * Apply from the initial set value or from quick list select
     */
    applyFromDropdown(): void {
        const formValue = this.selectedOption.value;
        const filter = <AssetLimitOption>{ ...formValue.value };
        this.furtherLimitForm.setValue(filter);
    }

    private _buildForm() {
        this.furtherLimitForm = new UntypedFormGroup({
            overrideFilterColumns: this.selectedOverrides,
            invalidCostAllocations: this.invalidCostAllocations,
            noReportingParcel: this.noReportingParcel
        });
        this.furtherLimitForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this._formChanged());
    }

    private _formChanged(): void {
        const option: Compliance.NameValuePair<AssetLimitOption> = _.find(this.limitOptions,
            (x, i) => {
                this.furtherLimitForm.value.overrideFilterColumns.sort();
                x.value.overrideFilterColumns.sort();
                // By default, "Custom" is the same as "Nothing selected", so if they picked custom don't auto-set it to nothing selected
                if (i === 0 && this.selectedOption.value === this.limitOptions[this.limitOptions.length - 1]) {
                    return false;
                }
                return _.isEqual(this.furtherLimitForm.value, x.value);
            });
        this.selectedOption.setValue((option) ? option : this.limitOptions[this.limitOptions.length - 1]);
    }
}
