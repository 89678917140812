import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

export interface ICellRendererParamsForExpandCellHeaderParams extends IHeaderParams {
    expandAll: (params: ICellRendererParamsForExpandCellHeaderParams) => void;
    collapseAll: (params: ICellRendererParamsForExpandCellHeaderParams) => void;
    canExpandAll: (params: ICellRendererParamsForExpandCellHeaderParams) => boolean;
    canCollapseAll: (params: ICellRendererParamsForExpandCellHeaderParams) => boolean;
    headerText: string;
}

@Component({
    selector: 'expand-cell-header',
    template: `
<span class="expand-controls">
    <span class="fa node-expanded" *ngIf="params.canCollapseAll(params)" (click)="params.collapseAll(params)" title="Collapse all"></span>
    <span class="fa node-collapsed" *ngIf="params.canExpandAll(params)" (click)="params.expandAll(params)" title="Expand all"></span>
</span> {{params.headerText}}`
})
export class ExpandCellHeaderRendererComponent implements IHeaderAngularComp {
    params: ICellRendererParamsForExpandCellHeaderParams;

    agInit(params: ICellRendererParamsForExpandCellHeaderParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
