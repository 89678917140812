import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase } from '../../AgGrid';
import { AgGridDataSourceResult } from '../../AgGrid';
import { AllocationRepository } from '../../Repositories';

export interface AllocationListDataSourceParams {
    includeCompleted: boolean;
    companyId: number;
}

export class AllocationListAgGridDataSource extends AgGridDataSourceBase<AllocationListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _allocationRepository: AllocationRepository,
        protected _dataSourceParamsFn: () => AllocationListDataSourceParams
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.AllocationPropertyEnum>[] = [
        { name: 'description', value: Compliance.AllocationPropertyEnum.Description },
        { name: 'companyName', value: Compliance.AllocationPropertyEnum.Company },
        { name: 'taxYear', value: Compliance.AllocationPropertyEnum.TaxYear },
        { name: 'stateAbbr', value: Compliance.AllocationPropertyEnum.State },
        { name: 'siteProperty', value: Compliance.AllocationPropertyEnum.SiteName },
        { name: 'siteNumber', value: Compliance.AllocationPropertyEnum.SiteNumber }
    ];

    async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Compliance.AllocationSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap)
        };

        const result = await lastValueFrom(this._allocationRepository.getList(this._dataSourceParams.companyId, searchParams));

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<{ allocationId: number, isLockedOrCompleted: boolean; }>> {
        return null;
    }

    getSearchParamsWithoutPagination(): Compliance.AllocationSearchModel {
        return {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            includeCompleted: this._dataSourceParams.includeCompleted
        } as Compliance.AllocationSearchModel;
    }

    protected canGetRows(): boolean {
        return (this._dataSourceParams.companyId !== null);
    }

}
