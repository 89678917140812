<div class="container">
  <div class="single-column-layout">
    <h1>Import Assessments</h1>

    <div *ngIf="!isImporting && !userCancelled">
      <div class="mb-3">
        <label>State:</label>
        <select [(ngModel)]="payload.stateID" (change)="stateSelected()" class="form-select form-select-sm" style="width: auto;">
          <option [ngValue]="null" disabled>Select a State</option>
          <option *ngFor="let state of states" [ngValue]="state.stateID">{{state.abbr}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label>Assessor:</label>
        <select [(ngModel)]="payload.assessorID" class="form-select form-select-sm" style="width: auto;" [disabled]="!payload.stateID">
          <option [ngValue]="null" disabled>Select an Assessor</option>
          <option *ngFor="let assessor of assessors" [ngValue]="assessor.assessorID">{{assessor.abbr}} {{assessor.municipalityName}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label>Year:</label>
        <select [(ngModel)]="payload.annualYear" class="form-select form-select-sm" style="width: auto;">
          <option *ngFor="let year of years" [value]="year">{{year}}</option>
        </select>
      </div>

      <div class="mb-3">
        <label>Comment:</label>
        <textarea class="form-control" style="width: 50%" rows="5" [(ngModel)]="payload.comment"></textarea>
      </div>

      <input type='file' (change)="fileChanged($event)" accept=".txt">

      <button class="ace-btn btn-primary" (click)="startImporting()" style="margin-top: 15px;" [disabled]="!payload.assessorID || !fileLines">Import</button>
    </div>

    <div *ngIf="isImporting" class="text-center">
      <h3 *ngIf="initIndex < fileLines.length" class="repeating-ellipses text-start">Reading File</h3>
      <h3 *ngIf="initIndex >= fileLines.length" class="text-start">Done</h3>
      <progressbar class="active" [class.progress-striped]="initIndex < fileLines.length" [max]="fileLines.length - 1" [value]="initIndex - 1" type="info"></progressbar>
      <h3>{{successfulImportCount}} / {{fileLines.length - 1}} Parcels Successfully Imported</h3>

      <div style="margin-top: 20px;">
        <button *ngIf="initIndex < fileLines.length" class="ace-btn btn-primary btn-danger" (click)="cancelImport()">Cancel</button>
        <button *ngIf="initIndex >= fileLines.length" class="ace-btn btn-primary" (click)="initValues()">OK</button>
      </div>
    </div>

    <div *ngIf="userCancelled" class="text-center">
      <h3>Cancelling...</h3>
      <i class="fa fa-spinner fa-pulse fa-5x" style="margin-top: 50px;"></i>
    </div>
  </div>
</div>
