import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BulkUpdateDynamicBase, BulkUpdateOption } from '../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../Busy-Indicator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { IncomeStatementRepository } from '../incomeStatement.repository';

export interface IncomeStatementBulkUpdateParams {
    companyId: number;
    siteId?: number;
    selection: Compliance.SelectedRowsModel;
    selectedCount: number;
    endDate?: Date;
}

@Component({
    selector: 'income-statement-bulk-update',
    templateUrl: '../../../UI-Lib/Bulk-Update/bulkUpdateDynamicBase.component.html',
})
export class IncomeStatementBulkUpdateComponent extends BulkUpdateDynamicBase<IncomeStatementBulkUpdateParams, any> implements OnInit, IWeissmanModalComponent<IncomeStatementBulkUpdateParams, boolean>{
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _incomeStatementRepository: IncomeStatementRepository,
        _bsModalRef: BsModalRef,
        _fb: UntypedFormBuilder
    ) {
        super(_bsModalRef, _fb);
    }

    @ViewChild('number', { static: true }) numberTemplate: ElementRef;
    @ViewChild('picklist', { static: true }) picklistTemplate: ElementRef;

    params: IncomeStatementBulkUpdateParams;
    result: boolean = false;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    isSaving: boolean = false;

    ngOnInit(): void {
        this.header = `Bulk Update ${this.params.selectedCount} Records`;

        this._templateMap = new Map([
            [Core.DescriptorFieldTypes.Number, this.numberTemplate],
            [Core.DescriptorFieldTypes.Picklist, this.picklistTemplate]
        ]);

        const isValidatedControl = new UntypedFormControl(null);
        isValidatedControl.disable();
        this.bulkUpdateForm.addControl('isValidated', isValidatedControl);

        this.updateOptions.push({
            name: 'Validate',
            isRequired: true,
            template: this._templateMap.get(Core.DescriptorFieldTypes.Picklist),
            picklist: [
                {value: true, name: 'Validated'} as Compliance.NameValuePair<boolean>,
                {value: false, name: 'Not Validated'} as Compliance.NameValuePair<boolean>
            ],
            action: Core.IncomeStatementLineBulkUpdateActionEnum.NoChange,
            hiddenField: Core.IncomeStatementLineBulkUpdateActionEnum.RemoveOverride,
            formControlName: 'isValidated',
            formControl: isValidatedControl
        } as BulkUpdateOption);

        const balanceControl = new UntypedFormControl(null);
        balanceControl.disable();
        this.bulkUpdateForm.addControl('balance', balanceControl);

        this.updateOptions.push({
            name: 'Balance',
            isRequired: true,
            template: this._templateMap.get(Core.DescriptorFieldTypes.Number),
            action: Core.IncomeStatementLineBulkUpdateActionEnum.NoChange,
            formControlName: 'balance',
            formControl: balanceControl,
            removeOptionName: 'Remove Override',
            validation: {
                maxLength: 16
            }
        } as BulkUpdateOption);
    }

    async save(): Promise<void> {
        this.isSaving = true;
        await this._bulkUpdate();
    }

    cancel() {
        this._bsModalRef.hide();
    }

    private async _bulkUpdate(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        console.log(this.updateOptions);
        const formValues = this.bulkUpdateForm.value;
        try {
            const model: Core.IncomeStatementLineBulkUpdateModel = {
                selectedRows: this.params.selection,
                siteId: this.params.siteId,
                endDate: this.params.endDate,
                isValidatedAction: this.updateOptions[0].action,
                isValidated: formValues['isValidated'],
                balanceAction: this.updateOptions[1].action,
                balance: formValues['balance']
            };

            this.result = await lastValueFrom(this._incomeStatementRepository.bulkUpdate(this.params.companyId, model));

            this.result = true;
            this._bsModalRef.hide();
        } finally {
            this.isSaving = false;
            await busyRef.hide();
        }
    }
}
