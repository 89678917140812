import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Constants, TaxAuthorityStatuses } from '../../../constants.new';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import {
    AvailableTaxRateArea,
    AvailableTaxAuthority,
    ParcelTaxRateService,
    TaxAuthorityRate,
    TaxRateDetails,
    TaxAuthorityRateDetails,
    TaxSetupMinimumDTO,
    FirstEncounterSaveResult
} from './parcelTaxRateService';
import { ToastrService } from 'ngx-toastr';
import { TaxRatesService } from '../../../Assessor-Collector/Tax-Rates/tax.rates.service';
import { Subscriber } from 'rxjs';
import * as _ from 'lodash';
import { Bill } from '../../../Annual-Details/Taxes/bill.model';
import * as moment from 'moment-timezone';

export class BillClusterTaxRateSetupModalResult {
    taxRateAreaId: number;
    taxAuthorityIds: number[];
    rowVersion: string;
}

@Component({
    templateUrl: './billClusterTaxRateSetupModal.component.html'
})
export class BillClusterTaxRateSetupModalComponent implements OnInit {
    constructor(
        private bsModalRef: BsModalRef,
        private toastr: ToastrService,
        private parcelTaxRateService: ParcelTaxRateService,
        private taxRatesService: TaxRatesService,
        private messageBoxService: MessageBoxService,
        public constants: Constants
    ) { }

    @ViewChild('taxRateAreaInput')
    private taxRateAreaInputRef: ElementRef;
    // loadingAll indicates the whole modal should be hidden with a loading indicator; loading just means
    // disable the controls temporarily while additional data is coming in
    loadingAll: boolean;
    loading: boolean;
    availableTaxRateAreas: AvailableTaxRateArea[];
    availableTaxAuthorities: AvailableTaxAuthority[];
    filteredAvailableTaxAuthorities: AvailableTaxAuthority[];
    model: TaxRateDetails;
    billClusterId: number;
    billClusterRowVersion: string;
    groupByCategory: boolean;
    currentTaxAuthorityGroups: { taxAuthorityCategoryId: number, categoryName: string, taxAuthorities: TaxAuthorityRateDetails[] }[];
    title: string;
    billEstimated: boolean;
    totalEstimated: boolean;
    newTaxAuthorityId: string;
    modalResultSubscriber: Subscriber<BillClusterTaxRateSetupModalResult>;
    readOnly: boolean;
    isDocumentProcessing: boolean;
    overrideSetup: BillClusterTaxRateSetupModalResult;
    processingParcelTaxSetup: TaxSetupMinimumDTO;
    processingBill: Bill;
    firstEncounterTaxAuthority: TaxAuthorityRateDetails;
    intakeAttachmentId: string;
    hasProcessingChanges: boolean;
    calcProjected: boolean;
    // I'd like to use Angular forms handling for a dirty flag, but it seems this works much better
    // for reactive forms than it does for template-driven forms. For now, just use a flag.
    isDirty: boolean;
    firstEncounterSavedCallback: (result: FirstEncounterSaveResult) => Promise<void>;

    async ngOnInit() {
        this.loadingAll = true;
        this.newTaxAuthorityId = '';
        this.firstEncounterTaxAuthority = null;
        this.groupByCategory = this.taxRatesService.getIsGroupByEnabled();

        try {
            // The model is usually determined from the bill cluster, however document processing allows for an
            // overrideSetup object. If such an object is present, get the model based on the tax rate area, tax
            // authorities, or from the parcel collector as appropriate.
            let modelPromise: Promise<TaxRateDetails>;
            if (!this.overrideSetup) {
                // Another wrinkle, it's possible to change the parcel setup on document processing before
                // opening this modal. In that case, the API will have the actual saved values, and we might
                // need the intermediate values (saved in processingParcelTaxSetup). getTaxDetailsForParent
                // automatically adjusts for this, but in this case use special handling to re-fetch the values
                // if this is synched to the parcel.
                modelPromise = new Promise(async (res) => {
                    let details = await this.getCurrentTaxDetails();
                    if (this.processingParcelTaxSetup && details.usingParentRates) {
                        details = await this.getTaxDetailsForParent();
                    }
                    res(details);
                });
            }
            else if (this.overrideSetup.taxRateAreaId) {
                modelPromise = this.getTaxDetailsByRateArea(this.overrideSetup.taxRateAreaId);
            }
            else if (this.overrideSetup.taxAuthorityIds && this.overrideSetup.taxAuthorityIds.length > 0) {
                modelPromise = this.getTaxDetailsByAuthorities(this.overrideSetup.taxAuthorityIds);
            }
            else {
                modelPromise= this.getTaxDetailsForParent();
            }

            [this.availableTaxAuthorities, this.availableTaxRateAreas, this.model] = await Promise.all([
                this.parcelTaxRateService.getAvailableTaxAuthoritiesForBillCluster(this.billClusterId),
                this.parcelTaxRateService.getAvailableTaxRateAreasForBillCluster(this.billClusterId),
                modelPromise
            ]);

            this.onModelChange(true);
            this.isDirty = false;
        }
        finally {
            this.loadingAll = false;
        }
    }

    onTaxRateAreaTextChange() {
        if (this.taxRateAreaInputRef.nativeElement.value == '') {
            this.clearSelectedTaxRateAreaId();
        }
        else {
            this.taxRateAreaInputRef.nativeElement.value = this.model.name;
        }
    }

    async onTaxRateAreaIdChange(event: TypeaheadMatch) {
        try {
            this.loadingAll = true;
            this.model = await this.getTaxDetailsByRateArea(event.item.taxRateAreaId);
            this.onModelChange();
        }
        finally {
            this.loadingAll = false;
        }
    }

    clearSelectedTaxRateAreaId() {
        Object.assign(this.model, { taxRateAreaId: null, name: null, code: null });
        this.isDirty = true;
    }

    async addNewTaxAuthority(newAvailableTaxAuthority: AvailableTaxAuthority) {
        this.loading = true;
        try {
            const newTaxAuthority = await this.getTaxAuthority(newAvailableTaxAuthority.taxAuthorityId);
            if (newTaxAuthority) {
                this.model.taxAuthorities.push(newTaxAuthority);
                await this.refetchTaxDetailsForAuthorities();
                this.onModelChange();
            }
        }
        finally {
            this.loading = false;
        }
    }

    async removeTaxAuthority(taxAuthority: TaxAuthorityRate) {
        this.model.taxAuthorities = this.model.taxAuthorities.filter(a => a.taxAuthorityId !== taxAuthority.taxAuthorityId);
        await this.refetchTaxDetailsForAuthorities();
        this.onModelChange();
    }

    async refetchTaxDetailsForAuthorities() {
        // Re-fetch the Tax Details for all Tax Authorities to get totals re-computed.
        const taxAuthorityIds = this.model.taxAuthorities.map(a => a.taxAuthorityId);
        this.model = await this.getTaxDetailsByAuthorities(taxAuthorityIds);
    }

    async refreshFromModel() {
        if (this.model.usingParentRates) {
            this.model = await this.getTaxDetailsForParent();
        }
        else if (this.model.taxRateAreaId) {
            this.model = await this.getTaxDetailsByRateArea(this.model.taxRateAreaId);
        }
        else {
            this.model = await this.getTaxDetailsByAuthorities(this.model.taxAuthorities ? this.model.taxAuthorities.map(a => a.taxAuthorityId) : []);
        }
        this.onModelChange(true);
    }

    onModelChange(skipDirtySet?: boolean) {
        if (!skipDirtySet) {
            this.isDirty = true;
        }
        this.filteredAvailableTaxAuthorities = _.sortBy(this.availableTaxAuthorities.filter(avail =>
            !this.model.taxAuthorities.some(a => avail.taxAuthorityId === a.taxAuthorityId)),
            [function(ta) { return ta.name.toLowerCase(); }]);
        this.totalEstimated = this.model.taxAuthorities.some(ta => ta.taxAuthorityStatusId !== TaxAuthorityStatuses.Actual);
        if (this.groupByCategory) {
            const distinctCategoryIds = [...new Set(this.model.taxAuthorities.map(ta => ta.taxAuthorityCategoryId))];
            this.currentTaxAuthorityGroups = _.chain(distinctCategoryIds)
                .map(c => {
                    return {
                        taxAuthorityCategoryId: c,
                        categoryName: c ? this.constants.TaxAuthorityCategories[c].displayName : null,
                        taxAuthorities: this.model.taxAuthorities
                            .filter(ta => ta.taxAuthorityCategoryId == c)
                            .sort((x, y) => x.name.localeCompare(y.name))
                    };
                })
                .sortBy('categoryName')
                .value();
        }
        else {
            this.currentTaxAuthorityGroups = [{
                taxAuthorityCategoryId: 0,
                categoryName: null,
                taxAuthorities: this.model.taxAuthorities.sort((x, y) => x.name.localeCompare(y.name))
            }];
        }
    }

    onGroupByCategoryChanged($event: boolean) {
        this.groupByCategory = $event;
        this.taxRatesService.setIsGroupByEnabled($event);
        this.onModelChange(true);
    }

    async onUsingParentRatesChanged($event: boolean) {
        let confirmed = !$event ? true : false;
        if ($event) {
            confirmed = await this.confirmSwitchToParentRates();
        }
        if (confirmed) {
            this.model.usingParentRates = $event;
            if ($event) {
                try {
                    this.loadingAll = true;
                    this.model = await this.getTaxDetailsForParent();
                }
                finally {
                    this.loadingAll = false;
                }
            }
        }
        else {
            this.model.usingParentRates = false;
        }
        this.onModelChange();
    }

    async confirmSwitchToParentRates(): Promise<boolean> {
        const result = await this.messageBoxService.open({
            message: 'Are you sure you want to discard this configuration and Sync to parcel\'s Collector defaults?',
            buttons: MessageBoxButtons.OKCancel
        });

        return result === MessageBoxResult.OK;
    }

    async closeModal() {
        let shouldClose = true;

        if (this.isDirty) {
            const confirmResult = await this.messageBoxService.open({
                message: 'You have unsaved changes; are you sure you wish to leave?',
                buttons: MessageBoxButtons.OKCancel
            });

            shouldClose = confirmResult === MessageBoxResult.OK;
        }

        if (shouldClose) {
            this.bsModalRef.hide();
        }
    }

    async save() {
        if (!this.isDirty) {
            this.bsModalRef.hide();
            return;
        }

        this.loadingAll = true;
        try {
            const modalResult = await this.getModalResult();
            this.modalResultSubscriber.next(modalResult);
            this.modalResultSubscriber.complete();
            this.bsModalRef.hide();
            if (!this.isDocumentProcessing) {
                this.toastr.success('Bill Cluster Tax Rate Setup Saved');
            }
        }
        catch (err) {
            this.loadingAll = false;
            throw err;
        }
    }

    onNewTaxAuthorityChange(newTaxAuthorityId: string) {
        this.newTaxAuthorityId = '';
        if (newTaxAuthorityId) {
            this.addNewTaxAuthority(this.availableTaxAuthorities.find(ta => ta.taxAuthorityId == +newTaxAuthorityId));
        }
    }

    async getTaxAuthority(taxAuthorityId: number) {
        return (await this.getTaxDetailsByAuthorities([taxAuthorityId])).taxAuthorities[0];
    }

    async getCurrentTaxDetails() {
        return await this.parcelTaxRateService.getTaxRateDetails(this.billClusterId, this.processingBill);
    }

    async getTaxDetailsByAuthorities(taxAuthorityIds: number[]) {
        return await this.parcelTaxRateService.searchTaxRateDetails(
            this.billClusterId,
            null,
            taxAuthorityIds,
            this.processingBill);
    }

    async getTaxDetailsByRateArea(taxRateAreaId: number) {
        return await this.parcelTaxRateService.searchTaxRateDetails(
            this.billClusterId,
            taxRateAreaId,
            null,
            this.processingBill);
    }

    async getTaxDetailsForParent() {
        let result: TaxRateDetails;

        if (!this.processingParcelTaxSetup) {
            result = await this.parcelTaxRateService.getCollectorTaxRateDetails(this.billClusterId, this.processingBill);
        }
        else {
            if (this.processingParcelTaxSetup.taxRateAreaId) {
                result = await this.getTaxDetailsByRateArea(this.processingParcelTaxSetup.taxRateAreaId);
            }
            else if (this.processingParcelTaxSetup.taxAuthorities && this.processingParcelTaxSetup.taxAuthorities) {
                result = await this.getTaxDetailsByAuthorities(this.processingParcelTaxSetup.taxAuthorities.map(ta => ta.taxAuthorityId));
            }
            else {
                result = await this.getTaxDetailsByAuthorities([]);
            }
            result.usingParentRates = true;
        }

        return result;
    }

    showFirstEncounter(taxAuthority: TaxAuthorityRateDetails) {
        return this.isDocumentProcessing && taxAuthority && taxAuthority.taxAuthorityStatusId === TaxAuthorityStatuses.Estimated;
    }

    startFirstEncounter(taxAuthority: TaxAuthorityRateDetails) {
        if (this.isDocumentProcessing) {
            this.firstEncounterTaxAuthority = taxAuthority;
        }
    }

    async endFirstEncounter(reload: boolean, result?: FirstEncounterSaveResult) {
        this.firstEncounterTaxAuthority = null;
        if (reload) {
            this.loading = true;
            try {
                result.updateVersion = this.hasProcessingChanges && !this.calcProjected;
                if (this.isDirty) {
                    result.inProcessTaxSetupChanges = await this.getModalResult();
                }
                await this.firstEncounterSavedCallback(result);
                if (!result.updateVersion) {
                    this.bsModalRef.hide();
                }
                else {
                    await this.refreshFromModel();
                }
            }
            finally {
                this.loading = false;
            }
        }
    }

    rateClass(taxAuthority: TaxAuthorityRateDetails) {
        switch (taxAuthority.taxAuthorityStatusId) {
            case TaxAuthorityStatuses.Estimated:
                return 'estimated';
            case TaxAuthorityStatuses.Pending:
                return 'pending';
            default:
                return '';
        }
    }

    taTooltip(taxAuthority: TaxAuthorityRateDetails) {
        let result: string = null;
        if (taxAuthority.taxAuthorityStatusId === TaxAuthorityStatuses.Pending) {
            if (taxAuthority.qcRequestTimeUtc) {
                result = `QC requested at ${  moment(taxAuthority.qcRequestTimeUtc).tz('America/Chicago').format('M/D/Y h:mm a')}`;
                if (taxAuthority.qcRequestUserFullName) {
                    result += ` by ${  taxAuthority.qcRequestUserFullName}`;
                }
            }
        }

        return result;
    }

    taxableAssessmentTypeName(taxableAssessmentTypeId: number) {
        if (!taxableAssessmentTypeId) {
            return null;
        }
        const taxableAssessmentType = this.constants.TaxableAssessmentTypes[taxableAssessmentTypeId];
        if (!taxableAssessmentType) {
            return null;
        }
        return taxableAssessmentType.displayName;
    }

    taxAuthorityCategoryName(taxAuthorityCategoryId: number) {
        if (!taxAuthorityCategoryId) {
            return null;
        }
        const taxAuthorityCategory = this.constants.TaxAuthorityCategories[taxAuthorityCategoryId];
        if (!taxAuthorityCategory) {
            return null;
        }
        return taxAuthorityCategory.displayName;
    }

    private async getModalResult(): Promise<BillClusterTaxRateSetupModalResult> {
        let taxRateAreaId: number = null;
        let taxAuthorityIds: number[] = [];
        // If we're using parent rates, clear out all existing entries. Otherwise, prefer the
        // taxRateAreaId if it exists, falling back to the model's tax authorities array otherwise.
        if (!this.model.usingParentRates) {
            if (this.model.taxRateAreaId) {
                taxRateAreaId = this.model.taxRateAreaId;
            }
            else {
                taxAuthorityIds = this.model.taxAuthorities.map(ta => ta.taxAuthorityId);
            }
        }

        let modalResult = {
            rowVersion: <string>null,
            taxRateAreaId,
            taxAuthorityIds
        };

        if (!this.isDocumentProcessing) {
            const { billClusterRowVersion } = await this.parcelTaxRateService.saveTaxRateDetails(
                this.billClusterId,
                this.billClusterRowVersion,
                taxRateAreaId,
                taxAuthorityIds
            );
            modalResult = {
                rowVersion: billClusterRowVersion,
                taxRateAreaId,
                taxAuthorityIds
            };
        }

        return modalResult;
    }
}
