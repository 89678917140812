import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForUserGroupsGridAction extends ICellRendererParams {
    hasEditPermission: () => boolean;
    viewDetails: (params: ICellRendererParamsForUserGroupsGridAction) => void;
    delete: (params: ICellRendererParamsForUserGroupsGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button" class="ace-btn btn-primary has-icon grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i *ngIf="hasEditPermission" class="fa fa-pencil"></i>
            <i *ngIf="!hasEditPermission" class="fa fa-eye"></i>
        </button>
        <button *ngIf="hasEditPermission" type="button" class="ace-btn has-icon btn-primary btn-danger grid-action-button" title="Delete" (click)="onDeleteClicked()">
            <i class="fa fa-trash"></i>
        </button>`
})
export class UserGroupsGridActionCellRendererComponent implements ICellRendererAngularComp {
    userGroupGridItem: Core.GroupModel;
    hasEditPermission: boolean;
    private _params: ICellRendererParamsForUserGroupsGridAction;

    agInit(params: ICellRendererParamsForUserGroupsGridAction): void {
        this._params = params;
        this.hasEditPermission = params.hasEditPermission();
        this.userGroupGridItem = params.data as Core.GroupModel;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }

    onDeleteClicked(): void {
        this._params.delete(this._params);
    }
}
