import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseRepository } from './base.repository';
import { DataCache } from './dataCache';

export abstract class CachedRepository<T> extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected dataCache: DataCache<T>;

    getCached<H>(path: string): Observable<H> {
        return this.dataCache.getCached(path);
    }
}
