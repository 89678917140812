<div [style.display]="dataLoading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
    <i class="fa fa-spinner fa-pulse fa-5x"></i>
</div>
<div class="container" *ngIf="!dataLoading">
    <div class="single-column-layout">
        <h1>Teams</h1>
        <table class="roles table table-striped table-hover table-condensed" style="line-height: 1.428">
            <tr>
                <th style="width: 40%; text-transform: uppercase;">Team Name</th>
                <th style="width: 42%; text-transform: uppercase;">Practice Area</th>
                <th style="width: 10%; text-transform: uppercase;">Cost Center</th>
                <th></th>
            </tr>
            <tr [class.row-error]="team.isEditing && showUniqueTeamNameError" *ngFor="let team of teams">
                <td [class.has-error]="team.isEditing && showUniqueTeamNameError">
                    <span *ngIf="!team.isEditing">
                        {{ team.name}}
                    </span>
                    <input type="text"
                           class="form-control"
                           [(ngModel)]="team.name"
                           *ngIf="team.isEditing"
                           required
                           maxlength="100" />
                    <span class="text-danger" *ngIf="team.isEditing && showUniqueTeamNameError">Must be unique</span>
                </td>
                <td>
                    <span *ngIf="!team.isEditing">
                        {{ team.practiceArea}}
                    </span>
                    <input type="text" class="form-control" [(ngModel)]="team.practiceArea"
                        *ngIf="team.isEditing" maxlength="100" />
                </td>
                <td>
                    <span *ngIf="!team.isEditing">
                        {{ team.costCenter}}
                    </span>
                    <input type="text" class="form-control" [(ngModel)]="team.costCenter"
                        *ngIf="team.isEditing" maxlength="50" />
                </td>

                <td style="white-space: nowrap">
                    <span>&nbsp;
                        <span *ngIf="hasTeamEditPermissions">
                            <span class="" *ngIf="team.isEditing">
                                <button type="button" class="ace-btn has-icon btn-primary btn-success" tooltip="Save"
                                    (click)="saveTeam(team)" [disabled]="!team.name  || team.name.length === 0">
                                    <i class="fa fa-save"></i>
                                </button>
                                <button type="button" (click)="cancelEdit(team)"
                                    class="ace-btn has-icon btn-primary btn-danger" tooltip="Cancel">
                                    <i class="fa fa-close"></i>
                                </button>
                            </span>
                            <span class="buttons" *ngIf="!team.isEditing">
                                <button class="ace-btn has-icon btn-primary" (click)="enableEdit(team)"
                                    tooltip="Edit" [disabled]="editMode">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button *ngIf="!team.isDeleting" class="ace-btn has-icon btn-primary btn-danger"
                                    (click)="deleteTeam(team, unableToDeleteTemplate)" tooltip="Delete" [disabled]="editMode">
                                    <i class="fa fa-trash"></i>
                                </button>
                                <i *ngIf="team.isDeleting" class="fa fa-spinner fa-pulse"></i>
                            </span>
                        </span>
                    </span>
                </td>
            </tr>
        </table>
        <button class="ace-btn btn-primary" (click)="addTeam()" [disabled]="editMode"
            *ngIf="hasTeamEditPermissions">Add Team</button>
    </div>
</div>

<ng-template #unableToDeleteTemplate>
    <div class="modal-header">
        <h4 class="modal-title">Unable to Delete Team</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="unableToDeleteRef.hide()"></button>
    </div>
    <div class="modal-body">
        <div [style.display]="teamMembersLoading ? 'block' : 'none'" class="text-center" style="margin-top: 50px;">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div *ngIf="!teamMembersLoading">
            Cannot delete a team with existing users. To delete, please remove the following users from this team:
            <ul>
                <li *ngFor="let user of teamMembers">
                    {{user.firstName}} {{user.lastName}}
                </li>
            </ul>
        </div>
    </div>
</ng-template>
