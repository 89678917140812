import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs'
import { UserSettingsService } from '../../Account/userSettings.service';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()
export class AssetRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) { super(httpClient) }

    private readonly _lienDateSettingName: string = 'Compliance-Assets-LienDate';
    private readonly _comparisonDateSettingName: string = 'Compliance-Assets-ComparisonDate';
    private readonly _comparisonDateTypeSettingName: string = 'Compliance-Assets-ComparisonDateType';

    getSourceAssetList(companyId: number, searchParams: Compliance.AssetSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>> {
        return this.httpPost(`/api/asset/company/${companyId}/SourceAssets`, searchParams);
    }

    getReportingAssetList(companyId: number, searchParams: Compliance.AssetSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>> {
        return this.httpPost(`/api/asset/company/${companyId}/ReportingAssets`, searchParams);
    }

    getBulkUpdateMetadata(companyId: number, metadataParams: Compliance.ReportingAssetBulkUpdateMetadataModel): Observable<Compliance.ReportingAssetBulkUpdateSelectionMetadataModel> {
        return this.httpPost(`/api/asset/company/${companyId}/BulkUpdateMetadata`, metadataParams);
    }

    startReportingAssetExport(companyId: number, exportModel: Compliance.AssetExportModel): Observable<number> {
        return this.httpPost(`/api/asset/company/${companyId}/reportingAssets/export`, exportModel);
    }

    startSourceAssetExport(companyId: number, exportModel: Compliance.AssetExportModel): Observable<number> {
        return this.httpPost(`/api/asset/company/${companyId}/sourceAssets/export`, exportModel);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/asset/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    getReportingAssetRowIds(companyId: number, searchParams: Compliance.AssetSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/asset/company/${companyId}/ReportingAssetRowIds`, searchParams);
    }

    get(assetId: number, lienDate: Date, includeTotals: boolean = false, includeChangeDates: boolean = false, includeAssetDescriptors: boolean = false,
        returnDisposed: boolean = false):
        Observable<{assetDetailModel : Compliance.AssetDetailModel, assetSearchTotalsModel : Compliance.AssetSearchTotalsModel, changeDates: Date[],
            minLienDate?: Date, maxLienDate?: Date, assetDescriptors?: Compliance.CompanyAssetDescriptorMappingModel[]}> {

        const lienDateParam = `lienDate=${lienDate.getUTCMonth() + 1}/${lienDate.getUTCDate()}/${lienDate.getUTCFullYear()}`;
        const includeTotalsParam = `includeTotals=${includeTotals ? "true": "false"}`;
        const includeChangeDatesParam = `includeChangeDates=${includeChangeDates ? "true" : "false"}`;
        const includeAssetDescriptorsParam = `includeAssetDescriptors=${includeAssetDescriptors ? "true" : "false"}`;
        const returnDisposedParam = `returnDisposed=${returnDisposed ? 'true' : 'false'}`;

        return this.httpGet(`/api/asset/${assetId}?${lienDateParam}&${includeTotalsParam}&${includeChangeDatesParam}&${includeAssetDescriptorsParam}&${returnDisposedParam}`)
        .pipe(
            map((y: {item1: Compliance.AssetDetailModel, item2: Compliance.AssetSearchTotalsModel, item3: Date[], item4?: Date, item5?: Date,
                item6?: Compliance.CompanyAssetDescriptorMappingModel[]}) =>
                ({assetDetailModel: y.item1, assetSearchTotalsModel: y.item2, changeDates: y.item3, minLienDate: y.item4, maxLienDate: y.item5, assetDescriptors: y.item6})));
    }

    getMultiple(lienDate: Date, reportingAssetIds: number[], returnTotals: boolean): Observable<Compliance.QueryResultWithTotalsModel<Compliance.AssetModel, Compliance.AssetSearchTotalsModel>> {
        const data: Compliance.AssetGetModel = {
            lienDate: lienDate,
            reportingAssetIds: reportingAssetIds,
            returnTotals: returnTotals};
        return this.httpPost(`/api/asset/assetIds`, data);
    }

    update(assetId: number, updateModel: Compliance.AssetUpdateModel): Observable<void> {
        return this.httpPut(`/api/asset/${assetId}`, updateModel);
    }

    startBulkUpdate(companyId: number, updateModel: Compliance.ReportingAssetBulkUpdateModel): Observable<number> {
        return this.httpPut(`/api/asset/company/${companyId}/startBulkUpdate/`, updateModel);
    }

    getReturns(assetId: number): Observable<Compliance.AssetReturnModel[]> {
        return this.httpGet(`/api/asset/${assetId}/returns`);
    }

    getActivityLog(reportingAssetId: number): Observable<Compliance.SourceAssetVerificationLogModel[]> {
        return this.httpGet(`/api/asset/${reportingAssetId}/activitylog`);
    }

    getLienDates(lienDateRequestModel: Compliance.AssetLienDateRequestModel): Observable<Compliance.AssetLienDateModel> {
        return this.httpPost(`/api/asset/company/${lienDateRequestModel.companyId}/lienDates`, lienDateRequestModel);
    }

    getLienDateUserSetting(): Date {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.Assets);
        const setting = _.find(settings, (x: any) => x.name === this._lienDateSettingName);
        const lienDate = setting ? new Date(setting.value) : new Date();
        return lienDate;
    }

    async updateLienDateUserSetting(lienDate: Date): Promise<void> {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.Assets);
        const setting = settings.find(x => x.name === this._lienDateSettingName);
        const userSetting: any = {
            id: setting ? setting.id : 0,
            name: this._lienDateSettingName,
            value: lienDate,
            groupId: Core.UserSettingGroup.Assets,
            folderId: Core.UserSettingFolder.Default
        };
        await this._userSettingsService.save(userSetting);
    }

    getComparisonDateUserSetting(): Date {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.AssetsComparisonLienDate);
        const setting = _.find(settings, (x: any) => x.name === this._comparisonDateSettingName);
        var comparisonDate = setting && setting.value ? new Date(setting.value) : null;

        return comparisonDate;
    }

    async updateComparisonDateUserSetting(comparisonDate: Date): Promise<void> {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.AssetsComparisonLienDate);
        const setting = settings.find(x => x.name === this._comparisonDateSettingName);

        const userSetting: any = {
            id: setting ? setting.id : 0,
            name: this._comparisonDateSettingName,
            value: comparisonDate,
            groupId: Core.UserSettingGroup.AssetsComparisonLienDate,
            folderId: Core.UserSettingFolder.Default
        };

        await this._userSettingsService.save(userSetting);
    }

    getComparisonDateTypeUserSetting(): Compliance.ComparisonLienDateTypeEnum {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.AssetsComparisonLienDateType);
        const setting = _.find(settings, (x: any) => x.name === this._comparisonDateTypeSettingName);
        const comparisonDateType = setting ? setting.value : Compliance.ComparisonLienDateTypeEnum.OneYearAgo;
        return comparisonDateType;
    }

    async updateComparisonDateTypeUserSetting(comparisonDateType: Compliance.ComparisonLienDateTypeEnum): Promise<void> {
        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.AssetsComparisonLienDateType);
        const setting = settings.find(x => x.name === this._comparisonDateTypeSettingName);
        const userSetting: any = {
            id: setting ? setting.id : 0,
            name: this._comparisonDateTypeSettingName,
            value: comparisonDateType,
            groupId: Core.UserSettingGroup.AssetsComparisonLienDateType,
            folderId: Core.UserSettingFolder.Default
        };
        await this._userSettingsService.save(userSetting);
    }

    createUserCreatedAsset(addModel: Compliance.UserCreatedAssetAddModel): Observable<{assetDetailsModel: Compliance.AssetModel, minLienDate: Date}> {
        return this.httpPost('/api/asset/userCreated', addModel)
            .pipe(map((x: {item1: Compliance.AssetModel, item2: Date}) => ({assetDetailsModel: x.item1, minLienDate: x.item2})));
    }

    getOwnershipTypes(): Observable<Compliance.AssetOwnershipTypeModel[]> {
        return this.httpGet('/api/asset/ownershipTypes');
    }

    getLeaseTypes(): Observable<Compliance.LeaseTypeModel[]> {
        return this.httpGet('/api/asset/leaseTypes');
    }

    getVerificationReasons(): Observable<string[]> {
        return this.httpGet('/api/asset/verificationReasons');
    }

    startBulkDelete(companyId: number, model: Compliance.AssetBulkDeleteModel): Observable<number> {
        return this.httpPut(`/api/asset/company/${companyId}/startBulkDelete/`, model);
    }
}
