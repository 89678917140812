import { Component, OnInit, OnDestroy } from '@angular/core';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { ReturnService } from '../../../return.service';
import { ReturnSettingsService } from '.././returnSettings.service';
import { IComponentRef } from '../../../Models/iComponentRef';
import { ReturnSettingsEntityTypeEnum } from '../../../Models/enums';
import { BusyIndicatorService } from '../../../../../Busy-Indicator';
import { WeissmanMutexService, IMutexServiceHandler } from '../../../../WeissmanMutexService';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';

@Component({
    selector: 'return-settings-override',
    templateUrl: './returnSettingsOverride.component.html',
    styleUrls: ['./returnSettingsOverride.component.scss']
})
export class ReturnSettingsOverrideComponent implements OnInit, OnDestroy, IReturnPartComponent, IMutexServiceHandler {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnSettingsService: ReturnSettingsService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _mutexService: WeissmanMutexService
    ) { }

    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;
    private _destroy$: Subject<void> = new Subject();
    private _parcelsChangeDate: Date = null;

    ReturnSettingsEntityTypeEnum = ReturnSettingsEntityTypeEnum;
    returnSettingsComponent: IComponentRef;
    hasChanges: boolean = false;
    editMode: boolean = false;
    isInitialized: boolean = false;

    get canEdit(): boolean {
        return !this._returnService.isReturnInReadOnlyMode && this._returnService.canEditCompany;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._returnService.editGroup);
    }

    ngOnInit(): void {
        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._returnService.editGroup);
            }
        });

        this._returnSettingsService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._returnSettingsService.unsubscribeFromServiceActivationCycle(this);
        this._editModeSub.unsubscribe();
        this._mutexService.release(this, this._returnService.editGroup);
        this._destroy$.next();
        this._destroy$.complete();
    }

    onReturnPartServiceActivated(): void {
        this.isInitialized = true;

        this._returnService.parcelsChanged$.pipe(takeUntil(this._destroy$)).subscribe(async (x) =>
        {
            if (x) {
                if (this._parcelsChangeDate !== x) {
                    this._parcelsChangeDate = x;
                    await this.returnSettingsComponent.load();
                }
            }
        }
        );
    }

    onReturnPartServiceDeactivated(): void {
        this._destroy$.next();
    }

    get filingBatchId(): number {
        return this._returnService.sharedState ? this._returnService.filingBatchId : null;
    }

    get returnId(): number {
        return this._returnService.sharedState && this._returnService.sharedState.returns && this._returnService.sharedState.returns.length > 0
            ? this._returnService.sharedState.returns[0].returnId
            : null;
    }

    onReturnSettingsInitialized($ref: IComponentRef) {
        this.returnSettingsComponent = $ref;
    }

    onReturnSettingsChanged(value: boolean) {
        this.hasChanges = value;
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._mutexService.acquire(this, this._returnService.editGroup);
        this._editModeSubject.next(true);

        this.returnSettingsComponent.edit();
    }

    async save(): Promise<void> {
        if (!this.returnSettingsComponent) {
            return;
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            await this.returnSettingsComponent.save();
            this._editModeSubject.next(false);
            this.hasChanges = false;
        }
        finally {
            busyRef.hide();
        }
    }

    cancel(): void {
        this._editModeSubject.next(false);
        this.returnSettingsComponent.cancel();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }
}
