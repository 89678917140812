import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { LogoutMessageComponent } from './logoutMessage.component';
import { NavigationModalsModule } from '../Navigation/User-Save/Modals/navigationModals.module';

@NgModule({
    imports: [
        WeissmanSharedModule,
        UIRouterUpgradeModule,
        NavigationModalsModule
    ],
    declarations: [
        LogoutMessageComponent
    ],
    exports: [
        LogoutMessageComponent
    ]
})
export class LayoutModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('logoutMessage', LogoutMessageComponent);
    }
}
