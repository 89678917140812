<div class="ws-flex-container-horizontal ws-flex-align-items-center">
    <ws-select [ngModel]="lienDateItem"
               (ngModelChange)="lienDateChanged($event)"
               [options]="lienDateItems"
               [valueFormatter]="formatDateOption"
               [optionStyleClass]="currentLienDate"
               [isLoading]="isLoading"
               [disabled]="isDisabled"
               [size]="size"
               [canSearch]="false"
               [isShowMoreFromParent]="true"
               [returnEntireOption]="true"
               [alignOptionText]="'right'"
               (showMoreClicked)="showMoreClicked()">
    </ws-select>
    <div class="ws-flex-container-horizontal">
        <weissman-datetime [(inputDate)]="lienDate.date"
                            [dateOnly]="true"
                            (datePickerClosed)="customLienDateChanged($event)"
                            *ngIf="lienDateItem && lienDateItem.isCustom"
                            name="customLienDate"
                            [className]="isLienDateCustom ? 'asset-lien-date-custom': ''"
                            [required]="true"
                            [isDisabled]="isDisabled"
                            id="customLienDate">
        </weissman-datetime>
    </div>
    <button type="button"
            class="ace-btn btn-sm btn-primary has-icon"
            (click)="edit()"
            *ngIf="showFilters"
            helpTooltip
            helpContentId="app.edit"
            position="bottom"
            [disabled]="isDisabled">
        <i class="fa fa-pencil"></i>
    </button>
</div>
