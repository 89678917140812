import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { RestrictService, Roles } from '../../../../Common/Permissions/restrict.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingScenarioService } from '../billingScenario.service';
import { UtilitiesService } from '../../../../UI-Lib/Utilities/Utilities.Service.upgrade';
import { ToastrService } from 'ngx-toastr';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { Subject, Subscription } from 'rxjs';
import { CollectorBillScenario, CollectorPayment } from '../billingScenario.models';
import { distinctUntilChanged } from 'rxjs/operators';

import * as moment from 'moment';
import * as _ from 'lodash';

export interface BillingScenarioModalParams {
    collector: any;
    inputScenario: any;
}

export interface BillingScenarioResult {
    remove?: number;
    scenario?: CollectorBillScenario
}

interface IdName {
    id: number;
    name: string;
}

@Component({
    selector: 'billing-scenario-modal',
    templateUrl: './billingScenarioModal.component.html',
    styleUrls: ['./billingScenarioModal.component.scss']
})
export class BillingScenarioModalComponent implements IWeissmanModalComponent<BillingScenarioModalParams, BillingScenarioResult>, OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _fb: UntypedFormBuilder,
        private readonly _billingScenarioService: BillingScenarioService,
        private readonly _utils: UtilitiesService,
        private readonly _toastr: ToastrService,
        private readonly _messageModalService: MessageModalService
    ) {}

    params: BillingScenarioModalParams;
    result: BillingScenarioResult;

    scenarioForm: UntypedFormGroup;
    collector;
    scenario: CollectorBillScenario;
    viewOnly: boolean;
    loading: boolean;

    whens: IdName[] = [{
        id: -1,
        name: 'Prior'
    }, {
        id: 0,
        name: 'Current'
    }, {
        id: 1,
        name: 'Next'
    }, {
        id: 2,
        name: 'Next Next'
    }];

    projTypes: IdName[] = [{
        id: 1,
        name: 'Proj Rate'
    }, {
        id: 2,
        name: 'Prior Tax'
    }, {
        id: 3,
        name: 'Remain Tax'
    }];

    private _oldScenario;
    private _paymentSubs: Subscription[][] = [];
    private _destroy$: Subject<void> = new Subject();

    get billNumber(): UntypedFormControl {
        return this.scenarioForm.get('billNumber') as UntypedFormControl;
    }

    get isDefault(): UntypedFormControl {
        return this.scenarioForm.get('isDefault') as UntypedFormControl;
    }

    get collectorBills(): UntypedFormArray {
        return this.scenarioForm.get('collectorBills') as UntypedFormArray;
    }

    toWhens = (fromWhen: number): IdName[] => {
        switch (fromWhen) {
            case 0:
                return this.whens.filter(x => x.id !== -1);
            case 1:
                return this.whens.filter(x => !(x.id === -1 || x.id === 0));
            case 2:
                return this.whens.filter(x => !(x.id === -1 || x.id === 0 || x.id === 1));
            default:
                return this.whens;
        }
    };

    async ngOnInit(): Promise<void> {
        this.loading = true;

        this.collector = this.params.collector;
        this.scenario = _.cloneDeep(this.params.inputScenario);
        this._oldScenario = _.cloneDeep(this.params.inputScenario);
        this.viewOnly = this._restrictService.isNotInRoles([Roles.COLLECTOREDIT]) && !this.collector.isCompanyCollector;

        if (this.scenario === undefined) {
            this.scenarioForm = this._newScenario(this.collector.collectorID);
            this.loading = false;
        } else {
            try {
                const result = await this._billingScenarioService.getInUse(this.scenario.collectorBillScenarioID);
                this._prepareScenario(result);
            } finally {
                this.loading = false;
            }
        }

        this.scenarioForm.controls['billNumber'].valueChanges.subscribe(() => this._addRemoveBills());
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    removeItem(list: UntypedFormArray, item, index: number) {
        const itemValues = item.getRawValue();
        if (itemValues.efAction === 'add') {
            list.removeAt(index);
        } else {
            item.patchValue({ efAction: 'delete' });
        }
    }

    addOption(bill: any): void {
        bill = bill as UntypedFormGroup;

        const billValues = bill.getRawValue();
        const pmtOption = this._newOption(billValues.collectorBillScenarioID, billValues.collectorBillID);
        // When adding the first Payment Option to a bill, set isDefault=true
        if (billValues.collectorPaymentOptions.length === 0) {
            pmtOption.patchValue({ isDefault: true });
        }
        (bill.get('collectorPaymentOptions') as UntypedFormArray).push(pmtOption);
    }

    addBill(): void {
        if (this.collectorBills.length === 4) {
            return;
        }

        this.scenarioForm.patchValue({ billNumber: Number(this.billNumber.value) + 1 });
    }

    removeBill(): void {
        if (this.collectorBills.length === 1) {
            return;
        }

        this.scenarioForm.patchValue({ billNumber: Number(this.billNumber.value) - 1 });
    }

    addPayment(option: UntypedFormGroup, optionIndex: number): void {
        const payments = option.get('collectorPayments') as UntypedFormArray;
        if (payments.length === 12) {
            return;
        }
        option.patchValue({ paymentNumber: payments.length + 1 });
        const payment = this._newPayment(option.get('collectorPaymentOptionID').value);
        this._watchPaymentChanges(payment, optionIndex, payments.length);
        payments.push(payment);

        if (payments.length > 1 && !this.collector.isCompanyCollector) {
            option.get('ptxDiscount').disable();
            option.get('ptxDiscount').setValue(false);
        }
    }

    removePayment(option: UntypedFormGroup, optionIndex: number): void {
        const payments = option.get('collectorPayments') as UntypedFormArray;
        if (payments.length === 0) {
            return;
        }
        const lastIndex = payments.length - 1;
        const payment = payments.at(lastIndex);
        option.patchValue({ paymentNumber: lastIndex });
        this._paymentSubs[optionIndex][lastIndex].unsubscribe();
        this._paymentSubs[optionIndex][lastIndex] = null;
        this.removeItem(payments, payment, lastIndex);

        if (payments.value.length === 1 && !!payments.value[0].discountPercent && !this.collector.isCompanyCollector) {
            option.get('ptxDiscount').enable();
        }
    }

    addRemovePayments(option: UntypedFormGroup, optionIndex: number): void {
        const optionValues = option.getRawValue();

        if (optionValues.paymentNumber < 1 || optionValues.paymentNumber > 12) {
            return;
        }

        const nonDeleted = this._utils.nonDeleted(optionValues.collectorPayments);
        const collectorPayments = option.get('collectorPayments') as UntypedFormArray;
        // Find the total change in number
        const diff = Math.abs(optionValues.paymentNumber - nonDeleted.length);
        let i;

        if (optionValues.paymentNumber > nonDeleted.length) {
            for (i = 0; i < diff; i++) {
                const payment = this._newPayment(optionValues.collectorPaymentOptionID);
                this._watchPaymentChanges(payment, optionIndex, collectorPayments.length);
                collectorPayments.push(payment);
            }
        } else {
            if (optionValues.collectorPayments.some(x => x.inUse) && optionValues.collectorPayments[optionValues.collectorPayments.length - 1].collectorPaymentID) {
                optionValues.patchValue({ paymentNumber: optionValues.paymentNumber++ });

                this._toastr.error('At least one payment is in use.', 'Cannot Remove!');
                return;
            }

            for (i = 0; i < diff; i++) {
                const removeIndex = collectorPayments.length - 1;
                this._paymentSubs[optionIndex][removeIndex].unsubscribe();
                this._paymentSubs[optionIndex][removeIndex] = null;
                collectorPayments.removeAt(removeIndex);
            }
        }
    }

    removeCollectorPaymentOption(bill: any, option: any, j: number) {
        this.removeItem(bill.get('collectorPaymentOptions'), option, j);
    }

    makeDefault(): void {
        this.collector.collectorBillScenarios = this._utils.nonDeleted(this.collector.collectorBillScenarios);
        this.collector.collectorBillScenarios.forEach((scenario) => {
            scenario.isDefault = false;
        });

        this.scenarioForm.patchValue({ isDefault: true });
    }

    async save(): Promise<void> {
        const scenario = this.scenarioForm.getRawValue();

        if (!this.validateOnSave(scenario)) {
            return;
        }

        const scenarioToSave = this._prepare(scenario);

        if (!scenarioToSave) {
            return;
        }

        try {
            let result;
            if (scenario.efAction === 'add') {
                result = await this._billingScenarioService.addScenario(scenarioToSave);
            } else {
                result = await this._billingScenarioService.updateScenario(scenarioToSave);
            }

            this.result = { scenario: result };
            this._bsModalRef.hide();
        } catch (err) {
            const action = scenario.efAction === 'add' ? 'adding' : 'updating';
            this._toastr.error(`There was an error ${action} the billing scenario.`);
        }
    }

    async deleteScenarioModal(): Promise<void> {
        const scenario = this.scenarioForm.getRawValue();
        try {
            await this._messageModalService.confirm(`Are you sure you want to delete ${scenario.name}?`);
        } catch (err) {
            return;
        }

        try {
            await this._billingScenarioService.deleteScenario(scenario.collectorBillScenarioID);

            this.result = { remove: scenario.collectorBillScenarioID };
            this._bsModalRef.hide();
        } catch (err) {
            this._toastr.error('There was an error attempting to delete this scenario.');
        }
    }

    undefaultOthers(bill, newDefault: UntypedFormGroup, i) {
        const list = bill.get('collectorPaymentOptions') as UntypedFormArray;
        list.controls.forEach(x => {
            if (x.value.efAction !== 'delete') {
                x.patchValue({ isDefault: false });
            }
        });

        newDefault.patchValue({ isDefault: true });
    }

    undiscountOthers(bill, newDiscount: UntypedFormGroup, event: any) {
        const ptxDiscount = event.target.checked;

        const list = bill.get('collectorPaymentOptions') as UntypedFormArray;
        list.controls.forEach(x => {
            if (x.value.efAction !== 'delete') {
                x.patchValue({ ptxDiscount: false });
            }
        });

        newDiscount.patchValue({ ptxDiscount });
    }

    discountPercentChanged(option: UntypedFormGroup, event: any) {
        if(this.collector.isCompanyCollector) {
            return;
        }

        const ptxDiscount = option.get('ptxDiscount');
        const payments = option.get('collectorPayments') as UntypedFormArray;

        if(event.target.value && payments.length === 1) {
            ptxDiscount.enable();
        } else {
            ptxDiscount.disable();
            ptxDiscount.setValue(false);
        }
    }

    validateOnSave(scenario) {
        // Verify that at least one Bill is configured for the Bill Scenario.
        if (this._utils.nonDeleted(scenario.collectorBills).length < 1) {
            this._toastr.error('At least one Bill must be configured for a Bill Scenario.', 'Cannot Save!');
            return false;
        }

        return this._utils.nonDeleted(scenario.collectorBills).every((bill) => {

            // Verify that at least one Payment Option is configured for each Bill.
            if (this._utils.nonDeleted(bill.collectorPaymentOptions).length < 1) {
                this._toastr.error('At least one Payment Option must be configured for each Bill.', 'Cannot Save!');
                return false;
            }

            return this._utils.nonDeleted(bill.collectorPaymentOptions).every((option) => {

                // Verify that at least one Payment is configured for each Payment Option.
                if (this._utils.nonDeleted(option.collectorPayments).length < 1) {
                    this._toastr.error('At least one Payment must be configured for each Payment Option.', 'Cannot Save!');
                    return false;
                }

                // Verify that required content is not missing for each Payment configured and
                // content is valid.
                return _.every(this._utils.nonDeleted(option.collectorPayments), (payment) => {

                    let isValid = true;
                    if (!payment.dueMonthDay) {
                        this._toastr.error('Each Payment must have a Pmt Due Date configured, in M/D format.', 'Cannot Save!');
                        isValid = false;
                    } else if (!moment(payment.dueMonthDay, 'M/D', true).isValid()) {
                        this._toastr.error('Invalid Pmt Date. Must be in M/D format.');
                        isValid = false;
                    }
                    if (!payment.projectionPercentage || payment.projectionPercentage < 0 || payment.projectionPercentage > 100) {
                        this._toastr.error('Each Payment must have a Tax Projection configured, from 0 to 100%.', 'Cannot Save!');
                        isValid = false;
                    }
                    if (!payment.accrualBeginDate || !payment.accrualEndDate) {
                        this._toastr.error('Each Payment must have a Accrual Period Begin and End configured, in M/D format.', 'Cannot Save!');
                        isValid = false;
                    }
                    if (_.trim(payment.accrualBeginDate, '0') === '2/29' || _.trim(payment.accrualEndDate, '0') === '2/29') {
                        this._toastr.error('Leap Days are not permitted as accrual dates.');
                        isValid = false;
                    }
                    if (payment.projectionPercentage > 1.0) {
                        this._toastr.error('Each Payment Tax Projection must be in the range from 0 to 100%.', 'Cannot Save!');
                        isValid = false;
                    }
                    if (payment.discountPercent && payment.discountPercent > 1.0) {
                        this._toastr.error('An entered Payment Discount Percent must be in the range from 0 to 100%.', 'Cannot Save!');
                        isValid = false;
                    }
                    return isValid;
                })
                && this._validateNoOverlap(option.collectorPayments);
           })
           && this._validateAtLeastOnePaymentOptionIsDefault(bill);
        });
    }

    private _prepare(scenarioToPrepare): CollectorBillScenario {
        let scenario = _.omit(_.cloneDeep(scenarioToPrepare), ['billNumber', 'inUse']);

        //TODO there has to be a cleaner way to strip these values
        scenario.collectorBills = scenario.collectorBills.map((bill) => {
            if (scenario.efAction === 'add') {
                bill = _.omit(bill, 'collectorBillScenarioID');
            }
            if (bill.efAction === 'add') {
                bill =  _.omit(bill, 'collectorBillID');
            }
            bill = _.omit(bill, 'inUse');
            bill.collectorPaymentOptions = bill.collectorPaymentOptions.map((option) => {
                option.collectorPayments = option.collectorPayments.map((payment) => {
                    if (scenario.efAction === 'add') {
                        payment = _.omit(payment, 'collectorPaymentOptionID');
                    }
                    if (payment.efAction === 'add') {
                        payment = _.omit(payment, 'collectorPaymentID');
                    }
                    return _.omit(payment, ['monthDay', 'inUse']);
                });
                if (scenario.efAction === 'add') {
                    option = _.omit(option, ['collectorBillScenarioID', 'collectorBillID']);
                }
                if (option.efAction === 'add') {
                    if (!option.collectorPaymentOptionID) {
                        option = _.omit(option, 'collectorPaymentOptionID');
                    }
                    if (!option.collectorBillID) {
                        option = _.omit(option, 'collectorBillID');
                    }
                }
                return _.omit(option, ['paymentNumber', 'earliestPaymentSortValue', 'inUse']);
            });
            return bill;
        });

        if (scenario.efAction === 'add') {
            scenario = _.omit(scenario, 'collectorBillScenarioID');
            return scenario as CollectorBillScenario;
        }

        if (!_.isEqual(this._oldScenario, scenario)) {
            scenario.efAction = 'update';
        }

        scenario.collectorBills = scenario.collectorBills.map((bill) => {
            if (bill.efAction !== null) {
                return bill;
            }

            const oldBill = this._oldScenario.collectorBills.find(x => x.collectorBillID === bill.collectorBillID);
            if (!_.isEqual(bill, oldBill)) {
                bill.efAction = 'update';
            }

            bill.collectorPaymentOptions = bill.collectorPaymentOptions.map((option) => {
                if (option.efAction !== null) {
                    return option;
                }
                const oldOption = oldBill?.collectorPaymentOptions.find(x => x.collectorPaymentOptionID === option.collectorPaymentOptionID);
                if (!_.isEqual(option, oldOption)) {
                    option.efAction = 'update';
                }

                option.collectorPayments = option.collectorPayments.map((payment) => {
                    if (payment.efAction !== null) {
                        return payment;
                    }
                    const oldPayment = oldOption?.collectorPayments.find(x => x.collectorPaymentID === payment.collectorPaymentID);
                    if (!_.isEqual(payment, oldPayment)) {
                        payment.efAction = 'update';
                    }

                    return payment;
                });

                return option;
            });

            return bill;
        });

        return scenario as CollectorBillScenario;
    }

    private _addRemoveBills(): void {
        const scenario = this.scenarioForm.getRawValue();
        let billNumber = Number(scenario.billNumber);

        if (billNumber < 1 || billNumber > 4) {
            return;
        }
        const nonDeleted = this._utils.nonDeleted(scenario.collectorBills);
        // Find the total change in number
        const diff = Math.abs(billNumber - nonDeleted.length);
        let i: number;

        if (billNumber >= nonDeleted.length) {
            for (i = 0; i < diff; i++) {
                (this.scenarioForm.get('collectorBills') as UntypedFormArray).push(this._newBill(scenario.collectorBillScenarioID, true));
            }
        } else {
            if (scenario.collectorBills.some(x => x.inUse) && scenario.collectorBills[scenario.collectorBills.length - 1].collectorBillID) {
                this.scenarioForm.patchValue({ billNumber: billNumber++ }, { emitEvent: false });

                this._toastr.error('At least one bill is in use.', 'Cannot Remove!');
                return;
            }

            for (i = 0; i < diff; i++) {
                const lastIndex = this.collectorBills.length - 1;
                const lastBill = this.collectorBills.at(lastIndex);
                this.removeItem(this.scenarioForm.get('collectorBills') as UntypedFormArray, lastBill, lastIndex);
            }
        }
    }

    private _newPayment(id?: number): UntypedFormGroup {
        return this._fb.group({
            collectorPaymentOptionID: [id || 0],
            collectorPaymentID: [null],
            name: [{ value: '', disabled: this.viewOnly }],
            dueMonthDay: [{ value: '', disabled: this.viewOnly }, [Validators.pattern('^([1-9]|0[1-9]|1[0-2])\/([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])$')]],
            monthDay: [-1],
            efAction: ['add'],
            accrualBeginDate: [{ value: '', disabled: this.viewOnly }, [Validators.pattern('^([1-9]|0[1-9]|1[0-2])\/([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])$')]],
            accrualBeginYearID: [{ value: -1, disabled: this.viewOnly }],
            accrualEndDate: [{ value: '', disabled: this.viewOnly }, [Validators.pattern('^([1-9]|0[1-9]|1[0-2])\/([1-9]|0[1-9]|1[0-9]|2[0-9]|3[0-1])$')]],
            accrualEndYearID: [{ value: -1, disabled: this.viewOnly }],
            dueYearID: [{ value: -1, disabled: this.viewOnly }],
            projectionPercentage: [{ value: 0, disabled: this.viewOnly }],
            projectionType: [{ value: 1, disabled: this.viewOnly }],
            discountPercent: [{ value: 0, disabled: this.viewOnly }],
        });
    }

    private _newOption(scenarioId: number, id?: number, isDefault = false, startEmpty = false): UntypedFormGroup {
        return this._fb.group({
            collectorBillScenarioID: [scenarioId || null],
            collectorBillID: [id || null],
            collectorPaymentOptionID: [null],
            collectorBillName: [null],
            isDefault: [{ value: isDefault, disabled: this.viewOnly }],
            name: [{ value: '', disabled: this.viewOnly }],
            efAction: ['add'],
            inUse: [false],
            paymentNumber: [{ value: 1, disabled: this.viewOnly }],
            collectorPayments: this._fb.array(startEmpty ? [] : [this._newPayment()]),
            ptxDiscount: [{value: false, disabled: true}]
        });
    }

    private _newBill(id?: number, startEmpty = false): UntypedFormGroup {
        // For a new Bill, set isDefault=true for the initial empty Payment Option
        return this._fb.group({
            collectorBillScenarioID: [id || null],
            collectorBillID: [null],
            name: [{ value: '', disabled: this.viewOnly }],
            procureDays: [{ value: 1, disabled: this.viewOnly }],
            efAction: ['add'],
            inUse: [false],
            collectorPaymentOptions: this._fb.array(startEmpty ? [] : [this._newOption(id, null, true)])
        });
    }

    private _newScenario(id?: number, startEmpty = false): UntypedFormGroup {
        return this._fb.group({
            name: [{ value: '', disabled: this.viewOnly }],
            billNumber: [{ value: 1, disabled: this.viewOnly || this.scenario?.inUse }],
            isDefault: [false],
            collectorID: [id || null],
            efAction: ['add'],
            enabled: [{ value: true, disabled: this.viewOnly }],
            inUse: [false],
            collectorBillScenarioID: [null],
            collectorBills: this._fb.array(startEmpty ? [] : [this._newBill()])
        });
    }

    private _prepareScenario(inUseObj): void {
        this.scenarioForm = this._newScenario(inUseObj.collectorID, true);

        this.scenario.collectorBills.forEach((bill, i) => {
            const billControl = this._newBill(this.scenario.collectorBillScenarioID, true);
            const collectorPaymentOptions = billControl.get('collectorPaymentOptions') as UntypedFormArray;

            bill.collectorPaymentOptions
                .sort((a, b) => {
                    // Kind of a hack to create a sort value for payment option dueYear ID then monthDay
                    const ae = (a.collectorPayments[0].dueYearID * 100) + a.collectorPayments[0].monthDay;
                    const be = (b.collectorPayments[0].dueYearID * 100) + b.collectorPayments[0].monthDay;
                    if (a.isDefault > b.isDefault || ae < be || (a.name || '').localeCompare(b.name) === 1) {
                        return 1;
                    } else if (a.isDefault < b.isDefault || ae > be || (a.name || '').localeCompare(b.name) === -1) {
                        return -1;
                    } else {
                        return 0;
                    }
                })
                .forEach((option, j) => {
                    const optionControl = this._newOption(option.collectorBillScenarioID, bill.collectorBillID, false, true);
                    const collectorPayments = optionControl.get('collectorPayments') as UntypedFormArray;

                    option.collectorPayments
                          .sort((a, b) => {
                              const at = a.dueMonthDay.split('/');
                              const bt = b.dueMonthDay.split('/');
                              return (a.dueYearID > b.dueYearID ? 1 : (a.dueYearID < b.dueYearID ? -1 : 0))
                                     || (+at.join('.') > +bt.join('.') ? 1 : (+at.join('.') > +bt.join('.') ? -1 : 0));
                          })
                          .forEach((payment, k) => {
                              const paymentControl = this._newPayment(payment.collectorPaymentOptionID);

                              const dt = payment.dueMonthDay.split('/');
                              paymentControl.patchValue({
                                  ...payment,
                                  monthDay: parseFloat(`${dt[0]  }.${  dt[1]}`),
                                  inUse: inUseObj.collectorPayments.find(x => x.collectorPaymentId === payment.collectorPaymentID)?.inUse
                              });
                              this._watchPaymentChanges(paymentControl, j, k);
                              collectorPayments.push(paymentControl);
                          });

                    const inUse = inUseObj.collectorPaymentOptions.find(x => x.collectorPaymentOptionId === option.collectorPaymentOptionID)?.inUse;

                    optionControl.patchValue({
                        ...option,
                        paymentNumber: option.collectorPayments.length,
                        inUse
                    });

                    if (inUse) {
                        optionControl.get('paymentNumber').disable();
                    }

                    if(!this.collector.isCompanyCollector) {
                        if (this.viewOnly || option.collectorPayments.length > 1 || !option.collectorPayments[0].discountPercent) {
                            optionControl.get('ptxDiscount').disable();
                        } else {
                            optionControl.get('ptxDiscount').enable();
                        }
                    }

                    collectorPaymentOptions.push(optionControl);
                });

            billControl.patchValue({
                ...bill,
                inUse: inUseObj.collectorBills.find(x => x.collectorBillId === bill.collectorBillID)?.inUse
            });

            // All items must be pushed into the form array, Angular doesn't allow setValue or patchValue for FormArray types
            this.collectorBills.push(billControl);
        });

        this.scenarioForm.patchValue({
            ...this.scenario,
            billNumber: this.scenario.collectorBills.length,
            inUse: inUseObj.collectorBillScenario.inUse
        });
    }

    private _validateAtLeastOnePaymentOptionIsDefault(bill) {
        // See if any Payment Option of this Bill has Default checked.
        const nonDeletedOptions = this._utils.nonDeleted(bill.collectorPaymentOptions);
        let anyChecked = _.some(nonDeletedOptions, ['isDefault', true]);

        if (!anyChecked) {
            // None with Default checked.
            // If only one Payment Option, auto-set Default=true.
            // Otherwise, validation fails.
            if (nonDeletedOptions.length === 1) {
                _.head(nonDeletedOptions).isDefault = true;
                anyChecked = true;
            }
        }
        if (!anyChecked) {
            this._toastr.error('At least one Payment Option for a Bill must be checked as Default.', 'Cannot Save!');
        }

        return anyChecked;
    }

    private _validateNoOverlap(payments: CollectorPayment[]): boolean {
        const currentYear = new Date().getFullYear();

        const taxPeriods = _.map(payments, payment => {
            const beginStr = `${payment.accrualBeginDate}/${currentYear + payment.accrualBeginYearID}`;
            const endStr = `${payment.accrualEndDate}/${currentYear + payment.accrualEndYearID}`;
            return {
                begin: new Date(beginStr),
                end: new Date(endStr)
            };
        });

        // Check for overlap or if they are identical
        let valid = _.every(taxPeriods, (taxPeriod1, i) => {
            return _.every(taxPeriods, (taxPeriod2, j) => {
                if(i === j) {
                    return true;
                }

                const areTaxPeriodsIdentical = (taxPeriod1.begin.getTime() === taxPeriod2.begin.getTime()
                    && taxPeriod1.end.getTime() === taxPeriod2.end.getTime());
                const taxPeriodsNotOverlapping = (taxPeriod1.begin < taxPeriod2.begin || taxPeriod1.begin > taxPeriod2.end)
                    && (taxPeriod1.end < taxPeriod2.begin || taxPeriod1.end > taxPeriod2.end);

                return areTaxPeriodsIdentical || taxPeriodsNotOverlapping;
            });
        });

        if(!valid) {
            this._toastr.error('At least two payments\' tax periods are overlapping', 'Cannot Save!');
            return valid;
        }

        // Check that the beginning and end dates are contiguous for all tax periods
        if (taxPeriods.length > 1) {
            valid = taxPeriods.every((taxPeriod1, i) => {
                return !!taxPeriods.find((taxPeriod2, j) => {
                    if(i === j) {
                        return false;
                    }

                    // Allow identical tax periods
                    if (taxPeriod1.begin.getTime() === taxPeriod2.begin.getTime()
                        && taxPeriod1.end.getTime() === taxPeriod2.end.getTime()) {
                        return true;
                    }

                    const period1BeginMinusOne = new Date(taxPeriod1.begin);
                    period1BeginMinusOne.setDate(period1BeginMinusOne.getDate() - 1);
                    const period1EndPlusOne = new Date(taxPeriod1.end);
                    period1EndPlusOne.setDate(period1EndPlusOne.getDate() + 1);

                    return period1BeginMinusOne.getTime() === taxPeriod2.end.getTime()
                        || period1EndPlusOne.getTime() === taxPeriod2.begin.getTime();
                });
            });

            if(!valid) {
                this._toastr.error('At least two payments\' tax periods are not contiguous', 'Cannot Save!');
            }
        }

        return valid;
    }

    private _watchPaymentChanges(payment: UntypedFormGroup, optionIndex: number, index: number): void {
        if(!Array.isArray(this._paymentSubs[optionIndex])) {
            this._paymentSubs[optionIndex] = [];
        }
        this._paymentSubs[optionIndex][index] = payment.get('accrualBeginYearID').valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
            const endYear = payment.get('accrualEndYearID');
            if (x === 0 && endYear.value === -1) {
                endYear.patchValue(0);
            } else if (x === 1 && (endYear.value === -1 || endYear.value === 0)) {
                endYear.patchValue(1);
            } else if (x === 2) {
                endYear.patchValue(2);
            }
        });
    }
}
