import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ErrorLogListGridActionCellRendererParams extends ICellRendererParams {
    viewDetails: (params: ErrorLogListGridActionCellRendererParams) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button"
            *ngIf="errorLog"
            class="ace-btn btn-primary has-icon grid-action-button"
            (click)="params.viewDetails(params)"
            helpTooltip
            helpContentId="app.view"
            position="bottom">
            <i class="fa fa-pencil"></i>
        </button>`
})
export class ErrorLogListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ErrorLogListGridActionCellRendererParams;
    errorLog: Core.ErrorLogModel;

    agInit(params: ErrorLogListGridActionCellRendererParams): void {
        this.params = params;
        this.errorLog = params.data as Core.ErrorLogModel;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
