import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

enum ReportingAssetBulkUpdateFieldActionEnum {
    NoChange = 0,
    SetToBlank = 1,
    ChangeTo = 2
}

@Component({
    selector: 'asset-bulk-update-action',
    templateUrl: './assetBulkUpdateAction.component.html',
    styles: ['select {width:120px}']
})
export class AssetBulkUpdateActionComponent implements OnInit{
    constructor(
    ){
        this.possibleActions = [
            { name: 'No Change', value: Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange },
            { name: 'Change To', value: Compliance.ReportingAssetBulkUpdateFieldActionEnum.ChangeTo },
            { name: 'Set to Blank', value: Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank },
            { name: 'Remove Override', value: Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride },
            { name: 'Verify', value: Compliance.ReportingAssetBulkUpdateFieldActionEnum.Verify }
        ];
    }

    @Input()
    set fieldAction(fieldAction: Compliance.ReportingAssetBulkUpdateFieldActionEnum) {
        this.fieldActionChanged(fieldAction);
    }

    @Output() fieldActionChange = new EventEmitter<Compliance.ReportingAssetBulkUpdateFieldActionEnum>();
    @Input() requiredField: boolean;
    @Input() fieldHasOverrides: boolean = false;
    @Input() verifyField: boolean = false;
    @Input() disabled: boolean;

    action: Compliance.ReportingAssetBulkUpdateFieldActionEnum;
    possibleActions: Compliance.NameValuePair<Compliance.ReportingAssetBulkUpdateFieldActionEnum>[];
    selectedAction: Compliance.ReportingAssetBulkUpdateFieldActionEnum = Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange;

    ngOnInit(): void {
        if (this.verifyField){
            this.possibleActions = this.possibleActions.filter(i => i.value === Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange ||
                                                                    i.value === Compliance.ReportingAssetBulkUpdateFieldActionEnum.Verify);
        } else {
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.Verify);
        }

        if (this.requiredField){
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.SetToBlank);
        }

        if (!this.fieldHasOverrides){
            this.possibleActions = this.possibleActions.filter(i => i.value !== Compliance.ReportingAssetBulkUpdateFieldActionEnum.RemoveOverride);
        }
    }

    public fieldActionChanged(action: Compliance.ReportingAssetBulkUpdateFieldActionEnum): void {
        if (action || action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange) {
            this.selectedAction = action;
            this.fieldActionChange.next(action);
        } else {
            this.selectedAction = Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange;
        }
    }

    isSelected(action: Compliance.ReportingAssetBulkUpdateFieldActionEnum): boolean{
        return action === Compliance.ReportingAssetBulkUpdateFieldActionEnum.NoChange;
    }
}
