import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { PermissionService } from './../../permission.service';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../Compliance/AgGrid';

export interface CompanyListDataSourceParams {
    instanceId: number;
    groupId?: number;
    userId?: System.Guid;
    excludeCompanyIds: number[];
    excludeInactive: boolean;
}

export class CompanyListAgGridDataSource extends AgGridDataSourceBase<CompanyListDataSourceParams> {
    constructor(
        private readonly _gridApi: GridApi,
        private readonly _permissionService: PermissionService,
        protected _dataSourceParamsFn: () => CompanyListDataSourceParams
    ) { super(_gridApi); }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.PermissionModelPropertyEnum>[] = [
        { name: 'entityName', value: Compliance.PermissionModelPropertyEnum.EntityName },
        { name: 'topLevelCompany', value: Compliance.PermissionModelPropertyEnum.TopLevelCompany }
    ];

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.PermissionSearchModel = this.getSearchModel(startIndex, endIndex);

        return await this._permissionService.getAvailableCompanyPermissionsRowIds(this._dataSourceParams.instanceId, searchParams);
    }

    getSearchModel(startIndex?: number, endIndex?: number): Core.PermissionSearchModel {
        return {
            pagination: {
                skip: (startIndex) ? startIndex : 0,
                take: (endIndex) ? endIndex - startIndex + 1 : 0
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            groupId: this._dataSourceParams.groupId,
            userId: this._dataSourceParams.userId,
            excludeCompanyIds: this._dataSourceParams.excludeCompanyIds,
            excludeInactive: this._dataSourceParams.excludeInactive
        };
    }

    protected canGetRows(): boolean {
        return (this._dataSourceParams.instanceId !== null);
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.PermissionSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            groupId: this._dataSourceParams.groupId,
            userId: this._dataSourceParams.userId,
            excludeCompanyIds: this._dataSourceParams.excludeCompanyIds,
            excludeInactive: this._dataSourceParams.excludeInactive
        };

        const result = await this._permissionService.getAvailableCompanyPermissionsForGroup(this._dataSourceParams.instanceId, searchParams);

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }

}
