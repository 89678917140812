import { ButtonState, ButtonStyle } from '@ace/shared';
import {
    Component,
    EventEmitter,
    forwardRef, HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UIRouter } from '@uirouter/angular';
import { IButtonInput } from '../Button/button.component';

export interface IButtonGroupInput {
    /** Child buttons to display */
    buttons: IButtonInput[];

    singleSelect?: {
        activeStyle: ButtonStyle;
        inactiveStyle: ButtonStyle;
        activeState: ButtonState;
        inactiveState: ButtonState;
    };
}

@Component({
    selector: 'ws-ace-button-group',
    templateUrl: './buttonGroup.component.html',
    styleUrls: ['./buttonGroup.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AceButtonGroupComponent),
            multi: true
        }
    ]
})
export class AceButtonGroupComponent implements ControlValueAccessor, OnInit, OnDestroy {
    constructor(private readonly _router: UIRouter) {
    }
    @Input() input!: IButtonGroupInput;
    @Input() selectedButton: IButtonInput;

    @HostBinding('attr.singleSelect') get singleSelect() {
        return !!this.input.singleSelect || null;
    }

    @Output() selectedButtonChange: EventEmitter<IButtonInput> = new EventEmitter<IButtonInput>();
    @Output() buttonClicked: EventEmitter<IButtonInput> = new EventEmitter<IButtonInput>();

    buttons: IButtonInput[];

    // Angular Form methods

    onChange: (val: IButtonInput) => void;
    onTouched: () => void;

    private _destroyTransitionHook: any;

    writeValue(value: IButtonInput): void {
        this.selectedButton = value;
    }

    setDisabledState(disabled: boolean): void {
        this.buttons.forEach((button) => {
            button.isDisabled = disabled;
        });
    }

    next(): void {
        this.selectedButtonChange.emit(this.selectedButton);
        if (this.onChange) {
            this.onChange(this.selectedButton);
        }
        if (this.onTouched) {
            this.onTouched();
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    ngOnInit() {
        this.buttons = this.input.buttons;
        if (this.input.singleSelect) {
            this._destroyTransitionHook = this._router.transitionService.onSuccess({}, () => {
                this._validateRouterChange();
            });
            this._validateRouterChange();
        }
    }

    ngOnDestroy(): void {
        if (this._destroyTransitionHook) {
            this._destroyTransitionHook();
        }
    }

    onButtonClicked(button: IButtonInput): void {
        if (this.input.singleSelect) {
            this.selectedButton = button;
            this._updateButtonStyles();
            this.next();
        }

        this.buttonClicked.emit(button);
    }

    private _validateRouterChange(): void {
        const route = this._router.globals.current;
        this.selectedButton = this.buttons.find((b) => b.routerOutlet === route.name);
        this._updateButtonStyles();
    }

    private _updateButtonStyles(): void {
        this.buttons.forEach((b) => {
            if (b === this.selectedButton) {
                b.style = this.input.singleSelect.activeStyle;
                b.state = this.input.singleSelect.activeState;
            } else {
                b.style = this.input.singleSelect.inactiveStyle;
                b.state = this.input.singleSelect.inactiveState;
            }
        });
    }
}
