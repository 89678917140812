import { Injectable } from '@angular/core';
import {SDHttpService} from '../../Common/Routing/sd-http.service';
import {
    ClientServiceExceptionRequest,
    ClientServiceExceptionResponse,
    EntityResponsibilityAssignmentChange,
} from './client-service-exception.models';


@Injectable()
export class ClientServicesExceptionService {

    constructor(private http: SDHttpService) { }


    getClientServiceExceptionsForEntity(exceptionInput: ClientServiceExceptionRequest): Promise<ClientServiceExceptionResponse> {
        return this.http.post('/api/ClientServiceResponsibility/Exception/Search', exceptionInput);
    }

    saveClientServiceExceptions(updatedExceptions: EntityResponsibilityAssignmentChange[]): Promise<Core.BulkExceptionSetResponse> {
        return this.http.put('/api/ClientServiceResponsibility/BulkExceptionSet', updatedExceptions);
    }
}
