<ws-stamp [dataValue]="parcel"
          [visible]="false"
          [activityStatusId]="parcel.activityStatusID"
          [transaction]="parcel.transaction">
</ws-stamp>
<div style="display: flex; justify-content: space-between;">
    <div style="flex: 1;">
        <div style="display: flex; gap: 10px; align-items: center;">
            <parcel-icon class="ws-display-inline-block"
                         [parcel]="parcel"
                         [isLinkedParcel]="!!parcel.masterParcelId || !!parcel.subParcelCount"
                         [isMasterParcel]="!parcel.masterParcelId && !!parcel.subParcelCount"
                         propTypeParam="propertyType.propTypeAbbr"
                         size="large"
                         [includeIcons]="true"
                         [ppReturnPreparationAllowed]="parcelViewDto.ppReturnPreparationAllowed"
                         [stateAllowConsolidating]="stateAllowConsolidating">
            </parcel-icon>
            <h3 ng-class="{'preserve-white-space': acctNumHasDoubleSpace}" style="margin: 0;">{{parcel.acctNum}}</h3>
        </div>

        <table class="view-info-table">
            <tr *ngFor="let acctNum of altAcctNums">
                <td>{{acctNum.accountNumberType.accountNumberDesc}}:</td>
                <td>{{acctNum.acctNumber}}</td>
            </tr>
            <tr>
                <td>Assessee:</td>
                <td>{{parcel.assesseeName}}</td>
            </tr>
            <tr>
                <td>Desc:</td>
                <td>{{parcel.description}}</td>
            </tr>
            <tr>
                <td>Address:</td>
                <td>
                    <address-view-edit [address]="address" [isEditMode]="false"></address-view-edit>
                </td>
            </tr>
            <tr>
                <td>Assessor:</td>
                <td>
                    <a anchor-route
                       helpTooltip
                       [contentComponent]="assessorDetailsRenderer"
                       [contentComponentValue]="parcelAssessor"
                       [sref]="{ target: 'assessor', options: { stateId: parcel.address.stateID, id: parcel.assessorID } }">
                        {{parcelAssessor?.abbr}} {{parcelAssessor?.municipalityName}}
                    </a>
                </td>
            </tr>
            <tr *ngIf="showTaxingDistrict">
                <td>Taxing District</td>
                <td>{{parcel.taxingDistrictModel?.displayName}}</td>
            </tr>
            <tr>
                <td>Assessment Class(es):</td>
                <td>
                    <span *ngFor="let ac of parcel.parcelAssessmentClasses; let last=last">
                        {{ac.assessmentClass.name}}<span *ngIf="!last">,</span>
                    </span>
                </td>
            </tr>
            <tr>
                <td>Collector(s):</td>
                <td>
                    <div *ngFor="let collector of parcel.parcelCollectors">
                        <a anchor-route [sref]="getCollectorSref(collector)">
                            {{collector.collectorAbbr}} <span
                            *ngIf="collector.collectorBillingScenarioName">- {{collector.collectorBillingScenarioName}}</span>
                        </a>
                        <div *ngIf="collector.collectorAcctNum">
                            Collector Acct Num: {{collector.collectorAcctNum}}
                        </div>
                        <div *ngFor="let option of collector.parcelCollectorPaymentOptions">
                            <span *ngIf="option.collectorPaymentOption.name">
                                <span
                                    *ngIf="option.collectorPaymentOption.collectorBillName">{{ option.collectorPaymentOption.collectorBillName }} </span>Pmt Option: {{option.collectorPaymentOption.name}}
                            </span>
                        </div>
                        <div>
                            <collector-tax-rate-area [trs]="getTRS(collector)" [parcelCollector]="collector"
                                                     [parcel]="parcel" [editMode]="false"
                                                     [years]="years"></collector-tax-rate-area>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>Legal Desc:</td>
                <td>
                    {{parcel.legalDescription?.substring(0, 50)}}
                    <a *ngIf="parcel.legalDescription?.length > 50" (click)="showLegalDescriptionDialog()"
                       style="padding-left: 10px; cursor: pointer;">more...</a>
                </td>
            </tr>
            <ng-container *ngIf="parcel.accrualsEnabled">
                <tr *ngIf="parcel.accrualsExclude.parentExcludedFromAccruals">
                    <td>Parent Excluded From Accruals</td>
                    <td></td>
                </tr>
                <tr *ngIf="!parcel.accrualsExclude.parentExcludedFromAccruals">
                    <td>Exclude From Accruals:</td>
                    <td>
                        <input type="checkbox"
                               [(ngModel)]="parcel.accrualsExclude.excludeFromAccruals"
                               [disabled]="true">
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td>
                    <linked-parcels
                        [parcelId]="parcel.parcelID"
                        [parcelAcctNum]="parcel.acctNum"
                        [masterParcelId]="parcel.masterParcelId"
                        [masterParcelAcctNum]="parcel.masterParcelAcctNum"
                        [linkedParcelCount]="parcel.subParcelCount">
                    </linked-parcels>
                </td>
                <td></td>
            </tr>
        </table>
    </div>
    <div>
        <div>
            <entity-attachments buttonClass="has-icon btn-lg"
                                [isNestedModal]="false"
                                [analyticsCategory]="analyticsCategory"
                                helpTooltip helpContentId="parcel-info-panel.attachments"
                                position="left"
                                [(hasAttachments)]="parcel.hasAttachments"
                                [modalData]="attachmentsModel">
            </entity-attachments>
        </div>
        <div *ngIf="parcel.hasImages">
            <entity-attachments buttonClass="has-icon btn-lg"
                                [isNestedModal]="false"
                                [analyticsCategory]="analyticsCategory"
                                helpTooltip
                                helpContentId="parcel-info-panel.images"
                                position="left"
                                [forImages]="true"
                                (hasImagesChange)="parcel.hasImages = $event"
                                [modalData]="attachmentsModel">
            </entity-attachments>
        </div>
        <div>
            <entity-comments [(hasComments)]="parcel.hasComments"
                             [analyticsCategory]="analyticsCategory"
                             buttonClass="has-icon btn-lg"
                             helpTooltip
                             helpContentId="parcel-info-panel.comments"
                             position="left"
                             [modalData]="commentsModel">
            </entity-comments>
        </div>
        <div *ngIf="optimusLinkInfo?.url">
            <a class="ace-btn has-icon btn-lg btn-primary"
               href="{{optimusLinkInfo?.url}}" target="_blank"
               helpTooltip helpContentId="parcel-info-panel.optimus" position="left">
                <img src="/images/OptimusIconOnDark.png"
                     style="border: 0; vertical-align: middle; margin-top: 5px; max-height: 80%; max-width: 80%;"/>
            </a>
        </div>
    </div>
</div>
