<div class="modal-header">
    <h4 class="modal-title">Parcel Links</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="_bsModalRef.hide()"></button>
</div>
<div class="modal-body exclude-modal-styling">
    <div class="ws-flex-container-vertical ws-flex-auto ws-stretch">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="ace-btn btn-primary" (click)="save()" [disabled]="!rowsSelected">OK</button>
        <button class="ace-btn btn-secondary" (click)="cancel()">Cancel</button>
    </div>
</div>
