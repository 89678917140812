import { Component, OnInit, OnDestroy } from '@angular/core';
import { GridOptions, GridReadyEvent, GridApi, ColDef } from 'ag-grid-community';
import { ReturnOutputService } from '../../Output/returnOutput.service';
import { AgGridOptionsBuilder } from '../../../../AgGrid/agGridOptionsBuilder';
import { AgGridColumns } from '../../../../AgGrid/agGridColumns';
import { IReturnPartComponent } from '../../../Models/returnPartServiceBase';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectTracker, AgGridMultiSelectRendererParams } from '../../../../AgGrid/MultiSelectTracker';
import { BehaviorSubject,  lastValueFrom,  Subscription } from 'rxjs';
import { ICellRendererParamsForPackageListDropdownCellRenderer, ReturnOutputPackageListDropdownCellRenderer } from './agGridDropdownCellRenderer.component';
import { BusyIndicatorService } from '../../../../../Busy-Indicator/busyIndicator.service';
import * as _ from 'lodash';
import { ReturnRepository } from '../../../../Repositories/return.repository';
import { ReturnService } from '../../../return.service';

@Component({
    selector: 'return-output-package-list',
    templateUrl: './returnOutputPackageList.component.html',
    styleUrls: ['./returnOutputPackageList.component.scss']
})
export class ReturnOutputPackageListComponent implements OnInit, OnDestroy, IReturnPartComponent {
    constructor(
        private readonly _returnRepository: ReturnRepository,
        private readonly _returnService: ReturnService,
        private readonly _returnOutputService: ReturnOutputService,
        private readonly _busyIndicatorService: BusyIndicatorService
    ) { }

    private _gridApi: GridApi;
    private _packageData: Compliance.ReturnPackageModel[];
    private _gridMultiSelectSub: Subscription;
    private _mailingTypes: Compliance.ReturnMailingTypeModel[];
    private _editModeSubject = new BehaviorSubject<boolean>(true);

    isInitialized: boolean = false;
    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Compliance.ReturnPackageModel).returnPackageId)
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withTextSelection()
        .build();

    get filingBatchId(): number {
        return this._returnService.filingBatchId;
    }

    async ngOnInit(): Promise<void> {
        this._returnOutputService.subscribeToServiceActivationCycle(this);
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._editModeSubject && this._editModeSubject.unsubscribe();
        this._returnOutputService.unsubscribeFromServiceActivationCycle(this);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns: ColDef[] = [
            {
                field: 'returnPackageId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Package ID',
                field: 'returnPackageId',
                suppressSizeToFit: true,
                width: AgGridColumns.textColumnMedWidth,
                valueFormatter: (params) => params.value ? `RP${_.padStart(params.value, 6, '0')}` : ''
            },
            {
                headerName: 'Description',
                field: 'description',
                sortable: false,
                width: AgGridColumns.textColumnMedWidth,
            },
            {
                headerName: 'Mailing Type',
                field: 'returnMailingTypeId',
                cellRendererFramework: ReturnOutputPackageListDropdownCellRenderer,
                cellRendererParams: {
                    canEdit: (x) => this._returnService.canEditCompany && !this._returnService.isFinalized,
                    isDisabled: (x) => false,
                    editMode$: this._editModeSubject.asObservable(),
                    dropdownItems: () => this._mailingTypes,
                    name: 'returnMailingTypes',
                    valueField: 'returnMailingTypeId',
                    allowNull: true,
                    change: this._mailingTypeChanged.bind(this),
                    displayGetter: this._mailTypesTableRenderer.bind(this)
                } as ICellRendererParamsForPackageListDropdownCellRenderer
            },
            {
                headerName: '# Returns',
                field: 'numberOfReturns',
                sortable: false,
                width: AgGridColumns.numericColumnWidth
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(() => {
            this.gridTracker.getSelectedRowIds().then(rowIds => {
                this._returnOutputService.sharedState.returnOutputModel.packageIds = rowIds;
            });
        });
    }

    async onReturnPartServiceActivated(): Promise<void> {
        if (!this.isInitialized) {
            this._mailingTypes = await lastValueFrom(this._returnRepository.getMailingTypes());
            this._packageData = await lastValueFrom<Compliance.ReturnPackageModel[]>(this._returnRepository.getPackages(this.filingBatchId));
            this.isInitialized = true;
        }

        this._setRowData();
    }

    async onReturnPartServiceDeactivated(): Promise<void> {
    }

    private _mailTypesTableRenderer(item: Compliance.ReturnMailingTypeModel) {
        return item.returnMailingTypeName;
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map(x => {
                const returnPackage = x.data as Compliance.ReturnPackageModel;
                return returnPackage && returnPackage.returnPackageId;
            })
        } as Compliance.QueryResultModel<number>);
    }

    private async _mailingTypeChanged(params: ICellRendererParamsForPackageListDropdownCellRenderer): Promise<void> {
        const returnPackage = params.data as Compliance.ReturnPackageModel;

        if (!returnPackage) {
            return;
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {
            await lastValueFrom(this._returnRepository.updatePackageMailingType(returnPackage.returnPackageId, returnPackage.returnMailingTypeId));
        } finally {
            busyRef.hide();
        }
    }

    private async _setRowData(): Promise<void> {
        if (!(this._gridApi && this.isInitialized)) {
            return;
        }

        this._gridApi.setRowData(this._packageData);
        this._gridApi.sizeColumnsToFit();
    }
}
