import { Component, } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthData } from 'src/app/User/authStorage.service';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { LoginService } from './login.service';

@Component({
    selector: 'terms-and-conditions-modal',
    styleUrls: ['termsAndConditionsModal.component.scss'],
    template: `
        <ws-busy-indicator *ngIf="serverAction"></ws-busy-indicator>
        <ws-modal-wrapper
            headerLabel="PropertyPoint Terms and Conditions Read-only Access"
            acceptLabel="OK"
            (cancel)="close()"
            (save)="save()"
            [disableSave]="!hasUserAccepted">
                <p>
                    <strong>Read-Only Access Terms:</strong> If you are an authorized user who has been given login and password access ("<strong>User</strong>") to use the PropertyPoint service ("<strong>Service</strong>") by Ryan, LLC, or its Affiliates ("<strong>Ryan</strong>"), and you <em>DO NOT</em> have a separate agreement in place with Ryan, you must first accept the PropertyPoint Terms and Conditions for Read-only Access in order to access the Service.
                </p>
                <div class="important-text">
                    <p>
                        <strong>PropertyPoint Terms and Conditions for Read-only Access:</strong> <br>
                        Ryan grants you a limited, revocable, non-exclusive, worldwide, royalty-free, read-only license to access the Service for internal use only on behalf of your company. You may not download, copy, repost, or otherwise share with third parties any information you access through the Service.
                    </p>
                    <p>Your license to access the Services expressly prohibits:</p>
                    <ul>
                        <li>Account sharing. You will take reasonable measures to keep your password and login credentials secure for your own exclusive use. You will alert us promptly if you become aware of any unauthorized use of your login credentials.</li>
                        <li>Creating derivative works. You may not modify, translate, or otherwise create derivative works based on any part of the Service, and you must keep intact all Ryan trademarks and logos on the information accessed through the Service.</li>
                        <li>Reverse engineering, decompiling, disassembling, or otherwise attempting to discover the underlying code, structure, ideas, know-how, or algorithms relevant to the Service. </li>
                        <li>Uploading, posting, transmitting, or making available any unsolicited or unauthorized advertising, marketing, spam, junk mail, or any content that is unlawful, harassing, or objectionable, nor any material that contains viruses or other codes, files, or programs designed to interrupt, destroy, or limit the functionality of any software or hardware.</li>
                    </ul>
                    <p>
                        All intellectual property of the Service, including the software and platform, belongs exclusively to and remains with Ryan, LLC and its licensors.
                    </p>
                    <p>
                    THE SOFTWARE IS PROVIDED “AS IS,” AND RYAN HEREBY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND OR NATURE, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. RYAN DOES NOT WARRANT OR REPRESENT THAT THE SOFTWARE WILL BE FREE FROM BUGS OR THAT ITS USE WILL BE UNINTERRUPTED OR ERROR FREE, AND DOES NOT MAKE ANY OTHER REPRESENTATIONS REGARDING THE USE, OR THE RESULTS OF USE, OF THE SOFTWARE IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE.
                    </p>
                </div>
                <p>
                    If you do not accept these <strong>Terms and Conditions for Read-only Access</strong>, do not log on to the Service. By clicking on the access button below, you acknowledge and agree to the <strong>Terms and Conditions for Read-only </strong>.
                </p>
                <div class="checkbox" style="text-align: right; margin-bottom: 0;">
                    <label>
                        <input type="checkbox" [(ngModel)]="hasUserAccepted"> I accept the Terms and Conditions
                    </label>
                </div>
        </ws-modal-wrapper>
    `,
})
export class TermsAndConditionsModalComponent implements IWeissmanModalComponent<any, boolean> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _loginService: LoginService,
    ) { }

    hasUserAccepted: boolean = false;
    serverAction: boolean = false;

    params: {tokenResponse: AuthData};
    result: boolean = false;


    async save() {
        this.serverAction = true;

        try {
            await this._loginService.acceptTermsAndConditions(this.params.tokenResponse);
            this.result = this.hasUserAccepted;
        } finally {
            this.serverAction = false;
        }

        this.close();
    }

    close() {
        this._bsModalRef.hide();
    }
}
