import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface FormAssetClassMappingListGridActionCellRendererParams extends ICellRendererParams {
    viewDepreciationTable: (params: FormAssetClassMappingListGridActionCellRendererParams) => void;
    canViewDepreciationTable: (params: FormAssetClassMappingListGridActionCellRendererParams) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button *ngIf="params.data" class="ace-btn has-icon btn-secondary grid-action-button" title="View depreciation table" [disabled]="!params.canViewDepreciationTable(params)" (click)="params.viewDepreciationTable(params)">
        <i class="fa fa-external-link"></i>
    </button>
    `
})
export class FormAssetClassMappingListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: FormAssetClassMappingListGridActionCellRendererParams;

    agInit(params: FormAssetClassMappingListGridActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
