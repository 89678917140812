import { lastValueFrom } from 'rxjs';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../AgGrid';
import { GridApi, IGetRowsParams } from 'ag-grid-community';
import { AllocationDetailRepository } from '../../Repositories/allocationDetail.repository';
import { AllocationDetailGridRowModel } from './AllocationDetailGridRowModel';
import { AllocationDetailsService } from './allocationDetails.service';
import * as _ from 'lodash';

export interface AllocationDetailsListDataSourceParams {
    allocationId: number;
    hideNotReportedAssets: boolean;
    managementReviewOption: Compliance.AllocationDetailManagementReviewOptionEnum;
}

export class AllocationDetailsListAgGridDataSource extends AgGridDataSourceBase<AllocationDetailsListDataSourceParams> {
    constructor(gridApi: GridApi,
                private readonly _allocationDetailRepository: AllocationDetailRepository,
                private readonly _allocationDetailService: AllocationDetailsService,
                protected _dataSourceParamsFn: () => AllocationDetailsListDataSourceParams,
                private readonly _totalsUpdateCallback: (totals: any, selectedTotals?: boolean, isLoading?: boolean) => void) {
        super(gridApi);
    }

    lastFetchedTotals: Compliance.AllocationDetailTotalsModel;

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.AllocationDetailPropertyEnum>[] = [
        { name: 'assetNumber', value: Compliance.AllocationDetailPropertyEnum.AssetNumber },
        { name: 'cost', value: Compliance.AllocationDetailPropertyEnum.Cost },
        { name: 'returnValue', value: Compliance.AllocationDetailPropertyEnum.ReturnValue },
        { name: 'initialAllocationAmount', value: Compliance.AllocationDetailPropertyEnum.InitialAllocationAmount },
        { name: 'finalAllocationAmount', value: Compliance.AllocationDetailPropertyEnum.FinalAllocationAmount },
        { name: 'calculatedParcel', value: Compliance.AllocationDetailPropertyEnum.CalculatedParcel },
        { name: 'statusId', value: Compliance.AllocationDetailPropertyEnum.AllocationDetailStatusId },
        { name: 'isReconciled', value: Compliance.AllocationDetailPropertyEnum.IsReconciled },
        { name: 'description', value: Compliance.AllocationDetailPropertyEnum.Description },
        { name: 'glAccount', value: Compliance.AllocationDetailPropertyEnum.GLAccount },
        { name: 'glAccountNumber', value: Compliance.AllocationDetailPropertyEnum.GLAccountNumber },
        { name: 'classificationName', value: Compliance.AllocationDetailPropertyEnum.ClassificationName },
        { name: 'parcel', value: Compliance.AllocationDetailPropertyEnum.Parcel },
        { name: 'parcelDescription', value: Compliance.AllocationDetailPropertyEnum.ParcelDescription },
        { name: 'assessorAbbr', value: Compliance.AllocationDetailPropertyEnum.AssessorAbbr },
        { name: 'acqDate', value: Compliance.AllocationDetailPropertyEnum.AcqDate },
        { name: 'age', value: Compliance.AllocationDetailPropertyEnum.Age },
        { name: 'priorAcqDate', value: Compliance.AllocationDetailPropertyEnum.PriorAcqDate },
        { name: 'priorCost', value: Compliance.AllocationDetailPropertyEnum.PriorCost },
        { name: 'scheduleName', value: Compliance.AllocationDetailPropertyEnum.Schedule },
        { name: 'site', value: Compliance.AllocationDetailPropertyEnum.Site },
        { name: 'siteName', value: Compliance.AllocationDetailPropertyEnum.SiteName },
        { name: 'siteProperty', value: Compliance.AllocationDetailPropertyEnum.SiteProperty },
        { name: 'parcelSiteProperty', value: Compliance.AllocationDetailPropertyEnum.ParcelSiteProperty },
        { name: 'parcelSiteName', value: Compliance.AllocationDetailPropertyEnum.ParcelSiteName },
        { name: 'priorScheduleName', value: Compliance.AllocationDetailPropertyEnum.PriorSchedule },
        { name: 'depreciationFactorTableName', value: Compliance.AllocationDetailPropertyEnum.DepreciationFactorTableName },
        { name: 'priorDepreciationFactorTableName', value: Compliance.AllocationDetailPropertyEnum.PriorDepreciationFactorTableName},
        { name: 'factor', value: Compliance.AllocationDetailPropertyEnum.Factor },
        { name: 'acqYear', value: Compliance.AllocationDetailPropertyEnum.AcqYear },
        { name: 'linkedParcelAccountNumber', value: Compliance.AllocationDetailPropertyEnum.LinkedParcelAccountNumber },
        { name: 'linkedParcelDescription', value: Compliance.AllocationDetailPropertyEnum.LinkedParcelDescription },
        { name: 'linkedParcelAssessorAbbr', value: Compliance.AllocationDetailPropertyEnum.LinkedParcelAssessorAbbr },
        { name: 'acqYear', value: Compliance.AllocationDetailPropertyEnum.AcqYear },
        { name: 'acqYear', value: Compliance.AllocationDetailPropertyEnum.AcqYear },
        { name: 't1', value: Compliance.AllocationDetailPropertyEnum.T1 },
        { name: 't2', value: Compliance.AllocationDetailPropertyEnum.T2 },
        { name: 't3', value: Compliance.AllocationDetailPropertyEnum.T3 },
        { name: 't4', value: Compliance.AllocationDetailPropertyEnum.T4 },
        { name: 't5', value: Compliance.AllocationDetailPropertyEnum.T5 },
        { name: 't6', value: Compliance.AllocationDetailPropertyEnum.T6 },
        { name: 't7', value: Compliance.AllocationDetailPropertyEnum.T7 },
        { name: 't8', value: Compliance.AllocationDetailPropertyEnum.T8 },
        { name: 't9', value: Compliance.AllocationDetailPropertyEnum.T9 },
        { name: 't10', value: Compliance.AllocationDetailPropertyEnum.T10 },
        { name: 't11', value: Compliance.AllocationDetailPropertyEnum.T11 },
        { name: 't12', value: Compliance.AllocationDetailPropertyEnum.T12 },
        { name: 't13', value: Compliance.AllocationDetailPropertyEnum.T13 },
        { name: 't14', value: Compliance.AllocationDetailPropertyEnum.T14 },
        { name: 't15', value: Compliance.AllocationDetailPropertyEnum.T15 },
        { name: 't16', value: Compliance.AllocationDetailPropertyEnum.T16 },
        { name: 't17', value: Compliance.AllocationDetailPropertyEnum.T17 },
        { name: 't18', value: Compliance.AllocationDetailPropertyEnum.T18 },
        { name: 't19', value: Compliance.AllocationDetailPropertyEnum.T19 },
        { name: 't20', value: Compliance.AllocationDetailPropertyEnum.T20 },
        { name: 't20', value: Compliance.AllocationDetailPropertyEnum.T20 },
        { name: 't21', value: Compliance.AllocationDetailPropertyEnum.T21 },
        { name: 't22', value: Compliance.AllocationDetailPropertyEnum.T22 },
        { name: 't23', value: Compliance.AllocationDetailPropertyEnum.T23 },
        { name: 't24', value: Compliance.AllocationDetailPropertyEnum.T24 },
        { name: 't25', value: Compliance.AllocationDetailPropertyEnum.T25 },
        { name: 't26', value: Compliance.AllocationDetailPropertyEnum.T26 },
        { name: 't27', value: Compliance.AllocationDetailPropertyEnum.T27 },
        { name: 't28', value: Compliance.AllocationDetailPropertyEnum.T28 },
        { name: 't29', value: Compliance.AllocationDetailPropertyEnum.T29 },
        { name: 't30', value: Compliance.AllocationDetailPropertyEnum.T30 },
        { name: 'n1', value: Compliance.AllocationDetailPropertyEnum.N1 },
        { name: 'n2', value: Compliance.AllocationDetailPropertyEnum.N2 },
        { name: 'n3', value: Compliance.AllocationDetailPropertyEnum.N3 },
        { name: 'n4', value: Compliance.AllocationDetailPropertyEnum.N4 },
        { name: 'n5', value: Compliance.AllocationDetailPropertyEnum.N5 },
        { name: 'n6', value: Compliance.AllocationDetailPropertyEnum.N6 },
        { name: 'n7', value: Compliance.AllocationDetailPropertyEnum.N7 },
        { name: 'n8', value: Compliance.AllocationDetailPropertyEnum.N8 },
        { name: 'n9', value: Compliance.AllocationDetailPropertyEnum.N9 },
        { name: 'n10', value: Compliance.AllocationDetailPropertyEnum.N10 },
        { name: 'd1', value: Compliance.AllocationDetailPropertyEnum.D1 },
        { name: 'd2', value: Compliance.AllocationDetailPropertyEnum.D2 },
        { name: 'd3', value: Compliance.AllocationDetailPropertyEnum.D3 },
        { name: 'd4', value: Compliance.AllocationDetailPropertyEnum.D4 },
        { name: 'd5', value: Compliance.AllocationDetailPropertyEnum.D5 },
        { name: 'd6', value: Compliance.AllocationDetailPropertyEnum.D6 },
        { name: 'd7', value: Compliance.AllocationDetailPropertyEnum.D7 },
        { name: 'd8', value: Compliance.AllocationDetailPropertyEnum.D8 },
        { name: 'd9', value: Compliance.AllocationDetailPropertyEnum.D9 },
        { name: 'd10', value: Compliance.AllocationDetailPropertyEnum.D10 },
        { name: 'inventoryJan', value: Compliance.AllocationDetailPropertyEnum.InventoryJan },
        { name: 'inventoryFeb', value: Compliance.AllocationDetailPropertyEnum.InventoryFeb },
        { name: 'inventoryMar', value: Compliance.AllocationDetailPropertyEnum.InventoryMar },
        { name: 'inventoryApr', value: Compliance.AllocationDetailPropertyEnum.InventoryApr },
        { name: 'inventoryMay', value: Compliance.AllocationDetailPropertyEnum.InventoryMay },
        { name: 'inventoryJun', value: Compliance.AllocationDetailPropertyEnum.InventoryJun },
        { name: 'inventoryJul', value: Compliance.AllocationDetailPropertyEnum.InventoryJul },
        { name: 'inventoryAug', value: Compliance.AllocationDetailPropertyEnum.InventoryAug },
        { name: 'inventorySep', value: Compliance.AllocationDetailPropertyEnum.InventorySep },
        { name: 'inventoryOct', value: Compliance.AllocationDetailPropertyEnum.InventoryOct },
        { name: 'inventoryNov', value: Compliance.AllocationDetailPropertyEnum.InventoryNov },
        { name: 'inventoryDec', value: Compliance.AllocationDetailPropertyEnum.InventoryDec },
        { name: 'inventoryAvr', value: Compliance.AllocationDetailPropertyEnum.InventoryAvr },
        { name: 'splitAllocationPercentage', value: Compliance.AllocationDetailPropertyEnum.SplitAllocationPercentage },
        { name: 'additionalDepreciation', value: Compliance.AllocationDetailPropertyEnum.AdditionalDepreciation },
        { name: 'estimatedFMV', value: Compliance.AllocationDetailPropertyEnum.EstimatedFMV },
        { name: 'isReportable', value: Compliance.AllocationDetailPropertyEnum.IsReportable },
        { name: 'isTaxable', value: Compliance.AllocationDetailPropertyEnum.IsTaxable },
        { name: 'parcelAddressComplete', value: Compliance.AllocationDetailPropertyEnum.ParcelAddressComplete },
        { name: 'companyCode', value: Compliance.AllocationDetailPropertyEnum.CompanyCode },
        { name: 'netBookValue', value: Compliance.AllocationDetailPropertyEnum.NetBookValue },
        { name: 'workPaperCost', value: Compliance.AllocationDetailPropertyEnum.WorkPaperCost },
        { name: 'workPaperAcqDate', value: Compliance.AllocationDetailPropertyEnum.WorkPaperAcqDate },
        { name: 'varianceDollar', value: Compliance.AllocationDetailPropertyEnum.VarianceDollar },
        { name: 'variancePercentage', value: Compliance.AllocationDetailPropertyEnum.VariancePercentage },
        { name: 'workPaperDescription', value: Compliance.AllocationDetailPropertyEnum.WorkPaperDescription },
        { name: 'workPaperAcqYear', value: Compliance.AllocationDetailPropertyEnum.WorkPaperAcqYear },
        { name: 'workPaperSchedule', value: Compliance.AllocationDetailPropertyEnum.WorkPaperSchedule },
        { name: 'workPaperFactor', value: Compliance.AllocationDetailPropertyEnum.WorkPaperFactor },
        { name: 'workPaperAddlDep', value: Compliance.AllocationDetailPropertyEnum.WorkPaperAddlDep },
        { name: 'workPaperAssessor', value: Compliance.AllocationDetailPropertyEnum.WorkPaperAssessor },
        { name: 'workPaperTaxingUnit', value: Compliance.AllocationDetailPropertyEnum.WorkPaperTaxingUnit },
        { name: 'workPaperPhysicalAddress', value: Compliance.AllocationDetailPropertyEnum.WorkPaperPhysicalAddress },
        { name: 'workPaperLesseeName', value: Compliance.AllocationDetailPropertyEnum.WorkPaperLesseeName },
        { name: 'workPaperNote', value: Compliance.AllocationDetailPropertyEnum.WorkPaperNote },
        { name: 'workPaperMisc1', value: Compliance.AllocationDetailPropertyEnum.WorkPaperMisc1 },
        { name: 'workPaperMisc2', value: Compliance.AllocationDetailPropertyEnum.WorkPaperMisc2 },
        { name: 'workPaperMisc3', value: Compliance.AllocationDetailPropertyEnum.WorkPaperMisc3 },
        { name: 'workPaperMisc4', value: Compliance.AllocationDetailPropertyEnum.WorkPaperMisc4 },
        { name: 'workPaperMisc5', value: Compliance.AllocationDetailPropertyEnum.WorkPaperMisc5 },
        { name: 'priorYearEffectiveTaxRate', value: Compliance.AllocationDetailPropertyEnum.PriorYearEffectiveTaxRate },
        { name: 'priorYearFinalAllocationAmount', value: Compliance.AllocationDetailPropertyEnum.PriorYearFinalAllocationAmount },
        { name: 'priorYearFactor', value: Compliance.AllocationDetailPropertyEnum.PriorYearFactor },
        { name: 'taxVarianceDollar', value: Compliance.AllocationDetailPropertyEnum.TaxVarianceDollar }
    ];

    private _lastFilterUsedInTotals: string;

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.pagination = { skip: startIndex, take: endIndex - startIndex + 1} as Core.PaginationModel;

        return await lastValueFrom(this._allocationDetailRepository.getRowIds(this._dataSourceParams.allocationId, searchParams));
    }

    async getSelectedRowTotals(selectedRowsModel: Compliance.SelectedRowsModel): Promise<Compliance.AllocationDetailTotalsModel> {
        const searchParams = this.getSearchParamsWithoutPagination();
        searchParams.selectedRows = selectedRowsModel;
        searchParams.includeTotals = true;
        searchParams.includeOnlyTotals = true;
        searchParams.pagination = {
            skip: 0,
            take: 100
        };

        const result = await lastValueFrom(this._allocationDetailRepository.getList(this._dataSourceParams.allocationId, searchParams));

        return result.totals;
    }

    getSearchParamsWithoutPagination(): Compliance.AllocationDetailSearchModel {
        this.refreshDataSourceParams();

        const searchModel: Compliance.AllocationDetailSearchModel = {
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            selectedRows: null,
            includeTotals: false,
            includeOnlyTotals: false,
            summaryFilter: this._allocationDetailService.allocationDetailsFilter && this._allocationDetailService.allocationDetailsFilter.model,
            hideNotReportedAssets: this._dataSourceParams.hideNotReportedAssets,
            managementReviewSelectedOption: this._dataSourceParams.managementReviewOption
        };

        return searchModel;
    }

    reloadDataAfterUpdate(field: string): boolean {
        let reload: boolean = true;
        const columnMap = _.find(this._propertyMap, i => i.name === field);

        if (columnMap){
            const isInUseInFilter = !!_.find(this.getColumnFilters(this._propertyMap), i => i.filterProperty === columnMap.value);
            const isInUseInSort = !!_.find(this.getSortColumns(this._propertyMap), i => i.sortProperty === columnMap.value);
            reload = isInUseInFilter || isInUseInSort;
        }

        return reload;
    }

    clearTotals(): void {
        this._lastFilterUsedInTotals = '';
    }

    protected canGetRows(): boolean {
        return true;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        this.gridApi.setPinnedBottomRowData([]);
        const searchParams = this.getSearchParamsWithoutPagination();
        const searchParamsString = JSON.stringify(searchParams);
        const fetchTotals: boolean = (!this.lastFetchedTotals) || (this._lastFilterUsedInTotals !== searchParamsString);

        this._lastFilterUsedInTotals = searchParamsString;

        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await lastValueFrom(this._allocationDetailRepository.getList(this._dataSourceParams.allocationId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        const dataSourceResult = {
            rows: _.map(result.data, i => new AllocationDetailGridRowModel(i)),
            totalRows: result.totalRows,
            lastModifiedTimestamp: result.lastModifiedTimestamp
        };

        if (fetchTotals) {
            this._getAllRowTotals(params);
        }

        return dataSourceResult;
    }

    private async _getAllRowTotals(params: IGetRowsParams): Promise<Compliance.AllocationDetailTotalsModel> {
        this._totalsUpdateCallback(this.lastFetchedTotals, false, true);
        const searchParams = this.getSearchParamsWithoutPagination();

        searchParams.includeTotals = true;
        searchParams.includeOnlyTotals = true;
        searchParams.pagination = {
            skip: params.startRow,
            take: params.endRow - params.startRow
        };

        const result = await lastValueFrom(this._allocationDetailRepository.getList(this._dataSourceParams.allocationId, searchParams));

        this.lastFetchedTotals = result.totals;
        this._totalsUpdateCallback(this.lastFetchedTotals);

        return result.totals;
    }
}
