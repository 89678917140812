import { NgModule } from '@angular/core';
import { SmartSearchComponent } from './smartSearch.component';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { AgGridModule } from 'ag-grid-angular';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { SmartSearchService } from './smartSearch.service';
import { AdvancedSearchListComponent } from './Advanced-Search-List/advancedSearchList.component';
import { AdvancedSearchComponent } from './Advanced-Search/advancedSearch.component';
import { AdvancedSearchBulkUpdateComponent } from './Advanced-Search-List/Bulk-Update/advancedSearchBulkUpdate.component';
import { SaveSearchModalComponent } from './Save-Search-Modal/saveSearchModal.component';
import { AdvancedSearchColumnBuilder } from './Advanced-Search-List/advancedSearchColumnBuilder.service';
import { AdvancedSearchRepository } from '../../Core-Repositories';
import { FilterListComponent } from './Advanced-Search/Filter-List/filterList.component';
import { OutputListComponent } from './Advanced-Search/Output-List/outputList.component';
import { SiteModule } from '../../Entity/Site/site.module';
import { DragAndDropListModule } from '../../UI-Lib/Directives/Drag-And-Drop-List/dragAndDropList.module';
import { AdvancedSearchListService } from './Advanced-Search-List/advancedSearchList.service';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        WeissmanSharedModule,
        AgGridExtensionsModule,
        AgGridModule,
        SiteModule,
        DragAndDropListModule,
        BusyIndicatorModule.forRoot()
    ],
    declarations: [
        SmartSearchComponent,
        AdvancedSearchComponent,
        AdvancedSearchListComponent,
        AdvancedSearchBulkUpdateComponent,
        SaveSearchModalComponent,
        FilterListComponent,
        OutputListComponent
    ],
    providers: [
        SmartSearchService,
        AdvancedSearchColumnBuilder,
        AdvancedSearchRepository,
        AdvancedSearchListService
    ],
    exports: [SmartSearchComponent]
})
export class SmartSearchModule {
    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Provider('advancedSearchService', SmartSearchService);
        hybridAdapterUtility.downgradeNg2Component('smartSearch', SmartSearchComponent);
    }
}
