import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
// TODO: Migrate to @angular/google-maps
// import { MapTypeStyle } from '@agm/core';

@Injectable()
export class SiteMapService {

  constructor(private http: HttpClient) {
   }

    // TODO: Migrate to @angular/google-maps
    //https://mapstyle.withgoogle.com/
    mapStyles: google.maps.MapTypeStyle[] = [
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'labels.text',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'poi.business',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'labels.icon',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'road.local',
          'elementType': 'labels',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        },
        {
          'featureType': 'transit',
          'stylers': [
            {
              'visibility': 'off'
            }
          ]
        }
      ];

    getSitesToMap(sitesToMapRequest: Core.SitesToMapRequest): Promise<Core.SitesToMapResult> {
        return lastValueFrom(this.http.post<Core.SitesToMapResult>('/api/GeoMapping/FetchSites', sitesToMapRequest));
    }

    getSiteMarkerInfo(siteIds: number[]): Promise<Core.SiteMarkerInfoGetResult> {
        return lastValueFrom(this.http.post<Core.SiteMarkerInfoGetResult>('/api/geomapping/site/markers', { siteIds }));
    }
}
