import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AccountingPeriod, AccountingPeriodsRequest, CompanyAccounting, CompanyFiscalYear, NewFiscalYearRequest } from './accounting.periods.model';
import { AccountingPeriodsService } from './accounting.periods.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Constants } from '../../../../constants.new';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'accounting-periods-modal',
    templateUrl: './accounting.periods.modal.component.html',
    styleUrls: ['./accounting.periods.modal.component.scss']
})
export class AccountingPeriodsModalComponent implements OnInit {
    constructor(private bsModalRef: BsModalRef,
        private accountingPeriodsService: AccountingPeriodsService,
        private constants: Constants,
        private toastr: ToastrService) {}

    //initialState
    companyId: number;
    canEdit: boolean;

    loading: boolean = false;
    editMode: boolean = false;
    canDelete: boolean = false;
    deleting: boolean = false;
    companyAccounting: CompanyAccounting;
    selectedFiscalYear: CompanyFiscalYear;
    selectedFiscalYearDurationText: string;
    originalSelectedFiscalYear: CompanyFiscalYear;

    showAddToBeginningCheckbox: boolean = false;
    durationTypes: {label: string, id: number}[];

    placementChosen: boolean = false;
    beginningChosen: boolean = false;
    forcePlacement: boolean = false;
    newFiscalYearRequest: NewFiscalYearRequest;


    async ngOnInit() {
        this.loading = true;

        try {
            this.durationTypes = _.map(this.constants.AccountingDurationTypes, (label: string, id: string) => {
                return {label: label, id: Number(id)};
            });
            await this._initPeriodData(0);
        } finally {
            this.loading = false;
        }
    }

    async _initPeriodData(fiscalYearIdx: number): Promise<void> {
        this.companyAccounting =  await this.accountingPeriodsService.getAccountingPeriods(this.companyId);
        this.companyAccounting.companyFiscalYears = _.chain(this.companyAccounting.companyFiscalYears)
            .map(year => {
                year.accountingPeriods = _.map(year.accountingPeriods, period => {
                    period.durationText = this._getDurationText(year, period);
                    return period;
                });

                return year;
            })
            .orderBy('startDate', 'desc')
            .value();

        if(this.companyAccounting.companyFiscalYears.length) {
            this.selectedFiscalYear = fiscalYearIdx == 999 ? _.last(this.companyAccounting.companyFiscalYears) : this.companyAccounting.companyFiscalYears[fiscalYearIdx];
        } else {
            this.selectedFiscalYear = undefined;
        }
    }

    goToEditMode(): void {
        this.originalSelectedFiscalYear = _.cloneDeep(this.selectedFiscalYear);
        this.editMode = true;
        const firstYear = _.first(this.companyAccounting.companyFiscalYears);
        this.canDelete = firstYear === this.selectedFiscalYear && !this.selectedFiscalYear.accountingPeriods.some(ap => ap.isFrozen);
    }

    beginDelete(): void {
        this.deleting = true;
    }

    cancelDelete(): void {
        this.deleting = false;
    }

    async delete(): Promise<void> {
        this.loading = true;
        this.cancel();
        this.deleting = false;
        try {
            await this.accountingPeriodsService.deleteFiscalYear(
                this.selectedFiscalYear.companyId,
                this.selectedFiscalYear.accountingPeriods[0].companyFiscalYearId,
                this.companyAccounting.companyRowVersion
            );
        }
        finally {
            await this._initPeriodData(0);
            this.loading = false;
        }
    }

    cancel(): void {
        _.assign(this.selectedFiscalYear, this.originalSelectedFiscalYear);
        this.editMode = false;
    }

    async save(): Promise<void> {
        if(this._arePeriodsInvalid()) {
            return;
        }

        const request = new AccountingPeriodsRequest();

        request.companyRowVersion = this.companyAccounting.companyRowVersion;
        request.companyFiscalYearId = this.selectedFiscalYear.companyFiscalYearId;
        if(this.selectedFiscalYear.name !== this.originalSelectedFiscalYear.name) {
            request.fiscalYearName = this.selectedFiscalYear.name;
            request.efAction = 'update';
        }
        request.accountingPeriods = _.map(this.selectedFiscalYear.accountingPeriods, period => {
            const originalPeriod = _.find(this.originalSelectedFiscalYear.accountingPeriods, {accountingPeriodId: period.accountingPeriodId});
            if(!period.efAction && !_.isEqual(period, originalPeriod)) {
                period.efAction = 'update';
            }

            return period;
        });

        this.loading = true;

        try {
            await this.accountingPeriodsService.updateAccountingPeriods(this.companyId, request);
            const fiscalYearIdx = _.findIndex(this.companyAccounting.companyFiscalYears, {companyFiscalYearId: this.selectedFiscalYear.companyFiscalYearId});
            await this._initPeriodData(fiscalYearIdx);
            this.editMode = false;
        } finally {
            this.loading = false;
        }
    }

    dateChange(type: 'start' | 'end', i: number): void {
    //     let periods = this.undeletedPeriods();

    //     if(type == 'start' && i > 0) {
    //         periods[i-1].endDate = moment(periods[i].startDate).subtract(1, 'days').toDate();
    //     } else if(i < periods.length - 1) {
    //         periods[i+1].startDate = moment(periods[i].endDate).add(1, 'days').toDate();
    //     }
    }

    addSinglePeriod(): void {
        // if(_.last(this.undeletedPeriods()).endDate >= this.selectedFiscalYear.endDate) {
        //     this.toastr.error("Latest period's End Date must be before Fiscal year's End Date to add new period.");
        //     return;
        // }

        const periodToAdd = new AccountingPeriod();
        const lastExistingPeriod = _.last(this.undeletedPeriods());
        periodToAdd.startDate = moment(lastExistingPeriod.endDate).add(1, 'days').toDate();
        // periodToAdd.endDate = this.selectedFiscalYear.endDate;
        periodToAdd.efAction = 'add';
        periodToAdd.companyFiscalYearId = this.selectedFiscalYear.companyFiscalYearId;
        this.selectedFiscalYear.accountingPeriods.push(periodToAdd);
    }

    undeletedPeriods(): AccountingPeriod[] {
        return _.reject(this.selectedFiscalYear.accountingPeriods, {efAction: 'delete'});
    }

    remove(period: AccountingPeriod): void {
        if(period.efAction == 'add') {
            this.selectedFiscalYear.accountingPeriods.pop();
        } else {
            period.efAction = 'delete';
        }

        //removing bottom period has to change date of the new last period to end of fiscal year
        // _.last(this.undeletedPeriods()).endDate = this.selectedFiscalYear.endDate;
    }

    choosePlacement(placement: 'beginning' | 'end' | 'new', e?): void {
        if(e) {
            e.stopPropagation();
        }

        this.newFiscalYearRequest = new NewFiscalYearRequest();
        this.newFiscalYearRequest.accountingDurationTypeId = this.durationTypes[0].id;
        this.newFiscalYearRequest.companyRowVersion = this.companyAccounting.companyRowVersion;

        this.placementChosen = true;

        switch(placement) {
            case 'beginning':
                this.newFiscalYearRequest.endDate = moment(_.last(this.companyAccounting.companyFiscalYears).startDate).subtract(1, 'days').toDate();
                this.beginningChosen = true;
                break;
            case 'end':
                this.newFiscalYearRequest.startDate = moment(_.first(this.companyAccounting.companyFiscalYears).endDate).add(1, 'days').toDate();
                this.beginningChosen = false;
                break;
            case 'new':
                this.beginningChosen = true;
                break;
        }
    }

    addFiscalYearClicked(): void {
        this.beginningChosen = false;
        this.forcePlacement = false;
        this.placementChosen = false;

        if(!this.companyAccounting.companyFiscalYears.length) {
            this.forcePlacement = true;
            this.choosePlacement('new');
            return;
        }

        const allowAddToBeginning = _.every(this.companyAccounting.companyFiscalYears, year => _.every(year.accountingPeriods, ['isFrozen', false]));

        if(!allowAddToBeginning) {
            this.forcePlacement = true;
            this.choosePlacement('end');
        }
    }

    async addFiscalYear(): Promise<void> {
        if(this.newFiscalYearRequest.startDate >= this.newFiscalYearRequest.endDate) {
            this.toastr.error('Begin Date must be before End Date');
            return;
        }

        const duplicateName = _.some(this.companyAccounting.companyFiscalYears, fiscalYear => _.lowerCase(fiscalYear.name) == _.lowerCase(this.newFiscalYearRequest.name));

        if(duplicateName) {
            this.toastr.error('Name already exists');
            return;
        }

        this.loading = true;

        try {
            await this.accountingPeriodsService.addNewFiscalYear(this.companyId, this.newFiscalYearRequest);
        } catch(e) {
            if(e.error && e.error.message) {
                this.toastr.error(e.error.message);
            }
        } finally {
            await this._initPeriodData(this.beginningChosen ? 999 : 0);
            this.loading = false;
        }
    }

    close(): void {
        this.bsModalRef.hide();
        // this.onClose();
    }

    private _getDurationText(year: CompanyFiscalYear, period: AccountingPeriod): string {
        switch (year.accountingDurationTypeId) {
            case Core.AccountingDurationTypeEnum.Monthly:
                return '1 Mo';
            case Core.AccountingDurationTypeEnum.Quarterly:
                return '1 Qtr';
            default:
                return `${moment(period.endDate).diff(period.startDate, 'days') + 1} days`;
        }
    }

    private _arePeriodsInvalid(): boolean {
        const periods = this.undeletedPeriods();

        if(!periods.length) {
            return false;
        }

        if(!moment(periods[0].startDate).isSame(this.selectedFiscalYear.startDate)) {
            this.toastr.error('The first period\'s Begin Date must match the Fiscal Year\'s Begin Date');
            return true;
        }

        if(!moment(_.last(periods).endDate).isSame(this.selectedFiscalYear.endDate)) {
            this.toastr.error('The last period\'s End Date must match the Fiscal Year\'s End Date');
            return true;
        }

        if(!_.every(periods, 'name')) {
            this.toastr.error('\'Name\' is required on all periods');
            return true;
        }

        const isContinuous = _.every(periods, (period, i) => {
            if(i < periods.length - 1) {
                return moment(period.endDate).add(1, 'days').isSame(periods[i+1].startDate);
            } else {
                return true;
            }
        });

        if(!isContinuous) {
            this.toastr.error('There cannot be any gaps or overlaps between periods');
            return true;
        }

        return false;
    }

}
