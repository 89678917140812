import { Component, Inject, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { AttachmentModalData, AttachmentModalEntityData } from '../attachment.modal.model';
import { CurrentEntity, CurrentEntityService } from 'src/app/Common/Entity/CurrentEntityService';
import { EntityTypeLevels } from 'src/app/constants.new';
import { RestrictData, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { AttachmentService } from '../attachment.service';
import { chain, cloneDeep, compact, filter, find, findIndex, head, includes, intersection, last, map, some } from 'lodash';
import { Attachment, AttachmentCategory } from '../attachment.model';
import { UtilitiesService } from 'src/app/UI-Lib/Utilities';
import * as moment from 'moment';
import { DocumentServiceUpgrade } from 'src/app/Documents/document.service.upgrade';
import { IPopUpRef, PopUpService } from 'src/app/Common/Popup/popup.service';
import { HelpService } from 'src/app/UI-Lib/Help-Tooltip';
import { ATTACHMENT_MODAL_HELP } from './attachmentModal.component.help';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';

interface AttachmentListItem extends Attachment {
    lineOne: string;
    lineTwo: string;
    lineThree: string;
    active: boolean;
}

interface PopupData {
    attachmentInfo: {
        attachmentID: string;
        fileName: string;
        fileExtension: string;
    }
}

export interface AttachmentModalResult {
    count: number;
    hasImages: boolean;
    selectedAttachmentId?: System.Guid;
    selectedAttachmentFileName?: string;
}

@Component({
    selector: 'attachment-modal',
    templateUrl: 'attachmentModal.component.html',
    styleUrls: ['./attachmentModal.component.scss']
})
export class AttachmentModalComponent implements OnInit, IWeissmanModalComponent<AttachmentModalData, AttachmentModalResult> {
    constructor(private readonly _bsModalRef: BsModalRef,
            private readonly _currentEntityService: CurrentEntityService,
            private readonly _navService: UpgradeNavigationServiceHandler,
            private readonly _documentService: DocumentServiceUpgrade,
            private readonly _attachmentService: AttachmentService,
            private readonly _utilities: UtilitiesService,
            private readonly _popupService: PopUpService,
            private readonly _helpService: HelpService,
            @Inject('$rootScope') private _rootScope: any,
            private readonly _restrictService: RestrictService,
            private readonly _messageBoxService: MessageBoxService
        ) {}
    params: AttachmentModalData;
    result: AttachmentModalResult;

    subHeaderLabel: string;
    childLabel: string;
    currentEntity: AttachmentModalEntityData | CurrentEntity;
    includeChildren: boolean = false;
    skipEntityService: boolean = false;
    allowEntityEdit: boolean = false;
    editMode: boolean = false;
    attachmentTypes: Core.AttachmentTypeModel[];
    categoryFilterId: number;
    yearFilter: number;
    selectedAttachment: AttachmentListItem;
    isBelowParcelLevel: boolean = false;
    viewFlags: {isListHidden: boolean; isViewerFullWidth: boolean } = {
        isListHidden: false,
        isViewerFullWidth: false
    };
    currentEntityForForm: AttachmentModalEntityData | CurrentEntity;
    file: File;
    hovered: boolean = false;
    loading: boolean = false;

    private readonly _CATEGORY_ID_ALL = -1;
    private readonly _CATEGORY_ID_PHOTOS = -2;
    private _attachments: AttachmentListItem[] = [];
    private _imageCategoryIds: number[]= [];
    private _cachedAttachments: AttachmentListItem[] = [];
    private _cachedParentAttachments: AttachmentListItem[] = [];
    private _numberOfDistinctEntities: number;
    private _attachmentCategories: AttachmentCategory[];
    private _years: Compliance.NameValuePair<number>[] = [];

    async ngOnInit(): Promise<void> {
        // entityData is not normally specified; I added it a while after the attachment modal code was written so I didn't
        // have to use currentEntityService for document processing. In a perfect world we'd refactor this and get rid of
        // currentEntityService entirely.
        // Also, an additional hack here is that later on, the entity service is used in the edit form; we need to know if we
        // used this.params.entityData or currentEntityService (see comments around the edit form function)
        if (this.params.belowParcelEntity) {
            this.currentEntity = this.params.belowParcelEntity;
        }
        else if (this.params.entityData) {
            this.currentEntity = this.params.entityData;
            this.skipEntityService = true;
        }
        else {
            this.currentEntity = this._currentEntityService.get();
        }

        this.isBelowParcelLevel = EntityTypeLevels[this.currentEntity?.typeId] < EntityTypeLevels[Core.EntityTypes.Parcel];
        this.includeChildren = this.params.entityType === 'Parcel';
        this._helpService.setContent(ATTACHMENT_MODAL_HELP);

        if (this.params.startOnPhotos) {
            this.viewFlags.isListHidden = true;
            this.viewFlags.isViewerFullWidth = true;
        }

        this.loading = true;
        try {
            await this._setPermissions();

            this.attachmentTypes = await this._attachmentService.getAttachmentTypes();
            this._imageCategoryIds = chain(this.attachmentTypes)
                .filter('isImage')
                .map('attachmentCategoryID')
                .uniq()
                .value();

            this._attachmentCategories = await this._attachmentService.getAttachmentCategories();
            this._attachmentCategories.unshift({
                attachmentCategoryID: this._CATEGORY_ID_ALL,
                category: 'Attachments',
                efAction: null
            }, {
                attachmentCategoryID: this._CATEGORY_ID_PHOTOS,
                category: 'Photos/Plats',
                efAction: null
            });

            this.categoryFilterId = this.params.startOnPhotos ? this._CATEGORY_ID_PHOTOS : (this.params.attachmentCategoryId || this._CATEGORY_ID_ALL);
        } finally {
            this.loading = false;
        }

        await this.getAllAttachments();
    }

    get headerLabel(): string {
        let headerLabel = `${this.params.parentType || this.params.entityType}`;
        headerLabel += this.params.entityName ? `: ${this.params.entityName}` : '';
        headerLabel += this.params.entityDescription ? `, ${this.params.entityDescription}` : '';

        return headerLabel;
    }

    get includeSubEntities(): boolean {
        return EntityTypeLevels[this.currentEntity?.typeId] > EntityTypeLevels[Core.EntityTypes.Parcel] && (this.includeChildren || this._numberOfDistinctEntities > 1);
    }

    get filteredYears(): Compliance.NameValuePair<number>[] {
        if(this.params.singleYear) {
            return this._years;
        }

        const filteredAttachmentYears = chain(this._attachments)
            .filter(x => this._passesCategoryFilter(x))
            .map('year')
            .uniq()
            .value();

        return filter(this._years, year => {
			return !year.value
				|| (year.value === -1 && filteredAttachmentYears.length !== compact(filteredAttachmentYears).length)
				|| includes(filteredAttachmentYears, year.value)
				|| this.isBelowParcelLevel;
        });
    }

    get filteredAttachments(): AttachmentListItem [] {
        return filter(this._attachments, attachment => {
            return attachment === undefined
                || (this._passesYearFilter(attachment) && this._passesCategoryFilter(attachment));
        });
    }

    get filteredCategories(): AttachmentCategory[] {
        const uniqueCategoryIds = chain(this._attachments)
            .filter(x => this._passesYearFilter(x))
            .map('attachmentCategoryID')
            .uniq()
            .value();

        const imageAttachmentExists = intersection(uniqueCategoryIds, this._imageCategoryIds).length > 0;

        return filter(this._attachmentCategories, category => {
            return includes(uniqueCategoryIds, category.attachmentCategoryID)
                || category.attachmentCategoryID === this._CATEGORY_ID_ALL
                || (category.attachmentCategoryID === this._CATEGORY_ID_PHOTOS && imageAttachmentExists);
        });
    }

    get isFirstAttachment() {
        return this.selectedAttachment?.attachmentID === head(this.filteredAttachments).attachmentID;
    }

    get isLastAttachment() {
        return this.selectedAttachment?.attachmentID === last(this.filteredAttachments).attachmentID;
    }

    async getAllAttachments() {
        this.loading = true;

        try {
            await this.selectAttachment(null);
            this._attachments = [];

            if(this.includeChildren && this._cachedParentAttachments.length) {
                this._attachments = this._cachedParentAttachments;
            } else if(!this.includeChildren && this._cachedAttachments.length) {
                this._attachments = this._cachedAttachments;
            } else {
                await this._getAttachmentsForCurrentEntity();

                if(this.includeChildren) {
                    this._cachedParentAttachments = cloneDeep(this._attachments);
                } else {
                    this._cachedAttachments = cloneDeep(this._attachments);
                }
            }
        } finally {
            this.loading = false;
        }
    }

    async selectAttachment(attachment: AttachmentListItem) {
        this.file = undefined;
        this._attachments = map(this._attachments, a => {
            if (a !== null) {
                a.active = false;
            }
            return a;
        });

        this.selectedAttachment = attachment;

        if (this.selectedAttachment === null) {
            return;
        }

        this.selectedAttachment.active = true;
        this._utilities.focusOnElement('.active', 0);

        const { previewSupported, type } = this._attachmentService.getMimeTypeByFileExtension(this.selectedAttachment?.fileExtension);

        if (previewSupported) {
            try {
                const response = await this._documentService.getDocumentBody(this.selectedAttachment.attachmentID);
                const fileName = this._attachmentService.getFileNameFromHttpResponse(response, true);
                const fileNameType = fileName.split('.')[1].substring(0, 3);
                const blob = new Blob([response.data], { type: fileNameType });

                    this.file = new File([blob], fileName, { type });
            } catch {
                // this.fileError = true;
            }
        }

        // notify pop-up window that that attachment changed
        if (this.selectedAttachment) {
            setTimeout(() => {
                const globalAttachmentsPopUpRef = this._popupService.getByAliasGroup(this._popupService.StaticAliases.Attachment)[0];
                globalAttachmentsPopUpRef && this._notifyChildWindow(globalAttachmentsPopUpRef, this._getPopUpData(this.selectedAttachment));
            });
        }
    }

    async selectFirstAttachment() {
        await new Promise(resolve => setTimeout(resolve, 500));
        const attachment = this.filteredAttachments.length ? this.filteredAttachments[0] : null;

        await this.selectAttachment(attachment);
    }

    async loadParentAttachments() {
        if (!this.isBelowParcelLevel) {
            return;
        }

        // TODO: include logic to show/hide _attachments based on level.
        const id = this.params.parentId || +this._navService.getQuerystringParam('parcelId');
        const parentType = this.params.parentTypeId || Core.EntityTypes.Parcel;

        this._attachments = [];
        this.params.entityDescription = '';
        this.isBelowParcelLevel = false;

        this.loading = true;
        try {
            const results = await this._attachmentService.getAll(parentType, id, true);
            this._buildAttachmentList(results);
            await this.selectFirstAttachment();
        } finally {
            this.loading = false;
        }
    }

    addAttachment() {
        this.selectedAttachment = null;
        this.editAttachment();
    }

    editAttachment() {
        this.currentEntityForForm = (this.isBelowParcelLevel || this.skipEntityService)
            ? this.currentEntity
            : this._currentEntityService.get();

        this.editMode = true;
    }

    async downloadAttachment() {
        await this._attachmentService.downloadAttachmentFile(this.selectedAttachment);
    }

    async attachmentFormUpdated(savedAttachment: Attachment) {
        this.editMode = false;
        this.loading = true;

        try {
            await this._getAttachmentsForCurrentEntity();

            const idx = findIndex(this._attachments, {attachmentID: savedAttachment.attachmentID});
            this.selectAddedOrUpdatedAttachment(this._attachments[idx]);
        } finally {
            this.loading = false;
        }
    }

    async attachmentFormCreated(savedAttachment: Attachment) {
        this._cachedAttachments = [];
        this.attachmentFormUpdated(savedAttachment);
    }

    async attachmentFormDeleted() {
        this.editMode = false;
        this._cachedAttachments = [];

        this.loading = true;
        try {
            await this._getAttachmentsForCurrentEntity();
            await this.selectFirstAttachment();
        } finally {
            this.loading = false;
        }
    }

    async attachmentFormCancelled() {
        this.editMode = false;

        this.loading = true;
        try {
            if (this.selectedAttachment) {
                await this.selectAttachment(this.selectedAttachment);
            } else {
                await this.selectFirstAttachment();
            }
        } finally {
            this.loading = false;
        }
    }

    async selectAddedOrUpdatedAttachment(attachment: AttachmentListItem) {
        // if (!this.isBelowParcelLevel) {
            // Not sure why this clears out the yearFilter, but if the attachment modal
            // was opened for a specific year, we need to keep that around (see WK-3856)
            // This is a change; originally this was:
            // this.yearFilter = null;
            // const year = this._years.find(x => x.value === this.params.year);
            // this.yearFilter = year?.value || null;
        // }

        this.categoryFilterId = this.params.attachmentCategoryId || this._CATEGORY_ID_ALL;

        this.loading = true;

        try {
            await this.selectAttachment(attachment);
        } finally {
            this.loading = false;
        }
    }

    displayDate(date: Date): string {
        return moment(date).fromNow();
    }

    toggleList() {
        const viewerWidthDelay = 200;

        if(this.viewFlags.isViewerFullWidth) {
            this.viewFlags.isListHidden = !this.viewFlags.isListHidden;

            setTimeout(() => {
                this.viewFlags.isViewerFullWidth = !this.viewFlags.isViewerFullWidth;
            }, 0);
        } else {
            this.viewFlags.isViewerFullWidth = !this.viewFlags.isViewerFullWidth;

            setTimeout(() => {
                this.viewFlags.isListHidden = !this.viewFlags.isListHidden;
            }, viewerWidthDelay);
        }

    }

    async popOutAttachment(isDynamic: boolean) {
        const popUpData = this._getPopUpData(this.selectedAttachment);

        if (!isDynamic) {
            const popUpRef = await this._popupService.openAttachmentPreview();
                this._notifyChildWindow(popUpRef, popUpData);
            return;
        }

        const globalAttachmentsPopUpRef = this._popupService.getByAliasGroup(this._popupService.StaticAliases.Attachment)[0];

        if (globalAttachmentsPopUpRef?.isOpen()) {
            this._notifyChildWindow(globalAttachmentsPopUpRef, popUpData);
            return;
        }

        const popUpRef = await this._popupService.openAttachmentPreview(this._popupService.StaticAliases.Attachment, globalAttachmentsPopUpRef);
        this._notifyChildWindow(popUpRef, popUpData);
    }

    async getNext() {
        const currentIndex = findIndex(this.filteredAttachments, this.selectedAttachment);
        const nextAttachment = this.filteredAttachments[currentIndex + 1];

        if (nextAttachment) {
            this.loading = true;

            try {
                await this.selectAttachment(nextAttachment);
            } finally {
                this.loading = false;
            }
        }
    }

    async getPrev() {
        const currentIndex = findIndex(this.filteredAttachments, this.selectedAttachment);
        const prevAttachment = this.filteredAttachments[currentIndex - 1];

        if (prevAttachment) {
            this.loading = true;

            try {
                await this.selectAttachment(prevAttachment);
            } finally {
                this.loading = false;
            }
        }
    }

    close() {
        this._composeDialogResult();
        this._rootScope.$emit('attachment.modal.closing');
        this._bsModalRef.hide();
    }

    async emailAttachment(): Promise<void> {
        this.result = {
            count: 1,
            hasImages: false,
            selectedAttachmentId: this.selectedAttachment.attachmentID,
            selectedAttachmentFileName: `${this.selectedAttachment.fileName}.${this.selectedAttachment.fileExtension}`
        };

        if (this.params.selectedAttachmentId && this.selectedAttachment.attachmentID !== this.params.selectedAttachmentId) {
            if ((await this._messageBoxService.open({
                message: 'An attachment for this site has already been included. Do you want to replace the existing attachment?',
                buttons: MessageBoxButtons.OKCancel
            })) !== MessageBoxResult.OK) {
                this.result.selectedAttachmentId = this.params.selectedAttachmentId;
                this.result.selectedAttachmentFileName = null;
            }
        }

        this._bsModalRef.hide();
    }

    private async _setPermissions(): Promise<void> {
        let isCompany = false;
        let entityId = Number(this.currentEntity.id);
        if (this.params.entityType === 'Company') {
            isCompany = true;
            this.childLabel = 'Sites';
        } else if (this.params.entityType === 'Site') {
            this.childLabel = 'Parcels';
        } else {
            entityId = +this._navService.getQuerystringParam('siteId') || undefined;
        }

        const restrictionData = {
            isCompany,
            entityId,
            roles: [],
            flag: Core.AccessRightsEnum.Write
        } as RestrictData;

        const hasPermission = await this._restrictService.hasPermission(restrictionData);
        this.allowEntityEdit = hasPermission
            && (this.params.readOnly === null
                || this.params.readOnly === undefined
                || !this.params.readOnly);
    }

    private async _getAttachmentsForCurrentEntity() {
        const results = await this._attachmentService.getAll(this.currentEntity.typeId, +this.currentEntity.id, this.includeChildren);
        this._buildAttachmentList(results);
        await this.selectFirstAttachment();
    }

    private _buildAttachmentList(attachments: Attachment[]): void {
        this._attachments = map(attachments, a => {
            let lineOne = this.currentEntity.typeId === a.entityTypeID ? this.currentEntity.name : a.entityName;
            let lineTwo = a.year?.toString() || '';
            const lineThree = a.description;

            const { attachmentTypeName } = find(this.attachmentTypes, { attachmentTypeID: a.attachmentTypeID });
            lineTwo += ` ${attachmentTypeName || ''}`;

            if (EntityTypeLevels[a.entityTypeID] < EntityTypeLevels[Core.EntityTypes.Parcel]) {
                if (a.entityName !== null) {
                    lineTwo += ` ${  a.entityName.replace(a.year?.toString(), '')}`;
                }
                if (this.includeChildren) { // include parcel account number when user clicks 'Include Parcel'
                    lineOne = a.parentEntityName;
                }
            }

            return {
                ...a,
                lineOne, lineTwo, lineThree,
                active: false
            };
        });

        this._numberOfDistinctEntities = chain(this._attachments)
            .countBy('entityTypeID')
            .toArray()
            .value()
            .length;

        if(this.isBelowParcelLevel || (this.params.disableYears && this.params.year)) {
            this._years = [{
                name: this.params.year?.toString(),
                value: this.params.year
            }];

            this.yearFilter = this.params.year;
        } else {
            this._years =  chain(this._attachments)
                .filter('year')
                .uniqBy('year')
                .map(a => {
                    return {
                        name: a.year?.toString(),
                        value: a.year
                    };
                })
                .orderBy(['value'], ['desc'])
                .unshift({
                    name: 'No Year',
                    value: -1
                })
                .unshift({
                    name: 'All',
                    value: null
                })
                .value();
            this.yearFilter = this._years[0].value;
        }

        this._attachments.sort((a, b) => this._sortAttachments(a, b));
    }

    private _sortAttachments(item1: AttachmentListItem, item2: AttachmentListItem) {
        /*
            List items should be sorted by:
                Entity Name (Line 1)  value (if present),
                Tax Year DESC with blanks last,
                Attachment Category ASC,
                extra information for entity lower than a parcel ASC,
                Attachment Description ASC
        */

        /*

            First Line: Entity Name value to which the attachment is related (show just parcel for levels below parcel).
            Line suppressed unless showing mixed entities in attachment list.

            If the attachment is attached deeper than the parcel level (assessment, tax bill, etc.), the first line continues with a
            space + hyphen + space then, if an assessment, the revision description or, if a tax bill, the additional information shown in the tab header of the tax bill itself + the bill name.

            Second Line: Year (if available) + space (if there was a year) + Attachment Type.

            Third Line: Attachment Description. Line suppressed if there is no attachment description for the attachment.
        */

        let returnVal = 0;

        if (this.includeChildren) {
            // sort by entity name - we always have it if we include children
            if (
                //   EntityTypeLevels[item1.enityTypeID] <= EntityTypeLevels[EntityTypeIDs.PARCEL]
                //&& EntityTypeLevels[item2.enityTypeID] <= EntityTypeLevels[EntityTypeIDs.PARCEL]
                //&&
                item1.lineOne < item2.lineOne) {
                returnVal = -1;
            }
            if (
                //   EntityTypeLevels[item1.enityTypeID] <= EntityTypeLevels[EntityTypeIDs.PARCEL]
                //&& EntityTypeLevels[item2.enityTypeID] <= EntityTypeLevels[EntityTypeIDs.PARCEL]
                //&&
                item1.lineOne > item2.lineOne) {
                returnVal = 1;
            }

            if (returnVal !== 0) {
                return returnVal;
            }
        }

        // next by year DESC. force null years to the bottom
        const year1 = item1.year;
        const year2 = item2.year;

        item1.year = year1 || 0;
        item2.year = year2 || 0;

        if (item2.year < item1.year) {
            returnVal = -1;
        }
        if (item2.year > item1.year) {
            returnVal = 1;
        }
        item1.year = year1; // put back the original value
        item2.year = year2; // put back the original value

        if (returnVal !== 0) {
            return returnVal;
        }

        // next by category
        const name1 = this._getAttachmentTypeName(item1.attachmentTypeID);
        const name2 = this._getAttachmentTypeName(item2.attachmentTypeID);

        if (name1 < name2) {
            returnVal = -1;
        }
        if (name1 > name2) {
            returnVal = 1;
        }
        if (returnVal !== 0) {
            return returnVal;
        }

        // next by description
        if (item1.description < item2.description) {
            returnVal = -1;
        }
        if (item1.description > item2.description) {
            returnVal = 1;
        }

        return returnVal;
    }

    private _getAttachmentTypeName(attachmentTypeID: number): string {
        const { attachmentTypeName } = find(this.attachmentTypes, { attachmentTypeID });
        return attachmentTypeName || '';
    }

    private _passesCategoryFilter(item: AttachmentListItem): boolean {
        const isImage = includes(this._imageCategoryIds, item.attachmentCategoryID);

        return this.categoryFilterId === null
            || item.attachmentCategoryID === this.categoryFilterId
            || this.categoryFilterId === this._CATEGORY_ID_ALL
            || (this.categoryFilterId === this._CATEGORY_ID_PHOTOS && isImage);
    }

    private _passesYearFilter(item: AttachmentListItem) {
        // item.year could be int, this.yearFilter  is string
        return item.year == this.yearFilter
            || this.yearFilter === null
            || (this.yearFilter === -1 && !item.year);

    }

    private _getPopUpData(attachment: Attachment): PopupData {
        return {
            attachmentInfo: {
                attachmentID: attachment.attachmentID,
                fileName: attachment.fileName,
                fileExtension: attachment.fileExtension
            }
        };
    }

    private _notifyChildWindow(popUpRef: IPopUpRef, popUpData: PopupData) {
        popUpRef.isOpen() && popUpRef.publish(this._popupService.Topics.Attachment.AttachmentChanged, popUpData);
    }

    private _composeDialogResult() {
        if (this.loading) {
            // we are closing while dialog is still loading data; we don't want to update calling icon etc
            return null;
        }

        let entityAttachments = this._attachments;
        if(this.currentEntity.typeId !== Core.EntityTypes.Parcel) {
            entityAttachments = filter(this._attachments, {
                entityTypeID: this.currentEntity.typeId
            });
        }

        if (this.params.disableYears && this.params.year) {
            entityAttachments = filter(entityAttachments, {
                year: this.params.year
            });
        }

        const hasImages = some(entityAttachments, attachment => includes(this._imageCategoryIds, attachment.attachmentCategoryID));

        this.result = {
            count: entityAttachments.length,
            hasImages
        };
    }
}
