import { DecimalPipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { Decimal } from 'decimal.js';
import { ToastrService } from 'ngx-toastr';
import { AttachmentModalData, AttachmentModalEntityData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AnnualAssessmentDetail, Assessment } from './assessment.model';

import { map, sortBy } from 'lodash/fp';
import * as _ from 'lodash';
const ASSESSMENT_ENTITY_TYPE_ID = 7;

@Injectable()
export class AssessmentService {
  constructor(
      private http: SDHttpService,
      private annualDetailsService: AnnualDetailsService,
      private decimalPipe: DecimalPipe,
      public toastr: ToastrService
  ) {}

  getAssessment(assessmentID: number): Promise<Assessment> {
    return this.http.get(`/api/annualassessment/${  assessmentID}`);
  }

  async getAssessmentViewModelByID(assessmentID: number, assessorID: number, parcelID: number, parcelAcctNum: string, annualYear: AnnualDetailYear): Promise<AssessmentViewModel> {
    const assessment = assessmentID ? await this.getAssessment(assessmentID) : new Assessment(annualYear.annualYearID, 0, 0, 'add', []);
    const viewModel = this.buildAssessmentViewModel(assessment);

    viewModel.assessorID = assessorID;
    viewModel.parcelID = parcelID;
    viewModel.parcelAcctNum = parcelAcctNum;
    viewModel.currentYear = annualYear;

    return viewModel;
  }

  getAssessmentViewModelByAssessmentID(assessmentID: number): Promise<AssessmentViewModel> {
    return this.http.get(`api/annualassessmentview/assessment/${  assessmentID}`).then((result: AssessmentViewDTO) => {
      const vm = this.buildAssessmentViewModel(result.assessment);
      vm.assessorID = result.assessorID;
      vm.components = result.componentTypes;
      vm.currentYear = result.annualYearView;
      return vm;
    });
  }

  getAssessmentComponent(params): Promise<AnnualAssessmentDetail> {
    return this.http.get('/api/AnnualAssessmentDetail', {
      params: params
    });
  }

  saveAssessmentFromViewModel(viewModel: AssessmentViewModel): Promise<Assessment> {

    //if the year's autocalculate has been set to false, it wasn't saved yet, so we must save it
    if ((viewModel.dirtyAnnualYear && !viewModel.currentYear.calcProjected) || viewModel.dirtyTargetValue) {
      return this.annualDetailsService.saveYear(viewModel.currentYear).then((result) => {
        viewModel.currentYear.rowVersion = result.rowVersion;

        const resultModel = _.find(result.annualGridDetails, (detailModel) => {
          return detailModel.annualAssessmentID === viewModel.model.annualAssessmentID;
        });

        if (resultModel){
          viewModel.model.rowVersion = resultModel.rowVersion;
        }

        /*
                if (viewModel.currentYear.annualGridDetails){
                  _.forEach(viewModel.currentYear.annualGridDetails, (gridModel) => {
                    var resultModel = _.find(result.annualGridDetails, (detailModel) => {
                      return detailModel.annualAssessmentID === gridModel.annualAssessmentID;
                    });

                    if (resultModel){
                      gridModel.rowVersion = resultModel.rowVersion;
                    }
                  })
                }
        */

        // NOTE: It may look like we want "viewModel.dirtyAnnualYear = false;" here, but the dirty flag
        // should tell the grid to reload the year later on, so we don't want to reset that flag yet
        viewModel.dirtyAnnualYear = false;
        viewModel.dirtyTargetValue = false;
        return this.saveAssessment(this.prepareAssessmentForSave(viewModel.model));
      });
    }

    return this.saveAssessment(this.prepareAssessmentForSave(viewModel.model));
  }

  deleteAssessment(assessmentID: number): Promise<any> {
    return this.http.delete(`/api/AnnualAssessment/${  assessmentID}`);
  }

  cancelAssessmentEdit(viewModel: AssessmentViewModel): void {
    viewModel.cancelEdit();
  }

  prepareAssessmentForSave(annualAssessment: Assessment): Assessment {
    const annualAssessmentCopy = _.cloneDeep(annualAssessment);

    annualAssessmentCopy.annualAssessmentDetails = annualAssessmentCopy.annualAssessmentDetails.map((comp) => {
        if (!comp.fmvOverridden) {
          comp.fairMarketValue = null;
        }

        comp.ratio = comp.ratio === comp.assessmentClassRatio.assessmentRatio ? null : comp.ratio;
        return comp;
      });

    if (this.areDeadlinesEqual(annualAssessmentCopy)) {
      annualAssessmentCopy.appealDeadline = null;
    }

    return annualAssessmentCopy;
  }

  areDeadlinesEqual(assessment: Assessment): boolean {
    const appeal = new Date(assessment.appealDeadline);
    const original = new Date(assessment.originalDeadline);

    if (appeal && original) {
      return appeal.getFullYear() === original.getFullYear() &&
        appeal.getMonth() === original.getMonth() &&
        appeal.getDate() === original.getDate();
    } else {
      return !appeal && !original;
    }
  }

  saveAssessment(assessment: Assessment): Promise<Assessment> {
    let totalAV: Decimal = new Decimal(0);

    _.forEach(assessment.annualAssessmentDetails, (assessmentDetail: AnnualAssessmentDetail) => {
        totalAV = totalAV.add(assessmentDetail.assessedValue);
    });

    if(totalAV.toNumber() < 0) {
        this.toastr.error('Cannot save assessment with negative assessed value');
        return Promise.reject('Cannot save assessment with negative assessed value');
    } else {
        return this.http.put('/api/annualassessment/', assessment);
    }
  }

  toggleAssessmentEdit(viewModel: AssessmentViewModel, editMode: boolean): void {
    if (editMode) {
      viewModel.beginEdit();
    }
  }

  prepareAnnualAssessmentForCreate(annualAssessment: Assessment): Assessment {
    const annualAssessmentCopy: Assessment = _.cloneDeep(annualAssessment);

    annualAssessmentCopy.annualAssessmentDetails = annualAssessmentCopy.annualAssessmentDetails
      .filter((item) => {
        return item.efAction !== 'delete';
      })
      .map((comp) => {
        if (!comp.fmvOverridden) {
          comp.fairMarketValue = null;
        }

        comp.ratio = comp.ratio === comp.assessmentClassRatio.assessmentRatio ? null : comp.ratio;
        return comp;
      });

    if (this.areDeadlinesEqual(annualAssessmentCopy)) {
      annualAssessmentCopy.appealDeadline = null;
    }

    return annualAssessmentCopy;
  }

  //This function is basically the opposite of prepareAssessmentForSave
  prepareAnnualAssessment(annualAssessment: Assessment): Assessment {
    annualAssessment.annualAssessmentDetails = _.flow([
        map(function (comp) {
            //Prepare Ratio
            if(comp.ratio === null) {
                comp.ratio = comp.assessmentClassRatio.assessmentRatio;
            }

            //Prepare FMV
            if (comp.fairMarketValue) {
                comp.fmvOverridden = true;
            } else {
                comp.fairMarketValue = new Decimal(comp.assessedValue).dividedBy(comp.ratio).round().toNumber();
            }
            return comp;
        }),
        sortBy('sequence')
    ])(annualAssessment.annualAssessmentDetails);

    //Prepare appeal deadline
    if(annualAssessment.appealDeadline === null) {
      annualAssessment.appealDeadline = annualAssessment.originalDeadline;
    }

    return annualAssessment;
  }

  private buildAssessmentViewModel(result: Assessment) {
    const viewModel = new AssessmentViewModel(this, this.decimalPipe);
    viewModel.model = this.prepareAnnualAssessment(result);

    return viewModel;
  }

}


export class AssessmentViewModel {
  constructor(private assessmentService: AssessmentService, private decimalPipe: DecimalPipe) {}

  model: Assessment;
  isLastAssessment: boolean;
  assessorID: number;
  parcelID: number;
  parcelAcctNum: string;
  components: any[];
  currentYear: AnnualDetailYear;
  // TODO: Consider refactoring this; when originally built, auto-calculate was the only thing from AnnualYear
  // being updated in assessment. Now there's target value, and more may come later. We're handling those fields
  // separately, but we could unify that. For now, "dirtyAnnualYear" means that autoCalc has changed.
  dirtyAnnualYear: boolean;
  dirtyTargetValue: boolean;
  commentModalData: CommentModalData;
  attachmentModalData: AttachmentModalData;
  hasWritePermission: boolean;
  AssessmentChanged: EventEmitter<Assessment>;

  private preEditModelBackup: Assessment;
  // The targetValue is a property on the year, so it needs to get backed up separately
  private preEditTargetValueBackup: number;

  beginEdit(): void {
    if (this.model) {
      this.preEditModelBackup = _.cloneDeep(this.model) as Assessment;
      this.preEditTargetValueBackup = this.currentYear.targetValue;
    }
    else {
      this.preEditModelBackup = undefined;
      this.preEditTargetValueBackup = undefined;
    }
  }

  //used to re-set the backup in cases like autosave when we need to re-set
  // the backup outside of starting edit
  setEditBackup(): void {
      _.assign(this.preEditModelBackup, this.model);
  }

  cancelEdit(): void {
    //if the year has changed, aka if autocalc has changed, change it back
    //we only save the year if we change TO calc, because this generates the numbers
    //this is done poorly but we are restricted by how auto calculate works
    //so this is basically "if changed and true, make it false and save"
    if(this.dirtyAnnualYear && !this.currentYear.calcProjected) {
      this.currentYear.calcProjected = true;
      //this.annualDetailsService.saveYear(this.currentYear);

      this.dirtyAnnualYear = false;
      this.dirtyTargetValue = false;
    }
    this.currentYear.targetValue = this.preEditTargetValueBackup;
    _.assign(this.model, this.preEditModelBackup);
    if(this.AssessmentChanged) {
      this.AssessmentChanged.emit(this.model);
    }
  }

  attachmentIcon(hasAttachments): string {
    return hasAttachments ? 'fa-files-o' : 'fa-paperclip';
  }

  assignFromExistingAssessment(assessment: Assessment): void {
    if (!this.model) {
      throw new Error('Invalid attempt to load assessment from existing data when no current assessment is set');
    }



    if (this.model.annualAssessmentID !== assessment.annualAssessmentID) {
      throw new Error(`Invalid attempt to map assessment with assessmentID ${  assessment.annualAssessmentID
        } into assessment with assessmentID ${  this.model.annualAssessmentID}`);
    }

    _.assign(this.model, this.assessmentService.prepareAnnualAssessment(assessment));
  }

  isActual(): boolean {
    return this.model.status === 1;
  }

  updateModalData(): void {
    const assessment = this.model;
    //let appealLevel = this.appealLevelAbbrDisplay(appeal.appealLevelID);
    const description = `${+this.currentYear.annualYear1  } - ${  assessment.revisionDesc}`;

    // comment data
    this.commentModalData = new CommentModalData();
    this.commentModalData.entityID = assessment.annualAssessmentID;
    this.commentModalData.entityTypeID = ASSESSMENT_ENTITY_TYPE_ID;
    this.commentModalData.canEdit = this.hasWritePermission;
    this.commentModalData.parcelID = this.parcelID;
    this.commentModalData.parcelAcctNum = this.parcelAcctNum;
    this.commentModalData.description = description;
    this.commentModalData.year = this.currentYear.annualYear1.toString();

    // attachment data
    this.attachmentModalData = new AttachmentModalData();
    this.attachmentModalData.belowParcelEntity = new AttachmentModalEntityData();
    this.attachmentModalData.belowParcelEntity.id = assessment.annualAssessmentID;
    this.attachmentModalData.belowParcelEntity.typeId = ASSESSMENT_ENTITY_TYPE_ID;
    this.attachmentModalData.belowParcelEntity.name = assessment.revisionDesc;
    this.attachmentModalData.entityType = 'Assessment';
    this.attachmentModalData.parentId = this.parcelID;
    this.attachmentModalData.parentType = 'Parcel';
    this.attachmentModalData.entityName = this.parcelAcctNum;
    this.attachmentModalData.entityDescription = description;
    this.attachmentModalData.year = this.currentYear.annualYear1;
  }

  recalculateVariables() {
    if (this.currentYear) {
      // "First" in array order is the leftmost on the grid, so it's actually the last one chronologically
      const lastAssessment = _.head(this.currentYear.annualGridDetails);
      this.isLastAssessment = lastAssessment.annualAssessmentID === this.model.annualAssessmentID;
    }

    this.model.annualAssessmentDetails = _.map(this.model.annualAssessmentDetails, (component) => {
      component.displayRatio = +this.decimalPipe.transform(component.ratio * 100, '1.3-3');
      return component;
    });
  }

}

//This is for retrieving all data for the assessment when we're loading it outside the grid
export class AssessmentViewDTO {
  annualYearView: AnnualDetailYear;
  assessment: Assessment;
  assessorID: number;
  componentTypes: any[];
}
