import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardDataService } from '../../dashboard.data.service';
import { MyTasksWidgetSettings, MyTasksView } from './my.tasks.widget.model';

@Component({
    selector: 'my-tasks-widget-settings',
    templateUrl: './my.tasks.widget.settings.component.html'
})
export class MyTasksWidgetSettingsComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private dashboardDataService: DashboardDataService
    ) { }

    loading: boolean;
    userWidgetId: number;
    settings: MyTasksWidgetSettings;
    selectedMyTasksView: MyTasksView;
    myTasksViews: { viewId: MyTasksView, name: string }[];
    onClose: (shouldReload: boolean) => void;


    async ngOnInit() {
        this.myTasksViews = [{
            viewId: MyTasksView.myTasks,
            name: 'My Tasks'
        }, {
            viewId: MyTasksView.myTeamsTasks,
            name: 'My Team\'s Tasks'
        }];

        this.loading = true;

        try {
            this.settings = (await this.dashboardDataService.getWidgetSettings(this.userWidgetId)) as MyTasksWidgetSettings;
            this.selectedMyTasksView = this.settings.myTasksView;
        } finally {
            this.loading = false;
        }
    }

    async saveAndClose() {
        this.loading = true;

        try {
            this.settings.myTasksView = this.selectedMyTasksView;
            await this.dashboardDataService.saveWidgetSettings(this.userWidgetId, this.settings);
            this.onClose(true);
            this.bsModalRef.hide();
        } finally {
            this.loading = false;
        }
    }
}

