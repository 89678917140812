<div [class.container-wide]="!isModal" style="height: 100%;">
    <div [class.single-column-layout]="!isModal" [hidden]="selectedTransmittalID">
        <div *ngIf="companyName" class="entity-header">
            <ol class="breadcrumb float-start" style="background-color: initial;">
                <li class="breadcrumb-item">
                    <a class="pointer" (click)="navigateToCompany()">{{companyName}}</a>
                </li>
            </ol>
            <div class="clearfix"></div>
        </div>
        <div>
            <h1 class="float-start mt-4" style="font-family: 'Oswald'">Bill Payment Packages</h1>
            <div *ngIf="finalizing">
                <div class="clearfix"></div>
                <div class="text-center" style="margin-top: 50px;">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                    <span class="spinner-label-text">
                        <span *ngIf="!finalizeLoadingMessage">Finalizing...</span>
                        <span *ngIf="finalizeLoadingMessage">{{ finalizeLoadingMessage }}</span>
                        <br />
                        <span class="anchor-style" *ngIf="!processingCancelled && !hideCancelHyperlink" (click)="processingCancelled = true">(Cancel)</span>
                        <span *ngIf="processingCancelled">Cancelling Remaining Packages...</span>
                    </span>
                </div>
            </div>
            <div [style.visibility]="finalizing ? 'hidden' : 'visible'">
                <div *ngIf="readOnly && !isModal && showDates" class="d-flex align-items-center float-end mt-4">
                    <label class="text-nowrap me-2 mb-0">Sent Range: </label>
                    <div class="input-group">
                        <weissman-datetime [(inputDate)]="startDate"
                                           [dateOnly]="true"
                                           [timeZone]="'Central'"
                                           #startDatePicker>
                        </weissman-datetime>
                    </div>
                    <span class="mx-2">to</span>
                    <div class="input-group">
                        <weissman-datetime [(inputDate)]="endDate"
                                           [dateOnly]="true"
                                           [timeZone]="'Central'"
                                           [rangeEnd]="true"
                                           #endDatePicker>
                        </weissman-datetime>
                    </div>
                    <button class="ace-btn btn-sm btn-primary"
                            (click)="refreshList()">
                        Refresh
                    </button>
                </div>
                <div class="clearfix"></div>
                <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <table class="table table-striped table-hover payment-package-table" style="line-height: 1.428" *ngIf="!loading && paymentPackages?.length > 0">
                    <thead>
                        <tr>
                            <th style="width: 20px;">
                                <input type="checkbox"
                                        [indeterminate]="selectedCount > 0 && selectedCount < paymentPackages.length"
                                        [checked]="selectedCount === paymentPackages.length"
                                        (click)="toggleSelectAll()" [disabled]="isDisabled"/>
                            </th>
                            <th style="width: 5%">Package #</th>
                            <th style="width: 5%">Sent</th>
                            <th style="width: 15%;">Responsible Party</th>
                            <th style="width: 18%;">Cc/Bcc</th>
                            <th colspan="2" style="width: 40%;">
                                <table style="width: 100%;">
                                    <tr>
                                        <th width="70%">Transmittals</th>
                                        <th width="30%">Payment Count</th>
                                    </tr>
                                </table>
                            </th>
                            <th style="width: 20%">Special Instructions</th>
                            <th *ngIf="readOnly && !isModal">Recall?</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let package of paymentPackages"
                            (click)="selectRow($event, package)"
                            [style.font-style]="package.completedDateTime ? 'italic' : 'normal'"
                            [style.color]="package.isRecalled ? '#a3a4b2' : 'var(--ace-neutral-800)'">
                            <td><input type="checkbox" *ngIf="!package.completedDateTime" [(ngModel)]="package.isSelected" [disabled]="isDisabled" /></td>
                            <td>
                                <span *ngIf="!package.completedDateTime">
                                    <a class="no-bubble" style="cursor: pointer;" [style.color]="package.isRecalled ? '#80C7FF' : '#337ab7'" (click)="openReviewModal($event, package)">
                                        {{ package.transmittalType }} Draft {{ package.packageNumber }}
                                    </a>
                                </span>
                                <span *ngIf="package.completedDateTime">
                                    <a class="no-bubble" style="cursor: pointer;" (click)="openReviewModal($event, package)" [style.color]="package.isRecalled ? '#80C7FF' : '#337ab7'">
                                        {{ package.transmittalType }} - {{ package.packageNumber }}
                                    </a>
                                </span>
                            </td>
                            <td>
                                <span *ngIf="!package.completedDateTime">
                                    Draft
                                    <i *ngIf="package.lockedForOverrideEmail"
                                       class="no-bubble fa fa-lock float-end"
                                       style="position: absolute; margin-top: 3px; cursor: pointer;"
                                       tooltip="This package is locked for editing because the email has been customized; click to unlock"
                                       (click)="unlockPackage($event, package)">
                                    </i>
                                </span>
                                <span *ngIf="package.completedDateTime">{{ package.completedDateTime | weissmanDateFormat }}</span>
                            </td>
                            <td>
                                <ng-container *ngIf="!isPrelimApFeed">
                                    <div *ngFor="let recipient of package.paymentPackageRecipients">
                                        <span *ngIf="recipient.recipientType === RecipientType.ResponsibleParty"
                                              [tooltip]="recipientAddress" (mouseenter)="setHoveredRecipient(recipient)">
                                            {{ recipient.fullName }}
                                        </span>
                                    </div>
                                </ng-container>
                                <div *ngIf="isPrelimApFeed">N/A</div>
                            </td>
                            <td>
                                <ng-container *ngIf="!isPrelimApFeed">
                                    <div *ngIf="getRecipientCount(package.paymentPackageRecipients) > 0">
                                        <div class="delete-hover" *ngFor="let recipient of package.paymentPackageRecipients | recipientType: { recipientType: RecipientType.Cc }">
                                            <div>
                                                <div style="width: 84%" class="float-start" [tooltip]="recipientAddress" (mouseenter)="setHoveredRecipient(recipient)">
                                                    <span>CC: </span>
                                                    {{ recipient.fullName }}
                                                </div>
                                                <i class="no-bubble fa fa-close hidden-hover float-end" *ngIf="!readOnly && !package.completedDateTime && !package.lockedForOverrideEmail"
                                                        (click)="removeRecipient($event, recipient, package)">
                                                </i>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div class="delete-hover" *ngFor="let recipient of package.paymentPackageRecipients | recipientType: { recipientType: RecipientType.Bcc }">
                                            <div>
                                                <div style="width: 84%" class="float-start" [tooltip]="recipientAddress" (mouseenter)="setHoveredRecipient(recipient)">
                                                    <span>BCC: </span>
                                                    {{ recipient.fullName }}
                                                </div>
                                                <i class="no-bubble fa fa-close hidden-hover float-end" *ngIf="!readOnly && !package.completedDateTime && !package.lockedForOverrideEmail"
                                                    (click)="removeRecipient($event, recipient, package)">
                                                </i>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                        <div *ngIf="!readOnly && !package.completedDateTime && !package.lockedForOverrideEmail" class="hidden-col-hover">
                                            <button class="no-bubble ace-btn btn-sm btn-primary" (click)="openContactModal($event, package)">Add</button>
                                        </div>
                                    </div>
                                    <div *ngIf="getRecipientCount(package.paymentPackageRecipients) === 0"
                                            style="font-style: italic;">
                                        There are no other recipients
                                        <div *ngIf="!readOnly && !package.completedDateTime && !package.lockedForOverrideEmail" class="hidden-col-hover">
                                            <button class="no-bubble ace-btn btn-sm btn-primary" (click)="openContactModal($event, package)">Add</button>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="isPrelimApFeed">N/A</div>
                            </td>
                            <td colspan="2">
                                <div style="max-height: 220px; overflow:auto;">
                                    <table style="width: 100%;">
                                        <tr>
                                            <td width="80%" style="vertical-align: top;">
                                                {{ package.companyName }}
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr class="delete-hover" *ngFor="let transmittal of package.paymentTransmittals; let i = index">
                                            <td width="80%" style="vertical-align: top; position: relative">
                                                <a class="no-bubble float-start" [style.color]="package.isRecalled ? '#80C7FF' : '#337ab7'" style="cursor: pointer;" (click)="goToDetails($event, transmittal, package)">
                                                    <span *ngIf="transmittal.collectorAddress.abbr">{{ transmittal.collectorAddress.abbr }} -</span> {{ transmittal.title }}
                                                </a>
                                                <i class="no-bubble fa fa-close hidden-hover float-end" *ngIf="(!readOnly && !package.completedDateTime && !package.lockedForOverrideEmail) && !isFromPaymentBatch"
                                                    style="position: absolute; right: -8%;"
                                                    (click)="removeTransmittal($event, package, i)">
                                                </i>
                                            </td>
                                            <td width="20%" style="vertical-align: top; text-align: center">
                                                {{ transmittal.paymentTransmittalItems.length }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </td>
                            <td>
                                <ng-container *ngIf="!isPrelimApFeed">
                                    <textarea *ngIf="!readOnly && !package.completedDateTime && !package.lockedForOverrideEmail" (click)="$event.stopPropagation()" [(ngModel)]="package.specialInstructions" style="width: 100%; min-height: 70px;" maxlength="5000" class="no-bubble"></textarea>
                                    <span *ngIf="readOnly || package.completedDateTime">{{ package.specialInstructions }}</span>
                                </ng-container>
                                <div *ngIf="isPrelimApFeed">N/A</div>
                            </td>
                            <td *ngIf="readOnly && !isModal && (package.completedDateTime || package.lockedForOverrideEmail)">
                                <button *ngIf="!package.isRecalled" [disabled] ="package.paymentBatchId !== 0" class="no-bubble ace-btn has-icon btn-text icon-dark" (click)="recallPackage(package)">
                                    <i class="fa fa-undo"></i>
                                </button>
                                <span *ngIf="package.isRecalled">(recalled)</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div *ngIf="paymentPackages?.length === 0">
                    <h3 style="text-align: center">No payment packages</h3>
                </div>
                <div *ngIf="!readOnly" class="ws-button-row">
                    <button class="ace-btn btn-primary btn-danger" *ngIf="getCompletePackageCount() < paymentPackages?.length" (click)="cancelPackages()">
                        Cancel
                    </button>
                    <button class="no-bubble ace-btn btn-primary" *ngIf="getCompletePackageCount() === paymentPackages?.length" (click)="returnToActionView()">
                        {{returnButtonText}}
                    </button>
                    <button class="ace-btn btn-primary btn-success"
                            *ngIf="showSaveButton"
                            (click)="finalizePackages()">
                        {{saveButtonText}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <transmittal-details *ngIf="selectedTransmittalID"
                         [selectedPackage]="selectedPackage"
                         [selectedTransmittalID]="selectedTransmittalID"
                         [readOnly]="readOnly"
                         [isModal]="isModal"
                         [isFromPaymentBatch]="isFromPaymentBatch"
                         (backClicked)="backClicked()">
    </transmittal-details>
    <finalize-payment-package-modal [packageList]="paymentPackages"
                                    [cancelExecutionLoop]="processingCancelled"
                                    (hasPackageFinalized)="packageFinalized()"
                                    [setParentLoading]="setFinalizeLoading"
                                    [isFromPaymentBatch]="isFromPaymentBatch"
                                    [paymentBatchId]="paymentBatchId"
                                    [taskId]="taskId"
                                    #FinalizePaymentPackageModal>
    </finalize-payment-package-modal>
</div>

<ng-template #recipientAddress>
    <address *ngIf="hoveredRecipient" style="text-align: left; margin: 0;">
        {{hoveredRecipient.companyName}}
        <br> {{hoveredRecipient.fullName}}
        <br> {{hoveredRecipient.title}}
        <div *ngIf="hoveredRecipient.address">
            {{hoveredRecipient.address.address1}}
            <br> {{hoveredRecipient.address.address2}}
            <br *ngIf="hoveredRecipient.address.address2"> {{hoveredRecipient.address.city}}<span *ngIf="hoveredRecipient.address.city">,</span> {{hoveredRecipient.address.abbr}} {{hoveredRecipient.address.zip}}
        </div>
    </address>
</ng-template>

