import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface ICellRendererParamsForCostCenter extends ICellRendererParams {
    editMode: () => boolean;
}

@Component({
    selector: 'Renderer-cell',
    template: `
        <team-picker *ngIf="params && params.data && !params.data.isTotal" [editMode]="params.editMode()" [useCostCenter]="true" [(costCenterTeamId)]="params.data.costCenterTeamId" [(costCenter)]="params.data.costCenter"></team-picker>
    `,
})
export class AgGridCostCenterCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForCostCenter;

    agInit(params: ICellRendererParamsForCostCenter): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
