import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BulkOperationResult } from '../../Common/Models/bulk-operation-result.model';
import { AppealRecommendationTemplateComponent } from './appealRecommendationTemplate.component';

@Injectable()
export class AppealRecommendationTemplateModalService {
    constructor(private modalService: BsModalService) { }

    modalRef: BsModalRef;

    launchModal(taskIds: number[], instanceId: number): Promise<() => void> {
        return new Promise<() => Promise<BulkOperationResult[]>>((resolve, reject) => {
            const initialState = {
                taskIds: taskIds,
                instanceId: instanceId,
                callback: (result: () => Promise<BulkOperationResult[]>) => {
                    resolve(result);
                }
            };

            this.modalRef = this.modalService.show(AppealRecommendationTemplateComponent, {initialState, class: 'modal-no-max-width modal-xl'});
        });
    }
}
