<div class="invoice">
    <div>
        <div class="invoice-header" style="margin-top:30px;" *ngIf="!loading">
            <ol class="breadcrumb float-start" style="background-color: var(--ace-neutral-white);margin-top:-30px;">
                <li class="breadcrumb-item">
                    <a [href]="companyUrl" style="cursor:pointer;">{{currentInvoice.companyName}}</a>
                </li>
                <li class="breadcrumb-item" *ngIf="currentInvoice.siteName">
                    <a [href]="siteUrl" style="cursor: pointer;">{{currentInvoice.siteName}}</a>
                </li>
                <li class="breadcrumb-item active">Invoice</li>
            </ol>
        </div>
    </div>
    <div>
        <div class="card panel-flat-color primary-panel" style="margin-top: 24px;">
            <div class="panel-flat-header">
                <div class="header-icon"><i class="fa fa-pie-chart"></i></div>
                <h3>Invoice</h3>
                <div class="float-end header-button-container" [class.invisible]="callInProgress || loading">
                    <button type="button"
                            *ngIf="!editMode && !taskOnlyEditMode"
                            class="ace-btn btn-text has-icon btn-primary"
                            (click)="setEditMode()">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button type="button"
                            *ngIf="editMode || taskOnlyEditMode"
                            class="ace-btn btn-text has-icon btn-primary"
                            (click)="cancelEdit()">
                        <i class="fa fa-close"></i>
                    </button>
                </div>
            </div>
            <div class="panel-flat-body form-inline">
                <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <div *ngIf="!loading">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Contract Type:</label>
                            <span *ngIf="!editMode || !canChangeContractType" [ngClass]="{'overridden': currentInvoice.contractServiceType !== currentInvoice.defaultContractServiceType}">{{ invoiceService.contractServiceName(currentInvoice.contractServiceType) }}</span>
                            <span *ngIf="currentInvoice.contractServiceType == ContractServiceType.Appeal && currentInvoice.isAdHoc"> (ad hoc)</span>
                            <select *ngIf="editMode && canChangeContractType" [(ngModel)]="currentInvoice.contractServiceType"
                                    (ngModelChange)="onContractServiceTypeChange()"
                                    class="form-select form-select-sm">
                                <option *ngFor="let type of appealContractTypes" [value]="type.value">{{type.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <label>Company:</label>
                            <span>
                                <a [href]="companyUrl" target="_blank" rel="noreferrer" style="cursor: pointer">
                                    {{ currentInvoice.companyName }}
                                </a>
                            </span>
                        </div>
                        <div class="col-lg-4">
                            <label style="width: 150px;">Processing Instructions: </label>
                            <div class="position-relative">
                                <textarea class="form-control w-100 position-absolute"
                                          style="height: 80px; z-index: 2;"
                                          [disabled]="!editMode"
                                          [(ngModel)]="currentInvoice.processingInstructions">
                                </textarea>
                            </div>
                        </div>
                       <div class="w-auto pull-right position-absolute" style="right:1em;">
                            <entity-attachments buttonClass="has-icon" [modalData]="attachmentData" [(hasAttachments)]="currentInvoice.hasAttachments">
                            </entity-attachments>
                            <entity-comments buttonClass="has-icon" [modalData]="commentData" [(hasComments)]="currentInvoice.hasComments" (hasCommentsChange)="currentInvoice.hasComments = $event">
                            </entity-comments>
                       </div>
                     </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>RIBS Due Date:</label>
                            <weissman-datetime [readOnly]="!editMode"
                                               [dateOnly]="true"
                                               [(inputDate)]="currentInvoice.dueDate">
                            </weissman-datetime>
                        </div>
                        <div class="col-md-4">
                            <label>Account Handler:</label>
                            <span>{{ currentInvoice.accountHandler.firstName }} {{currentInvoice.accountHandler.lastName}}</span>
                        </div>
                    </div>
                    <div class="row" *ngIf="enableInvoiceUBR && (currentInvoice.isUBR || currentInvoice.invoiceUBRNumber)">
                        <div class="col-md-4">
                            <label>UBR Request #:</label>
                            <span *ngIf="currentInvoice.invoiceUBRNumber">{{ currentInvoice.invoiceUBRNumber }}</span>
                            <span *ngIf="!currentInvoice.invoiceUBRNumber">Draft</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>RIBS Request #:</label>
                            <span *ngIf="currentInvoice.invoiceNumber">{{ currentInvoice.invoiceNumber }}</span>
                            <span *ngIf="!currentInvoice.invoiceNumber">Draft</span>
                        </div>
                        <div *ngIf="currentInvoice.siteId" class="col-md-4">
                            <label>Site ST-Name:</label>
                            <span>
                                <a [href]="siteUrl" target="_blank" rel="noreferrer" style="cursor: pointer">
                                    {{currentInvoice.siteState.abbr}} - {{ currentInvoice.siteName }}
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>Status:</label>
                            <a style="cursor: pointer" (click)="launchTaskModal()">{{currentInvoice.taskSummary[0].status}}</a>
                        </div>
                        <div class="col-md-4">
                            <label>Recipient:</label>
                            <invoice-recipient-lookup #invoiceRecipientLookup [editMode]="editMode" [invoice]="currentInvoice"></invoice-recipient-lookup>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>UBR:</label>
                            <ws-switch [(ngModel)]="currentInvoice.isUBR"
                                       (ngModelChange)="setButtonText()"
                                       [disabled]="!editMode">
                            </ws-switch>
                        </div>
                        <div class="col-md-4">
                            <label>Third Party Approval Date:</label>
                            <weissman-datetime [readOnly]="!editMode"
                                               [dateOnly]="true"
                                               [(inputDate)]="currentInvoice.thirdPartyApprovalDate"
                                               helpTooltip
                                               tooltipText="Latest appeal savings date when type is Appeal, otherwise current date."
                                               position="right">
                            </weissman-datetime>
                        </div>
                        <div class="col-md-4">
                            <label style="margin-bottom: 0;">Terms:</label>
                            <hr style="margin-top: 0; margin-bottom: 7px;">
                            <select *ngIf="editMode && companyAppealContractTerms.length > 1" name="terms"
                                    [ngModel]="currentContractTermDetailId" (ngModelChange)="onContractTermChange($event)"
                                    class="form-select form-select-sm">
                                <option *ngFor="let term of companyAppealContractTerms" [value]="term.contractTermDetailId">
                                    <term-rates-and-fees [term]="term" [usePercent]="true"></term-rates-and-fees>
                                </option>
                            </select>
                            <div *ngIf="!editMode || companyAppealContractTerms.length <= 1" style="display: flex; justify-content: space-between;" [class.overridden]="isContractTermOverridden">
                                <term-rates-and-fees [term]="contractTerm" [usePercent]="true"></term-rates-and-fees>
                                <button *ngIf="currentInvoice.contractTermDetailRemarks"
                                    class="ace-btn has-icon"
                                    triggers="mouseenter:mouseleave"
                                    [popover]="currentInvoice.contractTermDetailRemarks">
                                    <i class="fa fa-commenting"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="isRyanLegalServiceEnabled">
                        <div class="col-md-4">
                            <label>RLS:</label>
                            <input class="form-check-input" [disabled]="!editMode" type="checkbox" [(ngModel)]="currentInvoice.isRyanLegalServices">
                        </div>
                    </div>
                    <!-- INVOICE DETAILS COMPONENT -->
                    <invoice-details
                                     [currentInvoice]="currentInvoice"
                                     [editMode]="editMode"
                                     [loading]="loading"
                                     [cachedInvoice]="cachedInvoice">
                    </invoice-details>

                    <!-- FEE ALLOCATION COMPONENT -->
                    <fee-allocation
                                    [currentInvoiceAmount]="currentInvoice.invoiceAmount"
                                    [currentInvoice]="currentInvoice"
                                    [editMode]="editMode"
                                    (engagementChanged)="invoiceRecipientLookup.getRecipientList()">
                    </fee-allocation>
                    <button type="button"
                        class="ace-btn btn-primary btn-danger float-end"
                        *ngIf="editMode"
                        tooltip="Delete Invoice"
                        (click)="delete()"
                        [disabled]="callInProgress || loading || !currentInvoice || !currentInvoice.canBeDeleted">Delete</button>
                </div>
            </div>
        </div>
    </div>
    <div class="action-footer">
        <div class="action-buttons-row" style="margin-top: 10px; padding: 0px 75px;">
            <div class="float-start">
                <button type="button"
                    *ngIf="editMode"
                    [disabled]="callInProgress || loading"
                    tooltip="Save Invoice"
                    (click)="save()"
                    class="ace-btn btn-sm btn-primary">Save</button>
                <button type="button"
                    *ngIf="showCompleteButton()"
                    [disabled]="callInProgress || loading"
                    (click)="complete()"
                    class="ace-btn btn-sm btn-secondary">{{completeText}}</button>
                <span style="margin-left: 4px;" *ngIf="callInProgress">
                    <i class="fa fa-spinner fa-pulse"></i>
                </span>
            </div>
            <div class="float-end">
                <button type="button"
                    *ngIf="editMode && taskList"
                    tooltip="Skip Invoice"
                    [disabled]="callInProgress || loading"
                    (click)="skipInvoice()"
                    class="ace-btn btn-sm btn-primary">{{closeText}}</button>
            </div>
        </div>
    </div>
</div>

