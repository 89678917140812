// AngularJS events have other members, but we're not using event results at this time,
// so for now just including the obvious parts
export interface Event {
    name: string;
    defaultPrevented: boolean;
    preventDefault(): void;
}

export abstract class EventAdapterService {
    abstract broadcast(name: string, ...args: any[]): Event;
}