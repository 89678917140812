import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wsKeyValueDisplay' })
export class WeissmanKeyValueDisplayPipe implements PipeTransform {
    transform(model: any, key: string|number, value: string|number): string {
        let keyTemplate: string, valueTemplate: string;

        if (typeof key === 'number') { keyTemplate = isNaN(key) ? '' : key.toString(); }
        if (typeof value === 'number') { valueTemplate = isNaN(value) ? '' : value.toString(); }

        if (typeof key === 'string') { keyTemplate = [null, undefined].indexOf(key) === -1 ? key.toString() : ''; }
        if (typeof value === 'string') { valueTemplate = [null, undefined].indexOf(value) === -1 ? value.toString() : ''; }

        return `(${keyTemplate}) ${valueTemplate}`;
    }
}