import { Injectable } from '@angular/core';

export interface IPubSubSubscription {
    unsubscribe: () => void;
}

export interface IPubSubService {
    publish: (topic: string, data?: any) => void;
    publishParent: (topic: string, data?: any) => void;
    publishChild: (childWindow: Window, topic: string, data?: any) => void;
    subscribe: (topic: string, callback: (data: any, topic: string, windowName: string) => void) => IPubSubSubscription;
    subscribeOnce: (topic: string, callback: (data: any, topic: string, windowName: string) => void) => IPubSubSubscription;
}

/**
 * WARNING: We should use RXJS instead of this
 */
@Injectable(
    { providedIn: 'root' }
)
export class PubSubService implements IPubSubService {
    constructor() {
        window.addEventListener('message', (event: Event) => {
            if (event['origin'] !== window['origin']) return;

            const messageInfo = event['data'];
            this._invokeCallback(messageInfo.publishTopic, messageInfo.publishData, messageInfo.publishWindowName);
        }, false);
    }

    private readonly _Messages = {
        MissingPublishTopic: 'Missing topic. A topic is required for publishing.',
        MissingSubscriptionTopic: 'Missing topic. A subscription topic is required.',
        MissingTargetWindow: 'Missing target window. A target window is required for publishing.',
        FailedInvokingCallback: 'Failed executing subscription callback.'
    };

    // key/value map of topic names and a collection of subscribers
    private _subscriptions: { [topic: string]: any[] } = { };

    publish(topic: string, data?: any): void {
        if (!topic) throw new Error(this._Messages.MissingPublishTopic);

        this._invokeCallback(topic, data);
    }

    publishParent(topic: string, data?: any): void {
        if (!topic) throw new Error(this._Messages.MissingPublishTopic);
        if (!window.opener) throw new Error(this._Messages.MissingTargetWindow);

        this._publishRemote(window.opener, topic, data);
    }

    publishChild(targetWindow: Window, topic: string, data?: any): void {
        if (!topic) throw new Error(this._Messages.MissingPublishTopic);
        if (!targetWindow) throw new Error(this._Messages.MissingTargetWindow);

        this._publishRemote(targetWindow, topic, data);
    }

    subscribe(topic: string, callback: (data: any, topic: string, windowName: string) => void): IPubSubSubscription {
        if (!topic) throw new Error(this._Messages.MissingSubscriptionTopic);

        const subscribers = this._subscriptions[topic] || (this._subscriptions[topic] = []);
        subscribers.push(callback);
        return {
            unsubscribe: () => {
                const index = subscribers.indexOf(callback);
                if (index !== -1) {
                    subscribers.splice(index, 1);
                }
            }
        };
    }

    subscribeOnce(topic: string, callback: (data: any, topic: string, windowName: string) => void): IPubSubSubscription {
        const subscription = this.subscribe(topic, (a, b, c) => {
            subscription.unsubscribe();
            return callback(a, b, c);
        });
        return subscription;
    }

    private _invokeCallback(topic: string, data?: any, windowName?: string) {
        let subscribers = this._subscriptions[topic];
        if (subscribers) {
            subscribers = subscribers.slice();
            let i = subscribers.length;
            while (i--) {
                try {
                    subscribers[i](data, topic, windowName);
                } catch(e) {
                    console.error(this._Messages.FailedInvokingCallback, e);
                }
            }
        }
    }

    private _publishRemote(targetWindow: Window, topic: string, data?: any): void {
        const postMessageInfo = {
            publishTopic: topic,
            publishData: data,
            publishWindowName: window.name
        };
        targetWindow.postMessage(postMessageInfo, '*');
    }

}
