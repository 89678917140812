import { Component, Input } from '@angular/core';
import {DisplayStringArrayModel} from './display.String.Array.model';

@Component({
    selector: 'display-String-Array-component',
    templateUrl:
        './display.String.Array.component.html'
})
export class DisplayStringArrayComponent {

    @Input() data: DisplayStringArrayModel;

    // constructor(
    //     public  data: DisplayStringArrayModel,
    // ) {
    //     this.dataToDisplay = data;
    // }

}