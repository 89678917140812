import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { animate, trigger, transition, style } from '@angular/animations';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { AssetClassificationRepository } from '../../Repositories/assetClassification.repository';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

export interface AssetClassPickerParams {
    assetClassificationId: number;
    assetClassifications: Compliance.AssetClassificationModel[];
}

@Component({
    selector: 'asset-class-picker-modal',
    templateUrl: './assetClassPicker.component.html',
    animations: [
        trigger('pickerEnter', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-2%)' }),
                animate(125, style({ opacity: 1, transform: 'translateX(0)' }))
            ]),
            transition(':leave', [
                animate(100, style({ opacity: 0, transform: 'translateX(-2%)' }))
            ])
        ])
    ]
})
export class AssetClassPickerComponent implements OnInit, IWeissmanModalComponent<AssetClassPickerParams, Compliance.AssetClassificationModel> {
    constructor(
        private _modalRef: BsModalRef,
        private _assetClassificationRepository: AssetClassificationRepository,
        private _elem: ElementRef
    ) { }

    @ViewChild('.active-class-picker') selectedAssetClass: ElementRef;
    params: AssetClassPickerParams;
    result: Compliance.AssetClassificationModel;
    assetClassifications: Compliance.AssetClassificationModel[];

    private _expandedAssetClassifications: Set<number> = new Set<number>();

    async ngOnInit(): Promise<void> {
        const result = this.params.assetClassifications || await lastValueFrom(this._assetClassificationRepository.getAssetClassifications());

        this.assetClassifications = result;
        if (this.params.assetClassificationId) {
            this.assetClassifications.forEach(topLevel => {
                if (topLevel.childClassifications) {
                    topLevel.childClassifications.forEach(secondLevel => {
                        if (secondLevel.assetClassificationId === this.params.assetClassificationId) {
                            this._expandedAssetClassifications.add(topLevel.assetClassificationId);
                        } else if (secondLevel.childClassifications &&
                            secondLevel.childClassifications.find(ac => ac.assetClassificationId === this.params.assetClassificationId)) {
                            this._expandedAssetClassifications.add(topLevel.assetClassificationId);
                            this._expandedAssetClassifications.add(secondLevel.assetClassificationId);
                        }
                    });
                }
            });
        }

        setTimeout(() => {
            const elem = this._elem.nativeElement.querySelector('.active-class-link');
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth' });
            }
        }, 0);
    }

    selectAssetClass(assetClassification: Compliance.AssetClassificationModel): void {
        this.result = assetClassification;
        this._modalRef.hide();
    }

    isSelected(assetClassification: Compliance.AssetClassificationModel): boolean {
        return assetClassification.assetClassificationId === this.params.assetClassificationId;
    }

    cancel(): void {
        this._modalRef.hide();
    }

    isExpanded(classification: Compliance.AssetClassificationModel): boolean {
        return this._expandedAssetClassifications.has(classification.assetClassificationId);
    }

    expandClass(classification: Compliance.AssetClassificationModel): void {
        this._expandedAssetClassifications.add(classification.assetClassificationId);
    }

    collapseClass(classification: Compliance.AssetClassificationModel): void {
        this._expandedAssetClassifications.delete(classification.assetClassificationId);
    }
}
