import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthStorageService } from '../User/authStorage.service';

export enum AppStates {
    Initializing = 'Initializing',
    Initialized = 'Initialized',
    LoggedIn = 'LoggedIn',
    LoggingOut = 'LoggingOut',
    LoggedOut = 'LoggedOut'
}

@Injectable(
    { providedIn: 'root' }
)
export class AppStateService {
    constructor(authStorageService: AuthStorageService, ngZone: NgZone) {
        this.appState$ = new BehaviorSubject(AppStates.Initializing);
        authStorageService.logoutMessage$.subscribe(message => {
            // Often we'll be setting the logout message from outside Angular's Zone via a setTimeout; run this
            // subscription within the Angular Zone so that change detection notices that we've set the
            // application's state
            ngZone.run(() => {
                if (message) {
                    this.appState$.next(AppStates.LoggedOut);
                }
            });
        });
    }

    appState$: BehaviorSubject<AppStates>;
    // Expose enum for usage in JavaScript
    appStates = AppStates;

}
