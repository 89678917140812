import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'messageModal',
    templateUrl: './message-modal.component.html'
})
export class MessageModalComponent {
    constructor(public bsModalRef: BsModalRef) { }

    result: string = 'Cancel';  // Initialized to Cancel in case user closes modal with outside click

    title: string;

    subtitle: string;

    message: string;

    messageList: string[] = [];

    hasOK: boolean;
    hasYes: boolean;
    hasNo: boolean;
    hasCancel: boolean;
    hasContinue: boolean;

    isErrorState: boolean;

    save(): void {
        if(this.hasOK || this.hasContinue) {
            this.result = 'OK';
        } else if (this.hasYes) {
            this.result = 'Yes';
        }
        this.bsModalRef.hide();
    }

    cancel(): void {
        if (this.hasCancel) {
            this.result = 'Cancel';
        } else if (this.hasNo) {
            this.result = 'No';
        }
        this.bsModalRef.hide();
    }
}
