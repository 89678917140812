<div class="multi-select-wrapper" [class.disabled]="disabled" clickOutside (clickOutside)="closeDropdown()">
    <span class="caret-toggle">
        <i class="fa fa-angle-down"></i>
    </span>
    <span class="input-result" (click)="toggleDropdown()">
        <span *ngIf="!selectedSummary && placeholder">{{placeholder}}</span>
        <span *ngIf="selectedSummary">{{selectedSummary}}</span>
    </span>
    <ul #dropdown class="multi-select-dropdown" [class.active]="open" [class.is-dropup]="isDropup">
        <li *ngIf="canSearch" class="search-option">
            <span class="d-block py-2 px-3 clickable">
                <label class="select-search-label">
                    <input class="exclude-modal-styling"
                           #searchInput
                           type="text"
                           [(ngModel)]="searchValue"
                           (input)="search()"/>
                    <i class="fa fa-search"></i>
                </label>
            </span>
            <span *ngIf="deselectAll && selectedOptions.length"
                  class="deselect-all-option d-block py-2 px-3 clickable"
                  (click)="deselectAll()">
                <label class="multi-select-label ws-font-weight-normal clickable">
                    <i class="fa fa-x"></i>
                    Clear Selections
                </label>
            </span>
            <span *ngIf="!hideSelectAll"
                  class="select-all-option d-block py-2 px-3 clickable"
                  (click)="selectAll()">
                <label class="checkbox-container multi-select-label clickable">
                    Select All
                    <input type="checkbox" [checked]="selectedOptions.length === dropdownOptions.length" />
                    <span class="checkbox-checkmark"></span>
                </label>
            </span>
        </li>
        <li class="w-100 deselect-all-option sticky-option clickable"
            *ngIf="deselectAll && !canSearch && selectedOptions.length"
            (click)="deselectAll()">
            <span class="d-block py-2 px-3">
                <label class="multi-select-label ws-font-weight-normal">
                    <i class="fa fa-x"></i>
                    Clear Selections
                </label>
            </span>
        </li>
        <li class="w-100 select-all-option sticky-option clickable"
            *ngIf="!hideSelectAll && !canSearch"
            (click)="selectAll()">
            <span class="d-block py-2 px-3">
                <label class="checkbox-container multi-select-label">
                    Select All
                    <input type="checkbox" [checked]="selectedOptions.length === dropdownOptions.length" />
                    <span class="checkbox-checkmark"></span>
                </label>
            </span>
        </li>
        <li *ngFor="let option of searchableOptions"
            class="w-100 clickable dropdown-option"
            (click)="optionSelected(option)">
            <span class="d-block py-2 px-3">
                <label class="checkbox-container multi-select-label ws-font-weight-normal">
                    {{(isPrimitive ? option : option[labelProperty])}}
                    <input type="checkbox" [checked]="multiSelectOptionActive(option)" />
                    <span class="checkbox-checkmark"></span>
                </label>
            </span>
        </li>
    </ul>
</div>
