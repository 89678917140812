<ws-modal-wrapper headerLabel="Document Intake Item Exception"
                  [disableCancel]="loading"
                  [disableSave]="loading || !isValid()"
                  (save)="save()"
                  (cancel)="cancel()">
    <div class="document-processing-exception-modal">
        <loading-spinner [show]="loading" [size]="5"></loading-spinner>
        <div *ngIf="!loading" class="row">
            <div class="col col-2">
                Action:
            </div>
            <div class="col col-10">
                <div class="radio">
                    <label>
                        <input type="radio"
                               name="action-selection"
                               [value]="DocumentExceptionActions.REASSIGN_ITEM"
                               [(ngModel)]="actionType"
                               required
                               (change)="createComment()">
                        <span>Reassign item</span><span *ngIf="multipleTasks">s</span>
                    </label>
                </div>
                <div *ngIf="actionType === DocumentExceptionActions.REASSIGN_ITEM">
                    <user-team-picker [(user)]="reassign"
                                      [entityTypeScope]="'Instance'"
                                      [entityIdScope]="instanceId"
                                      (userChange)="createComment()">
                    </user-team-picker>
                </div>
                <div *ngIf="(multipleTasks || rejectedByUser) &&
                        !(intakeItem && intakeItem.clientInstanceId && clientInstanceId !== instanceId ||
                        clientInstanceId && clientInstanceId !== instanceId)"
                     class="radio">
                    <label ng-style="disableBounceRejected ? {'color':'lightgray'} : {}">
                        <input type="radio"
                               name="action-selection"
                               required
                               [disabled]="disableBounceRejected"
                               [value]="DocumentExceptionActions.BOUNCE_REJECTED"
                               [(ngModel)]="actionType">
                        <span *ngIf="multipleTasks">Reassign to rejector<span
                            *ngIf="rejectedByUser"> ({{ rejectedByUser.firstName }} {{ rejectedByUser.lastName }}
                            )</span></span>
                        <span
                            *ngIf="!multipleTasks">Reassign item to rejector ({{ rejectedByUser.firstName }} {{ rejectedByUser.lastName }}
                            )</span>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               name="action-selection"
                               required
                               [value]="DocumentExceptionActions.ESCALATE_ITEM"
                               [(ngModel)]="actionType"
                               (change)="createComment()">
                        <span>Escalate items
                    <span *ngIf="intakeItem && intakeItem.clientInstanceId && clientInstanceId !== instanceId"> to contact user ({{ intakeItem.contactUser.firstName }} {{ intakeItem.contactUser.lastName }}
                        )</span>
                    <span
                        *ngIf="!intakeItem && clientInstanceId && clientInstanceId !== instanceId"> to contact user ({{ contactUser.firstName }} {{ contactUser.lastName }}
                        )</span></span>
                    </label>
                </div>
                <div *ngIf="actionType === DocumentExceptionActions.ESCALATE_ITEM &&
                (multipleTasks && (!clientInstanceId || clientInstanceId === instanceId) || !multipleTasks && (!intakeItem.clientInstanceId || clientInstanceId === instanceId))">
                    <user-team-picker [(user)]="escalate"
                                      [entityTypeScope]="'Instance'"
                                      [entityIdScope]="instanceId"
                                      (userChange)="createComment()">
                    </user-team-picker>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               name="action-selection"
                               required
                               [value]="DocumentExceptionActions.REQUEST_RESEARCH"
                               [(ngModel)]="actionType"
                               (change)="createComment()">
                        <span>Request Research</span>
                    </label>
                </div>
                <div *ngIf="actionType === DocumentExceptionActions.REQUEST_RESEARCH">
                    <user-team-picker [(user)]="request"
                                      [entityTypeScope]="'Instance'"
                                      [entityIdScope]="instanceId"
                                      (userChange)="createComment()">
                    </user-team-picker>
                </div>
                <div class="radio" *ngIf="showRequestTaxRateSetups">
                    <label>
                        <input type="radio"
                               name="action-selection"
                               required
                               [value]="DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS"
                               [(ngModel)]="actionType"
                               (change)="createComment()">
                        <span>Request tax rate setups</span>
                    </label>
                </div>
                <div *ngIf="actionType === DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS">
                    <user-team-picker [(user)]="taxRateSetups"
                                      [entityTypeScope]="'Instance'"
                                      [entityIdScope]="instanceId"
                                      (userChange)="createComment()">
                    </user-team-picker>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               name="action-selection"
                               required
                               [value]="DocumentExceptionActions.REJECT_ITEM"
                               [(ngModel)]="actionType"
                               (change)="createComment()">
                        <span
                            *ngIf="!multipleTasks">Reject to contact user ({{ intakeItem.contactUser.firstName }} {{ intakeItem.contactUser.lastName }}
                            )</span>
                        <span *ngIf="multipleTasks">Reject to contact user<span
                            *ngIf="contactUser"> ({{ contactUser.firstName }} {{ contactUser.lastName }})</span></span>
                    </label>
                </div>
                <div class="radio">
                    <label>
                        <input type="radio"
                               name="action-selection"
                               required
                               [value]="DocumentExceptionActions.CLOSE_ITEM"
                               [(ngModel)]="actionType"
                               (change)="createComment()">
                        <span>Close item<span *ngIf="multipleTasks">s</span> (Added in error)</span>
                    </label>
                </div>
                <div *ngIf="hasExceptionStatus || multipleTasks" class="radio">
                    <label [ngStyle]="disableClearException ? {'color':'lightgray'} : {}">
                        <input [disabled]="disableClearException"
                               type="radio"
                               name="action-selection"
                               required
                               [value]="DocumentExceptionActions.CLEAR_EXCEPTION"
                               [(ngModel)]="actionType"
                               (change)="createComment()">
                        <span>Clear Exception<span *ngIf="multipleTasks">s</span></span>
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="loading" class="row" style="margin-top: 20px;">
            <div class="col col-2">
                Comment:
            </div>
            <div class="col col-10">
                <textarea class="form-control" cols="40" rows="5" [(ngModel)]="commentBody"></textarea>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
