<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
                   *ngIf="busyIndicatorMessageManager.count() > 0">
</ws-busy-indicator>

<ws-modal-wrapper
    headerLabel="Request Instance Access"
    acceptLabel="Send Request"
    (save)="save()"
    (cancel)="cancel()"
    [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div style="display: flex; flex-direction: column">
            <div class="user-name">
                {{userName}}
            </div>
            <div style="font-weight: 600; padding-bottom: 10px;">
                REASON FOR REQUEST
            </div>
            <div>
                <textarea formControlName="reason" maxlength="500" rows="5"></textarea>
            </div>
            <div class="small" style="display: flex; flex-direction: row; justify-content: space-between">
                <div>Please enter the reason for which access is being requested. This is required to proceed.</div>
                <div style="flex-shrink: 0; padding-left: 15px;">{{reasonLength}} / 500</div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
