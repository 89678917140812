import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityTypeIds } from 'src/app/constants.new';
import { CommentDateTimeFormat } from 'src/app/UI-Lib/Pipes/Date-Format/date-formatting.pipe';

@Component({
    selector: 'comment-list-item',
    template: `
        <div class="d-flex justify-content-between">
            <em style="color: #626374;">{{getTimestampString(comment)}}</em>
            <div *ngIf="canEdit" class="hidden-item">
                <button (click)="editComment.emit()"
                    [disabled]="loading"
                    class="ace-btn has-icon btn-text btn-primary btn-secondary p-0">
                    <i class="fa fa-pencil"></i>
                </button>
                <button *ngIf="comment.isPinnable && !pinned" class="ace-btn has-icon btn-text btn-primary btn-secondary" (click)="pinComment.emit()" style="padding:0px;">
                    <i class="fa fa-thumb-tack"></i>
                </button>
            </div>
        </div>
        <div class="comment-item">
            <div class="fw-bold">
                <div>
                    <span *ngIf="showYearTitle(comment)">{{ comment.year }} - </span>{{comment.lineOne}}
                </div>
                <div class="comment-title" *ngIf="comment.lineTwo">
                    {{comment.lineTwo}}
                </div>
                <div class="comment-title" *ngIf="comment.lineThree">
                    {{comment.lineThree}}
                </div>
            </div>
            <div class="comment-description-container" [ngClass]="{ 'comment-show-less': comment.isOverLimit && !comment.showMore }">
                <!-- Please don't put any extra whitespace between the binding and the tags here; we're using whitespace:pre-line to maintain
                newlines in the text, so if more whitespace is added it might show up in the display-->
                <div class="comment-description" line-limit [lineLimitIsOver]="comment.isOverLimit" [lineLimitModel]="comment.description">{{comment.description}}</div>
            </div>
            <div *ngIf="comment.isOverLimit">
                <span (click)="toggleHide($event, comment)" class="btn btn-link">
                    <span *ngIf="comment.showMore">less...</span>
                    <span *ngIf="!(comment.showMore)">more...</span>
                </span>
            </div>
        </div>
    `,
    styles: [`
        em { color: var(--ace-neutral-600);}
        .comment-item { color: var(--ace-neutral-700) }
    `]
})

export class CommentListItemComponent {
    constructor(private _commentDateTimePipe: CommentDateTimeFormat) { }

    @Input() comment: any;
    @Input() pinned: boolean = false;
    @Input() canEdit: boolean = false;
    @Input() loading: boolean = false;
    @Output() editComment: EventEmitter<void> = new EventEmitter();
    @Output() pinComment: EventEmitter<any> = new EventEmitter();

    getTimestampString(comment) {
        if(this._commentIsUpdated(comment)) {
            return `Updated by ${comment.changedByName} at ${this._commentDateTimePipe.transform(comment.changeDate)}`;
        } else {
            return `${comment.createdByName} at ${this._commentDateTimePipe.transform(comment.createDate)}`;
        }
    }

    showYearTitle(comment: any): boolean {
        return comment.year && comment.entityTypeID !== EntityTypeIds.TAX_BILL;
    }

    toggleHide(event, comment) {
        comment.showMore = !comment.showMore;
        event.preventDefault();
        event.stopPropagation();
    }

    private _commentIsUpdated(comment): boolean {
        // Sometimes on create a comment gets a slightly different change and create date; detect a delta of
        // at least 10 seconds before a comment "counts" as updated, or alternatively detect that someone else
        // changed a comment regardless of the time (noticed in WK-10171)
        return comment.changeDate.getYear() > 1 && ((Math.abs(comment.changeDate - comment.createDate) > 10000) || (comment.changedByName && comment.changedByName != comment.createdByName));
    }
}
