<div *ngIf="month" class="widget-month">
    <div class="widget-header d-flex justify-content-between align-items-center">
        <div class="invisible">d</div>
        <div style="text-transform: uppercase; font-size: 120%;">{{month.name}} <span *ngIf="month.year !== now.year()">({{month.year}})</span></div>
        <div style="cursor: pointer; user-select: none; margin-right: 5px;">
            <i class="fa fa-caret-left fa-2x" (click)="previousMonth()"></i>
            <i class="fa fa-caret-right fa-2x" (click)="nextMonth()" style="margin-left: 5px;"></i>
        </div>
    </div>
    <div class="month-wrapper">
        <table class="month-table">
            <thead>
                <tr class="widget-grid-header"><th>S</th><th>M</th><th>T</th><th>W</th><th>T</th><th>F</th><th>S</th></tr>
            </thead>
            <tbody>
                <tr class="week-row" *ngFor="let week of month.weeks; let i = index;">
                    <td *ngFor="let day of week.days; let j = index;"
                        [class.actual-day]="day.monthDay"
                        [class.no-day]="!day.monthDay"
                        [class.first-week]="i === 0"
                        [class.last-week]="i === month.weeks.length - 1"
                        [class.clicked-day]="clickedDay && clickedDay.monthDay && day.monthDay === clickedDay.monthDay"
                        [class.today]="isDayToday(day)"
                        [popover]="eventsPopover"
                        container="body"
                        [placement]="getPopoverPlacement(i,j)"
                        [outsideClick]="true"
                        (onHidden)="clickedDay = undefined"
                        (click)="dayClicked(day)">
                        <div>
                            <strong>{{day.monthDay}}</strong>
                        </div>
                        <div>
                            <span *ngFor="let iconClass of day.icons"><i [class]="iconClass"></i> </span>
                            <span *ngIf="!day.eventTypeBlocks" class="invisible"><i class="fa fa-gavel small-icon-badge"></i></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div style="margin-top: 5px;">
        <a *ngFor="let legendBlock of legendBlocks"
            style="padding: 1px 5px; margin-right: 5px; display: inline-block; cursor: pointer; min-width: 12em;"
            [class]="getBackgroundColor(legendBlock.dashboardCalendarEventType)"
            (click)="legendClicked(legendBlock)"
            (onHidden)="clickedDay = undefined"
            [popover]="eventsPopover"
            container="body"
            placement="top"
            [outsideClick]="true">
            <i [class]="getIconClass(legendBlock.dashboardCalendarEventType)"></i> {{legendBlock.count | number}} {{legendBlock.title}}<span *ngIf="legendBlock.count > 1">s</span>
        </a>
    </div>
</div>

<ng-template #eventsPopover>
    <div *ngIf="clickedDay">
        <i class="fa fa-spinner fa-pulse fa-3x text-center" [hidden]="!loading"></i>
        <div class="d-flex" [hidden]="loading">
            <div *ngIf="clickedDay.monthDay" class="text-center">
                <div><strong>{{clickedDay.weekdayAbbr}}</strong></div>
                <div class="fw-bold">{{clickedDay.monthDay}}</div>
            </div>
            <div class="w-100 ms-3">
                <div class="d-flex" *ngFor="let eventTypeBlock of clickedDay.eventTypeBlocks"
                     [class]="getBackgroundColor(eventTypeBlock.dashboardCalendarEventType)">
                    <div><i [class]="getIconClass(eventTypeBlock.dashboardCalendarEventType)"></i></div>
                    <div class="ps-2">
                        <a (click)="goToSmartFromPopover(eventTypeBlock)">
                            <span>{{eventTypeBlock.totalCount | number}}</span>
                            <span>{{getEventTypeTitle(eventTypeBlock.dashboardCalendarEventType, true)}}<span *ngIf="eventTypeBlock.totalCount > 1">s</span></span>
                        </a>
                        <div>
                            (<span *ngFor="let event of eventTypeBlock.events; let i=index;"
                                   (click)="goToSmartFromPopover(eventTypeBlock, event.stateAbbr)">
                                <a class="clickable">{{event.count | number}} {{event.stateAbbr}}</a>
                                <span *ngIf="i < eventTypeBlock.events.length -1">, </span>
                            </span>)
                        </div>
                    </div>
                </div>
                <div *ngIf="!clickedDay.eventTypeBlocks">
                    <em>No events this day</em>
                </div>
            </div>
        </div>
    </div>
</ng-template>
