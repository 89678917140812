import { Component, Input } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BudgetDescriptorsModalComponent } from './budget.descriptors.modal.component';
import * as _ from 'lodash';

@Component({
    selector: 'budget-descriptors-link',
    template: `
            <a (click)="launchModal()" class="pointer">Site/Parcel Characteristics</a>
        `
})
export class BudgetDescriptorsLinkComponent {
    constructor(private modalService: BsModalService) { }

    @Input() companyId: number;
    @Input() options: any;
    @Input() editMode: boolean;
    @Input() flatInUseDescriptors: Core.CompanyFeatureDescriptorModel[];

    launchModal() {
        const initialState = {
            companyId: this.companyId,
            budgetDescriptors: _.cloneDeep(this.options.budgetDescriptors),
            canEdit: this.editMode,
            flatInUseDescriptors: this.flatInUseDescriptors
        };

        const modalRef = this.modalService.show(BudgetDescriptorsModalComponent, { initialState, ignoreBackdropClick: true });

        modalRef.content.onClose = (descriptors: Core.CompanyFeatureDescriptorModel[]) => {
            console.log(descriptors);
            this.options.budgetDescriptors = descriptors;
        };
    }

}
