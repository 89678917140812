<div *ngIf="viewModel">
    <add-appeal-modal #AddAppealModal
                      [appealLevels]="viewModel.stateAppealLevels"
                      [assessments]="viewModel.annualYearModel.annualGridDetails"
                      (addAppeal)="addAppeal($event)">
    </add-appeal-modal>
    <div *ngIf="viewModel.model.length === 0" class="text-center">
        <div class="lead" *ngIf="currentYear && currentRevision">
            There are presently no appeals for {{currentYear.annualYear1}}
            {{currentRevision.revisionDesc}}.
            <div class="appeal-deadline" *ngIf="isStatusADate()">
                Deadline of {{currentRevision.appealStatus}} from the
                <span class="anchor-style" (click)="assessmentNavigateHandler(currentYear, currentRevision)">{{currentRevision.revisionDesc}}</span>
                assessment.
            </div>
        </div>
        <div class="lead" *ngIf="!currentYear || !currentRevision">
            There are presently no appeals for this year.
        </div>
        <button class="ace-btn btn-primary" *ngIf="editState.hasWritePermission" (click)="openAddAppeal()">New Appeal</button>
    </div>
    <button type="button" *ngIf="viewModel.showTabs && editState.editMode" (click)="openAddAppeal()" style="float:right;margin-top:0.5em;" class="ace-btn btn-primary btn-sm">New Appeal</button>
    <div [style.max-width]="editState.editMode ? '80%' : 'none'">
        <tabset *ngIf="viewModel.model && viewModel.model.length > 0 && viewModel.showTabs" #appealTabs>
            <ng-container *ngFor="let appeal of viewModel.model; let i=index; let last=last">
                <tab [heading]="appealHeading(i)"
                    [active]="i === viewModel.currentAppealIndex"
                    (selectTab)="switchTabs(i)">
                    <ng-container [ngTemplateOutlet]="appealTemplate" [ngTemplateOutletContext]="{ $implicit: appeal }"></ng-container>
                </tab>
            </ng-container>
        </tabset>
    </div>
    <ng-container *ngIf="viewModel.currentAppeal && !viewModel.showTabs"
                [ngTemplateOutlet]="appealTemplate"
                [ngTemplateOutletContext]="{ $implicit: viewModel.currentAppeal }">
    </ng-container>
</div>

<ng-template #appealTemplate let-appeal>
    <div class="float-end">
        <entity-in-progress-indicator *ngIf="appeal.hasInProgressIntakeItems && !isDocumentProcessing" [entityId]="appeal.appealID" [entityTypeId]="appealEntityTypeId" [isDocumentProcessing]="isDocumentProcessing"></entity-in-progress-indicator>
        <entity-attachments [(hasAttachments)]="appeal.hasAttachments"
                            buttonClass="has-icon"
                            [modalData]="viewModel.attachmentModalData">
        </entity-attachments>
        <entity-comments [(hasComments)]="appeal.hasComments"
                         buttonClass="has-icon"
                         [modalData]="viewModel.commentModalData"
                         [disabled]="viewModel.loadingTaskSummary">
        </entity-comments>
    </div>
    <table class="annual-appeal" (change)="setDirty()">
        <tr>
            <td>Appeal Level:</td>
            <td>
                <span *ngIf="!editState.editMode">{{viewModel.appealLevelAbbrDisplay(appeal.appealLevelID)}}</span>
                <select *ngIf="editState.editMode" [(ngModel)]="appeal.appealLevelID" class="form-select form-select-sm" (ngModelChange)="changeAppealLevelObj($event, appeal)" >
                    <!--ng-change="changeAppealLevelObj(appeal)"-->
                    <option *ngFor="let level of viewModel.stateAppealLevels" [ngValue]="level.appealLevelID">{{level.abbr}}</option>
                </select>
            </td>
        </tr>
        <tr *ngIf="enableAppealLitigation">
            <td>Litigation:</td>
            <td>
                <span>{{viewModel.appealLevelLitigationDisplay(appeal.appealLevelID)}}</span>
            </td>
        </tr>
        <tr>
            <td>Revision Appealed:</td>
            <td>
                <span *ngIf="!editState.editMode">{{getAssessmentDescription(appeal.annualAssessmentID)}}</span>
                <select *ngIf="editState.editMode" [(ngModel)]="appeal.annualAssessmentID" class="form-select form-select-sm" (ngModelChange)="setSavings(appeal);">
                    <option *ngFor="let assessment of viewModel.annualYearModel.annualGridDetails"
                            [ngValue]="assessment.annualAssessmentID">{{assessment.revisionDesc}}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td>Filing Reference:</td>
            <td>
                <span *ngIf="!editState.editMode">{{appeal.filingReference}}</span>
                <input *ngIf="editState.editMode" class="form-control" [(ngModel)]="appeal.filingReference" maxlength="50" />
            </td>
        </tr>
        <tr>
            <td>Case #:</td>
            <td>
                <span *ngIf="!editState.editMode">{{appeal.caseNumber}}</span>
                <input *ngIf="editState.editMode" class="form-control" maxlength="35" [(ngModel)]="appeal.caseNumber" />
            </td>
        </tr>
        <tr>
            <td>Hearing Location:</td>
            <td>
                <span *ngIf="!editState.editMode">{{appeal.hearingLocation}}</span>
                <input *ngIf="editState.editMode" class="form-control" maxlength="50" [(ngModel)]="appeal.hearingLocation" />
            </td>
        </tr>
        <tr>
            <td>File By Date:</td>
            <td class="hearing-date">
                <div class="input-group" style="display:inline-block">
                    <weissman-datetime [(inputDate)]="appeal.fileByDate"
                                       [readOnly]="!editState.editMode"
                                       [dateOnly]="true"
                                       (change)="onTaskRelevantFieldChanged()"
                                       #fileByDate>
                    </weissman-datetime>
                </div>
            </td>
        </tr>
        <tr *ngIf="showSubmitEvidence() || appeal.isFromAcquisition">
            <td>Submit Evidence Date:</td>
            <td class="hearing-date">
                <div class="input-group" style="display:inline-block">
                    <weissman-datetime [(inputDate)]="appeal.submitEvidenceDateTimeLocal"
                                       [readOnly]="!editState.editMode"
                                       (validationChange)="onSubmitEvidenceValidationChange($event)"
                                       (change)="onTaskRelevantFieldChanged()"
                                       #submitEvidenceDateTimeLocal>
                    </weissman-datetime>
                </div>
                <div style="display: inline-block">
                    <i *ngIf="showBrokenChain(appeal.submitEvidenceDateTimeLocal)" class="fa fa-chain-broken"
                       style="margin-left: 6px; position:absolute; margin-top: -22px;" aria-hidden="true"></i>
                </div>
            </td>
        </tr>
        <tr *ngIf="showConfirmHearing() || appeal.isFromAcquisition">
            <td>Confirm Hearing Date:</td>
            <td class="hearing-date">
                <div class="input-group" style="display:inline-block">
                    <weissman-datetime [(inputDate)]="appeal.confirmHearingDate"
                                       [readOnly]="!editState.editMode"
                                       [dateOnly]="true"
                                       (change)="onTaskRelevantFieldChanged()"
                                       #confirmHearingDate>
                    </weissman-datetime>
                </div>
            </td>
        </tr>
        <tr *ngIf="showInformalHearing() || appeal.isFromAcquisition">
            <td>Informal Hearing Date:</td>
            <td class="hearing-date">
                <div class="input-group" style="display:inline-block">
                    <weissman-datetime [(inputDate)]="appeal.informalHearingDateTimeLocal"
                                       [readOnly]="!editState.editMode"
                                       (validationChange)="onInformalHearingValidationChange($event)"
                                       (change)="onTaskRelevantFieldChanged()"
                                       #informalHearingDateTimeLocal>
                    </weissman-datetime>
                </div>
                <div style="display: inline-block">
                    <i *ngIf="showBrokenChain(appeal.informalHearingDateTimeLocal)" class="fa fa-chain-broken"
                       style="margin-left: 6px; position:absolute; margin-top: -22px;" aria-hidden="true"></i>
                </div>
            </td>
        </tr>
        <tr *ngIf="showHearing() || appeal.isFromAcquisition">
            <td>Formal Hearing Date:</td>
            <td class="hearing-date">
                <div class="input-group" style="display:inline-block">
                    <weissman-datetime [(inputDate)]="appeal.hearingDateTimeLocal"
                                       [readOnly]="!editState.editMode"
                                       (validationChange)="onHearingValidationChange($event)"
                                       (change)="onTaskRelevantFieldChanged()"
                                       #hearingDateTimeLocal>
                    </weissman-datetime>
                </div>
                <div style="display: inline-block">
                    <i *ngIf="showBrokenChain(appeal.hearingDateTimeLocal)" class="fa fa-chain-broken"
                       style="margin-left: 6px; position:absolute; margin-top: -22px;" aria-hidden="true"></i>
                </div>
            </td>
        </tr>
        <tr *ngIf="!viewModel.loadingTaskSummary && viewModel.currentTaskSummary">
            <td style="vertical-align: top;">Status: </td>
            <td *ngIf="viewModel.currentTaskSummary.Status && !viewModel.loadingTaskSummary">
                <div style="margin-bottom: 6px; font-size: 14px;">
                    <i class="fa fa-check" [hidden]="!viewModel.currentTaskSummary || !viewModel.currentTaskSummary.SeriesIsComplete"
                       style="color: green; float: left; font-size: 16px;"></i>
                    <span class="anchor-style" (click)="launchTaskModal(appeal)"
                           tooltip="{{this.editState.validationMessage}}" placement="left"
                           triggers="click focus" #appealTaskValidation="bs-tooltip">
                        {{ viewModel.currentTaskSummary.Status }}
                        <span *ngIf="viewModel.currentTaskSummary.CompletedDateTime">
                            {{ viewModel.currentTaskSummary.CompletedDateTime | weissmanDateFormat: true: "Central" }}
                        </span>
                    </span>
                </div>
                <div *ngIf="canPrepareApplication(appeal)">
                    <a style="cursor: pointer;" (click)="navigateToAppealApplication(appeal)">Edit Application</a>
                </div>
            </td>
        </tr>
        <tr *ngIf="!viewModel.currentTaskSummary && !viewModel.loadingTaskSummary">
            <td>Status: </td>
            <td *ngIf="!viewModel.currentTaskSummary && !viewModel.loadingTaskSummary">
                <div style="margin-bottom: 6px; font-size: 14px;">
                    N/A - No Responsibility
                </div>
            </td>
        </tr>
        <tr>
            <td>Resolution:</td>
            <td>
                <span *ngIf="!editState.editMode">{{ getAppealStatus(appeal) }}</span>
                <select
                    class="form-select form-select-sm"
                    *ngIf="editState.editMode"
                    [ngModel]="appeal.appealStatusID"
                    (ngModelChange)="appealStatusChanged(appeal, $event)">
                    <option *ngFor="let status of appealStatuses" [ngValue]="status.appealStatusID">{{status.status}}</option>
                </select>
            </td>
        </tr>
        <tr *ngIf="isClosed(appeal)">
            <td>Outcome Revision:</td>
            <td>
                <span *ngIf="!editState.editMode">{{ getAppealOutcomeRevision(appeal) }}</span>
                <select
                    class="form-select form-select-sm"
                    *ngIf="editState.editMode"
                    [(ngModel)]="appeal.outcomeAnnualAssessmentID"
                    (ngModelChange)="appealOutcomeRevisionChanged(appeal)">
                    <option [ngValue]="null"></option>
                    <option *ngFor="let revision of viewModel.annualYearModel.annualGridDetails" [ngValue]="revision.annualAssessmentID">{{revision.revisionDesc}}</option>
                </select>
            </td>
        </tr>
        <tr *ngIf="!isClosed(appeal)">
            <td>Anticipated FMV:</td>
            <td>
                <span *ngIf="!editState.editMode">{{ appeal.anticipatedFMV | number:'1.0-0' }}</span>
                <input
                    class="form-control input-number"
                    *ngIf="editState.editMode"
                    [(ngModel)]="appeal.anticipatedFMV"
                    (ngModelChange)="setDirty()"
                    (blur)="calculateSavings(appeal)"
                    currencyMask
                    [options]="{ allowNegative: false, precision: 0, prefix: '' }" />
            </td>
        </tr>
        <tr>
            <td>Savings:</td>
            <td class="savings">
                <span [ngClass]="{'overridden': appeal.savingsOverridden, 'estimated': !isClosed(appeal)}" *ngIf="!editState.editMode && appeal.savings">{{ appeal.savings | currency: 'USD'}}</span>
                <div *ngIf="editState.editMode" class="mb-3 has-feedback" style="margin-bottom: 0;">
                    <input
                        class="form-control input-number"
                        style="padding-right: 10px; display: inline-block;"
                        [ngClass]="{'overridden': appeal.savingsOverridden, 'estimated': !isClosed(appeal)}"
                        [(ngModel)]="appeal.savings"
                        (ngModelChange)="savingsChanged(appeal)"
                        currencyMask
                        [options]="{ allowNegative: false, precision: 2 }" />
                    <i class="fa fa-spinner fa-pulse form-control-feedback" *ngIf="isCalculatingSavings"></i>
                    <button class="ace-btn btn-sm btn-primary btn-danger"
                        *ngIf="appeal.savingsOverridden"
                        (click)="revertToOriginalSavings(appeal)"
                        style="margin: 0;">
                            <i class="fa fa-undo"></i>
                    </button>
                            <!--
                                (blur)="savingsChanged(appeal)"
                                -->
                </div>
            </td>
        </tr>
        <tr *ngIf="isClosed(appeal)">
            <td>Savings Date:</td>
            <td class="hearing-date">
                <div class="input-group" style="display:inline-block">
                    <weissman-datetime [(inputDate)]="appeal.savingsDate"
                                       [readOnly]="!editState.editMode"
                                       [dateOnly]="true"
                                       (change)="onTaskRelevantFieldChanged()"
                                       #savingsDate>
                    </weissman-datetime>
                </div>
            </td>
        </tr>
        <tr *ngIf="hasRyanPrivateView && isRyanInstance">
            <td>Non-Ryan:</td>
            <td>
                <input type="checkbox" [disabled]="!(hasRyanPrivateEdit && editState.editMode)" [(ngModel)]="appeal.isNonRyan">
            </td>
        </tr>
        <tr *ngIf="hasRyanPrivateView && isRyanInstance && isRyanLegalServiceEnabled">
            <td>RLS:</td>
            <td>
                <input type="checkbox" [disabled]="!(hasRyanPrivateEdit && editState.editMode)" [(ngModel)]="appeal.isRyanLegalServices">
            </td>
        </tr>
    </table>
    <button class="ace-btn btn-sm btn-primary btn-danger"
            type="button"
            *ngIf="!hideDelete && editState.editMode"
            (click)="deleteAppeal(appeal)">Delete</button>
</ng-template>
