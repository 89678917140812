import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LoadPdfIframeService } from './LoadPdfIframeService';

@Component({
    selector: 'pdf-viewer',
    template: '<iframe #iframe style="width: 100%; height: 100%;"></iframe>'
})
export class PDFViewerComponent implements OnInit {
    constructor(private loadPdfIframeService: LoadPdfIframeService) { }

    @Input() fileUrl: string;
    @ViewChild('iframe', { static: true }) iframe: ElementRef;

    ngOnInit(): void {
        this.loadPdfIframeService.initializePdfJs(this.iframe.nativeElement, this.fileUrl);
    }
}
