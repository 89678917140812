<div class="card panel-flat-color secondary-panel">
    <div class="ws-flex-container-horizontal">
        <div class="panel-flat-header ws-flex-grow"
             (click)="expandOrCollapse()"
             [ngClass]="{ 'pointer': !editMode }"
             style="align-items: baseline;">
            <panel-chevron [showBody]="isShowInfo"></panel-chevron>
            <h3>Return Settings</h3>
        </div>
        <div class="panel-flat-header" *ngIf="isShowInfo">
            <div class="float-end header-button-container">
                <button class="ace-btn btn-text has-icon"
                        *ngIf="allowEdit && !editMode && !editLock"
                        (click)="setEditMode()">
                    <i class="fa fa-pencil"></i>
                </button>
                <button class="ace-btn btn-text has-icon" *ngIf="editMode" (click)="save()" [disabled]="!hasChanges">
                    <i class="fa fa-save"></i>
                </button>
                <button class="ace-btn btn-text has-icon" *ngIf="editMode" (click)="cancelEdit()">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="card-body" *ngIf="isShowInfo">
        <loading-spinner [show]="isLoading" [size]="5" [marginBottom]="50"></loading-spinner>
        <ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>
        <return-settings [entityId]="companyId"
                         [entityType]="ReturnSettingsEntityTypeEnum.Company"
                         (onInitialized)="onReturnSettingsInitialized()"
                         (changed)="onReturnSettingsChanged($event)">
        </return-settings>
    </div>
</div>
