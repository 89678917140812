import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail, SiteStatus } from '../report.manager.model';
import * as _ from 'lodash';
import { Constants } from '../../../constants.new';
import { ReportManagerService } from '../report.manager.service';

@Component({
    selector: 'further-limit-panel-progress-status',
    template: `
        <div class="form-inline progress-status-form">
            <div class="form-group">
                <label>Tax Years: </label>
                <select [(ngModel)]="report.criteria.taxYearBegin" (change)="yearChangedValidation()" class="form-select-sm">
                    <option [ngValue]=""></option>
                    <option *ngFor="let year of availableYears" [ngValue]="year">{{ year }}</option>
                </select>
                <label> to </label>
                <select [(ngModel)]="report.criteria.taxYearEnd" [disabled]="!report.criteria.taxYearBegin" class="form-select-sm report-manager-year-list">
                    <option [ngValue]=""></option>
                    <option *ngFor="let year of availableYears" [disabled]="year < report.criteria.taxYearBegin" [ngValue]="year">{{ year }}</option>
                </select>
            </div>
            <div class="form-group" style="margin-left: 20px;">
                <label style="vertical-align: top; margin-top: 5px;">Property Type: </label>
                <select multiple [(ngModel)]="report.criteria.propertyTypeIDs" class="form-select-sm">
                    <option *ngFor="let propType of propertyTypes" [ngValue]="propType.propertyTypeId">{{ propType.name }}</option>
                </select>
            </div>
            <div class="form-group" style="margin-left: 20px;">
                <label>Site Status: </label>
                <select class="form-select-sm" [(ngModel)]="report.criteria.siteStatus">
                    <option *ngFor="let status of siteStatuses" [ngValue]="status.id">{{ status.name }}</option>
                </select>
            </div>
        </div>
    `
})
export class FurtherLimitPanelProgressStatusComponent implements OnInit {
    constructor(private constants: Constants, private reportManagerService: ReportManagerService) { }

    @Input() report: ReportDetail;
    @Input() availableYears: number[];
    propertyTypes: any[];
    siteStatuses: SiteStatus[];

    ngOnInit() {

        this.propertyTypes = _.map(this.constants.PropertyTypeNames, (name: string, id: number) => {
            return {
                name: name,
                propertyTypeId: Number(id)
            };
        });

        this.siteStatuses = this.reportManagerService.getSiteStatusOptions();

        this.report.criteria.siteStatus = this.report.criteria.siteStatus || this.siteStatuses[0].id;
        if (!this.report.criteria.taxYearBegin) {
            const newYearValue = new Date().getFullYear() - 3;
            this.report.criteria.taxYearBegin = newYearValue;
        }
        this.report.criteria.propertyTypeIDs = this.report.criteria.propertyTypeIDs || [1, 2, 3, 4];
    }

    yearChangedValidation(): void {
        if (this.report.criteria.taxYearBegin > this.report.criteria.taxYearEnd) {
            this.report.criteria.taxYearEnd = null;
        }
    }
}
