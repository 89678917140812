import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ExtraReportDetailCriteria } from '../report.manager.model';
import { AssessmentTaxesOptions, AssessmentTaxesCriteria } from '../../../Common/Assessment-Taxes-Details/assessment-taxes-details.models';
import * as _ from 'lodash';

@Component({
    selector: 'report-detail-options',
    template: `
    <div class="detail-report-options" style="padding: 10px;">
        <div>
            <label class="select-label">Value Details:</label>
            <select [(ngModel)]="detailReportOptions.valueDetails" class="form-select-sm intput-sm">
                <option *ngFor="let option of valueDetailsOptions; let i = index;" [ngValue]="i">{{option}}</option>
            </select>
        </div>
        <div>
            <label class="select-label">Include Valuation Metrics:</label>
            <select [(ngModel)]="detailReportOptions.includeValuationMetrics" class="form-select-sm intput-sm">
                <option *ngFor="let option of includeValuationMetricsOptions; let i = index;" [ngValue]="i">{{option}}</option>
            </select>
        </div>
        <div>
            <label class="select-label">Include Target Value:</label>
            <select [(ngModel)]="detailReportOptions.includeTargetValue" class="form-select-sm intput-sm">
                <option *ngFor="let option of includeTargetValueOptions; let i = index;" [ngValue]="i">{{option}}</option>
            </select>
        </div>
        <div>
            <label class="select-label">Include Assessed Value Info:</label>
            <select [(ngModel)]="detailReportOptions.includeAssessedValueInfo" class="form-select-sm intput-sm">
                <option *ngFor="let option of includeAssessedValueInfoOptions; let i = index;" [ngValue]="i">{{option}}</option>
            </select>
        </div>
        <div>
            <label class="select-label">Break Out Tax Payment Details:</label>
            <select [(ngModel)]="detailReportOptions.breakOutTaxPaymentDetails" class="form-select-sm intput-sm">
                <option *ngFor="let option of breakOutTaxPaymentDetailsOptions; let i = index;" [ngValue]="i">{{option}}</option>
            </select>
        </div>
        <div>
            <label class="select-label">Show % Change:</label>
            <select [(ngModel)]="detailReportOptions.showPercentChange" class="form-select-sm intput-sm">
                <option *ngFor="let option of showPercentChangeOptions; let i = index;" [ngValue]="i">{{option}}</option>
            </select>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <div class="checkbox" *ngIf="isBudgetReport">
                        <label>
                            <input type="checkbox" [(ngModel)]="detailReportOptions.includeBudgetComments"> Include Budget Comments
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="detailReportOptions.showLienDate"> Show Lien Date
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="detailReportOptions.includePaymentDueDates"> Include Payment Due Dates
                        </label>
                    </div>
                    <div class="checkbox">
                        <label>
                            <input type="checkbox" [(ngModel)]="detailReportOptions.includeAlternateAssessments"> Include Alternate Assessments
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `
})
export class ReportDetailOptionsComponent implements OnInit {
    @Input() isBudgetReport?: boolean;

    @Output()
    detailReportOptionsChange = new EventEmitter<ExtraReportDetailCriteria>();
    valueDetailsOptions: string[];
    includeAssessedValueInfoOptions: string[];
    includeTargetValueOptions: string[];
    includeValuationMetricsOptions: string[];
    breakOutTaxPaymentDetailsOptions: string[];
    showPercentChangeOptions: string[];

    @Input()
    get detailReportOptions() {
        return this._detailReportOptions;
    }

    set detailReportOptions(val) {
        this._detailReportOptions = val;
        this.detailReportOptionsChange.emit(this._detailReportOptions);
    }

    private _detailReportOptions: ExtraReportDetailCriteria;

    ngOnInit() {
        _.assign(this, new AssessmentTaxesOptions());

        this.detailReportOptions = this.detailReportOptions || new AssessmentTaxesCriteria();
    }
}
