import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output, SimpleChanges, ViewChild
} from '@angular/core';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import { ProcessingService } from '../../../Processing/processing.service.upgrade';
import { SearchService } from '../../search.service';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { PropertyTypeService } from '../../../Common/Services/propertyType.service.upgrade';
import { QuickCriteria, QuickCriteriaFilter, QuickResultType } from './quickCriteria.model';

@Component({
    selector: 'quick-search-criteria',
    templateUrl: './quickSearchCriteria.component.html'
})
export class QuickSearchCriteriaComponent implements OnInit, OnChanges {
    constructor(private readonly _propertyTypeService: PropertyTypeService,
                private readonly _processingService: ProcessingService,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _searchService: SearchService
    ) { }

    @Input() criteria: QuickCriteria;
    @Input() isDocumentProcessing: boolean;
    @Input() resultTypes: QuickResultType[];
    @Input() currentDocument: any;
    @Input() isOpen: boolean;

    @Output() criteriaChange: EventEmitter<QuickCriteria> = new EventEmitter();
    @Output() search: EventEmitter<boolean> = new EventEmitter();

    states = [];
    propertyTypes = [];

    @HostListener('keydown.enter') onEnterPressed() {
        this.search.emit(true);
    }

    @ViewChild('dropdown') bsDropdown: BsDropdownDirective;

    ngOnInit(): void {
        this._propertyTypeService.get()
            .then(result => {
                result.unshift({
                    propertyTypeID: '',
                    propTypeAbbr: ''
                });

                this.propertyTypes = result;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentDocument && changes.currentDocument.currentValue) {
            this.resultTypes = this._processingService.getResultTypesByDocumentType(this.currentDocument.attachmentType);

            if (this.resultTypes.length) {
                const foundResultType = this.resultTypes.find(x => x.id === this.currentDocument.attachmentType.defaultResultsEntityTypeID);

                this.criteria.resultType = foundResultType ? foundResultType.type : this.resultTypes[0].type;
            }

            this.criteria.activeFilters = this.criteria.activeFilters.map((filter) => {
                filter.text = this.currentDocument[filter.field]
                    || this.currentDocument[filter.resultField]
                    || filter.text;

                return filter;
            });

            this.criteria.inactiveFilters.forEach(filter => {
                filter.text = this.currentDocument[filter.field] || this.currentDocument[filter.resultField] || filter.text;

                if (filter.text) {
                    this.criteria.activeFilters.push(filter);
                    this.criteria.inactiveFilters = this.criteria.inactiveFilters.filter(x => x !== filter);
                }
            });
        }
        if (changes.isOpen && !changes.isOpen.firstChange && !changes.isOpen.currentValue) {
            this.bsDropdown.hide();
        }
    }

    addFilterButtonClicked(): void {
        this._productAnalyticsService.logEvent('add-additional-criteria');
    }

    addFilter(filter: QuickCriteriaFilter): boolean {
        filter.text = '';

        this.criteria.inactiveFilters = this.criteria.inactiveFilters.filter(x => x !== filter);
        this.criteria.activeFilters = [...this.criteria.activeFilters, filter];

        const sortedFilters = this.criteria.activeFilters.sort((a, b) => a.sequenceNum - b.sequenceNum);
        const sortedIndex = sortedFilters.findIndex(x => x.field === filter.field);

        this._productAnalyticsService.logSequenceEvent('add-new-criteria', 'clickFilter');
        this._searchService.focusOnFilter(sortedIndex);
        return false;
    }

    removeFilter(filter: QuickCriteriaFilter, index: number): void {
        this.criteria.activeFilters = this.criteria.activeFilters.filter(x => x !== filter);
        this.criteria.inactiveFilters = [...this.criteria.inactiveFilters, filter];

        this._searchService.focusOnFilter(index - 1);
    }

    changeResultType(): void {
        const entityTypes = ['parcel', 'site', 'company'];
        const subEntityTypes = ['filing', 'taxBill', 'assessment', 'appeal'];

        const filterTypes = this.criteria.activeFilters.filter(x => x.text).map(x => x.associatedWith);

        const foundType = [...entityTypes, ...subEntityTypes].find((type) => {
            return filterTypes.includes(type) && this.resultTypes.some(x => x.type === type);
        });

        if(!foundType || (foundType === 'parcel' && subEntityTypes.includes(this.criteria.resultType))) {
            return;
        }

        this.criteria.resultType = foundType;
    }

    showAnticipated(): boolean {
        const anticipatedResultTypes = ['assessment', 'appeal', 'taxBill', 'refund', 'filing'];

        return this.isDocumentProcessing && anticipatedResultTypes.includes(this.criteria.resultType);
    }

    addFilterToggled(isOpen: boolean): void {
        if (isOpen) {
            this._productAnalyticsService.initiateSequenceEvent({
                event: 'add-new-criteria',
                properties: {
                    addCriteria: 'no'
                }
            }, ['clickAdd', 'clickAway'], true);
            this._productAnalyticsService.initiateSequenceEvent({
                event: 'add-new-criteria',
                properties: {
                    addCriteria: 'yes'
                }
            }, ['clickAdd', 'clickFilter'], true);

        } else {
            this.addFilterClosed();
        }
    }

    addFilterClosed(): void {
        this._productAnalyticsService.logSequenceEvent('add-new-criteria', 'clickAway');
    }

    togglePrimaryAcctNumOnly() {
        this._productAnalyticsService.logEvent('check-primary-acct-num-only', {
            DpPrimaryAcct: this.criteria.primaryAcctNumOnly ? 'yes' : 'no'
        });
    }

    toggleExcludeInactive() {
        this._productAnalyticsService.logEvent('check-exclude-inactive', {
            DpExcludeInactive: this.criteria.activeOnly ? 'yes' : 'no'
        });
    }

    toggleAnticipated() {
        this._productAnalyticsService.logEvent('check-anticipated', {
            DpAnticipated: this.criteria.anticipated ? 'yes' : 'no'
        });
    }
}
