import { Injectable } from '@angular/core';
import { UtilitiesService } from '../UI-Lib/Utilities';
import { Constants, EntityTypeIds } from '../constants.new';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { Observable, Subject, lastValueFrom, takeUntil, tap } from 'rxjs';
import { Attachment, AttachmentCategory, AttachmentInfo, MimeTypeExtension } from './attachment.model';
import { endsWith, find, includes } from 'lodash';
import { mimeTypeExtensions } from './attachments.constants';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';

declare global {
    interface Navigator {
        msSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
        webkitSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
        mozSaveBlob?: (blob: Blob, defaultName?: string) => boolean;
        saveBlob?: (blob: Blob, defaultName?: string) => boolean;
    }
}

@Injectable()
export class AttachmentService {
    constructor(private readonly _utils: UtilitiesService,
        private readonly _http: HttpClient,
        private readonly _constants: Constants,
        private readonly _navService: UpgradeNavigationServiceHandler,
        private readonly _featureFlagsService: FeatureFlagsService) { }

    private readonly SERVICE_URL = 'api/attachment';
    private readonly CATEGORY_SERVICE_URL = '/api/AttachmentCategory';
    private readonly TYPE_SERVICE_URL = '/api/AttachmentType';
    private _terminateSub: Subject<void> = new Subject();

    createAttachment(files: File[], attachment: Attachment): Promise<Attachment[]> {
        const entityTypeName = this._getEntityTypeName(attachment.entityTypeID);
        const formData = new FormData();

        if (files.length > 0) {
            formData.append('0', files[0]);
        }

        const url = `${this.SERVICE_URL}/${entityTypeName}/${attachment.entityID}?json=${encodeURIComponent(JSON.stringify(attachment))}`;

        const request = this._http.post(url, formData, {
            params: {
                siteID: this._navService.getQuerystringParam('siteId') // This is weird, we should be passing this in
            }
        });

        return lastValueFrom(request) as Promise<Attachment[]>;
    }

    getInfo(attachmentId:string): Promise<Attachment> {
        return lastValueFrom(this._http.get<Attachment>(`${this.SERVICE_URL}/${encodeURIComponent(attachmentId)}/info`));
    }

    getAll(entityTypeId: number, entityId: number, includeChildren: boolean): Promise<Attachment[]> {
        const entityTypeName = this._getEntityTypeName(entityTypeId);

        if (entityTypeName === 'Parcel') {
            includeChildren = true;		// we always include children for parcel
        }

        const config = {
            params: {
                siteID: this._navService.getQuerystringParam('siteId'), // This is weird, we should be passing this in
                year: 'All',
                categoryID: 0,
                includeChildren,
                typeID: entityTypeId
            }
        };

        const url = `${this.SERVICE_URL}/${entityTypeName}/all/${entityId}`;
        return lastValueFrom(this._http.get(url, config)) as Promise<Attachment[]>;
    }

    getSingleAttachmentBody(attachmentInfo: AttachmentInfo, dontCancel?: boolean): Observable<HttpResponse<ArrayBuffer>> {
        if(!dontCancel) {
            this._terminateSub.next();
        }
        const url = `${this.SERVICE_URL}/${attachmentInfo.attachmentID}`;

        return this._http.get(url, {responseType: 'arraybuffer', observe: 'response'}).pipe(
            takeUntil(this._terminateSub)
        );
    }

    // Based on http://stackoverflow.com/questions/24080018/download-file-from-an-asp-net-web-api-method-using-angularjs
		//
    performDownload(data, filename: string, fileExtension: string): void {
        const octetStreamMime = 'application/octet-stream';
        let success = false;
        const contentType = this.getMimeTypeByFileExtension(fileExtension);

        try {
            if (!endsWith(filename, `.${fileExtension}`)) {
                filename = `${filename}.${fileExtension}`;
            }
            // Try using msSaveBlob if supported
            console.log('Trying saveBlob method ...');
            const blob = new Blob([data], { type: contentType.type });
            if (navigator.msSaveBlob)
                navigator.msSaveBlob(blob, filename);
            else {
                // Try using other saveBlob implementations, if available
                const saveBlob = navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                if (saveBlob === undefined) throw 'Not supported';
                saveBlob(blob, filename);
            }
            console.log('saveBlob succeeded');
            success = true;
        } catch (ex) {
            console.log('saveBlob method failed with the following exception:');
            console.log(ex);
        }

        if (!success) {
            // Get the blob url creator
            const urlCreator = window.URL || window.webkitURL;
            if (urlCreator) {
                // Try to use a download link
                const link = document.createElement('a');
                if ('download' in link) {
                    // Try to simulate a click
                    try {
                        // Prepare a blob URL
                        console.log('Trying download link method with simulated click ...');
                        const blob = new Blob([data], { type: contentType.type });
                        const url = urlCreator.createObjectURL(blob);
                        link.setAttribute('href', url);

                        // Set the download attribute (Supported in Chrome 14+ / Firefox 20+)
                        link.setAttribute('download', filename);

                        // Simulate clicking the download link
                        const event = document.createEvent('MouseEvents');
                        event.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 0, null);
                        link.dispatchEvent(event);
                        console.log('Download link method with simulated click succeeded');
                        success = true;

                    } catch (ex) {
                        console.log('Download link method with simulated click failed with the following exception:');
                        console.log(ex);
                    }
                }

                if (!success) {
                    // Fallback to window.location method
                    try {
                        // Prepare a blob URL
                        // Use application/octet-stream when using window.location to force download
                        console.log('Trying download link method with window.location ...');
                        const blob = new Blob([data], { type: octetStreamMime });
                        const url = urlCreator.createObjectURL(blob);
                        window.location = url as any;
                        console.log('Download link method with window.location succeeded');
                        success = true;
                    } catch (ex) {
                        console.log('Download link method with window.location failed with the following exception:');
                        console.log(ex);
                    }
                }
            }
        }
    }

    getMimeTypeByFileExtension(fileExt: string): MimeTypeExtension {
        // todo should we store mime type along with the file?
        //

        if (fileExt && fileExt.length > 0) {
            if (fileExt[0] != '.')
                fileExt = `.${  fileExt}`;
        }

        const foundMimeType = find(mimeTypeExtensions, type => includes(type.exts, fileExt.toLowerCase()));
        const defaultMimeType = {
            type: 'application/octet-stream',
            previewSupported: false,
            exts: []
        };

        return foundMimeType || defaultMimeType;
    }

    async downloadAttachmentFile(attachmentInfo: AttachmentInfo, allowMultiple?: boolean): Promise<void> {
        // If the file has a size specified and is large, stream instead of buffering it all into the client
        if (attachmentInfo.size && attachmentInfo.size > (this._featureFlagsService.featureFlags.fileDownloadBufferMaxSizeKB * 1024)) {
            await this.downloadAttachmentFileStreamed(attachmentInfo);
            return;
        }
        
        await this.downloadAttachmentFileBuffered(attachmentInfo, allowMultiple);
    }

    async downloadAttachmentFileBuffered(attachmentInfo: AttachmentInfo, allowMultiple?: boolean): Promise<void> {
        await lastValueFrom(this.getSingleAttachmentBody(attachmentInfo, allowMultiple).pipe(
            tap((response: HttpResponse<any>) => {

                if(!attachmentInfo.fileName) {
                    attachmentInfo.fileName = this.getFileNameFromHttpResponse(response);
                }
                if(attachmentInfo.fileName && !attachmentInfo.fileExtension) {
                    attachmentInfo.fileExtension = attachmentInfo.fileName.split('.').pop();
                }

                this.performDownload(response.body, attachmentInfo.fileName, attachmentInfo.fileExtension);
            })
        ));
    }

    async downloadAttachmentFileStreamed(attachmentInfo: AttachmentInfo): Promise<void> {
        const oneTimeCode = await lastValueFrom(this._http.get<string>(`/api/attachment/${attachmentInfo.attachmentID}/OneTimeCode`));

        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', `/api/attachment/Download/${encodeURIComponent(oneTimeCode)}`);
        link.dispatchEvent(new MouseEvent('click'));
    }

    getFileNameFromHttpResponse(httpResponse: HttpResponse<any> | any, isUntyped?: boolean): string {
        let fileName = '';
        const contentDisposition = isUntyped
            ? httpResponse.headers('Content-Disposition') || ''
            : httpResponse.headers.get('Content-Disposition') || '';

        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '').trim();
        }

        return fileName;
    }

    downloadAttachmentByGuid(attachmentGuid: string, filename: string, fileExtension: string): void {
        this._terminateSub.next(); // cancels any outstanding requests

        const url = `${this.SERVICE_URL}/${attachmentGuid}`;

        this._http.get(url, {responseType: 'arraybuffer'}).pipe(
            takeUntil(this._terminateSub)
        ).subscribe((response: any) => {
            this.performDownload(response, filename, fileExtension);
        });
    }

    isPreviewSupported(type: string): boolean {
        const entry = find(mimeTypeExtensions, { type });
        return entry?.previewSupported || false;
    }

    async deleteAttachment(attachmentInfo: AttachmentInfo): Promise<void> {
        const url = `${this.SERVICE_URL}/${attachmentInfo.attachmentID}`;
        await lastValueFrom(this._http.delete(url));
    }

    update(attachmentInfo: AttachmentInfo): Promise<Attachment> {
        const entityTypeName = this._getEntityTypeName(attachmentInfo.entityTypeID);
        const url = `${this.SERVICE_URL}/${entityTypeName}/${attachmentInfo.entityID}`;

        return lastValueFrom(this._http.put(url, attachmentInfo)) as Promise<Attachment>;
    }

    getAttachmentIcon(hasAttachments: boolean): string {
        return hasAttachments
            ? this._constants.ImageURLs.ATTACHMENTS_ALL_SOME_ICON
            : this._constants.ImageURLs.ATTACHMENTS_ALL_NONE_ICON;
    }

    checkForAttachments(entityTypeID: number, entityID: number): Promise<boolean> {
        const url = `${this.SERVICE_URL}/${entityTypeID}/${entityID}/hasattachments`;
        return lastValueFrom(this._http.get(url)) as Promise<boolean>;
    }

    getAttachmentCategories(): Promise<AttachmentCategory[]> {
        return lastValueFrom(this._http.get(this.CATEGORY_SERVICE_URL)) as Promise<AttachmentCategory[]>;
    }

    getAttachmentTypes(): Promise<Core.AttachmentTypeModel[]> {
        return lastValueFrom(this._http.get(this.TYPE_SERVICE_URL)) as Promise<Core.AttachmentTypeModel[]>;
    }

    private _getEntityTypeName(entityTypeId: number): string {
        const entityTypeName = this._utils.findPropertyName(EntityTypeIds, entityTypeId);

        if (entityTypeName === 'tax_bill') {
            return 'Bill';
        }
        if (entityTypeName === 'assessment') {
            return 'AnnualAssessment';
        }
        if(entityTypeName === 'payment_batch') {
            return 'PaymentBatch';
        }

        return entityTypeName;
    }
}