<ws-modal-wrapper headerLabel="Appeal Determination Assignment"
                  headerHelpContentId="asset-advanced.header"
                  acceptHelpContentId="asset-advanced.accept"
                  (save)="save()"
                  (cancel)="cancel()"
                  [disableSave]="disableSubmit">
    <div class="ws-flex-container-vertical ws-stretch">
        <div class="ws-flex-container-horizontal ws-stretch">
            <label style="margin-right: 10px;">Quick:</label>
            <select name="selectOptions" id="selectOptions" class="form-select form-select-sm" style="width: 30%" [ngModel]="selectedOptionName" (ngModelChange)="selectedOptionChanged($event, true)">
                <option *ngFor="let option of params.options" [ngValue]="option.name">{{ option.name }}</option>
            </select>
        </div>
        <div class="ws-flex-container-horizontal ws-stretch" style="margin-top: 20px;">
            <div class="ws-flex-container-vertical ws-stretch">
                <label>Show:</label>
                <div class="radio">
                    <label><input type="radio" name="show" [ngModel]="selectedOption.value.show" (ngModelChange)="selectionChanged($event, 'show')" [value]="appealRecommendationCCAssignedFilterShowOptionEnum.MyTasks">My Tasks</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="show" [ngModel]="selectedOption.value.show" (ngModelChange)="selectionChanged($event, 'show')" [value]="appealRecommendationCCAssignedFilterShowOptionEnum.MyTeamTasks">My Team's Tasks</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="show" [ngModel]="selectedOption.value.show" (ngModelChange)="selectionChanged($event, 'show')" [value]="appealRecommendationCCAssignedFilterShowOptionEnum.SpecificIndividualTasks">Specific Individual's Tasks</label>
                    <div *ngIf="+selectedOption.value.show === +appealRecommendationCCAssignedFilterShowOptionEnum.SpecificIndividualTasks">
                        <div *ngFor="let user of selectedOption.value.specificUsers; index as i" class="ws-flex-container-horizontal ws-stretch" style="align-items: center; margin-bottom: 5px; margin-top: 5px;">
                            <user-team-picker [user]="user" (userChange)="setUser($event, i)" style="flex-grow: 1"></user-team-picker>
                            <button class="ace-btn has-icon btn-primary btn-danger" style="margin-bottom: 1px;" (click)="removeUser(i)">
                                <i class="fa fa-minus fa-lg"></i>
                            </button>
                        </div>
                        <button class="ace-btn has-icon btn-secondary" (click)="addNewUser()">
                            <i class="fa fa-plus fa-lg"></i>
                        </button>
                    </div>
                </div>
                <div class="radio">
                    <label><input type="radio" name="show" [ngModel]="selectedOption.value.show" (ngModelChange)="selectionChanged($event, 'show')" [value]="appealRecommendationCCAssignedFilterShowOptionEnum.SpecificTeamTasks">Specific Team's Tasks</label>
                    <div *ngIf="+selectedOption.value.show === +appealRecommendationCCAssignedFilterShowOptionEnum.SpecificTeamTasks">
                        <div *ngFor="let team of specificTeams; index as i" class="ws-flex-container-horizontal ws-stretch" style="align-items: center; margin-bottom: 5px; margin-top: 5px;">
                            <team-picker [team]="team" (teamChange)="teamChanged($event, i)" [canEdit]="true" style="flex-grow: 1;"></team-picker>
                            <button class="ace-btn has-icon btn-primary btn-danger" style="margin-bottom: 1px;" (click)="removeTeam(i)">
                                <i class="fa fa-minus fa-lg"></i>
                            </button>
                        </div>
                        <button class="ace-btn has-icon btn-secondary" (click)="addNewTeam()">
                            <i class="fa fa-plus fa-lg"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="ws-flex-container-vertical ws-stretch">
                <label>Where:</label>
                <div class="radio">
                <label><input type="radio" name="where" [ngModel]="selectedOption.value.where" (ngModelChange)="selectionChanged($event, 'where')" [value]="appealRecommendationCCAssignedFilterWhereOptionEnum.Assigned">Assigned</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="where" [ngModel]="selectedOption.value.where" (ngModelChange)="selectionChanged($event, 'where')" [value]="appealRecommendationCCAssignedFilterWhereOptionEnum.UltimatelyResponsible">Ultimately Responsible</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="where" [ngModel]="selectedOption.value.where" (ngModelChange)="selectionChanged($event, 'where')" [value]="appealRecommendationCCAssignedFilterWhereOptionEnum.AssignedOrUltimatelyResponsible">Assigned or Ultimately Responsible</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="where" [ngModel]="selectedOption.value.where" (ngModelChange)="selectionChanged($event, 'where')" [value]="appealRecommendationCCAssignedFilterWhereOptionEnum.AccountHandler">Account Handler</label>
                </div>
            </div>
            <div class="ws-flex-container-vertical ws-stretch">
                <label>That are:</label>
                <div class="radio">
                    <label><input type="radio" name="that" [ngModel]="selectedOption.value.that" (ngModelChange)="selectionChanged($event, 'that')" [value]="appealRecommendationCCAssignedFilterThatOptionEnum.Ready">Ready</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="that" [ngModel]="selectedOption.value.that" (ngModelChange)="selectionChanged($event, 'that')" [value]="appealRecommendationCCAssignedFilterThatOptionEnum.NotReady">Not Ready</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="that" [ngModel]="selectedOption.value.that" (ngModelChange)="selectionChanged($event, 'that')" [value]="appealRecommendationCCAssignedFilterThatOptionEnum.ReadyOrNotReady">Ready or Not Ready</label>
                </div>
                <div class="radio">
                    <label><input type="radio" name="that" [ngModel]="selectedOption.value.that" (ngModelChange)="selectionChanged($event, 'that')" [value]="appealRecommendationCCAssignedFilterThatOptionEnum.Completed">Completed</label>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
