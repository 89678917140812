import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ManageAPIKeysService } from './manage-api-keys.service';
import { IntegrationHmacDetail, IntegrationHmacUserDetail } from './manage-api-keys.models';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';

declare const _: any;

@Component({
    selector: 'api-key-detail',
    templateUrl: './api-key-detail.component.html'
})
export class APIKeyDetailComponent implements OnInit {
    constructor(private toastr: ToastrService,
            private messageBoxService: MessageBoxService,
            private manageAPIKeysService: ManageAPIKeysService,
            private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler) { }

    loading: boolean;
    integrationHmacId: number;
    integrationHmacDetail: IntegrationHmacDetail;
    integrationHmacDetailBackup: IntegrationHmacDetail;
    inEditMode: boolean;
    addingUser: boolean;
    newUser: Core.UserTeamModel;
    keyNameValidationMessage: string;


    async ngOnInit() {
        this.loading = true;
        this.addingUser = false;
        this.inEditMode = false;
        this.newUser = {} as Core.UserTeamModel;
        this.integrationHmacDetailBackup = null;
        this.integrationHmacId = +this.upgradeNavigationServiceHandler.getQuerystringParam('integrationHmacId');

        try {
            this.integrationHmacDetail = await this.manageAPIKeysService.getPublicKey(this.integrationHmacId);
        } finally {
            this.loading = false;
        }
    }

    backToManage(): void {
        this.upgradeNavigationServiceHandler.go('manageAPIKeys', {});
    }

    edit_click(): void {
        this.inEditMode = true;
        this.integrationHmacDetailBackup = _.cloneDeep(this.integrationHmacDetail);
        this.keyNameValidationMessage = null;
    }

    cancel_click(): void {
        this.newUser = {} as Core.UserTeamModel;
        this.addingUser = false;
        this.integrationHmacDetail = this.integrationHmacDetailBackup;
        this.integrationHmacDetailBackup = null;
        this.inEditMode = false;
    }

    async save_click(): Promise<void> {
        let valid = true;
        this.keyNameValidationMessage = null;
        if (!this.integrationHmacDetail.name) {
            valid = false;
            this.keyNameValidationMessage = 'Name is required';
        }
        else if (this.integrationHmacDetail.name.length > 50) {
            valid = false;
            this.keyNameValidationMessage = 'Name cannot be longer than 50 characters';
        }

        if (valid) {
            this.loading = true;

            try {
                this.integrationHmacDetail = await this.manageAPIKeysService.savePublicKey(this.integrationHmacDetail);
                this.integrationHmacDetailBackup = null;
                this.addingUser = false;
                this.inEditMode = false;
            } finally {
                this.loading = false;
            }
        }
    }

    deleteKey_click(): void {
        this.messageBoxService.confirmYesNo('It is generally recommended that you deactivate a key instead of deleting it. Are you really sure you want to delete this API key?').then(async () => {
            this.loading = true;

            try {
                await this.manageAPIKeysService.deleteKey(this.integrationHmacId);
                this.upgradeNavigationServiceHandler.go('manageAPIKeys', {});
            } finally {
                this.loading = false;
            }
        });
    }

    sendEmail(user: IntegrationHmacUserDetail): void {
        this.messageBoxService.confirmYesNo('Are you sure you want to send a private key email to this user?').then(async () => {
            this.loading = true;

            try {
                await this.manageAPIKeysService.sendKeyEmail(user.userId, this.integrationHmacDetail);
                this.toastr.success('An email has been sent to the user with instructions to retrieve the private key', 'Email Sent');
            } finally {
                this.loading = false;
            }
        });
    }

    removeUser(user: IntegrationHmacUserDetail): void {
        this.integrationHmacDetail.users = this.integrationHmacDetail.users.filter(u => u !== user);
    }

    beginAddUser_click(): void {
        // TODO: Maybe we should make a user control that doesn't have teams, since teams aren't relevant here.
        this.addingUser = true;
        this.newUser = {} as Core.UserTeamModel;
    }

    setNewUser(newUser: Core.UserTeamModel) {
        this.newUser = newUser;
    }

    cancelAddUser_click(): void {
        this.newUser = {} as Core.UserTeamModel;
        this.addingUser = false;
    }

    saveAddUser_click(): void {
        if (this.newUser.userID && !_.some(this.integrationHmacDetail.users, u => u.userId == this.newUser.userID)) {
            this.integrationHmacDetail.users.push(new IntegrationHmacUserDetail(this.newUser));
        }
        this.newUser = {} as Core.UserTeamModel;
        this.addingUser = false;
    }

    identify(user) {
        return user.integrationHmacUserId;
    }
}
