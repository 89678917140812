import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForLinkedParcelsListGridAction extends ICellRendererParams {
    canRemove: (params: ICellRendererParamsForLinkedParcelsListGridAction) => boolean;
    remove: (params: ICellRendererParamsForLinkedParcelsListGridAction) => void;
    toggleMaster: (params: ICellRendererParamsForLinkedParcelsListGridAction) => void;
    getMasterTitle: (params: ICellRendererParamsForLinkedParcelsListGridAction) => string;
    canToggleMaster: (params: ICellRendererParamsForLinkedParcelsListGridAction) => boolean;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <button *ngIf="params.canToggleMaster(params)" class="ace-btn has-icon btn-primary grid-action-button" [disabled]="true" [title]="params.getMasterTitle(params)" (click)="params.toggleMaster(params)">
        <i class="fa fa-star"></i>
    </button>
    <button class="ace-btn has-icon btn-primary btn-danger grid-action-button" title="Remove Link" [disabled]="!params.canRemove(params)" (click)="params.remove(params)">
        <i class="fa fa-times"></i>
    </button>
    `
})
export class LinkedParcelsListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForLinkedParcelsListGridAction;

    agInit(params: ICellRendererParamsForLinkedParcelsListGridAction): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
