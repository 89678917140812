export class AllocationDetailGridRowModel{
    constructor(private _allocationDetailModel: Compliance.AllocationDetailModel) {
    }

    get allocationDetailId(): number {
        return this._allocationDetailModel.allocationDetailId;
    }

    get assetNumber(): string {
        return this._allocationDetailModel.assetNumber;
    }

    set assetNumber(value: string) {
        this._allocationDetailModel.assetNumber = value;
    }

    get reportedCost(): number {
        return this._allocationDetailModel.reportedCost;
    }

    get returnValue(): number {
        return this._allocationDetailModel.returnValue;
    }

    get workPaperInitialAllocationAmount(): number {
        return this._allocationDetailModel.workPaperInitialAllocationAmount;
    }

    get initialAllocationAmount(): number{
        return this._allocationDetailModel.initialAllocationAmount ||
            this._allocationDetailModel.workPaperInitialAllocationAmount ||
            this._allocationDetailModel.returnValue;
    }

    set initialAllocationAmount(value: number) {
        if (this._allocationDetailModel.initialAllocationAmount !== value &&
            this._allocationDetailModel.workPaperInitialAllocationAmount !== value) {
            this._allocationDetailModel.initialAllocationAmount = value;
        }
    }

    get initialAllocationAmountOverridden(): boolean {
        return !!this._allocationDetailModel.initialAllocationAmount;
    }

    get initialAllocationAmountCalculated(): boolean {
        return !this._allocationDetailModel.initialAllocationAmount &&
            !this._allocationDetailModel.workPaperInitialAllocationAmount &&
            !!this._allocationDetailModel.returnValue;
    }

    get workPaperFinalAllocationAmount(): number {
        return this._allocationDetailModel.workPaperFinalAllocationAmount;
    }

    get finalAllocationAmount(): number{
        return this._allocationDetailModel.finalAllocationAmount ||
            this._allocationDetailModel.workPaperFinalAllocationAmount ||
            this._allocationDetailModel.initialAllocationAmount ||
            this._allocationDetailModel.workPaperInitialAllocationAmount;
    }
    set finalAllocationAmount(value: number) {
        if (this._allocationDetailModel.finalAllocationAmount !== value &&
            this._allocationDetailModel.workPaperFinalAllocationAmount !== value){
            this._allocationDetailModel.finalAllocationAmount = value;
        }
    }

    get finalAllocationAmountOverridden(): boolean {
        return !!this._allocationDetailModel.finalAllocationAmount;
    }

    set parcel(value: string) {
        this._allocationDetailModel.parcel = value;
    }
    get parcel(): string {
        return this._allocationDetailModel.parcel;
    }

    get calculatedParcel(): string {
        return this._allocationDetailModel.calculatedParcel;
    }

    get isWorkPaperParcelOverridden(): boolean {
        return this._allocationDetailModel.isWorkPaperParcelOverridden;
    }

    get statusId(): number{
        return this._allocationDetailModel.allocationDetailStatusId;
    }

    set statusId(value: number) {
        this._allocationDetailModel.allocationDetailStatusId = value;
    }

    get status(): string{
        return this._allocationDetailModel.allocationDetailStatus;
    }

    set status(value: string) {
        this._allocationDetailModel.allocationDetailStatus = value;
    }

    get isReconciled(): boolean {
        return this._allocationDetailModel.isReconciled;
    }

    set isReconciled(value: boolean) {
        this._allocationDetailModel.isReconciled = value;
    }

    get rowVersion(): number[] {
        return this._allocationDetailModel.rowVersion;
    }

    get assignedAllocationAssessmentIds(): number[]{
        return this._allocationDetailModel.assignedAllocationAssessmentIds;
    }

    get description(): string {
        return this._allocationDetailModel.description;
    }

    get companyCode(): string {
        return this._allocationDetailModel.companyCode;
    }

    get glAccount(): string {
        return this._allocationDetailModel.glAccount;
    }

    get glAccountNumber(): string {
        return this._allocationDetailModel.glAccountNumber;
    }

    get assetReturnStatus(): string {
        return this._allocationDetailModel.assetReturnStatus;
    }

    get splitAllocationPercentage(): number {
        return this._allocationDetailModel.splitAllocationPercentage;
    }

    get classificationName(): string {
        return this._allocationDetailModel.classificationName;
    }

    get parcelAddressComplete(): string {
        return this._allocationDetailModel.parcelAddressComplete;
    }

    get linkedParcelAccountNumber(): string {
        return this._allocationDetailModel.linkedParcelAccountNumber;
    }

    get parcelDescription(): string {
        return this._allocationDetailModel.parcelDescription;
    }

    get linkedParcelDescription(): string {
        return this._allocationDetailModel.linkedParcelDescription;
    }

    get assessorAbbr(): string {
        return this._allocationDetailModel.assessorAbbr;
    }

    get linkedParcelAssessorAbbr(): string {
        return this._allocationDetailModel.linkedParcelAssessorAbbr;
    }

    get priorAcqDate(): Date{
        return this._allocationDetailModel.priorAcqDate;
    }

    get acqDate(): Date {
        return this._allocationDetailModel.acqDate;
    }

    get priorCost(): number {
        return this._allocationDetailModel.priorCost;
    }

    get costChange(): number {
        return this._allocationDetailModel.costChange;
    }

    get netBookValue(): number {
        return this._allocationDetailModel.netBookValue;
    }

    get site(): string {
        return this._allocationDetailModel.site;
    }

    get siteProperty(): string {
        return this._allocationDetailModel.siteProperty;
    }

    get siteName(): string {
        return this._allocationDetailModel.siteName;
    }

    get priorScheduleName(): string {
        return this._allocationDetailModel.priorScheduleName;
    }

    get scheduleName(): string {
        return this._allocationDetailModel.scheduleName;
    }

    get priorDepreciationFactorTableName(): string {
        return this._allocationDetailModel.priorDepreciationFactorTableName;
    }

    get depreciationFactorTableName(): string {
        return this._allocationDetailModel.depreciationFactorTableName;
    }

    get factor(): number {
        return this._allocationDetailModel.factor;
    }

    get additionalDepreciation(): number {
        return this._allocationDetailModel.additionalDepreciation;
    }

    get estimatedFMV(): number {
        return this._allocationDetailModel.estimatedFMV;
    }

    get acqYear(): number {
        return this._allocationDetailModel.acqYear;
    }

    get age(): number {
        return this._allocationDetailModel.age;
    }

    get leaseLocationName(): string {
        return this._allocationDetailModel.leaseLocationName;
    }

    get leaseAddress(): string {
        return this._allocationDetailModel.leaseAddress;
    }

    get leaseCity(): string {
        return this._allocationDetailModel.leaseCity;
    }

    get leaseState(): string {
        return this._allocationDetailModel.leaseState;
    }

    get leaseZip(): string {
        return this._allocationDetailModel.leaseZip;
    }

    get leaseStartDate(): Date {
        return this._allocationDetailModel.leaseStartDate;
    }

    get leaseEndDate(): Date {
        return this._allocationDetailModel.leaseEndDate;
    }

    get leaseTermInMonths(): number {
        return this._allocationDetailModel.leaseTermInMonths;
    }

    get leaseMonthlyPayment(): number {
        return this._allocationDetailModel.leaseMonthlyPayment;
    }

    get leaseTypeId(): string {
        return this._allocationDetailModel.leaseTypeId;
    }

    get leaseAssetId(): string {
        return this._allocationDetailModel.leaseAssetId;
    }

    get leaseNumber(): string {
        return this._allocationDetailModel.leaseNumber;
    }

    get leaseBillingName(): string {
        return this._allocationDetailModel.leaseBillingName;
    }

    get leaseBillingAddress(): string {
        return this._allocationDetailModel.leaseBillingAddress;
    }

    get leaseBillingCity(): string {
        return this._allocationDetailModel.leaseBillingCity;
    }

    get leaseBillingState(): string {
        return this._allocationDetailModel.leaseBillingState;
    }

    get leaseBillingZip(): string {
        return this._allocationDetailModel.leaseBillingZip;
    }

    get leaseClientId(): string {
        return this._allocationDetailModel.leaseClientId;
    }

    get inventoryJan(): number {
        return this._allocationDetailModel.inventoryJan;
    }

    get inventoryFeb(): number {
        return this._allocationDetailModel.inventoryFeb;
    }

    get inventoryMar(): number {
        return this._allocationDetailModel.inventoryMar;
    }

    get inventoryApr(): number {
        return this._allocationDetailModel.inventoryApr;
    }

    get inventoryMay(): number {
        return this._allocationDetailModel.inventoryMay;
    }

    get inventoryJun(): number {
        return this._allocationDetailModel.inventoryJun;
    }

    get inventoryJul(): number {
        return this._allocationDetailModel.inventoryJul;
    }

    get inventoryAug(): number {
        return this._allocationDetailModel.inventoryAug;
    }

    get inventorySep(): number {
        return this._allocationDetailModel.inventorySep;
    }

    get inventoryOct(): number {
        return this._allocationDetailModel.inventoryOct;
    }

    get inventoryNov(): number {
        return this._allocationDetailModel.inventoryNov;
    }

    get inventoryDec(): number {
        return this._allocationDetailModel.inventoryDec;
    }

    get inventoryAvrCalculated(): number {
        return this._allocationDetailModel.inventoryAvrCalculated;
    }

    get inventoryAggregatedString(): string {
        return this._allocationDetailModel.inventoryAggregatedString;
    }

    get isReportable(): boolean {
        return this._allocationDetailModel.isReportable;
    }

    get isTaxable(): boolean {
        return this._allocationDetailModel.isTaxable;
    }

    get workPaperCost(): number {
        return this._allocationDetailModel.workPaperCost;
    }

    get workPaperAcqDate(): Date {
        return this._allocationDetailModel.workPaperAcqDate;
    }

    get canBeReconciled(): boolean {
        return !!this._allocationDetailModel.assetId || !!this._allocationDetailModel.returnAssetId;
    }

    get isMatched(): boolean {
        return !!this._allocationDetailModel.assetId || !!this._allocationDetailModel.returnAssetId;
    }

    get varianceDollar(): number {
        return this._allocationDetailModel.varianceDollar;
    }

    get variancePercentage(): number {
        return this._allocationDetailModel.variancePercentage;
    }

    get workPaperDescription(): string {
        return this._allocationDetailModel.workPaperDescription;
    }

    get workPaperAcqYear(): number {
        return this._allocationDetailModel.workPaperAcqYear;
    }

    get workPaperSchedule(): string {
        return this._allocationDetailModel.workPaperSchedule;
    }

    get workPaperFactor(): number {
        return this._allocationDetailModel.workPaperFactor;
    }

    get workPaperAddlDep(): number {
        return this._allocationDetailModel.workPaperAddlDep;
    }

    get workPaperAssessor(): string {
        return this._allocationDetailModel.workPaperAssessor;
    }

    get workPaperTaxingUnit(): string {
        return this._allocationDetailModel.workPaperTaxingUnit;
    }

    get workPaperPhysicalAddress(): string {
        return this._allocationDetailModel.workPaperPhysicalAddress;
    }

    get workPaperLesseeName(): string {
        return this._allocationDetailModel.workPaperLesseeName;
    }

    get workPaperNote(): string {
        return this._allocationDetailModel.workPaperNote;
    }

    get workPaperMisc1(): string {
        return this._allocationDetailModel.workPaperMisc1;
    }

    get workPaperMisc2(): string {
        return this._allocationDetailModel.workPaperMisc2;
    }

    get workPaperMisc3(): string {
        return this._allocationDetailModel.workPaperMisc3;
    }

    get workPaperMisc4(): string {
        return this._allocationDetailModel.workPaperMisc4;
    }

    get workPaperMisc5(): string {
        return this._allocationDetailModel.workPaperMisc5;
    }

    get reportingAssetId(): number {
        return this._allocationDetailModel.reportingAssetId;
    }

    get assetId(): number {
        return this._allocationDetailModel.assetId;
    }

    get returnAssetId(): number {
        return this._allocationDetailModel.returnAssetId;
    }

    get siteIsDifferent(): boolean {
        return this._allocationDetailModel.siteId !== this._allocationDetailModel.workPaperSiteId;
    }

    get parcelIsDifferent(): boolean {
        return this._allocationDetailModel.parcelId !== this._allocationDetailModel.workPaperParcelId &&
            this._allocationDetailModel.linkedParcelId !== this._allocationDetailModel.workPaperParcelId;
    }

    get calculatedAssetId(): number {
        return this._allocationDetailModel.calculatedAssetId;
    }

    get calculatedReportingAssetId(): number {
        return this._allocationDetailModel.calculatedReportingAssetId;
    }

    get parcelSiteId(): number {
        return this._allocationDetailModel.parcelSiteId;
    }

    get priorYearEffectiveTaxRate(): number {
        return this._allocationDetailModel.priorYearEffectiveTaxRate;
    }

    get priorYearFinalAllocationAmount(): number {
        return this._allocationDetailModel.priorYearFinalAllocationAmount;
    }

    get priorYearFactor(): number {
        return this._allocationDetailModel.priorYearFactor;
    }

    get taxVarianceDollar(): number {
        return this._allocationDetailModel.taxVarianceDollar;
    }
}
