import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

@Injectable({providedIn: 'root'})
export class InvoiceRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    getInvoiceByTaskIds(taskIds: number[]): Observable<any> {
        return this.httpPost('/api/invoice', taskIds);
    }

    bulkMarkUBR(taskIds: number[]): Observable<any> {
        return this.httpPost('/api/invoice/bulkmarkubr', taskIds);
    }

    bulkTransferToRIBS(taskIds: number[]): Observable<any> {
        return this.httpPost('/api/invoice/bulktransfer', taskIds);
    }
}
