import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AssetSummary } from '../../../Models/assetSummary';
import { Decimal } from 'decimal.js';
import { IWeissmanModalComponent } from '../../../../WeissmanModalService';

export interface AssetInfoCostEditorParams {
    assets: AssetSummary[];
    calculatedCost: number;
    alternativeCostMappingModel: Compliance.CompanyAssetDescriptorMappingModel
}

export interface AssetInfoCostEditorResult{
    costAmounts: number[];
}

@Component({
    selector: 'asset-info-cost-editor',
    templateUrl: './assetInfoCostEditor.component.html'
})
export class AssetInfoCostEditorComponent implements OnInit, IWeissmanModalComponent<AssetInfoCostEditorParams, AssetInfoCostEditorResult> {
    constructor(
        private _bsModalRef: BsModalRef
    ) { }

    params: AssetInfoCostEditorParams;
    result: AssetInfoCostEditorResult;

    percentages: number[] = [];
    assets: AssetSummary[];
    calculatedCost: number;
    unattributedPercent: number;
    costAmounts: number[] = [];

    ngOnInit() {
        this.assets = this.params.assets;
        this.calculatedCost = this.params.calculatedCost;
        this.unattributedPercent = 100;
        for (let i = 0; i < this.assets.length; i++) {
            this.percentages[i] = 0;
            this.costAmounts[i] = 0;
        }

        //TRY TO CALCULATE PERCENTAGES
        // if no overrides, split as 100 to primary
        // if no override on first asset, set to 100, otherwise take best estimate
        let percentagesIt: number = 0;
        if (this.calculatedCost > 0) {
            this.assets.forEach((asset: AssetSummary) => {
                const columnName = this._getAlternativeCostColumnName(asset);
                if (asset.reportedColumnHasOverride(columnName)) {
                    const costAmount = new Decimal(asset[`reported${columnName}`]);
                    const percentage = costAmount.dividedBy(new Decimal(this.calculatedCost)).times(100).toDecimalPlaces(2);
                    this.percentages[percentagesIt] = percentage.toNumber();
                } else {
                    this.percentages[percentagesIt] = asset.isPrimary ? 100 : 0;
                }
                percentagesIt++;
            });
            this.onUpdatePercentages();
        }
    }

    onUpdatePercentages() {
        this.unattributedPercent = 100;

        let unattributedPercentDecimal = new Decimal(100);
        // clip percentages at 100
        for (let i = 0; i < this.percentages.length; i++) {
            if (this.percentages[i] > 100) {
                this.percentages[i] = 100;
            }
        }

        this.percentages.forEach((percent: number) => {
            unattributedPercentDecimal = unattributedPercentDecimal.minus(new Decimal(percent === null ? 0 : percent));
        });

        this.unattributedPercent = unattributedPercentDecimal.toDecimalPlaces(2).toNumber();

        // calculate real amounts
        const realAmounts: number[] = [];
        let totalAmount = 0;
        this.percentages.forEach((percent: number) => {
            const amount = new Decimal(percent).times(this.calculatedCost).dividedBy(100);
            const roundedAmount = amount.toDecimalPlaces(2).toNumber();
            realAmounts.push(roundedAmount);
            totalAmount += roundedAmount;
        });

        // check left over amount, assign to first with largest value, if all percent is used
        const leftOver = this.calculatedCost - totalAmount;
        if (this.unattributedPercent === 0) {
            if (leftOver > 0) {
                const highestValue = Math.max(...realAmounts);
                const index = realAmounts.findIndex(element => element === highestValue);
                realAmounts[index] = new Decimal(realAmounts[index]).plus(leftOver).toDecimalPlaces(2).toNumber();
            } else if (leftOver < 0) {
                const lowestValue = Math.min(...realAmounts);
                const index = realAmounts.lastIndexOf(lowestValue);
                realAmounts[index] = new Decimal(realAmounts[index]).plus(leftOver).toDecimalPlaces(2).toNumber();
            }
        }

        this.costAmounts = realAmounts;
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    saveDisabled(): boolean {
        return this.unattributedPercent !== 0;
    }

    save(): void {
        this.result = {
            costAmounts: this.costAmounts
        };

        this._bsModalRef.hide();
    }

    private _getAlternativeCostColumnName(asset: AssetSummary): string {
        const altCostMappingModel = this.params.alternativeCostMappingModel;
        if (altCostMappingModel !== null && asset.reportedColumnHasOverride(altCostMappingModel.columnName)) {
            return altCostMappingModel.columnName;
        } else {
            return 'Cost';
        }
    }

}
