import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';
import {
    InvoiceAttachmentInfo,
    InvoicePreviewData,
    PaymentPackage, PaymentPackageAttachmentSummary
} from '../Processing/Transmittal/transmittal.models';

@Injectable({ providedIn: 'root' })
export class TransmittalRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        @Inject('$http') private internalHttp: any
    ) {
        super(httpClient);
    }

    // TODO: Create a model for bulk operation results and use it here instead of "any"
    CreatePaymentPackage(taskIDs: number[]): Observable<any> {
        return this.httpPost('/api/PaymentPackage', taskIDs);
    }

    ReReview(payload: any): Observable<any> {
        return this.httpPut('/api/Tasks/rereviewpayment', payload);
    }

    CheckFinalizeLongRunningProcess(packageIds: number[]): Observable<Core.PaymentPackageFinalizeLongRunningCheck[]> {
        return this.httpPost('/api/PaymentPackage/CheckFinalizeLongRunningProcess', packageIds);
    }

    GetPaymentPackages(packageIDs: number[]): Observable<PaymentPackage[]> {
        return this.httpPost('/api/PaymentPackage/search', packageIDs);
    }

    GetPaymentPackagesByCompanyID(companyID: string, startDate: Date, endDate: Date): Observable<PaymentPackage[]> {
        const config = {
            params: {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString()
            }
        };
        return this.httpGet(`/api/PaymentPackage/company/${companyID}`, config);
    }

    GetPaymentPackagesByPaymentID(paymentID: number): Observable<PaymentPackage[]> {
        return this.httpGet(`/api/PaymentPackage/payment/${paymentID}`);
    }

    GetPaymentPackageByID(paymentPackageID: number): Observable<PaymentPackage> {
        return this.httpGet(`/api/PaymentPackage/${paymentPackageID}`);
    }

    CheckForMissingAttachments(taskIDs: number[]): Observable<number[]> {
        return this.httpPost('/api/PaymentPackage/CheckForMissingAttachments', taskIDs);
    }

    CancelPaymentPackages(packageIDs: number[]): Observable<any> {
        return this.httpPost('/api/PaymentPackage/cancel', packageIDs);
    }

    FinalizePaymentPackages(packages: PaymentPackage[]): Observable<any> {
        return this.httpPut('/api/PaymentPackage/finalize', packages, { handleCode: 500 });
    }

    FinalizePaymentPackagesLR(request: Core.PaymentPackageFinalizeRequestDTO): Observable<number> {
        return this.httpPost('/api/PaymentPackage/LongRunning/finalize', request);
    }

    RecallPaymentPackage(packageItem: PaymentPackage): Observable<any> {
        return this.httpDelete(`/api/PaymentPackage/${packageItem.paymentPackageID}/recall`);
    }

    GetLRFinalizeResults(longRunningProcessId: number): Observable<any> {
        return this.httpGet(`/api/PaymentPackage/LongRunning/${longRunningProcessId}/FinalizeResults`);
    }

    GetPreviewData(currentPackage: PaymentPackage): Observable<InvoicePreviewData> {
        return this.httpPost('/api/PaymentPackage/Preview', currentPackage);
    }

    GetPreviewAttachment(currentPackage: PaymentPackage, attachmentInfo: InvoiceAttachmentInfo): Promise<any> {
        return this.internalHttp({
            url: `/api/PaymentPackage/Preview/Attachments/${attachmentInfo.id ? attachmentInfo.id : -1}?attachmentType=${attachmentInfo.attachmentType}`,
            method: 'POST',
            data: currentPackage,
            responseType: 'arraybuffer'
        });
    }

    GetPaymentPackageAttachmentSummary(paymentPackageID: number): Observable<PaymentPackageAttachmentSummary> {
        return this.httpGet(`/api/PaymentPackage/${paymentPackageID}/attachmentSummary`);
    }
}
