<ws-modal-wrapper headerLabel="Test Service"
                  (save)="test()"
                  (cancel)="cancel()"
                  [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div class="row">
            <div class="col-lg-12">
                <label>Defaults</label>
            </div>
        </div>
        <div class="row" style="margin-bottom:1em;">
            <div class="col-lg-12">
                <button class="ace-btn btn-sm"
                        (click)="setInstantsDefault()">
                    Instant
                </button>
                <button class="ace-btn btn-sm"
                        (click)="setWithDurationDefault()">
                    With Duration
                </button>
                <button class="ace-btn btn-sm"
                        (click)="setMultipleDefault()">
                    Multiple Simultaneous
                </button>
                <button class="ace-btn btn-sm"
                        (click)="setOverTimeDefault()">
                    Multiple Over Time
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.queueType.touched && form.controls.queueType.errors }">
                    <label for="queueType"
                           helpTooltip
                           helpContentId="long-running-process-synthetic-modal.queue-type"
                           position="bottom">
                        # Queue Type
                    </label>
                    <ws-select id="queueType"
                               [returnEntireOption]="false"
                               [options]="queueTypes"
                               formControlName="queueType"
                               labelProperty="name"
                               valueProperty="longRunningProcessQueueType">
                    </ws-select>
                    <div *ngIf="form.controls.queueType.touched && form.controls.queueType.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.queueType.errors.required">Queue Type is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.processes.touched && form.controls.processes.errors }">
                    <label for="processes"
                           helpTooltip
                           helpContentId="long-running-process-synthetic-modal.number-processes"
                           position="bottom">
                        # Processes
                    </label>
                    <input type="number" class="form-control" id="processes" formControlName="processes" autocomplete="off" />
                    <div *ngIf="form.controls.processes.touched && form.controls.processes.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.processes.errors.min"># Processes must be at least 1.</div>
                        <div class="text-danger small" *ngIf="form.controls.processes.errors.required"># Processes is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.duration.touched && form.controls.duration.errors }">
                    <label for="duration"
                           helpTooltip
                           helpContentId="long-running-process-synthetic-modal.duration"
                           position="bottom">
                        Duration
                    </label>
                    <input type="number" class="form-control" id="duration" formControlName="duration" autocomplete="off" />
                    <div *ngIf="form.controls.duration.touched && form.controls.duration.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.duration.errors.min"># Duration must be at least 0.</div>
                        <div class="text-danger small" *ngIf="form.controls.duration.errors.required">Duration is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.wait.touched && form.controls.wait.errors }">
                    <label for="wait"
                           helpTooltip
                           helpContentId="long-running-process-synthetic-modal.wait"
                           position="bottom">
                        Wait
                    </label>
                    <input type="number" class="form-control" id="wait" formControlName="wait" autocomplete="off" />
                    <div *ngIf="form.controls.wait.touched && form.controls.wait.errors" class="error-feedback">
                        <div class="text-danger small" *ngIf="form.controls.wait.errors.min"># Wait must be at least 0.</div>
                        <div class="text-danger small" *ngIf="form.controls.wait.errors.required">Wait is required.</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.throwException.touched && form.controls.throwException.errors }">
                    <label for="throwException"
                           helpTooltip
                           helpContentId="long-running-process-synthetic-modal.exception"
                           position="bottom">
                        <input type="checkbox" id="throwException" formControlName="throwException" autocomplete="off" />
                        Throw Exception
                    </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="mb-3" [ngClass]="{ 'has-error': form.controls.publishProgressChangeMessage.touched && form.controls.publishProgressChangeMessage.errors }">
                    <label for="publishProgressChangeMessage"
                           helpTooltip
                           helpContentId="long-running-process-synthetic-modal.publish-progress"
                           position="bottom">
                        <input type="checkbox" id="publishProgressChangeMessage" formControlName="publishProgressChangeMessage" autocomplete="off" />
                        Publish Progress Message
                    </label>
                </div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
