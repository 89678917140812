import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BulkOperationResult } from '../../Common/Models/bulk-operation-result.model';
import { RetrievalStatusService } from './retrieval-status.service';
import { RetrievalStatus, RetrievalStatusPayload } from './retrieval-status.model';
import * as _ from 'lodash';
import { WeissmanDatetimeComponent } from '../../UI-Lib/Weiss-Datepicker/weissman-datetime.component';
import {WeissmanDateFormatPipe} from '../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';

declare const moment: any;

export enum FollowUpChoice {
    DAYS,
    DATE,
    NO_FOLLOWUP,
    NO_CHANGE
}

@Component({
    selector: 'set-document-retrieval-status-modal',
    templateUrl: './set-document-retrieval-status.modal.component.html'
})
export class SetDocumentRetrievalStatusModalComponent implements OnInit {
    constructor(private bsModalRef: BsModalRef, private retrievalStatusService: RetrievalStatusService, private datePipe: WeissmanDateFormatPipe) { }

    // These will be initialized by the service which opens the modal
    taskIds: number[];
    instanceId: number;
    callback: (result: () => Promise<BulkOperationResult[]>) => void;
    // (end service-initialized properties)
    retrievalStatuses: RetrievalStatus[];
    retrievalStatusPayload: RetrievalStatusPayload = new RetrievalStatusPayload();
    followUpChoice: number;
    followUpChoices = FollowUpChoice;
    daysOptions: number[];
    computedDate: Date;
    selectedDate: Date;
    generatedComment: string;
    selectedUserInfo: string;
    followUpInXdays: number;


    ngOnInit() {
        this.daysOptions = _.range(1, 20);
        this.setComputedDate(this.daysOptions[0]);
        this.followUpChoice = this.followUpChoices.NO_CHANGE;

        this.retrievalStatusService.getRetrievalStatuses()
            .then((retrievalStatuses: RetrievalStatus[]) => {
                retrievalStatuses = _.sortBy(retrievalStatuses, 'name');

                const extraStatuses: RetrievalStatus[] = [{
                    name: 'Set to Blank',
                    retrievalStatusId: null
                }];

                this.retrievalStatuses = _.union(extraStatuses, retrievalStatuses);
            });

        // setTimeout is necessary, because taskIds aren't immediately available for some reason
        setTimeout(() => {
            this.retrievalStatusService.getRetrievalStatusIdsByTaskIds(this.taskIds)
                .then((uniqueRetrievalStatuses: number[]) => {
                    if(uniqueRetrievalStatuses.length === 1) {
                        this.retrievalStatusPayload.retrievalStatusId = uniqueRetrievalStatuses[0];
                    } else {
                        this.retrievalStatuses.unshift({
                            name: 'Take no Action',
                            retrievalStatusId: 999
                        });
                        this.retrievalStatusPayload.retrievalStatusId = 999;
                    }
                });
        });
    }

    setUser(e: Core.UserTeamModel): void {
        if ( !e ) {
            // No User Team picked. Clear user info so it's not included in the Comment.
            this.selectedUserInfo = undefined;
            //console.log('ClearUser!');
        }
        else
        {
            this.retrievalStatusPayload.newAssignment = _.pick(e, ['userID', 'teamID']) as Core.UserTeamModel;
            //console.log('SetUser!');
            this.selectedUserInfo = `${e.lastName  }, ${  e.firstName  } (${  e.teamName  })`;
            //console.log(this.retrievalStatusPayload.newAssignment);
        }
    }

    setComputedDataFromSelect(eventTarget: EventTarget) {
        const days = (eventTarget as HTMLSelectElement).value;
        this.setComputedDate(Number(days));
    }

    // https://stackoverflow.com/a/20948347
    setComputedDate(days: number): void {

        this.followUpInXdays = days;
        //let date = moment(); // use a clone
        // using moment() will get the current date/time; we just want the current date midnight UTC
        let date = WeissmanDatetimeComponent.getMidnightMoment(new Date(), false, 'utc');

        while (days > 0) {
            date = date.add(1, 'days');
            // decrease "days" only if it's a weekday.
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                days -= 1;
            }
        }

        this.computedDate = date.toDate();
    }


    submit(): void {
        this._setPayload();
        this.bsModalRef.hide();
        this.callback(() => {
            return this.retrievalStatusService.setStatus(this.retrievalStatusPayload);
        });
        //this.retrievalStatusService.setStatus(this.retrievalStatusPayload)
        //    .then((result: BulkOperationResult[]) => {
        //        this.callback(result);

        //        this.bsModalRef.hide();
        //    });
    }

    recreateComment(): void {
        if (!this.retrievalStatusPayload.comment || ( this.retrievalStatusPayload.comment == this.generatedComment ))
        {
            this.retrievalStatusPayload.comment  = this._getCommentBody();
            this.generatedComment = this.retrievalStatusPayload.comment;
        }
    }

    closeModal(): void {
        this.bsModalRef.hide();
    }

    private _setPayload(): void {
        this.retrievalStatusPayload.taskIds = this.taskIds;

        if(this.retrievalStatusPayload.retrievalStatusId === 999) {
            this.retrievalStatusPayload.retrievalStatusId = null;
            this.retrievalStatusPayload.ignoreRetrievalStatus = true;
        }

        switch(this.followUpChoice) {
            case this.followUpChoices.DAYS:
                this.retrievalStatusPayload.followUpDate = this.computedDate;
                this.retrievalStatusPayload.ignoreFollowUpDate = false;
                break;
            case this.followUpChoices.DATE:
                this.retrievalStatusPayload.followUpDate = this.selectedDate;
                this.retrievalStatusPayload.ignoreFollowUpDate = false;
                break;
            case this.followUpChoices.NO_CHANGE:
                this.retrievalStatusPayload.ignoreFollowUpDate = true;
                break;
            case this.followUpChoices.NO_FOLLOWUP:
                this.retrievalStatusPayload.followUpDate = null;
                this.retrievalStatusPayload.ignoreFollowUpDate = false;
                break;
        }
    }


    private _getCommentBody(): string {

        let result =  'Retrieval status has been updated: \n';

        console.log('this.retrievalStatusPayload.retrievalStatusId', this.retrievalStatusPayload.retrievalStatusId);

        if (this.retrievalStatusPayload.retrievalStatusId){
            result  +=  ` Retrieval Status: ${  this.retrievalStatuses.find(x => x.retrievalStatusId == this.retrievalStatusPayload.retrievalStatusId).name  }\n`;
        }

        result  +=  ' Follow Up: ';

        switch(this.followUpChoice) {
            case this.followUpChoices.DAYS:
                result  += `In ${  this.followUpInXdays  } weekdays which is ${  this.datePipe.transform(this.computedDate, true)}`;
                break;
            case this.followUpChoices.DATE:
                result  += `On ${  this.datePipe.transform(this.selectedDate, true)}`;
                break;
            case this.followUpChoices.NO_CHANGE:
                result  += 'No Change';
                break;
            case this.followUpChoices.NO_FOLLOWUP:
                result  += 'No Follow Up';
                break;
        }
        if (this.selectedUserInfo){
            result += '\n';
            result += `Re-Assign To: ${  this.selectedUserInfo}`;
        }

        return result;
    }

}
