import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ReturnService } from '../../../return.service';
import { Subscription } from 'rxjs';

export interface ICellRendererParamsForReturnAssetListGridAction extends ICellRendererParams {
    viewDetails: (params: ICellRendererParamsForReturnAssetListGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button *ngIf="returnAsset" class="ace-btn btn-primary has-icon grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>`
})

export class ReturnAssetListGridActionCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
    constructor(
        private _returnService: ReturnService) { }

    private _params: ICellRendererParamsForReturnAssetListGridAction;
    private _returnReadOnlyFlagSub: Subscription;

    returnAsset: Compliance.ReturnAssetModel;
    isReadOnly: boolean = false;

    agInit(params: ICellRendererParamsForReturnAssetListGridAction): void {
        this._returnReadOnlyFlagSub = this._returnService.isReturnInReadOnlyMode$.subscribe(x => this.isReadOnly = x);
        this.isReadOnly = this._returnService.isReturnInReadOnlyMode;
        this._params = params;
        this.returnAsset = params.data as Compliance.ReturnAssetModel;

        if (!this.returnAsset) {

        }
    }

    ngOnDestroy(): void {
        this._returnReadOnlyFlagSub.unsubscribe();
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
