export type ForecastBudgetModeType = 'forecast' | 'budget';
export type BudgetModeViewType = 'taxYear' | 'accrualAndCash';


export class ForecastBudgetAvailableFiltersUI {
    constructor() {
        this.companies = [];
        this.states = [];
        this.propertyTypes = [];
        this.activityStatuses = [];
    }

    companies: Core.ForecastBudgetCompanyFilter[];      // TL Company & Subsidiaries OR given Subsidiary OR parent Company of Site
    states: Core.NameIdPair[];         // States available for given Site, or all Sites within the Companies.
    propertyTypes: Core.NameIdPair[];  // The 4 Property Types (RE, PP, MN, CA)
    activityStatuses: Core.NameIdPair[];  // Active, Pending
}

export class ForecastGridSearchModel {
    constructor(entityTypeId: number, entityId: number) {
        this.scope = new ForecastBudgetScopeSelections(entityTypeId, entityId);
        this.showYoYChangesFor = ShowAssessmentsTaxesEnum.AssessmentsAndTaxes;
        this.alternateAssessmentView = ForecastBudgetAltAssessmentView.Hide;

        this.mode = ForecastBudgetScreenModeEnum.Forecast;

        this.showBudgetEntriesFor = ShowAssessmentsTaxesEnum.AssessmentsAndTaxes;
        this.showBudgetVariances = ShowAssessmentsTaxesEnum.None;
        this.budgetBasis = Core.CompanyBudgetBasisEnum.AccrualAndCash;
        }

    tlCompanyID: number;
    scope: ForecastBudgetScopeSelections;
    filters: ForecastBudgetFilterSelections;
    showYoYChangesFor: ShowAssessmentsTaxesEnum;
    alternateAssessmentView: ForecastBudgetAltAssessmentView;

    mode: ForecastBudgetScreenModeEnum;

    // budget-mode related parameters
    companyBudgetId: number;       // format: '2020-05-15', should we use string instead?
    showBudgetEntriesFor: ShowAssessmentsTaxesEnum;
    showBudgetVariances: ShowAssessmentsTaxesEnum;
    budgetBasis: Core.CompanyBudgetBasisEnum;

    pagination: Core.PaginationModel;
}

export enum ShowAssessmentsTaxesEnum {
    None = 0,
    Assessments = 1,
    Taxes = 2,
    AssessmentsAndTaxes = 3
}

export enum ForecastBudgetAltAssessmentView {
    Hide = 0,
    Show = 1,
    ShowOnlyAlternates = 2
}

export enum ForecastBudgetScreenModeEnum {
    Forecast = 1,
    TaxYearBudget = 2,
    AccrualAndCashBudget = 3
}

export enum CompanyBudgetBasisEnum {
    Accrual = 1,
    Cash = 2,
    AccrualAndCash = 3
}

export class ForecastBudgetScopeSelections {
    constructor(entityTypeId: number, entityId: number) {
        this.entityTypeId = entityTypeId;
        this.entityId = entityId;
    }

    entityTypeId: number;   // Company (TL or Subsidiary), Site
    entityId: number;         // CompanyID, SiteID
}

export class ForecastBudgetFilterSelections implements Core.ForecastBudgetFilterSelections {
    constructor() {
        this.companyIds = [];
        this.stateIds = [];
        this.propertyTypeIds = [];
        this.activityStatusIds = [];
        this.siteRollup = true;
        this.yearEnd = new Date().getFullYear();
        this.yearBegin = this.yearEnd - 2;
        this.excludeInactive = false;
    }

    companyIds: number[];       // empty = All
    stateIds: number[];         // empty = All
    propertyTypeIds: number[];  // empty = All
    activityStatusIds: number[];    // empty = All except Inactive
    siteRollup: boolean;
    yearBegin: number;
    yearEnd: number;
    excludeInactive: boolean;
}

export class BudgetTaxYearMissingSyncData {
    constructor() {
        this.numMissingTaxYears = 0;
        this.numMissingBudgets = 0;
        this.numOutOfSyncBudgets = 0;
    }

    numMissingTaxYears: number;
    numMissingBudgets: number;
    numOutOfSyncBudgets: number;
}

export class ForecastBudgetEditDetails implements Core.ForecastBudgetEditDetails {
    constructor() {
        this.editAction = Core.ForecastBudgetEditActionEnum.None;
    }

    editAction: Core.ForecastBudgetEditActionEnum;
    taxYear?: number;
    valueFMV?: number;
    yoyPercentFMV?: number;
}

export class ForecastBudgetEditRequest implements Core.ForecastBudgetEditRequest {
    constructor() {
        this.companyBudgetId = 0;
        this.details = new ForecastBudgetEditDetails();
        this.filters = new ForecastBudgetFilterSelections();
        this.autoSyncBudgetToLatest = false;
        this.forBulkUpdate = false;
        this.budgetBasis = Core.CompanyBudgetBasisEnum.AccrualAndCash;
    }

    companyBudgetId: number;
    details: ForecastBudgetEditDetails;
    filters: ForecastBudgetFilterSelections;
    autoSyncBudgetToLatest: boolean;
    forBulkUpdate: boolean;
    budgetBasis: Core.CompanyBudgetBasisEnum;
    referenceFMVForConcurrency?: number;
}

export class ForecastBudgetBulkUpdateProgress implements Core.ForecastBudgetEditResult {
    constructor() {
        this.reset();
    }

    parcelCount?: number;
    siteCount?: number;
    assessmentCount?: number;
    billCount?: number;
    paymentCount?: number;
    missingTaxYearCount?: number;
    emptyAssessmentCount?: number;
    actualizedAssessmentCount?: number;
    missingBudgetCount?: number;
    budgetFrozenCount?: number;
    budgetUnfrozenCount?: number;
    budgetCreatedCount?: number;
    budgetSyncedCount?: number;
    modifiedGridEntry?: Core.ForecastGridEntry;

    selections: Core.ForecastGridEntry[] = [];
    currentIndex: number = 0;
    request: ForecastBudgetEditRequest;
    overallDistributionFMV: number = 0;
    distributionFMVs: number[] = [];

    reset() : void {
        this.parcelCount = null;
        this.siteCount = null;
        this.assessmentCount = null;
        this.billCount = null;
        this.paymentCount = null;
        this.missingTaxYearCount = null;
        this.emptyAssessmentCount = null;
        this.actualizedAssessmentCount = null;
        this.missingBudgetCount = null;
        this.budgetFrozenCount = null;
        this.budgetUnfrozenCount = null;
        this.budgetCreatedCount = null;
        this.budgetSyncedCount = null;
        this.modifiedGridEntry = null;
        this.selections = [];
        this.currentIndex = 0;
        this.request = null;
    }

    capture( result: Core.ForecastBudgetEditResult ) {
        this.captureCount(result, 'parcelCount');
        this.captureCount(result, 'siteCount');
        this.captureCount(result, 'assessmentCount');
        this.captureCount(result, 'billCount');
        this.captureCount(result, 'paymentCount');
        this.captureCount(result, 'missingTaxYearCount');
        this.captureCount(result, 'emptyAssessmentCount');
        this.captureCount(result, 'actualizedAssessmentCount');
        this.captureCount(result, 'missingBudgetCount');
        this.captureCount(result, 'budgetFrozenCount');
        this.captureCount(result, 'budgetUnfrozenCount');
        this.captureCount(result, 'budgetCreatedCount');
        this.captureCount(result, 'budgetSyncedCount');
    }

    incrementCount( attrName: string, count: number = 1 ) {
        if ( !this[attrName] ) {
            this[attrName] = 0;
        }

        this[attrName] += count;
    }

    private captureCount( result: Core.ForecastBudgetEditResult, attrName: string ) {
        if ( result[attrName] ) {
            if ( !this[attrName] ) {
                this[attrName] = 0;
            }

            this[attrName] += result[attrName];
        }
    }
}
