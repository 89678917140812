<div>
    <table class="budget-taxyear-sync-budgets-table">
        <tr>
            <td>
                <label style="margin-bottom: 0;">Out-of-sync Parcel Budgets:</label>
            </td>
            <td></td>
            <td>
                {{missingSyncData.numOutOfSyncBudgets}}
            </td>
            <td></td>
            <td>
                <button *ngIf="editMode" [disabled]="!isSyncBudgetsAllowed()" class="ace-btn btn-sm btn-primary" (click)="syncBudgets()">Sync</button>
            </td>
        </tr>
    </table>
</div>
