import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForActionViewOutputDefaultsGridAction extends ICellRendererParams {
    hasEditPermission: () => boolean;
    viewDetails: (params: ICellRendererParamsForActionViewOutputDefaultsGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button" class="ace-btn btn-primary has-icon grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i *ngIf="hasEditPermission" class="fa fa-pencil"></i>
            <i *ngIf="!hasEditPermission" class="fa fa-eye"></i>
        </button>`
})
export class ActionViewOutputDefaultsGridActionCellRendererComponent implements ICellRendererAngularComp {
    userGroupGridItem: Core.GroupModel;
    hasEditPermission: boolean;
    private _params: ICellRendererParamsForActionViewOutputDefaultsGridAction;

    agInit(params: ICellRendererParamsForActionViewOutputDefaultsGridAction): void {
        this._params = params;
        this.hasEditPermission = params.hasEditPermission();
        this.userGroupGridItem = params.data as Core.GroupModel;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
