import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BusyIndicatorService } from './busyIndicator.service';
import { BusyIndicatorComponent } from './busyIndicator.component';
import { BusyIndicatorMessageIdService } from './busyIndicatorMessageId.service';
import { HelpModule } from '../UI-Lib/Help-Tooltip/help.module';
import { LongRunningProcessIndicatorConfigService } from './Config/longRunningProcessIndicatorConfig.service';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HelpModule
    ],
    exports: [
        BusyIndicatorComponent
    ],
    declarations: [
        BusyIndicatorComponent
    ],
    providers: [
        BusyIndicatorService,
        BusyIndicatorMessageIdService,
        LongRunningProcessIndicatorConfigService
    ]
})
export class BusyIndicatorModule {
    static forRoot(): ModuleWithProviders<BusyIndicatorModule> {
        return {
            ngModule: BusyIndicatorModule,
            providers: [
                BusyIndicatorService,
                BusyIndicatorMessageIdService
            ]
        };
    }

    static setupModule(): void {
        hybridAdapterUtility.downgradeNg2Provider('busyIndicatorService', BusyIndicatorService);
    }
}
