import { Injectable } from '@angular/core';

@Injectable(
    { providedIn: 'root' }
)
export class FeatureFlagsService {
    public featureFlags: Core.FeatureFlags;
    get taxDotComBase(): string {
        let base = this.featureFlags.taxDotComBase;
        if (base.endsWith('/')) {
            base = base.substring(0, base.length - 1);
        }
        return base;
    }
}
