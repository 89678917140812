import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportDetail } from '../report.manager.model';
import { ReportManagerService } from '../report.manager.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'further-limit-panel-budget-variance',
    template: `
        <div class="form-inline further-limit-budget">
            <div class="form-group">
                <label style="vertical-align: top; margin-top: 5px;">Budget Period(s): </label>
                <select multiple [(ngModel)]="report.criteria.budgetPeriods" class="form-select-sm" (change)="budgetPeriodSelectionChanged()">
                    <option *ngFor="let period of availablePeriods" [ngValue]="period">{{period.displayName}}</option>
                </select>
            </div>
            <div style="display: inline-block; vertical-align: top; margin-left: 50px;" *ngIf="hasRyanPrivatePermission">
                <label>
                    <ws-switch [(ngModel)]="report.criteria.includeDisclaimer"></ws-switch> Include Disclaimer
                </label>
            </div>
        </div>
    `
})
export class FurtherLimitPanelBudgetVarianceComponent implements OnInit {
    constructor(private reportManagerService: ReportManagerService)
    {
    }

    @Input() report: ReportDetail;
    @Input() hasRyanPrivatePermission: boolean;

    private _topLevelCompanyChangedSub: Subscription;

    ngOnInit() {
        if ([null, undefined].indexOf(this.report.criteria.includeDisclaimer) !== -1) {
            this.report.criteria.includeDisclaimer = true;
        }
        this.report.criteria.budgetPeriods = this.report.criteria.budgetPeriods || [];

        this._topLevelCompanyChangedSub = this.reportManagerService.topLevelCompanies$.subscribe(async topLevelCompaniesObj => {
            if (topLevelCompaniesObj.topLevelCompanyIds.length == 1) {
                // Top Level Company changed.  Refresh the Budget Periods.
                // This will clear Budget Period selections and cascade to Format Output component.
                await this.reportManagerService.refreshBudgetPeriods(this.report, topLevelCompaniesObj.topLevelCompanyIds[0]);
            }
        });
    }

    ngOnDestroy() {
        this._topLevelCompanyChangedSub.unsubscribe();
    }

    get availablePeriods() {
        return this.reportManagerService.availableBudgetPeriods;
    }

    budgetPeriodSelectionChanged() {
        this.reportManagerService.refreshSelectableBudgetSources(this.report.criteria.budgetPeriods);
    }
}
