<loading-spinner *ngIf="!(search && search.searchCriteria)" [size]="5"></loading-spinner>
<div *ngIf="search">
    <div class="page-header as-header">
        <h1 class="as-title" *ngIf="showPageTitle">
            <span>S<small>earch</small></span>
            <span>M<small>ining</small></span>
            <span>A<small>nd</small></span>
            <span>R<small>eporting</small></span>
            <span>T<small>ool</small></span>
        </h1>
        <h1 class="as-title" *ngIf="!showPageTitle">{{ search.searchName }}</h1>
        <div class="as-header-functions">
            <div *ngIf="!isShown" helpTooltip helpContentId="smartSearch.show-criteria">
                <button class="ace-btn has-icon btn-text icon-dark mb-0" (click)="showCriteria()">
                    <i class="fa fa-arrow-left fa-lg"></i>
                </button>
            </div>
            <div class="favorite" *ngIf="isSavedSearch && isSystemSearch">
                <i class="fa fa-star-o favorite-star" *ngIf="!search.isFavorite" (click)="favoriteSearchToggle(true)"></i>
                <i class="fa fa-star favorite-star" *ngIf="search.isFavorite" (click)="favoriteSearchToggle(false)"></i>
            </div>
            <div helpTooltip helpContentId="smartSearch.stop-sharing">
                <button class="ace-btn has-icon btn-text icon-dark mb-0" (click)="revokeSearchShare()" *ngIf="!hideRevokeShareButton">
                    <i class="fa fa-share-alt fa-lg" aria-hidden="true"></i>
                </button>
            </div>
            <div *ngIf="!isShown" helpTooltip helpContentId="app.refresh">
                <button class="ace-btn has-icon btn-text icon-dark mb-0" (click)="reloadSearch()">
                    <i class="fa fa-refresh fa-lg"></i>
                </button>
            </div>
            <div *ngIf="isShown" helpTooltip helpContentId="smartSearch.clear-criteria">
                <button class="ace-btn has-icon btn-text icon-dark mb-0" (click)="clearCriteria()">
                    <i class="fa fa-eraser fa-lg"></i>
                </button>
            </div>
            <div helpTooltip helpContentId="app.save">
                <button *ngIf="!hideSaveButton"
                        class="ace-btn has-icon btn-text icon-dark mb-0"
                        (click)="saveSearch()"
                        [disabled]="!isSaveEnabled || !search.searchCriteria?.outputColumns.length">
                    <i class="fa fa-save fa-lg"></i>
                </button>
            </div>
            <div helpTooltip [helpContentId]="isSingleInstanceSelected ? 'app.save-as' : 'smartSearch.all-instances-selected'">
                <button class="ace-btn has-icon btn-text icon-dark mb-0 ws-vertical-align-middle"
                        (click)="saveSearchAs()"
                        [disabled]="!search.searchCriteria?.outputColumns.length || !isSingleInstanceSelected">
                    <ws-save-as-icon class="w-100"></ws-save-as-icon>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="invalidColumns.length" class="alert-danger" >
        The following output columns are now invalid; please remove the disputing output columns to proceed
        <ul>
            <li *ngFor="let column of invalidColumns">
                <strong>{{column.colName}}</strong> because of: <span class="label label-default" style="margin-left: 5px;"
                                                                      *ngFor="let offendingColumn of column.offendingColumns">{{offendingColumn}}</span>
            </li>
        </ul>
    </div>
    <div class="row advanced-criteria" *ngIf="isShown && search.searchCriteria">
        <div class="col-lg-8 ws-stretch-height">
            <advanced-filter-list [searchCriteria]="search.searchCriteria"
                                  [routeParams]="routeParams"
                                  (filtersChanged)="userChangedSettings()">
            </advanced-filter-list>
        </div>
        <div class="col-lg-4 ws-stretch-height">
            <advanced-output-list [searchCriteria]="search.searchCriteria"
                                  [invalidColumns]="invalidColumns"
                                  (outputChanged)="userChangedSettings()">
            </advanced-output-list>
        </div>
    </div>
</div>

