import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

export interface ICellRendererParamsForPercentAllocation extends ICellRendererParams {
    editMode: () => boolean;
    onBlur: (params: ICellRendererParamsForPercentAllocation) => void;
}

@Component({
    selector: 'Renderer-cell',
    template: `
        <div *ngIf="params && params.data">
            <span *ngIf="!params.data.isTotal">
                <input
                    type="text"
                    class="form-control"
                    style="width: 100px;"
                    currencyMask
                    [options]="{ allowNegative: false, precision: 4, prefix: '', suffix: '%' }"
                    [(ngModel)]="params.data.displayAllocationPct"
                    (blur)="params.onBlur(params)"
                    *ngIf="params.editMode()">
                <span *ngIf="!params.editMode()">{{params.data.allocationPct | percent:'1.4-4'}}</span>
            </span>
            <span *ngIf="params.data.isTotal">TOTAL:</span>
        </div>
    `,
})
export class AgGridPercentAllocationCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForPercentAllocation;

    agInit(params: ICellRendererParamsForPercentAllocation): void {
        this.params = params;
    }

    refresh() {
        return false;
    }
}
