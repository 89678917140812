<div class="card panel-flat-color secondary-panel" *ngIf="showComplianceInfo">
    <div class="panel-flat-header pointer" (click)="expandOrCollapse()">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Assets</h3>
    </div>
    <div class="card-body" *ngIf="showBody" style="padding-top: 0;height: 490px;">
        <div class="text-center" style="margin-top: 50px;" *ngIf="onLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div [hidden]="onLoading" class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
            <asset-list [companyIdFilter]="companyId" [parcelIdFilter]="parcelId" [siteIdFilter]="siteId" [isEmbeddedMode]="true" [(loading)]="onLoading"></asset-list>
        </div>
    </div>
</div>
