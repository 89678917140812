import { Injectable } from '@angular/core';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { ForecastingBudgetingService } from '../../Budget/forecasting.budgeting.service';
import { ReportCategory, ReportSummary, ReportDetail, SelectedEntity, OrderAndTotalByOption, SiteStatus, GenericEntity, BudgetPeriodSelection, BudgetVarianceBudgetSource } from './report.manager.model';
import { ActiveStatuses } from '../../constants.new';
import { ReportType } from './report-type';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

//declare const _: any;

@Injectable()
export class ReportManagerService {
    constructor(private http: SDHttpService,
                private budgetingService: ForecastingBudgetingService) {
    }

    reportCategoryManagerUrl: string = '/api/reportmanager/category/';
    reportCategoryManagerSystemUrl: string = '/api/reportmanager/category/system/';
    reportCategoryManagerCustomUrl: string = '/api/reportmanager/category/custom/';
    reportManagerUrl: string = '/api/reportmanager/';
    ReportCategoryTypes: any = {
        SYSTEM: 0,
        CUSTOM: 1
    };

    activeStatuses = ActiveStatuses;
    availableBudgetPeriods: BudgetPeriodSelection[] = [];
    selectedBudgetPeriods: BudgetPeriodSelection[] = [];
    availableBudgetSources: BudgetVarianceBudgetSource[] = [];
    selectableBudgetSources: BudgetVarianceBudgetSource[] = [];

    topLevelCompanies$: Subject<{ topLevelCompanyIds: number[], initFromSavedReport: boolean }> = new Subject();

    private currentPropCharsSource = new Subject<GenericEntity[]>();
    private _selectableBudgetsChangedSubject = new BehaviorSubject<any>(false);

    currentPropChars$ = this.currentPropCharsSource.asObservable();


    setNewPropChars(propChars: GenericEntity[]): void {
        this.currentPropCharsSource.next(propChars);
    }

    get selectableBudgetsChanged$(): Observable<void> {
        return this._selectableBudgetsChangedSubject.asObservable();
    }

    getReportCategories(): Promise<Core.SavedReportCategoryManagementDTO[]> {
        return this.http.get(this.reportCategoryManagerUrl);
    }

    reorder(categoryList, reportType: number): Promise<ReportCategory[]> {
        if (reportType !== this.ReportCategoryTypes.CUSTOM) {
            throw new Error('Invalid report category type for reordering (only allowed on custom reports)');
        }
        return this.http.put(`${this.reportCategoryManagerCustomUrl  }reorder`, categoryList);
    }

    changeCategory(reportType: number, reportItem: Core.ReportListDTO, categoryId: number): Promise<any> {
        const url = this.reportManagerUrl + (reportType === this.ReportCategoryTypes.SYSTEM ? 'system/' : 'custom/');

        const categoryItem = {
            reportID: reportItem.reportID,
            categoryID: categoryId,
            rowVersion: reportItem.rowVersion
        };

        return this.http.patch(url + reportItem.reportID, categoryItem)
            .then(function (result) {
                return result;
            });
    }

    deleteReport(report, reportType): Promise<void> {
        let categoryName: string = 'custom';
        if (reportType == this.ReportCategoryTypes.SYSTEM) {
            categoryName = 'system';
        }
        return this.http.deleteByEntity(this.reportManagerUrl + categoryName, report);
    }

    deleteComplianceReport(report, reportType): Promise<void> {
        return this.http.delete(`/api/compliancereport/${report.reportID}`);
    }

    deleteReportCategory(category): Promise<void> {
        return this.http.delete(this.reportCategoryManagerUrl + category.categoryId);
    }

    patchReport(report, reportType): Promise<ReportSummary> {
        let categoryName: string = 'custom';
        if (reportType == this.ReportCategoryTypes.SYSTEM) {
            categoryName = 'system';
        }
        return this.http.patch(`${this.reportManagerUrl + categoryName  }/${  report.reportID}`, report);
    }

    saveReport(report: ReportDetail, isSystemReport: boolean): Promise<void> {
        const url = this.reportManagerUrl + (isSystemReport ? 'system' : 'custom');
        return this.http.put(url, this.prepareReport(report));
    }

    createCategory(name: string, isSystemReport: boolean): Promise<ReportCategory> {
        const newCategory = new ReportCategory();
        newCategory.categoryName = name;
        newCategory.categoryType = isSystemReport ? this.ReportCategoryTypes.SYSTEM : this.ReportCategoryTypes.CUSTOM;
        return this.http.post(this.reportCategoryManagerUrl, newCategory);
    }

    getReport(id: number): Promise<ReportDetail> {
        return this.http.get(`${this.reportManagerUrl  }custom/${  id}`).then((result: any) => {
            result['criteria'] = JSON.parse(result['criteria']);
            // We'll deal with version changes here for now; if this gets more complicated, we should break
            // this out into a new function. An example of why this is needed: we added an "Include Alternate Assessments"
            // Budget Report option that defaults to true in Epic 40. If a user saved a Budget Report in Epic 39, that property
            // would be missing if reloaded, and that would basically make the default value "false" if not present. Detect
            // cases like that and fill in defaults.
            const reportDetail = result as ReportDetail;
            switch (reportDetail.reportTypeId) {
                case ReportType.Budget:
                    if (reportDetail.criteria && reportDetail.criteria.detailReportOptions &&
                        reportDetail.criteria.detailReportOptions.includeAlternateAssessments === undefined) {
                        reportDetail.criteria.detailReportOptions.includeAlternateAssessments = true;
                    }
                    break;
                case ReportType.BudgetVariance:
                    // Convert dates in saved report from string to Date to facilitate
                    // comparison logic.
                    if (reportDetail.criteria && reportDetail.criteria.budgetPeriods ) {
                        _.forEach(reportDetail.criteria.budgetPeriods, (period) => {
                            if ( typeof(period.periodBegin) === 'string' )
                            {
                                period.periodBegin = new Date(period.periodBegin);
                            }
                            if ( typeof(period.periodEnd) === 'string' )
                            {
                                period.periodEnd = new Date(period.periodEnd);
                            }
                        });
                    }
                    if ( reportDetail.criteria && reportDetail.criteria.bvrFormatOutput &&
                         reportDetail.criteria.bvrFormatOutput.budgetSources ) {
                        _.forEach(reportDetail.criteria.bvrFormatOutput.budgetSources, (src) => {
                            if ( src.budgetPeriod.periodBegin && typeof(src.budgetPeriod.periodBegin) === 'string' )
                            {
                                src.budgetPeriod.periodBegin = new Date(src.budgetPeriod.periodBegin);
                            }
                            if ( src.budgetPeriod.periodEnd && typeof(src.budgetPeriod.periodEnd) === 'string' )
                            {
                                src.budgetPeriod.periodEnd = new Date(src.budgetPeriod.periodEnd);
                            }
                        });
                    }
                    // Delete original budgetSources array if present in object so they don't
                    // get re-saved, in favor of bvrFormatOutput.budgetSources.
                    if ( reportDetail.criteria && (reportDetail.criteria as any).budgetSources ) {
                        delete (reportDetail.criteria as any).budgetSources;
                    }
                    break;
            }
            return reportDetail;
        });
    }

    createReport(report: ReportDetail, isSystemReport: boolean): Promise<ReportDetail> {
        const url = this.reportManagerUrl + (isSystemReport ? 'system' : 'custom');
        return this.http.post(url, this.prepareReport(report, false));
    }

    share(report: Core.ReportListDTO): Promise<void> {
      const url = `${this.reportManagerUrl  }share`;
      return this.http.patch(url, report);
    }

    revokeShare(report: ReportDetail): Promise<void> {
      const url = `${this.reportManagerUrl  }revokeshare`;
      return this.http.patch(url, report);
    }

    getOrderAndTotalByOptions(): Promise <OrderAndTotalByOption[]> {
        const url = `${this.reportManagerUrl  }budgetreport/orderandtotalby`;
        return this.http.get(url);
    }

    getTotalAndGroupByOptions(): Promise <OrderAndTotalByOption[]> {
        const url = `${this.reportManagerUrl  }projectstatusreport/orderandtotalby`;
        return this.http.get(url);
    }

    getAvailableYears(): number[] {
		const currentYear = new Date().getFullYear();
		const firstYear = currentYear - 10;
		const secondYear = currentYear + 6;

		return _.range(firstYear, secondYear).reverse();
    }

    async refreshBudgetPeriods(report: ReportDetail, topLevelCompanyId: number) : Promise<void> {
        // Walk the availBudgets, gathering distinct budget periods.
        const availableBudgets = await this.budgetingService.getAvailableCompanyBudgets(topLevelCompanyId);
        const periods: BudgetPeriodSelection[] = _.chain(availableBudgets)
            .map((budget: Core.AvailableCompanyBudget) : BudgetPeriodSelection => {
                const sel = new BudgetPeriodSelection();
                sel.periodBegin = budget.fiscalPeriodBegin;
                sel.periodEnd = budget.fiscalPeriodEnd;
                sel.displayName = `${moment.utc(sel.periodBegin).startOf('day').format('MM/DD/YYYY')  } - ${
                                moment.utc(sel.periodEnd).startOf('day').format('MM/DD/YYYY')}`;
                return sel;
            })
            .orderBy(['displayName'], ['desc'])
            .value();

        // Populate availableBudgetPeriods with gathered periods, sorted in descending order.
        this.availableBudgetPeriods = _.sortedUniqBy(periods, 'displayName');

        // Re-apply period selections, filtering out non-applicable.
        const selections: BudgetPeriodSelection[] = _.filter(this.availableBudgetPeriods, (period) => {
            return _.some(report.criteria.budgetPeriods, [ 'displayName',  period.displayName ]);
        });
        report.criteria.budgetPeriods = selections;

        const forcastSources = _.map(this.availableBudgetPeriods, function(period: BudgetPeriodSelection) : BudgetVarianceBudgetSource {
            const src = new BudgetVarianceBudgetSource;
            src.budgetId = 0;
            src.budgetName = `${period.periodBegin.getUTCFullYear().toString()  } Forecast`;
            src.budgetBasis = Core.CompanyBudgetBasisEnum.AccrualAndCash;
            src.budgetPeriod.periodBegin = period.periodBegin;
            src.budgetPeriod.periodEnd = period.periodEnd;
            return src;
        });
        this.availableBudgetSources = _.concat(forcastSources, _.map(availableBudgets, function(budget:Core.AvailableCompanyBudget) {
            const src = new BudgetVarianceBudgetSource();
            src.budgetId = budget.companyBudgetId;
            src.budgetName = budget.budgetName;
            src.budgetBasis = budget.budgetBasis;
            src.budgetPeriod.periodBegin = budget.fiscalPeriodBegin;
            src.budgetPeriod.periodEnd = budget.fiscalPeriodEnd;
            return src;
        }));
        // Refresh selectable budget sources, now that budget periods may have been selected
        // and we have the available budget sources.
        this.refreshSelectableBudgetSources(report.criteria.budgetPeriods);
    }

    refreshSelectableBudgetSources(selectedBudgetPeriods: BudgetPeriodSelection[]) : void {
        this.selectableBudgetSources = _.filter(this.availableBudgetSources, (budget: BudgetVarianceBudgetSource) => {
            // Collect budgets with a period that begins within a selected period OR ends within a selected period.
            return _.some(selectedBudgetPeriods, (period: BudgetPeriodSelection) => {
                return (budget.budgetPeriod.periodBegin >= period.periodBegin && budget.budgetPeriod.periodBegin <= period.periodEnd) ||
                       (budget.budgetPeriod.periodEnd >= period.periodBegin && budget.budgetPeriod.periodEnd <= period.periodEnd);
            });
        });
        // Notify Format Output panel that Selectable Budgets have changed.
        this._selectableBudgetsChangedSubject.next(true);
    }

    getSiteStatusOptions(): SiteStatus[] {
        return [{
            name: 'All Except Inactive',
            id: 1
        }, {
            name: 'All ',
            id: 2
        }, {
            name: 'Active',
            id: 3
        }, {
            name: 'Active (Pending)',
            id: 4
        }, {
            name: 'Inactive',
            id: 5
        }];
    }

    getSiteStatusOptionsExceptInactive(): SiteStatus[] {
        return [{
            name: 'All Except Inactive',
            id: 1
		}];
    }

    private prepareReport(report: ReportDetail, stringify?: boolean): any {
        if (stringify === undefined) {
            stringify = true;
        }
        // Work on a copy so we don't screw up a referenced report
        const apiRequest: any = _.cloneDeep(report);
        apiRequest['criteria']['entitySelection']['selectedEntities'] =
            _.map(apiRequest['criteria']['entitySelection']['selectedEntities'], (selectedEntity: SelectedEntity): any => {
                return {
                    id: selectedEntity.id,
                    selectedDropdown: selectedEntity.selectedDropdown,
                    selectedIDs: selectedEntity.selectedIDs,
                    selectedPropCharVals: selectedEntity.selectedPropCharVals
                };
            });
        if (stringify) {
            apiRequest['criteria'] = JSON.stringify(apiRequest['criteria']);
        }
        return apiRequest;
    }

}
