import { Component, OnDestroy, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { BaseExpandableAgGridComponent } from '../../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import { ValuationTemplateService } from '../../valuationTemplate.service';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../Compliance/AgGrid';
import { WeissmanMutexService } from '../../../../Compliance/WeissmanMutexService';
import { WeissmanModalService } from '../../../../Compliance/WeissmanModalService';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AddAssessorComponent, ValuationsAddAssessorModalParams } from './Add-Assessor/addAssessor.component';
import { AgGridLinkCellRendererComponent, AgGridLinkCellRendererParams } from '../../../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridMultiSelectCellRendererParams, AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectHeaderRendererParams, AgGridMultiSelectTracker } from '../../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridActionCellRendererComponent, AgGridActionCellRendererParams } from '../../../../Compliance/AgGrid/CellRenderers/agGridActionCellRenderer.component';
import { BusyIndicatorService } from '../../../../Busy-Indicator';

@Component({
    selector: 'valuation-assessors-list',
    templateUrl: './valuationAssessorsList.component.html'
})
export class ValuationAssessorsListComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _modalService: WeissmanModalService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _busyIndicatorService: BusyIndicatorService
    ) {
        super(_valuationTemplateService, 'valuation-assessors-list');
    }

    gridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => {
                    const data = params.data as Core.ValuationTemplateAssessorModel;
                    return data && this._gridTracker.isRowSelected(data.assessorId);
                }
            },
            onFilterChanged: () => this._gridTracker.onGridFilterChanged(),
            onSortChanged: () => this._gridTracker.onGridSortChanged()
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    template: Core.ValuationTemplateModel;
    canEdit: boolean;
    canEnterEditMode: boolean;
    editMode: boolean;
    hasSelectedRows: boolean;

    private _gridTracker: AgGridMultiSelectTracker;
    private _gridApi: GridApi;
    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._valuationTemplateService.template$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => {
                if (x) {
                    this.template = x;
                    this.canEdit = x.states.length === 1;
                    this.canEnterEditMode = x.isInDevelopment;
                    this._setGridData();

                    if(!x.isInDevelopment && this.editMode){
                        this.cancel();
                    }
                }
            });

        this._mutexService.lockChanged$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.canEnterEditMode = !x[this._valuationTemplateService.editGroup] && this.template.isInDevelopment);
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._mutexService.release(this, this._valuationTemplateService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._gridTracker.selectedRows$
            .pipe(takeUntil(this._destroy$))
            .subscribe(x => this.hasSelectedRows = x.selectAllRows || !!(!x.selectAllRows && x.selectedRows.length));

        super.setGridApi(event.api);

        const columns: ColDef[] = [
            {
                field: 'assessorId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                hide: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this._gridTracker
                } as AgGridMultiSelectHeaderRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this._gridTracker
                } as AgGridMultiSelectCellRendererParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                    newWindow: true,
                    isHelpDisabled: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const entry = params.data as Core.ValuationTemplateAssessorModel;
                        if (!entry) {
                            return '';
                        }
                        return `#/states/${this.template.states[0].stateId}/assessors/${entry.assessorId}`;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                hide: true,
                cellRendererFramework: AgGridActionCellRendererComponent,
                cellRendererParams: {
                    buttonConfigs: [
                        {
                            iconClass: 'fa-trash',
                            onClick: this._onRemove.bind(this),
                            isShown: () => true,
                            buttonClass: 'btn-primary btn-danger',
                            helpContentId: 'app.delete'
                        }
                    ]
                } as AgGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessorName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setGridData();
    }

    async addNewAssessor(): Promise<void> {
        const params = {
            valuationTemplateId: this.template.valuationTemplateId,
            stateId: this.template.states[0].stateId,
            assessorsSelected: this.template.assessors.map(x => x.assessorId)
        } as ValuationsAddAssessorModalParams;

        await this._modalService.showAsync(AddAssessorComponent, params, 'modal-md');

        this._gridApi.redrawRows();
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._toggleGridEdit(true);
    }

    cancel(): void {
        this._toggleGridEdit(false);
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    async bulkRemove(): Promise<void> {
        const rowModel = this._gridTracker.getSelectedRowsModel();
        if (!this.hasSelectedRows) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Removing Assessors' });

        try {
            const assessors = this.template.assessors.filter(x => {
                if (rowModel.selectAllRows) {
                    return rowModel.selectedRows.find(y => y === x.assessorId);
                } else {
                    return !rowModel.selectedRows.find(y => y === x.assessorId);
                }
            });
            await this._valuationTemplateService.updateTemplateAssessors(this.template.valuationTemplateId, assessors);
        } finally {
            this._gridTracker.clear();
            busyRef.hide();
        }
    }

    private _setGridData(): void {
        if (!this._gridApi) {
            return;
        }
        this._gridApi.setRowData(this.template.assessors);
        this._gridApi.sizeColumnsToFit();
    }

    private _toggleGridEdit(editing: boolean): void {
        this.editMode = editing;
        if (this.gridOptions && this.gridOptions.columnApi) {
            this.gridOptions.columnApi.setColumnsVisible(['assessorId', 'actions'], this.editMode);
            this.gridOptions.api.sizeColumnsToFit();
        }
        if (!this.editMode) {
            this._mutexService.release(this, this._valuationTemplateService.editGroup);
            this._gridTracker.clear();
        } else {
            this._mutexService.acquire(this, this._valuationTemplateService.editGroup);
        }
        this._gridApi.redrawRows();
    }

    private async _onRemove(params: AgGridActionCellRendererParams): Promise<void> {
        const assessor = params.data as Core.ValuationTemplateAssessorModel;
        if (!assessor) {
            return;
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Removing Assessor' });

        try {
            const assessors = this.template.assessors.filter(x => x.assessorId !== assessor.assessorId);
            await this._valuationTemplateService.updateTemplateAssessors(this.template.valuationTemplateId, assessors);
        } finally {
            busyRef.hide();
        }
    }

    private _getGridRowIds(skip, take): Compliance.QueryResultModel<number> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                const assessor = x.data as Core.ValuationTemplateAssessorModel;
                return assessor && assessor.assessorId;
            })
        } as Compliance.QueryResultModel<number>;
    }
}
