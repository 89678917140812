import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { HelpService } from 'src/app/UI-Lib/Help-Tooltip';
import { DELIVERY_DETAILS_HELP } from './deliveryDetails.help';
import { PaymentBatchService } from 'src/app/Payment-Batch/paymentBatch.service';
import { endsWith, find, isEmpty, mapValues, omitBy } from 'lodash';
import { efAction } from 'src/app/constants.new';
import { TeamService } from 'src/app/Team/team.service';

export interface DeliveryDetailsPaymentBatchParams {
    editMode: boolean;
    entity: CompanyEntity;
    userSearchInstanceId: number
    currentPaymentBatchData: Core.PaymentBatchDeliveryDetail;
}

export type DeliveryDetailsPaymentBatchResult = [Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn, Core.PaymentBatchDeliveryDetail];

@Component({
    selector: 'delivery-details-payment-batch-modal',
    templateUrl: './deliveryDetailsPaymentBatchModal.component.html',
    styleUrls: ['./deliveryDetailsPaymentBatchModal.component.scss']
})
export class DeliveryDetailsPaymentBatchModalComponent implements OnInit, IWeissmanModalComponent<DeliveryDetailsPaymentBatchParams, DeliveryDetailsPaymentBatchResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService,
        private readonly _teamService: TeamService,
        private readonly _paymentBatchService: PaymentBatchService
    ) { }

    params: DeliveryDetailsPaymentBatchParams;
    result: DeliveryDetailsPaymentBatchResult = [undefined, undefined] as DeliveryDetailsPaymentBatchResult;

    taskSeriesList: Core.PaymentBatchConfigModel[];
    selectedWorkflow: Core.PaymentBatchConfigModel;
    currentData: Core.PaymentBatchDeliveryDetail = {} as Core.PaymentBatchDeliveryDetail;
    parentUsers: Core.UserTeamModel[];
    efAction: efAction = 'add';

    readonly prefixMap: { [taskTypeId: number]: string; } = {
        61: 'preparePreliminaryAPFeed',
        62: 'reviewPreliminaryAPFeed',
        64: 'requestFundingApproval',
        65: 'prepareCheckFeed',
        66: 'prepareFinalAPFeed',
        67: 'confirmFunding',
        68: 'reviewJurisProtocols',
        69: 'printChecks',
        70: 'mailChecks',
        71: 'archiveChecks',
        74: 'approvePayments'
    };

    loading: boolean = false;

    private _isDirty: boolean = false;

    async ngOnInit() {
        this._helpService.setContent(DELIVERY_DETAILS_HELP);

        this.loading = true;

        try {
            this.taskSeriesList = await this._paymentBatchService.getTaskSeriesConfig();
            this.currentData = this.params.currentPaymentBatchData
                || await this._paymentBatchService.getDeliveryDetailPaymentBatch(this.params.entity.id)
                || {} as Core.PaymentBatchDeliveryDetail;

            if(!isEmpty(this.currentData)) {
                this.selectedWorkflow = find(this.taskSeriesList, { paymentBatchTaskSeriesOptionId: this.currentData.paymentBatchTaskSeriesOptionId });
                this.efAction = 'update';
            } else {
                this.selectedWorkflow = this.taskSeriesList[0];
                this.efAction = 'add';
            }

            if (this.params.editMode) {
                this.parentUsers = await this._teamService.getAllAssignableUsers(false, false, this.params.entity.id, this.params.entity.type, this.params.userSearchInstanceId);
            }

        } finally {
            this.loading = false;
        }
    }

    userChanged(newUser: Core.UserTeamModel, userTypePrefix: string, isRequired: boolean): void {
        if (!newUser) {
            this._removeUser(userTypePrefix, isRequired);
        } else {
            this.currentData[`${userTypePrefix}UserId`] = newUser.userID;
            this.currentData[`${userTypePrefix}TeamId`] = newUser.teamID;

            this.currentData[`${userTypePrefix}Team`] = {
                name: newUser.teamName,
                teamID: newUser.teamID
            } as Weissman.Model.Workflow.Team;
            this.currentData[`${userTypePrefix}User`] = {
                efAction: null,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                userID: newUser.userID
            };
        }

        this._isDirty = true;
    }

    canSave(): boolean {
        // We now allow an incomplete Payment Batch configuration to be saved, deferring
        // validation to creation of a Payment Batch, so we don't need to ensure that
        // each Required task has an assigned user.
        return this._isDirty;
    }

    getDisplayName(user: Core.ContactShortDTO, team: Weissman.Model.Workflow.Team): string {
        //consultant person assigned - show consulting company name if not consultant viewing
        // if (team.instanceID !== this.params.entityInstanceId && this.params.isConsultantPerspective === false) {
        //     //look up consultant's name
        //     const consultantName = this.ces.find(ce => ce.instanceId === team.instanceID)?.name;
        //     return consultantName ? `Consultant- ${consultantName}` : '';
        // }
        //client person assigned - show person's name
        // else {
            return `${user.lastName}, ${user.firstName} (${team.name})`;
        // }
    }

    workflowChanged() {
        this.currentData = mapValues(this.currentData, (val, key) => {
            if(endsWith(key, 'UserId') || endsWith(key, 'TeamId')) {
                return undefined;
            } else {
                return val;
            }
        }) as Core.PaymentBatchDeliveryDetail;

        this.currentData.paymentBatchTaskSeriesOptionId = this.selectedWorkflow.paymentBatchTaskSeriesOptionId;

        this._isDirty = true;
    }

    save() {
        const idsOnly =  omitBy(this.currentData, (val, key) => {
            return endsWith(key, 'User') || endsWith(key, 'Team');
        });

        this.result = [{
            ...idsOnly,
            efAction: this.efAction,
            ownerEntityID: this.params.entity.id,
            paymentBatchTaskSeriesOptionId: this.selectedWorkflow.paymentBatchTaskSeriesOptionId
        } as Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn,
        this.currentData];

        this.close();
    }

    close() {
        this._bsModalRef.hide();
    }

    private _removeUser(userTypePrefix: string, isRequired: boolean): void {
        this.currentData[`${userTypePrefix}User`] = {};
        this.currentData[`${userTypePrefix}Team`] = {};

        this.currentData[`${userTypePrefix}UserId`] = null;
        this.currentData[`${userTypePrefix}TeamId`] = null;
    }
}