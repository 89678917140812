import {Component, OnInit} from '@angular/core';
import {RestrictService} from '../../Common/Permissions/restrict.service';
import {Constants} from '../../constants.new';

@Component({
    selector: 'help-component',
    templateUrl: './_help.html'
})
export class HelpComponent implements OnInit {
    constructor(private restrictService: RestrictService,
                private constants: Constants) {}

    hasViewPermission: boolean = false;
    editingInProgress: boolean;

    ngOnInit(): void {
        this.hasViewPermission = this.restrictService.isInRole(this.constants.Roles['RYANPRIVATEITEMSVIEW']) ||
            this.restrictService.isInRole(this.constants.Roles['RYANPRIVATEITEMSEDIT']);
    }

}
