import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppealDeadlinePayload } from './Panel/deadlineListPanel.component';

@Injectable()
export class DeadlineService {
    constructor(private readonly _http: HttpClient) {}

    private readonly _baseUrl = 'api/group';

    getAvailableStateYears(stateId: number): Promise<{years: number}[]> {
        return lastValueFrom(this._http.get(`api/State/${stateId}/AppealDeadline/Years`)) as Promise<{
            years: number
        }[]>;
    }

    getAvailableAssessorYears(assessorId: number): Promise<{years: number}[]> {
        return lastValueFrom(this._http.get(`api/Assessor/${assessorId}/AppealDeadline/Years`)) as Promise<{
            years: number
        }[]>;
    }

    getStateDeadlines(stateId: number): Promise<Core.AppealDeadlineModel[]> {
        return lastValueFrom(this._http.get(`api/State/${stateId}/AppealDeadline`)) as Promise<Core.AppealDeadlineModel[]>;
    }

    getAssessorDeadlines(assessorId: number): Promise<Core.AppealDeadlineModel[]> {
        return lastValueFrom(this._http.get(`api/Assessor/${assessorId}/AppealDeadline`)) as Promise<Core.AppealDeadlineModel[]>;
    }

    getAppealDeadlineTypes(): Promise<any> {
        return lastValueFrom(this._http.get('/api/AppealDeadlineType/'));
    }

    saveForState(stateId: number, payload: any): Promise<any> {
        return lastValueFrom(this._http.post(`/api/state/${stateId}/AppealDeadlines`, payload)) as Promise<any>;
    }

    create(entityString: string, entityId: number, payload: AppealDeadlinePayload): Promise<any> {
        return lastValueFrom(this._http.post(`/api/${entityString}/${entityId}/AppealDeadline`, payload)) as Promise<any>;
    }

    update(entityString: string, entityId: number, payload: AppealDeadlinePayload): Promise<any> {
        return lastValueFrom(this._http.put(`/api/${entityString}/${entityId}/AppealDeadline`, payload)) as Promise<any>;
    }

    remove(entityString: string, entityId: number, appealDeadlineId: number, doUpdate: boolean): Promise<any> {
        const params = new HttpParams().set('doUpdate', doUpdate.toString());

        return lastValueFrom(this._http.delete(`/api/${entityString}/${entityId}/AppealDeadline/${appealDeadlineId}`, { params: params })) as Promise<any>;
    }

    getAssessorExceptions(deadline: Core.AppealDeadlineModel, stateId: number, taxYear: string | number): Promise<any> {
        if (deadline.appealDeadlineTypeID === undefined) throw 'deadlineTypeId is required';
        if (deadline.propertyTypeID === undefined) throw 'propertyTypeId is required';
        if (taxYear === undefined) throw 'taxYear is required';
        if (stateId === undefined) throw 'stateId is required';
        if (deadline.deadlineMonthDay === undefined) throw 'stateDeadline is required';

        const params  = new HttpParams()
            .set('stateID', stateId.toString())
            .set('taxyear', taxYear.toString())
            .set('proptypeid', deadline.propertyTypeID.toString())
            .set('deadlinetypeid', deadline.appealDeadlineTypeID.toString())
            .set('statedeadline', deadline.deadlineMonthDay.toString());

        return lastValueFrom(this._http.get(`/api/state/${stateId}/appealdeadline/exceptions`, { params: params }));
    }
}
