<span [popover]="isTopLevelCompany ? '' : 'Site Characteristics may only be configured at the top-company level.'"
    triggers="mouseenter:mouseleave">
    <button class="ace-btn btn-sm has-icon btn-primary"
            [ngClass]="{'no-descriptors': !siteDescriptors?.length, 'has-descriptors': siteDescriptors?.length}"
            [title]="title"
            [disabled]="!isTopLevelCompany"
            (click)="view()">
        <i class="fa fa-list-alt"></i>
    </button>
</span>

