import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PaymentPackage, PaymentTransmittal, PaymentTransmittalItem, PaymentPackageRecipient, RecipientType } from './transmittal.models';
import { Constants, EntityTypeIds } from '../../constants.new';
import { PopUpService } from '../../Common/Popup/popup.service';
import { Attachment, AttachmentInfo } from '../../Attachment/attachment.model';
import { AssessorCollectorRepository } from '../../Core-Repositories';
import { AddressDetailsModalLaunchService } from '../../Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { EntityAddress } from '../../Common/Address/Address-View-Edit/address.model';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';

declare const _: any;

@Component({
    selector: 'transmittal-details',
    templateUrl: './transmittal-details.component.html'
})
export class TransmittalDetailsComponent implements OnInit {
    constructor(
        public constants: Constants,
        private popUpService: PopUpService,
        private assessorCollectorService: AssessorCollectorRepository,
        private addressModalService: AddressDetailsModalLaunchService,
        private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private attachmentService: AttachmentService) {
    }

    @Input() selectedPackage: PaymentPackage;
    @Input() selectedTransmittalID: number;
    @Input() readOnly: boolean = true;
    @Input() isModal: boolean;
    @Input() isFromPaymentBatch: boolean;
    @Output() backClicked: EventEmitter<void> = new EventEmitter<void>();

    currentTransmittalIndex: number = null;
    recipients: object;
    RecipientType = RecipientType;
    isInit: boolean = true;
    currentBillAttachment: Attachment;
    collectorAddresses: EntityAddress[];


    ngOnInit() {
        this.recipients = _.groupBy(this.selectedPackage.paymentPackageRecipients, (recipient: PaymentPackageRecipient) => {
            return recipient.recipientType;
        });
        this.currentBillAttachment = null;

    }

    getPropertyTypeName(propertyTypeID: number): string {
        return this.constants.PropertyTypeNames[propertyTypeID];
    }

    getTotalAmount(items: PaymentTransmittalItem[]): number {
        return _.reduce(items, (total: Decimal, item: PaymentTransmittalItem) => {
            return total.plus(item.amount);
        }, new Decimal(0))
        .toNumber();
    }

    previewBill(item: PaymentTransmittalItem): void {
        const popUpData = {
            attachmentInfo: item.attachment
        };

        // if we have already opened the default window and the user hasn't closed it, just publish directly to it (it's faster)
        const globalAttachmentsPopUpRef = this.popUpService.getByAliasGroup(this.popUpService.StaticAliases.Attachment)[0];
        if (globalAttachmentsPopUpRef && globalAttachmentsPopUpRef.isOpen()) {
            globalAttachmentsPopUpRef.publish(this.popUpService.Topics.Attachment.AttachmentChanged, popUpData);
        } else {
            this.popUpService.openAttachmentPreview(this.popUpService.StaticAliases.Attachment, globalAttachmentsPopUpRef).then((popUpRef) => {
                popUpRef.publish(this.popUpService.Topics.Attachment.AttachmentChanged, popUpData);
            });
        }
    }

    async downloadBill(item: PaymentTransmittalItem): Promise<void> {
        const downloadInfo: AttachmentInfo = new AttachmentInfo(item.attachment.entityTypeID,
            item.attachmentID,
            item.attachment.entityID,
            item.attachment.fileName,
            item.attachment.fileExtension,
            item.attachment.size);

        await this.attachmentService.downloadAttachmentFile(downloadInfo, false);
    }

    removeItem(transmittal: PaymentTransmittal, item: PaymentTransmittalItem): void {
        _.remove(transmittal.paymentTransmittalItems, item);
    }

    backClickedUI(): void {
         this.backClicked.emit();
    }

    async goToTransmittal(): Promise<void> {
        this.selectedTransmittalID = Number(this.selectedTransmittalID);
        this.currentTransmittalIndex = _.findIndex(this.selectedPackage.paymentTransmittals, { paymentTransmittalID: this.selectedTransmittalID }) as number;

        const currentTransmittal = this.selectedPackage.paymentTransmittals[this.currentTransmittalIndex];
        const transmittalCollector = await lastValueFrom(this.assessorCollectorService.getCollector(currentTransmittal.collectorID));
        this.collectorAddresses = _.map(transmittalCollector.entityAddresses, x => {
            x.isDefault = x.addressID == currentTransmittal.collectorAddress.addressID;
            return x;
        });

    }

    slideChanged(e: number): void {
        // SlideChanged is called when this component is initialiazed, which is what we don't want, because the index gets set to 0
        // So we bypass it for this first time.
        if(this.isInit) {
            this.goToTransmittal();

            this.isInit = false;
            return;
        }

        this.selectedTransmittalID = this.selectedPackage.paymentTransmittals[this.currentTransmittalIndex].paymentTransmittalID;
    }

    async editCollectorAddress(): Promise<void> {
        const currentTransmittal = this.selectedPackage.paymentTransmittals[this.currentTransmittalIndex];

        this.collectorAddresses = await this.addressModalService.openAddressDetailsModal(
            currentTransmittal.collectorID,
            EntityTypeIds.COLLECTOR,
            this.collectorAddresses,
            false,
            true
        );

        currentTransmittal.collectorAddress = _.find(this.collectorAddresses, 'isDefault').address;
    }

    preventCarouselFromInterceptingSpace($event) {
        if ($event.key === ' ') {
            $event.stopPropagation();
        }
    }
}
