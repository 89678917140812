import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { WeissmanModalService } from '../../../WeissmanModalService';
import { AssetAdvancedDetailsComponent, AssetAdvancedDetailsParams } from '../Asset-Advanced-Details/assetAdvancedDetails.component';
import { AssetPropertyMap } from '../../Models/assetPropertyMap';
import { AssetPropertyInfo } from '../../Models/assetPropertyMap';
import * as _ from 'lodash';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { ASSET_ADVANCED_HELP } from './assetAdvanced.component.help';
import { UserSettingsService } from '../../../../Account/userSettings.service';

@Component({
    selector: 'asset-advanced',
    templateUrl: './assetAdvanced.component.html'
})
export class AssetAdvancedComponent {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _userSettingsService: UserSettingsService
    ) { }

    @Input()
    set assetDescriptors(assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[]) {
        if (assetDescriptors) {
            this._setAssetDescriptors(assetDescriptors);
        }
    }

    @Output() optionChange: EventEmitter<Compliance.AdvancedAssetFilters> = new EventEmitter<Compliance.AdvancedAssetFilters>();

    isInitialized: boolean;

    selectOptions: Compliance.NameValuePair<Compliance.AdvancedAssetFilters>[] = [
        {
            name: 'All assets at lien date and disposed', value: {
                allAssetsAtLienDate: true,
                fieldChangedColumns: null,
                includeAnyReturnImpactfulFieldChange: true,
                includeDisposed: true,
                includeDisposedSinceLastLienDate: false,
                includeNewAddition: true,
                includePotentiallyDisposed: true,
                includeChanged: true,
                includeNotChanged: true,
                includeTransferred: true,
                comparisonLienDate: null,
                comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
            }
        },
        {
             name: 'All assets at lien date', value: {
                allAssetsAtLienDate: true,
                fieldChangedColumns: null,
                includeAnyReturnImpactfulFieldChange: true,
                includeDisposed: false,
                includeDisposedSinceLastLienDate: false,
                includeNewAddition: true,
                includePotentiallyDisposed: true,
                includeChanged: true,
                includeNotChanged: true,
                includeTransferred: true,
                comparisonLienDate: null,
                comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
            }
        },
        {
            name: 'New additions and transfers', value: {
                allAssetsAtLienDate: false,
                fieldChangedColumns: null,
                includeAnyReturnImpactfulFieldChange: false,
                includeDisposed: false,
                includeDisposedSinceLastLienDate: false,
                includeNewAddition: true,
                includePotentiallyDisposed: false,
                includeChanged: false,
                includeNotChanged: false,
                includeTransferred: true,
                comparisonLienDate: null,
                comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
            }
        },
        {
            name: 'Potentially disposed', value: {
                allAssetsAtLienDate: false,
                fieldChangedColumns: null,
                includeAnyReturnImpactfulFieldChange: false,
                includeDisposed: false,
                includeDisposedSinceLastLienDate: false,
                includeNewAddition: false,
                includePotentiallyDisposed: true,
                includeChanged: false,
                includeNotChanged: false,
                includeTransferred: false,
                comparisonLienDate: null,
                comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
            }
        },
        {
            name: 'Disposed', value: {
                allAssetsAtLienDate: false,
                fieldChangedColumns: null,
                includeAnyReturnImpactfulFieldChange: false,
                includeDisposed: true,
                includeDisposedSinceLastLienDate: true,
                includeNewAddition: false,
                includePotentiallyDisposed: false,
                includeChanged: false,
                includeNotChanged: false,
                includeTransferred: false,
                comparisonLienDate: null,
                comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
            }
        },
        {
            name: 'Custom', value: {
                allAssetsAtLienDate: false,
                fieldChangedColumns: null,
                includeAnyReturnImpactfulFieldChange: true,
                includeDisposed: false,
                includeDisposedSinceLastLienDate: false,
                includeNewAddition: true,
                includePotentiallyDisposed: true,
                includeChanged: true,
                includeNotChanged: true,
                includeTransferred: true,
                comparisonLienDate: null,
                comparisonLienDateType: Compliance.ComparisonLienDateTypeEnum.OneYearAgo
            }
        }
    ];
    selectedOption: UntypedFormControl;

    private readonly _settingName = 'Show-Selected-Option';
    private _fieldSelectOptions: AssetPropertyInfo[];
    private _savedSelectedOptionSetting: any;

    ngOnInit(): void {
        this._helpService.setContent(ASSET_ADVANCED_HELP);

        this.selectOptions.forEach(x => {
            if (x.value.allAssetsAtLienDate && !x.value.fieldChangedColumns) {
                x.value.fieldChangedColumns = _.map(this._fieldSelectOptions, y => y.property);
            }
        });

        const settings = this._userSettingsService.getSettingsByGroup(Core.UserSettingGroup.CompanyAssetsList);
        this._savedSelectedOptionSetting = settings.find(x => x.name === this._settingName);

        let selectedOption: Compliance.NameValuePair<Compliance.AdvancedAssetFilters>;

        if (this._savedSelectedOptionSetting) {
            selectedOption = this.selectOptions.find(x => x.name === this._savedSelectedOptionSetting.value.name);

            if (selectedOption && selectedOption.name === 'Custom') {
                for (const prop in this._savedSelectedOptionSetting.value.value) {
                    selectedOption.value[prop] = this._savedSelectedOptionSetting.value.value[prop];
                }

                this._fieldSelectOptions.forEach(x => x['isSelected'] = !!_.find(selectedOption.value.fieldChangedColumns, y => x.property === y));
                selectedOption.value.fieldChangedColumns = _.map(this._fieldSelectOptions.filter(x => x.isSelected), x => x.property);
            }
        }

        if (!selectedOption) {
            selectedOption = this.selectOptions[0];
        }

        this.selectedOption = new UntypedFormControl(selectedOption);
        this.optionChange.emit(this.selectedOption.value.value);
    }

    /**
     * Select Option change
     */
    async selectedOptionChanged(): Promise<void> {
        const filter: Compliance.NameValuePair<Compliance.AdvancedAssetFilters> = this.selectedOption.value;
        this.optionChange.emit(filter.value);
        await this._saveSelectedOption();
    }

    /**
     * Handle advanced filter modal
     */
    async edit(): Promise<void> {
        const params: AssetAdvancedDetailsParams = {
            selectOptions: this.selectOptions,
            selectControl: this.selectedOption,
            fieldSelectOptions: this._fieldSelectOptions
        };

        const result = await this._modalService.showAsync(AssetAdvancedDetailsComponent, params, 'modal-md');

        // trigger change detection on dropdown value
        this.selectedOption.setValue(this.selectedOption.value);

        if (!result) {
            return Promise.resolve();
        }

        if (this.selectedOption.value === this.selectOptions[this.selectOptions.length - 1]) {
            this.selectOptions[this.selectOptions.length - 1].value = result;
        }

        this.optionChange.emit(result);

        await this._saveSelectedOption();
    }

    /**
     * Assign asset descriptors and override properties
     * @param assetDescriptors
     */
    private _setAssetDescriptors(assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[]): void {
        const overrideProperties = _.map(_.filter(AssetPropertyMap.properties, x => x.isChangeFilter && !x.isDescriptor), x => x);

        _.forEach(assetDescriptors, x => {
            const descriptorKey = `source${x.columnName}`;
            const descriptorPropertyInfo = _.find(AssetPropertyMap.properties, (p: AssetPropertyInfo) => p.isChangeFilter && p.isDescriptor && p.key === descriptorKey);

            if (descriptorPropertyInfo) {
                overrideProperties.push(_.extend(descriptorPropertyInfo, { isSelected: false, displayName: x.descriptor.name }));
            }
            else {
                console.warn(`Property info not found for asset descriptor ${descriptorKey}`);
            }
        });

        this._fieldSelectOptions = overrideProperties.sort((a, b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
    }

    private async _saveSelectedOption() {
        if (!this._savedSelectedOptionSetting) {
            this._savedSelectedOptionSetting = {
                id: 0,
                name: this._settingName,
                groupId: Core.UserSettingGroup.CompanyAssetsList,
                folderId: Core.UserSettingFolder.Default
            };
        }

        this._savedSelectedOptionSetting.value = this.selectedOption.value;
        this._savedSelectedOptionSetting = await this._userSettingsService.save(this._savedSelectedOptionSetting);
    }
}
