import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class ErrorLogRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    get(errorLogId: number): Observable<Core.ErrorLogModel> {
        return this.httpGet(`/api/errorlog/${errorLogId}`);
    }

    getViaGuid(errorLogGuid: string): Observable<Core.ErrorLogModel> {
        return this.httpGet(`/api/errorlog/${errorLogGuid}`);
    }

    getList(searchModel: Core.ErrorLogInfoSearchModel): Observable<Compliance.QueryResultModel<Core.ErrorLogInfoModel>> {
        return this.httpPost('/api/errorlog', searchModel);
    }
}
