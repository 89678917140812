import { Component, EventEmitter, Input, OnChanges, Output, ViewChild, OnInit, SimpleChanges } from '@angular/core';
import { Decimal } from 'decimal.js';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AttachmentModalData, AttachmentModalEntityData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailsNavigationEventService } from '../annual-details-event.service';
import { AnnualDetailEditState } from '../annual-details-navigation.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailAssessment } from '../Annual-Year/annual-year.model';
import { PaymentPackagesModalComponent } from '../Modals/payment-package-modal.component';
import { PaymentStubModalComponent } from '../Modals/payment-stub.component';
import { BillClusterService, BillViewModel, TaxesViewModel } from './bill-cluster.service';
import { Bill } from './bill.model';
import { Payment } from './payment.model';
import { StateService } from '../../Common/States/States.Service';
import { StateSummary } from '../../Common/States/state.model';
import { TaxRateSetupModalLaunchService } from '../../Entity/Parcel/TaxRateSetup/taxRateSetupModalLaunchService';
import { TaxSetupMinimumDTO, FirstEncounterSaveResult, ParcelTaxRateService, TaxRateDetails } from '../../Entity/Parcel/TaxRateSetup/parcelTaxRateService';
import { TaxAuthorityStatuses } from '../../constants.new';
import { flatMap, filter } from 'lodash/fp';
import { WeissmanModalService } from 'src/app/Compliance/WeissmanModalService';
import { BillImageModalComponent } from 'src/app/Attachment/Bill-Image-Modal/billImageModal.component';
import { AddressDetailsModalLaunchService } from '../../Common/Address/Address-View-Edit/address.details.modal.launch.service';
import { ProductAnalyticsService } from 'src/app/Common/Amplitude/productAnalytics.service';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service'

declare const moment: any;

const TAXBILL_ENTITY_TYPE_ID = 9;

@Component({
	selector: 'bills',
	templateUrl: './bills.component.html'
})
export class BillsComponent implements OnChanges, OnInit {

	constructor(
		private billClusterService: BillClusterService,
		private taskService: TaskService,
		private messageBoxService: MessageBoxService,
		private toastr: ToastrService,
		private annualDetailsService: AnnualDetailsService,
		private navigationEvent: AnnualDetailsNavigationEventService,
        private statesService: StateService,
        private taxRateSetupModalLaunchService: TaxRateSetupModalLaunchService,
		private modalService: WeissmanModalService,
        private parcelTaxRateService: ParcelTaxRateService,
		private productAnalyticsService: ProductAnalyticsService,
		private readonly addressDetailsModalLaunchService: AddressDetailsModalLaunchService,
		private featureFlagService: FeatureFlagsService
    ) {
		this.tempVals = {};
		this.allowEdit = true;
		this.enableTaxPaymentAddressLink = this.featureFlagService.featureFlags.enableTaxPaymentAddressLink;

		this.statesService.getSummary().then((states: StateSummary[]) => {
			const foundState = _.find(states, { stateID: this.stateId });

			this.stateIsSupplemental = foundState ? foundState.supplementalTaxBill : false;
		});
	}

	@Input() editState: AnnualDetailEditState;
	@Input() viewModel: BillViewModel;
	@Input() gridNavigationHandler: () => void;
	@Input() isDocumentProcessing: boolean = false;
    @Input() firstEncounterSavedCallback?: (result: FirstEncounterSaveResult) => Promise<void>;
	@Input() taxesViewModel: TaxesViewModel;
	@Input() showExtraFields: { bill: boolean, payment: boolean };
    @Input() stateId: number;
    @Input() processingParcelTaxSetup?: TaxSetupMinimumDTO;
	@Output() RevisionChanged: EventEmitter<AnnualDetailAssessment> = new EventEmitter<AnnualDetailAssessment>();
    @Output() AnnualDetailsSaveAllowedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() saveBillCluster: EventEmitter<any> = new EventEmitter<any>();
    @Input() hasProcessingChanges?: boolean;
	@ViewChild('PaymentStubModal') paymentStubModal: PaymentStubModalComponent;
	@ViewChild('PaymentPackageModal', { static: true }) paymentPackagesModal: PaymentPackagesModalComponent;

	waitingOnServer: boolean;
	tempVals;
	allowEdit: boolean;
	paymentStubParams: { billID: number, payment: Payment };
	taxBillEntityTypeId: number = TAXBILL_ENTITY_TYPE_ID;
	stateIsSupplemental: boolean;
    showActualRow: boolean;
    showFirstEncounterIndicator: boolean;
	oldBillAmount: number;
	enableTaxPaymentAddressLink: boolean;

	ngOnInit() {
        // This won't execute on document processing, but the different view model handler should take care of this anyway
        // (TODO: Test that more carefully to ensure it's happening as expected)
        if (this.viewModel) {
            this.viewModel.taskSummariesPromise
                .then(() => {
                    this.showActualRow = this.showActualCheckboxRow();
                });
        }
	}

    ngOnChanges(changes: SimpleChanges): void {
        const changeFields = Object.keys(changes);
		if (this.isDocumentProcessing && this.viewModel) {
			if (!this.viewModel.model[0].annualAssessmentID) {
				this.viewModel.model[0].annualAssessmentID = _.maxBy(this.viewModel.yearRevisions, 'revisionNum').annualAssessmentID;
            }

            if (changeFields.some(f => f === 'processingParcelTaxSetup' || f === 'viewModel')) {
                this.calculateShowFirstEncounterIndicator();
            }

			this.assocRevisionChanged(this.viewModel.model[0]);
		}
    }

	get analyticsCategory(): string {
		if(this.isDocumentProcessing) {
			return this._atLeastOnePaymentinQC() ? 'document-qc' : 'document-dp';
		} else {
			return '';
		}
	}

	get paymentDuePadding(): string {
		if (!this.editState.editMode) {
			return '0px';
		}

		const paymentsWithDueDatesChanged = _.flow([
			flatMap('payments'),
			filter('dueDateChanged')
		])(this.viewModel.model);

		if (!paymentsWithDueDatesChanged.length) {
			return '0px';
		}

		let max = 0;
		_.forEach(paymentsWithDueDatesChanged, payment => {
			let padding = 0;
			const dueDateIsInPast = this.dueDateIsInPast(payment.dueDate);
			const dueDateIsLaterThanOriginal = this.dueDateIsLaterThanOriginal(payment);

			if(dueDateIsInPast) {
				padding = 22;
			}

			if(dueDateIsLaterThanOriginal) {
				padding = 37;
			}

			if(dueDateIsInPast && dueDateIsLaterThanOriginal) {
				padding = 52;
			}

			max = padding > max ? padding : max;
		});

		return `${max}px`;

	}

    calculateShowFirstEncounterIndicator() {
        const billClusterId = this.viewModel.model[0].billClusterID;
        let detailPromise: Promise<TaxRateDetails> = null;
        const makeDetailPromise = (taxRateAreaId: number, taxAuthorityIds: number[]) => {
            if (taxRateAreaId || (taxAuthorityIds && taxAuthorityIds.length > 0)) {
                return this.parcelTaxRateService.searchTaxRateDetails(
                    billClusterId,
                    taxRateAreaId,
                    taxAuthorityIds,
                    null);
            }
            return null;
        };

        if (this.viewModel.processingTaxSetupResult) {
            if ((this.viewModel.processingTaxSetupResult.taxAuthorityIds && this.viewModel.processingTaxSetupResult.taxAuthorityIds.length > 0) || this.viewModel.processingTaxSetupResult.taxRateAreaId) {
                detailPromise = makeDetailPromise(
                    this.viewModel.processingTaxSetupResult.taxRateAreaId,
                    this.viewModel.processingTaxSetupResult.taxAuthorityIds);
            }
            else {
                if (this.processingParcelTaxSetup) {
                    detailPromise = makeDetailPromise(
                        this.processingParcelTaxSetup.taxRateAreaId,
                        this.processingParcelTaxSetup.taxAuthorities ?
                            this.processingParcelTaxSetup.taxAuthorities.map(ta => ta.taxAuthorityId) :
                            null);
                }
                else {
                    detailPromise = this.parcelTaxRateService.getCollectorTaxRateDetails(billClusterId, null);
                }
            }
        }
        else {
            detailPromise = this.parcelTaxRateService.getTaxRateDetails(billClusterId, null).then(details => {
                if (!this.processingParcelTaxSetup || !details.usingParentRates) {
                    return details;
                }
                else {
                    return makeDetailPromise(
                        this.processingParcelTaxSetup.taxRateAreaId,
                        this.processingParcelTaxSetup.taxAuthorities ?
                            this.processingParcelTaxSetup.taxAuthorities.map(ta => ta.taxAuthorityId) :
                            null);
                }
            });
        }

        if (detailPromise) {
            detailPromise.then(details => {
                this.showFirstEncounterIndicator = details &&
                    details.taxAuthorities &&
                    details.taxAuthorities.some(ta => ta.taxAuthorityStatusId === TaxAuthorityStatuses.Estimated);
            });
        }
    }

	assocRevisionChanged(bill: Bill): void {
		const revision = _.find(this.viewModel.yearRevisions, { annualAssessmentID: bill.annualAssessmentID }) as AnnualDetailAssessment;

		this.RevisionChanged.emit(revision);
	}

	async deletePayment(bill: Bill, payment: Payment): Promise<void> {
		const result = await this.messageBoxService.open({
			message: 'Confirm Deleting Payment?',
			buttons: MessageBoxButtons.OKCancel
		});

		if (result !== MessageBoxResult.OK) {
			return;
		}

		this.editState.refreshGrid = true;

		if (bill.payments.length > 1) {
			_.remove(bill.payments, payment);

			return;
		}

		if (this.viewModel.model.length > 1) {
			this.billClusterService.deleteBill(bill.billID).then(() => {
				_.remove(this.viewModel.model, bill);
				this.navigationEvent.refreshActivityData();
			});
		} else {
			if (bill.collectorPaymentOptionID) {
				this.billClusterService.deleteBillCluster(bill.billClusterID).then(() => {
					this.gridNavigationHandler();
					this.navigationEvent.refreshActivityData();
				});
			}
			else {
				this.billClusterService.deleteBill(bill.billID).then(() => {
					this.gridNavigationHandler();
					this.navigationEvent.refreshActivityData();
				});
			}
		}
	}

	makeDiscountNegative(payment: Payment): void {
		if (payment.discountAmount > 0) {
			payment.discountAmount = -payment.discountAmount;
		} else {
			this.zeroIfNull(payment, 'discountAmount');
		}

		this.paymentPropChanged(payment);
	}

    makeExemptionNegative(payment: Payment): void {
        if (payment.exemptionAmount > 0) {
            payment.exemptionAmount = -payment.exemptionAmount;
        } else {
            this.zeroIfNull(payment, 'exemptionAmount');
        }
        this.paymentPropChanged(payment);
    }

    dateOverridden(payment: Payment, field: string): boolean {
		const current = payment[field];
		const original = this._getOriginalDate(payment, field);

		if (!current || !original) {
			return false;
		}

		return !moment(current).isSame(original);
	}

	getBillPaymentOptionName(bill: Bill): string {
		if (bill.collectorPaymentOptionID === null || bill.collectorPaymentOptions === undefined) {
			return '';
		} else if (bill.collectorPaymentOptions.length === 0) {
			return bill.collectorPaymentOption ? bill.collectorPaymentOption.name : 'N/A';
		}

		const option = _.find(bill.collectorPaymentOptions, {
			collectorPaymentOptionID: bill.collectorPaymentOptionID
		});

		return option ? option.name : 'N/A';
	}

	billAmountBlurred(oldBillAmount: number, bill: Bill): void {
		if (oldBillAmount !== bill.billAmount) {
			this._distribute(bill);
		}
	}

	paymentPropChanged(payment): void {
		payment.paymentAmount = this.billClusterService.paymentPropChanged(payment);
		//This recalcuates totals for the annual year
		// this.calcTaxTotals();
	}

	//Not liking this...
	captureVal(entity: any, field: string): void {
		setTimeout(() => {
			this.tempVals[field] = entity[field];
		}, 250);
	}

	divide(numerator: number, divisor: number): number[] {
		const results: number[] = [];
		// let dividend: number = parseFloat((Math.floor(numerator / divisor * 100) / 100).toFixed(2)); // dividend with 2 decimal places

		const dividend: number = new Decimal(numerator).dividedBy(divisor).times(100).floor().dividedBy(100).toNumber();

		for (let i = 0; i < divisor - 1; i++) {
			results.push(dividend); // Put n-1 copies of dividend in results
		}

		const amtWithRemainder: number = new Decimal(divisor).minus(1).neg().times(dividend).plus(numerator).toNumber();
		results.unshift(amtWithRemainder); // Add remainder to first payment amt

		return results;
	}

	// Possibly redistribute $ when gross payment amt changed
	grossPaymentChanged(bill: Bill, payment: Payment): void {
		// if amt unchanged, no work to be done
		if (payment.grossPayment === this.tempVals.grossPayment) {
			return;
		} else if (this.billClusterService.calcPaymentAmount(payment) < 0) {
			payment.grossPayment = this.tempVals.grossPayment;
			this.paymentPropChanged(payment);

			// I think this should be toast
			this.toastr.error('Error!', 'Payment Amount Cannot be negative');

			return;
		}

		let paymentIdx: number = _.findIndex(bill.payments, { paymentID: payment.paymentID });

		// Bug Fix - if index is -1 then it means the payment hasn't been saved yet, and this breaks the calculation
		if (paymentIdx < 0) {
			paymentIdx = bill.payments.length - 1;
		}

		const previousPayments: Payment[] = bill.payments.slice(0, paymentIdx);
		const subsequentPayments: Payment[] = bill.payments.slice(paymentIdx + 1);
		let totalAllocation: number = new Decimal(bill.billAmount).sub(new Decimal(payment.grossPayment)).toNumber();

		if (subsequentPayments.length > 0 && _.some(subsequentPayments, x => this.notProcessed(x))) {
			const previousGross = _.chain(previousPayments)
				.map('grossPayment')
				.reduce((sum: number, num: number) => new Decimal(sum).plus(num).toNumber())
				.value() || 0;

			// this is us allocating previous gross payments before distributing subsequent
			totalAllocation = new Decimal(totalAllocation).sub(previousGross).toNumber();

			// only update editable payment amounts
			const editablePaymentIDs: number[] = _.chain(subsequentPayments)
				.filter(subsequentPayment => this.paymentAmountIsEditable(bill, subsequentPayment))
				.map('paymentID')
				.value();

			if(editablePaymentIDs.length && totalAllocation >= 0) {
				//allocate payment amounts that can't be edited
				_.forEach(subsequentPayments, (subsequentPayment) => {
					if(!this.paymentAmountIsEditable(bill, subsequentPayment)) {
						totalAllocation = new Decimal(totalAllocation).sub(subsequentPayment.grossPayment).toNumber();
					}
				});

				const evenDist: number[] = this.divide(totalAllocation, editablePaymentIDs.length);

				_.forEach(editablePaymentIDs, (paymentId, i) => {
					const idx = _.findIndex(bill.payments, {paymentID: paymentId});

					bill.payments[idx].grossPayment = evenDist[i];
					this.paymentPropChanged(bill.payments[idx]);
				});
			} else {
				// if no editable payments or total allocation is negative, transfer change to bill amount and notify the user
				this._setBillAmountToSumOfPayments(bill);
			}
		} else {
			const firstPayment = bill.payments[0];

			if (paymentIdx === 1 && bill.payments.length === 2 && this.notProcessed(firstPayment)) {
				if (totalAllocation < 0) {
					this._setBillAmountToSumOfPayments(bill);
				} else if(this.paymentAmountIsEditable(bill, firstPayment)) {
					firstPayment.grossPayment = totalAllocation;
					this.paymentPropChanged(firstPayment);
				} else {
					this._setBillAmountToSumOfPayments(bill);
				}
			} else if (this._isTransmitIncomplete(payment)) {
				this._setBillAmountToSumOfPayments(bill);
			}
			else {
				payment.grossPayment = payment.paymentAmount = this.tempVals.grossPayment;

				this.toastr.error('Error!', 'No unprocessed subsequent payments');
			}
		}

	}

	notProcessed(payment: Payment): boolean {
		return !payment.processedDate;
	}

	showAdd(bill: Bill): boolean {
		return this.billPropertyIsEditable(bill) && !this.isDocumentProcessing;
	}

	showClose(bill: Bill, payment: Payment): boolean {
		return this.editState.editMode && !payment.collectorPaymentID && this.billPropertyIsEditable(bill) && !this.isDocumentProcessing;
	}

	disablePaymentOptionDropdown(bill: Bill): boolean {
		return _.some(bill.payments, (payment: Payment) => payment.processedDate || (payment.taskSummary && payment.taskSummary.TaxBillReceived && payment.taskSummary.ReviewPaymentComplete));
	}

	disableAssocRevisionDropdown(bill: Bill): boolean {
		// return !this._atLeastOnePaymentHasAdjustNotice(bill) && (+bill.status !== 0 || (this.billHasTaskSummary(bill) && (allSeriesComplete || allReviewComplete)));    turning off until we turn it on
		return +bill.status !== 0 && (this.billHasTaskSummary(bill) && this._atLeastOnePaymentTransmitted(bill));
	}

	addPayment(bill: Bill) {
		const newPayment = new Payment();

		newPayment.billID = bill.billID;
		newPayment.dueDate = moment().startOf('day').toDate();

		bill.payments.push(newPayment);

		this.editState.setDirty(true);
	}

	zeroIfNull(payment: Payment, field: string): void {
		if (payment[field] === null || payment[field] === undefined || isNaN(payment[field])) {
			payment[field] = 0;

			this.paymentPropChanged(payment);
		}
	}

	paymentAmountChanged(): void {
		this.editState.staleAppealSavings = true;
		this.editState.refreshGrid = true;

		//This recalcuates totals for the annual year
		// this.calcTaxTotals();
	}

	paymentAmountBlurred(bill: Bill, payment: Payment): void {
		this.zeroIfNull(payment, 'paymentAmount');

		payment.grossPayment = payment.paymentAmount;

		this.grossPaymentChanged(bill, payment);
		//This recalcuates totals for the annual year
		// this.calcTaxTotals();
	}

	revertIfNegative(payment: Payment, field: string): void {
		if (this.billClusterService.calcPaymentAmount(payment) < 0) {
			payment[field] = this.tempVals[field];

			this.toastr.error('Error!', 'Payment Amount Cannot be negative');
		}

		this.paymentPropChanged(payment);
	}

	penaltyOrInterestAmountChanged(payment: Payment, field: string) {
		this.zeroIfNull(payment, field);
		this.revertIfNegative(payment, field);
	}

	async paymentOptionChanged(bill: Bill): Promise<void> {
		const result = await this.messageBoxService.open({
			message: 'This will save the current state of the Bill',
			buttons: MessageBoxButtons.OKCancel
		});

		switch (result) {
			case MessageBoxResult.OK:
				this.editState.validationHandler(async (isValid, errorMessage) => {
					if (!isValid) {
						this.editState.validationMessage = errorMessage;

						// HACK need setTimeout for some reason as when the 'show'
						// is called the validationMessage is not set unless we have the timeout
						setTimeout(() => {
							// TODO this validation tooltip is not available in the
							// annual details service. Need to figure out how to set it
							//
							// editState.aDValidation.show();
							//
							// For now, console.warn:
							this.toastr.error(errorMessage);
						});
						return;
					}

					this.editState.entityLoading = true;

					try {
						this.AnnualDetailsSaveAllowedChanged.emit(false);
						const tempBillAmount = bill.billAmount;
						const savedBill = await this.billClusterService.saveOneBill(bill);

						if (!this.isDocumentProcessing) {
							this.taxesViewModel.resetEdit(true);
						}

						const savedBillIdx = _.findIndex(this.viewModel.model, { billID: savedBill.billID });
						this.viewModel.model[savedBillIdx] = this.billClusterService.getBillForView(savedBill);
						this._getTaskSummariesForBill(this.viewModel.model[savedBillIdx]);

						if (!this.viewModel.model[savedBillIdx].calcProjected) {
							this.viewModel.model[savedBillIdx].billAmount = tempBillAmount;
							this._distribute(this.viewModel.model[savedBillIdx]);
						}

						this.showExtraFields = this.billClusterService.getShowExtraFields([savedBill]);

						this.AnnualDetailsSaveAllowedChanged.emit(true);
					} finally {
						this.editState.entityLoading = false;
					}

				});
				break;

			case MessageBoxResult.Cancel:
				this.editState.cancelHandler();
				break;
		}
	}

	launchTaskModalBill(bill: Bill) {
		if (!this.editState.editMode || this.isDocumentProcessing) {
			this.taskService.launchTaskModal(bill.billID, TAXBILL_ENTITY_TYPE_ID, true);

			return;
		}

        this.autoSaveAndPerformAction([bill], showToast => this.launchTaskModalAndReload(bill.billID, showToast));
    }

    autoSaveAndPerformAction(bills: Bill[], action: (showToast: boolean) => void) {
		if (!this.editState.editMode || !this.editState.getDirty() || this.isDocumentProcessing) {
            action(false);
			return;
		}

		this.editState.validationHandler((isValid, errorMessage) => {
			if (!isValid) {
				this.editState.validationMessage = errorMessage;

				// HACK need setTimeout for some reason as when the 'show'
				// is called the validationMessage is not set unless we have the timeout
				setTimeout(() => {
					// TODO this validation tooltip is not available in the
					// annual details service. Need to figure out how to set it
					//
					// editState.aDValidation.show();
					//
					// For now, console.warn:
					this.toastr.error(errorMessage);
				});
				return;
			}

            this.AnnualDetailsSaveAllowedChanged.emit(false);
            Promise.all(bills.map(bill => {
                return this.billClusterService.saveOneBill(bill).then((savedBill: Bill) => {
                    if (!this.isDocumentProcessing) {
                        this.taxesViewModel.resetEdit(true);
                    }

                    const savedBillIdx = _.findIndex(this.viewModel.model, { billID: savedBill.billID });
                    this.viewModel.model[savedBillIdx] = this.billClusterService.getBillForView(savedBill);
                    return this._getTaskSummariesForBill(this.viewModel.model[savedBillIdx]);
                }).then(() => {
                    this.editState.setDirty(false);
                });
            })).then(() => {
                action(true);
                this.AnnualDetailsSaveAllowedChanged.emit(true);
            });
		});
    }

	async launchTaskModalAndReload(billID: number, showToast: boolean): Promise<void> {
		const toastMessage = showToast ? 'Bill changes autosaved' : '';

		const shouldReload = await this.taskService.launchTaskModal(billID, TAXBILL_ENTITY_TYPE_ID, false, toastMessage);

		if (!shouldReload) {
			return;
		}

		this.reloadOnModalClose(billID);
    }

    async reloadOnModalClose(billID?: number) {
		this.AnnualDetailsSaveAllowedChanged.emit(false);
		this.editState.showEditControls = false;
		this.editState.entityLoading = true;
		this.editState.refreshGrid = true;
		this.navigationEvent.refreshActivityData();
		this.navigationEvent.refreshAnnualYear();

		const updateSingleBill = async (id: number) => {
			const savedBill = await this.billClusterService.getOneBill(id);
			this.updateSavedBill(savedBill);
			this.taxesViewModel.currentTab.rowVersion = savedBill.billClusterRowVersion;
		};
		if (billID) {
			await updateSingleBill(billID);
		}
		else {
			for (let i = 0; i < this.viewModel.model.length; i++) {
				await updateSingleBill(this.viewModel.model[i].billID);
			}
		}

		this.editState.entityLoading = false;
		this.editState.showEditControls = true;
		this.AnnualDetailsSaveAllowedChanged.emit(true);
    }

	getCommentModalData(bill) {
		const commentModalData = new CommentModalData();
		commentModalData.entityID = bill.billID;
		commentModalData.entityTypeID = TAXBILL_ENTITY_TYPE_ID;
		commentModalData.canEdit = this.viewModel.hasWritePermission;
		commentModalData.parcelID = this.viewModel.parcelID;
		commentModalData.parcelAcctNum = this.viewModel.parcelAcctNum;
		commentModalData.description = `${this.viewModel.year  } - ${  this.viewModel.tabTitle  } ${  bill.name === null ? 'Bill' : bill.name}`;
		commentModalData.year = this.viewModel.year.toString();

		return commentModalData;
	}

	getAttachmentModalData(bill) {
		const attachmentModalData = new AttachmentModalData();

		attachmentModalData.belowParcelEntity = new AttachmentModalEntityData();
		attachmentModalData.belowParcelEntity.id = bill.billID;
		attachmentModalData.belowParcelEntity.typeId = TAXBILL_ENTITY_TYPE_ID;
		attachmentModalData.belowParcelEntity.name = bill.name;
		attachmentModalData.entityType = 'Tax Bill';
		attachmentModalData.parentId = this.viewModel.parcelID;
		attachmentModalData.parentType = 'Parcel';
		attachmentModalData.entityName = this.viewModel.parcelAcctNum;
		attachmentModalData.entityDescription = `${this.viewModel.year  } - ${  this.viewModel.tabTitle  } ${  bill.name === null ? 'Bill' : bill.name}`;
		attachmentModalData.year = this.viewModel.year;

		return attachmentModalData;
	}

	billPropertyIsEditable(bill: Bill): boolean {
		return this.editState.editMode
			&& !bill.calcProjected
			&& (!bill.status || !this._atLeastOnePaymentTransmitted(bill));
	}

	paymentAmountIsEditable(bill: Bill, payment: Payment): boolean {
		return this.editState.editMode
			&& !bill.calcProjected
			&& !this._atLeastOnePaymentTransmitted(bill)
			&& bill.payments.length > 1;
	}

	paymentPropertyIsEditable(bill: Bill, payment: Payment): boolean {
		return this.editState.editMode
			&& !bill.calcProjected
			&& this._isTransmitIncomplete(payment);
	}

	paymentOptionIsEditable(bill: Bill): boolean {
		return this.editState.editMode
			&& bill.collectorPaymentOptions.length > 1
			&& (!bill.status || !this._atLeastOnePaymentTransmitted(bill))
			&& !this.isDocumentProcessing;
	}

	billHasTaskSummary(bill: Bill): boolean {
		return _.some(bill.payments, (payment: Payment) => !_.isEmpty(payment.taskSummary));
	}

	changeActual(bill: Bill): void {
		bill.status = bill.status ? 0 : 1;

		if (bill.status && !bill.annualAssessmentID) {
			bill.annualAssessmentID = _.maxBy(this.viewModel.yearRevisions, 'revisionNum').annualAssessmentID;
		}
	}

	revertDirectAsmtIfInvalid(bill: Bill): void {
		let revert: boolean = false;

		if (bill.directAsmt === undefined || bill.directAsmt === null || isNaN(bill.directAsmt)) {
			bill.directAsmt = 0;
		}

		if (bill.directAsmt > bill.billAmount) {
			this.toastr.error('Cannot exceed Bill Amount!');
			revert = true;
		}

		if (revert) {
			bill.directAsmt = this.tempVals.directAsmt;
		}
	}

	maybeRevertToOriginalDate(payment: Payment, field: string): void {
		if (!payment[field]) {
			payment[field] = this._getOriginalDate(payment, field);
		}
	}

	async calcProjectedChanged(bill: Bill): Promise<void> {
		if (!bill.calcProjected) {
			return;
		}

		if (this.isDocumentProcessing) {
			bill.calcProjected = false;

			this.messageBoxService.open({
				message: 'Please Refresh instead.',
				buttons: MessageBoxButtons.OK
			});

			return;
		}

		const result = await this.messageBoxService.open({
			title: 'WARNING',
			message: 'Enabling auto calculate will force the bill to be saved. Do you wish to continue?',
			buttons: MessageBoxButtons.OKCancel
		});

		switch (result) {
			case MessageBoxResult.OK:
				this.editState.validationHandler(async (isValid, errorMessage) => {
					if (!isValid) {
						this.editState.validationMessage = errorMessage;

						// HACK need setTimeout for some reason as when the 'show'
						// is called the validationMessage is not set unless we have the timeout
						setTimeout(() => {
							// TODO this validation tooltip is not available in the
							// annual details service. Need to figure out how to set it
							//
							// editState.aDValidation.show();
							//
							// For now, console.warn:
							this.toastr.error(errorMessage);
						});
						return;
					}

					this.AnnualDetailsSaveAllowedChanged.emit(false);
					this.saveBillCluster.emit(() => {
						this.AnnualDetailsSaveAllowedChanged.emit(true);
					});
				});

				break;
		}
	}

	async updateSavedBill(bill: Bill): Promise<void> {
		const savedBillIdx = _.findIndex(this.viewModel.model, { billID: bill.billID });
		this.viewModel.model[savedBillIdx] = this.billClusterService.getBillForView(bill);
		await this._getTaskSummariesForBill(this.viewModel.model[savedBillIdx]);

		if (this.billHasTaskSummary(bill) && !bill.annualAssessmentID && _.some(bill.payments, 'taskSummary.TaxBillReceived')) {
			this.viewModel.model[savedBillIdx].annualAssessmentID = _.maxBy(this.viewModel.yearRevisions, 'revisionNum').annualAssessmentID;

			this.billClusterService.saveOneBill(this.viewModel.model[savedBillIdx]).then((updatedBill) => {
				this.viewModel.model[savedBillIdx].rowVersion = updatedBill.rowVersion;
				this.toastr.info('Bill autosaved with latest revision');
			});
		}

		if (!this.isDocumentProcessing) {
			this.taxesViewModel.resetEdit(true);
		}
	}

	showExcludeFromAccruals(bill: Bill): boolean {
        if (bill === null) {
            return _.some(this.viewModel.model, b => this.showExcludeFromAccruals(b));
        }

        // TODO: It appears taxesViewModel is always missing in document processing. For now that's OK; just don't show this checkbox in that
        // case, but this needs some investigation.
        if (!this.taxesViewModel || bill.payments.length === 0) {
			return false;
		}

		const bc = this.taxesViewModel.model.find(x => x.billClusterID === bill.billClusterID);
		return bc ? bc.accrualsEnabled : false;
	}

	excludeFromAccrualsChanged(): void {
		this.setDirty();
		this.navigationEvent.refreshAccrualDetailsLink(this.billClusterService.areAllPaymentsExcludedFromAccruals(this.viewModel.model));
	}

	setDirty(): void {
		this.editState.setDirty(true);
		this.editState.refreshGrid = true;

		if(this.isDocumentProcessing) {
			const eventName = this._atLeastOnePaymentinQC() ? 'edit-document-amount-qc' : 'edit-document-amount-dp';
			this.productAnalyticsService.logEvent(eventName);
		}
	}

	showActualCheckbox(bill: Bill): boolean {
		if (bill.payments.length == 0)
			return false;

		return !this.billHasTaskSummary(bill);
	}

	showAutoCalculateCheckbox(bill: Bill): boolean {
		return !bill.status && bill.collectorPaymentOptionID !== undefined && bill.collectorPaymentOptionID !== null;
	}

	showActualCheckboxRow(): boolean {
		return this.viewModel && _.some(this.viewModel.model, (bill: Bill) => this.showActualCheckbox(bill));
	}

	showAutoCalculateCheckboxRow(): boolean {
		return this.viewModel && _.some(this.viewModel.model, (bill: Bill) => this.showAutoCalculateCheckbox(bill));
	}

	showAssocRevision(): boolean {
		if (!this.viewModel.model) {
			return false;
		}

		const someBillsAreEditable: boolean = _.some(this.viewModel.model, (bill: Bill) => this.assocRevisionIsEditable(bill)) as boolean;
		const someBillsHaveAssocRevision: boolean = _.some(this.viewModel.model, (bill: Bill) => bill.annualAssessmentID) as boolean;

		return someBillsAreEditable || someBillsHaveAssocRevision;
	}

	showSupplemental(): boolean {
		return this.stateIsSupplemental && !_.every(this.viewModel.model, 'collectorPaymentOptionID');
	}

	showProration(): boolean {
		return _.some(this.viewModel.model, 'isSupplemental');
	}

	assocRevisionIsEditable(bill: Bill): boolean {
		return this.editState.editMode && !bill.calcProjected;
	}

	getAssocRevisionDesc(bill: Bill): string {
		const yearRevision = _.find(this.viewModel.yearRevisions, { annualAssessmentID: bill.annualAssessmentID });

		if(yearRevision) {
			return yearRevision.revisionDesc;
		} else {
			console.warn('Cannot find year revision for Bill!');
			return '';
		}
	}

	async openPaymentStubModal(billId: number, payment: Payment): Promise<void> {
		await this.annualDetailsService.preNavigateWarn(this.editState);

		const params = {billId, payment};
        const { saved, attachmentId } = await this.modalService.showAsync(BillImageModalComponent, params, 'modal-lg');

		if(saved) {
			payment.attachmentID = attachmentId;
		}

	}

	async openParcelCollectorAddressModal(billClusterId: number, payment: Payment) {
		try {
			const collectorEntityAddresses = await this.billClusterService.getCollectorEntityAddressesForBillCluster(billClusterId);
			const taxPaymentCollectorEntityAddresses = collectorEntityAddresses.length != 0
					? collectorEntityAddresses.filter(x => x.correspondenceTypes?.some(y => y.correspondenceTypeID == Core.CorrespondenceTypes.TaxPayment))
					: [];

            const result = await this.addressDetailsModalLaunchService.openAddressDetailsModal(
				payment.paymentID,
                Core.EntityTypes.Payment,
                _.cloneDeep(taxPaymentCollectorEntityAddresses),
                this.editState.editMode,
                false,
                true
            );

            if (this.editState.editMode) {
                //save address
            }
        } catch (err) {
            if(err.error?.message) {
                this.toastr.error(err.error.message);
            }
        }
	}

	openTransmittalDetailsModal(payment: Payment) {
		this.paymentPackagesModal.show(payment.paymentID);
	}

	prorationChange(bill: Bill, e): void {
		this.setDirty();

		bill.prorationPct = new Decimal(e)
			.dividedBy(100)
			.toSignificantDigits(6)
			.toNumber();
    }

    openTaxRateSetup() {
        if (this.viewModel && this.viewModel.model && this.viewModel.model[0]) {
            this.autoSaveAndPerformAction(this.viewModel.model, showToast => {
                if (showToast) {
                    this.toastr.info('Bill changes autosaved');
                }
                const billClusterID = this.viewModel.model[0].billClusterID;
                let currentBill: Bill = null;
                if (this.isDocumentProcessing) {
                    currentBill = this.viewModel.model[0];
                }
                this.taxRateSetupModalLaunchService.openBillClusterTaxRateDetailsModal(
                    billClusterID,
                    this.viewModel.model[0].billClusterRowVersion,
                    this.viewModel.tabTitle,
                    !this.viewModel.model.some(b => !!b.status),
                    !this.editState.editMode,
                    this.isDocumentProcessing,
                    this.viewModel.processingTaxSetupResult,
                    this.processingParcelTaxSetup,
                    currentBill,
                    this.viewModel.intakeAttachmentId,
                    currentBill ? currentBill.calcProjected : null,
                    this.hasProcessingChanges,
                    this.firstEncounterSavedCallback
                ).subscribe(result => {
                    // If this is document processing, nothing was saved in the modal, so there is a lot
                    // less to handle.
                    if (this.isDocumentProcessing) {
                        this.viewModel.processingTaxSetupResult = result;
                        this.setDirty();
                        this.calculateShowFirstEncounterIndicator();
                    }
                    else {
                        // Adding this years after the bill component was written; we want to update the
                        // bill cluster row version for any model that has the same bill cluster ID. Given
                        // how this is called, I suspect this isn't necessary (I probably could have just
                        // updated this.viewModel.model[0]), but I'm not sure, so this seemed safer.
                        this.viewModel.model
                            .filter(m => m.billClusterID === billClusterID)
                            .forEach(m => m.billClusterRowVersion = result.rowVersion);
                        // We also have the row version here
                        this.taxesViewModel.model
                            .filter(m => m.billClusterID === billClusterID)
                            .forEach(m => m.rowVersion = result.rowVersion);

                        // We've also potentially changed the BillCluster itself, so update the relevant
                        // property (in this case "taxRateAreaId").
                        this.taxesViewModel.model.filter(m => m.billClusterID === billClusterID).forEach(m => {
                            m.rowVersion = result.rowVersion;
                            m.taxRateAreaId = result.taxRateAreaId;
                        });

                        this.taxesViewModel.preEditModelBackup.rowVersion = result.rowVersion;
                        this.taxesViewModel.preEditModelBackup.taxRateAreaId = result.taxRateAreaId;

                        // All of that only dealt with the bill cluster; it's also possible that a bill changed.
                        // Reload all the bills in that case.
                        this.reloadOnModalClose();
                    }
                });
            });
        }
    }

    undoDate(payment: Payment, field: string) {
        payment[field] = this._getOriginalDate(payment, field);
		payment.dueDateChanged = false;
        this.setDirty();
    }

	dueDateIsInPast(dueDate): boolean {
        return moment(dueDate).isBefore(moment());
    }

    dueDateIsLaterThanOriginal(payment: Payment): boolean {
        return moment(payment.dueDate).isAfter(payment.originalDueDate);
    }

    get hasExemptions(): boolean {
        return this.viewModel.model.some(x => x.payments.some(y => y.exemptionAmount));
    }

    getPaymentBatchDetailsSref(payment: Payment) {
		// Return sref  Payment Batch Details page in another tab for the BelongsToPaymentBatchId
		console.log(`openPaymentBatchDetails(${payment.paymentID}, ${payment.belongsToPaymentBatchId}, ${payment.belongsToPaymentBatchNo})`);
        return {
            target: 'paymentBatchDetails',
            options: {
                paymentBatchId: payment.belongsToPaymentBatchId
            }
        };
    }


	private _getOriginalDate(payment: Payment, field: string): Date {
		const capitalizedField: string = field.charAt(0).toUpperCase() + field.slice(1);

		return payment[`original${  capitalizedField}`];
	}

	private _getDiscount(payment: Payment): number {
		if (payment.collectorPayment && payment.collectorPayment.discountPercent) {
			const discount = new Decimal(payment.grossPayment)
				.times(payment.collectorPayment.discountPercent)
				.times(100)
				.round()
				.dividedBy(100)
				.toNumber();

			return -Math.abs(discount);
		} else {
			return 0;
		}
	}

	// When a bills Gross Bill Amt is changed, distribute the amount over the bills payments
	private _distribute(bill: Bill): void {
		// Separate bill's payments by processed and unprocessed
		const payments = _.groupBy(bill.payments, (payment: Payment) => {
			return this.notProcessed(payment) ? 'unprocessed' : 'processed';
		});

		// if no unprocessed, no work to be done
		if (!payments.unprocessed) {
			return;
		}

		// Get the sum of the processed Gross payment amounts
		const totalProcessedAmount = _.reduce(payments.processed, (result: number, payment: Payment) => {
			return new Decimal(result).plus(payment.grossPayment).toNumber();
		}, 0);

		// Get an array of Gross payment amounts (distribution)
		// by dividing remaining amount by the number of unprocessed
		// payments
		const remainingAmount = new Decimal(bill.billAmount).minus(totalProcessedAmount).toNumber();
		const evenDist = this.divide(remainingAmount, payments.unprocessed.length);

		// Assign each unprocessed payment their amounts by using the distributed array.
		bill.payments = _.map(bill.payments, (payment: Payment, idx: number): Payment => {
			payment.grossPayment = evenDist[idx];
			payment.discountAmount = this._getDiscount(payment);
			payment.penaltyAmount = 0;
			payment.interestAmount = 0;
			payment.paymentAmount = new Decimal(payment.grossPayment).plus(payment.discountAmount).toNumber();

			return payment;
		});

		// recalcute total and effective rate to the right of Bills
		//This recalcuates totals for the annual year
		// this.calcTaxTotals();
	}

	private _setBillAmountToSumOfPayments(bill: Bill): void {
		bill.billAmount = _.reduce(bill.payments, (sum, payment) => sum + (payment.grossPayment || 0), 0);

		this.toastr.info('Overall Bill Amount has been updated');
	}

	private _allocationIsNegative(payment: Payment): void {
		payment.grossPayment = payment.paymentAmount = this.tempVals.grossPayment;

		this.toastr.error('Error!', 'Total Allocation cannot be negative');
	}

	private _atLeastOnePaymentTransmitted(bill: Bill): boolean {
		return _.some(bill.payments, x => x.taskSummary && x.taskSummary && x.taskSummary.TransmitComplete);
	}

	private _atLeastOnePaymentinQC(): boolean {
		return _.some(this.viewModel.model, x => {
			return _.some(x.payments, ({taskSummary}) => taskSummary && taskSummary.isQCed && !taskSummary.QCTaxBillComplete);
		});
	}

	private async _getTaskSummariesForBill(bill: Bill): Promise<any> {
		return this.billClusterService.getTaskSummariesForBill(bill)
			.then(() => {
				this.showActualRow = this.showActualCheckboxRow();
				bill.showActualCheckbox = this.showActualCheckbox(bill);
			});
	}

	private _isTransmitIncomplete(payment: Payment): boolean {
		return !payment.paymentID
			|| _.isEmpty(payment.taskSummary)
			// || (!payment.taskSummary.TransmitComplete || payment.taskSummary.AdjustPaymentIsReady)   turning off until turning on
			|| !payment.taskSummary.TransmitComplete;
	}

	private _atLeastThreeIncompleteTransmitPayments(bill: Bill): boolean {
		const paymentCount: number = _.reduce(bill.payments, (count: number, payment: Payment) => {
			if (this._isTransmitIncomplete(payment)) {
				count++;
			}

			return count;
		}, 0);

		return paymentCount > 2;
	}

	// private _atLeastOnePaymentHasAdjustNotice(bill: Bill): boolean {
	// 	return _.some(bill.payments, payment => !_.isEmpty(payment.taskSummary) && payment.taskSummary.AdjustPaymentIsReady);              turning off until turning on
	// }

	// leftover from angular 2 implementation.
	// Might need this again
	// updatePaymentAttachmentID(attachmentID: string): void {
	// 	this.paymentStubParams.payment.attachmentID = attachmentID;
	// }
}
