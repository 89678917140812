import {Component, OnInit} from '@angular/core';
import { TaxRateService } from '../tax.rate.service';
import { Constants, TaxAuthorityStatuses } from '../../constants.new';
import { TaxAuthoritiesOrderBy, TaxRateAreaPayload, TaxRateAreaCollector, PanelProjectionsPayload } from '../tax.rate.model';
import { Address } from '../../Common/Models/common.model';
import { TaxAuthority, TaxAuthorityDetail, TaxRateAreaTaxAuthorityDetail } from '../../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { ToastrService } from 'ngx-toastr';
import { AttachmentModalData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { TimerService } from '../../UI-Lib/Utilities';
import { BusyIndicatorRef, BusyIndicatorService } from '../../Busy-Indicator';
import { Subject, takeUntil } from 'rxjs';
import { FeatureFlagsService } from 'src/app/Common/FeatureFlags/feature-flags-service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'tax-rate-area',
    templateUrl: './tax.rate.area.component.html',
    styleUrls: ['./tax.rate.area.component.scss']
})
export class TaxRateAreaComponent implements OnInit {
    constructor(private readonly _taxRateService: TaxRateService,
                private readonly _constants: Constants,
                private readonly _toastr: ToastrService,
                private readonly _restrictService: RestrictService,
                private readonly _messageBoxService: MessageBoxService,
                public readonly busyIndicatorService: BusyIndicatorService,
                private readonly _featureFlagsService: FeatureFlagsService,
                public bsModalRef: BsModalRef,
                private readonly _timer: TimerService) {
            this.canEdit = _restrictService.isInRole(Roles.TAXRATESETUP);
    }

    canEdit: boolean = true;
    hideAddCollectorButton = false;

    showTaxYearOptions: number[];
    taxYearBeginEndOptions: number[];
    priorOptions: number[];
    orderBy: TaxAuthoritiesOrderBy = 'alpha';
    groupByCategory: boolean = false;
    taxYearBegin: number;
    taxYearsPrior: number;
    globalYearEnd: number;

    years: number[];

    taxRateArea: TaxRateAreaPayload;
    originalTaxRateArea: TaxRateAreaPayload;
    clonedTaxRateArea: TaxRateAreaPayload;

    saving: boolean = false;
    loadingProjections: boolean = false;
    cloning: boolean = false;

    // Data from Tax Rates Panel
    editMode: boolean;
    taxRateAreaId: number;
    onClose: any;
    busyRefId: string = this.busyIndicatorService.generateUniqueMessageIdentifier();

    private _busyRef: BusyIndicatorRef;
    private _destroy$: Subject<void> = new Subject();

    async ngOnInit() {
        const currentYear = Number(moment().format('YYYY'));
        this.showTaxYearOptions = _.range(currentYear + 5, currentYear - 10);
        this.taxYearBeginEndOptions = _.range(currentYear + 5, currentYear - 10);
        this.priorOptions =  [1, 2, 3, 4];
        this.taxYearBegin = currentYear;
        this.taxYearsPrior = 2;
        this.showTaxYearChanged();

        const taxRateArea = await this._taxRateService.getTaxRateArea(this.taxRateAreaId);
        this._initTaxRateArea(taxRateArea);
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.taxRateArea) {
            return null;
        }

        return {
            entityType: 'TaxRateArea',
            entityName: this.taxRateArea.name,
            entityData: {
                typeId: Core.EntityTypes.TaxRateArea,
                id: this.taxRateArea.taxRateAreaId,
                name: this.taxRateArea.name
            },
            readOnly: !this.canEdit
        } as AttachmentModalData;
    }
    get commentsModel(): CommentModalData {
        if (!this.taxRateArea) {
            return null;
        }

        return {
            entityID: this.taxRateArea.taxRateAreaId,
            entityTypeID: Core.EntityTypes.TaxRateArea,
            description: this.taxRateArea.name,
            canEdit: this.canEdit
        } as CommentModalData;
    }

    goToEditMode() {
        this.originalTaxRateArea = _.cloneDeep(this.taxRateArea);
        this.editMode = true;
    }

    cancel() {
        if (this.originalTaxRateArea) {
            this.taxRateArea = _.cloneDeep(this.originalTaxRateArea);
        }
        this.editMode = false;
    }

    getCategory(taxAuthorityCategoryId: number): string {
        return taxAuthorityCategoryId ?  this._constants.TaxAuthorityCategories[taxAuthorityCategoryId].displayName : '';
    }

    getYearRate(taxAuthority: TaxAuthority, tratdDetail: TaxRateAreaTaxAuthorityDetail, year: number): number | string {

        if (tratdDetail.yearBegin && year < tratdDetail.yearBegin  )
            return null;

        if (tratdDetail.yearEnd && year > tratdDetail.yearEnd)
            return null;

        const yearDetail: TaxAuthorityDetail = _.find(taxAuthority.details, {taxYear: year});

        return yearDetail ? new Decimal(yearDetail.rate).times(100).toFixed(6) : null;
    }

    getTaxAuthotitiesSortedByName(taxAuthorities: TaxAuthority[]): TaxAuthority[] {
        return _.sortBy(taxAuthorities, 'name');
    }

    getTotalRateForDisplay(taxAuthorities: TaxAuthority[], year: number): string {
        const totalRate = this._getTotalRate(taxAuthorities, year);

        return this._isValidNumber(totalRate) ? new Decimal(totalRate).times(100).toFixed(6) : '';
    }

    getYearEstimated(taxAuthority: TaxAuthority, year: number): boolean {
        const yearDetail = _.find(taxAuthority.details, {taxYear: year});

        if (!yearDetail)
            return true;

        return !this._isDetailActual(yearDetail);
    }

    getTotalEstimated(taxAuthorities: TaxAuthority[], year: number): boolean {
        return _.some(taxAuthorities, taxAuthority => {
            return this._nonDeletedDetails(taxAuthority).length
                && _.some(taxAuthority.details, yearDetail =>
                    yearDetail.taxYear === year && !this._isDetailActual(yearDetail)
                );
        });
    }

    sortData(): void {
        window['groupByCategory'] = this.groupByCategory;

        //console.log('sorting ', this.taxRateArea);
        this.taxRateArea.collectors = _.map(this.taxRateArea.collectors, (collector: TaxRateAreaCollector) => {

            //console.log('processing collector ', collector);
            collector.taxAuthorities.sort((a: TaxAuthority, b: TaxAuthority) => {
                if (window['groupByCategory']){
                    // sorting by category first
                    if (this.getCategory(a.taxAuthorityCategoryId) === this.getCategory(b.taxAuthorityCategoryId))
                        return a.name >= b.name ? 1: -1;
                    else
                        return this.getCategory(a.taxAuthorityCategoryId) > this.getCategory(b.taxAuthorityCategoryId) ? 1: -1;
                } else {
                    // ignoring category
                    return a.name >= b.name ? 1: -1;
                }
            });

            //console.log('collector.taxAuthorities has been sorted ', collector);

            // now sort taxAuthority.taxRateAreaTaxAuthorityDetails

            collector.taxAuthorities = _.map(collector.taxAuthorities, (taxAuthority: TaxAuthority) => {

                //console.log('processing taxAuthority ', taxAuthority);

                taxAuthority.taxRateAreaTaxAuthorityDetails.sort( (a: TaxRateAreaTaxAuthorityDetail, b: TaxRateAreaTaxAuthorityDetail) => {
                    const ae = a.yearEnd ? a.yearEnd: 9999;
                    const be = b.yearEnd ? b.yearEnd: 9999;

                    if (ae == be)
                        return 0;

                    return ae < be ? 1: -1;
                });

                //console.log('taxAuthority.taxRateAreaTaxAuthorityDetails has been sorted ', taxAuthority);
                return taxAuthority;
            });

            // collector.taxAuthorities = _.sortBy(collector.taxAuthorities, sortProperty)
            //console.log('we are done with collector ', collector);
            return collector;

        });
    }

    showTaxYearChanged(): void {
        this.years = _.range(this.taxYearBegin, this.taxYearBegin - this.taxYearsPrior - 1);
    }

    addTaxAuthority(taxAuthority: TaxAuthority) {
        taxAuthority.taxRateAreaTaxAuthorityDetails.push(new TaxRateAreaTaxAuthorityDetail(this.taxRateArea.taxRateAreaId, taxAuthority.taxAuthorityId));
        this.sortData();

        this._timer.setTimeout(() => {
            const table:any = document.getElementsByClassName('tra-table');
            table[0].scrollTop = table[0].scrollHeight;
        });
    }

    yearBeginChange(taxRateAreaTaxAuthorityDetail: TaxRateAreaTaxAuthorityDetail, taxAuthority: TaxAuthority) {
        if(taxRateAreaTaxAuthorityDetail.yearBegin > taxRateAreaTaxAuthorityDetail.yearEnd && taxRateAreaTaxAuthorityDetail.yearEnd !== null) {
            taxRateAreaTaxAuthorityDetail.yearEnd = taxRateAreaTaxAuthorityDetail.yearBegin;
        }

        this._taxYearChange(taxRateAreaTaxAuthorityDetail, taxAuthority);
    }

    yearEndChange(taxRateAreaTaxAuthorityDetail: TaxRateAreaTaxAuthorityDetail, taxAuthority: TaxAuthority) {
        if(taxRateAreaTaxAuthorityDetail.yearEnd < taxRateAreaTaxAuthorityDetail.yearBegin) {
            taxRateAreaTaxAuthorityDetail.yearBegin = taxRateAreaTaxAuthorityDetail.yearEnd;
        }

        this._taxYearChange(taxRateAreaTaxAuthorityDetail, taxAuthority);
    }

    removeTaxRateAreaTaxAuthorityDetail(taxAuthority: TaxAuthority, detail: TaxRateAreaTaxAuthorityDetail) {

        //console.log('removing detail', detail);

        if(detail.efAction == 'add') {
            taxAuthority.taxRateAreaTaxAuthorityDetails.splice(taxAuthority.taxRateAreaTaxAuthorityDetails.indexOf(detail), 1);
        } else {
            detail.efAction = 'delete';
        }
    }

    get shouldDisableAddCollector(): boolean {
        return _.every(this.taxRateArea.collectors, 'isTab');
    }

    get atLeastOneTab(): boolean {
        return _.some(this.taxRateArea.collectors, 'isTab');
    }

    addCollector(collector: TaxRateAreaCollector): void {
        collector.isTab = collector.active = true;
    }

    disableTaxRateArea(): void {
        this.taxRateArea.collectors = _.map(this.taxRateArea.collectors, collector => {
            collector.taxAuthorities = _.map(collector.taxAuthorities, taxAuthority => {
                taxAuthority.taxRateAreaTaxAuthorityDetails = _.map(taxAuthority.taxRateAreaTaxAuthorityDetails, detail => {
                    detail.yearEnd =  detail.yearEnd || this.globalYearEnd;
                    return detail;
                });

                return taxAuthority;
            });

            return collector;
        });

        this.taxRateArea.disabled = true;
        this.globalYearEnd = null;
    }

    async save() {
        if(this.taxRateArea.disabled && this._atLeastOneInfiniteTaxYearEnd()) {
            this._toastr.error('A disabled Tax Rate Area cannot have an Infinite Tax Year End');
            return;
        }

        if(this._atLeastOneTaxYearRangeOverlap()) {
            this._toastr.error('Tax year ranges cannot overlap for a Tax Authority');
            return;
        }

        if(!_.isEqual(_.omit(this.taxRateArea, 'collectors', 'yearRateSummaryList', 'details'), _.omit(this.originalTaxRateArea, 'collectors', 'yearRateSummaryList', 'details'))) {
            this.taxRateArea.efAction = 'update';
        }

        this.saving = true;

        try {
            const savedArea = await this._saveOrUpdateTaxRateArea(this.taxRateArea);
            this.taxRateArea = this._prepareData(savedArea);

            this.editMode = false;

            this.onClose(savedArea);
            this.bsModalRef.hide();
        } finally {
            this.saving = false;
        }
    }

    async closeModal() {
        if(this.editMode && !_.isEqual(_.omit(this.taxRateArea, 'collectors', 'yearRateSummaryList', 'details'), _.omit(this.originalTaxRateArea, 'collectors', 'yearRateSummaryList', 'details'))) {
            const result: number = await this._messageBoxService.open({
                message: 'Are you sure you want to cancel and discard all changes?',
                buttons: MessageBoxButtons.OKCancel
            });

            if (result === MessageBoxResult.OK) {
                if(this.clonedTaxRateArea) {
                    this.onClose(this.clonedTaxRateArea);
                }

                this.bsModalRef.hide();
            }
        } else {
            if(this.clonedTaxRateArea) {
                this.onClose(this.clonedTaxRateArea);
            }

            this.bsModalRef.hide();
        }
    }

    async delete(): Promise<void> {
        const result: number = await this._messageBoxService.open({
            message: `Are you sure you want to delete ${this.taxRateArea.name}?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if (result === MessageBoxResult.OK) {
         if (this._featureFlagsService.featureFlags.enableTaxRateAreaTaxAuthorityBackgroundProcess){
            this._showBusyIndicator('Tax Rate Area', 'Deleting Tax Rate Area', null, false, true);
            try {
                const longRunningProcessId = await this._taxRateService.deleteTaxRateArea(this.taxRateArea.taxRateAreaId);
                await this._busyRef.setLongRunningProcessId(longRunningProcessId);
            }
            catch (e) {
                await this._hideBusyIndicator();
                return Promise.reject(e);
            }
            this.bsModalRef.hide();
            return Promise.resolve(); }
            else {
             await this._taxRateService.deleteTaxRateArea(this.taxRateArea.taxRateAreaId);
             this.onClose();
             this.bsModalRef.hide();
             }

    }

    }


    shouldUseDropup(taxAuthorities: TaxAuthority[]) {
        const taLength = _.reduce(taxAuthorities, (sum, taxAuthority) => {
            return sum + _.reduce(taxAuthority.taxRateAreaTaxAuthorityDetails, (sum2, detail) => {
                return detail.efAction === 'delete' ? sum2 : sum2 + 1;
            }, 0);
        }, 0);

        return taLength > 4 || (taLength > 2 && taxAuthorities.length > 4);
    }

    async clone() {
        this.cloning = true;

        this.clonedTaxRateArea = await this._taxRateService.cloneTaxRateArea(this.taxRateArea.taxRateAreaId);

        this._initTaxRateArea(_.cloneDeep(this.clonedTaxRateArea));
        this.goToEditMode();

        this.cloning = false;
    }

    private async _taxYearChange(taxRateAreaTaxAuthorityDetail: TaxRateAreaTaxAuthorityDetail, taxAuthority: TaxAuthority): Promise<void> {
        this.loadingProjections = true;

        try {
            taxRateAreaTaxAuthorityDetail.efAction = taxRateAreaTaxAuthorityDetail.efAction || 'update';

            const panelProjectionsRequestPayload: PanelProjectionsPayload = {
                taxRateAreaId: taxRateAreaTaxAuthorityDetail.taxRateAreaId,
                collectorId: taxAuthority.collectorId,
                taxAuthorityId: taxAuthority.taxAuthorityId,
                taxAuthorityDefaultAnnualRateIncrease: taxAuthority.defaultAnnualRateIncrease,
                details: taxAuthority.details,
                taxRateAreaTaxAuthorityDetails: taxAuthority.taxRateAreaTaxAuthorityDetails,
                yearBegin: taxRateAreaTaxAuthorityDetail.yearBegin,
                yearEnd: taxRateAreaTaxAuthorityDetail.yearEnd
            };

            const panelProjectionsResponsePayload = await this._taxRateService.getPanelProjections(panelProjectionsRequestPayload);
            taxAuthority.details = panelProjectionsResponsePayload.details;
        } finally {
            this.loadingProjections = false;
        }

    }

    private _prepareData(taxRateArea: TaxRateAreaPayload): TaxRateAreaPayload {
        taxRateArea.address = taxRateArea.address || new Address();
        taxRateArea.collectors = _.chain(taxRateArea.collectors)
            .map(collector => {
                collector.isTab =  _.some(collector.taxAuthorities, taxAuthority => taxAuthority.taxRateAreaTaxAuthorityDetails.length);

                return collector;
            })
            .sortBy('name')
            .value();

        if(taxRateArea.collectors.length) {
            if(taxRateArea.collectorId) {
                taxRateArea.collectors[0].isTab = true;
                taxRateArea.collectors[0].active = true;
                this.hideAddCollectorButton = true;
            } else {
                const firstActiveIdx: number = _.findIndex(taxRateArea.collectors, 'isTab');
                if(firstActiveIdx > -1) {
                    taxRateArea.collectors[firstActiveIdx].active = true;
                }
            }

        }

        return taxRateArea;
    }

    private _saveOrUpdateTaxRateArea(taxRateArea: TaxRateAreaPayload): Promise<TaxRateAreaPayload> {
        if(taxRateArea.taxRateAreaId) {
            return this._taxRateService.updateTaxRateArea(this.taxRateArea);
        } else {
            return this._taxRateService.createTaxRateArea(this.taxRateArea);
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this.busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            if (success) {
                this._refreshAndNotifyParent();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private _refreshAndNotifyParent() {
        this.onClose();
        //this.gridDataChanged.emit();
    }

    private _isValidNumber(value: string | number): boolean {
        return !(value === null || value === undefined || value === '' || value === '.');
    }

    private _getTotalRate(taxAuthorities: TaxAuthority[], year: number): number {

        //console.log('procesing taxAuthorities', taxAuthorities);

        let result = 0;
        for(let k = 0; k < taxAuthorities.length; k++){

            const taxAuthority = taxAuthorities[k];
            for(let i = 0; i < taxAuthority.taxRateAreaTaxAuthorityDetails.length; i++){
                if (
                    (!taxAuthority.taxRateAreaTaxAuthorityDetails[i].yearBegin || taxAuthority.taxRateAreaTaxAuthorityDetails[i].yearBegin <= year)
                    &&
                    (!taxAuthority.taxRateAreaTaxAuthorityDetails[i].yearEnd || taxAuthority.taxRateAreaTaxAuthorityDetails[i].yearEnd >= year)
                ){
                    const yearDetail = _.find(taxAuthority.details, {taxYear: year});
                    if (yearDetail)
                        result += new Decimal(yearDetail.rate).toNumber();
                }
            }
        }
        return result;
    }

    private _initTaxRateArea(taxRateArea: TaxRateAreaPayload) {
        this.taxRateArea = this._prepareData(taxRateArea);

        if(!this.taxRateArea.collectors.length && this.taxRateArea.taxRateAreaId) {
            this._toastr.info('No tax authorities configured for Collector');
        }

        console.log('this.taxRateArea', _.cloneDeep(this.taxRateArea));

        this.sortData();
    }

    private _nonDeletedDetails(taxAuthority: TaxAuthority): TaxRateAreaTaxAuthorityDetail[] {
        return _.reject(taxAuthority.taxRateAreaTaxAuthorityDetails, detail => detail.efAction == 'delete');
    }

    private _isDetailActual(yearDetail: TaxAuthorityDetail): boolean {
        return yearDetail.taxAuthorityStatusId == TaxAuthorityStatuses.Actual;
    }

    private _atLeastOneInfiniteTaxYearEnd(): boolean {
        return  _.chain(this.taxRateArea.collectors)
            .cloneDeep()
            .flatMap('taxAuthorities')
            .flatMap((taxAuthority: TaxAuthority) => this._nonDeletedDetails(taxAuthority))
            .some(['yearEnd', null])
            .value();
    }

    private _atLeastOneTaxYearRangeOverlap(): boolean {

        const numberOfProblematicEntries = _.chain(this.taxRateArea.collectors)
        .cloneDeep()
        //.filter({ isTab: true } )
        .flatMap('taxAuthorities')
        .map((taxAuthority: TaxAuthority) => this._nonDeletedDetails(taxAuthority))
        .map((detailArray: TaxRateAreaTaxAuthorityDetail[]) => {
            if (detailArray.length == 0) return 0;

            for(let i = 0; i < detailArray.length; i++){
                detailArray[i].yearBegin = detailArray[i].yearBegin || 1;
                detailArray[i].yearEnd = detailArray[i].yearEnd || 9999;
            }

            detailArray = _.sortBy(detailArray, o => o.yearBegin);

            for(let i = 0; i < detailArray.length; i++){
                if (i > 0 && (detailArray[i-1].yearBegin >= detailArray[i].yearBegin || detailArray[i-1].yearEnd >= detailArray[i].yearBegin))
                    return 1;
            }
            return 0;
        })
        .sum()
        .value();

        //console.log('numberOfProblematicEntries', numberOfProblematicEntries);

        return (numberOfProblematicEntries != 0);
    }

}
