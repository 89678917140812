<div id="delivery-details-assessment-allocation-settings">
    <div>
        <label>DR Team Retrieves</label>
        <div class="checkbox">
            <label>
                <input
                type="checkbox"
                [(ngModel)]="currentData.drTeamRetrievesWP"
                [disabled]="!allowEdit()">
                WP
            </label>
        </div>
    </div>
    <div>
        <div>
            <label>Allocation Reviewer</label>
        </div>
        <div class="allocation-reviewer-user" *ngIf="allowEdit()">
            <user-team-picker
                style="min-width: 300px;"
                [user]="currentData.allocationReviewerUser"
                [userId]="currentData.allocationReviewerUserID"
                [teamId]="currentData.allocationReviewerTeamID"
                [entityIdScope]="entity.id"
                [entityTypeScope]="entity.type"
                [instanceIdScope] = "userSearchInstanceId"
                (userChange)="userChanged($event)"
            >
            </user-team-picker>
            <button
                class="ace-btn has-icon btn-primary btn-danger"
                (click)="userChanged(undefined)">
                <i class="fa fa-trash"></i>
            </button>
        </div>
        <span
            *ngIf="!allowEdit()"
            ngClass="allocation-reviewer-user">
            {{getDisplayName(currentData.allocationReviewerUser, currentData.allocationReviewerTeam)}}
        </span>
    </div>
</div>
