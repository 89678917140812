import { Component, OnDestroy, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { PaymentBatchService } from '../../paymentBatch.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { ToastrService } from 'ngx-toastr';
import { AgGridMultiSelectTracker } from '../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { Subject, Subscription } from 'rxjs';
import { PaymentBatchAddPaymentAgGridDataSource, PaymentBatchAddPaymentDataSourceParams } from './agGridDataSource';
import { DecimalPipe } from '@angular/common';
import { PAYMENT_BATCH_ADD_PAYMENT_COLUMNS } from './paymentBatchAddPayment.columns';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { PAYMENT_BATCH_DETAILS_HELP } from '../paymentBatchDetails.component.help';
import * as moment from 'moment';
import { WeissmanKeyValueDisplayPipe } from 'src/app/UI-Lib/Pipes';
import { cloneDeep } from 'lodash';

@Component({
    selector: 'payment-batch-add-payments-modal',
    template: `
     <ws-modal-wrapper headerLabel="Add Payments"
            headerHelpContentId="payment-batch-details.add-payments-modal"
            (save)="save()"
            (cancel)="cancel()"
            [disableSave]="!selectedRowsModel?.selectedRows.length && !selectedRowsModel?.selectAllRows">
                <div class="d-flex align-items-center justify-content-between mt-4">
                    <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions"></ag-grid-row-count>
                    <div class="d-flex align-items-center gap-2">
                        <label class="text-nowrap me-2 mb-0">Due Date Range: </label>
                        <div class="input-group">
                            <weissman-datetime [(inputDate)]="dataSourceParams.fromDate"
                                            [dateOnly]="true"
                                            [timeZone]="'Central'"
                                            #startDatePicker>
                            </weissman-datetime>
                        </div>
                        <span class="mx-2">to</span>
                        <div class="input-group">
                            <weissman-datetime [(inputDate)]="dataSourceParams.toDate"
                                            [dateOnly]="true"
                                            [timeZone]="'Central'"
                                            [rangeEnd]="true"
                                            #endDatePicker>
                            </weissman-datetime>
                        </div>
                        <button class="ace-btn btn-sm btn-primary"
                                (click)="refresh()">
                            Refresh
                        </button>
                    </div>
                </div>
                <ws-ag-grid-angular [gridOptions]="gridOptions"
                                    [gridId]="gridId"
                                    [showConfigureLayout]="false"
                                    (gridReady)="onAgGridReady($event)">
                </ws-ag-grid-angular>
        </ws-modal-wrapper>
    `
})

export class PaymentBatchAddPaymentsModalComponent implements OnInit, OnDestroy,
                 IWeissmanModalComponent<number, Core.PaymentBatchAddPaymentResultModel> {
    constructor(
        private readonly _paymentBatchService: PaymentBatchService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _toastr: ToastrService,
        private readonly _helpService: HelpService,
        public readonly decimalPipe: DecimalPipe,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _bsModalRef: BsModalRef
    ) { }

    params: number;
    result: Core.PaymentBatchAddPaymentResultModel;

    dataSourceParams: PaymentBatchAddPaymentDataSourceParams;
    selectedRowsModel: Compliance.SelectedRowsModel;
    totalsLoading: any;

    gridId: System.Guid = '68B1A759-6709-4F4A-92B7-02985301DE15';
    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true,
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected(params.data.paymentId),
            'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow
        },
        rowModelType: 'infinite'
    }).withInfiniteScroll(100)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withColumnPinning()
        .build();

    private _gridApi: GridApi;
    private _gridMultiSelectSub: Subscription;
    private _destroy$: Subject<void> = new Subject();
    private _gridDataSource: PaymentBatchAddPaymentAgGridDataSource;
    private _lastFetchedTotals: Core.PaymentBatchPaymentTotalsModel;


    ngOnInit() {
        this._helpService.setContent(PAYMENT_BATCH_DETAILS_HELP);

        const now = new Date();
        // Set initial From Date to now - 3 months, beginning of month, 12:00:00
        // Set initial To Date to now + 3 months, end of month, 23:59:59
        this.dataSourceParams = {
            paymentBatchId: this.params,
            fromDate: moment(now).subtract(3, 'months').startOf('month').toDate(),
            toDate: moment(now).add(15, 'months').endOf('month').toDate()
        },
        this.selectedRowsModel = {
            selectAllRows: false,
            selectedRows: []
        };

        this.result = {
            paymentBatchId: this.params,
            succeeded: false,
            addedCount: 0,
        };
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns = PAYMENT_BATCH_ADD_PAYMENT_COLUMNS(this);
        this._gridApi.setColumnDefs(columns);

        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.selectedRowsModel = x;
            this._reloadTotals();
        });

        this._setDataSource();
    }

    async save() {
        const busyRef = this._busyIndicatorService.show({ message: 'Adding payments...' });

        try {
            const request: Core.PaymentBatchAddPaymentRequestModel = {
                searchParams: this._gridDataSource.getSearchModel(),
                selection: this.selectedRowsModel
            };

            this.result = await this._paymentBatchService.addPaymentsToPaymentBatch(request.searchParams.paymentBatchId, request);
            if (!this.result.succeeded)
            {
                this._toastr.error(this.result.errorMessage);
                return;
            }

            this._bsModalRef.hide();
        }
        catch (e) {
            const errorMessage = e && e.error && e.error.message ? e.error.message : 'Unexpected error without message';
            this._toastr.error(errorMessage);
        }
        finally {
            busyRef.hide();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async refresh(): Promise<void> {
        this._refreshDataSource();
    }

    handleTotalsUpdate = (totals: Core.PaymentBatchPaymentTotalsModel, isLoading: boolean = false) => {
        const model = {};
        const paymentRow: any = {
            model,
            getModel: () => { return model; },
            displayPipe: this._keyValueDisplayPipe,
            index: 0,
            paymentAmount: totals && totals.paymentAmountTotal,
            installmentName: 'SELECTED',
            selectedTotalsRow: true
        };

        if (isLoading) {
            this.totalsLoading = this._gridDataSource.getLoadingMessage(msg => {
                paymentRow.installmentName = msg;
                this._gridApi.setPinnedBottomRowData([paymentRow]);
            });
            return;
        } else if (!isLoading && this.totalsLoading >= 0) {
            clearInterval(this.totalsLoading);
            this.totalsLoading = null;
        }

        const totalRows = this.gridTracker.hasAnythingSelected() ? [paymentRow] : [];

        setTimeout(() => this._gridApi.setPinnedBottomRowData(totalRows), 100);
    };

    private async _getGridRowIds(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(startIndex, endIndex);
    }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear(false);
        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear(false);

        const dataSourceParams = (): PaymentBatchAddPaymentDataSourceParams => {
            return this.dataSourceParams;
        };

        this._gridDataSource = new PaymentBatchAddPaymentAgGridDataSource(
            this._gridApi,
            this._paymentBatchService,
            dataSourceParams,
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
        this.handleTotalsUpdate(result);
    }
}
