<div class="payment-batch-details ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="pageTitle"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Payment Batch Details
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <strong>Status:</strong>
                    <a style="cursor:pointer; margin-left: 10px;"
                       (click)="launchTaskModal($event)">{{ workflowStatus?.taskStatusName }}</a>
                </div>

                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         position="bottom">
                        <button type="button"
                                *ngIf="workflowStatus"
                                (click)="advanceTask()"
                                [disabled]="!workflowStatus?.canAdvanceTask || rowCount === 0"
                                class="ace-btn btn-sm btn-primary">
                            {{ workflowStatus?.taskAdvanceName }}
                        </button>
                    </div>
                    <div class="action-button-wrapper"
                         position="bottom">
                        <button type="button"
                                *ngIf="workflowStatus && isBillPay"
                                (click)="launchExceptionModal()"
                                [disabled]="!workflowStatus?.canReassignTask && !workflowStatus?.canRescindTask"
                                class="ace-btn btn-sm btn-primary btn-danger">
                            Exception
                        </button>
                        <button type="button"
                                *ngIf="isTransmittal && workflowStatus?.isSeriesCompleted"
                                (click)="recallBatch()"
                                class="ace-btn btn-sm btn-primary btn-danger">
                            Recall
                        </button>
                    </div>
                </div>
                <div class="ws-section__header__actions__item">
                    <entity-attachments [(hasAttachments)]="hasAttachments"
                                        buttonClass="ace-btn btn-primary btn-sm"
                                        [modalData]="attachmentModalData"
                                        helpTooltip
                                        helpContentId="app.attachments"
                                        position="bottom">
                    </entity-attachments>
                    <entity-comments
                        [(hasComments)]="hasComments"
                        buttonClass="ace-btn btn-primary btn-sm"
                        [modalData]="commentModalData"
                        helpTooltip
                        helpContentId="app.comments"
                        position="bottom">
                    </entity-comments>
                </div>
                <div class="ws-section__header__actions__item">
                    <button (click)="goBack()" class="btn-sm ace-btn btn-primary">
                        Go Back
                    </button>
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="app.refresh"
                         position="bottom">
                        <button type="button" class="ace-btn has-icon btn-sm btn-secondary"
                                (click)="refreshPage({payments: true, packages: true, status: true}, true)">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-section" style="padding: 15px 0;" *ngIf="paymentBatch">
        <payment-batch-summary [isPaymentGridActive]="paymentDetailsTabs.tabs[0].active"
                               [paymentBatch]="paymentBatch"
                               [workflowStatus]="workflowStatus"
                               (refreshPage)="refreshPage($event)"
                               [isTransmittal]="isTransmittal">
        </payment-batch-summary>
    </div>
    <tabset #paymentDetailsTabs>
        <tab heading="Payments" [disabled]="settingsEditing">
            <payment-batch-payments-grid #paymentBatchDetailsGrid
                                         (gridDataChanged)="refreshPage({payments: true, packages: false, status: true}, true)"
                                         [workflowStatus]="workflowStatus"
                                         (rowCount)="rowCount = $event"
                                         [paymentBatch]="paymentBatch">
            </payment-batch-payments-grid>
        </tab>
        <tab heading="Packages" #packagesTab="tab" [disabled]="settingsEditing">
            <payment-batch-packages #paymentBatchPackages
                                    *ngIf="paymentBatch && packagesTab.active"
                                    [workflowStatus]="workflowStatus"
                                    [paymentBatch]="paymentBatch">
            </payment-batch-packages>
        </tab>
        <tab heading="Output" *ngIf="paymentBatch && isBillPay" [disabled]="!paymentBatch.hasAPFeed || settingsEditing">
            <payment-batch-output [paymentBatch]="paymentBatch"></payment-batch-output>
        </tab>
        <tab heading="Settings" *ngIf="paymentBatch">
            <payment-batch-settings *ngIf="workflowStatus" [paymentBatch]="paymentBatch"
                                    [workflowStatus]="workflowStatus"
                                    (editChange)="settingsEditing = $event"></payment-batch-settings>
        </tab>
    </tabset>

</div>
