import { Component, OnInit } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { TransmittalService } from './transmittal.service';
import { AttachmentInfo } from '../../Attachment/attachment.model';
import { PaymentPackageAttachmentSummary } from './transmittal.models';
import { AttachmentService } from 'src/app/Attachment/attachment.service';

// Note that at this moment you can't navigate to this page via the main interface; links to the payment
// package attachment summary page are sent in emails. The URL for this page is:
// /processing/paymentPackages/attachmentSummary/:paymentPackageID
@Component({
    selector: 'payment-package-attachment-summary',
    templateUrl: './payment-package-attachment-summary.component.html'
})
export class PaymentPackageAttachmentSummaryComponent implements OnInit {
    constructor(
        private upgradeNavigationService: UpgradeNavigationServiceHandler,
        private transmittalService: TransmittalService,
        private attachmentService: AttachmentService
    ) {
    }

    summary: PaymentPackageAttachmentSummary;
    downloading: boolean;

    ngOnInit() {
        const paymentPackageID = Number(this.upgradeNavigationService.getQuerystringParam('paymentPackageID'));
        this.transmittalService.GetPaymentPackageAttachmentSummary(paymentPackageID).then(summary => {
            this.summary = summary;
        });
    }

    goToPackage(summary: PaymentPackageAttachmentSummary): void {
        this.upgradeNavigationService.go('paymentPackageDetail', {
            companyID: summary.companyID,
            paymentPackageID: summary.paymentPackageID
        });
    }

    async downloadAttachment(attachment: AttachmentInfo) {
        this.downloading = true;
        try {
            await this.attachmentService.downloadAttachmentFile(attachment, false);
        } finally {
            this.downloading = false;
        }
    }
}
