import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { Bill } from '../../../Annual-Details/Taxes/bill.model';
import { BillClusterTaxRateSetupModalResult } from './billClusterTaxRateSetupModal.component';

// TODO: This should probably be integrated with the tax rate service (I'm making it as a separate service for now
// until the feature is done)

@Injectable()
export class ParcelTaxRateService {
    constructor (private http: HttpClient) { }

    // TODO: Perhaps the current "ByParcel" versions should be the only versions that exist, and we should delete
    // the versions of these endpoints that use parcelCollectorId so that the UI is more consistent.
    async getAvailableTaxRateAreas(parcelCollectorId: number) {
        return await lastValueFrom(this.http.get<AvailableTaxRateArea[]>(
            `/api/ParcelCollector/${parcelCollectorId}/AvailableTaxRateAreas`
        ));
    }

    async getAvailableTaxAuthorities(parcelCollectorId: number) {
        return await lastValueFrom(this.http.get<AvailableTaxAuthority[]>(
            `/api/ParcelCollector/${parcelCollectorId}/AvailableTaxAuthorities`
        ));
    }

    async getAvailableTaxRateAreasByParcel(parcelId: number, collectorId: number) {
        return await lastValueFrom(this.http.get<AvailableTaxRateArea[]>(
            `/api/ParcelCollector/Parcel/${parcelId}/Collector/${collectorId}/AvailableTaxRateAreas`
        ));
    }

    async getAvailableTaxAuthoritiesByParcel(parcelId: number, collectorId: number) {
        return await lastValueFrom(this.http.get<AvailableTaxAuthority[]>(
            `/api/ParcelCollector/Parcel/${parcelId}/Collector/${collectorId}/AvailableTaxAuthorities`
        ));
    }

    async getCollectorsByParcelId(parcelId: number): Promise<any[]> {
        const params = {parcelid: parcelId};
        return await lastValueFrom(this.http.get('/api/ParcelCollector/', { params })) as Promise<any[]>;
    }

    async getAvailableTaxRateAreasForBillCluster(billClusterId: number) {
        return await lastValueFrom(this.http.get<AvailableTaxRateArea[]>(
            `/api/BillCluster/${billClusterId}/AvailableTaxRateAreas`
        ));
    }

    async getAvailableTaxAuthoritiesForBillCluster(billClusterId: number) {
        return await lastValueFrom(this.http.get<AvailableTaxAuthority[]>(
            `/api/BillCluster/${billClusterId}/AvailableTaxAuthorities`
        ));
    }

    async getTaxRateSetup(parcelCollectorId: number, year: number) {
        return await lastValueFrom(this.http.get<TaxRateSetup>(
            `/api/ParcelCollector/${parcelCollectorId}/Year/${year}/TaxRateSetup`
        ));
    }

    async getTaxRateDetails(billClusterId: number, processingBill: Bill) {
        if (processingBill) {
            return await lastValueFrom(this.http.post<TaxRateDetails>(
                `/api/BillCluster/${billClusterId}/FetchCurrentTaxRateDetails`,
                processingBill
            ));
        }
        else {
            return await lastValueFrom(this.http.get<TaxRateDetails>(
                `/api/BillCluster/${billClusterId}/TaxRateDetails`
            ));
        }
    }

    async searchTaxRateSetup(parcelCollectorId: number, year: number, taxRateAreaId: number, taxAuthorityIds: number[]) {
        return await lastValueFrom(this.http.post<TaxRateSetup>(
            `/api/ParcelCollector/${parcelCollectorId}/Year/${year}/FetchTaxRateSetup`, {
                taxRateAreaId: taxRateAreaId,
                taxAuthorityIds: taxAuthorityIds
            }
        ));
    }

    async searchTaxRateSetupByParcel(parcelId: number, collectorId: number, year: number, taxRateAreaId: number, taxAuthorityIds: number[]) {
        return await lastValueFrom(this.http.post<TaxRateSetup>(
            `/api/ParcelCollector/Parcel/${parcelId}/Collector/${collectorId}/Year/${year}/FetchTaxRateSetup`, {
                taxRateAreaId: taxRateAreaId,
                taxAuthorityIds: taxAuthorityIds
            }
        ));
    }

    async searchTaxRateDetails(billClusterId: number, taxRateAreaId: number, taxAuthorityIds: number[], processingBill: Bill) {
        return await lastValueFrom(this.http.post<TaxRateDetails>(
            `/api/BillCluster/${billClusterId}/FetchTaxRateDetails`, {
                taxRateAreaId: taxRateAreaId,
                taxAuthorityIds: taxAuthorityIds,
                billOverride: processingBill
            }
        ));
    }

    async getCollectorTaxRateDetails(billClusterId: number, processingBill: Bill) {
        if (processingBill) {
            return await lastValueFrom(this.http.post<TaxRateDetails>(
                `/api/BillCluster/${billClusterId}/FetchCollectorTaxRateDetails`,
                processingBill
            ));
        }
        else {
            return await lastValueFrom(this.http.get<TaxRateDetails>(
                `/api/BillCluster/${billClusterId}/CollectorTaxRateDetails`
            ));
        }
    }

    async saveTaxRateSetup(
        parcelCollectorId: number,
        parcelCollectorRowVersion: string,
        taxRateAreaId: number,
        taxAuthorityIds: number[]
    ) {
        return await lastValueFrom(this.http.put<{ parcelCollectorRowVersion }>(
            `/api/ParcelCollector/${parcelCollectorId}/TaxRateSetup`, {
                taxRateAreaId,
                taxAuthorityIds,
                parcelCollectorRowVersion
            }
        ));
    }

    async saveTaxRateDetails(
        billClusterId: number,
        billClusterRowVersion: string,
        taxRateAreaId: number,
        taxAuthorityIds: number[]
    ) {
        return await lastValueFrom(this.http.put<{ billClusterRowVersion }>(
            `/api/BillCluster/${billClusterId}/TaxRateDetails`, {
                billClusterRowVersion,
                taxRateAreaId,
                taxAuthorityIds
            }
        ));
    }

    async getFirstEncounterData(taxAuthorityId: number, taxYear: number) {
        return await lastValueFrom(this.http.get<Core.FirstEncounterDTO>(
            `/api/TaxAuthority/${taxAuthorityId}/Year/${taxYear}/FirstEncounter`
        ));
    }

    async saveFirstEncounterData(taxAuthorityId: number, taxYear: number, request: FirstEncounterSaveRequest) {
        return await lastValueFrom(this.http.post<FirstEncounterSaveResult>(
            `/api/TaxAuthority/${taxAuthorityId}/Year/${taxYear}/FirstEncounter`,
            request
        ));
    }
}

// TODO: These models should be exported from the API
export class AvailableTaxAuthority {
    taxAuthorityId: number;
    name: string;
    code: string;
}
export class AvailableTaxRateArea {
    taxRateAreaId: number;
    name: string;
    code: string;
}
export class TaxAuthorityRate {
    taxAuthorityId: number;
    name: string;
    code: string;
    efAction?: string;
    taxAuthorityCategoryId: number;
    taxableAssessmentTypeId: number;
    taxAuthorityStatusId: number;
    rate: number;
    taxYear: number;
    qcRequestTimeUtc?: Date;
    qcRequestUserId?: string;
    qcRequestUserFullName?: string;
}
export class TaxRateSetup {
    taxRateAreaId: number;
    name: string;
    code: string;
    taxAuthorities: TaxAuthorityRate[];
}
export class TaxAuthorityRateDetails extends TaxAuthorityRate {
    assessedValue: number;
    assessedValueAdjustment: number;
    taxableAssessedValue: number;
    annualTaxAmount: number;
}
export class TaxRateDetails {
    taxRateAreaId: number;
    name: string;
    code: string;
    taxAuthorities: TaxAuthorityRateDetails[];
    usingParentRates: boolean;
    totalTaxRate: number;
    totalTaxAmount: number;
    directAssessmentTaxAmount: number;
    calculatedTaxAmount: number;
    totalBillTaxAmount: number;
    otherBillTaxAmount: number;
    taxAmountVariance: number;
}

export interface TaxSetupMinimumDTO {
    taxRateAreaId: number;
    taxAuthorities: { taxAuthorityId: number }[];
}

export class FirstEncounterSaveRequest
{
    trackBillId?: number;
    rate: number;
    attachmentId: string;
}

export class FirstEncounterSaveResult
{
    initialBillClusterRowVersion: string;
    initialBillRowVersion: string;
    finalBillClusterRowVersion: string;
    finalBillRowVersion: string;
    updateVersion: boolean;
    inProcessTaxSetupChanges: BillClusterTaxRateSetupModalResult;
}


