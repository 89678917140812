import { TemplateRef } from '@angular/core';

export interface AppealFormOverrideModel extends Compliance.VersionedModel {
    fieldName: string;
    appealFormRevisionOverrideId: number;
    appealFormRevisionId: number;
    value: string;
}

export class AppealFormItem {
    appealId: number;
    appealLevel: string;
    appealFormRevisionId: number;
    annualYear: number;
    companyId: number;
    companyName: string;
    siteId: number;
    siteName: string;
    parcelId: number;
    parcelAcctNum: string;
    formRevisionId: number;
    formRevisionName: string;
    parentFormRevisionId: number;
    hasOverrides: boolean;
    isTopAppeal: boolean;
    //matchedFormRevisionId: number;
    additionalAccounts: AppealFormItem[];
    propTypeAbbr: string;
    useLRProcess: boolean;
    useCache: boolean;
    propertyTypeID: number;
    isMultiAccount: boolean;
    template: TemplateRef<any>;
}

export class AppealFormSettingLookup {
    [groupNumber: number]: {
        options: AppealFormSettingLookupOption[];
        exceptions: AppealFormException[];
    }
}

export class AppealFormSettingLookupOption {
    appealFormSettingsLookupId: number;
    groupNumber: number;
    name: string;
}

export class AppealFormSettings {
    constructor() {
        this.settings = new AppealFormDropdownSettings();
    }

    settings: AppealFormDropdownSettings;
    isMultiAccount: boolean;
    exceptions: AppealFormException[];
}

export class AppealFormDropdownSettings {
    constructor() {
        this.ownerName = 4;
        this.ownerAddress = 21;
        this.agentContact = 30;
        this.agentAddress = 40;
        this.agentPhone = 50;
        this.signer = 60;
        this.signerType = 70;
    }

    ownerName: number;
    ownerAddress: number;
    agentContact: number;
    agentContactOtherValue: number;
    agentAddress: number;
    agentPhone: number;
    signer: number;
    signerOtherValue: number;
    signerType: number;
    applyDigitalSignature: number;
    multiAccount: any;
}

export class AppealFormException {
    appealFormRevisionSettingsID: number;
    appealFormRevisionID: number;
    groupNumber: number;
    value: number;
    otherValue: string;
    efAction: string;
}
