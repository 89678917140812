import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent, ColDef } from 'ag-grid-community';
import { RestrictService, InstanceRights } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../Compliance/AgGrid';
import { UserInstanceService } from '../../User/userInstance.service';
import { UserGroupsService } from '../userGroup.service';
import { UserGroupsGridActionCellRendererComponent, ICellRendererParamsForUserGroupsGridAction } from './agGridActionCellRendererComponent';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AddUserGroupModalComponent } from './addUserGroupModal.component';
import {
    AgGridYesNoFloatingFilterComponent
} from '../../Compliance/AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';

@Component({
    selector: 'user-group-list',
    templateUrl: './userGroupList.component.html',
    styleUrls: ['./userGroupList.component.scss']
})
export class UserGroupListComponent implements OnInit {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _restrictService: RestrictService,
        private readonly _userGroupsService: UserGroupsService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _featureFlagService: FeatureFlagsService) { }

    gridId: System.Guid = '7D885CAC-59F4-4CAE-AE56-B8948DE60508'; // need new guid
    isInitialized: boolean = false;
    hasEditPermission: boolean = false;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        suppressScrollOnNewData: true
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    refreshing: boolean;
    instanceId: number;

    private _gridApi: GridApi;
    private _userGroups: Core.GroupModel[];

    async ngOnInit(): Promise<void> {
        this.hasEditPermission = this._restrictService.hasInstanceRight(InstanceRights.MANAGEUSERSETUP);
        this.instanceId = this._userInstanceService.getSelectedInstance().instanceId;

        this.isInitialized = true;
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Name',
                field: 'name',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Description',
                field: 'description',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnLargeWidth
            },
            {
                headerName: '# Members',
                field: 'userCount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Consultant Group',
                field: 'isConsultantGroup',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    const userGroup = params.data as Core.GroupModel;

                    return userGroup.isConsultantGroup ? 'Yes' : 'No';
                }
            },
            {
                headerName: 'Support Group',
                field: 'isSupportGroup',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: params => {
                    const userGroup = params.data as Core.GroupModel;

                    return userGroup.isSupportGroup ? 'Yes' : 'No';
                }
            }
        ];

        if (this._featureFlagService.featureFlags.enableLicensedDocumentIntakeAndProcessing &&
            this._userInstanceService.RyanInstanceId === this._userInstanceService.getSelectedInstance().instanceId) {
            columns.push({
                    headerName: 'Documents Group',
                    field: 'isDocumentGroup',
                    width: AgGridColumns.textColumnSmallWidth,
                    filter: 'agYesNoColumnFilter',
                    filterParams: AgGridFilterParams.yesNoFilterParams,
                    floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                    floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                    valueGetter: params => {
                        const userGroup = params.data as Core.GroupModel;

                        return userGroup.isDocumentsGroup ? 'Yes' : 'No';
                    }
                }
            );
        }

        columns.push({
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: UserGroupsGridActionCellRendererComponent,
                cellRendererParams: {
                    hasEditPermission: () => this.hasEditPermission,
                    viewDetails: this._viewDetails.bind(this),
                    delete: this._delete.bind(this)
                } as ICellRendererParamsForUserGroupsGridAction
            }
        );

        const defaultSortModel = [
            {
                colId: 'name',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._gridApi.sizeColumnsToFit();

        await this._refresh();
    }

    async refresh(): Promise<void> {
        await this._refresh();
    }

    async addNew(): Promise<void> {
        const result = await this._modalService.showAsync(AddUserGroupModalComponent, null, '');

        if(!result) {
            return Promise.resolve();
        }

        result.instanceID = this.instanceId;

        try {
            this._gridApi && this._gridApi.showLoadingOverlay();

            const newUserGroup = await this._userGroupsService.create(result);
            this._upgradeNavigationServiceHandler.go('userGroupDetail', { userGroupId: newUserGroup.groupID });
        } finally {
            this._gridApi && this._gridApi.hideOverlay();
        }
    }

    private async _viewDetails(params: ICellRendererParamsForUserGroupsGridAction): Promise<void> {
        const userGroup = params.data as Core.GroupModel;

        this._upgradeNavigationServiceHandler.go('userGroupDetail', { userGroupId: userGroup.groupID });
    }

    private async _delete(params: ICellRendererParamsForUserGroupsGridAction): Promise<void> {
        const userGroup = params.data as Core.GroupModel;
        if (!userGroup) {
            return;
        }

        const deleted = await this._userGroupsService.deleteWithConfirm(userGroup);
        if (deleted) {
            await this._refresh();
        }

    }

    private async _refresh(): Promise<void> {
        this.refreshing = true;

        this._userGroups = [];

        try {
            this._gridApi && this._gridApi.showLoadingOverlay();

            const groupSearchModel: Core.GroupSearchModel = {};

            this._userGroups = await this._userGroupsService.getList(this.instanceId, groupSearchModel);

        } finally {
            this._gridApi && this._gridApi.hideOverlay();
        }

        this._setRowData();
        this.refreshing = false;
    }

    private _setRowData() {
        if (!(this._gridApi && this._userGroups)) {
            return;
        }

        this._gridApi.setRowData(this._userGroups);
    }
}
