<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="169 -172 392 392"
    xml:space="preserve" transform="scale(1.0)">
    <g>
        <path d="M471.7,88.6v66.2h-25.6v-36.4l-0.8,0.4l-24.9,10.1v26H267V78.1h92.7l0.5-21.3c0.2-1.4,0.5-2.8,0.9-4.2H260.6
                c-5.4,0-9.9,1.8-13.6,5.6c-3.7,3.8-5.6,8.3-5.6,13.6v83.1h-25.6v-255.8h25.6v83.1c0,5.4,1.8,9.9,5.6,13.6c3.8,3.7,8.3,5.6,13.6,5.6
                h115.1c5.4,0,9.9-1.8,13.6-5.6c3.7-3.8,5.6-8.3,5.6-13.6v-83.1c2,0,4.6,0.7,7.8,2s5.5,2.7,6.8,4l30.4,30.4l14.8-21.4l-27-27
                c-3.8-3.7-8.8-6.9-15.2-9.6c-6.4-2.7-12.2-4-17.6-4H209.5c-5.4,0-9.9,1.8-13.6,5.6c-3.7,3.8-5.6,8.3-5.6,13.6v268.5
                c0,5.4,1.8,9.9,5.6,13.6s8.3,5.6,13.6,5.6H478c5.4,0,9.9-1.8,13.6-5.6c3.7-3.8,5.6-8.3,5.6-13.6V51.4L471.7,88.6z M318.2-94.6
                c0-1.7,0.6-3.2,1.9-4.5c1.3-1.3,2.8-1.9,4.5-1.9H363c1.7,0,3.2,0.6,4.5,1.9c1.3,1.3,1.9,2.8,1.9,4.5v63.9c0,1.8-0.6,3.2-1.9,4.5
                c-1.3,1.3-2.8,1.9-4.5,1.9h-38.4c-1.7,0-3.2-0.6-4.5-1.9c-1.3-1.3-1.9-2.8-1.9-4.5V-94.6z" />
        <g>
            <path d="M537.3-42.9l-11.8,17.2l-59.2-40.7l11.8-17.2c3.7-5.4,10.5-7.3,15.1-4.1l42.5,29.3
                    C540.3-55.3,541-48.3,537.3-42.9z M460.7-58.4l59.2,40.7L441.2,96.7c-0.4,0.5-1.4,2-3,2.7c-0.2,0.1-0.4,0.3-0.6,0.4l-53,21.5
                    c-1.3,0.7-2.8,0.7-3.9-0.1c-1.1-0.8-1.6-2.1-1.5-3.6l1.1-57.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.8,1.1-3.3,1.5-3.8 M428.2,95.9
                    l-40.8-28l-1.3,23.3l20.2,13.9L428.2,95.9z M462.1-42c-1.7-1.2-4-0.7-5.2,1l-61.3,89.1c-1.2,1.7-0.7,4,1,5.2s4,0.7,5.2-1
                    l61.3-89.1C464.2-38.6,463.8-40.9,462.1-42z" />
        </g>
    </g>
</svg>
