import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'wsYesNo' })
export class WeissmanYesNoPipe implements PipeTransform {
    transform(value: boolean): string {
        return value ? 'Yes' : 'No';
    }
}

@Pipe({ name: 'wsTrueFalse' })
export class WeissmanTrueFalsePipe implements PipeTransform {
    transform(value: boolean): string {
        return value ? 'True' : 'False';
    }
}