import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

export interface DeleteConfirmationComponentParams {
    item: string;
    message?: string;
    url?: string;
}

@Component({
    selector: 'delete-confirmation-component',
    template: `
        <ws-modal-wrapper [headerLabel]="'Deleting ' + item"
                          acceptLabel="Delete"
                          acceptClass="btn-primary btn-danger"
                          cancelClass="btn-primary"
                          [disableCancel]="deleting"
                          [disableSave]="deleting"
                          (save)="delete()"
                          (cancel)="cancel()">
            <loading-spinner [size]="5" margin-bottom="0" [show]="deleting"></loading-spinner>
            <p class="lead text-danger" *ngIf="!deleting">{{message}}</p>
        </ws-modal-wrapper>
    `
})
export class DeleteConfirmationComponent implements OnInit {
    constructor(
        private readonly _http: HttpClient,
        private readonly _bsModalRef: BsModalRef,
        private readonly _toastr: ToastrService
    ) { }

    params: DeleteConfirmationComponentParams;
    result: boolean;

    item: string;
    message: string;
    url: string;
    deleting: boolean;

    ngOnInit(): void {
        this.item = this.params.item;
        this.message = this.params.message;
        this.url = this.params.url;
    }

    async delete(): Promise<void> {
        if (this.url) {
            this.deleting = true;
            try {
                await lastValueFrom(this._http.delete(this.url));
                this.result = true;
            } catch (err) {
                const errorMessage = err && err.error && err.error.message ? err.error.message : '';
                this._toastr.error(errorMessage, 'Error!');
            }
        } else {
            this.result = true;
        }
        this.cancel();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
