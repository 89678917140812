import { Component, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { WeissmanDateFormat, formatDateOutputToUTC } from '../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { CellEditorValidationResult } from './validator.interface';

declare let $: any;
declare let moment: any;

export interface DatepickerCellEditorParams extends ICellEditorParams {
    cellChanged?: (initialRowValue: any, changedDate: Date) => Promise<void>;
    cellFocusLost?: (initialRowValue: any, changedDate: Date) => Promise<void>;
    validator?: (value: Date) => CellEditorValidationResult;
    canReset?: boolean;
    hasOverride?: (params: DatepickerCellEditorParams) => boolean;
    args?: any;
}

@Component({
    selector: 'editor-cell',
    template: `
        <div class="ws-ag-grid-date-cell-editor ws-flex-container-horizontal">
            <input #picker
                   type="text"
                   [formControl]="dateValue"
                   [class.invalid]="!isValid"
                   [class.has-reset]="showReset"
                   helpTooltip
                   [tooltipText]="validationMessage"
                   [helpDisabled]="isValid"/>
            <span class="reset-value clickable"
                  [class.showReset]="showReset"
                  helpTooltip
                  tooltipText="Reset to the original value"
                  position="right"
                  (click)="resetToOriginalValue()">
                <i class="fa fa-times"></i>
            </span>
        </div>
    `,
    styleUrls: ['./cellEditors.scss']
})
export class AgGridDatepickerCellEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
    @ViewChild('picker', { static: true }) picker: ElementRef;

    initialValue: string;
    isValid: boolean = true;
    validationMessage: string;
    dateValue: UntypedFormControl = new UntypedFormControl();
    canReset: boolean = false;

    private _params: DatepickerCellEditorParams;
    private _destroy$: Subject<void> = new Subject();

    get hasOverride(): boolean {
        return this._params.hasOverride && this._params.hasOverride(this._params);
    }

    get showReset(): boolean {
        return this.canReset && ((this.dateValue && !this.dateValue.value) || this.hasOverride);
    }

    agInit(params: DatepickerCellEditorParams): void {
        this._params = params;
        this.canReset = params.canReset;
        this.initialValue = (params.value) ? WeissmanDateFormat(params.value, true) : '';
        this.dateValue.setValue(this.initialValue);

        this.dateValue.valueChanges
            .pipe(takeUntil(this._destroy$))
            .pipe(distinctUntilChanged())
            .subscribe(async (date: string) => {
                if (this._params.validator) {
                    const validation = this._params.validator(new Date(date));
                    this.isValid = validation.isValid;
                    this.validationMessage = validation.validationMessage;
                }
                if (this._params.cellChanged && this._isUnique(date)) {
                    await this._params.cellChanged(this._params, formatDateOutputToUTC(this.dateValue.value));
                }
            });
    }

    ngAfterViewInit(): void {
        this.picker.nativeElement.focus();
        this.picker.nativeElement.select();
        $(this.picker.nativeElement).datepicker({
            dateFormat: 'mm/dd/yy',
            direction: 'down',
            onClose: (dateText) => this.dateValue.setValue(dateText)
        });
    }

    async ngOnDestroy(): Promise<void> {
        if (!this.isValid) {
            this.dateValue.setValue(this.initialValue);
        }

        if (this._params.cellFocusLost && this._isUnique(this.dateValue.value)) {
            await this._params.cellFocusLost(this._params, formatDateOutputToUTC(this.dateValue.value));
        }

        this._destroy$.next();
        this._destroy$.complete();
    }

    getValue(): Date {
        return formatDateOutputToUTC(this.dateValue.value);
    }

    isPopup(): boolean {
        return false;
    }

    async resetToOriginalValue(): Promise<void> {
        this.dateValue.setValue('');
        this._params.api.stopEditing();
    }

    private _isUnique(date: string): boolean {
        return !(date === this.initialValue);
    }
}
