import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import { Constants } from '../../../constants.new';
import * as _ from 'lodash';

@Component({
    selector: 'further-limit-panel-psr',
    template: `
        <div style="display: flex; white-space: nowrap; gap: 30px;">
            <div style="display: flex; align-items: baseline; gap: 5px;">
                <label>Tax Years: </label>
                <select [(ngModel)]="report.criteria.taxYearBegin" (change)="yearChangedValidation()" class="form-select-sm" style="width: 80px;">
                    <option [ngValue]=""></option>
                    <option *ngFor="let year of availableYears" [ngValue]="year">{{ year }}</option>
                </select>
                <label> to </label>
                <select [(ngModel)]="report.criteria.taxYearEnd" [disabled]="!report.criteria.taxYearBegin" class="form-select-sm report-manager-year-list" style="width: 80px;">
                    <option [ngValue]=""></option>
                    <option *ngFor="let year of availableYears" [disabled]="year < report.criteria.taxYearBegin" [ngValue]="year">{{ year }}</option>
                </select>
            </div>
            <div style="display: flex; gap: 5px; align-items: baseline;">
                <label>Property Type: </label>
                <select multiple [(ngModel)]="report.criteria.propertyTypeIDs" class="form-select-sm">
                    <option *ngFor="let propType of propertyTypes" [ngValue]="propType.propertyTypeId">{{ propType.name }}</option>
                </select>
            </div>
            <div>
                <label>
                    <ws-switch [(ngModel)]="report.criteria.includeEstimatedAssessments"></ws-switch> Include Estimated Assessments
                </label>
            </div>
            <div>
                <label>
                    <ws-switch [(ngModel)]="report.criteria.includeOnlySitesUnderAppeal"></ws-switch> Include Only Sites Under Appeal
                </label>
            </div>
            <div *ngIf="hasRyanPrivatePermission">
                <label>
                    <ws-switch [(ngModel)]="report.criteria.includeDisclaimer"></ws-switch> Include Disclaimer
                </label>
            </div>
        </div>
    `
})
export class FurtherLimitPanelPsrComponent implements OnInit {
    constructor(private constants: Constants) { }

    @Input() report: ReportDetail;
    @Input() availableYears: number[];
    @Input() hasRyanPrivatePermission: boolean;
    propertyTypes: any[];

    ngOnInit() {
        if (!this.report.criteria.taxYearBegin) {
            const newYearValue = new Date().getFullYear() - 3;
            this.report.criteria.taxYearBegin = newYearValue;
        }

        this.propertyTypes = _.map(this.constants.PropertyTypeNames, (name: string, id: number) => {
            return {
                name: name,
                propertyTypeId: Number(id)
            };
        });

        this.report.criteria.propertyTypeIDs = this.report.criteria.propertyTypeIDs || [1, 2, 3, 4];

        this.report.criteria.includeEstimatedAssessments = this.report.criteria.includeEstimatedAssessments || false;
        this.report.criteria.includeOnlySitesUnderAppeal = this.report.criteria.includeOnlySitesUnderAppeal || false;

        if ([null, undefined].indexOf(this.report.criteria.includeDisclaimer) !== -1) {
            this.report.criteria.includeDisclaimer = true;
        }
    }

    yearChangedValidation(): void {
        if (this.report.criteria.taxYearBegin > this.report.criteria.taxYearEnd) {
            this.report.criteria.taxYearEnd = null;
        }
    }
}
