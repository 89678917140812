import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentBatchService } from '../../paymentBatch.service';
import { cloneDeep, some } from 'lodash';
import { NavigationService } from 'src/app/Layout/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { BusyIndicatorMessageManager } from 'src/app/Busy-Indicator';

interface CollectorOverridesUI extends Core.PaymentBatchSettingsCollectorModel {
    inputValue: number;
}

@Component({
    selector: 'payment-batch-settings',
    templateUrl: 'paymentBatchSettings.component.html',
    styles: [`
        .ng-invalid {
            border-color: var(--ace-danger);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
        }
    `]
})

export class PaymentBatchSettingsComponent implements OnInit {
    constructor(
        private readonly _paymentBatchService: PaymentBatchService,
        private readonly _toastr: ToastrService,
        private readonly _navigationService: NavigationService
    ) {}

    @Input() paymentBatch: Core.PaymentBatchModel;
    @Input() workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;
    @Output() editChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly MAX_PAYMENT_ITEMS = 1000;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    editMode: boolean = false;
    settings: Core.PaymentBatchSettingsModel;
    collectorOverridesUI: CollectorOverridesUI[];

    private _originalSettings: Core.PaymentBatchSettingsModel;
    private _originalCollectorOverridesUI: CollectorOverridesUI[];


    async ngOnInit(): Promise<void> {
        const busyMsg = 'Saving';
        this.busyIndicatorMessageManager.add('Loading', busyMsg);

        try {
            this.settings = await this._paymentBatchService.getPaymentBatchSettings(this.paymentBatch.paymentBatchId);
            this._setCollectorOverridesUI();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }

    get canEdit(): boolean {
        return this.workflowStatus.canModifyMaxItemsPerCheck;
    }

    get allPayees(): Core.PaymentBatchCollectorOverrideScopeEnum  {
        return Core.PaymentBatchCollectorOverrideScopeEnum.All;
    }

    get individualPayees(): Core.PaymentBatchCollectorOverrideScopeEnum  {
        return Core.PaymentBatchCollectorOverrideScopeEnum.Individual;
    }

    get isAppliedToAll(): boolean {
        return this.settings.collectorOverrideScope === this.allPayees;
    }

    edit() {
        this._originalSettings = cloneDeep(this.settings);
        this._originalCollectorOverridesUI = cloneDeep(this.collectorOverridesUI);

        this.editMode = true;
        this.editChange.emit(true);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    revertOverride(payee: CollectorOverridesUI) {
        payee.inputValue = payee.maxItemsPerCheckCollector;
        payee.maxItemsPerCheckOverride = null;
    }

    async save() {
        if(this.isAppliedToAll) {
            if(this.settings.maxItemsPerCheck > this.MAX_PAYMENT_ITEMS) {
                this._toastr.error(`Max Payment Items must be below ${this.MAX_PAYMENT_ITEMS}`);
                return;
            }
        } else {
            if(some(this.collectorOverridesUI, x => x.inputValue > this.MAX_PAYMENT_ITEMS)) {
                this._toastr.error(`Max Payment Items must be below ${this.MAX_PAYMENT_ITEMS}`);
                return;
            }
            if(some(this.collectorOverridesUI, x => x.maxItemsPerCheckCollector && x.inputValue > x.maxItemsPerCheckCollector)) {
                this._toastr.error('Max Payment Items cannot exceed value at collector\'s protocol');
                return;
            }
        }

        const busyMsg = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsg);

        try {
            if(!this.isAppliedToAll) {
                this.settings.collectorOverrides = this.settings.collectorOverrides.map((x, i) => {
                    if (!this.collectorOverridesUI[i].inputValue) {
                        // No override entered. Clear it from the model.
                        x.maxItemsPerCheckOverride = null;
                    }
                    else if (x.maxItemsPerCheckCollector &&
                             this.collectorOverridesUI[i].inputValue === x.maxItemsPerCheckCollector) {
                        // Override entered but it matches Collector setting. Clear it from the model.
                        x.maxItemsPerCheckOverride = null;
                    }
                    else {
                        // Override entered and doesn't match Collector setting
                        // or Collector has no setting. Clear it from the model.
                        x.maxItemsPerCheckOverride = this.collectorOverridesUI[i].inputValue;
                    }

                    return x;
                });
            }

            this.settings = await this._paymentBatchService.savePaymentBatchSettings(this.paymentBatch.paymentBatchId, this.settings);
            this._setCollectorOverridesUI();

            this._exitEditMode();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }

    cancel() {
        this._exitEditMode();
        this.settings = this._originalSettings;
        this.collectorOverridesUI = this._originalCollectorOverridesUI;
        // this.settingsForm.reset(this.settings);
    }

    private _exitEditMode() {
        this._navigationService.disableNavWarning();
        this.editMode = false;
        this.editChange.emit(false);
    }

    private _setCollectorOverridesUI(): void {
        this.collectorOverridesUI = this.settings.collectorOverrides.map(x => ({
            ...x,
            inputValue: x.maxItemsPerCheckOverride ?? x.maxItemsPerCheckCollector
        }));
    }
}
