
import {of as observableOf,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { has } from 'lodash';

export class DataCache<T> {
    constructor(
        private _http: HttpClient,
        private _primaryAreaName: string,
        private _primaryCacheExpiration: number = 0
    ) { }

    private _expirationMillis: number;

    private _entriesMap: { [key: string]: any } = {};

    getCached<T1>(path: string, areaName: string = this._primaryAreaName): Observable<T1> {
        if (has(this._entriesMap, areaName) && this._primaryCacheExpiration === 0) {
            return observableOf(this._entriesMap[areaName]);
        }

        return this._http
            .get<T1>(path).pipe(
            map(response => {
                this._entriesMap[areaName] = response;
                return response;
            }));
    }

    clearCache(areaName:string = this._primaryAreaName) {
        delete this._entriesMap[areaName];
    }

    clearAllCache() {
        this._entriesMap = {};
    }
}
