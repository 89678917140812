import {TaskSummary} from '../../Task/Task.Model';
import {UserTeamMember, Address} from '../../Common/Models/common.model';
import {Team} from '../../Team/team.model';
import { PropertyType, ContractServiceType } from '../../constants.new';

export class Invoice {
    //Details and info
    invoiceId: number;
    companyId: number;
    companyName: string;
    siteId: number;
    siteName: string;
    siteAddress: Address;
    siteState: InvoiceState;
    invoiceType: number;
    invoiceTypeName: string;
    isAdHoc: boolean;
    contractServiceType: ContractServiceType;
    defaultContractServiceType: ContractServiceType;
    dueDate: Date;
    invoiceNumber: number;
    invoiceUBRNumber: number;
    recipientName: string;
    invoiceAmount: number;
    invoiceRowVersion: string;
    annualYear: number;
    appeals: InvoiceAppeal[];
    recipientMailingAddress: string;
    thirdPartyApprovalDate: Date;
    recipientCdsContactId: string;
    isRyanLegalServices: boolean;

    //Booleans
    includeOtherParcels: boolean;
    automaticallyUpdateNotes: boolean;
    hasComments: boolean;
    hasAttachments: boolean;
    canBeDeleted: boolean;
    isUBR: boolean;

    //Overrides
    previousFMVStatus: OverrideStatus;
    invoiceFMVStatus: OverrideStatus;
    fmvChangeStatus: OverrideStatus;
    savingsStatus: OverrideStatus;
    taxRateStatus: OverrideStatus;
    contingencyPctStatus: OverrideStatus;
    feeAmountStatus: OverrideStatus;
    fixedFeeStatus: OverrideStatus;
    contingencyCapStatus: OverrideStatus;
    invoiceAmountStatus: OverrideStatus;

    isSavingsOverridden: boolean;

    //Tasks
    taskSummary: TaskSummary;
    readyTaskId: number;
    readyTaskTypeId: number;
    hasUBRTasks: boolean;

    //Fee Allocation
    contingencyInvoiceRowVersion: string;
    invoiceRevenueShareCodes: RevenueShare [];

    //Detail Fields
    otherParcelFMV: number;
    contingencyPct: number;
    displayContingencyPct: number;
    contingencyCap: number;
    previousFMV: number;
    invoiceFMV: number;
    taxRate: number;
    displayTaxRate: number;
    savings: number;
    feeAmount: number;
    fixedFee: number;
    expenseAmount: number;
    expenseIndex: number;
    fmvChange: number;
    propertyTypeID: number;
    totalFeeAmount: number;
    contractTermDetailRemarks: string;

    //Calculated Detail Fields
    calculatedPreviousFMV: number;
    calculatedInvoiceFMV: number;
    calculatedTaxRate: number;
    calculatedSavings: number;
    calculatedFeeAmount: number;

    backupContingencyPct: number;
    contingencyCapOriginal: number;
    contingencyPctOriginal: number;
    fixedFeeOriginal: number;

    processingInstructions: string;
    notes: string;

    //Account Handler
    accountHandler: UserTeamMember;
    accountHandlerTeam: Team;
    accountHandlerTeamID: number;
    accountHandlerUserID: string;
}

export enum InvoiceTypes {
    Contingency = 1
}

export enum InvoiceRouteNames {
    Processing = 'processInvoice',
    Site = 'siteInvoice',
    Company = 'companyInvoice'
}

export enum OverrideStatus {
    Default= 'black',
    Recalculated = 'blue',
    Overridden = 'red'
}

export class InvoiceAppeal {
    parcelID: number;
    parcelAcctNum: string;
    appealLevelName: string;
    appealLevelAbbr: string;
    annualYear: number;
    appealID: number;
    invoiceFMV: number;
    previousFMV: number;
    finalFMV: number;
    fmvChange: number;
    revisionDesc: string;
    savings: number;
    savingsOverridden: boolean;
    taxRate: number;
}

export class InvoiceAppealSearchResult {
    otherParcelFMV: number;
    appeals: InvoiceAppeal[];
}

export class InvoiceState {
    abbr: string;
    assessorCount: 0;
    collectorCount: 0;
    country: string;
    countryId: number;
    fiscalDateMonthDay: string;
    fiscalDateYearID: number;
    fullName: string;
    hasAttachments: boolean;
    hasComments: boolean;
    lienDateMonthDay: string;
    lienDateYearID: number;
    personalPropTaxable: boolean;
    stateID: number;
    website: string;
}

export class CreateAdHocInvoiceRequest {
    companyId: number;
    siteId: number;
    annualYear: number;
    contractServiceType: ContractServiceType;
    propertyType: PropertyType;
}

export class BulkInvoicePreCheckResult {
    existingInvoiceCount: number;
    siteCount: number;
}

export class RevenueShare {
    constructor(invoiceId: number) {
        this.allocationPct = 0;
        this.displayAllocationPct = 0;
        this.costCenter = null;
        this.costCenterTeamId = null;
        this.engagementNum = '';
        this.invoiceId = invoiceId;
        this.isAccountHandler = false;
        this.optionalIdentifier = null;
        this.feeAmount = 0;
        this.invoiceAmount = 0;
    }

    allocationPct: number;
    displayAllocationPct: number;
    costCenter: string;
    costCenterTeamId: number;
    engagementNum: string;
    invoiceRevenueShareCodeId: number;
    invoiceId: number;
    isAccountHandler: boolean;
    optionalIdentifier: string;
    rowVersion: string;
    sequence: number;
    feeAmount: number;
    invoiceAmount: number;
    isTotal: boolean;
}
