import { Injectable } from '@angular/core';
import { SDHttpService } from './sd-http.service';
import { TimerService } from '../../UI-Lib/Utilities';

export interface KeyedStaticCacheDefinition {
    cacheKey: string,
    endpoint: string,
    cacheDisabled: boolean,
    debugMode?: boolean
}

interface KeyedStaticCache {
    definition: KeyedStaticCacheDefinition,
    keyPromise: Promise<string>,
    resultPromise: Promise<any>
}

export class CacheManager {
    constructor(private sdHttp: SDHttpService) {
        this.keyedStaticCaches = new Map<string, KeyedStaticCache>();
    }

    private keyedStaticCaches: Map<string, KeyedStaticCache>;

    registerKeyedStaticCache(cacheName: string, definition: KeyedStaticCacheDefinition): void {
        if (definition.cacheDisabled !== true && definition.cacheDisabled !== false) {
            throw new Error('You must explicitly state if the cache is disabled by setting the cacheDisabled flag to true or false');
        }
        this.keyedStaticCaches.set(cacheName, {
            definition: definition,
            keyPromise: null,
            resultPromise: null
        });
    }

    static buildCacheURL(endpoint: string, cacheKey: string, debugMode: boolean): string {
        // This is a simplistic mechanism of modifying the endpoint; if we need something more
        // sophisticated, we should make the calling code pass in something in the definition
        // that we can work with for strange cases (it hasn't come up yet)
        if (endpoint.indexOf('?') >= 0) {
            endpoint = `${endpoint  }&`;
        }
        else {
            endpoint = `${endpoint  }?`;
        }
        endpoint = `${endpoint  }cacheKey=${  encodeURIComponent(cacheKey)}`;
        // In debug mode, we guarantee a new endpoint for each call, since AngularJS's http
        // mechanism has its own caching that we may want to bypass, or possibly we just want
        // to disable the http portion of the cache. Even in debug mode, we're still guaranteed
        // one call per cache per page load (basically an "in-memory" cache)
        if (debugMode) {
            endpoint = `${endpoint  }&debug=${  +(new Date())}`;
        }

        return endpoint;
    }

    async keyedStaticGet<T>(cacheName: string): Promise<T> {
        const cache: KeyedStaticCache = this.keyedStaticCaches.get(cacheName);
        if (!cache) {
            throw new Error(`Unable to locate cache ${  cacheName}`);
        }

        if (cache.definition.cacheDisabled === true) {
            console.log(`WARNING: Cache disabled for keyed static cache ${  cacheName
                }. This was likely a debugging change; please ensure this was intentional before releasing`);
            return this.sdHttp.get(cache.definition.endpoint);
        }

        if (cache.keyPromise === null) {
            const cacheKey = await this.sdHttp.get(`/api/cachekey/${  encodeURIComponent(cache.definition.cacheKey)}`);

            if (cache.resultPromise === null) {
                const endpoint = CacheManager.buildCacheURL(cache.definition.endpoint, cacheKey, cache.definition.debugMode);
                return await this.sdHttp.get(endpoint, { cache: true });
            }
        }
    }
}

@Injectable(
    { providedIn: 'root' }
)
export class CacheManagerService {
    constructor(private sdHttp: SDHttpService, private readonly _timer: TimerService) {
        this.buildCacheMap = new Map<string, Promise<any>>();
        this.buildTimestampPromise = null;
    }

    private buildCacheMap: Map<string, Promise<any>>;
    private buildTimestampPromise: Promise<string>;

    createCacheManager(): CacheManager {
        return new CacheManager(this.sdHttp);
    }

    getAPIBuildTimestamp(): Promise<string> {
        if (this.buildTimestampPromise === null) {
            this.buildTimestampPromise = this.sdHttp.get('/api/CacheKey/APIBuildTime').then(ts => {
                // Convert the result to hex; the result is something like "Tue 05/29/2018 12:54:23.66",
                // which is not great in a URL. We should probably try to get the API build to produce a
                // real integer timestamp instead at some point.
                return ts.split('').map(c => {
                    let hex = c.charCodeAt(0).toString(16).toLowerCase();
                    if (hex.length < 2) {
                        hex = `0${  hex}`;
                    }
                    return hex;
                }).join('');
            });
        }

        return this.buildTimestampPromise;
    }

    buildCacheGet<T>(url: string, debugMode): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            this._timer.setTimeout(() => {
                this.getAPIBuildTimestamp().then(ts => {
                    if (!this.buildCacheMap.has(url)) {
                        const cachedEndpoint = CacheManager.buildCacheURL(url, ts, debugMode);
                        this.buildCacheMap.set(url, this.sdHttp.get(cachedEndpoint, { cache: true }));
                    }

                    this.buildCacheMap.get(url).then(resolve, reject);
                });
            }, 1);
        });
    }
}
