import { Component, OnInit } from '@angular/core';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { StateSummary } from '../../Common/States/state.model';
import { StateService } from '../../Common/States/States.Service';
import { AssessorCollectorService } from '../assessor.collector.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { RestrictData, RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { HelpService } from '../../UI-Lib/Help-Tooltip';
import { COLLECTOR_HELP } from './collector.help';
import { TaxRatesService } from '../Tax-Rates/tax.rates.service';
import { CompanyService } from '../../Entity/Company/company.service';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';

@Component({
    selector: 'collector',
    templateUrl: './collector.component.html'
})
export class CollectorComponent implements OnInit {
    constructor(
        private readonly _route: UpgradeNavigationServiceHandler,
        private readonly _stateService: StateService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _restrictService: RestrictService,
        private readonly _helpService: HelpService,
        private readonly _taxRatesService: TaxRatesService,
        private readonly _companyService: CompanyService,
        private readonly _featureFlagsService: FeatureFlagsService

    ) {}

    entityId: number;
    companyId: number;
    viewOnly: boolean;
    isTaxRatesMaximized: boolean;
    isCompanyCollector: boolean;
    breadcrumbs: BreadCrumb[] = [];
    // if we find there are more states with tax districts, then we can move this flag to the backend
    // but for now, we have been asked to hard-code this to indiana assessors
    allowTaxingDistricts: boolean = false;
    collector: any;
    entityProperties: any;

    private readonly COLLECTOR = 'collector';
    private _states: StateSummary[];
    private _state: StateSummary;
    private _companyName: string;

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(COLLECTOR_HELP);
        this.companyId = +this._route.getQuerystringParam('companyID');
        this.isCompanyCollector = !!this.companyId;

        if (this.isCompanyCollector) {
            const restrictionData = {
                roles: null,
                instanceRights: [],
                explicitInstanceId: null,
                isCompany: true,
                entityId: this.companyId,
                flag: Core.AccessRightsEnum.Write
            } as RestrictData;

            const hasPermission = await this._restrictService.hasPermission(restrictionData);
            this.viewOnly = !hasPermission;
            this._companyName = await this._companyService.getCompanyName(this.companyId);
        } else {
            this.viewOnly = this._restrictService.isNotInRoles([Roles.COLLECTOREDIT]);
        }

        this.entityId = +this._route.getQuerystringParam(this.isCompanyCollector ? 'collectorID' : 'stateCollectorId');

        const [states, collector]: [StateSummary[], any] = await Promise.all([
            this._stateService.getSummary(),
            this._loadCollector()
        ]);

        // states response
        this._states = states;

        // assessor-collector response
        this.collector = collector;
        this._state = this._states.find((state) => {
            return state.stateID === this.collector.stateID;
        });
        this._setBreadcrumbs();

        this.entityProperties = {
            id: this.entityId,
            type: this.COLLECTOR,
            newAllowed: false,
            name: this.collector.name
        };
    }

    toggleTaxRatesMaximize(e: any): void {
        this.isTaxRatesMaximized = e as unknown as boolean;
    }

    async refreshCollector(): Promise<void> {
        this.collector = await this._loadCollector();
    }

    private async _loadCollector(): Promise<any> {
        const extraParam = this.isCompanyCollector ? `?companyId=${this.companyId}`: null;

        return await this._assessorCollectorService.getUpdatedCollector(this.entityId, extraParam);
    }

    private _setBreadcrumbs(): void {
        let target: string, options, crumb: BreadCrumb;
        if (this.isCompanyCollector) {
            target = this._assessorCollectorService.selectedCollectorLetter ? 'companyCollectors.letter' : 'companyCollectors';
            options = { companyID: this.companyId, letter: this._assessorCollectorService.selectedCollectorLetter };
            crumb = {
                name: this._companyName,
                target: 'company',
                options: { companyId: this.companyId }
            };
        } else {
            target = this._assessorCollectorService.selectedCollectorLetter ? 'collectors.letter' : 'collectors';
            options = { stateId: this._state.stateID, letter: this._assessorCollectorService.selectedCollectorLetter };
            crumb = {
                name: this._state.fullName,
                target: 'state',
                options: { stateId: this._state.stateID }
            };
        }
        this.breadcrumbs = [
            crumb,
            {
                name: 'Collectors',
                target,
                options
            },
        ];
    }


}
