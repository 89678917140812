<ws-modal-wrapper headerLabel="Save Report"
                  [disableSave]="!report || !report.variantName || (report.categoryID === -1 && !newCategoryName)"
                  (save)="save()"
                  (cancel)="cancel()">
    <loading-spinner [show]="loading" [size]="4"></loading-spinner>
    <div *ngIf="!loading && report" class="mb-4">
        <input type="hidden" *ngIf="!hasEditPermission" [(ngModel)]="isSystemReport" [value]="false" />
        <div *ngIf="hasEditPermission && !isComplianceReport" class="row mb-4 align-items-center">
            <label class="col-md-2 control-label">Type:</label>
            <div class="col-md-10">
                <div class="radio" style="display: inline-block;">
                    <label>
                        <input type="radio" [(ngModel)]="isSystemReport" [value]="false" (click)="reportTypeClicked()"> User Report
                    </label>
                </div>
                <div class="radio" style="display: inline-block; margin-left: 20px;">
                    <label>
                        <input type="radio" [(ngModel)]="isSystemReport" [value]="true" (click)="reportTypeClicked()"> System Report
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="!isComplianceReport" class="row mb-4 align-items-center">
            <label class="col-md-2 control-label">Category:</label>
            <div class="col-md-10">
                <div class="input-group">
                    <select *ngIf="isSystemReport" class="form-select form-select-sm w-auto flex-grow-0" [(ngModel)]="report.categoryID">
                        <option *ngFor="let category of systemReportCategories" [ngValue]="category.categoryId">
                            {{category.categoryName}}
                        </option>
                        <option [ngValue]="-1">(new category...)</option>
                    </select>
                    <select *ngIf="!isSystemReport" class="form-select form-select-sm w-auto flex-grow-0" [(ngModel)]="report.categoryID">
                        <option *ngFor="let category of userReportCategories" [ngValue]="category.categoryId">
                            {{category.categoryName}}
                        </option>
                        <option [ngValue]="-1">(new category...)</option>
                    </select>
                    <input *ngIf="report.categoryID === -1" type="text" class="form-control"
                           [(ngModel)]="newCategoryName" style="margin:5px 0;" maxlength="100" />
                    <!--<span class="input-group-btn">
                        <div class="btn-group" uib-dropdown auto-close="outsideClick" on-toggle="vm.toggled(open)" is-open="vm.enteringCategory">
                            <button class="btn btn-secondary" type="button" uib-dropdown-toggle ng-disabled="vm.categoryEntered">New</button>
                            <ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="single-button">
                                <li role="menuitem">
                                    <a href>
                                        <input type="text" id="new-search-category" class="form-control" sd-enter="vm.createNewCategory()" ng-model="vm.newCategory">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </span>-->
                </div>
            </div>
        </div>
        <div class="row mb-4 align-items-center">
            <label class="col-md-2 control-label">Name:</label>
            <div class="col-md-10">
                <span style="float:left; margin-top: 5px;">{{report.displayName}} - </span>
                <input type="text"
                       maxlength="50"
                       class="form-control"
                       style="float:left; margin-left:1em; width:285px;"
                       [(ngModel)]="report.variantName"
                       #name="ngModel"/>
                <span *ngIf="name.errors?.['maxlength']" class="text-danger">
                    The maximum length for the name is 50 characters
                </span>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
