<div class="d-flex flex-column ws-flex-auto" *ngIf="isInitialized">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Factor Tables
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div><small class="me-2">View:</small></div>
                    <div dropdown container="body" placement="bottom right">
                        <button id="button-basic" dropdownToggle type="button" class="ace-btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-alignment">
                            {{ selectedGridSourceOption.name }}
                            <span class="caret"></span>
                        </button>
                        <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-alignment">
                            <li role="menuitem" *ngFor="let gso of gridSourceOptions">
                                <a class="pointer" (click)="setSelectedGridSourceOption(gso)">{{ gso.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canExportAttachments">
                    <button type="button" class="ace-btn btn-sm btn-primary" (click)="exportAttachments()" title="Export factor table pdf attachments">Export</button>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button type="button" class="ace-btn btn-sm btn-primary" (click)="addNew()" title="New Factor Table">New Factor Table</button>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" (click)="refresh()" [disabled]="refreshing" title="Refresh"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column ws-flex-auto">
        <div class="d-flex filter-row">
            <div class="d-flex filter-group align-items-center">
                <label class="ws-flex-auto my-0" for="selectedState">State:</label>
                <div class="ws-flex-auto">
                    <ws-select labelProperty="stateName"
                               [returnEntireOption]="true"
                               placeholder="-- Select --"
                               [canDeselect]="true"
                               deselectLabel="-- Select --"
                               [valueFormatter]="formatStates"
                               [formControl]="selectedState"
                               [options]="states"
                               (selectedOptionChanged)="onSelectedStateChanged()">
                    </ws-select>
                </div>
            </div>
            <div *ngIf="selectedGridSourceOption.value === GridSourceOptionEnum.Company" class="d-flex filter-group align-items-center">
                <label class="ws-flex-auto my-0" for="company">Company:</label>
                <div class="ws-flex-auto">
                    <div class="input-group">
                        <input type="text"
                               class="form-control"
                               autocomplete="off"
                               placeholder="Filter Companies..."
                               container="body"
                               [typeaheadWaitMs]="750"
                               [(ngModel)]="companyFilter"
                               [typeahead]="companiesDataSource$"
                               typeaheadOptionField="companyName"
                               [typeaheadOptionsLimit]="100"
                               [typeaheadMinLength]="1"
                               [typeaheadScrollable]="true"
                               (typeaheadOnSelect)="onCompanySelected($event)"
                               (typeaheadLoading)="onCompanyLoadingChange($event)"
                               (blur)="onCompanyBlur()"/>
                        <span class="input-group-text" [style.visibility]="companiesLoading ? 'visible' : 'hidden'">
                            <i class="fa fa-spin fa-spinner"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex filter-group align-items-center">
                <label class="ws-flex-auto my-0">Type:</label>
                <div class="ws-flex-auto">
                    <label class="radio-inline" *ngFor="let tableType of tableTypes" [for]="tableType.name">
                        <input type="radio" name="tableTypeRadioGroup" [id]="tableType.name" [value]="tableType" [(ngModel)]="selectedTableType" (change)="onSelectedTableTypeChange()">{{tableType.name}}
                    </label>
                </div>
            </div>
            <div class="d-flex filter-group align-items-center">
                <label class="ws-flex-auto my-0" for="selectedTaxYear">Tax Year:</label>
                <div class="ws-flex-auto">
                    <ws-select name="selectedTaxYear"
                               [isPrimitive]="true"
                               [returnEntireOption]="true"
                               [formControl]="selectedTaxYear"
                               [options]="taxYears"
                               (selectedOptionChanged)="onSelectedTaxYearChanged()">
                    </ws-select>
                </div>
            </div>
            <div class="d-flex filter-group align-items-center">
                <label class="ws-flex-none my-0">
                    <input type="checkbox"
                           name="tableIncludeRetiredTables"
                           [(ngModel)]="includeRetiredTables"
                           (change)="onIncludeRetiredTablesChanged()">
                    Include Retired Tables
                </label>
            </div>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [exportOptions]="exportOptions"
                            [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>
