import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import * as _ from 'lodash';
import { lastValueFrom, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UserSettingsService } from '../Account/userSettings.service';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { CalendarWidgetFieldIds, Dashboard, RefreshPolicies, UserWidget, WidgetCache } from './dashboard.model';
import { DashboardCalendarEventTypes } from './Widgets/Calendar-Widget/calendar.widget.model';

interface WidgetDataLookup {
    [userWidgetId: number]: WidgetCache;
}
@Injectable()
export class DashboardDataService {
    constructor(private readonly http: HttpClient,
                private readonly _userSettingsService: UserSettingsService,
                private readonly _featureFlagsService: FeatureFlagsService
    ){}

    RefreshPolicies = RefreshPolicies;

    readonly eventTypeTitleMap = new Map<DashboardCalendarEventTypes, string>([
        [DashboardCalendarEventTypes.AbatementComplianceDeadline, 'Compliance Filing'],  // used to be Abatement filing, but now it represents all compliance filings
        [DashboardCalendarEventTypes.FreeportComplianceDeadline, 'FreePort Filing'],
        [DashboardCalendarEventTypes.MiscFilingComplianceDeadline, 'Misc Filing'],
        [DashboardCalendarEventTypes.PPReturnComplianceDeadline, 'PP Return Filing'],
        [DashboardCalendarEventTypes.PollutionControlComplianceDeadline, 'Pollution Control Filing'],
        [DashboardCalendarEventTypes.AppealFormalHearing, 'Formal Hearing'],
        [DashboardCalendarEventTypes.AppealInformalHearing, 'Informal Hearing'],
        [DashboardCalendarEventTypes.AppealSubmitEvidence, 'Submit Evidence Date'],
        [DashboardCalendarEventTypes.AppealDeadline, 'Appeal Deadline'],
        [DashboardCalendarEventTypes.PaymentDueDate, 'Payment Due Date'],
        [DashboardCalendarEventTypes.AppealConfirmHearing, 'Confirm Hearing']
    ]);

    userWidgetResize$: Subject<number> = new Subject<number>();
    resizeGrid$: Subject<void> = new Subject<void>();
    showDashboardToggled$: Subject<boolean> = new Subject<boolean>();

    private widgetDataLookup: WidgetDataLookup = {};
    private widgetUrl: string = '/api/dashboard/userwidgets/';
    private readonly _showDashboardSettingName: string = 'Landing-Page';
    private readonly _showDashboardSettingGroupId: number = 24;


    getDashboard(): Promise<Dashboard> {
        return lastValueFrom(this.http.get<Dashboard>('/api/dashboard').pipe(
            tap(data => console.log(data))));
    }

    resetDashboard(): Promise<Dashboard> {
        return lastValueFrom(this.http.put<Dashboard>('/api/dashboard/reset', null));
    }

    addToDashboard(widgetId: number, firstPossiblePosition: GridsterItem): Promise<Dashboard> {
        return lastValueFrom(this.http.post<Dashboard>(this.widgetUrl, {
            widgetId: widgetId,
            y: firstPossiblePosition.y,
            x: firstPossiblePosition.x,
            rows: firstPossiblePosition.rows,
            cols: firstPossiblePosition.cols
        }));
    }

    removeFromDashboard(widgetId: number): Promise<Dashboard> {
        return lastValueFrom(this.http.delete<Dashboard>(this.widgetUrl + widgetId));
    }

    updateUserWidget(userWidget: UserWidget) {
        return lastValueFrom(this.http.put(this.widgetUrl + userWidget.userWidgetId,
            _.pick(userWidget, ['x', 'y', 'rows', 'cols']),
            { responseType: 'text' }));
    }

    async getWidgetData(userWidget: UserWidget, params?: object): Promise<WidgetCache> {
        if(!params && this.widgetDataLookup[userWidget.userWidgetId]) {
            return Promise.resolve(this.widgetDataLookup[userWidget.userWidgetId]);
        }

        let httpParams: HttpParams = new HttpParams();

        if(params) {
            Object.keys(params).forEach(function (key) {
                httpParams = httpParams.set(key, params[key]);
           });
        }

        const data = await lastValueFrom(this.http.get<any>(this.widgetUrl + userWidget.userWidgetId, { params: httpParams }));
        const widgetCache = {
            data: data,
            params: params
        };

        if(userWidget.widget.refreshPolicy === RefreshPolicies.InMemoryUI && (!params || _.isEmpty(params))) {
            this.widgetDataLookup[userWidget.userWidgetId] = widgetCache;
        }

        return widgetCache;
        // let dataSub$ = this.http.get<any>(this.widgetUrl + userWidget.userWidgetId, {params: httpParams}).pipe(
        //     map(data => {
        //         return {
        //             data: data,
        //             params: params
        //         }
        //     }))

        // dataSub$.subscribe((widgetCache: WidgetCache) => {
        //     if(userWidget.widget.refreshPolicy === RefreshPolicies.InMemoryUI) {
        //         this.widgetDataLookup[userWidget.userWidgetId] = widgetCache;
        //     }
        // });

        // return dataSub$;
    }

    getWidgetSettings(userWidgetId: number): Promise<object> {
        return lastValueFrom(this.http.get<object>(`${this.widgetUrl + userWidgetId  }/settings`));
    }

    saveWidgetSettings(userWidgetId: number, widgetSettings: object): Promise<any> {
        return lastValueFrom(this.http.put(`${this.widgetUrl + userWidgetId  }/settings`,
            _.omit(widgetSettings, 'version'),
            { responseType: 'text' }));
    }

    getShowDashBoardUserSetting(): boolean {
        const settings = this._userSettingsService.getSettingsByGroup(this._showDashboardSettingGroupId);
        const setting = _.find(settings, (x: any) => x.name === this._showDashboardSettingName);
        return setting ? setting.value : false;
    }

    async updateShowDashboardUserSetting(showDashboard: boolean): Promise<void> {
        const settings = this._userSettingsService.getSettingsByGroup(this._showDashboardSettingGroupId);
        const setting = settings.find(x => x.name === this._showDashboardSettingName);
        const userSetting: any = {
            id: setting ? setting.id : 0,
            name: this._showDashboardSettingName,
            value: showDashboard,
            groupId: this._showDashboardSettingGroupId,
            folderId: Core.UserSettingFolder.Default
        };
        await this._userSettingsService.save(userSetting);

        this.showDashboardToggled$.next(showDashboard);
    }

    async getSmartAvFields(): Promise<CalendarWidgetFieldIds> {
        return lastValueFrom(this.http.get(`${this.widgetUrl}SmartAvFields`)) as Promise<CalendarWidgetFieldIds>;
    }

    async saveSmartAvFields(calendarWidgetFields: CalendarWidgetFieldIds): Promise<any> {
        return lastValueFrom(this.http.put(`${this.widgetUrl}SmartAvFields`, calendarWidgetFields));
    }

    async getSmartAvFieldsDefault(): Promise<CalendarWidgetFieldIds> {
        return lastValueFrom(this.http.get(`${this.widgetUrl}SmartAvFields/default`)) as Promise<CalendarWidgetFieldIds>;
    }
}
