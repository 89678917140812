import { Component, forwardRef, Input, ViewChild, ElementRef, Output, EventEmitter, DoCheck } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Switch
 *
 * Allows the user to toggle a boolean value
 *
 * Tied into ControlValueAccessor for integration with native Angular FormControls and ngModel
 *
 * Example usage:
 * <ws-switch onLabel="show"
 *            offLabel="hide"
 *            onTextColor="white"
 *            onColor="green"
 *            [formControl]="contentDisplayToggle">
 * </ws-switch>
 *
 */
@Component({
    selector: 'ws-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchComponent),
            multi: true
        }
    ]
})
export class SwitchComponent implements DoCheck, ControlValueAccessor {

    @Input() onLabel: string = '';
    @Input() offLabel: string = '';
    @Input() onTextColor: string = '#fff';
    @Input() offTextColor: string = '#555';
    // @Input() onColor: string = '#0E247E';
    @Input() onColor: string = '#5373b4';
    @Input() offColor: string = '#eeeeee';
    @Input() size: string;
    @Input() title: string;

    // for angularjs downgrade
    @Input() active: boolean = true;

    @Output() selectedOptionChanged: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('optionOne', { static: true }) optionOne: ElementRef;
    @ViewChild('optionTwo', { static: true }) optionTwo: ElementRef;

    toggleWidth: number = 0;
    switchWrapper: string = '0px';
    switchContainer: string = '0px';

    // Angular control value accessor methods
    value: boolean = false;

    disabled: boolean;

    ngDoCheck(): void {
        const opt1 = this.optionOne.nativeElement.offsetWidth;
        const opt2 = this.optionTwo.nativeElement.offsetWidth;
        this.toggleWidth = Math.ceil((this.onLabel.length > this.offLabel.length) ? opt1 : opt2);

        this.switchWrapper = `${(this.toggleWidth + 25)}px`;
        this.switchContainer = `${((this.toggleWidth * 2) + 27)}px`;
    }

    writeValue(value: boolean): void {
        this.value = value;
    }

    setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    next(): void {
        this.value = !this.value;
        this.onChange(this.value);
        this.selectedOptionChanged.emit(this.value);
        this.onTouched();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    // Switch methods

    toggleSwitch(): boolean {
        if (this.disabled || !this.active) {
            return;
        }
        this.next();
        return false; // prevent propagation
    }

    private onChange: (val: boolean) => void = (val) => { return null; };
    private onTouched: () => void = () => { return null; };

}
