<ws-modal-wrapper headerLabel="Assessment Details & Taxes"
                  (cancel)="cancel()"
                  [hideFooter]="true">
    <div class="assessment-taxes-details">
        <div class="text-center" style="margin-top: 40px;" *ngIf="isLoading && !gridData.rows">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <i class="fa fa-spinner fa-pulse fa-5x" style="position: absolute; top: 20%; left: 45%; z-index: 2;"
           *ngIf="isLoading && gridData.rows"></i>
        <table class="table table-condensed table-striped table-bordered" [class.table-loading]="isLoading">
            <thead>
            <tr class="active">
                <th *ngFor="let header of gridData.headers; let i = index;"
                    [class.text-center]="i > 0">{{ header }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-end" *ngFor="let row of gridData.rows" [class.is-total]="row.isTotal">
                <td class="text-start">{{ row.label }}</td>
                <td *ngFor="let field of row.fields" [class.estimated]="field.isEstimated"
                    [class.under-appeal]="field.isUnderAppeal"
                    [class.text-center]="field.type === dataTypes.date">{{ field.displayValue }}
                </td>
            </tr>
            </tbody>
        </table>
        <div class="card panel-flat-color secondary-panel small-panel">
            <div class="panel-flat-header small-header">
                <h3>Criteria</h3>
                <div class="float-end header-button-container" style="margin-top:-3px;">
                    <button *ngIf="!savingSettings" type="button" class="ace-btn btn-text has-icon btn-primary"
                            (click)="saveSettings()">
                        <i class="fa fa-save"></i>
                    </button>
                    <button *ngIf="savingSettings" type="button" class="ace-btn btn-text has-icon btn-primary"
                            disabled>
                        <i class="fa fa-spinner fa-pulse"></i>
                    </button>
                    <button type="button" class="ace-btn btn-text has-icon btn-primary" (click)="loadGrid()">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row" *ngIf="criteria">
                    <div class="col-lg-6">
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <label>Tax Years:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.fromYear">
                                        <option *ngFor="let year of getSelectableYears(true)"
                                                [ngValue]="year">{{ year }}
                                        </option>
                                    </select>
                                    to
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.toYear">
                                        <option *ngFor="let year of getSelectableYears(false)"
                                                [ngValue]="year">{{ year }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Value Details:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.valueDetails">
                                        <option *ngFor="let value of valueDetailsOptions; let i = index;"
                                                [ngValue]="i">{{ value }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Break Out Tax Payment Details:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.breakOutTaxPaymentDetails">
                                        <option
                                            *ngFor="let value of breakOutTaxPaymentDetailsOptions; let i = index;"
                                            [value]="i">{{ value }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Show % Change:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.showPercentChange">
                                        <option *ngFor="let value of showPercentChangeOptions; let i = index;"
                                                [value]="i">{{ value }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="criteria.showLienDate"> Show Lien Date
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    <label>Include Assessed Value Info:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.includeAssessedValueInfo">
                                        <option
                                            *ngFor="let value of includeAssessedValueInfoOptions; let i = index;"
                                            [value]="i">{{ value }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Include Target Value:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.includeTargetValue">
                                        <option *ngFor="let value of includeTargetValueOptions; let i = index;"
                                                [value]="i">{{ value }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Include Valuation Metrics:</label>
                                </td>
                                <td>
                                    <select class="form-select form-select-sm"
                                            style="width: auto; display: inline-block;"
                                            [(ngModel)]="criteria.includeValuationMetrics">
                                        <option
                                            *ngFor="let value of includeValuationMetricsOptions; let i = index;"
                                            [value]="i">{{ value }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="criteria.includePPPropertyType"> Include PP
                                Property Type
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="criteria.includePaymentDueDates"> Include
                                Payment Due Dates
                            </label>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox" [(ngModel)]="criteria.includeAlternateAssessments">
                                Include Alternate Assessments
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
