import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForReturnSettingsReportListComponentGridAction extends ICellRendererParams {
    edit: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => void;
    getEditTitle: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => string;
    canEdit: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => boolean;
    canEnterEditMode: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => boolean;
    canRemove: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => boolean;
    remove: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => void;
    canUndoRemove: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => boolean;
    undoRemove: (params: ICellRendererParamsForReturnSettingsReportListComponentGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
    <div class="grid-action-button-wrapper" [title]="params.getEditTitle(params)">
        <button *ngIf="params.canEdit(params)" [disabled]="!params.canEnterEditMode(params)" type="button" class="ace-btn btn-primary has-icon grid-action-button" title="Edit" (click)="params.edit(params)">
            <i class="fa fa-pencil"></i>
        </button>
    </div>
    <i class="fa fa-fw" *ngIf="!params.canEdit(params)"></i>
    <button *ngIf="params.canRemove(params)" class="ace-btn has-icon btn-primary btn-danger grid-action-button" title="Remove" (click)="params.remove(params)">
        <i class="fa fa-times"></i>
    </button>
    <button *ngIf="params.canUndoRemove(params)" class="ace-btn has-icon success-button grid-action-button" title="Undo Remove" (click)="params.undoRemove(params)">
        <i class="fa fa-undo"></i>
    </button>
    `
})
export class ReturnSettingsReportListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForReturnSettingsReportListComponentGridAction;

    agInit(params: ICellRendererParamsForReturnSettingsReportListComponentGridAction): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
