<loading-spinner [show]="isBusy" [hasOverlay]="true" [size]="5"></loading-spinner>
<ws-modal-wrapper headerLabel="Save Search"
                  headerHelpContentId="smartSearch.save-header"
                  [acceptHelpContentId]="saveHelpContentId"
                  (save)="saveSearch()"
                  (cancel)="cancel()"
                  [disableSave]="!searchFormGroup.valid || addingNewCategory">
    <div [formGroup]="searchFormGroup">
        <div *ngIf="hasEditPermission" class="col-md-12">
            <label class="col-md-3 control-label">Type:</label>
            <div class="col-md-9">
                <div class="radio-inline control-spacing">
                    <label>
                        <input type="radio" formControlName="type" (click)="setCustomCategory()" [value]="categoryTypes.CUSTOM"> User Search
                    </label>
                </div>
                <div class="radio-inline control-spacing">
                    <label>
                        <input type="radio" formControlName="type" (click)="findSystemCategory()" [value]="categoryTypes.SYSTEM"> System Search
                    </label>
                </div>
            </div>
        </div>
        <div class="col-md-12 control-spacing">
            <label class="col-md-3 control-label">Display Type:</label>
            <div class="col-md-9">
                <div class="radio-inline ws-display-inline-block">
                    <label>
                        <input type="radio" formControlName="defaultDisplay" [value]="defaultDisplayTypes.CRITERIA">Filter
                    </label>
                </div>
                <div class="radio-inline ws-display-inline-block">
                    <label>
                        <input type="radio" formControlName="defaultDisplay" [value]="defaultDisplayTypes.RESULTS">Detailed List
                    </label>
                </div>
            </div>
        </div>
        <div class="col-md-12 control-spacing">
            <label class="col-md-3 control-label">Category:</label>
            <div class="col-md-9">
                <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <ws-select class="ws-stretch-width"
                               [options]="searchCategoryOptions"
                               [isLoading]="categoriesLoading"
                               labelProperty="categoryName"
                               valueProperty="categoryId"
                               formControlName="categoryId">
                    </ws-select>
                    <button class="ace-btn btn-sm btn-primary has-icon"
                            [disabled]="addingNewCategory || newCategoryName.value"
                            (click)="toggled()">
                        <i class="fa fa-plus fa-align"></i>
                    </button>
                </div>
                <div class="ws-flex-container-horizontal ws-flex-align-items-center new-category-panel" [class.open]="addingNewCategory">
                    <input #newCategoryNameRef type="text" id="new-search-category" class="form-control" [formControl]="newCategoryName">
                    <button class="ace-btn btn-primary btn-sm"
                            (click)="createNewCategory()"
                            [disabled]="!newCategoryName.value">Add</button>
                    <button class="ace-btn has-icon btn-primary btn-danger btn-sm"
                            (click)="toggled()">
                        <i class="fa fa-times fa-align"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <label class="col-md-3 control-label">Name:</label>
            <div class="col-md-9">
                <input #searchNameRef type="text" class="form-control" formControlName="searchName" maxlength="50" required>
                <span *ngIf="searchName.errors?.['maxlength']" class="text-danger">
                    The maximum length for the name is 50 characters
                </span>
            </div>
        </div>
    </div>
</ws-modal-wrapper>
