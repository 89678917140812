<div class="ws-flex-container-vertical ws-flex-auto">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">Test DXP</h4>
        </div>
    </div>
    <div class="ws-flex-container-vertical ws-flex-auto">
        <div>{{message}}</div>
        <button
            *ngIf="!isDisabled"
            style="width:10em;"
            class="ace-btn btn-sm btn-secondary"
            (click)="sync()">
            Sync
        </button>
    </div>
</div>
