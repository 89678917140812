<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
                   *ngIf="busyIndicatorMessageManager.count() > 0">
</ws-busy-indicator>

<ws-modal-wrapper
    headerLabel="Deactivate Instance - Pending"
    acceptLabel="OK"
    (save)="save()"
    (cancel)="cancel()"
    [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div style="display: flex; flex-direction: column">
            <div style="font-weight: 600; padding-bottom: 10px;">
                Please indicate why this instance is being deactivated.
            </div>
            <div>
                <textarea formControlName="reason" maxlength="{{maxReasonLength}}" rows="5" required></textarea>
            </div>
            <div class="small" style="display: flex; flex-direction: row; justify-content: space-between">
                <div>Instance status will be changed to 'Pending Inactive' until confirmed.</div>
                <div style="flex-shrink: 0; padding-left: 15px;">{{reasonLength}} / {{maxReasonLength}}</div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
