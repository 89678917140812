import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';

@Injectable()
export class QueryInfoRepository extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    get(queryInfoId: number): Observable<Core.QueryInfoModel> {
        return this.httpGet(`/api/queryinfo/${queryInfoId}`);
    }

    getList(searchModel: Core.QueryInfoSearchModel): Observable<Compliance.QueryResultModel<Core.QueryInfoModel>> {
        return this.httpPost('/api/queryinfo', searchModel);
    }
}
