import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForExpandCellRenderer extends ICellRendererParams {
    expand: (params: ICellRendererParamsForExpandCellRenderer) => void;
    collapse: (params: ICellRendererParamsForExpandCellRenderer) => void;
    showHiddenChildren: (params: ICellRendererParamsForExpandCellRenderer) => void;
    canExpand: (params: ICellRendererParamsForExpandCellRenderer) => boolean;
    canCollapse: (params: ICellRendererParamsForExpandCellRenderer) => boolean;
    canShowHiddenChildren: (params: ICellRendererParamsForExpandCellRenderer) => boolean;
    getName: (params: ICellRendererParamsForExpandCellRenderer) => string;
    getTooltip: (params: ICellRendererParamsForExpandCellRenderer) => string;
}

@Component({
    selector: 'expand-cell',
    template: `
<span class="expand-controls">
    <span class="fa node-expanded" *ngIf="params.canCollapse(params)" (click)="params.collapse(params)" title="Collapse"></span>
    <span class="fa node-collapsed" *ngIf="params.canExpand(params)" (click)="params.expand(params)" title="Expand"></span>
</span>
<span [title]="params.getTooltip(params)">{{ params.getName(params) }}</span>
<a (click)="params.showHiddenChildren(params)" *ngIf="params.canShowHiddenChildren(params)" title="Show Children Unfiltered"> ( +  )</a>`
})
export class ExpandCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForExpandCellRenderer;

    agInit(params: ICellRendererParamsForExpandCellRenderer): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
