import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { ActionViewService } from '../../Task/ActionView/action-view-service.upgrade';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { LandingViewTypes } from '../landing.component';

@Component({
    selector: 'overview-single-table',
    templateUrl: './overview-single-grouping.component.html'
})
export class OverviewSingleGroupingComponent implements OnChanges {
    constructor(private actionViewService: ActionViewService,
                private actionViewPersistenceService: ActionViewPersistenceService,
                private upgradeNavigationService: UpgradeNavigationServiceHandler) {}

    @Input() viewMode: LandingViewTypes;

    filter: any;
    drilldownFilter: any;
    tableData: any;
    headerNames: string[] = [];
    isLoading: boolean = true;
    actionViewTimeout: boolean = false;

    ngOnChanges(changes: SimpleChanges): void {
        const changedKeys = Object.keys(changes);
        if (changedKeys.indexOf('viewMode') >= 0) {
            this.executeActionView();
        }
    }

    drilldownCell(cell: any, ready: string, task: any): void {
        this.actionViewPersistenceService.clearSavedData();
        //step 1: set drilldown persistenceservice variable
        this.actionViewPersistenceService.externalDrilldown = true;
        this.actionViewPersistenceService.masterFilter = _.cloneDeep(this.filter);
        this.actionViewPersistenceService.drilldownFilter = _.cloneDeep(this.filter);
        this.actionViewPersistenceService.externalDrilldownData = {
            ready: ready,
            item: cell,
            task: task
        };

        this.upgradeNavigationService.go('actionview', {});
    }

    goToActionView(): void {
        this.actionViewPersistenceService.clearSavedData();
        this.actionViewPersistenceService.masterFilter = this.filter;
        this.upgradeNavigationService.go('actionview', {});
    }

    private async executeActionView() {
        this.isLoading = true;

        try {
            const result = await this.actionViewService.welcomeSearch(this.viewMode);
            this.filter = result.originalRequest;

            if (result.isCancelled) {
                this.actionViewTimeout = true;
            }
            else {
                this.tableData = _.map(result.group1ResultList, (task: any) => {
                    task.data = _.map(task.data, (item: any) => {
                        item.colClass = this.actionViewService.getUrgencyColumnStyleClass(item.sequence);
                        return item;
                    });
                    return task;
                });

                if (this.tableData.length > 0) {
                    const orderedHeaders: any[] = this.tableData[0].data;
                    this.headerNames = _.chain(orderedHeaders)
                        .sortBy('sequence')
                        .map('label')
                        .value();
                }
                this.drilldownFilter = _.cloneDeep(this.filter);
            }
        } finally {
            this.isLoading = false;
        }
    }
}
