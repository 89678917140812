<div class="ws-flex-container-vertical ws-flex-auto">
    <ws-bread-crumbs *ngIf="companyName" [breadcrumbs]="breadcrumbs" currentPageTitle="Import specifications"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                Data Import Specifications
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <a href="./assets/ImportFieldDefinitions.xlsx">Download Import Field Definitions</a>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="ace-btn btn-sm btn-secondary has-icon"
                            (click)="refresh()"
                            [disabled]="refreshing"
                            helpTooltip
                            helpContentId="app.refresh"
                            position="bottom"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-flex-container-vertical ws-flex-auto">
        <div class="ws-flex-container-horizontal filter-row">
            <div class="ws-flex-container-horizontal filter-group ws-flex-align-items-center">
                <label class="ws-flex-none small ws-font-weight-normal"
                       helpTooltip
                       helpContentId="import-specification-list.include-disabled"
                       position="bottom">
                    <input type="checkbox" [formControl]="includeDisabled" (change)="onIncludeDisabledChanged()"> Include Disabled
                </label>
            </div>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>
