import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { InstanceRights, RestrictService } from '../../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../AgGrid';
import {
    AgGridLinkCellRendererComponent,
    AgGridLinkCellRendererParams
} from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AllocationRepository } from '../../Repositories';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AddAllocationModalComponent, AddAllocationModalParams } from './Add-Allocation/addAllocationModal.component';
import {
    AllocationListGridActionCellRendererComponent,
    ICellRendererParamsForAllocationListGridAction
} from './agGridActionCellRenderer.component';
import { AllocationListAgGridDataSource, AllocationListDataSourceParams } from './agGridDataSource';
import { ALLOCATION_LIST_HELP } from './allocationList.component.help';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../AgGrid/ToolPanel/models';
import { lastValueFrom } from 'rxjs';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;

@Component({
    selector: 'allocation-list',
    templateUrl: './allocationList.component.html',
    styleUrls: ['./allocationList.component.scss']
})
export class AllocationListComponent implements OnInit {
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _companyService: CompanyService,
        private readonly _allocationRepository: AllocationRepository,
        private readonly _restrictService: RestrictService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _messageModalService: MessageModalService,) { }

    breadcrumbs: BreadCrumb[] = [];
    companyId: number;
    companyName: string;

    isInitialized: boolean;
    canEdit: boolean;
    includeCompleted: boolean;
    refreshing: boolean;

    gridId: System.Guid = '70EC2321-804B-40E3-A32D-CA8C5C943D95';
    gridOptions: GridOptions = new AgGridOptionsBuilder(
            {
                rowClassRules: {
                    'row-completed': (params) => {
                        const gridRow = params.data as Compliance.FilingBatchModel;
                        return gridRow && gridRow.completedDate !== null;
                    }
                }
            }).buildDefault(this);

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();
            const exportModel: Compliance.AllocationExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                fileFormat
            };

            const lrp$ = this._allocationRepository.startExport(this.companyId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportAllocations };
        },
        canCancel: true,
        showFileFormatSelection: true
    };

    private _gridApi: GridApi;
    private _gridDataSource: AllocationListAgGridDataSource;

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(ALLOCATION_LIST_HELP);

        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        try {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));

            const responses = await Promise.all([
                this._companyService.load(this.companyId, false, false),
                this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write)
            ]);

            const company = responses[0];

            if (!(company.ppReturnPreparationAllowed && this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, company.instanceID))) {
                this.navigateToUnauthorized();
                return Promise.resolve();
            }

            this.companyName = company.companyName;
            this.canEdit = responses[1];

            this.breadcrumbs.push({
                name: this.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            });
        } finally {
            busyRef.hide();
        }

        this.isInitialized = true;
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Description',
                field: 'description',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth
            },
            {
                headerName: 'Company Name',
                field: 'companyName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const allocation = params.data as Compliance.AllocationModel;
                        if (!allocation) {
                            return '';
                        }
                        return `#/company/${allocation.companyId}`;
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                headerName: 'Tax Year',
                field: 'taxYear',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.numericColumnWidth
            },
            {
                headerName: 'State',
                field: 'stateAbbr',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.stateAbbreviationColumnWidth
            },
            {
                headerName: 'Site',
                field: 'siteName',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const allocation = params.data as Compliance.AllocationModel;
                        if (!allocation) {
                            return '';
                        }
                        return `#/site/${allocation.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const allocation = params.data as Compliance.AllocationModel;
                        if (!allocation) {
                            return '';
                        }
                        return `#/site/${allocation.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(4),
                minWidth: AgGridColumns.getActionColumnWidth(4),
                maxWidth: AgGridColumns.getActionColumnWidth(4),
                lockPinned: true,
                suppressColumnsToolPanel: true,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                sortable: false,
                cellRendererFramework: AllocationListGridActionCellRendererComponent,
                cellRendererParams: {
                    viewDetails: this._allocationSelected.bind(this),
                    delete: this._delete.bind(this),
                    canDelete: this._canDelete.bind(this),
                    canEdit: this.canEdit
                } as ICellRendererParamsForAllocationListGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'dueDate',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setDataSource();
    }

    async addNew(): Promise<void> {
        const params: AddAllocationModalParams = {
            companyId: this.companyId
        };

        const result = await this._modalService.showAsync(AddAllocationModalComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this._navigateToDetail(result.allocationId);

        return Promise.resolve();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    onIncludeCompletedChange(): void {
        this._refreshDataSource();
    }

    navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    private _allocationSelected(row: ICellRendererParamsForAllocationListGridAction): void {
        const allocation = row.data as Compliance.AllocationModel;
        this._navigateToDetail(allocation.allocationId);
    }

    private _navigateToDetail(allocationId: number): void {
        this._routerService.go('allocationDetails', { allocationId });
    }

    private _canDelete(params: ICellRendererParamsForAllocationListGridAction): boolean {
        const allocation = params.data as Compliance.AllocationModel;
        if (!allocation) {
            return false;
        }

        return this.canEdit; // TODO && check if allocation not complete
    }

    private async _delete(params: ICellRendererParamsForAllocationListGridAction): Promise<void> {
        const allocation = params.data as Compliance.AllocationModel;
        if (!allocation) {
            return Promise.resolve();
        }

        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to delete the allocation?',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve();
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await lastValueFrom(this._allocationRepository.delete(allocation.allocationId));
            this._refreshDataSource();
        } finally {
            busyRef.hide();
        }

        return Promise.resolve();
    }

    private _refreshDataSource(): void {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): AllocationListDataSourceParams => {
            return {
                includeCompleted: this.includeCompleted,
                companyId: this.companyId
            };
        };

        this._gridDataSource = new AllocationListAgGridDataSource(this._gridApi, this._allocationRepository, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }
}
