import { tap } from 'rxjs/operators';
import { HttpEvent, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { AuthStorageService } from '../../../User/authStorage.service';
import { HandledError } from '../../Models/errorModels';
import { AppStates, AppStateService } from '../../../Layout/appStateService';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        @Inject('$rootScope') private _rootScope: any,
        @Inject('$location') private _location: any,
        private _authStorageService: AuthStorageService,
        private _appStateService: AppStateService
    ) {
        _appStateService.appState$.subscribe(s => this.appState = s);
    }

    private appState: AppStates;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.appState === AppStates.LoggedOut) {
            throw 'Cannot make web request when logged out';
        }

        let token: string = null;
        const inMemoryAuthData = this._authStorageService.getAuthData();
        // Check for calls to /api/token where there is an Authorization header already. In that case, we
        // want to respect the auth header override.
        if (inMemoryAuthData && !(request.url.endsWith('/api/token') && request.headers.has('Authorization'))) {
            token = inMemoryAuthData.access_token;
        }
        if (token !== null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        // All HTTP requests should count as "Activity" (unless it is refreshing the token, which happens automatically).
        if (!(request.url.indexOf('api/token') >= 0)) {
            this._authStorageService.recordActivity('Angular2+ HTTP');
        }

        return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => { return null; }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                if (error.status == 403) {
                    this._rootScope.$broadcast('navigationErrorCode403', error.message);
                }
                // WK-7767 - Same deal as the AngularJS interceptor for 401 responses; if the token changed between
                // the start and end of the request, it's a clue that this may not really be because the session died.
                // Assume this is an unexpected error in that case and let it fall through.
                else if (error.status == 401 && this._authStorageService.getAuthData().access_token === token) {
                    if (this._authStorageService.authInterceptEnabled) {
                        // The "error" property on Angular's response object actually contains the body of the
                        // response. Confusingly, there is a message property, but it is something like
                        // "Http failure response for (url): 401 Unauthorized. Usually the API will respond in a
                        // 401 with a string that explains why the user is not authorized, but not always. If
                        // it doesn't just use a generic error message.
                        let logoutMessage = 'Your session has ended unexpectedly';
                        if (error.error && typeof(error.error) === 'string') {
                            logoutMessage = error.error;
                        }
                        this._authStorageService.logoutMessage$.next(logoutMessage);
                        throw new HandledError('401 response', error);
                    }
                } else if (error.status == 503 || error.status == 502) {
                    (window as any).location.reload(true);
                }
            }
        }));
    }
}
