import { Injectable } from '@angular/core';
import { SDHttpService } from 'src/app/Common/Routing/sd-http.service';

@Injectable()
export class ApplicationPermissionService {
    constructor(private http: SDHttpService) { }

    readonly SERVICE_URL = '/api/user/role/';

    get(): Promise<any> {
        return this.http.get(this.SERVICE_URL);
    }

    getByContactId(contactId: number): Promise<any> {
        return this.http.get(`${this.SERVICE_URL}${contactId}`);
    }

    getByContactIdScoped(contactId: number): Promise<any> {
        return this.http.get(`${this.SERVICE_URL}${contactId}/scoped`);
    }

    getByGroupId(id): Promise<any> {
        return this.http.get(`/api/usergroup/${id}/roles`);
    }

}