import { Injectable } from '@angular/core';
import { SDHttpService } from 'src/app/Common/Routing/sd-http.service';

@Injectable()
export class CompanyPermissionsService {
    constructor(private http: SDHttpService) { }

    getCompaniesThatAreMissingPermissionsForGroup(groupId, pageNumber: number, pageSize: number, companyNameFilter, excludeCompanyIds: any): Promise<any> {
        if (!pageNumber) pageNumber = 1;
        if (!pageSize) pageSize = 100;
        if (!companyNameFilter || !companyNameFilter.length) companyNameFilter = null;
        if (!excludeCompanyIds) excludeCompanyIds = [];

        const serviceUrl = `/api/usergroup/${groupId}/CompaniesWithoutPermissions`;
        const config = {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                companyNameFilter: companyNameFilter
            }
        };

        return this.http.post(serviceUrl, excludeCompanyIds, config);
    }

     getCompaniesThatAreMissingPermissionsForUser(userId: string, pageNumber: number, pageSize: number, companyNameFilter, excludeCompanyIds: any): Promise<any> {
        if (!pageNumber) pageNumber = 1;
        if (!pageSize) pageSize = 100;
        if (!companyNameFilter || !companyNameFilter.length) companyNameFilter = null;
        if (!excludeCompanyIds) excludeCompanyIds = [];

        const serviceUrl = `/api/user/${userId}/CompaniesWithoutPermissions`;

        const config = {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                companyNameFilter: companyNameFilter
            }
        };

        return this.http.post(serviceUrl, excludeCompanyIds, config);
    }

    getForGroup(groupId, pageNumber: number, pageSize: number, companyNameFilter): Promise<any> {
        if (!pageNumber) pageNumber = 1;
        if (!pageSize) pageSize = 100;
        if (!companyNameFilter || !companyNameFilter.length) companyNameFilter = null;

        const serviceUrl = `/api/usergroup/${groupId}/AccessibleCompanies`;

        const config = {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                companyNameFilter: companyNameFilter
            }
        };
        return this.http.get(serviceUrl, config);
    }

    getForUser(userId, pageNumber?: number, pageSize?: number, companyNameFilter?): Promise<any> {
        if (!pageNumber) pageNumber = 1;
        if (!pageSize) pageSize = 100;
        if (!companyNameFilter || !companyNameFilter.length) companyNameFilter = null;

        const serviceUrl = `/api/user/${userId}/AccessibleCompanies`;

        const config = {
            params: {
                pageNumber: pageNumber,
                pageSize: pageSize,
                companyNameFilter: companyNameFilter
            }
        };
        return this.http.get(serviceUrl, config);
    }
}