import { Injectable } from '@angular/core';
import { CacheManagerService } from '../Common/Routing/cache-manager.service';
import { SDHttpService } from '../Common/Routing/sd-http.service';

@Injectable()
export class DescriptorHelperService {
    constructor(private http: SDHttpService, private cacheManagerService: CacheManagerService) { }

    private readonly _SetupColumns = ['Characteristic Name', 'Company Usage', 'Site Usage', 'Parcel Usage', 'Assessor Usage', 'Asset Usage', 'ARCC Usage', 'Type', 'Comments', 'Enabled'];

    getCategories(): Promise<Weissman.Model.Descriptors.DescriptorCategory[]> {
        return this.cacheManagerService.buildCacheGet('/api/descriptorcategory', false);
    }

    getFieldTypes(): Promise<Weissman.Model.Descriptors.DescriptorFieldType[]> {
        return this.cacheManagerService.buildCacheGet('/api/descriptorfieldtype', false);
    }

    getUsages(): Promise<Weissman.Model.Descriptors.DescriptorUsage[]> {
        return this.cacheManagerService.buildCacheGet('/api/descriptorusage/', false);
    }

    getFormats(): Promise<Weissman.Model.Descriptors.DescriptorFormat[]> {
        return this.cacheManagerService.buildCacheGet('/api/descriptorformat', false);
    }

    getSetup(): string[] {
        return this._SetupColumns;
    }
}
