import { Component } from '@angular/core';
import { GridOptions, GridReadyEvent, GridApi, ColDef } from 'ag-grid-community';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectedCellRenderer, AgGridMultiSelectRendererParams } from './../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { CompanyListAgGridDataSource,  CompanyListDataSourceParams} from './agGridDataSource';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AgGridFilterParams } from '../../../Compliance/AgGrid';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../Busy-Indicator';
import { PermissionService } from '../../permission.service';
import { CompanyPermissionOptions } from '../Panel/CompanyPermissionOptions';
import * as _ from 'lodash';

export interface CompanyPermissionAddParams {
    instanceId: number;
    groupId?: number;
    userId?: System.Guid;
    excludeCompanyIds: number[];
}
export interface CompanyPermissionAddResult {
    totalRows: number;
    selectedRows: Core.PermissionModel[];
    permission: CompanyPermissionOptions;
}

@Component({
    selector: 'company-permission-add',
    templateUrl: './companyPermissionAdd.component.html',
    styleUrls: ['./companyPermissionAdd.component.scss']
})
export class CompanyPermissionAddComponent implements IWeissmanModalComponent<CompanyPermissionAddParams, CompanyPermissionAddResult> {
    constructor(
        private readonly _permissionService: PermissionService,
        private readonly _modalRef: BsModalRef,
        private _busyIndicatorService: BusyIndicatorService
    ) { }

    public permissionTypes = Object.values(CompanyPermissionOptions);
    selectedPermissionType: CompanyPermissionOptions = CompanyPermissionOptions.ViewAllSites;
    excludeInactive: boolean = false;

    params: CompanyPermissionAddParams;
    result: CompanyPermissionAddResult;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    gridTracker: AgGridMultiSelectTracker;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected(params.data.entityID)
        }
    })
        .withContext(this)
        .withInfiniteScroll()
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    private _gridApi: GridApi;
    private _gridDataSource: CompanyListAgGridDataSource;

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        const columns: ColDef[] = [
            {
                headerName: '',
                field: 'entityID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Company',
                field: 'entityName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Top Level Company',
                field: 'topLevelCompany',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth
            }
        ];

        const defaultSortModel = [
            {
                colId: 'entityName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
        this._gridApi.sizeColumnsToFit();
    }

    async save(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Adding' });

        try {
            const selectedRows: Core.PermissionModel[] = [];
            const selected = await this.gridTracker.getSelectedRowIds();

            this._gridApi.forEachNode(x => {
                if(_.includes(selected, x.data.entityID)) {
                    selectedRows.push(x.data);
                }
            });

            this.result = {
                selectedRows: await selectedRows,
                permission: this.selectedPermissionType,
                totalRows: this.gridTracker.getTotalRowsCount()
            } as CompanyPermissionAddResult;

            this._modalRef.hide();
        }
        finally {
            busyRef.hide();
        }
    }

    cancel(): void {
        this.gridTracker.setSelectedRowsModel({
            selectAllRows: false,
            selectedRows: []
        });

        this._modalRef.hide();
    }

    onExcludeInactive() {
        this._refreshDataSource();
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        this.gridTracker.clear();

        const dataSourceParams = (): CompanyListDataSourceParams => {
            return {
                groupId: this.params.groupId,
                userId: this.params.userId,
                instanceId: this.params.instanceId,
                excludeCompanyIds: this.params.excludeCompanyIds,
                excludeInactive: this.excludeInactive
            };
        };

        this._gridDataSource = new CompanyListAgGridDataSource(this._gridApi, this._permissionService, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _refreshDataSource() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.refresh();
    }
}
