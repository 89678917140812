<div class="ws-flex-container-horizontal ws-flex-align-items-center">
    <label class="m-0 small ms-5 me-2" style="width: 110px;"
        helpTooltip
        helpContentId="appeal-recommendation-command-center.assigned"
        position="bottom">Assigned</label>
    <select name="selectOptions" id="selectLabel" class="form-select form-select-sm" [(ngModel)]="selectedOptionName" (change)="selectedOptionChanged()">
        <option *ngFor="let option of options" [ngValue]="option.name">{{ option.name }}</option>
    </select>
    <button type="button"
            class="ace-btn btn-sm btn-primary has-icon"
            (click)="edit()"
            helpTooltip
            helpContentId="app.edit"
            position="bottom">
        <i class="fa fa-pencil"></i>
    </button>
</div>
