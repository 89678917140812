import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { AllocationRepository } from '../../../Repositories';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export interface CreateNewMergedParcelsParams{
    allocationId: number,
    allocationDetailSelectRowsModel?: Compliance.SelectedRowsModel,
    selectedAssessmentSummaryIds?: number[]
}

export interface CreateNewMergedParcelsResult {
    assessmentsSummary: Compliance.AllocationAssessmentSummaryModel[]
}

export class CollectorOption {
    id: number;
    name: string;
    isDefault: boolean;
}

@Component({
    selector: 'create-new-merged-parcels',
    templateUrl: './create-new-merged-parcels.component.html'
})
export  class CreateNewMergedParcelsComponent implements OnInit, IWeissmanModalComponent<CreateNewMergedParcelsParams, CreateNewMergedParcelsResult> {
    constructor(private readonly _modalRef: BsModalRef,
                private readonly _allocationRepository: AllocationRepository) {
    }

    params: CreateNewMergedParcelsParams;
    result: CreateNewMergedParcelsResult;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    form: UntypedFormGroup;

    collectorErrorMessage: string;

    private _allocationAnnualAssessmentIds: number[];
    private _sites: Compliance.AllocationGetCreateNewMergedParcelsMetadataResultSite[] = [];
    private _assessors: Compliance.AllocationGetCreateNewMergedParcelsMetadataResultAssessor[] = [];
    private _collectors: Compliance.AllocationGetCreateNewMergedParcelsMetadataResultCollector[] = [];
    private _filteredCollectors: CollectorOption[] = [];

    async ngOnInit(): Promise<void> {
        const busyMsgId = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgId);

        this.form = new UntypedFormGroup({
            site: new UntypedFormControl(null, [Validators.required]),
            assessor: new UntypedFormControl(null, [Validators.required]),
            collector: new UntypedFormControl(null, [Validators.required, this.validateCollectorSelection])
        });

        try {
            let selectedRowsModel: Compliance.SelectedRowsModel;

            if (this.params.allocationDetailSelectRowsModel)
            {
                selectedRowsModel = {
                    selectedRows: this.params.allocationDetailSelectRowsModel.selectedRows,
                    selectAllRows: this.params.allocationDetailSelectRowsModel.selectAllRows
                };
            }

            const metadata = await lastValueFrom(this._allocationRepository.getCreateNewMergedParcelsMetadata(this.params.allocationId, selectedRowsModel));
            this._sites = metadata.sites;
            this._assessors = metadata.assessors;
            this._collectors = metadata.collectors;
            this._allocationAnnualAssessmentIds = this.params.allocationDetailSelectRowsModel
                ? metadata.allocationAnnualAssessmentIds
                : this.params.selectedAssessmentSummaryIds;

            if (this._sites.length === 1){
                this.form.controls['site'].setValue(this._sites[0].siteId);
                this.form.controls['site'].disable({emitEvent: false});
            } else {
                this._sites.sort((i, j) =>
                    i.siteName < j.siteName ? -1 : i.siteName > j.siteName ? 1 : 0);
            }

            if (this._assessors.length === 1){
                this.form.controls['assessor'].setValue(this._assessors[0].assessorId);
                this.form.controls['assessor'].disable({emitEvent: false});
            } else {
                this._assessors.sort((i, j) =>
                    i.assessorName < j.assessorName ? -1 : i.assessorName > j.assessorName ? 1 : 0);
            }

            this._filterCollectors();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    get sites(): Compliance.AllocationGetCreateNewMergedParcelsMetadataResultSite[] {
        return this._sites;
    }

    get assessors(): Compliance.AllocationGetCreateNewMergedParcelsMetadataResultAssessor[]{
        return this._assessors;
    }

    get collectors(): CollectorOption[] {
        return this._filteredCollectors;
    }

    onSiteChanged(): void {
        if (this._sites.length >= 1){
            this.form.controls['assessor'].setValue(null);
        }
    }

    onAssessorChange(): void {
        this._filterCollectors();
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Creating parcels', busyMsgId);

        try{
            const model: Compliance.AllocationCreateMergedParcelsModel = {
                siteId: this.form.controls['site'].value,
                assessorId: this.form.controls['assessor'].value,
                collectorBillScenarioIds: this.form.controls['collector'].value,
                allocationAnnualAssessmentIds: this._allocationAnnualAssessmentIds
            };

            const result  = await lastValueFrom(this._allocationRepository.createNewMergedParcels(this.params.allocationId, model));

            this.result = {
                assessmentsSummary: result
            };

            this._modalRef.hide();
        }
        finally{
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._modalRef.hide();
    }

    get isFormValid(): boolean {
        return this.form.valid;
    }

    validateCollectorSelection = (control: AbstractControl): {[key: string]: any} | null  => {
        if (control.value && (control.value as []).length !== 0) {
            const selectedBillScenarioIds = control.value as number[];
            const selectedCollectorBillScenarios = this._collectors.filter(i => i.assessorId === +this.form.get('assessor').value &&
                selectedBillScenarioIds.indexOf(i.collectorBillScenarioId) !== -1);
            const collectors = selectedCollectorBillScenarios.reduce((i, j) => {
                i[j.collectorId] = [...i[j.collectorId] || [], j];
                return i;
            }, []);

            if (collectors.filter(i => i.length > 1).length !== 0){
                return { 'collectorHasDuplicatesSelected': true };
            }
        }

        return null;
    };

    private _filterCollectors(): void {
        this._filteredCollectors = this._collectors
            .filter(i => i.siteId === +this.form.controls['site'].value &&
                i.assessorId === +this.form.controls['assessor'].value)
            .map(i => { return {
                id: i.collectorBillScenarioId,
                name: i.collectorBillScenarioName ? `${i.collectorName} - ${i.collectorBillScenarioName}` : `${i.collectorName}`,
                isDefault: i.isDefault} as CollectorOption;
            })
            .sort((i, j) => i.name < j.name ? -1 : i.name > j.name ? 1 : 0);

        if (this._filteredCollectors.length === 0){
            this.form.controls['collector'].disable({emitEvent: false});
        } else {
            this.form.controls['collector'].enable({emitEvent: false});

            const defaultCollectors = this._filteredCollectors.filter(i => i.isDefault).map(i => i.id);
            this.form.controls['collector'].setValue(defaultCollectors);
        }
    }
}
