import { ElementRef, NgZone, Renderer2 } from '@angular/core';
import { Subscription,  timer } from 'rxjs';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { ReturnPreviewFormOverrideEditComponent, ReturnPreviewFormOverrideEditParams } from '../../Compliance/Return/Return-Parts/Preview/Form-Viewer/Override-Edit/returnPreviewFormOverrideEdit.component';
import { ScriptLoaderService, ScriptLocations } from 'src/app/UI-Lib/Utilities/scriptLoader.service';
import { Constants } from 'src/app/constants.new';
//todo: de-couple the override edit component from compliance return module

declare const pdfjsLib: any;
declare const pdfjsViewer: any;

const MAX_RENDERED_PAGES: number = 80; // number to render at once, after double this, truncate
const FULL_FIELD_NAME_REGEX = /\]\.([^\]]*)(\[\d+\])?$/;
const ACCOUNT_PARAMETER_REGEX = /^account-\d+$/i;
const UI_ACCOUNT_INDEX_PARAMETER_REGEX = /^uiaccountindex-(\d+)$/i;

export interface FieldOverride {
    formElements: Element[];
    fieldName: string;
    overrideValue: string;
}

export class FieldOverrideHolder {
    private _savedOverrides: Map<string, Compliance.FormOverrideModel> = new Map();
    private _overrides: Map<string, FieldOverride> = new Map();

    get overrides(): FieldOverride[] {
        return Array
            .from(this._overrides.values())
            .sort((a, b) => {
                const aR = a.formElements[0].getBoundingClientRect();
                const bR = b.formElements[0].getBoundingClientRect();
                if (aR.top < bR.top) {
                    return -1;
                } else if (aR.top > bR.top) {
                    return 1;
                } else {
                    return (aR.left < bR.left) ? -1 : 1;
                }
            });
    }

    getSaved(fieldName: string): Compliance.FormOverrideModel {
        return this._savedOverrides.get(fieldName);
    }

    getFieldOverride(fieldName: string): FieldOverride {
        return this._overrides.get(fieldName);
    }

    saveOverride(override: Compliance.FormOverrideModel, fieldName?: string): void {
        this._savedOverrides.set(fieldName || override.fieldName, override);
    }

    addOverride(override: FieldOverride): FieldOverride {
        const existingOverride = this._overrides.get(override.fieldName);
        if (existingOverride) {
            override.formElements = [...existingOverride.formElements, ...override.formElements];
        }
        this._overrides.set(override.fieldName, override);
        return override;
    }

    deleteOverride(override: FieldOverride): void {
        this._overrides.delete(override.fieldName);
    }

    renumberOverrides(): void {
        const overrides = this.overrides;
        for (let i = 0, len = overrides.length; i < len; i++) {
            overrides[i].formElements.forEach(elem => {
                //const elem: Element = this.overrides[i].formElement;
                const followingElement = elem.parentElement.querySelector('.overrideInfo');
                if (followingElement) {
                    followingElement.innerHTML = `${i + 1}`;
                }
            });
        }
    }

    reset(): void {
        this._overrides = new Map();
    }
}

export abstract class PDFViewerBase {

    constructor(
        protected _PDF_OUTPUT_HOLDER_QUERY_SELECTOR: string,
        protected _modalService: WeissmanModalService,
        protected _scriptLoaderService: ScriptLoaderService,
        protected _constants: Constants,
        protected _renderer: Renderer2,
        protected _zone: NgZone
    ) { }

    public overrideHolder: FieldOverrideHolder = new FieldOverrideHolder();
    public showPreviousPagesVisible: boolean = false;
    public showNextPagesVisible: boolean = false;
    public zoomPercentage: number = 100;
    public zoomScaleUpdating: boolean;
    protected _pdfPageViews: any = {};
    protected _annotations: any = {};
    protected _fieldIdMap: { [field: string]: string } = {};
    protected _idFieldMap: { [id: string]: { fieldName: string, fullName: string, elements: Element[] } } = {};
    protected _oldFormatOverrides: { [shortFieldName: string]: { override: FieldOverride, rowVersion: number[] } } = {};
    protected _accountlessNameIdMap: { [accountlessName: string]: string[] };
    protected _multiAccountFieldMap: { [fieldName: string]: number } = {};
    protected readonly MODIFIED_FIELD_CSS_CLASS: string = 'pdf-modified-field';
    //protected _fieldDataLookup: Map<string, any> = new Map();
    protected _fieldDataLookup: { [fullName: string]: Compliance.ReturnFormFieldDataModel } = {};
    protected _fieldDataLookupByShortName: { [shortName: string]: Compliance.ReturnFormFieldDataModel } = {};
    protected _originalValues: { [fullName: string]: string } = {};
    protected _originalValuesByShortName: { [shortName: string]: string; } = {};
    protected isReadOnly: boolean = true;
    protected isAppealForm: boolean = false;
    private pdfDocument: any;
    private pageDivs: any = {};
    private scrollExtentSubscription: Subscription;
    private currentlyRenderedPageNums: Set<number> = new Set();
    private _zoomScale: number = 1.0;

    calculateLowestRenderedPage(): number {
        let lowestRenderedPage;
        const highestPageNumberInDoc = this.pdfDocument.numPages;
        for (let pageNum = 1; pageNum < highestPageNumberInDoc; pageNum++) {
            if (this.currentlyRenderedPageNums.has(pageNum)) {
                lowestRenderedPage = pageNum;
                break;
            }
        }
        return lowestRenderedPage;
    }

    calculateHighestRenderedPage() {
        let highestRenderedPage = 1;
        const highestPageNumberInDoc = this.pdfDocument.numPages;
        for (let pageNum = highestPageNumberInDoc; pageNum > 0; pageNum--) {
            if (this.currentlyRenderedPageNums.has(pageNum)) {
                highestRenderedPage = pageNum;
                break;
            }
        }
        return highestRenderedPage;
    }

    showPreviousPages() {
        let lowestRenderedPage = this.calculateLowestRenderedPage();
        const highestPageNumberInDoc = this.pdfDocument.numPages;
        for (let pageNum = 1; pageNum < highestPageNumberInDoc; pageNum++) {
            if (this.currentlyRenderedPageNums.has(pageNum)) {
                lowestRenderedPage = pageNum;
                break;
            }
        }
        if (lowestRenderedPage > 1) {
            let lowestPageToRender;
            if (lowestRenderedPage <= MAX_RENDERED_PAGES) {
                lowestPageToRender = 1;
            } else {
                lowestPageToRender = lowestRenderedPage - MAX_RENDERED_PAGES;
            }
            this.renderPagesWithAnnotations(lowestPageToRender, lowestRenderedPage - 1).then(() => {
                this.updateNextPageVisibility();
            }).then(() => {
                this.checkForPageDeletions(true);
            });
        }
    }

    showNextPages() {
        //calculate final page
        const highestRenderedPage = this.calculateHighestRenderedPage();
        const highestPageNumberInDoc = this.pdfDocument.numPages;
        if (highestRenderedPage < highestPageNumberInDoc) {
            let topPageToRender;
            if ((highestPageNumberInDoc - highestRenderedPage) > MAX_RENDERED_PAGES) {
                topPageToRender = highestRenderedPage + MAX_RENDERED_PAGES;
            } else {
                topPageToRender = highestPageNumberInDoc;
            }
            this.renderPagesWithAnnotations(highestRenderedPage + 1, topPageToRender).then(() => {
                this.updateNextPageVisibility();
            }).then(() => {
                this.checkForPageDeletions(false);
            });
        }
    }


    checkForPageDeletions(deleteFromUpperEnd: boolean): void {
        if (this.currentlyRenderedPageNums.size > MAX_RENDERED_PAGES * 2) {
            if (!deleteFromUpperEnd) {
                const lowestRenderedPage = this.calculateLowestRenderedPage();
                this.deletePages(lowestRenderedPage, lowestRenderedPage + MAX_RENDERED_PAGES - 1);
            } else {
                const highestRenderedPage = this.calculateHighestRenderedPage();
                this.deletePages(highestRenderedPage - MAX_RENDERED_PAGES + 1, highestRenderedPage);
            }
            this.updateNextPageVisibility();
        }
    }

    deletePages(lowestPageToDelete, highestPageToDelete) {
        for (let pageNum = lowestPageToDelete; pageNum <= highestPageToDelete; pageNum++) {
            this.pageDivs[pageNum].innerHTML = '';
            const pdfPage = this._pdfPageViews[pageNum];
            this._pdfPageViews[pageNum] = null;
            pdfPage.destroy();
            this.currentlyRenderedPageNums.delete(pageNum);
        }
    }

    updateZoomScale(value: number): void {
        this._zoomScale = value;
        this.zoomScaleUpdating = true;
        this.zoomPercentage = Math.floor(value * 100);
        const lowest = this.calculateLowestRenderedPage() || 1;
        const highest = this.calculateHighestRenderedPage();
        let finished = lowest === highest ? (highest + 1) : highest;
        for (let i = lowest, len = highest; i <= len; i++) {
            const pdfPageView = this._pdfPageViews[i];
            pdfPageView.update({ scale: this._zoomScale });
            this._annotations[i].forEach(annotation => {
                if (annotation.fieldName) {
                    this._fieldIdMap[annotation.fieldName] = annotation.id;
                }
            });

            this._zone.runOutsideAngular(() => {
                pdfPageView.draw().then(() => {
                    finished -= 1;
                    if (finished === lowest) {
                        this._zone.run(() => this.zoomScaleUpdating = false);
                    }
                });
            });
        }
    }

    scrollToElement(element: Element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    public abstract onPdfFieldChange(event): void;

    // render n, offer a next n - add a paginator or do infinite scroll?
    protected async loadPdfDocument(params): Promise<any> {
        await this.addPdfViewer();

        if (this.scrollExtentSubscription) {
            this.scrollExtentSubscription.unsubscribe();
        }

        this.pdfDocument = await pdfjsLib.getDocument(params).promise;
        this.currentlyRenderedPageNums = new Set();
        const numPages = this.pdfDocument.numPages;
        const lastPageToRender = numPages > MAX_RENDERED_PAGES ? MAX_RENDERED_PAGES : numPages;
        this.renderPageDivs(numPages);
        return this.renderPagesWithAnnotations(1, lastPageToRender).then(() => {
            this.setupScrollObservable();
        });
    }

    protected updateNextPageVisibility() {
        this.showNextPagesVisible = (!this.currentlyRenderedPageNums.has(this.pdfDocument.numPages));
        this.showPreviousPagesVisible = (!this.currentlyRenderedPageNums.has(1));
    }

    protected disposePdfDocument(): void {
        if (this.scrollExtentSubscription) {
            this.scrollExtentSubscription.unsubscribe();
        }
        this.getElementRef().nativeElement.querySelector(this._PDF_OUTPUT_HOLDER_QUERY_SELECTOR).textContent = '';
        if (this._pdfPageViews) {
            this.currentlyRenderedPageNums.forEach(pageNum => {
                if (this._pdfPageViews[pageNum]) {
                    this._pdfPageViews[pageNum].destroy();
                }
            });
        }
        this._pdfPageViews = {};
        this.overrideHolder.reset();
    }

    protected _createOverrideUI(overrideUIElement: FieldOverride) {
        const override = this.overrideHolder.addOverride(overrideUIElement);
        this._deleteOverrideMarker(override);
        this._createOverrideMarker(override);
    }

    protected _createOverrideMarker(override: FieldOverride, isSaved: boolean = true) {
        override.formElements.forEach(formElement => {
            let elem;
            if (!formElement.parentElement.querySelector('.overrideInfo')) {
                elem = formElement.ownerDocument.createElement('div');
                const fieldInfo = this._getFieldData(override.fieldName);
                const ruleResults = fieldInfo ? fieldInfo.ruleResults : null;
                elem.innerHTML = 'i';
                elem.className = `overrideInfo ${(isSaved ? 'saved' : 'unsaved')}`;
                elem.addEventListener('click', () => { this._clickOverride(override, ruleResults); });
            } else {
                elem = formElement.parentElement.querySelector('.overrideInfo');
                elem.className = `overrideInfo ${(isSaved ? 'saved' : 'unsaved')}`;
            }
            this._renderer.appendChild(formElement.parentElement, elem);
        });
    }

    // The fieldName can be a full name or a short name
    protected _getOriginalValue(fieldName: string) {
        if (this._originalValues[fieldName]) {
            return this._originalValues[fieldName];
        }
        else {
            return this._originalValuesByShortName[fieldName];
        }
    }

    // The fieldName can be a full name or a short name
    protected _getFieldData(fieldName: string) {
        if (this._fieldDataLookup[fieldName]) {
            return this._fieldDataLookup[fieldName];
        }
        else {
            return this._fieldDataLookupByShortName[fieldName];
        }
    }

    protected async _clickOverride(override: FieldOverride, ruleResults: Compliance.ReturnFormFieldRuleResultModel[] = null, inputElem: HTMLInputElement = null): Promise<void> {
        const saved = override ? this.overrideHolder.getSaved(override.fieldName) : null;

        // The click event creates a closure when it is first created, this can lead to loading stale data
        // Try to retrieve the latest stored values if they are available
        const storedOverride = override ? this.overrideHolder.getFieldOverride(override.fieldName) : null;

        const params: ReturnPreviewFormOverrideEditParams = {
            override: storedOverride || override,
            oldValue: override ? this._getOriginalValue(override.fieldName) : inputElem.value,
            rowVersion: override && saved ? saved.rowVersion : null,
            ruleResults: ruleResults,
            canEdit: this.isAppealForm
        };

        const result = await this._modalService.showAsync(ReturnPreviewFormOverrideEditComponent, params, 'modal-lg');

        if (!result) {
            return Promise.resolve();
        }

        if (result.deleteConfirmed) {
            this._deleteOverride(result.override, result.rowVersion);
        }
    }

    protected _deleteInfoMarker(overrideUIElement: FieldOverride) {
        overrideUIElement.formElements.forEach(formElement => {
            const followingElement = formElement.parentElement.querySelector('.fieldInfo');
            if (followingElement) {
                this._renderer.removeChild(followingElement.parentNode, followingElement);
            }
        });
    }

    protected _deleteOverrideUI(overrideUIElement: FieldOverride) {
        const displayValue = this._getOriginalValue(overrideUIElement.fieldName);
        (overrideUIElement.formElements as any[]).forEach(formElement => {
            // restore original value
            if (formElement.type === 'checkbox') {
                formElement.checked = this._isCheckedValue(displayValue);
            } else {
                formElement.value = displayValue;
            }
            this.overrideHolder.deleteOverride(overrideUIElement);
            this._deleteOverrideMarker(overrideUIElement);
            const fieldInfo = this._getFieldData(overrideUIElement.fieldName);
            this._createInfoMarker(formElement, fieldInfo);
        });
        this.overrideHolder.renumberOverrides();
    }

    protected _createInfoMarker(element: HTMLInputElement, fieldInfo: Compliance.ReturnFormFieldDataModel) {
        const doc = element.ownerDocument;
        const elem = doc.createElement('div');
        elem.innerHTML = 'i';
        elem.className = 'fieldInfo';
        elem.addEventListener('click', () => { this._clickFieldInfo(fieldInfo, element); });
        this._renderer.appendChild(element.parentElement, elem);
    }

    protected isFullFieldName(fieldName: string) {
        return fieldName.indexOf(']') > -1;
    }

    protected shortenFullFieldName(fullName: string, skipUIAccountIndex?: boolean) {
        if (this.isFullFieldName(fullName)) {
            const match = FULL_FIELD_NAME_REGEX.exec(fullName);
            if (match && match[1]) {
                // After shortening, we need to account for collisions based on the "Account" parameter. It's possible for the first
                // page of a form to have something like Account-1 and Account-2 parameters, refering to per-account fields that occur
                // before the repeating portion of a multi-account form, then see those same parameters again later. To deal with this,
                // remove the account parameter when shortening a full name and add back an artificial parameter called UIAccountIndex
                // so we can link this field back to it's multi-account data later
                const accountIndex = this._multiAccountFieldMap[fullName];
                if (accountIndex !== undefined) {
                    let result = match[1]
                        .split('\\.')
                        .filter(x => !ACCOUNT_PARAMETER_REGEX.test(x))
                        .join('\\.');
                    if (!skipUIAccountIndex) {
                        result = `${result  }\\.UIAccountIndex-${  accountIndex}`;
                    }
                    return result;
                } else {
                    return match[1];
                }

            }
        }

        return fullName;
    }

    protected getUIAccountIndex(shortName: string): number {
        const paramMatch = shortName
            .split('\\.')
            .map(x => UI_ACCOUNT_INDEX_PARAMETER_REGEX.exec(x))
            .filter(x => x)[0];

        return paramMatch ? +paramMatch[1] : null;
    }

    protected removeUIAccountIndex(shortName: string): string {
        return shortName
            .split('\\.')
            .filter(x => !UI_ACCOUNT_INDEX_PARAMETER_REGEX.test(x))
            .join('\\.');
    }

    protected setUIAccountIndex(shortName: string, index: number): string {
        return `${this.removeUIAccountIndex(shortName)  }\\.UIAccountIndex-${  index}`;
    }

    // review mode is a special case where we want to still see overrides (and hence calculate data, even though we're only showing the flattened form and are in read only)
    protected async _loadPreviewForForm(blob: Blob, formData: Compliance.ReturnFormResultModel, overrides: Compliance.FormOverrideModel[], showFormForReviewOnly: boolean = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (formData) {
                this._multiAccountFieldMap = {};
                if (formData.perAccountFieldLookup) {
                    Object.keys(formData.perAccountFieldLookup).forEach(accountIndex => {
                        formData.perAccountFieldLookup[accountIndex].forEach(fieldName => {
                            this._multiAccountFieldMap[fieldName] = +accountIndex;
                        });
                    });
                }
            }

            const fileReader = new FileReader();
            fileReader.onload = async () => {
                this.showNextPagesVisible = false;
                this.showPreviousPagesVisible = false;

                await this.loadPdfDocument({ data: fileReader.result });

                await this._loadFormData(formData, overrides, showFormForReviewOnly);

                this._postFormLoadAction();
                resolve();

            };
            fileReader.readAsArrayBuffer(blob);
        });
    }

    protected async _loadFormData(formData: Compliance.ReturnFormResultModel, overrides: Compliance.FormOverrideModel[], showFormForReviewOnly: boolean = false): Promise<void> {
        this.updateNextPageVisibility();
        this.overrideHolder.reset();
        this._fieldDataLookup = {};
        if (!formData) {
            return;
        }

        // let overrideLookup: { [accountIndex: number]: { [fieldName: string]: Compliance.FormOverrideModel } };
        const overrideLookup = overrides.reduce((acc, override) => {
            const accountIndex = this.isFullFieldName(override.fieldName) ? 0 : this.resolveAccountIndex(override);
            acc[accountIndex] = acc[accountIndex] || {};
            acc[accountIndex][override.fieldName] = override;
            return acc;
        }, {});

        // const overrideMap: Map<string, Compliance.FormOverrideModel> = overrides.reduce((acc, override) => {
        //     acc.set(override.fieldName, override);
        //     return acc;
        // }, new Map());

        // create info markers as well as overrides, include info in overrides for locations
        formData.fieldData.forEach((x: Compliance.ReturnFormFieldDataModel) => {
            const inputElem: HTMLInputElement = this._getInputByFieldName(x.fullName);

            if (inputElem && !(formData.fieldsToHide && formData.fieldsToHide.find(i => i === x.fullName))) {
                const shortName = this.shortenFullFieldName(x.fullName);
                this._originalValues[x.fullName] = x.value;
                this._originalValuesByShortName[shortName] = x.value;
                let displayValue = x.value;

                // fieldInfos
                this._fieldDataLookup[x.fullName] = x;
                this._fieldDataLookupByShortName[shortName] = x;

                const fieldIndex = this._multiAccountFieldMap[x.fullName] || 0;

                let savedOverride: Compliance.FormOverrideModel = undefined;

                if (overrideLookup[fieldIndex]) {

                    const shortNameWithoutAccountIndex = this.shortenFullFieldName(x.fullName, true);
                    const fullNameOverride = overrideLookup[fieldIndex][x.fullName];
                    const shortNameOverride = overrideLookup[fieldIndex][shortNameWithoutAccountIndex];

                    savedOverride = fullNameOverride || shortNameOverride;
                }

                if (savedOverride) {
                    if (!this.isFullFieldName(savedOverride.fieldName) && this._multiAccountFieldMap[x.fullName] !== undefined) {
                        savedOverride.fieldName = this.setUIAccountIndex(savedOverride.fieldName, fieldIndex);
                    }

                    const overrideUIElement: FieldOverride = {
                        formElements: [inputElem],
                        overrideValue: savedOverride.value,
                        fieldName: savedOverride.fieldName
                    };

                    if (this.isFullFieldName(savedOverride.fieldName)) {
                        const shortFieldName = this.shortenFullFieldName(savedOverride.fieldName);
                        if (shortFieldName !== savedOverride.fieldName) {
                            this._oldFormatOverrides[shortFieldName] = {
                                override: overrideUIElement,
                                rowVersion: savedOverride.rowVersion
                            };
                        }
                    }

                    this.overrideHolder.saveOverride(savedOverride, savedOverride.fieldName);
                    this._createOverrideUI(overrideUIElement);
                    displayValue = savedOverride.value;
                    inputElem.classList.add(this.MODIFIED_FIELD_CSS_CLASS);
                } else {
                    if (x.ruleResults && x.ruleResults.length > 0 && x.ruleResults[0].entityName) {
                        // need rule result info
                        this._createInfoMarker(inputElem, x);
                    }
                }

                // update value from data unless in locked mode
                if (!showFormForReviewOnly) {
                    if (inputElem.type === 'checkbox') {
                        inputElem.checked = this._isCheckedValue(displayValue);
                    } else {
                        inputElem.value = displayValue;
                    }
                }

                if ((x.fullName.indexOf('SignatureImage') > 0) && x.value !== '') {
                    const signatureDiv = inputElem.ownerDocument.createElement('div');
                    signatureDiv.setAttribute('style', `width: 100%; position: absolute; display: block; left: 0px; top: 0px; overflow: visible; height: ${inputElem.parentElement.style.height};`);
                    signatureDiv.innerHTML = `<img src="${x.value}" style = "height: ${inputElem.parentElement.style.height}; position: absolute;" />`;
                    this._renderer.appendChild(inputElem.parentElement, signatureDiv);
                    inputElem.style.visibility = 'hidden';
                }
            }
        });

        this.overrideHolder.renumberOverrides();
        this._updateInputFieldReadOnlyAttributes();
    }

    protected _isCheckedValue(value: string): boolean {
        return ['1', 'on', 'yes'].some(x => (value && value.toLowerCase()) === x);
    }

    protected _updateInputFieldReadOnlyAttributes() {
        this.getElementRef().nativeElement.querySelectorAll('.annotationLayer input, .annotationLayer textarea').forEach(element => {
            // WR-6574 readonly properties do not work on checkboxes/radio buttons so changed to disabled on all inputs
            element.disabled = this.isReadOnly;
        });
    }

    // Overload this to take action after form loaded
    protected _postFormLoadAction() {
        return null;
    }

    protected _deleteOverrideMarker(override: FieldOverride) {
        override.formElements.forEach(formElement => {
            const followingElement = formElement.parentElement.querySelector('.overrideInfo');
            if (followingElement) {
                this._renderer.removeChild(followingElement.parentNode, followingElement);
            }
        });
    }

    protected abstract getElementRef(): ElementRef;
    protected abstract isShowingAnnotations(): boolean;
    protected abstract _deleteOverride(override: FieldOverride, rowVersion: number[]): Promise<void>;
    protected abstract resolveAccountIndex(override: Compliance.FormOverrideModel): number;

    private async addPdfViewer() {
        if (await this._scriptLoaderService.loadScript(ScriptLocations.PDFJSLib, true))
        {
            pdfjsLib.GlobalWorkerOptions.workerSrc = `${this._constants.PdfJsBase}build/pdf.worker.mjs`;
            await this._scriptLoaderService.loadScript(ScriptLocations.PDFJSViewer, true);
        }
    }

    private setupScrollObservable() {
        this.scrollExtentSubscription = timer(200, 50).subscribe(() => {
            if (!this.showNextPagesVisible && !this.showPreviousPagesVisible) {
                return;
            }
            const showPreviousPagesButton: Element = this.getElementRef().nativeElement.querySelector('#showPreviousPagesButton');
            const showNextPagesButton: Element = this.getElementRef().nativeElement.querySelector('#showNextPagesButton');
            if (showPreviousPagesButton && this.showPreviousPagesVisible && showPreviousPagesButton.getBoundingClientRect().top > 0) {
                this.showNextPagesVisible = false;
                this.showPreviousPagesVisible = false;
                this.showPreviousPages();
            } else if (showNextPagesButton && this.showNextPagesVisible && showNextPagesButton.getBoundingClientRect().top < window.innerHeight) {
                this.showNextPagesVisible = false;
                this.showPreviousPagesVisible = false;
                this.showNextPages();
            }
        });

    }

    // split out div creation, keep index
    private renderPagesWithAnnotations(firstPage: number, lastPage: number): Promise<any> {
        const annotationsPromise: Promise<any>[] = [];
        this._fieldIdMap = {};
        for (let pageNum = firstPage; pageNum <= lastPage; pageNum++) {
            //pageNums.forEach(pageNum => {
            annotationsPromise.push(
                this.pdfDocument.getPage(pageNum).then(pdfPage => {
                    // creating the page view with default parameters.
                    const pdfPageViewParams: any = {
                        container: this.pageDivs[pageNum],
                        id: pageNum,
                        scale: this._zoomScale,
                        defaultViewport: pdfPage.getViewport({ scale: this._zoomScale }),
                        eventBus: new pdfjsViewer.EventBus(),
                        // Note we used to set textLayerMode to 0 (disable), but that controls copy/paste
                        // functionality; there isn't a good reason to disable that
                        textLayerMode: 1, // ENABLE
                        annotationMode: 1 // ENABLE
                    };

                    if (this.isShowingAnnotations()) {
                        pdfPageViewParams.annotationMode = 2; // ENABLE_FORMS
                        // In pdf.js v2 this is how interactive forms were turned on; it seems in v4 the
                        // annotationMode setting controls it.
                        //pdfPageViewParams.renderInteractiveForms = true;
                    }

                    const pdfPageView = new pdfjsViewer.PDFPageView(pdfPageViewParams);

                    this._pdfPageViews[pageNum] = (pdfPageView);
                    this.currentlyRenderedPageNums.add(pageNum);

                    // associates the actual page with the view, and drawing it
                    pdfPageView.setPdfPage(pdfPage);

                    return pdfPage.getAnnotations().then(result => {
                        this._annotations[pageNum] = result;
                        result.forEach(annotation => {
                            if (annotation.fieldName) {
                                this._fieldIdMap[annotation.fieldName] = annotation.id;
                            }
                        });
                        return pdfPageView.draw();
                    });
                })

            );
        }

        return Promise.all(annotationsPromise).then(result => {
            this._idFieldMap = {};
            this._accountlessNameIdMap = {};
            const fieldElementMaps: { [shortName: string]: Element[] } = {};

            Object.keys(this._fieldIdMap).forEach(fullName => {
                const id = this._fieldIdMap[fullName];
                const shortName = this.shortenFullFieldName(fullName);
                fieldElementMaps[shortName] = fieldElementMaps[shortName] || [];
                fieldElementMaps[shortName].push(
                    this.getElementRef().nativeElement.querySelector(`[data-annotation-id='${  id  }']>input, [data-annotation-id='${  id  }']>textarea`)
                );

                const accountIndex = this.getUIAccountIndex(shortName);
                if (accountIndex !== null) {
                    const accountlessName = this.removeUIAccountIndex(shortName);
                    this._accountlessNameIdMap[accountlessName] = this._accountlessNameIdMap[accountlessName] || [];
                    this._accountlessNameIdMap[accountlessName].push(id);
                }

                this._idFieldMap[id] = {
                    fieldName: shortName,
                    fullName: fullName,
                    elements: fieldElementMaps[shortName]
                };
            });

            return result;
        });
    }

    // split out div creation, keep index
    private renderPageDivs(numberOfPages: number): void {
        this.pageDivs = {};
        const outerHolder: Element = this.getElementRef().nativeElement.querySelector(this._PDF_OUTPUT_HOLDER_QUERY_SELECTOR);
        outerHolder.innerHTML = '';
        for (let pageNum = 1; pageNum <= numberOfPages; pageNum++) {
            const div: Element = outerHolder.ownerDocument.createElement('div');
            div.className = 'pageHolderDiv';
            this._renderer.appendChild(outerHolder, div);
            this.pageDivs[pageNum] = div;
        }
    }

    private _getInputByFieldName(fieldName: string): any {
        const id = this._fieldIdMap[fieldName];
        if (id) {
            const element = this.getElementRef().nativeElement.querySelector(`section[data-annotation-id='${id}'] input, section[data-annotation-id='${id}'] textarea, section[data-annotation-id='${id}'] image`);
            return element;
        }
        return null;
    }

    private _clickFieldInfo(fieldInfo: Compliance.ReturnFormFieldDataModel, inputElem: HTMLInputElement) {
        this._clickOverride(null, fieldInfo.ruleResults, inputElem);
    }
}
