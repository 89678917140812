<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Allocations"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Allocations
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="canEdit">
                    <button type="button"
                            class="ace-btn btn-sm btn-primary"
                            (click)="addNew()"
                            helpTooltip
                            helpContentId="allocation-list.new-allocation"
                            position="bottom">
                        New Allocation
                    </button>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="ace-btn btn-sm btn-secondary has-icon"
                            (click)="refresh()"
                            [disabled]="refreshing"
                            helpTooltip
                            helpContentId="app.refresh"
                            position="bottom">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="ws-flex-container-vertical ws-flex-auto">
        <div class="ws-flex-container-horizontal filter-row">
            <div class="ws-flex-container-horizontal filter-group ws-flex-align-items-center">
                <label class="ws-flex-none small ws-font-weight-normal"
                       helpTooltip
                       helpContentId="allocation-list.completed"
                       position="bottom">
                    <input type="checkbox" [(ngModel)]="includeCompleted" (change)="onIncludeCompletedChange()"> Include Completed
                </label>
            </div>
        </div>
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)"
                            [companyId]="companyId"
                            [gridId]="gridId"
                            [exportOptions]="exportOptions">
        </ws-ag-grid-angular>
    </div>
</div>
