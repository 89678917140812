import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail, UserActivityReportCriteria } from '../report.manager.model';
import * as _ from 'lodash';
import { ReportType } from '../report-type';

@Component({
    selector: 'format-output-panel-user-activity',
    template: `
        <div class="panel-flat-body">
            <div class="col-md-4">
                <div style="margin: 15px 0;" *ngIf="report.reportTypeId !== reportTypes.UserAccess">
                    <label>Output Format:</label>
                    <select [(ngModel)]="report.criteria.outputFormat" class="form-select-sm">
                        <option *ngFor="let option of outputFormatOptions; let i = index;" [ngValue]="i">{{option}}</option>
                    </select>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.userActivityFormatOutputOptions.showUserName"> Show User Name
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.userActivityFormatOutputOptions.showContactTitle"> Show Contact Title
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.userActivityFormatOutputOptions.showLoginDates"> Show Login Dates
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.userActivityFormatOutputOptions.showLoginDayCount" > Show Login Day Count
                    </label>
                </div>
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="report.criteria.userActivityFormatOutputOptions.showLastPasswordChange" > Show Last Password Change
                    </label>
                </div>
            </div>
            </div>
    `
})
export class FormatOutputPanelUserActivityComponent implements OnInit {
    @Input() report: ReportDetail;
    outputFormatOptions: string[];
    reportTypes = ReportType;

    ngOnInit() {
        this.outputFormatOptions = [ 'Summary Report (PDF)', 'Summary Report (Excel)', 'Details Report (PDF)', 'Details Report (Excel)'];
        this.report.criteria.outputFormat = this.report.criteria.outputFormat || 0;
        this.report.criteria.userActivityFormatOutputOptions = this.report.criteria.userActivityFormatOutputOptions || new UserActivityReportCriteria();
    }
}
