
<div class="modal-header">
    <h4 class="modal-title">Reports Sites</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body exclude-modal-styling">
    <div class="ws-flex-container-vertical ws-flex-auto ws-stretch">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
<div class="modal-footer">
    <div class="ws-flex-auto ws-flex-container-horizontal">
        <div class="ws-flex-auto">
            <button class="ace-btn btn-secondary" (click)="cancel()">Close</button>
        </div>
    </div>
</div>
