import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { Attachment } from 'src/app/Attachment/attachment.model';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { BusyIndicatorService } from 'src/app/Busy-Indicator';

@Component({
    selector: 'attachment-download-diagnostic',
    templateUrl: './attachmentDownloadDiagnostic.component.html',
    styleUrls: ['./attachmentDownloadDiagnostic.component.scss']
})
export class AttachmentDownloadDiagnosticComponent {
    attachmentId: string;
    attachmentInfo: string;
    currentByte: number;
    byteCount: number;
    downloading: boolean;
    abortController: AbortController;

    constructor(
        private readonly _toastr: ToastrService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _attachmentService: AttachmentService,
        private readonly _http: HttpClient
    ) {
    }

    async getInfo() {
        if (!this.validate()) {
            return;
        }

        const busyRef = this._busyService.show({message: 'Getting Info'});

        try {
            await this.loadInfo();
        } finally {
            await busyRef.hide();
        }
    }

    async bufferedDownload() {
        if (!this.validate()) {
            return;
        }
        const busyRef = this._busyService.show({message: 'Processing download'});

        try {
            await this._attachmentService.downloadAttachmentFileBuffered(await this.loadInfo());
        } finally {
            await busyRef.hide();
        }
    }

    async streamedDownload() {
        if (!this.validate()) {
            return;
        }
        const busyRef = this._busyService.show({message: 'Processing download'});

        try {
            await this._attachmentService.downloadAttachmentFileStreamed(await this.loadInfo());
        } finally {
            await busyRef.hide();
        }
    }

    async simulateDownload() {
        if (!this.validate()) {
            return;
        }
        const busyRef = this._busyService.show({message: 'Processing download'});
        this.abortController = new AbortController();
        let fetchResponse: Response;

        try {
            const info = await this.loadInfo();
            const oneTimeCode = await lastValueFrom(this._http.get<string>(`/api/attachment/${info.attachmentID}/OneTimeCode`));
            fetchResponse = await fetch(`/api/attachment/Download/${encodeURIComponent(oneTimeCode)}`, { signal: this.abortController.signal });
        } finally {
            await busyRef.hide();
        }

        this.downloading = true;
        this.byteCount = +fetchResponse.headers.get('content-length');
        this.currentByte = 0;
        const reader = fetchResponse.body.getReader();
        let done = false;
        let value;

        while (!done) {
            ({ done, value } = await reader.read());

            if (!done) {
                this.currentByte += value.length;
                // Honestly it's usually pretty annoying to flood the log with these, so put a hidden flag here a dev an set if needed.
                if (window['showDownloadChunkLengths']) {
                    console.log(`Read chunk (${value.length} bytes)`);
                }
            }
        }

        this.downloading = false;
    }

    cancel() {
        this.abortController.abort();
        this.downloading = false;
    }

    private async loadInfo(): Promise<Attachment> {
        this.byteCount = null;
        this.attachmentInfo = null;
        const result = await this._attachmentService.getInfo(this.attachmentId);
        this.attachmentInfo = JSON.stringify(result, null, 4);
        return result;
    }

    private validate(): boolean {
        if (!this.attachmentId || /^\s*$/.test(this.attachmentId)) {
            this._toastr.warning('You must supply an Attachment Id.');
            return false;
        }

        if (!/^\s*[0-9a-fA-F]{8}(-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}\s*/.test(this.attachmentId)) {
            this._toastr.warning('Attachment Id must be a GUID.');
            return false;
        }

        return true;
    }
}
