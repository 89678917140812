import { BaseRepository } from '../Common/Data/base.repository';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { WeissmanDateFormatPipe } from '../UI-Lib/Pipes';
import { ToastrService } from 'ngx-toastr';
import { ColDef, ColGroupDef, GridApi, RowNode } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams } from '../Compliance/AgGrid';
import * as _ from 'lodash';
import { ForecastBudgetModeType, BudgetModeViewType, ForecastGridSearchModel, ForecastBudgetFilterSelections, ShowAssessmentsTaxesEnum, ForecastBudgetAltAssessmentView,
    BudgetTaxYearMissingSyncData, ForecastBudgetScreenModeEnum, ForecastBudgetEditRequest, ForecastBudgetEditDetails } from './forecasting.budgeting.model';
import { Observable, BehaviorSubject, lastValueFrom } from 'rxjs';
import { ForecastBudgetYearCellRendererComponent, ForecastBudgetYearCellRendererParams, YearColumnType } from './agGridForecastBudgetYearCellRender.component';
import { ForecastBudgetYearCellEditorParams, ForecastBudgetYearCellEditorComponent } from './agGridForecastBudgetYearCellEditor.component';
import { AgGridLinkCellRendererComponent, AgGridLinkCellRendererParams } from '../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridCheckboxCellRendererComponent, ICellRendererParamsForAgGridCheckbox } from '../Compliance/AgGrid/CellRenderers/agGridCheckboxCellRender.component';
import { Decimal } from 'decimal.js';

@Injectable(
    { providedIn: 'root' }
)
export class ForecastingBudgetingService extends BaseRepository {
    constructor(httpClient: HttpClient,
        private toastr: ToastrService,
        private currencyPipe: CurrencyPipe,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _decimalPipe: DecimalPipe)
    {
        super(httpClient);
    }

    selectedMode: ForecastBudgetModeType = 'forecast';
    selectedView: BudgetModeViewType = 'taxYear';
    isEditingCell: boolean = false;
    editMode: boolean = false;

    gridApi: GridApi;

    scopeEntityTypeId: number = Core.EntityTypes.Company;
    scopeEntityId: number = 0;

    tlCompany: Core.CompanyRecordDTO;
    filters: ForecastBudgetFilterSelections = new ForecastBudgetFilterSelections();

    forecastGridSearchModel: ForecastGridSearchModel;
    forecastGridResults: Core.ForecastGridResults;

    selectedBudget: Core.AvailableCompanyBudget = undefined;
    autoSyncBudgetToLatest: boolean = false;

    missingSyncData: BudgetTaxYearMissingSyncData = { numMissingTaxYears: 0, numMissingBudgets: 0, numOutOfSyncBudgets: 0 };

    loadingData: boolean = false;
    cancelLoad: boolean = false;
    updatingCell: boolean = false;

    private _forecastingBudgetingGridDataSubject = new BehaviorSubject<any>(false);

    get forecastingBudgetingGridData$(): Observable<boolean> {
        return this._forecastingBudgetingGridDataSubject.asObservable();
    }
    get freezeButtonLabel(): string {
        return this.selectedBudget && this.selectedBudget.isFrozen ? 'Unfreeze Entire Budget' : 'Freeze Entire Budget';
    }

    resetMissingSyncData() : void
    {
        this.missingSyncData.numMissingTaxYears = 0;
        this.missingSyncData.numMissingBudgets = 0;
        this.missingSyncData.numOutOfSyncBudgets = 0;
    }

    isTLCompany(companyID: number): boolean {
        return this.tlCompany.companyID === companyID;
    }

    initServiceModel(entityTypeId: number, entityId: number): void {
        this.scopeEntityTypeId = entityTypeId;
        this.scopeEntityId = entityId;

        this.forecastGridSearchModel = new ForecastGridSearchModel(this.scopeEntityTypeId, this.scopeEntityId);
        this.forecastGridSearchModel.tlCompanyID = this.tlCompany.companyID;
    }

    getGridColumns(): ColDef[] {
        const identityCols = this._getIdentityColumns();

        const taxYears: number[] = _.range(this.filters.yearEnd, this.filters.yearBegin - 1);
        const fmvCols = _.reduce(taxYears, (columns: ColDef[], taxYear: number, i: number) => {
            if (this.forecastGridSearchModel.alternateAssessmentView != ForecastBudgetAltAssessmentView.ShowOnlyAlternates) {
                if (this.forecastGridSearchModel.mode == ForecastBudgetScreenModeEnum.TaxYearBudget) {
                    if (this.forecastGridSearchModel.showBudgetEntriesFor == ShowAssessmentsTaxesEnum.Assessments
                        || this.forecastGridSearchModel.showBudgetEntriesFor == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes) {
                        columns.push(this._getBudgetFMVColumn(taxYear));
                    }

                    if(this.forecastGridSearchModel.showBudgetVariances == ShowAssessmentsTaxesEnum.Assessments
                        || this.forecastGridSearchModel.showBudgetVariances == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes) {
                        columns.push(this._getBudgetFMVVarianceColumn(taxYear));
                    }
                }

                columns.push(this._getFMVColumn(taxYear));

                if ((this.forecastGridSearchModel.showYoYChangesFor == ShowAssessmentsTaxesEnum.Assessments
                        || this.forecastGridSearchModel.showYoYChangesFor == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes)
                    && i < taxYears.length - 1) {
                    columns.push(this._getFMVChangeColumn(taxYear));
                }
            }

            if (this.forecastGridSearchModel.alternateAssessmentView != ForecastBudgetAltAssessmentView.Hide ) {
                if (this.forecastGridSearchModel.mode == ForecastBudgetScreenModeEnum.TaxYearBudget) {
                    if (this.forecastGridSearchModel.showBudgetEntriesFor == ShowAssessmentsTaxesEnum.Assessments
                         || this.forecastGridSearchModel.showBudgetEntriesFor == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes) {
                        columns.push(this._getBudgetAltFMVColumn(taxYear));
                    }

                    if (this.forecastGridSearchModel.showBudgetVariances == ShowAssessmentsTaxesEnum.Assessments
                        || this.forecastGridSearchModel.showBudgetVariances == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes) {
                        columns.push(this._getBudgetAltFMVVarianceColumn(taxYear));
                    }
                }

                columns.push(this._getAltFMVColumn(taxYear));

                if ((this.forecastGridSearchModel.showYoYChangesFor == ShowAssessmentsTaxesEnum.Assessments
                    || this.forecastGridSearchModel.showYoYChangesFor == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes)
                    && i < taxYears.length - 1) {
                    columns.push(this._getAltFMVChangeColumn(taxYear));
                }
            }

            return columns;
        }, []);

        const taxCols = _.reduce(taxYears, (columns: ColDef[], taxYear: number, i: number) => {
            if (this.forecastGridSearchModel.mode == ForecastBudgetScreenModeEnum.TaxYearBudget) {
                if (this.forecastGridSearchModel.showBudgetEntriesFor == ShowAssessmentsTaxesEnum.Taxes
                    || this.forecastGridSearchModel.showBudgetEntriesFor == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes) {
                    columns.push(this._getBudgetTaxColumn(taxYear));
                }

                if (this.forecastGridSearchModel.showBudgetVariances == ShowAssessmentsTaxesEnum.Taxes
                    || this.forecastGridSearchModel.showBudgetVariances == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes) {
                    columns.push(this._getBudgetTaxVarianceColumn(taxYear));
                }
            }

            columns.push(this._getTaxColumn(taxYear));

            if ((this.forecastGridSearchModel.showYoYChangesFor == ShowAssessmentsTaxesEnum.Taxes
                || this.forecastGridSearchModel.showYoYChangesFor == ShowAssessmentsTaxesEnum.AssessmentsAndTaxes)
                && i < taxYears.length - 1) {
                columns.push(this._getTaxChangeColumn(taxYear));
            }

            return columns;
        }, []);

        return  [{headerName: '', children: identityCols},
                 {headerName: 'FMV', children: fmvCols, headerClass: 'fmv-header'},
                 {headerName: 'Taxes', children: taxCols, headerClass: 'tax-header'} as ColGroupDef];
    }

    public postProcessEditResult(request: ForecastBudgetEditRequest, editResult: Core.ForecastBudgetEditResult) : void
    {
        if ( request.forBulkUpdate )
        {
            const entityTypeName: string = this.forecastGridSearchModel.filters.siteRollup ? 'Sites' : 'Parcels';
            let budgetActionTaken: string = '';
            switch ( request.details.editAction )
            {
                case Core.ForecastBudgetEditActionEnum.Freeze:
                    budgetActionTaken = 'Frozen';
                    break;
                case Core.ForecastBudgetEditActionEnum.Unfreeze:
                    budgetActionTaken = 'Unfrozen';
                    break;
                case Core.ForecastBudgetEditActionEnum.CreateMissingBudget:
                    budgetActionTaken = 'Created';
                    break;
                case Core.ForecastBudgetEditActionEnum.SyncBudget:
                    budgetActionTaken = 'Synced';
                    break;
            }
            if ( editResult.missingTaxYearCount && editResult.missingTaxYearCount > 0 )
            {
                if ( budgetActionTaken )
                {
                    this.toastr.info(`Budget entries NOT ${budgetActionTaken} for ${editResult.missingTaxYearCount} Parcels - Reason: Missing Tax Year.`);
                }
                else
                {
                    this.toastr.info(`Selected ${entityTypeName} contained ${editResult.missingTaxYearCount} Parcels NOT edited - Reason: Missing Tax Year.`);
                }
            }
            if ( editResult.emptyAssessmentCount && editResult.emptyAssessmentCount > 0 )
            {
                this.toastr.info(`Selected ${entityTypeName} contained ${editResult.emptyAssessmentCount} Parcels NOT edited - Reason: Empty Assessment.`);
            }
            if ( editResult.actualizedAssessmentCount && editResult.actualizedAssessmentCount > 0 )
            {
                this.toastr.info(`Selected ${entityTypeName} contained ${editResult.actualizedAssessmentCount} Parcels NOT edited - Reason: Actualized Assessment.`);
            }
            if ( editResult.missingBudgetCount && editResult.missingBudgetCount > 0 )
            {
                this.toastr.info(`Budget entries NOT ${budgetActionTaken} for ${editResult.missingBudgetCount} Parcels - Reason: Missing Budget.`);
            }
            if ( editResult.budgetFrozenCount && editResult.budgetFrozenCount > 0 )
            {
                this.toastr.info(`${editResult.budgetFrozenCount} Budget entries Frozen`);
            }
            if ( editResult.budgetUnfrozenCount && editResult.budgetUnfrozenCount > 0 )
            {
                this.toastr.info(`${editResult.budgetUnfrozenCount} Budget entries Unfrozen`);
            }
            if ( editResult.budgetCreatedCount && editResult.budgetCreatedCount > 0 )
            {
                this.toastr.info(`${editResult.budgetCreatedCount} Budget entries Created`);
            }
            if ( editResult.budgetSyncedCount && editResult.budgetSyncedCount > 0 )
            {
                this.toastr.info(`${editResult.budgetSyncedCount} Budget entries Synced`);
            }

            if ( (request.details.editAction === Core.ForecastBudgetEditActionEnum.SetTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.DistribTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetAltFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.DistribAltFMV) &&
                 (!editResult.parcelCount || editResult.parcelCount === 0) )
            {
                this.toastr.error(`Selected ${entityTypeName} contained NO Parcels eligible for the Edit.`, 'NOT Edited!');
            }
            return;
        }

        if ( this.forecastGridSearchModel.filters.siteRollup )
        {
            if ( editResult.missingTaxYearCount && editResult.missingTaxYearCount > 0 )
            {
                this.toastr.info(`Site contained ${editResult.missingTaxYearCount} Parcels NOT edited - Reason: Missing Tax Year.`);
            }
            if ( editResult.emptyAssessmentCount && editResult.emptyAssessmentCount > 0 )
            {
                this.toastr.info(`Site contained ${editResult.emptyAssessmentCount} Parcels NOT edited - Reason: Empty Assessment.`);
            }
            if ( editResult.actualizedAssessmentCount && editResult.actualizedAssessmentCount > 0 )
            {
                this.toastr.info(`Site contained ${editResult.actualizedAssessmentCount} Parcels NOT edited - Reason: Actualized Assessment.`);
            }

            if ( (request.details.editAction === Core.ForecastBudgetEditActionEnum.SetTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetAltFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV) &&
                 (!editResult.parcelCount || editResult.parcelCount === 0) )
            {
                this.toastr.error('Site contained NO Parcels eligible for the Edit.', 'NOT Edited!');
            }
        }
        else
        {
            if ( (request.details.editAction === Core.ForecastBudgetEditActionEnum.SetTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetAltFMV ||
                  request.details.editAction === Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV) &&
                 (!editResult.parcelCount || editResult.parcelCount === 0) )
            {
                this.toastr.error('Parcel is NOT eligible for the Edit.', 'NOT Edited!');
            }
        }
    }

    async loadData() {
        this.loadingData = true;

        try {
            this.forecastGridSearchModel.filters = this.filters;
            this.forecastGridResults = await this.getForecastModeList(this.forecastGridSearchModel);

            // Retrieve missing/out-of-sync counts
            await this.refreshMissingOutOfSyncCounts();

            if(this.cancelLoad) {
                this.cancelLoad = false;
            } else {
                this._forecastingBudgetingGridDataSubject.next(true);
            }
        } finally {
            this.loadingData = false;
        }

    }

    clearData() {
        if(this.loadingData) {
            this.gridApi.hideOverlay();
            this.cancelLoad = true;
        }

        if(this.forecastGridResults) {
            this.forecastGridResults.entries = [];
            this._forecastingBudgetingGridDataSubject.next(true);
        }

    }

    async refreshMissingOutOfSyncCounts() {
        // If Forecast mode:
        // Retrieve missing tax year counts for the selected tax year and filters.
        await this.getMissingCountsForecast();

        // If Budget mode:
        // Retrieve missing tax year and budget counts and out-of-sync budget counts
        // for the selected budget datae and filters.
        await this.getMissingCountsBudget();
        await this.getOutOfSyncCountsBudget();
    }

    getData(): Core.ForecastGridEntry[] {
        return _.chain(this.forecastGridResults.entries)
            .cloneDeep()
            .sortBy(['stateAbbr', 'siteName', 'parcelAcctNum'])
            .value();
    }

    updateTotals() {
        const totalRows = [];

        let areSelectedRows: boolean = false;
        let isAtLeastOneRow: boolean = false;

        const totalEntry: any = {assessments: [] as any[], budgetData: [], taxes: [], totalRow: true, siteName: 'TOTAL'};
        const selectedEntry: any = {assessments: [] as any[], budgetData: [], taxes: [], totalRow: true, siteName: 'Selected'};

        this.gridApi.forEachNodeAfterFilter((rowNode: any) => {
            if(rowNode.selected) {
                areSelectedRows = true;
                this._compileNestedTotals(rowNode, selectedEntry);
            }

            isAtLeastOneRow = true;
            this._compileNestedTotals(rowNode, totalEntry);

        });

        if(isAtLeastOneRow) {
            totalRows.push(totalEntry);
        }
        if(areSelectedRows) {
            totalRows.push(selectedEntry);
        }

        this.gridApi.setPinnedBottomRowData(totalRows);
    }

    //
    // API Calls
    //
    getAreBudgetsEnabled(entityTypeId: number, entityId: number): Promise<boolean> {
        return lastValueFrom(this.httpClient.get(`/api/companybudget/AreBudgetsEnabledForEntity/${entityId}/entitytype/${entityTypeId}`)) as Promise<boolean>;
    }

    getCompanyBudgetBasis(entityTypeId: number, entityId: number): Promise<Core.CompanyBudgetBasisEnum> {
        return lastValueFrom(this.httpClient.get(`/api/companybudget/GetBudgetBasisForEntity/${entityId}/entitytype/${entityTypeId}`)) as Promise<Core.CompanyBudgetBasisEnum>;
    }

    getAvailableCompanyBudgets(companyId: number, excludeFrozen: boolean = false): Promise<Core.AvailableCompanyBudget[]> {
        const params = new HttpParams().set('excludeFrozen', excludeFrozen.toString());

        return lastValueFrom(this.httpClient.get(`/api/CompanyBudget/Available/${Core.EntityTypes.Company}/${companyId}`, { params: params })) as Promise<Core.AvailableCompanyBudget[]>;
    }

    getCompanyBudget(companyBudgetId: number): Promise<Core.CompanyBudgetDTO> {
        return lastValueFrom(this.httpClient.get(`/api/CompanyBudget/${companyBudgetId}`)) as Promise<Core.CompanyBudgetDTO>;
    }

    createCompanyBudget(tlCompanyId: number, budgetName: string, fiscalStartYear: number, budgetDate: Date) : Promise<Core.CompanyBudgetDTO> {
        const addRequest: Core.AddCompanyBudgetRequest = {
            budgetName: budgetName,
            fiscalStartYear: fiscalStartYear,
            budgetDate: budgetDate
        };

        return lastValueFrom(this.httpClient.post(`/api/CompanyBudget/Company/${tlCompanyId}`, addRequest)) as Promise<Core.CompanyBudgetDTO>;
    }

    getAvailableFilters(entityTypeId: number, entityId: number): Promise<Core.ForecastBudgetAvailableFilters> {
        return lastValueFrom(this.httpClient.get(`/api/CompanyBudget/AvailableFilters/${entityTypeId}/${entityId}`)) as Promise<Core.ForecastBudgetAvailableFilters>;
    }

    getForecastModeList(forecastGridSearchModel: ForecastGridSearchModel): Promise<Core.ForecastGridResults> {
        return lastValueFrom(this.httpClient.post('/api/companybudget/forecast', forecastGridSearchModel)) as Promise<Core.ForecastGridResults>;
    }

    editForecastBudget(entityTypeId: number, entityId: number, forecastBudgetEditRequest: Core.ForecastBudgetEditRequest): Promise<Core.ForecastBudgetEditResult> {
        return lastValueFrom(this.httpClient.put(`/api/CompanyBudget/Edit/${entityTypeId}/${entityId}`, forecastBudgetEditRequest)) as Promise<Core.ForecastBudgetEditResult>;
    }

    deleteCompanyBudget(companyBudgetId: number): Promise<void> {
        return lastValueFrom(this.httpClient.delete(`/api/companybudget/${companyBudgetId}`)) as Promise<any>;
    }

    exportToExcel(exportRequest: Core.ForecastGridExportModel): Promise<number> {
        return lastValueFrom(this.httpClient.post('/api/budgetservice/export', exportRequest)) as Promise<number>;
    }

    getExport(longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/budgetservice/export/${longRunningProcessId}`, options);
    }

    saveCompanyBudget(): Promise<void> {
        return lastValueFrom(this.httpClient.put(`/api/companybudget/${this.selectedBudget.companyBudgetId}`, { budgetBasis: this.selectedBudget.budgetBasis })) as Promise<any>;
    }

    getMissingCountsForecast(): Promise<void> {
        if ( this.selectedMode !== 'forecast' )
        {
            return Promise.resolve();
        }

        const request: Core.ForecastParcelMissingYearsDTO = {
            topLevelCompanyId: this.tlCompany.companyID,
            companyIds: this.filters.companyIds,
            stateIds: this.filters.stateIds,
            propertyTypeIds: this.filters.propertyTypeIds,
            taxYear: this.filters.yearEnd
        };
        return lastValueFrom(this.httpClient.post('/api/CompanyBudget/MissingForecastAnnualYearCount', request)).then((resp: any) => {
            console.log('MissingCountForecast=', resp);
            this.missingSyncData.numMissingTaxYears = resp as number;
        });
    }

    getMissingCountsBudget(): Promise<void> {
        if ( this.selectedMode !== 'budget' )
        {
            return Promise.resolve();
        }
        if ( !this.selectedBudget )
        {
            this.missingSyncData.numMissingTaxYears = 0;
            this.missingSyncData.numMissingBudgets = 0;
            return Promise.resolve();
        }

        const request: Core.BudgetMissingTaxYearAndAnnualBudgetRequest = {
            topLevelCompanyId: this.tlCompany.companyID,
            companyBudgetId: this.selectedBudget.companyBudgetId,
            companyIds: this.filters.companyIds,
            stateIds: this.filters.stateIds,
            propertyTypeIds: this.filters.propertyTypeIds,
            activityStatusIds: this.filters.activityStatusIds,
            cashPayEarlyAdjustment: 0,
            budgetBasis: this.selectedBudget.budgetBasis
        };
        return lastValueFrom(this.httpClient.post('/api/CompanyBudget/MissingBudgetAnnualYearCount', request)).then((resp: Core.BudgetMissingYearAndBudgetCount) => {
            console.log('MissingCountsBudget=', resp);
            this.missingSyncData.numMissingTaxYears = resp.missingTaxYears;
            this.missingSyncData.numMissingBudgets = resp.missingBudgets;
        });
    }

    async getMissingCountsBudgetForFreeze(): Promise<BudgetTaxYearMissingSyncData> {
        const result = new BudgetTaxYearMissingSyncData();
        if ( this.selectedMode !== 'budget' || !this.selectedBudget )
        {
            return Promise.resolve(result);
        }

        const request: Core.BudgetMissingTaxYearAndAnnualBudgetRequest = {
            topLevelCompanyId: this.tlCompany.companyID,
            companyBudgetId: this.selectedBudget.companyBudgetId,
            companyIds: [],
            stateIds: [],
            propertyTypeIds: [],
            activityStatusIds: [],
            cashPayEarlyAdjustment: 0,
            budgetBasis: this.selectedBudget.budgetBasis
        };
        return lastValueFrom(this.httpClient.post('/api/CompanyBudget/MissingBudgetAnnualYearCount', request)).then((resp: Core.BudgetMissingYearAndBudgetCount) => {
            result.numMissingTaxYears = resp.missingTaxYears;
            result.numMissingBudgets = resp.missingBudgets;
            return result;
        });
    }

    getOutOfSyncCountsBudget(): Promise<void> {
        if ( this.selectedMode !== 'budget' )
        {
            return Promise.resolve();
        }
        if ( !this.selectedBudget )
        {
            this.missingSyncData.numOutOfSyncBudgets = 0;
            return Promise.resolve();
        }

        const request: Core.BudgetMissingTaxYearAndAnnualBudgetRequest = {
            topLevelCompanyId: this.tlCompany.companyID,
            companyBudgetId: this.selectedBudget.companyBudgetId,
            companyIds: this.filters.companyIds,
            stateIds: this.filters.stateIds,
            propertyTypeIds: this.filters.propertyTypeIds,
            activityStatusIds: this.filters.activityStatusIds,
            cashPayEarlyAdjustment: 0,
            budgetBasis: this.selectedBudget.budgetBasis
        };
        return lastValueFrom(this.httpClient.post('/api/CompanyBudget/getOutOfSyncBudgetCount', request)).then((resp: any) => {
            console.log('OutOfSyncCount=', resp);
            this.missingSyncData.numOutOfSyncBudgets = resp as number;
        });
    }

    startCreateMissingForecast(request: Core.ForecastParcelMissingYearsDTO) {
        return lastValueFrom(this.httpClient.post<Compliance.LongRunningProcessModel>(
            '/api/CompanyBudget/addMissingForecastTaxYears',
            request
        ));
    }

    startCreateMissingBudgetTaxYear(request: Core.BudgetMissingTaxYearAndAnnualBudgetRequest) {
        return lastValueFrom(this.httpClient.post<Compliance.LongRunningProcessModel>(
            '/api/CompanyBudget/addMissingBudgetTaxYearsAndBudgets',
            request
        ));
    }

    startSyncBudgets(request: Core.BudgetMissingTaxYearAndAnnualBudgetRequest) {
        return lastValueFrom(this.httpClient.post<Compliance.LongRunningProcessModel>(
            '/api/companybudget/SyncBudgets',
            request
        ));
    }

    async freezeUnfreezeBudget() {
        if ( this.selectedMode !== 'budget' || !this.selectedBudget )
        {
            return Promise.resolve();
        }

        const request: ForecastBudgetEditRequest = new ForecastBudgetEditRequest();
        request.companyBudgetId = this.selectedBudget.companyBudgetId;
        request.filters.siteRollup = false;
        request.filters.yearBegin = this.filters.yearBegin;
        request.filters.yearEnd = this.filters.yearEnd;
        request.autoSyncBudgetToLatest = this.autoSyncBudgetToLatest;
        request.forBulkUpdate = true;
        request.details.editAction = this.selectedBudget.isFrozen ? Core.ForecastBudgetEditActionEnum.Unfreeze : Core.ForecastBudgetEditActionEnum.Freeze;
        request.budgetBasis - this.selectedBudget.budgetBasis;

        const editResult: Core.ForecastBudgetEditResult = await this.editForecastBudget(Core.EntityTypes.Company, this.tlCompany.companyID, request);

        if ( request.details.editAction === Core.ForecastBudgetEditActionEnum.Unfreeze )
            console.log(`${editResult.budgetUnfrozenCount} Budgets Unfrozen`);
        else
            console.log(`${editResult.budgetFrozenCount} Budgets Frozen`);

        this.postProcessEditResult(request, editResult);
    }

    async applyBulkUpdateToEntry(entry: Core.ForecastGridEntry, editRequest: ForecastBudgetEditRequest): Promise<Core.ForecastBudgetEditResult> {

        /*
        return new Promise((resolve) => {
            setTimeout(() => {
                let editResult: Core.ForecastBudgetEditResult = {
                    parcelCount: 2,
                    siteCount: 1,
                    assessmentCount: 2,
                    billCount: 3,
                    paymentCount: 6,
                    budgetSyncedCount: 2
                };
                resolve(editResult);
            }, 1000);
        });
        */
       // Have to set Reference FMV for appropriate actions to facilitate DB Concurrency checks.
       this.setReferenceFMV(entry, editRequest);

       const editResult: Core.ForecastBudgetEditResult =
            await this.editForecastBudget(entry.entityTypeId, entry.entityId, editRequest);
        console.log('editResult=', editResult);

        return editResult;
    }

    private _getIdentityColumns(): ColDef [] {
        let cols = [this._getCheckboxColumn()];

        if(this.selectedMode == 'budget') {
            cols.push(this._getIsFrozenColumn());
        }

        cols = [...cols,
                ...this._getLocationColumns(),
                ...this._getEntityColumns(),
                this._getActivityStatusColumn()];

        if (this.forecastGridResults.characteristics.length) {
            cols = [...cols, ...this._getBudgetCharacteristicsColumns()];
        }

        return cols;
    }

    private _getCheckboxColumn(): ColDef {
        return {
            colId: 'bigCheckbox',
            width: AgGridColumns.selectionColumnWidth,
            minWidth: AgGridColumns.selectionColumnWidth,
            maxWidth: AgGridColumns.selectionColumnWidth,
            resizable: false,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            suppressColumnsToolPanel: true,
            pinned: 'left',
            lockPinned: true
        };
    }

    private _getIsFrozenColumn(): ColDef {
        return {
            colId: 'isFrozen',
            headerName: 'Frozen',
            field: 'isFrozen',
            pinned: 'left',
            lockPinned: true,
            filter: true,
            filterParams: AgGridFilterParams.booleanFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.booleanFloatingFilterParams,
            headerClass: 'text-center',
            cellClass: 'text-center',
            width: AgGridColumns.checkboxColumnMinWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            maxWidth: AgGridColumns.textColumnSmallWidth,
            suppressAutoSize: true,
            cellRendererFramework: AgGridCheckboxCellRendererComponent,
            cellRendererParams: {
                onValueChanged: this._onFrozenEdited.bind(this),
                isVisible: (params) => !params.data.totalRow,
                canEdit: (params) => this.editMode && params.data.budgetData.length && _.every(params.data.budgetData, ['isMissing', false]) && !this.selectedBudget.isFrozen,
                canEnterEditMode: () => true
            } as ICellRendererParamsForAgGridCheckbox
        };
    }

    private _getLocationColumns(): ColDef[] {
        return [{
            colId: 'stateAbbr',
            headerName: 'State',
            headerClass: 'text-align-center',
            pinned: 'left',
            lockPinned: true,
            lockVisible: true,
            field: 'stateAbbr',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.stateAbbreviationColumnWidth,
            minWidth: AgGridColumns.stateAbbreviationColumnWidth,
            maxWidth: AgGridColumns.textColumnSmallWidth
        },
        {
            colId: 'city',
            headerName: 'City',
            field: 'city',
            pinned: 'left',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            hide: true
        }, {
            colId: 'assessorInfo',
            headerName: 'Assessor Abbr',
            pinned: 'left',
            field: 'assessorInfo',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            hide: true,
            cellRendererFramework: AgGridLinkCellRendererComponent,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data; // as Core.ForecastGridEntry;
                    if (params.data.totalRow || !entry || !entry.assessorId) {
                        return '';
                    }

                    return `#/states/${entry.stateId}/assessors/${entry.assessorId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    return params.data.totalRow || !entry || !entry.assessorId || this.isEditingCell || this.updatingCell;
                }
            } as AgGridLinkCellRendererParams
        }];
    }

    private _getEntityColumns(): ColDef[] {
        let entityCols = [...this._getSiteIdentityColumns()];

        if (!this.filters.siteRollup) {
            entityCols = [...entityCols, this._getParcelAcctNumColumn()];
        }

        return entityCols;
    }

    private _getSiteIdentityColumns(): ColDef[] {
        return [{
            colId: 'siteName',
            headerName: 'Site Name',
            field: 'siteName',
            pinned: 'left',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            cellRendererFramework: AgGridLinkCellRendererComponent,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    if (params.data.totalRow || !entry || !entry.siteId) {
                        return '';
                    }
                    return `#/site/${entry.siteId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    return params.data.totalRow || !entry || !entry.siteId || this.isEditingCell || this.updatingCell
                }
            } as AgGridLinkCellRendererParams
        },{
            colId: 'siteNumber',
            headerName: 'Site Number',
            field: 'siteNumber',
            pinned: 'left',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            cellRendererFramework: AgGridLinkCellRendererComponent,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    if (params.data.totalRow || !entry || !entry.siteId) {
                        return '';
                    }
                    return `#/site/${entry.siteId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    return params.data.totalRow || !entry || !entry.siteId || this.isEditingCell || this.updatingCell;
                }
            } as AgGridLinkCellRendererParams
        }, {
            colId: 'siteClass',
            headerName: 'Site Class',
            pinned: 'left',
            field: 'siteClass',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnMedWidth,
            hide: true
        }];
    }

    private _getParcelAcctNumColumn(): ColDef {
        return {
            colId: 'parcelAcctNum',
            headerName: 'Parcel Acct #',
            field: 'parcelAcctNum',
            pinned: 'left',
            width: AgGridColumns.textColumnMedWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            cellRendererFramework: AgGridLinkCellRendererComponent,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                newWindow: true,
                isHelpDisabled: true,
                getLink: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    if (params.data.totalRow || !entry || !entry.parcelId) {
                        return '';
                    }
                    return `#/parcel/${entry.parcelId}`;
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    const entry = params.data as Core.ForecastGridEntry;
                    return params.data.totalRow || !entry || !entry.parcelId || this.isEditingCell || this.updatingCell;
                }
            } as AgGridLinkCellRendererParams
        };
    }

    private _getActivityStatusColumn(): ColDef {
        return {
            colId: 'activityStatus',
            headerName: 'Status',
            pinned: 'left',
            field: 'activityStatus',
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            width: AgGridColumns.textColumnSmallWidth,
            minWidth: AgGridColumns.textColumnExtraSmallWidth,
            hide: true
        };
    }

    private _getBudgetCharacteristicsColumns(): ColDef[] {
        const duplicates = this.forecastGridResults.characteristics.reduce((acc, x) => {
            acc[x.descriptorName] = _.has(acc, x.descriptorName);
            return acc;
        }, {});
        return this.forecastGridResults.characteristics.map((x, i) => {
            let type = '';
            if (duplicates[x.descriptorName]) {
                if (x.entityTypeId === 5) {
                    type = 'Site';
                } else if (x.entityTypeId === 6) {
                    type = 'Parcel';
                }
            }

            let filter = 'agTextColumnFilter';
            let filterParams = AgGridFilterParams.textFilterWithBlankOptionsParams;
            let floatingFilterComponentParams = AgGridFilterParams.textFloatingFilterParams;
            let columnWidth: number = AgGridColumns.textColumnMedWidth;
            let valueFormatter: any;
            switch(x.descriptorFieldTypeId) {
                case Core.DescriptorFieldTypes.Number:
                case Core.DescriptorFieldTypes.Currency:
                    filter = 'agNumberColumnFilter';
                    filterParams = AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams;
                    floatingFilterComponentParams = AgGridFilterParams.numberFloatingFilterParams;
                    columnWidth = AgGridColumns.numericColumnWidth;
                    if ( x.descriptorFieldTypeId == Core.DescriptorFieldTypes.Currency ) {
                        valueFormatter = (params) => {
                            if (params.value === null || params.value === undefined || params.value === '') {
                                return '';
                            }
                            const numCurrency: number = parseFloat(params.value);
                            if ( isNaN(numCurrency) ) {
                                return '';
                            }
                            return this._formatCurrency(numCurrency);
                        };
                    }
                    else {
                        valueFormatter = (params) => this._decimalPipe.transform(params.value, '1.2-2');
                    }
                    break;
                case Core.DescriptorFieldTypes.Date:
                    filter = 'agDateColumnFilter';
                    filterParams = AgGridFilterParams.dateFilterParamsWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.dateFloatingFilterParams;
                    columnWidth = AgGridColumns.dateColumnWidth;
                    valueFormatter = (y) => {
                        if (!y.value) {
                            return '';
                        }

                        const d = new Date(y.value);
                        return this._datePipe.transform(d, true);
                    };
                    break;
                case Core.DescriptorFieldTypes.YesNo:
                    filterParams = AgGridFilterParams.booleanFilterWithBlankOptionsParams;
                    floatingFilterComponentParams = AgGridFilterParams.booleanFloatingFilterParams;
                    valueFormatter = (y) => {
                        return y.value.toLowerCase() === 'true' ? 'Yes'
                            : (y.value.toLowerCase() === 'false' ? 'No' : '');
                    };
                    break;
            }

            return {
                colId: `characteristic.${type ? `${type}.` : ''}${x.descriptorName.replace(/\s+/g, '')}`,
                headerName: `${type ? `${type} ` : ''}${x.descriptorName}`,
                field: `${type}.${x.descriptorName}`,
                toolPanelClass: 'Characteristics',
                pinned: 'left',
                lockPinned: false,
                hide: i >= 3,
                filter,
                filterParams,
                floatingFilterComponentParams,
                width: columnWidth,
                minWidth: AgGridColumns.textColumnSmallWidth,
                valueFormatter: valueFormatter,
                valueGetter: (y) => {
                    const entry = y.data as Core.ForecastGridEntry;
                    return (entry && entry.characteristicValues) ? entry.characteristicValues[i] : '';
                }
            };
        });
    }


    private async _onFrozenEdited(params: ICellRendererParamsForAgGridCheckbox, newValue: boolean): Promise<void> {
        const forecastBudgetEditDetails: Core.ForecastBudgetEditDetails = {
            editAction: newValue ? Core.ForecastBudgetEditActionEnum.Freeze : Core.ForecastBudgetEditActionEnum.Unfreeze
        };

        await this._updateCell(params.data, forecastBudgetEditDetails);
    }

    private _getFMVColumn(year: number): ColDef {
        return {
            headerName: `FMV ${year}`,
            headerClass: 'text-align-right',
            field: `totalFMV${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            editable: (params) => {
                if(!this.editMode) {
                    return false;
                }

                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});

                return assessment && !params.data.totalRow && !this._isInvalidNumber(assessment.totalFMV) && !assessment.actualized;
            },
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.Fmv
            } as ForecastBudgetYearCellRendererParams,
            cellEditorFramework: ForecastBudgetYearCellEditorComponent,
            cellEditorParams: {
                onValueBlur: async (params: ForecastBudgetYearCellEditorParams, value: number) => {
                    const editDetails: ForecastBudgetEditDetails = {
                        editAction: Core.ForecastBudgetEditActionEnum.SetTotalFMV,
                        taxYear: params.year,
                        valueFMV: value
                    };

                    await this._updateCell(params.data, editDetails);

                },
                year: year,
                yearColumnType: YearColumnType.Fmv
            } as ForecastBudgetYearCellEditorParams,
            cellClass: params => this._getFMVCellClass(params, year, true),
            valueGetter: params => {
                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});

                return assessment ? assessment.totalFMV : 0;
            }
        };
    }

    private async _updateCell(entry: Core.ForecastGridEntry, forecastBudgetEditDetails: ForecastBudgetEditDetails): Promise<void> {
        this.gridApi.showLoadingOverlay();
        this.updatingCell = true;

        const request: ForecastBudgetEditRequest = new ForecastBudgetEditRequest();
        request.companyBudgetId = this.selectedMode == 'budget' ? this.selectedBudget.companyBudgetId : 0;
        request.details = forecastBudgetEditDetails;
        request.filters = _.cloneDeep(this.filters);
        request.autoSyncBudgetToLatest = this.autoSyncBudgetToLatest;
        request.forBulkUpdate = false;
        if ( this.selectedBudget ) {
            request.budgetBasis = this.selectedBudget.budgetBasis;
        }

       // Have to set Reference FMV for appropriate actions to facilitate DB Concurrency checks.
       this.setReferenceFMV(entry, request);

        const editResult: Core.ForecastBudgetEditResult =
            await this.editForecastBudget(this.forecastGridSearchModel.filters.siteRollup ? Core.EntityTypes.Site : Core.EntityTypes.Parcel,
                                          entry.entityId,
                                          request);
        console.log('editResult=', editResult);
        this.postProcessEditResult(request, editResult);

        // Refresh Out-of-sync Budgets count, if in Budget mode, in case a budget
        // was synced after FMV change and bill recalc or unfreeze.
        if ( this.autoSyncBudgetToLatest )
        {
            await this.getOutOfSyncCountsBudget();
        }

        await this.loadData();
        this.updateTotals();

        this.updatingCell = false;
        this.gridApi.hideOverlay();
    }

    private setReferenceFMV(entry: Core.ForecastGridEntry, request: ForecastBudgetEditRequest) : void
    {
        if (request.details.editAction == Core.ForecastBudgetEditActionEnum.SetTotalFMV ||
            request.details.editAction == Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV ||
            request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribTotalFMV)
        {
            const assessment = _.find(entry.assessments, {annualYear: request.details.taxYear});
            request.referenceFMVForConcurrency = assessment ? assessment.returnedTotalFMV : null;
        }
        else if (request.details.editAction == Core.ForecastBudgetEditActionEnum.SetAltFMV ||
                 request.details.editAction == Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV ||
                 request.details.editAction == Core.ForecastBudgetEditActionEnum.DistribAltFMV)
        {
            const assessment = _.find(entry.assessments, {annualYear: request.details.taxYear});
            request.referenceFMVForConcurrency = assessment ? assessment.returnedAltFMV : null;
        }
    }

    private _isInvalidNumber(value: string | number): boolean {
        return value === null || value === undefined || value === '' || value === '.';
    }

    private _getAltFMVColumn(year: number): ColDef {
        return {
            headerName: `FMV_A ${year}`,
            headerClass: 'text-align-right',
            field: `altFMV${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            editable: (params) => {
                if(!this.editMode) {
                    return false;
                }

                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});
                return assessment && !params.data.totalRow && !this._isInvalidNumber(assessment.altFMV) && !assessment.actualized;
            },
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.Fmv_A
            } as ForecastBudgetYearCellRendererParams,
            cellEditorFramework: ForecastBudgetYearCellEditorComponent,
            cellEditorParams: {
                onValueBlur: async (params: ForecastBudgetYearCellEditorParams, value: number) => {
                    const forecastBudgetEditDetails: Core.ForecastBudgetEditDetails = {
                        editAction: Core.ForecastBudgetEditActionEnum.SetAltFMV,
                        taxYear: params.year,
                        valueFMV: value
                    };

                    await this._updateCell(params.data, forecastBudgetEditDetails);
                },
                year: year,
                yearColumnType: YearColumnType.Fmv_A
            } as ForecastBudgetYearCellEditorParams,
            cellClass: params => this._getFMVCellClass(params, year, true),
            valueGetter: params => {
                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});

                return assessment ? assessment.altFMV : 0;
            }
        };
    }

    private _getFMVChangeColumn(year: number) {
        return {
            headerName: 'FMV Chg',
            headerClass: 'text-align-right',
            field: 'yoyPctChg',
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            editable: (params) => {
                if(!this.editMode) {
                    return false;
                }

                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});
                return assessment && !this._isInvalidNumber(assessment.yoyPctChg) && !assessment.actualized;
            },
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.FmvChange
            } as ForecastBudgetYearCellRendererParams,
            cellEditorFramework: ForecastBudgetYearCellEditorComponent,
            cellEditorParams: {
                onValueBlur: async (params: ForecastBudgetYearCellEditorParams, value: number) => {
                    const forecastBudgetEditDetails: Core.ForecastBudgetEditDetails = {
                        editAction: Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV,
                        taxYear: params.year,
                        yoyPercentFMV: value
                    };

                    await this._updateCell(params.data, forecastBudgetEditDetails);
                },
                year: year,
                yearColumnType: YearColumnType.FmvChange
            } as ForecastBudgetYearCellEditorParams,
            cellClass: params => this._getFMVCellClass(params, year),
            valueGetter: params => {
                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});

                return assessment ? assessment.yoyPctChg : 0;
            }
        };
    }

    private _getAltFMVChangeColumn(year: number) {
        return {
            headerName: 'FMV_A Chg',
            headerClass: 'text-align-right',
            field: 'altYoyPctChg',
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            editable: (params) => {
                if(!this.editMode) {
                    return false;
                }

                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});
                return assessment && !this._isInvalidNumber(assessment.altYoyPctChg) && !assessment.actualized;
            },
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.FmvChange_A
            } as ForecastBudgetYearCellRendererParams,
            cellEditorFramework: ForecastBudgetYearCellEditorComponent,
            cellEditorParams: {
                onValueBlur: async (params: ForecastBudgetYearCellEditorParams, value: number) => {
                    const forecastBudgetEditDetails: Core.ForecastBudgetEditDetails = {
                        editAction: Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV,
                        taxYear: params.year,
                        yoyPercentFMV: value
                    };

                    await this._updateCell(params.data, forecastBudgetEditDetails);
                },
                year: year,
                yearColumnType: YearColumnType.FmvChange_A
            } as ForecastBudgetYearCellEditorParams,
            cellClass: params => this._getFMVCellClass(params, year),
            valueGetter: params => {
                const assessment = _.find(params.data.assessments as Core.ForecastGridEntryAssessment[], {annualYear: year});

                return assessment ? assessment.altYoyPctChg : 0;
            }
        };
    }

    private _getBudgetFMVColumn(year: number): ColDef {
        return {
            headerName: `Bdg ${year}`,
            headerClass: 'text-align-right',
            field: `budgetFmv${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.BudgetFmv
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getBudgetCellClass(params, year),
            valueGetter: params => {
                const budget = _.find(params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: year});

                return budget ? budget.budgetFmv : 0;
            }
        };
    }

    private _getBudgetFMVVarianceColumn(year: number): ColDef {
        return {
            headerName: 'Bdg Variance',
            headerClass: 'text-align-right',
            field: `budgetFmvVariance${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.BudgetFmvVariance
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getBudgetCellClass(params, year, false, true),
            valueGetter: params => {
                const budget = _.find(params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: year});

                return budget ? budget.budgetFmvVariance : 0;
            }
        };
    }

    private _getBudgetAltFMVColumn(year: number): ColDef {
        return {
            headerName: `Bdg_A ${year}`,
            headerClass: 'text-align-right',
            field: `budgetAltFmv${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.BudgetAltFmv
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getBudgetCellClass(params, year),
            valueGetter: params => {
                const budget = _.find(params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: year});

                return budget ? budget.budgetAltFmv : 0;
            }
        };
    }

    private _getBudgetAltFMVVarianceColumn(year: number): ColDef {
        return {
            headerName: 'Bdg Variance_A',
            headerClass: 'text-align-right',
            field: `budgetAltFmvVariance${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.BudgetAltFmvVariance
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getBudgetCellClass(params, year, false, true),
            valueGetter: params => {
                const budget = _.find(params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: year});

                return budget ? budget.budgetAltFmvVariance : 0;
            }
        };
    }

    private _getBudgetTaxColumn(year: number): ColDef {
        return {
            headerName: `Bdg ${year}`,
            headerClass: 'text-align-right',
            field: `budgetTax${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.BudgetTax
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getBudgetCellClass(params, year, true),
            valueGetter: params => {
                const budget = _.find(params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: year});

                return budget ? budget.budgetTax : 0;
            }
        };
    }

    private _getBudgetTaxVarianceColumn(year: number): ColDef {
        return {
            headerName: 'Bdg Variance',
            headerClass: 'text-align-right',
            field: `budgetTaxVariance${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.BudgetTaxVariance
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getBudgetCellClass(params, year, true, true),
            valueGetter: params => {
                const budget = _.find(params.data.budgetData as Core.ForecastGridEntryBudget[], {annualYear: year});

                return budget ? budget.budgetTaxVariance : 0;
            }
        };
    }

    private _getFMVCellClass(params, year: number, isFmvColumn?: boolean): string {
        const entry: Core.ForecastGridEntry = params.data;
        const assessment = _.find(entry.assessments, {annualYear: year}) as Core.ForecastGridEntryAssessment;

        if(!assessment) {
            return '';
        }

        let classString = 'text-align-right';

        if(!params.data.totalRow) {
            classString += assessment.actualized ? '' : ' tax-rate-estimated';
            classString += assessment.autoCalculated || assessment.actualized ? '' : ' overridden';
            classString += isFmvColumn && assessment.hasExemptions ? ' exemption' : '';
        }
        // classString += assessment.utilized ? ' bold-text' : '';

        return classString;
    }

    private _getBudgetCellClass(params, year: number, isTax?: boolean, isVariance?: boolean): string {
        const entry: Core.ForecastGridEntry = params.data;
        const budget = _.find(entry.budgetData, {annualYear: year}) as Core.ForecastGridEntryBudget;

        if(!budget) {
            return '';
        }

        let classString = 'text-align-right';

        if(!params.data.totalRow) {
            classString += !isTax && budget.fmvUtilized ? ' bold-text' : '';
            classString += isTax && budget.taxUtilized ? ' bold-text' : '';
            classString += budget.isMissing && !isVariance ? ' pending' : '';
        }

        return classString;
    }

    private _getTaxColumn(year: number): ColDef {
        return {
            headerName: `Tax ${year}`,
            headerClass: 'text-align-right',
            field: `totalTax${  year}`,
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.Tax
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getTaxCellClass(params, year),
            valueGetter: params => {
                const tax = _.find(params.data.taxes as Core.ForecastGridEntryTax[], {annualYear: year});

                return tax ? tax.totalTax : 0;
            }
        };
    }

    private _getTaxChangeColumn(year: number): ColDef {
        return {
            headerName: 'Rate Chg',
            headerClass: 'text-align-right',
            field: 'yoyPctChg',
            width: AgGridColumns.numericColumnWidth,
            suppressToolPanel: true,
            cellRendererFramework: ForecastBudgetYearCellRendererComponent,
            cellRendererParams: {
                year: year,
                yearColumnType: YearColumnType.TaxChange
            } as ForecastBudgetYearCellRendererParams,
            cellClass: params => this._getTaxCellClass(params, year),
            valueGetter: params => {
                const tax = _.find(params.data.taxes as Core.ForecastGridEntryTax[], {annualYear: year});

                return tax ? tax.yoyPctChg : 0;
            }
        };
    }

    private _getTaxCellClass(params, year: number): string {
        const entry: Core.ForecastGridEntry = params.data;
        const taxYear = _.find(entry.taxes, {annualYear: year});

        if(!taxYear) {
            return '';
        }

        let classString = 'text-align-right';

        if(!params.data.totalRow) {
            classString += taxYear.actualized ? '' : ' tax-rate-estimated';
            classString += taxYear.autoCalculated || taxYear.actualized ? '' : ' overridden';
            // classString += taxYear.utilized ? ' bold-text' : '';
        }

        return classString;
    }

    private _formatCurrency(value: number): string {
        if(value == null) {
            return '';
        }

        const formattedVal = this.currencyPipe.transform(Math.abs(value), 'USD', 'symbol-narrow');
        return value < 0 ? `(${formattedVal})` : formattedVal;
    }

    private _compileNestedTotals(rowNode:RowNode, totalEntry: any) {
        const entry = rowNode.data as Core.ForecastGridEntry;

        _.forEach(entry.assessments, assessment => {
            const foundAssessment: any = _.find(totalEntry.assessments, {annualYear: assessment.annualYear});
            if(!foundAssessment) {
                const startingAssessment = _.chain(assessment)
                    .pick('altFMV', 'totalFMV', 'annualYear')
                    .mapValues(value => value || 0)
                    .value();

                totalEntry.assessments.push(startingAssessment);
            } else {
                if(!this._isInvalidNumber(assessment.altFMV)) {
                    foundAssessment.altFMV = new Decimal(foundAssessment.altFMV).add(assessment.altFMV).toNumber();
                }

                if(!this._isInvalidNumber(assessment.totalFMV)) {
                    foundAssessment.totalFMV = new Decimal(foundAssessment.totalFMV).add(assessment.totalFMV).toNumber();
                }
            }
        });

        _.forEach(entry.taxes, taxYear => {
            const foundTaxYear: any = _.find(totalEntry.taxes, {annualYear: taxYear.annualYear});
            if(!foundTaxYear) {
                const startingTaxYear = _.chain(taxYear)
                    .pick('totalTax', 'annualYear')
                    .mapValues(value => value || 0)
                    .value();

                totalEntry.taxes.push(startingTaxYear);
            } else {
                if(!this._isInvalidNumber(taxYear.totalTax)) {
                   foundTaxYear.totalTax = new Decimal(foundTaxYear.totalTax).add(taxYear.totalTax).toNumber();
                }
            }
        });

        _.forEach(entry.budgetData, budget => {
            const foundBudget: any = _.find(totalEntry.budgetData, {annualYear: budget.annualYear});
            if(!foundBudget) {
                const startingBudget = _.chain(budget)
                    .pick('budgetFmv', 'budgetAltFmv', 'budgetTax', 'annualYear')
                    .mapValues(value => value || 0)
                    .value();

                totalEntry.budgetData.push(startingBudget);
            } else {
                if(!this._isInvalidNumber(budget.budgetFmv)) {
                    foundBudget.budgetFmv = new Decimal(foundBudget.budgetFmv).add(budget.budgetFmv).toNumber();
                }

                if(!this._isInvalidNumber(budget.budgetAltFmv)) {
                    foundBudget.budgetAltFmv = new Decimal(foundBudget.budgetAltFmv).add(budget.budgetAltFmv).toNumber();
                }

                if(!this._isInvalidNumber(budget.budgetTax)) {
                    foundBudget.budgetTax = new Decimal(foundBudget.budgetTax).add(budget.budgetTax).toNumber();
                }
            }
        });
    }
}
