import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseRepository } from '../Common/Data/base.repository';
import { Observable } from 'rxjs';

export interface ParcelCollectorBillScenarioInfo {
    collectorAbbr: string;
    collectorBillInfo: ParcelCollectorBillInfo[];
    collectorBillScenarioID: number;
    collectorBillScenarioName: string;
    collectorID: number;
}

export interface ParcelCollectorBillInfo {
    collectorBillID: number;
    collectorBillName: string;
    collectorBillScenarioID: number;
    collectorPaymentOptionInfo: ParcelCollectorPaymentOptionInfo[];
}

export interface ParcelCollectorPaymentOptionInfo {
    collectorPaymentOptionID: number;
    collectorPaymentOptionName: string;
    isDefault: boolean;
}

@Injectable()
export class BillingScenarioRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient
    ) {
        super(httpClient);
    }

    private readonly SERVICE_URL = '/api/collectorbillscenario/';

    getById(id: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${id}`, {});
    }

    getByParcelId(id: number): Observable<ParcelCollectorBillScenarioInfo[]> {
        return this.httpGet(`${this.SERVICE_URL}parcel/${id}`);
    }

    getInUse(id: number): Observable<any> {
        return this.httpGet(`${this.SERVICE_URL}${id}/inuse`);
    }

    addScenario(scenario: any): Observable<any> {
        return this.httpPost(`${this.SERVICE_URL}`, scenario);
    }

    updateScenario(scenario: any): Observable<any> {
        return this.httpPut(`${this.SERVICE_URL}`, scenario);
    }

    deleteScenario(id: number): Observable<any> {
        return this.httpDelete(`${this.SERVICE_URL}${id}`);
    }
}
