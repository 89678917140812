import { Injectable } from '@angular/core';

@Injectable()
export class BusyIndicatorMessageIdService {
    private readonly _BUSY_IDENTIFIER_PREFIX = '_RYN_BUSY_INDICATOR_';
    private _busyIdentifierCounter: number = 0;

    generateUniqueMessageIdentifier(): string {
        return `${this._BUSY_IDENTIFIER_PREFIX}${++this._busyIdentifierCounter}`;
    }
}
