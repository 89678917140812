import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

export interface ICsrUpdateStatusCellRendererParams extends ICellRendererParams{
    retryAction: (ICellRendererParams)=>Promise<void>
}

@Component({
    selector: 'grid-csr-update-status-cell',
    template:
        `
        <div>
            <div *ngIf="this.csrUpdateStatus === 1 || this.csrUpdateStatus=== 0" title="This responsibility is updating in the background.  Changes can't be made until they are finished">
                <i class="fa fa-info-circle"></i>
            </div>
            <button class="ace-btn has-icon" (click)="retryResponsiblityUpdate()" *ngIf="this.csrUpdateStatus === 3"
                    title="This responsibility didn't update correctly.  Push this button to retry the updates.  Some changes can't be made until the updates succeed.">
                <i class="fa fa-repeat"></i>
            </button>
        </div>
        `
})
export class CsrUpdateStatusCellRendererComponent implements ICellRendererAngularComp {
    csrUpdateStatus: number;
    params: ICellRendererParams;
    retryAction: (ICellRendererParams)=>Promise<void>;

    refresh(params: any): boolean {
        return false;
    }
    agInit(params: ICsrUpdateStatusCellRendererParams): void {
        this.csrUpdateStatus = params?.data?.csrUpdateStatus;
        this.params = params;
        this.retryAction = params.retryAction as (ICellRendererParams)=>Promise<void>;
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        return null;
    }
    async retryResponsiblityUpdate() {
        await this.retryAction(this.params);
        this.csrUpdateStatus = null;
    }
}
