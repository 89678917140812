import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { ToastrService } from 'ngx-toastr';
import { DownloadFileService } from '../../../Common/Download/downloadFileService';
import { AppealApplicationService } from '../../appeal.application.service';
import { AppealFormItem } from '../../appeal.application.model';
import { has } from 'lodash';

export interface DownloadAppealFormModalParams {
    appealForms: AppealFormItem[],
    supplementalForms: { [id: number]: number[] }
}

@Component({
    selector: 'download-appeal-form-modal',
    templateUrl: './downloadAppealForm.component.html'
})
export class DownloadAppealFormComponent implements OnInit, IWeissmanModalComponent<DownloadAppealFormModalParams, void> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _toastr: ToastrService,
        private readonly _downloadFileService: DownloadFileService,
        private readonly _appealApplicationService: AppealApplicationService
    ) {}

    params: DownloadAppealFormModalParams;
    result: void;

    appealForms: AppealFormItem[] = [];
    selectedForm: AppealFormItem;
    isBusy: boolean;
    includeSupplementalForms: boolean;

    ngOnInit(): void {
        this.appealForms = this.params.appealForms || [];
        this.selectedForm = this.appealForms[0];
    }

    async downloadForm(): Promise<void> {
        this.isBusy = true;

        let appealIds = [this.selectedForm.appealId, ...this.selectedForm.additionalAccounts.map(x => x.appealId)];

        if (this.includeSupplementalForms && has(this.params.supplementalForms, this.selectedForm.appealId)) {
            appealIds = [...appealIds, ...this.params.supplementalForms[this.selectedForm.appealId]];
        }

        try {
            const downloadRequest: Core.AppealsDownloadRequest = {
                batchAppealIds: appealIds,
                flatten: false,
                onlyTheseFormRevisions: [],
                zipOutput: false,
                skipOptimize: false
            };

            const response = await this._appealApplicationService.download(downloadRequest);
            await this._downloadFileService.downloadResponse(response);
        } catch (e) {
            this._toastr.error('An error occurred while downloading the form. Please try again later.');
        } finally {
            this.isBusy = false;
        }

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
