import { Component, Input, OnInit } from '@angular/core';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { ContactsUpgradeService } from '../../contacts.service.upgrade';
import { RestrictService } from '../../../Common/Permissions/restrict.service';
import { ContactModalOrigin } from '../../../constants.new';
import {
    ContactListModalComponent,
    ContactListModalParams
} from '../List/Associate-Contact/contactListModal.component';
import { ContactModalService } from '../../contactModal.service';
import { GridOptions } from 'ag-grid-community';

@Component({
    selector: 'contacts-panel-body-actions',
    templateUrl: './panelBodyActions.component.html'
})
export class ContactPanelBodyActionsComponent implements OnInit {
    constructor(
        private readonly _contactsService: ContactsUpgradeService,
        private readonly _contactModalService: ContactModalService,
        private readonly _restrictService: RestrictService,
        private readonly _weissmanModalService: WeissmanModalService
    ) {}

    @Input() entity;
    @Input() contacts;
    @Input() roles;
    @Input() hasAssociateExistingPermission: boolean;
    @Input() isConsultantUser: boolean = false;
    @Input() gridOptions: GridOptions & { api?: { appliedFilter?: any }};

    newAllowed = false;
    hasNewContactPermission = false;
    notAllowedRoles = [];
    singleRoles = [26];

    ngOnInit(): void {
        this.newAllowed = this.entity.newAllowed;

        this.getNewContactPermission();
    }

    async newContact(): Promise<void> {
        await this._contactModalService.openContactDialog(null, ContactModalOrigin.NewContact, this.contacts, this.entity, this.roles);
    }

    async associateExisting(): Promise<any> {
        this.notAllowedRoles = [];

        this.contacts.forEach(x => {
            x.contactRoleAssociations.forEach(y => {
                if (this.singleRoles.indexOf(y.contactRoleID) !== -1) {
                    if (this.notAllowedRoles.indexOf(y.contactRoleID) === -1) {
                        this.notAllowedRoles.push(y.contactRoleID);
                    }
                }
            });
        });

        const params = {
            entityInfo: this.entity,
            notAllowedRoles: this.notAllowedRoles
        } as ContactListModalParams;

        const result = await this._weissmanModalService.showAsync(ContactListModalComponent, params, 'modal-lg');

        if (result && result.contact) {
            // ok, now we have to save new relationship, lets get a contact from our grid (if we have this contact).
            let contact = result.contact;

            const existingContactsWithThisId = this._contactsService.getCached().filter(x => x.contactID === contact.contactID);

            if (existingContactsWithThisId.length > 0) {
                contact = existingContactsWithThisId[0];
            } else {
                // we have contact with ALL roles - either relevant to this entity or not.
                contact.contactRoleAssociations = contact.contactRoleAssociations
                    ? contact.contactRoleAssociations.filter(x => {
                        return (x.entityID === this.entity.id);
                    })
                    : [];
            }

            await this.saveAssociation(contact, result.selectedRole);
        }
    }

    async saveAssociation(contact, selectedRole): Promise<void> {
        const newAssociation = {
            contactID: contact.contactID,
            contactRoleID: selectedRole.contactRoleID,
            entityID: this.entity.id,
            entityType: this.entity.type
        };

        const result = await this._contactsService.addContactRoleAssociation(newAssociation);

        result.roleDesc = selectedRole.roleDesc;

        contact.contactRoleAssociations.push(result);

        // now check if our contact list does have this contact or not.
        const existingContactsWithThisId = this._contactsService.getCached().filter(x => x.contactID === contact.contactID);
        if (existingContactsWithThisId.length === 0) {
            if (!contact.address) {
                contact.address = {
                    phone: contact.phone,
                    mobilePhone: contact.mobilePhone
                };
            }

            this._contactsService.addToCache(contact);
        }
    }

    async getNewContactPermission(): Promise<any> {
        const restrictionData = this._restrictService.getRestrictionDataForEntity(this.entity, Core.AccessRightsEnum.Write);

        try {
            const hasContactPermission = await this._restrictService.hasPermission(restrictionData);
            this.hasNewContactPermission = hasContactPermission || !this.isConsultantUser;
        } catch(err) {
            console.warn('checkEntity - error', err);
        }
    }
}
