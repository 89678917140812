import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Ng2StateDeclaration, UIRouter } from '@uirouter/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagsService } from '../Common/FeatureFlags/feature-flags-service';
import { RestrictService, Roles } from '../Common/Permissions/restrict.service';

interface DiagnosticsOption {
    name: string;
    route: string;
    isShown: () => boolean;
}

@Component({
    selector: 'diagnostics',
    templateUrl: './diagnostics.component.html',
    styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _featureFlagService: FeatureFlagsService,
        private readonly _router: UIRouter,
        private readonly _restrictService: RestrictService
    ) {
    }

    diagnosticControl: FormControl = new FormControl();
    diagnosticOptions: DiagnosticsOption[] = [];

    private _hasAdminRole: boolean;
    private _hasAdminOperationsView: boolean;
    private _hasAdminOperationsEdit: boolean;
    private _hasAllowManageSystem: boolean;

    private _allDiagnosticOptions: DiagnosticsOption[] = [
        {
            name: 'About',
            route: 'diagnostic.about',
            isShown: () => this._hasAdminOperationsView || this._hasAdminOperationsEdit
        },
        {
            name: 'Database',
            route: 'diagnostic.database',
            isShown: () => this._hasAllowManageSystem
        },
        {
            name: 'Error Log',
            route: 'diagnostic.errorLog',
            isShown: () => this._hasAdminOperationsView || this._hasAdminOperationsEdit
        },
        {
            name: 'Long-Running Processes',
            route: 'diagnostic.longRunningProcess',
            isShown: () => this._hasAdminOperationsView || this._hasAdminOperationsEdit
        },
        {
            name: 'Smart, AV, Report Queries',
            route: 'diagnostic.queryInfo',
            isShown: () => this._hasAdminOperationsView || this._hasAdminOperationsEdit
        },
        {
            name: 'Attachment Download Tool',
            route: 'diagnostic.attachmentDownload',
            isShown: () => this._hasAdminOperationsView || this._hasAdminOperationsEdit
        }
    ];

    private _destroy$: Subject<void> = new Subject<void>();
    private _destroyTransitionHook: any;

    ngOnInit(): void {
        this._hasAdminOperationsEdit = this._restrictService.isInRole(Roles.ADMINOPERATIONSEDIT);
        this._hasAdminOperationsView = this._restrictService.isInRole(Roles.ADMINOPERATIONSVIEW);
        this._hasAllowManageSystem = this._restrictService.isInRole(Roles.ALLOWMANAGESYSTEM);

        this._hasAdminRole = this._hasAdminOperationsView;

        if (!this._hasAdminRole) {
            this._router.stateService.go('home', {});
        }

        this.diagnosticOptions = this._allDiagnosticOptions.filter(x => x.isShown());

        this._setDiagnosticControlValue(this._router.globals.current);

        this._destroyTransitionHook = this._router.transitionService.onSuccess({}, (transition) => {
            this._setDiagnosticControlValue(this._router.globals.current);
        });

        this.diagnosticControl.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
            this._router.stateService.transitionTo(value, {});
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
        this._destroyTransitionHook();
    }

    private _setDiagnosticControlValue(routerState: Ng2StateDeclaration): void {
        if (routerState.name === 'diagnostic') {
            return;
        }
        const option = this.diagnosticOptions.find(x => x.route === routerState.name);
        if (option) {
            this.diagnosticControl.setValue(option.route);
        } else {
            console.warn('No route found for', routerState.name);
            this._router.stateService.go('home', {});
        }
    }
}
