<form class="row" [formGroup]="infoForm">
    <div class="col-lg-6">
        <div class="mb-4" [class.has-error]="infoForm.controls.companyName.invalid && infoForm.controls.companyName.touched">
            <label>Name:</label>
            <input type="text" formControlName="companyName" maxlength="100" class="form-control">
        </div>
        <div class="mb-4">
            <label>Top Level Company:</label>
            <input type="text" [value]="isTopLevel ? '[None] - Is Top Level' : parentCompany.companyName" class="form-control" disabled>
        </div>
        <div class="row m-auto">
            <div class="mb-4 col-lg-6" style="padding-left: 0px" [class.has-error]="infoForm.controls.companyCode.invalid && infoForm.controls.companyCode.touched">
                <label>Code:</label>
                <input type="text" formControlName="companyCode" maxlength="100" class="form-control">
            </div>
            <div class="mb-4 col-lg-6" style="padding-right: 0px" [class.has-error]="infoForm.controls.crmNumber.invalid && infoForm.controls.crmNumber.touched">
                <label>CRM Number:</label>
                <input type="text" formControlName="crmNumber" maxlength="10" class="form-control">
            </div>
        </div>
        <div class="mb-4" [class.has-error]="infoForm.controls.url.invalid && infoForm.controls.url.touched">
            <label>Website:</label>
            <input type="text" formControlName="url" maxlength="100" class="form-control">
        </div>
        <div *ngIf="showAddSubsidiary">
            <button type="button" class="ace-btn btn-sm btn-primary" (click)="addSubsidiary()">Add New Subsidiary</button>
        </div>
    </div>
    <div class="col-lg-6">
        <div class="mb-4" style="height: 125px;">
            <div style="display: flex; justify-content: space-between;">
                <label>Address</label>
                <a class="pointer" (click)="launchAddressDetailsModal()">
                    Address Details <span *ngIf="nonDeletedEntityAddresses.length > 1">({{nonDeletedEntityAddresses.length}})</span>
                </a>
            </div>
            <address-view-edit [address]="defaultAddress" [isEditMode]="false" [removeBottomMargin]="true"></address-view-edit>
        </div>
        <div class="mb-4" [class.has-error]="infoForm.controls.iamTenantId.invalid && infoForm.controls.iamTenantId.touched"
             *ngIf="isIAMEnabled">
            <label>tax.com Tenant:</label>
            <input type="text" formControlName="iamTenantId" maxlength="100" class="form-control" *ngIf="!company.iamTenantId">
            <span class="form-control" *ngIf="company.iamTenantId"> {{company.iamTenantName}}</span>
        </div>
        <div class="mb-4">
            <label>Status:</label>
            <select class="form-select form-select-sm"
                    formControlName="activityStatusID"
                    triggers="mouseenter:mouseleave"
                    [popover]="activatedByPopover"
                    placement="auto">
                <option *ngFor="let option of statuses" [ngValue]="option.id">{{option.value}}</option>
            </select>
        </div>
        <div *ngIf="hasMoveCopyPermission">
            <button class="ace-btn btn-sm btn-primary" (click)="goToMoveCopy(true)">Move</button>
            <button class="ace-btn btn-sm btn-primary" (click)="goToMoveCopy()">Copy</button>
            <button type="button" class="ace-btn btn-sm btn-primary btn-danger" (click)="deleteCompany()">Delete Company</button>
        </div>
    </div>
</form>
