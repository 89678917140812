<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header d-flex">
        <h3 class="ws-truncate ws-flex-auto">
            Asset Class Mappings<span *ngIf="assessor"> [{{classificationMappingsAssessorName}} Mappings - {{ factorTablesAssessorName }} Tables - {{taxYear}}]</span>
            <ag-grid-row-count [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
        </h3>
        <div class="ws-flex-none header-button-container">
            <button *ngIf="!editMode"
                    title="Export"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="export()">
                <i class="fa fa fa-file-excel-o fa-align"></i>
            </button>
            <button *ngIf="!editMode && canEdit"
                    [disabled]="!canEnterEditMode"
                    title="Edit"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="edit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
            <button title="Save"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    *ngIf="editMode"
                    [disabled]="!okToSave()"
                    (click)="save()">
                <i class="fa fa-save fa-align"></i>
            </button>
            <button title="Cancel"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    *ngIf="editMode"
                    (click)="cancel()">
                <i class="fa fa-times fa-align"></i>
            </button>
            <ag-grid-filter-toggle [gridOptions]="gridOptions"></ag-grid-filter-toggle>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isExpanded" (click)="expandComponent()">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isExpanded" (click)="collapseComponent()">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>

    </div>
    <div class="panel-flat-body d-flex flex-column">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (filterChanged)="onAgGridFilterChanged($event)"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
