export class CalendarMonth {
    constructor() {
        this.weeks = [];
    }

    weeks: CalendarWeek[];
    name: string;
    year: number;
}

export class CalendarWeek {
    constructor() {
        this.days = [];

        for(let i=0; i< 7; i++) {
            this.days.push(new CalendarDay());
        }
    }

    days: CalendarDay[];
    weekNumber: number;
}

export class CalendarDay {
    monthDay: number;
    eventTypeBlocks: EventTypeBlock[];
    weekdayAbbr: string;
    icons: string[];
}

export class EventTypeBlock {
    dashboardCalendarEventType: number;
    events: DateEvent[];
    totalCount: number;
}

export enum DashboardCalendarEventTypes {
    AppealDeadline = 1,
    AppealFormalHearing = 2,
    AppealInformalHearing = 3,
    AppealSubmitEvidence = 4,
    PaymentDueDate = 5,
    PPReturnComplianceDeadline = 6,
    MiscFilingComplianceDeadline = 7,
    FreeportComplianceDeadline = 8,
    AbatementComplianceDeadline = 9,
    PollutionControlComplianceDeadline = 10,
    AppealConfirmHearing = 11
}

export class DateEvent {
    date: Date;
    stateAbbr: string;
    dashboardCalendarEventType: number;
    count: number;
}

export class LegendBlock {
    title: string;
    count: number;
    dashboardCalendarEventType: number;
    eventTypeBlocks: EventTypeBlock[];
}