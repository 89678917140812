import { Injectable } from '@angular/core';
import {
    BulkInvoicePreCheckResult,
    CreateAdHocInvoiceRequest,
    Invoice,
    InvoiceAppeal,
    InvoiceAppealSearchResult,
    OverrideStatus
} from './invoice.model';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { Decimal } from 'decimal.js';
import * as _ from 'lodash';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Constants, ContractServiceType } from '../../constants.new';

@Injectable()
export class InvoiceService {
    constructor(private http: SDHttpService,
        private numberPipe: DecimalPipe,
        private currencyPipe: CurrencyPipe,
        private constants: Constants) {
    }

    contractServiceName(contractServiceType: ContractServiceType): string {
        return Object.keys(this.constants.ContractServiceTypes)
            .map(k => this.constants.ContractServiceTypes[k])
            .filter(t => t.id == contractServiceType)
            .map(t => t.name)[0];
    }

    getInvoice(invoiceId: string): Promise<Invoice> {
        return this.http.get(`/api/Invoice/${  invoiceId}`).then((invoice: Invoice) => {
            const finalInvoice = this.calculateInvoiceData(invoice);
            return finalInvoice;
        });
    }

    getInvoiceForTask(taskId: number): Promise<Invoice> {
        return this.http.get(`/api/Invoice/Task/${  taskId}`).then((invoice: Invoice) => {
            const finalInvoice = this.calculateInvoiceData(invoice);
            return finalInvoice;
        });
    }

    refreshInvoice(originalInvoice: Invoice): Promise<Invoice> {
        const appealIDs = _.map(originalInvoice.appeals, 'appealID');
        return this.http.post(`/api/Invoice/${  originalInvoice.invoiceId  }/appeal/search`, appealIDs)
                .then((result: InvoiceAppealSearchResult) => {
            let finalInvoice: Invoice = originalInvoice;

            //Need to manually map over the fields we are refreshing. This is so far just appeals and recalculating the data.
            finalInvoice.appeals = result.appeals;
            finalInvoice.otherParcelFMV = result.otherParcelFMV;
            finalInvoice.includeOtherParcels = true;
            finalInvoice = this.resetFromAppealData(finalInvoice);

            return finalInvoice;
        });
    }

    saveInvoice(invoice: Invoice): Promise<Invoice> {
        return this.http.put(`/api/Invoice/${invoice.invoiceId}`, invoice);
    }

    completeInvoice(invoice: Invoice): Promise<Invoice> {
        return this.http.put(`/api/Invoice/${  invoice.invoiceId  }/readytask`, invoice, { handleCode: 400 });
    }

    deleteInvoice(invoiceId: number): Promise<void> {
        return this.http.delete(`/api/invoice/${  invoiceId}`);
    }

    getInvoices(companyId: number, siteId: number, fromDate: Date, toDate: Date): Promise<Invoice[]>{
        let url = '';

        if (siteId){
            url = `/api/site/${  siteId  }/invoices`;
        }
        else{
            url = `/api/company/${  companyId  }/invoices`;
        }

        return this.http.post(url, {fromDate: fromDate, toDate: toDate});
    }

    getReadyInvoiceAppealSavingTasksCount(siteId: number) {
        return this.http.get(`/api/site/${  siteId  }/getParcelsReadyForInvoiceCount`);
    }

    getReadyAdminFeeInvoiceCount(companyId: number): Promise<number> {
        return this.http.get(`/api/company/${  companyId  }/getReadyAdminFeeInvoiceCount`);
    }

    bulkInvoicePreCheck(companyId: number, annualYear: number): Promise<BulkInvoicePreCheckResult> {
        return this.http.get(`/api/company/${  companyId  }/year/${  annualYear  }/bulkInvoicePreCheck`);
    }

    createInvoices(siteId: number) {
        return this.http.post(`/api/invoice/${  siteId  }/createInvoices`);
    }

    createCompanyAdminInvoices(companyId: number, annualYear: number) {
        return this.http.post('/api/invoice/adminbulk', {
            companyId: companyId,
            annualYear: annualYear
        });
    }

    removeAppeal(invoice: Invoice, appealIndex: number): Invoice {
        const removedAppeal: InvoiceAppeal = invoice.appeals.splice(appealIndex, 1)[0];

        if (!_.some(invoice.appeals, (appeal: InvoiceAppeal) => { return appeal.parcelID === removedAppeal.parcelID; })) {
            // Now that this appeal is no longer included, it's final FMV is "another parcel's" FMV (as long
            // as that parcel is not also linked to another appeal on this invoice)
            invoice.otherParcelFMV = new Decimal(invoice.otherParcelFMV).plus(removedAppeal.finalFMV).toNumber();
        }

        return this.resetFromAppealData(invoice);
    }

    resetFromAppealData(invoice: Invoice, preserveOverrides: boolean = false): Invoice {
        const savingsTotal: number = this._appealsSum(invoice.appeals, 'savings').toNumber();

        invoice = this.calculateFMVFields(invoice);

        if (!preserveOverrides) {
            invoice.previousFMVStatus = OverrideStatus.Default;
            invoice.invoiceFMVStatus = OverrideStatus.Default;
            invoice.fmvChangeStatus = OverrideStatus.Default;
            invoice.savingsStatus = OverrideStatus.Default;
            invoice.taxRateStatus = OverrideStatus.Default;
            invoice.contingencyPctStatus = OverrideStatus.Default;
            invoice.feeAmountStatus = OverrideStatus.Default;
            invoice.contingencyCapStatus = OverrideStatus.Default;
            invoice.invoiceAmountStatus = OverrideStatus.Default;
            invoice.isSavingsOverridden = false;

            invoice.previousFMV = invoice.calculatedPreviousFMV;
            invoice.invoiceFMV = invoice.calculatedInvoiceFMV;

            invoice.calculatedSavings = savingsTotal;
            invoice.savings = savingsTotal;

            invoice.contingencyPct = invoice.contingencyPctOriginal;
            invoice.contingencyCap = invoice.contingencyCapOriginal;

            invoice.taxRate = this.calculateTaxRate(invoice);
            invoice.calculatedTaxRate = invoice.taxRate;

            invoice.feeAmount = this.calculateFeeAmount(invoice);
            invoice.calculatedFeeAmount = invoice.feeAmount;
        }
        else {
            if (invoice.previousFMVStatus === OverrideStatus.Default) {
                invoice.previousFMV = invoice.calculatedPreviousFMV;
            }
            if (invoice.invoiceFMVStatus === OverrideStatus.Default) {
                invoice.invoiceFMV = invoice.calculatedInvoiceFMV;
            }

            invoice.fmvChange = invoice.previousFMV - invoice.invoiceFMV;
            // fmvChangeStatus should already be right, since we're not changing FMVStatuses here
        }

        // Re-run the calculate step; if we did our job right, statuses will remain the way we set them here,
        // but any side-effects of changing values will also happen as expected
        return this.calculateInvoiceData(invoice);
    }

    calculateInvoiceData(invoice: Invoice): Invoice {
        const savingsTotal: number = this._appealsSum(invoice.appeals, 'savings').toNumber();

        invoice = this.calculateFMVFields(invoice);

        //Set invoice contingency percent status
        if ((invoice.contingencyPct || 0) === (invoice.contingencyPctOriginal || 0)) {
            invoice.contingencyPctStatus = OverrideStatus.Default;
        } else {
            invoice.contingencyPctStatus = OverrideStatus.Overridden;
        }

        //Set invoice contingency cap status
        if (invoice.contingencyCap === invoice.contingencyCapOriginal) {
            invoice.contingencyCapStatus = OverrideStatus.Default;
        } else {
            invoice.contingencyCapStatus = OverrideStatus.Overridden;
        }

        //Set fixed fee status
        if (invoice.fixedFee === invoice.fixedFeeOriginal) {
            invoice.fixedFeeStatus = OverrideStatus.Default;
        } else {
            invoice.fixedFeeStatus = OverrideStatus.Overridden;
        }

        //Set previous FMV status and replace value if it's default
        if(invoice.previousFMV === invoice.calculatedPreviousFMV) {
            invoice.previousFMVStatus = OverrideStatus.Default;
        } else {
            invoice.previousFMVStatus = OverrideStatus.Overridden;
        }

        //Set invoice FMV status and replace value if it's default
        if(invoice.invoiceFMV === invoice.calculatedInvoiceFMV) {
            invoice.invoiceFMVStatus = OverrideStatus.Default;
        } else {
            invoice.invoiceFMVStatus = OverrideStatus.Overridden;
        }

        invoice.fmvChangeStatus = (invoice.invoiceFMVStatus === OverrideStatus.Overridden ||
                                    invoice.previousFMVStatus === OverrideStatus.Overridden ?
                                    OverrideStatus.Recalculated : OverrideStatus.Default);

        invoice = this.calculateSavingsTaxRate(invoice, savingsTotal);

        invoice.backupContingencyPct = invoice.contingencyPct;

        if(!invoice.contingencyPct) {
            invoice.contingencyPct = 0;
        }

        invoice.calculatedFeeAmount = this.calculateFeeAmount(invoice);

        //Set invoice fee amount status
        //This code block causes some problems when recalculating fee amount
        //There is code to handle this in the details component to combat the problems
        if ((invoice.feeAmount || 0) === invoice.calculatedFeeAmount) {
            invoice.feeAmountStatus = OverrideStatus.Default;
        } else {
            if(invoice.contingencyCapStatus !== OverrideStatus.Overridden &&
                invoice.contingencyPctStatus !== OverrideStatus.Overridden &&
                invoice.savingsStatus !== OverrideStatus.Overridden &&
                invoice.invoiceFMVStatus !== OverrideStatus.Overridden &&
                invoice.feeAmountStatus !== OverrideStatus.Recalculated &&
                invoice.previousFMVStatus !== OverrideStatus.Overridden) {

                invoice.feeAmountStatus = OverrideStatus.Overridden;
            } else if(invoice.feeAmountStatus !== OverrideStatus.Overridden) {
                invoice.feeAmountStatus = OverrideStatus.Recalculated;
                invoice.feeAmount = invoice.calculatedFeeAmount;
            }
        }

        this.setDisplayPercentages(invoice);

        this.setInvoiceStatus(invoice);

        invoice.totalFeeAmount = (invoice.feeAmount || 0) + (invoice.fixedFee || 0);
        invoice.invoiceAmount = invoice.totalFeeAmount + invoice.expenseAmount;

        //This should always go last, so all variables are present
        if(invoice.automaticallyUpdateNotes) {
            invoice.notes = this.generateNotes(invoice);
        }

        return invoice;
    }

    calculateTaxRate(invoice: Invoice): number {
        if (invoice.fmvChange == 0) {
            return 0;
        }
        const savingsDec: Decimal = new Decimal(invoice.savings);

        const taxRateTotal = savingsDec.dividedBy(invoice.fmvChange).toDecimalPlaces(8).toNumber();

        return taxRateTotal;
    }

    calculateSavings(invoice: Invoice): number {
        const fmvChangeDec: Decimal = new Decimal(invoice.fmvChange);
        return fmvChangeDec.times(invoice.taxRate).toDecimalPlaces(2).toNumber();
    }

    resetTotalSavings(invoice: Invoice): Invoice {
        invoice.savings = this._appealsSum(invoice.appeals, 'savings').toNumber();

        return invoice;
    }

    // "Initial Tax Rate" means what the tax rate would be if the fmvChange were not overridden
    // and everything was at defaults
    calculateInitialTaxRate(invoice: Invoice, totalSavings?: number): number {
        if (invoice.calculatedPreviousFMV == invoice.calculatedInvoiceFMV) {
            return 0;
        }
        totalSavings = totalSavings || this._appealsSum(invoice.appeals, 'savings').toNumber();

        const initialTaxRate: number = (new Decimal(totalSavings)).dividedBy(
            (new Decimal(invoice.calculatedPreviousFMV - invoice.calculatedInvoiceFMV)))
            .toDecimalPlaces(8).toNumber();
        return initialTaxRate;
    }

    calculateSavingsTaxRate(invoice: Invoice, totalSavings: number): Invoice {
        // It is the responsibility of this function to set the status, real value, and calculated value
        // for tax rate and savings; every if block must account for all six values

        if (invoice.fmvChangeStatus === OverrideStatus.Recalculated) {
            // initialTaxRate = totalSavings / (calculatedPreviousFMV - calculatedInvoiceFMV)
            const initialTaxRate: number = this.calculateInitialTaxRate(invoice, totalSavings);

            if (invoice.isSavingsOverridden) {
                invoice.savingsStatus = OverrideStatus.Overridden;
                invoice.taxRateStatus = OverrideStatus.Recalculated;
                // invoice.savings is already set
                invoice.taxRate = this.calculateTaxRate(invoice);
                invoice.calculatedSavings = (new Decimal(invoice.fmvChange).times(
                    new Decimal(initialTaxRate).toDecimalPlaces(8))).toNumber();
                invoice.calculatedTaxRate = invoice.taxRate;
            }
            else if (invoice.taxRate !== initialTaxRate) { // Tax rate is overridden
                invoice.savingsStatus = OverrideStatus.Recalculated;
                invoice.taxRateStatus = OverrideStatus.Overridden;
                invoice.savings = this.calculateSavings(invoice);
                // invoice.taxRate is already set
                invoice.calculatedSavings = invoice.savings;
                invoice.calculatedTaxRate = initialTaxRate;
            }
            else { // Neither is overridden
                invoice.savingsStatus = OverrideStatus.Recalculated;
                invoice.taxRateStatus = OverrideStatus.Default;
                invoice.taxRate = initialTaxRate;
                invoice.savings = this.calculateSavings(invoice);
                invoice.calculatedSavings = invoice.savings;
                invoice.calculatedTaxRate = invoice.taxRate;
            }
        }
        else {
            if (invoice.isSavingsOverridden) {
                invoice.savingsStatus = OverrideStatus.Overridden;
                invoice.taxRateStatus = OverrideStatus.Recalculated;
                // invoice.savings is already set
                invoice.taxRate = this.calculateTaxRate(invoice);
                invoice.calculatedSavings = totalSavings;
                invoice.calculatedTaxRate = invoice.taxRate;
            }
            else if (invoice.savings === totalSavings) { // Neither is overridden
                invoice.savingsStatus = OverrideStatus.Default;
                invoice.taxRateStatus = OverrideStatus.Default;
                // invoice.savings is already set
                invoice.taxRate = this.calculateTaxRate(invoice);
                invoice.calculatedSavings = totalSavings;
                invoice.calculatedTaxRate = invoice.taxRate;
            }
            else { // Tax rate is overridden
                invoice.savingsStatus = OverrideStatus.Recalculated;
                invoice.taxRateStatus = OverrideStatus.Overridden;
                invoice.savings = this.calculateSavings(invoice);
                // invoice.taxRate is already set
                invoice.calculatedSavings = invoice.savings;
                invoice.calculatedTaxRate = (new Decimal(totalSavings)).dividedBy(new Decimal(invoice.fmvChange))
                    .toDecimalPlaces(8).toNumber();
            }
        }

        return invoice;
    }

    getCompanyInvoiceDefaults(companyId: number): Promise<CreateAdHocInvoiceRequest> {
        return this.http.get(`/api/invoice/company/${  companyId  }/adhocdefaults`) as Promise<CreateAdHocInvoiceRequest>;
    }

    getSiteInvoiceDefaults(siteId: number): Promise<CreateAdHocInvoiceRequest> {
        return this.http.get(`/api/invoice/site/${  siteId  }/adhocdefaults`) as Promise<CreateAdHocInvoiceRequest>;
    }

    createAdHocInvoice(request: CreateAdHocInvoiceRequest): Promise<number> {
        return this.http.post('/api/invoice/adhoc', request) as Promise<number>;
    }

    getDisplayPercent(sourceNum: number): number {
        if(sourceNum) {
            const sourceDec = new Decimal(sourceNum);
            return sourceDec.times(100).toNumber();
        } else {
            return 0;
        }
    }

    getRealPercentFromDisplay(sourceNum: number): number {
        if(sourceNum) {
            const sourceDec = new Decimal(sourceNum);
            return sourceDec.dividedBy(100).toNumber();
        } else {
            return 0;
        }
    }

    generateNotes(invoice: Invoice): string{
        let tempNotes: string = '';
        const uncappedFeeAmount: number = invoice.feeAmountStatus === OverrideStatus.Overridden ?
            invoice.feeAmount : this.calculateFeeAmount(invoice, true);

        const litigationPostfix = +invoice.contractServiceType === ContractServiceType.Litigation
            ? ' - Litigation'
            : '';
        tempNotes += `${invoice.annualYear  } Property Tax Services${litigationPostfix}\n`;
        if (invoice.siteName) {
            tempNotes += `${invoice.siteName  }\n`;
        }
        if (invoice.siteAddress) {
            if (invoice.siteAddress.address1){
                tempNotes += `${invoice.siteAddress.address1  }\n`;
            }
            if (invoice.siteAddress.address2){
                tempNotes += `${invoice.siteAddress.address2  }\n`;
            }
            if (invoice.siteAddress.city){
                tempNotes += invoice.siteAddress.city;
                if (invoice.siteState || invoice.siteAddress.zip)
                {
                    tempNotes += ', ';
                }
                else
                {
                    tempNotes += '\n';
                }
            }
            if (invoice.siteState.abbr){
                tempNotes += invoice.siteState.abbr;
                if (invoice.siteAddress.zip)
                {
                    tempNotes += ' ';
                }
                else
                {
                    tempNotes += '\n';
                }
            }
            if (invoice.siteAddress.zip){
                tempNotes += `${invoice.siteAddress.zip  }\n`;
            }
        }


        tempNotes += '\n';

        if (!invoice.isAdHoc) {
            if (invoice.includeOtherParcels) {
                tempNotes += `Site Previous FMV: ${  this.numberPipe.transform(invoice.previousFMV, '1.0-0')  }\n`;
                tempNotes += `Site Billing FMV: ${  this.numberPipe.transform(invoice.invoiceFMV, '1.0-0')  }\n`;

            } else {
                tempNotes += `Parcel(s) Previous FMV: ${  this.numberPipe.transform(invoice.previousFMV, '1.0-0')  }\n`;
                tempNotes += `Parcel(s) Billing FMV: ${  this.numberPipe.transform(invoice.invoiceFMV, '1.0-0')  }\n`;
            }
        }

        if (invoice.displayTaxRate) {
            tempNotes += `Billable Rate: ${invoice.displayTaxRate}%\n`;
        }
        if (!invoice.isAdHoc) {
            tempNotes += `Tax Savings: ${  this.currencyPipe.transform(invoice.savings, 'USD', 'symbol')  }\n`;
        }
        if (invoice.feeAmount) {
            tempNotes += `Contingency: ${  invoice.displayContingencyPct  }%\n`;
            tempNotes += `${this._showCapOnFees(invoice, uncappedFeeAmount) ? 'Uncapped ' : ''  }Contingency Fee Amount: ${  this.currencyPipe.transform(uncappedFeeAmount, 'USD', 'symbol')  }\n`;
            tempNotes += this._showCapOnFees(invoice, uncappedFeeAmount) ? `Cap on Fees: ${  this.currencyPipe.transform(invoice.contingencyCap, 'USD', 'symbol')  }\n` : '';
        }
        if (invoice.fixedFee) {
            const contractServiceType = Object
                .keys(this.constants.ContractServiceTypes)
                .map(key => this.constants.ContractServiceTypes[key])
                .filter(t => t.id == invoice.contractServiceType)[0];

            const fixedFeeLabel = `${contractServiceType.fullDisplayName || contractServiceType.name  } Fixed Fee: `;

            tempNotes += `${fixedFeeLabel + this.currencyPipe.transform(invoice.fixedFee, 'USD', 'symbol')  }\n`;
        }
        tempNotes += invoice.expenseAmount && invoice.expenseAmount != 0 ? `Expenses: ${  this.currencyPipe.transform(invoice.expenseAmount, 'USD', 'symbol')  }\n` : '';

        if(uncappedFeeAmount != invoice.invoiceAmount) {
            tempNotes += `Invoice Amount: ${  this.currencyPipe.transform(invoice.invoiceAmount, 'USD', 'symbol')  }\n`;
        }

        tempNotes += `\nInternal ID: ${  invoice.invoiceId.toString()}`;

        return tempNotes;
    }

    calculateFeeAmount(invoice: Invoice, excludeCap?: boolean): number {
        if (!invoice.contingencyPct) {
            return 0;
        }
        const totalSavingsDec: Decimal = new Decimal(invoice.savings);
        let calcFee: number = totalSavingsDec.times(invoice.contingencyPct).toNumber();

        if(!excludeCap && (invoice.contingencyCap !== undefined && invoice.contingencyCap !== null) && calcFee > invoice.contingencyCap) {
            calcFee = invoice.contingencyCap;
        }

        return new Decimal(calcFee).toDecimalPlaces(2).toNumber();
    }

    private _showCapOnFees(invoice: Invoice, uncappedFeeAmount: number): boolean {
        return invoice.contingencyCap && invoice.contingencyCap < uncappedFeeAmount;
    }


    private calculateFMVFields(invoice: Invoice): Invoice {
        let previousFmvTotal: Decimal = this._appealsSum(invoice.appeals, 'previousFMV');
        let invoiceFmvTotal: Decimal = this._appealsSum(invoice.appeals, 'invoiceFMV');

        if(invoice.includeOtherParcels) {
            previousFmvTotal = previousFmvTotal.plus(invoice.otherParcelFMV);
            invoiceFmvTotal = invoiceFmvTotal.plus(invoice.otherParcelFMV);
        }

        //set calculated FMV fields
        invoice.calculatedPreviousFMV = previousFmvTotal.toNumber();
        invoice.calculatedInvoiceFMV = invoiceFmvTotal.toNumber();

        invoice.fmvChange = new Decimal(invoice.previousFMV).minus(invoice.invoiceFMV).toNumber();

        return invoice;
    }

    private setInvoiceStatus(invoice: Invoice) {

        //set fee amount status
        if (invoice.fmvChangeStatus === OverrideStatus.Recalculated ||
            invoice.taxRateStatus === OverrideStatus.Overridden ||
            invoice.savingsStatus === OverrideStatus.Overridden ||
            invoice.contingencyCapStatus === OverrideStatus.Overridden ||
            invoice.contingencyPctStatus === OverrideStatus.Overridden
        ) {
            if (invoice.feeAmount === invoice.calculatedFeeAmount) {
                invoice.feeAmountStatus = OverrideStatus.Recalculated;
            }
        } else if(invoice.fmvChangeStatus === OverrideStatus.Default &&
                invoice.invoiceFMVStatus === OverrideStatus.Default &&
                invoice.taxRateStatus === OverrideStatus.Default &&
                invoice.savingsStatus === OverrideStatus.Default &&
                invoice.contingencyCapStatus === OverrideStatus.Default &&
                invoice.contingencyPctStatus === OverrideStatus.Default &&
                invoice.feeAmountStatus !== OverrideStatus.Overridden) {

            //Reset default status
            invoice.feeAmountStatus = OverrideStatus.Default;
        } else {
            if (invoice.feeAmount === invoice.calculatedFeeAmount) {
                invoice.feeAmountStatus = OverrideStatus.Default;
            }
        }

        if (invoice.fmvChangeStatus === OverrideStatus.Recalculated ||
            invoice.taxRateStatus === OverrideStatus.Overridden ||
            invoice.savingsStatus === OverrideStatus.Overridden ||
            invoice.contingencyCapStatus === OverrideStatus.Overridden ||
            invoice.contingencyPctStatus === OverrideStatus.Overridden ||
            invoice.feeAmountStatus === OverrideStatus.Overridden ||
            invoice.fixedFeeStatus === OverrideStatus.Overridden
        ) {
            invoice.invoiceAmountStatus = OverrideStatus.Recalculated;
        } else {
            invoice.invoiceAmountStatus = OverrideStatus.Default;
        }


    }

    private setDisplayPercentages(invoice: Invoice) {
        invoice.displayContingencyPct = this.getDisplayPercent(invoice.contingencyPct);
        invoice.displayTaxRate = this.getDisplayPercent(invoice.taxRate);
        invoice.invoiceRevenueShareCodes = _.map(invoice.invoiceRevenueShareCodes, x => {
            x.displayAllocationPct = this.getDisplayPercent(x.allocationPct);
            x.feeAmount = x.invoiceAmount = 0;
            return x;
        });
    }

    private _appealsSum(appeals: InvoiceAppeal[], property): Decimal {
        return _.reduce(appeals, (result, appeal) => {
            return result.plus(appeal[property]);
        }, new Decimal(0));
    }
}
