import { BehaviorSubject, lastValueFrom, Observable, Subject, Subscription } from 'rxjs';
import { WebsocketListenerService } from '../../../websocketListener.service';
import Timeout = NodeJS.Timeout;
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { Injectable, OnDestroy } from '@angular/core';
import { EntityImportRepository } from '../../../Repositories';

export interface ActiveImportInfo {
    longRunningProcessId: number;
    selectedRowsCount: number;
}

@Injectable()
export class AssetImportProgressService implements OnDestroy{

    constructor(
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _entityImportRepository: EntityImportRepository
    ) {
        console.log('AssetImportProgressService created');
    }
    private _companyId: number;
    private _assetImportInProgressMetadata: Compliance.AssetImportInProgressMetadataModel;
    private _progressIntervalId: Timeout;
    private _timeRemainingIntervalId: Timeout;
    private _importStartedSubject: BehaviorSubject<ActiveImportInfo> = new BehaviorSubject<ActiveImportInfo>(null);
    private _importFinishedSubject: Subject<number> = new Subject();
    private _selectedRowsCountLoadedSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _processedRowsSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private _timeRemainingSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _reconnectedSub: Subscription;
    private _statusChangedSub: Subscription;
    private _progressChangedSub: Subscription;
    private _selectedRowsCount: number = 0;
    private _processedRowsCount: number = 0;
    private _loadingAssetImportInProgressMetadata: boolean = false;

    get importStarted$(): Observable<ActiveImportInfo> {
        return this._importStartedSubject.asObservable();
    }

    get importFinished$(): Observable<number> {
        return this._importFinishedSubject.asObservable();
    }

    get processedRows$(): Observable<number> {
        return this._processedRowsSubject.asObservable();
    }

    get timeRemaining$(): Observable<string> {
        return this._timeRemainingSubject.asObservable();
    }

    get selectedRowsCountLoaded$(): Observable<number> {
        return this._selectedRowsCountLoadedSubject.asObservable();
    }

    ngOnDestroy(): void {
        console.log('AssetImportProgressService destroyed');
        this._reconnectedSub && this._reconnectedSub.unsubscribe();
        this._statusChangedSub && this._statusChangedSub.unsubscribe();
        this._progressChangedSub && this._progressChangedSub.unsubscribe();
    }

    async initialize(companyId: number): Promise<void> {
        this._companyId = companyId;

        await this._loadAssetImportInProgress();

        this._reconnectedSub = this._websocketListenerService.longRunningProcessReconnected$
            .subscribe(async () => await this._loadAssetImportInProgress());

        this._statusChangedSub = this._websocketListenerService.longRunningProcessStatusChange$
            .subscribe(async (x) => {
                if (x.processType === LongRunningProcessTypeEnum.ImportFile && x.operationName === 'Transfer') {
                    console.log('LRP (OnStatusChanged)', x);

                    if (this._assetImportInProgressMetadata && this._assetImportInProgressMetadata.longRunningProcessId === x.longRunningProcessId) {
                        if (x.isCompleted || x.isCanceled || x.isError) {
                            this._importFinishedSubject.next(this._assetImportInProgressMetadata.longRunningProcessId);

                            this._processedRowsSubject.next(0);
                            this._timeRemainingSubject.next('');
                            this._selectedRowsCountLoadedSubject.next(0);
                            this._importStartedSubject.next(null);

                            this._assetImportInProgressMetadata = null;
                            this._processedRowsCount = 0;
                            this._selectedRowsCount = 0;

                            if (this._progressIntervalId) {
                                clearInterval(this._progressIntervalId);
                                this._progressIntervalId = null;
                            }

                            if (this._timeRemainingIntervalId) {
                                clearInterval(this._timeRemainingIntervalId);
                                this._timeRemainingIntervalId = null;
                            }

                            console.log('Removing LRP', x.longRunningProcessId);
                        }
                    } else {
                        if (x.isCompleted || x.isCanceled || x.isError || this._loadingAssetImportInProgressMetadata) {
                            return;
                        }

                        this._loadingAssetImportInProgressMetadata = true;
                        try {
                            const assetImportInProgressMetadata = await lastValueFrom(this._entityImportRepository.getAssetImportInProgressMetadataByLongRunningProcessId(x.longRunningProcessId));

                            if (assetImportInProgressMetadata && assetImportInProgressMetadata.companyId === this._companyId && !this._assetImportInProgressMetadata) {
                                this._assetImportInProgressMetadata = assetImportInProgressMetadata;
                                this._initializeAssetImportProgress();

                                console.log('Adding LRP (OnStatusChanged)', this._assetImportInProgressMetadata.longRunningProcessId);
                            }
                        } finally {
                            this._loadingAssetImportInProgressMetadata = false;
                        }
                    }
                }
            });

        this._progressChangedSub = this._websocketListenerService.longRunningProcessProgressChange$
            .subscribe(async (progress) => {
                if (progress.processType === LongRunningProcessTypeEnum.ImportFile && progress.operationName === 'Transfer') {
                    if (!this._assetImportInProgressMetadata || this._assetImportInProgressMetadata.longRunningProcessId !== progress.longRunningProcessId) {

                        if (!this._loadingAssetImportInProgressMetadata) {
                            this._loadingAssetImportInProgressMetadata = true;

                            try {
                                const assetImportInProgressMetadata = await lastValueFrom(this._entityImportRepository.getAssetImportInProgressMetadataByLongRunningProcessId(progress.longRunningProcessId));

                                if (assetImportInProgressMetadata && assetImportInProgressMetadata.companyId === this._companyId && !this._assetImportInProgressMetadata) {
                                    this._assetImportInProgressMetadata = assetImportInProgressMetadata;
                                    this._initializeAssetImportProgress();

                                    console.log('Adding LRP (OnProgressChanged)', this._assetImportInProgressMetadata.longRunningProcessId);
                                }
                            } finally {
                                this._loadingAssetImportInProgressMetadata = false;
                            }
                        }
                    } else if (this._assetImportInProgressMetadata.longRunningProcessId === progress.longRunningProcessId &&
                        !this._selectedRowsCount) {
                        this._selectedRowsCount = progress.total;

                        this._selectedRowsCountLoadedSubject.next(this._selectedRowsCount);
                        console.log('Setting initial value for importRowsSelected', this._selectedRowsCount);
                    }
                }
            });
    }

    private async _loadAssetImportInProgress(): Promise<void> {
        if (this._loadingAssetImportInProgressMetadata) {
            return;
        }

        this._loadingAssetImportInProgressMetadata = true;

        try {
            this._assetImportInProgressMetadata = await lastValueFrom(this._entityImportRepository.getAssetImportInProgressMetadataByCompanyId(this._companyId));

            console.log('this._assetImportInProgressMetadata', this._assetImportInProgressMetadata);

            if (this._assetImportInProgressMetadata) {
                this._initializeAssetImportProgress();

                console.log('Adding LRP (LoadAssetImportInProgress)', this._assetImportInProgressMetadata.longRunningProcessId);
            }
        } finally {
            this._loadingAssetImportInProgressMetadata = false;
        }
    }

    private _initializeAssetImportProgress() {
        this._processedRowsCount = this._assetImportInProgressMetadata.transferredRowsCount;
        this._selectedRowsCount = this._assetImportInProgressMetadata.selectedRowsCount;

        const activeImportInfo: ActiveImportInfo = {
            longRunningProcessId: this._assetImportInProgressMetadata.longRunningProcessId,
            selectedRowsCount: this._selectedRowsCount
        };
        this._importStartedSubject.next(activeImportInfo);

        this._progressIntervalId = setInterval(() => {
            if (this._assetImportInProgressMetadata && this._processedRowsCount < this._selectedRowsCount) {
                this._processedRowsCount++;
                this._processedRowsSubject.next(this._processedRowsCount);
            }
        }, this._assetImportInProgressMetadata.millisecondsPerRow);

        this._timeRemainingIntervalId = setInterval(() => {
            let result = '';

            if (this._assetImportInProgressMetadata) {
                const rowsToProcess = this._selectedRowsCount - this._processedRowsCount;
                const timeToProcess = rowsToProcess * this._assetImportInProgressMetadata.millisecondsPerRow;

                let seconds = Math.round(timeToProcess / 1000);
                const hours = Math.floor(seconds / 3600);
                seconds = seconds % 3600;
                const minutes = Math.floor(seconds / 60);
                seconds = seconds % 60;

                result = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                if (hours) {
                    result = `${hours.toString().padStart(2, '0')}:${result}`;
                }
            }

            this._timeRemainingSubject.next(result);
        }, 1000);
    }
}
