import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../Account/userSettings.service';

@Injectable(
    { providedIn: 'root' }
)
export class RentRollRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) { super(httpClient); }

    getRentRollList(companyId: number, searchParams: Core.RentRollLineItemSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Core.RentRollLineItemSearchModel, Core.RentRollLineItemSearchTotalModel>> {
        return this.httpPost(`/api/rentRollLineItem/company/${companyId}/rows`, searchParams);
    }

    getRentRollEndDates(companyId: number, siteId?: number): Observable<Date[]> {
        let url = `/api/rentRollLineItem/company/${companyId}/endDates`;

        if(siteId) {
            url += `?siteId=${siteId}`;
        }

        return this.httpGet(url);
    }

    startRentRollExport(companyId: number, exportModel: Compliance.RentRollLineItemExportModel): Observable<number> {
        return this.httpPost(`/api/rentRollLineItem/company/${companyId}/export`, exportModel);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/rentRollLineItem/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    getRentRollRowIds(companyId: number, searchParams: Core.RentRollLineItemSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/rentRollLineItem/company/${companyId}/rowids`, searchParams);
    }

    deleteItem(rentRollLineItemId: number): Observable<void> {
        return this.httpDelete(`api/rentRollLineItem/${rentRollLineItemId}`);
    }

    startBulkDelete(companyId: number, model: Compliance.RentRollLineItemBulkDeleteModel): Observable<number> {
        return this.httpPut(`/api/rentRollLineItem/company/${companyId}/startBulkDelete/`, model);
    }

    updateIsValidated(model: Core.RentRollLineItemIsValidatedUpdateModel): Observable<Core.RentRollLineItemIsValidatedUpdateResultModel> {
        return this.httpPut(`/api/rentRollLineItem/updateIsValidated`, model);
    }

    bulkUpdate(companyId: number, model: Core.RentRollLineItemBulkUpdateModel): Observable<boolean> {
        return this.httpPut(`/api/rentRollLineItem/company/${companyId}/bulkUpdate`, model);
    }
}
