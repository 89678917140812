<div class="container single-column-layout dashboard">
    <div class="dashboard-header">
        <h1 *ngIf="userInfo">Welcome, {{userInfo.firstName}}</h1>
    </div>
    <div class="row" style="position:absolute; right: 30px; top:260px;" *ngIf="hasInstancePrivatePermission || userInfo.isTeamMemberAndAssignable">
        <label style="float: right;">
            Default View:
            <select class="form-select form-select-sm" [(ngModel)]="defaultView" (change)="defaultViewChange()">
                <option value="1">My Tasks</option>
                <option value="2" *ngIf="hasInstancePrivatePermission">My Team's Tasks</option>
                <option value="0">None</option>
            </select>
        </label>
    </div>

    <!-- for spacing purposes -->
    <div class="row" style="height: 230px;"></div>

    <div class="row" *ngIf="hasInstancePrivatePermission || userInfo.isTeamMemberAndAssignable">
        <overview-single-table *ngIf="showActionView" [viewMode]="defaultView"></overview-single-table>
    </div>
    <!-- "Old" dashboard/landing content -->
    <div class="row" style="margin-top: 34px;">
        <div class="dashboard-content-section">
            <i class="fa fa-search fa-5x fa-fw section-icon" (click)="openQuickSearch(true)"></i>
            <div class="content-section">
                <h2>Quick Search</h2>
                <p>Navigate to a Company, Site, or Parcel. Access Quick Search by clicking the magnifying glass icon in the upper right-hand corner, or press alt-q.</p>
            </div>
        </div>
    </div>
    <div class="row" style="margin-top: 34px;">
        <div class="dashboard-content-section">
            <!-- ng-show="vm.hasStatesViewPermission">-->
            <i class="fa fa-globe fa-5x fa-fw section-icon" (click)="goToStatePage()"></i>
            <div class="content-section">
                <h2>States</h2>
                <p>Here, you will find a list of states to which you can navigate. From there, access assessment classes, collectors, and assessors.</p>
            </div>
        </div>
    </div>

    <!-- "New" dashboard link with clean landing page -->
    <div class="row text-center" style="margin-top: 50px;">
        <div>
            <button class="ace-btn btn-primary" (click)="goToDashboard()">Go to Dashboard</button>
        </div>
    </div>
</div>
