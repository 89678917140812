import { Injectable } from '@angular/core';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { EntityTypeIds } from '../constants.new';

const url = '/api/role/';

@Injectable(
    { providedIn: 'root' }
)
export class ContactRoleService {
    constructor(private http: SDHttpService) { }

    private _roles: Weissman.Model.Contacts.ContactRole[];

    async get(): Promise<Weissman.Model.Contacts.ContactRole[]> {
        const result = await this.http.get(url);
        this._roles = result;
        return result;
    }

    update(role: Weissman.Model.Contacts.ContactRole): Promise<Weissman.Model.Contacts.ContactRole> {
        return this.http.put(url, role);
    }

    async create(role: Weissman.Model.Contacts.ContactRole): Promise<Weissman.Model.Contacts.ContactRole> {
        const result = await this.http.post(url, role);
        this._roles.push(result);

        return result;
    }

    delete(id: number): Promise<void> {
        return this.http.delete(`${url}${id}`);
    }

    getAvailableRolesForContactEntityCombinationAPI(contactID: number, entityInfo): Promise<any[]> {
        const entityTypeID = EntityTypeIds[entityInfo.type.toUpperCase()];

        return this.http.get(url, {
            params: {
                contactID,
                entityTypeID,
                entityId: entityInfo.id
            }
        });
    }

    getCached(): Weissman.Model.Contacts.ContactRole[] {
        return this._roles;
    }
}
