<div class="contacts-panel-body-actions">
    <button class="ace-btn btn-sm btn-primary"
            *ngIf="newAllowed && hasNewContactPermission && !isConsultantUser"
            (click)="newContact()">
        New Contact
    </button>
    <button class="ace-btn btn-sm btn-primary"
            *ngIf="hasAssociateExistingPermission"
            (click)="associateExisting()">
        Associate Existing
    </button>
</div>
