import { Component, Input } from '@angular/core';
import { ProductAnalyticsService } from '../../Common/Amplitude/productAnalytics.service';
import { NavigationState } from '../annual-details-navigation.service';
import { AnnualDetailsService } from '../annual-details.service';
import { AnnualDetailAssessment, AnnualDetailComponent, AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { AppealService } from '../Appeals/appeal.service';
import { FilingType, FilingTypeSummary } from '../Compliance/compliance.model';
import { BillClusterService } from '../Taxes/bill-cluster.service';

declare const _: any;

@Component({
    selector: 'annual-details-grid',
    templateUrl: './annual-details-grid.component.html',
    styleUrls: ['./annual-details-grid.component.scss'],
    providers: [AppealService, BillClusterService]
})

export class AnnualDetailsGridComponent {
    constructor(
        public readonly annualDetailsService: AnnualDetailsService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    )
    { }

    @Input() years: AnnualDetailYear[];
    @Input() components: AnnualDetailComponent[];
    @Input() componentTypes: any;
    @Input() filings: FilingTypeSummary[];
    @Input() filingTypes: FilingType[];
    @Input() parcelState: number;
    @Input() navigationState: NavigationState;
    @Input() hasComplianceResponsibility: boolean;
    @Input() budgetsEnabled: boolean;
    @Input() consolidatingTypeId: Core.ConsolidatingTypeEnum;
    //  @Input() appealsClick() :void;
    state: number;

    /* CLICK FUNCTIONS */
    billClicked(year: AnnualDetailYear): void {
        if (this.consolidatingTypeId == Core.ConsolidatingTypeEnum.Consolidated) return;
        this._productAnalyticsService.logEvent('click-annual-details-taxes');
        this.navigationState.goToTaxes(year);
    }

    revisionClicked(year: AnnualDetailYear, revision: AnnualDetailAssessment): void {
        if(this.consolidatingTypeId == Core.ConsolidatingTypeEnum.Consolidated) return;
        this._productAnalyticsService.logEvent('click-annual-details-asmts');
        this.navigationState.goToAssessment(year, revision);
    }

    appealClicked(year: AnnualDetailYear, revision?: AnnualDetailAssessment): void {
        if (this.consolidatingTypeId == Core.ConsolidatingTypeEnum.Consolidated) return;
        this._productAnalyticsService.logEvent('click-annual-details-appeals');
        this.navigationState.goToAppeals(year, revision);
    }

    complianceClicked(year: AnnualDetailYear): void {
        this._productAnalyticsService.logEvent('click-annual-details-comp');
        this.navigationState.goToCompliance(year);
    }

    budgetClicked(year: AnnualDetailYear, annualBudgetId: number): void {
        if (this.consolidatingTypeId == Core.ConsolidatingTypeEnum.Consolidated) return;
        this._productAnalyticsService.logEvent('click-annual-details-budget');
        this.navigationState.goToBudget(year, annualBudgetId);
    }

    /* STYLE FUNCTIONS */
    clickableTaxClass(year): string {
        let str = '';

        if (year.expanded) {
            str += 'expanded';
        }

        if (year.taxIsEstimated) {
            str += ' not-actual';
        }

        return str;
    }

    isNumber(value): boolean {
        return typeof value === 'number';
    }

    isFromConsolidated(year: AnnualDetailYear, filingType: FilingTypeSummary): boolean {
        return !!year.complianceFilingTypeSummaries.find(summary => summary.isFromConsolidatedParcel)
            && (filingType && filingType.filingTypeId === 1);
    }

    get notAllowedIfConsolidated() {
        return this.isConsolidatedParcel ? 'not-allowed' : 'pointer';
    }

    get isConsolidatedParcel(): boolean {
        return this.consolidatingTypeId == Core.ConsolidatingTypeEnum.Consolidated;
    }
}
