import { Component, ElementRef, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { AssetRepository, EntityImportRepository, ImportSpecificationRepository } from '../../Repositories';
import { AuthenticationService } from '../../../Account/authentication-service.upgrade';
import * as _ from 'lodash';
import { AssetLienDate } from '../../Asset/Asset-Lien-Date/assetLienDate.component';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { EntityImportService } from '../entityImport.service';
import { WeissmanDatetimeComponent } from '../../../UI-Lib/Weiss-Datepicker/weissman-datetime.component';
import { FeatureFlagsService } from '../../../Common/FeatureFlags/feature-flags-service';

export interface EntityImportUploadParams {
    companyId?: number;
    allocationId?: number;
}

@Component({
    selector: 'entity-import-upload',
    templateUrl: './upload.component.html'
})
export class EntityImportUploadComponent implements OnInit, IWeissmanModalComponent<EntityImportUploadParams, Compliance.ImportFileModel> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _entityImportRepository: EntityImportRepository,
        private readonly _entityImportSpecificationRepository: ImportSpecificationRepository,
        private readonly _ngZone: NgZone,
        private readonly _authService: AuthenticationService,
        private readonly _assetRepository: AssetRepository,
        private readonly _restrictService: RestrictService,
        private readonly _featureFlagService: FeatureFlagsService,
        private readonly _entityImportService: EntityImportService) { }

    // the import display name is internally managed unless the user changes it
    private _importDisplayNameChangedByUser: boolean = false;

    private _userInfo: any;

    params: EntityImportUploadParams;
    result: Compliance.ImportFileModel;

    // busy indicator message manager
    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    // content types dropdown
    contentTypes: Compliance.ImportContentTypeModel[] = [];
    selectedContentType: Compliance.ImportContentTypeModel = null;

    // file types dropdown
    fileTypes: Compliance.ImportFileTypeModel[] = [];
    selectedFileType: Compliance.ImportFileTypeModel = null;

    // file specifications dropdown with the default file specification
    fileSpecifications: Compliance.ImportFileSpecificationModel[] = [];
    selectedFileSpecification: Compliance.ImportFileSpecificationModel = this.fileSpecifications[0];

    // file delimiters dropdown
    otherFileDelimiter = { name: 'Other', value: '' };
    fileDelimiters: Compliance.NameValuePair<string>[] = [
        { name: 'Tab', value: '\t' },
        { name: 'Comma', value: ',' },
        this.otherFileDelimiter
    ];
    selectedFileDelimiter: Compliance.NameValuePair<string> = null;

    file: File;
    headerRowIndex: number = 1;
    useFileSpecification: string = 'no';
    viewType: string = '';
    importDisplayName: string;
    attachmentFile: File;
    assetEffectiveDate: Date = new Date();
    visibleFileFields: Compliance.ImportFileFieldModel[] = [];
    fileFields: Compliance.ImportFileFieldModel[] = [];
    fileFieldsSelectedValues: Record<number, any> = {};

    @ViewChild('lienDate', { static: true }) lienDateTemplate: ElementRef;
    @ViewChild('select', { static: true }) selectTemplate: ElementRef;
    @ViewChild('date', { static: true }) dateTemplate: ElementRef;

    private _templateMap: Map<string, ElementRef>;

    async ngOnInit(): Promise<void> {
        this.busyMessageManager.add('Loading', 'init');

        try {
            const data = await Promise.all([
                lastValueFrom(this._entityImportRepository.getFileTypes()),
                lastValueFrom(this._entityImportRepository.getContentTypes(this.params.companyId))
            ]);

            this.fileTypes = data[0];
            this.contentTypes = this._filterContentTypes(data[1]);

            this.selectedFileType = this.fileTypes[0];
            this.selectedFileDelimiter = this.fileDelimiters[0];

            this._userInfo = await this._authService.getUserInfo();

            if (this.params.allocationId) {
                this.selectedContentType = _.find(this.contentTypes, i => i.importContentTypeId === Compliance.ImportContentTypeIdEnum.AllocationWorkPapers);
                this.selectedContentTypeChanged();
            }
        } finally {
            this.busyMessageManager.remove('init');
        }

        this._templateMap = new Map([
            ['lienDate', this.lienDateTemplate],
            ['select', this.selectTemplate],
            ['date', this.dateTemplate]
        ]);
    }

    private _filterContentTypes(contentTypes: Compliance.ImportContentTypeModel[]): Compliance.ImportContentTypeModel[] {
        let allowedContentTypes = _.reject(contentTypes, ct => {
            return !this._restrictService.isInRole(Roles.TAXRATESETUP)
                    && (
                        ct.importContentTypeId == Compliance.ImportContentTypeIdEnum.TaxAuthority
                        || ct.importContentTypeId == Compliance.ImportContentTypeIdEnum.TaxRateAreaAssessor
                        || ct.importContentTypeId == Compliance.ImportContentTypeIdEnum.TaxRateAreaCollector
                    );
        });

        if (!this.params.allocationId){
            allowedContentTypes = _.filter(allowedContentTypes, i => i.importContentTypeId !== Compliance.ImportContentTypeIdEnum.AllocationWorkPapers);
        }

        return allowedContentTypes;
    }

    async getSpecifications(): Promise<void> {
        let specPromise: Promise<Compliance.ImportFileSpecificationModel[]>;
        switch (this.viewType) {
            case 'private':
                specPromise = lastValueFrom(this._entityImportSpecificationRepository.getListByUser());
                break;
            case 'company':
                specPromise = lastValueFrom(this._entityImportSpecificationRepository.getListByCompany(this.params.companyId));
                break;
            case 'shared':
                specPromise = lastValueFrom(this._entityImportSpecificationRepository.getListShared());
                break;
            default:
                return;
        }

        this.busyMessageManager.add('Loading Specifications', 'loading-specs');

        try {
            const specifications = await specPromise;

            if (this.params.allocationId) {
                this.fileSpecifications = _.filter(specifications, i => i.importContentId === Compliance.ImportContentTypeIdEnum.AllocationWorkPapers);
            } else{
                this.fileSpecifications = _.filter(specifications, i => i.importContentId !== Compliance.ImportContentTypeIdEnum.AllocationWorkPapers);
            }

            this.selectedFileSpecification = null;
        } finally {
            this.busyMessageManager.remove('loading-specs');
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    filePicked(files: File[]): void {
        this._ngZone.run(()=>{
            this.file = files[0];

            let index = this.file.name.lastIndexOf('.');
            if (index !== 0){
                const fileExtension = this.file.name.substring(index + 1).toLowerCase();
                if (fileExtension === 'xlsx' || fileExtension === 'xls'){
                    this.selectedFileType = this.fileTypes.find(i => i.importFileTypeId === +Compliance.ImportFileTypeEnum.Excel);
                } else{
                    this.selectedFileType = this.fileTypes.find(i => i.importFileTypeId === +Compliance.ImportFileTypeEnum.DelimitedTextFile);
                }
            }

            if (!this._importDisplayNameChangedByUser) {
                this.importDisplayName = this._generateImportDisplayName();
            }
        });
    }

    attachmentFilePicked(files: File[]): void {
        this._ngZone.run(()=>{
            this.attachmentFile = files[0];
        });
    }

    isFileTypeDelimited(importFileType: Compliance.ImportFileTypeModel) {
        return importFileType && importFileType.importFileTypeId === Compliance.ImportFileTypeEnum.DelimitedTextFile;
    }

    importDisplayNameChanged($event: string): void {
        this._importDisplayNameChangedByUser = true;
        this.importDisplayName = $event;
    }

    selectedContentTypeChanged(): void {
        if (!this._importDisplayNameChangedByUser) {
            this.importDisplayName = this._generateImportDisplayName();
        }

        this.fileFieldsSelectedValues = {};

        this._filterFileFields();
    }

    useFileSpecificationChanged(): void {
        if (this.useFileSpecification === 'no') {
            this.selectedFileSpecification = null;
        }
    }

    specChosen(): void {
        if (this.selectedFileSpecification) {
            this.headerRowIndex = this.selectedFileSpecification.headerRowIndex;

            this.selectedFileType = this.fileTypes.find(ft => ft.importFileTypeId === this.selectedFileSpecification.importFileTypeId);

            this.selectedContentType = this.contentTypes.find(ct => ct.importContentTypeId === this.selectedFileSpecification.importContentId);
            this.selectedContentTypeChanged();

            this.selectedFileDelimiter = this.fileDelimiters.find(fd => fd.value === this.selectedFileSpecification.delimiter);
            if (!this.selectedFileDelimiter) {
                this.selectedFileDelimiter = this.otherFileDelimiter;
                this.otherFileDelimiter.value = this.selectedFileDelimiter.value;
            }
        }
    }

    //Do not remove this function. It is used in the #lienDate template
    onEffectiveDateChange(effectiveDate: AssetLienDate, field: Compliance.ImportFileFieldModel): void {
        this.assetEffectiveDate = effectiveDate.date;
        this.importDisplayName = this._generateImportDisplayName();
        this.fileFieldsSelectedValues[field.importFieldId] = effectiveDate.date.toISOString();
    }

    disableSpecDerivedFields(): boolean {
        return !!(this.useFileSpecification === 'yes' && this.selectedFileSpecification);
    }

    async uploadFile(): Promise<void> {
        const customFields: any = {};

        this.fileFields.forEach(x => customFields[`${x.importFieldId}`] = this.fileFieldsSelectedValues[x.importFieldId]);

        const fileMetadata = {
            CompanyId: this.params.companyId,
            FileName: this.file.name,
            ImportContentTypeId: this.selectedContentType.importContentTypeId,
            ImportFileTypeId: this.selectedFileType.importFileTypeId,
            Delimiter: this.selectedFileType.importFileTypeId === +Compliance.ImportFileTypeEnum.Excel ? null : this.selectedFileDelimiter.value,
            HeaderRowIndex: this.headerRowIndex,
            ImportFileSpecificationId: this.selectedFileSpecification && this.selectedFileSpecification.importFileSpecificationId,
            DisplayName: this.importDisplayName,
            ContentTypeCustomFields: customFields,
            AttachmentFileName: this.attachmentFile ? this.attachmentFile.name : null
        };

        this.busyMessageManager.add('Uploading File', 'uploading');

        try {
            const importFileModel = await lastValueFrom(this._entityImportRepository.upload(fileMetadata, this.file, this.attachmentFile));

            if (importFileModel) {
                this.result = importFileModel;
                this._bsModalRef.hide();
            }

        } finally {
            this.busyMessageManager.remove('uploading');
        }
    }

    get hasEffectiveDate(): boolean {
        return this.selectedContentType && (
            this.selectedContentType.importContentTypeId === Compliance.ImportContentTypeIdEnum.Assets ||
            this.selectedContentType.importContentTypeId === Compliance.ImportContentTypeIdEnum.AssetUpdatesOnly ||
            this.selectedContentType.importContentTypeId === Compliance.ImportContentTypeIdEnum.AssetsCreateSplits ||
            this.selectedContentType.importContentTypeId === Compliance.ImportContentTypeIdEnum.AssetsUpdateClassification ||
            this.selectedContentType.importContentTypeId === Compliance.ImportContentTypeIdEnum.AssetsUpdateAssetNumber);
    }

    get isReadyForUpload(): boolean {
        let allFileFieldsHaveValue: boolean = this.fileFields.every(x => !!this.fileFieldsSelectedValues[x.importFieldId]);

        return !!(
            this.selectedContentType && this.selectedContentType.importContentTypeId &&
            (this.selectedContentType.supportAttachments && this.selectedContentType.isAttachmentsFileRequired ? this.file && this.attachmentFile : this.file) &&
            this.selectedFileType &&
            (this.selectedFileType.importFileTypeId === Compliance.ImportFileTypeEnum.Excel || this.selectedFileDelimiter && this.selectedFileDelimiter.value) &&
            (this.useFileSpecification === 'no' || this.selectedFileSpecification) &&
            this.importDisplayName && allFileFieldsHaveValue
        );
    }

    get showImportContentTypeSelections(): boolean {
        return !this.params.allocationId;
    }

    get classRef(): EntityImportUploadComponent {
        return this;
    }

    getTemplate(fileField: Compliance.ImportFileFieldModel): TemplateRef<any> {
        return this._templateMap.get(fileField.renderer) as unknown as TemplateRef<any>
    }

    trackByItem(i, item: Compliance.ImportFileFieldModel): Compliance.ImportFileFieldModel {
        return item;
    }

    selectedOptionChanged(): void {
        this._filterFileFields();

        if (!this._importDisplayNameChangedByUser) {
            this.importDisplayName = this._generateImportDisplayName();
        }
    }

    private _generateImportDisplayName(): string {
        const contentTypeName = this.selectedContentType && this.selectedContentType.name;
        let result: string;
        if (!!contentTypeName){
            const fileName = (this.file && this.file.name) || '';
            const date = this.hasEffectiveDate && this.assetEffectiveDate ? this.assetEffectiveDate : new Date();
            const month = `00${(date.getUTCMonth() + 1).toString()}`.slice(-2);
            const day = `00${(date.getUTCDate()).toString()}`.slice(-2);
            const year = date.getUTCFullYear().toString();
            const dateString = EntityImportUploadComponent._joinIfNotEmpty([month, day, year], '/', 10);
            const firstName = this._userInfo && this._userInfo.firstName || '';
            const lastName = this._userInfo && this._userInfo.lastName || '';
            result = EntityImportUploadComponent._joinIfNotEmpty([contentTypeName, dateString, firstName, lastName, fileName], '_', 100);
        }

        return result;
    }

    private static _joinIfNotEmpty(parts: string[], separator: string, maxLength: number): string {
        return _.join(_.filter(parts,
            (x) => {
                return x && true && x !== '';
            }), separator).substr(0, maxLength);
    }

    private _filterFileFields(): void {
        const newFileFields = this.selectedContentType.fileFields.filter(x => {
            let result = !x.showWhen.length;

            if(!result) {
                for (let i = 0; i < x.showWhen.length && !result; i++) {
                    result = this.fileFieldsSelectedValues[x.showWhen[i].key] === x.showWhen[i].value;
                }
            }

            return result;
        });

        this.fileFields.forEach(x => {
            if (!newFileFields.find(y => y.importFieldId === x.importFieldId)) {
                delete this.fileFieldsSelectedValues[x.importFieldId];
            }
        })

        this.fileFields = newFileFields;

        this.fileFields
            .filter(x => x.initialValue && [null, undefined].indexOf(this.fileFieldsSelectedValues[x.importFieldId]) !== -1)
            .forEach(x => this.fileFieldsSelectedValues[x.importFieldId] = this.calculateInitialValue(x.initialValue));

        this.visibleFileFields = this.fileFields.filter(x => x.renderer);
    }

    calculateInitialValue = (initialValueString: string): string => eval(initialValueString);

    //Do not remove this function. It is used in the file fields logic
    getMidnightMoment(inputDate: Date, rangeEnd: boolean, timeZone: string): any {
        return WeissmanDatetimeComponent.getMidnightMoment(new Date(new Date().getFullYear(), 11, 31), false, 'utc');
    }
}

