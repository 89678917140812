import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductAnalyticsService } from '../../../../Common/Amplitude/productAnalytics.service';
import { SmartSearchService } from '../../smartSearch.service';
import { RestrictService, Roles } from '../../../../Common/Permissions/restrict.service';
import { AdvancedSearchCriteria, AdvancedSearchPersistenceService, SearchFilters, SearchOutputColumns } from '../../advancedSearchPersistence.service';
import * as _ from 'lodash';

@Component({
    selector: 'advanced-output-list',
    templateUrl: './outputList.component.html',
    styleUrls: ['./outputList.component.scss']
})
export class OutputListComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _smartSearchService: SmartSearchService,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Input() invalidColumns;
    @Input()
    set searchCriteria(searchCriteria: AdvancedSearchCriteria) {
        this._searchCriteria = { ...searchCriteria };

        this.outputColumns = this._searchCriteria.outputColumns;
        this.outputFormat = this._searchCriteria.outputFormat;
        this.searchFilters = this._searchCriteria.filters;
        this.groupByBatches = this._searchCriteria.groupByBatches;
    }

    @Output() outputChanged: EventEmitter<void> = new EventEmitter();

    outputColumns: SearchOutputColumns[];
    outputFormat: number;
    searchFilters: SearchFilters[];
    groupByBatches: boolean;
    hasRyanPrivatePermission: boolean;
    loadSaveSettings: boolean;

    private _searchCriteria: AdvancedSearchCriteria;

    ngOnInit(): void {
        this.hasRyanPrivatePermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT) ||
        this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
    }

    getColumnUniqueId = (value) => `${value.categoryId}${value.columnId}`;

    addOutputs(columns: any[]): void {
        this.outputColumns = _.union(this.outputColumns, columns);

        this._smartSearchService.disableColumns(_.union(this.outputColumns, this.searchFilters));
        this.updateCriteria();
    }

    removeOutput(column: any): void {
        _.remove(this.outputColumns, column);

        this._smartSearchService.disableColumns(_.union(this.outputColumns, this.searchFilters));
        this.invalidColumns = this._smartSearchService.getInvalidColumns(_.cloneDeep(this.outputColumns));
        this.updateCriteria();
    }

    columnMoved(): void {
        this.outputColumns = this.outputColumns.map(column => {
            column.showClose = false;
            return column;
        });
        this.updateCriteria();
    }

    updateCriteria(): void {
        this._searchCriteria.outputColumns = this.outputColumns;
        this._searchCriteria.outputFormat = this.outputFormat;
        this._searchCriteria.filters = this.searchFilters;
        this._searchCriteria.groupByBatches = this.groupByBatches;

        this._advancedSearchPersistenceService.setSearchCriteria(this._searchCriteria);
        this.outputChanged.emit();
    }

    async persistSetting(analyticsEvent?: string): Promise<void> {
        this.updateCriteria();
        this.loadSaveSettings = true;
        await this._smartSearchService.persistSetting(this);
        this.loadSaveSettings = false;
        if (analyticsEvent) {
            this._productAnalyticsService.logEvent(analyticsEvent);
        }
    }

    areBatchFields(): boolean {
        const batchFieldsPresent = this.outputColumns.some(column => {
            // These IDs are company, Filing, and Batch respectively
            return [10, 140, 160].includes(column.depthValue);
        });

        if(!batchFieldsPresent) {
            this.groupByBatches = false;
        }

        return batchFieldsPresent;
    }
}
