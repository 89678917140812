import { FeatureFlagsService } from '../FeatureFlags/feature-flags-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { WeissmanEnvironmentConfig } from 'src/app/weissmanEnvironmentConfig';

@Injectable(
    { providedIn: 'root' }
)
export class SettingsService {
    constructor(
        private featureFlagsService: FeatureFlagsService,
        private http: HttpClient
    ) { }

    async Load(): Promise<void> {
        const config = await lastValueFrom(this.http.get<Core.AppConfigModel>('api/AppInfo/UIConfig'));
        this.featureFlagsService.featureFlags = config.featureFlags;

        // Note that main.ts sets up initial fields on weissmanEnvironmentConfig immediately on app startup
        // by calling WeissmanEnvironmentConfig.initEnvironment. This fills in additional values that require
        // an API call.
        const environmentConfig = this.getEnvironmentConfig();

        // TypeLite exports Uris in C# literally as the System.Uri type, but really it should be string
        environmentConfig.signalRMessageBrokerUrl = this.resolveUri(config.uiConfig.signalRMessageBrokerUrl);
        environmentConfig.signalRUseUnsafeLogin = config.uiConfig.signalRUseUnsafeLogin;
        environmentConfig.weissman2Url = this.resolveUri(config.uiConfig.weissman2Url);
        environmentConfig.amplitudeApiKey = config.uiConfig.amplitudeApiKey;
        if (config.uiConfig.runtimeEnvironment) {
            environmentConfig.runtimeEnvironment = <'prod' | 'test' | 'local'>config.uiConfig.runtimeEnvironment;
        }
    }

    getEnvironmentConfig() : WeissmanEnvironmentConfig {
        return (window as any).weissmanEnvironmentConfig;
    }

    private resolveUri(inp: System.Uri): string {
        return <string><unknown>inp;
    }
}
