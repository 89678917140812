import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RestrictService, InstanceRights } from '../Common/Permissions/restrict.service';
import { Team } from './team.model';
import * as _ from 'lodash';
import { TeamService } from './team.service';

export interface CostCenterTeam extends Team {
    costCenterString: string;
}

@Component({
    selector: 'team-picker',
    template: `
      <input *ngIf="editMode" type="text" [(ngModel)]="typeaheadString" [typeahead]="teams" [typeaheadScrollable]="true"
            [typeaheadOptionsInScrollableView]="10" [placeholder]="placeHolder"
            (typeaheadOnSelect)="itemSelected($event.item)" [typeaheadMinLength]="2" container="body"
            [typeaheadOptionField]="typeaheadField" [typeaheadOptionsLimit]="20" [disabled]="dataLoading || !hasEditPermission"
            class="form-control">
        <span *ngIf="team && !editMode">
            <span *ngIf="useCostCenter">{{team.costCenterString}}</span>
            <span *ngIf="!useCostCenter">{{team.name}}</span>
        </span>
    `
})
export class TeamPickerComponent implements OnInit {
    constructor(private teamsService: TeamService,
        private restrictService: RestrictService) { }

    @Input() useCostCenter: boolean;
    @Input() team: CostCenterTeam;
    @Input() costCenter: string;
    @Input() costCenterTeamId: number;
    @Input() editMode = true;
    @Input() canEdit: boolean = false;
    @Output() teamChange: EventEmitter<CostCenterTeam> = new EventEmitter<CostCenterTeam>();
    @Output() costCenterChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() costCenterTeamIdChange: EventEmitter<number> = new EventEmitter<number>();

    hasEditPermission: boolean = false;
    dataLoading: boolean = false;
    teams: CostCenterTeam[];
    placeHolder: string = '';
    typeaheadField: string = '';
    typeaheadString: string = '';

    async ngOnInit() {
        try {
            this.dataLoading = true;
            const teams = await this.teamsService.getAllTeams(false) as CostCenterTeam[];
            this.teams = _.sortBy(teams, 'name');

            if(this.useCostCenter) {
                this.teams = _.chain(this.teams)
                    .filter('costCenter')
                    .map(x => {
                        x.costCenterString = `${x.costCenter || ''} (${x.name})`;
                        return x;
                    })
                    .value();

                this.placeHolder = 'Search Cost Center';
                this.typeaheadField = 'costCenterString';
                this.typeaheadString = this.costCenter;

                if(this.costCenterTeamId) {
                    this.team = _.find(this.teams, {teamID: this.costCenterTeamId});
                }
                else if(this.costCenter) {
                    this.team = _.find(this.teams, {costCenter: this.costCenter});
                    this.costCenterTeamId = this.team.teamID;
                    this.costCenterTeamIdChange.emit(this.costCenterTeamId);
                }
                this.typeaheadString = this.team?.costCenterString;

                this.hasEditPermission = true;
            } else {
                this.placeHolder = 'Search Teams';
                this.typeaheadField = 'name';

                if(this.team) {
                    this.typeaheadString = this.team.name;
                }

                this.hasEditPermission = this.restrictService.hasInstanceRight(InstanceRights.TEAMSETUPSEDIT) || this.canEdit;
            }

        } finally {
            this.dataLoading = false;
        }
    }

    itemSelected(team: CostCenterTeam) {
        this.team = team;
        this.costCenter = this.team.costCenter;
        this.typeaheadString = this.useCostCenter ? this.team.costCenterString : this.team.name;

        this.teamChange.emit(team);
        this.costCenterChange.emit(this.team.costCenter);
        this.costCenterTeamIdChange.emit(this.team.teamID);
    }

}
