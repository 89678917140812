import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ClientServicesExceptionModalService } from '../Client-Services/Exceptions/client-service-exception-modal.service';
import { ClientServicesExceptionModalComponent } from '../Client-Services/Exceptions/client-service-exception.modal';
import { ClientServicesExceptionService } from '../Client-Services/Exceptions/client-services-exception.service';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { SharedPipesModule } from '../UI-Lib/Pipes/shared-pipes.module';
import { ActionViewOutputCustomComponent } from './ActionView/Action-View-Output-Custom/actionViewOutputCustom.component';
import { ActionViewOutputDefaultsComponent } from './ActionView/Action-View-Output-Defaults/actionViewOutputDefaults.component';
import { ActionViewOutputDefaultsGridActionCellRendererComponent } from './ActionView/Action-View-Output-Defaults/agGridActionCellRenderer.component';
import { AppealRecommendationModule } from '../Appeal-Recommendation/appealRecommendation.module';
import { ErrorNotificationComponent } from './Error-Notification/errorNotification.component';
import { ReassignTaskModalComponent } from './Reassign-Task-Modal/reassignTaskModal.component';
// import { TaskSeriesArrowsComponent } from './Task-Series-Arrows/taskSeriesArrows.component';
// import { TaskModalComponent } from './taskModal.component';
import { TaskModalsService } from './taskModals.service';


const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        SharedPipesModule,
        PopoverModule,
        AppealRecommendationModule
    ],
    declarations: [
        ClientServicesExceptionModalComponent,
        ActionViewOutputDefaultsGridActionCellRendererComponent,
        ActionViewOutputDefaultsComponent,
        ActionViewOutputCustomComponent,
        ErrorNotificationComponent,
        ReassignTaskModalComponent,
        // TaskModalComponent,
        // TaskSeriesArrowsComponent
    ],
    providers: [
        ClientServicesExceptionService,
        ClientServicesExceptionModalService
    ],
    exports: [
        ActionViewOutputDefaultsComponent
    ]
})
export class TaskModule {
    static setupModule(): void {
        //let hybridAdapterUtility = new HybridAdapterUtility();

        //Entry components must have only two words, for example: parcelActivity not parcelActivitySummary
        //This is due to a bug in angular 1 case formatting where it gets confused with kebab casing with multiple dashes
        //And angular 1 will autoconvert camelCase to kebab-case
        hybridAdapterUtility.downgradeNg2Component('client-services-exception-modal', ClientServicesExceptionModalComponent);
        hybridAdapterUtility.downgradeNg2Component('actionViewOutputDefaults', ActionViewOutputDefaultsComponent);
        hybridAdapterUtility.downgradeNg2Provider('taskModalsService', TaskModalsService);
    }
}
