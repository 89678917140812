import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { Subscription } from 'rxjs';
import { AppealApplicationService } from './appeal.application.service';
import { ToastrService } from 'ngx-toastr';
import { AppealApplicationViewerComponent } from './PDF-Viewer/appealApplicationViewer.component';
import { ActionViewPersistenceService } from '../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { AppealFormItem } from './appeal.application.model';
import { BusyIndicatorRef, BusyIndicatorService, SnackBarService } from '../Busy-Indicator';
import { DownloadFileService } from '../Common/Download/downloadFileService';
import { HelpService } from '../UI-Lib/Help-Tooltip';
import { APPEAL_APPLICATION_HELP } from './appealApplication.help';

@Component({
    selector: 'appeal-application',
    templateUrl: './appealApplication.component.html'
})
export class AppealApplicationComponent implements OnInit {
    constructor(private readonly _routerService: UpgradeNavigationServiceHandler,
                private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
                private readonly _appealApplicationService: AppealApplicationService,
                private readonly _toastsManager: ToastrService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _changeDetectorRef: ChangeDetectorRef,
                private readonly _busyIndicatorService: BusyIndicatorService,
                private readonly _downloadFileService: DownloadFileService,
                private readonly _helpService: HelpService,
                private readonly _snackBarService: SnackBarService
            ) { }

    @ViewChild(AppealApplicationViewerComponent) appealApplicationViewer;
    appealIds: number[];
    defaultSelectedAppealId: number;
    appealFormList: AppealFormItem[];
    appealFormRevisionIds: number[];
    selectedForm: AppealFormItem;
    state: any;
    isDownloadingForms: boolean = false;
    haveSettingsChanged: boolean = false;
    hasMultiAccountSettingChanged: boolean = false;
    isCompleting: boolean = false;
    initBusyRef: BusyIndicatorRef;

    private _appealApplicationChangeSub: Subscription;
    private _longRunningProcessId: number;

    ngOnInit() {
        this._helpService.setContent(APPEAL_APPLICATION_HELP);

        this.state = this._upgradeNavigationServiceHandler.getCurrentState();
        this.defaultSelectedAppealId = null;
        this.initBusyRef = this._busyIndicatorService.show({ message: 'Initializing Viewer' });

        switch (this.state.name) {
            case 'appealApplicationBatch':
                const appealBatchId = this._upgradeNavigationServiceHandler.getQuerystringParam('appealBatchId');
                if (appealBatchId) {
                    const serializedAppealIds: string = sessionStorage[`AppealApplicationBatch${  appealBatchId}`];
                    if (serializedAppealIds) {
                        this.appealIds = JSON.parse(serializedAppealIds);
                    }
                    else {
                        // TODO: Handle this case somehow (we got an appeal batch ID string, but sessionStorage didn't have it)
                    }
                }
                break;
            case 'appealApplication':
                this.appealIds = [parseInt(this._routerService.getQuerystringParam('appealId'))];
                break;
        }
    }

    ngOnDestroy(): void {
        this._appealApplicationChangeSub && this._appealApplicationChangeSub.unsubscribe();
    }

    onAppealFormListRetrieved(appealFormList: AppealFormItem[]) {
        this.initBusyRef.hide();
        this.appealFormList = appealFormList;
        this.appealFormRevisionIds = appealFormList.map(af => af.appealFormRevisionId);
    }

    async onViewerTabSelected(_event: any) {
        if (this.haveSettingsChanged) {
            await this.appealApplicationViewer.loadForm();
            this.haveSettingsChanged = false;
        }
        else if (this.hasMultiAccountSettingChanged) {
            // If we changed the multi-account settings, we want to reset the appeal list component.
            this.defaultSelectedAppealId = this.selectedForm.appealId;
            const originalAppealIds = this.appealIds;
            this.selectedForm = null;
            this.appealIds = null;
            this._changeDetectorRef.detectChanges();
            this.appealIds = originalAppealIds;
            this._changeDetectorRef.detectChanges();

            this.hasMultiAccountSettingChanged = false;
        }
    }

    async topAppealSelected(newTopAppealFormRevisionId: number) {
        const busyRef = this._busyIndicatorService.show({ message: 'Setting Top Account' });
        let topAppealId: number;
        try {
            const additionalTopAccounts = this.selectedForm.additionalAccounts.filter(a => a.appealFormRevisionId === newTopAppealFormRevisionId);
            if (additionalTopAccounts.length > 0) {
                topAppealId = additionalTopAccounts[0].appealId;
            }
            else if (this.selectedForm.appealFormRevisionId === newTopAppealFormRevisionId) {
                topAppealId = this.selectedForm.appealId;
            }

            await this._appealApplicationService.setTopAppeal({
                otherAppealIds: [this.selectedForm]
                    .concat(this.selectedForm.additionalAccounts)
                    .map(a => a.appealId)
                    .filter(a => a !== topAppealId),
                topAppealId: topAppealId
            });
        }
        finally {
            busyRef.hide();
        }

        // As with multi-account settings, reload the appeal list component if a new top page was selected
        this.defaultSelectedAppealId = topAppealId;
        const originalAppealIds = this.appealIds;
        this.selectedForm = null;
        this.appealIds = null;
        this._changeDetectorRef.detectChanges();
        this.appealIds = originalAppealIds;
        this._changeDetectorRef.detectChanges();
    }

    async generateOutput(): Promise<void> {
        if (this.appealFormList.some(a => a.useLRProcess)) {
            this._longRunningProcessId = await this._appealApplicationService.generateOutputFile(this.appealIds);
            this._snackBarService.addById(this._longRunningProcessId, Compliance.LongRunningProcessTypeEnum.GetAppealApplications);
        } else {
            this.isDownloadingForms = true;

            try {
                const downloadRequest: Core.AppealsDownloadRequest = {
                    batchAppealIds: this.appealIds,
                    flatten: false,
                    onlyTheseFormRevisions: [],
                    zipOutput: false,
                    skipOptimize: false
                };

                const response = await this._appealApplicationService.flattenAndDownload(downloadRequest);
                await this._downloadFileService.downloadResponse(response);
            } catch (e) {
                this._toastsManager.error('An error occurred while downloading the forms. Please try again later.');
            } finally {
                this.isDownloadingForms = false;
            }
        }
    }

    async downloadEditable(): Promise<void> {
        this.isDownloadingForms = true;

        try {
            const downloadRequest: Core.AppealsDownloadRequest = {
                batchAppealIds: this.appealIds,
                flatten: false,
                onlyTheseFormRevisions: [],
                zipOutput: false,
                skipOptimize: false
            };

            const response = await this._appealApplicationService.download(downloadRequest);
            await this._downloadFileService.downloadResponse(response);
        } catch (e) {
            this._toastsManager.error('An error occurred while downloading the forms. Please try again later.');
        } finally {
            this.isDownloadingForms = false;
        }
    }

    async downloadFlat(): Promise<void> {
        this.isDownloadingForms = true;

        try {
            const downloadRequest: Core.AppealsDownloadRequest = {
                batchAppealIds: this.appealIds,
                flatten: true,
                onlyTheseFormRevisions: [],
                zipOutput: false,
                skipOptimize: false
            };

            const response = await this._appealApplicationService.flattenAndDownload(downloadRequest)
            await this._downloadFileService.downloadResponse(response);
        } catch (e) {
            this._toastsManager.error('An error occurred while downloading the forms. Please try again later.');
        } finally {
            this.isDownloadingForms = false;
        }
    }

    async complete(): Promise<void> {
        this.isCompleting = true;
        try {
            await this._appealApplicationService.completeAppealTasks(this.appealIds);
        } catch (e) {
            this.isCompleting = false;
            this._toastsManager.error(e.errorMessage);

            return Promise.resolve();
        }

        this._goBackToPreviousState();
    }

    close() {
        this._goBackToPreviousState();
    }

    private _goBackToPreviousState() {
        switch (this.state.name) {
            case 'appealApplicationBatch':
                this._upgradeNavigationServiceHandler.go('actionview', this._actionViewPersistenceService.routeParams);
                break;
            case 'appealApplication':
                this._upgradeNavigationServiceHandler.go('parcel', { companyId: this.selectedForm.companyId, siteId: this.selectedForm.siteId, parcelId: this.selectedForm.parcelId });
                break;
        }
    }
}
