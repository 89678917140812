import { Directive, ElementRef, EventEmitter, Input, NgZone, Output, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

interface Vector2 {
    x: number;
    y: number;
}

@Directive({
    selector: '[ws-resizable]'
})
export class ResizableDirective {
    constructor(
        private readonly _renderer: Renderer2,
        private readonly _elementRef: ElementRef,
        private readonly _zone: NgZone
    ) { }

    @Input() rDragAxis: string;
    @Input() rDragPosition: string;
    @Input() rMinWidth: number;
    @Input() rMinHeight: number;
    @Input() rMaxWidth: number;
    @Input() rMaxHeight: number;
    @Input() rFlex: boolean;
    @Input() rDisabled: boolean;
    @Input() rWidth: number;
    @Input() rHeight: number;

    @Output() rChanged: EventEmitter<number[]> = new EventEmitter();

    private _container: HTMLElement;
    private _handle: HTMLElement;
    private _styleProperty: string;
    private _dragInProgress: boolean;
    private _startWidth: number;
    private _startHeight: number;
    private _delta: Vector2 = { x: 0, y: 0 };

    private destroy$ = new Subject<void>();

    ngAfterViewInit(): void {
        this._container = this._elementRef.nativeElement;
        this._renderer.addClass(this._container, 'resizable');

        if (this.rFlex) {
            this._styleProperty = 'flexBasis' in document.documentElement.style ? 'flexBasis' :
                'webkitFlexBasis' in document.documentElement.style ? 'webkitFlexBasis' :
                    'msFlexPreferredSize' in document.documentElement.style ? 'msFlexPreferredSize' : 'flexBasis';
        } else {
            this._styleProperty = this.rDragAxis === 'x' ? 'width' : 'height';
        }

        // Set base width if provided
        if (typeof this.rWidth === 'number' && this.rDragAxis === 'x') {
            this._renderer.setStyle(this._container, this._styleProperty, `${this.rWidth}px`);
        }

        // Set base height if provided
        if (typeof this.rHeight === 'number' && this.rDragAxis === 'y') {
            this._renderer.setStyle(this._container, this._styleProperty, `${this.rHeight}px`);
        }

        // Create the drag handle
        this._handle = this._renderer.createElement('div');

        // add class for styling purposes
        this._renderer.addClass(this._handle, `rg-${this.rDragPosition}`);
        this._renderer.addClass(this._handle, 'ws-cursor-grab');
        this._renderer.setProperty(this._handle, 'innerHTML', '<span></span>');
        this._renderer.appendChild(this._container, this._handle);

        this._setStartValues();
        this._setupEvents();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    private _setupEvents(): void {
        this._zone.runOutsideAngular(() => {
            const mousedown$ = fromEvent(this._handle, 'mousedown');
            const mousemove$ = fromEvent(document, 'mousemove');
            const mouseup$ = fromEvent(document, 'mouseup');

            mousedown$
                .pipe(takeUntil(this.destroy$), filter(() => !this.rDisabled), switchMap((event: MouseEvent) => {
                    event.preventDefault();
                    this._setStartValues();
                    const startX = event.clientX;
                    const startY = event.clientY;

                    return mousemove$
                        .pipe(map((e: MouseEvent) => {
                            e.preventDefault();
                            this._dragInProgress = true;
                            this._delta = {
                                x: e.clientX - startX,
                                y: e.clientY - startY
                            };
                        }))
                        .pipe(takeUntil(mouseup$));
                }))
                .subscribe(() => {
                    if (this._delta.x === 0 && this._delta.y === 0) {
                        return;
                    }

                    this._translate();
                });

            mouseup$.pipe(takeUntil(this.destroy$)).subscribe((event: MouseEvent) => {
                // We don't want this event handler to steal mouse up events from
                // other controls if we don't have a drag in progress.
                // A "select multiple" within the draggable div was not getting
                // events consistently without this short-circuit.
                if (!this._dragInProgress) { return; }

                event.preventDefault();
                this._delta = { x: 0, y: 0 };
                this._setStartValues();
                this._dragInProgress = false;
            });
        });
    }

    private _translate(): void {
        requestAnimationFrame(() => {
            if (this.rDragAxis === 'x') {
                let width = this._startWidth + (this._delta.x * 2);
                if (this.rMinWidth && width < this.rMinWidth) {
                    width = this.rMinWidth;
                } else if (this.rMaxWidth && width > this.rMaxWidth) {
                    width = this.rMaxWidth;
                }
                this._renderer.setStyle(this._container, this._styleProperty, `${width}px`);
                this.rChanged.emit([width, this._startHeight]);
            } else {
                let height = this._startHeight + (this._delta.y * 2);
                if (this.rMinHeight && height < this.rMinHeight) {
                    height = this.rMinHeight;
                } else if (this.rMaxHeight && height > this.rMaxHeight) {
                    height = this.rMaxHeight;
                }
                this._renderer.setStyle(this._container, this._styleProperty, `${height}px`);
                this.rChanged.emit([this._startWidth, height]);
            }
        });
    }

    private _setStartValues(): void {
        if (this.rFlex && this._container.style[this._styleProperty]) {
            const value = parseInt(this._container.style[this._styleProperty]);
            this._startWidth = value;
            this._startHeight = value;
        } else {
            const baseValues = this._container.getBoundingClientRect();
            this._startWidth = baseValues.width;
            this._startHeight = baseValues.height;
        }
    }

}
