import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { AddParcelModalComponent, AddParcelModalParams } from './Add-Parcel-Modal/addParcelModal.component';
import { ColDef, GridReadyEvent, GridApi, ColumnApi, FilterChangedEvent, RowNode, RowClickedEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridFilterParams, AgGridOptionsBuilder, AgGridColumns } from '../../../Compliance/AgGrid';
import { ParcelService } from '../../Parcel/parcel.service.upgrade';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StateService } from '../../../Common/States/States.Service';
import { Site, AssessorSummary } from '../Site.Model';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { SiteService } from '../Site.Service.upgrade';
import { BaseExpandableComponent } from '../../../UI-Lib/Expandable-Component/baseExpandableComponent';
import { ParcelListControlRow, ParcelList } from '../Models/parcelList';
import { EntityService } from '../../entity.service';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { PARCEL_LIST_PANEL_HELP } from './parcelListPanel.component.help';
import { AgGridMultiSelectTracker, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer } from '../../../Compliance/AgGrid/MultiSelectTracker';
import { ParcelBulkUpdateParams, ParcelBulkUpdateComponent } from './Parcel-Bulk-Update/parcelBulkUpdate.component';
import { WeissmanMutexService, IMutexServiceHandler } from '../../../Compliance/WeissmanMutexService';
import { NavigationService } from '../../../Layout/navigation.service';
import { ParcelListActionCellRendererParams, ParcelListActionCellRendererComponent } from './agGridActionCellRenderer.component';
import { ParcelTypeCellRendererComponentParams, ParcelTypeCellRendererComponent } from '../../Parcel/Parcel-Type-Cell-Renderer/parcelTypeCellRenderer.component';
import { PercentChangeRendererParams, PercentChangeRendererComponent } from './agGridPercentChangeRenderer.component';
import { FMVChangeRendererParams, FMVChangeRendererComponent } from './agGridFMVChangeRenderer.component';
import { TotalFMVRendererParams, TotalFMVRendererComponent } from './agGridFMVRenderer.component';
import { ToastrService } from 'ngx-toastr';
import { TimerService } from '../../../UI-Lib/Utilities/timer.service';
import ActivityStatuses = Core.ActivityStatuses;

@Component({
    selector: 'parcel-list-panel',
    templateUrl: './parcelListPanel.component.html',
    styleUrls: ['./parcelListPanel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ParcelListPanelComponent extends BaseExpandableComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _decimalPipe: DecimalPipe,
        private readonly _entityService: EntityService,
        private readonly _siteService: SiteService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _navigationService: NavigationService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _modalService: WeissmanModalService,
        private readonly _parcelService: ParcelService,
        private readonly _stateService: StateService,
        private readonly _toastrService: ToastrService,
        private readonly _helpService: HelpService,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService) {
        super(_siteService, 'form-parcel-list');
    }

    @Input() site: Site;
    @Input() hasSiteWritePermission: boolean = false;
    @Input() parcelYears: number[];

    //Passed functions to communicate with angularjs parent
    @Input() maximize: (panelName: string) => void;
    @Input() minimize: () => void;

    editMode: boolean = false;
    hasChanges: boolean = false;
    dataLoading: boolean = false;
    isExcludeInactiveParcelsCheckboxVisible: boolean;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();

    contentTypeToggle: UntypedFormControl = new UntypedFormControl(false);
    excludeInactive: boolean;
    excludeInactiveParcels: UntypedFormControl = new UntypedFormControl(false);

    latestTaxYearLabel: string = 'Latest';
    taxYear: string;
    availableTaxYears: (number | string)[] = [];
    selectedTaxYear: UntypedFormControl = new UntypedFormControl();

    parcelList: ParcelList;

    agGridReady: boolean = false;
    gridTracker: AgGridMultiSelectTracker;
    selectedRows: number[] = [];
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    gridId: System.Guid = '0AD911C5-029D-47B6-847C-00B30505AD23';
    gridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'totals-row': (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    return parcel && parcel.isTotalRow;
                },
                'clickable': (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    return parcel && !parcel.isTotalRow && !this.editMode;
                },
                'ag-row-selected': (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    return parcel && this.gridTracker.isRowSelected(parcel.parcelID);
                }
            },
            onRowClicked: this._navigateToParcelFromRowSelect.bind(this)
        })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withFilterChanged(this._updateTotal.bind(this))
        .withRowId((data) => {
            const parcel = data as ParcelListControlRow;
            return parcel && parcel.parcelID.toString();
        })
        .withColumnPinning()
        .build();

    private _gridApi: GridApi;
    private _columnApi: ColumnApi;
    private _firstAssessor: AssessorSummary;
    private _stateId: number;
    private _state: Weissman.Model.Misc.State;
    private _maxTileCount: number = 100;
    private _selectedParcelIndex: number;
    private _destroy$: Subject<boolean> = new Subject();
    private _gridColumnsInitialized: boolean;

    get canEdit(): boolean {
        return this.hasSiteWritePermission && this.ppReturnPreparationAllowed;
    }

    get hasMasterSite(): boolean {
        return this.site && this.site.masterSiteId && this.site.masterSiteId !== this.site.siteID;
    }

    get stateAllowConsolidating(): boolean {
        return this._state && this._state.allowConsolidating;
    }

    get ppReturnPreparationAllowed(): boolean {
        return this.site && this.site.ppReturnPreparationAllowed;
    }

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(PARCEL_LIST_PANEL_HELP);
        this._siteService.site = this.site;

        this.availableTaxYears = [this.latestTaxYearLabel, ...((this.parcelYears && this.parcelYears.length) ? this.parcelYears.reverse() : [])];

        if (this._entityService.currentSiteId !== this.site.siteID) {
            // Handle site specific state until all site route components are migrated from AngularJS
            this._entityService.reset();
            this._entityService.currentSiteId = this.site.siteID;
        }

        // State persistence
        this._entityService.parcelListDisplayState$.pipe(takeUntil(this._destroy$)).subscribe(state => this.contentTypeToggle.setValue(state));

        this._entityService.parcelListTaxYear$.pipe(takeUntil(this._destroy$)).subscribe(async (year) => {
            this._entityService.setParcelListScrollIndex(null);
            this.selectedTaxYear.setValue(year);
            await this._getParcels(year);
        });

        this._entityService.parcelListScrollIndex$.pipe(takeUntil(this._destroy$)).subscribe(i => {
            this._selectedParcelIndex = i;
            if (i !== null && i >= 0 && this._gridApi) {
                this._gridApi.ensureIndexVisible(this._selectedParcelIndex, 'top');
            }
        });

        this.excludeInactiveParcels.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(exclude => {
            if (this.parcelList && this.excludeInactive !== exclude) {
                this.parcelList.filterInactive(exclude);
                if (this.agGridReady && this.contentTypeToggle.value) {
                    this._setRowData();
                }
            }
        });

        this._siteService.parcelList$.pipe(takeUntil(this._destroy$)).subscribe(parcelList => {
            if (parcelList) {
                this.parcelList = parcelList;
                // Check for inactive parcels
                const inactiveParcelCount = this.parcelList.tiles.filter((p) => p.activityStatusID === 0).length;
                this.isExcludeInactiveParcelsCheckboxVisible = inactiveParcelCount !== 0;
                this.excludeInactiveParcels.setValue(inactiveParcelCount !== this.parcelList.tiles.length);
                if (this.contentTypeToggle.value) {
                    this._setColumnDefinitions();
                }
            }
        });

        this._mutexService.lockChanged$.pipe(takeUntil(this._destroy$)).subscribe((x) => {
            const isLocked = x && x[this._entityService.editGroup];
            if (isLocked) {
                this.editMode = true;
                this.selectedTaxYear.disable();
                this.contentTypeToggle.disable();
                this._navigationService.enableNavWarning('Editing is in progress.  Are you sure you wish to leave?');
                this._gridColumnsInitialized && this._columnApi && this._columnApi.setColumnsVisible(['grid-column-select'], true);
            }
            else {
                this.editMode = false;
                this.hasChanges = false;
                this.selectedTaxYear.enable();
                this.contentTypeToggle.enable();
                this._navigationService.disableNavWarning();
                this.gridTracker && this.gridTracker.clear();
                this.selectedRows = [];
                this._gridColumnsInitialized && this._columnApi && this._columnApi.setColumnsVisible(['grid-column-select'], false);
                this._gridApi && this._gridApi.redrawRows();
            }
        });

        // Theoretically we should re-initialize the page if the site changes, but in practice the only thing
        // that matters that we get from this.site is the address, so just reset the property on change (WK-8446)
        this._siteService.site$.pipe(takeUntil(this._destroy$)).subscribe(s => {
            this.site = s;
            if(this.site) {
                this._setSiteVars();
            }
        });
    }

    ngOnDestroy(): void {
        this._siteService.reset();
        this._mutexService.release(this, this._entityService.editGroup);
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._columnApi = event.columnApi;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe((selected: Compliance.SelectedRowsModel) => {
            this.selectedRows = (selected.selectAllRows)
                ? this.parcelList.parcels.filter(x => !x.isTotalRow).map(x => x.parcelID)
                : selected.selectedRows;

            this.isBulkUpdateVisible$.next(this.selectedRows && this.selectedRows.length > 0);
        });

        if (this.parcelList) {
            this._setColumnDefinitions();
        }

        this.agGridReady = true;
        this._setRowData();
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    onViewChanged(toggleValue: boolean): void {
        this._entityService.changeParcelListDisplayState(toggleValue);
    }

    onTaxYearChanged(taxYear: string): void {
        this._entityService.changeParcelListTaxYear(taxYear);
    }

    async bulkUpdate(): Promise<void> {
        let isConsolidatedParcelSelected: boolean = false;
        let isNonPPParcelsSelected: boolean = false;

        this.selectedRows.forEach(x => {
            const rowNode = this._gridApi.getRowNode(x.toString());
            const parcel = rowNode.data as ParcelListControlRow;

            if (parcel && parcel.parcelID == parcel.consolidatedParcelId) {
                isConsolidatedParcelSelected = true;
            }

            if (parcel && parcel.propertyTypeId !== Core.PropertyTypes.Personal) {
                isNonPPParcelsSelected = true;
            }
        });

        const params: ParcelBulkUpdateParams = {
            siteId: this.site.siteID,
            selectedRows: this.gridTracker.getSelectedRowsModel(),
            stateAllowConsolidating: this._state.allowConsolidating,
            isConsolidatedParcelSelected: isConsolidatedParcelSelected,
            isNonPPParcelsSelected: isNonPPParcelsSelected
        };

        const result = await this._modalService.showAsync(ParcelBulkUpdateComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const update: RowNode[] = [];

        if (result.optInParcelsAction === Core.ParcelBulkUpdateFieldActionEnum.ChangeTo) {
            result.optInParcels.forEach(x => {
                const rowNode = this._gridApi.getRowNode(x.toString());
                const parcel = rowNode && rowNode.data as ParcelListControlRow;
                parcel.consolidatingTypeId = null;
                update.push(rowNode);
                this.hasChanges = true;
            });
        }

        this._gridApi.redrawRows({ rowNodes: update });

        this.gridTracker && this.gridTracker.clear();
        this.selectedRows = [];
    }

    edit(): void {
        this._mutexService.acquire(this, this._entityService.editGroup);
    }

    async save(): Promise<void> {
        const bulkUpdateModel: Core.ParcelBulkUpdateModel = {
            siteId: this.site.siteID,
            force: true,
            reportingParcelAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            reportingParcelId: null,
            consolidatedParcelAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            consolidatedParcelId: null,
            optInParcelsAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            optInParcels: [],
            optOutParcelsAction: Core.ParcelBulkUpdateFieldActionEnum.NoChange,
            optOutParcels: []
        };

        this._gridApi.forEachNode((node: RowNode) => {
            const parcel = node.data as ParcelListControlRow;
            if (parcel && parcel.propertyTypeId === Core.PropertyTypes.Personal && !parcel.isExternalReportingParcel && !parcel.isTotalRow) {
                // update reporting parcel
                if (parcel.isReportingParcel !== parcel.originalIsReportingParcel) {
                    bulkUpdateModel.reportingParcelAction = Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
                    if (parcel.isReportingParcel) {
                        bulkUpdateModel.reportingParcelId = parcel.parcelID;
                    }
                }

                // update consolidated parcel
                if (parcel.consolidatedParcelId !== parcel.originalConsolidatedParcelId) {
                    bulkUpdateModel.consolidatedParcelAction = Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
                    if (parcel.consolidatedParcelId === parcel.parcelID) {
                        bulkUpdateModel.consolidatedParcelId = parcel.parcelID;
                    }
                }

                // update opt in/out
                if (parcel.consolidatedParcelId && (parcel.consolidatingTypeId !== parcel.originalConsolidatingTypeId)) {
                    if (parcel.consolidatingTypeId === Core.ConsolidatingTypeEnum.None) {
                        bulkUpdateModel.optOutParcelsAction = Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
                        bulkUpdateModel.optOutParcels.push(parcel.parcelID);
                    }

                    if (!parcel.consolidatingTypeId) {
                        bulkUpdateModel.optInParcelsAction = Core.ParcelBulkUpdateFieldActionEnum.ChangeTo;
                        bulkUpdateModel.optInParcels.push(parcel.parcelID);
                    }
                }
            }
        });

        this._setDataLoading(true);
        try {
            await this._parcelService.bulkUpdate(bulkUpdateModel);
            await this._siteService.updateParcelList(this.selectedTaxYear.value);
            this._mutexService.release(this, this._entityService.editGroup);
        } finally {
            this._setDataLoading(false);
        }

        return Promise.resolve();
    }

    cancel(): void {
        const update: RowNode[] = [];

        this._gridApi.forEachNode((node: RowNode) => {
            const parcel = node.data as ParcelListControlRow;
            if (parcel && parcel.propertyTypeId === Core.PropertyTypes.Personal && !parcel.isExternalReportingParcel && !parcel.isTotalRow &&
                (parcel.originalIsReportingParcel !== parcel.isReportingParcel ||
                parcel.originalConsolidatingTypeId !== parcel.consolidatingTypeId ||
                parcel.originalConsolidatedParcelId !== parcel.consolidatedParcelId)) {
                parcel.isReportingParcel = parcel.originalIsReportingParcel;
                parcel.consolidatingTypeId = parcel.originalConsolidatingTypeId;
                parcel.consolidatedParcelId = parcel.originalConsolidatedParcelId;
                update.push(node);
            }
        });

        this._gridApi.redrawRows({ rowNodes: update });

        this._mutexService.release(this, this._entityService.editGroup);
    }

    expandPanel() {
        super.expandComponent();
        if (this._gridApi && this.contentTypeToggle.value) {
            this._timer.setTimeout(() => {
                this._gridApi.sizeColumnsToFit();
            }, 500);
        }
        this.maximize('form-parcel-list');
    }

    collapsePanel(): void {
        super.collapseComponent();
        if (this._gridApi && this.contentTypeToggle.value) {
            this._timer.setTimeout(() => {
                this._gridApi.sizeColumnsToFit();
            }, 500);
        }
        this.minimize();
    }

    navigateToParcel(parcelID: number, parcel: Core.ParcelTileDTO): void {
        if (this.editMode) {
            return;
        }
        this._productAnalyticsService.logEvent('click-parcel-tile', { accessSiteParcel: `${parcel.propTypeAbbr} Parcel` });

        this._routerService.go('parcelRedirect', { entityID: parcelID });
    }

    async showAddParcel(): Promise<void> {
        const masterParcel = this.parcelList.tiles.find(x => x.isMasterParcel);

        const params: AddParcelModalParams = {
            siteId: this.site.siteID,
            siteAddress: this.site.address,
            stateId: this._stateId,
            stateName: this._state.fullName,
            siteAssessor: this._firstAssessor,
            masterParcel: masterParcel
        };

        const result = await this._modalService.showAsync(AddParcelModalComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this.navigateToParcel(result.parcelID, result);
    }

    private _canRemoveReportingParcel(params: ParcelListActionCellRendererParams): boolean {
        const parcel = params.data as ParcelListControlRow;
        return this.editMode &&
            parcel &&
            parcel.isReportingParcel &&
            !this.hasMasterSite; // prevent changing reporting parcel when in a master parcel relationship
    }

    private _removeReportingParcel(params: ParcelListActionCellRendererParams): void {
        const parcel = params.data as ParcelListControlRow;
        if (!parcel) {
            return;
        }

        const update: RowNode[] = [];

        parcel.isReportingParcel = false;

        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.hasChanges = true;
    }

    private _canSetReportingParcel(params: ParcelListActionCellRendererParams): boolean {
        const parcel = params.data as ParcelListControlRow;
        return this.editMode &&
            parcel &&
            parcel.propertyTypeId === Core.PropertyTypes.Personal &&
            parcel.activityStatusID !== ActivityStatuses.Inactive &&
            !parcel.isReportingParcel &&
            !this.hasMasterSite; // prevent changing reporting parcel when in a master parcel relationship
    }

    private _setReportingParcel(params: ParcelListActionCellRendererParams): void {
        const parcel = params.data as ParcelListControlRow;
        if (!parcel) {
            return;
        }

        const update: RowNode[] = [];

        this._gridApi.forEachNode((node: RowNode) => {
            const oldParcel = node.data as ParcelListControlRow;
            if (oldParcel && oldParcel.propertyTypeId === Core.PropertyTypes.Personal && !parcel.isExternalReportingParcel && !parcel.isTotalRow) {
                if (oldParcel.isReportingParcel) {
                    oldParcel.isReportingParcel = false;
                    update.push(node);
                }
            }
        });

        parcel.isReportingParcel = true;

        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.hasChanges = true;
    }

    private _canSetConsolidated(params: ParcelListActionCellRendererParams): boolean {
        const parcel = params.data as ParcelListControlRow;
        return this.editMode &&
            parcel &&
            parcel.propertyTypeId === Core.PropertyTypes.Personal &&
            parcel.activityStatusID !== Core.ActivityStatuses.Inactive &&
            !parcel.consolidatedParcelId &&
            !parcel.isExternalReportingParcel;
    }

    private _setConsolidatedParcel(params: ParcelListActionCellRendererParams): void {
        const parcel = params.data as ParcelListControlRow;
        if (!parcel) {
            return;
        }

        const update: RowNode[] = [];

        this._gridApi.forEachNode((node: RowNode) => {
            const oldParcel = node.data as ParcelListControlRow;
            if (oldParcel && oldParcel.propertyTypeId === Core.PropertyTypes.Personal && !parcel.isExternalReportingParcel && !oldParcel.isTotalRow)
            {
                if (oldParcel.consolidatedParcelId !== parcel.parcelID) {
                    oldParcel.consolidatedParcelId = parcel.parcelID;
                    update.push(node);
                }
            }
        });

        parcel.consolidatingTypeId = Core.ConsolidatingTypeEnum.Consolidated;

        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.hasChanges = true;

        this._warnOnConsolidatedChange();
    }

    private _canRemoveConsolidated(params: ParcelListActionCellRendererParams): boolean {
        const parcel = params.data as ParcelListControlRow;
        return this.editMode &&
            parcel &&
            parcel.consolidatedParcelId &&
            parcel.consolidatedParcelId === parcel.parcelID &&
            !parcel.isExternalReportingParcel;
    }

    private _removeConsolidatedParcel(params: ParcelListActionCellRendererParams): void {
        const parcel = params.data as ParcelListControlRow;
        if (!parcel) {
            return;
        }

        const update: RowNode[] = [];

        this._gridApi.forEachNode((node: RowNode) => {
            const oldParcel = node.data as ParcelListControlRow;

            if (oldParcel && oldParcel.propertyTypeId === Core.PropertyTypes.Personal && !parcel.isExternalReportingParcel && !parcel.isTotalRow) {
                if (oldParcel.consolidatedParcelId) {
                    oldParcel.consolidatedParcelId = null;
                    update.push(node);
                }
            }
        });

        parcel.consolidatedParcelId = null;
        parcel.consolidatingTypeId = null;

        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.hasChanges = true;
    }

    private _canOptOutConsolidated(params: ParcelListActionCellRendererParams): boolean {
        const parcel = params.data as ParcelListControlRow;
        return this.editMode &&
            parcel &&
            parcel.consolidatedParcelId &&
            parcel.consolidatedParcelId !== parcel.parcelID &&
            parcel.consolidatingTypeId === null &&
            !parcel.isExternalReportingParcel;
    }

    private _optOutConsolidatedParcel(params: ParcelListActionCellRendererParams): void {
        const parcel = params.data as ParcelListControlRow;
        if (!parcel) {
            return;
        }

        const update: RowNode[] = [];

        parcel.consolidatingTypeId = Core.ConsolidatingTypeEnum.None;

        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.hasChanges = true;
    }

    private _canOptInConsolidated(params: ParcelListActionCellRendererParams): boolean {
        const parcel = params.data as ParcelListControlRow;
        return this.editMode &&
            parcel &&
            parcel.activityStatusID !== 0 &&
            parcel.consolidatedParcelId &&
            parcel.consolidatedParcelId !== parcel.parcelID &&
            parcel.consolidatingTypeId === Core.ConsolidatingTypeEnum.None &&
            !parcel.isExternalReportingParcel;
    }

    private _optInConsolidatedParcel(params: ParcelListActionCellRendererParams): void {
        const parcel = params.data as ParcelListControlRow;
        if (!parcel) {
            return;
        }

        const update: RowNode[] = [];

        parcel.consolidatingTypeId = null;

        update.push(params.node);

        this._gridApi.redrawRows({ rowNodes: update });

        this.hasChanges = true;

        this._warnOnConsolidatedChange();
    }

    private async _setSiteVars() {
        this._stateId = this.site.address.stateID;
        this._firstAssessor = (this.site.assessors && this.site.assessors.length) ? this.site.assessors[0] : null;
        this._state = await this._stateService.getById(this._stateId);
    }

    private _setColumnDefinitions(): void {
        let columns: ColDef[] = [
            {
                colId: 'grid-column-select',
                field: 'parcelID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                hide: true
            },
            {
                headerName: 'Type',
                field: 'propTypeAbbr',
                width: 100,
                suppressSizeToFit: true,
                suppressAutoSize: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: ParcelTypeCellRendererComponent,
                cellRendererParams: {
                    getParcelType: (params: ParcelTypeCellRendererComponentParams): string => {
                        const parcel = params.data as ParcelListControlRow;
                        return parcel.propTypeAbbr;
                    },
                    isLinkedParcel: (params: ParcelTypeCellRendererComponentParams): boolean => {
                        const parcel = params.data as ParcelListControlRow;
                        return parcel.isLinked;
                    },
                    isMasterParcel: (params: ParcelTypeCellRendererComponentParams): boolean => {
                        const parcel = params.data as ParcelListControlRow;
                        return parcel.isMasterParcel;
                    },
                    isReportingParcel: (params: ParcelTypeCellRendererComponentParams): boolean => {
                        const parcel = params.data as ParcelListControlRow;
                        return parcel.isReportingParcel && this.ppReturnPreparationAllowed;
                    },
                    isConsolidatedParcel: (params: ParcelTypeCellRendererComponentParams): boolean => {
                        const parcel = params.data as ParcelListControlRow;
                        return parcel.consolidatingTypeId === Core.ConsolidatingTypeEnum.Consolidated && this.ppReturnPreparationAllowed;
                    },
                    ppReturnPreparationAllowed: (params: ParcelTypeCellRendererComponentParams) => this.ppReturnPreparationAllowed,
                    stateAllowConsolidating: (params: ParcelTypeCellRendererComponentParams) => this.stateAllowConsolidating,
                    isMergedParcel: (params: ParcelTypeCellRendererComponentParams): boolean => {
                        const parcel = params.data as ParcelListControlRow;
                        return parcel.consolidatedParcelId && !parcel.consolidatingTypeId && this.ppReturnPreparationAllowed;
                    }
                }
            },
            {
                headerName: 'Acct Num',
                field: 'acctNum',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    return (!parcel.isTotalRow) ? parcel.acctNum : 'TOTAL';
                }
            },
            {
                headerName: 'Description',
                field: 'description',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    return (!parcel.isTotalRow) ? parcel.description : '-';
                }
            },
            {
                headerName: 'Parcel #2',
                field: 'altParcel2',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    return (parcel.altParcel2) ? parcel.altParcel2 : '';
                }
            },
            {
                headerName: 'Assessor',
                field: 'assessorAbbr',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    return (parcel.assessorAbbr) ? parcel.assessorAbbr : '';
                }
            },
            {
                headerName: 'Assessee',
                field: 'assesseeName',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    return (parcel.assesseeName) ? parcel.assesseeName : '';
                }
            },
            {
                headerName: 'Address',
                field: 'address',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    const address = parcel.address;
                    let combined = '';
                    if (address && address.address1) {
                        combined += address.address1;
                    }
                    if (address && address.address2) {
                        combined += ` ${address.address2}`;
                    }
                    return combined;
                }
            },
            {
                headerName: 'City',
                field: 'city',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    const address = parcel.address;
                    return (address && address.city) ? address.city : '';
                }
            },
            {
                headerName: 'State',
                field: 'state',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    const address = parcel.address;
                    return (address && address.state) ? address.state.abbr : '';
                }
            },
            {
                headerName: 'Zip',
                field: 'zip',
                hide: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    const address = parcel.address;
                    return (address && address.zip) ? address.zip : '';
                }
            },
            {
                headerName: 'Year',
                field: 'annualYear',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;
                    if (parcel.isTotalRow) {
                        return '-';
                    }
                    return (parcel.annualYear) ? parcel.annualYear.toString() : '';
                }
            },
            {
                headerName: 'Total FMV',
                field: 'totalFMV',
                type: 'numericColumn',
                width: 35 + AgGridColumns.numericColumnWidth,
                minWidth: 35 + AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellRendererFramework: TotalFMVRendererComponent,
                cellRendererParams: {
                    activityStatusId: (params: TotalFMVRendererParams): number => {
                        const parcel = params.data as ParcelListControlRow;
                        return (parcel) ? parcel.activityStatusID : 0;
                    },
                    isFmvInclude: (params: TotalFMVRendererParams): boolean => {
                        const parcel = params.data as ParcelListControlRow;
                        return (parcel) ? parcel.isFmvInclude : false;
                    },
                    getTotal: (params: TotalFMVRendererParams): string => {
                        const parcel = params.data as ParcelListControlRow;

                        if (parcel.consolidatingTypeId === Core.ConsolidatingTypeEnum.Consolidated) {
                            return 'N/A';
                        }

                        return (parcel && parcel.totalFMV) ? this._decimalPipe.transform(parcel.totalFMV) : '0';
                    }
                } as TotalFMVRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const parcel = params.data as ParcelListControlRow;
                    return ((parcel.status === null || parcel.status === 0)
                            && !parcel.isTotalRow && parcel.consolidatingTypeId !== Core.ConsolidatingTypeEnum.Consolidated)
                        ? 'text-end not-actual'
                        : 'text-end';
                }
            },
            {
                headerName: 'FMV Change',
                field: 'fmvChange',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellRendererFramework: FMVChangeRendererComponent,
                cellRendererParams: {
                    getText: (params: FMVChangeRendererParams): number => {
                        const parcel = params.data as ParcelListControlRow;
                        if (!parcel) {
                            return;
                        }
                        return parcel.fmvChange;
                    }
                } as FMVChangeRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const parcel = params.data as ParcelListControlRow;
                    return ((parcel.status === null || parcel.status === 0)
                            && !parcel.isTotalRow && parcel.consolidatingTypeId !== Core.ConsolidatingTypeEnum.Consolidated)
                        ? 'text-end not-actual'
                        : 'text-end';
                }
            },
            {
                headerName: 'Taxes',
                field: 'totalTaxes',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => {
                    const parcel = params.data as ParcelListControlRow;

                    if (parcel.consolidatingTypeId === Core.ConsolidatingTypeEnum.Consolidated) {
                        return 'N/A';
                    }

                    return `$${  (parcel && parcel.totalTaxes) ? this._decimalPipe.transform(parcel.totalTaxes, '1.2') : '0.00'}`;
                },
                cellClass: (params: ICellRendererParams): string => {
                    const parcel = params.data as ParcelListControlRow;
                    return (parcel.taxIsEstimated !== false
                            && !parcel.isTotalRow && parcel.consolidatingTypeId !== Core.ConsolidatingTypeEnum.Consolidated)
                        ? 'text-end tax-is-estimated'
                        : 'text-end';
                }
            },
            {
                headerName: 'Tax % Difference',
                field: 'taxPercentDiff',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                filterValueGetter: (params: PercentChangeRendererParams): number => {
                    const parcel = params.data as ParcelListControlRow;
                    return (parcel && parcel.taxPercentDiff) ? +((parcel.taxPercentDiff * 100).toFixed(3)) : 0;
                },
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellRendererFramework: PercentChangeRendererComponent,
                cellRendererParams: {
                    getPercentage: (params: PercentChangeRendererParams): number => {
                        const parcel = params.data as ParcelListControlRow;
                        if (!parcel) {
                            return 0;
                        }

                        if (parcel.isTotalRow) {
                            return null;
                        }

                        return (parcel.taxPercentDiff) ? parcel.taxPercentDiff : 0;
                    }
                } as PercentChangeRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const parcel = params.data as ParcelListControlRow;
                    return (parcel.taxIsEstimated !== false
                            && !parcel.isTotalRow && parcel.consolidatingTypeId !== Core.ConsolidatingTypeEnum.Consolidated)
                        ? 'text-end tax-is-estimated'
                        : 'text-end';
                }
            }
        ];

        // Inject unique component columns if any
        const uniqueComponents = this._getUniqueComponents();
        if (uniqueComponents && uniqueComponents.length) {
            const componentCols = uniqueComponents.map(component => {
                const uniqueComponent = () => component; // Create closure
                return {
                    headerName: component.componentName,
                    field: 'components',
                    type: 'numericColumn',
                    width: AgGridColumns.numericColumnWidth,
                    filter: 'agNumberColumnFilter',
                    filterParams: AgGridFilterParams.numberWithRangeAndBlankOptionsFilterParams,
                    filterValueGetter: (params) => {
                        const parcel = params.data as ParcelListControlRow;
                        if (parcel.components) {
                            const unique = uniqueComponent();
                            const foundComponent = parcel.components.find(x => x.componentName === unique.componentName);
                            return foundComponent && foundComponent.fairMarketValue;
                        }

                    },
                    cellClass: (params: ICellRendererParams): string => {
                        const parcel = params.data as ParcelListControlRow;
                        return ((parcel.status === null || parcel.status === 0) && !parcel.isTotalRow) ? 'not-actual' : '';
                    },
                    floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                    valueFormatter: (params) => {
                        const parcel = params.data as ParcelListControlRow;
                        const unique = uniqueComponent();
                        if (parcel.isTotalRow) {
                            return parcel.totalComponents[unique.componentName]
                                ? this._decimalPipe.transform(parcel.totalComponents[unique.componentName])
                                : '-';
                        }
                        if (parcel.components) {
                            const foundComponent = parcel.components.find(x => x.componentName === unique.componentName);
                            return foundComponent ? this._decimalPipe.transform(foundComponent.fairMarketValue) : '--';
                        }
                        return '--';
                    }
                } as ColDef;
            }) as ColDef[];

            const clone = [...columns];
            clone.splice(columns.length - 1, 0, ...componentCols);
            columns = clone;
        }

        columns.push({
            colId: 'grid-column-actions',
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(2),
            minWidth: AgGridColumns.getActionColumnWidth(2),
            maxWidth: AgGridColumns.getActionColumnWidth(2),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            pinned: 'right',
            lockVisible: true,
            sortable: false,
            cellRendererFramework: ParcelListActionCellRendererComponent,
            cellRendererParams: {
                canSetReporting: this._canSetReportingParcel.bind(this),
                setReporting: this._setReportingParcel.bind(this),
                canRemoveReporting: this._canRemoveReportingParcel.bind(this),
                removeReporting: this._removeReportingParcel.bind(this),
                canSetConsolidated: this._canSetConsolidated.bind(this),
                setConsolidated: this._setConsolidatedParcel.bind(this),
                canRemoveConsolidated: this._canRemoveConsolidated.bind(this),
                removeConsolidated: this._removeConsolidatedParcel.bind(this),
                canOptOutConsolidated: this._canOptOutConsolidated.bind(this),
                optOutConsolidated: this._optOutConsolidatedParcel.bind(this),
                canOptInConsolidated: this._canOptInConsolidated.bind(this),
                optInConsolidated: this._optInConsolidatedParcel.bind(this),
                stateAllowConsolidating: (params) => this.stateAllowConsolidating
            } as ParcelListActionCellRendererParams,
            pinnedRowCellRenderer: () => { return ''; },
            hide: !this.canEdit
        });

        const defaultSortModel = [
            {
                colId: 'accountNumber',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridColumnsInitialized = true;
        this._gridApi.setSortModel(defaultSortModel);
    }

    private _setRowData(): void {
        if (!this._gridApi) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();
        this.selectedRows = [];

        this._gridApi.setRowData((this.parcelList) ? this.parcelList.parcels.filter(x => !x.isTotalRow) : []);

        this._gridApi.setPinnedBottomRowData(this.parcelList && this.parcelList.parcels.filter(x => x.isTotalRow));

        if (this._selectedParcelIndex !== null && this._selectedParcelIndex >= 0) {
            this._gridApi.ensureIndexVisible(this._selectedParcelIndex, 'top');
        }
    }

    private async _getParcels(year: number | string): Promise<void> {
        this._setDataLoading(true);
        try {
            await this._siteService.updateParcelList(year);
        } finally {
            this._setDataLoading(false);
            return Promise.resolve();
        }
    }

    private _updateTotal(event: FilterChangedEvent): void {
        let totalFMV = 0;
        let totalTaxes = 0;
        const totalComponents = {};

        this._gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
            const parcel = rowNode.data as ParcelListControlRow;
            if (parcel) {
                totalFMV += parcel.totalFMV || 0;
                totalTaxes += parcel.totalTaxes || 0;

                if (parcel.components) {
                    parcel.components.forEach(c => {
                        totalComponents[c.componentName] += c.fairMarketValue;
                    });
                }
            }
        });

        const rowNode = this._gridApi.getPinnedBottomRow(0);
        const parcel = rowNode.data as ParcelListControlRow;
        if (parcel) {
            parcel.totalFMV = totalFMV;
            parcel.totalTaxes = totalTaxes;
            Object.keys(totalComponents).forEach(key => parcel[key] = totalComponents[key]);
        }
        this._gridApi.redrawRows({ rowNodes: [rowNode] });
    }

    private _getUniqueComponents() {
        return this.parcelList.tiles
            .reduce((acc, parcel) => (parcel.components && parcel.components.length) ? [...acc, ...parcel.components] : acc, [])
            .filter((comp) => !!comp)
            .reduce((acc, comp) => (acc.findIndex(x => x.componentName === comp.componentName) === -1) ? [...acc, comp] : acc, []);
    }

    private _navigateToParcelFromRowSelect(row: RowClickedEvent): void {
        const parcel = row.data as ParcelListControlRow;
        const index = row.rowIndex;
        this._entityService.setParcelListScrollIndex(index);
        this.navigateToParcel(parcel.parcelID, parcel);
    }

    private _setDataLoading(disable: boolean): void {
        this.dataLoading = disable;
        if (disable) {
            this.contentTypeToggle.disable();
            this.excludeInactiveParcels.disable();
            this.selectedTaxYear.disable();
        } else {
            this.excludeInactiveParcels.enable();
            this.selectedTaxYear.enable();
            let tooltipText = 'Toggle between Tile and List views';
            if (this.parcelList && this.parcelList.tiles.length > this._maxTileCount) {
                this.contentTypeToggle.setValue(true);
                tooltipText = `Tile view is not available on sites with greater than ${this._maxTileCount} parcels.`;
                this.contentTypeToggle.disable();
            } else {
                this.contentTypeToggle.enable();
            }
            this._helpService.updateContent([{
                helpContentId: 'parcel-list-panel.view-switch',
                tooltipText
            }]);
        }
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const parcel = x.data as ParcelListControlRow;
                return parcel && parcel.parcelID;
            })
        };

        return Promise.resolve(queryResultModel);
    }

    private _warnOnConsolidatedChange(): void {
        this._toastrService.warning('This change will remove Assessments, Bills, Appeals, and Budget from consolidated parcel, and incomplete filings and filings in unlocked batches from merged parcels.');
    }
}
