import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForAllocationDetailListGridAction extends ICellRendererParams {
    canView: (params: ICellRendererParamsForAllocationDetailListGridAction) => boolean;
    viewDetails: (params: ICellRendererParamsForAllocationDetailListGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button *ngIf="allocationDetail && canView" class="ace-btn btn-primary has-icon grid-action-button" title="View Details" (click)="onViewDetailsClicked()">
            <i class="fa fa-pencil"></i>
        </button>`
})

export class AllocationDetailListGridActionCellRendererComponent implements ICellRendererAngularComp {

    allocationDetail: Compliance.AllocationDetailModel;
    canView: boolean = false;

    private _params: ICellRendererParamsForAllocationDetailListGridAction;

    agInit(params: ICellRendererParamsForAllocationDetailListGridAction): void {
        this._params = params;
        this.allocationDetail = params.data as Compliance.AllocationDetailModel;
        this.canView = params.canView(params);
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this._params.viewDetails(this._params);
    }
}
