<div class="card panel-flat-color primary-panel ws-flex-auto m-0 d-flex flex-column">
    <div class="panel-flat-header">
        <div class="float-start">
            <h3 helpTooltip
                helpContentId="filing-batch-accounts.panel"
                position="bottom">
                Filings
                <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
            </h3>
        </div>
        <div class="float-end header-button-container d-flex">
            <div class="action-button-wrapper"
                 helpTooltip
                 [helpContentId]="moveHelpContentId"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text btn-link"
                        *ngIf="canEdit"
                        [disabled]="(!canEnterEditMode) || editMode || isBusy || !(selectedRows && selectedRows.length)"
                        (click)="moveFilings()">
                    <i class="fa fa-arrow-circle-right fa-align"></i> Move Filings
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 [helpContentId]="addHelpContentId"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text btn-link"
                        *ngIf="canEdit"
                        [disabled]="((!editMode) && !canEnterEditMode) || isBusy"
                        (click)="add()">
                    <i class="fa fa-plus-circle fa-align"></i> Add Filings
                    <i *ngIf="isAvailableBusy" class="fa fa-spinner fa-pulse"></i>
                    <span *ngIf="!isAvailableBusy">({{ (availableFilingCount || 0) | number }} available)</span>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 [helpContentId]="editHelpContentId"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-primary"
                        *ngIf="canEdit && !editMode"
                        [disabled]="(!canEnterEditMode) || isBusy"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="app.save"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-primary"
                        *ngIf="editMode"
                        [disabled]="(!hasChanges) || isBusy"
                        (click)="save()">
                    <i class="fa fa-save fa-align"></i>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="app.cancel"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-primary"
                        *ngIf="editMode"
                        (click)="cancel()">
                    <i class="fa fa-times fa-align"></i>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="app.expand"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-secondary"
                        *ngIf="!isExpanded"
                        (click)="expandComponent()">
                    <i class="fa fa-arrows-alt"></i>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="app.collapse"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-secondary"
                        *ngIf="isExpanded"
                        (click)="collapseComponent()">
                    <i class="fa fa-compress"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="panel-flat-body d-flex flex-column h-100 m-0">
    <ws-ag-grid-angular [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [companyId]="companyId"
                        [gridId]="gridId"
                        [isBulkDeleteVisible$]="isBulkDeleteVisible$"
                        (bulkDelete)="bulkDelete()">
    </ws-ag-grid-angular>
    </div>
</div>
