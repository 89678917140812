import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BusyIndicatorModule } from '../../Busy-Indicator/busyIndicator.module';
import { AgGridCheckboxCellRendererComponent } from './CellRenderers/agGridCheckboxCellRender.component';
import { AgGridLinkCellRendererComponent } from './CellRenderers/agGridLinkCellRenderer.component';
import { AgGridFilterToggleComponent } from './FilterToggle/agGridFilterToggle.component';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer } from './MultiSelectTracker';
import { AgGridLayoutSpecificationComponent } from './ToolPanel/LayoutSpecification/agGridLayoutSpecification.component';
import {
    AgGridCurrentLayoutAppliedComponent
} from './ToolPanel/LayoutSpecification/CurrentLayoutApplied/agGridCurrentLayoutApplied.component';
import { AgGridLayoutSpecificationDetailsComponent } from './ToolPanel/LayoutSpecification/Details/agGridLayoutSpecificationDetails.component';
import { AgGridToolPanelComponent } from './ToolPanel/agGridToolPanel.component';
import { AgGridToolPanelColumnsComponent } from './ToolPanel/Columns/agGridToolPanelColumns.component';
import { HelpModule } from '../../UI-Lib/Help-Tooltip/help.module';
import { AgGridDatepickerCellEditorComponent } from './CellEditors/agGridDatePickerCellEditor.component';
import { AgGridDropdownCellEditorComponent } from './CellEditors/agGridDropdownCellEditor.component';
import { SelectModule } from '../../UI-Lib/Select/select.module';
import { AgGridNumberCellEditorComponent } from './CellEditors/agGridNumberCellEditor.component';
import { AgGridCurrencyCellEditorComponent } from './CellEditors/agGridCurrencyCellEditor.component';
import { AgGridTextCellEditorComponent } from './CellEditors/agGridTextCellEditor.component';
import { AgGridTooltipCellRendererComponent } from './CellRenderers/agGridTooltipCellRenderer.component';
import { AgGridRowCountComponent } from './Row-Count/agGridRowCount.component';
import { AgGridTextFilterComponent } from './Filters/Text/agGridTextFilter.component';
import { AgGridTextFloatingFilterComponent } from './FloatingFilters/agGridTextFloatingFilter.component';
import { AgGridNumberFilterComponent } from './Filters/Number/agGridNumberFilter.component';
import { AgGridNumberFloatingFilterComponent } from './FloatingFilters/agGridNumberFloatingFilter.component';
import { DirectivesModule } from '../../UI-Lib/Directives/directives.module';
import { AgGridDateFloatingFilterComponent } from './FloatingFilters/agGridDateFloatingFilter.component';
import { TriStateCheckboxModule } from '../../UI-Lib/Tri-State-Checkbox/triStateCheckbox.module';
import { DateFilterPickerInstanceComponent } from './Filters/Date/dateFilterPickerInstance.component';
import { AgGridDateFilterComponent } from './Filters/Date/agGridDateFilter.component';
import { AgGridHeaderCellRendererComponent } from './ColumnHeader/agGridHeaderCellRenderer.component';
import { AgGridHeaderLegendTooltipComponent } from './ColumnHeader/agGridHeaderLegendTooltip.component';
import { AgGridYesNoFloatingFilterComponent } from './FloatingFilters/agGridYesNoFloatingFilter.component';
import { AgGridTextWithTotalsUpdateCellRendererComponent } from './CellRenderers/agGridTextWithTotalsUpdateCellRenderer.component';
import { AgGridPersistenceService } from './agGridPersistence.service';
import { AgGridYesNoFilterComponent } from './Filters/YesNo/agGridYesNoFilter.component';
import { AgGridActionCellRendererComponent } from './CellRenderers/agGridActionCellRenderer.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        BusyIndicatorModule.forRoot(),
        ModalModule,
        PopoverModule,
        HelpModule,
        SelectModule,
        DirectivesModule,
        TriStateCheckboxModule
    ],
    declarations: [
        AgGridLayoutSpecificationComponent,
        AgGridLayoutSpecificationDetailsComponent,
        AgGridToolPanelComponent,
        AgGridToolPanelColumnsComponent,
        AgGridCheckboxCellRendererComponent,
        AgGridFilterToggleComponent,
        AgGridRowCountComponent,
        AgGridDatepickerCellEditorComponent,
        AgGridDatepickerCellEditorComponent,
        AgGridDropdownCellEditorComponent,
        AgGridNumberCellEditorComponent,
        AgGridCurrencyCellEditorComponent,
        AgGridTextCellEditorComponent,
        AgGridTooltipCellRendererComponent,
        AgGridTextFilterComponent,
        AgGridTextFloatingFilterComponent,
        AgGridNumberFilterComponent,
        AgGridNumberFloatingFilterComponent,
        AgGridDateFloatingFilterComponent,
        AgGridDateFilterComponent,
        DateFilterPickerInstanceComponent,
        AgGridHeaderCellRendererComponent,
        AgGridHeaderLegendTooltipComponent,
        AgGridYesNoFilterComponent,
        AgGridYesNoFloatingFilterComponent,
        AgGridTextWithTotalsUpdateCellRendererComponent,
        AgGridActionCellRendererComponent,
        AgGridMultiSelectedCellRenderer,
        AgGridMultiSelectedHeaderRenderer,
        AgGridLinkCellRendererComponent,
        AgGridCurrentLayoutAppliedComponent
    ],
    providers: [
        AgGridPersistenceService
    ],
    exports: [
        AgGridToolPanelComponent,
        AgGridCheckboxCellRendererComponent,
        AgGridFilterToggleComponent,
        AgGridRowCountComponent,
        AgGridDatepickerCellEditorComponent,
        AgGridDropdownCellEditorComponent,
        AgGridNumberCellEditorComponent,
        AgGridCurrencyCellEditorComponent,
        AgGridTextCellEditorComponent,
        AgGridTooltipCellRendererComponent,
        AgGridTextFilterComponent,
        AgGridTextFloatingFilterComponent,
        AgGridNumberFilterComponent,
        AgGridNumberFloatingFilterComponent,
        AgGridDateFloatingFilterComponent,
        AgGridDateFilterComponent,
        AgGridHeaderCellRendererComponent,
        AgGridYesNoFilterComponent,
        AgGridYesNoFloatingFilterComponent,
        AgGridTextWithTotalsUpdateCellRendererComponent,
        AgGridActionCellRendererComponent,
        AgGridMultiSelectedCellRenderer,
        AgGridMultiSelectedHeaderRenderer,
        AgGridLinkCellRendererComponent,
        AgGridCurrentLayoutAppliedComponent
    ]
})
export class AgGridExtensionsModule {
}
