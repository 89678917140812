<div class="ws-stretch-height">
    <div class="ws-flex-container-vertical ws-stretch-height">
        <div class="card panel-flat-color secondary-panel ws-stretch-height">
            <div class="panel-flat-header"
                 [style.cursor]="isCompany ? 'pointer' : ''"
                 (click)="expandOrCollapse()">
                <panel-chevron [showBody]="showBody" *ngIf="isCompany"></panel-chevron>
                <h3>{{ isCompany ? 'Company' : currentEntity.name }} Characteristics</h3>
                <div class="float-end header-button-container">
                    <!-- margin: -1px is a hack to make sure the buttons stay on the header if the window is narrow -->
                    <button type="button" class="ace-btn btn-text has-icon" style="margin-right: -1px;"
                        (click)="launchChangeHistoryModal($event)" *ngIf="hasInstancePrivateView && (!isCompany || showBody)">
                        <i class="fa fa-history"></i>
                    </button>
                    <!-- button to show form -->
                    <button type="button" class="ace-btn btn-text has-icon btn-primary" style="margin-right: -1px;"
                        (click)="goToEditMode($event)"
                        *ngIf="hasWritePermission && !isEditMode && !editLock && (!isCompany || showBody)">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <span *ngIf="isEditMode">
                        <button type="button" class="ace-btn btn-text has-icon btn-primary btn-success"
                            (click)="save($event)"
                            [disabled]="loading">
                            <i class="fa fa-save"></i>
                        </button>
                        <button type="button" class="ace-btn btn-text has-icon" [disabled]="loading"
                            (click)="cancel($event)">
                            <i class="fa fa-close"></i>
                        </button>
                    </span>
                    <span *ngIf="allowMaximize && !isCompany">
                        <button type="button" class="ace-btn btn-text has-icon btn-secondary"
                            style="margin-right: -1px;" *ngIf="!isMaximized"
                            (click)="maximize()">
                            <i class="fa fa-arrows-alt"></i>
                        </button>
                        <button type="button" class="ace-btn btn-text has-icon btn-secondary"
                            style="margin-right: -1px;" *ngIf="isMaximized"
                            (click)="unmaximize()">
                            <i class="fa fa-compress"></i>
                        </button>
                    </span>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="panel-flat-body" style="max-width: 700px; overflow: auto;"
                [ngClass]="{'site-parcel-list-panel-height': currentEntity.entityTypeId == EntityTypes.Site,
                'panel-flat-body-alt': currentEntity.entityTypeId == EntityTypes.Collector }"
                *ngIf="showBody">
                <div *ngIf="loading" class="text-center" style="margin-top: 50px;">
                    <i class="fa fa-spinner fa-pulse fa-5x"></i>
                </div>
                <div *ngIf="!loading">
                    <div *ngIf="!isEditMode" style="display: flex; justify-content: end; margin-bottom: 15px;">
                        <ws-switch onLabel="Categories" offLabel="Alphabetical" [(ngModel)]="sortByCategory"></ws-switch>
                    </div>
                    <property-characteristics-picker *ngIf="isEditMode"
                        [entityId]="currentEntity.entityId"
                        [entityName]="currentEntity.name"
                        (descriptorsSelected)="descriptorsSelected($event)"
                        [descriptorsToExclude]="descriptorsAlpha">
                    </property-characteristics-picker>
                    <entity-property-characteristics-list *ngIf="!sortByCategory" [descriptors]="descriptorsAlpha" [isEditMode]="isEditMode" [saveAttempted]="saveAttempted"></entity-property-characteristics-list>
                    <accordion *ngIf="sortByCategory" [closeOthers]="false" [isAnimated]="true" class="entity-prop-char-panel">
                        <accordion-group
                            style="margin-bottom: 15px;"
                            *ngFor="let category of descriptorCategories"
                            [(isOpen)]="category.isOpen">
                                <button class="btn-block clearfix" style="border: none;" accordion-heading>
                                    <div class="float-start float-left">
                                        <panel-chevron [showBody]="category.isOpen"></panel-chevron>
                                        {{category.name}}
                                    </div>
                                </button>
                                <entity-property-characteristics-list [descriptors]="category.descriptors" [isEditMode]="isEditMode" [saveAttempted]="saveAttempted"></entity-property-characteristics-list>
                        </accordion-group>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</div>
