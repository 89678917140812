<div class="d-flex quiesce-row" *ngIf="quiescedStatus">

    <div class="d-flex align-items-center">
        <label for="quiescedStatus" class="mb-0">Service status:</label>
        <div [class]="quiescedStatus.isQuiesced ? 'quiesce-paused' : 'quiesce-running'">
            <i class="fa" [ngClass]="{'fa-pause': quiescedStatus.isQuiesced,'fa-play': !quiescedStatus.isQuiesced, 'quiesce-running':quiescedStatus.isQuiesced, 'quiesce-paused': quiescedStatus.isQuiesced}"></i>
            <span id="quiescedStatus" class="ms-1">{{quiescedStatus.isQuiesced ? "Paused" : "Running"}}</span>
        </div>
    </div>

    <div class="d-flex align-items-center">
        <label for="quiescedMessage" *ngIf="quiescedStatus.isQuiesced">Message:</label>
        <span id="quiescedMessage" [class]="quiescedStatus.isQuiesced ? 'quiesce-paused' : ''">{{quiescedStatus.message}}</span>
    </div>

    <div class="d-flex align-items-center">
        <long-running-process-control [canEdit]="canEdit"></long-running-process-control>
        <long-running-process-synthetic [canEdit]="canEdit"></long-running-process-synthetic>
        <button type="button"
                *ngIf="canEdit && !quiescedStatus.isQuiesced"
                class="ace-btn btn-sm btn-primary btn-danger"
                (click)="pause()"
                helpTooltip
                helpContentId="long-running-process-quiesce.pause"
                position="bottom">
            <i class="fa fa-pause"></i> Pause Service
        </button>
        <button type="button"
                *ngIf="canEdit && quiescedStatus.isQuiesced"
                class="ace-btn btn-sm btn-primary btn-success"
                (click)="resume()"
                helpTooltip
                helpContentId="long-running-process-quiesce.resume"
                position="bottom">
            <i class="fa fa-play"></i> Resume Service
        </button>
    </div>

</div>
