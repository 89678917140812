import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseRepository } from '../Common/Data/base.repository';

const SERVICE_URL = '/api/Reporting/';

@Injectable({
    providedIn: 'root'
})
export class ReportProcessIntegrationRepository extends BaseRepository {
    constructor (http : HttpClient) { super(http); }

    downloadReport(processId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpPost(`${SERVICE_URL  }lr/download`, processId, options);
    }
}