import { BusyIndicatorMessage } from './busyIndicatorMessage';

export class BusyIndicatorMessageManager<T> {
    constructor(private readonly _useAutoEllipsis: boolean = false) {}

    /**
     * A formatted message containing all message entries;
     */
    message: string;

    private _messages: BusyIndicatorMessage<T>[] = [];

    /**
     * Adds a message.
     * @param message The message to add.
     * @param id The message identifier.
     */
    add(message: string, id: T): void {
        const item = this._getMessageById(id);
        if (item) {
            throw new Error('A message with the specified identifier exists. Message identifier must be unique.');
        }
        this._messages.push(new BusyIndicatorMessage(id, message));
        this._refreshMessage();
    }

    /**
     * Removes a message.
     * @param id The message identifier.
     */
    remove(id: T): void {
        const item = this._getMessageById(id);
        if (item) {
            this._messages.splice(this._messages.indexOf(item), 1);
            this._refreshMessage();
        }
    }

    /**
     * Updates a messages.
     * @param message The updated messages.
     * @param id The identifier of the message to update.
     */
    update(message: string, id: T): void {
        const item = this._getMessageById(id);
        if (item) {
            item.message = message;
            this._refreshMessage();
        }
    }

    /**
     * Returns a count of all messages in the list.
     */
    count(): number {
        return this._messages.length;
    }

    /**
     * Removes all messages and resets to original state.
     */
    reset(): void {
        let i = this._messages.length;
        while (i--) {
            this._messages.pop();
        }
        this._refreshMessage();
    }

    private _getMessageById(id: T): BusyIndicatorMessage<T> {
        return this._messages.find((i) => i.identifier === id);
    }

    private _refreshMessage(): void {
        let message = '';

        // for each item in the message list
        for (let i = 0; i < this._messages.length; i++) {
            const item = this._messages[i];
            let itemMessage = item.message.trim();

            if (this._useAutoEllipsis) {
                // check if the message already ends with an ellipsis
                const endsWithEllipsis = itemMessage.length >= 3 && itemMessage.endsWith('...');

                // if the last item and it does not end with ellipsis; add ellipsis
                if (i === this._messages.length - 1 && !endsWithEllipsis) {
                    itemMessage += '...';
                }

                // if not the last item and it does have an ellipsis; remove ellipsis
                if (i !== this._messages.length && endsWithEllipsis) {
                    itemMessage = itemMessage.substring(0, itemMessage.length - 3);
                }
            }

            // concatenate message item with the other messages; insert a comma if not the last message
            message += `${itemMessage}${i < this._messages.length - 1 ? ', ' : ''}`;
        }

        this.message = message;
    }


}
