<div class="return-part-content-area ws-flex-container-vertical ws-stretch">

    <div class="ws-section ws-flex-container-vertical ws-flex-auto" *ngIf="!showOutput">
        <span class="return-generation-info">Packages are available once preparation is complete</span>
    </div>

    <div class="ws-section ws-flex-container-vertical ws-flex-auto" *ngIf="showOutput">
        <return-output-package-list></return-output-package-list>
    </div>

    <div class="ws-section ws-flex-none ws-flex-container-vertical" *ngIf="showOutput">
        <return-output-printing-mailing></return-output-printing-mailing>
    </div>

    <div class="float-end" *ngIf="showOutput">
        <button type="button"
                class="ace-btn btn-sm ace-btn btn-primary"
                [disabled]="!packagesSelected || !printingOptionsSelected || disableDownload"
                title="Download Selected Items"
                (click)="download()">
            Download Selected Items
        </button>
    </div>

</div>
