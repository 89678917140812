<ng-template #returnTemplate let-r="data" let-classRef="classRef">
    <div class="account-block" [ngClass]="{ 'active-account-block': selectedReturnId === r.returnId }">
        <div class="account-row account-row-return ws-flex-container-horizontal">
            <div class="ws-flex-auto"
                 helpTooltip
                 [helpContentId]="classRef.returnNotDefaultMap && classRef.returnNotDefaultMap[r.returnId] ? 'return-preview-form-list.return-forms-custom' : 'return-preview-form-list.return'"
                 position="bottom">
                <span class="fa fa-folder"></span>
                <span class="form-list-item" [class.overridden]="classRef.returnNotDefaultMap && classRef.returnNotDefaultMap[r.returnId]">{{ r.parcelAcctNumDisplay }}</span>
            </div>
            <div class="ws-flex-none return-button-container" *ngIf="!editMode">
                <span class="ace-btn btn-link btn-xs py-0" (click)="classRef.downloadReturnDocument(r.returnId)" helpTooltip helpContentId="return-preview-form-list.download" position="bottom">
                    <span class="fa fa-download"></span>
                </span>
                <span *ngIf="classRef.canEdit" class="ace-btn btn-link btn-xs py-0" (click)="classRef.manageReturnFormRevisions(r)" helpTooltip helpContentId="return-preview-form-list.edit-forms" position="bottom">
                    <span class="fa fa-pencil"></span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #formTemplate let-r="data" let-classRef="classRef">
    <div class="account-block" [ngClass]="{ 'active-account-block': selectedReturnId === r.returnId }">
        <div class="account-row account-row-form ws-flex-container-horizontal"
             [ngClass]="{ 'active-account-row':
                                    classRef.selectedReturnId === r.returnId &&
                                    classRef.selectedReturnFormRevisionId === r.form.returnFormRevisionId &&
                                    classRef.selectedReturnFormRevisionReportId === null &&
                                    classRef.selectedMergeParcelId === r.parcelId }">
            <button class="ace-btn btn-link btn-sm account-row-link ws-flex-auto text-nowrap"
                    (click)="classRef.showPreview(r.returnId, r.form.returnFormRevisionId, r.form.formRevisionId, null, null, r.parcelId, false)">
                <span *ngIf="r.form.priorReturnUsedAnotherForm"
                    helpTooltip
                    helpContentId="return-preview-form-list.priorReturnUsedAnotherForm"
                    position="bottom">
                    <i class="fa fa-exclamation-triangle warning-text"></i>
                </span>
                <span *ngIf="r.form.hasElectronicFileType"
                    helpTooltip
                    helpContentId="return-preview-form-list.electronic-filing-is-supported"
                    position="bottom">
                    <i class="fa fa-cloud-arrow-up"></i>
                </span>
                <span helpTooltip
                      helpContentId="app.form"
                      position="bottom">
                    <i class="fa fa-file-pdf-o"></i>
                </span>
                <span class="form-list-item"
                      [ngClass]="{ 'form-primary': r.form.isPrimary || r.formsLength === 1, 'has-override': r.hasOverride }"
                      helpTooltip
                      [helpContentId]="r.hasOverride ? 'return-preview-form-list.preview-form-has-override' : 'return-preview-form-list.preview-form'"
                      position="bottom">
                    {{r.form.formRevisionName}}{{(r.form.isInDevelopment ? ' (DEV)' : '')}}
                    </span>
                <span class="icon-primary" *ngIf="r.form.isPrimary ||  r.formsLength === 1"
                      helpTooltip
                      helpContentId="return-preview-form-list.primary"
                      position="bottom">
                    <i class="fa fa-star"></i>
                </span>
            </button>
            <div class="ws-flex-none return-button-container" *ngIf="!editMode && classRef.hasEditComplianceSetupsPermission">
                <span class="ace-btn btn-link btn-xs py-0" anchor-route
                      [sref]="{ target: 'formPage', options: { formRevisionId: r.form.formRevisionId, taxYear: taxYear } }"
                      [isTargetBlank]="true"
                      helpTooltip
                      helpContentId="return-page.form-setup"
                      position="bottom">
                    <span class="fa fa-external-link"></span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #reportTemplate let-r="data" let-classRef="classRef">
    <div class="account-block" [ngClass]="{ 'active-account-block': selectedReturnId === r.returnId }">
        <div class="account-row account-row-report ws-flex-container-horizontal ws-flex-align-items-center"
             [ngClass]="{ 'active-account-row':
                        classRef.selectedReturnId === r.returnId &&
                        classRef.selectedReturnFormRevisionId === r.form.returnFormRevisionId &&
                        classRef.selectedReturnFormRevisionReportId === r.report.returnFormRevisionReportId &&
                        classRef.selectedMergeParcelId === null }"
             helpTooltip
             [tooltipText]="!classRef.editMode ? 'Preview report' : classRef.getReportSource(r.report)"
             position="bottom">
            <input *ngIf="classRef.editMode"
                   class="ws-flex-none ws-no-margin"
                   type="checkbox"
                   [checked]="r.selected"
                   (click)="classRef.selectReport(r, classRef._refreshFormRevisions.form)">
            <button class="ace-btn btn-link btn-sm account-row-link ws-flex-auto text-nowrap"
                    [ngClass]="{ 'disabled': !r.report.returnFormRevisionReportId }"
                    (click)="classRef.showPreview(r.returnId, r.form.returnFormRevisionId, r.form.formRevisionId, r.report.returnFormRevisionReportId, r.report.savedSearchId, null, r.report.isExcel)">
                <span helpTooltip
                      helpContentId="app.report"
                      position="bottom">
                    <i class="fa"
                       [ngClass]="{ 'fa-file-text-o': !r.report.isExcel, 'fa-file-excel-o': r.report.isExcel }"
                       helpTooltip
                       [helpContentId]="r.report.isExcel ? 'return-preview-form-list.preview-excel-report' : 'return-preview-form-list.preview-report'"
                       position="bottom"></i>
                </span>
                {{r.report.name}}{{(r.report.variantName ? ' - ' + r.report.variantName : '')}}
                </button>
        </div>
    </div>
</ng-template>

<ng-template #mergedTemplate let-r="data" let-classRef="classRef">
    <div class="account-block" [ngClass]="{ 'active-account-block': selectedReturnId === r.returnId }">
        <div class="ws-flex-auto account-row account-row-merged"
             helpTooltip
             [helpContentId]="classRef.returnNotDefaultMap && classRef.returnNotDefaultMap[r.returnId] ? 'return-preview-form-list.return-forms-custom' : 'return-preview-form-list.return'"
             position="bottom">
            <span helpTooltip
                  helpContentId="return-page.merged"
                  position="bottom">
                <i class="fa fa-sitemap"></i>
            </span>
            <span class="form-list-item" [class.overridden]="classRef.returnNotDefaultMap && classRef.returnNotDefaultMap[r.returnId]">{{ r.parcelAcctNumDisplay }}</span>
        </div>
    </div>
</ng-template>

<ws-busy-indicator *ngIf="isBusy" [zIndexOverride]="1040"></ws-busy-indicator>
<div>
    <div class="ws-flex-container-horizontal">
        <div class="ws-flex-auto"
             helpTooltip
             [helpContentId]="formsAndReportsHelpContentId"
             position="bottom">
            <strong>Forms and Reports</strong>
        </div>
        <div class="ws-flex-container-horizontal ws-flex-none">
            <div class="action-button-wrapper"
                 helpTooltip
                 [helpContentId]="editReportsHelpContentId"
                 position="bottom">
                <button type="button"
                        class="ace-btn has-icon btn-primary float-end"
                        *ngIf="canEdit"
                        [disabled]="!(flatReturns.length && canEnterEditMode)"
                        (click)="enterEditMode()">
                    <i class="fa fa-pencil"></i>
                </button>
            </div>
            <button type="button"
                    class="ace-btn has-icon btn-primary float-end"
                    *ngIf="editMode"
                    (click)="saveFormReports()"
                    helpTooltip
                    helpContentId="app.save"
                    position="bottom">
                <i class="fa fa-save fa-align"></i>
            </button>
            <button type="button"
                    class="ace-btn has-icon btn-primary float-end"
                    *ngIf="editMode"
                    (click)="cancelEditMode()"
                    helpTooltip
                    helpContentId="app.cancel"
                    position="bottom">
                <i class="fa fa-times fa-align"></i>
            </button>
            <return-preview-form-overrides *ngIf="!editMode"></return-preview-form-overrides>
        </div>
    </div>
</div>

<div class="ws-position-relative ws-flex-grow form-list" *ngIf="showList" [class.large]="hasLongName">
    <cdk-virtual-scroll-viewport itemSize="26" class="accounts">
        <ng-container *cdkVirtualFor="let r of flatReturns; templateCacheSize: 0">
            <ng-container [ngTemplateOutlet]="getTemplate(r.template)"
                          [ngTemplateOutletContext]="{ data: r, classRef: this }">
            </ng-container>
        </ng-container>
    </cdk-virtual-scroll-viewport>
    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none"></div>
</div>

<div *ngIf="editMode">
    <div class="report-change-controls">
        <div class="checkbox ws-no-margin">
            <label>
                <input type="checkbox" [(ngModel)]="showOnlyFrequentlyUsedReports"> Show only frequently used reports
            </label>
        </div>
        <div class="checkbox ws-no-margin">
            <label>
                <input type="checkbox" [(ngModel)]="applySelectionsToAllReports"> Apply report changes to all forms
            </label>
        </div>
    </div>
</div>
