import { Component, Input, OnInit } from '@angular/core';
import { PaymentBatchService } from '../../paymentBatch.service';
import { PaymentPackage, RecipientType } from 'src/app/Processing/Transmittal/transmittal.models';
import { BusyIndicatorMessageManager, SnackBarService } from 'src/app/Busy-Indicator';
import { UpgradeNavigationServiceHandler } from 'src/app/Common/Routing/upgrade-navigation-handler.service';
import { TransmittalService } from 'src/app/Processing/Transmittal/transmittal.service';
import { Constants } from 'src/app/constants.new';

interface PaymentPackageUI extends Weissman.Model.TaxBills.PaymentPackage {
    feedType?: Core.PaymentBatchFeedTypes
    isDownloading?: boolean;
    paymentCount?: number;
}

@Component({
    selector: 'payment-batch-packages',
    templateUrl: 'paymentBatchPackages.component.html',
    styles: [`

        .packages-table td {
            vertical-align: middle;
        }

    `]
})

export class PaymentBatchPackagesComponent implements OnInit {
    constructor(private readonly _paymentBatchService: PaymentBatchService,
        private readonly _transmittalService: TransmittalService,
        private readonly constants: Constants,
        private readonly _navService: UpgradeNavigationServiceHandler,
        private readonly _snackBarService: SnackBarService) { }

    @Input() paymentBatch: Core.PaymentBatchModel;
    @Input() workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;

    paymentPackages: PaymentPackageUI[] = [];
    RecipientType = RecipientType;
    hoveredRecipient: Weissman.Model.TaxBills.PaymentPackageRecipient;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    readonly recipientTypeMap: {
        [rType: string]: Weissman.Model.RecipientType
    } = {
        responsible: Weissman.Model.RecipientType.ResponsibleParty,
        cc: Weissman.Model.RecipientType.Cc,
        bcc: Weissman.Model.RecipientType.Bcc
    };

    readonly feedTextMap: {
        [paymentBatchFeedType: number]: string
    } = {
        [Core.PaymentBatchFeedTypes.CheckFeed]: 'Check Feed',
        [Core.PaymentBatchFeedTypes.FinalAPFeed]: 'Final AP Feed',
        [Core.PaymentBatchFeedTypes.PrelimAPFeed]: 'Preliminary AP Feed'

    };
    get checkFeed() : number {
        return Core.PaymentBatchFeedTypes.CheckFeed;
    }

    get isTransmittal(): boolean {
        return this.paymentBatch?.batchType === Core.PaymentBatchTypeEnum.Transmittal;
    }

    get awaitingPackageCreation(): boolean {
        return this.isTransmittal && !this.workflowStatus?.isSeriesCompleted;
    }

    async ngOnInit() {
        this.loadData();
    }

    async loadData() {
        if(this.awaitingPackageCreation) {
            return;
        }

        const busyMsg = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsg);

        try {
            this.paymentPackages = await this._getPackages();
            this.paymentPackages.forEach(paymentPackage => {
                paymentPackage.paymentCount = paymentPackage.paymentTransmittals.map(res => res.paymentTransmittalItems.length).reduce((acc, ele) => acc +ele, 0);
            });
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsg);
        }
    }

    getPackageNum(pmtPackage: PaymentPackageUI) {
        return pmtPackage.packageNumber
            ? `${this.constants.TransmittalOutputTypeAbbr[pmtPackage.transmittalOutputType]}-${pmtPackage.packageNumber}`
            : '';
    }

    setHoveredRecipient(recipient: Weissman.Model.TaxBills.PaymentPackageRecipient): void {
        this.hoveredRecipient = recipient;
    }

    navigateToBillPaymentPackages(paymentPackage: PaymentPackageUI) {
        this._navService.go('paymentPackageDetail', {
            companyID: paymentPackage.companyID,
            paymentPackageID: paymentPackage.paymentPackageID,
            paymentBatchId: this.paymentBatch.paymentBatchId
        });
    }

    launchReviewModal(paymentPackage: PaymentPackageUI) {
        this._transmittalService.showPaymentPackageReviewModal(paymentPackage as unknown as PaymentPackage);
    }

    isDownloadDisabled(paymentPackage: PaymentPackageUI) {
        return paymentPackage.isDownloading
            || (paymentPackage.feedType === Core.PaymentBatchFeedTypes.CheckFeed && !this.paymentBatch.userHasElevatedPermission);
    }

    async download(paymentPackage: PaymentPackageUI) {
        paymentPackage.isDownloading = true;

        try {
            const longRunningProcessId = await this._paymentBatchService.getOutputZip(this.paymentBatch.paymentBatchId, [paymentPackage.feedType]);
            this._snackBarService.addById(longRunningProcessId, Compliance.LongRunningProcessTypeEnum.PaymentBatchExportFeedsAndFiles);
        } finally {
            paymentPackage.isDownloading = false;
        }
    }

    private async _getPackages(): Promise<PaymentPackageUI[]> {
        if(this.isTransmittal) {
            return this._paymentBatchService.getTransmittalPaymentPackages(this.paymentBatch.paymentBatchId);
        } else {
            const feedPackages = await this._paymentBatchService.getBillPaymentPackages(this.paymentBatch.paymentBatchId);
            return feedPackages.map(fp => {
                return {
                    ...fp.package[0],
                    feedType: fp.feedType
                };
            });
        }
    }
}
