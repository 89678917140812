<div>
    <ws-modal-wrapper headerLabel="Consultants"
                        [suppressKeyboardEvents]="true"
                        (cancel)="done()"
                        [hideFooter]="true">
        <div class="text-center" style="margin-top: 50px; text-align: center;"  *ngIf="isLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div class="float-end header-button-container" *ngIf="(consultingEngagements.length > 0 || availableConsultants.length > 0) && !isLoading">
            <button class="ace-btn has-icon btn-primary" *ngIf="!isEditMode && !isLoading" (click)="toggleEdit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
        </div>
        <!-- no consultants -->
        <div *ngIf="!isLoading && visualState === 'List' && (consultingEngagements.length === 0 && availableConsultants.length === 0)">
            <h2>There are currently no consultants available</h2>
        </div>
        <!-- consultants table-->
        <table class="table table-hover" *ngIf="!isLoading && visualState === 'List' && (consultingEngagements.length > 0 || availableConsultants.length > 0)">
            <thead>
                <tr>
                    <th>Company</th>
                    <th>Status</th>
                    <th><div *ngIf="this.isEditMode">Actions</div></th>
                </tr>
            </thead>
            <tbody>
                <!-- invited/accepted engagments-->
                <tr *ngFor="let engagement of consultingEngagements">
                    <td>{{engagement.name}}</td>
                    <td>{{getEngagementStatus(engagement)}}</td>
                    <td>
                        <div *ngIf="this.isEditMode">
                            <button *ngIf="!engagement.accepted" class="ace-btn btn-sm ace-btn btn-primary" (click)="cancelEngagement(engagement.consultingEngagementId)">Withdraw Request</button>
                            <button class="ace-btn btn-sm ace-btn btn-primary" (click)="editEngagement(engagement)">Edit Permissions</button>
                            <button *ngIf="engagement.accepted" class="ace-btn btn-sm ace-btn btn-primary" (click)="cancelEngagement(engagement.consultingEngagementId)">Cancel Engagement</button>
                        </div>
                    </td>
                </tr>
                <!-- available consultants-->
                <tr *ngFor="let consultant of availableConsultants">
                    <td>{{consultant.consultantName}}</td>
                    <td></td>
                    <td>
                        <div *ngIf="this.isEditMode">
                            <button class="ace-btn btn-sm ace-btn btn-primary" (click)="invite(consultant)">Invite</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <!--permissions list - for invite confirm and edits-->
        <div *ngIf="!isLoading && visualState !== 'List'">
            <instance-rights-list (editModeEvent)="editModeEventHander($event)" [availableInstanceRights]="availableInstanceRights" [engagement]="selectedEngagement"></instance-rights-list>
            <hr/>
            <div class="float-end">
                <button *ngIf="visualState === 'Invite'" class="ace-btn btn-sm ace-btn btn-primary" (click)="confirmInvite()">Confirm Invite</button>
                <button *ngIf="visualState === 'Edit'" class="ace-btn btn-sm ace-btn btn-primary" (click)="updatePermissions()" [disabled]="permsBeingEdited">Update Permissions</button>
                <button class="ace-btn btn-sm ace-btn btn-primary" (click)="cancel()" [disabled]="permsBeingEdited">Cancel</button>
            </div>
        </div>
        <div *ngIf="visualState === 'List'" class="float-end">
            <button class="ace-btn btn-primary" (click)="done()" tabindex="0">
                Close
            </button>
        </div>
    </ws-modal-wrapper>
</div>
