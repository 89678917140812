<ws-busy-indicator *ngIf="isBusy"></ws-busy-indicator>
<div *ngIf="schedule"
    (click)="onScheduleClicked($event)"
     [ngClass]="{ 'active-schedule': isScheduleActive(), 'inactive-schedule': scheduleFilterId && !isScheduleActive() }">
    <div class="toggle-switch" *ngIf="canExpand">
        <button class="ace-btn btn-link btn-sm" title="Expand" *ngIf="!isExpanded" (click)="toggleIsExpanded($event)">
            <i class="fa fa-plus"></i>
        </button>
        <button class="ace-btn btn-link btn-sm" title="Collapse" *ngIf="isExpanded" (click)="toggleIsExpanded($event)">
            <i class="fa fa-minus"></i>
        </button>
    </div>
    <div class="header" *ngIf="schedule.formRevisionScheduleId && !scheduleForNonReportableAssets">
        <div class="schedule-title">{{ schedule.name }} {{ schedule.description ? '(' + schedule.description + ')' : '' }}</div>
        <div class="factor-table-title small" *ngIf="schedule.depreciationTableId">{{ schedule.depreciationTableName}} ({{ schedule.depreciationTableLife }} year life)</div>
    </div>
    <div class="form-values values-summary" *ngIf="!isExpanded">
        <div class="form-value-group">
            <div class="form-value-label">Assets</div>
            <div class="form-value">{{ (schedule.assetCount || 0) | number }}</div>
        </div>
        <div class="form-value-group">
            <div class="form-value-label">Cost</div>
            <div class="form-value">{{ (schedule.cost || 0) | currency: 'USD' : 'symbol' }}</div>
        </div>
        <div class="form-value-group">
            <div class="form-value-label"><span>Value</span></div>
            <div class="form-value">
                <span *ngIf="schedule.isReportable">{{ (schedule.reportedValue || 0) | currency: 'USD' : 'symbol' }}</span>
                <span *ngIf="!schedule.isReportable">N/A</span>
            </div>
        </div>
    </div>
    <div class="form-values values-detail" *ngIf="isExpanded">
        <table class="table table-hover table-condensed ws-no-margin">
            <thead>
                <tr>
                    <th>Sch. Year</th>
                    <th class="ws-text-align-right">Assets</th>
                    <th class="ws-text-align-right">Cost</th>
                    <th *ngIf="schedule.depreciationTableId">Factor</th>
                    <th class="ws-text-align-right">Rep. Value</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detail of scheduleDetails"
                    (click)="onScheduleDetailClicked($event, detail)"
                    [ngClass]="{ 'active-factor': isScheduleDetailActive(detail) }">
                    <td>{{ detail.year }}</td>
                    <td class="ws-text-align-right">{{ detail.assetCount | number }}</td>
                    <td class="ws-text-align-right">{{ (detail.cost || 0) | currency: 'USD' : 'symbol' }}</td>
                    <td *ngIf="schedule.depreciationTableId">{{ (detail.depreciationFactor * 100) | number: '1.2-5' }}</td>
                    <td class="ws-text-align-right">{{ (detail.reportedValue || 0) | currency: 'USD' : 'symbol' }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr class="ws-font-weight-bold">
                    <td>Total</td>
                    <td class="ws-text-align-right">{{ (schedule.assetCount || 0) | number }}</td>
                    <td class="ws-text-align-right">{{ (schedule.cost || 0) | currency: 'USD' : 'symbol' }}</td>
                    <td *ngIf="schedule.depreciationTableId"></td>
                    <td class="ws-text-align-right">{{ (schedule.reportedValue || 0) | currency: 'USD' : 'symbol' }}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>
