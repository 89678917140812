import { Injectable, ComponentFactoryResolver, Injector, ComponentRef, EmbeddedViewRef } from '@angular/core';
import { AceSelectDropdownComponent } from './Select-Dropdown/dropdown.component';
import { SelectConfig } from './select.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TimerService } from '../../Utilities';

@Injectable({ providedIn: 'root' })
export class AceSelectService {
    constructor(
        private readonly _componentFactoryResolver: ComponentFactoryResolver,
        private readonly _injector: Injector,
        private readonly _timer: TimerService
    ) { }

    dropdownActive: boolean;
    dropdownOpen: boolean;

    private _dropdownRef: ComponentRef<AceSelectDropdownComponent>;
    private _dropdown: HTMLElement;

    private _currentDropdown: SelectConfig;

    private _targetBounds: DOMRect;
    private _closingInterval;
    private _destroy$: Subject<void> = new Subject();

    /**
     * Open the dropdown
     * @param selectConfig
     * @param position
     */
    openDropdown(selectConfig: SelectConfig, position: DOMRect): void {
        if (this._currentDropdown) {
            this.closeDropdown(() => {
                this._currentDropdown = selectConfig;
                this._targetBounds = position;
                this._showDropdown();
            });
        } else {
            this._currentDropdown = selectConfig;
            this._targetBounds = position;
            this._showDropdown();
        }
    }

    /**
    * Close the dropdown
    * Timed to allow for fade-out animation
    * @param callback
    */
    closeDropdown(callback?: any): void {
        this._dropdownRef.instance.isOpen = false;
        this._destroy$.next();
        this._dropdownRef.changeDetectorRef.detectChanges();

        if (this._closingInterval) {
            clearTimeout(this._closingInterval);
        }
        this._closingInterval = this._timer.setTimeout(() => {
            this.resetDropdown();
            if (callback) {
                callback();
            }
            this.dropdownOpen = false;
            this._closingInterval = null;
        }, 150);
    }

    /**
     * Update the position for scroll and resize events
     */
    updatePosition(selectConfig: SelectConfig, position: DOMRect): void {
        if (this._currentDropdown === selectConfig) {
            this._targetBounds = position;
            this._dropdownRef.instance.targetPosition = this._targetBounds;
            this._dropdownRef.changeDetectorRef.detectChanges();
        }
    }

    /**
     * Update the list if the options change while it is open
     */
    optionsChanged(): void {
        if (this._dropdownRef) {
            this._dropdownRef.changeDetectorRef.detectChanges();
        }
    }

    /**
    * Reset the dropdown component values
    */
    resetDropdown(): void {
        this._currentDropdown = null;
        this._dropdownRef.instance.targetPosition = {
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
            height: 0,
            width: 0,
            x: 260, // allows left pre-render for positioning calculations
            y: 0,
            toJSON: null
        };

        this._dropdownRef.changeDetectorRef.detectChanges();
    }

    /**
    * Update the dropdown with new values
    */
    private _showDropdown(): void {
        if (!this.dropdownActive) {
            if (!this._dropdown) {
                this._createDropdown();
            }
            this.dropdownActive = true;
            document.body.appendChild(this._dropdown);
        }
        // Init blank component
        this._dropdownRef.instance.dropdownConfig = this._currentDropdown;
        this._dropdownRef.changeDetectorRef.detectChanges();

        this._dropdownRef.instance.targetPosition = this._targetBounds;
        this._dropdownRef.changeDetectorRef.detectChanges();

        this._dropdownRef.instance.isOpen = true;
        this._dropdownRef.changeDetectorRef.detectChanges();
        this.dropdownOpen = true;

        this._dropdownRef.instance.closed
            .pipe(takeUntil(this._destroy$))
            .subscribe((config) => config === this._currentDropdown && this.closeDropdown());
    }

    /**
    * Create the dropdown DOM element
    */
    private _createDropdown(): void {
        const componentFactory = this._componentFactoryResolver.resolveComponentFactory(AceSelectDropdownComponent);
        this._dropdownRef = componentFactory.create(this._injector);

        this._dropdown = (this._dropdownRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        this._dropdownRef.changeDetectorRef.detectChanges();
    }
}
