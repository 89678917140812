import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EntityClientService, DRTeamRetrievesWPDeliveryDetail } from '../../clientServices.model';
import * as _ from 'lodash';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { CompanyService } from '../../../Entity/Company/company.service';

@Component({
    selector: 'delivery-details-assessment-allocation',
    templateUrl: './deliveryDetailsAssessmentAllocation.component.html',
    styleUrls: ['./deliveryDetailsAssessmentAllocation.component.scss']
})
export class DeliveryDetailsAssessmentAllocationComponent implements OnInit {
    constructor(private _companyService: CompanyService){}

    @Input() editMode: boolean;
    @Input() entity: CompanyEntity;
    @Input() currentData: DRTeamRetrievesWPDeliveryDetail;
    @Input() ces: Core.ConsultingEngagementResponse[];
    @Input() service: EntityClientService;
    @Input() entityInstanceId: number;
    @Input() isConsultantPerspective: boolean;
    @Input() userSearchInstanceId: number;

    @Output() currentDataChange: EventEmitter<any> = new EventEmitter();

    ngOnInit() {
        this.currentData = this.currentData || {} as DRTeamRetrievesWPDeliveryDetail;
        this.currentDataChange.emit(this.currentData);
    }

    userChanged(newUser: Core.UserTeamModel): void {
        if(newUser){
            this._updateAllocationReviewer(newUser);
        }else{
            this._removeAllocationReviewer();
        }

        this.currentDataChange.emit(this.currentData);
    }

    allowEdit() {
        return this.entity.type === 'company' && this.editMode && this._companyService.isTopLevel();
    }

    getDisplayName(user: Core.UserTeamModel, team: Weissman.Model.Workflow.Team): string {
        //consultant person assigned - show consulting company name if not consultant viewing
        if(user && team){
            if (team.instanceID !== this.entityInstanceId && this.isConsultantPerspective === false) {
                //look up consultant's name
                const consultantName = this.ces.find(ce => ce.instanceId === team.instanceID)?.name;
                return consultantName ? `Consultant- ${consultantName}` : '';
            }
            //client person assigned - show person's name
            else if (user.lastName || user.firstName) {
                return `${user.lastName}, ${user.firstName} (${team.name})`;
            }
        }

        return '';
    }

    private _removeAllocationReviewer(): void {
        this.currentData.allocationReviewerUser = null;
        this.currentData.allocationReviewerTeam = null;
        this.currentData.allocationReviewerUserID = null;
        this.currentData.allocationReviewerTeamID = null;
    }

    private _updateAllocationReviewer(newUser: Core.UserTeamModel): void{
        this.currentData.allocationReviewerUserID = newUser.userID;
        this.currentData.allocationReviewerTeamID = newUser.teamID;

        this.currentData.allocationReviewerTeam = {
            name: newUser.teamName,
            teamID: newUser.teamID
        } as Weissman.Model.Workflow.Team;

        this.currentData.allocationReviewerUser = newUser;
    }
}
