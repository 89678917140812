<div class="modal-header">
    <h4 class="modal-title">Responsibility Setup Exceptions</h4>
</div>
<div>
    <div class="modal-body">
        <div class="text-center" style="margin-top: 50px; text-align: center;" *ngIf="isLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>
        <div *ngIf="!isLoading">
            <div class="exception-details" style="margin-top:-28px;">
                <div class="exception-details-breadcrumbs">
                    <button class="ace-btn btn-text icon-dark"
                            *ngIf="clientServiceExceptions.parentEntityId"
                            (click)="changeEntityContext(clientServiceExceptions.parentEntityId, clientServiceExceptions.parentEntityTypeId)"
                            style="padding: 5px;">
                        <i class="fa fa-arrow-up"></i>
                    </button>
                    {{ clientServiceExceptions.entityName }}
                    <button class="float-end ace-btn btn-text icon-dark" style="padding:5px;" (click)="initModal()">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
                <div class="exception-details-filter" style="margin-bottom: 8px;">
                    <div style="width: 47%; display: inline-block">
                        <label>Client Service:</label><br />
                        <select multiple style="width: 100%;" (change)="clientServicesChanged($event.target)">
                            <option *ngFor="let clientService of clientServicesTypes" [selected]="clientServiceIds.includes(clientService.id)" [value]="clientService.id">{{ clientService.name }}</option>
                        </select>
                    </div>

                    <div style="width: 44%; position: absolute; margin-left: 19px; display: inline-block">
                        <label>Property Type:</label><br />
                        <select multiple style="width: 100%;" (change)="propertyTypesChanged($event.target)">
                            <option *ngFor="let property of propertyTypes" [selected]="propertyTypeIds.includes(property.id)" [value]="property.id">{{ property.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="card panel-flat-color primary-panel small-panel" style="margin-bottom: 8px; max-height: 300px;">
                <div class="panel-flat-header small-header" style="color: var(--ace-neutral-700);">
                    <h3>{{ clientServiceExceptions.entityName }} Info</h3>
                </div>
                <div class="panel-flat-body" style="min-height: unset; height: 70px; max-height: 70px;">
                    <table style="width: 100%">
                        <thead>
                            <tr>
                                <th>Client Service</th>
                                <th>Property Type</th>
                                <th>Responsibility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let responsibility of clientServiceExceptions.entityResponsibilities">
                                <td>{{ responsibility.clientServiceName }}</td>
                                <td>{{ responsibility.propertyTypeName }}</td>
                                <td>{{ getAssigneeLabelForResponsibility(responsibility) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card panel-flat-color primary-panel small-panel">
                <div class="panel-flat-header small-header" style="color: var(--ace-neutral-700); position: relative;">
                    <h3>Overrides (Exceptions)</h3>
                    <span class="float-end include-all-switch" *ngIf="clientServiceExceptions.isTopLevel || clientServiceExceptions.entityTypeId === EntityTypes.Company">
                        <ws-switch [(ngModel)]="includeAll"
                                   (selectedOptionChanged)="initModal()">
                        </ws-switch> Include All
                    </span>
                </div>
                <div class="panel-flat-body" style="max-height: 350px; padding: 10px 8px;">
                    <table style="border-collapse: separate; border-spacing: 0px 8px; width: 100%;">
                        <thead>
                            <tr>
                                <th style="width: 25px; padding-left: 6px;">
                                    <input type="checkbox"
                                        *ngIf="!isInactive"
                                        [indeterminate]="selectedCount > 0 && selectedCount < clientServiceExceptions.children.length"
                                        [checked]="selectedCount === clientServiceExceptions.children.length"
                                        (click)="toggleAllResponsibilitySelections()" />
                                </th>
                                <th style="width: 18%">Company Name</th>
                                <th style="width: 18%">Site Name</th>
                                <th style="width: 18%">Parcel Name</th>
                                <th style="width: 30px;">State</th>
                                <th style="width: 18%">Client Service</th>
                                <th style="width: 18%">Responsibility</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let exception of clientServiceExceptions.children"
                                (click)="toggleResponsibilitySelection(exception)"
                                [style.background-color]="exception.isSelected ? '#ecf5ff' : 'white'">
                                <td style="padding-left: 6px;">
                                    <input type="checkbox" [(ngModel)]="exception.isSelected" />
                                </td>
                                <td>
                                    <span class="anchor-style" (click)="navigateToEntity(exception, EntityTypes.Company)">
                                        {{ exception.companyName }}
                                    </span>
                                </td>
                                <td>
                                    <span ng-if="exception.siteName">
                                        <span class="anchor-style" (click)="navigateToEntity(exception, EntityTypes.Site)">
                                            {{ exception.siteName }}
                                        </span>
                                    </span>
                                    <span *ngIf="!exception.siteName">--</span>
                                </td>
                                <td>
                                    <span *ngIf="exception.parcelAcctNum">
                                        <span class="anchor-style" (click)="navigateToEntity(exception, EntityTypes.Parcel)">
                                            {{ exception.parcelAcctNum }}
                                        </span>
                                    </span>
                                    <span *ngIf="!exception.parcelAcctNum">--</span>
                                </td>
                                <td>
                                    {{ exception.state }}
                                </td>
                                <td>
                                    {{exception.clientServiceName}}
                                </td>
                                <td style="padding-right: 6px;">
                                    <span>
                                        {{ getAssigneeLabelForChildResponsibility(exception) }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
    <div class="modal-footer justify-content-between" *ngIf="!isLoading">
        <div>
            <div *ngIf="selectedCount > 0 && canEditCompanySetup">
                <button class="ace-btn btn-primary has-icon"
                        style="margin-left: 42px; margin-top: 6px;"
                        [popover]="assignmentTemplate"
                        [isOpen]="showPopover"
                        placement="top"
                        #assignmentPopover="bs-popover"
                        [outsideClick]="true">
                    <i class="fa fa-user"></i>
                </button>
                <label *ngIf="showDatePicker" style="width:140px; vertical-align: middle; margin-top:5px; padding-left: 10px; margin-right: 20px;">
                    <weissman-datetime [(inputDate)]="setDateFrom"
                                       [dateOnly]="true"
                                       [readOnly]="false"
                                       [direction]="'up'">
                    </weissman-datetime>
                </label>
                <button class="ace-btn btn-primary btn-success has-icon"
                        tooltip="Apply Changes"
                        (click)="applyChanges()"
                        [disabled]="showDatePicker && !setDateFrom"
                        *ngIf="!assignmentSaved && (updateWorkflowType >= 0 || (updateWorkflowType === CONSULTING_ENGAGEMENT && consultingEngagementId))">
                    <i class="fa fa-save"></i>
                </button>
            </div>
        </div>
        <button class="ace-btn btn-secondary" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>

<ng-template #assignmentTemplate>
    <!-- account handler option-->
    <div class="radio">
        <label>
            <input type="radio" (change)="changeAssignmentSelection()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['ACCOUNTHANDLER'].id"> Account Handler
        </label>
    </div>

	<!--jurisdiction specialist (only available inside ryan instance)-->
    <div class="radio" *ngIf="isCurrentInstanceRyan">
        <label>
            <input type="radio" (change)="changeAssignmentSelection()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['JURISDICTIONSPECIALIST'].id" > Jurisdiction Specialist
        </label>
    </div>

    <!-- consultant option (only for for consulting clients) -->
	<div class="radio" *ngIf="isConsultingClient() && consultingEngagements && consultingEngagements.length">
		<label>
			<input type="radio" (change)="consultingEngagements.length === 1 ? changeConsultingEngagement(consultingEngagements[0].consultingEngagementId) : changeAssignmentSelection()" [(ngModel)]="updateWorkflowType" [value]="CONSULTING_ENGAGEMENT"> Consultant
            <!-- only one consultant available - just show their name -->
			<span *ngIf="consultingEngagements.length === 1"> - {{consultingEngagements[0].name}}</span>
		</label>
        <!--more than one consultant available - show dropdown for user to select-->
		<div *ngIf="consultingEngagements.length > 1" class="consultant-selector">
			<select name="consultingEngagement"
					class="form-select form-select-sm"
					style="width: auto;"
                    [disabled]="updateWorkflowType >= 0 || !updateWorkflowType"
					(change)="changeConsultingEngagementFromSelect($event.target)"
					popover-trigger="mouseenter">
                    <option value="null" selected></option>
                    <option *ngFor="let ce of consultingEngagements" [value]="ce.consultingEngagementId">{{ ce.name }}</option>
			</select>
		</div>
	</div>

    <!-- specific individual -->
    <div class="radio">
        <label>
            <input type="radio" (change)="changeAssignmentSelection()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['USER'].id"> Specific Individual
        </label>
        <div>
            <user-team-picker
                [user]="updateUser"
                (userChange)="setUser($event)"
                [entityTypeScope]="'Instance'"
                [entityIdScope] = "instanceId">
            </user-team-picker>
        </div>
    </div>

    <!--N/A option-->
    <div class="radio">
        <label>
            <input type="radio" (change)="changeAssignmentSelection()" [(ngModel)]="updateWorkflowType" [value]="WorkflowUserTypes['NA'].id"> N/A
        </label>
    </div>
</ng-template>

