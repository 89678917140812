<div *ngIf="template" class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-flex-auto ws-truncate">
            States
            <ag-grid-row-count *ngIf="template.states.length"
                               [gridOptions]="gridOptions"
                               [cssClass]="null">
            </ag-grid-row-count>
        </h3>
        <div class="ws-flex-none header-button-container">
            <button *ngIf="editMode && canEdit"
                    title="Add"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="addNewState()">
                <i class="fa fa-plus-circle fa-align"></i>
            </button>
            <button *ngIf="!editMode && canEdit"
                    title="Edit"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    [disabled]="!canEnterEditMode"
                    (click)="edit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
            <button *ngIf="editMode && canEdit"
                    title="Close"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="cancel()">
                <i class="fa fa-times fa-align"></i>
            </button>
            <button *ngIf="editMode && canEdit && hasSelectedRows"
                    title="Bulk Remove Assessors"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="bulkRemove()">
                <i class="fa fa-trash fa-align"></i>
            </button>
            <ag-grid-filter-toggle *ngIf="template.states.length" [gridOptions]="gridOptions"></ag-grid-filter-toggle>
            <button type="button"
                    class="ace-btn btn-text has-icon btn-secondary"
                    *ngIf="!isExpanded"
                    (click)="expandComponent()">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button type="button"
                    class="ace-btn btn-text has-icon btn-secondary"
                    *ngIf="isExpanded"
                    (click)="collapseComponent()">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body ws-flex-container-vertical">
        <ws-ag-grid-angular [hidden]="!template.states.length"
                            [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
        <div *ngIf="!template.states.length"
             class="ws-stretch ws-flex-container-horizontal ws-flex-align-items-center ws-flex-justify-content-center">
            <h4>National</h4>
        </div>
    </div>
</div>

