import { lastValueFrom } from 'rxjs';
import { EntityTypeIds } from '../constants.new';
import { Injectable } from '@angular/core';
import { CommentsRepository, SendTaskCommentsBody } from '../Core-Repositories/comments.repository';

export class CommentModalData {
    entityID: number;
    entityTypeID: number;
    commentID?: number;
    commentTypeID?: number;
    parcelAcctNum?: string;
    parcelID?: number;
    canEdit: boolean;
    description: string;
    year?: string;
    isPinned?: boolean;
    disableYearSelection?: boolean;
    disableCommentTypeSelection?: boolean;
}

export interface CommentModalResult {
    commentCount: number;
    pinnedComment?: string;
}

export interface AddCommentModalData {
    allowBlank: boolean;
    commentBody: string;
    defaultYear: string;
    processResult: (string) => Promise<void>;
    readOnly: boolean;
    showFull: boolean;
    title: string;
    yearIsRelevant: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class CommentsService {
    constructor(
        private readonly _commentsRepository: CommentsRepository
    ) {}

    fetch(filter): Promise<any> {
        return lastValueFrom(this._commentsRepository.fetch(filter));
    }

    createComment(comment): Promise<any> {
        return lastValueFrom(this._commentsRepository.createComment(comment));
    }

    editComment(comment): Promise<any> {
        return lastValueFrom(this._commentsRepository.editComment(comment));
    }

    deleteComment(commentId: number): Promise<any> {
        return lastValueFrom(this._commentsRepository.deleteComment(commentId));
    }

    sendTaskComments(taskIDs, comment, entityYearOverrides): Promise<any> {
        const update = {
            taskIDs: taskIDs,
            comment: comment,
            entityYearOverrides: entityYearOverrides
        } as SendTaskCommentsBody;
        return lastValueFrom(this._commentsRepository.sendTaskComments(update));
    }

    getEntityDataForTasks(taskIds: number[]): Promise<any> {
        return lastValueFrom(this._commentsRepository.getEntityDataForTasks(taskIds));
    }

    getEntityData(entityId: number, entityTypeId: EntityTypeIds): Promise<any> {
        return lastValueFrom(this._commentsRepository.getEntityData(entityId, entityTypeId));
    }

    yearIsRelevantForEntityTypeID(entityTypeId: EntityTypeIds): boolean {
        return ![EntityTypeIds.INTAKE_ITEM, EntityTypeIds.CONTACT, EntityTypeIds.PAYMENT_BATCH].some(x => x === entityTypeId);
    }

    // This checks if an entity has comments. Usually we don't want to make a separate API call
    // to make that determination, but at least on document processing there isn't a more logical
    // place to add a hasComments flag on an API call result
    checkForComments(entityTypeId: EntityTypeIds, entityId: number): Promise<any> {
        return lastValueFrom(this._commentsRepository.checkForComments(entityTypeId, entityId));
    }

    getAvailableCommentTypesForEntityType(entityTypeId: EntityTypeIds): Promise<any[]> {
        return this._commentsRepository.getAvailableCommentTypesForEntityType(entityTypeId);
    }

    pinComment(commentId: number): Promise<any> {
        return lastValueFrom(this._commentsRepository.pinComment(commentId));
    }

    unpinComment(commentId: number): Promise<any> {
        return lastValueFrom(this._commentsRepository.unpinComment(commentId));
    }
}
