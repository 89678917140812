<ws-busy-indicator *ngIf="isBusy"></ws-busy-indicator>
<table *ngIf="data" class="table table-striped table-condensed table-borderless-body">
    <thead>
        <tr>
            <th></th>
            <th class="ws-text-align-right">Prior Return</th>
            <th class="ws-text-align-right">Additions</th>
            <th class="ws-text-align-right">Disposals</th>
            <th class="ws-text-align-right">Current Return</th>
            <th class="ws-text-align-right">Net Change</th>
            <th class="ws-text-align-right">% Change</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let schedule of reportableSchedules">
            <td>{{ schedule.scheduleName }}</td>
            <td class="ws-text-align-right">{{ schedule.priorCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnTotals(schedule, true, false, false, false)">{{ schedule.additions | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnDisposals(schedule, true, false)">{{ schedule.disposals | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnTotals(schedule, false, false, false, false)">{{ schedule.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">{{ schedule.netChange | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ schedule.percentChange === null ? '' : (schedule.percentChange | percent: '1.2') }}</td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Reported Cost</td>
            <td class="ws-text-align-right">{{ data.totalReportable.priorCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right ws-font-weight-bold">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnTotals(null, true, false, true, false)">{{ data.totalReportable.additions | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right ws-font-weight-bold">
                <button class="ace-btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToReturnDisposals(null, true, false)">{{ data.totalReportable.disposals | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button class="ace-btn btn-link ws-no-padding ws-vertical-align-top ws-font-weight-bold" (click)="navigateToReturnTotals(null, false, false, true, false)">{{ data.totalReportable.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">{{ data.totalReportable.netChange | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.totalReportable.percentChange === null ? '' : (data.totalReportable.percentChange | percent: '1.2') }}</td>
        </tr>
        <tr>
            <td colspan="6">&nbsp;</td>
        </tr>
        <tr>
            <td>Not Reported Cost</td>
            <td class="ws-text-align-right">{{ data.totalNonReportable.priorCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnTotals(null, true, true, false, false)">{{ data.totalNonReportable.additions | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnDisposals(data.totalNonReportable, false, true)">{{ data.totalNonReportable.disposals | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnTotals(null, false, true, false, false)">{{ data.totalNonReportable.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">{{ data.totalNonReportable.netChange | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.totalNonReportable.percentChange === null ? '' : (data.totalNonReportable.percentChange | percent: '1.2') }}</td>
        </tr>
        <tr class="ws-font-weight-bold">
            <td>Total Cost</td>
            <td class="ws-text-align-right">{{ data.total.priorCost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right ws-font-weight-bold">{{ data.total.additions | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.total.disposals | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.total.cost | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.total.netChange | currency: 'USD' : 'symbol' }}</td>
            <td class="ws-text-align-right">{{ data.total.percentChange === null ? '' : (data.total.percentChange | percent: '1.2') }}</td>
        </tr>
        <tr *ngIf="data.notAssignedToASchedule.cost || data.notAssignedToASchedule.disposalsCost">
            <td>Not Assigned to a Schedule</td>
            <td class="ws-text-align-right"></td>
            <td class="ws-text-align-right"></td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnDisposals(null, false, false)">{{ data.notAssignedToASchedule.disposalsCost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right">
                <button [disabled]="!canNavigate" class="ace-btn btn-link ws-no-padding ws-vertical-align-top" (click)="navigateToReturnTotals(null, false, false, true, false)">{{ data.notAssignedToASchedule.cost | currency: 'USD' : 'symbol' }}</button>
            </td>
            <td class="ws-text-align-right"></td>
            <td class="ws-text-align-right"></td>
        </tr>
    </tbody>
</table>
