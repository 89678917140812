import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HotkeyService } from '../../../UI-Lib/HotKeys/hotKeys.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { CompanyService } from '../../../Entity/Company/company.service';
import { DecimalPipe } from '@angular/common';
import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes';
import { BusyIndicatorRef, BusyIndicatorService } from '../../../Busy-Indicator';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import {
    MessageBoxButtons,
    MessageBoxOptions,
    MessageBoxResult,
    MessageBoxService
} from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { SiteRepository } from '../../../Core-Repositories';
import { ParcelService } from '../../../Entity/Parcel/parcel.service.upgrade';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { IncomeStatementAgGridDataSource, IncomeStatementDataSourceParams } from './agGridDataSource';
import { BehaviorSubject, lastValueFrom, Subject, Subscription } from 'rxjs';
import { BreadCrumb } from '../../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import {
    AgGridColumns,
    AgGridDropdownCellEditor,
    AgGridFilterParams, AgGridNumberCellEditor,
    AgGridOptionsBuilder
} from '../../../Compliance/AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../../Compliance/AgGrid/ToolPanel/models';
import * as _ from 'lodash';
import {
    AgGridLinkCellRendererComponent,
    AgGridLinkCellRendererParams
} from '../../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { takeUntil } from 'rxjs/operators';
import { IncomeStatementRepository } from '../incomeStatement.repository';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import {
    DeleteConfirmationComponent,
    DeleteConfirmationComponentParams
} from '../../../Common/Delete-Confirmation/deleteConfirmation.component';
import {
    AgGridActionCellRendererComponent,
    AgGridActionCellRendererParams
} from '../../../Compliance/AgGrid/CellRenderers/agGridActionCellRenderer.component';
import { GLAccountService } from '../../../Compliance/GL-Account/glAccount.service';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { WeissmanDateFormatPipe } from '../../../UI-Lib/Pipes';
import {
    AgGridYesNoFloatingFilterComponent
} from '../../../Compliance/AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';
import { ICellEditorParams } from 'ag-grid-community/dist/lib/interfaces/iCellEditor';
import { BulkEditButtonOptions } from '../../../Compliance/AgGrid/ToolPanel/agGridToolPanel.component';
import {
    AppealRecommendationCommandCenterBulkUpdateParams,
    ARCCBulkUpdateComponent
} from '../../../Appeal-Recommendation/ARCC/ARCC-Bulk-Update/aRCCBulkUpdate.component';
import {
    AppealRecommendationCommandCenterBulkUpdateResultConfirmationComponentParams,
    ARCCBulkUpdateResultConfirmationComponent
} from '../../../Appeal-Recommendation/ARCC/ARCC-Bulk-Update/ARCC-Bulk-Update-Result-Confirmation/aRCCBulkUpdateResultConfirmation.component';
import {
    IncomeStatementBulkUpdateComponent, IncomeStatementBulkUpdateParams
} from '../Income-Statement-Bulk-Update/incomeStatementBulkUpdate.component';
import { CellEditorValidationResult } from '../../../Compliance/AgGrid/CellEditors/validator.interface';

@Component({
    selector: 'income-statement-list',
    templateUrl: './incomeStatementList.component.html',
    styleUrls: ['./incomeStatementList.component.scss'],
    providers: [HotkeyService]
})
export class IncomeStatementListComponent implements OnInit{
    constructor(
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _incomeStatementRepository: IncomeStatementRepository,
        private readonly _companyService: CompanyService,
        private readonly _decimalPipe: DecimalPipe,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _modalService: WeissmanModalService,
        private readonly _restrictService: RestrictService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _siteService: SiteRepository,
        private readonly _parcelService: ParcelService,
        private readonly _messageModalService: MessageModalService,
        private readonly _glAccountService: GLAccountService,
        private readonly _datePipe: WeissmanDateFormatPipe
    ) { }

    @Input() companyIdFilter: number;
    @Input() siteIdFilter: number;
    @Input() parcelIdFilter: number;
    @Input() isEmbededMode: boolean = false;
    @Input() onLoading: boolean;
    @Output() onLoadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    breadcrumbs: BreadCrumb[] = [];
    selectedTotalsLoading: boolean = true;
    companyId: number;
    newCompanyId: number;
    companyName: string;
    canEdit: boolean = false;
    canViewRyanPrivateItems: boolean = false;
    isInitialized: boolean = false;
    gridId: System.Guid = 'D705A9AA-2ACE-4831-82FE-09A712A451B7';
    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    busyRefId: string = this._busyIndicatorService.generateUniqueMessageIdentifier();
    filterHref: string;
    siteName: string;
    parcelName: string;
    selectedEndDate: Date;
    endDates: Date[];
    currentTotals: Core.IncomeStatementLineSearchTotalsModel;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Core.IncomeStatementLineModel).incomeStatementLineId),
                'totals-row': (params) => params.data && params.data.totalsRow,
                'selected-totals-row': (params) => params.data && params.data.selectedTotalsRow
            }
        })
        .withColumnPinning()
        .buildDefault(this);

    gridTracker: AgGridMultiSelectTracker;

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const searchParams = this._gridDataSource.getSearchParamsWithoutPagination();

            searchParams.selectedRows = this.gridTracker.getSelectedRowsModel();
            if (!searchParams.selectedRows.selectAllRows && searchParams.selectedRows.selectedRows.length === 0){
                searchParams.selectedRows.selectAllRows = true;
            }
            const exportModel: Compliance.IncomeStatementLineExportModel = {
                searchModel: searchParams,
                columnsToReturn: columnsToReturn,
                selectedRows: searchParams.selectedRows
            };

            const lrp$ = this._incomeStatementRepository.startIncomeStatementLineExport(this.companyId, exportModel);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.IncomeStatementLine };
        },
        canCancel: true,
        showFileFormatSelection: false
    };

    private _gridApi: GridApi;
    private _gridDataSource: IncomeStatementAgGridDataSource;
    private _gridMultiSelectSub: Subscription;
    private _busyRef: BusyIndicatorRef;
    private _destroy$: Subject<void> = new Subject();

    get refreshing(): boolean {
        return this._gridDataSource && this._gridDataSource.isRefreshing;
    }

    async ngOnInit(): Promise<void> {
        if (this.companyIdFilter) {
            this.companyId = this.companyIdFilter;
        } else {
            this.companyId = parseInt(this._routerService.getQuerystringParam('companyId'));
        }

        if (!this.isEmbededMode && typeof this.siteIdFilter === 'undefined') {
            this.siteIdFilter = parseInt(this._routerService.getQuerystringParam('siteId'));
        }

        if (!this.isEmbededMode && typeof this.parcelIdFilter === 'undefined') {
            this.parcelIdFilter = parseInt(this._routerService.getQuerystringParam('parcelId'));
        }

        this.newCompanyId = this.companyId;

        let busyRef: BusyIndicatorRef;

        if (this.isEmbededMode) {
            this.onLoading = true;
            this.onLoadingChange.emit(true);

            if(typeof this.siteIdFilter !== 'undefined') {
                this.filterHref = this._routerService.getHrefFromState('siteIncomeStatement', { companyId: this.companyId,  siteId: this.siteIdFilter });
            }
            else if(typeof this.parcelIdFilter !== 'undefined') {
                this.filterHref = this._routerService.getHrefFromState('incomeStatementParcelFilter', { companyId: this.companyId,  parcelId: this.parcelIdFilter });
            }
        } else {
            busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        }

        try {
            this.canEdit = await this._restrictService.hasCompanyPermission(this.companyId, Core.AccessRightsEnum.Write);
            this.canViewRyanPrivateItems = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);

            let sitePromise: Promise<void>;

            if(!this.isEmbededMode && this.siteIdFilter) {
                sitePromise = lastValueFrom(this._siteService.getSiteName(this.siteIdFilter)).then(result => {
                    this.siteName = result;
                });
            }

            let parcelPromise: Promise<void>;
            let parcelSiteId: number;

            if(!this.isEmbededMode && this.parcelIdFilter) {
                parcelPromise = this._parcelService.getView(this.parcelIdFilter, false).then(result => {
                    this.parcelName = result.parcel.acctNum;
                    parcelSiteId = result.siteId;
                });
            }

            let companyInfo: Core.CompanyModel;

            const companyPromise = this._companyService.load(this.companyId, true, false);
            companyPromise.then((company) => {
                this.companyName = company.companyName;
                companyInfo = company;
            });

            await Promise.all([
                companyPromise,
                sitePromise,
                parcelPromise
            ]);

            this.breadcrumbs.push({
                name: this.companyName,
                target: 'company',
                options: { companyId: this.companyId }
            });

            if (this.siteName) {
                this.breadcrumbs.push({
                    name: this.siteName,
                    target: 'site',
                    options: { companyId: this.companyId, siteId: this.siteIdFilter }
                });
            }

            if (this.parcelName) {
                this.breadcrumbs.push({
                    name: this.parcelName,
                    target: 'parcel',
                    options: { companyId: this.companyId, siteId: parcelSiteId, parcelId: this.parcelIdFilter }
                });
            }

            await this._initEndDates();

            this.isInitialized = true;
        } finally {
            if(this.isEmbededMode) {
                this.onLoading = false;
                this.onLoadingChange.emit(false);
            }
            else {
                busyRef.hide();
            }
        }
        this._glAccountService.start(this.companyId);
        return Promise.resolve();
    }

    ngOnDestroy(): void {
        this._gridMultiSelectSub && this._gridMultiSelectSub.unsubscribe();
        this._destroy$.next();
        this._destroy$.complete();
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;
        this._gridApi.showLoadingOverlay();

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._setGridColumns();

        // notify the bulk update button (on the column tool panel) every time the grid selection changes
        this._gridMultiSelectSub = this.gridTracker.selectedRows$.subscribe(async () => {
            const isBulkUpdateVisible = this.canEdit && this.gridTracker.hasSelectedRows();
            this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
            this.isBulkDeleteVisible$.next(isBulkUpdateVisible);
        });

        this._setDataSource();
    }

    onEndDateChange(): void {
        this._refreshDataSource();
    }

    async refresh(): Promise<void> {
        await this._initEndDates();
        await this._refreshDataSource();
    }

    async bulkDelete(): Promise<void> {
        const confirmOptions = {
            message: 'Are you sure you want to delete selected line items?',
            buttons: MessageBoxButtons.OKCancel
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.OK) {

            const params: DeleteConfirmationComponentParams = {
                item: 'Income Statement Line Items',
                message: 'Are you sure you want to delete selected line items? If you choose to proceed, all information related to line items will be removed and cannot be recovered.'
            };

            const result = await this._modalService.showAsync(DeleteConfirmationComponent, params, 'modal-md');

            if (result) {
                this._showBusyIndicator('Bulk Delete', 'Deleting line items', null, false, false);

                const model: Compliance.IncomeStatementLineBulkDeleteModel = {
                    lastModifiedTimestamp: this._gridDataSource.lastModifiedTimestamp,
                    selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                    selectedRows: this.gridTracker.getSelectedRowsModel().selectedRows,
                    siteId: this.siteIdFilter,
                    endDate: this.selectedEndDate
                };

                try {
                    const longRunningProcessId = await lastValueFrom(this._incomeStatementRepository.startBulkDelete(this.companyId, model));
                    await this._busyRef.setLongRunningProcessId(longRunningProcessId);
                }
                catch (e) {
                    await this._hideBusyIndicator();
                    return Promise.reject(e);
                }
            }
        }

        return Promise.resolve();
    }

    handleTotalsUpdate = (totals: Core.IncomeStatementLineSearchTotalsModel, selectedTotals: boolean = false) => {
        if (!selectedTotals) {
            this.currentTotals = totals;
        }
    };

    async bulkUpdate(): Promise<void> {
        const params: IncomeStatementBulkUpdateParams = {
            companyId: this.companyId,
            siteId: this.siteIdFilter,
            selection: this.gridTracker.getSelectedRowsModel(),
            selectedCount: this.gridTracker.getSelectedRowsCount(),
            endDate: this.selectedEndDate
        };

        const result = await this._modalService.showAsync(IncomeStatementBulkUpdateComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this.gridTracker.clear();
        await this.refresh();
        const totals = await this._gridDataSource.getSelectedRowTotals({selectedRows: [], selectAllRows: true});
        this.handleTotalsUpdate(totals);
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        return this._gridDataSource.getRowIdsInternal(skip, take);
    }

    private async _refreshDataSource(): Promise<void> {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        this._gridDataSource.clearTotals();

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return false;
        }

        this.gridTracker.clear(false);

        this.isBulkUpdateVisible$.next(false);
        this.isBulkDeleteVisible$.next(false);

        const dataSourceParams = (): IncomeStatementDataSourceParams => {
            return {
                companyId: this.companyId,
                siteId: this.siteIdFilter,
                endDate: this.selectedEndDate
            };
        };

        this._gridDataSource = new IncomeStatementAgGridDataSource(
            this._gridApi,
            this._incomeStatementRepository,
            dataSourceParams,
            this.handleTotalsUpdate
        );

        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }

    private _setGridColumns(): void {
        this._gridApi.setColumnDefs([]);
        const columns = this._getGridColumnsBySource();
        this._gridApi.setColumnDefs(columns);
        this._gridApi.setFilterModel(null);
    }

    private _getGridColumnsBySource(): ColDef[] {
        const columns: ColDef[] = [];

        const actionsColDef: ColDef = {
            colId: 'grid-column-actions',
            headerName: '',
            field: 'actions',
            width: AgGridColumns.getActionColumnWidth(1),
            minWidth: AgGridColumns.getActionColumnWidth(1),
            maxWidth: AgGridColumns.getActionColumnWidth(1),
            suppressSizeToFit: true,
            suppressAutoSize: true,
            resizable: false,
            suppressColumnsToolPanel: true,
            lockPinned: true,
            pinned: 'right',
            lockVisible: true,
            sortable: false,
            cellRendererFramework: AgGridActionCellRendererComponent,
            cellRendererParams: {
                buttonConfigs: [
                    {
                        iconClass: 'fa-trash',
                        onClick: this._deleteItem.bind(this),
                        isShown: this._canDelete.bind(this),
                        buttonClass: 'btn-primary btn-danger',
                        helpContentId: 'app.delete'
                    }
                ]
            } as AgGridActionCellRendererParams
        };

        columns.push(
            {
                colId: 'grid-column-multiselect',
                headerName: '',
                field: 'incomeStatementLineId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountName}`,
                headerName: 'G/L Account',
                // toolPanelClass: 'Source Values',
                field: 'glAccountName',
                pinned: 'left',
                lockPinned: true,
                lockVisible: true,
                lockPosition: true,
                suppressMovable: true,
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.CompanyName}`,
                headerName: 'Company',
                // toolPanelClass: 'Source Values',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.TopLevelCompanyName}`,
                headerName: 'Top Level Company',
                // toolPanelClass: 'Source Values',
                field: 'topLevelCompanyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.SiteName}`,
                headerName: 'Site',
                // toolPanelClass: 'Source Values',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const lineItem = params.data as Core.IncomeStatementLineModel;
                        // if (! lineItem && lineItem.sourceSite)) {
                        if (! lineItem ) {
                            return '';
                        }
                        return `#/site/${lineItem.siteId}`;
                    }
                } as AgGridLinkCellRendererParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.SiteNumber}`,
                headerName: 'Site Number',
                field: 'siteNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const lineItem = params.data as Core.IncomeStatementLineModel;
                        // if (! lineItem && lineItem.sourceSite)) {
                        if (! lineItem ) {
                            return '';
                        }
                        return `#/site/${lineItem.siteId}`;
                    }
                } as AgGridLinkCellRendererParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.IsValidated}`,
                headerName: 'Validated',
                field: 'isValidated',
                type: 'textColumn',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const data = params.data as Core.IncomeStatementLineModel;
                    return data && data.incomeStatementLineId
                        ? data.isValidated === true ? 'Yes' : 'No'
                        : '-';
                },
                editable: params => {
                    const data = params.data as Core.IncomeStatementLineModel;
                    return !!data.incomeStatementLineId;
                },
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => {
                        return [
                            {name: 'Yes', value: true},
                            {name: 'No', value: false}
                        ];
                    },
                    cellFocusLost: (params, updatedValue) => this._updateIsValidated(params, updatedValue)
                },
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountNumber}`,
                headerName: 'G/L Account Number',
                // toolPanelClass: 'Source Values',
                field: 'glAccountNumber',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountDescription}`,
                headerName: 'G/L Account Description',
                // toolPanelClass: 'Source Values',
                field: 'glAccountDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryName}`,
                headerName: 'Category',
                // toolPanelClass: 'Asset',
                field: 'glAccountIncomeStatementCategoryName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.GLAccountIncomeStatementCategoryTypeName}`,
                headerName: 'Account Type',
                // toolPanelClass: 'Asset',
                field: 'glAccountIncomeStatementCategoryTypeName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.CalculatedBalance}`,
                headerName: 'Balance',
                // toolPanelClass: 'Source Values',
                field: 'calculatedBalance',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeAndEqualToAndBlankFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2'),
                cellClass: 'ag-numeric-cell',
                cellClassRules: {
                    'overridden': params => {
                        const data = params.data as Core.IncomeStatementLineModel;
                        return data && data.incomeStatementLineId && data.calculatedBalance != null && data.calculatedBalance !== data.importedBalance;
                    },
                    'inline-editor-disabled': params => {
                        const data = params.data as Core.IncomeStatementLineModel;
                        return data && data.incomeStatementLineId && data.isValidated;
                    }
                },
                editable: params => {
                    const data = params.data as Core.IncomeStatementLineModel;
                    return data && data.incomeStatementLineId && !data.isValidated;
                },
                cellEditorFramework: AgGridNumberCellEditor,
                cellEditorParams: {
                    allowNull: true,
                    cellFocusLost: (params, updatedValue) => this._updateBalance(params, updatedValue),
                    validator: (value: number): CellEditorValidationResult => {
                        return value > 9999999999999992
                            ? {isValid: false, validationMessage: 'Value is too big'}
                            : {isValid: true, validationMessage: ''};
                    }
                },
                tooltipValueGetter: params => {
                    const data = params.data as Core.IncomeStatementLineModel;
                    return data && data.incomeStatementLineId && data.isValidated
                        ? 'May not edit a validated line item.'
                        : '';
                }
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.AccountHierarchyDescription}`,
                headerName: 'Account Hierarchy Description',
                // toolPanelClass: 'Source Values',
                field: 'accountHierarchyDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.CreateDate}`,
                headerName: 'Import Date',
                field: 'createDate',
                type: 'dateColumn',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => this._datePipe.transform(params.value, true),
                cellClass: 'ag-date-cell',
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.ValidatedBy}`,
                headerName: 'Validated By',
                field: 'validatedBy',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            } as ColDef,
            {
                colId: `${Core.IncomeStatementLinePropertyEnum.ValidatedDate}`,
                headerName: 'Validated Date',
                field: 'validatedDate',
                type: 'dateColumn',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => this._datePipe.transform(params.value, true),
                cellClass: 'ag-date-cell',
                hide: true
            } as ColDef,
            actionsColDef
        );

        return columns;
    }

    private async _deleteItem(params: AgGridActionCellRendererParams): Promise<boolean> {
        const item = params.data as Core.IncomeStatementLineModel;

        if(!item) {
             return;
        }

        try {
            await this._messageModalService.confirm(
                `Are you sure you wish to delete ${item.glAccountName}?`,
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve(false);
        }

        const busyRef = this._busyIndicatorService.show({ message: 'Deleting' });
        try {
            await lastValueFrom(this._incomeStatementRepository.deleteItem(item.incomeStatementLineId));
            await this._initEndDates();
            this.refresh();
            return Promise.resolve(true);
        } finally {
            busyRef.hide();
        }

    }

    private _canDelete(params: AgGridActionCellRendererParams): boolean {
        return this.canEdit;
    }

    private async _reloadTotals(): Promise<void> {
        if (!this._gridDataSource) {
            return;
        }

        this.selectedTotalsLoading = true;

        try {
            const result = await this._gridDataSource.getSelectedRowTotals(this.gridTracker.getSelectedRowsModel());
            this.selectedTotalsLoading = false;
            this.handleTotalsUpdate(result, true);
        }
        finally {
            this.selectedTotalsLoading = false;
        }
    }

    private _showBusyIndicator(title: string, message: string = 'Working on it...', lrpId: number, canDismiss = true, hasProgressBar = true): void {
        if (this._busyRef) {
            this._busyRef.updateMessage(message, this.busyRefId);
            this._busyRef.setLongRunningProcessId(lrpId);
            return;
        }

        this._busyRef = this._busyIndicatorService.show({
            identifier: this.busyRefId,
            longRunningProcessId: lrpId,
            title: title ? title : 'Processing',
            message: message,
            hasProgressBar: hasProgressBar,
            canDismiss
        });

        this._busyRef.onProgressBarComplete().pipe(takeUntil(this._destroy$)).subscribe(async (success) => {
            await this._hideBusyIndicator();
            if (success) {
                await this._initEndDates();
                await this.refresh();
            }
        });
    }

    private async _hideBusyIndicator(): Promise<void> {
        if (this._busyRef) {
            await this._busyRef.hide();
            this._busyRef = null;
        }
        this._destroy$.next();
    }

    private async _initEndDates() {
        this.endDates = await lastValueFrom(this._incomeStatementRepository.getIncomeStatementEndDates(this.companyId, this.siteIdFilter));

        if(!_.includes(this.endDates, this.selectedEndDate)) {
            this.selectedEndDate = _.maxBy(this.endDates, x => new Date(x));
        }
    }

    private async _updateIsValidated(params: ICellEditorParams, updatedValue: boolean): Promise<void> {
        const data = params.data as Core.IncomeStatementLineModel;

        if (!data) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const updateModel: Core.IncomeStatementLineItemIsValidatedUpdateModel = {
            incomeStatementLineId: data.incomeStatementLineId,
            isValidated: updatedValue,
            rowVersion: data.rowVersion
        };

        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        try {
            const result = await lastValueFrom(this._incomeStatementRepository.updateIsValidated(updateModel));
            data.isValidated = result.isValidated;
            data.validatedBy = result.validatedBy;
            data.validatedDate = result.validatedDate;
            data.rowVersion = result.rowVersion;
        } catch (e) {
            data.isValidated = initialValue;
            throw e;
        } finally {
            await busyRef.hide();
        }

        params.node.setData(data);

        return Promise.resolve();
    }

    private async _updateBalance(params: ICellEditorParams, updatedValue?: number): Promise<void> {
        const data = params.data as Core.IncomeStatementLineModel;

        if (!data) {
            return Promise.resolve();
        }

        const initialValue = params.value;
        if (updatedValue === initialValue) {
            return Promise.resolve();
        }

        const updateModel: Core.IncomeStatementLineBalanceUpdateModel = {
            incomeStatementLineId: data.incomeStatementLineId,
            balance: updatedValue,
            rowVersion: data.rowVersion
        };

        const busyRef = this._busyIndicatorService.show({message: 'Saving'});

        try {
            data.rowVersion = await lastValueFrom(this._incomeStatementRepository.updateBalance(updateModel));
            data.calculatedBalance = updatedValue || data.importedBalance;
        } catch (e) {
            data.calculatedBalance = initialValue;
            throw e;
        } finally {
            await busyRef.hide();
        }

        params.node.setData(data);

        const totals = await this._gridDataSource.getSelectedRowTotals({selectedRows: [], selectAllRows: true});
        this.handleTotalsUpdate(totals);

        return Promise.resolve();
    }
}
