import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';
import { EntityImportUploadComponent, EntityImportUploadParams } from '../../Compliance/Entity-Import/Upload/upload.component';
import { ExcelPreviewComponent, ExcelPreviewComponentParams } from '../../Compliance/Entity-Import/Excel-Preview/excelPreview.component';
import { IBusyIndicatorConfig, BusyIndicatorService, BusyIndicatorRef } from '../../Busy-Indicator';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityImportRepository } from '../../Compliance/Repositories';

@Component({
    selector: 'begin-new-import',
    template: `
        <button class="ace-btn btn-primary"
            [ngClass]="{ 'btn-sm': buttonSize === 'small' }"
            (click)="newImport()"
            helpTooltip
            helpContentId="app.new-data-import"
            position="bottom"
            [disabled]="isDisabled">
            {{label}}
        </button>
`
})
export class BeginNewImportComponent implements OnDestroy {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _entityImportRepository: EntityImportRepository
    ) { }

    @Input() label: string = 'New Data Import';
    @Input() canCloseProgressModal: boolean = true;
    @Input() companyId: number;
    @Input() buttonSize: string;
    @Input() isDisabled: boolean = false;
    @Input() allocationId?: number;

    @Output() refresh: EventEmitter<void> = new EventEmitter();

    private destroy$: Subject<void> = new Subject();

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    async newImport(): Promise<void> {
        const params: EntityImportUploadParams = {
            companyId: this.companyId,
            allocationId: this.allocationId
        };

        const importFile = await this._modalService.showAsync(EntityImportUploadComponent, params, 'modal-md');

        if (!importFile) {
            return Promise.resolve();
        }

        this.refresh.emit();

        await this.resumeImport(importFile);
    }

    async resumeImport(importFile: Compliance.ImportFileModel): Promise<Compliance.ImportFileModel> {
        if (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.ShowPreview) {
            const params: ExcelPreviewComponentParams = {
                importFileId: importFile.importFileId
            };

            const previewResult = await this._modalService.showAsync(ExcelPreviewComponent, params, 'modal-xl');
            if (!previewResult) {
                return Promise.resolve(null);
            }

            importFile = previewResult;
        }

        if (importFile.processStatus === Compliance.ImportFileProcessStatusEnum.Uploaded) {
            const busyRef = await this.showImportFileIsProcessingMessage(importFile);

            let startResult: Compliance.ImportFileModel;
            try {
                startResult = await lastValueFrom(this._entityImportRepository.startProcess(importFile.importFileId));
                if (!startResult) {
                    return Promise.resolve(null);
                }

                await busyRef.setLongRunningProcessId(startResult.longRunningProcessId);
            }
            catch (e) {
                busyRef.hide();
                throw e;
            }

            importFile = startResult;
        }

        return importFile;
    }

    async showImportFileIsProcessingMessage(importFile: Compliance.ImportFileModel): Promise<BusyIndicatorRef> {
        const busyRefId = this._busyIndicatorService.generateUniqueMessageIdentifier();

        const busyConfig: IBusyIndicatorConfig = {
            identifier: busyRefId,
            longRunningProcessId: importFile.longRunningProcessId,
            title: 'Processing Import',
            message: 'Import Uploading',
            hasProgressBar: false,
            canDismiss: !this.allocationId
        };

        const busyRef = this._busyIndicatorService.show(busyConfig);

        busyRef.onProgressBarComplete().pipe(takeUntil(this.destroy$)).subscribe((succeeded: boolean) => {
            busyRef.hide();
            if (succeeded) {
                this._dismissImportFileIsProcessingMessage(importFile);
            } else {
                this.refresh.emit();
            }
            this.destroy$.next();
        });

        busyRef.onDismiss().pipe(takeUntil(this.destroy$)).subscribe(() => {
            busyRef.hide();
            this.destroy$.next();
        });

        return busyRef;
    }

    private _dismissImportFileIsProcessingMessage(importFile: Compliance.ImportFileModel): void {
        if (!importFile) {
            return;
        }

        if (this.allocationId) {
            this._routerService.go('processAllocationImport', { allocationId: this.allocationId, importId: importFile.importFileId });
        } else if (importFile.companyId) {
            this._routerService.go('processCompanyImport', { companyId: importFile.companyId, importId: importFile.importFileId });
        } else {
            this._routerService.go('processSystemImport', { importId: importFile.importFileId });
        }
    }
}
