import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataWidgetI, WidgetCache } from '../../dashboard.model';
import * as _ from 'lodash';
import { DashboardCalendarEventTypes, CalendarMonth, DateEvent, CalendarWeek, EventTypeBlock, CalendarDay, LegendBlock } from './calendar.widget.model';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdvancedSearchPersistenceService } from '../../../Search/Advanced/advancedSearchPersistence.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { SmartSearchService } from '../../../Search/Advanced/smartSearch.service';
import { TimerService } from '../../../UI-Lib/Utilities';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';

import * as moment from 'moment';

@Component({
    selector: 'calendar-widget',
    templateUrl: './calendar.widget.component.html',
    styleUrls: ['./calendar.widget.component.scss']
})
export class CalendarWidgetComponent implements OnInit, DataWidgetI {
    constructor(
        private readonly _http: HttpClient,
        private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _advancedSearchService: SmartSearchService,
        private readonly _timer: TimerService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {}

    @Input() data$: Observable<any>;
    @Output() newParams: EventEmitter<object> = new EventEmitter();

    DashboardCalendarEventTypes = DashboardCalendarEventTypes;

    objectKeys = Object.keys;

    month: CalendarMonth;
    now: moment.Moment = moment();
    calendarDate: any = moment();
    clickedDay: CalendarDay;
    legendBlocks: LegendBlock [];
    loading: boolean = false;

    private readonly _EVENT_TYPE_TO_TEXT: Record<DashboardCalendarEventTypes, string> = {
        [DashboardCalendarEventTypes.AbatementComplianceDeadline]: 'Abatement Compliance Deadline',
        [DashboardCalendarEventTypes.FreeportComplianceDeadline]: 'Freeport Compliance Deadline',
        [DashboardCalendarEventTypes.MiscFilingComplianceDeadline]: 'Misc Filing Compliance Deadline',
        [DashboardCalendarEventTypes.PPReturnComplianceDeadline]: 'PP Return Compliance Deadline',
        [DashboardCalendarEventTypes.PollutionControlComplianceDeadline]: 'Pollution Control Compliance Deadline',
        [DashboardCalendarEventTypes.AppealFormalHearing]: 'Appeal Formal Hearing',
        [DashboardCalendarEventTypes.AppealInformalHearing]: 'Appeal Informal Hearing',
        [DashboardCalendarEventTypes.AppealSubmitEvidence]: 'Appeal Submit Evidence',
        [DashboardCalendarEventTypes.AppealConfirmHearing]: 'Appeal Confirm Hearing',
        [DashboardCalendarEventTypes.AppealDeadline]: 'Appeal Deadline',
        [DashboardCalendarEventTypes.PaymentDueDate]: 'Payment Due Date'
    };

    ngOnInit() {
        this.data$.subscribe((widgetCache: WidgetCache) => {
            if(widgetCache.params && !_.isEmpty(widgetCache.params)) {
                this.calendarDate = moment([widgetCache.params.year, Number(widgetCache.params.month) - 1]);
            }
            const eventTypesByDay = this._groupEventTypesByDay(widgetCache.data);
            this.month = this._generateMonth(eventTypesByDay);
            this.legendBlocks = this._getLegendFromData(widgetCache.data);
        });
    }

    nextMonth(): void {
        this.calendarDate = this.calendarDate.add(1, 'month');

        this._monthChanged();
    }

    previousMonth(): void {
        this.calendarDate = this.calendarDate.subtract(1, 'month');

        this._monthChanged();
    }

    getIconClass(dashboardCalendarEventType: number): string {
        const className: string = 'small-icon-badge fa ';

        switch(dashboardCalendarEventType) {
            case DashboardCalendarEventTypes.AbatementComplianceDeadline:
            case DashboardCalendarEventTypes.FreeportComplianceDeadline:
            case DashboardCalendarEventTypes.MiscFilingComplianceDeadline:
            case DashboardCalendarEventTypes.PPReturnComplianceDeadline:
            case DashboardCalendarEventTypes.PollutionControlComplianceDeadline:
                return `${className  }fa-file-text-o blue-badge`;

            case DashboardCalendarEventTypes.AppealFormalHearing:
            case DashboardCalendarEventTypes.AppealInformalHearing:
            case DashboardCalendarEventTypes.AppealSubmitEvidence:
            case DashboardCalendarEventTypes.AppealConfirmHearing:
                return `${className  }fa-gavel gold-badge`;

            case DashboardCalendarEventTypes.AppealDeadline:
                return `${className  }fa-clipboard red-badge`;

            case DashboardCalendarEventTypes.PaymentDueDate:
                return `${className  }fa-dollar dollar-icon-fix green-badge`;
        }
    }

    getBackgroundColor(dashboardCalendarEventType: number): string {
        const className: string = 'event-type-block ';

        switch(dashboardCalendarEventType) {
            case DashboardCalendarEventTypes.AbatementComplianceDeadline:
            case DashboardCalendarEventTypes.FreeportComplianceDeadline:
            case DashboardCalendarEventTypes.MiscFilingComplianceDeadline:
            case DashboardCalendarEventTypes.PPReturnComplianceDeadline:
            case DashboardCalendarEventTypes.PollutionControlComplianceDeadline:
                return `${className  }blue-badge`;

            case DashboardCalendarEventTypes.AppealFormalHearing:
            case DashboardCalendarEventTypes.AppealInformalHearing:
            case DashboardCalendarEventTypes.AppealSubmitEvidence:
            case DashboardCalendarEventTypes.AppealConfirmHearing:
                return `${className  }gold-badge`;

            case DashboardCalendarEventTypes.AppealDeadline:
                return `${className  }red-badge`;

            case DashboardCalendarEventTypes.PaymentDueDate:
                return `${className  }green-badge`;
        }
    }

    async goToSmartFromPopover(eventTypeBlock: EventTypeBlock, stateAbbr?: string): Promise<void> {
        let httpParams: HttpParams = new HttpParams();
        let url: string, analyticsEvent: string;

        httpParams = httpParams.set('eventTypeId', eventTypeBlock.dashboardCalendarEventType.toString());

        if(stateAbbr) {
            httpParams = httpParams.set('stateAbbr', stateAbbr);
        }

        if(this.clickedDay.monthDay) {
            let dateString: string = moment(eventTypeBlock.events[0].date).utc().toISOString();
            dateString = dateString.split('T')[0];
            httpParams = httpParams.set('date', encodeURI(dateString));

            analyticsEvent = 'click-task-icon-smart';

            url = '/api/search/Widget/Calendar';
        } else {
            httpParams = httpParams.set('month', this.calendarDate.format('M'));
            httpParams = httpParams.set('year', this.calendarDate.format('YYYY'));

            analyticsEvent = 'click-task-list-smart';

            url = '/api/search/Widget/Calendar/Legend';
        }

        this.loading = true;

        try {
            if(!this._advancedSearchService.checkIfAllFieldsPresent()) {
              await this._advancedSearchService.getAllFields(true);
            }
            const data = await lastValueFrom(this._http.get<any>(url, {params: httpParams}));
            this._productAnalyticsService.logEvent(analyticsEvent);
            this._goToSmart(data);
        } finally {
            this.loading = false;
        }
    }

    legendClicked(legendBlock: LegendBlock): void {
        this.loading = true;

        this._timer.setTimeout(() => {
            // TODO: This type really is wrong, but it didn't used to throw an error. My best guess is that
            // this broke on TypeScript 2.4.2->2.9.2 or @types/lodash 4.14.64->4.14.103
            //this.clickedDay = _.pick(legendBlock, 'dashboardCalendarEventType', 'eventTypeBlocks');
            this.clickedDay = (_ as any).pick(legendBlock, 'dashboardCalendarEventType', 'eventTypeBlocks');
            this._productAnalyticsService.logEvent('click-calendar-month-task', {
                calendarTaskIcon: this.clickedDay.eventTypeBlocks.map(x => this._EVENT_TYPE_TO_TEXT[x.dashboardCalendarEventType])
            });
            this.loading = false;
        });
    }

    isDayToday(day: CalendarDay): boolean {
        return this.month.name === this.now.format('MMMM') && day.monthDay === this.now.date() && this.month.year === this.now.year();
    }

    getEventTypeTitle(dashboardCalendarEventType, dayView?: boolean): string {
        switch(dashboardCalendarEventType) {
            case DashboardCalendarEventTypes.AbatementComplianceDeadline:
                if(dayView) {
                    return 'Abatement Filing';
                }
            case DashboardCalendarEventTypes.FreeportComplianceDeadline:
                if(dayView) {
                    return 'FreePort Filing';
                }
            case DashboardCalendarEventTypes.MiscFilingComplianceDeadline:
                if(dayView) {
                    return 'Misc Filing';
                }
            case DashboardCalendarEventTypes.PPReturnComplianceDeadline:
                if(dayView) {
                    return 'PP Return Filing';
                }
            case DashboardCalendarEventTypes.PollutionControlComplianceDeadline:
                return dayView ? 'Polution Control Filing' : 'Compliance Filing';
            case DashboardCalendarEventTypes.AppealFormalHearing:
                if(dayView) {
                    return 'Formal Hearing';
                }
            case DashboardCalendarEventTypes.AppealInformalHearing:
                if(dayView) {
                    return 'Informal Hearing';
                }
            case DashboardCalendarEventTypes.AppealSubmitEvidence:
                return dayView ? 'Submit Evidence Date' : 'Appeal Hearing';
            case DashboardCalendarEventTypes.AppealDeadline:
                return 'Appeal Deadline';
            case DashboardCalendarEventTypes.PaymentDueDate:
                return 'Payment Due Date';
            case DashboardCalendarEventTypes.AppealConfirmHearing:
                return 'Confirm Hearing';
        }
    }

    getPopoverPlacement(weekIdx: number, dayIdx: number): any {
        switch(dayIdx) {
            case 0:
                return 'right';
            case 6:
                return 'left';
            default:
                return weekIdx === 0 ? 'bottom' : 'top';
        }
    }

    dayClicked(day: CalendarDay): void {
        this._timer.setTimeout(() => {
            if(this.clickedDay) {
                return;
            }

            this.clickedDay = day;
            this._productAnalyticsService.logEvent('click-calendar-date-task', {
                calendarDateIcon: this.clickedDay.eventTypeBlocks.map(x => this._EVENT_TYPE_TO_TEXT[x.dashboardCalendarEventType])
            });
        });
    }

    private _getEventTypeSequence(dashboardCalendarEventType: number): number {
        switch(dashboardCalendarEventType) {
            case DashboardCalendarEventTypes.AbatementComplianceDeadline:
            case DashboardCalendarEventTypes.FreeportComplianceDeadline:
            case DashboardCalendarEventTypes.MiscFilingComplianceDeadline:
            case DashboardCalendarEventTypes.PPReturnComplianceDeadline:
            case DashboardCalendarEventTypes.PollutionControlComplianceDeadline:
                return 1;

            case DashboardCalendarEventTypes.AppealFormalHearing:
            case DashboardCalendarEventTypes.AppealInformalHearing:
            case DashboardCalendarEventTypes.AppealSubmitEvidence:
            case DashboardCalendarEventTypes.AppealConfirmHearing:
                return 2;

            case DashboardCalendarEventTypes.AppealDeadline:
                return 3;

            case DashboardCalendarEventTypes.PaymentDueDate:
                return 4;
        }
    }

    private _generateMonth(eventDays?: any): CalendarMonth {
        //Get weekday index of the first day of the current month
        //So if the 1st falls on a thursday, firstDayOfTheWeekIdx = 4
        const firstDayOfTheWeekIdx: number = this.calendarDate.subtract(this.calendarDate.date() - 1, 'days').day();

        const month = new CalendarMonth();
        let week: CalendarWeek = new CalendarWeek();

        for(let j = 0; j < this.calendarDate.daysInMonth(); j++) {
            //Incrementing the dayOfTheWeekIdx of each day of the month (0 - 6 repeating)
            const dayOfTheWeekIdx: number = (firstDayOfTheWeekIdx + j) % 7;

            //If we're back on Sunday, add that week to the calendar,
            //then start working with a new one
            //(checking j != 0  ensures we aren't adding a blank week if the month starts on a sunday)
            if(dayOfTheWeekIdx == 0 && j != 0) {
                month.weeks.push(week);
                week = new CalendarWeek();
            }

            week.days[dayOfTheWeekIdx].monthDay = j + 1;
            week.days[dayOfTheWeekIdx].weekdayAbbr = moment().day(dayOfTheWeekIdx).format('ddd');

            if(eventDays) {
                week.days[dayOfTheWeekIdx].eventTypeBlocks = eventDays[j + 1];
                week.days[dayOfTheWeekIdx].icons = _.chain(eventDays[j + 1])
                    .groupBy((eventTypeBlock: EventTypeBlock) => {
                        return this.getEventTypeTitle(eventTypeBlock.dashboardCalendarEventType);
                    })
                    .toArray()
                    .sortBy([(eventTypeBlocks: EventTypeBlock[]) => {
                        return this._getEventTypeSequence(eventTypeBlocks[0].dashboardCalendarEventType);
                    }])
                    .map((eventTypeBlocks: EventTypeBlock[]) => {
                        return this.getIconClass(eventTypeBlocks[0].dashboardCalendarEventType);
                    })
                    .value();
            }
        }

        //We ran out of month days, so push the final week onto the month
        month.weeks.push(week);

        month.name = this.calendarDate.format('MMMM');
        month.year = this.calendarDate.year();

        return month;
    }

    private _goToSmart(data): void  {
        this._advancedSearchService.returnSearch(data)
            .then((result) => {
                this._advancedSearchPersistenceService.search = result;
                this._advancedSearchPersistenceService.fromWidget = true;

                this._upgradeNavigationServiceHandler.go('search', {});
            });
    }

    private _getLegendFromData(data: {events: DateEvent[]}): LegendBlock [] {
        const legendBlocks = _.chain(data.events)
            .groupBy((event: DateEvent) => {
                return this.getEventTypeTitle(event.dashboardCalendarEventType);
            })
            .map((events: DateEvent[], key: string) => {
                const count =  _.reduce(events, (sum, event: DateEvent) => {
                    return sum + event.count;
                }, 0);

                return {
                    title: key,
                    count: count,
                    dashboardCalendarEventType: events[0].dashboardCalendarEventType,
                    eventTypeBlocks: this._createEventTypeBlocks(events)
                };
            })
            .sortBy([(legendBlock: LegendBlock) => {
                return this._getEventTypeSequence(legendBlock.dashboardCalendarEventType);
            }])
            .value();

        return legendBlocks;
    }

    private _monthChanged() {
        const params = {month: this.calendarDate.format('M'), year: this.calendarDate.format('YYYY')};
        this.newParams.emit(params);
    }

    private _groupEventTypesByDay(data: {events: DateEvent[]}) {
        const eventDays = _.chain(data.events)
            .groupBy((event: DateEvent) => {
                return moment(event.date).utc().date();
            })
            .mapValues((events: DateEvent[]) => this._createEventTypeBlocks(events))
            .value();

        return eventDays;
    }

    private _createEventTypeBlocks(events: DateEvent[]): EventTypeBlock[] {
        return _.chain(events)
            .groupBy('dashboardCalendarEventType')
            .map((events: DateEvent[], key: number) => {
                    return {
                        dashboardCalendarEventType: Number(key),
                        events: this._aggregateStates(events),
                        totalCount: _.reduce(events, (sum, event: DateEvent) => {
                            return sum + event.count;
                        }, 0)
                    };
            })
            .sortBy([(eventTypeBlock: EventTypeBlock) => {
                return this._getEventTypeSequence(eventTypeBlock.dashboardCalendarEventType);
            }])
            .value();
    }

    //Need to get rid of duplicate states for legend
    private _aggregateStates(events: DateEvent[]): DateEvent[] {
        return _.chain(events)
            .groupBy('stateAbbr')
            .map((dateEvents: DateEvent[]) => {
                return _.reduce(dateEvents, (summedEvent: DateEvent, event: DateEvent) => {
                    summedEvent.count += event.count;

                    return summedEvent;
                }, {
                    count: 0,
                    dashboardCalendarEventType: dateEvents[0].dashboardCalendarEventType,
                    stateAbbr: dateEvents[0].stateAbbr,
                    date: dateEvents[0].date
                } as DateEvent);
            })
            .sortBy('stateAbbr')
            .value();
    }
}
