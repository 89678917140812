import { NgModule } from '@angular/core';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { ProcessingModule } from '../../Processing/processing.module';
import { CompanySettingsComponent } from './Company-Settings/companySettings.component';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { AccountingPeriodsLinkComponent } from './Company-Settings/Accounting-Periods/accounting.periods.link.component';
import { StepProgressIndicatorModule } from '../../Common/Step-Progress-Indicator/stepProgressIndicator.module';
import { AccrualsDescriptorsLinkComponent } from './Company-Settings/Accruals-Descriptors/accruals.descriptors.link.component';
import { AccrualsDescriptorsModalComponent } from './Company-Settings/Accruals-Descriptors/accruals.descriptors.modal.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CompanyComplianceComponent } from './Company-Compliance/companyCompliance.component';
import { CompanyPickerComponent } from './Company.Picker.Component';
import { CompanyService } from './company.service';
import { AccrualsDescriptorSelectorComponent } from './Company-Settings/Accruals-Descriptors/accruals.descriptor.selector.component';
import { VendorCodeModule } from './Vendor-Codes/vendorCode.module';
import { CompanyExplorationComponent } from './Exploration/companyExploration.component';
import { AddSiteModalComponent } from './Exploration/addSiteModal.component';
import { AddCompanyModalComponent } from './Add/addCompanyModal.component';
import { LaunchAddCompanyModalService } from './Add/launchAddCompanyModal.service';
import { CompanyInfoPanelComponent } from './Info/companyInfoPanel.component';
import { CompanyInfoPanelViewComponent } from './Info/companyInfoPanelView.component';
import { CompanyInfoPanelEditComponent } from './Info/companyInfoPanelEdit.component';
import { CompanyPageComponent } from './companyPage.component';
import { ClientServicesModule } from 'src/app/Client-Services/client-services.module';
import { ComplianceCompanyModule } from 'src/app/Compliance/Company/company.module';
import { ContactModule } from 'src/app/Contact/contact.module';
import { BusyIndicatorModule } from 'src/app/Busy-Indicator';
import { PropertyCharacteristicsModule } from '../../Property-Characteristics/propertyCharacteristics.module';
import { BudgetDescriptorsLinkComponent } from './Company-Settings/Budget-Descriptors/budget.descriptors.link.component';
import { BudgetDescriptorsModalComponent } from './Company-Settings/Budget-Descriptors/budget.descriptors.modal.component';
import { BudgetDescriptorSelectorComponent } from './Company-Settings/Budget-Descriptors/budget.descriptor.selector.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        StepProgressIndicatorModule,
        TypeaheadModule,
        VendorCodeModule,
        ClientServicesModule,
        ComplianceCompanyModule,
        ContactModule,
        BusyIndicatorModule,
        PropertyCharacteristicsModule,
        ProcessingModule
    ],
    declarations: [
        CompanySettingsComponent,
        AccountingPeriodsLinkComponent,
        AccrualsDescriptorsLinkComponent,
        AccrualsDescriptorsModalComponent,
        CompanyComplianceComponent,
        AccrualsDescriptorSelectorComponent,
        CompanyExplorationComponent,
        AddSiteModalComponent,
        AddCompanyModalComponent,
        CompanyInfoPanelComponent,
        CompanyInfoPanelViewComponent,
        CompanyInfoPanelEditComponent,
        CompanyPageComponent,
        CompanyPickerComponent,
        BudgetDescriptorsLinkComponent,
        BudgetDescriptorsModalComponent,
        BudgetDescriptorSelectorComponent,
    ],
    providers: [CompanyService, LaunchAddCompanyModalService],
    exports: [
        CompanySettingsComponent,
        CompanyPickerComponent
    ]
})
export class CompanyModule {
    static setupModule(): void {
        VendorCodeModule.setupModule();

        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('companySettings', CompanySettingsComponent);
        hybridAdapterUtility.downgradeNg2Component('companyCompliance', CompanyComplianceComponent);
        hybridAdapterUtility.downgradeNg2Component('companyExploration', CompanyExplorationComponent);
        hybridAdapterUtility.downgradeNg2Component('companyInfoPanel', CompanyInfoPanelComponent);
        hybridAdapterUtility.downgradeNg2Component('companyPage', CompanyPageComponent);
        hybridAdapterUtility.downgradeNg2Provider('sd.Company.Service', CompanyService);
        hybridAdapterUtility.downgradeNg2Provider('launchAddCompanyModalService', LaunchAddCompanyModalService);
    }
}
