<div class="modal-header">
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
    <!-- <h4 class="modal-title">Report</h4> -->
</div>

<div class="modal-body exclude-modal-styling">
    <report-manager [modalParameters]="params" (saveModalClosed)="handleSaveModalClose($event)"></report-manager>
</div>

<div class="modal-footer">
    <!-- <button class="ace-btn btn-primary" (click)="cancel()">{{ override ? 'Cancel' : 'OK' }}</button> -->
</div>
