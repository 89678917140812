import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RevenueShare, Invoice } from '../invoice.model';

export interface ICellRendererParamsForExpenseAmount extends ICellRendererParams {
    onClick: (params: ICellRendererParamsForExpenseAmount) => void;
    editMode: () => boolean;
    currentInvoice: () => Invoice;
}

@Component({
    selector: 'Renderer-cell',
    template: `
        <div *ngIf="currentInvoice">
            <div *ngIf="!revenueShare.isTotal" class="radio-inline">
                <label class="fw-normal">
                    <input type="radio"
                              (change)="params.onClick(params)"
                              [checked]="currentInvoice.expenseIndex === (revenueShare.sequence + 1)"
                              [disabled]="!params.editMode()">
                    <ng-container *ngIf="currentInvoice.expenseIndex === (revenueShare.sequence + 1)">{{ currentInvoice.expenseAmount | currency: 'USD'}}</ng-container>
                </label>
            </div>
            <span *ngIf="revenueShare.isTotal">{{currentInvoice.expenseAmount | currency: 'USD'}}</span>
        </div>
    `
})
export class AgGridExpenseAmountCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForExpenseAmount;
    revenueShare: RevenueShare;

    get currentInvoice() {
        return this.params.currentInvoice();
    }

    agInit(params: ICellRendererParamsForExpenseAmount): void {
        this.params = params;
        this.revenueShare = params.data as RevenueShare;
    }

    refresh() {
        return false;
    }
}
