import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { BaseRepository } from 'src/app/Common/Data/base.repository';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthData } from 'src/app/User/authStorage.service';

@Injectable()
export class LoginService extends BaseRepository {

    constructor(httpClient: HttpClient) {
        super(httpClient);
     }

     acceptTermsAndConditions(tokenResponse: AuthData): Promise<any> {
         return lastValueFrom(this.httpPut('api/Account/AcceptTermsAndConditions', {}, {
             headers: new HttpHeaders({ 'Authorization': `Bearer ${  tokenResponse.access_token}` })
         }));
     }

}
