import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { EntityImportRepository } from '../../Repositories';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { ColDef, GridApi, GridOptions, GridReadyEvent, ColumnApi } from 'ag-grid-community';
import { AgGridOptionsBuilder } from '../../AgGrid';
import * as _ from 'lodash';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

export interface ExcelPreviewComponentParams {
    importFileId: number;
}

@Component({
    selector: 'excel-preview',
    templateUrl: './excelPreview.component.html',
    styleUrls: ['./excelPreview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExcelPreviewComponent implements OnInit, IWeissmanModalComponent<ExcelPreviewComponentParams, Compliance.ImportFileModel>{
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _entityImportRepository: EntityImportRepository) {
    }

    params: ExcelPreviewComponentParams;
    result: Compliance.ImportFileModel;

    selectedWorksheetName: string;
    headerRowIndex?: number = 1;
    previewInfo: Compliance.ExcelImportPreviewModel = null;
    busyMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    gridId: System.Guid = '11713691-1C0B-4EB5-A136-92EF182117A9';
    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withColumnResize()
        .withLoadingOverlay()
        .build();

    private _gridApi: GridApi;
    private _gridColumnApi: ColumnApi;

    async ngOnInit(): Promise<void> {
        this.busyMessageManager.add('Loading', 'loading');
        try{
            this.previewInfo = await lastValueFrom(this._entityImportRepository.getExcelPreviewAsync(this.params.importFileId));

            if (this.previewInfo.worksheetsInfo && this.previewInfo.worksheetsInfo.length > 0){
                this.selectedWorksheetName = this.previewInfo.worksheetsInfo[0].worksheetName;
            }

            this._setRowData();
        }
        finally{
            this.busyMessageManager.remove('loading');
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        this.busyMessageManager.add('Saving', 'saving');

        try {
            const model: Compliance.ImportFileExcelOptionsModel = {
                headerRowIndex: this.headerRowIndex,
                worksheetName: this.selectedWorksheetName
            };

            this.result = await lastValueFrom(this._entityImportRepository.updateExcelOptions(this.params.importFileId, model));

            this._bsModalRef.hide();
        } finally {
            this.busyMessageManager.remove('saving');
        }
    }

    selectedWorksheetChanged(): void {
        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this._gridColumnApi = event.columnApi;

        this._setRowData();
    }

    private _setRowData(): void {
        if (!this._gridApi || !this.previewInfo) {
            return;
        }

        const worksheetInfo = _.find(this.previewInfo.worksheetsInfo, i => i.worksheetName === this.selectedWorksheetName);
        const columns: ColDef[] = [
            {
            headerName: '',
            field: 'rowIndex',
            width: 100,
            minWidth: 50}
        ];

        for (let i = 0; i < worksheetInfo.columnsCount; i++){
            columns.push({
                headerName: this._toColumnName(i + 1),
                field: `f${i}`,
                width: 100,
                minWidth: 50
            });
        }

        let rowIndex: number = 1;
        const data = _.map(worksheetInfo.rows, rawData => {
            const row = {
                ['rowIndex']: rowIndex++
            };
            for (let i = 0; i < worksheetInfo.columnsCount; i++){
                row[`f${i}`] = rawData[i];
            }
            return row;
        });

        this._gridApi.setColumnDefs(columns);

        const allColumnIds = [];
        this._gridColumnApi.getAllColumns().forEach(function(column) {
            allColumnIds.push(column.getColId());
        });

        this._gridApi.setRowData(data);
        this._gridColumnApi.autoSizeColumns(allColumnIds);
    }

    private _toColumnName(num: number): string {
        let ret = '';
        for (let a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
            ret = String.fromCharCode((num % b) / a + 65) + ret;
        }
        return ret.toLocaleUpperCase();
    }
}
