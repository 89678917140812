import { Component, OnDestroy } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { CellEditorValidationResult } from './validator.interface';

export interface DropdownCellEditorParams extends ICellEditorParams {
    getOptions: (params?) => Compliance.NameValuePair<any>[];
    cellChanged?: (initialRowValue: any, changedDate: string) => Promise<void>;
    cellFocusLost?: (initialRowValue: any, changedDate: string) => Promise<void>;
    validator?: (value: number) => CellEditorValidationResult;
}

@Component({
    selector: 'editor-cell',
    template: `
        <select [formControl]="dropdownValue" [class.invalid]="!isValid">
            <option style="color: initial;" *ngFor="let option of dropdownOptions" [value]="option.value">{{option.name}}</option>
        </select>
    `,
    styles: [
        'select.invalid { background-color: var(--ace-danger-ghosted); }'
    ]
})
export class AgGridDropdownCellEditorComponent implements ICellEditorAngularComp, OnDestroy {

    initialValue: string;
    isValid: boolean = true;
    dropdownValue: UntypedFormControl = new UntypedFormControl();
    dropdownOptions: Compliance.NameValuePair<any>[];

    private _params: DropdownCellEditorParams;
    private destroy$: Subject<void> = new Subject();

    agInit(params: DropdownCellEditorParams): void {
        this._params = params;
        this.initialValue = params.value;
        this.dropdownOptions = params.getOptions(params);
        this.dropdownValue.setValue(this.initialValue);

        this.dropdownValue.valueChanges
            .pipe(takeUntil(this.destroy$))
            .pipe(distinctUntilChanged())
            .subscribe(async (value: any) => {
                if (this._params.validator) {
                    this.isValid = this._params.validator(value).isValid;
                }
                if (this._params.cellChanged && this._isUnique(value)) {
                    await this._params.cellChanged(this._params, value);
                }
            });
    }

    async ngOnDestroy(): Promise<void> {
        if (!this.isValid) {
            this.dropdownValue.setValue(this.initialValue);
        }
        if (this._params.cellFocusLost && this._isUnique(this.dropdownValue.value)) {
            await this._params.cellFocusLost(this._params, this.dropdownValue.value);
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    getValue(): boolean {
        return this.dropdownValue.value;
    }

    isPopup(): boolean {
        return false;
    }

    private _isUnique(value: any): boolean {
        return !(value === this.initialValue);
    }
}
