import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AssessmentSummaryInfo } from './assessmentSummaryInfo';
import { HelpContentComponentConfig } from '../../../UI-Lib/Help-Tooltip';
import { AllocationAssessmentSummaryComponentComponent, AllocationAssessmentSummaryComponentParams } from './Allocation-Assessment-Summary/allocationAssessmentSummaryComponent.component';
import { AllocationDetailsService } from './allocationDetails.service';

export interface ICellRendererParamsForAssessmentSummaryCellRenderer extends ICellRendererParams {
    deleteAssessmentClicked: (summary: AssessmentSummaryInfo) => void;
    lockReleaseLockedAssessmentClicked: (summary: AssessmentSummaryInfo, lock: boolean) => void;
    filterClicked: (summary: AssessmentSummaryInfo) => void;
    clearFilterClicked: () => void;
}

@Component({
    selector: 'grid-action-cell',
    template: `
        <div style="width: 20px; height: 20px;">
            <span *ngIf="isNormalType"
                  class="summary-action-button delete-button clickable"
                  id="deleteAssessment"
                  [class.disabled]="!summary.canDelete"
                  (click)="deleteAssessmentClicked($event)"
                  helpTooltip
                  tooltipText="Delete the Assessment">
                <i class="fa fa-trash"></i>
            </span>
        </div>
        <div style="width: 20px; height: 20px;">
            <span *ngIf="isNormalType"
                  class="summary-action-button lock-button clickable"
                  id="lockAssessment"
                  (click)="lockReleaseLockedAssessmentClicked($event, !summary.isLocked)"
                  [class.disabled]="!summary.canLock"
                  helpTooltip
                  [tooltipText]="lockCheckboxTitle">
                <i class="fa" [ngClass]="{ 'fa-lock': summary.isLocked, 'fa-unlock': !summary.isLocked}"></i>
            </span>
        </div>
        <div style="width: 20px; height: 20px;">
            <span class="summary-action-button info-button clickable"
                  [class.active]="filterActive"
                  (click)="filterOnSummary()"
                  helpTooltip
                  position="bottom"
                  helpContentId="app.filter">
                <i class="fa fa-filter"></i>
            </span>
        </div>
        <div style="width: 20px; height: 20px;">
            <span *ngIf="isNormalType"
                  class="summary-action-button info-button"
                  helpTooltip
                  [class.disabled]="summary.isTaxAuthority"
                  [helpDisabled]="summary.isTaxAuthority"
                  position="right"
                  [contentComponent]="customRenderer"
                  [contentComponentValue]="summary">
                <i class="fa fa-info-circle"></i>
            </span>
        </div>`
})
export class AgGridAssessmentSummaryActionCellRendererComponent implements ICellRendererAngularComp {
    constructor(private readonly _allocationDetailService: AllocationDetailsService) { }

    summary: AssessmentSummaryInfo;
    isNormalType: boolean;
    customRenderer: HelpContentComponentConfig<AllocationAssessmentSummaryComponentComponent, AllocationAssessmentSummaryComponentParams>;

    private _params: ICellRendererParamsForAssessmentSummaryCellRenderer;

    get lockCheckboxTitle(): string {
        let result: string = '';

        if (this.summary.isComitted) {
            result = 'Committed Allocation';
        } else if (this.summary.isLocked) {
            result = 'Un-Lock the Allocation';
        } else {
            result = 'Lock the Allocation';
        }

        return result;
    }

    get filterActive(): boolean {
        return this._allocationDetailService.allocationDetailsFilter &&
            this._allocationDetailService.allocationDetailsFilter.summaryType === this.summary.summaryType &&
            this._allocationDetailService.allocationDetailsFilter.allocationAnnualAssessmentId === this.summary.allocationAnnualAssessmentId;
    }

    agInit(params: ICellRendererParamsForAssessmentSummaryCellRenderer): void {
        this._params = params;
        this.summary = params.data as AssessmentSummaryInfo;

        this.customRenderer = {
            component: AllocationAssessmentSummaryComponentComponent,
            componentParams: {
                summary: this.summary,
                components: this.summary.components,
            },
            canHover: true
        };

        this.isNormalType = this.summary.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    deleteAssessmentClicked(event): void {
        if (!this.summary.canDelete) {
            return;
        }

        this._params.deleteAssessmentClicked(this.summary);
        event.stopPropagation();
    }

    lockReleaseLockedAssessmentClicked(event, lock: boolean): void{
        if (this.summary.isComitted || !this.summary.canLock && !this.summary.canReleaseLock) {
            return;
        }

        this.summary.isLocked = lock;
        this._params.lockReleaseLockedAssessmentClicked(this.summary, lock);
        event.stopPropagation();
    }

    filterOnSummary(): void {
        if (!this.filterActive) {
            this._params.filterClicked(this.summary);
        } else {
            this._params.clearFilterClicked();
        }
    }
}
