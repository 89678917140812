import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridApi, GridReadyEvent, ColDef, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ValuationTemplateService } from '../../../valuationTemplate.service';
import { AssessorService } from '../../../../../Assessor-Collector/Assessor/assessor-service';
import { IWeissmanModalComponent } from '../../../../../Compliance/WeissmanModalService';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectTracker } from '../../../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../../Compliance/AgGrid';
import { Assessor } from '../../../../../Assessor-Collector/assessor.model';
import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';

export interface ValuationsAddAssessorModalParams {
    stateId: number;
    assessorsSelected: number[];
    valuationTemplateId: number;
}

@Component({
    selector: 'valuation-assessor-add',
    templateUrl: './addAssessor.component.html'
})
export class AddAssessorComponent implements OnInit, OnDestroy, IWeissmanModalComponent<ValuationsAddAssessorModalParams, Core.ValuationTemplateModel> {
    constructor(
        private readonly _valuationTemplateService: ValuationTemplateService,
        private readonly _assessorService: AssessorService,
        private readonly _bsModalRef: BsModalRef
    ) {}

    params: ValuationsAddAssessorModalParams;
    result: Core.ValuationTemplateModel;

    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Assessor).assessorID)
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withFloatingFilter()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();


    loading: boolean = true;
    hasChanges: boolean;

    private _gridApi: GridApi;
    private _selectedRowsSub: Subscription;
    private _allAssessors: Assessor[];
    private _selectableAssessors: Assessor[];

    async ngOnInit(): Promise<void> {
        const assessors = await this._assessorService.getByStateId(this.params.stateId);
        this._allAssessors = assessors.sort((a, b) => {
                                          return `${a.abbr} ${a.municipalityAbbr}`.localeCompare(`${b.abbr} ${b.municipalityAbbr}`);
                                      });
        this._selectableAssessors = this._allAssessors.filter(x => !this.params.assessorsSelected.includes(x.assessorID));

        this._setRowData();
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));
        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe(x => {
            this.hasChanges = (x.selectAllRows && x.selectedRows.length !== this._selectableAssessors.length)
                              || (!x.selectAllRows && !!x.selectedRows.length);
        });

        const columns: ColDef[] = [
            {
                field: 'assessorID',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Name',
                field: 'abbr',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params: ValueFormatterParams) => {
                    const data = params.data as Assessor;
                    return `${data.abbr} ${data.municipalityAbbr}`;
                }
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._setRowData();
    }

    async save(): Promise<void> {
        this.loading = true;

        try {
            const rowModel = await this.gridTracker.getSelectedRowsModel();
            const assessors = this._allAssessors
                               .reduce((acc, x) => {
                                   const row = rowModel.selectedRows.find(y => x.assessorID === y);
                                   if ((!rowModel.selectAllRows && row) || (rowModel.selectAllRows && !row) || this.params.assessorsSelected.includes(x.assessorID)) {
                                       acc.push({
                                           assessorId: x.assessorID,
                                           valuationTemplateId: this.params.valuationTemplateId
                                       });
                                   }
                                   return acc;
                               }, [] as Core.ValuationTemplateAssessorModel[]);

            const result = await this._valuationTemplateService.updateTemplateAssessors(this.params.valuationTemplateId, assessors);

            if (result) {
                this.result = result;
            }
        } finally {
            this.cancel();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setRowData(): void {
        if (!this._gridApi || !this._selectableAssessors) {
            return;
        }

        this._gridApi.setRowData(this._selectableAssessors);
        this._gridApi.sizeColumnsToFit();
        this.loading = false;
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        return Promise.resolve({
            data: rows.map((x) => {
                const assessor = x.data as Assessor;
                return assessor && assessor.assessorID;
            })
        } as Compliance.QueryResultModel<number>);
    }
}
