<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" (click)="expandOrCollapse()" [ngClass]="{'pointer': !isEdit}">
        <panel-chevron [showBody]="showBody"></panel-chevron>
        <h3>Collector Protocols</h3>

        <div class="float-end header-button-container" (click)="$event.stopPropagation()">
            <button class="ace-btn btn-text has-icon btn-primary"
                    *ngIf="!isEdit && showBody && canEdit"
                    (click)="editMode()">
                <i class="fa fa-pencil"></i>
            </button>
            <div *ngIf="isEdit && showBody">
                <button class="ace-btn btn-text has-icon btn-primary btn-success" (click)="savePanel()" [disabled]="!protocolForm.valid || saving">
                    <i class="fa fa-save"></i>
                </button>
                <button class="ace-btn btn-text has-icon btn-primary btn-danger" (click)="cancelEdit()" [disabled]="saving">
                    <i class="fa fa-close"></i>
                </button>
            </div>
        </div>

    </div>
    <div class="panel-flat-body" *ngIf="showBody">
        <div *ngIf="collector && protocolForm" class="collector" [formGroup]="protocolForm">
            <div class="checkbox">
                <label>
                    <input type="checkbox" formControlName="originalTaxBillRequired">
                    Collector requires original tax bill
                </label>
            </div>
            <div class="checkbox">
                <label>
                    <input type="checkbox" formControlName="receiveByDueDate">
                    Payment must be received by due date
                </label>
            </div>
            <div class="form-inline">
                <label *ngIf="isEdit" class="ws-font-weight-normal">
                    Maximum <input type="text"
                                   class="form-control input-number max-items-field"
                                   name="fieldMaxItemsPerCheck"
                                   formControlName="maxItemsPerCheck"
                                   [numbersOnly]="true"
                                   [decimalAllowed]="false"
                                   [negativeAllowed]="false"> payment item{{maxItemsPerCheck.value > 1 || maxItemsPerCheck.value === 0 ? 's' : ''}} per check
                </label>
                <div *ngIf="maxItemsPerCheck.invalid && (maxItemsPerCheck.dirty || maxItemsPerCheck.touched)" class="text-danger">
                    <div *ngIf="maxItemsPerCheck.errors?.min">
                        *Value cannot be lower than 0
                    </div>
                    <div *ngIf="maxItemsPerCheck.errors?.max">
                        *Value cannot be greater than 1000
                    </div>
                </div>
                <span *ngIf="!isEdit">Maximum {{collector.maxItemsPerCheck || 0}} payment item{{(collector.maxItemsPerCheck > 1 || collector.maxItemsPerCheck === 0 ? 's' : '')}} per check</span>
            </div>
        </div>
    </div>
</div>
