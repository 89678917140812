import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ContactModalOrigin, EntityTypeIds } from '../../../constants.new';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import {
    AgGridLinkCellRendererComponent,
    AgGridLinkCellRendererParams
} from '../../../Compliance/AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import {
    AgGridActionCellRendererComponent,
    AgGridActionCellRendererParams
} from '../../../Compliance/AgGrid/CellRenderers/agGridActionCellRenderer.component';
import { ContactModalService } from '../../contactModal.service';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { EditRoleModalComponent, EditRoleModalParams } from './Edit-Role/editRoleModal.component';

@Component({
    selector: 'entity-contact-list',
    templateUrl: './contactList.component.html',
    styleUrls: ['./contactList.component.scss']
})
export class ContactListComponent implements OnChanges {
    constructor(
        private readonly _contactModalService: ContactModalService,
        private readonly _weissmanModalService: WeissmanModalService
    ) {
    }

    @Input() entity;
    @Input() roles;
    @Input() hasEditPermission: boolean;
    @Input() hasViewPermission: boolean;
    @Input() editMode: boolean;
    @Input() contacts: any[];

    @Output() contactsUpdated: EventEmitter<void> = new EventEmitter();

    gridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .build();

    private _gridApi: GridApi;
    private _contacts: any[];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contacts && changes.contacts.currentValue) {
            this._contacts = changes.contacts.currentValue;
            this._setRowData();
        } else if (changes.editMode && changes.editMode.currentValue && this._gridApi) {
            this._gridApi.redrawRows();
        }
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const worksForColumn = {
            field: 'lastName',
            lockVisible: true,
            cellRendererFramework: AgGridLinkCellRendererComponent,
            cellRendererParams: {
                getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                getButtonLink: (params: AgGridLinkCellRendererParams) => {
                    const contact = params.data;
                    return `${contact.lastName}, ${contact.firstName}`;
                },
                onClick: (params: AgGridLinkCellRendererParams) => {
                    const contact = params.data;
                    this._openContactModal(contact);
                },
                isDisabled: (params: AgGridLinkCellRendererParams) => {
                    return !((!this.editMode) ? this.hasViewPermission : this.hasEditPermission);
                }
            } as AgGridLinkCellRendererParams,
            filter: 'agTextColumnFilter',
            filterParams: AgGridFilterParams.textFilterParams,
            floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
        };

        let columns: ColDef[] = [
            {
                headerName: 'Contact',
                field: 'lastName',
                lockVisible: true,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                    getButtonLink: (params: AgGridLinkCellRendererParams) => {
                        const contact = params.data;
                        return `${contact.lastName}, ${contact.firstName}`;
                    },
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const contact = params.data;
                        this._openContactModal(contact);
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        return !((!this.editMode) ? this.hasViewPermission : this.hasEditPermission);
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueGetter: (params) => {
                    const contact = params.data;
                    return `${contact.lastName}, ${contact.firstName}`;
                }
            },
            {
                headerName: 'Roles',
                field: 'contactRoleAssociations',
                valueFormatter: (params) => {
                    const contact = params.data;
                    return contact.contactRoleAssociations.map(x => x.roleDesc).join(', ');
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: AgGridActionCellRendererComponent,
                cellRendererParams: {
                    buttonConfigs: [
                        {
                            iconClass: 'fa-pencil',
                            onClick: (params: AgGridActionCellRendererParams) => {
                                const contact = params.data;
                                this._openRoleModal(contact);
                            },
                            isShown: () => this.editMode,
                            helpContentId: 'contact-list.edit-roles',
                            disabled: () => !this.editMode
                        }
                    ]
                } as AgGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'lastName',
                sort: 'asc'
            }
        ];

        if (this._hasWorksFor()) {
            columns = [worksForColumn, ...columns];
        }

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setRowData();
    }

    worksFor(contact): boolean {
        return contact
            && this.entity
            && !(contact.entityTypeID !== EntityTypeIds[this.entity.type.toUpperCase()]
                || contact.entityID !== this.entity.id);
    }

    private _hasWorksFor(): boolean {
        return this._contacts.some(x => this.worksFor(x));
    }

    private async _openContactModal(contact): Promise<void> {
        await this._contactModalService.openContactDialog(contact.contactID, ContactModalOrigin.EntityContact, null, this.entity);
    }

    private async _openRoleModal(contact): Promise<void> {
        const params = {
            contact,
            entity: this.entity,
            roles: this.roles
        } as EditRoleModalParams;
        const result = await this._weissmanModalService.showAsync(EditRoleModalComponent, params, 'modal-md');

        if (result) {
            this.contactsUpdated.emit();
        }
    }

    private _setRowData(): void {
        if (!this._gridApi) {
            return;
        }

        this._contacts.forEach(x => {
            x.contactRoleAssociations = x.contactRoleAssociations.filter(y => {
                return y.entityID === this.entity.id
                    && y.entityType.toLowerCase() === this.entity.type.toLowerCase();
            });
            x.contactRoleAssociations.sort((a, b) => a.roleDesc.localeCompare(b.roleDesc));
        });

        this._gridApi.setRowData(this._contacts);
    }
}
