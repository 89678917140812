import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { AceModule } from '../UI-Lib/ACE/ace.module';
import { DiagnosticsComponent } from './diagnostics.component';
import { AttachmentDownloadDiagnosticComponent } from './Attachment-Download/attachmentDownloadDiagnostic.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WeissmanSharedModule,
        AceModule,
        UIRouterUpgradeModule
    ],
    declarations: [
        DiagnosticsComponent,
        AttachmentDownloadDiagnosticComponent
    ],
    exports: [
        DiagnosticsComponent
    ]
})
export class DiagnosticsModule {
}
