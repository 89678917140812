import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SupportInstanceRepository } from '../../Compliance/Repositories/supportInstance.repository';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subject } from 'rxjs';
import { UserService } from '../../Account/user.service';
import { takeUntil } from 'rxjs/operators';
import { UserInstanceService } from '../../User/userInstance.service';
import { ToastrService } from 'ngx-toastr';

export interface SupportInstanceDeactivateInstancePendingComponentParams {
    instanceId: number;
}
@Component({
    selector: 'support-instance-deactivate-instance-pending',
    templateUrl: './supportInstanceDeactivateInstancePending.component.html',
    styleUrls: ['./supportInstanceDeactivateInstancePending.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SupportInstanceDeactivateInstancePendingComponent implements OnInit, OnDestroy, IWeissmanModalComponent<SupportInstanceDeactivateInstancePendingComponentParams, Core.SupportInstanceInfoModel>{
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _formBuilder: UntypedFormBuilder,
        private readonly _supportInstanceRepository: SupportInstanceRepository,
        private readonly _userService: UserService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _toastr: ToastrService
    ) {}

    params: SupportInstanceDeactivateInstancePendingComponentParams;
    result: Core.SupportInstanceInfoModel;
    reasonLength: number = 0;
    maxReasonLength: number = 1000;
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    form: UntypedFormGroup;

    private destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.form = this._formBuilder.group({
            reason: [null, [Validators.required, Validators.maxLength(this.maxReasonLength)]],
        });

        this.form.controls['reason'].valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(x => this.reasonLength = (x || '').length);
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgId);

        const request: Core.SupportInstanceRequestModel = {
            instanceId: this.params.instanceId,
            reason: this.form.get('reason').value
        }

        try {
            this.result = await lastValueFrom(this._supportInstanceRepository.deactivateInstancePending(request));
            this._bsModalRef.hide();

            this._toastr.success(`The status of ${this.result.instanceName} instance was successfully changed to "Pending Inactive".`)
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    onReasonChange(reason: string) {
        this.reasonLength = (reason || '').length;
    }
}
