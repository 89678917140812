import { ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxButtons, MessageBoxResult, MessageBoxService } from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { InstanceRights, Roles, RestrictService } from '../Common/Permissions/restrict.service';
import { NavigationService } from '../Layout/navigation.service';
import { DescriptorHelperService } from './descriptor.helper.service';
import { DescriptorPicklistService } from './descriptor.picklist.service';
import { DescriptorService } from './descriptor.service';
import { TimerService } from '../UI-Lib/Utilities';

import * as _ from 'lodash';
import { UserInstanceService } from '../User/userInstance.service';

const warningMessage = 'Editing is in progress. Are you sure you want to leave?';
enum DescriptorFieldTypes {
    Picklist = Core.DescriptorFieldTypes.Picklist as number
}

@Component({
    selector: 'property-characteristics',
    templateUrl: './property.characteristics.component.html',
    styleUrls: ['./property.characteristics.component.scss']
})
export class PropertyCharacteristicsComponent implements OnInit {
    constructor(
        private readonly _descriptorHelperService: DescriptorHelperService,
        private readonly _navigationService: NavigationService,
        private readonly _descriptorService: DescriptorService,
        private readonly _toastrService: ToastrService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _descriptorPicklistService: DescriptorPicklistService,
        private readonly _restrictService: RestrictService,
        private readonly _timer: TimerService,
        private readonly _userInstanceService: UserInstanceService,){}

    @ViewChildren('formRow') rows: QueryList<ElementRef>;
    @ViewChild('picklistValuesModal', { static: true }) picklistValuesModal: ModalDirective;
    dataLoading: boolean = false;
    descriptorIsSaving: boolean = false;
    canEdit: boolean = false;
    hasComplianceFeatures: boolean = false;
    selectedCategoryId: Core.DescriptorCategoryEnum;
    descriptors: Core.DescriptorDTO[];
    descriptorToEdit: Core.DescriptorDTO;
    categories: Weissman.Model.Descriptors.DescriptorCategory[];
    usages: Weissman.Model.Descriptors.DescriptorUsage[];
    fieldTypes: Weissman.Model.Descriptors.DescriptorFieldType[];
    assetUsages: any[];
    aRCCUsages: any[];
    columns: string[];
    picklist: Core.DescriptorPickListModel[];
    FieldTypeEnum: typeof DescriptorFieldTypes = DescriptorFieldTypes;
    deletingDescriptorId: number;
    isRyanInstance: boolean;


    async ngOnInit(): Promise<void> {
        this.dataLoading = true;
        this.isRyanInstance = this._userInstanceService.RyanInstanceId == this._userInstanceService.getSelectedInstance().instanceId;

        try {
            this.canEdit = this._restrictService.isInRole(Roles.PROPCHAREDIT);
            this.hasComplianceFeatures = this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW);

            this.columns = this._descriptorHelperService.getSetup();
            if (!this.isRyanInstance) {
                const arccUsageColumnId = this.columns.findIndex(x => x === 'ARCC Usage');
                this.columns.splice(arccUsageColumnId, 1);
            }

            const categories = await this._descriptorHelperService.getCategories();
            this.categories = _.sortBy(categories, 'name');
            this.selectedCategoryId = this.categories[0].descriptorCategoryID;

            if (!this.hasComplianceFeatures) {
                this.categories = _.reject(this.categories, x => _.startsWith(x.name, 'Asset'));
                this.columns.splice(this.columns.indexOf('Asset Usage'), 1);
            }

            this.fieldTypes = await this._descriptorHelperService.getFieldTypes();
            this.usages = await this._descriptorHelperService.getUsages();

            // override the select values for assets
            this.assetUsages = JSON.parse(JSON.stringify(this.usages));
            this.assetUsages = _.map(this.assetUsages,
                usage => {
                    if (usage.name === 'Frequent') {
                        usage.name = 'Required';
                    } else if (usage.name === 'Occasional') {
                        usage.name = 'Optional';
                    }

                    return usage;
                });

            this.aRCCUsages = JSON.parse(JSON.stringify(this.usages));
            this.aRCCUsages = this.aRCCUsages
                .filter(x => x.descriptorUsageID !== 2)
                .map(x => {
                    x.name = x.descriptorUsageID === 1 ? 'Yes' : 'No';
                    return x;
                });

            await this._loadDescriptors();
        } finally {
            this.dataLoading = false;
        }
    }

    async getDescriptors(): Promise<void> {
        this.dataLoading = true;

        try {
            await this._loadDescriptors();
        } finally {
            this.dataLoading = false;
        }
    }

    getDescriptorUsage(id: number): string {
        const usage = _.find(this.usages, { descriptorUsageID: id });
        return usage ? usage.name : '';
    }

    getAssetDescriptorUsage(id: number): string {
        const assetUsage =  _.find(this.assetUsages, { descriptorUsageID: id });
        return assetUsage ? assetUsage.name : '';
    }

    getARCCDescriptorUsage(id: number): string {
        const usage =  _.find(this.aRCCUsages, { descriptorUsageID: id });
        return usage ? usage.name : '';
    }

    getDescriptorFieldType(id: number): string {
        const fieldType =  _.find(this.fieldTypes, { descriptorFieldTypeID: id });
        return fieldType ? fieldType.name : '';
    }

    editDescriptor(descriptor: Core.DescriptorDTO): void {
        this.descriptorToEdit = _.cloneDeep(descriptor);
        this._navigationService.enableNavWarning(warningMessage);
    }

    cancelEdit(descriptor: Core.DescriptorDTO): void {
        if (!descriptor.descriptorID) {
            this.descriptors.pop();
        }

        this.descriptorToEdit = undefined;
        this._navigationService.disableNavWarning();
    }

    addDescriptor(): void {
        const newDescriptor = {
            assetUsageID: Core.DescriptorUsageEnum.Never,
            assessorUsageID: Core.DescriptorUsageEnum.Never,
            collectorUsageID: Core.DescriptorUsageEnum.Never,
            siteUsageID: Core.DescriptorUsageEnum.Never,
            parcelUsageID: Core.DescriptorUsageEnum.Never,
            categoryID: this.selectedCategoryId,
            fieldTypeID: Core.DescriptorFieldTypes.Number,
            formatID: 1,
            pickListGroupID: null,
            enabled: true,
            sequenceNumber: _.get(_.maxBy(this.descriptors, 'sequenceNumber'), 'sequenceNumber', 0) + 1
        } as Core.DescriptorDTO;

        this.descriptors.push(newDescriptor);
        this.editDescriptor(newDescriptor);

        this._timer.setTimeout(() => this.rows.last.nativeElement.children[0].children[0].focus());
    }

    async saveDescriptor(idx: number): Promise<void> {
        const message = this._getValidationError();

        if(message) {
            this._toastrService.error(message);
            return Promise.resolve();
        }

        this.descriptorIsSaving = true;

        try {
            if (this.descriptorToEdit.descriptorID) {
                await this._descriptorService.update(this.descriptorToEdit);
                this.descriptors[idx] = this.descriptorToEdit;
            } else {
                this.descriptors[idx] = await this._descriptorService.create(this.descriptorToEdit);
            }

            this.descriptorToEdit = undefined;
            this._navigationService.disableNavWarning();
        } finally {
            this.descriptorIsSaving = false;
        }
    }

    async deleteDescriptor(descriptor: Core.DescriptorDTO): Promise<void>  {
        const result: number = await this._messageBoxService.open({
            message: `Are you sure you want to delete ${descriptor.name}?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if (!result) {
            return Promise.resolve();
        }

        if (result === MessageBoxResult.OK) {
            this.deletingDescriptorId = descriptor.descriptorID;

            try {
                const deleteResult = await this._descriptorService.deleteById(descriptor.descriptorID);

                if(deleteResult) {
                    descriptor.enabled = false;
                } else {
                    _.remove(this.descriptors, descriptor);
                }
            } finally {
                this.deletingDescriptorId = undefined;
            }
        }

        return Promise.resolve();
    }

    async showPicklistValuesModal(descriptor: Core.DescriptorDTO): Promise<void> {
        if(descriptor.pickListGroupID) {
            const picklist = await this._descriptorPicklistService.getByPicklistGroupId(descriptor.pickListGroupID);
            this.picklist = _.sortBy(picklist, 'name');
        }

        this.picklistValuesModal.show();
    }

    shouldDisableEditButtons(descriptor: Core.DescriptorDTO): boolean {
        return descriptor.isSystemControlled || descriptor.descriptorID === this.deletingDescriptorId || !!this.descriptorToEdit;
    }

    private async _loadDescriptors(): Promise<void> {
        const descriptors = await this._descriptorService.getByCategoryId(this.selectedCategoryId);
        this.descriptors = _.chain(descriptors)
            .map(x => {
                x.formatID = x.formatID || 1;
                return x;
            })
            .sortBy('name')
            .value();
    }

    private _getValidationError(): string {
        if(this.descriptorToEdit.validation.maxValue
            && this.descriptorToEdit.validation.minValue
            && this.descriptorToEdit.validation.maxValue < this.descriptorToEdit.validation.minValue) {
            return 'Min Value must be less than Max!';
        }

        if(this.descriptorToEdit.validation.maxValue < 0
            || this.descriptorToEdit.validation.minValue < 0
            || this.descriptorToEdit.validation.maxLength < 0) {
            return 'Value cannot be negative!';
        }

        return '';
    }
}
