export class Assessment {
    constructor(annualYearID: number, revisionNumber: number, status: number, efAction: string, annualAssessmentDetails: AnnualAssessmentDetail[]) {
        this.annualYearID = annualYearID;
        this.revisionNum = revisionNumber;
        this.status = status;
        this.annualAssessmentDetails = annualAssessmentDetails;
        this.efAction = efAction;
        this.revisionDesc = '';
    }

    allocationId: number;
    annualAssessmentDetails: AnnualAssessmentDetail[];
    annualAssessmentID: number;
    annualYearID: number;
    revisionNum: number;
    status: number;
    finalRevision: boolean;
    revisionDate: Date;
    revisionDesc: string;
    hasAttachments: boolean;
    appealDeadLineID: number;
    appealDeadline: Date;
    originalDeadline: Date;
    hasComments: boolean;
    efAction: string;
    rowVersion: any;
    annualYearRowVersion: any;
    hasInProgressIntakeItems: boolean;

}

export class AnnualAssessmentDetail {
    assessmentClassComponent: any; //I don't know what this is
    assessmentClassRatio: AssessmentClassRatio;
    annualAssessmentDetailID: number;
    annualAssessmentID: number;
    assessmentClassComponentID: number;
    assessmentClassRatioID: number;
    ratio: number;
    displayRatio: number;
    assessedValue: number;
    fairMarketValue: number;
    fmvOverridden: boolean;
    componentName: string;
    assessmentComponentTypeID: number;
    efAction: string;
    sequence: number;
}

export class AssessmentClassRatio
{
    assessmentClassRatioID: number;
    assessmentClassID: number;
    assessorID: number;
    taxYear: number;
    assessmentRatio: number;
    efAction: string;
}
