import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParcelRepository } from '../../Core-Repositories';
import { Site } from '../Site/Site.Model';
import { ParcelCollectorTaxAuthorities } from './Info/Tax-Rates/collectorTaxRateArea.component';

@Injectable()
export class ParcelService {
    constructor(private readonly _parcelRepository: ParcelRepository) {}

    private _parcelCollectorTaxAuthoritiesSubject: BehaviorSubject<ParcelCollectorTaxAuthorities[]> = new BehaviorSubject<ParcelCollectorTaxAuthorities[]>([]);
    private _parcelCount: number;

    get parcelCount(): number {
        return this._parcelCount;
    }

    set parcelCount(parcelCount: number) {
        this._parcelCount = parcelCount;
    }

    get parcelCollectorTaxAuthorities$(): Observable<ParcelCollectorTaxAuthorities[]> {
        return this._parcelCollectorTaxAuthoritiesSubject.asObservable();
    }

    setParcelCollectorTaxAuthorities(parcelCollectorTaxAuthorities: ParcelCollectorTaxAuthorities[]): void {
        this._parcelCollectorTaxAuthoritiesSubject.next(parcelCollectorTaxAuthorities);
    }

    resolveView(result) {
        result.taxRateSetup.forEach((trs) => {
            const pc = result.parcel.parcelCollectors.find(x => x.parcelCollectorID === trs.parcelCollectorId);
            Object.assign(pc, trs);
            pc.hasTaxRateSetup = !!(trs.taxRateAreaName || trs.taxAuthorityCount > 0);
            pc.hasAvailableTaxSetup = trs.hasAvailableTaxSetup;
        });
        return result;
    }

    getView(parcelId: number, navigationRequest: boolean): Promise<any> {
        return lastValueFrom(this._parcelRepository.getView(parcelId, navigationRequest)).then(result => this.resolveView(result));
    }

    get(callParams: any): Promise<any> {
        return lastValueFrom(this._parcelRepository.get(callParams));
    }

    getAvailableYears(siteId: number): Promise<number[]> {
        return lastValueFrom(this._parcelRepository.getAvailableYears(siteId));
    }

    getParcelLatestAssessmentYear(parcelId: number): Promise<any> {
        return lastValueFrom(this._parcelRepository.getParcelLatestAssessmentYear(parcelId));
    }

    // yearValue will be 0 fo rthe most recent one
    //
    getParcelsInfoForSingleYear(siteId: number, yearValue: number) {
        return lastValueFrom(this._parcelRepository.getParcelsInfoForSingleYear(siteId, yearValue))
            .then(result => {
                // Hack to set the parcel count from angularjs.
                // Needed for site state readonly/edit logic
                this.parcelCount = result.length;
                return result;
            });
    }

    update(parcel: Weissman.Model.Domain.Parcel): Promise<Core.ParcelViewDTO> {
        return lastValueFrom(this._parcelRepository.update(parcel)).then(result => {
            return this.resolveView(result);
        });
    }

    post(parcel: Core.ParcelModel): Promise<Site> {
        return lastValueFrom(this._parcelRepository.post(parcel));
    }

    getNavigationInfo(parcelId: number): Promise<any> {
        return lastValueFrom(this._parcelRepository.getNavigationInfo(parcelId));
    }

    getParcelLinkageTypes(): Promise<any> {
        return lastValueFrom(this._parcelRepository.getParcelLinkageTypes());
    }

    getLinkedParcels(parcelId: number, includeUnlinked: boolean): Promise<any> {
        return lastValueFrom(this._parcelRepository.getLinkedParcels(parcelId, includeUnlinked));
    }

    addNewLinks(payload: any): Promise<any> {
        return lastValueFrom(this._parcelRepository.addNewLinks(payload));
    }

    updateLinks(payload: any): Promise<any> {
        return lastValueFrom(this._parcelRepository.updateLinks(payload));
    }

    getParcelListBySiteId(siteId: number, excludeInactive: boolean): Promise<any> {
        return lastValueFrom(this._parcelRepository.getParcelListBySiteId(siteId, excludeInactive));
    }

    getParcelYears(parcelId: number): Promise<any> {
        return lastValueFrom(this._parcelRepository.getParcelYears(parcelId));
    }

    getOptimusLinkInfo(parcelId: number): Promise<any> {
        return lastValueFrom(this._parcelRepository.getOptimusLinkInfo(parcelId));
    }

    bulkUpdate(bulkUpdateModel: Core.ParcelBulkUpdateModel): Promise<void> {
        return lastValueFrom(this._parcelRepository.bulkUpdate(bulkUpdateModel));
    }

    getParcelAssessmentClasses(parcelId: number): Promise<any> {
        return lastValueFrom(this._parcelRepository.getParcelAssessmentClasses(parcelId));
    }

    deleteParcel(parcelId: number): Promise<any> {
        return lastValueFrom(this._parcelRepository.deleteParcel(parcelId));
    }

    getAcctNumberTypes(): Promise<Weissman.Model.Domain.AccountNumberType[]> {
        return lastValueFrom(this._parcelRepository.getAcctNumberTypes());
    }
}
