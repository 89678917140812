<div class="page-header as-header" *ngIf="!loading">
    <h1 class="as-title">{{ report.displayName }}
        <span *ngIf="report.variantName"> - {{ report.variantName }}</span>
    </h1>
    <div class="as-header-functions" style="padding-top: 6px; padding-bottom: 0px;">
        <div>
            <button class="ace-btn has-icon btn-text icon-dark" (click)="revokeReportShare()" *ngIf="showRevokeReportButton()" title="Stop Sharing">
                <i class="fa fa-share-alt fa-lg" aria-hidden="true"></i>
            </button>
        </div>
        <div>
            <button class="ace-btn has-icon btn-text icon-dark" (click)="refresh()" title="Reload">
                <i class="fa fa-refresh fa-lg"></i>
            </button>
        </div>
        <div *ngIf="saveVisible">
            <button class="ace-btn has-icon btn-text icon-dark" (click)="saveReport()" title="Save">
                <i class="fa fa-save fa-lg"></i>
            </button>
        </div>
        <div *ngIf="saveAsVisible" helpTooltip [helpContentId]="isSingleInstanceSelected ? 'report-manager.save-as' : 'report-manager.all-instances-selected' ">
            <button class="ace-btn has-icon btn-text icon-dark ws-vertical-align-middle" (click)="saveReportAs()" [disabled]="!isSingleInstanceSelected">
                <ws-save-as-icon class="ws-stretch"></ws-save-as-icon>
            </button>
        </div>
    </div>
</div>
<div class="text-center" style="margin-top: 50px;" *ngIf="loading">
    <i class="fa fa-spinner fa-pulse fa-5x"></i>
</div>
<div class="row" *ngIf="!loading">
    <div class="col-lg-12">
        <div *ngIf="report.reportTypeId === reportTypes.UserAccess" style="margin-bottom: 20px;">
            <label class="radio-inline">
                <input type="radio" name="start-with" [(ngModel)]="report.criteria.startWith" [value]="StartWithEnum.Company"> Start with Company
            </label>
            <label class="radio-inline">
                <input type="radio" name="start-with" [(ngModel)]="report.criteria.startWith" [value]="StartWithEnum.SingleUser"> Start with Single User
            </label>
        </div>
        <div *ngIf="report.criteria.startWith === StartWithEnum.SingleUser || report.reportTypeId === reportTypes.UserCSR" style="margin-bottom: 20px; width: 300px;">
            <user-picker [search]="userSearch" [currentContact]="report.criteria.selectedContact" [includeCompany]="true" [usersOnly]="true" (contactChanged)="setSelectedContact($event)"></user-picker>
        </div>
        <report-manager-select-entities
                [report]="report"
                [entitySelection]="report.criteria.entitySelection"
                [hideTopLevelCompanyPicker]="report.criteria.hideTopLevelCompanyPicker"
                [showSupportInstancePicker]="report.criteria.showSupportInstancePicker"
                (reportFavoriteChange)="updateFavoriteStatus($event)"
                *ngIf="showEntitiesSelector">
        </report-manager-select-entities>
    </div>
</div>

<div class="row" *ngIf="!loading && report.reportTypeId !== reportTypes.UserCSR">
    <div class="col-lg-12">
        <div class="card panel-flat-color secondary-panel">
            <div class="panel-flat-header" style="margin-bottom: 0;">
                <div class="header-icon">
                    <i class="fa fa-filter"></i>
                </div>
                <h3 *ngIf="report.reportTypeId !== reportTypes.ReturnScheduleSummary">Further Limit the Contents of your Report</h3>
                <h3 *ngIf="report.reportTypeId === reportTypes.ReturnScheduleSummary">Limit the Report Content</h3>
            </div>
            <div class="panel-flat-body" style="min-height: 80px;">
                <div style="margin-top:10px;">
                    <further-limit-panel-psr *ngIf="report.reportTypeId === reportTypes.ProjectStatus" [report]="report" [availableYears]="availableYears" [hasRyanPrivatePermission]="hasRyanPrivatePermission"></further-limit-panel-psr>
                    <further-limit-panel-psr *ngIf="report.reportTypeId === reportTypes.AppealRecommendation" [report]="report" [availableYears]="availableYears" [hasRyanPrivatePermission]="hasRyanPrivatePermission"></further-limit-panel-psr>
                    <further-limit-panel-site *ngIf="report.reportTypeId === reportTypes.Site" [report]="report" [availableYears]="availableYears" [hasRyanPrivatePermission]="hasRyanPrivatePermission"></further-limit-panel-site>
                    <further-limit-panel-budget *ngIf="report.reportTypeId === reportTypes.Budget" [report]="report" [availableYears]="availableYears" [hasRyanPrivatePermission]="hasRyanPrivatePermission"></further-limit-panel-budget>
                    <further-limit-panel-budget-variance *ngIf="report.reportTypeId === reportTypes.BudgetVariance" [report]="report" [hasRyanPrivatePermission]="hasRyanPrivatePermission"></further-limit-panel-budget-variance>
                    <further-limit-panel-maar *ngIf="report.reportTypeId === reportTypes.Maar" [report]="report" [availableYears]="availableYears"></further-limit-panel-maar>
                    <further-limit-panel-progress-status *ngIf="report.reportTypeId === reportTypes.ProgressStatus" [report]="report" [availableYears]="availableYears"></further-limit-panel-progress-status>
                    <further-limit-panel-schedules-summary *ngIf="report.reportTypeId === reportTypes.ReturnScheduleSummary" [report]="report" [modalParameters]="modalParameters"></further-limit-panel-schedules-summary>
                    <further-limit-panel-asset-detail *ngIf="report.reportTypeId === reportTypes.ReturnAssetDetail" [report]="report" [modalParameters]="modalParameters"></further-limit-panel-asset-detail>
                    <further-limit-panel-user-activity *ngIf="report.reportTypeId === reportTypes.UserActivity" [report]="report"></further-limit-panel-user-activity>
                    <further-limit-panel-user-activity *ngIf="report.reportTypeId === reportTypes.UserAccess" [report]="report"></further-limit-panel-user-activity>
                    <further-limit-panel-support-instance-escalation-activity *ngIf="report.reportTypeId === reportTypes.SupportInstanceEscalationActivity" [report]="report"></further-limit-panel-support-instance-escalation-activity>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row" *ngIf="!loading && report.reportTypeId !== reportTypes.ProgressStatus && report.reportTypeId !== reportTypes.Maar && report.reportTypeId !== reportTypes.UserCSR">
    <div class="col-lg-12">
        <div class="card panel-flat-color secondary-panel">
            <div class="panel-flat-header" style="margin-bottom: 0;">
                <div class="header-icon">
                    <i class="fa fa-file-text-o"></i>
                </div>
                <h3>Format the Report Output</h3>
                <div class="float-end">
                </div>
            </div>
            <format-output-panel-psr *ngIf="report.reportTypeId === reportTypes.ProjectStatus" [report]="report"></format-output-panel-psr>
            <format-output-panel-psr *ngIf="report.reportTypeId === reportTypes.AppealRecommendation" [report]="report"></format-output-panel-psr>
            <format-output-panel-site *ngIf="report.reportTypeId === reportTypes.Site" [report]="report"></format-output-panel-site>
            <format-output-panel-budget *ngIf="report.reportTypeId === reportTypes.Budget" [report]="report"></format-output-panel-budget>
            <format-output-panel-budget-variance *ngIf="report.reportTypeId === reportTypes.BudgetVariance" [report]="report"></format-output-panel-budget-variance>
            <format-output-panel-schedules-summary *ngIf="report.reportTypeId === reportTypes.ReturnScheduleSummary" [report]="report" [scheduleName]="report.criteria.scheduleName"></format-output-panel-schedules-summary>
            <format-output-panel-asset-detail *ngIf="report.reportTypeId === reportTypes.ReturnAssetDetail" [(report)]="report" [modalParameters]="modalParameters"
                                              [scheduleName]="report.criteria.scheduleName" [priorReturnStatusName]="report.criteria.priorReturnStatusName"></format-output-panel-asset-detail>
            <format-output-panel-user-activity *ngIf="report.reportTypeId === reportTypes.UserActivity" [report]="report"></format-output-panel-user-activity>
            <format-output-panel-user-activity *ngIf="report.reportTypeId === reportTypes.UserAccess" [report]="report"></format-output-panel-user-activity>
            <format-output-panel-support-instance-escalation-activity *ngIf="report.reportTypeId === reportTypes.SupportInstanceEscalationActivity" [report]="report"></format-output-panel-support-instance-escalation-activity>
        </div>
    </div>
</div>

<div class="float-end" *ngIf="!loading && showRequestReportButton()" style="margin-bottom: 20px;">
    <button
        class="ace-btn btn-primary"
        [disabled]="requestButtonIsDisabled"
        (click)="requestReport()">Request Report</button>
</div>
