import { Component, ElementRef, Input, OnDestroy, OnChanges, Output, EventEmitter, Renderer2, NgZone } from '@angular/core';
import { PDFViewerBase, FieldOverride } from '../../Common/PDFViewer/pdfViewerBase';
import { AppealApplicationService } from '../appeal.application.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { AppealFormItem, AppealFormOverrideModel } from '../appeal.application.model';
import { AppealApplicationSetTopModalComponent, AppealApplicationSetTopModalParams } from '../Set-Top-Modal/appealApplicationSetTopModal.component';
import { ScriptLoaderService } from 'src/app/UI-Lib/Utilities/scriptLoader.service';
import { Constants } from 'src/app/constants.new';


@Component({
    selector: 'appeal-application-viewer',
    templateUrl: './appealApplicationViewer.component.html'
})
export class AppealApplicationViewerComponent extends PDFViewerBase implements OnChanges, OnDestroy {
    constructor(
        private _elementRef: ElementRef,
        public appealApplicationService: AppealApplicationService,
        private _busyIndicatorService: BusyIndicatorService,
        _renderer: Renderer2,
        _zone: NgZone,
        _modalService: WeissmanModalService,
        _scriptLoaderService: ScriptLoaderService,
        _constants: Constants
    ) {
        super('#appeal-output', _modalService, _scriptLoaderService, _constants, _renderer, _zone);
     }

    @Input() selectedForm: AppealFormItem;
    @Input() appealFormList: AppealFormItem[];
    @Output() topAppealSelected: EventEmitter<number> = new EventEmitter();

    showFieldLocation: boolean = false;
    // appealIds: number[];
    // appealId: number;
    //additionalAccountAppealIds: number[];
    isFormLoading: boolean = false;
    // multiAccountData: AppealFormReference[];
    overrideAccountIndexLookup: { [appealFormRevisionOverrideId: number]: number };


    async ngOnChanges(): Promise<void> {
        this.isReadOnly = false;
        this.isAppealForm = true;
        // this.appealIds = this.appealFormList.map(af => af.appealId);

        if(this.selectedForm) {
            await this.loadForm();
        }
    }

    ngOnDestroy(): void {
        this.disposePdfDocument();
    }

    onApplyEditsToAllChange(value: boolean): void {
        this.appealApplicationService.setApplyEditsToAll(value);
    }

    processAppealOverrideMultiAccountIndexes(overrides: AppealFormOverrideModel[]): AppealFormOverrideModel[] {
        this.overrideAccountIndexLookup = {};
        const multiAccountOverrides: string[] = [];
        const topAppealFormRevisionId = this.selectedForm.appealFormRevisionId;
        overrides.forEach(o => {
            let result = 0;
            if (o.appealFormRevisionId !== topAppealFormRevisionId) {
                result = this.selectedForm.additionalAccounts.map(a => a.appealFormRevisionId).indexOf(o.appealFormRevisionId) + 1;
            }
            this.overrideAccountIndexLookup[o.appealFormRevisionOverrideId] = result;
            multiAccountOverrides.push(o.fieldName);
        });
        // Note that we normally don't need a multi-account index lookup entry for fields on the top form (that's where overrides go by
        // default), however if multiple overrides exist for the same field and one of them is on the top form, it needs an entry here
        // to disambiguate it.
        if (multiAccountOverrides.length > 0) {
            overrides
                .filter(o =>
                    o.appealFormRevisionId === topAppealFormRevisionId &&
                    multiAccountOverrides.some(f => f === o.fieldName))
                .forEach(o => {
                    this.overrideAccountIndexLookup[o.appealFormRevisionId] = 0;
                });
        }

        return overrides;
    }

    async selectTopAppeal(): Promise<void> {
        const params: AppealApplicationSetTopModalParams = {
            appealFormList: [this.selectedForm].concat(this.selectedForm.additionalAccounts)
        };

        const result = await this._modalService.showAsync(AppealApplicationSetTopModalComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this.topAppealSelected.emit(result);
    }

    async loadForm(): Promise<void> {
        this.disposePdfDocument();

        if (!this.selectedForm) return;

        const busyRef = this._busyIndicatorService.show({ message: 'Loading preview' });
        this.isFormLoading = true;
        const appealFormReference = this.appealApplicationService.toAppealFormReference(this.selectedForm);
        try {
            const response = await Promise.all(this.selectedForm.additionalAccounts.length === 0 ? [
                    this.appealApplicationService.getFormTemplate(appealFormReference),
                    this.appealApplicationService.getFormDefaultValues(appealFormReference),
                    this.appealApplicationService.getFormOverrides(appealFormReference).then(o => this.processAppealOverrideMultiAccountIndexes(o))
                ]: [
                    this.appealApplicationService.getMultiAccountFormTemplate(appealFormReference),
                    this.appealApplicationService.getMultiAccountFormDefaultValues(appealFormReference),
                    this.appealApplicationService.getMultiAccountFormOverrides(appealFormReference).then(o => this.processAppealOverrideMultiAccountIndexes(o))
                ]
            );

            await this._loadPreviewForForm(response[0], response[1], response[2]);

        } finally {
            await busyRef.hide();
            this.isFormLoading = false;
        }
    }

    async onPdfFieldChange(event): Promise<void> {
        if (event.target) {
            const id = event.target.parentElement.getAttribute('data-annotation-id');
            const fieldData = this._idFieldMap[id];

            //todo: is there an annotation or configuration to do this automatically?
            // workaround to set the checkbox value to checked/unchecked value
            if (event.target.type === 'checkbox') {
                event.target.value = event.target.checked ? 'On' : 'Off';
            }

            const newValue = event.target.value;

            const shouldApplyEditsToAll = this.appealApplicationService.shouldApplyEditsToAll;
            if (shouldApplyEditsToAll) {
                // Normally disable field during override, but in this case multiple fields may get simultaneously overridden;
                // show the loading
                const busyRef = this._busyIndicatorService.show({ message: 'Saving overrides...' });
                this.isFormLoading = true;
                try {
                    await this._processSingleFieldChange(fieldData, newValue, true);
                    if (this.getUIAccountIndex(fieldData.fieldName) !== null) {
                        // We've applied the override to this specific field and to all other forms, but now
                        // we need to also apply it to other accounts on this particular form (note this logic
                        // is also present in _deleteOverride)
                        const accountlessName = this.removeUIAccountIndex(fieldData.fieldName);
                        // TODO: We should create an API endpoint that does this update in bulk and returns the
                        // affected RowVersions so we don't have to individually call it for each override.
                        for (let i = 0; i < this._accountlessNameIdMap[accountlessName].length; i++) {
                            const relatedId = this._accountlessNameIdMap[accountlessName][i];
                            if (relatedId !== id) {
                                const relatedFieldData = this._idFieldMap[relatedId];
                                await this._processSingleFieldChange(relatedFieldData, newValue, false);
                            }
                        }
                    }
                }
                finally {
                    this.isFormLoading = false;
                    await busyRef.hide();
                }
            }
            else {
                await this._processSingleFieldChange(fieldData, newValue, false);
            }
        }
    }

    protected resolveAccountIndex(override: Compliance.FormOverrideModel): number {
        if (!override) { return null; }
        return this.overrideAccountIndexLookup[(<AppealFormOverrideModel>override).appealFormRevisionOverrideId];
    }

    protected getElementRef(): ElementRef {
        return this._elementRef;
    }

    protected isShowingAnnotations(): boolean {
        return true;
    }

    protected async _deleteOverride(override: FieldOverride, rowVersion: number[]) {
        if (this.appealApplicationService.shouldApplyEditsToAll) {
            const busyRef = this._busyIndicatorService.show({ message: 'Removing overrides...' });
            this.isFormLoading = true;
            try {
                await this._deleteSingleOverride(override, rowVersion, true);
                const accountIndex = this.getUIAccountIndex(override.fieldName);
                if (accountIndex !== null) {
                    // Same deal as in saved override; apply to other accounts on this form one at a time, and
                    // TODO: Make this not one at a time
                    const accountlessName = this.removeUIAccountIndex(override.fieldName);
                    const fieldOverrides = this.overrideHolder.overrides.filter(o =>
                        this.getUIAccountIndex(o.fieldName) !== accountIndex &&
                        this.removeUIAccountIndex(o.fieldName) === accountlessName);
                    for (let i = 0; i < fieldOverrides.length; i++) {
                        const fieldOverride = fieldOverrides[i];
                        await this._deleteSingleOverride(fieldOverride, this.overrideHolder.getSaved(fieldOverride.fieldName).rowVersion, false);
                    }
                }
            }
            finally {
                this.isFormLoading = false;
                await busyRef.hide();
            }
        }
        else {
            await this._deleteSingleOverride(override, rowVersion, false);
        }
    }

    private async _processSingleFieldChange(
        fieldData: { fieldName: string, fullName: string, elements: Element[] },
        newValue: string,
        shouldApplyEditsToAll: boolean
    ): Promise<void> {
        /* Note: this comment is years after the fact, so hopefully I'm remembering this right.
         * Basically, a "full field name" is something like:
         *
         * topmostSubform[0].Page1[0].PP\.TaxpayerAddress\.State[0]
         *
         * Where PP\.TaxpayerAddress\.State is the "short name". The short name can be split into parts delimited by \. (so in
         * the example above PP, TaxpayerAddress, and State). The first component of the short name is the "namespace", in this
         * case PP. Basically the PP namespace signifies this as a field PropertyPoint should attempt to process, everything
         * else is ignored. The second part is the name. All remaining parts are parameters.
         *
         * Originally, overrides were stored with the full name, but this became a problem after multi-account forms started being
         * used, since the first part of the field name depends on how the PDF is constructed, which then began to vary. The new
         * method stored the short name with the account parameter removed. For example, you may have something like
         * topmostSubform[0].Page1[0].PP\.AccountNumber\.Account-1[0]; that full name would then be converted to PP\.AccountNumber
         * (the short name with the account number removed). So this "old format override" code is to deal with overrides that were
         * stored in the database before the change was made.
         *
         * An additional layer of complexity is in the account number parameters; PDFs have account number parameters starting from
         * 1 for every page in the PDF, but the UI needs account number indexes starting from 1 once at the start of the whole PDF,
         * so the "uiaccountindex" parameter tracks that. You can image a PDF like this:
         * Page 1
         *      PP\.OOV\.Schedule-Land (UIAccountIndex-1)
         * Page 2
         *      PP\.OOV\.Schedule-Land.Account-1 (UIAccountIndex-2)
         *      PP\.OOV\.Schedule-Land.Account-2 (UIAccountIndex-3)
         *      PP\.OOV\.Schedule-Land.Account-3 (UIAccountIndex-4)
         *      PP\.OOV\.Schedule-Land.Account-4 (UIAccountIndex-5)
         *      PP\.OOV\.Schedule-Land.Account-5 (UIAccountIndex-6)
         * Page 3
         *      PP\.OOV\.Schedule-Land.Account-1 (UIAccountIndex-7)
         *      PP\.OOV\.Schedule-Land.Account-2 (UIAccountIndex-8)
         *      PP\.OOV\.Schedule-Land.Account-3 (UIAccountIndex-9)
         *      PP\.OOV\.Schedule-Land.Account-4 (UIAccountIndex-10)
         *      PP\.OOV\.Schedule-Land.Account-5 (UIAccountIndex-11)
         *
         * So if you see references to UIAccountIndex, that's what it's talking about. When we store overrides, the account number
         * should not be present (so that it will track with the correct account regardless of the context), but when the PDF is
         * loaded the account index -> AppealFormRevisionId association needs to be tracked.
        */
        const oldFormatOverride = this._oldFormatOverrides[fieldData.fieldName];
        if (oldFormatOverride) {
            await this._deleteOverride(oldFormatOverride.override, oldFormatOverride.rowVersion);
        }

        fieldData.elements.forEach(element => (element as any).value = newValue);

        const overrideUIElement: FieldOverride = {
            formElements: fieldData.elements,
            overrideValue: newValue,
            fieldName: fieldData.fieldName
        };

        let rowVersion: number[] = null;
        let appealFormItem = this.selectedForm;

        const savedOverride = this.overrideHolder.getSaved(fieldData.fieldName);
        let currentAccountIndex = 0;
        const perAccountField = this._multiAccountFieldMap[fieldData.fullName] !== undefined;
        if (perAccountField) {
            currentAccountIndex = this._multiAccountFieldMap[fieldData.fullName];
            // If currentAccountIndex is "0", it's an override on the main form; otherwise it's one of the items in the addtional account list
            if (currentAccountIndex > 0) {
                appealFormItem = appealFormItem.additionalAccounts[currentAccountIndex - 1];
            }
        }
        if (savedOverride !== undefined) {
            if (this.resolveAccountIndex(savedOverride) === currentAccountIndex) {
                rowVersion = savedOverride.rowVersion;
            }
        }

        // persist
        const appealFormReference = this.appealApplicationService.toAppealFormReference(appealFormItem);
        const override: Compliance.AppealFormOverrideRequestModel = {
            inEdit: appealFormReference,
            otherReferences: [],
            fieldName: fieldData.fieldName,
            value: newValue,
            rowVersion: rowVersion
        };

        if(shouldApplyEditsToAll) {
            const topFormReference = this.appealApplicationService.toAppealFormReference(this.selectedForm);
            // Add other appealIds as long as they're not for this form (either by appealId or additional account)
            override.otherReferences = this.appealFormList
                .filter(x => ! this.appealApplicationService.allAppealIds(topFormReference).includes(x.appealId))
                .map(a => this.appealApplicationService.toAppealFormReference(a));
        }


        (overrideUIElement.formElements as any[]).forEach(element => { element.disabled = true; });
        const cleanedOverride = Object.assign({}, override, {
            fieldName: this.removeUIAccountIndex(fieldData.fieldName),
            // During an override, do not include additional multi-account references
            inEdit: Object.assign({}, override.inEdit, {
                additionalAccounts: []
            })
        });
        const allResults = await this.appealApplicationService.createUpdateOverride(cleanedOverride);
        const result = allResults.find(r => r.appealFormRevisionId === cleanedOverride.inEdit.appealFormRevisionId);
        if (perAccountField) {
            result.fieldName = this.setUIAccountIndex(result.fieldName, currentAccountIndex);
        }

        (overrideUIElement.formElements as any[]).forEach(element => {
            element.disabled = false;
            element.classList.add(this.MODIFIED_FIELD_CSS_CLASS);
        });
        this._createOverrideUI(overrideUIElement);
        this._deleteInfoMarker(overrideUIElement);
        if(result) {
            this.overrideHolder.saveOverride(result, fieldData.fieldName);
            this.overrideAccountIndexLookup[result.appealFormRevisionOverrideId] = currentAccountIndex;
        }

        // Use selected Id instead of override Id here; what we're really interested in is the
        // top-page appealId, not the account-specific appealId
        this.appealApplicationService.setOverrideChange(this.selectedForm.appealFormRevisionId, true);
    }

    private async _deleteSingleOverride(override: FieldOverride, rowVersion: number[], shouldApplyEditsToAll: boolean): Promise<void> {
        const oldFormatShortName = Object.keys(this._oldFormatOverrides).find(shortName => this._oldFormatOverrides[shortName].override === override);
        if (oldFormatShortName) {
            delete this._oldFormatOverrides[oldFormatShortName];
        }

        let appealFormItem = this.selectedForm;

        const currentAccountIndex = this.getUIAccountIndex(override.fieldName);
        const perAccountField = currentAccountIndex !== null;

        if (perAccountField && currentAccountIndex > 0) {
            appealFormItem = appealFormItem.additionalAccounts[currentAccountIndex - 1];
        }

        const appealFormReference = this.appealApplicationService.toAppealFormReference(appealFormItem);
        const overrideModel: Compliance.AppealFormOverrideRequestModel = {
            inEdit: appealFormReference,
            fieldName: override.fieldName,
            otherReferences: [],
            value: null,
            rowVersion: rowVersion
        };

        // if (perAccountField) {
        //     overrideAppealId = this.multiAccountData[currentAccountIndex].appealId;
        // }
        // else {
        //     currentAccountIndex = 0;
        // }

        if (shouldApplyEditsToAll) {
            const topFormReference = this.appealApplicationService.toAppealFormReference(this.selectedForm);
            // Add other appealIds as long as they're not for this form (either by appealId or additional account)
            overrideModel.otherReferences = this.appealFormList
                .filter(x => ! this.appealApplicationService.allAppealIds(topFormReference).includes(x.appealId))
                .map(a => this.appealApplicationService.toAppealFormReference(a));
        }
        else if (!perAccountField) {
            // The idea is to keep overrides "synced" between multi-account fields. So if we have a multi-account form and an
            // override is removed on a field that isn't a per-account field, also "silently" remove the unshown overrides
            // for other accounts on this form.
            overrideModel.otherReferences = [appealFormReference];
        }


        (override.formElements as any[]).forEach(formElement => { formElement.disabled = true; });
        await this.appealApplicationService.deleteOverride(Object.assign({}, overrideModel, {
            fieldName: this.removeUIAccountIndex(overrideModel.fieldName)
        }));

        (override.formElements as any[]).forEach(formElement => {
            formElement.disabled = false;
            formElement.classList.remove(this.MODIFIED_FIELD_CSS_CLASS);
        });
        this._deleteOverrideUI(override);

        if(!this.overrideHolder.overrides.length) {
            // Use this.appealId instead of overrideAppealId here; what we're really interested in is the
            // top-page appealId, not the account-specific appealId
            this.appealApplicationService.setOverrideChange(this.selectedForm.appealFormRevisionId, false);
        }
    }

}
