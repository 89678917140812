import { HelpContent } from '../../../UI-Lib/Help-Tooltip';

export const ASSET_DETAILS_HELP: HelpContent[] = [
    {
        helpContentId: 'asset-info.source-column',
        tooltipText: 'Values for the asset from the applicable asset import.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.reporting-column',
        tooltipText: 'Values for the asset including overrides by the user. Red text in the Reported Value column indicates an override of the Source Value.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.new-split',
        tooltipText: 'Create a new split of the asset sharing the same import source.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.delete-split',
        tooltipText: 'Delete this split of the asset. Other asset(s) from the same import source will be unaffected.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.acquisition-date-field',
        tooltipText: 'The acquisition date is used to determine the earliest lien date at which the asset will appear.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.acqDateAssetDescriptors', // dynamically added so doesn't follow typical convention
        tooltipText: 'Select which field will be used to determine the effective acqusition date for this asset and any asset splits.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.disposed-date-field',
        tooltipText: 'Date at which asset is no longer considered for tax purposes.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.cost-field',
        tooltipText: 'Cost of asset is used for purposes of calculations.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.net-book-value-row-label',
        tooltipText: 'Net Book Value of asset is used for purposes of calculations.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.costAssetDescriptors', // dynamically added so doesn't follow typical convention
        tooltipText: 'Select which field will be used to determine the cost for this asset and any asset splits.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.cost-selector-arrow',
        tooltipText: 'Alternative cost field has been selected for this asset.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.acquisition-date-selector-arrow',
        tooltipText: 'Alternative acquisition date field has been selected for this asset.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.add-cost-adjustment',
        tooltipText: 'Cost adjustments can be added to the source cost value for the asset. If the cost is overridden it will ignore these adjustments.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.adjusted-source-cost',
        tooltipText: 'Cost adjustments are applied to the source cost value for the asset. If the source cost is overridden it will ignore these adjustments.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.site-calculated-parcel',
        tooltipText: 'Assets are automatically associated with parcels for taxation based upon their site. If overridden, the chosen parcel will replace this behavior for tax return inclusion.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.allocate-cost-by-percentage',
        tooltipText: 'Open a tool to set the costs of individual split assets using percentages of the source value.',
        hasIcon: false
    },
    {
        helpContentId: 'asset-info.unattributed-percent',
        tooltipText: 'This shows the source percentage amount which still needs to be allocated. When this is zero you can click OK to apply your allocation.',
        hasIcon: true
    },
    {
        helpContentId: 'asset-info.override-all',
        tooltipText: 'Override all fields.',
        hasIcon: false
    },
];
