import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForAgGridSwitch extends ICellRendererParams {
    isVisible: (params: ICellRendererParamsForAgGridSwitch) => boolean;
    canEdit: (params: ICellRendererParamsForAgGridSwitch) => boolean;
    canEnterEditMode: () => boolean;
    onValueChanged: (params: ICellRendererParamsForAgGridSwitch, newValue: boolean) => Promise<void>;
}

@Component({
    selector: 'ag-grid-grid-switch',
    template: `
                <div style="height: 35px;">
                    <ws-switch [(ngModel)]="params.value" onColor="var(--ace-success)" onLabel="" offLabel="" onTextColor="#fff" (selectedOptionChanged)="onModelChanged($event)"></ws-switch>
                </div>
              `
})
export class AgGridSwitchCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForAgGridSwitch;

    agInit(params: ICellRendererParamsForAgGridSwitch): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onModelChanged(value: boolean): void {
        this.params.value = value;
        this.params.data[this.params.colDef.field] = value;
        this.params.onValueChanged(this.params, value);
    }
}
