import { Directive, Input, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {
    constructor(private readonly _control: NgControl) {}

    @Input() numbersOnly: boolean;
    @Input() forDateInput: boolean;
    @Input() negativeAllowed: boolean = true;
    @Input() decimalAllowed: boolean = true;
    @Input() singleDecimalOnly: boolean = true;
    @Input() minValue: number;
    @Input() maxValue: number;

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        if (this.numbersOnly) {
            const value: string = e.target['value'];
            if (!this.forDateInput) {
                if (this.negativeAllowed && (['NumpadSubtract', 'Minus'].indexOf(e.code) !== -1)) {
                    // Allow minus sign at beginning only
                    if (value === '') {
                        return; // Allow it if the value is empty
                    } else {
                        // Check that one doesn't exist and place it at the beginning
                        this._control.control.setValue((value.charAt(0) === '-') ? value.slice(1) : `-${value}`);
                        e.preventDefault();
                    }
                }
                if (this.decimalAllowed && this.singleDecimalOnly && ['NumpadDecimal', 'Period'].indexOf(e.code) !== -1 && value.indexOf('.') !== -1) {
                    e.preventDefault();
                }
            }
            if (['Insert', 'Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].indexOf(e.code) !== -1 ||
                // Numeric characters, not used for dates
                (!this.forDateInput && ['NumpadDecimal', 'Period'].indexOf(e.code) !== -1) ||
                // Allow: Ctrl+A
                (e.code === 'KeyA' && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+C
                (e.code === 'KeyC' && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+V
                (e.code === 'KeyV' && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+X
                (e.code === 'KeyX' && (e.ctrlKey || e.metaKey)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39) ||
                // Allow: forward slash if set (used for date input)
                (this.forDateInput && (e.code === 'Slash' || e.code === 'NumpadDivide'))) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        }
    }

    @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
        let value: string = e.clipboardData.getData('text/plain');
        e.preventDefault();

        if (this.forDateInput) {
            value = value.replace(/[^0-9/-]/g, '');
            this._control.control.setValue(value);
        } else {
            const formatted = value.replace(/[^0-9.-]/g, '')
                             .replace(/^(-)|-+/g, '$1');
            const num = parseFloat(formatted); // Potentially could be NaN so check is necessary

            let validNum = !isNaN(num) ? num : 0;

            if (typeof this.minValue === 'number' && validNum < this.minValue) {
                validNum = this.minValue;
            }

            if (typeof this.maxValue === 'number' && validNum > this.maxValue) {
                validNum = this.maxValue;
            }

            value = `${validNum}`;
            this._control.control.setValue(value);
        }
    }

    @HostListener('blur', ['$event']) validateMinMax(event) {
        const value = event.target['value'];
        if (typeof this.minValue === 'number' && value < this.minValue) {
            this._control.control.setValue(this.minValue);
        }

        if (typeof this.maxValue === 'number' && value > this.maxValue) {
            this._control.control.setValue(this.maxValue);
        }
    }
}
