import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { DirectivesModule } from '../../UI-Lib/Directives/directives.module';
import { ActionViewContextMenuService } from './actionViewContextMenu.service';
import { ActionDetailedComponent } from './Detailed-List/actionDetailed.component';

@NgModule({
    imports: [
        WeissmanSharedModule,
        DirectivesModule,
        AgGridExtensionsModule,
        AgGridModule
    ],
    declarations: [ActionDetailedComponent],
    exports: [],
})
export class ActionViewModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('avContextMenuService', ActionViewContextMenuService);
        hybridAdapterUtility.downgradeNg2Component('actionViewDetailed', ActionDetailedComponent);
    }
}
