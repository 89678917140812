import { NgModule } from '@angular/core';
import { CommonModule, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoogleMapsModule } from '@angular/google-maps';

import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { SortableModule } from 'ngx-bootstrap/sortable';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxFileDropModule } from 'ngx-file-drop';
import { QuillModule } from 'ngx-quill';

import { UILibModule } from '../UI-Lib/UILib.module';
import { AgGridExtensionsModule } from '../Compliance/AgGrid/agGridExtensions.module';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { ColumnPickerModule } from '../Search/Advanced/Column-Picker/columnPicker.module';
import { CommentsModule } from '../Comments/comments.module';
import { EntityAttachmentsComponent } from '../Attachment/Entity/entity-attachments.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { ActivityModule } from './Activity/activity.module';
import { AssessmentTaxesDetailsModule } from './Assessment-Taxes-Details/assessment-taxes-details.module';
import { PDFViewerComponent } from './PDFViewer/pdf-viewer.component';
import { PDFControlsComponent } from './PDFViewer/PDF-Controls/pdfControls.component';
import { WsAgGridWrapperModule } from '../Compliance/AgGrid/AgGridWrapper/wsAgGridWrapper.module';
import { WsAgGridWrapperComponent } from '../Compliance/AgGrid/AgGridWrapper/wsAgGridWrapper.component';


import { ContractTermsPanelCompanyComponent } from './Contracts-Invoices/contract-terms-panel-company.component';
import { EngagementNumberLookupModalComponent } from './Contracts-Invoices/engagement-number-lookup-modal.component';
import { ContractTermsPanelSiteComponent } from './Contracts-Invoices/contract-terms-panel-site.component';
import { ChooseTermPopoverComponent } from './Contracts-Invoices/choose-term-popover.component';
import { TermRatesAndFeesComponent } from './Contracts-Invoices/term-rates-and-fees.component';
import { SaveAsIconComponent } from './SaveAsIcon/saveAsIcon.component';
import { CustomEmailTemplateModalComponent } from './Custom-Email-Template/custom-email-template-modal.component';
import { UserTeamPickerComponent } from './User-Pickers/user-team-picker.component';
import { PreviewEmailTemplateModalComponent } from './Custom-Email-Template/preview-email-template-modal.component';
import { AgGridLoadingOverlayComponent } from './AgGrid/agGridLoadingOverlay.component';
import { EntityInProgressIndicatorComponent } from './Entity/entity.in.progress.indicator.component';
import { ChangeHistoryModalComponent } from './Change-History/change-history-modal.component';
import { InvoiceModalComponent } from './Invoice/create-invoice-modal.component';
import { StateDropdownComponent } from './States/State-Dropdown/state.dropdown.component';
import { UserPickerComponent } from './User-Pickers/user.picker.component';
import { AddressViewEditComponent } from './Address/Address-View-Edit/address.view.edit.component';
import { AddressDetailsModalComponent } from './Address/Address-View-Edit/address.details.modal.component';
import { EntityAddressDeliverabilityIssueComponent } from './Address/Address-View-Edit/address.deliverability.issue';
import { StepProgressIndicatorModule } from './Step-Progress-Indicator/stepProgressIndicator.module';
import { BeginNewImportComponent } from './import/beginNewImport.component';
import { DeleteConfirmationComponent } from './Delete-Confirmation/deleteConfirmation.component';
import { GoogleMapsSingleMarkerModalComponent } from './Address/google.maps.single.marker.modal.component';
import { CategoryColumnPickerComponent } from './AV-AS/category.column.picker.component';
import { InstanceFilterComponent } from './Instance-Filter/instanceFilter.component';
import { ColumnFiltersComponent } from './AV-AS/Column-Filters/columnFilters.component';
import { CompanyPickerComponent } from './Company-Picker/companyPicker.component';
import { StampModule } from './Stamp/stamp.module';
import { StampComponent } from './Stamp/stamp.component';
import { WarningMessageComponent } from './Warning-Message/warningMessage.component';
import { SwitchComponent } from '../UI-Lib/Switch/switch.component';
import { ExpandCellRendererComponent } from './AgGrid/agGridExpandCellRenderer.component';
import {
    ExpandCellHeaderRendererComponent
} from './AgGrid/agGridExpandCellHeaderRenderer.component';

const hybridAdapterUtility = new HybridAdapterUtility();

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UILibModule,
        TooltipModule,
        ModalModule,
        BsDropdownModule,
        CurrencyMaskModule,
        CarouselModule,
        PopoverModule,
        QuillModule,
        TypeaheadModule,
        ProgressbarModule,
        NgxFileDropModule,
        BusyIndicatorModule,
        TabsModule,
        StepProgressIndicatorModule,
        AgGridExtensionsModule,
        WsAgGridWrapperModule,
        GoogleMapsModule,
        SortableModule,
        BrowserAnimationsModule,
        AccordionModule,
        StampModule,
        ColumnPickerModule,
        CommentsModule,
        ActivityModule,
        AssessmentTaxesDetailsModule
    ],
    declarations: [
        AgGridLoadingOverlayComponent,
        SaveAsIconComponent,
        EntityAttachmentsComponent,
        PDFViewerComponent,
        ContractTermsPanelCompanyComponent,
        ContractTermsPanelSiteComponent,
        EngagementNumberLookupModalComponent,
        ChooseTermPopoverComponent,
        CustomEmailTemplateModalComponent,
        PreviewEmailTemplateModalComponent,
        UserTeamPickerComponent,
        EntityInProgressIndicatorComponent,
        ChangeHistoryModalComponent,
        InvoiceModalComponent,
        TermRatesAndFeesComponent,
        StateDropdownComponent,
        UserPickerComponent,
        AddressViewEditComponent,
        AddressDetailsModalComponent,
        EntityAddressDeliverabilityIssueComponent,
        BeginNewImportComponent,
        DeleteConfirmationComponent,
        GoogleMapsSingleMarkerModalComponent,
        PDFControlsComponent,
        CategoryColumnPickerComponent,
        InstanceFilterComponent,
        ColumnFiltersComponent,
        CompanyPickerComponent,
        WarningMessageComponent,
        ExpandCellRendererComponent,
        ExpandCellHeaderRendererComponent
    ],
    providers: [
        PercentPipe
    ],
    exports: [
        //Modules
        AccordionModule,
        AgGridExtensionsModule,
        BsDropdownModule,
        CarouselModule,
        ColumnPickerModule,
        CommentsModule,
        CommonModule,
        CurrencyMaskModule,
        FormsModule,
        ModalModule,
        PopoverModule,
        ProgressbarModule,
        ReactiveFormsModule,
        StampModule,
        TabsModule,
        TooltipModule,
        TypeaheadModule,
        UILibModule,
        AssessmentTaxesDetailsModule,
        //Components
        SaveAsIconComponent,
        EntityAttachmentsComponent,
        UserTeamPickerComponent,
        AgGridLoadingOverlayComponent,
        EntityInProgressIndicatorComponent,
        AddressViewEditComponent,
        BeginNewImportComponent,
        DeleteConfirmationComponent,
        UserPickerComponent,
        PDFControlsComponent,
        PDFViewerComponent,
        CategoryColumnPickerComponent,
        InstanceFilterComponent,
        ColumnFiltersComponent,
        CompanyPickerComponent,
        WsAgGridWrapperComponent,
        StateDropdownComponent,
        ContractTermsPanelCompanyComponent,
        WarningMessageComponent,
        TermRatesAndFeesComponent,
        ExpandCellRendererComponent,
        ExpandCellHeaderRendererComponent
    ]
})
export class WeissmanSharedModule {
    static setupModule(): void {
        ActivityModule.setupModule();
        UILibModule.setupModule();
        BusyIndicatorModule.setupModule();
        ColumnPickerModule.setupModule();
        AssessmentTaxesDetailsModule.setupModule();
        hybridAdapterUtility.downgradeNg2Component('contractTermsPanelCompany', ContractTermsPanelCompanyComponent);
        hybridAdapterUtility.downgradeNg2Component('contractTermsPanelSite', ContractTermsPanelSiteComponent);
        hybridAdapterUtility.downgradeNg2Component('wsSaveAsIcon', SaveAsIconComponent);
        hybridAdapterUtility.downgradeNg2Component('addressViewEdit', AddressViewEditComponent);
        hybridAdapterUtility.downgradeNg2Component('categoryColumnPicker', CategoryColumnPickerComponent);
        hybridAdapterUtility.downgradeNg2Component('instanceFilter', InstanceFilterComponent);
        hybridAdapterUtility.downgradeNg2Component('columnFilters', ColumnFiltersComponent);
        hybridAdapterUtility.downgradeNg2Component('wsStamp', StampComponent);
        hybridAdapterUtility.downgradeNg2Component('wsSwitch', SwitchComponent);
        hybridAdapterUtility.downgradeNg2Component('wsColumnFilters', ColumnFiltersComponent);
    }
}
