import { Component } from '@angular/core';
import { HelpContentRendererValue, HelpContentComponent, HelpService } from '../../../UI-Lib/Help-Tooltip';
import { FlattenedCostAdjustmentSource} from '../Asset-Details/Asset-Info/assetInfo.component';

export type CostAdjustmentHelpComponentParams = HelpContentRendererValue<FlattenedCostAdjustmentSource[]>;

@Component({
    selector: 'cost-adjustment-tooltip',
    template: `
        Cost of asset is used for purposes of calculations. The source value may be altered by adjustments, applied for this state in the following order:
        <ul>
            <li *ngFor="let costAdjustment of adjustments">{{ costAdjustment.typeName }} (% values use {{ costAdjustment.applyToSource ? 'original cost' : 'running total' }})</li>
        </ul>
    `
})
export class CostAdjustmentHelpComponent implements HelpContentComponent<CostAdjustmentHelpComponentParams> {
    constructor(private readonly _helpService: HelpService) { }

    params: CostAdjustmentHelpComponentParams;
    adjustments: FlattenedCostAdjustmentSource[];

    async helpInit(params: CostAdjustmentHelpComponentParams): Promise<void> {
        if (params) {
            this.adjustments = params.value;
        }
        this._helpService.refresh();

    }
}
