<div class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header d-flex">
        <h3 class="ws-flex-auto ws-truncate">
            Assessors [All Assessors - {{taxYear}}]
            <ag-grid-row-count [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
        </h3>
        <div class="ws-flex-none header-button-container">
            <div class="certified-icon">
                <span class="fa-stack"
                      helpTooltip
                      [contentComponent]="certifiedTooltip"
                      [ngClass]="{ 'certified-warning': !areAllCertified, 'certified-success': areAllCertified }">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-check fa-stack-1x"></i>
            </span>
            </div>

            <button title="Add Assessors"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="addNewAssessor()"
                    *ngIf="editMode && canEdit">
                <i class="fa fa-plus-circle fa-align"></i>
            </button>
            <button *ngIf="!editMode && canEdit"
                    title="Edit"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    [disabled]="!canEnterEditMode"
                    (click)="edit()">
                <i class="fa fa-pencil fa-align"></i>
            </button>
            <button title="Close"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    *ngIf="editMode && canEdit"
                    (click)="cancel()">
                <i class="fa fa-times fa-align"></i>
            </button>
            <button title="Bulk Remove Assessors"
                    type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="bulkRemove()"
                    *ngIf="editMode && canEdit && hasSelectedRows">
                <i class="fa fa-trash fa-align"></i>
            </button>
            <ag-grid-filter-toggle [gridOptions]="gridOptions"></ag-grid-filter-toggle>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isExpanded" (click)="expandComponent()">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isExpanded" (click)="collapseComponent()">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body d-flex flex-column">
        <ws-ag-grid-angular [gridOptions]="gridOptions"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
