import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GridOptions, GridReadyEvent, ColDef, GridApi, RowNode, StartEditingCellParams } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams, AgGridTextCellEditor } from '../../../Compliance/AgGrid';
import { TaxingDistrictListGridActionCellRendererComponent, TaxingDistrictListGridActionCellRendererParams } from './agGridActionCellRenderer.component';

export interface TaxingDistrictListParams {
    assessorId: number;
    assessorName: string;
    taxingDistricts: Core.TaxingDistrictModel[];
    editMode: boolean;
}

export interface TaxingDistrictListResult {
    taxingDistricts: Core.TaxingDistrictModel[];
}

@Component({
    selector: 'taxing-district-list',
    templateUrl: './taxingDistrictList.component.html',
    styleUrls: ['./taxingDistrictList.component.scss']
})
export class TaxingDistrictListComponent implements OnInit, IWeissmanModalComponent<TaxingDistrictListParams, TaxingDistrictListResult> {
    constructor(private readonly _bsModalRef: BsModalRef) { }

    params: TaxingDistrictListParams;
    result: TaxingDistrictListResult;
    isValid: boolean = true;

    gridId: System.Guid = '9FEF25C2-C398-4398-B5DF-74258762E46F';
    gridOptions: GridOptions = new AgGridOptionsBuilder({
            singleClickEdit: true
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withFloatingFilter()
        .withTextSelection()
        .withFirstDataRendered(x => {
            x.api.sizeColumnsToFit();
        })
        .withCellValueChanged(x => {
            this._validate();
        })
        .withoutCellEditingStoppedOnGridLostFocus()
        .build();

    private _gridApi: GridApi;

    get editMode(): boolean {
        return this.params && this.params.editMode;
    }

    ngOnInit(): void {
        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Taxing District Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (x) => {
                    return this.params.editMode;
                },
                cellEditorFramework: AgGridTextCellEditor,
                cellEditorParams: {
                    maxLength: 250
                }
            },
            {
                headerName: 'Taxing District Code',
                field: 'code',
                width: AgGridColumns.textColumnMedWidth,
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (x) => {
                    return this.params.editMode;
                },
                cellEditorFramework: AgGridTextCellEditor,
                cellEditorParams: {
                    maxLength: 50
                }
            },
            {
                headerName: 'Township Name',
                field: 'townshipName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (x) => {
                    return this.params.editMode;
                },
                cellEditorFramework: AgGridTextCellEditor,
                cellEditorParams: {
                    maxLength: 250
                }
            },
            {
                headerName: 'Township Code',
                field: 'townshipCode',
                width: AgGridColumns.textColumnMedWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (x) => {
                    return this.params.editMode;
                },
                cellEditorFramework: AgGridTextCellEditor,
                cellEditorParams: {
                    maxLength: 50
                }
            },
            {
                headerName: '',
                field: 'actions',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: TaxingDistrictListGridActionCellRendererComponent,
                cellRendererParams: {
                    canDelete: this._canDelete.bind(this),
                    delete: this._delete.bind(this)
                } as TaxingDistrictListGridActionCellRendererParams,
                hide: !this.params.editMode
            }
        ];

        const defaultSortModel = [
            {
                colId: 'name',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    save(): void {
        const taxingDistricts: Core.TaxingDistrictModel[] = [];
        this._gridApi.forEachNode((node: RowNode) => {
            const taxingDistrict = node.data as Core.TaxingDistrictModel;
            if (taxingDistrict) {
                taxingDistricts.push(taxingDistrict);
            }
        });

        this.result = {
            taxingDistricts: taxingDistricts
        };

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    add(): void {
        const newTaxingDistrict: Core.TaxingDistrictModel = {
            assessorId: this.params.assessorId,
            name: '',
            code: '',
            townshipCode: '',
            townshipName: '',
            displayName: '',
            taxingDistrictId: 0
        };

        this._gridApi.updateRowData({ add: [newTaxingDistrict] });

        const params: StartEditingCellParams = {
            rowIndex: 0,
            colKey: 'name'
        };

        this._gridApi.startEditingCell(params);
        this._validate();
    }

    private _validate(): void {
        let isValid = true;

        const regexp = new RegExp('^[\\s\\S]*\\S[\\s\\S]*$');

        this._gridApi.forEachNode((node: RowNode) => {
            const taxingDistrict = node.data as Core.TaxingDistrictModel;

            if (!(regexp.test(taxingDistrict.name) &&
                regexp.test(taxingDistrict.code) &&
                regexp.test(taxingDistrict.townshipName) &&
                regexp.test(taxingDistrict.townshipCode))) {
                isValid = false;
            }
        });

        this.isValid = isValid;
    }

    private _canDelete(params: TaxingDistrictListGridActionCellRendererParams): boolean {
        const taxingDistrict = params.data as Core.TaxingDistrictModel;
        if (!taxingDistrict) {
            return false;
        }

        return this.params.editMode;
    }

    private _delete(params: TaxingDistrictListGridActionCellRendererParams): void {
        const taxingDistrict = params.data as Core.TaxingDistrictModel;
        if (!taxingDistrict) {
            return;
        }

        this._gridApi.updateRowData({ remove: [taxingDistrict] });
        this._validate();
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.params.taxingDistricts)) {
            return;
        }

        this._gridApi.setRowData(this.params.taxingDistricts);
        this._validate();
    }
}
