import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, GridOptions, RowNode } from 'ag-grid-community';
import { AgGridOptionsBuilder } from '../../AgGrid/agGridOptionsBuilder';
import { AgGridFilterParams } from '../../AgGrid/agGridFilterParams';
import { AgGridColumns } from '../../AgGrid/agGridColumns';
import { ReportingParcelRepository } from '../../Repositories/reportingParcel.repository';
import { AgGridLinkCellRendererComponent } from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridLinkCellRendererParams } from '../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import * as _ from 'lodash';
import { IComponentRef } from '../../Return/Models/iComponentRef';
import { lastValueFrom } from 'rxjs';

export interface ReportingParcelSiteRowModel extends Compliance.ReportingParcelSiteModel {
    added: boolean;
    deleted: boolean;
    changed: boolean;
}

@Component({
    selector: 'reporting-parcel-site-list',
    templateUrl: './reportingParcelSiteList.component.html'
})
export class ReportingParcelSiteListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _reportingParcelRepository: ReportingParcelRepository) {
    }

    private _gridApi: GridApi;
    private _sites: ReportingParcelSiteRowModel[] = [];
    private _originalSites: Compliance.ReportingParcelSiteModel[] = [];
    private _originalParentSite: Compliance.ReportingParcelSiteModel;

    @Input() siteId: number;
    @Input() parcelId: number;

    @Output() changed: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onInitialized: EventEmitter<IComponentRef> = new EventEmitter<IComponentRef>();

    gridOptions: GridOptions = new AgGridOptionsBuilder(
            {
                suppressScrollOnNewData: true,
                rowClassRules: {
                    'grid-row-deleted': params => params.data && params.data.deleted
                }
            })
        .withContext(this)
        .withLoadingOverlay()
        .withMultipleColumnSort()
        .withColumnResize()
        .withTextSelection()
        .build();

    allowEdit: boolean = true;
    editMode: boolean = false;
    addedSiteIds: number[] = [];

    get deletedSiteIds(): number[] {
        return _.map(_.filter(this._sites, x => x.deleted), x => x.siteId);
    }

    parentSite: ReportingParcelSiteRowModel;

    get showSiteList(): boolean {
        var parentSiteInList = _.find(this._sites, (x: Compliance.ReportingParcelSiteModel) => x.siteId === this.parentSite.siteId);
        var minLength = parentSiteInList ? 1 : 0;
        
        return this._sites.length > minLength;
    }

    get hasReportingParcel(): boolean {
        return this.parentSite && this.parentSite.reportingParcelId ? true : false;
    }

    get isReportingParcelForParentSite(): boolean {
        return this.parentSite && (!this.parentSite.deleted) && this.parentSite.reportingParcelId === this.parcelId;
    }
    set isReportingParcelForParentSite(isReportingParcel: boolean) {
        if (!this.parentSite) {
            return;
        }

        if (isReportingParcel) {
            this.parentSite.reportingParcelId = this.parcelId;
            this.parentSite.changed = true;
        } else {
            this.parentSite.deleted = true;
        }

        this.changed.emit(this.hasChanges);
    }

    get isPartOfAMasterSite(): boolean {
        return this.parentSite.isSiteLinked;
    }

    get isAnotherParcelReportingForParentSite(): boolean {
        return this.parentSite && this.parentSite.reportingParcelId && this.parentSite.reportingParcelId !== this.parcelId;
    }

    get hasChanges(): boolean {
        if (this.parentSite && (this.parentSite.changed || this.parentSite.deleted)) {
            return true;
        }

        if (this.addedSiteIds.length) {
            return true;
        }

        let hasChanges: boolean = false;

        if (this._gridApi) {
            this._gridApi.forEachNode((node: RowNode) => {
                const site = node.data as ReportingParcelSiteRowModel;
                if (site && (site.added || site.deleted || site.changed)) {
                    hasChanges = true;
                }
            });
        }

        return hasChanges;
    }

    async ngOnInit(): Promise<void> {
        await this._load();

        const ref: IComponentRef = {
            save: this.save.bind(this),
            cancel: this.cancel.bind(this),
            edit: this.edit.bind(this),
            getSaveModel: this.getSaveModel.bind(this),
            load: this._load.bind(this),
            isDisabled: () => false
        };

        this.onInitialized.emit(ref);
    }

    ngOnDestroy(): void {
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Site Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const site = params.data as Compliance.ReportingParcelSiteModel;
                        if (!site) {
                            return '';
                        }
                        return `#/site/${site.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                valueFormatter: (params) => {
                    const site = params.data as Compliance.ReportingParcelSiteModel;
                    if (!site) {
                        return '';
                    }

                    return `${site.name}${(site.siteId === this.siteId) ? ' (this site)' : ''}`;
                },
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Site Number',
                field: 'property',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'siteName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async refresh(): Promise<void> {
        await this._load();
    }

    async save(): Promise<void> {
        const updates = this.getSaveModel();

        await lastValueFrom(this._reportingParcelRepository.updateSites(this.parcelId, updates));

        await this._loadSites();

        this.addedSiteIds = [];

        this.editMode = false;
    }

    private getSaveModel(): Compliance.ReportingParcelSiteUpdateModel[]{
        const updates: Compliance.ReportingParcelSiteUpdateModel[] = [];

        // add parent site
        if (this.parentSite.changed || this.parentSite.deleted) {
            updates.push(this._getSiteUpdateModel(this.parentSite));
        }

        // add added sites
        _.forEach(this.addedSiteIds,
            x => {
                updates.push({
                    siteId: x,
                    reportingParcelId: this.parcelId
                } as Compliance.ReportingParcelSiteUpdateModel);
            });

        if (this._gridApi) {
            this._gridApi.forEachNode((node: RowNode) => {
                const site = node.data as ReportingParcelSiteRowModel;
                if (site && (site.added || site.deleted || site.changed)) {
                    updates.push(this._getSiteUpdateModel(site));
                }
            });
        }
        return updates;
    }

    edit(): void {
        this.editMode = true;
    }

    cancel(): void {
        this._setSites(this._originalSites);
        this._setParentSite(this._originalParentSite);
        this.addedSiteIds = [];
        this.editMode = false;
    }

    private async _load(): Promise<void> {
        await this._loadSites();
    }

    private async _loadSites(): Promise<void> {
        // get information about the parcel's parent site
        const parcelSite: Compliance.ReportingParcelSiteModel = await lastValueFrom<Compliance.ReportingParcelSiteModel>(this._reportingParcelRepository.getSiteByParcel(this.parcelId));
        this._setParentSite(parcelSite);

        // get the sites that are using this parcel as a reporting parcel
        const searchParams = {
            parcelId: this.parcelId
        } as Compliance.ReportingParcelSiteSearchModel;

        const sites = (await lastValueFrom<Compliance.QueryResultModel<Compliance.ReportingParcelSiteModel>>(this._reportingParcelRepository.getSitesByReportingParcel(searchParams))).data;

        this._setSites(sites);
    }

    private _setParentSite(site: Compliance.ReportingParcelSiteModel): void {
        this._originalParentSite = site;

        this.parentSite = this._getSiteRowModel(this._originalParentSite);
    }

    private _setSites(sites: Compliance.ReportingParcelSiteModel[]): void {
        this._originalSites = sites;

        this._sites = _.map(sites, (x: Compliance.ReportingParcelSiteModel) => this._getSiteRowModel(x));

        this._setRowData();
    }

    private _setRowData(): void {
        if (!this._gridApi) {
            return;
        }

        this._gridApi.setRowData(this._sites);
        this._gridApi.sizeColumnsToFit();
    }

    private _getSiteRowModel(siteModel: Compliance.ReportingParcelSiteModel): ReportingParcelSiteRowModel {
        return {

            added: false,
            deleted: false,
            changed: false,
            ...siteModel
        } as ReportingParcelSiteRowModel;
    }

    private _getSiteUpdateModel(siteRowModel: ReportingParcelSiteRowModel): Compliance.ReportingParcelSiteUpdateModel {
        return {
            reportingParcelId: siteRowModel.deleted ? null : siteRowModel.reportingParcelId,
            siteId: siteRowModel.siteId
        } as Compliance.ReportingParcelSiteUpdateModel;
    }
}
