<div class="modal-body exclude-modal-styling tax-rate-setup">
    <div class="text-center loading-all" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div class="first-encounter row content-container" *ngIf="!loading">
        <div style="display:inline-block;max-width:32em;margin-right:1em;width:50%;">
            <h2 style="margin-top:0">Tax Rate First Encounter</h2>
            <label>
                <span>Tax Authority:</span>
                <span>{{taxAuthorityDisplayName}}</span>
            </label>
            <label>
                <span>Collector:</span>
                <span>{{model.collectorName}}</span>
            </label>
            <label class="radio">
                <span>Display as:</span>
                <span>
                    <label>
                        <input type="radio" name="displayType" value="rate" [(ngModel)]="displayAs" (ngModelChange)="onModelChange()" />
                        Rate
                    </label>
                    <label>
                        <input type="radio" name="displayType" value="mills" [(ngModel)]="displayAs" (ngModelChange)="onModelChange()" />
                        Mills
                    </label>
                </span>
            </label>
            <hr />
            <label>
                <span>{{taxAuthority.taxYear - 2}} Rate:</span>
                <span>{{twoYearsAgoDisplayRate | number:"1.6-6"}}</span>
            </label>
            <label>
                <span>{{taxAuthority.taxYear - 1}} Rate:</span>
                <span class="rate-display">{{lastYearDisplayRate | number:"1.6-6"}}</span>
                <span *ngIf="lastYearDisplayRateIncrease">({{lastYearDisplayRateIncrease | number: "1.2-2"}} % increase)</span>
                <span *ngIf="!lastYearDisplayRateIncrease">(no change)</span>
            </label>
            <label>
                <span>{{taxAuthority.taxYear}} Rate:</span>
                <span class="rate-display">
                    <input
                        #rateInput
                        type="text"
                        style="padding-left:0.5em"
                        [ngModel]="displayRate === null ? '' : (displayRate | number:'1.6-6')"
                        (ngModelChange)="onRateChange($event)"
                        (blur)="onModelChange()"
                    />
                </span>
                <span *ngIf="this.rateIncrease">({{this.rateIncrease | number: "1.2-2"}} % increase)</span>
            </label>
        </div>
        <div style="display:inline-block;max-width:30em;vertical-align:top">
            <p>
                You are entering the actual {{taxAuthority.taxYear}} tax rate for tax authority
                <span style="font-weight:bold">{{taxAuthorityDisplayName}}</span>.
                Please double check the accuracy of the entry before clicking Save. The rate's status will be changed from
                Estimated to Pending QC, the bill for this parcel will be attached as evidence, and all auto-calculated tax
                bills system-wide utilizing this rate will be re-estimated.
            </p>
            <p *ngIf="hasProcessingChanges" style="color:red">
                WARNING: There are pending changes on this bill made since this document was associated, and the bill will
                be affected by entering a tax rate for this tax authority. If you continue, all pending changes will be
                reverted automatically once you click Save. Please ensure this is OK before continuing.
            </p>
        </div>
    </div>
</div>
<div class="modal-footer tax-rate-setup">
    <div class="action-buttons">
        <button class="ace-btn btn-primary" [disabled]="loading" (click)="save()">Save</button>
        <button class="ace-btn btn-secondary" (click)="hide()">Cancel</button>
    </div>
</div>
