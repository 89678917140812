import { Component, Input, OnInit } from '@angular/core';
import { ParcelActivitySummary } from './parcel-activity.model';
import { ParcelActivityService } from './parcel-activity.service';
import { TaskService } from '../../../Task/task.service.upgrade';
import { AnnualDetailsNavigationEventService, AppealNavigationInput, AssessmentNavigationInput, TaxNavigationInput, FilingNavigationInput } from '../../../Annual-Details/annual-details-event.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { Subscription } from 'rxjs';
import { ClientServiceResponsibilityService } from '../../../Client-Services/clientServiceResponsibility.service';

@Component({
    selector: 'parcel-activity',
    templateUrl: './parcel-activity-summary.component.html'
})
export class ParcelActivitySummaryComponent implements OnInit {
    constructor(
        private readonly _activityService: ParcelActivityService,
        private readonly _taskService: TaskService,
        private readonly _navigationEvent: AnnualDetailsNavigationEventService,
        private readonly _upgradeNavigationService: UpgradeNavigationServiceHandler,
        private readonly _clientServiceResponsibilityService: ClientServiceResponsibilityService) {
    }

    @Input() parcelId: number;
    @Input() siteId: number;
    @Input() showComplianceInfo: boolean = false;

    activitySummaryItems: ParcelActivitySummary[];
    isParcelLevel: boolean = true;
    dataLoading: boolean = true;
    refreshSubscription: Subscription;

    private _clientServicesChangeSubscription: Subscription;

    async ngOnInit(): Promise<void> {
        if (this.siteId) {
            this.isParcelLevel = false;
        }

        await this.getActivityData();

        this._clientServicesChangeSubscription = this._clientServiceResponsibilityService.clientServicesChange$
            .subscribe(async () => {
                await this.getActivityData();
            });

        this.refreshSubscription = this._navigationEvent.refreshEvent$
            .subscribe(async () => {
                await this.getActivityData();
            });
    }

    ngOnDestroy(): void {
        this.refreshSubscription.unsubscribe();
        this._clientServicesChangeSubscription.unsubscribe();
    }

    canNavigate(activity: ParcelActivitySummary): boolean {
        if (activity && activity.entityTypeID === Core.EntityTypes.FilingBatch && !this.showComplianceInfo) {
            return false;
        }

        return true;
    }

    async getActivityData(): Promise<void> {
        this.dataLoading = true;

        try {
            this.activitySummaryItems = this.isParcelLevel
                ? await this._activityService.getParcelActivity(this.parcelId)
                : await this._activityService.getParcelActivityForSite(this.siteId);
        } finally {
            this.dataLoading = false;
        }
    }

    launchTaskModal(event: any, entityId: number, entityTypeId: number): void {
        event.stopPropagation();
        this._taskService.launchTaskModal(entityId, entityTypeId, true);
    }

    navigateToEntity(activity: ParcelActivitySummary): void {
        if (!this.canNavigate(activity)) {
            return;
        }

        if (this.isParcelLevel) {
            switch (activity.entityTypeID) {
                case Core.EntityTypes.AnnualAssessment:
                    this._navigationEvent.goToAssessment(new AssessmentNavigationInput(activity.annualYearID, activity.entityID));
                    break;
                case Core.EntityTypes.Appeal:
                    this._navigationEvent.goToAppeal(new AppealNavigationInput(activity.annualYearID, activity.parentEntityID, activity.entityID));
                    break;
                case Core.EntityTypes.Payment:
                    // For tax payments, entityID is the PaymentID and parentEntityID is the BillClusterID
                    this._navigationEvent.goToTax(new TaxNavigationInput(activity.annualYearID, activity.parentEntityID));
                    break;
                case Core.EntityTypes.Refund:
                    this._navigationEvent.goToRefund(new TaxNavigationInput(activity.annualYearID, activity.entityID));
                    break;
                case Core.EntityTypes.Filing:
                    this._navigationEvent.goToFiling(new FilingNavigationInput(activity.annualYearID, activity.entityID));
                    break;
                case Core.EntityTypes.FilingBatch:
                    this._upgradeNavigationService.goToFilingBatch(activity.entityID);
                    break;
            }
        } else {
            const siteId: number = parseInt(this._upgradeNavigationService.getQuerystringParam('siteId'));
            const companyId: number = parseInt(this._upgradeNavigationService.getQuerystringParam('companyId'));

            switch (activity.entityTypeID) {
                case Core.EntityTypes.AnnualAssessment:
                    this._upgradeNavigationService.goToAnnualDetailsAssessment(companyId, siteId, activity.parcelID, activity.annualYearID, activity.entityID);
                    break;
                case Core.EntityTypes.Appeal:
                    this._upgradeNavigationService.goToAnnualDetailsAppeal(companyId, siteId, activity.parcelID, activity.annualYearID, activity.parentEntityID, activity.entityID);
                    break;
                case Core.EntityTypes.Payment:
                    // For tax payments, entityID is the PaymentID and parentEntityID is the BillClusterID
                    this._upgradeNavigationService.goToAnnualDetailsTax(companyId, siteId, activity.parcelID, activity.annualYearID, activity.parentEntityID);
                    break;
                case Core.EntityTypes.Refund:
                    this._upgradeNavigationService.goToAnnualDetailsRefund(companyId, siteId, activity.parcelID, activity.annualYearID, activity.entityID);
                    break;
                case Core.EntityTypes.Filing:
                    this._upgradeNavigationService.goToAnnualDetailsFiling(companyId, siteId, activity.parcelID, activity.annualYearID, activity.entityID);
                    break;
                case Core.EntityTypes.FilingBatch:
                    this._upgradeNavigationService.goToFilingBatch(activity.entityID);
                    break;
                case Core.EntityTypes.Invoice:
                    this._upgradeNavigationService.go('siteInvoice', { companyID: companyId, siteID: siteId, invoiceID: activity.parentEntityID }, true);
                    break;
            }
        }
    }

    getTaskIcon(taskSeriesTypeId: number): string {
        switch (taskSeriesTypeId) {
            case Core.TaskSeriesTypes.PPReturn:
                return 'fa fa-file-text-o';
            case Core.TaskSeriesTypes.AsmtAppealReview:
                return 'fa fa-clipboard';
            case Core.TaskSeriesTypes.AppealPursuit:
                return 'fa fa-gavel';
            case Core.TaskSeriesTypes.Refund:
                return 'fa fa-money';
            case Core.TaskSeriesTypes.TaxPayment:
                return 'fa fa-dollar dollar-icon-fix';
            case Core.TaskSeriesTypes.DocumentIntake:
                return 'fa fa-file-o';
            case Core.TaskSeriesTypes.MiscFiling:
            case Core.TaskSeriesTypes.FreeportFiling:
            case Core.TaskSeriesTypes.AbatementFiling:
            case Core.TaskSeriesTypes.PollutionControlFiling:
                return 'fa fa-file-text-o';
            case Core.TaskSeriesTypes.ClientInvoice:
                return 'fa fa-money';
            default:
                return 'fa fa-info info-icon-fix';
        }
    }

    getTaskIconColor(taskSeriesTypeId: number): string {
        const className = 'icon-badge';
        switch (taskSeriesTypeId) {
            case Core.TaskSeriesTypes.PPReturn:
                return `${className  } blue-badge`;
            case Core.TaskSeriesTypes.AsmtAppealReview:
                return `${className  } red-badge`;
            case Core.TaskSeriesTypes.AppealPursuit:
                return `${className  } gold-badge`;
            case Core.TaskSeriesTypes.Refund:
                return `${className  } green-badge`;
            case Core.TaskSeriesTypes.TaxPayment:
                return `${className  } green-badge`;
            case Core.TaskSeriesTypes.DocumentIntake:
                return `${className  } gold-badge`;
            default:
                return `${className  } blue-badge`;
        }
    }
}
