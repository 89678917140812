import { Address } from '../../Common/Models/common.model';
import { Attachment, AttachmentInfo } from '../../Attachment/attachment.model';

export enum TransmittalOutputType {
    PaperTransmittal = 1,
    DigitalTransmittal = 2,
    APFeed = 3,
    BillPay = 4
}

export class TransmittalOutputTypeButtonLabel{
    public static PaperTransmittal: string = 'Print Package';
    public static DigitalTransmittal: string = 'Email Package';
    public static APFeed: string = 'Send AP Feed';
    public static BillPay: string = 'Pay Bills';
}

export enum TransmittalFormat {
    Original_Default = 1,
    FMV_AV_Legal = 2,
    FMV_AV_TV_RV_Legal = 3,
    FiscalYear = 4,
    DirectAsmtSummary = 5
}

export enum TransmittalGroupBy {
    Subsidiary_Site = 1,
    Collector_Site = 2,
    Subsidiary = 3,
    Collector = 4
}

export enum RecipientType {
    ResponsibleParty = Weissman.Model.RecipientType.ResponsibleParty,
    Cc = Weissman.Model.RecipientType.Cc,
    Bcc = Weissman.Model.RecipientType.Bcc
}

export enum AttachmentType{
    PaymentPackage = 1,
    PaymentTransmittal = 2,
    APFeed = 3
}

export class PaymentPackage {
    paymentPackageID: number;
    companyID: number;
    companyName: string;
    transmittalOutputType: TransmittalOutputType;
    transmittalType: string;
    specialInstructions: string;
    packageNumber: string;
    completedDateTime: Date;
    completedByUserID: string;
    isRecalled: boolean;
    paymentPackageRecipients: PaymentPackageRecipient[];
    paymentTransmittals: PaymentTransmittal[];
    paymentPackageFeedSpecifications: PaymentPackageFeedSpecification[];
    isSelected: boolean;
    packageType: Core.PaymentPackageTypes;
    emailSubjectTemplate: string;
    emailSubject: string;
    emailTemplate: string;
    emailText: string;
    apFeedAttachments: Attachment[];
    consolidateTransmittalAttachment: boolean;
    skipBillImage: boolean;
    attachments: Attachment[];
    isFinalized: boolean;
    paymentBatchId: number;
    paymentPackageFinalizeRequestId: number;
    // Does not come from server; use this for ability to restore an original email template
    lockedForOverrideEmail: boolean;
    originalEmailSubjectTemplate: string;
    originalEmailTextTemplate: string;

}

export class PaymentPackageRecipient {
    constructor(newRecipientType: RecipientType,
                newContactID: number,
                newFullName: string,
                newTitle: string,
                newAddress: Address,
                newEmail: string,
                newCompanyName: string) {
        this.recipientType = newRecipientType;
        this.contactID = newContactID;
        this.fullName = newFullName;
        this.title = newTitle;
        this.address = newAddress;
        this.email = newEmail;
        this.companyName = newCompanyName;
    }

    paymentPackageRecipientID: number;
    paymentPackageID: number;
    recipientType: RecipientType;
    contactID: number;
    fullName: string;
    title: string;
    address: Address;
    email: string;
    companyName: string;
}

export class PaymentTransmittal {
    paymentTransmittalID: number;
    paymentPackageID: number;
    collectorID: number;
    collectorName: string;
    siteID: number;
    title: string;
    collectorAddress: Address;
    dueDate: Date;
    note: string;
    transmittalFormat: TransmittalFormat;
    paymentTransmittalItems: PaymentTransmittalItem[];
}

export class PaymentTransmittalItem {
    paymentTransmittalItemID: number;
    paymentTransmittalID: number;
    paymentID: number;
    attachmentID: number;
    attachment: Attachment;
    siteName: string;
    acctNum: string;
    collectorAcctNum: string;
    propertyType: number;
    amount: number;
    intakeItemID: number;
    intakeBatchNumber: number;
    intakeItemNumber: number;
}

export class TransmittalOutputTypeOption {
    constructor(type: TransmittalOutputType, textLabel: string){
        this.outputType = type;
        this.label = textLabel;
    }

    outputType: TransmittalOutputType;
    label: string;
}

export class PaymentPackageFeedSpecification {
    PaymentPackageFeedSpecificationID: number;
    PaymentPackageID: number;
    FeedSpecificationID: number;
    IncludedInEmail: boolean;
}

export class InvoiceAttachmentInfo {
    fileName: string;
    fileExtension: string;
    // We'll either have an attachment or enough data to generate a preview
    Attachment?: Attachment;
    id?: string;
    attachmentType: AttachmentType;
}

export class InvoicePreviewData {
    emailTextTemplate: string;
    emailText: string;
    emailSubjectTemplate: string;
    emailSubject: string;
    attachments: InvoiceAttachmentInfo[];
}

export class PackageEmailTemplate {
    bodyTemplate: string;
    name: string;
    packageEmailTemplateID: number;
    subjectTemplate: string;
}

export class PaymentPackageAttachmentSummary {
    paymentPackageID: number;
    packageName: string;
    companyID: number;
    attachments: AttachmentInfo[];
}
