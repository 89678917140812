<div class="modal-header">
    <h4 class="modal-title">Custom Email Template</h4>
    <span class="float-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </span>
</div>
<div class="modal-body quill-height-fix" style="margin-top: 20px;">
    <div class="mb-4">
        <label>
            Base Template
            <select *ngIf="packageEmailTemplates"
                    class="form-select form-select-sm"
                    style="margin-right: 10px;"
                    (ngModelChange)="baseTemplateChange($event)"
                    [ngModel]="selectedEmailTemplate">
                <option *ngFor="let template of packageEmailTemplates" [ngValue]="template">{{template.name}}</option>
            </select>
        </label>
        <!-- TODO: This is copied to payment-packages-review.modal; we should create a shared component instead
            of copy/pasting the implementation -->
        <label style="margin-top: 24px;">
            Template Subject
            <input type="text" id="custom-template-subject" style="font-weight: normal; width: 362px; display: inline-block; margin-left: 10px; margin-bottom: 15px;"
                class="form-control" [(ngModel)]="template.packageEmailCustomTemplateSubject" placeholder="Subject ...">
            <div class="btn-group buttongroup-last" style="margin-left: -5px;" dropdown>
                <button dropdownToggle type="button"
                        class="ace-btn btn-secondary dropdown-toggle"
                        style="width: initial;">
                    Insert Field
                    <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                    <li role="menuitem" *ngFor="let field of emailTemplateFields.subject">
                        <a style="cursor: pointer;" class="dropdown-item" (click)="insertFieldInSubject(field)">{{field.name}}</a>
                    </li>
                </ul>
            </div>
        </label>
    </div>
    <label>
        <div>Template Body</div>
    </label>
    <quill-editor
        [(ngModel)]="template.packageEmailCustomTemplateBody"
        [placeholder]="'Email Body ...'"
        (onEditorCreated)="addBindingCreated($event)"
    >
        <div quill-editor-toolbar>
            <!-- Basic buttons -->
            <span class="ql-formats">
                <button class="ql-bold" [title]="'Bold'"></button>
                <button class="ql-italic" [title]="'Italic'"></button>
                <button class="ql-underline" [title]="'Underline'"></button>
                <button class="ql-strike" [title]="'Strike'"></button>
            </span>

            <!-- Size -->
            <span class="ql-formats">
                <select class="ql-size" [title]="'Size'">
                    <option value="small"></option>
                    <option selected></option>
                    <option value="large"></option>
                    <option value="huge"></option>
                </select>
            </span>
            <span class="ql-formats">
                <select class="ql-align" [title]="'Aligment'">
                    <option selected></option>
                    <option value="center"></option>
                    <option value="right"></option>
                    <option value="justify"></option>
                </select>
            </span>
            <div class="btn-group" dropdown>
                <button dropdownToggle type="button" class="btn dropdown-toggle" style="width: initial;">
                    Insert Field
                    <span class="caret"></span>
                </button>
                <ul *dropdownMenu class="dropdown-menu" role="menu">
                    <li role="menuitem" *ngFor="let field of emailTemplateFields.body">
                        <a style="cursor: pointer;" class="dropdown-item" (click)="insertFieldInBody(field)">{{field.name}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </quill-editor>

</div>
<div class="modal-footer">
    <div class="ws-button-row">
        <button class="ace-btn btn-primary" (click)="closeModal(template)" [disabled]="!template.packageEmailCustomTemplateBody">Save</button>
        <button class="ace-btn btn-secondary" (click)="closeModal()">Cancel</button>
    </div>
</div>
