import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ContactsUpgradeService } from '../../../contacts.service.upgrade';
import { ToastrService } from 'ngx-toastr';

export interface EditRoleModalParams {
    contact: Weissman.Model.Contacts.Contact;
    entity;
    roles: Weissman.Model.Contacts.ContactRole[];
}

@Component({
    selector: 'edit-role-modal',
    templateUrl: './editRoleModal.component.html'
})
export class EditRoleModalComponent implements IWeissmanModalComponent<EditRoleModalParams, boolean>, OnInit {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _contactsService: ContactsUpgradeService,
        private readonly _toastr: ToastrService
    ) {}

    params: EditRoleModalParams;
    result: boolean;

    contact: Weissman.Model.Contacts.Contact;
    entity;
    availableRoles: Weissman.Model.Contacts.ContactRole[];
    associatedRoles: number[];
    originalRoles: number[];
    saving: boolean;

    get isLastAssociatedRole(): boolean {
        return this.contact['entityID'] === this.entity?.id && this.contact?.contactRoleAssociations?.filter(x => x.entityType === this.entity.type).length === 1;
    }

    ngOnInit(): void {
        this.contact = this.params.contact;
        this.entity = this.params.entity;
        this.availableRoles = this.params.roles.sort((x, y) => x.roleDesc.localeCompare(y.roleDesc));
        this.associatedRoles = this.contact.contactRoleAssociations.map(x => x.contactRole?.contactRoleID || x.contactRoleID);

        this.originalRoles = [...this.associatedRoles];
    }

    get modalHeader(): string {
        return `${this.params.contact.lastName}, ${this.params.contact.firstName} Roles`;
    }

    async save(): Promise<void> {
        this.saving = true;

        const promises = [];

        const rolesToAdd = this.associatedRoles.filter(x => !this.originalRoles.includes(x))
                               .map(x => this.availableRoles.find(y => y.contactRoleID === x));
        rolesToAdd.forEach(x => promises.push(this.addRole(x)));

        const rolesToRemove = this.originalRoles.filter(x => !this.associatedRoles.includes(x))
                                  .map(x => this.contact.contactRoleAssociations.find(y => y.contactRoleID === x));
        rolesToRemove.forEach(x => promises.push(this.removeRole(x)));

        await Promise.all(promises);

        this.saving = false;
        this.result = true;
        this._bsModalRef.hide();
    }

    async addRole(selectedRole: Weissman.Model.Contacts.ContactRole): Promise<void> {
        const newRole = {
            contactID: this.contact.contactID,
            contactRoleID: selectedRole.contactRoleID,
            entityID: this.entity.id,
            entityType: this.entity.type
        };

        try {
            const result = await this._contactsService.addContactRoleAssociation(newRole);

            result.roleDesc = selectedRole.roleDesc;
            this.contact.contactRoleAssociations.push(result);

            // Now check if our contact list has this contact or not.
            const existingContactsWithThisId = this._contactsService.contacts.filter(x => x.contactID === this.contact.contactID);
            if (existingContactsWithThisId.length == 0) {
                if (!this.contact.address) {
                    // todo This was from the old code - phone and mobile phone don't exist on the contact model so not sure it does anything
                    this.contact.address = {
                        phone: this.contact['phone'],
                        mobilePhone: this.contact['mobilePhone']
                    } as Weissman.Model.Misc.Address;
                }

                this._contactsService.addToCache(this.contact);
            }
            this._contactsService.updateContactInCache(this.contact);
        } catch(err) {
            this._toastr.error(`There was an error adding ${selectedRole.roleDesc}`);
        }
    }

    async removeRole(selectedRole: Weissman.Model.Contacts.ContactRoleAssociation): Promise<void> {
        await this._contactsService.deleteContactRoleAssociation(selectedRole.contactRoleAssociationID);
        this._contactsService.updateContactInCache(this.contact);
    }

    cancel(): void {
        this._bsModalRef.hide();
    }
}
