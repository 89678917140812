<div #dropdown
     class="dropdown-menu ace-con ace-con-inline"
     [class.show]="open"
     [style.top]="dropdownPosition?.top"
     [style.left]="dropdownPosition?.left"
     clickOutside
     (clickOutside)="closeDropdown()">
    <slot name="dropdown-content">
        <span class="dropdown-content" slot="dropdown-content">
            <ng-container *ngIf="!renderedTop">
                <div
                    *ngIf="selectConfig && selectConfig.canSearch && selectConfig.options.length >= selectConfig.includeSearchAfterN"
                    class="filter">
                    <div class="search">
                        <div class="prepend">
                            <ws-ace-icon glyph="search"></ws-ace-icon>
                        </div>
                        <input type="text" autocomplete="off" placeholder="Search" #searchInput
                               [(ngModel)]="searchValue" (input)="search()">
                    </div>
                </div>
            </ng-container>
            <button *ngIf="selectConfig && selectConfig.canDeselect"
                    class="option"
                    (click)="optionSelected(selectConfig.deselectValue)">
                <span class="content">
                    {{ selectConfig.deselectLabel }}
                </span>
            </button>
            <ng-container *ngFor="let option of selectConfig?.searchableOptions">
                <ng-container *ngIf="option.group && option.items?.length">
                    <div class="group">
                        <div class="group-heading">
                            {{ option.group }}
                        </div>
                        <button *ngFor="let item of option.items"
                                class="option"
                                [class.active]="item === selectConfig.selectedOption"
                                [class.disabled]="item[selectConfig.disabledProperty]"
                                (click)="optionSelected(item)">
                            <span class="content"
                                  [ngClass]="(selectConfig.optionStyleClass && selectConfig.optionStyleClass(item[selectConfig.valueProperty]))"
                                  [ngStyle]="{ 'text-align': selectConfig.alignOptionText }"
                                  [title]="((selectConfig.isPrimitive) ? item : item[selectConfig.labelProperty])">
                                {{ ((selectConfig.isPrimitive) ? item : item[selectConfig.labelProperty]) }}
                            </span>
                        </button>
                    </div>
                </ng-container>
                <button *ngIf="!option.group"
                        class="option"
                        [class.active]="option === selectConfig.selectedOption"
                        [class.disabled]="option[selectConfig.disabledProperty]"
                        (click)="optionSelected(option)">
                    <span class="content"
                          [ngClass]="(selectConfig.optionStyleClass && selectConfig.optionStyleClass(option[selectConfig.valueProperty]))"
                          [ngStyle]="{ 'text-align': selectConfig.alignOptionText }"
                          [title]="((selectConfig.isPrimitive) ? option : option[selectConfig.labelProperty])">
                        {{ ((selectConfig.isPrimitive) ? option : option[selectConfig.labelProperty]) }}
                    </span>
                </button>
            </ng-container>

            <button
                *ngIf="!searchValue && (selectConfig?.isShowMoreFromParent || (selectConfig?.maxListSize && selectConfig?.searchableOptions.length < searchResultCount))"
                class="option"
                (click)="showMore($event)">
                <span class="content show-more"><i class="fa fa-caret-down"></i> Show More</span>
            </button>
            <ng-container *ngIf="renderedTop">
                <button
                    *ngIf="selectConfig && (selectConfig.options.length < selectConfig.includeSearchAfterN || !selectConfig.canSearch)"
                    class="option bottom">
                    <span class="content" *ngIf="!selectConfig.selectedOption">{{ selectConfig.placeholder }}</span>
                    <span class="content"
                          *ngIf="selectConfig.selectedOption">{{ (selectConfig.isPrimitive) ? selectConfig.selectedOption : selectConfig.selectedOption[selectConfig.labelProperty] }}</span>
                </button>
                <span
                    *ngIf="selectConfig && selectConfig.canSearch && selectConfig.options.length >= selectConfig.includeSearchAfterN"
                    class="search-option bottom">
                    <span class="content">
                        <label class="select-search-label">
                            <input class="exclude-modal-styling" #searchInput type="text" [(ngModel)]="searchValue"
                                   (input)="search()" />
                            <i class="fa fa-search"></i>
                        </label>
                    </span>
                </span>
            </ng-container>
        </span>
    </slot>
</div>
