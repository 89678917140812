import { Injectable } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';
import { NavigationService } from '../../Layout/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { PubSubService, IPubSubSubscription } from './pubsub.service';

/** A collection of topics for communication with the pop-up window via RPC. */
export interface IPopUpTopics {
    Attachment: {
        PreviewInitialized: string;
        AttachmentChanged: string;
    }
}

/** A collection of identifiers for global windows access in multiple points. */
export interface IPopUpStaticAliases {
    Attachment: string;
}

/** Pop-Up Service Interface */
export interface IPopUpService {
    StaticAliases: IPopUpStaticAliases;
    Topics: IPopUpTopics;
    openAttachmentPreview: (aliasGroup?: string, ref?: IPopUpRef) => Promise<IPopUpRef>;
    closeByAliasGroup: (aliasGroup: string) => void;
    getByAliasGroup: (aliasGroup: string) => IPopUpRef[];
}

/** A public API for communicating with a pop-up window. */
export interface IPopUpRef {
    publish: (topic: string, data?: any) => void;
    isOpen: () => boolean;
    close: () => void;
}

/** A private API for communicating with a pop-up window. */
interface IPopUp {
    aliasGroup: string;
    windowName: string;
    window: Window;
    ref: IPopUpRef;
    close: () => void;
    // used to resolve the promise and notify the caller that the pop-up window is ready to interact with
    ready: (ref: IPopUpRef) => void;
}

@Injectable(
    { providedIn: 'root' }
)
export class PopUpService implements IPopUpService {
    constructor(
        private _navigationServiceHandler: UpgradeNavigationServiceHandler,
        private _navigationService: NavigationService,
        private _toastr: ToastrService,
        private _pubsub: PubSubService
    ) { }

    readonly StaticAliases = {
        Attachment: 'global-attachment-pop-up'
    };

    readonly Topics = {
        Attachment: {
            PreviewInitialized: 'topics.attachment.preview-initialized',
            AttachmentChanged: 'topics.attachment.attachment-changed'
        }
    };

    private readonly _Messages = {
        PopUpBlocked: 'Your web browser appears to have blocked this function from opening a pop-up window. Please check your browser settings.'
    };

    private readonly _AppStates = {
        AttachmentPreview: 'attachmentPopup'
    };

    private _lastUsedWindowName: number = 0;

    // a collection of all pop-up
    private _popUps: IPopUp[] = [];

    private _attachmentPreviewInitializedSubscription: IPubSubSubscription;

    openAttachmentPreview(aliasGroup?: string, ref?: IPopUpRef): Promise<IPopUpRef> {
        // we're using one subscription handlers for all attachment windows and resolving the correct pop-up reference within that handler
        if (!this._attachmentPreviewInitializedSubscription) {
            this._attachmentPreviewInitializedSubscription = this._pubsub.subscribe(this.Topics.Attachment.PreviewInitialized, this._attachmentPreviewInitializedCallback.bind(this));
        }

        return new Promise((resolve, reject) => {
            // figure out what URL we're navigating to
            const url = this._navigationServiceHandler.getHrefFromState(this._AppStates.AttachmentPreview, null);

            // if a pop-up ref was specified, find the existing pop-up associated with it
            let popUp = this._popUps.find((i) => { return i.ref === ref; });

             // if no pop-up was found, create a new one
             if (!popUp) {
                popUp = {
                    aliasGroup: aliasGroup,
                    window: null,
                    windowName: this._getNextWindowName(),
                    ref: {
                        publish: (topic: string, data?: any) => {
                            if (popUp.window && !popUp.window.closed) {
                                popUp.window.focus();
                                this._pubsub.publishChild(popUp.window, topic, data);
                            }
                        },
                        isOpen: () => {
                            return popUp.window && !popUp.window.closed;
                        },
                        close: () => { popUp.close(); }
                    },
                    close: () => {
                        if (popUp.window && !popUp.window.closed) {
                            popUp.window.close();
                        }
                        delete popUp.window;
                        delete popUp.windowName;
                        const index = this._popUps.indexOf(popUp);
                        if (index !== -1) {
                            this._popUps.splice(index, 1);
                        }
                    },
                    // keep track of the resolve so we can notify the caller later that the pop-up is ready
                    ready: resolve
                };
                popUp.window = this._openWindow(url, popUp.windowName);
                if (popUp.window) {
                    this._navigationService.registerChildWindow(popUp.window);
                    this._popUps.push(popUp);
                } else {
                    reject && reject();
                }
                return;
            }

            // if found a pop-up but the window was closed by the user, we will re-open using same ref
            if (popUp && popUp.window && popUp.window.closed) {
                popUp.ready = resolve;
                popUp.window = this._openWindow(url, popUp.windowName);
                if (popUp.window) {
                    this._navigationService.registerChildWindow(popUp.window);
                    this._popUps.push(popUp);
                } else {
                    reject();
                }
                return;
            }

            // if found a pop-up and the window is open, we will reload using same ref and bring the window to focus
            if (popUp && popUp.window && !popUp.window.closed) {
                popUp.ready = resolve;
                popUp.window.focus();
                // (see logoutMessage.component.ts for notes on why the any cast is necessary)
                (<any>popUp.window.location).reload(true);
                return;
            }

            // we should never get here, all cases should have been covered in the IF checks above
            console.warn('Unknown state of the pop-up reference. Check pop-up handling logic to determine next step.');
            reject();
        });
    }

    closeByAliasGroup(aliasGroup: string) {
        const popUps = this._popUps.filter((i) => i.aliasGroup === aliasGroup);
        let i = popUps.length;
        while (i--) {
            popUps[i].close();
        }
    }

    getByAliasGroup(aliasGroup: string): IPopUpRef[] {
        return this._popUps
            .filter((i) => i.aliasGroup === aliasGroup)
            .map((i) => i.ref);
    }

    private _attachmentPreviewInitializedCallback(data: any, topic: string, windowName: string): void {
        // child window is ready, find the pop-up by window name and notify the caller that it is ready
        const popUp = this._popUps.find((i) => i.windowName === windowName);
        if (popUp) {
            popUp.ready(popUp.ref);
        }
    }

    private _getNextWindowName(): string {
        return `WEISSMAN_${++this._lastUsedWindowName}`;
    }

    private _openWindow(url: string, windowName: string): Window {
        const left = window.screenLeft + (0.6 * window.outerWidth) - 100;
        const top = window.screenTop;
        const width = (0.4 * window.outerWidth);
        const height = window.outerHeight - 100;

        const childWindow = window.open(url, windowName, `toolbar=no,location=no,directories=no,status=no,menubar=no,resizable=yes,left=${left},top=${top},width=${width},height=${height}`);
        if (!childWindow) {
            this._toastr.warning(this._Messages.PopUpBlocked);
        }
        return childWindow;
    }

}
