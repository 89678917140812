
<div class="card panel-flat-color secondary-panel">
	<div class="panel-flat-header" style="display: flex; justify-content: space-between;">
		<h3>Site and Parcel Exploration</h3>
		<div class="header-button-container">
			<div class="checkbox" style="margin: 0 5px 0 0;">
				<span *ngIf="refreshingSites">
					<i class="fa fa-spinner fa-spin"></i>&nbsp;Processing...
				</span>
				<label *ngIf="!refreshingSites && isTopLevel && companyHasSubsidiaries">
					<input type="checkbox" [(ngModel)]="includeSubsidiarySites" (change)="includeSubsidiarySitesChanged()">Include Sites for Subsidiaries
				</label>
				<label ng-hide="refreshingSites" style="margin-left: 15px;">
					<input type="checkbox" [(ngModel)]="activeOnly" (change)="getStatesWithSites()">Exclude Inactive
				</label>
			</div>
		</div>
	</div>
	<div class="panel-flat-body">
		<loading-spinner [show]="serverAction" [size]="5" [marginTop]="100"></loading-spinner>
		<div *ngIf="!serverAction">
			<div *ngIf="states?.length">
				<h4>{{sitesTitle}}</h4>
				<div class="row">
					<button class="ace-btn btn-sm btn-secondary col-lg-1"
						 (click)="doQuickSearch(state)"
						 *ngFor="let state of states">
							{{state}}
					</button>
				</div>
			</div>

			<hr />

			<button *ngIf="hasWritePermission && !isConsultantPerspective" class="ace-btn btn-primary" (click)="addSite()">Add Site</button>
		</div>
	</div>
</div>
