import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ICellRendererParamsForImportSpecificationListGridAction extends ICellRendererParams {
    viewDetails: (params: ICellRendererParamsForImportSpecificationListGridAction) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button type="button"
            *ngIf="importSpecification"
            class="ace-btn btn-primary has-icon grid-action-button"
            (click)="onViewDetailsClicked()"
            helpTooltip
            helpContentId="app.view"
            position="bottom">
            <i class="fa fa-pencil"></i>
        </button>
`
})
export class ImportSpecificationListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: ICellRendererParamsForImportSpecificationListGridAction;
    importSpecification: Compliance.ImportFileSpecificationModel;

    agInit(params: ICellRendererParamsForImportSpecificationListGridAction): void {
        this.params = params;
        this.importSpecification = params.data as Compliance.ImportFileSpecificationModel;

        if (!this.importSpecification) {

        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }

    onViewDetailsClicked(): void {
        this.params.viewDetails(this.params);
    }
}
