<div class="modal-header">
    <h4 class="modal-title">Addresses</h4>
    <span class="float-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
    </span>
</div>
<div class="modal-body exclude-modal-styling" style="padding: 35px 15px 5px 15px;">
    <div *ngIf="!loading">
        <div class="ws-flex-none" *ngIf="isAbleToBeEdited">
            <button type="button"
                    class="ace-btn btn-sm btn-primary float-end"
                    (click)="addNew()"
                    helpTooltip
                    helpContentId="app.add"
                    position="bottom"
                    [disabled]="isEditing">Add Address</button>
        </div>
        <table class="table table-striped">
            <thead>
            <tr>
                <th style="text-align: center;">
                    <span *ngIf="!justSelect">Default</span>
                </th>
                <th>Address</th>
                <th>Correspondence</th>
                <th>Remark</th>
                <th *ngIf="isAbleToBeEdited"></th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let entityAddress of entityAddresses; let i=index;">
                <tr *ngIf="entityAddress.efAction !== 'delete'" (mouseenter)="entityAddress.isHovered = true" (mouseleave)="entityAddress.isHovered = false;">
                    <td style="text-align: center;">
                        <label style="width: 100%; min-height: 35px;">
                            <input type="checkbox" [(ngModel)]="entityAddress.isDefault" (ngModelChange)="defaultSelected(entityAddress, i)"
                            [disabled]="!isAbleToBeEdited  && !justSelect">
                        </label>
                    </td>
                    <td>
                        <address-view-edit [address]="entityAddress.address" [isEditMode]="entityAddress.isEditMode"></address-view-edit>
                    </td>
                    <td>
                        <div *ngIf="!entityAddress.isEditMode">
                            <span *ngFor="let correspondence of entityAddress.entityAddressCorrespondences; let last = last;">{{correspondence.correspondenceType.name}}<span *ngIf="!last">, </span></span>
                        </div>
                        <div *ngIf="entityAddress.isEditMode">
                            <span class="label label-default"
                                  style="margin-left: 5px; display: inline-block;"
                                  *ngFor="let correspondence of entityAddress.entityAddressCorrespondences; let last = last;">
                                {{correspondence.correspondenceType.name}} <i class="fa fa-close" (click)="removeCorrespondence(correspondence, entityAddress)" style="color: white; margin-left: 5px;"></i>
                            </span>
                            <div class="btn-group" dropdown style="display: block;">
                                <button dropdownToggle type="button" class="ace-btn btn-sm btn-secondary dropdown-toggle">
                                    Add <span class="caret"></span>
                                </button>
                                <ul *dropdownMenu class="dropdown-menu" role="menu">
                                    <li role="menuitem" *ngFor="let correspondenceType of entityAddress.correspondenceTypes">
                                        <a class="dropdown-item" style="cursor: pointer;" (click)="addCorrespondence(correspondenceType, entityAddress)">{{correspondenceType.name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span *ngIf="!entityAddress.isEditMode" style="word-break: break-word;">{{entityAddress.remark}}</span>
                        <textarea *ngIf="entityAddress.isEditMode" type="text" class="form-control" [(ngModel)]="entityAddress.remark" rows="7" style="resize: none"></textarea>
                    </td>
                    <td *ngIf="isAbleToBeEdited" style="white-space: nowrap;">
                        <div *ngIf="!entityAddress.isEditMode" [class.invisible]="!entityAddress.isHovered || isEditing">
                            <button class="ace-btn has-icon btn-primary" (click)="editEntityAddress(entityAddress)"><i class="fa fa-pencil"></i></button>
                            <button class="ace-btn has-icon btn-primary btn-danger" (click)="entityAddress.efAction = 'delete'" *ngIf="!entityAddress.isDefault && !justSelect"><i class="fa fa-trash"></i></button>
                        </div>
                        <div *ngIf="entityAddress.isEditMode">
                            <button class="ace-btn has-icon btn-primary btn-success" (click)="update(entityAddress)"><i class="fa fa-check"></i></button>
                            <button class="ace-btn has-icon btn-primary btn-danger" (click)="cancelAddEdit(entityAddress)"><i class="fa fa-close"></i></button>
                        </div>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>
    <div *ngIf="loading" class="text-center">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
</div>
<div class="modal-footer">
    <div class="ws-button-row" style="justify-content: end;">
        <button class="ace-btn btn-primary" (click)="save()" [disabled]="isEditing || loading" *ngIf="isAbleToBeEdited || justSelect" helpTooltip helpContentId="app.ok">OK</button>
        <button class="ace-btn btn-secondary" *ngIf="isAbleToBeEdited || justSelect" (click)="cancel()" helpTooltip helpContentId="app.cancel">Cancel</button>
        <button class="ace-btn btn-secondary" *ngIf="!(isAbleToBeEdited || justSelect)" (click)="cancel()" helpTooltip helpContentId="app.cancel">Close</button>
    </div>
</div>
