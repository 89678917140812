import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { AssetInfoComponent, ExtendedCostAdjustmentTypeModel } from './Asset-Info/assetInfo.component';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../../Busy-Indicator';
import { AssetRepository } from '../../Repositories';
import { ModalComponentBase } from '../../modalComponentBase';
import { CommentModalData } from '../../../Comments/comments.service';
import { IWeissmanModalComponent } from '../../WeissmanModalService';
import { ASSET_DETAILS_HELP } from './assetDetails.component.help';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { AssetSummary } from '../Models/assetSummary';
import { WeissmanKeyValueDisplayPipe } from '../../../UI-Lib/Pipes';
import { StateService } from '../../../Common/States/States.Service';
import { StateSummary } from '../../../Common/States/state.model';
import { AssetInfoService } from './Asset-Info/assetInfo.service';
import { AssetLienDate } from '../Asset-Lien-Date/assetLienDate.component';
import { MessageModalService } from '../../../UI-Lib/Message-Box/messageModal.service';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import * as _ from 'lodash';

export interface AssetDetailsParams {
    reportingAssetId: number;
    assetId: number;
    lienDate: Date;
    canEdit: boolean;
    createNewAsset?: boolean;
    topLevelCompanyId: number;
    newAssetCompanyId?: number;
    assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    canViewRyanPrivateItems: boolean;
    fromLockedReturn?: boolean;
    fromLockedAllocation?: boolean;
}

export interface AssetDetailsResult {
    hasChanges: boolean;
    savedModels: Compliance.AssetModel[];
    newAssetCompanyId: number;
    initialReportingAssetIds: number[];
    totalsModel: Compliance.AssetSearchTotalsModel;
}

export interface AssetDescriptors {
    acqDateAssetDescriptors: number;
    costAssetDescriptors: number;
}

@Component({
    selector: 'asset-details',
    templateUrl: './assetDetails.component.html',
    styleUrls: ['./assetDetails.component.scss'],
    providers: [AssetInfoService]
})
export class AssetDetailsComponent extends ModalComponentBase implements OnInit, IWeissmanModalComponent<AssetDetailsParams, AssetDetailsResult> {
    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _assetRepository: AssetRepository,
        private readonly _assetInfoService: AssetInfoService,
        private readonly _helpService: HelpService,
        private readonly _keyValueDisplayPipe: WeissmanKeyValueDisplayPipe,
        private readonly _stateService: StateService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _navigationService: UpgradeNavigationServiceHandler
    ) {
        super();
    }

    @ViewChild('assetInfo') assetInfo: AssetInfoComponent;

    assetDetailModel: Compliance.AssetDetailModel;
    assetDescriptors: Compliance.CompanyAssetDescriptorMappingModel[];
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    assets: AssetSummary[] = [];
    states: StateSummary[] = [];
    changeDates: Date[] = [];

    params: AssetDetailsParams;

    result: AssetDetailsResult = {
        hasChanges: false,
        savedModels: [],
        newAssetCompanyId: 0,
        initialReportingAssetIds: [],
        totalsModel: null
    };

    canEdit: boolean = false;
    isExpandedAssetInfoPanel: boolean = true;
    isMaximizedAssetInfoPanel: boolean = false;
    isExpandedAssetActivityPanel: boolean = false;
    isMaximizedAssetActivityPanel: boolean = false;
    isExpandedReturnFilingsPanel: boolean = false;
    isMaximizedReturnFilingsPanel: boolean = false;
    isReadyAssetInfoComponent: boolean = false;
    isAssetInfoComponentInEditState: boolean = false;
    createNewAsset: boolean = false;
    newAssetCompanyId: number;
    topLevelCompanyId: number;
    assetId: number;
    reportingAssetId: number;
    assetListLienDate: Date;
    updatedOverrideLienDates: Record<number, Compliance.AssetModel[]> = {};
    lienDate: Date;
    commentModalData: CommentModalData;
    isFromLockedReturn: boolean = false;
    isFromLockedAllocation: boolean = false;
    currentAsset: AssetSummary;
    selectedAssetDescriptors: AssetDescriptors;
    minLienDate: Date;
    maxLienDate: Date;

    isInitialized: boolean = false;
    isLoading: boolean = false;
    isReturnAsset: boolean; //Are we on the return page?

    private _lienDateChanged: boolean = false;

    get assetTitle(): string {
        return this.createNewAsset ? 'New Asset' : 'Asset Details';
    }

    get hasComments(): boolean {
        return this.assetDetailModel
            && (this.assetDetailModel.assets.some(x => x.hasNonRyanInternalComments)
                || (this.params.canViewRyanPrivateItems && this.assetDetailModel.assets.some(x => x.hasRyanInternalComments)));
    }

    set hasComments(value: boolean) {
        if (this.assetDetailModel && value) {
            this.assetDetailModel.assets[0].hasNonRyanInternalComments = true;
            this.result.hasChanges = true;
            this.result.savedModels = this.assetDetailModel.assets;
        }
    }

    async ngOnInit(): Promise<void> {
        this.isReturnAsset = this._navigationService.getCurrentState().name === 'returnFilingBatchPage';
        this._helpService.setContent(ASSET_DETAILS_HELP);
        this.canEdit = this.params.canEdit;
        this.assetId = this.params.assetId;
        this.reportingAssetId = this.params.reportingAssetId;
        this.assetListLienDate = this.params.lienDate;
        this.lienDate = this.params.lienDate;
        this.assetDescriptors = this.params.assetDescriptors;
        this.isFromLockedReturn = this.params.fromLockedReturn;
        this.topLevelCompanyId = this.params.topLevelCompanyId;
        this.isFromLockedAllocation = this.params.fromLockedAllocation;

        this.createNewAsset = this.params.createNewAsset;
        if (this.createNewAsset) {
            this.newAssetCompanyId = this.params.newAssetCompanyId;
            this.assetDetailModel = {
                assetId: null,
                companyId: this.newAssetCompanyId,
                assets: [],
                alternativeCostDescriptorId: null,
                alternativeAcqDateDescriptorId: null
            };
        }

        if (this.assetId) {
            const busyMsgLoadAsset = 'load-asset';
            this.busyIndicatorMessageManager.add('Loading', busyMsgLoadAsset);

            try {
                const resultFromRepository = await lastValueFrom(this._assetRepository.get(this.assetId, this.lienDate, false, true, !this.assetDescriptors));
                this.assetDetailModel = resultFromRepository.assetDetailModel;
                this.result.initialReportingAssetIds = this.assetDetailModel.assets.map((model: Compliance.AssetModel) => model.reportingAsset.reportingAssetId);
                this.changeDates = resultFromRepository.changeDates;
                this.minLienDate = resultFromRepository.minLienDate;
                this.maxLienDate = resultFromRepository.maxLienDate;
                this.assetDescriptors = resultFromRepository.assetDescriptors || this.assetDescriptors;
                this.updatedOverrideLienDates[this.lienDate.getTime()] = resultFromRepository.assetDetailModel.assets;
            } finally {
                this.busyIndicatorMessageManager.remove(busyMsgLoadAsset);
            }

            this.commentModalData = {
                entityID: this.assetDetailModel.assetId,
                entityTypeID: Core.EntityTypes.Asset,
                parcelAcctNum: null,
                canEdit: true,
                description: null,
                parcelID: null,
                year: null
            };
        }

        this.states = await this._stateService.getSummary();
        this._setAssets();

        this.isInitialized = true;
    }

    commentCountChange(value: number) {
        if (this.assetDetailModel && value === 0) {
            this.assetDetailModel.assets[0].hasNonRyanInternalComments = false;
            this.assetDetailModel.assets[0].hasRyanInternalComments = false;
            this.result.hasChanges = true;
            this.result.savedModels = this.assetDetailModel.assets;
        }
    }

    isDirty(): boolean {
        return this.assetInfo && this.assetInfo.isFormDirty();
    }

    toggleIsExpandedForAssetInfoPanel(): void {
        if (!this.isMaximizedAssetInfoPanel) {
            this.isExpandedAssetInfoPanel = !this.isExpandedAssetInfoPanel;
        }
    }

    toggleIsMaximizedForAssetInfoPanel(): void {
        this.isExpandedAssetInfoPanel = true;
        this.isMaximizedAssetInfoPanel = !this.isMaximizedAssetInfoPanel;
    }

    toggleIsExpandedReturnFilingsPanel(): void {
        if (!this.isMaximizedReturnFilingsPanel) {
            this.isExpandedReturnFilingsPanel = !this.isExpandedReturnFilingsPanel;
        }
    }

    toggleIsMaximizedReturnFilingsPanel(): void {
        this.isExpandedReturnFilingsPanel = true;
        this.isMaximizedReturnFilingsPanel = !this.isMaximizedReturnFilingsPanel;
    }

    toggleIsExpandedForAssetActivityPanel(): void {
        if (!this.isMaximizedAssetActivityPanel) {
            this.isExpandedAssetActivityPanel = !this.isExpandedAssetActivityPanel;
        }
    }

    toggleIsMaximizedForAssetActivityPanel(): void {
        this.isExpandedAssetActivityPanel = true;
        this.isMaximizedAssetActivityPanel = !this.isMaximizedAssetActivityPanel;
    }

    enterAssetInfoEdit(): void {
        this.isAssetInfoComponentInEditState = true;
    }

    cancelAssetInfoEdit(): void {
        this.isAssetInfoComponentInEditState = false;
        this._setAssets();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    close(): void {
        this._bsModalRef.hide();
    }

    updateAssetDescriptors(assetDescriptors: AssetDescriptors): void {
        this.selectedAssetDescriptors = assetDescriptors;
    }

    setCurrentAssetModel(asset: AssetSummary): void {
        this.currentAsset = asset;
    }

    assetInfoIsReady(ready: boolean): void {
        this.isReadyAssetInfoComponent = ready;
    }

    async saveAssetInfoEdit(): Promise<void> {
        if (this.createNewAsset) {
            return await this.saveNewUserCreatedAsset();
        }

        const updateModels = this.assets.map(
            x => {
                const updateModel = x.getOverrides() as Compliance.ReportingAssetUpdateModel;
                updateModel.classificationId = x.reportedAssetClassificationId;
                updateModel.lienDate = this.lienDate;
                updateModel.sourceAssetId = x.sourceAssetId;
                updateModel.isPerpetual = x.reportingIsPerpetual;
                updateModel.ownershipTypeId = x.assetOwnershipTypeId;
                updateModel.oldAssetNumber = x.reportedOldAssetNumber;
                updateModel.isAnchored = x.reportedIsAnchored;
                updateModel.isLocked = x.reportedIsLocked;
                updateModel.doNotPotentiallyDispose = x.reportedDoNotPotentiallyDispose;
                updateModel.reportingAssetId = x.reportingAssetId;

                if (x.reportedParcelHasOverride) {
                    updateModel.reportingParcelId = x.reportedParcelId;
                }

                return updateModel;
            });

        const busyRef = this._busyIndicatorService.show({ message: 'Saving' });

        try {

            const updateModel: Compliance.AssetUpdateModel = {
                reportingAssets: updateModels,
                lienDate: this.lienDate
            };

            updateModel.alternativeAcqDateDescriptorId = this.selectedAssetDescriptors.acqDateAssetDescriptors > 0 ? this.selectedAssetDescriptors.acqDateAssetDescriptors : null;
            updateModel.alternativeCostDescriptorId = this.selectedAssetDescriptors.costAssetDescriptors > 0 ? this.selectedAssetDescriptors.costAssetDescriptors : null;

            const nonNullAdjustments = _.cloneDeep(this._assetInfoService.extendedCostAdjustmentTypes
                .map((cat: ExtendedCostAdjustmentTypeModel) => this._assetInfoService.costAdjustments[cat.costAdjustmentTypeId])
                .filter((ca: Compliance.AssetCostAdjustmentModel) => ca && (ca.adjustmentAmount !== null || ca.adjustmentPercentage !== null)));

            // convert percents to fractions of 100
            nonNullAdjustments.forEach(
                (adjustment: Compliance.AssetCostAdjustmentModel) => {
                    if (adjustment.adjustmentPercentage !== null) {
                        adjustment.adjustmentPercentage = adjustment.adjustmentPercentage / 100;
                    }
                });

            updateModel.costAdjustments = nonNullAdjustments;

            const stateAbbr = this.currentAsset ? this.currentAsset.state : null;
            if (stateAbbr) {
                const state = this.states.find(x => x.abbr === stateAbbr);
                updateModel.stateId = state && state.stateID;
            }

            await lastValueFrom(this._assetRepository
                .update(this.assetDetailModel.assetId, updateModel));

            updateModel.costAdjustments = [];

            // Save and close if the acquisition date was changed and will cause the asset to be removed from the modal
            if (updateModels.some(x => x.acqDate > this.lienDate)) {
                this.result.hasChanges = true;
                this.cancelAssetInfoEdit();
                this.close();
                return;
            }

            const resultsFromRepository = await lastValueFrom(this._assetRepository
                .get(this.assetDetailModel.assetId, this.lienDate, true, true, true));
            this.assetDetailModel = resultsFromRepository.assetDetailModel;
            this.result.totalsModel = resultsFromRepository.assetSearchTotalsModel;
            this.changeDates = resultsFromRepository.changeDates;
            this.maxLienDate = resultsFromRepository.maxLienDate;
            this.updatedOverrideLienDates[this.lienDate.getTime()] = this.assetDetailModel.assets;

            this._setAssets();
        } finally {
            if (this.isReturnAsset) {
                const propertyUpdates = this.assets.reduce((acc, x) => {
                    acc.isLocked.push(x.isLocked);
                    acc.ownershipType.push(x.assetOwnershipType);
                    acc.assetNumber.push(x.reportedAssetNumber);
                    acc.acquisitionDate.push(x.acqDate ? x.acqDate.toISOString() : null);
                    acc.disposedDate.push(x.reportedDisposedDate ? x.reportedDisposedDate.toISOString() : null);
                    acc.description.push(x.reportedDescription || null);
                    acc.cost.push(x.reportedCostHasOverride ? `$${x.reportedCost}` : null);
                    acc.netBookValue.push(x.reportedNetBookValueHasOverride ? `$${x.reportedNetBookValue}` : null);
                    acc.glAccount.push(x.reportedGlAccount && x.reportedGlAccount.accountName || null);
                    acc.site.push(x.reportedSite ? x.reportedSite.name : null);
                    acc.assetClass.push(x.reportedAssetClassificationDisplay ?  x.reportedAssetClassificationDisplay : null);
                    acc.parcel.push(x.parcelDisplay ? x.parcelDisplay : null);
                    return acc;
                }, {
                    isLocked: [],
                    ownershipType: [],
                    assetNumber: [],
                    acquisitionDate: [],
                    disposedDate: [],
                    description: [],
                    cost: [],
                    netBookValue: [],
                    glAccount: [],
                    site: [],
                    assetClass: [],
                    parcel: []
                });
                this._productAnalyticsService.logEvent('click-asset-details-save', propertyUpdates);
            }
            busyRef.hide();
        }

        this.result.hasChanges = true;
        this.result.savedModels = this.updatedOverrideLienDates[this.assetListLienDate.getTime()];
        this.cancelAssetInfoEdit();
        this.isAssetInfoComponentInEditState = false;
        this._lienDateChanged = false;
    }

    async saveNewUserCreatedAsset(): Promise<void> {
        if (!this.createNewAsset) { return; }

        const busyMsgSaveAsset = 'save-asset';
        this.busyIndicatorMessageManager.add('Saving', busyMsgSaveAsset);

        try {
            const addModel: Compliance.UserCreatedAssetAddModel = {
                acqDate: this.currentAsset.reportedAcqDate,
                assetNumber: this.currentAsset.reportedAssetNumber,
                topLevelCompanyId: this.topLevelCompanyId,
                companyId: this._assetInfoService.selectedCompanyId,
                cost: this.currentAsset.reportedCost,
                siteId: this.currentAsset.reportedSiteId,
                assetOwnershipTypeId: this.currentAsset.assetOwnershipTypeId,
                leaseTypeId: this.currentAsset.reportedLeaseTypeId,
                oldAssetNumber: this.currentAsset.reportedOldAssetNumber
            };

            const result = await lastValueFrom(this._assetRepository.createUserCreatedAsset(addModel));

            if (this.lienDate.getTime() !== result.minLienDate.getTime() &&
                this.lienDate.getTime() <= result.minLienDate.getTime()) {
                this._lienDateChanged = true;
                this.lienDate = result.minLienDate;
                this.minLienDate = result.minLienDate;
                this.changeDates = [result.minLienDate];
            } else {
                this.minLienDate = this.lienDate;
                this.changeDates = [this.lienDate];
            }

            const resultsFromRepository = await lastValueFrom(this._assetRepository.get(result.assetDetailsModel.assetId, this.lienDate));
            resultsFromRepository.assetDetailModel.assets[0].reportingAsset.doNotPotentiallyDispose = true;
            this.assetDetailModel = resultsFromRepository.assetDetailModel;
            this.createNewAsset = false;
            this._setAssets();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgSaveAsset);
        }

        this.result.hasChanges = true;
        this.result.newAssetCompanyId = this.assetDetailModel.companyId;

        this.createNewAsset = false;
        this.reportingAssetId = this.assetDetailModel.assets[0].reportingAsset.reportingAssetId;
        this.assetId = this.assetDetailModel.assetId;

        this.commentModalData = {
            entityID: this.assetDetailModel.assetId,
            entityTypeID: Core.EntityTypes.Asset,
            parcelAcctNum: null,
            canEdit: true,
            description: null,
            parcelID: null,
            year: null
        };

        this.enterAssetInfoEdit();
        this.isAssetInfoComponentInEditState = true;
    }

    async onLienDateChanged(lienDate: AssetLienDate): Promise<void> {
        if (!this.createNewAsset) {
            if (this.isAssetInfoComponentInEditState) {
                const lienDateDate = new Date(this.lienDate);
                try {
                    await this._messageModalService.confirm(
                        'Editing is in progress. If you change the date, you will lose all your changes. Are you sure you want to proceed?',
                        'Confirm'
                    );
                } catch (e) {
                    this.lienDate = lienDateDate;
                    return Promise.resolve();
                }
            }

            this.isLoading = true;
            const busyMsgLoadAsset = 'load-asset';
            this.busyIndicatorMessageManager.add('Loading', busyMsgLoadAsset);

            try {
                const resultFromRepository = await lastValueFrom(this._assetRepository.get(this.assetDetailModel.assetId, lienDate.date, false, false));
                this.assetDetailModel = resultFromRepository.assetDetailModel;
                this.result.initialReportingAssetIds = this.assetDetailModel.assets.map((model: Compliance.AssetModel) => model.reportingAsset.reportingAssetId);
            } finally {
                this.busyIndicatorMessageManager.remove(busyMsgLoadAsset);
                this.isLoading = false;
            }

            this.commentModalData = {
                entityID: this.assetDetailModel.assetId,
                entityTypeID: Core.EntityTypes.Asset,
                parcelAcctNum: null,
                canEdit: true,
                description: null,
                parcelID: null,
                year: null
            };

            this._setAssets();
        }

        this.lienDate = lienDate.date;
    }

    get lienDateChanged(): boolean {
        return this._lienDateChanged;
    }

    private _setAssets(): void {
        if (this.createNewAsset) {
            this._assetInfoService.selectedCompanyId = this.newAssetCompanyId;

            const reportingAsset: Compliance.ReportingAssetModel = {
                assetClassification: null,
                assetId: null,
                reportingAssetId: null,
                isPerpetual: false,
                isPrimary: true,
                isAnchored: false,
                doNotPotentiallyDispose: false,
                isLocked: false,
                rowVersion: null
            };

            const assetModel: Compliance.AssetModel = {
                acqDate: null,
                cost: null,
                assetId: null,
                assetNumber: null,
                companyId: this.newAssetCompanyId,
                companyCode: null,
                companyName: null,
                assetOwnershipType: null,
                hasNonRyanInternalComments: false,
                hasRyanInternalComments: false,
                hasImportedSource: false,
                reportingAsset: reportingAsset,
                reportingAssetOverride: null,
                sourceAsset: null,
                alternativeAcqDateDescriptorId: null,
                alternativeCostDescriptorId: null,
                costAdjustments: null,
                areSplitAllocationsValid: true,
                assetAdjustmentSetId: null,
                changeStatus: null,
                assetOwnershipTypeId: 0,
                splitAllocationPercentage: null,
                assetVerificationReason: null,
                alternativeAcqDateDescriptorName: null,
                alternativeCostDescriptorName: null
            };

            this.assets = [
                new AssetSummary(assetModel, this._keyValueDisplayPipe, null)
            ];

            this.currentAsset = this.assets[0];
        } else {

            if (!(this.assetDetailModel && this.assetDetailModel.assets)) {
                return;
            }

            // map the assets
            this.assets = this.assetDetailModel.assets.map((x) => {
                return new AssetSummary(_.cloneDeep(x), this._keyValueDisplayPipe, null);
            }).sort((a, b) => a.isPrimary ? -1 : 1);

            let assetIndex = this.assets.findIndex(x => x.isPrimary);

            if (assetIndex === -1) {
                assetIndex = 0;
            }

            this.currentAsset = this.assets[assetIndex];
        }
    }
}
