<div class="preview-form-viewer">
    <div class="apply-form-overrides d-flex align-baseline justify-content-between">
        <div class="d-flex">
            <div class="ws-flex-none ws-flex-align-self-center ps-3">
                <small class="me-2">Apply form overrides to:</small>
            </div>
            <div class="ws-flex-none ws-flex-align-self-center" dropdown container="body" placement="bottom left">
                <button id="button-basic" dropdownToggle type="button" class="ace-btn btn-link btn-sm dropdown-toggle ws-no-margin"
                        aria-controls="dropdown-basic">
                    {{ !appealApplicationService.shouldApplyEditsToAll ? 'This form' : 'All forms' }}
                    <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem">
                        <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="onApplyEditsToAllChange(false)">This form</a>
                    </li>
                    <li role="menuitem">
                        <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="onApplyEditsToAllChange(true)">All forms</a>
                    </li>
                </ul>
            </div>
            <div class="checkbox m-0 p-2 ps-3">
                <label>
                    <input type="checkbox" [(ngModel)]="showFieldLocation"> Show Field Locations
                </label>
            </div>
        </div>
        <div class="mr-3">
            <button *ngIf="selectedForm && selectedForm.additionalAccounts && selectedForm.additionalAccounts.length > 0"
                    class="ace-btn has-icon"
                    (click)="selectTopAppeal()"
                    [disabled]="isFormLoading"
                    title="Select Top Account">
                <i class="fa fa-wrench"></i>
            </button>
            <button class="ace-btn has-icon" (click)="loadForm()" [disabled]="isFormLoading"><i class="fa fa-refresh"></i></button>
        </div>
    </div>
    <div class="pdf-host-empty" *ngIf="!selectedForm">
        <i class="fa fa-file-pdf-o fa-5x"></i>
        <br />
        <br />
        <p class="text-muted small">Document Preview Is Not Available</p>
    </div>

    <!-- return form selected; show the PDF preview -->
    <div class="pdf-host-render" [hidden]="!selectedForm">
        <div class="form-output-wrapper" [ngClass]="{ 'hide-field-location' : !showFieldLocation }">
            <!-- TODO: Re-enable progressive loading (form fields don't load with this feature on yet) -->
            <!--<button [ngClass]="{'scroll-button-hidden' : !showPreviousPagesVisible}" id="showPreviousPagesButton" (click)="showPreviousPages()">Loading Previous Pages...</button>-->
            <div id="appeal-output" (change)="onPdfFieldChange($event)"></div>
            <!--<button [ngClass]="{'scroll-button-hidden' : !showNextPagesVisible}" id="showNextPagesButton" (click)="showNextPages()">Loading Next Pages...</button>-->
        </div>
    </div>

    <div class="pdf-field-overrides" *ngIf="selectedForm && overrideHolder.overrides.length > 0">
        <h4>Form Overrides ({{overrideHolder.overrides.length}})</h4>
        <div class="override-listing" *ngFor="let override of overrideHolder.overrides; index as i" (click)="scrollToElement(override.formElements[0])">
            <span class="number">{{i + 1}}</span> - {{ override.overrideValue }}
        </div>
    </div>
</div>
