import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({name: 'timeago'})
export class TimeAgoPipe implements PipeTransform {
    transform(value: Date): string {
        if (!value) { return ''; }
        return moment(value).fromNow();
    }
}
