import { Component, OnDestroy, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { AssetClassificationRepository } from '../../Repositories';
import { WeissmanModalService } from '../../WeissmanModalService';
import { AssetClassPickerComponent, AssetClassPickerParams } from './assetClassPicker.component';
import { of as observableOf,  Observable,  Subscription } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

interface AssetClassSelectorComponentChanges extends SimpleChanges {
    assetClassificationId: SimpleChange
}

@Component({
    selector: 'asset-class-selector',
    templateUrl: './assetClassSelector.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AssetClassSelectorComponent,
        multi: true
    }]
})
export class AssetClassSelectorComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    constructor(
        private readonly _assetClassificationRepository: AssetClassificationRepository,
        private readonly _modalService: WeissmanModalService
    ) { }

    @Input() assetClassificationId: number;
    @Input() assetClassificationName: string;
    @Input() isDisabled: boolean = false;
    @Input() isRequiredField: boolean = true;
    @Input() isOverridden: boolean = false;
    @Input() assetClassifications: Compliance.AssetClassificationModel[] = null;

    @Output() assetClassificationIdChange = new EventEmitter<number>();
    @Output() assetClassificationChange = new EventEmitter<Compliance.AssetClassificationModel>();

    assetClassificationFilter: string = '';

    // this component will be considered initialized when the asset classifications have been loaded
    // in the meanwhile, any select asset classification attempts will be skipped
    isInitialized: boolean = false;

    assetClassifications$: Observable<Compliance.AssetClassificationModel[]> = (Observable
        .create((observer: any) => { observer.next(this.assetClassificationFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterAssetClassifications(token)));

    private _assetClassificationSubscription: Subscription;
    private _onChangeFn: any;
    private _onTouchedFn: any;


    private _assetClassifications: Compliance.AssetClassificationModel[] = [];

    ngOnInit(): void {
        if (this.assetClassifications){
            this._populateAssetClassifications(this.assetClassifications);
        } else {
            this._assetClassificationSubscription = this._assetClassificationRepository
                .getAssetClassifications().pipe(
                first())
                .subscribe(data => {
                    const assetClassifications: any = data;
                    this._populateAssetClassifications(assetClassifications);
                });
        }
    }

    ngOnDestroy(): void {
        this._assetClassificationSubscription && this._assetClassificationSubscription.unsubscribe();
    }

    ngOnChanges(changes: AssetClassSelectorComponentChanges): void {
        if (changes.assetClassificationId) {
            this._selectAssetClass(this.assetClassificationId);
        }
    }

    writeValue(assetClassificationId: number): void {
        this._selectAssetClass(assetClassificationId);
    }

    registerOnChange(fn: any): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouchedFn = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onAssetClassificationBlur(): void {
        if (!this.isRequiredField && this.assetClassificationFilter === '') {
            this.assetClassificationId = null;
            this.assetClassificationName = null;
            this.assetClassificationChange.emit(null);
        }

        this._selectAssetClass(this.assetClassificationId);
        this._onTouchedFn && this._onTouchedFn();
    }

    onAssetClassificationSelected(match: TypeaheadMatch): void {
        if (match && match.item) {
            const assetClass = match.item as Compliance.AssetClassificationModel;
            this._selectAssetClass(assetClass.assetClassificationId);
            this.assetClassificationIdChange.emit(assetClass.assetClassificationId);
            this.assetClassificationChange.emit(assetClass);
        }
    }

    async openPickerModal(): Promise<void> {
        const params: AssetClassPickerParams = {
            assetClassificationId: this.assetClassificationId,
            assetClassifications: this.assetClassifications
        };

        const result = await this._modalService.showAsync(AssetClassPickerComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        this._selectAssetClass(result.assetClassificationId);
        this.assetClassificationIdChange.emit(this.assetClassificationId);
        this.assetClassificationChange.emit(result);
    }

    private _filterAssetClassifications(filter: string): Observable<Compliance.AssetClassificationModel[]> {
        if (filter === null || filter === undefined) filter = '';

        return observableOf(
            (this._assetClassifications || []).filter(i => i.name.toLowerCase().includes(filter.toLowerCase()))
        );
    }

    private _selectAssetClass(assetClassificationId: number, assetClassificationName?: string): void {
        // keep track of the value that the classification is being set to
        this.assetClassificationId = assetClassificationId;

        // if not initialized then return
        // when initialized the asset class ID that is being tracked (above) will be selected
        if (!this.isInitialized) {
            return;
        }

        const assetClass = this._assetClassifications.find(ac => ac.assetClassificationId === assetClassificationId || ac.name === assetClassificationName);
        if (assetClass) {
            this.assetClassificationId = assetClass.assetClassificationId;
            this.assetClassificationFilter = assetClass.name;
        } else {
            this.assetClassificationId = null;
            this.assetClassificationFilter = assetClassificationName;
        }

        this._onChangeFn && this._onChangeFn(this.assetClassificationId);
    }

    private _populateAssetClassifications(assetClassifications: any){
        if (assetClassifications) {
            assetClassifications.forEach(outerItem => {
                this._assetClassifications.push(outerItem);
                if (outerItem.childClassifications) {
                    outerItem.childClassifications.forEach(secondLevelItem => {
                        secondLevelItem.extraPath = `${outerItem.name  } >  `;
                        this._assetClassifications.push(secondLevelItem);
                        if (secondLevelItem.childClassifications) {
                            secondLevelItem.childClassifications.forEach(thirdLevelItem => {
                                thirdLevelItem.extraPath = `${secondLevelItem.extraPath + secondLevelItem.name  } > `;
                                this._assetClassifications.push(thirdLevelItem);
                            });
                        }
                    });
                }
            });
        }

        this.isInitialized = true;
        this._selectAssetClass(this.assetClassificationId, this.assetClassificationName);
    }
}
