import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { TaskService } from '../../Task/task.service.upgrade';
import { MessageBoxService } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ProcessingService } from '../processing.service.upgrade';

export interface CompleteFileAppealModalParams {
    taskIDs: number[];
    searchTimestamp: Date;
    optionalArgs: { parentCallsService: true }
}

@Component({
    selector: 'app-complete-file-appeal-modal',
    templateUrl: './completeFileAppealModal.component.html'
    })
export class CompleteFileAppealModalComponent implements OnInit, IWeissmanModalComponent<CompleteFileAppealModalParams, () => Promise<any>> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _taskService: TaskService,
                private readonly _messageBoxService: MessageBoxService,
                private readonly _processingService: ProcessingService) {
    }

    params: CompleteFileAppealModalParams;
    result: () => Promise<any>;

    loading: boolean;
    payload = {
        taskIDs: [],
        filingReference: null,
        searchTimestamp: null
    };

    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.payload = {
            taskIDs: this.params.taskIDs,
            filingReference: null,
            searchTimestamp: this.params.searchTimestamp
        };
        const warning = await this._taskService.validateAssessorStateReqs(this.params.taskIDs);
        if (warning) {
            await this._messageBoxService.warning(warning);
        }
        this.loading = false;
    }

    cancel() {
        this._bsModalRef.hide();
    }

    async process(): Promise<void> {
        if (this.params.optionalArgs.parentCallsService) {
            this.result = () => this._processingService.completeFileAppeal(this.payload);
            this.cancel();
        }
        else {
            this.loading = true;

            try {
                this.result = await this._processingService.completeFileAppeal(this.payload);
            } finally {
                this.cancel();
            }
        }

        //// Code to demonstrate partial success
        //console.log(['Faking out completing tasks', this.payload]);
        //setTimeout(function () {
        //    var result = {
        //        errorResult: null
        //    };

        //    result.actionResults = _.map(this.payload.taskIDs, function (taskID) {
        //        return {
        //            taskActionResult: {
        //                taskID: taskID,
        //                result: null, // would have value in real-world example
        //                errorMessage: null,
        //                isAuthorized: true,
        //                authorizationReason: 'Assigned User'
        //            }
        //        };
        //    });

        //    result.actionResults[0].taskActionResult = {
        //        taskID: result.actionResults[0].taskActionResult.taskID,
        //        result: null,
        //        errorMessage: 'An unexpected error has occurred: [uuid]',
        //        isAuthorized: true,
        //        authorizationReason: null
        //    };

        //    $uibModalInstance.close(result);
        //}, 500);
    }
}
