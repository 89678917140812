<div class="d-flex flex-column ws-flex-auto h-100 w-100" *ngIf="isInitialized">
    <div class="d-flex ws-flex-none">
        <div class="ws-flex-auto ws-truncate">
            <ws-bread-crumbs [breadcrumbs]="breadcrumbs" [currentPageTitle]="(addMode ? 'New Batch' : filingBatch?.description)"></ws-bread-crumbs>
        </div>
        <div class="ws-flex-none">
            <filing-batch-progress [filingBatch]="filingBatch" [clickable]="false"></filing-batch-progress>
        </div>
    </div>

    <div class="ws-section ws-flex-none" [hidden]="isHidden('dummy')">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title"><span *ngIf="addMode">New </span>Filing Batch</h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div class="action-button-wrapper"
                         helpTooltip
                         [helpContentId]="beginPreparationHelpContentId"
                         position="bottom">
                        <button type="button"
                                class="ace-btn btn-sm btn-primary"
                                *ngIf="!addMode && canEdit && isNotStarted"
                                [disabled]="!hasFilings || !canEnterEditMode"
                                (click)="navigateToReturnBatch()">
                            Begin Preparation
                        </button>
                    </div>
                    <div class="action-button-wrapper">
                        <button type="button"
                                class="ace-btn btn-sm btn-primary"
                                *ngIf="!addMode && isStarted"
                                [disabled]="!hasFilings || !canEnterEditMode"
                                (click)="navigateToReturnBatch()"
                                style="margin-right:0"
                                helpTooltip
                                [helpContentId]="continuePreparationHelpContentId"
                                position="bottom">
                            Continue Preparation
                        </button>
                    </div>
                    <div class="action-button-wrapper"
                         helpTooltip
                         [helpContentId]="viewReturnsHelpContentId"
                         position="bottom">
                        <button type="button"
                                class="ace-btn btn-sm btn-primary"
                                *ngIf="!addMode && isReadOnly"
                                [disabled]="!canEnterEditMode"
                                (click)="navigateToReturnBatch()">
                            View Returns
                        </button>
                    </div>
                    <div class="action-button-wrapper"
                         helpTooltip
                         helpContentId="filing-batch-details.amend-returns"
                         position="bottom">
                        <button type="button"
                                class="ace-btn btn-sm btn-secondary"
                                *ngIf="!addMode && isFinalized"
                                [disabled]="!hasFilings || !canEnterEditMode"
                                (click)="amendReturns()">
                            Amend Returns
                        </button>
                    </div>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="!addMode">
                    <entity-attachments buttonClass="ace-btn btn-sm btn-primary"
                                        [(hasAttachments)]="filingBatch && filingBatch.hasAttachments"
                                        [modalData]="attachmentsModel"
                                        [disabled]="!canEnterEditMode"
                                        helpTooltip
                                        [helpContentId]="attachmentsHelpContentId"
                                        position="bottom">
                    </entity-attachments>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="!addMode">
                    <entity-comments buttonClass="ace-btn btn-sm btn-primary"
                                     [(hasComments)]="filingBatch && filingBatch.hasComments"
                                     [modalData]="commentsModel"
                                     [disabled]="!canEnterEditMode"
                                     helpTooltip
                                     [helpContentId]="commentsHelpContentId"
                                     position="bottom">
                    </entity-comments>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="!addMode">
                    <div class="action-button-wrapper"
                         helpTooltip
                         [helpContentId]="refreshHelpContentId"
                         position="bottom">
                        <button type="button"
                                class="ace-btn btn-sm btn-secondary has-icon"
                                (click)="refresh()"
                                [disabled]="!canEnterEditMode || refreshing">
                            <i class="fa fa-refresh"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ws-section__description small" *ngIf="addMode">To get started with a new batch, populate and save the required information below.</div>
    </div>

    <div class="d-flex flex-column ws-flex-auto">
        <div class="d-flex flex-column" [ngClass]="isHidden('filing-batch-accounts') ? 'ws-flex-auto' : 'ws-flex-none'" [hidden]="isHidden('filing-batch-info')">
            <filing-batch-info *ngIf="filingBatch"
                               [filingBatch]="filingBatch"
                               [canEdit]="canEdit"
                               (onFilingBatchCreated)="onFilingBatchCreated($event)"
                               (onFilingBatchUpdated)="onFilingBatchUpdated($event)"
                               (onFilingBatchDeleted)="onFilingBatchDeleted()"
                               (onFilingBatchChangesCancelled)="onFilingBatchChangesCancelled()"
                               [showChangeDetection]="showChangeDetection">
            </filing-batch-info>
        </div>
        <div class="d-flex flex-column ws-flex-auto" [hidden]="isHidden('filing-batch-accounts')">
            <filing-batch-accounts *ngIf="filingBatch && !addMode"
                                   [filingBatch]="filingBatch"
                                   [canEdit]="canEdit"
                                   (filingsChanged)="onFilingsChanged($event)"
                                   [refresh$]="refresh$">
            </filing-batch-accounts>
        </div>
    </div>
</div>
