import { Component, OnDestroy } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { UntypedFormControl } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounce } from 'rxjs/operators';
import { CellEditorValidationResult } from './validator.interface';

export interface CheckboxCellEditorParams extends ICellEditorParams {
    cellChanged?: (initialRowValue: any, changedAmount: string) => Promise<void>;
    cellFocusLost?: (initialRowValue: any, changedAmount: string) => Promise<void>;
    validator?: (value: number, params?: CheckboxCellEditorParams) => CellEditorValidationResult;
}

@Component({
    selector: 'editor-cell',
    template: `
        <div class="currency-cell-editor">
            <span class="dollar-sign">$</span>
            <input type="number" min="0.00" step="0.01" [formControl]="currencyValue" [class.invalid]="!isValid"/>
        </div>
    `,
    styles: [
        '.currency-cell-editor { position: relative; }',
        '.dollar-sign { position: absolute; padding: 3px 5px 0; }',
        '.currency-cell-editor input { padding-left: 12px; }',
        '.currency-cell-editor input.invalid { background-color: var(--ace-danger-ghosted); }'
    ]
})
export class AgGridCurrencyCellEditorComponent implements ICellEditorAngularComp, OnDestroy {

    initialValue: number;
    isValid: boolean = true;
    currencyValue: UntypedFormControl = new UntypedFormControl();

    private _value: string;
    private _formattedValue: string;
    private _params: CheckboxCellEditorParams;
    private destroy$: Subject<void> = new Subject();

    agInit(params: CheckboxCellEditorParams): void {
        this._params = params;
        this.initialValue = params.value;
        this._currencyValueChanged(this.initialValue, false);

        this.currencyValue.valueChanges
            .pipe(takeUntil(this.destroy$))
            .pipe(debounce(() => timer(400)))
            .pipe(distinctUntilChanged())
            .subscribe((amount: string) => {
                if (!this._isUnique(+amount)) { return; }
                this._currencyValueChanged(amount);
            });
    }

    async ngOnDestroy(): Promise<void> {
        if (!this.isValid) {
            this.currencyValue.setValue(this.initialValue);
        }
        if (this._params.cellFocusLost && this.isValid && this._isUnique(+this._value)) {
            await this._params.cellFocusLost(this._params, this._formattedValue);
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    getValue(): boolean {
        return this.currencyValue.value;
    }

    isPopup(): boolean {
        return false;
    }

    private async _currencyValueChanged(amount: number | string, distinct: boolean = true): Promise<void> {
        if(amount === undefined || amount === null || isNaN(+amount)) {
            return;
        }

        this._value = (+amount).toFixed(2);

        if (this._params.validator) {
            this.isValid = this._params.validator(+amount, this._params).isValid;
        }

        this._formattedValue = `${this._value}`;
        this.currencyValue.setValue(this._formattedValue);
        if (distinct && this._params.cellChanged) {
            await this._params.cellChanged(this._params, this._value);
        }
    }

    private _isUnique(amount: number): boolean {
        return !(isNaN(amount) || this.initialValue === amount || (!amount && amount !== 0));
    }
}
