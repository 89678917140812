import { Injectable, Type } from '@angular/core';
import * as _ from 'lodash';
import { AppealSavingsWidgetComponent } from './Widgets/appeal.savings.widget.component';
import { QuickSearchButtonWidgetComponent } from './Widgets/quick.search.button.widget.component';
import { StatesButtonWidgetComponent } from './Widgets/states.button.widget.component';
import { CalendarWidgetComponent } from './Widgets/Calendar-Widget/calendar.widget.component';
import { ShortcutWidgetComponent } from './Widgets/Shortcuts-Widget/shortcut.widget.component';
import { ShortcutWidgetSettingsComponent } from './Widgets/Shortcuts-Widget/shortcut.widget.settings.component';
import { PortfolioAnalysisWidgetComponent } from './Widgets/Portfolio-Analysis-Widget/portfolio.analysis.widget.component';
import { WidgetIds } from './dashboard.model';
import { MyTasksWidgetComponent } from './Widgets/My-Tasks-Widget/my.tasks.widget.component';
import { MyTasksWidgetSettingsComponent } from './Widgets/My-Tasks-Widget/my.tasks.widget.settings.component';
import { CalendarWidgetSettingsComponent } from './Widgets/Calendar-Widget/calendar.widget.settings.component';

export class WidgetRegistry {
    widgetId: number;
    widget: Type<any>;
    settings: Type<any>;
    smallerSettingsModal?: boolean;
}

@Injectable()
export class WidgetComponentRegistryService {
    private widgetComponents: WidgetRegistry[]  = [{
        widgetId: WidgetIds.AppealSavings,
        widget: AppealSavingsWidgetComponent,
        settings: null
    }, {
        widgetId: WidgetIds.QuickSearchButton,
        widget: QuickSearchButtonWidgetComponent,
        settings: null
    }, {
        widgetId: WidgetIds.StatesButton,
        widget: StatesButtonWidgetComponent,
        settings: null
    }, {
        widgetId: WidgetIds.Calendar,
        widget: CalendarWidgetComponent,
        settings: CalendarWidgetSettingsComponent,
        smallerSettingsModal: true
    }, {
        widgetId: WidgetIds.Shortcut,
        widget: ShortcutWidgetComponent,
        settings: ShortcutWidgetSettingsComponent
    }, {
        widgetId: WidgetIds.PortfolioAnalysis,
        widget: PortfolioAnalysisWidgetComponent,
        settings: null
    }, {
        widgetId: WidgetIds.PortfolioAnalysisSummary,
        widget: PortfolioAnalysisWidgetComponent,
        settings: null
    }, {
        widgetId: WidgetIds.MyTasks,
        widget: MyTasksWidgetComponent,
        settings: MyTasksWidgetSettingsComponent
    }];

    getWidgetComponentById(widgetId: number): Type<any> {
        return _.find(this.widgetComponents, {widgetId: widgetId}).widget;
    }

    getRegisteredWidgetById(widgetId: number): WidgetRegistry {
        return _.find(this.widgetComponents, { widgetId: widgetId });
    }
}
