import { Injectable } from '@angular/core';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { Agent } from './agents.model';

const url = '/api/agent/';

@Injectable(
    { providedIn: 'root' }
)
export class AgentService {
    constructor(private http: SDHttpService) { }

    getAgents(): Promise<Agent[]> {
        return this.http.get(url) ;
    }

    getAgentById(id: number): Promise<Agent> {
        return this.http.get(url + id);
    }

    createAgent(agent: Agent): Promise<Agent> {
        return this.http.post(url, agent);
    }

    updateAgent(agent: Agent): Promise<Agent> {
        return this.http.put(url, agent);
    }

    deleteAgent(id: number): Promise<void> {
        return this.http.delete(url + id);
    }
}
