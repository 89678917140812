import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ConsultingEngagementsRepository } from 'src/app/Consulting/consultingEngagements.repository';
import { ClientServiceType, EntityTypeIds, PropertyType, RyanInstanceId } from 'src/app/constants.new';
import { ClientServiceResponsibilityService } from '../clientServiceResponsibility.service';
import { ClientServiceResponsibility, ClientServicesPanelData, EntityClientService, ClientServicePanelWorkflowUserTypes, WorkflowUserTypesUI } from '../clientServices.model';
import { InstanceRepository } from 'src/app/Entity/Instance/instance.repository';
import { InstanceRights, RestrictService } from 'src/app/Common/Permissions/restrict.service';
import { UserInstanceService } from 'src/app/User/userInstance.service';
import { ChangeHistoryModalLaunchService } from 'src/app/Common/Change-History/change-history-modal-launch.service';
import { CompanyService } from 'src/app/Entity/Company/company.service';
import { NavigationService } from 'src/app/Layout/navigation.service';
import { PropertyTypeService } from 'src/app/Common/Services/propertyType.service.upgrade';
import { ClientServicesExceptionModalService } from '../Exceptions/client-service-exception-modal.service';
import { ToastrService } from 'ngx-toastr';
import { AnnualDetailsNavigationEventService } from 'src/app/Annual-Details/annual-details-event.service';
import { lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { ProcessingService } from '../../Processing/processing.service.upgrade';
import { LongRunningProcessRepository } from '../../Compliance/Repositories';
import { WebsocketListenerService } from '../../Compliance/websocketListener.service';
import { BusyIndicatorRef, BusyIndicatorService } from 'src/app/Busy-Indicator';
import { TaxFeedSettingRepository } from '../../Entity/taxFeedSettingRepository';
import {
    DocumentIntakeLicensedClientDetailRepository
} from '../../Documents/Document-Intake-Licensed-Clients/documentIntakeLicensedClient.repository';
import { UserGroupService } from '../../User-Group/user-group-service.upgrade';
import { UserService } from '../../Account/user.service';
import { TransmittalOutputType } from 'src/app/Processing/Transmittal/transmittal.models';
import { EmptyGuid } from '../../constants.new';

import EntityTypes = Core.EntityTypes;
import ClientServiceResponsibilityUpdateQueueStatusEnum = Core.ClientServiceResponsibilityUpdateQueueStatusEnum;

import * as _ from 'lodash';
import { cloneDeep, compact, filter, flatMap, groupBy, map, reject, sortBy } from 'lodash/fp';
import { TeamService } from 'src/app/Team/team.service';

@Component({
    selector: 'client-services-panel',
    templateUrl: 'clientServicesPanel.component.html',
    styles: [`
        option[disabled] {
            color: lightgray;
        }
    `]
})
export class ClientServicesPanelComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _consultingEngagementsRepository: ConsultingEngagementsRepository,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _restrictService: RestrictService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _companyService: CompanyService,
        private readonly _navigationService: NavigationService,
        private readonly _changeHistoryModalLaunchService: ChangeHistoryModalLaunchService,
        private readonly _toastr: ToastrService,
        private readonly _annualDetailsNavigationEventService: AnnualDetailsNavigationEventService,
        private readonly _propertyTypeService: PropertyTypeService,
        private readonly _clientServicesExceptionModalService: ClientServicesExceptionModalService,
        private readonly _featureFlagService: FeatureFlagsService,
        @Inject('$rootScope') private _rootScope: any,
        private readonly _csrService: ClientServiceResponsibilityService,
        private readonly _processingService: ProcessingService,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository,
        private readonly _websocketListenerService: WebsocketListenerService,
        private readonly _busyService: BusyIndicatorService,
        private readonly _teamService: TeamService,
        private readonly _taxFeedSettingRepository: TaxFeedSettingRepository,
        private readonly _documentIntakeLicensedClientRepository: DocumentIntakeLicensedClientDetailRepository,
        private readonly _userGroupService: UserGroupService,
        private readonly _userService: UserService,
        private readonly _featureFlagsService: FeatureFlagsService
    ) {
        this._rootScope.$on('WS.COMPANY.ROWVERSION.CHANGED', (event, companyRowVersion) => {
            if (this.panelData) {
                this.panelData.companyRowVersion = companyRowVersion;
            }
        });
    }

    @Input() entity: CompanyEntity;
    @Input() company: Weissman.Model.Domain.Company;
    @Input() getAnnualGridCallback: (getCompTypesOnly: boolean) => void;
    @Input() externalLoad$: Subject<void>;
    @Output() ppReturnPreparationAllowedChanged: EventEmitter<boolean> = new EventEmitter();

    serverAction: boolean = false;
    enteringEditMode: boolean = false;
    editMode: boolean = false;
    editLocked: boolean = false;
    showBody: boolean = false;
    loadingDropdownData: boolean = false;
    panelLoading: boolean = false;
    isLongRunningProcessRunning: boolean = false;
    externalLoadSub: Subscription;

    entityInstanceId: number;
    topLevelCompanyId: number;
    hasEditPermission: boolean;
    hasComplianceFeatureView: boolean;
    isExplicitView: boolean;
    isConsultantPerspective: boolean;
    consultantsInstanceId: number;
    hasTaxFeeds: boolean;
    canEditCompany: boolean;
    isLicensedDIUser: boolean;

    panelData: ClientServicesPanelData;
    consultingEngagements: Core.ConsultingEngagementResponse[];
    availableClientServices: Weissman.Model.Workflow.ClientService[];
    newClientService: Weissman.Model.Workflow.ClientService;
    availablePropertyTypes: Weissman.Model.Assessments.PropertyType[];
    newPropertyType: Weissman.Model.Assessments.PropertyType;
    ClientServiceType = ClientServiceType;
    entityTree: Core.EntityTree;
    taxFeedSettings: Core.TaxFeedSettingModel;
    topLevelCompanyTaxFeedSettings: Core.TaxFeedSettingModel;
    ahAvailableUsers: Core.UserTeamModel[];
    csrAvailableUsers: Core.UserTeamModel[];
    paymentBatchData: Weissman.Model.Domain.DeliveryDetailPaymentBatchDTOIn;
    workflowUserTypes: Compliance.NameValuePair<ClientServicePanelWorkflowUserTypes>[] = [
        { value: Core.WorkflowUserTypes.AccountHandler, name: 'Account Handler' },
        { value: WorkflowUserTypesUI.ConsultingEngagement, name: 'Consultant' },
        { value: Core.WorkflowUserTypes.JurisdictionSpecialist, name: 'Jurisdiction Specialist' },
        { value: Core.WorkflowUserTypes.User, name: 'Specific Individual' },
        { value: Core.WorkflowUserTypes.NA, name: 'N/A' },
        { value: Core.WorkflowUserTypes.ConsultantAccountHandler, name: 'Account Handler' }, //isn't shown as a dropdown option, but is shown for consultants when their account handler is chosen
    ]; //order is intentional

    private _consultingEngagementAccountHandlerTitle: string;
    private _originalPanelData: ClientServicesPanelData;
    private _propertyTypes: Weissman.Model.Assessments.PropertyType[];
    private _originalPPReturnPreparationAllowed: boolean;
    private readonly COMPLIANCE_PREFIX = 'batch';
    private readonly ALLOCATIONS_PREFIX = 'allocation';
    private _destroy$: Subject<void> = new Subject();
    private _busyDestroy$: Subject<void> = new Subject();
    private _busyRef: BusyIndicatorRef;
    private _instanceLicensedDISettings: Core.DocumentIntakeLicensedClientModel;

    get isCompany() {
        return this.entity.type === 'company';
    }

    get isTopLevelCompany() {
        return this.isCompany && this._companyService.isTopLevel();
    }

    get accountHandlerTitle() {
        //explicit instance access
        if (this.isExplicitView) {
            if (!this.panelData.accountHandler || !this.panelData.accountHandlerTeam) {
                return 'None Assigned';
            }

            return this._formatPersonsName(this.panelData.accountHandler.lastName, this.panelData.accountHandler.firstName, this.panelData.accountHandlerTeam.name);
        }
        //implicit instance access - show consultant account handler
        else {
            return this._consultingEngagementAccountHandlerTitle;
        }
    }

    get isAccountHandlerOverridden() {
        //a subsidiary company/site/parcel cannot override the consultant's account handler
        //the account handler is controlled only through the manage consulting engagment menu

        return this.isExplicitView && this.panelData.accountHandlerIsOverridden;
    }

    get canLaunchHistory() {
        return !this.editMode
            && !this.panelLoading
            && this.entity.canEditCompanySetups
            && (!this.isCompany || this.entity.canEditCompany)
            && this.entity.hasWritePermission;
    }

    get canEdit() {
        return !this.editMode
            && !this.editLocked
            && !this.panelLoading
            && !this.isLongRunningProcessRunning
            && (
                !this.isExplicitView
                || (
                    this.hasEditPermission
                    && this.entity.canEditCompanySetups
                    && (!this.isCompany || this.entity.canEditCompany)
                    && this.entity.hasWritePermission
                    && !this.entity.isInactive
                )
            );
    }

    get canSave(): boolean {
        return this.editMode
            && (
                !this.isExplicitView
                || (
                    this.entity.canEditCompanySetups
                    && (!this.isCompany || this.entity.canEditCompany)
                    && this.entity.hasWritePermission
                )
            );
    }

    get isEntityInRyanInstance(): boolean {
        return this.entityInstanceId === RyanInstanceId;
    }

    get showJurisdictionSpecialistOption(): boolean {
        return this._userInstanceService.isCurrentInstanceRyan();
    }

    get isConsultingClient(): boolean {
        return this.consultingEngagements.length
            && this.entityInstanceId
            && !this._userInstanceService.isInstanceImplicit(this.entityInstanceId);
    }

    async ngOnInit(): Promise<void> {
        const validProcessTypes = [Compliance.LongRunningProcessTypeEnum.UpdateCsrBulk, Compliance.LongRunningProcessTypeEnum.UpdateCsr];
        this._websocketListenerService.longRunningProcessStatusChange$.pipe(takeUntil(this._destroy$)).subscribe(
            async (x) => {
                if ((!validProcessTypes.includes(x.processType)) || x.entityId !== this.topLevelCompanyId) {
                    return;
                }
                if (x.isCanceled || x.isError || x.isCompleted) {
                    this.isLongRunningProcessRunning = false;
                    this.panelLoading = true;

                    await this._loadPanel();
                    this.editMode = false;

                    this.panelLoading = false;
                    if (this._busyRef) {
                        this._busyRef.hide();
                        this._busyRef = null;
                    }
                    this._csrService.notifyClientServicesChange();
                    const lrp = await lastValueFrom(this._longRunningProcessRepository.get(x.longRunningProcessId));
                    if (lrp.result) {
                        const result = JSON.parse(lrp.result);
                        if (result?.result?.companyRowVersion) {
                            this._broadcastRowVersionChange(result.result.companyRowVersion);
                        }
                    }
                } else {
                    this.isLongRunningProcessRunning = true;
                }
            });

        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$)).subscribe(editMode => {
            this.editLocked = editMode;
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async expandOrCollapse(): Promise<void> {
        if (this.editMode) {
            return;
        }

        this.showBody = !this.showBody;

        if (this.showBody) {
            this.panelLoading = true;
            await this._loadPanel();

            this.panelLoading = false;
            this.externalLoadSub = this.externalLoad$?.subscribe(() => this._loadPanel());
        }
        else {
            this.externalLoadSub?.unsubscribe();
        }
    }

    launchHistoryModal(): void {
        this._changeHistoryModalLaunchService.openChangeHistoryModal(this.entity.name, this.entity.id, this.entity.typeId, 'CSR');
    }

    async goToEditMode(): Promise<void> {
        this.enteringEditMode = true;
        this.loadingDropdownData = true;

        try {
            const clientServices = await this._csrService.getAll();

            this.availableClientServices = _.reject(clientServices, (x: Weissman.Model.Workflow.ClientService) => {
                return (x.name === 'Allocations' && !this._featureFlagService.featureFlags.enableAllocationFeature)
                       || _.some(this.panelData.entityClientServices, { clientServiceID: x.clientServiceID });
            });

            this.availablePropertyTypes = _.reject(this._propertyTypes, x => {
                return _.some(this.panelData.entityClientServices[0].responsibilities, { propertyTypeID: x.propertyTypeID });
            });
        } catch {
            throw('Error loading services or property types');
        } finally {
            this.loadingDropdownData = false;
        }

        this._originalPPReturnPreparationAllowed = this.entity.ppReturnPreparationAllowed;

        this._originalPanelData = _.cloneDeep(this.panelData);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');

        this.ahAvailableUsers = await this._teamService.getAllAssignableUsers(false, false, this.entity.id, this.entity.type);

        if (this.isConsultantPerspective) {
            this.csrAvailableUsers = await this._teamService.getAllAssignableUsers(false, false, this.entity.id, this.entity.type, this.consultantsInstanceId);
        }
        else {
            this.csrAvailableUsers = this.ahAvailableUsers;
        }

        this.panelData.entityClientServices.forEach((service: EntityClientService) => {
            service.responsibilities.forEach(async(responsibility: ClientServiceResponsibility) => {
                responsibility.placeholderText =  await this._getPlaceholderText(responsibility);
            });
        });

        this.enteringEditMode = false;
        this.editMode = true;
    }

    cancelEdit() {
        if (this.isTopLevelCompany) {
            this.entity.ppReturnPreparationAllowed = this._originalPPReturnPreparationAllowed;
        }

        this._navigationService.disableNavWarning();
        this.panelData = this._originalPanelData;
        this.editMode = false;
    }

    async savePanel(): Promise<void> {
        if (this.panelData.companyIsActive && (!this.panelData.accountHandlerUserID || this.panelData.accountHandlerTeamID === undefined)) {
            this._toastr.error('Account Handler is required for active companies', 'Error');
            return;
        }

        const panelToSave: any = _.pick(this.panelData, 'accountHandlerUserID', 'accountHandlerTeamID', 'entityID', 'entityTypeID', 'companyRowVersion');

        if (this.isCompany && this.entity.isTopLevel) {
            panelToSave.ppReturnPreparationAllowed = this.entity.ppReturnPreparationAllowed;
        }

        if (this.isCompany && this.entity.isTopLevel || this.isParcel) {
            panelToSave.assessmentsTaxFeedEnabled = this.taxFeedSettings.assessmentsTaxFeedsEnabled;
            panelToSave.taxBillsTaxFeedEnabled = this.taxFeedSettings.taxBillsTaxFeedsEnabled;
        }

        const originalResponsibilities = _.flatMap(this._originalPanelData.entityClientServices, 'responsibilities');

        panelToSave.responsibilities = _.flow([
            cloneDeep,
            flatMap('responsibilities'),
            map(resp => {
                const originalResp = _.find(originalResponsibilities, {clientServiceResponsibilityID: resp.clientServiceResponsibilityID});

                if (!resp.clientServiceResponsibilityID) {
                    resp.efAction = 'add';
                } else if (originalResp &&
                    (originalResp.workflowUserTypeID !== resp.workflowUserTypeID ||
                        originalResp.userID !== resp.userID ||
                        originalResp.teamID !== resp.teamID ||
                        originalResp.consultingEngagementId !== resp.consultingEngagementId ||
                        (originalResp.dateFrom - resp.dateFrom !== 0))) {
                    if (resp.entityID !== this.entity.id) {
                        resp.entityID = this.entity.id;
                        resp.entityTypeID = EntityTypeIds[this.entity.type.toUpperCase()];
                        resp.addingOverride = true;

                        delete resp.isException;
                        delete resp.isTopCompany;
                        delete resp.clientServiceResponsibilityID;

                        resp.efAction = 'add';
                    } else {
                        resp.efAction = 'update';
                    }
                }

                resp.clientService = null;
                resp.assignee = null;
                resp.workflowUserType = null;
                resp.team = null;

                return resp;
            }),
            filter('efAction')
        ])(this.panelData.entityClientServices);

        // now check which details were changed
        //
        const editedDeliveryDetails = this._extractDeliveryDetails(this.panelData.entityClientServices);
        const originalDeliveryDetails = this._extractDeliveryDetails(this._originalPanelData.entityClientServices);

        // We now allow an incomplete Payment Batch configuration to be saved, deferring
        // validation to creation of a Payment Batch, so we don't need to ensure the
        // Payment Batch/ configuration is present or feed specifications have been selected.

        const invalidInputFound = _.some(editedDeliveryDetails, detail => {
            return detail
                && ((detail.isMaxPackageSize && !detail.maxPackageSize)
                    || (detail.isMaxPaymentItems && !detail.maxPaymentItemsPerTransmittal));
        });

        if (invalidInputFound) {
            this._toastr.error('Maximum values must be between 1 and 1000', 'Error');
            return;
        }

        let removePaymentBatchDataFlag = false;

        // // so, which deliveryDetails are we going to save?
        // //
        const deliveryDetailsToSave = _.flow([
            map(editedDeliveryDetail => {

                // update editedDeliveryDetail if necessary
                //
                let originalDeliveryDetail = this._getOriginalDeliveryDetail(originalDeliveryDetails, editedDeliveryDetail);

                if (!originalDeliveryDetail) {  // if compliance return or assessment allocation, then editedDeliveryDetail can be null
                    if (this._isComplianceReturns(editedDeliveryDetail) || this._isAllocations(editedDeliveryDetail )) {
                        originalDeliveryDetail = {};
                    } else {
                        console.warn('Could not find a matching original delivery detail');
                        return {};
                    }
                }

                if (!originalDeliveryDetail.useTopLevelCompanySettings && editedDeliveryDetail.useTopLevelCompanySettings) {
                    originalDeliveryDetail.efAction = 'delete';

                    return _.omit(originalDeliveryDetail, 'isMaxPackageSize', 'isMaxPaymentItems', 'isOne');
                }

                //console.log('editedDeliveryDetail - before checking', editedDeliveryDetail);

                // useTopLevelCompanySettings - if original is different from edited
                //
                if (originalDeliveryDetail.useTopLevelCompanySettings && !editedDeliveryDetail.useTopLevelCompanySettings) {
                    if (editedDeliveryDetail.taxBillDeliveryDetailID !== undefined) {
                        editedDeliveryDetail.taxBillDeliveryDetailID = 0;
                    }
                    else if (editedDeliveryDetail.deliveryDetailAssessmentID !== undefined) {
                        editedDeliveryDetail.deliveryDetailAssessmentID = 0;
                    }
                    else if (editedDeliveryDetail.deliveryDetailBillID !== undefined) {
                        editedDeliveryDetail.deliveryDetailBillID = 0;
                    }

                    editedDeliveryDetail.efAction = 'add';
                }
                else if (!_.isEqual(originalDeliveryDetail, editedDeliveryDetail)) {
                    // WK-6116: If there was no existing entry for tax bill delivery detail but the user made changes,
                    // we need to add a new delivery detail
                    if ((originalDeliveryDetail.taxBillDeliveryDetailID === 0 && editedDeliveryDetail.taxBillDeliveryDetailID === 0) || _.isEmpty(originalDeliveryDetail)) {
                        editedDeliveryDetail.efAction = 'add';
                    }
                    else {
                        editedDeliveryDetail.efAction = 'update';
                    }
                }

                // user did not change batch users/ teams, but switched on the whole thing
                //
                if (
                    this._isComplianceReturns(editedDeliveryDetail)
                    && this._originalPPReturnPreparationAllowed != this.entity.ppReturnPreparationAllowed
                    && this.isTopLevelCompany			// this info can be saved for top-level company only
                ) {
                    editedDeliveryDetail.efAction = editedDeliveryDetail.deliveryDetails_PPBatchReturnsId
                        ? 'update'
                        : 'add';
                }

                // Prepare PP Returns is set to Off, but the panel still creates the detail with all properties empty and efAction = add. This caused PT-3826
                // https://weissmandemo.atlassian.net/browse/PT-3826
                if (this._isComplianceReturns(editedDeliveryDetail) &&
                    !this.entity.ppReturnPreparationAllowed &&
                    editedDeliveryDetail.efAction === 'add') {
                    editedDeliveryDetail.efAction = null;
                }

                // if an ef action hasn't been determined yet, check the feed specification
                if (
                    !editedDeliveryDetail.efAction
                    && editedDeliveryDetail.taxBillDeliveryDetailID
                    && (this._isFeedSpecificationsUpdated(originalDeliveryDetail, editedDeliveryDetail)
                        || this.paymentBatchData) // need to send feed spec data if payment batch has been updated
                    ) {
                        editedDeliveryDetail.efAction = 'update';
                }

                if (originalDeliveryDetail.transmittalOutputTypeID === TransmittalOutputType.BillPay
                    && editedDeliveryDetail.transmittalOutputTypeID !== TransmittalOutputType.BillPay) {
                        removePaymentBatchDataFlag = true;
                }

                return _.omit(editedDeliveryDetail, 'isMaxPackageSize', 'isMaxPaymentItems', 'isOne');
            }),
            filter('efAction'),
            groupBy(detail => {
                if (detail.taxBillDeliveryDetailID !== undefined) {
                    return 'deliveryDetails';
                }
                else if (detail.deliveryDetailAssessmentID !== undefined) {
                    return 'deliveryDetailAssessments';
                }
                else if (detail.deliveryDetailBillID !== undefined) {
                    return 'deliveryDetailBills';
                }
                else if (this._isComplianceReturns(detail)) {
                    return 'DeliveryDetailPPReturnBatches';
                }
                else if (this._isAllocations(detail)) {
                    return 'deliveryDetailAssessmentAllocation';
                }
                else {
                    return 'errorNoID';
                }
            })
        ])(editedDeliveryDetails);

        if (this._featureFlagsService.featureFlags.enablePaymentBatch &&
            this.isEntityInRyanInstance &&
            this.isCompany) {

            if(this.paymentBatchData) {
                deliveryDetailsToSave.deliveryDetailPaymentBatch = [this.paymentBatchData];
            }

            if(removePaymentBatchDataFlag) {
                deliveryDetailsToSave.deliveryDetailPaymentBatch = deliveryDetailsToSave.deliveryDetailPaymentBatch || [{ ownerEntityID: this.entity.id}];
                deliveryDetailsToSave.deliveryDetailPaymentBatch[0].efAction = 'delete';
            }
        }

        _.assign(panelToSave, deliveryDetailsToSave);

        //Check if all fields that require a date contain the date, otherwise throw toast
        const invalidDate = _.some(panelToSave.responsibilities, resp => resp.showDatePicker && !resp.dateFrom);
        if (invalidDate) {
            this._toastr.error('Please fill in all start dates', 'Missing date');
            return;
        }

        //Check invoice tasks under appeals
        const invalidAppeal = _.some(panelToSave.responsibilities, resp => {
            return resp.clientServiceID === ClientServiceType.Appeals
                && resp.workflowUserTypeID === Core.WorkflowUserTypes.NA
                && resp.hasOpenPrepareDraftInvoiceTasks;
        });

        if (invalidAppeal) {
            this._toastr.error('Appeals responsibility can not be set to N/A if there is at least one unfinished "Prepare Draft Invoice" task ', 'Validation error');
            return;
        }


        if (!this.isTopLevelCompany && panelToSave.DeliveryDetailPPReturnBatches)
            delete panelToSave.DeliveryDetailPPReturnBatches;

        // Prepare PP Return is on, but DeliveryDetailPPReturnBatches is empty because no batch responsibilities have been entered.
        if(this.entity.ppReturnPreparationAllowed  && !this._originalPPReturnPreparationAllowed && !panelToSave.DeliveryDetailPPReturnBatches) {
            this._toastr.error('Please enter Batch Responsibilities', 'Validation error');
            return;
        }

        if (panelToSave.DeliveryDetailPPReturnBatches && panelToSave.DeliveryDetailPPReturnBatches.length && panelToSave.ppReturnPreparationAllowed) {
            if (this._isUserEmpty('batchPreparer', panelToSave.DeliveryDetailPPReturnBatches[0])) {
                this._toastr.error('Please select Batch Preparer', 'Validation error');
                return;
            }

            if (this._isUserEmpty('batchProcessor', panelToSave.DeliveryDetailPPReturnBatches[0])) {
                this._toastr.error('Please select Batch Processor', 'Validation error');
                return;
            }

            if (this._isUserEmpty('batchReviewer', panelToSave.DeliveryDetailPPReturnBatches[0])) {
                this._toastr.error('Please select Batch Reviewer', 'Validation error');
                return;
            }
        }

        this.serverAction = true;

        try {
            const result = await this._csrService.saveEntity(panelToSave);

            if (result.longRunningProcessId) {
                //Show busy indicator
                this._busyRef = this._busyService.show({
                    longRunningProcessId: result.longRunningProcessId,
                    title: 'Updating Client Service Responsibility',
                    canDismiss: true,
                    message: '',
                    captureFocus: true,
                    restoreFocus: true,
                });

                this._busyRef.onDismiss().pipe(takeUntil(this._busyDestroy$)).subscribe(() => {
                    this._busyRef.hide();
                    this._busyDestroy$.next();
                    this._busyRef = null;
                    this.panelLoading = true;
                });

                this._navigationService.disableNavWarning();
            }
            else {
                this.editMode = false;
                this._navigationService.disableNavWarning();
                if (this.isCompany) {
                    this.ppReturnPreparationAllowedChanged.emit(this.entity.ppReturnPreparationAllowed);
                    this._broadcastRowVersionChange(result.companyRowVersion);
                }

                if (this.getAnnualGridCallback) {
                    this._annualDetailsNavigationEventService.goToGrid();
                    this.getAnnualGridCallback(false);
                }

                this._loadPanel();
            }
        } catch(err) {
            if(err) {
                this._toastr.error(err.error.message);
            }
        } finally {
            this.serverAction = false;
        }

    }

    accountHandlerSelected(newAccountHandler: Core.UserTeamModel) {
        if(newAccountHandler) {
            this.panelData.accountHandlerUserID = newAccountHandler.userID;
            this.panelData.accountHandlerTeamID = newAccountHandler.teamID;
            this.panelData.accountHandlerTeam = {
                name: newAccountHandler.teamName,
                teamID: newAccountHandler.teamID
            } as Weissman.Model.Workflow.Team;
            this.panelData.accountHandler = {
                efAction: null,
                firstName: newAccountHandler.firstName,
                lastName: newAccountHandler.lastName,
                userID: newAccountHandler.userID
            };
        } else {
            this.panelData.accountHandlerUserID = null;
            this.panelData.accountHandlerTeamID = null;
            this.panelData.accountHandlerTeam = {} as Weissman.Model.Workflow.Team;
            this.panelData.accountHandler = {} as Core.ContactShortDTO;
        }
    }

    addClientService(): void {
        const serviceToAdd = _.pick(this.newClientService, ['clientServiceID', 'name', 'sequence']) as EntityClientService;
        const entityResponsibilities = this.panelData.entityClientServices.length ? _.cloneDeep(this.panelData.entityClientServices[0].responsibilities) : [];

        serviceToAdd.responsibilities = _.map(entityResponsibilities, resp => {
            _.assign(resp, this._getInitialValues());
            resp.clientServiceID = this.newClientService.clientServiceID;
            resp.disabledWorkflowUserTypes = this._getDisabledWorkflowUserTypes({ propertyTypeID: resp.propertyTypeID, clientServiceResponsibilityUpdateStatus: ClientServiceResponsibilityUpdateQueueStatusEnum.Created }, serviceToAdd);
            resp.translatedWorkflowUserTypeId = this._getTranslatedWorkflowUserTypeId(resp);

            return _.omit(resp, 'workflowUserType', 'clientServiceResponsibilityID') as ClientServiceResponsibility;
        });

        this.panelData.entityClientServices.push(serviceToAdd);
        _.remove(this.availableClientServices, this.newClientService);
    }

    addPropertyType(): void {
        const propTypeToAdd = _.pick(this.newPropertyType, ['propTypeAbbr', 'propertyTypeID']) as any;
        _.assign(propTypeToAdd, this._getInitialValues());

        this.panelData.entityClientServices = _.map(this.panelData.entityClientServices, service => {
            const respToAdd = _.cloneDeep(propTypeToAdd);

            respToAdd.clientServiceID = service.clientServiceID;
            respToAdd.disabledWorkflowUserTypes = this._getDisabledWorkflowUserTypes({ propertyTypeID: respToAdd.propertyTypeID, clientServiceResponsibilityUpdateStatus: ClientServiceResponsibilityUpdateQueueStatusEnum.Created }, service);
            respToAdd.translatedWorkflowUserTypeId = this._getTranslatedWorkflowUserTypeId(respToAdd);

            //does not have all fields of ClientServiceResponsibility
            service.responsibilities.push(respToAdd as ClientServiceResponsibility);
            return service;
        });

        _.remove(this.availablePropertyTypes, this.newPropertyType);
    }

    canEditResponsibility(responsibility: any): boolean {
        return this.editMode
            && (responsibility.clientServiceResponsibilityUpdateStatus === null
                || responsibility.clientServiceResponsibilityUpdateStatus === ClientServiceResponsibilityUpdateQueueStatusEnum.Errant)
            && (!this.isConsultantPerspective
                && !this.isLicensedDIUser
                || (responsibility.consultingEngagementId && responsibility.isMyConsultingCompany));
    }


    showSpecificIndividualUserTeamPicker(responsibility: ClientServiceResponsibility): boolean {
        return responsibility.translatedWorkflowUserTypeId === Core.WorkflowUserTypes.User
            && this.canEditResponsibility(responsibility);
    }

    showConsultingEngagementsList(responsibility: ClientServiceResponsibility): boolean {
        return responsibility.translatedWorkflowUserTypeId === WorkflowUserTypesUI.ConsultingEngagement
            && this.canEditResponsibility(responsibility);
    }

    showWorkflowUserTypeOption(userTypeValue: number){
        switch(userTypeValue){
            case Core.WorkflowUserTypes.JurisdictionSpecialist:
                return this.isEntityInRyanInstance || this.showJurisdictionSpecialistOption;
            case Core.WorkflowUserTypes.ConsultantAccountHandler:
                return false;
            case WorkflowUserTypesUI.ConsultingEngagement:
                return this.isConsultingClient
                    && this.consultingEngagements
                    && this.consultingEngagements.length
                    && !this.isConsultantPerspective;
            default:
                return true;
        }
    }

    async retryResponsibilityUpdate(responsibility: any): Promise<void> {
        this._busyRef = this._busyService.show({
            title: 'Retrying task updates',
            canDismiss: true,
            message: '',
            captureFocus: true,
            restoreFocus: true,
        });
        try {
            await this._csrService.retryWorkflowUpdateFromQueue({entityTypeId: responsibility.entityTypeID, entityId: responsibility.entityID, clientServiceId: responsibility.clientServiceID, propertyTypeId: responsibility.propertyTypeID});
        } finally {
            this._busyRef.hide();
            this._busyRef = null;
        }

        responsibility.clientServiceResponsibilityUpdateStatus = null;
    }

    showResponsibilityUpdatingInfoIcon(status: number): boolean{
        return status === ClientServiceResponsibilityUpdateQueueStatusEnum.Created
        || status ===  ClientServiceResponsibilityUpdateQueueStatusEnum.Running
        || status === ClientServiceResponsibilityUpdateQueueStatusEnum.Completed;
    }

    showRetryResponsibilityUpdateIcon(status: number): boolean{
        return status === ClientServiceResponsibilityUpdateQueueStatusEnum.Errant;
    }

    userTypeChanged(workflowUserTypeId: ClientServicePanelWorkflowUserTypes, responsibility: ClientServiceResponsibility): void {
        const previousWorkflowUserTypeId = responsibility.translatedWorkflowUserTypeId;
        responsibility.translatedWorkflowUserTypeId = workflowUserTypeId;

        switch(responsibility.translatedWorkflowUserTypeId) {
            case WorkflowUserTypesUI.ConsultingEngagement:
                responsibility.workflowUserTypeID = Core.WorkflowUserTypes.ConsultantAccountHandler;
                // Note that there is a select component bound to responsibility.consultingEngagementID when there
                // is more than one consulting engagement; it does not exist when only one is available, so it needs
                // to be populated here
                if(this.consultingEngagements.length === 1) {
                    responsibility.consultingEngagementId = this.consultingEngagements[0].consultingEngagementId;
                }
                break;
            case Core.WorkflowUserTypes.AccountHandler:
                //special handling of account handler when consultant looking at it
                if(this.isConsultantPerspective) {
                    responsibility.workflowUserTypeID = Core.WorkflowUserTypes.ConsultantAccountHandler;
                    responsibility.consultingEngagementId = this.consultingEngagements.find(ce => this._userInstanceService.isInstanceExplicit(ce.instanceId)).consultingEngagementId;
                }
                //client perspective of account handler
                else {
                    responsibility.workflowUserTypeID = responsibility.translatedWorkflowUserTypeId;
                    responsibility.consultingEngagementId = null;
                }
                break;
            case Core.WorkflowUserTypes.JurisdictionSpecialist:
                responsibility.workflowUserTypeID = Core.WorkflowUserTypes.JurisdictionSpecialist;

                if(this.isConsultantPerspective) {
                    responsibility.consultingEngagementId = this.consultingEngagements.find(ce => this._userInstanceService.isInstanceExplicit(ce.instanceId)).consultingEngagementId;
                }
                else {
                    responsibility.consultingEngagementId = null;
                }
                break;
            case Core.WorkflowUserTypes.User:
                //consultant person selected
                if (this.isConsultantPerspective) {
                    responsibility.consultingEngagementId = this.consultingEngagements.find(ce => this._userInstanceService.isInstanceExplicit(ce.instanceId)).consultingEngagementId;
                }
                //client person selected
                else {
                    responsibility.consultingEngagementId = null;
                }

                responsibility.workflowUserTypeID = responsibility.translatedWorkflowUserTypeId;

                if (previousWorkflowUserTypeId === WorkflowUserTypesUI.ConsultingEngagement ||
                    previousWorkflowUserTypeId === Core.WorkflowUserTypes.AccountHandler) {
                    responsibility.userID = null;
                    responsibility.teamID = null;
                    responsibility.team = {} as Weissman.Model.Workflow.Team;
                    responsibility.assignee = {} as Core.ContactShortDTO;
                }
                break;
            //NA selected
            default:
                responsibility.workflowUserTypeID = responsibility.translatedWorkflowUserTypeId;
                if (this.isConsultantPerspective) {
                    responsibility.consultingEngagementId = this.consultingEngagements.find(ce => this._userInstanceService.isInstanceExplicit(ce.instanceId)).consultingEngagementId;
                }
                else {
                    responsibility.consultingEngagementId = null;
                }
        }

        //changing from NA to another user type
        if (previousWorkflowUserTypeId === Core.WorkflowUserTypes.NA && responsibility.workflowUserTypeID !== Core.WorkflowUserTypes.NA) {
            responsibility.showDatePicker = true;
        }
        //changing to NA
        else if (responsibility.workflowUserTypeID === Core.WorkflowUserTypes.NA) {
            responsibility.showDatePicker = false;
            responsibility.dateFrom = null;
        }

        //no specific user
        if (responsibility.workflowUserTypeID !== Core.WorkflowUserTypes.User) {
            responsibility.userID = null;
            responsibility.teamID = null;
            responsibility.team = {} as Weissman.Model.Workflow.Team;
            responsibility.assignee = {} as Core.ContactShortDTO;
        }

        this.panelData.entityClientServices = _.map(this.panelData.entityClientServices, service => {
            service.responsibilities = _.map(service.responsibilities, resp => {
                resp.disabledWorkflowUserTypes = this._getDisabledWorkflowUserTypes(resp, service);
                return resp;
            });

            return service;
        });

        // Initially seed data from selected consulting engagement (this might not get called otherwise if there's no dropdown)
        if (responsibility.workflowUserTypeID === Core.WorkflowUserTypes.ConsultantAccountHandler) {
            this.changeConsultingEngagement(responsibility);
        }
    }

    showExceptionsLink(status: number): boolean{
        return !(this.editMode && (status === null || status === ClientServiceResponsibilityUpdateQueueStatusEnum.Errant));
    }

    openExceptionsModal(responsibility: ClientServiceResponsibility, service: EntityClientService) {
        this._clientServicesExceptionModalService.openExceptionsModal(this.entity.id, +EntityTypeIds[this.entity.type.toUpperCase()], [service.clientServiceID], [responsibility.propertyTypeID], this.entity.isInactive);
    }

    specificIndividualSelected(newIndividual: Core.UserTeamModel, responsibility: ClientServiceResponsibility) {
        responsibility.userID = newIndividual.userID;
        responsibility.teamID = newIndividual.teamID;

        responsibility.team = {
            name: newIndividual.teamName,
            teamID: newIndividual.teamID
        } as Weissman.Model.Workflow.Team;

        responsibility.assignee = {
            efAction: null,
            firstName: newIndividual.firstName,
            lastName: newIndividual.lastName,
            userID: newIndividual.userID
        };
    }

    changeConsultingEngagement(responsibility: ClientServiceResponsibility): void {
        const engagement = _.find(this.consultingEngagements, {consultingEngagementId: responsibility.consultingEngagementId});

        responsibility.userID = engagement.accountHandler?.userId;
        responsibility.teamID = engagement.accountHandler?.teamId;
    }

    get isParcel(): boolean {
        return this.entity.type === 'parcel';
    }

    isTaxFeedToggleEnabled(topLevelTaxFeedSetting: boolean): boolean {
        return this.isTopLevelCompany
        && this.hasTaxFeeds
        || (this.isParcel
            && this.canEditCompany
            && topLevelTaxFeedSetting);
    }

    get currentInstanceId(): number{
        return this.isConsultantPerspective ? this.consultantsInstanceId : this.entityInstanceId;
    }

    private _getInitialValues(): ClientServiceResponsibility {
        return {
            teamID: null,
            userID: null,
            assignee: {},
            team: {},
            workflowUserTypeID: Core.WorkflowUserTypes.NA,
            propertyTypeXServiceHasChildExceptions: false,
            entityID: this.entity.id,
            entityTypeID: EntityTypeIds[this.entity.type.toUpperCase()],
            isException: !this.isTopLevelCompany
        } as ClientServiceResponsibility;
    }

    private async _loadPanel(): Promise<void> {
        this.paymentBatchData = undefined;

        this.serverAction = true;
        try {
            const [aeEntityTypeId, aeEntityId] = this.company
                ? [EntityTypeIds.COMPANY, this.company.topLevelCompanyId]
                : [this.entity.typeId, this.entity.id];

            [
                this.consultingEngagements,
                this._propertyTypes,
                this.panelData,
                this.entityInstanceId,
                this.entityTree,
                this.taxFeedSettings
            ] = await Promise.all(
                [
                    this._consultingEngagementsRepository.getAcceptedEngagements(aeEntityTypeId, aeEntityId),
                    this._propertyTypeService.get(),
                    this._csrService.getByEntity(this.entity),
                    lastValueFrom(this._instanceRepository.getEntityInstanceId(this.entity.type, this.entity.id)),
                    this._processingService.getEntityTree(this.entity.typeId, this.entity.id, false),
                    lastValueFrom(this._taxFeedSettingRepository.getByEntity(this.entity.id, this.entity.typeId))
                ]
            );

            this.topLevelCompanyId = this.entityTree.topLevelCompany
                                        ? this.entityTree.topLevelCompany.entityId
                                        : this.entityTree.subsidiaryCompany.entityId;

            if (this.entity.typeId === EntityTypes.Parcel) {
                this.topLevelCompanyTaxFeedSettings = await lastValueFrom(this._taxFeedSettingRepository.getByEntity(this.topLevelCompanyId, EntityTypes.Company));
            }
            const currentlyRunningUpdates = await lastValueFrom(this._longRunningProcessRepository.getList({
                columnFilters: [
                    {filterProperty: Compliance.LongRunningProcessPropertyEnum.EntityId, filterConditionType: Core.FilterConditionTypeEnum.And, filterValues: [{filterType: Core.FilterTypeEnum.Equals, filterValue: this.topLevelCompanyId.toString() }]},
                    {filterProperty: Compliance.LongRunningProcessPropertyEnum.LongRunningProcessTypeName, filterConditionType: Core.FilterConditionTypeEnum.And, filterValues: [{filterType: Core.FilterTypeEnum.EndsWith, filterValue: 'Update CSR' }]},
                    {filterProperty: Compliance.LongRunningProcessPropertyEnum.End, filterConditionType: Core.FilterConditionTypeEnum.And, filterValues: [{filterType: Core.FilterTypeEnum.Blank, filterValue: '' }]},
                ],
                pagination: {
                    noPagination: true,
                },
                allUsers: true,
                notAcknowledged: true,
            }));

            this.isLongRunningProcessRunning = !!currentlyRunningUpdates.totalRows;
            this.hasEditPermission = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, this.entityInstanceId);
            this.hasComplianceFeatureView = this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, this.entityInstanceId);
            this.isExplicitView = !this._userInstanceService.isCrossInstancePerspective(this.entityInstanceId);
            this.isConsultantPerspective = this._userInstanceService.isCrossInstancePerspective(this.entityInstanceId);
            this.hasTaxFeeds = this._restrictService.hasInstanceRight(InstanceRights.ALLOWTAXFEEDS);
            this.canEditCompany = await this._restrictService.hasCompanyPermission(this.topLevelCompanyId, Core.AccessRightsEnum.Write);

            if (this.entityInstanceId !== RyanInstanceId) {
                const instanceInfo = await lastValueFrom(this._instanceRepository.get(this.entityInstanceId));

                if (instanceInfo && instanceInfo.documentIntakeAndProcessingAssignToRyan) {
                    const responses = await Promise.all([
                        lastValueFrom(this._documentIntakeLicensedClientRepository.get(this.entityInstanceId)),
                        this._userGroupService.getForMember(this._userService.getUser().id)
                    ]);
                    this._instanceLicensedDISettings = responses[0];
                    const userGroups = responses[1];

                    this.isLicensedDIUser = this._instanceLicensedDISettings
                        && this._instanceLicensedDISettings.ryanUserGroupId
                        && userGroups.some(x => x.groupID === this._instanceLicensedDISettings.ryanUserGroupId);
                }
            }

            if(!this.isExplicitView) {
                const ce = this.consultingEngagements.find(x => x.instanceId === this._userInstanceService.getSelectedInstance().instanceId);
                if (ce) {
                    this._consultingEngagementAccountHandlerTitle = this._formatPersonsName(ce.accountHandler.lastName, ce.accountHandler.firstName, ce.accountHandler.teamName);
                }
            }

            if (this.isConsultantPerspective) {
                const ce = this.consultingEngagements.find(x => this._userInstanceService.isInstanceExplicit(x.instanceId));

                if (ce) {
                    this.consultantsInstanceId = ce.instanceId;
                } else {
                    this.consultantsInstanceId = this.entityInstanceId;
                    this.isConsultantPerspective = false;
                }
            }

            this.panelData.entityClientServices = _.flow([
                map((service: EntityClientService) => {
                    service.responsibilities = _.flow([
                        map((resp: ClientServiceResponsibility) => {
                            const propType = _.find(this._propertyTypes, {propertyTypeID: resp.propertyTypeID});
                            resp.propTypeAbbr =  propType.propTypeAbbr;

                            resp.isException = this._getIsException(resp);
                            resp.customerHasChildExceptions = this._getHasChildExceptions(resp);
                            resp.isMyConsultingCompany = this._getIsMyConsultingCompany(resp);
                            resp.assigneeDisplay = this._getAssigneeDisplay(resp);
                            resp.translatedWorkflowUserTypeId = this._getTranslatedWorkflowUserTypeId(resp);
                            resp.disabledWorkflowUserTypes = this._getDisabledWorkflowUserTypes(resp, service);

                            return resp;
                        }),
                        sortBy('propertyTypeID')
                    ])(service.responsibilities);

                    return service;
                }),
                sortBy('sequence')
            ])(this.panelData.entityClientServices);
        }
        finally {
            this.serverAction = false;
        }
    }

    private _getIsException(responsibility: ClientServiceResponsibility): boolean {
        if (!this._userInstanceService.isInstanceImplicit(this.entityInstanceId)
            && responsibility.consultingEngagementId) {
            return responsibility.customerIsException;
        }

        return responsibility.isException;
    }

    private _getHasChildExceptions(responsibility: ClientServiceResponsibility): boolean {
        if (!this._userInstanceService.isInstanceImplicit(this.entityInstanceId)
            && responsibility.consultingEngagementId) {
            return responsibility.customerHasChildExceptions;
        }

        return responsibility.propertyTypeXServiceHasChildExceptions;
    }

    private _getIsMyConsultingCompany(responsibility: ClientServiceResponsibility): boolean {
        if (
            !responsibility.consultingEngagementId
            || this.consultingEngagements.length == 0
            || !this.isConsultantPerspective
        ) {
            return false;
        }

        const consultingEngagement = this.consultingEngagements.find(ce => ce.consultingEngagementId === responsibility.consultingEngagementId);
        return this._userInstanceService.isInstanceExplicit(consultingEngagement.instanceId);
    }

    private _getAssigneeDisplay(responsibility: ClientServiceResponsibility): string {
        const workflowUserTypeId = this._lookupWorkflowUserType(responsibility.workflowUserTypeID).value;

        //user is consultant and is looking at company they are consulting on
        if (this.isConsultantPerspective && workflowUserTypeId == Core.WorkflowUserTypes.AccountHandler) {
            return 'Client Account Handler';
        }

        //responsibility is part of a consulting engagement (consultant responsibility)
        if (responsibility.consultingEngagementId && this.consultingEngagements.length > 0) {
            //client perspective or assigned to different consulting company
            if (!this.isConsultantPerspective || !responsibility.isMyConsultingCompany) {
                //look up engagement
                const engagement = _.find(this.consultingEngagements, (ce) => ce.consultingEngagementId === responsibility.consultingEngagementId);

                if (engagement)
                {
                    return `Consultant- ${engagement.name}`;
                }

                return 'Loading...';
            }
        }

        return this._getResponsibilityLabel(responsibility, workflowUserTypeId);
    }

    private _getPlaceholderText(responsibility: ClientServiceResponsibility): string {
        const csrUser = this.csrAvailableUsers.find(u => u.userID === responsibility.userID);
        const ahUser = this.ahAvailableUsers.find(u => u.userID === responsibility.userID);
        const userType = this._lookupWorkflowUserType(responsibility.workflowUserTypeID);

        if(responsibility.assigneeDisplay
            && responsibility.userID
            && responsibility.teamID
            && userType.value != Core.WorkflowUserTypes.ConsultantAccountHandler
            && (!csrUser && (!ahUser || this.isConsultantPerspective))) {
            return `${responsibility.assigneeDisplay} INACTIVE`;
        } else {
            return null;
        }
    }

    private _lookupWorkflowUserType(workflowUserTypeId: number): {value: number; name: string;} {
        return this.workflowUserTypes.find( x => x.value == workflowUserTypeId); //lodash find may be faster
    }

    private _getResponsibilityLabel(responsibility: ClientServiceResponsibility, workflowUserTypeId: number): string {

        if (workflowUserTypeId == Core.WorkflowUserTypes.User) {
            if (!_.isEmpty(responsibility.assignee) && !_.isEmpty(responsibility.team)) {
                return this._formatPersonsName(responsibility.assignee.lastName, responsibility.assignee.firstName, responsibility.team.name);
            }
            //i'm unsure of what should happen if the responsibility is assigned to a user, but there is no assignee and team on the responsibility
        }
        else {
            return this._lookupWorkflowUserType(workflowUserTypeId).name;
        }
    }

    private _getTranslatedWorkflowUserTypeId(responsibility: ClientServiceResponsibility): number {
        if (this.isConsultantPerspective && responsibility.workflowUserTypeID === Core.WorkflowUserTypes.ConsultantAccountHandler) {
            return Core.WorkflowUserTypes.AccountHandler;
        }

        if (responsibility.consultingEngagementId && !this.isConsultantPerspective && this.isConsultingClient) {
            return WorkflowUserTypesUI.ConsultingEngagement;
        }

        return responsibility.workflowUserTypeID;
    }

    private _checkIfNASelected(service: EntityClientService, propertyTypeId: number): boolean {
        // find a certain service among all available client services and check, if this client service has a certain user type selected
        // use propertyTypeID for currently edited responsibility as an additional filter
        //
        return _.some(service?.responsibilities, {
            propertyTypeID: propertyTypeId,
            workflowUserTypeID: Core.WorkflowUserTypes.NA
        });
    }

    private _checkServiceHasUserSelected(clientServiceId: number, propertyTypeId: number): boolean {
        const service = _.find(this.panelData.entityClientServices, {clientServiceID: clientServiceId});
        return !!service && !this._checkIfNASelected(service, propertyTypeId);
    }

    private _getDisabledWorkflowUserTypes(clientServiceResponsibility, service: EntityClientService): ClientServicePanelWorkflowUserTypes[] {
        let rslt=[];
        switch(service.clientServiceID){
            case ClientServiceType.ComplianceReturns:
                rslt = this._getComplianceReturnsDisabledWorkflowUserTypes(clientServiceResponsibility.propertyTypeID);
                break;
            case ClientServiceType.Assessments:
                rslt = this._getAssessmentsDisabledWorkflowUserTypes(clientServiceResponsibility.propertyTypeID);
                break;
            case ClientServiceType.TaxBills:
                rslt = this._getTaxBillsDisabledWorkflowUserTypes(clientServiceResponsibility.propertyTypeID);
                break;
            case ClientServiceType.TaxBillTransmit:
                rslt = this._getTaxBillTransmitDisabledWorkflowUserTypes(clientServiceResponsibility.propertyTypeID);
                break;
            case ClientServiceType.Appeals:
                rslt = this._getAppealsDisabledWorkflowUserTypes(clientServiceResponsibility.propertyTypeID);
                break;
            case ClientServiceType.Allocation:
                rslt = this._getAllocationDisabledWorkflowUserTypes(clientServiceResponsibility.propertyTypeID);
                break;
        }

        if (clientServiceResponsibility?.clientServiceResponsibilityUpdateStatus == ClientServiceResponsibilityUpdateQueueStatusEnum.Errant) {
            rslt = rslt.concat([Core.WorkflowUserTypes.AccountHandler,
                Core.WorkflowUserTypes.ConsultantAccountHandler,
                WorkflowUserTypesUI.ConsultingEngagement,
                Core.WorkflowUserTypes.JurisdictionSpecialist,
                Core.WorkflowUserTypes.User]);
        }

        if (this.isConsultantPerspective) {
            rslt = rslt.concat([Core.WorkflowUserTypes.NA]);
        }

        return rslt;
    }

    private _getComplianceReturnsDisabledWorkflowUserTypes(propertyTypeId: number): ClientServicePanelWorkflowUserTypes[] {
        const hasAllocationUser = this._checkServiceHasUserSelected(ClientServiceType.Allocation, propertyTypeId);

        return hasAllocationUser ? [Core.WorkflowUserTypes.NA] : [];
    }

    private _getTaxBillTransmitDisabledWorkflowUserTypes(propertyTypeId: number): ClientServicePanelWorkflowUserTypes[] {
        const hasTaxBillUser = this._checkServiceHasUserSelected(ClientServiceType.TaxBills, propertyTypeId);

        return !hasTaxBillUser
            ?  [
                    Core.WorkflowUserTypes.AccountHandler,
                    Core.WorkflowUserTypes.JurisdictionSpecialist,
                    Core.WorkflowUserTypes.User,
                    WorkflowUserTypesUI.ConsultingEngagement
                ]
            : [];

    }

    private _getTaxBillsDisabledWorkflowUserTypes(propertyTypeId: number) : ClientServicePanelWorkflowUserTypes[] {
        const hasTaxBillTransmitUser = this._checkServiceHasUserSelected(ClientServiceType.TaxBillTransmit, propertyTypeId);
        const hasAssessmentsUser = this._checkServiceHasUserSelected(ClientServiceType.Assessments, propertyTypeId);

        if(!hasAssessmentsUser) {
            return [
                Core.WorkflowUserTypes.AccountHandler,
                Core.WorkflowUserTypes.JurisdictionSpecialist,
                Core.WorkflowUserTypes.User,
                WorkflowUserTypesUI.ConsultingEngagement
            ];
        }

        //an assessments workflow user must be set to be able to have a tax bill transmit user set
        if(hasAssessmentsUser && hasTaxBillTransmitUser) {
            return [Core.WorkflowUserTypes.NA];
        }

        return [];
    }

    private _getAllocationDisabledWorkflowUserTypes(propertyTypeId: number): ClientServicePanelWorkflowUserTypes[] {
        const hasComplianceUser = this._checkServiceHasUserSelected(ClientServiceType.ComplianceReturns, propertyTypeId);
        const hasAssessmentsUser = this._checkServiceHasUserSelected(ClientServiceType.Assessments, propertyTypeId);

        const canAssignPPWorkflowUser = propertyTypeId == PropertyType.Personal
            && hasComplianceUser
            && hasAssessmentsUser;

        return (propertyTypeId != PropertyType.Personal || !canAssignPPWorkflowUser)
            ? [
                    Core.WorkflowUserTypes.AccountHandler,
                    Core.WorkflowUserTypes.JurisdictionSpecialist,
                    Core.WorkflowUserTypes.User,
                    WorkflowUserTypesUI.ConsultingEngagement,
                    Core.WorkflowUserTypes.ConsultantAccountHandler
                ]
            : [];
    }

    private _getAssessmentsDisabledWorkflowUserTypes(propertyTypeId: number): ClientServicePanelWorkflowUserTypes[] {
        const hasAppealsUser = this._checkServiceHasUserSelected(ClientServiceType.Appeals, propertyTypeId);
        const hasTaxBillsUser = this._checkServiceHasUserSelected(ClientServiceType.TaxBills, propertyTypeId);
        const hasAllocationsUser = this._checkServiceHasUserSelected(ClientServiceType.Allocation, propertyTypeId);

        return (hasAppealsUser || hasTaxBillsUser || hasAllocationsUser)
            ? [Core.WorkflowUserTypes.NA]
            : [];
    }

    private _getAppealsDisabledWorkflowUserTypes(propertyTypeId: number): ClientServicePanelWorkflowUserTypes[] {
        const hasAssessmentsUser = this._checkServiceHasUserSelected(ClientServiceType.Assessments, propertyTypeId);

        return !hasAssessmentsUser
                ? [
                        Core.WorkflowUserTypes.AccountHandler,
                        Core.WorkflowUserTypes.JurisdictionSpecialist,
                        Core.WorkflowUserTypes.User,
                        WorkflowUserTypesUI.ConsultingEngagement
                    ]
                : [];
    }

    private _extractDeliveryDetails(entityClientServices: EntityClientService[]): any[] {
        return _.flow([
            flatMap(x => [
                x.taxBillDeliveryDetailsCurrent,
                x.deliveryDetailAssessmentCurrent,
                x.deliveryDetailBillCurrent,
                x.deliveryDetailPPReturnBatchCurrent,
                x.deliveryDetailAssessmentAllocationTop
            ]),
            compact,
            reject(x => _.isEmpty(x))
        ])(entityClientServices);
    }

    private _isFeedSpecificationsUpdated({ feedSpecifications: originalFeedSpecs }, {feedSpecifications: editedFeedSpecs}): boolean {
        if(originalFeedSpecs && editedFeedSpecs) {
            if (originalFeedSpecs.length !== editedFeedSpecs.length) {
                return true;
            } else {
                return _.some(originalFeedSpecs, (x, i) =>  (
                     x.feedSpecificationID !== editedFeedSpecs[i].feedSpecificationID
                        || x.includedInEmail !== editedFeedSpecs[i].includedInEmail
                ));
            }
        } else {
            return true;
        }
    }

    private _isUserEmpty(prefix: string, userObj: Core.ContactShortDTO): boolean {
        if (userObj[`${prefix  }UserId`] && userObj[`${prefix  }UserId`] != EmptyGuid)
            return false;

        return true;
    }

    private _getOriginalDeliveryDetail(originalDeliveryDetails, editedDeliveryDetail): any {
        if (editedDeliveryDetail.taxBillDeliveryDetailID !== undefined) {
            return _.find(originalDeliveryDetails, {
                taxBillDeliveryDetailID: editedDeliveryDetail.taxBillDeliveryDetailID
            });
        }
        if (editedDeliveryDetail.deliveryDetailAssessmentID !== undefined) {
            return _.find(originalDeliveryDetails, {
                deliveryDetailAssessmentID: editedDeliveryDetail.deliveryDetailAssessmentID
            });
        }
        if (editedDeliveryDetail.deliveryDetailBillID !== undefined) {

            // PROBLEM - IS THIS HE WAY IT SHOULD WORK???
            // TODO
            // WK-6869
            const result = _.find(originalDeliveryDetails, item => {
                return item.deliveryDetailBillID !== undefined;
            });

            // this is how this item was found before, hovwever, somewhere else deliveryDetailBillID is replaced (by parent?)
            //{ deliveryDetailBillID: editedDeliveryDetail.deliveryDetailBillID });

            return result;
        }

        if (this._isComplianceReturns(editedDeliveryDetail)) {
            return _.find(originalDeliveryDetails, x => this._isComplianceReturns(x));
        }

        if (this._isAllocations(editedDeliveryDetail)) {
            return _.find(originalDeliveryDetails, x => this._isAllocations(x));
        }
    }

    private _isComplianceReturns(clientService: any): boolean {
        if(clientService.deliveryDetails_PPBatchReturnsId) {
            return true;
        }

        const keys = _.keys(clientService);
        const prefixKey = _.find(keys, key => key !== 'efAction');

        return _.startsWith(prefixKey, this.COMPLIANCE_PREFIX);
    }

    private _isAllocations(clientService: any): boolean {
        if(_.has(clientService, 'drTeamRetrievesWP')) {
            return true;
        }

        const keys = _.keys(clientService);
        const prefixKey = _.find(keys, key => key !== 'efAction');

        return _.startsWith(prefixKey, this.ALLOCATIONS_PREFIX);
    }

    private _broadcastRowVersionChange(newRowVersion) {
        this._rootScope.$broadcast('WS.COMPANY.ROWVERSION.CHANGED', newRowVersion);
    }

    private _formatPersonsName(lastName: string, firstName: string, teamName: string){
        return `${lastName}, ${firstName}  (${teamName})`;
    }
}

