import { OnInit, Input, Component, EventEmitter, Output } from '@angular/core';
import { GridApi, GridOptions, GridReadyEvent, ColDef, ValueGetterParams } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams, AgGridDropdownCellEditor } from '../../../Compliance/AgGrid';
import { DropdownCellEditorParams } from '../../../Compliance/AgGrid/CellEditors/agGridDropdownCellEditor.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { UserInstanceService } from '../../../User/userInstance.service';
import { PermissionService } from '../../permission.service';
import { CompanySitePermissionsComponent, CompanySitePermissionsParams } from '../Site/companySitePermissions.component';
import { CompanyPermissionAddComponent, CompanyPermissionAddParams } from '../Add/companyPermissionAdd.component';
import { CompanyPermissionAgGridDataSource, CompanyPermissionDataSourceParams } from './agGridDataSource';
import { CompanyPermissionOptions } from './CompanyPermissionOptions';
import { CompanyPermissionsGridActionCellRendererComponent, ICellRendererParamsForCompanyPermissionsGridAction } from './agGridActionCellRenderer.component';
import * as _ from 'lodash';
import { NavigationService } from '../../../Layout/navigation.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { USER_GROUP_DETAIL_HELP } from '../../../User-Group/User-Group-Detail/userGroupDetail.component.help';
import { UserGroupsService } from '../../../User-Group/userGroup.service';
import { UserGroupPanel } from 'src/app/User-Group/User-Group-Detail/userGroupDetail.component';

@Component({
    templateUrl: './companyPermissionPanel.component.html',
    selector: 'company-permission-panel',
    styles: [`
        :host {
            display: flex;
            flex-flow: column;
            height: 100%;
            width: 100%;
        }
    `]
})
export class CompanyPermissionPanelComponent implements OnInit {
    constructor(
        private readonly _userInstanceService: UserInstanceService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _permissionService: PermissionService,
        private readonly _modalService: WeissmanModalService,
        private readonly _helpService: HelpService,
        private readonly _userGroupsService: UserGroupsService,
        private readonly _navigationService: NavigationService) { }

    @Input() userGroup: Core.GroupModel;
    @Input() canEdit: boolean;
    @Output() expandClicked: EventEmitter<UserGroupPanel> = new EventEmitter<UserGroupPanel>();

    editMode: boolean = false;
    isExpanded: boolean = false;
    gridIsDirty: boolean = false;

    saveRequest: Core.PermissionSaveRequest = {
        adds: [],
        deletes: [],
        updates: []
    };
    addedCompanies: Core.PermissionModel[] = [];

    currentInstance: Core.InstanceViewModel;
    agGridReady: boolean;
    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            rowClassRules: {
                'grid-row-deleted': params => params.data && _.some(this.saveRequest.deletes, {permissionID: params.data.permissionID})
            },
            singleClickEdit: true,
            suppressHorizontalScroll: true
        })
        .withColumnPinning()
        .buildDefault(this);

    private _gridApi: GridApi;
    private _gridDataSource: CompanyPermissionAgGridDataSource;


    ngOnInit(): void {
        this.currentInstance = this._userInstanceService.getSelectedInstance();
        this._helpService.setContent(USER_GROUP_DETAIL_HELP);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Company',
                field: 'entityName',
                lockVisible: true,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth
            },
            {
                headerName: 'Top Level Company',
                field: 'topLevelCompany',
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParamsStartsWith,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                width: AgGridColumns.textColumnMedWidth
            },
            {
                headerName: 'Access Level',
                field: 'rightsDropdown',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                editable: (params) => {
                    const permissionRow = params.data as Core.PermissionModel;
                    if (!permissionRow) {
                        return false;
                    }

                    return this.canEdit && this.editMode && !_.some(this.saveRequest.deletes, { permissionID: permissionRow.permissionID });
                },
                cellEditorFramework: AgGridDropdownCellEditor,
                cellEditorParams: {
                    getOptions: () => [
                        { name: CompanyPermissionOptions.ViewAllSites, value: CompanyPermissionOptions.ViewAllSites },
                        { name: CompanyPermissionOptions.ViewEditSomeSites, value: CompanyPermissionOptions.ViewEditSomeSites },
                        { name: CompanyPermissionOptions.EditAllSites, value: CompanyPermissionOptions.EditAllSites }
                    ],
                    cellChanged: (params: DropdownCellEditorParams, newValue: string) => {
                        const permission = params.data as Core.PermissionModel;
                        this._setPermissionFlags(permission, newValue);

                        const alreadyUpdatedIdx = _.findIndex(this.saveRequest.updates, {permissionID: permission.permissionID});

                        if(alreadyUpdatedIdx >= 0) {
                            this.saveRequest.updates[alreadyUpdatedIdx] = permission;
                        } else {
                            this.saveRequest.updates.push(permission);
                        }

                        this.gridIsDirty = true;
                    }
                },
                valueGetter: this._getDropownPermission.bind(this)
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: CompanyPermissionsGridActionCellRendererComponent,
                cellRendererParams: {
                    editMode: params => this.canEdit && this.editMode,
                    canLaunchSitesModal: (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permissionOption = this._getDropownPermission(params);
                        return permissionOption === CompanyPermissionOptions.ViewEditSomeSites;
                    },
                    launchSitesModal: async (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permission = params.data as Core.PermissionModel;

                        const modalParams: CompanySitePermissionsParams = {
                            companyId: permission.entityID,
                            groupId: permission.groupID,
                            editMode: this.editMode,
                            saveRequest: _.cloneDeep(this.saveRequest)
                        };

                        const result = await this._modalService.showAsync(CompanySitePermissionsComponent, modalParams, 'modal-lg');

                        if(result) {
                            this.saveRequest = result;
                            this.gridIsDirty = true;
                        }
                    },
                    canRemove: params => {
                        const permissionRow = params.data as Core.PermissionModel;
                        if (!permissionRow) {
                            return false;
                        }
                        return this.canEdit && this.editMode && !_.some(this.saveRequest.deletes, {permissionID: permissionRow.permissionID});
                    },
                    remove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permissionRow = params.data as Core.PermissionModel;

                        if(params.node.rowPinned) {
                            this.addedCompanies = _.reject(this.addedCompanies, {entityID: permissionRow.entityID});
                        } else {
                            this.saveRequest.deletes.push(permissionRow);
                            this._gridApi.redrawRows();
                        }

                        this.gridIsDirty = true;
                    },
                    canUndoRemove: params => {
                        const permissionRow = params.data as Core.PermissionModel;
                        if (!permissionRow) {
                            return false;
                        }

                        return this.editMode && _.some(this.saveRequest.deletes, {permissionID: permissionRow.permissionID});
                    },
                    undoRemove: (params: ICellRendererParamsForCompanyPermissionsGridAction) => {
                        const permissionRow = params.data as Core.PermissionModel;
                        this.saveRequest.deletes = _.reject(this.saveRequest.deletes, {permissionID: permissionRow.permissionID});

                        this._gridApi.redrawRows();
                    },
                } as ICellRendererParamsForCompanyPermissionsGridAction
            }
        ];

        const defaultSortModel = [
            {
                colId: 'entityName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.setSortModel(defaultSortModel);
        this._setDataSource();
    }

    async addNewCompanyPermission() {
        // Add new permission modal
        const dataSourceParams: CompanyPermissionAddParams = {
                instanceId: this.currentInstance.instanceId,
                groupId: this.userGroup.groupID,
                excludeCompanyIds: _.map(this.addedCompanies, 'entityID')
            };

        const result = await this._modalService.showAsync(CompanyPermissionAddComponent, dataSourceParams, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        result.selectedRows = _.map(result.selectedRows, x => {
            this._setPermissionFlags(x, result.permission);
            return x;
        });

        this.addedCompanies = [...this.addedCompanies, ...result.selectedRows];

        this.gridIsDirty = true;
    }

    edit() {
        this.editMode = true;
        this._userGroupsService.setPanelEdit(this.editMode);
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    cancel() {
        this._resetPanel();
    }

    refresh(): void {
        this._refreshDataSource();
    }

    expandCollapse(expand: boolean) {
        this.expandClicked.emit('companyPermission');
        setTimeout(() => {
            this._gridApi.sizeColumnsToFit();
        });
        this.isExpanded = expand;
    }

    async save() {
        const busyRef = this._busyIndicatorService.show({ message: 'Updating Company Permissions' });

        try {
            this.saveRequest.adds = [...this.saveRequest.adds, ...this.addedCompanies];
            await this._permissionService.updateEntityPermissions(this.saveRequest);

            this._resetPanel();
        } catch (e2) {
            if (e2 && e2.status !== 422) {
                return Promise.reject(e2);
            }
        }
        finally {
            await busyRef.hide();
        }
    }

    private _getDropownPermission(params: ValueGetterParams): string {
        const dbPermission = params.data as Core.PermissionModel;

        if (!dbPermission) {
            return '';
        }

        const p = _.find(this.saveRequest.updates, {permissionID: dbPermission.permissionID}) || dbPermission;

        if (p.writeAllowed) {
            if (p.includeAllChildRecords) {
                return CompanyPermissionOptions.EditAllSites;
            } else {
                return CompanyPermissionOptions.ViewEditSomeSites;
            }
        }

        if (p.readAllowed) {
            if (p.includeAllChildRecords) {
                return CompanyPermissionOptions.ViewAllSites;
            } else {
                return CompanyPermissionOptions.ViewEditSomeSites;
            }
        }
    }

    private _setPermissionFlags(permission: Core.PermissionModel, rightsString: string): void {
        permission.readAllowed = true;
        permission.writeAllowed = rightsString === CompanyPermissionOptions.EditAllSites;
        permission.includeAllChildRecords = rightsString === CompanyPermissionOptions.ViewAllSites || rightsString === CompanyPermissionOptions.EditAllSites;
    }

    private _resetPanel() {
        this.editMode = false;
        this.saveRequest.updates = [];
        this.saveRequest.deletes = [];
        this.saveRequest.adds = [];
        this.addedCompanies = [];
        this._userGroupsService.setPanelEdit(this.editMode);
        this.gridIsDirty = false;
        this._navigationService.disableNavWarning();

        this.refresh();
    }

    private _refreshDataSource() {
        if (!this._gridDataSource) {
            const success = this._setDataSource();
            if (!success) {
                return;
            }
        }

        this._gridDataSource.refresh();
    }

    private _setDataSource(): boolean {
        if (!this._gridApi || this._gridDataSource) {
            return;
        }

        const dataSourceParams = (): CompanyPermissionDataSourceParams => {
            return {
                instanceId: this.currentInstance.instanceId,
                groupId: this.userGroup.groupID
            };
        };

        this._gridDataSource = new CompanyPermissionAgGridDataSource(this._gridApi, this._permissionService, dataSourceParams);
        this._gridApi.setDatasource(this._gridDataSource);
        return true;
    }
}
