import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { TaxAuthorityStatuses } from '../constants.new';
import { TaxAuthority, TaxAuthorityDetail } from '../Assessor-Collector/Tax-Rates/tax.rates.panel.model';

export interface ICellRendererParamsForTaxRateQcBy extends ICellRendererParams {
    rate: (params, year: number) => string;
    year: number;
}

@Component({
    selector: 'grid-qc-by-cell',
    template:
        ' <span [popover]="qcBy" triggers="mouseenter:mouseleave" placement="auto" container="body">{{rate}}</span> '
})
export class TaxRateQcByCellRendererComponent implements ICellRendererAngularComp {
    rate: string;
    qcBy: string = '';

    agInit(params: ICellRendererParamsForTaxRateQcBy): void {
        this.rate = params.rate(params, params.year);

        if(!params.data) {
            return;
        }

        const taxAuthority: TaxAuthority = params.data;
        const yearObj: TaxAuthorityDetail = _.find(taxAuthority.details as TaxAuthorityDetail[], {taxYear: params.year});


        if (yearObj && yearObj.taxAuthorityStatusId === TaxAuthorityStatuses.Pending) {
            if (yearObj.qcRequestTimeUtc) {
                this.qcBy = `QC requested at ${  moment(yearObj.qcRequestTimeUtc).utc().format('M/D/Y')}`;
                if (yearObj.qcRequestUserFullName) {
                    this.qcBy += ` by ${  yearObj.qcRequestUserFullName}`;
                }
            }
        }
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}