import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DatabaseRepository } from '../database.repository';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import { RestrictService, Roles } from '../../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import {
    DatabaseProcessListGridActionCellRendererComponent,
    DatabaseProcessListGridActionCellRendererParams
} from './agGridActionCellRenderer.component';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import {
    MessageBoxButtons,
    MessageBoxOptions,
    MessageBoxResult, MessageBoxService
} from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../Compliance/AgGrid/MultiSelectTracker';
import { DATABASE_PROCESS_LIST_HELP } from './processList.component.help';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { DatabaseClearCacheEntryComponent } from '../Database-Clear-Cache-Entry/databaseClearCacheEntry.component';

@Component({
    selector: 'database-process-list',
    templateUrl: './processList.component.html',
    styleUrls: ['./processList.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProcessListComponent implements OnInit, OnDestroy {
    constructor(
        private readonly _databaseRepository: DatabaseRepository,
        private readonly _restrictService: RestrictService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _busyService: BusyIndicatorService,
        private readonly _helpService: HelpService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _weissmanModalService: WeissmanModalService
    ) {
    }

    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = 'F3B74B92-6EEC-43F0-962D-D15BED9BFEAB';
    isInitialized: boolean = false;
    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as Core.DatabaseProcessModel).sessionId)
        }
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withSort()
        .withTextSelection()
        .build();
    agGridReady: boolean;
    loadingData: boolean;
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private _gridApi: GridApi;
    private _sourceData: Core.DatabaseProcessModel[];
    private _selectedRowsSub: Subscription;
    private _isSelectingRangeSub: Subscription;
    private _selectedRows: number[] = [];


    async ngOnInit(): Promise<void> {
        this._helpService.setContent(DATABASE_PROCESS_LIST_HELP);

        if (!(this._restrictService.isInRole(Roles.ALLOWMANAGESYSTEM))) {
            this._navigateToUnauthorized();
            return;
        }

        await this._loadData();

        this.isInitialized = true;
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
        this._isSelectingRangeSub && this._isSelectingRangeSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe((selected: Compliance.SelectedRowsModel) => {
            this._selectedRows = (selected.selectAllRows) ? this._sourceData.map(x => x.sessionId) : selected.selectedRows;
            this.isBulkDeleteVisible$.next(this._selectedRows.length !== 0);
        });

        this._isSelectingRangeSub = this.gridTracker.isSelectingRange$.subscribe(loading => {
            if (!loading) {
                this.isBulkDeleteVisible$.next(this.gridTracker.hasSelectedRows());
            } else {
                this.isBulkDeleteVisible$.next(false);
            }
        });

        const columns: ColDef[] = [
            {
                field: 'sessionId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
            },
            {
                headerName: 'Row Number',
                field: 'rowNumber',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Blocking Row Number',
                field: 'blockingRowNumber',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Session ID',
                field: 'sessionId',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Status',
                field: 'status',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Blocking Session ID',
                field: 'blockingSessionId',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Elapsed Time (ms)',
                field: 'totalElapsedTime',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams
            },
            {
                headerName: 'Query Text',
                field: 'queryText',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Wait Type',
                field: 'waitType',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Wait Resource',
                field: 'waitResource',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'CPU Time',
                field: 'cpuTime',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Row Count',
                field: 'rowCount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Logical Reads',
                field: 'logicalReads',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Reads',
                field: 'reads',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Writes',
                field: 'writes',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'User ID',
                field: 'userId',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Database',
                field: 'database',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Schema',
                field: 'schema',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Procedure',
                field: 'procedure',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Command',
                field: 'command',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Wait Time',
                field: 'waitTime',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Last Wait Type',
                field: 'lastWaitType',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Isolation',
                field: 'transactionIsolationLevel',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(1),
                minWidth: AgGridColumns.getActionColumnWidth(1),
                maxWidth: AgGridColumns.getActionColumnWidth(1),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: DatabaseProcessListGridActionCellRendererComponent,
                cellRendererParams: {
                    killProcess: this._killProcess.bind(this)
                } as DatabaseProcessListGridActionCellRendererParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'rowNumber',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async refresh(): Promise<void> {
        await this._loadData();
    }

    async bulkKill(): Promise<void> {
        await this._killProcesses(this._selectedRows);
    }

    async clearCacheEntry(): Promise<void> {
        await this._weissmanModalService.showAsync(DatabaseClearCacheEntryComponent, null, 'modal-lg');
    }

    async clearCache(): Promise<void> {
        const confirmOptions = {
            message: 'Are you sure you wish to clear cache?',
            buttons: MessageBoxButtons.OKCancel
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.OK) {
            const busyRef = this._busyService.show({message: 'Clearing cache'});

            try {
                await lastValueFrom(this._databaseRepository.clearCache());
            } finally {
                await busyRef.hide();
            }
        }
    }

    private async _loadData(): Promise<void> {
        const busyRef = this._busyService.show({ message: 'Loading' });

        try {
            this._sourceData = await lastValueFrom(this._databaseRepository.getRunningProcesses());
            this._setRowData();
        } finally {
            await busyRef.hide();
        }
    }
    private _setRowData(): void {
        if (!(this._gridApi)) {
            return;
        }

        this.gridTracker.clear(false);

        this.isBulkDeleteVisible$.next(false);

        this._gridApi.setRowData(this._sourceData);
    }

    private _navigateToUnauthorized(): void {
        this._routerService.go('unauthorizedAccess', {});
    }

    private async _killProcess(params: DatabaseProcessListGridActionCellRendererParams): Promise<void> {
        const process = params.data as Core.DatabaseProcessModel;

        await this._killProcesses([process.sessionId]);
    }

    private async _killProcesses(sessionIds: number[]): Promise<void> {
        let confirmationMessage = 'Are you sure you wish to kill ';

        if (sessionIds.length === 1) {
            confirmationMessage += `process ${sessionIds[0]}`;
        } else {
            confirmationMessage += `processes ${sessionIds.join(', ')}`;
        }

        const confirmOptions = {
            message: `${confirmationMessage}?`,
            buttons: MessageBoxButtons.OKCancel
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.OK) {
            const progressMessage = `Killing the process${sessionIds.length !== 1 ? 'es' : ''}`;
            const busyRef = this._busyService.show({message: progressMessage});

            let success: boolean = false;

            try {
                await lastValueFrom(this._databaseRepository.killProcesses(sessionIds));
                success = true;
            } finally {
                await busyRef.hide();
            }

            if (success) {
                await this._loadData();
            }
        }
    }
    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const process = x.data as Core.DatabaseProcessModel;
                return process && process.sessionId;
            })
        };

        return Promise.resolve(queryResultModel);
    }
}
