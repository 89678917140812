<ws-modal-wrapper [headerLabel]="headerLabel"
    [subHeaderLabel]="subHeaderLabel"
    [isHeaderLink]="isBelowParcelLevel"
    (headerClick)="loadParentAttachments()"
    (cancel)="close()"
    [hideFooter]="true"
    [useAceStyles]="true">
    <ws-busy-indicator *ngIf="loading"></ws-busy-indicator>

    <div class="row">
        <div [ngClass]="{'col-5': !viewFlags.isViewerFullWidth, 'attachment-list-collapsed': viewFlags.isViewerFullWidth}" [hidden]="viewFlags.isListHidden"  style="transition: width 0.3s">
            <div *ngIf="!editMode" style="display: flex; flex-direction: column; gap: 8px;">
                <div style="display: flex; align-items: center; gap: 8px;">
                    <label>Show:</label>
                    <ws-select class="show-control"
                        [(ngModel)]="yearFilter"
                        [options]="filteredYears"
                        labelProperty="name"
                        valueProperty="value"
                        [disabled]="isBelowParcelLevel || params.disableYears"
                        (selectedOptionChanged)="selectFirstAttachment()">
                    </ws-select>
                    <ws-select class="show-control"
                        [(ngModel)]="categoryFilterId"
                        [options]="filteredCategories"
                        labelProperty="category"
                        valueProperty="attachmentCategoryID"
                        (selectedOptionChanged)="selectFirstAttachment()"
                        [disabled]="params.disableCategories">
                    </ws-select>
                    <div class="checkbox" *ngIf="childLabel && !params.showEmailAttachmentButton">
                        <label>
                            <input type="checkbox" [(ngModel)]="includeChildren" (change)="getAllAttachments()"> Include {{childLabel}}
                        </label>
                    </div>
                </div>
                <div class="list-group" [class.list-border]="filteredAttachments.length" style="overflow-y: auto; height: 70vh;">
					<a class="list-group-item list-group-item-action"
					   *ngFor="let attachment of filteredAttachments"
					   (click)="selectAttachment(attachment)"
					   [class.active]="attachment.active">
						<div>
							<div *ngIf="includeSubEntities">
								<strong>{{attachment.lineOne}}</strong>
							</div>
							<div>
								{{attachment.lineTwo}}
							</div>
							<div>
								{{attachment.lineThree}}
							</div>
						</div>
					</a>
				</div>
                <div *ngIf="!viewFlags.isListHidden && !editMode" class="ace-button-container ws-button-row">
					<button class="ace-btn btn-sm btn-primary"
                        *ngIf="allowEntityEdit"
                        (click)="addAttachment()">
                            Add Attachment
                    </button>
					<button class="ace-btn btn-sm btn-primary"
                        *ngIf="allowEntityEdit && selectedAttachment"
                        (click)="editAttachment()">
                            Edit Attachment
                    </button>
					<button class="ace-btn btn-sm btn-primary"
                        *ngIf="selectedAttachment"
                        (click)="downloadAttachment()">
                            Download Attachment
                    </button>
                    <button class="ace-btn btn-sm btn-primary"
                            *ngIf="selectedAttachment && params.showEmailAttachmentButton"
                            (click)="emailAttachment()">
                        Email Attachment
                    </button>
				</div>
            </div>
            <attachment-form *ngIf="editMode"
                        [attachment]="selectedAttachment"
                        [currentEntity]="currentEntityForForm"
                        [disableYears]="params.disableYears"
                        [defaultYear]="yearFilter"
                        [disableAttachmentType]="params.disableCategories"
                        [attachmentTypeId]="params.attachmentTypeId"
                        (attachmentFormUpdated)="attachmentFormUpdated($event)"
                        (attachmentFormCreated)="attachmentFormCreated($event)"
                        (attachmentFormDeleted)="attachmentFormDeleted()"
                        (attachmentFormCancelled)="attachmentFormCancelled()">
            </attachment-form>
        </div>
        <div [class.invisible]="!selectedAttachment" [ngClass]="{'col-7': !viewFlags.isViewerFullWidth, 'col-12': viewFlags.isViewerFullWidth}" style="transition: width 0.3s" class="attachment-viewer position-relative">
            <div *ngIf="file" (mouseenter)="hovered = true" (mouseleave)="hovered = false">
                <div class="preview-nav" [hidden]="!hovered" *ngIf="viewFlags.isViewerFullWidth && filteredAttachments.length > 0 && selectedAttachment">
                    <i class="fa fa-arrow-left fa-4x" (click)="getPrev()" [hidden]="isFirstAttachment"></i>
                    <i class="fa fa-arrow-right fa-4x" (click)="getNext()" [hidden]="isLastAttachment"></i>
                </div>
                <div class="preview-window">
                    <file-viewer *ngIf="file" [file]="file"></file-viewer>
                </div>
            </div>
            <div *ngIf="!file" style="height: 90%; display: flex; justify-content: center; align-items: center;">
                <a style="cursor: pointer" (click)="downloadAttachment()">Click to download or view in native application</a>
            </div>
			<div *ngIf="selectedAttachment" style="display: flex; justify-content: space-between; margin-left: 54px; margin-top: 5px;">
				<div style="font-size: 12px;" *ngIf="selectedAttachment.createdByLastName">
					Added by: {{selectedAttachment.createdByLastName}}, {{selectedAttachment.createdByFirstName}} <abbr title="{{selectedAttachment.createdDate | date: 'M/d/YYYY h:mm a' : 'america/Chicago' }} CT">{{displayDate(selectedAttachment.createdDate)}}</abbr>
				</div>
				<div style="font-size: 24px;">{{selectedAttachment?.lineTwo || 'Preview Window'}}</div>
				<div class="invisible">Added by: {{selectedAttachment.createdByLastName}}, {{selectedAttachment.createdByFirstName}} <abbr>{{displayDate(selectedAttachment.createdDate)}}</abbr></div>
			</div>
			<button class="ace-btn btn-secondary btn-sm toggle-list"
                (click)="toggleList()"
                *ngIf="!editMode"
                helpTooltip
                [helpContentId]="viewFlags.isListHidden ? 'attachment-modal.show-list' : 'attachment-modal.hide-list'">
                    <i class="fa fa-list" *ngIf="viewFlags.isListHidden"></i>
                    <i class="fa fa-arrow-left" [hidden]="viewFlags.isListHidden"></i>
			</button>
			<!-- Pop-Out Attachment -->
			<div class="btn-group float-end ace-button-container" [dropup]="true" dropdown *ngIf="selectedAttachment && file">
				<button type="button" class="ace-btn btn-sm btn-secondary dropdown-toggle" dropdownToggle>
					Pop-out
					<span class="caret"></span>
				</button>
				<ul *dropdownMenu class="dropdown-menu" role="menu" >
					<li><a class="dropdown-item" style="cursor: pointer;" (click)="popOutAttachment(true)">Dynamic</a></li>
					<li><a class="dropdown-item" style="cursor: pointer;" (click)="popOutAttachment(false)">Static</a></li>
				</ul>
			</div>
		</div>
    </div>

</ws-modal-wrapper>
