import { IGetRowsParams, GridApi } from 'ag-grid-community';
import { AgGridDataSourceBase, AgGridDataSourceResult } from '../../../Compliance/AgGrid';
import { PermissionService } from '../../permission.service';

export interface CompanySitePermissionsDataSourceParams {
    companyId: number;
    groupId?: number;
    userId?: System.Guid;
}

export class CompanySitePermissionsAgGridDataSource extends AgGridDataSourceBase<CompanySitePermissionsDataSourceParams> {
    constructor(
        private _gridApi: GridApi,
        private _permissionService: PermissionService,
        protected _dataSourceParamsFn: () => CompanySitePermissionsDataSourceParams
    ) {
        super(_gridApi);
    }

    private readonly _propertyMap: Compliance.NameValuePair<Compliance.PermissionModelPropertyEnum>[] = [
        { name: 'siteStateAbbr', value: Compliance.PermissionModelPropertyEnum.SiteStateAbbr },
        { name: 'siteProperty', value: Compliance.PermissionModelPropertyEnum.SiteProperty },
        { name: 'entityName', value: Compliance.PermissionModelPropertyEnum.EntityName },
        { name: 'rightsDropdown', value: Compliance.PermissionModelPropertyEnum.Rights }
    ];

    async getRowIdsInternal(startIndex: number, endIndex: number): Promise<Compliance.QueryResultModel<number>> {
        const searchParams: Core.PermissionSearchModel = {
            pagination: {
                skip: startIndex,
                take: endIndex - startIndex + 1
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            groupId: this._dataSourceParams.groupId,
            userId: this._dataSourceParams.userId,
            excludeInactive: false
        };

        return await this._permissionService.getCompanySiteRowIds(this._dataSourceParams.companyId, searchParams);
    }

    destroy?(): void { return null; }

    protected canGetRows(): boolean {
        return !!this._dataSourceParams.groupId || !!this._dataSourceParams.userId;
    }

    protected async getRowsInternal(params: IGetRowsParams): Promise<AgGridDataSourceResult> {
        const searchParams: Core.PermissionSearchModel = {
            pagination: {
                skip: params.startRow,
                take: params.endRow - params.startRow
            },
            columnFilters: this.getColumnFilters(this._propertyMap),
            sortColumns: this.getSortColumns(this._propertyMap),
            groupId: this._dataSourceParams.groupId,
            userId: this._dataSourceParams.userId,
            excludeInactive: false
        };

        const result = await this._permissionService.getAvailableSitePermissions(this._dataSourceParams.companyId, searchParams);

        return {
            rows: result.data,
            totalRows: result.totalRows
        } as AgGridDataSourceResult;
    }
}
