<ng-template #appealTemplate let-appeal="data">
    <div class="account-block"
         [ngClass]="{ 'active-account-block': selectedAppealFormRevisionId === appeal.appealFormRevisionId, 'multi-account-over': hoveredAppealFormRevisionId === appeal.appealFormRevisionId }"
         (mouseenter)="toggleHovered(appeal)"
         (mouseleave)="toggleHovered(null)">
        {{appeal.hovered}}
        <div class="account-row account-row-return ws-flex-container-horizontal"
             [ngClass]="{ 'active-account-row': selectedAppealFormRevisionId === appeal.appealFormRevisionId }">
            <button class="ace-btn btn-link btn-sm account-row-link ws-flex-auto"
                    (click)="formSelectedOnList(appeal)" >
                <span class="ws-flex-auto"
                      helpTooltip
                      [helpContentId]="(appeal.hasOverrides ? 'appeal-application.preview-form-has-override' : 'appeal-application.preview-form')"
                      position="bottom">
                    <i class="fa fa-file-pdf-o"
                       helpTooltip
                       helpContentId="appeal-application.preview-form"
                       position="bottom"></i>
                    <span class="form-list-item" [class.has-override]="appeal.hasOverrides">{{ appeal.parcelAcctNum }}</span>
                </span>
                <span
                     *ngIf="appeal.additionalAccounts && appeal.additionalAccounts.length > 0"
                     class="ws-flex-auto"
                     helpTooltip
                     position="bottom"
                     [contentComponent]="additionalAccountsTooltip"
                     [contentComponentValue]="appeal">
                    <i class="fa fa-list-ul"></i>
                </span>
            </button>
            <div class="ws-flex-none return-button-container" *ngIf="!editMode">
                <span class="ace-btn btn-link btn-xs"
                      (click)="downloadAppealDocument(appeal.parcelAcctNum)"
                      helpTooltip
                      helpContentId="appeal-application.download-form"
                      position="bottom">
                    <span class="fa fa-download"></span>
                </span>
                <span *ngIf="canChangeMainForm(appeal)"
                      class="ace-btn btn-link btn-xs"
                      (click)="editAppealMainForms(appeal)"
                      helpTooltip
                      helpContentId="appeal-application.edit-main-form"
                      position="bottom">
                    <span class="fa fa-pencil-square"></span>
                </span>
                <span *ngIf="canChangeSupplements(appeal)"
                      class="ace-btn btn-link btn-xs"
                      (click)="editAppealSupplements(appeal)"
                      helpTooltip
                      helpContentId="appeal-application.edit-supplement-form"
                      position="bottom">
                    <span class="fa fa-pencil"></span>
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #supplementalTemplate let-appeal="data">
    <div class="account-block" [ngClass]="{ 'active-account-block': selectedAppealFormRevisionId === appeal.appealFormRevisionId }">
        <div class="account-row account-row-form ws-flex-container-horizontal ws-flex-align-items-center"
             [ngClass]="{ 'active-account-row': selectedAppealFormRevisionId === appeal.appealFormRevisionId }"
             helpTooltip
             [tooltipText]="(appeal.hasOverrides ? 'appeal-application.preview-form-has-override' : 'appeal-application.preview-form')"
             position="bottom">
            <button class="ace-btn btn-link btn-sm account-row-link ws-flex-auto"
                    (click)="formSelectedOnList(appeal)">
                <span class="form-list-item" [class.has-override]="appeal.hasOverrides">
                    <i class="fa fa-file-text-o"
                       helpTooltip
                       helpContentId="appeal-application.preview-supplemental-form"
                       position="bottom"></i>
                    {{appeal.formRevisionName}}
                </span>
            </button>
        </div>
    </div>
</ng-template>

<div class="ws-flex-container-vertical ws-stretch">
    <strong class="list-title">Appeals</strong>
    <div class="ws-position-relative ws-flex-grow form-list">
        <cdk-virtual-scroll-viewport itemSize="26" class="accounts">
            <ng-container *cdkVirtualFor="let appeal of appealFormList; templateCacheSize: 0">
                <ng-container [ngTemplateOutlet]="appeal.template"
                              [ngTemplateOutletContext]="{ data: appeal, $implicit: this }">
                </ng-container>
            </ng-container>
        </cdk-virtual-scroll-viewport>
        <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none"></div>
    </div>
</div>
