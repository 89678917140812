import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Assessor } from '../../Assessor-Collector/assessor.model';
import { Collector } from '../../Assessor-Collector/collector.model';
import { AssessorService } from '../../Assessor-Collector/Assessor/assessor-service';
import { CollectorService } from '../../Assessor-Collector/Collector/collector.service.upgrade';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { TypeaheadCompany } from '../../Entity/Company/Company.Picker.Component';
import { NavigationService } from '../../Layout/navigation.service';
import { DocumentIntakeService } from './document.intake.service';
import { TimerService } from '../../UI-Lib/Utilities';
import { DocumentIntakeLicensedClientDetailRepository } from '../Document-Intake-Licensed-Clients/documentIntakeLicensedClient.repository';
import { lastValueFrom } from 'rxjs';
import { UserInstanceService } from '../../User/userInstance.service';
import { RyanInstanceId } from '../../constants.new';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { AttachmentService } from 'src/app/Attachment/attachment.service';
import { TeamService } from 'src/app/Team/team.service';
import { ProductAnalyticsService } from 'src/app/Common/Amplitude/productAnalytics.service';
import { find } from 'lodash';
import * as moment from 'moment';

export interface BatchDocument {
    splitDocument: boolean;
    pagesPerItem: number;
    excludeFirstXPages: number;
    companyID: number;
    documentTypeID?: number;
    assignedQC?: Core.UserTeamModel;
    assignedEntry?: Core.UserTeamModel;
    ultimatelyResponsible?: Core.UserTeamModel;
    contact?: Core.UserTeamModel;
    earliestDueDate?: Date;
    entityName: string;
    stateID?: number;
    assessorID?: number;
    collectorID?: number;
    taxYear: number;
    commentBody: string;
    assignToRyan: boolean;
    clientInstanceId?: number;
    performOcr: boolean;
}
@Component({
    selector: 'document-intake',
    templateUrl: './document.intake.component.html',
    styleUrls: ['./document.intake.component.scss']
})
export class DocumentIntakeComponent implements OnInit {
    constructor(
        private readonly _assessorService: AssessorService,
        private readonly _collectorService: CollectorService,
        private readonly _toastr: ToastrService,
        private readonly _cdr: ChangeDetectorRef,
        private readonly _elem: ElementRef,
        private readonly _documentIntakeService: DocumentIntakeService,
        private readonly _navigationService: NavigationService,
        private readonly _messageModalService: MessageModalService,
        private readonly _modalService: BsModalService,
        private readonly _productAnalyticsService: ProductAnalyticsService,
        private readonly _teamService: TeamService,
        private readonly _timer: TimerService,
        public readonly featureFlagsService: FeatureFlagsService,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _documentIntakeLicensedClientRepository: DocumentIntakeLicensedClientDetailRepository,
        private readonly _attachmentService: AttachmentService,
        private readonly _restrictService: RestrictService) { }

    batchDocument: BatchDocument = {} as BatchDocument;
    assessorList: Assessor[] = [];
    collectorList: Collector[] = [];
    collectorsLoading: boolean = false;
    taxYearList: number[] = _.range(new Date().getFullYear() + 5, new Date().getFullYear() - 11);
    documentTypeList: Core.AttachmentTypeModel[] = [];
    pageCount = 0;
    pagesPerItemList: number[] = _.range(1, 11);
    invalidPageCount: boolean = false;
    multiFiles: File[] = [];
    singleFile: File;
    selectedMultiFile: File;
    uploading: boolean = false;
    saveAttempted: boolean = false;
    showForm: boolean = true;
    company: TypeaheadCompany;
    documentIntakeUsers: Core.UserTeamModel[] = [];
    documentIntakeSettings: Core.DocumentIntakeLicensedClientModel;
    enableIntakeToLicensedInstance: boolean;
    enableAssignToRyan: boolean;
    intakeToLicensedInstance: boolean;
    licensedClients: Core.DocumentIntakeLicensedClientModel[] = [];
    selectedInstance: Core.DocumentIntakeLicensedClientModel;
    selectedInstanceName: string;
    clientInstanceId: number;
    instanceHasIdpEnabled: boolean = false;

    private readonly MAX_FILE_NUM: number = 999;
    private readonly NAVIGATE_WARNING: string = 'An upload is in progress; are you sure you want to leave?';
    private _assignToRyan: boolean;
    private _defaultContact: Core.UserTeamModel;

    async ngOnInit() {
        this.showForm = false;
        let documentIntakeAndProcessingAssignToRyan: boolean = false;

        const requests: Promise<any>[] = [
            this._teamService.getAllAssignableUsers(false, false, undefined, undefined, undefined, undefined, undefined, true),
        ];

        const selectedInstance = this._userInstanceService.getSelectedInstance();
        const selectedInstanceId = selectedInstance.instanceId;

        this.enableAssignToRyan = this.featureFlagsService.featureFlags.enableLicensedDocumentIntakeAndProcessing 
            && selectedInstanceId != RyanInstanceId
            && selectedInstanceId != -1;
        this.enableIntakeToLicensedInstance = this.featureFlagsService.featureFlags.enableLicensedDocumentIntakeAndProcessing 
            && selectedInstanceId === RyanInstanceId 
            && this._restrictService.isInRole(Roles.LICENSEDDOCUMENTSERVICES);
        this.instanceHasIdpEnabled = selectedInstance.documentIntakeIdpEnabled;

        if (this.enableAssignToRyan) {
            const instanceInfo = await lastValueFrom(this._instanceRepository.get(selectedInstanceId));

            documentIntakeAndProcessingAssignToRyan = !!instanceInfo?.documentIntakeAndProcessingAssignToRyan;
            if (documentIntakeAndProcessingAssignToRyan) {
                requests.push(lastValueFrom(this._documentIntakeLicensedClientRepository.get(selectedInstanceId)));
            }
        } else if (this.enableIntakeToLicensedInstance) {
            requests.push(lastValueFrom(this._documentIntakeLicensedClientRepository.getListAcceptedForDI()));
        }

        const responses = await Promise.all(requests);

        this.documentIntakeUsers = responses[0];

        this.documentIntakeSettings = this.enableAssignToRyan && documentIntakeAndProcessingAssignToRyan
            ? responses[1]
            : {};
        this._assignToRyan = !!this.documentIntakeSettings.ryanUserGroupId;

        if (this.enableIntakeToLicensedInstance) {
            this.licensedClients = responses[1];
            this.licensedClients = this.licensedClients.filter(x => x.ryanUserGroupId);
        }

        await this.initializeForm();
        this.batchDocument.splitDocument = true;

        const documentTypeList = await this._attachmentService.getAttachmentTypes();
        this.documentTypeList = _.sortBy(documentTypeList, 'attachmentTypeName');

    }

    get shouldDisplayAssignToRyan() {
        return this.enableAssignToRyan && this.documentIntakeSettings?.ryanUserGroupId;
    }

    get shouldDisplayPerformOCR() {
        return this.featureFlagsService.featureFlags.enableIdp  && this.instanceHasIdpEnabled
            && (this.batchDocument.documentTypeID === Core.AttachmentTypes.TaxBill
                || (this.featureFlagsService.featureFlags.enableIdpAssessment 
                    && this.batchDocument.documentTypeID === Core.AttachmentTypes.AssessmentNotice)
                );
    }

    async initializeForm(): Promise<void> {
        this.showForm = false;

        _.assign(this.batchDocument, {
            pagesPerItem: 1,
            excludeFirstXPages: 0,
            companyID: 0,
            entityName: 'company',
            documentTypeID: undefined,
            assignedQC: undefined,
            assignedEntry: undefined,
            ultimatelyResponsible: undefined,
            contact: undefined,
            earliestDueDate: undefined,
            stateID: undefined,
            assessorID: undefined,
            collectorID: undefined,
            taxYear: undefined,
            commentBody: undefined,
            assignToRyan: this._assignToRyan
        });

        this._defaultContact = await this._teamService.getUserDefaultTeam();

        if (!this._assignToRyan) {
            this.batchDocument.contact = this._defaultContact;
        }
        this.company = undefined;

        this.showForm = true;
    }

    pageExclusionChanged(): void {
        this._productAnalyticsService.logEvent('exclude-first-page(s)');
        this.pageCount = 0;
        // If we haven't loaded a PDF yet, don't clear the pagesPerItemList
        // Works as long as document intake only has one iframe; if this assumption changes we'll need to alter this query
        if (this._elem.nativeElement.querySelector('iframe')) {
            this.pagesPerItemList = [];
        }
        this._startCheckPageCount();
    }

    resetExclusionInput(): void {
        if(!this.batchDocument.excludeFirstXPages && this.batchDocument.excludeFirstXPages != 0) {
            this.batchDocument.excludeFirstXPages = 0;
        }
    }

    ultimatelyResponsibleChosen(user: Core.UserTeamModel): void {
        if(user ) {
            this.batchDocument.assignedEntry = _.cloneDeep(user);
        }
    }

    async stateChanged(): Promise<void> {
        if(this.batchDocument.stateID) {
            this._productAnalyticsService.logEvent('enter-state');

            await this.getCollectorList();
            this.batchDocument.assessorID = null;

            const assessorList = await this._assessorService.getByStateId(this.batchDocument.stateID);
            this.assessorList = _.sortBy(assessorList, 'name');
        }
    }

    async getCollectorList(): Promise<void> {
        this.batchDocument.collectorID = undefined;
        let collectorList: Collector[];

        this.collectorsLoading = true;

        if(this.batchDocument.assessorID) {
            const results = await this._collectorService.getCollectorsByAssessor(this.batchDocument.assessorID);
            collectorList = _.map(results, 'collector');
        } else {
            collectorList = await this._collectorService.getCollectorsByState(this.batchDocument.stateID);
        }
        this.collectorList = _.sortBy(collectorList, 'abbr');

        this.collectorsLoading = false;
    }

    isUploadDisabled(): boolean {
        if (this.batchDocument.splitDocument) {
            return this.batchDocument.excludeFirstXPages >= this.pageCount || !this.singleFile;
        } else {
            return !this.multiFiles.length;
        }
    }

    filesPicked(files: File[]): void {
        if(!files.length) {
            this._toastr.error('Could not process file for upload');
            return;
        }

        if(this.batchDocument.splitDocument) {
            if(files[0].type != 'application/pdf') {
                this._toastr.error('Only PDF files are supported for Single Document (Split) uploads');
                return;
            }

            if(files.length > 1) {
                this._toastr.error('Only one document can be uploaded');
                return;
            }

           this.singleFile = files[0];
           this._startCheckPageCount();
        } else {
            this.multiFiles = [...files, ...this.multiFiles];

            if(this.multiFiles.length > this.MAX_FILE_NUM) {
                this.multiFiles = _.take(this.multiFiles, this.MAX_FILE_NUM);
                this._toastr.error('Please limit number of files to 999');
            }
        }

        this._cdr.detectChanges();
    }

    async selectInstance(selectedInstance: Core.DocumentIntakeLicensedClientModel): Promise<void> {
        this.selectedInstance = selectedInstance;
        this.selectedInstanceName = selectedInstance.instanceName;
        this.batchDocument.clientInstanceId = this.selectedInstance.instanceId;
        this.batchDocument.ultimatelyResponsible = this.selectedInstance.ryanUltimatelyResp;
        this.batchDocument.assignedEntry = this.selectedInstance.ryanAssignedEntry;
        this.batchDocument.assignedQC = this.selectedInstance.ryanAssignedQC;
        this.clientInstanceId = this.selectedInstance.instanceId;
        this.documentIntakeUsers = await this._teamService.getAllAssignableUsers(false, false, undefined, undefined, this.selectedInstance.instanceId, undefined, undefined, true);
    }

    changed(): void {
        if(!_.trim(this.selectedInstanceName)) {
            this.selectedInstance = null;
            this.batchDocument.clientInstanceId = null;
            this.batchDocument.ultimatelyResponsible = null;
            this.batchDocument.assignedEntry = null;
            this.batchDocument.assignedQC = null;
        }
    }

    async onBlur(): Promise<void> {
        if (!_.trim(this.selectedInstanceName) && this.selectedInstance) {
            this.selectedInstanceName = this.selectedInstance.instanceName;
            return;
        }

        if (_.trim(this.selectedInstanceName) && !this.selectedInstance) {
            const licensedClient = this.licensedClients.find(x => x.instanceName === this.selectedInstanceName);
            if (licensedClient) {
                await this.selectInstance(licensedClient);

            }
        }
    }

    intakeToLicensedInstanceChanged(e): void {
        this._productAnalyticsService.logEvent('intake-licensed-document');

        if (e.target.checked) {
            this.batchDocument.contact = null;

            if (!this.selectedInstance) {
                this.clientInstanceId = -1;
            }
        } else {
            this.batchDocument.contact = this._defaultContact;
            this.batchDocument.ultimatelyResponsible = null;
            this.batchDocument.assignedEntry = null;
            this.batchDocument.assignedQC = null;
            this.batchDocument.companyID = null;
            this.batchDocument.clientInstanceId = null;
            this.selectedInstance = null;
            this.selectedInstanceName = null;
            this.clientInstanceId = RyanInstanceId;
            this.company = null;
        }
    }

    removeSingleDocument(): void {
        this.singleFile = undefined;
        this.pagesPerItemList = _.range(1, 11);
        this.pageCount = 0;
    }

    viewMultiFile(file: File, multiFileViewer: TemplateRef<any>): void {
        this.selectedMultiFile = file;
        this._modalService.show(multiFileViewer, Object.assign({}, { class: 'modal-lg' }));
    }

    removeMultiFile(index: number): void {
        this.multiFiles.splice(index, 1);
    }

    select(eventTarget: EventTarget): void {
        (eventTarget as HTMLInputElement).select();
    }

    async uploadClicked(): Promise<void> {
        this._productAnalyticsService.logEvent('click-upload-documents');

        const isValid = this.batchDocument.documentTypeID
            && this.batchDocument.earliestDueDate
            && (
                (
                    (this.batchDocument.contact && this.batchDocument.assignedEntry && this.batchDocument.ultimatelyResponsible)
                        || this.batchDocument.assignToRyan
                )
                || (this.batchDocument.assignedEntry && this.batchDocument.ultimatelyResponsible && this.batchDocument.clientInstanceId)
            );

        if(!isValid) {
            this.saveAttempted = true;
            this._toastr.error('Please fill out the missing fields!');

            return;
        }

        if (this._assignToRyan || this.batchDocument.assignedQC && this.batchDocument.assignedQC.userID) {
            await this._createBatch();
        }
        else {
            try {
                await this._messageModalService.confirm(
                    'No user has been assigned to QC. If you proceed to upload documents only Identify/Data Enter tasks will be created for these items (no Perform QC task will be created). Do you wish to proceed?',
                    'Upload with no QC User?'
                );
            } catch (e) {
                return Promise.resolve();
            }

            await this._createBatch();
        }
    }

    companySelected(company: TypeaheadCompany): void {
        this._productAnalyticsService.logEvent('enter-company');

        this.company = company;
        this.batchDocument.companyID = company.companyID;
    }

    async assignToRyanChanged($event: any): Promise<void> {
        this._assignToRyan = $event.target.checked;

        _.assign(this.batchDocument, {
            companyID: 0,
            assignedQC: undefined,
            assignedEntry: undefined,
            ultimatelyResponsible: undefined,
            contact: undefined,
            earliestDueDate: undefined,
            stateID: undefined,
            assessorID: undefined,
            collectorID: undefined,
            taxYear: undefined,
            commentBody: undefined,
            assignToRyan: this._assignToRyan
        });

        if (!this._assignToRyan) {
            this.batchDocument.contact = this._defaultContact;
        }

        this.company = undefined;

        this.clientInstanceId = this._assignToRyan
            ? RyanInstanceId
            : this._userInstanceService.getSelectedInstance().instanceId;
    }

    togglePerformOcr() {
        if(this.batchDocument.performOcr) {
            this._productAnalyticsService.logEvent('check-perform-ocr');
        }
    }

    selectDocumentCount() {
        this._productAnalyticsService.logEvent('select-document-count');
    }

    assignedQcChanged() {
        this._productAnalyticsService.logEvent('enter-assigned-qc');
    }

    documentTypeSelected() {
        const docType = find(this.documentTypeList, {attachmentTypeID: this.batchDocument.documentTypeID});

        if(docType) {
            this._productAnalyticsService.logEvent('select-document-type', { DocumentType: docType.attachmentTypeName});
        }
    }

    earliestDueDateChanged() {
        const numDays = moment(this.batchDocument.earliestDueDate).diff(new Date(), 'days');

        let DaysToEarliestDueDate;

        if(numDays < 5 ) {
            DaysToEarliestDueDate = '<5';
        } else if(numDays < 10) {
            DaysToEarliestDueDate = '<10';
        } else if(numDays < 20) {
            DaysToEarliestDueDate = '<20';
        } else if(numDays < 30) {
            DaysToEarliestDueDate = '<30';
        } else {
            DaysToEarliestDueDate = '30+';
        }

        this._productAnalyticsService.logEvent('enter-earliest-due-date', { DaysToEarliestDueDate });
    }

    assessorChanged() {
        this._productAnalyticsService.logEvent('select-assessor');
        this.getCollectorList();
    }

    collectorChanged() {
        this._productAnalyticsService.logEvent('select-collector');
    }

    taxYearChanged() {
        this._productAnalyticsService.logEvent('select-tax-year');
    }

    commentChanged() {
        this._productAnalyticsService.logEvent('enter-comment');
    }

    clearForm() {
        this._productAnalyticsService.logEvent('click-clear-form');
        this.initializeForm();
    }

    private _startCheckPageCount(): void {
        const pageCountTimer = this._timer.setInterval(() => {
            const iframe: HTMLIFrameElement = document.querySelector('iframe.preview-window');
            const pageCount = (iframe?.contentWindow as any)?.PDFViewerApplication?.pagesCount;
            if (pageCount > 0 && pageCountTimer !== undefined) {
                clearInterval(pageCountTimer);
                this._preparePageCount(pageCount);
            }
        }, 500);
    }

    private _preparePageCount(count: number): void {
        const pagesPerItem = this.batchDocument.pagesPerItem;
        let countTotal = count;

        if (this.batchDocument.excludeFirstXPages >= 0) {
            countTotal -= this.batchDocument.excludeFirstXPages;
            this.invalidPageCount = countTotal < 1;
        }

        if (countTotal > 0 && !this.invalidPageCount) {
            this.pagesPerItemList = this._getPagesPerItem(countTotal, countTotal);

            //This is to preserve the pagesPerItem selection if we can... if not set to null to trigger required error so users know to change it
            if (this.batchDocument.pagesPerItem) {
                if (!_.includes(this.pagesPerItemList, this.batchDocument.pagesPerItem)) {
                    this.batchDocument.pagesPerItem = null;
                }
            }
            this.pageCount = count;

            if (pagesPerItem && (this.pageCount / pagesPerItem) > 999) {
                if (!_.includes(this.pagesPerItemList, pagesPerItem)) {
                    this.batchDocument.pagesPerItem = this.pagesPerItemList[0];
                }
            }
        }
    }

    private async _createBatch(): Promise<void> {
        const files = this.batchDocument.splitDocument ? [this.singleFile] : this.multiFiles;

        // If Tax Bill and Assessment Notice were not selected as the document type, ensure OCR is not performed
        if (this.batchDocument.performOcr
            && (this.batchDocument.documentTypeID !== Core.AttachmentTypes.TaxBill
                && this.batchDocument.documentTypeID !== Core.AttachmentTypes.AssessmentNotice)) {
            this.batchDocument.performOcr = false;
        }

        try {
            this.uploading = true;
            this._navigationService.enableNavWarning(this.NAVIGATE_WARNING);

            const response = await this._documentIntakeService.sendBatchDocuments(this.batchDocument, files);

            this._messageModalService.alertList([`Batch Number: ${response.batchNumber}`, `Items Processed: ${response.intakeItems.length}`], 'Document Intake Successful');

            this.singleFile = undefined;
            this.multiFiles = [];
        } finally {
            this.uploading = false;
            this._navigationService.disableNavWarning();
        }
    }

    private _getPagesPerItem(totalPages: number, pages: number): number[] {
        if(pages === 0) {
            return [];
        }

        if(totalPages % pages === 0) {
            return [totalPages / pages, ...this._getPagesPerItem(totalPages, pages - 1)];
        } else {
            return this._getPagesPerItem(totalPages, pages - 1);
        }
    }
}
