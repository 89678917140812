import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface FormConfig {
    label: string;
    formControlName: string;
}

@Component({
    selector: 'format-output-panel-schedules-summary',
    templateUrl: './format.output.panel.schedules.summary.component.html',
    styleUrls: ['./format.output.panel.scss']
})
export class FormatOutputPanelSchedulesSummaryComponent implements OnInit, OnDestroy {
    constructor(private readonly _fb: UntypedFormBuilder) {}

    @Input() report: ReportDetail;
    @Input()
    set scheduleName(scheduleName: string) {
        this.report.criteria.title = this._getTitle(scheduleName);
        this.reportOutputForm.patchValue({ title: this.report.criteria.title });
    }

    initialized: boolean;
    formatOptions: FormConfig[] = [
        {
            label: 'Show Taxpayer Name',
            formControlName: 'showTaxpayerName'
        },
        {
            label: 'Suppress zero cost',
            formControlName: 'suppressZeroCost'
        },
        {
            label: 'Show Grand Total',
            formControlName: 'showGrandTotal'
        },
        {
            label: 'Split out Factors',
            formControlName: 'splitOutFactors'
        }
    ];
    optionalCols: FormConfig[] = [
        {
            label: 'Cost',
            formControlName: 'includeCost'
        },
        {
            label: 'Prior Cost / Adds / Deletes',
            formControlName: 'includePriorCostAddsDeletes'
        },
        {
            label: 'Factor and Depreciated Value',
            formControlName: 'includeFactorAndDepreciatedValue'
        },
        {
            label: 'Estimated FMV',
            formControlName: 'includeEstimatedFMV'
        }
    ];
    reportOutputForm: UntypedFormGroup = this._fb.group({
        title: this._fb.control('')
    });

    private destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        const createControl = (x: FormConfig) => this.reportOutputForm.addControl(x.formControlName,
            new UntypedFormControl(this.report.criteria[x.formControlName]));

        this.formatOptions.forEach(createControl);
        this.optionalCols.forEach(createControl);

        this.reportOutputForm.patchValue({title: this.report.criteria.title});

        this.reportOutputForm.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this._updateReport());

        this.reportOutputForm.get('splitOutFactors').valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                if (!value) {
                    this.report.criteria.includeFactorAndDepreciatedValue = false;
                }
            });

        this.initialized = true;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _getTitle(scheduleName?: string): string{
        if (this.report.criteria && this.report.criteria.title) {
            return this.report.criteria.title;
        } else if (!scheduleName || scheduleName === 'All Reportable'){
            return 'Schedule Summary';
        } else if (scheduleName === 'All Non-Reportable') {
            return 'Schedule Summary (Non-Reportable)';
        } else {
            return scheduleName;
        }
    }

    private _updateReport(): void {
        this.report.criteria = { ...this.report.criteria, ...this.reportOutputForm.value };
    }
}
