import { Injectable } from '@angular/core';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';
import { GetEntitiesRequest, SummaryReportCriteria, ExtraReportDetailCriteria, BudgetVarianceFormatOutputCriteria } from './Manager/report.manager.model';
import { lastValueFrom } from 'rxjs';

export class PsrRequest extends GetEntitiesRequest {
    reportTitle: string;
    startYearValue: number;
    endYearValue: number;
    propertyTypes: number[];
    includeEstAssessment: boolean;
    includeOnlySitesUnderAppeal: boolean;
    outputFormat: number;
    psrReportType: number;
    includeDisclaimer: boolean;
}

export class MaarRequest extends GetEntitiesRequest {
    reportTitle: string;
    YearValue: number;
    propertyTypes: number[];
    includeAllCompanies: boolean;
}

export class BudgetRequest extends GetEntitiesRequest {
    reportTitle: string;
    budgetYear: number;
    budgetYearString: string; // calculated for jsreport
    //includePPPropertyType: boolean;
    propertyTypes: number[];
    minimumDetailYearsShown: number;
    outputFormat: number;
    currCompanyBudgetID: number;
    priorCompanyBudgetID: number;
    budgetBasis: Core.CompanyBudgetBasisEnum;
    detailReportOptions: ExtraReportDetailCriteria;
    summaryReportOptions: SummaryReportCriteria;
    includeDisclaimer: boolean;
}

export class BudgetVarianceReportingRequest extends GetEntitiesRequest {
    constructor()
    {
        super();
        this.formatOutput = new BudgetVarianceFormatOutputCriteria();
        this.includeDisclaimer = true;
    }

    reportTitle: string;
    formatOutput: BudgetVarianceFormatOutputCriteria;
    includeDisclaimer: boolean;
}

export class ProgressStatusRequest extends GetEntitiesRequest {
    reportTitle: string;
    startYearValue: number;
    endYearValue: number;
    siteStatus: number;
    propertyTypes: number[];
}

export class SiteRequest extends GetEntitiesRequest {
    reportTitle: string;
    orderBy: number[];
    startYearValue: number;
    endYearValue: number;
    numberOfYearsToInclude: number;
    propertyTypes: number[];
    detailReportOptions: ExtraReportDetailCriteria;
    includeDisclaimer: boolean;
}

export class UserActivityRequest extends GetEntitiesRequest {
    reportTitle: string;
    loginDateBegin: Date;
    loginDateEnd: Date;
    onlyActiveUsers: boolean;
    includeUsersWithoutLogin: boolean;
    outputFormat: number;
    includeInternalUsers: boolean;

    showUserName: boolean;
    showContactTitle: boolean;
    showLoginDates: boolean;
    showLoginDayCount: boolean;
    showLastPasswordChange: boolean;

    userAccessReportLimitByLoginDate: Core.UserAccessReportLimitByLoginDateEnum;
}

export class UserAccessRequest extends GetEntitiesRequest {
    reportTitle: string;
    loginDateBegin: Date;
    loginDateEnd: Date;
    onlyActiveUsers: boolean;
    includeUsersWithoutLogin: boolean;
    outputFormat: number;
    includeInternalUsers: boolean;

    showUserName: boolean;
    showContactTitle: boolean;
    showLoginDates: boolean;
    showLoginDayCount: boolean;
    showLastPasswordChange: boolean;

    selectedUserId: System.Guid;
    userAccessReportStartWith: Core.UserAccessReportStartWithEnum;
    userAccessReportLimitByLoginDate: Core.UserAccessReportLimitByLoginDateEnum;
}

export class UserCSRRequest extends GetEntitiesRequest {
    reportTitle: string;
    selectedUserId: System.Guid;
}

export class AppealListReportRequest extends GetEntitiesRequest {
    taskIds: number[];
}

export class SupportInstanceEscalationActivityReportRequest extends GetEntitiesRequest {
    instanceIds: number[];
    contactIds: number[];
    escalationDateBegin: Date;
    escalationDateEnd: Date;
    outputFormat: number;
    anyEscalationDates: boolean;
    escalationGroupBy: Core.SupportInstanceEscalationActivityReportGroupByEnum;
}


@Injectable()
export class ReportingService extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    private SERVICE_URL: string = '/api/Reporting/';

    async runPsrReport(psrRequest: PsrRequest, shouldZip: boolean): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }psr${  shouldZip ? '/zip' : ''}`, psrRequest)) as Promise<number>;
    }

    async runBudgetReport(budgetRequest: BudgetRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }budget`, budgetRequest)) as Promise<number>;
    }

    async runBudgetVarianceReport(budgetVarianceRequest: BudgetVarianceReportingRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }budgetvariance`, budgetVarianceRequest)) as Promise<number>;
    }

	async runMaarReport(maarRequest: MaarRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }maar`, maarRequest)) as Promise<number>;
    }

	async runProgressStatusExtract(pseRequest: ProgressStatusRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }pse`, pseRequest)) as Promise<number>;
    }

    async runSiteReport(siteRequest: SiteRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }site`, siteRequest)) as Promise<number>;
    }

    async runUserActivityReport(userActivityRequest: UserActivityRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }usr`, userActivityRequest)) as Promise<number>;
    }

    async runUserAccessReport(userAccessRequest: UserAccessRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }usrAccess`, userAccessRequest)) as Promise<number>;
    }

    async runUserCSRExtract(userCSRRequest: UserCSRRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }usrCsr`, userCSRRequest)) as Promise<number>;
    }

    async runAppealListReport(request: AppealListReportRequest): Promise<number> {
        return lastValueFrom(this.httpPost(`${this.SERVICE_URL  }appealList`, request)) as Promise<number>;
    }

    async runSupportInstanceEscalationActivityReport(request: SupportInstanceEscalationActivityReportRequest): Promise<number> {
        return await lastValueFrom(this.httpPost(`${this.SERVICE_URL  }siear`, request));
    }
}
