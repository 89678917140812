import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TaxRateArea, TaxAuthority } from '../Assessor-Collector/Tax-Rates/tax.rates.panel.model';
import { BaseRepository } from '../Common/Data/base.repository';
import { HttpClient } from '@angular/common/http';

@Injectable(
    { providedIn: 'root' }
)
export class TaxRateGridService extends BaseRepository {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    isInitialized: boolean;
    protected taxRateAreas: TaxRateArea[];
    protected taxAuthorities: TaxAuthority[];

    private _taxYearBegin: number = new Date().getFullYear();
    private _taxYearsPrior: number = 2;

    get allTaxRateAreas(): TaxRateArea[] {
        return this.taxRateAreas;
    }

    get allTaxAuthorities(): TaxAuthority[] {
        return this.taxAuthorities;
    }

    set allTaxAuthorities(taxAuthorities: TaxAuthority[]) {
        this.taxAuthorities = taxAuthorities;
    }

    get enabledTaxRateAreas(): TaxRateArea[] {
        return _.reject(this.taxRateAreas, 'disabled');
    }

    get enabledTaxAuthorities(): TaxAuthority[] {
        return _.reject(this.taxAuthorities, 'disabled');
    }

    get taxYearBegin(): number {
        return this._taxYearBegin;
    }

    set taxYearBegin(taxYearBegin) {
        this._taxYearBegin = taxYearBegin;
    }

    get taxYearsPrior(): number {
        return this._taxYearsPrior;
    }

    set taxYearsPrior(taxYearsPrior) {
        this._taxYearsPrior = taxYearsPrior;
    }
}
