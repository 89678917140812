import { Component, OnInit, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { DataWidgetI } from '../../dashboard.model';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { ActionViewService } from '../../../Task/ActionView/action-view-service.upgrade';
import { ActionViewPersistenceService } from '../../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import { TaskSeriesTypeItem } from './my.tasks.widget.model';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';

@Component({
    selector: 'my-tasks-widget',
    templateUrl: './my.tasks.widget.component.html',
    styleUrls: ['./my.tasks.widget.component.scss']
})
export class MyTasksWidgetComponent implements OnInit, DataWidgetI, OnDestroy {
    constructor(
        private readonly _actionViewService: ActionViewService,
        private readonly _actionViewPersistenceService: ActionViewPersistenceService,
        private readonly _upgradeNavigationService: UpgradeNavigationServiceHandler,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) { }

    @Input() data$?: Observable<any>;
    @Output() newParams?: EventEmitter<object> = new EventEmitter();

    dataSubscription: Subscription;
    taskSeriesTypeList: TaskSeriesTypeItem[];
    originalRequest: any;
    actionViewTimeout: boolean = false;

    ngOnInit() {
        this.taskSeriesTypeList = null;
        this.dataSubscription = this.data$.subscribe(event => {
            const data = _.cloneDeep(event.data);

            this._actionViewService.processSearchResultPlusRequest(data);
            this.originalRequest = data.originalRequest;

            if (data.isCancelled) {
                this.actionViewTimeout = true;
                return;
            }

            this.taskSeriesTypeList = _.map(data.group1ResultList, (task: TaskSeriesTypeItem) => {
                task.data = _.map(task.data, (item: any) => {
                    item.colClass = this._actionViewService.getUrgencyColumnStyleClass(item.sequence);
                    return item;
                });
                return task;
            });
        });
    }

    ngOnDestroy() {
        this.dataSubscription.unsubscribe();
    }

    drilldownCell(cell: any, ready: string, task: any): void {
        this._actionViewPersistenceService.clearSavedData();
        //step 1: set drilldown persistenceservice variable
        this._actionViewPersistenceService.externalDrilldown = true;
        this._actionViewPersistenceService.masterFilter = _.cloneDeep(this.originalRequest);
        this._actionViewPersistenceService.drilldownFilter = _.cloneDeep(this.originalRequest);
        this._actionViewPersistenceService.externalDrilldownData = {
            ready: ready,
            item: cell,
            task: task
        };

        this._productAnalyticsService.logEvent('click-task-widget', {
            [cell.label.toLowerCase()]: task.taskSeriesTypeName
        });
        this._upgradeNavigationService.go('actionview', {});
    }

    goToActionView(): void {
        this._actionViewPersistenceService.clearSavedData();
        this._actionViewPersistenceService.masterFilter = this.originalRequest;
        this._upgradeNavigationService.go('actionview', {});
    }
}
