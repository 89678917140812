<ws-busy-indicator *ngIf="busyMessageManager.count()" [message]="busyMessageManager.message"></ws-busy-indicator>

<div class="card panel-flat-color primary-panel ws-flex-auto m-0 d-flex flex-column">
    <div class="panel-flat-header">
        <div class="float-start">
            <h3 helpTooltip
                helpContentId="filing-batch-info.panel"
                position="bottom">
                Filing Batch Info
            </h3>
        </div>
        <div class="float-end header-button-container d-flex">
            <div class="action-button-wrapper"
                 helpTooltip
                 [helpContentId]="editHelpContentId"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-primary"
                        *ngIf="canEdit && !editMode"
                        [disabled]="!canEnterEditMode"
                        (click)="edit()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
            </div>
            <div class="action-button-wrapper">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-primary"
                        [disabled]="!f.valid"
                        *ngIf="editMode"
                        (click)="save()"
                        helpTooltip
                        helpContentId="app.save"
                        position="bottom">
                    <i class="fa fa-save fa-align"></i>
                </button>
            </div>
            <div class="action-button-wrapper">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-primary"
                        *ngIf="editMode"
                        (click)="cancel()"
                        helpTooltip
                        helpContentId="app.cancel"
                        position="bottom">
                    <i class="fa fa-times fa-align"></i>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="app.expand"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-secondary"
                        *ngIf="!isExpanded"
                        (click)="expandComponent()">
                    <i class="fa fa-arrows-alt"></i>
                </button>
            </div>
            <div class="action-button-wrapper"
                 helpTooltip
                 helpContentId="app.collapse"
                 position="bottom">
                <button type="button"
                        class="ace-btn btn-text has-icon btn-secondary"
                        *ngIf="isExpanded"
                        (click)="collapseComponent()">
                    <i class="fa fa-compress"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="panel-flat-body ws-flex-auto d-flex flex-column mt-4">
        <form class="form-horizontal row form-inline" [formGroup]="form">
            <div class="col-lg-6">
                <div class="mb-3 row" *ngIf="!addMode" [ngClass]="{ 'has-error': editMode && f.controls.filingType.touched && f.controls.filingType.errors }">
                    <label for="filingType" class="col-md-3 control-label">Batch #:</label>
                    <div class="col-md-9">
                        <p class="form-control-static">{{ filingBatch.displayId }}</p>
                    </div>
                </div>

                <div class="mb-3 row" [ngClass]="{ 'has-error': editMode && f.controls.filingType.touched && f.controls.filingType.errors }">
                    <label for="filingType" class="col-md-3 control-label">Filing Type:</label>
                    <div class="col-md-9">
                        <p *ngIf="!editMode" class="form-control-static">{{ f.controls.filingType.value?.name }}</p>
                        <ws-select *ngIf="editMode"
                                   formControlName="filingType"
                                   [options]="filingTypes"
                                   labelProperty="name"
                                   [returnEntireOption]="true">
                        </ws-select>
                        <div *ngIf="f.controls.filingType.touched && f.controls.filingType.errors" class="error-feedback">
                            <div class="text-danger small" *ngIf="f.controls.filingType.errors.required">Filing Type is required.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" [ngClass]="{ 'has-error': editMode && f.controls.taxYear.touched && f.controls.taxYear.errors }">
                    <label for="taxYear" class="col-md-3 control-label">Tax Year:</label>
                    <div class="col-md-9">
                        <p *ngIf="!editMode" class="form-control-static">{{ f.controls.taxYear.value }}</p>
                        <ws-select *ngIf="editMode"
                                   formControlName="taxYear"
                                   [options]="taxYears"
                                   [isPrimitive]="true"
                                   (selectedOptionChanged)="updateDeadlineForStateAndTaxYear()">
                        </ws-select>
                        <div *ngIf="f.controls.taxYear.touched && f.controls.taxYear.errors" class="error-feedback">
                            <div class="text-danger small" *ngIf="f.controls.taxYear.errors.required">Tax Year is required.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" formGroupName="stateGroup" [ngClass]="{ 'has-error': editMode && fState.controls.stateName.touched && fState.controls.state.errors }">
                    <label for="state" class="col-md-3 control-label">State:</label>
                    <div class="col-md-9">
                        <p *ngIf="!editMode" class="form-control-static">{{ fState.controls.stateName.value }}</p>
                        <ws-select *ngIf="editMode"
                                   formControlName="state"
                                   placeholder="Select a state..."
                                   labelProperty="fullName"
                                   [returnEntireOption]="true"
                                   [options]="states"
                                   searchType="custom"
                                   [customSearch]="customStateSearch"
                                   (selectedOptionChanged)="onStateSelected($event)">
                        </ws-select>
                        <div *ngIf="fState.controls.stateName.touched && fState.controls.state.errors" class="error-feedback">
                            <div class="text-danger small" *ngIf="fState.controls.state.errors.required">State is required.</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="mb-3 row" *ngIf="!addMode">
                    <label class="col-md-3 control-label">Status:</label>
                    <div class="col-md-9">
                        <p class="form-control-static">
                            <span class="ace-btn btn-link pointer p-0 border-0"
                                  (click)="viewTasks()"
                                  helpTooltip
                                  [helpContentId]="tasksHelpContentId"
                                  position="bottom">
                                {{ filingBatch.status }}
                            </span>
                        </p>
                    </div>
                </div>

                <div class="mb-3 row" [ngClass]="{ 'has-error': editMode && f.controls.description.touched && f.controls.description.errors }">
                    <label for="description" class="col-md-3 control-label">Description:</label>
                    <div class="col-md-9">
                        <p *ngIf="!editMode" class="form-control-static ws-truncate-keep-spaces" [title]="f.controls.description.value">{{ f.controls.description.value }}</p>
                        <input *ngIf="editMode"
                               type="text"
                               class="form-control"
                               placeholder="Description"
                               autocomplete="off"
                               formControlName="description" />
                        <div *ngIf="f.controls.description.touched && f.controls.description.errors" class="error-feedback">
                            <div class="text-danger small" *ngIf="f.controls.description.errors.required">Description is required.</div>
                            <div class="text-danger small" *ngIf="f.controls.description.errors.maxlength">Description max length is 4,000 characters.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row" [ngClass]="{ 'has-error': editMode && f.controls.dueDate.touched && f.controls.dueDate.errors }">
                    <label for="dueDate" class="col-md-3 control-label">Due Date:</label>
                    <div class="col-md-6">
                        <p *ngIf="!editMode" class="form-control-static">{{ f.controls.dueDate.value | weissmanDateFormat: true }}</p>
                        <weissman-datetime *ngIf="editMode"
                                           [dateOnly]="true"
                                           [inputDate]="f.controls.dueDate.value"
                                           (change)="onDueDateChanged($event)"
                                           helpTooltip
                                           helpContentId="app.date-picker"
                                           position="bottom">
                        </weissman-datetime>
                        <div *ngIf="f.controls.dueDate.touched && f.controls.dueDate.errors" class="error-feedback">
                            <div class="text-danger small" *ngIf="f.controls.dueDate.errors.required">Due date is required.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-3 row"
                     [ngClass]="{ 'has-error': editMode && f.controls.changeDetection.touched && f.controls.changeDetection.errors }"
                     *ngIf="showChangeDetection">
                    <label for="changeDetection"
                           class="col-md-3 control-label"
                           helpTooltip
                           helpContentId="filing-batch-info.change-detection"
                           position="right">
                           Change Detection:
                    </label>
                    <div class="col-md-6">
                        <p *ngIf="!editMode" class="form-control-static">{{ changeDetection }}</p>
                        <ws-select *ngIf="editMode"
                                   [options]="changeDetectionOptions"
                                   labelProperty="name"
                                   valueProperty="value"
                                   formControlName="changeDetection">
                        </ws-select>
                    </div>
                </div>
            </div>
        </form>

        <div class="actions" *ngIf="editMode && !addMode && !isReadOnly">
            <button type="button"
                    class="ace-btn btn-primary btn-danger btn-sm float-end"
                    (click)="delete()"
                    helpTooltip
                    helpContentId="app.delete"
                    position="bottom">
                Delete
            </button>
        </div>
    </div>
</div>
