<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<div class="modal-header">
    <h4 class="modal-title">Appeal Email Template</h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
</div>

<div class="modal-body exclude-modal-styling">
    <div class="row">
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="name">Name:</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    placeholder="Name"
                    maxlength="100"
                    autocomplete="off"
                    [(ngModel)]="name"/>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="description">Description:</label>
                <input
                    id="description"
                    type="text"
                    class="form-control"
                    placeholder="Description"
                    maxlength="100"
                    [(ngModel)]="description"/>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="hasEditSystemSearch">
        <div class="col-lg-12">
            <div class="mb-3">
                <label for="visibility">Visibility:</label>
                <select id="visibility"
                        class="form-select form-select-sm"
                        [(ngModel)]="visibility"
                        [disabled]="!!params.selectedTemplate">
                    <option *ngFor="let v of visibilities" [ngValue]="v">{{ v.name }}</option>
                </select>
                <div class="ag-grid-layout-specification-details-visibility-description small">
                    {{ visibility?.description }}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="d-flex">
        <div class="d-flex">
            <div class="mx-1" helpTooltip [helpContentId]="saveHelpId">
                <button type="button" class="ace-btn btn-primary" (click)="save()" [disabled]="!canSave()">Save</button>
            </div>
            <div class="mx-1" helpTooltip helpContentId="app.cancel">
                <button type="button" class="ace-btn btn-secondary" (click)="cancel()">Cancel</button>
            </div>
        </div>
    </div>
</div>
