export class AssessmentTaxesGridData {
    headers: string[];
    rows: GridDataRow[];
}

export class GridDataRow {
    fields: GridDataField[];
    isTotal: boolean;
    label: string;
    rowSubOrder: number;
}

export class GridDataField {
    displayValue: string;
    isEstimated: boolean;
    isUnderAppeal: boolean;
    type: number;
    value: any;
}

export class AssessmentTaxesCriteria {
    constructor() {
        this.showLienDate = true;
        this.valueDetails = 1;
        this.includeAssessedValueInfo = 1;
        this.includePPPropertyType = true;
        this.includeTargetValue = 2;
        this.includeValuationMetrics = 1;
        this.breakOutTaxPaymentDetails = 1;
        this.showPercentChange = 0;
        this.includePaymentDueDates = false;
        this.includeAlternateAssessments = true;
    }

    showLienDate: boolean;
    valueDetails: number;
    includeAssessedValueInfo: number;
    includePPPropertyType: boolean;
    includeTargetValue: number;
    includeValuationMetrics: number;
    breakOutTaxPaymentDetails: number;
    showPercentChange: number;
    includePaymentDueDates: boolean;
    toYear: number;
    fromYear: number;
    includeBudgetComments: boolean;
    includeAlternateAssessments: boolean;
}

export class AssessmentTaxesOptions {
    constructor() {
        this.valueDetailsOptions = ['Property Type', 'Assessment Component', 'None'];
        this.includeAssessedValueInfoOptions = ['Always', 'When AV isn\'t 100%', 'Never'];
        this.includeTargetValueOptions = ['Always', 'For <> 0', 'Never'];
        this.includeValuationMetricsOptions = ['Always', 'For Items <> 0', 'Never'];
        this.breakOutTaxPaymentDetailsOptions = ['Always', 'For Items <> 0', 'Never'];
        this.showPercentChangeOptions = ['Yes, with details', 'Yes, no details', 'No'];
    }

    valueDetailsOptions: string[];
    includeAssessedValueInfoOptions: string[];
    includeTargetValueOptions: string[];
    includeValuationMetricsOptions: string[];
    breakOutTaxPaymentDetailsOptions: string[];
    showPercentChangeOptions: string[];
}
