<ng-template #contactItemTemplate let-model="item" let-index="index" let-query="query">
    <span [ngClass]="{ 'overridden': !isTopLevelCompany && (entityType === ReturnSettingsEntityTypeEnum.Return || model.model.contactID !== parentContact?.contactID) }">{{ model.displayName }} </span>
</ng-template>

<div *ngIf="viewMode === ViewModelEnum.ReturnValues">
    <span [ngClass]="{ 'overridden': contactHasOverride }">{{contactDisplay}}</span><span [ngClass]="{ 'overridden': representationHasOverride }">{{representationDisplay}}</span>
</div>
<div class="d-flex" *ngIf="viewMode === ViewModelEnum.Settings">
    <input
        name="contact"
        type="text"
        placeholder="Filter contacts..."
        class="form-control"
        autocomplete="off"
        [ngClass]="{ 'overridden': contactHasOverride }"
        [(ngModel)]="contactFilter"
        container="body"
        [typeahead]="contacts$"
        [typeaheadWaitMs]="750"
        typeaheadOptionField="displayName"
        [typeaheadMinLength]="0"
        [typeaheadScrollable]="true"
        [typeaheadOptionsLimit]="50"
        (blur)="onContactBlur()"
        (typeaheadOnSelect)="onContactSelected($event)"
        [disabled]="isReadonly"
        [typeaheadItemTemplate]="contactItemTemplate"/>
    <select
        name="preparerRepresentation"
        class="form-select form-select-sm"
        [ngClass]="{ 'overridden': representationHasOverride }"
        [(ngModel)]="representationId"
        [disabled]="isReadonly">
        <option *ngFor="let rt of representationTypes" [ngValue]="rt.value" [ngStyle]="{ 'color': !isTopLevelCompany && (entityType === ReturnSettingsEntityTypeEnum.Return || rt.value !== parentRepresentationId) ? 'red' : 'black'}">
            {{ rt.name }}
        </option>
    </select>
</div>
