import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from 'src/app/Compliance/WeissmanModalService';
import { Constants } from '../../constants.new';
import { Site } from '../../Entity/Site/Site.Model';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';
import { Address } from '../Models/common.model';

@Component({
    selector: 'google-maps-single-marker-modal',
    template: `

        <ws-modal-wrapper [headerLabel]="params.site.name"
            [hideSave]="true"
            cancelLabel="Close"
            cancelClass="btn-primary"
            (cancel)="close()">
                <div class="text-end">
                    <a [href]="siteMapUrl" (click)="bsModalRef.hide()">Explore more sites <i class="fa fa-arrow-right"></i></a>
                </div>
                <google-map [center]="coordinates" [zoom]="12" height="500px" width="100%">
                    <map-marker #markerElem="mapMarker" [position]="coordinates" [options]="options"></map-marker>
                    <map-info-window>
                        <address-view-edit [address]="siteAddress"></address-view-edit>
                    </map-info-window>
                </google-map>
        </ws-modal-wrapper>
    `
})
export class GoogleMapsSingleMarkerModalComponent implements OnInit, AfterViewInit, IWeissmanModalComponent<any, void> {
    constructor(public bsModalRef: BsModalRef,
        public constants: Constants,
        private navigationServiceHandler: UpgradeNavigationServiceHandler) {}

    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
    @ViewChild('markerElem') markerElem: MapMarker;

    params: {
        site: Site;
    };
    result: void;

    options: google.maps.MarkerOptions;
    coordinates: google.maps.LatLngLiteral;
    siteMapUrl: string;

    get siteAddress() {
        return this.params.site.address as unknown as Address;
    }

    ngOnInit() {
        this.options = {
            animation: google.maps.Animation.DROP,
            icon: `/images/google-maps-icons/${this._getIconName()}.png`
        };

        this.coordinates = {
            lat: this.params.site?.address.latitude,
            lng: this.params.site?.address.longitude
        };

        const params = { siteId: this.params.site.siteID, companyId: this.params.site.companyID};
        this.siteMapUrl = this.navigationServiceHandler.getHrefFromState('siteMap', params);
    }

    ngAfterViewInit(): void {
        this.infoWindow.open(this.markerElem);
    }

    close() {
        this.infoWindow.close();
        this.bsModalRef.hide();
    }

    private _getIconName() {
        if(this.params.site?.siteClassSecondary?.siteClassPrimary?.siteClassPrimaryID != undefined) {
            return this.constants.SiteClassIcons[this.params.site.siteClassSecondary.siteClassPrimary.siteClassPrimaryID];
        } else {
            return 'symbol_inter';
        }
    }

}
