import { Injectable } from '@angular/core';
import { AttachmentModalData, AttachmentModalEntityData } from '../../Attachment/attachment.modal.model';
import { CommentModalData } from '../../Comments/comments.service';
import { SDHttpService } from '../../Common/Routing/sd-http.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { Filing, FilingType } from './compliance.model';
import * as _ from 'lodash';

export class FilingViewDTO {
    annualYearView: AnnualDetailYear;
    filings: Filing[];
}

@Injectable()
export class ComplianceService {
    constructor(
        private http: SDHttpService,
        private taskService: TaskService) { }

    private _filingBatches: Compliance.FilingBatchInfoModel[] = [];

    getFiling(filingId: number): Promise<Compliance.FilingModel> {
        return this.http.get(`/api/filing/${filingId}`);
    }

    getFilingBatchInfo(filingBatchId: number): Promise<Compliance.FilingBatchModel> {
        return this.http.get(`/api/filingBatch/${filingBatchId}/info`);
    }

    getFilingBatchInfoForAnnualYear(annualYearId: number): Promise<Compliance.FilingBatchInfoModel[]> {
        return this.http.get(`/api/filingBatch/annualYear/${annualYearId}`);
    }

    getFilingsForAnnualYear(annualYearId: number): Promise<Filing[]> {
        return this.http.get(`/api/filing/annualYear/${annualYearId}`);
    }

    getAvailablePriorFilings(filingId: number,): Promise<Compliance.FilingModel[]> {
        return this.http.get(`/api/filing/${filingId}/priorreturn`);
    }

    createFilingBatch(updateModel: Compliance.FilingReturnUpdateModel): Promise<Compliance.FilingBatchModel> {
        return this.http.post('/api/filingBatch/fromFiling', updateModel);
    }

    createFiling(filing: Filing): Promise<Filing> {
        return this.http.post('/api/filing', filing);
    }

    updateFiling(filing: Filing): Promise<Filing> {
        return this.http.put('/api/filing', filing);
    }

    deleteFiling(filingId: number): Promise<void> {
        return this.http.delete(`/api/filing/${filingId}`);
    }

    getFilingView(filingId: number): Promise<FilingViewDTO> {
        return this.http.get(`/api/annualassessmentview/filing/${filingId}`);
    }

    getFilingTypes(): Promise<FilingType[]> {
        return this.http.get('/api/filingType');
    }

    getFilingTypesByParcel(parcelId: number): Promise<FilingType[]> {
        return this.http.get(`/api/filingtype/parcel/${parcelId}`);
    }

    getReturnForFiling(filingId: number): Promise<Compliance.ReturnModel[]> {
        return this.http.get(`/api/return/filing/${filingId}`);
    }

    //****************** End API Calls ******************
    isInFilingBatch(filing: Filing): boolean {
        return filing.filingBatchId && this._filingBatches.find(x => x.filingBatchId === filing.filingBatchId) ? true : false;
    }

    getFilingBatch(filing): Compliance.FilingBatchInfoModel {
        if (this._filingBatches &&
            this._filingBatches.length &&
            filing &&
            filing.filingBatchId) {
            const filingBatch = this._filingBatches.find(x => x.filingBatchId === filing.filingBatchId);
            return filingBatch;
        } else {
            return null;
        }
    }

    getTaskEntityType(filing: Filing): number {
        return this.isInFilingBatch(filing) ? Core.EntityTypes.FilingBatch : Core.EntityTypes.Filing;
    }

    getTaskEntityId(filing: Filing): number {
        return this.isInFilingBatch(filing) ? filing.filingBatchId : filing.filingId;
    }

    getReturnStatusCode(filing: Filing): string {
        if (!(this._filingBatches && this._filingBatches.length)) {
            return null;
        }

        if (!(filing && filing.filingBatchId)) {
            return null;
        }

        const filingBatch = this._filingBatches.find(x => x.filingBatchId === filing.filingBatchId);

        if (!filingBatch) {
            return null;
        }

        return filingBatch.statusCode;
    }

    setCurrentFiling(viewModel: ComplianceViewModel, filingId: number): void {
        const filingIndex = _.findIndex(viewModel.model, (filing) => {
            return filing.filingId === filingId;
        });

        if (filingIndex >= 0) {
            this.setFilingIndex(viewModel, filingIndex);
        }
    }

    async setFilingIndex(viewModel: ComplianceViewModel, filingIndex: number, force: boolean = false): Promise<void> {
        if (force || viewModel.currentFilingIndex !== filingIndex) {
            viewModel.currentFilingIndex = filingIndex;
            viewModel.currentFiling = viewModel.model[filingIndex];
            viewModel.resetEdit();
            viewModel.updateModalData();

            const [filingBatches, returns] = await Promise.all(
                [
                    this.getFilingBatchInfoForAnnualYear(viewModel.annualYearModel.annualYearID),
                    this.getReturnForFiling(viewModel.currentFiling.filingId)
                ]);

            this._filingBatches = filingBatches;

            if (returns && returns.length) {
                viewModel.currentReturn = returns[0];
            }

            await this.loadTaskSummary(viewModel);

            await this.loadFilingBatch(viewModel);
        }
    }

    addNewFilingToViewModel(viewModel: ComplianceViewModel, filing: Filing): Promise<Filing> {
        const originalModel = viewModel.model || [];
        viewModel.model = [];
        // HACK: For whatever reason, ngx-bootstrap's tab control didn't handle this
        // correctly without the setTimeout; this gives it time to "breathe" after resetting
        // the list
        return new Promise<Filing>(resolve => {
            setTimeout(() => {
                originalModel.unshift(filing);
                viewModel.model = originalModel;
                this.setFilingIndex(viewModel, 0, true);
                resolve(filing);
            }, 1);
        });
    }

    saveFilingFromViewModel(viewModel: ComplianceViewModel): Promise<Filing> {
        return this.updateFiling(viewModel.currentFiling);
    }

    cancelFilingEdit(viewModel: ComplianceViewModel): void {
        viewModel.cancelEdit();
    }

    toggleFilingEdit(viewModel: ComplianceViewModel, editMode: boolean): void {
        if (editMode) {
            viewModel.beginEdit();
        }
    }

    async loadTaskSummary(viewModel: ComplianceViewModel): Promise<void> {
        viewModel.currentTaskSummary = null;

        const entityId = this.getTaskEntityId(viewModel.currentFiling);
        const entityType = this.getTaskEntityType(viewModel.currentFiling);

        // get the task series from the filing batch if in a batch
        const taskSummary = await this.taskService.getTaskSummaryByEntity(entityId, entityType);

        // If we've navigated to another appeal by the time this happens, don't pay attention to the result
        const currentEntityId = this.getTaskEntityId(viewModel.currentFiling);

        if (entityId === currentEntityId) {
            viewModel.currentTaskSummary = taskSummary[0];
        }
    }

    async loadFilingBatch(viewModel: ComplianceViewModel): Promise<void> {
        viewModel.currentFiling.filingBatch = null;

        if (this.isInFilingBatch(viewModel.currentFiling)) {
            viewModel.currentFiling.filingBatch = await this.getFilingBatchInfo(viewModel.currentFiling.filingBatchId);
        }
    }

    //*************** ViewModel factories ***************
    async getComplianceViewModelByYear(annualYearModel: AnnualDetailYear, parcelId: number, masterParcelId: number, parcelLinkageTypeId: number, parcelAcctNum: string, consolidatingTypeId?: number, consolidatedParcelId?: number, defaultFilingId?: number): Promise<ComplianceViewModel> {
        const filings = await this.getFilingsForAnnualYear(annualYearModel.annualYearID);
        const filingTypes = await this.getFilingTypes();

        const viewModel = await this._buildComplianceViewModel(filings, filingTypes, annualYearModel, parcelId, masterParcelId, parcelLinkageTypeId, parcelAcctNum, consolidatingTypeId, consolidatedParcelId);
        viewModel.defaultFilingId = defaultFilingId;
        viewModel.showAllocationButton = true;

        this.setCurrentFiling(viewModel, viewModel.defaultFilingId);

        return viewModel;
    }

    async getComplianceViewModelByFilingId(filingId: number): Promise<ComplianceViewModel> {
        const filingView = await this.getFilingView(filingId);
        const filingTypes = await this.getFilingTypes();

        const viewModel = await this._buildComplianceViewModel(filingView.filings, filingTypes, filingView.annualYearView, null, null, null, null, null);
        viewModel.defaultFilingId = filingId;
        viewModel.showAllocationButton = false;

        this.setCurrentFiling(viewModel, filingId);
        viewModel.showTabs = false;

        return viewModel;
    }

    private async  _buildComplianceViewModel(filings: Filing[], filingTypes: FilingType[], annualYearModel: AnnualDetailYear, parcelID: number, masterParcelId: number, parcelLinkageTypeId: number, parcelAcctNum: string, consolidatingTypeId?: number, consolidatedParcelId?: number): Promise<ComplianceViewModel> {
        const viewModel = new ComplianceViewModel();
        viewModel.filingTypes = filingTypes;
        viewModel.annualYearModel = annualYearModel;
        viewModel.model = filings;
        viewModel.showTabs = true;
        viewModel.parcelID = parcelID;
        viewModel.masterParcelId = masterParcelId;
        viewModel.parcelLinkageTypeId = parcelLinkageTypeId;
        viewModel.consolidatingTypeId = consolidatingTypeId;
        viewModel.consolidatedParcelId = consolidatedParcelId;
        viewModel.parcelAcctNum = parcelAcctNum;

        if (viewModel.model.length < 1) {
            viewModel.currentFilingIndex = -1;
            viewModel.currentFiling = null;
        }
        else {
            await this.setFilingIndex(viewModel, 0);
        }

        return viewModel;
    }
    //************* End ViewModel factories *************
}

export class ComplianceViewModel {

    constructor() {
        this.hasWritePermission = false;
        this.dueDateValid = true;
        this.resetDateTimeHandler = null;
    }

    model: Filing[];
    defaultFilingId: number;
    annualYearModel: AnnualDetailYear;
    currentTaskSummary: any;
    currentFilingIndex: number;
    currentFiling: Filing;
    currentReturn: Compliance.ReturnModel;
    filingTypes: FilingType[] = [];
    parcelID: number;
    masterParcelId: number;
    parcelLinkageTypeId: number;
    consolidatingTypeId?: number;
    consolidatedParcelId?: number;
    parcelAcctNum: string;
    showTabs: boolean;
    commentModalData: CommentModalData;
    attachmentModalData: AttachmentModalData;
    hasWritePermission: boolean;
    dueDateValid: boolean;
    filingBatchId?: number;
    resetDateTimeHandler: () => void;
    showAllocationButton: boolean;
    private preEditModelBackup: Filing;

    resetEdit(force: boolean = false): void {
        if (force || (this.currentFiling &&
                this.preEditModelBackup &&
                this.currentFiling.filingId !== this.preEditModelBackup.filingId)) {
            this.preEditModelBackup = _.cloneDeep(this.currentFiling) as Filing;

            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
    }

    cancelEdit(): void {
        if (this.currentFiling.filingId === this.preEditModelBackup.filingId) {
            _.assign(this.currentFiling, this.preEditModelBackup);
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
        else {
            throw new Error(`Sanity check failed; attempted to restore backup with different filing ID than current. Current filing ID: ${
                this.currentFiling.filingId  }, backup filing ID: + ${  this.preEditModelBackup.filingId}`);
        }
    }

    beginEdit(): void {
        if (this.currentFiling) {
            this.preEditModelBackup = _.cloneDeep(this.currentFiling) as Filing;
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
        else {
            this.preEditModelBackup = undefined;
            if (this.resetDateTimeHandler) {
                this.resetDateTimeHandler();
            }
        }
    }

    assignFromExistingFiling(filing: Filing): void {
        if (!this.currentFiling) {
            throw new Error('Invalid attempt to load filing from existing data when no current filing is set');
        }

        if (this.currentFiling.filingId !== filing.filingId) {
            throw new Error(`Invalid attempt to filing filing with filingID ${filing.filingId} into filing with filingID ${this.currentFiling.filingId}`);
        }

        _.assign(this.currentFiling, filing);
        if (this.resetDateTimeHandler) {
            this.resetDateTimeHandler();
        }
    }

    setParcelDetails(parcelId: number, parcelAcctNum: string): void {
        this.parcelID = parcelId;
        this.parcelAcctNum = parcelAcctNum;
        this.updateModalData();
    }

    updateModalData(): void {
        const filing = this.currentFiling;

        if (!filing) {
            return;
        }

        const filingType = _.find(this.filingTypes, x => x.filingTypeId === filing.filingTypeId);
        const name = `${filing.description  } ${  filingType.name}`;
        const description = `${this.annualYearModel.annualYear1  } - ${  name}`;

        // comment data
        this.commentModalData = new CommentModalData();
        this.commentModalData.entityID = filing.filingId;
        this.commentModalData.entityTypeID = Core.EntityTypes.Filing;
        this.commentModalData.canEdit = this.hasWritePermission && !filing.isFromConsolidatedParcel;
        this.commentModalData.parcelID = this.parcelID;
        this.commentModalData.parcelAcctNum = this.parcelAcctNum;
        this.commentModalData.description = description;
        this.commentModalData.year = this.annualYearModel.annualYear1.toString();

        // attachment data
        this.attachmentModalData = new AttachmentModalData();
        this.attachmentModalData.belowParcelEntity = new AttachmentModalEntityData();
        this.attachmentModalData.belowParcelEntity.id = filing.filingId;
        this.attachmentModalData.belowParcelEntity.typeId = Core.EntityTypes.Filing;
        this.attachmentModalData.belowParcelEntity.name = name;
        this.attachmentModalData.entityType = 'Filing';
        this.attachmentModalData.parentId = this.parcelID;
        this.attachmentModalData.parentType = 'Parcel';
        this.attachmentModalData.entityName = this.parcelAcctNum;
        this.attachmentModalData.entityDescription = description;
        this.attachmentModalData.year = this.annualYearModel.annualYear1;
        this.attachmentModalData.readOnly = filing.isFromConsolidatedParcel;
    }

    validate(callback: (boolean, string) => void): void {
        const invalidTimes: string[] = [];

        // For now I believe this is only possible by entering a date but not a time
        // TODO: Write this in a less crappy way

        if(!_.trim(this.currentFiling.description)) {
            callback(false, 'Description is Required');
            return;
        }

        if (!this.dueDateValid) {
            invalidTimes.push('Due Date');
        }

        switch (invalidTimes.length) {
            case 0:
                callback(true, '');
                break;
            case 1:
                callback(false, `${invalidTimes[0]  } not valid`);
                break;
        }
    }
}
