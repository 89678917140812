import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../../Busy-Indicator';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../AgGrid';
import { AllocationRepository } from '../../../Repositories';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectTracker } from '../../../AgGrid/MultiSelectTracker';
import { lastValueFrom, Subscription } from 'rxjs';

export interface AddTaxAuthorityParams {
    allocationId: number,
    showWarning: boolean
}

export interface AddTaxAuthorityResult {
    newSummaries: Compliance.AllocationAssessmentSummaryModel[];
}

@Component({
    selector: 'add-tax-authority',
    templateUrl: './addTaxAuthority.component.html',
    styleUrls: ['./addTaxAuthority.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddTaxAuthorityComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AddTaxAuthorityParams, AddTaxAuthorityResult> {
    constructor(private readonly _modalRef: BsModalRef,
                private readonly _allocationRepository: AllocationRepository) {
    }

    params: AddTaxAuthorityParams;
    result: AddTaxAuthorityResult;

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    gridTracker: AgGridMultiSelectTracker;
    showWarning: boolean;

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged()
    })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withSort()
        .withTextSelection()
        .withFloatingFilter()
        .build();

    private _gridApi: GridApi;
    private _taxAuthorities: Compliance.TaxAuthorityModel[];
    private _selectedRowsSub: Subscription;
    private _selectedRows: number[] = [];


    async ngOnInit(): Promise<void> {
        const busyMsgId = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgId);
        this.showWarning = this.params.showWarning;

        try {
            this._taxAuthorities = await lastValueFrom(this._allocationRepository.getTaxAuthorities(this.params.allocationId));
            this._setRowData();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    ngOnDestroy(): void {
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe((selected: Compliance.SelectedRowsModel) => {
            this._selectedRows = (selected.selectAllRows) ? this._taxAuthorities.map(f => f.taxAuthorityId) : selected.selectedRows;
        });

        const columns: ColDef[] = [
            {
                field: 'taxAuthorityId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressMovable: true,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
            },
            {
                headerName: 'Collector',
                field: 'collector',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Name',
                field: 'name',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Code',
                field: 'code',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            }
        ];

        const defaultSortModel = [
            {
                colId: 'collector',
                sort: 'asc'
            },
            {
              colId: 'name',
              sort: 'asc'
            },
            {
                colId: 'code',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.sizeColumnsToFit();
        this._gridApi.setSortModel(defaultSortModel);
        this._setRowData();
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgId);

        try {
            const newAssessmentSummaries = await lastValueFrom(this._allocationRepository.addTaxAuthorities(this.params.allocationId, this._selectedRows));

            this.result = {newSummaries: newAssessmentSummaries} as AddTaxAuthorityResult;

            this._modalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._modalRef.hide();
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const taxAuthority = x.data as Compliance.TaxAuthorityModel;
                return taxAuthority && taxAuthority.taxAuthorityId;
            })
        };

        return Promise.resolve(queryResultModel);
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._taxAuthorities)) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();

        this._gridApi.setRowData(this._taxAuthorities);
    }
}
