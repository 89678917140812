import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../Account/user.service';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { DocumentExceptionActions, ProcessingExceptionStatus } from '../../../constants.new';
import { TeamService } from '../../../Team/team.service';
import { ProcessingService } from '../../processing.service.upgrade';

import * as _ from 'lodash';

export interface DocumentProcessingExceptionData {
    selectedRow?: any;
    selectedEntity?: any;
    dataEntryJSON?: string;
    newDocumentTypeID?: number;
    entityDetails?: any;
    taskIDs?: number[];
}

export interface DocumentProcessingExceptionModalParams {
    exceptionData: DocumentProcessingExceptionData;
    searchTimestamp: Date;
    optionalArgs: { parentCallsService: boolean, isTaxBill: boolean };
    instanceId: number
}

@Component({
    selector: 'app-document-processing-exception-modal',
    templateUrl: './documentProcessingExceptionModal.component.html'
})
export class DocumentProcessingExceptionModalComponent implements OnInit, IWeissmanModalComponent<DocumentProcessingExceptionModalParams, any> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _processingService: ProcessingService,
                private readonly _teamsService: TeamService,
                private readonly _userService: UserService,
                private readonly _toastr: ToastrService) {}

    params: DocumentProcessingExceptionModalParams;
    result;

    loading: boolean;
    taskIDs: number[];
    instanceId: number;
    commentBody: string;

    disableBounceRejected: boolean;
    disableClearException: boolean;
    hasExceptionStatus: boolean;
    multipleTasks: boolean;
    isTaxBill: boolean;
    showRequestTaxRateSetups: boolean;
    reassign: any = {};
    escalate: any = {};
    request: any = {};
    taxRateSetups: any = {};
    actionType: DocumentExceptionActions;
    DocumentExceptionActions = DocumentExceptionActions;
    rejectedByUser = null;
    contactUser = null;
    clientInstanceId = null;
    intakeItem = null;

    async ngOnInit(): Promise<void> {
        this.multipleTasks = !!this.params.exceptionData.taskIDs;

        if (this.multipleTasks) {
            this.taskIDs = this.params.exceptionData.taskIDs;
            this.loading = true;

            try {
                const extraData = await this._processingService.getBulkDocumentExceptionData(this.params.exceptionData.taskIDs);
                this.disableBounceRejected = !extraData.showBounceRejectedOption;
                this.disableClearException = !extraData.showClearExceptionOption;
                if (extraData.rejectorLastName) {
                    this.rejectedByUser = {
                        firstName: extraData.rejectorFirstName,
                        lastName: extraData.rejectorLastName
                    };
                }
                if (extraData.contactLastName) {
                    this.contactUser = {
                        firstName: extraData.contactFirstName,
                        lastName: extraData.contactLastName
                    };
                }
                this.clientInstanceId = extraData.clientInstanceId;

                if (this.clientInstanceId) {
                    this.escalate = {
                        userID: extraData.contactUserId,
                        teamID: extraData.contactTeamId
                    };
                }
            } finally {
                this.loading = false;
            }
        } else {
            this.intakeItem = this.params.exceptionData.selectedRow.intakeDocumentInfo;
            this.hasExceptionStatus = !!this.params.exceptionData.selectedRow.intakeDocumentInfo.intakeItem.exceptionStatusID;
            if (this.intakeItem &&
                this.intakeItem.intakeItem &&
                this.intakeItem.intakeItem.rejectedByUserID &&
                this.intakeItem.intakeItem.exceptionStatusID == ProcessingExceptionStatus.REJECTED) {
                const userTeams = await this._teamsService.getAssignableUserTeams(this.intakeItem.intakeItem.rejectedByUserID);
                this.rejectedByUser = userTeams.find(ut => ut.isDefault);
            }

            if (this.intakeItem && this.intakeItem.intakeBatch.clientInstanceId) {
                this.escalate = {
                    userID: this.intakeItem.contactUser.userID,
                    teamID: this.intakeItem.contactTeam.teamID
                };
            }

            this.clientInstanceId = this.intakeItem.intakeBatch.clientInstanceId;
        }

        this.isTaxBill = this.params.optionalArgs.isTaxBill;

        if (!this._userService.isRyanInstanceSelected() && this.clientInstanceId) {
            this.instanceId = this.clientInstanceId;
            this.escalate = {};
        }

        this.showRequestTaxRateSetups = this.isTaxBill && this._userService.isRyanInstanceSelected();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    async save() {
        if (this.params.optionalArgs.parentCallsService) {
            this.result = () => this.saveShared();
            this.cancel();
        } else {
            this.loading = true;
            // I'm pretty sure this.multipleTasks will always be falsey at this point since action views
            // now pass in parentCallsService; that said, we really should just refactor this mess so the
            // processing modal doesn't depend on parts of the grid
            if (this.multipleTasks) {
                const result = await this.saveShared();
                if (result) {
                    this.result = result;
                }
                this.cancel();
            } else {
                try {
                    await this.saveShared();
                    this._toastr.success('Item Updated', 'Item was successfully updated');
                    this.result = this.actionType;
                    this._bsModalRef.hide();
                } finally {
                    this.loading = false;
                }
            }
        }
    }

    isValid() {
        if (!this.actionType) {
            return false;
        } else if ((this.actionType === DocumentExceptionActions.REASSIGN_ITEM && !this.reassign.userID)
            || (this.actionType === DocumentExceptionActions.ESCALATE_ITEM && !(this.escalate.userID || this.intakeItem && this.intakeItem.clientInstanceId || this.clientInstanceId))
            || (this.actionType === DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS && !this.taxRateSetups.userID)
            || (this.actionType === DocumentExceptionActions.REQUEST_RESEARCH && !this.request.userID)) {
            return false;
        }

        return true;
    }

    createComment() {
        this.commentBody = '';

        switch(this.actionType) {
            case DocumentExceptionActions.REASSIGN_ITEM:
                if(this.reassign && !_.isEmpty(this.reassign.assignee)) {
                    this.commentBody = `Reassigned item to ${  this.reassign.assignee.firstName  } ${  this.reassign.assignee.lastName  }.`;
                }
                break;
            case DocumentExceptionActions.ESCALATE_ITEM:
                if(this.escalate && !_.isEmpty(this.escalate.assignee)) {
                    this.commentBody = `Escalated item to ${  this.escalate.assignee.firstName  } ${  this.escalate.assignee.lastName  }.`;
                }
                break;
            case DocumentExceptionActions.REQUEST_RESEARCH:
                if(this.request && !_.isEmpty(this.request.assignee)) {
                    this.commentBody = `Research requested from ${  this.request.assignee.firstName  } ${  this.request.assignee.lastName  }.`;
                }
                break;
            case DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS:
                if(this.taxRateSetups && !_.isEmpty(this.taxRateSetups.assignee)) {
                    this.commentBody = `Tax rate setups requested from ${  this.taxRateSetups.assignee.firstName  } ${  this.taxRateSetups.assignee.lastName  }.`;
                }
                break;
            case DocumentExceptionActions.REJECT_ITEM:
                if (this.multipleTasks) {
                    this.commentBody = `Rejected item to ${  this.contactUser ? (`${this.contactUser.firstName  } ${  this.contactUser.lastName}`) : 'contact user'  }.`;
                }
                else {
                    this.commentBody = `Rejected item to ${  this.intakeItem.contactUser.firstName  } ${  this.intakeItem.contactUser.lastName  }.`;
                }
                break;
            case DocumentExceptionActions.CLOSE_ITEM:
                this.commentBody = 'Closed item (added in error)';
                break;
        }
    }

    saveShared() {
        if (this.multipleTasks) {
            return this._processingService.processDocumentBulkException({
                taskIDs: this.taskIDs,
                documentExceptionAction: this.actionType,
                newAssignment: this.getNewAssignment(),
                commentBody: this.commentBody,
                searchTimestamp: this.params.searchTimestamp

            });
        } else {
            this.checkForChangesInParcel();

            const documentIntakeID = this.intakeItem.intakeItem.intakeItemID;
            const taskID = this.params.exceptionData.selectedRow.n10908;
            return this._processingService.processDocumentException(documentIntakeID, taskID, this.params.exceptionData.selectedEntity,
                this.actionType, this.getNewAssignment(), this.commentBody, this.params.exceptionData.newDocumentTypeID,
                this.params.exceptionData.dataEntryJSON,
                this.intakeItem.intakeItem.rowVersion, this.intakeItem.parcelChanges);
        }
    }

    checkForChangesInParcel(): void {
        if(!this.isOverridden('assesseeName') && !this.isOverridden('parcelAddress') && !this.isOverridden('collectorAcctNum')) {
            this.intakeItem.parcelChanges = null;
        }
    }

    isOverridden(field: string): boolean {
        if(!this.intakeItem.parcelChanges) {
            return false;
        }

        if(field === 'parcelAddress') {
            const addressFields = ['address1', 'address2', 'city', 'zip'];
            return !_.isEqual(_.pick(this.intakeItem.parcelChanges.parcelAddress, addressFields), _.pick(this.params.exceptionData.entityDetails.extendedData.parcelAddress, addressFields));
        } else {
            return !_.isEqual(this.intakeItem.parcelChanges[field], this.params.exceptionData.entityDetails.extendedData[field]);
        }
    }

    getNewAssignment() {
        switch(this.actionType) {
            case DocumentExceptionActions.REASSIGN_ITEM:
                return {
                    userID: this.reassign.userID,
                    teamID: this.reassign.teamID
                };
            case DocumentExceptionActions.ESCALATE_ITEM:
                return {
                    userID: this.escalate.userID,
                    teamID: this.escalate.teamID
                };
            case DocumentExceptionActions.REQUEST_RESEARCH:
                return {
                    userID: this.request.userID,
                    teamID: this.request.teamID
                };
            case DocumentExceptionActions.REQUEST_TAX_RATE_SETUPS:
                return {
                    userID: this.taxRateSetups.userID,
                    teamID: this.taxRateSetups.teamID
                };
            case DocumentExceptionActions.REJECT_ITEM:
                if(!this.multipleTasks) {
                    return {
                        userID: this.intakeItem.contactUser.userID,
                        teamID: this.intakeItem.contactTeam.teamID
                    };
                } else {
                    return null;
                }
            case DocumentExceptionActions.BOUNCE_REJECTED:
                if(!this.multipleTasks) {
                    return {
                        userID: this.rejectedByUser.userID,
                        teamID: this.rejectedByUser.teamID
                    };
                } else {
                    return null;
                }
            default:
                return null;
        }
    }
}
