<div class="modal-header">
    <h4 class="modal-title">
        Bill Cluster Tax Rate Details
        <span *ngIf="title"> - {{title}}</span>
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div *ngIf="!firstEncounterTaxAuthority" class="modal-body exclude-modal-styling tax-rate-setup">
    <div class="text-center loading-all" *ngIf="loadingAll">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loadingAll && model" class="row content-container">
        <div *ngIf="readOnly" class="top-actions">
            <span style="font-weight:bold;">Tax Rate Area:</span>
            <span>{{model.name || '(none)'}}</span>
        </div>
        <div *ngIf="!readOnly" class="top-actions">
            <label>Tax Rate Area:</label>
            <input
                #taxRateAreaInput
                name="taxRateArea"
                type="text"
                placeholder="Tax Rate Area..."
                class="form-control"
                autocomplete="off"
                [ngModel]="model.name"
                (blur)="onTaxRateAreaTextChange()"
                [disabled]="loading || model.usingParentRates"
                [typeahead]="availableTaxRateAreas"
                [typeaheadOptionsLimit]="100"
                typeaheadOptionField="name"
                [typeaheadScrollable]="true"
                [typeaheadMinLength]="0"
                (typeaheadOnSelect)="onTaxRateAreaIdChange($event)" />
            <div class="clear-tax-rate-container">
                <button
                    *ngIf="model.taxRateAreaId && !model.usingParentRates"
                    [disabled]="loading"
                    type="button"
                    class="edit-control btn-sm ace-btn has-icon btn-primary btn-danger"
                    (click)="clearSelectedTaxRateAreaId()"
                >
                    <i class="fa fa-close"></i>
                </button>
            </div>
            <!-- <button type="button" class="ace-btn info-button btn-sm search" [disabled]="loading || model.usingParentRates">Search</button> -->
        </div>
        <hr />
        <div class="table-container">
            <table class="tax-rate-setup">
                <tr>
                    <th>Code</th>
                    <th>Tax Authority</th>
                    <th>Category</th>
                    <th>Taxable Asmt Type</th>
                    <th class="right-align">Assessment</th>
                    <th class="right-align">Adjustment</th>
                    <th class="right-align">Taxable Asmt</th>
                    <th class="right-align" style="width:6em">Rate</th>
                    <th class="processing-action"></th>
                    <th class="right-align" style="width:10em">Annual Tax Amount</th>
                    <th></th>
                </tr>
                <ng-container *ngFor="let group of currentTaxAuthorityGroups; index as i">
                    <tr *ngIf="groupByCategory && i !== 0" class="grouping-spacer"><td colspan="11"></td></tr>
                    <tr *ngFor="let taxAuthority of group.taxAuthorities">
                        <td>{{taxAuthority.code}}</td>
                        <td>{{taxAuthority.name}}</td>
                        <td>{{taxAuthorityCategoryName(taxAuthority.taxAuthorityCategoryId)}}</td>
                        <td>{{taxableAssessmentTypeName(taxAuthority.taxableAssessmentTypeId)}}</td>
                        <td class="right-align">{{taxAuthority.assessedValue | number:"1.0-0"}}</td>
                        <td class="right-align">{{taxAuthority.assessedValueAdjustment | number:"1.0-0"}}</td>
                        <td class="right-align">{{taxAuthority.taxableAssessedValue | number:"1.0-0"}}</td>
                        <td [class]="'right-align ' + rateClass(taxAuthority)" [tooltip]="taTooltip(taxAuthority)" container="body">
                            {{taxAuthority.rate * 100 | number:"1.6-6"}}
                        </td>
                        <td
                            [class]="'processing-action ' + rateClass(taxAuthority)"
                            (click)="startFirstEncounter(taxAuthority)"
                            [tooltip]="showFirstEncounter(taxAuthority) ? 'Process tax authority first encounter' : taTooltip(taxAuthority)"
                            container="body"
                        >
                            <i class="fa fa-edit" style="margin-left:5px;cursor:pointer;" *ngIf="showFirstEncounter(taxAuthority)"></i>
                        </td>
                        <td [class]="'right-align ' + rateClass(taxAuthority)" [tooltip]="taTooltip(taxAuthority)" container="body">
                            ${{taxAuthority.annualTaxAmount | number:"1.2-2"}}
                        </td>
                        <td class="right-align">
                            <button
                                *ngIf="!readOnly && !model.taxRateAreaId && !model.usingParentRates"
                                [disabled]="loading"
                                type="button"
                                class="edit-control btn-sm ace-btn has-icon btn-primary btn-danger remove-authority"
                                (click)="removeTaxAuthority(taxAuthority)"
                            >
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                <tr class="summary-row no-hover">
                    <td colspan="5">
                        <select
                            *ngIf="!readOnly && model && !model.usingParentRates && !model.taxRateAreaId && filteredAvailableTaxAuthorities.length > 0"
                            [ngModel]="newTaxAuthorityId"
                            (ngModelChange)="onNewTaxAuthorityChange($event)"
                            [disabled]="loading"
                            class="form-select form-select-sm new-tax-auth"
                        >
                            <option value="">Add new tax authority</option>
                            <option *ngFor="let availableTaxAuthority of filteredAvailableTaxAuthorities" [value]="availableTaxAuthority.taxAuthorityId">
                                {{availableTaxAuthority.name}}
                            </option>
                        </select>
                        <span *ngIf="model && !model.taxRateAreaId && filteredAvailableTaxAuthorities.length <= 0">
                            (no more available tax authorities to add)
                        </span>
                    </td>
                    <ng-container *ngIf="model && model.taxAuthorities && model.taxAuthorities.length > 0">
                        <td colspan="2" class="total-label">Total Ad Valorem:</td>
                        <td [class]="'total-rate' + (totalEstimated ? ' estimated' : '')">{{model.totalTaxRate * 100 | number:"1.6-6"}}</td>
                        <td [class]="'total-rate processing-action' + (totalEstimated ? ' estimated' : '')"></td>
                        <td [class]="'total-rate' + (totalEstimated ? ' estimated' : '')">${{model.totalTaxAmount | number:"1.2-2"}}</td>
                        <td></td>
                    </ng-container>
                    <ng-container *ngIf="!(model && model.taxAuthorities && model.taxAuthorities.length > 0)">
                        <td colspan="6"></td>
                    </ng-container>
                </tr>
                <ng-container  *ngIf="model && model.taxAuthorities && model.taxAuthorities.length > 0">
                    <tr class="summary-row no-hover">
                        <td colspan="5"></td>
                        <td colspan="2" class="total-label">Direct Asmt:</td>
                        <td colspan="2"></td>
                        <td [class]="'right-align' + (billEstimated ? ' estimated' : '')" style="border-bottom:1px solid black">${{model.directAssessmentTaxAmount | number:"1.2-2"}}</td>
                        <td></td>
                    </tr>
                    <tr class="summary-spacer no-hover">
                        <td colspan="9"></td>
                        <td [class]="totalEstimated ? ' estimated' : ''"></td>
                        <td></td>
                    </tr>
                    <tr class="summary-row no-hover">
                        <td colspan="5"></td>
                        <td colspan="2" class="total-label">System Calculated Tax:</td>
                        <td colspan="2"></td>
                        <td [class]="'right-align' + (totalEstimated ? ' estimated' : '')">${{model.calculatedTaxAmount | number:"1.2-2"}}</td>
                        <td></td>
                    </tr>
                    <tr class="summary-row no-hover">
                        <td colspan="5"></td>
                        <td colspan="2" class="total-label">Total Bill Amount(s):</td>
                        <td colspan="2"></td>
                        <td [class]="'right-align' + (billEstimated ? ' estimated' : '')">${{model.totalBillTaxAmount | number:"1.2-2"}}</td>
                        <td></td>
                    </tr>
                    <tr class="summary-row no-hover">
                        <td colspan="5">
                        <td colspan="2" class="total-label">Variance:</td>
                        <td colspan="2"></td>
                        <td class="right-align">${{model.taxAmountVariance | number:"1.2-2"}}</td>
                        <td></td>
                    </tr>
                    <tr class="summary-row no-hover" style="height:1em;" *ngIf="model.otherBillTaxAmount">
                        <td colspan="11">
                    </tr>
                    <tr class="summary-row no-hover" *ngIf="model.otherBillTaxAmount">
                        <td colspan="11" class="right-align">
                            Note that ${{model.otherBillTaxAmount | number:"1.2-2"}} of the bill amount is from tax bills not displayed here
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
        <hr />
    </div>
</div>
<div *ngIf="!firstEncounterTaxAuthority" class="modal-footer tax-rate-setup">
    <div *ngIf="!loadingAll && model" class="group-by-category">
        <label>
            <input type="checkbox" [ngModel]="groupByCategory" (ngModelChange)="onGroupByCategoryChanged($event)" />
            Group by Category
        </label>
        <br />
        <label style="margin-bottom:0">
            <input type="checkbox" [(ngModel)]="model.usingParentRates" (ngModelChange)="onUsingParentRatesChanged($event)" [disabled]="readOnly"/>
            Sync to parcel's collector defaults
        </label>
    </div>
    <div *ngIf="!readOnly" class="action-buttons">
        <button class="ace-btn btn-primary" *ngIf="!isDocumentProcessing" [disabled]="loading || loadingAll" (click)="save()">Save</button>
        <button class="ace-btn btn-primary" *ngIf="isDocumentProcessing" [disabled]="loading || loadingAll" (click)="save()">OK</button>
        <button class="ace-btn btn-secondary" (click)="closeModal()">Cancel</button>
    </div>
    <div *ngIf="readOnly" class="action-buttons">
        <button class="ace-btn btn-secondary" (click)="closeModal()">Done</button>
    </div>
</div>
<first-encounter
    *ngIf="firstEncounterTaxAuthority"
    [taxAuthority]="firstEncounterTaxAuthority"
    [intakeAttachmentId]="intakeAttachmentId"
    [hasProcessingChanges]="calcProjected && hasProcessingChanges"
    [billId]="processingBill.billID"
    (hidden)="endFirstEncounter(false)"
    (saved)="endFirstEncounter(true, $event)"
></first-encounter>
