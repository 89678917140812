import { Component } from '@angular/core';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../AgGrid';
import { UpgradeNavigationServiceHandler } from '../../../../Common/Routing/upgrade-navigation-handler.service';
import {
    AgGridLinkCellRendererComponent,
    AgGridLinkCellRendererParams
} from '../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';

export interface AssetsInAnotherAllocationsParams {
    assets: Compliance.AllocationAssessmentsWithAssetsInAnotherAllocationsModel[]
}

@Component({
    selector: 'assets-in-another-allocations',
    templateUrl: './assetsInAnotherAllocations.component.html'
})
export class AssetsInAnotherAllocationsComponent implements IWeissmanModalComponent<AssetsInAnotherAllocationsParams, void> {
    constructor(private readonly _modalRef: BsModalRef,
                private readonly _routerService: UpgradeNavigationServiceHandler) {
    }

    params: AssetsInAnotherAllocationsParams;
    result: void;

    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .build();

    private _gridApi: GridApi;

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                field: 'currentParcel',
                headerName: 'Assessment',
                width: AgGridColumns.textColumnWidth
            },
            {
                field: 'assetNumber',
                headerName: 'Asset Number',
                width: AgGridColumns.textColumnWidth
            },
            {
                field: 'additionalSite',
                headerName: 'Add\'l Site Allocation',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                    newWindow: true,
                    isHelpDisabled: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.AllocationAssessmentsWithAssetsInAnotherAllocationsModel;
                        if (model) {
                            return this._routerService.getHrefFromState('site', {
                                companyId: model.additionalCompanyId,
                                siteId: model.additionalSiteId
                            });
                        }
                    }
                } as AgGridLinkCellRendererParams
            },
            {
                field: 'additionalParcel',
                headerName: 'Add\'l Assessment',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => '',
                    newWindow: true,
                    isHelpDisabled: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const model = params.data as Compliance.AllocationAssessmentsWithAssetsInAnotherAllocationsModel;
                        if (model) {
                            return this._routerService.getHrefFromState('parcel', {
                                companyId: model.additionalCompanyId,
                                siteId: model.additionalSiteId,
                                parcelId: model.additionalParcelId,
                                annualYearID: model.additionalAnnualYearId,
                                annualAssessmentID: model.additionalAnnualAssessmentId
                            });
                        }
                    }
                } as AgGridLinkCellRendererParams
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setRowData(this.params.assets);
        this._gridApi.sizeColumnsToFit();
    }

    async ok(): Promise<void> {
        this._modalRef.hide();
    }
}
