import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';
import { TaxAuthorityRateDetails, ParcelTaxRateService, FirstEncounterSaveResult } from './parcelTaxRateService';
import { ToastrService } from 'ngx-toastr';
import { MessageBoxService, MessageBoxResult, MessageBoxButtons } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { TimerService } from '../../../UI-Lib/Utilities/timer.service';

@Component({
    templateUrl: './firstEncounter.component.html',
    selector: 'first-encounter'
})
export class FirstEncounterComponent implements OnInit {
    constructor(
        private readonly _parcelTaxRateSevice: ParcelTaxRateService,
        private readonly _toastr: ToastrService,
        private readonly _msgBox: MessageBoxService,
        private readonly _timer: TimerService
    ) { }

    @Output() hidden = new EventEmitter();
    @Output() saved = new EventEmitter<FirstEncounterSaveResult>();
    @Input() billId: number;
    @Input() taxAuthority: TaxAuthorityRateDetails;
    @Input() intakeAttachmentId: string;
    @Input() hasProcessingChanges: boolean;
    @ViewChild('rateInput') rateInputRef: ElementRef;
    model: Core.FirstEncounterDTO;
    taxAuthorityDisplayName: string;
    displayRate: number;
    twoYearsAgoDisplayRate: number;
    lastYearDisplayRate: number;
    rateIncrease: number;
    lastYearDisplayRateIncrease: number;
    loading: boolean;
    displayAs: 'rate' | 'mills' = 'rate';

    async ngOnInit() {
        this.loading = true;
        try {
            this.model = await this._parcelTaxRateSevice.getFirstEncounterData(this.taxAuthority.taxAuthorityId, this.taxAuthority.taxYear);
            this.rateIncrease = this.model.rateIncrease ? (this.model.rateIncrease * 100) : null;
            this.lastYearDisplayRateIncrease = this.model.lastYearRateIncrease ? (this.model.lastYearRateIncrease * 100) : null;
            this.onModelChange();
        }
        finally {
            this.loading = false;
            this._timer.setTimeout(() => {
                this.rateInputRef.nativeElement.focus();
            });
        }
    }

    hide() {
        this.hidden.emit();
    }

    onModelChange() {
        this.taxAuthorityDisplayName = this.model.taxAuthorityName || '';
        if (this.model.taxAuthorityCode) {
            this.taxAuthorityDisplayName += ` ${  this.model.taxAuthorityCode}`;
        }

        const factor = this.displayAs === 'mills' ? 1000 : 100;
        this.twoYearsAgoDisplayRate = this.model.twoYearsAgoRate * factor;
        this.lastYearDisplayRate = this.model.lastYearRate * factor;
        this.displayRate = this.model.rate === null ? null : (this.model.rate * factor);
    }

    onRateChange($event: string) {
        if (isNaN(parseFloat($event))) {
            this.model.rate = null;
            this.rateIncrease = null;
        }
        else {
            const factor = this.displayAs === 'mills' ? 1000 : 100;
            this.model.rate = (+$event) / factor;
            this.rateIncrease = this.model.lastYearRate ? (((this.model.rate - this.model.lastYearRate) / this.model.lastYearRate) * 100) : null;
        }
    }

    async save() {
        // Check for falseyness, but allow 0 (probably null is all this will ever be, but if it's false or undefined or something don't continue)
        if (!this.model.rate && this.model.rate !== 0) {
            this._toastr.warning(`Please select a valid rate for ${  this.taxAuthority.taxYear}`);
            return;
        }

        if (await this._msgBox.open({
            buttons: MessageBoxButtons.YesNo,
            message: 'Are you sure you want to set this bill as the first encountered rate for this tax authority?',
            title: 'Confirm First Encounter'
        }) === MessageBoxResult.Yes) {
            this.loading = true;
            try {
                const result = await this._parcelTaxRateSevice.saveFirstEncounterData(
                    this.taxAuthority.taxAuthorityId,
                    this.taxAuthority.taxYear, {
                        rate: this.model.rate,
                        attachmentId: this.intakeAttachmentId,
                        trackBillId: this.billId
                    }
                );

                this._toastr.success('Tax rate saved');
                this.saved.emit(result);
            }
            finally {
                this.loading = false;
            }
        }
    }
}
