import {
    Component,
    ViewChild,
    OnChanges,
    Input,
    Output,
    ElementRef,
    AfterViewInit,
    SimpleChanges,
    EventEmitter,
    OnDestroy
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TimerService } from '../Utilities';

declare const $: any;
// TODO: Configure a proper type here (note that I attempted it and ran into trouble with moment-timezone; I'm sure
// it's solvable somehow. An example of getting the moment types out of bower_components would look something like:
// import * as moment from '../../../../bower_components/moment/moment';
declare const moment: any;
const DATE_INVALID_MESSAGE = 'Date is invalid or missing';
const TIME_INVALID_MESSAGE = 'Time is invalid or missing';
const MIN_DATE = moment('1990-01-01').utc().toDate();
const MAX_DATE = moment().utc().add(30, 'years').endOf('year').toDate();

export interface IDateRangeOptions {
    minDate?: Date;
    maxDate?: Date;
}

@Component({
    selector: 'weissman-datetime',
    templateUrl: './weissman-datetime.component.html',
    styles: [`
        .ng-invalid.ng-touched {
            border-color: var(--ace-danger);
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
        }
    `]
})
export class WeissmanDatetimeComponent implements AfterViewInit, OnChanges, OnDestroy {
    constructor(private readonly _toastr: ToastrService, private readonly _timer: TimerService) {
        this.builtInChange = new EventEmitter();
        this.inputDateChange = new EventEmitter();
        this.datePickerClosed = new EventEmitter();
        this.inputDateBlur = new EventEmitter();
        this.validationChange = new EventEmitter();
    }

    @Input() readOnly: boolean = false;
    @Input() dateOnly: boolean = false;
    @Input() rangeEnd: boolean = false;
    @Input() inputDate: Date;
    @Input() required: boolean = false;
    @Input() direction: string = 'down';
    @Input() isDisabled: boolean = false;
    @Input() isOverridden: boolean = false;
    @Input() className: string = '';
    @Input() helpContentId: string;
    @Input() excludeValidation: boolean = false;
    @Input() fillWidth: boolean = false;
    @Input() dateRangeOptions: IDateRangeOptions;
    /* This input is case-insensitive. Valid values are:
    Mangle (DEPRECATED - the "old" way of saving/retrieving date/time values, should probably use "Stored" now)
    UserLocal (meaning local the user's browser)
    Stored (default; implemented as UTC, but logically any time with no specified timezone; "the timezone is what the user thinks it is")
    UTC
    Central
    */
    @Input() timeZone: string = 'Stored';
    @Input() setAsTouched: boolean = false;
    @Input() setInvalid: boolean = false;
    @Output() validationChange: EventEmitter<string>;
    @Output() datePickerClosed: EventEmitter<any>;
    @Output() inputDateChange: EventEmitter<Date>;
    /*
     * Use with extreme caution
     * Binding to (change) in this manner triggers a doubling of events fired
     * first will be the date value selected followed by a native Event value
     * It is much safer to bind to the (inputDateChange)
     */
    @Output('change') builtInChange: EventEmitter<Date>;
    @Output() inputDateBlur: EventEmitter<Date>;
    @ViewChild('dtDateInput') dtDateInput: ElementRef;
    @ViewChild('dtTimeInput') dtTimeInput: ElementRef;

    displayDate: string;
    internalTime: string;
    isInvalid: boolean;


    private _dateInput;
    private _hasScrollListener: boolean;

    ngOnChanges(changes: SimpleChanges): void {
        const changedKeys = Object.keys(changes);
        // Look for changes to input properties
        if (changedKeys.indexOf('inputDate') >= 0) {
            this.setDate(this.inputDate);
        }
        if (changedKeys.indexOf('dateOnly') >= 0) {
            this.internalTime = this.rangeEnd ? '11:59 pm' : '12:00 am';
            this.updateDisplayDate();
        }
        if (changedKeys.indexOf('readOnly') >= 0) {
            // BLEEEECH! - Yeah, setTimeout is terrible here, but the DOM element for our input won't be available yet,
            // since we get the onChanges event before *ngIf is processed; by waiting until the app breathes, we ensure
            // that *ngIf attributes have been processed first, thereby ensuring that our DOM element is available
            this._timer.setTimeout(() => {
                this.initPicker();
            }, 0);
        }
    }

    ngAfterViewInit(): void {
        this.initPicker();
    }

    ngOnDestroy(): void {
        if (this._hasScrollListener) {
            document.removeEventListener('scroll', this._closeOnScroll.bind(this), true);
        }
    }

    initPicker(): void {
        if (this._hasScrollListener) {
            document.removeEventListener('scroll', this._closeOnScroll.bind(this), true);
            this._hasScrollListener = false;
        }
        if (this.dtDateInput) {
            if (!this.dateOnly && this.rangeEnd) {
                throw new Error('Invalid date picker configuration; the "rangeEnd" option is only valid for date-only fields');
            }
            const minDate = this.dateRangeOptions?.minDate || MIN_DATE;
            const maxDate = this.dateRangeOptions?.maxDate || MAX_DATE;

            this._dateInput = $(this.dtDateInput.nativeElement).find('.weiss-datepicker-input');

            const changeEvent = () => {
                const newDate = this.onUpdateDate();
                if(!this.excludeValidation && newDate && (newDate < minDate || newDate > maxDate)) {
                    this._toastr.error('Date entered is outside the valid range.');
                    this.setDate(this.inputDate);
                } else {
                    this.inputDate = newDate;
                }

                this.inputDateChange.emit(this.inputDate);
                this.builtInChange.emit(this.inputDate);
                this.updateDisplayDate();
            };

            // https://stackoverflow.com/a/15111307
            $('#ui-datepicker-div').click(function (event) {
                event.stopPropagation();
            });

            $(this._dateInput).datepicker({
                dateFormat: 'mm/dd/yy',
                changeYear: true,
                changeMonth: true,
                direction: this.direction,
                minDate: this.excludeValidation ? null : minDate,
                maxDate: this.excludeValidation ? null : maxDate,
                showAnim: 'fadeIn',
                onClose: (dateText, obj) => {
                    this.datePickerClosed.emit({dateText: dateText, obj: obj});
                }
            });

            this._dateInput.on('change', changeEvent);
            if (!this.dateOnly) {
                const timeInput = $(this.dtTimeInput.nativeElement);
                timeInput.on('change', changeEvent);
            }

            if(!this.excludeValidation && !this.readOnly) {
                if(moment(this.inputDate).utc().isBefore(minDate)) {
                    this.inputDate = minDate;
                    this.inputDateChange.emit(this.inputDate);
                    this._toastr.warning('Date from server is outside the valid range and has been adjusted.');
                }

                if(moment(this.inputDate).utc().isAfter(maxDate)) {
                    this.inputDate = maxDate;
                    this.inputDateChange.emit(this.inputDate);
                    this._toastr.warning('Date from server is outside the valid range and has been adjusted.');
                }
            }

            this.setDate(this.inputDate);
            document.addEventListener('scroll', this._closeOnScroll.bind(this), true);
            this._hasScrollListener = true;
        }
    }

    correctInputType(newInput: any): Date {
        // Note that this only handles the case where an input comes in as a string;
        // if it comes in as a moment or something we don't handle it. That shouldn't
        // happen anyway. Also note, this may not exacly be doing timezone mangling
        // as we expect; the cases I've seen so far all correctly send in Date types
        // not string types.
        if (typeof (newInput) === 'string') {
            return new Date(newInput as string);
        }

        return newInput as Date;
    }

    getAdjustedMoment(newInput: Date): any {
        if (!newInput) {
            return null;
        }

        let newMoment: any = moment(newInput);
        switch (this.timeZone.toLowerCase())
        {
            case 'mangle':
                newMoment.add(newInput.getTimezoneOffset(), 'minutes');
                break;
            case 'userlocal':
                newMoment = newMoment.local();
                break;
            case 'stored':
            case 'utc':
                newMoment = newMoment.utc();
                break;
            case 'central':
                newMoment = newMoment.tz('America/Chicago'); // IANA code for "Central" time
                break;
            default:
                throw new Error('Unrecognized time zone specification for date picker');
        }

        return newMoment;
    }

    // This is a utility function used by date range pickers that need to initialize to midnight
    static getMidnightMoment(inputDate: Date, rangeEnd: boolean, timeZone: string): any {
        let hour = 0;
        let minute = 0;

        if (rangeEnd) {
            hour = 23;
            minute = 59;
        }

        return WeissmanDatetimeComponent.buildMomentHelper(inputDate.getFullYear(),
            inputDate.getMonth(),
            inputDate.getDate(),
            hour,
            minute,
            timeZone);
    }

    static getMidnight(inputDate: Date, rangeEnd: boolean, timeZone: string): Date {
        return this.getMidnightMoment(inputDate, rangeEnd, timeZone).toDate();
    }

    buildMoment(year: number, month: number, day: number, hours: number, minutes: number): any {
        return WeissmanDatetimeComponent.buildMomentHelper(year, month, day, hours, minutes, this.timeZone);
    }

    setDate(newInput: Date): void {
        // If the input is invalid, this event will fire when we set the field value
        // back to null; don't do anything in that case
        if (newInput === null && this.isInvalid) {
            return;
        }
        newInput = this.correctInputType(newInput);
        if (this.dtDateInput) {
            const dateInput = $(this.dtDateInput.nativeElement).find('.weiss-datepicker-input');
            const timeInput = this.dtTimeInput ? $(this.dtTimeInput.nativeElement) : null;

            const newMoment: any = this.getAdjustedMoment(newInput);

            if (newMoment) {
                dateInput.datepicker('setDate', newMoment.format('MM/DD/YYYY'));
                if (this.dateOnly) {
                    // if (this.rangeEnd) {
                    //     timeInput.val('11:59 pm');
                    // }
                    // else {
                    //     timeInput.val('12:00 am');
                    // }
                }
                else {
                    timeInput.val(newMoment.format('h:mm a'));
                }
            }
            else {
                dateInput.datepicker('setDate', null);
                if (timeInput) {
                    timeInput.val(null);
                }
            }
        }
        this.updateDisplayDate();
    }

    updateDisplayDate(): void {
        const currentMoment = moment(this.inputDate).utc();
        if (this.inputDate && currentMoment && currentMoment.isValid()) {
            if (this.dateOnly) {
                this.displayDate = currentMoment.format('M/D/YYYY');
            }
            else {
                this.displayDate = currentMoment.format('M/D/YYYY h:mm a');
            }
            this.setValidationMessage(null);
        }
        else {
            this.displayDate = '';
            // We only want to set a validation message if one doesn't already exist due to some
            // other processing
            if (this.inputDate && !currentMoment.isValid() && !this.isInvalid) {
                console.log([currentMoment, !currentMoment.isValid(), !this.isInvalid]);
                this.setValidationMessage(DATE_INVALID_MESSAGE);
            }
        }
    }

    onUpdateDate(): Date {
        const dateInput = $(this.dtDateInput.nativeElement).find('.weiss-datepicker-input');
        const timeInput = !this.dateOnly ? $(this.dtTimeInput.nativeElement) : null;

        const rawDateValue: Date = dateInput.datepicker('getDate');

        if (!rawDateValue) {
            if (this.required) {
                this.setValidationMessage(DATE_INVALID_MESSAGE);
            }
            else {
                if (timeInput) {
                    timeInput.val(null);
                }
                this.setValidationMessage(null);
            }
            return null;
        }

        let hour: number = null;
        let minute: number;
        let ampm: string;
        if (this.dateOnly) {
            if (this.rangeEnd) {
                hour = 11;
                minute = 59;
                ampm = 'pm';
            }
            else {
                hour = 12;
                minute = 0;
                ampm = 'am';
            }
        }
        else {
            const rawTimeValue: string = timeInput.val();
            if (!rawTimeValue) {
                this.setValidationMessage(TIME_INVALID_MESSAGE);
                return null;
            }
            const timeMatch = /^\s*(\d\d?):(\d\d)\s*([ap]m?)?\s*$/i.exec(rawTimeValue);

            if (timeMatch) {
                hour = parseInt(timeMatch[1], 10);
                minute = parseInt(timeMatch[2], 10);
                ampm = timeMatch[3];
            }

            const altTimeMatch = /^\s*(\d{1,4})\s*([ap])?m?\s*$/i.exec(rawTimeValue);

            if (altTimeMatch) {
                // 1 or 2 digits is an hour with no minute
                if (altTimeMatch[1].length < 3) {
                    hour = parseInt(altTimeMatch[1], 10);
                    minute = 0;
                }
                // 3 or 4 digits is an hour with a 2-digit minute
                else {
                    hour = parseInt(altTimeMatch[1].substring(0, altTimeMatch[1].length - 2), 10);
                    minute = parseInt(altTimeMatch[1].substring(altTimeMatch[1].length - 2), 10);
                }
                ampm = altTimeMatch[2];
            }

            if (hour && !ampm) {
                // User entered 24-hour time (like 14:00 for 2:00 pm)
                if (hour > 12 && hour < 24) {
                    hour -= 12;
                    ampm = 'pm';
                }
                // The user entered 12 with no am/pm; assume pm
                else if (hour === 12) {
                    ampm = 'pm';
                }
                // Another 24-hour time example; entering 0:30 will be 12:30 am
                else if (hour === 0) {
                    hour = 12;
                    ampm = 'am';
                }
                // Greater than or equal to 6, assume am
                else if (hour >= 6) {
                    ampm = 'am';
                }
                // Less than 6, assume pm
                else {
                    ampm = 'pm';
                }
            }
            else if (ampm && ampm.length === 1) {
                ampm += 'm';
            }

            if (hour === null || isNaN(hour) || isNaN(minute) || hour < 1 || hour > 12 || minute < 0 || minute > 59) {
                this.setValidationMessage(TIME_INVALID_MESSAGE);
                return null;
            }
        }

        let hourValue: number;
        hourValue = hour;
        if (hourValue !== 12) {
            if (ampm.trim().toLowerCase() === 'pm') {
                hourValue += 12;
            }
        }
        else {
            if (ampm.trim().toLowerCase() === 'pm') {
                hourValue = 12;
            }
            else {
                hourValue = 0;
            }
        }

        const newMoment = this.buildMoment(
            rawDateValue.getFullYear(),
            rawDateValue.getMonth(),
            rawDateValue.getDate(),
            hourValue,
            minute
        );

        this.setValidationMessage(null);

        return newMoment.toDate();
    }

    openDatepicker(): void {
        if(this.isDisabled) {
            return;
        }

        const dateInput = $(this.dtDateInput.nativeElement).find('.weiss-datepicker-input');
        dateInput.datepicker('show');
    }

    onBlur() {
        this.inputDateBlur.emit(this.inputDate);
        this.setAsTouched = true;
    }

    // This is a workaround I'm not super-happy with; basically, once an invalid time is entered,
    // the InputDate property is set to null. Once that happens, it's impossible to tell if a subsequent
    // update to InputDate setting it to null is internal (set because of validation) or external (set
    // because for example an edit has been cancelled). So, any place that has a date/time control must
    // call resetValidation after setting the inputDate to clear any existing validation.
    resetValidation() {
        if (this.required && !this.inputDate) {
            this.setValidationMessage(DATE_INVALID_MESSAGE);
        }
        else {
            this.setValidationMessage(null);
        }
    }

    private _closeOnScroll(): void {
        if (this.dtDateInput) {
            const dateInput = $(this.dtDateInput.nativeElement).find('.weiss-datepicker-input');
            if (dateInput && dateInput.datepicker( 'widget' ).is(':visible')) {
                dateInput.datepicker({ showAnim: '' });
                dateInput.datepicker('hide');
            }
        }
    }

    private static buildMomentHelper(year: number, month: number, day: number, hours: number, minutes: number, timeZone: string): any {
        const newObj = { y: year, M: month, d: day, h: hours, m: minutes };
        let newMoment: any;

        switch (timeZone.toLowerCase())
        {
            // Note that I have not tested this code; it's sort of migrated from the old solution. Hopefully it never gets used.
            case 'mangle':
                newMoment = moment(newObj);
                newMoment.add(newMoment.utcOffset(), 'minutes');
                break;
            case 'userlocal':
                newMoment = moment(newObj);
                break;
            case 'stored':
            case 'utc':
                newMoment = moment.tz(newObj, 'UTC');
                break;
            case 'central':
                newMoment = moment.tz(newObj, 'America/Chicago'); // IANA code for "Central" time
                break;
            default:
                throw new Error('Unrecognized time zone specification for date picker');
        }

        return newMoment;
    }

    private setValidationMessage(message: string) {
        this.validationChange.emit(message);
        this.isInvalid = message !== null;
    }
}
