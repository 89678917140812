import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AdvancedSearchRepository } from '../../../Core-Repositories';

@Injectable()
export class AdvancedSearchListService {
    constructor(
        private readonly _advancedSearchRepository: AdvancedSearchRepository
    ) {}

    getBulkUpdateDescriptors(fieldCollection: number[]): Promise<Core.SmartBulkUpdateMetadataModel> {
        const updateModel: Core.SmartBulkUpdateModel = {
            advancedSearchFieldIds: fieldCollection,
            descriptorValues: []
        };
        return lastValueFrom(this._advancedSearchRepository.getBulkUpdateDescriptors(updateModel));
    }

    async bulkUpdateList(updateModel: Core.SmartBulkUpdateModel): Promise<Core.SmartBulkUpdateResultModel> {
        return lastValueFrom(this._advancedSearchRepository.bulkUpdateList(updateModel));
    }
}
