import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent, RowNode } from 'ag-grid-community';
import { BehaviorSubject, Subscription, Subject, lastValueFrom } from 'rxjs';
import { BusyIndicatorService } from '../../../../Busy-Indicator';
import { FilingBatchRepository } from '../../../Repositories';
import { FilingBatchAccountsGridActionCellRendererComponent, FilingBatchAccountsGridActionCellRendererParams } from './filingBatchAccountsAgGridActionCellRenderer.component'
import { WeissmanModalService } from '../../../WeissmanModalService';
import { FilingBatchAddAccountsComponent, FilingBatchAddAccountsParams } from '../Filing-Batch-Add-Accounts/filingBatchAddAccounts.component';
import { FilingBatchService } from '../../filingBatch.service';
import { AgGridOptionsBuilder, AgGridFilterParams, AgGridColumns } from '../../../AgGrid';
import { WeissmanMutexService, IMutexServiceHandler } from '../../../WeissmanMutexService';
import { AgGridLinkCellRendererComponent } from '../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { AgGridLinkCellRendererParams } from '../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { FilingDetailsParams, FilingDetailsComponent } from '../Filing-Details/filingDetails.component';
import { MessageModalService } from '../../../../UI-Lib/Message-Box/messageModal.service';
import { BaseExpandableAgGridComponent } from '../../../../UI-Lib/Expandable-Component/baseExpandableAgGridComponent';
import { FilingBatchMoveModalComponent, FilingBatchMoveModalParams } from '../Filing-Batch-Move-Modal/filingBatchMoveModal.component';
import { AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectedCellRenderer, AgGridMultiSelectTracker } from '../../../AgGrid/MultiSelectTracker';
import { WeissmanDateFormatPipe } from '../../../../UI-Lib/Pipes';
import { FilingBatchMoveResultModalComponent, FilingBatchMoveResultModalParams } from '../Filing-Batch-Move-Result-Modal/filingBatchMoveResultModal';
import { FILING_BATCH_ACCOUNTS_HELP } from './filingBatchAccounts.component.help';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { FilingBatchDetailsService, FilingRowModel } from '../filingBatchDetails.service';
import { ProductAnalyticsService } from "../../../../Common/Amplitude/productAnalytics.service";

import * as _ from 'lodash';
import {
    AgGridYesNoFloatingFilterComponent
} from '../../../AgGrid/FloatingFilters/agGridYesNoFloatingFilter.component';

export interface FilingsChangeModel {
    count: number;
    hasPriorReturns: boolean;
}

@Component({
    selector: 'filing-batch-accounts',
    templateUrl: './filingBatchAccounts.component.html',
    styleUrls: ['./filingBatchAccounts.component.scss']
})
export class FilingBatchAccountsComponent extends BaseExpandableAgGridComponent implements OnInit, OnDestroy, IMutexServiceHandler {
    constructor(
        private readonly _busyService: BusyIndicatorService,
        private readonly _filingBatchRepository: FilingBatchRepository,
        private readonly _modalService: WeissmanModalService,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _mutexService: WeissmanMutexService,
        private readonly _messageModalService: MessageModalService,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _helpService: HelpService,
        private readonly _filingDetailsService: FilingBatchDetailsService,
        private readonly _productAnalyticsService: ProductAnalyticsService
    ) {
        super(_filingBatchService, 'filing-batch-accounts');
    }

    @Input() filingBatch: Compliance.FilingBatchModel;
    @Input() canEdit: boolean = false;
    @Input() refresh$: Subject<void>;
    @Output() filingsChanged = new EventEmitter<FilingsChangeModel>();

    private _filings: FilingRowModel[];
    private _gridApi: GridApi;
    private _editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _editModeSub: Subscription;
    private _selectedRowsSub: Subscription;
    private _refreshSub: Subscription;
    private _availableFilingCount = 0;

    selectedRows: number[] = [];
    availableFilingCount: number = 0;
    editMode: boolean;
    isBusy: boolean = false;
    isAvailableBusy: boolean = false;
    hasChanges: boolean = false;
    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = '9C994FA8-E715-4D48-A447-529643046BBC';
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    get companyId(): number {
        return this.filingBatch && this.filingBatch.companyId;
    }

    get canEnterEditMode(): boolean {
        return this._mutexService.canAcquire(this._filingBatchService.editGroup);
    }

    get editHelpContentId(): string {
        if (!this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'filing-batch-accounts.edit';
    }

    get moveHelpContentId(): string {
        if (this.editMode || !this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        if (!(this.selectedRows && this.selectedRows.length)) {
            return 'filing-batch-accounts.move-no-accounts';
        }

        return 'filing-batch-accounts.move';
    }

    get addHelpContentId(): string {
        if (this.editMode || !this.canEnterEditMode) {
            return 'app.disabled-edit-mode';
        }

        return 'filing-batch-accounts.add';
    }

    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as FilingRowModel).filingId),
                'grid-row-deleted': (params) => params.data && (params.data as FilingRowModel).deleted
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .withColumnPinning()
        .build();

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(FILING_BATCH_ACCOUNTS_HELP);

        this._editModeSub = this._editModeSubject.asObservable().subscribe(x => {
            this.editMode = x;
            if (!this.editMode) {
                this._mutexService.release(this, this._filingBatchService.editGroup);
            }
        });

        this._refreshSub = this.refresh$.asObservable().subscribe(async () => {
            await this._refresh();
        });
    }

    ngOnDestroy(): void {
        this._editModeSub && this._editModeSub.unsubscribe();
        this._selectedRowsSub && this._selectedRowsSub.unsubscribe();
        this._refreshSub && this._refreshSub.unsubscribe();
        this._mutexService.release(this, this._filingBatchService.editGroup);
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;
        super.setGridApi(event.api);

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this._selectedRowsSub = this.gridTracker.selectedRows$.subscribe((selected: Compliance.SelectedRowsModel) => {
            this.selectedRows = (selected.selectAllRows)
                ? this._filings.map(f => f.filingId).filter(x => !selected.selectedRows.includes(x))
                : selected.selectedRows;
            this.isBulkDeleteVisible$.next(this.selectedRows.length > 0 && this.editMode);
        });

        const columns: ColDef[] = [
            {
                field: 'filingId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                hide: !this.canEdit
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/assessor/${filing.assessorId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Filing',
                field: 'filingDescription',
                lockVisible: true,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/filing/${filing.filingId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/company/${filing.companyId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/site/${filing.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/site/${filing.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Parcel',
                field: 'parcelAcctNumberDisplay',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/parcel/${filing.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: params => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }

                    return filing.parcelAcctNumberDisplay;
                }
            },
            {
                headerName: 'Due Date',
                field: 'dueDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }

                    return this._datePipe.transform(filing.dueDate, true);
                },
                filterValueGetter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }

                    return this._datePipe.transform(filing.dueDate, true);
                }
            },
            {
                headerName: 'Prior Return',
                field: 'priorReturnDescription',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const filing = x.data as FilingRowModel;
                    return (filing && filing.priorReturnId === filing.calculatedPriorReturnId) ? null : 'ws-override';
                },
                valueFormatter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }
                    return filing.priorReturnDescription;
                },
                filterValueGetter: params => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }
                    return filing.priorReturnDescription;
                },
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-return',
                    newWindow: true,
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        return !filing.priorFilingBatchId;
                    },
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/returnBatch/${filing.priorFilingBatchId}`;
                    }
                } as AgGridLinkCellRendererParams,
            },
            {
                headerName: 'Asset Source',
                field: 'assetSource',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Started',
                field: 'isStarted',
                width: AgGridColumns.textColumnExtraSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }
                    return filing.isStarted ? 'Yes': 'No';
                },
                filterValueGetter: params => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }
                    return filing.isStarted ? 'Yes' : 'No';
                }
            },
            {
                headerName: 'Is Amended',
                field: 'isAmendment',
                width: AgGridColumns.textColumnSmallWidth,
                hide: true,
                filter: 'agYesNoColumnFilter',
                filterParams: AgGridFilterParams.yesNoFilterParams,
                floatingFilterComponentFramework: AgGridYesNoFloatingFilterComponent,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }
                    return filing.isAmendment ? 'Yes': 'No';
                }
            },
            {
                headerName: '',
                field: 'actions',
                pinned: 'right',
                width: AgGridColumns.getActionColumnWidth(2),
                minWidth: AgGridColumns.getActionColumnWidth(2),
                maxWidth: AgGridColumns.getActionColumnWidth(2),
                suppressSizeToFit: true,
                suppressAutoSize: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                lockPinned: true,
                sortable: false,
                cellRendererFramework: FilingBatchAccountsGridActionCellRendererComponent,
                cellRendererParams: {
                    remove: this.remove.bind(this),
                    undoRemove: this.undoRemove.bind(this),
                    edit: this._edit.bind(this),
                    editMode$: this._editModeSubject.asObservable(),
                    canEdit: (params: FilingBatchAccountsGridActionCellRendererParams) => {
                        const filing = params.node.data as FilingRowModel;
                        return this.canEdit && this.editMode && filing && !filing.deleted;
                    },
                    canRemove: (params: FilingBatchAccountsGridActionCellRendererParams) => {
                        const filing = params.node.data as FilingRowModel;
                        return this.canEdit && this.editMode && filing && !filing.deleted;
                    },
                    canUndoRemove: (params: FilingBatchAccountsGridActionCellRendererParams) => {
                        const filing = params.node.data as FilingRowModel;
                        return this.canEdit && this.editMode && filing && filing.deleted;
                    }
                } as FilingBatchAccountsGridActionCellRendererParams,
                hide: !this.canEdit
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessorName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this._refresh();
    }

    async moveFilings(): Promise<void> {
        const availableTargets = await this._filingBatchService.getListByAvailableToMoveFilings(this.selectedRows);
        const params: FilingBatchMoveModalParams = {
            availableTargets,
            filingIds: this.selectedRows,
            batchInfo: this.filingBatch
        };

        const result = await this._modalService.showAsync(FilingBatchMoveModalComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const moveResultParams: FilingBatchMoveResultModalParams = {
            targetFilingBatch: result,
            moveCount: params.filingIds.length
        };

        const moveResult = await this._modalService.showAsync(FilingBatchMoveResultModalComponent, moveResultParams, 'modal-md');

        if (!moveResult) {
            return Promise.resolve();
        }

        await this._filingBatchService.notifyFilingBatchFilingsChange(this.filingBatch.filingBatchId);
        await this._refresh();

        return Promise.resolve();
    }

    edit(): void {
        if (!this.canEdit) {
            return;
        }

        this._productAnalyticsService.logEvent('click-edit-filings');

        this._mutexService.acquire(this, this._filingBatchService.editGroup);
        this._editModeSubject.next(true);
        this.isBulkDeleteVisible$.next(this.selectedRows.length > 0 && this.editMode);
    }

    async save(): Promise<void> {
        if (!this.hasChanges) {
            return Promise.resolve();
        }

        if (this._filings.find((x) => x.changed && x.priorReturnId !== x.originalValue.priorReturnId)) {
            try {
                await this._messageModalService.confirm(`When the prior return is changed, any form overrides for the changed return will be removed and replaced with the overrides from the prior return form.${this.filingBatch.getPriorReturnFromAssetList ? '  Additionally, this will alter the change detection from Company Assets to Prior Return.' : ''}\n\n Are you sure you wish to continue?`, 'Confirm');
            }
            catch (e) {
                return Promise.resolve();
            }
        }

        const updateModel: Compliance.FilingBatchFilingsUpdateModel = {
            batchRowVersion: this.filingBatch.rowVersion,
            filings: []
        };

        this._gridApi.forEachNode((node: RowNode) => {
            const filingRow = node.data as FilingRowModel;
            if (!filingRow.deleted) {
                const filingReturnUpdateModel: Compliance.FilingReturnUpdateModel = {
                    filingId: filingRow.filingId,
                    priorReturnId: filingRow.priorReturnId,
                    isAmendment: filingRow.isAmendment
                };

                updateModel.filings.push(filingReturnUpdateModel);
            }
        });

        const busyRef = this._busyService.show({ message: 'Saving' });

        try {
            await lastValueFrom(this._filingBatchRepository.updateFilings(this.filingBatch.filingBatchId, updateModel));

            await this._filingBatchService.notifyFilingBatchFilingsChange(this.filingBatch.filingBatchId);

            this._editModeSubject.next(false);

            await this._refresh();
        }
        finally {
            this._productAnalyticsService.logEvent('click-filings-save-changes');

            busyRef.hide();
        }

        return Promise.resolve();
    }

    cancel(): void {
        // if added but not saved, remove it
        _.remove(this._filings, filing => filing.added);

        this._filings.forEach(filing => {
            filing.annualYearId = filing.originalValue.annualYearId;
            filing.priorReturnDescription = filing.originalValue.priorReturnDescription;
            filing.priorReturnId = filing.originalValue.priorReturnId;
            filing.changed = false;
            filing.deleted = false;
        });

        this.availableFilingCount = this._availableFilingCount;

        this._editModeSubject.next(false);

        this._setRowData();

        this.hasChanges = false;
    }

    async add(): Promise<void> {
        let enteredEditMode = false;

        if (!this.editMode) {
            this.edit();
            enteredEditMode = true;
        }

        const params: FilingBatchAddAccountsParams = {
            filingBatchId: this.filingBatch.filingBatchId,
            companyId: this.filingBatch.companyId,
            addedFilingIds: this._filings.filter((x) => x.added).map((x) => x.filingId)
        };

        const result = await this._modalService.showAsync(FilingBatchAddAccountsComponent, params, 'modal-xl');

        if (!result) {
            if (enteredEditMode) {
                this.cancel();
            }

            return Promise.resolve();
        }

        this.isBusy = true;
        this._gridApi && this._gridApi.showLoadingOverlay();

        try {
            // because the add modal uses infinite scroll and multi-select tracker
            //  get the new filings
            const filingIdsToGet = result.selectedRowIds.filter((selectedId) => !this._filings.find((y) => y.filingId === selectedId));

            if (!filingIdsToGet.length) {
                return Promise.resolve();
            }

            const filings = await lastValueFrom(this._filingBatchRepository.getListByFilingIds(filingIdsToGet));
            const newFilings = filings.map((x: Compliance.FilingModel) => this._getFilingRowModel(x, true));
            this._filings.push.apply(this._filings, newFilings);

            this.availableFilingCount = this.availableFilingCount - filings.length;
            if (this.availableFilingCount < 0) {
                this.availableFilingCount = 0;
            }

            this._setRowData();

            this.hasChanges = true;
        } finally {
            this._productAnalyticsService.logEvent('click-add-filings-ok', {
                numberOfFilingsAdded: result.selectedRowIds.length
            });
            this._gridApi && this._gridApi.hideOverlay();
            this.isBusy = false;
        }

        return Promise.resolve();
    }

    remove(params: FilingBatchAccountsGridActionCellRendererParams): void {
        const filing = params.data as FilingRowModel;
        if (!filing) {
            return;
        }

        if (!filing.added) {
            filing.deleted = true;
            this._gridApi.redrawRows({ rowNodes: [params.node] });
        } else {
            this._removeAddedFiling(filing);
        }

        this._productAnalyticsService.logEvent('click-remove-filings', {
            numberOfFilingsRemoved: 1
        });

        this.hasChanges = true;
    }

    bulkDelete(): void {
        this._gridApi.forEachNode((node: RowNode) => {
            const filingRow = node.data as FilingRowModel;
            if (this.selectedRows.find(x => filingRow.filingId === x)) {
                if (!filingRow.added) {
                    filingRow.deleted = true;
                    this._gridApi.redrawRows({ rowNodes: [node] });
                } else {
                    this._removeAddedFiling(filingRow);
                }
            }
        });

        this._productAnalyticsService.logEvent('click-remove-filings', {
            numberOfFilingsRemoved: this.selectedRows.length
        });

        this.gridTracker.clear();
        this.hasChanges = true;
    }

    undoRemove(params: FilingBatchAccountsGridActionCellRendererParams): void {
        const filing = params.data as FilingRowModel;
        if (!filing) {
            return;
        }

        filing.deleted = false;

        this._gridApi.redrawRows({ rowNodes: [params.node] });
    }

    wsMutexRelease(groupId: string): Promise<void> {
        return Promise.resolve();
    }

    private _removeAddedFiling(filing: FilingRowModel): void {
        const index = this._filings.findIndex((x) => x.filingId === filing.filingId);
        this._filings.splice(index, 1);
        this._gridApi.updateRowData({ remove: [filing] });
        this.availableFilingCount++;
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const filing = x.data as FilingRowModel;
                return filing && filing.filingId;
            })
        };

        return Promise.resolve(queryResultModel);
    }

    private async _edit(params: FilingBatchAccountsGridActionCellRendererParams): Promise<void> {
        const filing = params.node.data as FilingRowModel;
        if (!filing) {
            return Promise.resolve();
        }

        const inputParams: FilingDetailsParams = {
            filingId: filing.filingId,
            priorReturnId: filing.priorReturnId,
            calculatedPriorReturnId: filing.calculatedPriorReturnId,
            isAmendment: filing.isAmendment
        };

        const result = await this._modalService.showAsync(FilingDetailsComponent, inputParams, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        filing.priorReturnId = result.priorReturnId;
        filing.priorReturnDescription = result.priorReturnDescription;
        filing.isAmendment = result.isAmendment;
        filing.changed = true;

        let analyticsProperty = { priorReturn: 'none', amendingPriorReturn: result.isAmendment };
        if (result.priorReturnId) {
            analyticsProperty.priorReturn = result.priorReturnDescription;
        }

        this._productAnalyticsService.logEvent('click-edit-prior-return-OK', analyticsProperty);

        this._gridApi.redrawRows({ rowNodes: [params.node] });

        this.hasChanges = true;

        return Promise.resolve();
    }

    private async _refresh(): Promise<void> {
        if (!this.filingBatch) {
            return;
        }

        this.isBusy = true;
        this._gridApi && this._gridApi.showLoadingOverlay();

        try {
            const searchParams: Compliance.FilingSearchModel = {
                filingIdsToExclude: []
            };

            const result = await lastValueFrom(this._filingBatchRepository.getListByBatch(this.filingBatch.filingBatchId, searchParams));

            this._filings = result.data.map((x: Compliance.FilingModel) => this._getFilingRowModel(x));
            this._filingDetailsService.currentFilings = this._filings;

            const changeModel: FilingsChangeModel = {
                count: this._filings.length,
                hasPriorReturns: this._filings.some(x => x.priorReturnId !== null)
            };

            this.filingsChanged.emit(changeModel);

            this._setRowData();
            this.hasChanges = false;
            this.isBusy = false;

            if (this.canEdit) {
                await this._refreshAvailableFilingCount();
            }

        } finally {
            this._gridApi && this._gridApi.hideOverlay();
            this.isBusy = false;
        }
    }

    private _getFilingRowModel(filing: Compliance.FilingModel, added: boolean = false): FilingRowModel {
        return {
            ...filing,
            ...{
                priorReturnDescription: added ? filing.calculatedPriorReturnDescription : filing.priorReturnDescription,
                priorReturnId: added ? filing.calculatedPriorReturnId : filing.priorReturnId,
                changed: false,
                deleted: false,
                added: added,
                originalValue: filing
            }
        } as FilingRowModel;
    }

    private async _refreshAvailableFilingCount(): Promise<void> {
        this.isAvailableBusy = true;
        try {
            const addedFilingIds = this._filings.filter((x) => x.added).map((x) => x.filingId);
            this.availableFilingCount = await lastValueFrom(this._filingBatchRepository.getCountByBatchAvailable(this.filingBatch.filingBatchId, addedFilingIds));
            this._availableFilingCount = this.availableFilingCount;
        } finally {
            this.isAvailableBusy = false;
        }
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._filings)) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();

        this._gridApi.setRowData(this._filings);
    }
}
