import { Component, OnInit } from '@angular/core';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DatabaseRepository } from '../database.repository';
import { lastValueFrom } from 'rxjs';
import { MessageBoxButtons, MessageBoxOptions, MessageBoxResult, MessageBoxService } from '../../../UI-Lib/Message-Box/messagebox.service.upgrade';

@Component({
    selector: 'database-clear-cache-entry',
    templateUrl: './databaseClearCacheEntry.component.html',
    styleUrls: ['./databaseClearCacheEntry.component.scss']
})
export class DatabaseClearCacheEntryComponent implements IWeissmanModalComponent<void, void>, OnInit {
    constructor(
        private _bsModalRef : BsModalRef,
        private _databaseRepository: DatabaseRepository,
        private _messageBoxService: MessageBoxService
    ) {
    }
    params: void;
    result: void;
    objectName: string;
    planEntries: Core.PlanCacheModel[];
    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();

    ngOnInit(): void {
        this.planEntries = [];
    }

    close(): void {
        this._bsModalRef.hide();
    }

    async searchObject(): Promise<void> {
        const busyMessage = 'Loading';

        this.busyIndicatorMessageManager.add('Loading', busyMessage);

        try {
            this.planEntries = await lastValueFrom(this._databaseRepository.searchCachePlan(this.objectName));
        }
        finally {
            this.busyIndicatorMessageManager.remove(busyMessage);
        }
    }

    async clearPlanEntry(entry: Core.PlanCacheModel) {
        const confirmOptions = {
            message: 'Are you sure you wish to clear this cache entry?',
            buttons: MessageBoxButtons.YesNo
        } as MessageBoxOptions;

        const result = await this._messageBoxService.open(confirmOptions);

        if (result === MessageBoxResult.Yes) {
            const busyMessage = 'Clearing cache';

            this.busyIndicatorMessageManager.add('Clearing cache', busyMessage);

            try {
                await lastValueFrom(this._databaseRepository.clearCacheEntries([entry.planHandle]));
                this.planEntries = await lastValueFrom(this._databaseRepository.searchCachePlan(this.objectName));
            }
            finally {
                this.busyIndicatorMessageManager.remove(busyMessage);
            }
        }
    }
}
