import { Component } from '@angular/core';
import { IWeissmanModalComponent } from '../../../../Compliance/WeissmanModalService';
import { ContactsUpgradeService } from '../../../contacts.service.upgrade';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserInstanceService } from '../../../../User/userInstance.service';
import { UpgradeNavigationServiceHandler } from '../../../../Common/Routing/upgrade-navigation-handler.service';
import { ContactRoleService } from '../../../../Role/contact.role.service';
import { ContactModalOrigin } from '../../../../constants.new';
import { ContactModalService } from '../../../contactModal.service';

export interface ContactListModalParams {
    recipientMode?: boolean;
    entityInfo?: any;
    notAllowedRoles?: any[];
}

@Component({
    selector: 'entity-contact-list-modal',
    templateUrl: './contactListModal.component.html'
})
export class ContactListModalComponent implements IWeissmanModalComponent<ContactListModalParams, any> {
    constructor(
        private readonly _contactService: ContactsUpgradeService,
        private readonly _contactModalService: ContactModalService,
        private readonly _bsModalRef: BsModalRef,
        private readonly _userInstanceService: UserInstanceService,
        private readonly _navigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _roleService: ContactRoleService
    ) {}

    params: any;
    result: any;

    lastSearch: string = '';
    gettingDataForTheFirstTime: boolean = true;
    gettingMoreData: boolean;
    roles: any[] = [];
    notAllowedRoles: any[] = []; //Dima: list of roles, which needs to be removed from the drop down list.E.g. for entity contacts, we can select only one Tax Bill Recipient

    entityInfo;
    recipientTypes;
    recipientMode;
    contacts: any[] = [];
    selectedContact;
    pageNumber: number = 0;
    pageSize: number = 50;
    filter: string = '';
    filterValidationError: string = '';
    oldFilter: string = '';
    header: string = 'Contacts';
    dialogMode: boolean;
    noMoreData: boolean;
    gettingContactRoles: boolean;
    excludeInactive: boolean;

    ngOnInit(): void {
        this.dialogMode = !!this.params;
        this.notAllowedRoles = []; //Dima: list of roles, which needs to be removed from the drop down list.E.g. for entity contacts, we can select only one Tax Bill Recipient

        if (this.dialogMode) {
            this.recipientMode = this.params.recipientMode;
        } else {
            this.recipientMode = false;
        }

        this.excludeInactive = !this._userInstanceService.isCurrentInstanceSystem();

        if (this.dialogMode) {
            this.entityInfo = this.params.entityInfo;
            if (!this.recipientMode) {
                this.selectedContact = null;
                this.notAllowedRoles = this.params.notAllowedRoles;
                this.header = 'Associate Contact to '; // TODO this service call always provides nothing + this._utilsService.getEntityName(this.entityInfo.id);
            }
        }

        this.recipientTypes = {
            ResponsibleParty: 1,
            Cc: 2,
            Bcc: 3
        };

        this._loadContacts();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    validateFilter(): void {
        if (this.filter.length > 100) {
            this.filterValidationError = 'Filter length should be 100 characters or less';
            return;
        }
        this.filterValidationError = '';
    }

    async searchContactsOnServer(searchText: string, excludeInactiveChanged: boolean = false): Promise<void> {
        if (!searchText) {
            searchText = '';
        }

        this.validateFilter();

        if (this.filterValidationError !== '') {
            return;
        }

        this.filter = searchText;

        if (searchText !== this.oldFilter || excludeInactiveChanged) {
            this.oldFilter = searchText;
            this.pageNumber = 1;
            this.noMoreData = false;
            this.contacts = [];
        } else {
            this.pageNumber++;
        }

        if (this.noMoreData) {
            return;
        }

        if (this.contacts.length === 0) {
            this.gettingDataForTheFirstTime = true;
        } else {
            this.gettingMoreData = true;
        }

        let result;

        try {
            if(this.dialogMode) {
                if(this.entityInfo.type === 'assessor' || this.entityInfo.type === 'state' || this.entityInfo.type === 'collector') {
                    result = await this._contactService.searchRyan(searchText, this.pageNumber, this.pageSize, this.excludeInactive);
                } else {
                    result = await this._contactService.searchScoped(this.entityInfo.id, this.entityInfo.type, searchText, this.pageNumber, this.pageSize, this.excludeInactive);
                }
            } else {
                result = await this._contactService.search(this._userInstanceService.getSelectedInstance().instanceId, searchText, this.pageNumber, this.pageSize, this.excludeInactive);
            }

            if (!result.length || result.length === 0) {
                this.noMoreData = true;
            } else {
                this.contacts = this.contacts.concat(result);
                // Sort by last name, then first name
                this.contacts.sort((a, b) => {
                    const compare = a.lastName.localeCompare(b.lastName);
                    return compare !== 0 ? compare : a.firstName.localeCompare(b.firstName);
                });
            }

            if (result.length < this.pageSize) {
                this.noMoreData = true;
            }
        } finally {
            this.gettingDataForTheFirstTime = false;
            this.gettingMoreData = false;
            this.lastSearch = searchText;
        }
    }

    async clearFilter(): Promise<void> {
        this.filter = '';

        if (this.lastSearch) {
            await this.searchContactsOnServer('');
        }
    }

    openCompanyPage(companyId: number): void {
        this._navigationServiceHandler.go('company', {
            companyId: companyId
        });

        if (this.dialogMode) {
            this.cancel();
        }
    }

    async openContactDialog(contactId: number, allContacts): Promise<void> {
        const contact = await this._contactModalService.openContactDialog(contactId, ContactModalOrigin.ContactsList, null, this.entityInfo, null, true);
        //const editedContact = _.find(allContacts, {
        //	contactID: contactId
        //});

        //const index = allContacts.indexOf(editedContact);
        //allContacts[index] = editedContact;

        //const index = _.findIndex(this.active.letter.list, {
        //	contactID: contactId
        //});

        //this.active.letter.list[index] = editedContact;

        if(!contact) {
            await this.clearFilter();
        }
    }

    getAnyContactPhone(contact): string {
        if (!contact) {
            return '';
        }

        if (contact.phone) {
            if (contact.ext) {
                return `${contact.phone  } x${  contact.ext}`;
            }
            else {
                return contact.phone;
            }
        }

        if (contact.mobilePhone) {
            return contact.mobilePhone;
        }

        return '';
    }

    associateRole(contact): void {
        this.result = {
            contact: contact,
            selectedRole: contact.selectedRole
        };
        this.cancel();
    }

    closeDialog(): void {
        this.result = this.contacts.filter(x => x.selected);
        this.cancel();
    }

    closeRecipientDialog(recipientType) {
        const selectedContacts = this.contacts.filter(x => x.selected);
        this.result = {
            recipients: selectedContacts,
            recipientType: recipientType
        };
        this.cancel();
    }

    async selectContact(contactDto): Promise<void> {
        if (this.recipientMode) { return; }

        // deselect other contacts
        //
        this.contacts.forEach(x => {
            if (x.contactID !== contactDto.contactID) {
                x.selected = false;
            }
        });

        // did we get all available roles for this contact already?
        if (contactDto.availableRoles) {
            return;
        }

        this.gettingContactRoles = true;
        //contactDto.selected = false;	// to keep it from expanding before we got available roles

        const someRoles = await this._roleService.getAvailableRolesForContactEntityCombinationAPI(contactDto.contactID, this.entityInfo);

        contactDto.availableRoles = someRoles.filter(x => this.notAllowedRoles.indexOf(x.contactRoleID) === -1);
        this.gettingContactRoles = false;
    }

    async checkForEnterKey(keyEvent): Promise<void> {
        if (keyEvent.which === 13) {
            await this.searchContactsOnServer(this.filter);
        }
    }

    cancelContactAssignRole(contact): void {
        contact.selectedRole = null;
        contact.selected = false;
    }

    contactsTrackBy(i: number, contact): number {
        return contact.contactID;
    }

    private async _loadContacts(): Promise<any> {
        if (this.dialogMode) {
            // get roles first they will be needed in order to set available roles for each contact
            this.roles = await this._roleService.get();

            // now get all contacts
            await this.searchContactsOnServer('');
        } else {
            // we don't need roles, just load contacts
            await this.searchContactsOnServer('');
        }
    }
}
