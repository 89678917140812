import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Constants, TaxAuthorityStatuses } from '../../../constants.new';
import { AvailableTaxRateArea, AvailableTaxAuthority, ParcelTaxRateService, TaxRateSetup, TaxAuthorityRate, TaxSetupMinimumDTO } from './parcelTaxRateService';
import { ToastrService } from 'ngx-toastr';
import { Subscriber } from 'rxjs';
import { TaxRatesService } from '../../../Assessor-Collector/Tax-Rates/tax.rates.service';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

export class ParcelCollectorTaxRateSetupModalResult {
    parcelCollectorId: number;
    rowVersion: string;
    taxRateAreaId: number;
    taxRateAreaName: string;
    taxRateAreaCode: string;
    taxAuthorityCount: number;
    taxAuthorities?: TaxAuthorityRate[];
}

@Component({
    templateUrl: './parcelCollectorTaxRateSetupModal.component.html'
})
export class ParcelCollectorTaxRateSetupModalComponent implements OnInit {
    constructor(
        private bsModalRef: BsModalRef,
        private toastr: ToastrService,
        private parcelTaxRateService: ParcelTaxRateService,
        private taxRatesService: TaxRatesService,
        public constants: Constants
    ) { }

    @ViewChild('taxRateAreaInput')
    private taxRateAreaInputRef: ElementRef;
    // loadingAll indicates the whole modal should be hidden with a loading indicator; loading just means
    // disable the controls temporarily while additional data is coming in
    loadingAll: boolean;
    loading: boolean;
    readOnly: boolean;
    isDirty: boolean;
    availableTaxRateAreas: AvailableTaxRateArea[];
    availableTaxAuthorities: AvailableTaxAuthority[];
    filteredAvailableTaxAuthorities: AvailableTaxAuthority[];
    model: TaxRateSetup;
    parcelCollectorId: number;
    parcelCollectorRowVersion: string;
    parcelId: number;
    collectorId: number;
    originalTaxAuthorities: TaxAuthorityRate[];
    originalTaxRateAreaId: number;
    overrideSetup: TaxSetupMinimumDTO;
    totalRate: number;
    availableYears: number[];
    displayYear: number;
    title: string;
    groupByCategory: boolean;
    currentTaxAuthorityGroups: {
        taxAuthorityCategoryId: number,
        categoryName: string,
        taxAuthorities: TaxAuthorityRate[]
    }[];
    modalResultSubscriber: Subscriber<ParcelCollectorTaxRateSetupModalResult>;
    newTaxAuthorityId: string;
    totalEstimated: boolean;
    isDocumentProcessing: boolean;

    async ngOnInit() {
        this.loadingAll = true;
        this.groupByCategory = this.taxRatesService.getIsGroupByEnabled();
        this.newTaxAuthorityId = '';
        // The modal launch service should provide year data, but as a fallback use the current year
        // and +-5 years from current year.
        if (!this.displayYear) {
            this.displayYear = (new Date()).getFullYear();
        }
        if (!this.availableYears) {
            this.availableYears = [];
            this.availableYears = [];
            for (let i = this.displayYear + 5; i > this.displayYear - 5; i--) {
                this.availableYears.push(i);
            }
        }

        try {
            if (this.parcelCollectorId) {
                if (this.overrideSetup) {
                    let modelPromise = Promise.resolve({
                        taxRateAreaId: null,
                        name: null,
                        code: null,
                        taxAuthorities: []
                    });

                    if (this.overrideSetup.taxRateAreaId) {
                        modelPromise = this.getTaxSetupByRateArea(this.overrideSetup.taxRateAreaId);
                    }
                    else if (this.overrideSetup.taxAuthorities && this.overrideSetup.taxAuthorities.length > 0) {
                        modelPromise = this.getTaxSetupByAuthorities(this.overrideSetup.taxAuthorities.map(ta => ta.taxAuthorityId));
                    }

                    [this.availableTaxAuthorities, this.availableTaxRateAreas, this.model] = await Promise.all([
                        this.parcelTaxRateService.getAvailableTaxAuthorities(this.parcelCollectorId),
                        this.parcelTaxRateService.getAvailableTaxRateAreas(this.parcelCollectorId),
                        modelPromise
                    ]);
                }
                else {
                    [this.availableTaxAuthorities, this.availableTaxRateAreas, this.model] = await Promise.all([
                        this.parcelTaxRateService.getAvailableTaxAuthorities(this.parcelCollectorId),
                        this.parcelTaxRateService.getAvailableTaxRateAreas(this.parcelCollectorId),
                        this.parcelTaxRateService.getTaxRateSetup(this.parcelCollectorId, this.displayYear)
                    ]);
                }
            }
            else {
                [this.availableTaxAuthorities, this.availableTaxRateAreas] = await Promise.all([
                    this.parcelTaxRateService.getAvailableTaxAuthoritiesByParcel(this.parcelId, this.collectorId),
                    this.parcelTaxRateService.getAvailableTaxRateAreasByParcel(this.parcelId, this.collectorId)
                ]);

                this.model = {
                    taxRateAreaId: null,
                    name: null,
                    code: null,
                    taxAuthorities: []
                };
                if (this.originalTaxAuthorities || this.originalTaxRateAreaId) {
                    this.model.taxRateAreaId = this.originalTaxRateAreaId;
                    if (this.originalTaxRateAreaId) {
                        const taxRateArea = this.availableTaxRateAreas.find(tra => tra.taxRateAreaId === this.originalTaxRateAreaId);
                        if (taxRateArea) {
                            this.model.name = taxRateArea.name;
                            this.model.code = taxRateArea.code;
                        }
                        else {
                            // This shouldn't happen in practice except in pathological cases (where a user deletes a tax rate area while
                            // another user is actively adding that tax rate area to a parcel)
                            this.model.name = '(invalid)';
                        }
                    }
                    this.model.taxAuthorities = this.originalTaxAuthorities || [];
                }
            }

            this.onModelChange(true);
            this.isDirty = false;
        }
        finally {
            this.loadingAll = false;
        }
    }

    onTaxRateAreaTextChange() {
        if (this.taxRateAreaInputRef.nativeElement.value == '') {
            this.clearSelectedTaxRateAreaId();
        }
        else {
            this.taxRateAreaInputRef.nativeElement.value = this.model.name;
        }
    }

    async onTaxRateAreaIdChange(event: TypeaheadMatch) {
        try {
            this.loadingAll = true;
            this.model = await this.getTaxSetupByRateArea(event.item.taxRateAreaId);
            this.onModelChange();
        }
        finally {
            this.loadingAll = false;
        }
    }

    clearSelectedTaxRateAreaId() {
        this.isDirty = true;
        Object.assign(this.model, { taxRateAreaId: null, name: null, code: null });
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    async addNewTaxAuthority(newAvailableTaxAuthority: AvailableTaxAuthority) {
        this.loading = true;
        try {
            const newTaxAuthority = await this.getTaxAuthority(newAvailableTaxAuthority.taxAuthorityId);
            if (newTaxAuthority) {
                this.model.taxAuthorities.push(newTaxAuthority);
                this.onModelChange();
            }
        }
        finally {
            this.loading = false;
        }
    }

    removeTaxAuthority(taxAuthority: TaxAuthorityRate) {
        this.model.taxAuthorities = this.model.taxAuthorities.filter(a => a.taxAuthorityId !== taxAuthority.taxAuthorityId);
        this.onModelChange();
    }

    onModelChange(skipDirtySet?: boolean) {
        if (!skipDirtySet) {
            this.isDirty = true;
        }
        this.filteredAvailableTaxAuthorities = _.sortBy(this.availableTaxAuthorities.filter(avail =>
            !this.model.taxAuthorities.some(a => avail.taxAuthorityId === a.taxAuthorityId)),
            [function(ta) { return ta.name.toLowerCase(); }]);
        this.totalRate = this.model.taxAuthorities.reduce((acc, val) => acc + val.rate, 0);
        this.totalEstimated = this.model.taxAuthorities.some(ta => ta.taxAuthorityStatusId !== TaxAuthorityStatuses.Actual);
        if (this.groupByCategory) {
            const distinctCategoryIds = [...new Set(this.model.taxAuthorities.map(ta => ta.taxAuthorityCategoryId))];
            this.currentTaxAuthorityGroups = _.chain(distinctCategoryIds)
                .map(c => {
                    return {
                        taxAuthorityCategoryId: c,
                        categoryName: c ? this.constants.TaxAuthorityCategories[c].displayName : null,
                        taxAuthorities: _.chain(this.model.taxAuthorities)
                            .filter(ta => ta.taxAuthorityCategoryId == c)
                            .sortBy('name')
                            .value()
                    };
                })
                .sortBy('categoryName')
                .value();
        }
        else {
            this.currentTaxAuthorityGroups = [{
                taxAuthorityCategoryId: 0,
                categoryName: null,
                taxAuthorities: _.sortBy(this.model.taxAuthorities, 'name')
            }];
        }
    }

    async onDisplayYearChange($event: string) {
        this.displayYear = +$event;
        this.loading = true;
        try {
            if (this.model.taxRateAreaId) {
                this.model = await this.getTaxSetupByRateArea(this.model.taxRateAreaId);
            }
            else {
                this.model = await this.getTaxSetupByAuthorities(this.model.taxAuthorities.map(a => a.taxAuthorityId));
            }
            this.onModelChange(true);
        }
        finally {
            this.loading = false;
        }
    }

    onGroupByCategoryChanged($event: boolean) {
        this.groupByCategory = $event;
        this.taxRatesService.setIsGroupByEnabled($event);
        this.onModelChange(true);
    }

    async save() {
        if (!this.isDirty) {
            this.closeModal();
            return;
        }
        if (!this.parcelCollectorId || this.isDocumentProcessing) {
            const modalResult = {
                parcelCollectorId: null,
                rowVersion: null,
                taxRateAreaId: null,
                taxRateAreaName: <string>null,
                taxRateAreaCode: <string>null,
                taxAuthorityCount: 0,
                taxAuthorities: <TaxAuthorityRate[]>null
            };

            if (this.model.taxRateAreaId) {
                modalResult.taxRateAreaId = this.model.taxRateAreaId;
                modalResult.taxRateAreaName = this.model.name;
                modalResult.taxRateAreaCode = this.model.code;
            }
            else {
                modalResult.taxAuthorityCount = this.model.taxAuthorities.length;
                modalResult.taxAuthorities = this.model.taxAuthorities;
            }
            this.modalResultSubscriber.next(modalResult);
            this.modalResultSubscriber.complete();
            this.bsModalRef.hide();
        }
        else {
            this.loadingAll = true;
            try {
                const { parcelCollectorRowVersion } = await this.parcelTaxRateService.saveTaxRateSetup(
                    this.parcelCollectorId,
                    this.parcelCollectorRowVersion,
                    this.model.taxRateAreaId,
                    this.model.taxRateAreaId ? [] : this.model.taxAuthorities.map(ta => ta.taxAuthorityId)
                );
                const modalResult = {
                    parcelCollectorId: this.parcelCollectorId,
                    rowVersion: parcelCollectorRowVersion,
                    taxRateAreaId: null,
                    taxRateAreaName: <string>null,
                    taxRateAreaCode: <string>null,
                    taxAuthorityCount: 0
                };

                if (this.model.taxRateAreaId) {
                    modalResult.taxRateAreaId = this.model.taxRateAreaId;
                    modalResult.taxRateAreaName = this.model.name;
                    modalResult.taxRateAreaCode = this.model.code;
                }
                else {
                    modalResult.taxAuthorityCount = this.model.taxAuthorities.length;
                }

                this.modalResultSubscriber.next(modalResult);
                this.modalResultSubscriber.complete();
                this.bsModalRef.hide();
                this.toastr.success('Parcel Collector Tax Rate Setup Saved');
            }
            catch (err) {
                this.loadingAll = false;
                throw err;
            }
        }
    }

    onNewTaxAuthorityChange(newTaxAuthorityId: string) {
        this.newTaxAuthorityId = '';
        if (newTaxAuthorityId) {
            this.addNewTaxAuthority(this.availableTaxAuthorities.find(ta => ta.taxAuthorityId == +newTaxAuthorityId));
        }
    }

    async getTaxAuthority(taxAuthorityId: number) {
        return (await this.getTaxSetupByAuthorities([taxAuthorityId])).taxAuthorities[0];
    }

    async getTaxSetupByAuthorities(taxAuthorityIds: number[]) {
        if (this.parcelCollectorId) {
            return await this.parcelTaxRateService.searchTaxRateSetup(
                this.parcelCollectorId,
                this.displayYear,
                null,
                taxAuthorityIds);
        }
        else {
            return await this.parcelTaxRateService.searchTaxRateSetupByParcel(
                this.parcelId,
                this.collectorId,
                this.displayYear,
                null,
                taxAuthorityIds);
        }
    }

    async getTaxSetupByRateArea(taxRateAreaId: number) {
        if (this.parcelCollectorId) {
            return await this.parcelTaxRateService.searchTaxRateSetup(
                this.parcelCollectorId,
                this.displayYear,
                taxRateAreaId,
                null);
        }
        else {
            return await this.parcelTaxRateService.searchTaxRateSetupByParcel(
                this.parcelId,
                this.collectorId,
                this.displayYear,
                taxRateAreaId,
                null);
        }
    }

    rateClass(taxAuthority: TaxAuthorityRate) {
        switch (taxAuthority.taxAuthorityStatusId) {
            case TaxAuthorityStatuses.Estimated:
                return 'estimated';
            case TaxAuthorityStatuses.Pending:
                return 'pending';
            default:
                return '';
        }
    }

    taTooltip(taxAuthority: TaxAuthorityRate) {
        let result: string = null;
        if (taxAuthority.taxAuthorityStatusId === TaxAuthorityStatuses.Pending) {
            if (taxAuthority.qcRequestTimeUtc) {
                result = `QC requested at ${  moment(taxAuthority.qcRequestTimeUtc).tz('America/Chicago').format('M/D/Y h:mm a')}`;
                if (taxAuthority.qcRequestUserFullName) {
                    result += ` by ${  taxAuthority.qcRequestUserFullName}`;
                }
            }
        }

        return result;
    }

    taxableAssessmentTypeName(taxableAssessmentTypeId: number) {
        if (!taxableAssessmentTypeId) {
            return null;
        }
        const taxableAssessmentType = this.constants.TaxableAssessmentTypes[taxableAssessmentTypeId];
        if (!taxableAssessmentType) {
            return null;
        }
        return taxableAssessmentType.displayName;
    }

    taxAuthorityCategoryName(taxAuthorityCategoryId: number) {
        if (!taxAuthorityCategoryId) {
            return null;
        }
        const taxAuthorityCategory = this.constants.TaxAuthorityCategories[taxAuthorityCategoryId];
        if (!taxAuthorityCategory) {
            return null;
        }
        return taxAuthorityCategory.displayName;
    }
}