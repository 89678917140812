<ws-busy-indicator *ngIf="serverAction"></ws-busy-indicator>
<ws-modal-wrapper headerLabel="New Payment Batch"
    [disableSave]="newPaymentBatchForm.invalid || !companyID || !topCompanyID || serverAction"
    (save)="save()"
    (cancel)="cancel()">
        <form [formGroup]="newPaymentBatchForm">
            <div class="form-group">
                <label>Top Company:</label>
                <company-picker [topLevelOnly]="true" [activeOnly] = "true" (companySelected)="topCompanySelected($event)">
                </company-picker>
            </div>
            <div class="form-group" [class.has-error]="newPaymentBatchForm.controls.companyName.invalid && newPaymentBatchForm.controls.companyName.touched">
                <label>Company:</label>
                <div class="form-group has-feedback">
                    <input
                    type="text" formControlName="companyName" maxlength="100"
                    [typeahead]="dataSource"
                    placeholder="Choose Subsidiary Company"
                    (typeaheadLoading)="companiesLoading = $event"
                    class="form-control input-sm"
                    typeaheadOptionField="name"
                    [typeaheadOptionsLimit]="100"
                    (typeaheadOnSelect)="typeaheadOnSelect($event)"
                    [typeaheadMinLength]="2"
                    [typeaheadWaitMs]="500" />
                <i class="fa fa-spinner fa-pulse form-control-feedback" *ngIf="companiesLoading"></i>

                    </div>
            </div>
            <div class="form-group" [class.has-error]="newPaymentBatchForm.controls.descriptionText.invalid && newPaymentBatchForm.controls.descriptionText.touched">
                <label>Description:</label>
                <div>
                <input type="text" formControlName="descriptionText" maxlength="500" class="input-sm form-control">
                </div>
            </div>
            <div class="form-group" [class.has-error]="newPaymentBatchForm.controls.batchType.invalid && newPaymentBatchForm.controls.batchType.touched">
                <label>Batch Type:</label>
                <div>
                    <select 
                        formControlName="batchType" 
                        [(ngModel)]="selectedBatchTypeId" 
                        class="form-control input-sm">
                        <option value = null disabled selected>Select</option>
                        <option *ngFor="let option of batchTypeOptions" [ngValue]="option.id">{{option.name}}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="batchTypeOptions?.length === 0" style="margin: 10px 0 0 0;" class="alert alert-primary">
                Please select an output type for the 'Tax Bill - Transmit' delivery details of the selected company to create a new payment batch
            </div>
        </form>
</ws-modal-wrapper>
