<div class="container">
    <div class="single-column-layout">
        <div class="ws-flex-container-horizontal ws-flex-justify-content-space-between ws-flex-align-items-baseline instance-info-header">
            <div class="float-start"><h1>Instance Settings</h1></div>
            <div class="float-end" *ngIf="!isEditSettings && !isEditDocumentIntakeAndProcessing">
                <button 
                    class="ace-btn has-icon btn-primary" 
                    *ngIf="!isEditSettings" 
                    (click)="setEditSettings()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
            </div>
            <div class="float-end" *ngIf="isEditSettings">
                <span *ngIf="showSpinnerSettings">
                    <i class="fa fa-spinner fa-spin"> </i>Processing...
                </span>
                <button 
                    class="ace-btn has-icon btn-primary btn-success" 
                    (click)="saveSettings()" 
                    *ngIf="!showSpinnerSettings">
                    <i class="fa fa-save"></i>
                </button>
                <button 
                    class="ace-btn has-icon btn-primary btn-danger" 
                    (click)="cancelSettings()" 
                    *ngIf="!showSpinnerSettings">
                    <i class="fa fa-close" style="color: white"></i>
                </button>
            </div>
        </div>
        <div>
            <form name="instanceEditForm" *ngIf="instanceModel">
                <div class="row">
                    <div class="col-md-4">
                        <label for="instanceName">Name:</label>
                    </div>
                    <div class="col-md-4">
                        <span *ngIf="!isEditSettings">{{instanceModel.instanceName}}</span>
                        <input 
                            *ngIf="isEditSettings" 
                            id="instanceName" 
                            name="instanceName" 
                            class="form-control" 
                            maxlength="50" 
                            [(ngModel)]="instanceModel.instanceName">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>
                            Offers Consulting Services
                        </label>
                    </div>
                    <div class="col-md-5">
                        <div class="checkbox" style="margin-top: 0;">
                            <label>
                                <input 
                                    type="checkbox" 
                                    name="offersConsultingService" 
                                    [(ngModel)]="instanceModel.offersConsultingServices" 
                                    [disabled]="!isEditSettings">
                                Available for others to request consulting services
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>
                            IDP Enabled
                        </label>
                    </div>
                    <div class="col-md-5">
                        <div class="checkbox" style="margin-top: 0;">
                            <label>
                                <input 
                                    type="checkbox" 
                                    name="documentIntakeIdpEnabled" 
                                    [(ngModel)]="instanceModel.documentIntakeIdpEnabled" 
                                    [disabled]="!isEditSettings">
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Main company:</label>
                    </div>
                    <div class="col-md-4">
                        <span *ngIf="!isEditSettings">{{instanceModel.customerCompanyName}}</span>
                        <company-picker 
                            *ngIf="isEditSettings"
                            [topLevelOnly]="true" 
                            [company]="companyModel" 
                            [instanceIdFilter]="instanceId" 
                            [activeOnly]="true" 
                            (companySelected)="companySelected($event)">
                        </company-picker>
                    </div>
                </div>
            </form>
        </div>

        <div class="ws-flex-container-horizontal ws-flex-justify-content-space-between ws-flex-align-items-baseline instance-info-header"
             style="padding-top: 50px;" 
             *ngIf="showDocumentIntakeAndProcessingPanel">
            <div class="float-start">
                <h1>Document Intake & Processing Services</h1>
            </div>
            <div class="float-end" *ngIf="!isEditDocumentIntakeAndProcessing && !isEditSettings">
                <button 
                    class="ace-btn has-icon btn-primary " 
                    *ngIf="!isEditDocumentIntakeAndProcessing" 
                    (click)="setEditDocumentIntakeAndProcessing()">
                    <i class="fa fa-pencil fa-align"></i>
                </button>
            </div>
            <div class="float-end" *ngIf="isEditDocumentIntakeAndProcessing">
                <span *ngIf="showSpinnerDocumentIntakeAndProcessing">
                    <i class="fa fa-spinner fa-spin"> </i>Processing...
                </span>
                <button 
                    *ngIf="!showSpinnerDocumentIntakeAndProcessing" 
                    class="ace-btn has-icon btn-primary btn-success" 
                    (click)="saveDocumentIntakeAndProcessing()" 
                    [disabled]="!canSaveDocumentIntakeAndProcessingSettings">
                    <i class="fa fa-save"></i>
                </button>
                <button 
                    *ngIf="!showSpinnerDocumentIntakeAndProcessing"
                    class="ace-btn has-icon btn-primary btn-danger" 
                    (click)="cancelDocumentIntakeAndProcessing()">
                    <i class="fa fa-close" style="color: white"></i>
                </button>
            </div>
        </div>
        <div *ngIf="showDocumentIntakeAndProcessingPanel">
            <form name="documentIntakeAndProcessingForm">
                <div class="row">
                    <div class="col-md-4">
                        <label for="instanceName">Document Intake & Processing:</label>
                    </div>
                    <div class="col-md-8">
                        <div class="checkbox">
                            <label>
                                <input 
                                    type="checkbox" 
                                    name="documentIntakeAndProcessingAssignToRyan" 
                                    [ngModel]="instanceModel.documentIntakeAndProcessingAssignToRyan" 
                                    (change)="assignToRyanChanged($event)" 
                                    [disabled]="!isEditDocumentIntakeAndProcessing">
                                Assign to Ryan
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Contact</label>
                    </div>
                    <div class="col-md-6">
                        <span *ngIf="!isEditDocumentIntakeAndProcessing">{{selectedUserName}}</span>
                        <user-team-picker 
                            *ngIf="isEditDocumentIntakeAndProcessing"
                            [parentUsers]="users" 
                            [(user)]="selectedUser" 
                            (userChange)="userChosen($event)" 
                            [required]="true">
                        </user-team-picker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <label>Special Instructions:</label>
                    </div>
                    <div class="col-md-6">
                        <span *ngIf="!isEditDocumentIntakeAndProcessing">
                            {{instanceModel.documentIntakeAndProcessingClientSpecialInstructions}}
                        </span>
                        <textarea 
                            *ngIf="isEditDocumentIntakeAndProcessing" 
                            name="documentIntakeAndProcessingSpecialInstructions" 
                            maxlength="500" 
                            rows="5" 
                            [(ngModel)]="instanceModel.documentIntakeAndProcessingClientSpecialInstructions">
                        </textarea>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
