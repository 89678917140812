import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ReconciliationReport } from './Models/reconciliationReport';
import { ReturnSummaryReconciliationReport } from './Models/returnSummaryReconciliationReport';
import { ReturnAssetsService, ReturnAssetsServiceSharedState } from './Return-Parts/Assets/returnAssets.service';
import {
    ReturnOverviewService,
    ReturnOverviewServiceSharedState
} from './Return-Parts/Overview/returnOverview.service';
import { ReturnService, ReturnServiceSharedState } from './return.service';
import * as _ from 'lodash';

interface TabSharedState {
    returnService: ReturnServiceSharedState;
    returnAssetsService: ReturnAssetsServiceSharedState;
    returnOverviewService: ReturnOverviewServiceSharedState;
}

interface TabSharedStates {
    [key: string]: TabSharedState;
}

export interface ReturnFormRevisionListItem extends Compliance.ReturnFormRevisionModel {
    displayName?: string;
}

@Injectable()
export class ReturnUpdateLogicService {
    constructor(private readonly _returnService: ReturnService,
                private readonly _returnAssetsService: ReturnAssetsService,
                private readonly _returnOverviewService: ReturnOverviewService) { }

    private readonly OVERVIEW_TAB = 'Overview';
    private readonly ASSETS_TAB = 'Assets';

    private _activeTab: string = this.OVERVIEW_TAB;

    private _sharedStates: TabSharedStates = {
        [this.OVERVIEW_TAB]: {
            returnService: null,
            returnAssetsService: null,
            returnOverviewService: null
        },
        [this.ASSETS_TAB]: {
            returnService: null,
            returnAssetsService: null,
            returnOverviewService: null
        }
    };

    private _loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _loading$: Observable<boolean> = this._loadingSubject.asObservable();

    private _returnFormRevisionsSubject: BehaviorSubject<ReturnFormRevisionListItem[]> = new BehaviorSubject(null);
    private _returnFormRevision$: Observable<ReturnFormRevisionListItem[]> = this._returnFormRevisionsSubject.asObservable();

    private _returnOverviewSubject: BehaviorSubject<ReconciliationReport> = new BehaviorSubject(null);
    private _returnOverview$: Observable<ReconciliationReport> = this._returnOverviewSubject.asObservable();

    private _returnSummarySubject: BehaviorSubject<ReturnSummaryReconciliationReport> = new BehaviorSubject(null);
    private _returnSummary$: Observable<ReturnSummaryReconciliationReport> = this._returnSummarySubject.asObservable();

    private _returnAssessorsSubject: BehaviorSubject<Compliance.FormRevisionAssessorModel[]> = new BehaviorSubject(null);
    private _returnAssessors$: Observable<Compliance.FormRevisionAssessorModel[]> = this._returnAssessorsSubject.asObservable();

    get loading$(): Observable<boolean> { return this._loading$; }
    get returnFormRevisions$(): Observable<ReturnFormRevisionListItem[]> { return this._returnFormRevision$; }
    get returnOverviewReport$(): Observable<ReconciliationReport> { return this._returnOverview$; }
    get returnSummaryReport$(): Observable<ReturnSummaryReconciliationReport> { return this._returnSummary$; }
    get returnAssessors$(): Observable<Compliance.FormRevisionAssessorModel[]> { return this._returnAssessors$; }

    setActiveTab(activeTab: string): void {
        this._activeTab = activeTab;
    }

    startLoading(): void {
        if (!this._loadingSubject.value) {
            this._loadingSubject.next(true);
        }
    }

    stopLoading(): void {
        if (this._loadingSubject.value) {
            this._loadingSubject.next(false);
        }
    }

    async start(): Promise<void> {
        this.startLoading();
        await this._refreshActiveTab();
        this.stopLoading();
    }

    async parcelFilterChanged(): Promise<void> {
        await this._refreshActiveTab();
    }

    async returnFormRevisionChanged(): Promise<void> {
        switch(this._activeTab) {
            case this.OVERVIEW_TAB:
                await this.refreshReturnOverview();
                break;
            case this.ASSETS_TAB:
                await this.refreshAssetFormDetails();
                break;
            default:
                break;
        }
    }

    async refreshReturnFormRevisions(): Promise<void> {
        this._refreshReturnFormRevisions();
    }

    async refreshOverview(): Promise<void> {
        this._refreshReturnFormRevisions();
        await this.refreshReturnOverview();
        this.stopLoading();
    }

    async refreshReturnOverview(): Promise<void> {
        this._refreshReturnOverview();
        await this.refreshReturnSummary();
        this.stopLoading();
    }

    async refreshReturnSummary(): Promise<void> {
        await this._refreshReturnSummary();
        this._persistLocalState();
        this.stopLoading();
    }

    async refreshAssetFormDetails(): Promise<void> {
        this.startLoading();
        this._refreshReturnFormRevisions();
        await this.refreshAssessors();
        this.stopLoading();
    }

    async refreshAssessors(): Promise<void> {
        await this._refreshAssessors();
        this._persistLocalState();
        this.stopLoading();
    }

    private async _refreshActiveTab(): Promise<void> {
        switch(this._activeTab) {
            case this.OVERVIEW_TAB:
                await this.refreshOverview();
                break;
            case this.ASSETS_TAB:
                await this.refreshAssetFormDetails();
                break;
            default:
                break;
        }
    }

    private _refreshReturnFormRevisions(): void {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const localSharedState = this._sharedStates[this._activeTab]?.returnService;

        // get input parameters for comparison
        const localReturnIds = (localSharedState?.returns && localSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReturnFormRevisionsUpdatedTimestamp = localSharedState?.returnFormRevisionsUpdatedTimestamp;
        const sharedReturnFormRevisionsUpdatedTimestamp = returnServiceSharedState.returnFormRevisionsUpdatedTimestamp;

        // check to see if input parameters have changed
        if (
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReturnFormRevisionsUpdatedTimestamp, sharedReturnFormRevisionsUpdatedTimestamp)
        ) {
            const uniqueRevisions = this._returnService.getUniqueAssociatedReturnFormRevisions() as ReturnFormRevisionListItem[];
            const returnFormRevisions = _.sortBy(uniqueRevisions.map(x => {
                x.displayName = this._returnService.getReturnFormRevisionDisplayName(x);
                return x;
            }), (i: Compliance.ReturnFormRevisionModel) => i.formRevisionName);

            this._returnFormRevisionsSubject.next(returnFormRevisions);
        }
    }

    private _refreshReturnOverview(): void {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();

        const localReturnSharedState = this._sharedStates[this._activeTab]?.returnService;
        const localReturnAssetsSharedState = this._sharedStates[this._activeTab]?.returnAssetsService;
        const localReturnOverviewServiceSharedState = this._sharedStates[this._activeTab]?.returnOverviewService;

        // get input parameters for comparison
        const localReturnIds = (localReturnSharedState?.returns && localReturnSharedState.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localFormRevisionId = localReturnSharedState?.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localReturnFormRevisionsUpdatedTimestamp = localReturnSharedState?.returnFormRevisionsUpdatedTimestamp;
        const sharedReturnFormRevisionsUpdatedTimestamp = returnServiceSharedState.returnFormRevisionsUpdatedTimestamp;

        const localReconciliationReport = localReturnOverviewServiceSharedState?.reconciliationReport;
        const sharedReconciliationReport = this._returnOverviewService.sharedState.reconciliationReport;

        const localAssetsUpdatedTimestamp = localReturnAssetsSharedState?.assetDetailsUpdatedTimestamp;
        const sharedAssetsUpdatedTimestamp = returnAssetsServiceSharedState?.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = localReturnAssetsSharedState?.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState?.assetMappingsUpdatedTimestamp;

        // check to see if input parameters have changed
        if (!_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localReturnFormRevisionsUpdatedTimestamp, sharedReturnFormRevisionsUpdatedTimestamp) ||
            !_.isEqual(localReconciliationReport, sharedReconciliationReport) ||
            !_.isEqual(localAssetsUpdatedTimestamp, sharedAssetsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)) {
            const selectedReconciliationReport = this._returnOverviewService.availableReconciliationReports
                .find(x => x === this._returnOverviewService.sharedState.reconciliationReport) || this._returnOverviewService.reconciliationReports[0];

            this._returnOverviewSubject.next(selectedReconciliationReport);
        }
    }

    private async _refreshReturnSummary(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();
        const returnAssetsServiceSharedState: ReturnAssetsServiceSharedState = this._returnAssetsService.getSharedStateClone();
        const returnOverviewServiceSharedState: ReturnOverviewServiceSharedState = this._returnOverviewService.getSharedStateClone();

        const localReturnSharedState = this._sharedStates[this._activeTab]?.returnService;
        const localReturnAssetsSharedState = this._sharedStates[this._activeTab]?.returnAssetsService;
        const localReturnOverviewServiceSharedState = this._sharedStates[this._activeTab]?.returnOverviewService;

        // get input parameters for comparison
        const localFormRevisionId = localReturnSharedState?.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        const localReturnIds = (localReturnSharedState?.returns && localReturnSharedState?.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localReconciliationReport = localReturnOverviewServiceSharedState?.reconciliationReport;
        const sharedReconciliationReport = returnOverviewServiceSharedState.reconciliationReport;

        const localAssetDetailsUpdatedTimestamp = localReturnAssetsSharedState?.assetDetailsUpdatedTimestamp;
        const sharedAssetDetailsUpdatedTimestamp = returnAssetsServiceSharedState.assetDetailsUpdatedTimestamp;

        const localAssetMappingsUpdatedTimestamp = localReturnAssetsSharedState?.assetMappingsUpdatedTimestamp;
        const sharedAssetMappingsUpdatedTimestamp = returnAssetsServiceSharedState.assetMappingsUpdatedTimestamp;

        // check to see if input parameters are available
        if (!sharedReturnIds.length || sharedReconciliationReport === null) {
            this._returnSummarySubject.next(null);
            return;
        }

        // check to see if input parameters have changed
        if (
            !_.isEqual(localFormRevisionId, sharedFormRevisionId) ||
            !_.isEqual(localReturnIds, sharedReturnIds) ||
            !_.isEqual(localReconciliationReport, sharedReconciliationReport) ||
            !_.isEqual(localAssetDetailsUpdatedTimestamp, sharedAssetDetailsUpdatedTimestamp) ||
            !_.isEqual(localAssetMappingsUpdatedTimestamp, sharedAssetMappingsUpdatedTimestamp)
        ) {
            this.startLoading();
            const data = await this._returnOverviewService.getReturnSummaryReconciliationReport();
            this._returnSummarySubject.next(data);
            return;
        }

        this._returnSummarySubject.next(this._returnSummarySubject.getValue());
    }

    private async _refreshAssessors(): Promise<void> {
        const returnServiceSharedState: ReturnServiceSharedState = this._returnService.getSharedStateClone();

        const localReturnSharedState = this._sharedStates[this._activeTab]?.returnService;

        // get input parameters for comparison
        const localReturnIds = (localReturnSharedState?.returns && localReturnSharedState?.returns.map(x => x.returnId)) || [];
        const sharedReturnIds = returnServiceSharedState.returns.map(x => x.returnId);

        const localFormRevisionId = localReturnSharedState?.formRevisionId;
        const sharedFormRevisionId = returnServiceSharedState.formRevisionId;

        // check to see if input parameters are available
        if (!sharedReturnIds.length || !sharedFormRevisionId) {
            this._returnAssessorsSubject.next([]);
            return;
        } else {
            // check to see if input parameters have changed
            if (!this._returnAssessorsSubject.getValue()
                ||!_.isEqual(localReturnIds, sharedReturnIds)
                || !_.isEqual(localFormRevisionId, sharedFormRevisionId)) {
                this.startLoading();
                const assessors = await this._returnAssetsService.getAssessorsByFilingBatch(this._returnService.filingBatchId, {
                    parcelIds: this._returnService.sharedState.returns.map(x => x.parcelId),
                    formRevisionId: this._returnService.sharedState.formRevisionId,
                    reportingAssetIds: []
                }) || [];

                this._returnAssessorsSubject.next(assessors);
                return;
            }
        }

        this._returnAssessorsSubject.next(this._returnAssessorsSubject.getValue());
    }

    private _persistLocalState(): void {
        if (!this._sharedStates[this._activeTab]) {
            this._sharedStates[this._activeTab] = {
                returnService: null,
                returnAssetsService: null,
                returnOverviewService: null
            };
        }
        this._sharedStates[this._activeTab].returnService = this._returnService.getSharedStateClone();
        this._sharedStates[this._activeTab].returnAssetsService = this._returnAssetsService.getSharedStateClone();
        this._sharedStates[this._activeTab].returnOverviewService = this._returnOverviewService.getSharedStateClone();
    }
}
