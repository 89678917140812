<div class="ws-flex-container-vertical ws-flex-auto" style="padding-top: 8px">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Appeal Application
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div class="btn-group" dropdown>
                        <button type="button"
                                class="ace-btn btn-sm btn-primary mr-0"
                                [disabled]="isDownloadingForms"
                                (click)="generateOutput()">
                            {{ isDownloadingForms ? 'Downloading Forms...' : 'Download Forms' }}
                        </button><!-- Be sure not to leave any whitespace here
                     --><button type="button"
                               dropdownToggle
                               class="ace-btn btn-sm btn-primary dropdown-toggle"
                               [disabled]="isDownloadingForms">
                            <span class="caret"></span>
                            <span class="sr-only">Split button!</span>
                        </button>
                        <ul *dropdownMenu
                            class="dropdown-menu"
                            role="menu"
                            aria-labelledby="button-split">
                            <li role="menuitem">
                                <a class="pointer dropdown-item" (click)="downloadEditable()">Download Editable Form</a>
                            </li>
                            <li role="menuitem">
                                <a class="pointer dropdown-item" (click)="downloadFlat()">Download Flat Form</a>
                            </li>
                        </ul>
                    </div>
                    <button class="ace-btn btn-primary btn-sm"
                            (click)="complete()"
                            [disabled]="isCompleting">
                        Complete
                    </button>
                </div>
                <div class="ws-section__header__actions__item">
                    <button class="ace-btn btn-sm btn-primary btn-danger"
                            (click)="close()"
                            [disabled]="isCompleting">
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>

<!--<h1>Appeal Application</h1>-->
<div style="flex: 1 1 auto; position: relative;">
<!--    <div style="position: absolute; top: -5px; right: 0;">-->
<!--        <span *ngIf="isCompleting" style="font-style: italic; margin-right: 5px;">Completing...</span>-->
<!--        <div class="btn-group" style="margin-bottom:3px;" dropdown>-->
<!--            <button type="button"-->
<!--                    class="ace-btn"-->
<!--                    [disabled]="isDownloadingForms"-->
<!--                    (click)="generateOutput()">-->
<!--                {{ isDownloadingForms ? 'Downloading Forms...' : 'Download Forms' }}-->
<!--            </button>&lt;!&ndash; Be sure not to leave any whitespace here-->
<!--                    &ndash;&gt;<button type="button"-->
<!--                               dropdownToggle-->
<!--                               style="padding: 10px 8px;"-->
<!--                               class="ace-btn dropdown-toggle dropdown-toggle-split"-->
<!--                               [disabled]="isDownloadingForms">-->
<!--                <span class="caret"></span>-->
<!--                <span class="sr-only">Split button!</span>-->
<!--            </button>-->
<!--            <ul *dropdownMenu-->
<!--                class="dropdown-menu"-->
<!--                role="menu"-->
<!--                aria-labelledby="button-split">-->
<!--                <li role="menuitem">-->
<!--                    <a class="pointer dropdown-item" (click)="downloadEditable()">Download Editable Form</a>-->
<!--                </li>-->
<!--                <li role="menuitem">-->
<!--                    <a class="pointer dropdown-item" (click)="downloadFlat()">Download Flat Form</a>-->
<!--                </li>-->
<!--            </ul>-->
<!--        </div>-->
<!--        <button class="ace-btn" (click)="complete()" [disabled]="isCompleting">Complete</button>-->
<!--        <button class="ace-btn btn-primary btn-danger" (click)="close()" style="margin-left: 5px;" [disabled]="isCompleting">Close</button>-->
<!--    </div>-->
    <tabset style="display: flex; flex-flow: column; height: 100%;">
        <tab heading="Forms" id="tab1" (selectTab)="onViewerTabSelected($event)">
            <div style="display: flex; flex-direction: row; flex: 1 1 auto; height: 100%;">
                <div *ngIf="!selectedForm" style="flex: 1 1 auto; padding-right: 10px;">
                </div>
                <appeal-application-viewer *ngIf="selectedForm"
                                           style="flex: 1 1 auto; padding-right: 10px;"
                                           [selectedForm]="selectedForm"
                                           [appealFormList]="appealFormList"
                                           (topAppealSelected)="topAppealSelected($event)">
                </appeal-application-viewer>
                <appeal-application-list *ngIf="appealIds"
                                         [appealIds]="appealIds"
                                         [defaultSelectedAppealId]="defaultSelectedAppealId"
                                         (formSelected)="selectedForm = $event"
                                         style="flex: none; display: flex; flex-flow: column; background-color: var(--ace-neutral-100); padding: 8px; box-shadow: 1px 1px 10px 1px var(--ace-neutral-300); overflow-y: auto;"
                                         (appealFormListFetched)="onAppealFormListRetrieved($event)">
                </appeal-application-list>
            </div>
        </tab>
        <tab heading="Settings" id="tab2">
            <div class="container">
                <appeal-application-settings *ngIf="appealFormRevisionIds" [appealId]="selectedForm?.appealId" [appealFormRevisionIds]="appealFormRevisionIds" (multiAccountSettingChanged)="hasMultiAccountSettingChanged = true" (settingsChanged)="haveSettingsChanged = true"></appeal-application-settings>
            </div>
        </tab>
    </tabset>
</div>
</div>
