<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<div class="modal-header">
    <h4 class="modal-title">
        Begin Preparation
    </h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
</div>

<div class="modal-body exclude-modal-styling">
    <form #addReturnForm="ngForm" *ngIf="isInitialized">
        <div class="mb-3">
            <small class="ws-flex-none">
                <span>Select a prior return to use as the basis for calculations such as additions and disposals.</span>
            </small>
        </div>
        <div class="mb-3">
            <label for="priorReturn">Prior Return</label><br>
            <select id="priorReturn" name="priorReturn" class="form-select form-select-sm" [(ngModel)]="priorFiling" (change)="priorReturnChanged()">
                <option [ngValue]="null">None</option>
                <option *ngFor="let pf of priorFilings; let i = index" [ngValue]="pf">
                    {{pf.filingDescription + (i === 0 ? ' (Default)' : '')}}
                </option>
            </select>
        </div>
        <div class="mb-3">
            <label class="ws-flex-none ws-font-weight-normal" for="isAmendment">
                <input type="checkbox" id="isAmendment" name="isAmendment" [(ngModel)]="isAmendment" [disabled]="!priorReturnSelected"> Amending Prior Return
            </label>
        </div>
    </form>
</div>

<div class="modal-footer">
    <div class="ws-button-row">
        <button type="button" class="ace-btn btn-primary" (click)="save()">OK</button>
        <button type="button" class="ace-btn btn-secondary" (click)="cancel()">Cancel</button>
    </div>
</div>
