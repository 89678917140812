
<div class="ws-flex-container-horizontal ws-flex-align-items-center advanced-filter-dropdown">
    <form>
        <select name="selectOptions" id="selectLabel" class="form-select form-select-sm" [formControl]="selectedOption" (change)="selectedOptionChanged()">
            <option *ngFor="let option of selectOptions" [ngValue]="option">{{ option.name }}</option>
        </select>
    </form>
    <button type="button"
            class="ace-btn btn-sm btn-primary has-icon"
            (click)="edit()"
            helpTooltip
            helpContentId="app.edit"
            position="bottom">
        <i class="fa fa-pencil"></i>
    </button>
</div>
