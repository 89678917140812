import { Component, Input, Output, EventEmitter } from '@angular/core';

interface ParcelTileDTO extends Core.ParcelTileDTO {
    isExternalReportingParcel?: boolean;
}

@Component({
    selector: 'parcel-tile',
    templateUrl: './parcelTile.component.html',
    styleUrls: ['./parcelTile.component.scss']
})
export class ParcelTileComponent {
    @Input() item: ParcelTileDTO;
    @Input() stateAllowConsolidating: boolean;
    @Input() ppReturnPreparationAllowed: boolean;
    @Output() navigateToParcel: EventEmitter<number> = new EventEmitter();
}
