<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<ws-modal-wrapper headerLabel="Clear Cache Entry"
                  [hideCancel]="true"
                  [suppressKeyboardEvents]="true"
                  (save)="close()">
    <div class="app-ui-view h-100 w-100">
        <div class="row mb-4 align-items-center">
            <label class="col-md-3 control-label" for="dbObjectName">Object Name:</label>
            <div class="col-md-8">
                <input id="dbObjectName" type="text" required class="form-control" [(ngModel)]="objectName" (keyup.enter)="searchObject()"/>
            </div>
            <div class="col-md-1">
                <button type="button" position="bottom" class="ace-btn btn-sm" (click)="searchObject()">
                    <i class="fa fa-search"></i>
                </button>
            </div>
            <table class="col-md-12">
                <thead>
                    <tr>
                        <th>Object Name</th>
                        <th>Cache Object Type</th>
                        <th>Object Type</th>
                        <th>Handle</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let entry of planEntries">
                        <td>{{ entry.objectName }}</td>
                        <td>{{ entry.cacheObjectType }}</td>
                        <td>{{ entry.objectType }}</td>
                        <td>{{ (entry.planHandle.length > 10) ? (entry.planHandle | slice:0:10) + '...' : (entry.planHandle) }}</td>
                        <td>
                            <button type="button" class="ace-btn btn-text has-icon btn-primary btn-danger" (click)="clearPlanEntry(entry)">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ws-modal-wrapper>
