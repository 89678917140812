import { Component, OnDestroy, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FilingRowModel } from '../filingBatchDetails.service';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../AgGrid';
import { AgGridMultiSelectedCellRenderer, AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams, AgGridMultiSelectTracker } from '../../../AgGrid/MultiSelectTracker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgGridLinkCellRendererComponent, AgGridLinkCellRendererParams } from '../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { WeissmanDateFormatPipe } from '../../../../UI-Lib/Pipes/Date-Format/date-formatting.pipe';
import { FilingBatchService } from '../../filingBatch.service';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { AMEND_RETURNS_HELP } from './amendReturns.component.help';

export interface AmendReturnsModalParams {
    companyId: number;
    filingBatch: Compliance.FilingBatchModel;
    filings: FilingRowModel[];
}

@Component({
    selector: 'amend-returns',
    templateUrl: './amendReturns.component.html'
})
export class AmendReturnsComponent implements OnInit, OnDestroy, IWeissmanModalComponent<AmendReturnsModalParams, Compliance.FilingBatchModel> {

    constructor(
        private readonly _bsModalRef: BsModalRef,
        private readonly _datePipe: WeissmanDateFormatPipe,
        private readonly _filingBatchService: FilingBatchService,
        private readonly _helpService: HelpService
    ) {}

    params: AmendReturnsModalParams;
    result: Compliance.FilingBatchModel;

    companyId: number;
    isSaving: boolean;
    selectedRows: number[] = [];
    gridTracker: AgGridMultiSelectTracker;
    gridOptions: GridOptions = new AgGridOptionsBuilder(
        {
            onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
            onSortChanged: () => this.gridTracker.onGridSortChanged(),
            rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as FilingRowModel).filingId),
                'grid-row-deleted': (params) => params.data && (params.data as FilingRowModel).deleted
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .withColumnPinning()
        .build();

    private _gridApi: GridApi;
    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this._helpService.setContent(AMEND_RETURNS_HELP);

        this.companyId = this.params.companyId;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe((selected: Compliance.SelectedRowsModel) => {
            this.selectedRows = (selected.selectAllRows) ? this.params.filings.map(f => f.filingId) : selected.selectedRows;
        });

        const columns: ColDef[] = [
            {
                field: 'filingId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/assessor/${filing.assessorId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Filing',
                field: 'filingDescription',
                lockVisible: true,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/filing/${filing.filingId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/company/${filing.companyId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/site/${filing.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/site/${filing.siteId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Parcel',
                field: 'parcelAcctNumberDisplay',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filing = params.data as FilingRowModel;
                        if (!filing) {
                            return '';
                        }
                        return `#/parcel/${filing.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: params => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }

                    return filing.parcelAcctNumberDisplay;
                }
            },
            {
                headerName: 'Due Date',
                field: 'dueDate',
                width: AgGridColumns.dateColumnWidth,
                filter: 'agDateColumnFilter',
                filterParams: AgGridFilterParams.dateFilterParamsWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.dateFloatingFilterParams,
                valueFormatter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }

                    return this._datePipe.transform(filing.dueDate, true);
                },
                filterValueGetter: (params) => {
                    const filing = params.data as FilingRowModel;
                    if (!filing) {
                        return '';
                    }

                    return this._datePipe.transform(filing.dueDate, true);
                }
            }
        ];

        const defaultSortModel = [
            {
                colId: 'assessorName',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setDataSource();
    }

    async submit(): Promise<void> {
        if (!this.selectedRows) { return; }

        const filingIds = await this.gridTracker.getSelectedRowIds();
        const updateModel: Compliance.FilingBatchFilingsAmendModel = {
            filingIds
        }

        this.isSaving = true;

        try {
            this.result = await this._filingBatchService.amendFilings(updateModel);
            this._bsModalRef.hide();
        } finally {
            this.isSaving = false;
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _setDataSource(): void {
        if (!(this._gridApi && this.params.filings)) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();

        this._gridApi.setRowData(this.params.filings);
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const filing = x.data as FilingRowModel;
                return filing && filing.filingId;
            })
        };

        return Promise.resolve(queryResultModel);
    }

}
