<div class="ws-flex-container-vertical ws-stretch" *ngIf="isInitialized">
    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Supplemental Information"></ws-bread-crumbs>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">
                Supplemental Information
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div class="ws-section__header__actions__item" *ngIf="canEdit">
                        <button *ngIf="!editMode"
                                title="Edit"
                                type="button"
                                class="ace-btn btn-sm btn-primary has-icon"
                                (click)="edit()">
                            <i class="fa fa-pencil"></i>
                        </button>
                        <button type="button" *ngIf="editMode" class="ace-btn btn-sm btn-primary has-icon" (click)="save()" title="Save" [disabled]="!isDirty"><i class="fa fa-save"></i></button>
                        <button type="button" *ngIf="editMode" class="ace-btn btn-sm btn-primary has-icon" (click)="cancel()" title="Cancel"><i class="fa fa-times"></i></button>
                    </div>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" (click)="refresh()" title="Refresh" [disabled]="editMode || refreshing"><i class="fa fa-refresh"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-flex-none ws-flex-container-horizontal supplemental-page-row">
        <section class="ws-flex-container-vertical supplemental-page-flex-column-left">
            <select class="form-select form-select-sm" id="companyId" name="companyId" [(ngModel)]="selectedCompanyId"
                    (ngModelChange)="selectedCompanyIdChanged()" [disabled]="editMode">
                <option [ngValue]="company?.companyID">{{company?.companyName}}</option>
                <optgroup label="Subsidaries" *ngIf="company.childCompanies && company.childCompanies.length">
                    <option *ngFor="let childCompany of company.childCompanies" [ngValue]="childCompany.companyID">
                        {{childCompany.companyName}}
                    </option>
                </optgroup>
            </select>
        </section>
        <section class="ws-flex-container-vertical supplemental-page-flex-column-right">
            <select class="form-select form-select-sm" id="state" name="state" [(ngModel)]="stateFilterType"
                    (ngModelChange)="stateFilterTypeChanged()" [disabled]="editMode">
                <option value="ALL">All States</option>
                <option value="SITES">States with Sites</option>
                <option value="OVERRIDES">States with overrides</option>
            </select>
        </section>
    </div>
    <div class="supplemental-grid-containing-row ws-flex-container-horizontal supplemental-page-row">
        <section class="ws-flex-container-vertical supplemental-page-flex-column-left">
            <div class="ws-flex-container-vertical field-map-wrapper ws-stretch-height">
                <p class="small text-muted" style="margin-top:8px">Available Fields</p>
                <div *ngFor="let fld of fieldMap" [ngClass]="{selectedfield:fld.isSelected,unselectedfield:!fld.isSelected}" class="ws-flex-container-horizontal ws-flex-align-items-center">
                    <label for="{{fld.fieldName}}" class="ws-flex-none small ws-font-weight-normal">
                        <input type="checkbox" [(ngModel)]="fld.isSelected" id="{{fld.fieldName}}" (ngModelChange)="fieldMapChanged()"> {{fld.labelName}}
                    </label>
                </div>
            </div>
        </section>
        <section class="ws-flex-container-vertical supplemental-page-flex-column-right grid-wrapper">
            <ws-ag-grid-angular  [gridOptions]="gridOptions"
                                 (gridReady)="onAgGridReady($event)">
            </ws-ag-grid-angular>
        </section>
    </div>

</div>


