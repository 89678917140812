<div class="card panel-flat-color primary-panel">
    <div class="panel-flat-header" style="display: flex; align-items: center; justify-content: space-between;">
        <h3>Company Info</h3>
        <div style="display: flex; align-items: center;">
            <div class="header-button-container">
                <button *ngIf="!editMode && !editLocked"
                        class="ace-btn btn-text has-icon btn-primary btn-text m-0"
                        [hidden]="!hasEditPermission"
                        (click)="edit()"
                        [disabled]="serverAction">
                    <i class="fa fa-pencil"></i>
                </button>
                <span *ngIf="editMode">
                    <button class="ace-btn btn-text has-icon btn-primary btn-success m-0"
                            [disabled]="infoForm.invalid || serverAction" (click)="save()">
                        <i class="fa fa-save"></i>
                    </button>
                    <button class="ace-btn btn-text has-icon btn-primary btn-danger m-0" [disabled]="serverAction"
                            (click)="cancel()">
                        <i class="fa fa-close"></i>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="panel-flat-body">
        <loading-spinner [show]="serverAction" [marginTop]="100" [size]="5"></loading-spinner>
        <div [hidden]="serverAction">
            <company-info-panel-view *ngIf="!editMode"
                                     [company]="company"
                                     (serverActionChange)="serverAction = $event"
                                     [activatedByPopover]="activatedByPopover"
                                     [parentCompany]="parentCompany"
                                     [defaultAddress]="defaultAddress">
            </company-info-panel-view>
            <company-info-panel-edit *ngIf="editMode"
                                     [company]="company"
                                     (serverActionChange)="serverAction = $event"
                                     [parentCompany]="parentCompany"
                                     [infoForm]="infoForm"
                                     [defaultAddress]="defaultAddress"
                                     [activatedByPopover]="activatedByPopover"
                                     [saveFunc]="saveFunc">
            </company-info-panel-edit>
        </div>
    </div>
</div>

<ng-template #activatedByPopover>
    <div *ngIf="company.activatedDateTime > company.inactivatedDateTime">
        <div *ngIf="company.activatedBy">
            Activated by {{ company.activatedBy }} <br>
            Date: {{ company.activatedDateTime | date:'shortDate' }}
        </div>
        <div *ngIf="company.inactivatedBy || company.inactivatedDateTime" style="margin-top: 10px;">
            Inactivated <span *ngIf="company.inactivatedBy">by {{ company.inactivatedBy }}</span> <br>
            Date: {{ company.inactivatedDateTime | date:'shortDate' }}
        </div>
    </div>
    <div *ngIf="company.activatedDateTime <= company.inactivatedDateTime">
        <div *ngIf="company.inactivatedBy || company.inactivatedDateTime" style="margin-top: 10px;">
            Inactivated <span *ngIf="company.inactivatedBy">by {{ company.inactivatedBy }}</span> <br>
            Date: {{ company.inactivatedDateTime | date:'shortDate' }}
        </div>
        <div *ngIf="company.activatedBy">
            Activated by {{ company.activatedBy }} <br>
            Date: {{ company.activatedDateTime | date:'shortDate' }}
        </div>
    </div>
</ng-template>
