import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import {
    ColDef,
    ColumnApi,
    CsvExportParams,
    GridApi,
    GridReadyEvent,
    RowDataChangedEvent,
    RowNode
} from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProductAnalyticsService } from '../../../Common/Amplitude/productAnalytics.service';
import { AgGridColumns, AgGridOptionsBuilder } from '../../../Compliance/AgGrid';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer, AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../Compliance/AgGrid/MultiSelectTracker';
import { AgGridExportOptions } from '../../../Compliance/AgGrid/ToolPanel/models';
import { ActionViewCategoryTypes } from '../../../constants.new';
import {
    AdvancedSearchColumnBuilder
} from '../../../Search/Advanced/Advanced-Search-List/advancedSearchColumnBuilder.service';
import { AdvancedSearchPersistenceService } from '../../../Search/Advanced/advancedSearchPersistence.service';
import { SmartSearchService } from '../../../Search/Advanced/smartSearch.service';
import { ActionViewService } from '../action-view-service.upgrade';
import { ActionViewPersistenceService } from '../Action.View.Persistence.Service.upgrade';
import { ActionViewContextMenuService } from '../actionViewContextMenu.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'action-view-detailed',
    templateUrl: './actionDetailed.component.html',
    styleUrls: ['./actionDetailed.component.scss']
})
export class ActionDetailedComponent implements OnInit, OnDestroy {
    constructor(private readonly _actionViewService: ActionViewService,
                private readonly _actionViewContextMenuService: ActionViewContextMenuService,
                private readonly _actionViewPersistenceService: ActionViewPersistenceService,
                private readonly _advancedSearchPersistenceService: AdvancedSearchPersistenceService,
                private readonly _productAnalyticsService: ProductAnalyticsService,
                private readonly _uiRouter: UIRouter,
                private readonly _smartSearchService: SmartSearchService,
                private readonly _columnBuilder: AdvancedSearchColumnBuilder,
                private readonly _toastr: ToastrService) {
    }

    @Input() filterToExecute;
    @Input() drilldownMode;
    @Input() drilldownFilter;
    @Input() results;
    @Input() taskAssignmentApi;
    @Input() actionView;

    @Output() backToOverview: EventEmitter<boolean> = new EventEmitter<boolean>();

    gridId: string = '64f50d82-7571-4cdc-a705-f5100b054f16';
    gridTracker: AgGridMultiSelectTracker;
    gridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        getRowNodeId: (x) => x.n10908,
        rowClassRules: {
            'clickable': (params) => false,
            'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected(params.data.n10908)
        },
        getRowStyle: (params) => {
            if (params.data.error) {
                return { 'color': 'red' };
            } else if (params.data.warning) {
                return { 'color': '#ffa000', 'font-weight': 'bold' };
            } else if (params.data.stale) {
                return { 'font-style': 'italic', 'color': 'silver' };
            }
        },
        suppressScrollOnNewData: true,
        onRowDataChanged: (event: RowDataChangedEvent) => {
            if (this._advancedSearchPersistenceService.scrollLocationIndex) {
                this._gridApi.ensureIndexVisible(this._advancedSearchPersistenceService.scrollLocationIndex, 'top');
                this._advancedSearchPersistenceService.scrollLocationIndex = null;
            }
        }
    })
        .withSort()
        .withColumnResize()
        .withMultipleColumnSort()
        .withLoadingOverlay()
        .withTextSelection()
        .build();

    exportOptions: AgGridExportOptions = {
        onExportClick: (): void => {
            // this.emitAnalyticsEvent('click-SMART-excel-export');
            this.exportToExcel();
        },
        disabled: false,
        canCancel: true
    };

    contextMenuItems: any[];
    contextMenuLoading = false;
    contextMenuOpen = false;
    showContextMenu = false;

    columnApi: ColumnApi;

    loading = false;
    loadingText = null;
    isDestroyed = false;
    showErrorsOnly = {
        value: false
    };
    showValidRowsOnly = {
        value: false
    };
    originalData = null;
    columnsExpanded: boolean;
    excelDownloading: boolean;

    private _gridApi: GridApi;
    private _destroy$: Subject<void> = new Subject();

    get contextMenuDisabled(): boolean {
        return !this.gridTracker?.hasSelectedRows() || this.contextMenuLoading;
    }

    get contextMenuTooltip(): string {
        if (!this.gridTracker?.hasSelectedRows()) {
            return 'Please select at least one row to perform an action';
        } else if (this.contextMenuLoading) {
            return 'Loading actions...';
        } else if (!this.contextMenuItems?.length) {
            return 'Load selected actions';
        }  else {
            return null;
        }
    }

    async ngOnInit(): Promise<void> {
        this._actionViewContextMenuService.loading$.pipe(takeUntil(this._destroy$)).subscribe((loading) => {
            this.loading = !!loading;
            this.loadingText = loading;
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
        this._actionViewContextMenuService.clearErrors();
        this.isDestroyed = true;
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this._gridApi = event.api;
        this.columnApi = event.columnApi;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe((selectedRows) => {
            this.contextMenuItems = null;
            this.showContextMenu = this.gridTracker.hasSelectedRows();
        });

        let finalFilter = structuredClone(this.filterToExecute);

        //load the filter
        if (this._actionViewPersistenceService.drilldownFilter) {
            //If we're loading the drilldown externally, wipe out the external flag so we don't get caught in an infinite loop
            finalFilter = structuredClone(this._actionViewPersistenceService.drilldownFilter);
        } else if (this.drilldownMode) {
            finalFilter = structuredClone(this.drilldownFilter);
        } else if (this._actionViewPersistenceService.resultsChanged) {
            finalFilter = structuredClone(this._actionViewPersistenceService.masterFilter);
            this.filterToExecute = structuredClone(finalFilter);
            await this.executeDetailedFilter(finalFilter);
        }

        //if we have no cached results and we have a filter
        if (!this.results && this.filterToExecute !== undefined) {
            await this.executeDetailedFilter(finalFilter);
        }
        // if we have no saved cached results
        else if (!this._actionViewPersistenceService.detailResults) {
            const stateParams = this._uiRouter.globals.params;
            const actionViewId = parseInt(stateParams.actionViewId);
            const isSystemView = parseInt(stateParams.actionViewType) === ActionViewCategoryTypes.SYSTEM;

            if (isSystemView) {
                const response = await this._actionViewService.getSystemActionViewById(actionViewId);

                const newCriteria = JSON.parse(response.criteria);
                const searchFilter = newCriteria.masterFilter;
                searchFilter.DetailedResults = true;
                //searchFilter.TaskOutputColumns =_.map(searchFilter.TaskOutputColumns, 'columnId')
                const result = await this.taskAssignmentApi.resolveDefaultOutput(searchFilter);
                await this.executeDetailedFilter(result);
            } else if (!this._actionViewPersistenceService.resultsChanged) {
                const response = await this._actionViewService.getCustomActionViewById(actionViewId);

                const newCriteria = JSON.parse(response.criteria);
                const searchFilter = newCriteria.masterFilter;
                searchFilter.DetailedResults = true;
                const result = await this.taskAssignmentApi.resolveDefaultOutput(searchFilter);
                await this.executeDetailedFilter(result);
            }
        } else if (this._actionViewPersistenceService.detailResults && !this._actionViewPersistenceService.resultsChanged) {
            this.results = this._actionViewPersistenceService.detailResults.dataTable;
        } else if (!this.filterToExecute && finalFilter) {
            this.filterToExecute = structuredClone(finalFilter);
        }

        if (this.results) {
            this._buildTable(this.results);
        }

        this._setRowData();
    }

    setPersistenceServiceWithResult(result): void {
        this._actionViewPersistenceService.detailResults = structuredClone(result);
        this.setPersistenceService();
    }

    setPersistenceService(): void {
        if (!this._actionViewPersistenceService.masterFilter) {
            this._actionViewPersistenceService.masterFilter = structuredClone(this.filterToExecute) || {};
        }

        //If specific user or teams are blank in the filters, we need to make sure they include an empty object
        //for when the user returns to the filter screens (instead of null)
        this._actionViewPersistenceService.masterFilter.ShowUserTeams = this._actionViewPersistenceService.masterFilter.ShowUserTeams || [{}];
        this._actionViewPersistenceService.masterFilter.ShowTeams = this._actionViewPersistenceService.masterFilter.ShowTeams || [{}];

        if (this.drilldownMode) {
            this._actionViewPersistenceService.drilldownFilter = structuredClone(this.drilldownFilter);
        }
        const stateParams = this._uiRouter.globals.params;
        this._actionViewPersistenceService.routeParams = structuredClone(stateParams);
        this._actionViewPersistenceService.actionViewType = stateParams.actionViewType;
        this._actionViewPersistenceService.drilldownMode = structuredClone(this.drilldownMode);
        this._actionViewPersistenceService.actionViewName = this.actionView.actionViewName;
    }

    async executeDetailedFilter(filter): Promise<void> {
        this._actionViewPersistenceService.selectionData = [];

        this.filterToExecute = filter;

        try {
            const result = await this._actionViewService.executeActionView(filter);

            if (result.totalCount >= 50000) {
                this._toastr.warning('50,000 records are shown; if you want to see all results please use the "Excel" export option.', 'Action View');
            }

            this.results = result.dataTable;

            // please do not remove this console output,Grant is using it
            //
            console.info(['ActionView Detailed Search results', result]);
            console.info(['ActionView Detailed Search result sql', result.sql]);

            this.setPersistenceServiceWithResult(result);
        } catch (err) {
            if (err !== 'Cancelled') {
                console.log(['ActionView search - Failure', err]);
                // error handler
                if (err.status === 0) {
                    // $http timeout
                    console.log('ActionView search - Failure - $http timeout');
                } else {
                    // response error status from server
                    console.log('ActionView search - Failure ');
                    //toastr.info('', '"Action View search has been cancelled');
                    //warningService.warning("", "Action View search has been cancelled", 6000);
                    this.results = {};
                    this.originalData = null;
                    //showFilter()
                }
            }
        }
    }

    async getTableData(fromRefresh: boolean): Promise<void> {
        if (fromRefresh) {
            this._productAnalyticsService.logEvent('click-action-view-refresh');
        }
        this._gridApi.showLoadingOverlay();
        this.showErrorsOnly.value = false;
        this.showValidRowsOnly.value = false;
        this._actionViewContextMenuService.clearErrors();

        this.gridTracker.clear();

        let resetFilter;
        // Figure out which filter we need. The three states are basically
        // 1) use the persistence service. This is when we return using the nub and takes precedence
        // 2) use the drilldown filter. This means we have drilled down from the overview page and need to use that
        // 3) lastly, use the filter on the page. This is most common. This is a normally executed filter.
        if (this.drilldownMode && !_.isEmpty(this.drilldownFilter)) {
            resetFilter = this.drilldownFilter;
        } else if (this._actionViewPersistenceService.masterFilter && !this.filterToExecute) {
            resetFilter = this._actionViewPersistenceService.masterFilter;
        } else if (this.filterToExecute) {
            resetFilter = this.filterToExecute;
        }

        if (resetFilter) {
            this.results = null;
            this.originalData = null;
            await this.executeDetailedFilter(resetFilter);
            this._setRowData();
            this._gridApi.hideOverlay();
        }
    }

    async getMenuOptions(): Promise<void> {
        if (this.contextMenuOpen) {
            this.contextMenuOpen = false;
            return;
        }

        if (this.contextMenuItems?.length) {
            this.contextMenuOpen = true;
            return;
        }

        this.contextMenuLoading = true;

        this.setPersistenceService();
        this.contextMenuItems = await this._actionViewContextMenuService.getContextMenu(this._gridApi, this.gridTracker, this.gridOptions, this.results.searchTimestamp);

        this.contextMenuOpen = true;
        this.contextMenuLoading = false;
    }

    // remove CSV export per Grant comment regarding WK-4228 (already removed on the UI)
    exportToCsv(): void {
        const params = {
            fileName: `action-view - ${moment().format()}`
        } as CsvExportParams;
        this._gridApi.exportDataAsCsv(params);
    }

    async exportToExcel(): Promise<void> {
        this.excelDownloading = true;
        this._productAnalyticsService.logEvent('click-action-view-excel-export');
        const filterToExecute = this.drilldownMode
            ? structuredClone(this.drilldownFilter)
            : structuredClone(this.filterToExecute);

        if (!this.actionView.actionViewName || this.actionView.actionViewName.trim() == '') {
            filterToExecute.searchName = 'Action View Results';
        } else {
            filterToExecute.searchName = this.actionView.actionViewName.replace(/[^a-z0-9_ \-]/gi, '');
        }

        await this._actionViewService.executeActionViewToExcel(filterToExecute);
        this.excelDownloading = false;
    }

    resizeColumns(value: boolean): void {
        this.columnsExpanded = value;
        this._productAnalyticsService.logEvent('click-action-view-autosize');
    }

    hasErrors(): boolean {
        return this._actionViewContextMenuService.hasErrors();
    }

    filterResults(): void {
        if (!this.originalData) {
            this.originalData = structuredClone(this.results.dataTable);
        }

        if (this.showErrorsOnly.value || this.showValidRowsOnly.value) {
            const dataToFilter = this.gridTracker.hasAnythingSelected() ? this._gridApi.getSelectedRows() : this.originalData;

            this.results.dataTable = dataToFilter.filter(x =>
                (this.showErrorsOnly.value && (x.error || x.warning)) ||
                (this.showValidRowsOnly.value && !x.error && !x.warning));
        } else {
            this.results.dataTable = this.originalData;
        }
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.results)) {
            return;
        }

        this._gridApi.setRowData(this.results);
        if (this._advancedSearchPersistenceService.columnsExpanded) {
            this._gridApi.sizeColumnsToFit();
        } else {
            this.columnApi.autoSizeAllColumns();
        }
    }

    private _buildTable(tableData: any[]): void {
        if (!this._gridApi) {
            return;
        }

        // Important - For whatever reason, ag-grid will maintain the previous search column order if not reset
        this._gridApi.setColumnDefs([]);

        const search = this._advancedSearchPersistenceService.search;
        const columnIds = (tableData && tableData.length) ? Object.keys(tableData[0]) : [];

        // Reorder columnIds to match outputColumn order
        if (search && search.searchCriteria && search.searchCriteria.outputColumns) {
            const outputIds = search.searchCriteria.outputColumns.map(x => x.columnId);
            columnIds.sort((a, b) => {
                return outputIds.indexOf(+a.slice(1)) - outputIds.indexOf(+b.slice(1));
            });
        }

        const multiselectColDef = {
            colId: 'grid-column-multiselect',
            headerName: '',
            field: 'n10908',
            width: AgGridColumns.selectionColumnWidth,
            maxWidth: AgGridColumns.selectionColumnWidth,
            suppressSizeToFit: true,
            suppressAutoSize: true,
            suppressColumnsToolPanel: true,
            editable: false,
            pinned: 'left',
            lockPinned: true,
            lockVisible: true,
            lockPosition: true,
            suppressMovable: true,
            headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
            headerComponentParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams,
            cellRendererFramework: AgGridMultiSelectedCellRenderer,
            cellRendererParams: { tracker: this.gridTracker } as AgGridMultiSelectRendererParams
        } as ColDef;

        const columnProperties = columnIds.reduce((acc, x) => {
            const fieldId = +x.slice(1);
            if (isNaN(fieldId)) {
                return acc;
            }

            const field = this._smartSearchService.getFieldById(fieldId);
            if (!field) {
                return acc;
            }

            const baseColumn = this._columnBuilder.getColumnDefinition(x, field, 'av');

            acc.push(baseColumn);
            return acc;
        }, []);

        const columns = [
            multiselectColDef,
            ...columnProperties
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setRowData(tableData);
    }

    private _clearTable(): void {
        if (!this._gridApi) {
            return;
        }

        this.gridTracker.clear();
        this._gridApi.setRowData([]);
    }

    private async _getGridRowIds(skip: number, take: number): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows: RowNode[] = model.rowsToDisplay.slice(skip, take + 1);
        return {
            data: rows.map((x) => {
                return x.data.n10908;
            })
        } as Compliance.QueryResultModel<number>;
    }
}
