import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import { ReportManagerModalInputParameters } from '../reportManagerModal.component';
@Component({
    selector: 'further-limit-panel-schedules-summary',
    templateUrl: './further.limit.panel.schedules.summary.component.html'
})
export class FurtherLimitPanelSchedulesSummaryComponent implements OnInit{

    @Input() report: ReportDetail;
    @Input() modalParameters?: ReportManagerModalInputParameters;

    formRevisions: Compliance.ReturnFormRevisionModel[] = [];
    private _schedules: Compliance.FormRevisionScheduleModel[];

    async ngOnInit(): Promise<void> {
        this._schedules = [
            {
                formRevisionScheduleId: -2,
                name: 'Reportable, Taxable'
            },
            {
                formRevisionScheduleId: -4,
                name: 'Reportable, Not Taxable'
            },
            {
                formRevisionScheduleId: -3,
                name: 'Reportable Taxable and Not Taxable'
            },
            {
                formRevisionScheduleId: -1,
                name: 'Non-Reportable'
            }
        ] as Compliance.FormRevisionScheduleModel[];

        if (this.modalParameters){
            if (this.modalParameters.formRevisions){
                this.formRevisions = this.modalParameters.formRevisions;
            }

            if (this.modalParameters.schedules){
                this.modalParameters.schedules.forEach(item => this._schedules.push(item));
            }

            if (!this.report.criteria.formRevisionId && this.formRevisions.length === 1){
                this.report.criteria.formRevisionId = this.formRevisions[0].formRevisionId;
            }
        }

        if (this.report.criteria.scheduleId){
            const selectedSchedule = this._schedules.find(i => i.formRevisionScheduleId === this.report.criteria.scheduleId);
            this.report.criteria.scheduleName = selectedSchedule ? selectedSchedule.name : null;
        }
    }

    get isSystemControlled(): boolean {
        return this.report.isSystemReport;
    }

    get schedules(): Compliance.FormRevisionScheduleModel[]{
        return this._schedules;
    }

    get scheduleId(): number {
        return this.report.criteria.scheduleId;
    }

    set scheduleId(value: number) {
        this.report.criteria.scheduleId = value;
        const selectedSchedule = this._schedules.find(i => i.formRevisionScheduleId === value);
        this.report.criteria.scheduleName = selectedSchedule ? selectedSchedule.name : null;
    }

    get scheduleSelectorDisabled(): boolean {
        return this.modalParameters && this.modalParameters.filingBatchId && !this.report.criteria.formRevisionId;
    }
}
