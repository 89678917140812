import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { LinkedParcelsListParams, LinkedParcelsListComponent } from './Linked-Parcels-List/linkedParcelsList.component';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { LinkedParcelRepository } from './linkedParcel.repository';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { HelpService } from '../../../UI-Lib/Help-Tooltip';
import { LINKED_PARCELS_HELP } from './linkedParcels.component.help';
import { UpgradeNavigationServiceHandler } from '../../../Common/Routing/upgrade-navigation-handler.service';

@Component({
    selector: 'linked-parcels',
    templateUrl: './linkedParcels.component.html'
})
export class LinkedParcelsComponent implements OnInit {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _linkedParcelRepository: LinkedParcelRepository,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _routerService: UpgradeNavigationServiceHandler,
        private readonly _helpService: HelpService) { }

    @Input() parcelId: number;
    @Input() parcelAcctNum: string;
    @Input() linkedParcelCount: number;
    @Input() masterParcelId: number;
    @Input() masterParcelAcctNum: string;
    @Input() isDisabled: boolean;

    private _linkageTypes: Core.LinkageTypeModel[];


    ngOnInit(): void {
        this._helpService.setContent(LINKED_PARCELS_HELP);
    }

    async showParcels(): Promise<void> {
        if (!this._linkageTypes) {
            const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

            try {
                this._linkageTypes = await lastValueFrom(this._linkedParcelRepository.getLinkageTypes());
            }
            finally {
                busyRef.hide();
            }
        }

        const params: LinkedParcelsListParams = {
            parcelId: this.parcelId,
            parcelAcctNum: this.parcelAcctNum,
            masterParcelId: this.masterParcelId,
            masterParcelAcctNum: this.masterParcelAcctNum,
            linkageTypes: this._linkageTypes,
            linkedParcelCount: this.linkedParcelCount,
            editMode: false,
        };

        await this._modalService.showAsync(LinkedParcelsListComponent, params, 'modal-lg');

        return Promise.resolve();
    }

    navigateToParcel(parcelId: number): void {
        this._routerService.go('parcelRedirect', { 'entityID': parcelId });
    }
}
