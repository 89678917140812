import * as _ from 'lodash';

export class AssessmentSummaryInfo {
    constructor(
        private readonly _model: Compliance.AllocationAssessmentSummaryModel) {
    }

    private _isSelected: boolean;

    get model(): Compliance.AllocationAssessmentSummaryModel{
        return this._model;
    }

    get FMV(): number{
        return this._model.fmv;
    }

    get allocationAmount(): number {
        return this._model.allocationAmount;
    }

    get assetsCount(): number {
        return this._model.assetsCount;
    }

    get summaryType(): Compliance.AllocationAssessmentSummaryTypeEnum{
        return this._model.summaryType;
    }

    get allocationAnnualAssessmentId(): number{
        return this._model.allocationAnnualAssessmentId;
    }

    get annualAssessmentId(): number{
        return this._model.annualAssessmentId;
    }

    get parcelAccountNumber(): string{
        return this.model.parcelAccountNumber;
    }

    get title(): string{
        let title: string;
        const emptyValuePlaceholder: string = '---';

        if (this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Total){
            title = 'Total';
        } else if (this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned){
            title = 'Unassigned';
        } else if (this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom){
            title = this._model.description || emptyValuePlaceholder;
        } else {
            title = `${this._model.parcelAccountNumber || emptyValuePlaceholder} (${this._model.annualAssessmentRevisionDesc || emptyValuePlaceholder})`;
        }

        return title;
    }

    get taxAuthorityName(): string {
        return this._model.taxAuthorityName;
    }

    get variance(): number{
        return (this._model.fmv || 0) - (this._model.allocationAmount || 0);
    }

    get isLocked(): boolean{
        return !!this._model.lockDate;
    }

    set isLocked(value: boolean) {
        this._model.lockDate = value ? new Date() : null;
    }

    get components(): Compliance.AllocationAssessmentSummaryComponentModel[]{
        return _.sortBy(this._model.components, ['assessmentComponentTypeId']);
    }

    get canLock(): boolean {
        return this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal &&
            !this.isComitted &&
            this.isActual &&
            this.variance === 0 &&
            !this.isTaxAuthority;
    }

    get canReleaseLock(): boolean {
        return this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal && this.isLocked;
    }

    set isSelected(value: boolean) {
        this._isSelected = value;
    }

    get isSelected(): boolean {
        return this._isSelected;
    }

    get canSelect(): boolean {
        return this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal ||
            this._model.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom;
    }

    get assessorName(): string {
        return this._model.assessorName;
    }

    get canDelete(): boolean {
        return this.assetsCount === 0 && this.isTaxAuthority;
    }

    get isActual(): boolean {
        return this._model.isActual;
    }

    get companyId(): number {
        return this._model.companyId;
    }

    get siteId(): number {
        return this._model.siteId;
    }

    get annualYearId(): number {
        return this._model.annualYearId;
    }

    get parcelId(): number {
        return this._model.parcelId;
    }

    get description(): string {
        return this._model.description;
    }

    get parcelAccountNumber2(): string {
        return this._model.parcelAccountNumber2;
    }

    get parcelAddress1(): string {
        return this._model.parcelAddress1;
    }

    get parcelAddress2(): string {
        return this._model.parcelAddress2;
    }
    get parcelCity(): string {
        return this._model.parcelCity;
    }

    get parcelState(): string {
        return this._model.parcelState;
    }

    get parcelZip(): string {
        return this._model.parcelZip;
    }

    static isEqual(item1: AssessmentSummaryInfo, item2: AssessmentSummaryInfo): boolean {
        return !!item1 && !!item2 && (
            item1.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal &&
            item2.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Normal &&
            item1.allocationAnnualAssessmentId === item2.allocationAnnualAssessmentId ||
            item1.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom &&
            item2.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Phantom &&
            item1.allocationAnnualAssessmentId === item2.allocationAnnualAssessmentId ||
            item1.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned &&
            item2.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Unassigned &&
            item1.summaryType === item2.summaryType ||
            item1.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Total &&
            item2.summaryType === Compliance.AllocationAssessmentSummaryTypeEnum.Total &&
            item1.summaryType === item2.summaryType
        );
    }

    get hasActiveAppeal(): boolean {
        return this._model.hasActiveAppeal;
    }

    get isTaxAuthority(): boolean {
        return !!this._model.taxAuthorityId;
    }

    get taxAuthoritiesCount(): number {
        return this._model.taxAuthoritiesCount;
    }

    get sortByName(): string {
        return `${this.title  }${`Z${  this.taxAuthorityName || ''}`}`;
    }

    get isMarkedAsReviewed(): boolean {
        return this._model.isMarkedAsReviewed;
    }

    get isComitted(): boolean {
        return this._model.isCommitted;
    }
}
