import { NgModule } from '@angular/core';
import { WeissmanAbsoluteUrlPipe } from './Absolute-Url/absolute.url.pipe';
import { WeissmanYesNoPipe, WeissmanTrueFalsePipe } from './Boolean/boolean-pipe';
import { WeissmanDateTimeFormatPipe, WeissmanDateFormatPipe, WeissmanMonthDayDateFormatPipe, CommentDateTimeFormat } from './Date-Format/date-formatting.pipe';
import { FilterPipe, WeissmanFilterPipe } from './Filter/filter-pipe';
import { WeissmanKeyValueDisplayPipe } from './Key-Value-Display/keyValueDisplay-pipe';
import { SortByPipe } from './Order-By/orderBy.pipe';
import { SplitListPipe } from './Split-List/splitList.pipe';
import { TimeAgoPipe } from './Time-Ago/timeAgo.pipe';
import { WeissmanTimeSpanPipe } from './Time-Span/timeSpan.pipe';

@NgModule({
    declarations: [
        WeissmanDateTimeFormatPipe,
        WeissmanDateFormatPipe,
        WeissmanMonthDayDateFormatPipe,
        WeissmanYesNoPipe,
        WeissmanTrueFalsePipe,
        WeissmanKeyValueDisplayPipe,
        WeissmanFilterPipe,
        WeissmanTimeSpanPipe,
        WeissmanAbsoluteUrlPipe,
        SortByPipe,
        TimeAgoPipe,
        FilterPipe,
        SplitListPipe,
        CommentDateTimeFormat
    ],
    exports: [
        WeissmanDateTimeFormatPipe,
        WeissmanDateFormatPipe,
        WeissmanMonthDayDateFormatPipe,
        WeissmanYesNoPipe,
        WeissmanTrueFalsePipe,
        WeissmanKeyValueDisplayPipe,
        WeissmanFilterPipe,
        WeissmanTimeSpanPipe,
        WeissmanAbsoluteUrlPipe,
        SortByPipe,
        TimeAgoPipe,
        FilterPipe,
        SplitListPipe,
        CommentDateTimeFormat
    ],
    // Allows for use as dependency injection in constructor
    providers: [
        WeissmanDateTimeFormatPipe,
        WeissmanDateFormatPipe,
        WeissmanMonthDayDateFormatPipe,
        WeissmanYesNoPipe,
        WeissmanTrueFalsePipe,
        WeissmanKeyValueDisplayPipe,
        WeissmanFilterPipe,
        WeissmanTimeSpanPipe,
        WeissmanAbsoluteUrlPipe,
        SortByPipe,
        TimeAgoPipe,
        FilterPipe,
        SplitListPipe,
        CommentDateTimeFormat
    ]
})
export class SharedPipesModule { }
