<div class="entity-header">
</div>
<div *ngIf="!serverAction">
	<div class="row equal-panel">
		<div class="col-xl-6">
			<company-info-panel [company]="company" [entity]="entity"></company-info-panel>
		</div>
		<div class="col-xl-6">
			<company-exploration [company]="company"></company-exploration>
		</div>
	</div>
	<div>
		<div class="row">
			<div class="col-xl-12">
				<client-services-panel [entity]="entity"
					[company]="company"
					(ppReturnPreparationAllowedChanged)="setShowComplianceInfo($event)">
				</client-services-panel>
			</div>
		</div>
        <div class="row" *ngIf="showComplianceInfo">
            <div class="col-xl-12">
                <company-compliance [company]="company"
					[isTopLevelCompany]="isTopLevel"
					[parentCompany]="parentCompany">
				</company-compliance>
            </div>
        </div>
        <div class="row" *ngIf="showComplianceInfo">
            <div class="col-xl-12 panel-container">
                <compliance-company-return-settings-panel
					[companyId]="company.companyID"
					[allowEdit]="hasWritePermission">
				</compliance-company-return-settings-panel>
            </div>
        </div>
		<div class="row" *ngIf="hasPrivateItemsPermission">
			<div class="col-xl-12">
				<contract-terms-panel-company [entity]="entity" [isTopLevel]="isTopLevel"></contract-terms-panel-company>
			</div>
		</div>
        <div class="row" *ngIf="hasPrivateItemsPermission">
            <div class="col-xl-12">
                <invoice-panel [company]="entity" [allowEdit]="allowInvoiceEdit"></invoice-panel>
            </div>
        </div>
        <div class="row">
			<div class="col-xl-12">
				<company-settings [company]="company"
					[companyId]="company.companyID"
					[allowEdit]="hasWritePermission"
					[isTopLevelCompany]="isTopLevel"
					[parentCompany]="parentCompany">
				</company-settings>
			</div>
		</div>
		<div class="row">
			<div class="col-xl-12">
				<contacts-panel [entity]="entity"></contacts-panel>
			</div>
		</div>
		<div class="row" *ngIf="isTopLevel">
			<div class="col-xl-12">
				<div class="card panel-flat-color secondary-panel">
					<property-characteristics-overrides></property-characteristics-overrides>
				</div>
			</div>
		</div>
        <div>
            <div class="col-xl-12">
                <entity-property-characteristics [isCompany]="true"></entity-property-characteristics>
            </div>
        </div>
	</div>
</div>
