import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Input, OnDestroy,
    OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeHistoryModalLaunchService } from '../../Common/Change-History/change-history-modal-launch.service';
import { RestrictService, InstanceRights, Roles } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { EntityType } from '../../constants.new';
import { InstanceRepository } from '../../Entity/Instance/instance.repository';
import { NavigationService } from '../../Layout/navigation.service';
import { EntityPropertyCharacteristicsService } from './entity.property.characteristics.service';
import { DescriptorEntity, EntityDescriptorModelUI } from '../descriptor.model';

const warningMessage = 'Editing is in progress. Are you sure you want to leave?';

@Component({
    selector: 'entity-property-characteristics',
    templateUrl: './entity.property.characteristics.component.html'
})
export class EntityPropertyCharacteristicsComponent implements OnInit, OnDestroy {
    constructor(private readonly _navigationService: NavigationService,
        private readonly _upgradeNavService: UpgradeNavigationServiceHandler,
        private readonly _entityDescriptorService: EntityPropertyCharacteristicsService,
        private readonly _instanceRepository: InstanceRepository,
        private readonly _changeHistoryService: ChangeHistoryModalLaunchService,
        @Inject('$rootScope') private _rootScope: any,
        private readonly _toastr: ToastrService,
        private readonly _restrictService: RestrictService,
        private readonly _changeDetectorRef: ChangeDetectorRef) { }

    @Input() instanceId: number;
    @Input() isCompany?: boolean;

    @ViewChildren('formRow') rows: QueryList<ElementRef>;
    currentEntity: DescriptorEntity;
    allowMaximize: boolean = true;
    isMaximized: boolean = false;
    searchModel: Core.EntityDescriptorSearchModel;
    EntityTypes = EntityType;
    hasInstancePrivateView: boolean = false;
    hasWritePermission: boolean = false;

    descriptorsAlpha: EntityDescriptorModelUI[];
    descriptorCategories: {name: string; descriptors: EntityDescriptorModelUI[]; isOpen: boolean; categoryId: number}[];
    originalDescriptorsAlpha: EntityDescriptorModelUI[];
    originalDescriptorCategories: { name: string; descriptors: EntityDescriptorModelUI[]; isOpen: boolean; categoryId: number }[];

    isEditMode: boolean = false;
    editLock: boolean = false;
    sortByCategory: boolean = false;
    loading: boolean = false;
    saveAttempted: boolean = false;
    showBody: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    async ngOnInit() {
        if (!this.isCompany) {
            await this._loadPanel();
            this.showBody = true;
        }

        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$)).subscribe(editMode => {
            this.editLock = editMode;
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    descriptorsSelected(descriptors: Core.DescriptorDTO[]): void {
        // let addedDescriptorId: number;

        // if (descriptors.length == 1) {
        //     addedDescriptorId = descriptors[0].descriptorID;
        // }

        _.forEach(descriptors, x => {
            this.descriptorsAlpha.push({
                descriptorID: x.descriptorID,
                entityID: this.currentEntity.entityId,
                value: undefined,
                descriptor: x,
                rowVersion: [],
                isDeleted: false
            });
        });

        this._setDescriptorViews(this.descriptorsAlpha);
    }

    goToEditMode(event: MouseEvent): void {
        event.stopPropagation();

        this.originalDescriptorsAlpha = _.cloneDeep(this.descriptorsAlpha);
        this.originalDescriptorCategories = _.cloneDeep(this.descriptorCategories);
        this.isEditMode = true;

        this._navigationService.enableNavWarning(warningMessage);
    }

    cancel(event: MouseEvent): void {
        event.stopPropagation();

        this.descriptorsAlpha = this.originalDescriptorsAlpha;
        this.descriptorCategories = this.originalDescriptorCategories;
        this.isEditMode = false;

        this._navigationService.disableNavWarning();
    }

    async save(event: MouseEvent): Promise<void> {
        event.stopPropagation();

        this.saveAttempted = false;
        this._changeDetectorRef.detectChanges();
        let descriptorsToSave = this.sortByCategory ? _.flatMap(this.descriptorCategories, 'descriptors') : this.descriptorsAlpha;
        descriptorsToSave = _.reject(descriptorsToSave, 'isDeleted');

        const validationMessage = this._getValidationMessage(descriptorsToSave);

        if(validationMessage) {
            this.saveAttempted = true;
            this._toastr.error(validationMessage, '', {enableHtml: true});
            return;
        }

        try {
            this.loading = true;
            const descriptors = await this._entityDescriptorService.update(this.currentEntity.entityId, this.currentEntity.name, descriptorsToSave) as EntityDescriptorModelUI[];
            this._setDescriptorViews(descriptors);
            this.isEditMode = false;
            this._navigationService.disableNavWarning();
        } finally {
            this.loading = false;
        }
    }

    maximize(): void {
        this.isMaximized = true;
        this._broadcastMaximize();
    }

    unmaximize(): void {
        this.isMaximized = false;
        this._broadcastMaximize();
    }


    launchChangeHistoryModal(event: MouseEvent): void {
        event.stopPropagation();

        switch(this.currentEntity.entityTypeId) {
            case Core.EntityTypes.Site:
                this._changeHistoryService.openChangeHistoryModal('Site Characteristics', this.currentEntity.entityId, Core.EntityTypes.Site, 'descriptor');
                break;
            case Core.EntityTypes.Parcel:
                this._changeHistoryService.openChangeHistoryModal('Parcel Characteristics', this.currentEntity.entityId, Core.EntityTypes.Parcel, 'descriptor');
                break;
            case Core.EntityTypes.Assessor:
                this._changeHistoryService.openChangeHistoryModal('Assessor Characteristics', this.currentEntity.entityId, Core.EntityTypes.Assessor, 'descriptor');
                break;
            case Core.EntityTypes.Company:
                this._changeHistoryService.openChangeHistoryModal('Company Characteristics', this.currentEntity.entityId, Core.EntityTypes.Company, 'descriptor');
                break;
        }
    }

    async expandOrCollapse() {
        if (!this.isCompany) {
            return;
        }

        this.showBody = !this.showBody;

        if(this.showBody) {
            await this._loadPanel();
        }
    }

    private _setDescriptorViews(descriptors: EntityDescriptorModelUI[]): void {
        this.descriptorsAlpha = _.sortBy(descriptors, [x => x.descriptor.name.toLowerCase()]);
        this.descriptorCategories = _.chain(this.descriptorsAlpha)
            .groupBy(x => x.descriptor.categoryID)
            .toArray()
            .map(x => {
                const isOpenCategory = _.find(this.descriptorCategories, {categoryId: x[0].descriptor.categoryID});

                return {
                    categoryId: x[0].descriptor.categoryID,
                    name: x[0].descriptor.categoryName,
                    descriptors: x,
                    isOpen: isOpenCategory ? isOpenCategory.isOpen : true
                };
            })
            .sortBy('name')
            .value();
    }

    private _getCurrentEntity(): DescriptorEntity {
        const parcelId = +this._upgradeNavService.getQuerystringParam('parcelId');
        const siteId = +this._upgradeNavService.getQuerystringParam('siteId');
        const assessorId = +this._upgradeNavService.getQuerystringParam('id');
        const companyId = +this._upgradeNavService.getQuerystringParam('companyId');

        if (parcelId) {
            return {
                name: 'parcel',
                entityTypeId: Core.EntityTypes.Parcel,
                entityId: parcelId
            };
        } else if (siteId) {
            return {
                name: 'site',
                entityTypeId: Core.EntityTypes.Site,
                entityId: siteId
            };
        } else if (assessorId) {
            this.allowMaximize = false;
            return {
                name: 'assessor',
                entityTypeId: Core.EntityTypes.Assessor,
                entityId: assessorId
            };
        } else if (companyId) {
            this.allowMaximize = false;
            return {
                name: 'company',
                entityTypeId: Core.EntityTypes.Company,
                entityId: companyId
            };
        }
    }

    private async _doPermissionsCheck(): Promise<void> {
        if (this.currentEntity.entityTypeId == Core.EntityTypes.Site || this.currentEntity.entityTypeId == Core.EntityTypes.Parcel ||
            this.currentEntity.entityTypeId === Core.EntityTypes.Company) {
            this.instanceId = this.instanceId || await lastValueFrom(this._instanceRepository.getEntityInstanceId(this.currentEntity.name, this.currentEntity.entityId));
            const hasPrivateView = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW, this.instanceId);
            const hasPrivateEdit = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, this.instanceId);

            this.hasInstancePrivateView = hasPrivateEdit || hasPrivateView;

            if (hasPrivateEdit) {
                if (this.currentEntity.entityTypeId != Core.EntityTypes.Company) {
                    // check if user has site write permission
                    this.hasWritePermission = await this._restrictService.hasSitePermission(+this._upgradeNavService.getQuerystringParam('siteId'), Core.AccessRightsEnum.Write);
                } else {
                    this.hasWritePermission = await this._restrictService.hasCompanyPermission(+this._upgradeNavService.getQuerystringParam('companyId'), Core.AccessRightsEnum.Write);
                }
            }
        } else if (this.currentEntity.entityTypeId == Core.EntityTypes.Assessor) {
            this.hasWritePermission = this._restrictService.isInRole(Roles.ASSESSOREDIT);
        }
        else if (this.currentEntity.entityTypeId == Core.EntityTypes.Collector) {
            this.hasWritePermission = this._restrictService.isInRole(Roles.COLLECTOREDIT);
        }
    }

    private _getValidationMessage(descriptorsToSave: EntityDescriptorModelUI[]): string {
        const missingFields = _.some(descriptorsToSave, x => x.descriptor.fieldTypeID != Core.DescriptorFieldTypes.YesNo && !x.value && Number(x.value) !== 0);

        if(missingFields) {
            return 'Please fill in the missing fields';
        }

        const errors: string[] = [];

        const numberOutOfRange = _.chain(descriptorsToSave)
            .filter(x => x.descriptor.fieldTypeID == Core.DescriptorFieldTypes.Number)
            .some(y => {
                return (y.descriptor.validation.minValue && +y.value < y.descriptor.validation.minValue)
                    || (y.descriptor.validation.maxValue && +y.value > y.descriptor.validation.maxValue);
            })
            .value();

        if(numberOutOfRange) {
            errors.push('At least one number is out of range');
        }

        const datesOutOfRange = _.chain(descriptorsToSave)
            .filter(x => x.descriptor.fieldTypeID == Core.DescriptorFieldTypes.Date)
            .some(y => {
                return (y.descriptor.validation.minValue && new Date(y.value).getUTCFullYear() < y.descriptor.validation.minValue)
                    || (y.descriptor.validation.maxValue && new Date(y.value).getUTCFullYear() > y.descriptor.validation.maxValue);
            })
            .value();

        if(datesOutOfRange) {
            errors.push('At least one date is out of range');
        }

        return errors.join('<br />');
    }

    private _broadcastMaximize(): void {
        this._rootScope.$broadcast('maximize_change', {
            panelName: 'propertyCharacteristics',
            maximized: this.isMaximized
        });
    }

    private async _loadPanel() {
        this.currentEntity = this._getCurrentEntity();
        await this._doPermissionsCheck();

        this.loading = true;

        try {
            const descriptors = await this._entityDescriptorService.get(this.currentEntity.entityId, this.currentEntity.name) as EntityDescriptorModelUI[];
            this._setDescriptorViews(descriptors);
        } finally {
            this.loading = false;
        }
    }
}
