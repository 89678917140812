import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { WeissmanModalService } from '../../../Compliance/WeissmanModalService';
import { PaymentBatchAddPaymentsModalComponent } from '../Add-Payments-Modal/addPaymentsToBatchModal.component';
import { RefreshComponents } from '../paymentBatchDetails.component';
import { CheckImportModalComponent } from '../Check-Import-Modal/checkImportModal.component';
import { PaymentBatchService } from '../../paymentBatch.service';
import { BusyIndicatorService, IBusyIndicatorConfig } from 'src/app/Busy-Indicator';
import { Subject, takeUntil } from 'rxjs';
import { FinalizeCheckImportModalComponent } from '../Check-Import-Modal/Finalize/finalizeCheckImportModal.component';
import { cloneDeep } from 'lodash';
import { NavigationService } from '../../../Layout/navigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'payment-batch-summary',
    templateUrl: 'paymentBatchSummary.component.html',
    styleUrls: ['paymentBatchSummary.component.scss']
})
export class PaymentBatchSummaryComponent implements OnDestroy {
    constructor(
        private readonly _modalService: WeissmanModalService,
        private readonly _paymentBatchService: PaymentBatchService,
        private readonly _navigationService: NavigationService,
        private readonly _toastr: ToastrService,
        private readonly _busyIndicatorService: BusyIndicatorService
    ) { }

    @Input() paymentBatch: Core.PaymentBatchModel;
    @Input() isTransmittal: boolean;
    @Input() workflowStatus: Core.PaymentBatchDetailsWorkflowStatusModel;
    @Input() isPaymentGridActive: boolean;

    @Output() refreshPage: EventEmitter<RefreshComponents> = new EventEmitter<RefreshComponents>();

    editMode: boolean = false;

    private destroy$: Subject<void> = new Subject();
    private _originalPaymentBatch: Core.PaymentBatchModel;

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    edit() {
        this._originalPaymentBatch = cloneDeep(this.paymentBatch);

        this.editMode = true;
        this._navigationService.enableNavWarning('Editing is in progress. Are you sure you want to leave?');
    }

    get canModifyDescription() : boolean {
        return this.editMode && this.workflowStatus?.canModifyDescription;
    }

    get canModifyFundedAmount() : boolean {
        return this.editMode && this.workflowStatus?.canModifyFundedAmount;
    }

    async save() {
        if(!this.paymentBatch.description) {
            this._toastr.error('Description cannot be blank');
            return;
        }

        const busyRef = this._busyIndicatorService.show({message: 'Saving'});
        try {
            this.paymentBatch = await this._paymentBatchService.savePaymentBatchDetails(this.paymentBatch.paymentBatchId, this.paymentBatch);
            this._exitEditMode();
        } finally {
            busyRef.hide();
        }
    }

    cancel() {
        this._exitEditMode();
        this.paymentBatch = this._originalPaymentBatch;
    }

    async launchAddPaymentsModal(): Promise<void> {
        const params = this.paymentBatch.paymentBatchId;
        const result = await this._modalService.showAsync(
            PaymentBatchAddPaymentsModalComponent,
            params,
            'modal-xl modal-no-max-width'
        );

        if(result?.succeeded) {
            this.refreshPage.emit({status: true, payments: true, packages: false});
        }
    }

    async importChecks() {
        const file = await this._modalService.showAsync(CheckImportModalComponent, this.paymentBatch.paymentBatchId, 'modal-md');

        if (!file) return;


        const formData = new FormData();

        formData.append('file', file);

        const longRunningProcessId = await this._paymentBatchService.startPaymentBatchCheckImport(
            this.paymentBatch.paymentBatchId, formData
        );
        const identifier = this._busyIndicatorService.generateUniqueMessageIdentifier();

        const busyConfig: IBusyIndicatorConfig = {
            identifier,
            longRunningProcessId,
            title: 'Check Import',
            message: 'Import Uploading',
            hasProgressBar: false,
            // canDismiss: !this.allocationId
        };

        const busyRef = this._busyIndicatorService.show(busyConfig);

        busyRef.onProgressBarComplete().pipe(takeUntil(this.destroy$)).subscribe((succeeded: boolean) => {
            busyRef.hide();
            if (succeeded) {
                this.refreshPage.emit({status: true, payments: false, packages: false});
            }

            this.destroy$.next();
        });

        busyRef.onDismiss().pipe(takeUntil(this.destroy$)).subscribe(() => {
            busyRef.hide();
            this.destroy$.next();
        });

        return busyRef;
    }

    async finalizeCheckImport() {
        const params = {
            paymentBatchId: this.paymentBatch.paymentBatchId
        };

        const didFinalize = await this._modalService.showAsync(FinalizeCheckImportModalComponent, params, 'modal-xl');

        const packages = false;
        const status = true;
        let payments = false;

        if(didFinalize) {
            payments = true;
        }

        this.refreshPage.emit({status, payments, packages});
    }

    private _exitEditMode() {
        this._navigationService.disableNavWarning();
        this.editMode = false;
    }
}