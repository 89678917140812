import { ContactShortDTO } from '../User/contact.short.dto.model';
import { Team } from '../Team/team.model';

export class TaskType {
    taskTypeID: number;
    taskSeriesTypeID: number;
    sequence: number;
    name: string;
    completedName: string;
    currentName: string;
    anticipatedItem: boolean;
    deliverableName: string;
    authorizationRequired: boolean;
}

export interface TaskSeriesType {
    anticipatedAttachmentTypeID: number;
    authorizationRequired: boolean;
    columnIds: number[];
    completedName: string;
    currentName: string;
    deliverableName: string;
    efAction: string;
    name: string;
    sequence: number;
    systemCompletes: boolean;
    systemDeletes: boolean;
    systemReassigns: boolean;
    systemRescinds: boolean;
    systemSkips: boolean;
    taskSeriesTypeAdvancedSearchDepthID: number;
    taskSeriesTypeAdvancedSearchDepthValue: number;
    taskSeriesTypeID: number;
    taskSeriesTypeSequence: number;
    taskTypeID: number;
    depthValuesToEnable: number[];
    fieldIdsToEnable: number[];
}

export class TaskSummary {
    completedDateTime: string;
    entityID: number;
    entityTypeID: number;
    seriesIsComplete: boolean;
    status: string;
    taskSeriesID: number;
}

export class TaskAuthorizationInfo {
    completeTask: boolean;
    rescindTask: boolean;
    reassignTask: boolean;
    insertTaskBefore: boolean;
    insertTaskAfter: boolean;
    deleteTask: boolean;
    skipTask: boolean;
}

export class Task {
    taskID: number;
    taskSeriesID: number;
    taskTypeID: number;
    dueDate: Date;
    assignedUserID: string;
    assignedTeamID: number;
    responsibleUserID: string;
    responsibleTeamID: number;
    workflowUserTypeID: number;
    completedDateTime: Date;
    completedByUserID: string;
    skipped: boolean;
    sequence: number;
    name: string;
    isOpen: boolean;
    assignedUser: ContactShortDTO;
    responsibleUser: ContactShortDTO;
    completedByUser: ContactShortDTO;
    taskType: TaskType;
    assignedTeam: Team;
    responsibleTeam: Team;
    taskAuthorizationInfo: TaskAuthorizationInfo;
    pointsTo: number[];
    entityId: number;
    entityTypeID: number;
    deliverableName: string;
    assignedUserIsOverridden: boolean;
    assignedTeamIsOverridden: boolean;
    deliverableTaskID: number;
    isReady: boolean;
    rowVersion: string;
    userIsAuthorizedToChangeTask: boolean;
    //intakeBatch: IntakeBatch;
    //intakeItem: IntakeItem;
}

export class TaskDeadlineDTO {
    name: string;
    dateTime: Date;
}

export class TasksByEntityDTO {
    entityTypeID: number;
    entityID: number;
    tasks: Task[];
    deadlines: TaskDeadlineDTO;
}


//export class IntakeBatch {
//	intakeBatchID: number;
//	contactUserID: string; // GUID
//	contactTeamID: number;
//	batchNumber: string;
//}


//export class IntakeItem {
//	intakeItemID: number;
//	intakeBatchID: number;
//	itemNumber: string;
//	dueDate: Date;
//	companyID: number;
//	stateID: number;
//	assessorID: number;
//	collectorID: number;
//	taxYear: number;
//	entityTypeID: number;
//	entityID: number;
//	exceptionStatusID: number;
//}


// This enumeration should probably be defined in some sort of global constant system
// Also note, this is not all task types, just the ones we have special logic for in the UI
export enum TaskTypeID {
    ObtainDataEnterValueNotice = 1,
    FileAppeal = 6,
    ObtainHearingNotice = 7,
    ObtainDataEnterTaxBill = 11,
    ReviewPaymentDetails = 13,
    FilePPReturn = 21,
    TrackDataEnterRefund = 22,
    PrintApplication = 24,
    SubmitEvidence = 27,
    ObtainMagistrateRecommendation = 28,
    ObtainPaymentReceipt = 29,
    ObtainFinalDecision = 30,
    FileMiscFiling = 33,
    FileFreeport = 38,
    FileAbatement = 39,
    FilePollutionControl = 40,
    ObtainPostDeterminationValueNotice = 42,
    ConfirmHearing = 54
}

export interface EntityDeactivationValidation {
    canBeDeactivated: boolean;
    openAssessmentTaskCount: number;
    openFilingTaskCount: number;
    openTaxBillTaskCount: number;
    openPaymentBatchTaskCount: number;
    openInvoiceTaskCount: number;
}