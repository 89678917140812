
import { filter, takeUntil } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NavigationService } from '../../Layout/navigation.service';
import {Invoice} from './invoice.model';
import {InvoiceService} from './invoice.service';
import {MessageBoxService, MessageBoxButtons, MessageBoxResult} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { WeissmanDatetimeComponent } from '../../UI-Lib/Weiss-Datepicker/weissman-datetime.component';
import {UpgradeNavigationServiceHandler} from '../../Common/Routing/upgrade-navigation-handler.service';
import { TaskService } from '../../Task/task.service.upgrade';
import { EntityType, ContractServiceType } from '../../constants.new';
import { ToastrService } from 'ngx-toastr';
import { CreateInvoiceModalLaunchService } from '../../Common/Invoice/create-invoice-modal-launch.service';
import { ContractsInvoicesService } from '../../Common/Contracts-Invoices/contract-invoices.service';

declare const _: any;
declare const moment: any;

@Component({
    selector: 'invoice-panel',
    templateUrl: './invoice-panel.component.html'
})
export class InvoicePanelComponent implements OnInit, OnDestroy {
    constructor(public invoiceService: InvoiceService,
                private contractsInvoicesService: ContractsInvoicesService,
                private messageBoxService: MessageBoxService,
                private navigationService : UpgradeNavigationServiceHandler,
                private taskService: TaskService,
                private createInvoiceModalLaunchService: CreateInvoiceModalLaunchService,
                private toastr: ToastrService,
                private readonly _navigationService: NavigationService) { }

    @Input() company: {id: number};
    @Input() site: {siteID: number, companyID: number};
    @Input() allowEdit: boolean;

    editLock: boolean = false;
    isLoading: boolean = false;
    showBody: boolean = false;
    hasViewPermission: boolean = true;
    invoices: Invoice[];
    companyId: number;
    siteId: number;
    fromDate: Date;
    toDate: Date;
    parcelsReadyForInvoiceCount: number;
    sitesReadyForInvoiceCount: number;
    creatingAdminInvoices: boolean;
    annualYear: number;
    years: number[];
    ContractServiceType = ContractServiceType;
    contractTermsUpdateSubscription: Subscription;
    showSiteColumn: boolean = false;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        //Dima: for some reason [companyId]="vm.entity.id" binding does not work on Company page, so this is a workaround.
        this.companyId = this.company ? this.company.id : this.site.companyID;
        this.siteId  = this.site ? this.site.siteID : null;
        this.creatingAdminInvoices = false;
        this.contractTermsUpdateSubscription = null;

        if (this.siteId){
            this.fromDate = null;
            this.toDate = null;
        }
        else{
            this.fromDate = WeissmanDatetimeComponent.getMidnight(moment().subtract(90, 'day').toDate(), false, 'Central');

            //WK-4406 - changed this to null to prevent an issue where it looks like company page
            //isn't seeing site invoices due to due date range issues
            this.toDate = null;
        }

        this.years = [];
        this.annualYear = (new Date()).getFullYear();

        for (let i = this.annualYear + 5; i > this.annualYear - 11; i--) {
            this.years.push(i);
        }

        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$)).subscribe(editMode => {
            this.editLock = editMode;
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();

        if (this.contractTermsUpdateSubscription !== null) {
            console.log('Destroying');
            this.contractTermsUpdateSubscription.unsubscribe();
        }
    }


    expandOrCollapse(): void {
        this.showBody = !this.showBody;

        if(this.showBody) {
            this.loadPanel();
        }
    }

    loadPanel(): void {
        this.isLoading = true;

        const actions = [this.invoiceService.getInvoices(this.companyId, this.siteId, this.fromDate, this.toDate).then(result => {
            this.invoices = result;

            this.showSiteColumn = _.some(this.invoices, 'siteName');
        })];

        if (this.contractTermsUpdateSubscription !== null) {
            this.contractTermsUpdateSubscription.unsubscribe();
        }
        if (this.siteId) {
            this.contractTermsUpdateSubscription = this.contractsInvoicesService.contractTermsUpdateObservable.pipe(
                filter(event => event.entityId == this.siteId && event.entityType == EntityType.Site))
                .subscribe(() => this.updateSiteReadyCounts());
            actions.push(this.updateSiteReadyCounts());
        }
        else {
            this.contractTermsUpdateSubscription = this.contractsInvoicesService.contractTermsUpdateObservable.pipe(
                filter(event => event.entityId == this.companyId && event.entityType == EntityType.Company))
                .subscribe(() => this.updateCompanyReadyCounts());
            actions.push(this.updateCompanyReadyCounts());
        }

        Promise.all(actions).then(result => {
            this.isLoading = false;
        }).catch(() => { this.isLoading = false; });
    }

    cancelCreateAdminInvoices(): void {
        this.creatingAdminInvoices = false;
        this._navigationService.disableNavWarning();
    }

    startCreateAdminInvoices(): void {
        this.creatingAdminInvoices = true;
        this._navigationService.enableNavWarning('You are in the process of creating invoices. Are you sure you want to leave this page?');
    }

    createAdminInvoices(): void {
        this.isLoading = true;
        this.invoiceService.bulkInvoicePreCheck(this.companyId, this.annualYear).then(preCheckResult => {
            if (preCheckResult.siteCount < 1) {
                this.toastr.warning('No sites can be invoiced for that tax year (most likely there are no matching contract terms available)');
                this.isLoading = false;
            }
            else {
                let title: string = undefined;
                let message = `Are you sure you want to create ${  preCheckResult.siteCount  } invoice(s)?`;
                if (preCheckResult.existingInvoiceCount > 0) {
                    title = 'Existing admin invoices detected';
                    message = `WARNING: There appear to be ${  preCheckResult.existingInvoiceCount  } existing admin invoice(s). ${  message}`;
                }
                this.messageBoxService.open({
                    message: message,
                    buttons: MessageBoxButtons.YesNo,
                    title: title
                }).then(result => {
                    if (result == MessageBoxResult.Yes) {
                        this.creatingAdminInvoices = false;
                        this._navigationService.disableNavWarning();
                        this.invoiceService.createCompanyAdminInvoices(this.companyId, this.annualYear)
                            .then(x => this.handleInvoiceCreate(x, false))
                            .catch(() => this.loadPanel());
                    }
                    else {
                        this.isLoading = false;
                    }
                });
            }
            //this.messageBoxService.confirmYesNo("Are you sure you want to create " + readyCount + " invoices?")
        });
    }

    createInvoices() : void {
        this.messageBoxService.open({
            title: 'WARNING',
            message: 'Selected Invoice Appeal Task(s) will be marked complete and their related appeal savings converted into saved contingency invoices ready to process as drafts. Proceed?',
            buttons: MessageBoxButtons.OKCancel
        }).then((result) => {
            switch(result) {
                case MessageBoxResult.OK:
                    this.invoiceService.createInvoices(this.siteId)
                        .then((x : any) => this.handleInvoiceCreate(x, true))
                        .catch(() => this.loadPanel());
                    break;
                case MessageBoxResult.No:
                    break;
            }
        });
    }

    createAdHocInvoice(): void {
        this.createInvoiceModalLaunchService.openCreateInvoiceModal((invoiceId: number) => {
            // Redirect to new invoice on create. If we change our minds and want it to just reload the invoice
            // panel afterwards, we could remove the nav code and replace it with this:
            //this.toastr.success('Ad Hoc Invoice Created');
            //this.loadPanel();
            if (this.siteId) {
                this.navigationService.go('siteInvoice', {
                    companyID: this.companyId,
                    siteID: this.siteId,
                    invoiceID: invoiceId
                });
            }
            else {
                this.navigationService.go('companyInvoice', {
                    companyID: this.companyId,
                    invoiceID: invoiceId
                });
            }
        }, this.companyId, this.siteId);
    }

    rowSelected(invoice: Invoice) : void {
        if (this.siteId){
            this.navigationService.go('siteInvoice', {companyID: this.companyId, siteID: this.siteId, invoiceID: invoice.invoiceId});
        }
        else{
            console.log('navigate to company');
            this.navigationService.go('companyInvoice', {companyID: this.companyId, invoiceID: invoice.invoiceId});
        }
    }

    launchTaskModal($event, invoice: Invoice) : void{
        event.stopPropagation();
        this.taskService.launchTaskModal(invoice.invoiceId, EntityType.Invoice, true);
    }

    private updateSiteReadyCounts(): Promise<void> {
        this.parcelsReadyForInvoiceCount = null;
        return this.invoiceService.getReadyInvoiceAppealSavingTasksCount(this.siteId).then(result => {
            this.parcelsReadyForInvoiceCount = result;
        });
    }
    private updateCompanyReadyCounts(): Promise<void> {
        this.sitesReadyForInvoiceCount = null;
        return this.invoiceService.getReadyAdminFeeInvoiceCount(this.companyId).then(result => {
            this.sitesReadyForInvoiceCount = result;
        });
    }

    private handleInvoiceCreate(result: any, isTask: boolean): void {
        const successfulResults = _.filter(result.operationResult, function (x : any) {
            return !x.errorMessage && !x.warningMessage;
        });
        const errorResults = _.filter(result.operationResult, function (x : any) {
            return x.errorMessage || x.warningMessage;
        });

        if (errorResults.length < 1) {
            if (isTask) {
                this.toastr.success(`Successfully processed ${  successfulResults.length  } tasks`);
            }
            else {
                this.toastr.success(`Successfully created ${  successfulResults.length  } invoices`);
            }
        }
        else {
            const warnings: string[] = _.uniqBy(_.map(_.filter(errorResults, function (error) {
                return !!error.warningMessage;
            }), 'warningMessage'));

            let errors: string[] = _.uniqBy(_.map(_.filter(errorResults, function (error) {
                return !error.warningMessage;
            }), 'errorMessage'));

            let showError = true;
            // If we got error messages but no user-friendly warning messages, don't bother with the
            // error-notification modal; the toast will be sufficient for that case
            if (_.some(warnings)) {
                // Do not show the original message for an error; any user-readable errors should come back
                // as warnings
                if (_.some(errors)) {
                    errors = ['An unexpected error has occurred'];
                }
                this.taskService.showErrorNotificationModal(errors, warnings,
                    'The following error(s) were encountered attempting to create invoices');
                showError = false;
            }

            if (_.some(successfulResults)) {
                if (isTask) {
                    this.toastr.warning(`Only some task(s) successfully processed; ${  successfulResults.length  } succeeded and ${  errorResults.length  } failed`);
                }
                else {
                    this.toastr.warning(`Only some invoices(s) successfully created; ${  successfulResults.length  } succeeded and ${  errorResults.length  } failed`);
                }
                showError = false;
            }

            if(showError) {
                this.toastr.error('An unexpected error has occurred');
            }
        }

        this.loadPanel();
    }
}
