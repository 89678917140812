<ws-busy-indicator [message]="busyIndicatorMessageManager.message"
                   *ngIf="busyIndicatorMessageManager.count() > 0">
</ws-busy-indicator>

<ws-modal-wrapper
    headerLabel="Deactivate Instance - Confirm"
    acceptLabel="OK"
    auxButtonLabel="Revert"
    (auxButton)="revert()"
    (save)="save()"
    (cancel)="cancel()"
    [disableSave]="!form.valid">
    <form [formGroup]="form">
        <div style="display: flex; flex-direction: column">
            <div style="font-weight: 600; padding-bottom: 10px;">
                Please indicate why this instance is being deactivated.
            </div>
            <div>
                <textarea formControlName="reason" maxlength="{{maxReasonLength}}" rows="5" required [(ngModel)]="params.pendingReason"></textarea>
            </div>
            <div class="small" style="display: flex; flex-direction: row; justify-content: space-between">
                <div style="flex-shrink: 0;">Deactivation Initiated By: {{params.pendingUserName}} on {{params.pendingDateTime}}</div>
                <div style="flex-shrink: 0; padding-left: 15px;">{{reasonLength}} / {{maxReasonLength}}</div>
            </div>
            <br/>
            <div>
                <div>Instance will be deactivated.</div>
                <div>CSRs assigned to a consultant will be reassigned to the Client Account Handler, and all contacts will be removed.</div>
            </div>
        </div>
    </form>
</ws-modal-wrapper>
