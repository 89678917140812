<div class="ws-flex-container-vertical ws-stretch">
    <advanced-search [hasEditPermission]="hasEditPermission"
                     [search]="search"
                     [routeParams]="routeParams"
                     (executeSearch)="getGridData()"
                     (updateSearch)="updateSearch($event)"
                     (updateSearchResults)="updateSearchResults($event)">
    </advanced-search>

    <div class="row" *ngIf="search?.searchCriteria">
        <div class="col-lg-12 text-end search-button-group" [ngClass]="{ 'closed': hideCriteria }">
            <div class="btn-group dropup float-end">
                <button class="ace-btn btn-primary ws-no-margin lh-1" [disabled]="noOutputColumns" (click)="getGridData()">
                    <i class="fa fa-search"></i> Search
                </button>
                <button class="ace-btn btn-primary export-button" [disabled]="noOutputColumns || excelDownloading" (click)="exportToExcel()">
                    <i class="fa fa-file-excel-o m-0"></i>
                </button>
            </div>
        </div>
        <div class="col-lg-12 text-end criteria-button-group" [ngClass]="{ 'open': hideCriteria }">
            <div class="btn-group dropup float-end">
                <button class="ace-btn btn-primary py-2" (click)="showCriteria()">
                    <i class="fa fa-arrow-down"></i> Criteria
                </button>
            </div>
        </div>
    </div>

    <div class="row search-list ws-flex-grow" [ngClass]="{ 'open': hideCriteria }">
        <advanced-search-list class="ws-stretch-width"
                              [searchResults]="searchResults"
                              (exportToExcel)="exportToExcel()">
        </advanced-search-list>
    </div>
</div>

