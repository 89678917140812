import { Injectable } from '@angular/core';
import { NavigationService } from '../Layout/navigation.service';
import { AnnualDetailsNavigationEventService } from './annual-details-event.service';
import { AnnualDetailsService } from './annual-details.service';
import { AnnualDetailAssessment, AnnualDetailYear } from './Annual-Year/annual-year.model';
import { AppealService, AppealViewModel } from './Appeals/appeal.service';
import { Assessment } from './Assessments/assessment.model';
import { AssessmentService, AssessmentViewModel } from './Assessments/assessment.service';
import { ComplianceService, ComplianceViewModel } from './Compliance/compliance.service';
import { BillCluster } from './Taxes/bill-cluster.model';
import { BillClusterService, TaxesViewModel } from './Taxes/bill-cluster.service';
import { Bill } from './Taxes/bill.model';
import { AnnualBudgetService, AnnualBudgetViewModel } from './Budget/budget.details.service';

declare const _: any;

export enum ViewTarget { grid, appeal, assessment, tax, compliance, budget }

@Injectable()
export class AnnualDetailsNavigationService {
    constructor(private appealService: AppealService,
        private billClusterService: BillClusterService,
        private assessmentService: AssessmentService,
        private complianceService: ComplianceService,
        private annualBudgetService: AnnualBudgetService,
        private annualDetailsService: AnnualDetailsService,
        private navigationService: NavigationService,
        private navigationEvent: AnnualDetailsNavigationEventService) { }

    createNavigationState(years: AnnualDetailYear[], parcelID: number, masterParcelId: number, parcelLinkageTypeId: number, consolidatingTypeId: number, consolidatedParcelId: number, parcelAcctNum: string, stateID: number, assessorID: number, hasWritePermission: boolean): NavigationState {
        const navigationState = new NavigationState(years,
            this.appealService,
            this.billClusterService,
            this.assessmentService,
            this.complianceService,
            this.annualBudgetService,
            this.annualDetailsService,
            this.navigationService,
            this.navigationEvent,
            parcelID,
            masterParcelId,
            parcelLinkageTypeId,
            consolidatingTypeId,
            consolidatedParcelId,
            parcelAcctNum,
            stateID,
            assessorID,
            hasWritePermission);
        navigationState.initializeGrid();
        return navigationState;
    }
}

// As of this comment, we plan on two places an Annual Details entity can be edited; the parcel
// page and the document processing screen. Both of those locations will define some class for
// this interface so that detail entity components don't have to care how the edit state is
// implemented.
export interface AnnualDetailEditState {
    showEditControls: boolean;
    editMode: boolean;
    entityLoading: boolean;
    refreshGrid: boolean;
    refreshActivity: boolean;
    hasWritePermission: boolean;
    validationMessage: string;
    // Strikethrough for stale appeal data
    staleAppealSavings: boolean;
    staleAppealStatus: boolean;

    cancelHandler: () => void;
    saveHandler: () => Promise<any>;
    validationHandler: (callback: (boolean, string) => void) => void;

    setEditMode(editMode: boolean): void;
    // TODO: Since we're using an edit mode setter, we probably should remove editMode from
    // this interface and add a getter instead so we encapsulate edit mode. For now, anyone
    // who references AnnualDetailEditState should promise not to set editMode manually,
    // but instead use setEditMode
    setDirty(isDirty: boolean): void;
    getDirty(): boolean;
}

export class NavigationState implements AnnualDetailEditState {
    // The annual-details module is responsible for building this state initially
    constructor(
        // The AnnualDetailYear is the root model everything is based on
        public years: AnnualDetailYear[],
        // We need the following services
        private appealService: AppealService,
        private billClusterService: BillClusterService,
        private assessmentService: AssessmentService,
        private complianceService: ComplianceService,
        private annualBudgetService: AnnualBudgetService,
        private annualDetailsService: AnnualDetailsService,
        private navigationService: NavigationService,
        private navigationEvent: AnnualDetailsNavigationEventService,
        // Finally there's some data we need from the parcel
        private parcelID: number,
        private masterParcelId: number,
        private parcelLinkageTypeId: number,
        private consolidatingTypeId: number,
        private consolidatedParcelId: number,
        private parcelAcctNum: string,
        private stateID: number,
        private assessorID: number,
        public hasWritePermission: boolean) { }

    viewTarget: ViewTarget;
    currentAppealVM: AppealViewModel;
    currentAssessmentVM: AssessmentViewModel;
    currentTaxesVM: TaxesViewModel;
    currentComplianceVM: ComplianceViewModel;
    currentAnnualBudgetVM: AnnualBudgetViewModel;
    currentYear: AnnualDetailYear;
    currentRevision: AnnualDetailAssessment;
    isDirty: boolean;
    refreshActivity: boolean;
    entityLoading: boolean;
    refreshGrid: boolean;
    editMode: boolean;
    showEditControls: boolean;
    validationMessage: string;
    staleAppealSavings: boolean;
    staleAppealStatus: boolean;
    toggleEditHandler: (editMode: boolean) => void;
    cancelHandler: () => void;
    saveHandler: () => Promise<any>;
    resetYearsHandler: () => Promise<void>;
    validationHandler: (callback: (boolean, string) => void) => void;

    setDirty(isDirty: boolean) {
        if (this.isDirty !== isDirty) {
            this.isDirty = isDirty;
            if (this.isDirty) {
                this.navigationService.enableNavWarning('You have unsaved changes; if you continue, these changes will not be saved');
            }
            else {
                this.navigationService.disableNavWarning();
            }
        }
    }

    getDirty(): boolean {
        return this.isDirty;
    }

    setEditMode(editMode: boolean): void {
        this.editMode = editMode;
        if (this.toggleEditHandler) {
            this.toggleEditHandler(editMode);
        }
    }

    initializeGrid(): void {
        this.resetVMs();
        this.viewTarget = ViewTarget.grid;
    }

    goToGrid(): Promise<void> {
        return this.annualDetailsService.preNavigateWarn(this).then(() => {
            if (this.refreshGrid) {
                this.entityLoading = true;
                this.resetYearsHandler();
            }
            this.initializeGrid();

        }, () => null);
    }

    goToAppeals(year: AnnualDetailYear, revision?: AnnualDetailAssessment, defaultAppealID?: number): Promise<AppealViewModel> {
        return this.annualDetailsService.preNavigateWarn(this).then(() => {
            this.entityLoading = true;
            this.currentYear = year;
            if (revision === undefined) {
                this.currentRevision = year.annualGridDetails[0];
            }
            else {
                this.currentRevision = revision;
            }

            return this.appealService.getAppealViewModelByYear(year, this.parcelID, this.parcelAcctNum, this.stateID, defaultAppealID).then((appealViewModel: AppealViewModel) => {
                this.resetVMs();
                this.currentAppealVM = appealViewModel;
                this.viewTarget = ViewTarget.appeal;
                // If a particular revision was selected, we'll find the relevant appeal and select it here
                this.appealService.setAppealByAssessmentID(this.currentAppealVM, this.currentRevision.annualAssessmentID);
                // Set up edit state for appeals
                this.cancelHandler = () => {
                    this.appealService.cancelAppealEdit(this.currentAppealVM);
                };
                this.saveHandler = () => {
                    this.entityLoading = true;
                    return this.appealService.saveAppealFromViewModel(this.currentAppealVM).then((result) => {

                        this.appealService.loadTaskSummary(this.currentAppealVM);
                        this.currentAppealVM.assignFromExistingAppeal(result);

                        if (this.refreshActivity) {
                            this.navigationEvent.refreshActivityData();
                        }
                        this.navigationEvent.refreshAnnualYear();
                        this.refreshActivity = false;
                        this.entityLoading = false;

                        const toastMessage = result.additionalNotificationMessage;
                        result.additionalNotificationMessage = null;

                        return toastMessage;
                    }).catch(reason => {
                        this.entityLoading = false;
                        throw reason;
                    });
                };
                this.toggleEditHandler = () => {
                    this.appealService.toggleAppealEdit(this.currentAppealVM, this.editMode);
                };
                this.validationHandler = (callback: (boolean, string) => void) => {
                    this.currentAppealVM.validate(callback);
                };

                this.entityLoading = false;
                return appealViewModel;
            });
        }, () => undefined as AppealViewModel);
    }

    goToTaxes(year: AnnualDetailYear, billClusterID?: number, refundID?: number): Promise<TaxesViewModel> {
        return this.annualDetailsService.preNavigateWarn(this).then(() => {
            this.entityLoading = true;
            this.currentYear = year;
            this.currentRevision = year.annualGridDetails[0];

            return this.billClusterService.getTaxesViewModelByYear(year, this.parcelID, this.parcelAcctNum)
                .then((taxesViewModel: TaxesViewModel) => {
                    this.resetVMs();
                    this.currentTaxesVM = taxesViewModel;
                    this.viewTarget = ViewTarget.tax;
                    this.entityLoading = false;

                    if (refundID) {
                        this.billClusterService.setCurrentTabByRefundID(taxesViewModel, refundID);
                    }
                    else if(taxesViewModel.model.length) {
                        this.billClusterService.setCurrentTab(taxesViewModel, billClusterID);
                    }

                    this.cancelHandler = () => {
                        this.billClusterService.cancelTaxesEdit(this.currentTaxesVM);
                    };
                    this.saveHandler = () => {
                        this.entityLoading = true;
                        return this.billClusterService.saveTabFromViewModel(this.currentTaxesVM)
                            .then((result: BillCluster) => {

                                const isNewPayment: boolean = _.some(this.currentTaxesVM.currentTab.bills, (bill: Bill) => {
                                    return !_.every(bill.payments, 'paymentID');
                                });

                                if (isNewPayment) {
                                    this.setDirty(false);
                                    this.goToTaxes(this.currentTaxesVM.annualYearModel, result.billClusterID);

                                    return;
                                }

                                if (this.currentTaxesVM.currentTab.refund) {
                                    this.currentTaxesVM.currentTab.refund.refundRowVersion = result.refundRowVersion;
                                }
                                else if (this.currentTaxesVM.currentTab.billClusterID) {
                                    this.currentTaxesVM.currentTab.rowVersion = result.rowVersion;
                                    this.currentTaxesVM.currentTab.bills = _.map(this.currentTaxesVM.currentTab.bills, bill => {
                                        const savedBill = _.find(result.bills, {billID: bill.billID});

                                        if(savedBill) {
                                            bill.rowVersion = savedBill.rowVersion;
                                        }

                                        return bill;
                                    });
                                }

                                if (this.refreshActivity) {
                                    this.navigationEvent.refreshActivityData();
                                }
                                this.navigationEvent.refreshAnnualYear();
                                this.refreshActivity = false;
                                this.entityLoading = false;
                            }).catch(reason => {
                                this.entityLoading = false;
                                throw reason;
                            });
                    };
                    this.toggleEditHandler = () => {
                        this.billClusterService.toggleTaxesEdit(this.currentTaxesVM, this.editMode);
                    };
                    this.validationHandler = (callback: (boolean, string) => void) => {
                        // This really shouldn't be in the validation handler,
                        // But saving taxes functions differently from assessments
                        // and appeals, so I'm adding it here instead of adding
                        // another handler for this specific case
                        if (this.currentTaxesVM.preEditModelBackup && !_.isEqual(this.currentTaxesVM.preEditModelBackup, this.currentTaxesVM.currentTab)) {
                            this.refreshGrid = true;
                        }

                        this.currentTaxesVM.validate(callback);
                    };

                    return taxesViewModel;
                });
        }, () => undefined as TaxesViewModel);
    }

    goToAssessment(year: AnnualDetailYear, revision: AnnualDetailAssessment): Promise<AssessmentViewModel> {
        return this.annualDetailsService.preNavigateWarn(this).then(() => {
            this.entityLoading = true;
            this.currentRevision = revision;
            this.currentYear = year;

            return this.assessmentService.getAssessmentViewModelByID(revision.annualAssessmentID, this.assessorID, this.parcelID, this.parcelAcctNum, year)
                .then((assessmentVM: AssessmentViewModel) => {
                    this.resetVMs();

                    this.currentAssessmentVM = assessmentVM;
                    this.viewTarget = ViewTarget.assessment;

                    this.cancelHandler = () => {
                        this.assessmentService.cancelAssessmentEdit(this.currentAssessmentVM);
                    };

                    this.saveHandler = () => {
                        this.entityLoading = true;
                        return this.assessmentService.saveAssessmentFromViewModel(this.currentAssessmentVM).then((newAssessment: Assessment) => {
                            this.currentAssessmentVM.assignFromExistingAssessment(newAssessment);
                            this.currentAssessmentVM.recalculateVariables();

                            if (this.refreshActivity) {
                                this.navigationEvent.refreshActivityData();
                            }
                            this.navigationEvent.refreshAnnualYear();
                            this.refreshActivity = false;
                            this.entityLoading = false;
                        }).catch(reason => {
                            this.entityLoading = false;
                            throw reason;
                        });
                    };

                    this.toggleEditHandler = () => {
                        this.assessmentService.toggleAssessmentEdit(this.currentAssessmentVM, this.editMode);
                    };

                    this.entityLoading = false;
                    return assessmentVM;
                });
        }, () => undefined as AssessmentViewModel);

    }

    goToCompliance(year: AnnualDetailYear, filingId?: number): Promise<ComplianceViewModel> {
        return this.annualDetailsService.preNavigateWarn(this).then(() => {
            this.entityLoading = true;
            this.currentYear = year;
            this.currentRevision = year.annualGridDetails[0];

            return this.complianceService.getComplianceViewModelByYear(year, this.parcelID, this.masterParcelId, this.parcelLinkageTypeId, this.parcelAcctNum, this.consolidatingTypeId, this.consolidatedParcelId, filingId)
                .then(async (complianceVM: ComplianceViewModel) => {
                    this.resetVMs();
                    this.currentComplianceVM = complianceVM;
                    this.viewTarget = ViewTarget.compliance;

                    this.cancelHandler = () => {
                        this.complianceService.cancelFilingEdit(this.currentComplianceVM);
                    };
                    this.saveHandler = () => {
                        this.entityLoading = true;
                        return this.complianceService.saveFilingFromViewModel(this.currentComplianceVM).then((result) => {
                            if (this.currentComplianceVM.currentFiling) {
                                this.currentComplianceVM.currentFiling.rowVersion = result.rowVersion;
                            }
                            if (this.refreshActivity) {
                                this.navigationEvent.refreshActivityData();
                            }
                            this.navigationEvent.refreshAnnualYear();
                            this.refreshActivity = false;
                            this.entityLoading = false;
                        }).catch(reason => {
                            this.entityLoading = false;
                            throw reason;
                        });
                    };
                    this.toggleEditHandler = () => {
                        this.complianceService.toggleFilingEdit(this.currentComplianceVM, this.editMode);
                    };
                    this.validationHandler = (callback: (boolean, string) => void) => {
                        this.currentComplianceVM.validate(callback);
                    };

                    this.entityLoading = false;
                    return complianceVM;
                }, () => undefined as ComplianceViewModel);
        });
    }

    goToBudget(year: AnnualDetailYear, annualBudgetId: number): Promise<AnnualBudgetViewModel> {
        return this.annualDetailsService.preNavigateWarn(this).then(() => {
            this.entityLoading = true;
            this.currentYear = year;
            this.currentRevision = year.annualGridDetails[0];

            return this.annualBudgetService.getAnnualBudgetViewModelByID(year, annualBudgetId, this.parcelID, this.parcelAcctNum)
                .then(async (annualBudgetVM: AnnualBudgetViewModel) => {
                    this.resetVMs();
                    this.currentAnnualBudgetVM = annualBudgetVM;
                    this.viewTarget = ViewTarget.budget;

                    this.cancelHandler = () => {
                        this.annualBudgetService.cancelBudgetEdit(this.currentAnnualBudgetVM);
                    };
                    this.saveHandler = () => {
                        this.entityLoading = true;
                        return this.annualBudgetService.saveAnnualBudgetFromViewModel(this.currentAnnualBudgetVM).then((result) => {
                            if (this.currentAnnualBudgetVM.model) {
                                this.currentAnnualBudgetVM.model.rowVersion = result.rowVersion;
                            }
                            if (this.refreshActivity) {
                                this.navigationEvent.refreshActivityData();
                            }
                            this.navigationEvent.refreshAnnualYear();
                            this.refreshActivity = false;
                            this.entityLoading = false;
                        }).catch(reason => {
                            this.entityLoading = false;
                            throw reason;
                        });
                    };
                    this.toggleEditHandler = () => {
                        this.annualBudgetService.toggleAnnualBudgetEdit(this.currentAnnualBudgetVM, this.editMode);
                    };
                    this.validationHandler = (callback: (boolean, string) => void) => {
                        this.currentAnnualBudgetVM.validate(callback);
                    };

                    this.entityLoading = false;
                    return annualBudgetVM;
                }, () => undefined as AnnualBudgetViewModel);
        });
    }

    private resetVMs(): void {
        this.currentAppealVM = null;
        this.currentTaxesVM = null;
        this.currentComplianceVM = null;
        this.currentAnnualBudgetVM = null;
        this.validationMessage = '';
        this.cancelHandler = undefined;
        this.saveHandler = undefined;
        this.toggleEditHandler = undefined;
        this.validationHandler = (callback: any) => {
            callback(true, '');
        };
        this.showEditControls = false;
        this.editMode = false;
        this.setDirty(false);
    }
}
