<div class="ws-section__header__actions__item">
    <div
        helpTooltip
        [hasIcon]="!isReadOnly"
        position="top"
        [contentComponent]="dynamicFieldBehaviorTooltip">
        <small>Property Characteristics Behavior:</small>
    </div>
    <div style="padding-left: 10px;"
        *ngIf="isReadOnly"
        helpTooltip
        [hasIcon]="showFieldOptionsTooltip"
        position="top"
        [contentComponent]="dynamicFieldSelectedBehaviorTooltip">
        <small>{{selectedPropertyCharacteristicOptionName}}</small>
    </div>
    <div dropdown container="body" placement="bottom right" *ngIf="!isReadOnly">
        <button id="button-basic-characteristics" dropdownToggle type="button" class="ace-btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic">
            {{ selectedImportDynamicFieldBehavior?.name }}
            <span class="caret"></span>
        </button>
        <ul id="dropdown-basic-characteristics" *dropdownMenu class="dropdown-menu dropdown-menu-end" role="menu" aria-labelledby="button-basic">
            <li role="menuItem" *ngFor="let item of availableDynamicFieldBehaviorOptions">
                <a class="dropdown-item btn-sm" href="javascript:void(0);" (click)="setDynamicFieldBehavior(item.optionId)">{{ item.name }}</a>
            </li>
        </ul>
    </div>
    <button [disabled]="!canEditDynamicFieldConfiguration"
            type="button"
            class="ace-btn btn-sm btn-primary"
            (click)="editDynamicFieldBehavior()"
            helpTooltip
            helpContentId="app.edit"
            position="bottom"
            *ngIf="!isReadOnly">
        <i class="fa fa-pencil"></i>
    </button>
</div>
