import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import {
    FilingControlReconciliationReport,
    ReturnFilingControlAssetFilter,
    ReturnFilingControlRow
} from '../../../../Models/filingControlReconciliationReport';
import { AgGridColumns, AgGridFilterParams, AgGridOptionsBuilder } from '../../../../../AgGrid';
import {
    ColDef,
    FilterChangedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    ICellRendererParams,
    RowNode
} from 'ag-grid-community';
import {
    AgGridLinkCellRendererComponent,
    AgGridLinkCellRendererParams
} from '../../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { DecimalPipe } from '@angular/common';
import { AgGridExportOptions, AgGridExportStartLRP } from '../../../../../AgGrid/ToolPanel/models';
import { ReturnService } from '../../../../return.service';
import { GridHelper } from '../../../../../AgGrid/GridHelper';
import {
    AgGridMultiSelectedCellRenderer,
    AgGridMultiSelectedHeaderRenderer,
    AgGridMultiSelectRendererParams,
    AgGridMultiSelectTracker
} from '../../../../../AgGrid/MultiSelectTracker';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { BehaviorSubject, lastValueFrom, Subject } from 'rxjs';
import { MessageModalService } from '../../../../../../UI-Lib/Message-Box/messageModal.service';
import { BusyIndicatorService } from '../../../../../../Busy-Indicator';
import { ReturnRepository } from '../../../../../Repositories';
import { FilingControlComponentChanges } from '../filingControl.component';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;

@Component({
    selector: 'filing-control-list-reconciliation-report',
    templateUrl: './filingControlList.component.html'
})
export class FilingControlListReconciliationReportComponent implements OnDestroy, OnChanges {
    constructor(
        private readonly _decimalPipe: DecimalPipe,
        private readonly _returnService: ReturnService,
        private readonly _messageModalService: MessageModalService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _returnRepository: ReturnRepository) {
            this._returnService.isReturnInReadOnlyMode$.pipe(takeUntil(this._destroy$)).subscribe(x => this.isBulkDeleteVisible$.next(this.isBulkUpdateVisible));
    }

    private _gridApi: GridApi;
    private readonly _propertyMap: Compliance.NameValuePair<string>[] = [
        { name: 'assessorId', value: 'AssessorId' },
        { name: 'assessorName', value: 'AssessorName' },
        { name: 'assessorPostmarkAccepted', value: 'AssessorPostmarkAccepted' },
        { name: 'assessorOriginalFormsRequired', value: 'AssessorOriginalFormsRequired' },
        { name: 'assessorWetSignatureRequired', value: 'AssessorWetSignatureRequired' },
        { name: 'siteId', value: 'SiteId' },
        { name: 'siteName', value: 'SiteName' },
        { name: 'siteProperty', value: 'SiteProperty' },
        { name: 'priorFilingId', value: 'PriorFilingId' },
        { name: 'priorFilingDescription', value: 'PriorFilingDescription' },
        { name: 'calculatedPriorFilingId', value: 'CalculatedPriorFilingId' },
        { name: 'calculatedPriorFilingDescription', value: 'CalculatedPriorFilingDescription' },
        { name: 'parcelId', value: 'ParcelId' },
        { name: 'parcelAcctNumber', value: 'ParcelAcctNumber' },
        { name: 'parcelAcctNumberDisplay', value: 'ParcelAcctNumberDisplay' },
        { name: 'parcelActivityStatusId', value: 'ParcelActivityStatusId' },
        { name: 'companyId', value: 'CompanyId' },
        { name: 'companyName', value: 'CompanyName' },
        { name: 'cost', value: 'Cost' },
        { name: 'depreciatedCost', value: 'DepreciatedCost' },
        { name: 'taxLiability', value: 'TaxLiability' },
        { name: 'estimatedFMV', value: 'EstimatedFMV' },
        { name: 'reportableCost', value: 'ReportableCost' },
        { name: 'primaryFormRevisionName', value: 'PrimaryFormRevisionName' },
        { name: 'assessorAddress1', value: 'AssessorAddress1' },
        { name: 'assessorAddress2', value: 'AssessorAddress2' },
        { name: 'assessorCity', value: 'AssessorCity' },
        { name: 'assessorState', value: 'AssessorState' },
        { name: 'assessorZipReturnId', value: 'AssessorZipReturnId' },
        { name: 'primaryFormRevisionId', value: 'PrimaryFormRevisionId' },
        { name: 'reportedAssetCount', value: 'ReportedAssetCount' },
        { name: 'notReportedAssetCount', value: 'NotReportedAssetCount' },
        { name: 'priorYearReportedCost', value: 'PriorYearReportedCost' },
        { name: 'priorYearDepreciatedValue', value: 'PriorYearDepreciatedValue' }
    ];
    private _destroy$: Subject<void> = new Subject();
    private _isInitialLoad: boolean = true;

    gridTracker: AgGridMultiSelectTracker;
    gridId: System.Guid = 'EC61E95D-89DB-47CD-B683-D5B7DDDCC598';
    isBulkDeleteVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    gridOptions: GridOptions = new AgGridOptionsBuilder({
        onFilterChanged: () => this.gridTracker.onGridFilterChanged(),
        onSortChanged: () => this.gridTracker.onGridSortChanged(),
        rowClassRules: {
                'ag-row-selected': (params) => params.data && this.gridTracker.isRowSelected((params.data as ReturnFilingControlRow).parcelId),
                'totals-row': (params) => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    if (!filingControl) {
                        return false;
                    }
                    return filingControl.isSubTotalRow || filingControl.isTotalRow;
                },
                'selected-totals-row': (params) => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    if (!filingControl) {
                        return false;
                    }
                    return filingControl.isSelectedTotalsRow;
                }
            }
        })
        .withContext(this)
        .withColumnResize()
        .withMultipleColumnSort()
        .withFilterChanged(this._updateTotal.bind(this))
        .withTextSelection()
        .withLoadingOverlay()
        .withColumnPinning()
        .build();

    @Input() formRevisionId: number;
    @Input() data: FilingControlReconciliationReport;
    @Input() refreshing: boolean;

    @Output() navigateToAssets: EventEmitter<ReturnFilingControlAssetFilter> = new EventEmitter<ReturnFilingControlAssetFilter>();
    @Output() navigateToPreview: EventEmitter<ReturnFilingControlRow> = new EventEmitter<ReturnFilingControlRow>();
    @Output() refreshData: EventEmitter<void> = new EventEmitter<void>();

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    get isBulkUpdateVisible(): boolean {
        return !this._returnService.isReturnInReadOnly && this.canEdit && this.gridTracker && this.gridTracker.hasSelectedRows();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        this.gridTracker = new AgGridMultiSelectTracker(this.gridOptions, this._getGridRowIds.bind(this));

        if (this._isInitialLoad){
            this.gridTracker.selectedRows$.pipe(takeUntil(this._destroy$)).subscribe(async (x) => {
                this.isBulkDeleteVisible$.next(this.isBulkUpdateVisible);
                await this._updateSelectedRowsTotals();
            });

            this._isInitialLoad = false;
        }

        const columns: ColDef[] = [
            {
                colId: 'grid-column-multiselect',
                field: 'parcelId',
                width: AgGridColumns.selectionColumnWidth,
                suppressSizeToFit: true,
                resizable: false,
                suppressColumnsToolPanel: true,
                pinned: 'left',
                lockPinned: true,
                lockVisible: true,
                lockPosition: true,
                editable: false,
                headerComponentFramework: AgGridMultiSelectedHeaderRenderer,
                headerComponentParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                cellRendererFramework: AgGridMultiSelectedCellRenderer,
                cellRendererParams: {
                    tracker: this.gridTracker
                } as AgGridMultiSelectRendererParams,
                pinnedRowCellRenderer: () => {return '';}
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return null;
                        }

                        return `#/assessor/${filingControl.assessorId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return '-';
                        }

                        return filingControl.assessorName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
            },
            {
                headerName: 'Parcel',
                field: 'parcelAcctNumberDisplay',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }
                        return `#/parcel/${filingControl.parcelId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow) {
                            return 'TOTAL';
                        } else if (filingControl.isSelectedTotalsRow) {
                            return 'SELECTED';
                        }

                        return filingControl.parcelAcctNumberDisplay;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    if (!filingControl) {
                        return '';
                    }

                    return filingControl.parcelAcctNumberDisplay;
                }
            },
            {
                headerName: 'Filing',
                field: 'filingDescription',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-filing',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }
                        if (filingControl.isMergedParcel) {
                            // Typically we would use a redirect route but in this case we have the data to go directly to the page
                            return `#/company/${filingControl.companyId}/site/${filingControl.siteId}/parcel/${filingControl.parcelId}?taxYear=${this._returnService.taxYear}`;
                        }
                        if (filingControl.filingId) {
                            return `#/filing/${filingControl.filingId}`;
                        }

                        return '';
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Address 1',
                field: 'assessorAddress1',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Address 2',
                field: 'assessorAddress2',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'City',
                field: 'assessorCity',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'State',
                field: 'assessorState',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Zip',
                field: 'assessorZip',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Site',
                field: 'siteName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return null;
                        }

                        return `#/site/${filingControl.siteId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return '-';
                        }

                        return filingControl.siteName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Site Number',
                field: 'siteProperty',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-site',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return null;
                        }

                        return `#/site/${filingControl.siteId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return '-';
                        }

                        return filingControl.siteProperty;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: 'Company',
                field: 'companyName',
                width: AgGridColumns.textColumnWidth,
                hide: true,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-company',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return null;
                        }

                        return `#/company/${filingControl.companyId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return '-';
                        }

                        return filingControl.companyName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Prior Return',
                field: 'priorFilingDescription',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-return',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl && filingControl.priorFilingId) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return null;
                        }

                        return `#/filing/${filingControl.priorFilingId}`;
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return '-';
                        }

                        return filingControl.priorFilingDescription;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: x => {
                    const filingControl = x.data as ReturnFilingControlRow;
                    return (filingControl && (!(filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow)) && filingControl.priorFilingId !== filingControl.calculatedPriorFilingId) ? 'ws-override' : null;
                }
            },
            {
                headerName: 'Return Form',
                field: 'primaryFormRevisionName',
                width: AgGridColumns.textColumnWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-return-form',
                    newWindow: true,
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        this.navigateToPreview.emit(filingControl);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return '';
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return '-';
                        }

                        return filingControl.primaryFormRevisionName;
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        if (filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow) {
                            return true;
                        }

                        const formRevisionId = this.formRevisionId || filingControl.primaryFormRevisionId;
                        if (!formRevisionId) {
                            return true;
                        }

                        const returnFormRevision = filingControl.returnFormRevisions?.find(x => x.formRevisionId === formRevisionId);
                        if (!returnFormRevision) {
                            return true;
                        }
                    }
                } as AgGridLinkCellRendererParams,
                cellClass: (params: ICellRendererParams): string => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return filingControl && (!(filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow)) && !filingControl.primaryFormRevisionId ? 'cell-validation-error' : '';
                }
            },
            {
                headerName: 'Cost',
                field: 'cost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: false,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.cost) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.cost) || 0;
                }
            },
            {
                headerName: 'Rep. Cost',
                field: 'reportableCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: true,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.reportableCost) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.reportableCost) || 0;
                }
            },
            {
                headerName: 'Dep. Value',
                field: 'depreciatedCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assets',
                    onClick: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return;
                        }

                        const assetFilter: ReturnFilingControlAssetFilter = {
                            primaryFormRevisionId: filingControl.primaryFormRevisionId,
                            showOnlyAssignedToReportableSchedule: false,
                            returnIds: [filingControl.returnId]
                        };

                        this.navigateToAssets.emit(assetFilter);
                    },
                    getText: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        return this._decimalPipe.transform((filingControl && filingControl.depreciatedCost) || 0, '1.2-2');
                    },
                    isDisabled: (params: AgGridLinkCellRendererParams) => {
                        const filingControl = params.data as ReturnFilingControlRow;
                        if (!filingControl) {
                            return true;
                        }

                        return filingControl.isSubTotalRow || filingControl.isTotalRow || filingControl.isSelectedTotalsRow;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.depreciatedCost) || 0;
                }
            },
            {
                headerName: 'Est. Tax',
                field: 'taxLiability',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                filterValueGetter: params => {
                    const filingControl = params.data as ReturnFilingControlRow;
                    return (filingControl && filingControl.taxLiability) || 0;
                },
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2')
            },
            {
                headerName: 'Est. FMV',
                field: 'estimatedFMV',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2')
            },
            {
                headerName: 'Rep. Asset Count',
                field: 'reportedAssetCount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.0-2')
            },
            {
                headerName: 'Not Rep. Asset Count',
                field: 'notReportedAssetCount',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.0-2')
            },
            {
                headerName: 'Prior Year Rep. Cost',
                field: 'priorYearReportedCost',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2')
            },
            {
                headerName: 'Prior Year Rep. Value',
                field: 'priorYearDepreciatedValue',
                type: 'numericColumn',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberWithRangeFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                hide: true,
                valueFormatter: (params) => this._decimalPipe.transform(params.value, '1.2-2')
            }
        ];

        const defaultSortModel = [
            {
                colId: 'parcelAcctNumberDisplay',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);

        this._setRowData();
    }

    ngOnChanges(changes: FilingControlComponentChanges): void {
        if (changes.data) {
            this._setRowData();
        }

        if (changes.refreshing) {
            if (changes.refreshing.currentValue) {
                this._gridApi && this._gridApi.showLoadingOverlay();
            } else {
                this._gridApi && this._gridApi.hideOverlay();
            }
        }
    }

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[], fileFormat: Compliance.ExportFileFormatEnum): Promise<AgGridExportStartLRP> => {
            const filterModel = this._gridApi.getFilterModel();
            const sortModel = this._gridApi.getSortModel();

            const searchParams: Core.SearchModel<string> = {
                columnFilters: GridHelper.getFilterColumns(filterModel, this._propertyMap),
                sortColumns: GridHelper.getSortModel(sortModel, this._propertyMap)
            };

            const lrp$ = this._returnService.exportReturnFilingControlSummary(searchParams, columnsToReturn);
            const longRunningProcessId = await lastValueFrom(lrp$);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportReturnFilingControlSummary};
        },
        canCancel: true
    }

    refresh(): void {
        this.refreshData.emit();
    }

    async bulkDelete(): Promise<void> {
        try {
            await this._messageModalService.confirm(
                'Are you sure you wish to remove the selected returns?',
                'Confirm'
            );
        } catch (e) {
            return Promise.resolve();
        }

        const selectedRows = this._getSelectedRows();

        const potentiallyConsolidatedParcels = selectedRows.filter(x => x.filingId);

        if (this._returnService.isConsolidatedReturn) {
            if (potentiallyConsolidatedParcels.length !== 0) {
                let hasConsolidatedParcels: boolean = false;

                for(let potentiallyConsolidatedParcel of potentiallyConsolidatedParcels) {
                    for(let parcel of this.data.returns) {
                        if (parcel.isMergedParcel && parcel.filingId === potentiallyConsolidatedParcel.filingId) {
                            hasConsolidatedParcels = true;
                            break;
                        }
                    }

                    if (hasConsolidatedParcels) {
                        break;
                    }
                }

                if (hasConsolidatedParcels) {
                    try {
                        await this._messageModalService.confirm(
                            'Your selection contains at least one consolidated return. Removing a consolidated return will also remove all related merged returns. Are you sure you want to proceed?',
                            'Confirm'
                        );
                    } catch (e) {
                        return Promise.resolve();
                    }
                }
            }

            const mergedParcels = selectedRows.filter(x => x.isMergedParcel);

            let isInvalidSelection: boolean = false;
            mergedParcels.forEach((x) => {
                const consolidatedParcel = potentiallyConsolidatedParcels.find(y => y.filingId === x.filingId);

                if (!consolidatedParcel) {
                    isInvalidSelection = true;
                    this._messageModalService.alert(
                        'Your selection contains at least one merged return without the related consolidated return. Merged returns cannot be removed on their own.');
                }
            });

            if (isInvalidSelection) {
                return Promise.resolve();
            }
        }

        let numberOfParcelsToRemove = 0;
        potentiallyConsolidatedParcels.forEach(x => {
            numberOfParcelsToRemove++;
            numberOfParcelsToRemove += this.data.returns.filter(y => !y.filingId && y.filingId === x.filingId).length;
        });

        if (numberOfParcelsToRemove === this.data.returns.filter(x => !x.isTotalRow).length) {
            await this._messageModalService.alert('The last filing can\'t be removed from the filing control. Please return to the batch and remove it from the filings list.');
            return;
        }

        let busyRef = this._busyIndicatorService.show({ message: 'Removing parcels' });
        try {
            const model: Compliance.SelectedRowsModel = {
                selectAllRows: this.gridTracker.getSelectedRowsModel().selectAllRows,
                selectedRows: selectedRows.filter(x => x.filingId).map(x => x.filingId)
            };
            await lastValueFrom(this._returnRepository.removeFilings(this._returnService.filingBatchId, model));
            await busyRef.hide();

            busyRef = this._busyIndicatorService.show({ message: 'Loading returns' });

            await this._returnService.prepareData(this._returnService.filingBatchId, true);
            await this._returnService.notifyParcelsChanged();
            this.gridTracker.clear(true);
        } finally {
            await busyRef.hide();
        }
    }

    get canEdit(): boolean {
        return this._returnService.canEditCompany;
    }

    private _setRowData(): void {
        if (!(this._gridApi && this.data)) {
            return;
        }

        this.gridTracker && this.gridTracker.clear();

        this._gridApi.setRowData(this.data.returns.filter(x => !x.isTotalRow));
        this._gridApi.setPinnedBottomRowData(this.data.returns.filter(x => x.isTotalRow));
    }

    private async _updateSelectedRowsTotals(): Promise<void> {
        const selectedRows = this._getSelectedRows();

        if (selectedRows.length === 0) {
            if (this._gridApi.getPinnedBottomRowCount() === 2) {
                this._gridApi.setPinnedBottomRowData([this._gridApi.getPinnedBottomRow(0).data]);
            }
        } else {
            const selectedRow: ReturnFilingControlRow = {
                isGroupRow: false,
                isSubTotalRow: false,
                isTotalRow: false,
                isExpanded: false,
                isSelectedTotalsRow: true,
                returnCount: 0,
                reportableCost: selectedRows.reduce((acc, y) => (acc += y.reportableCost || 0), 0),
                cost: selectedRows.reduce((acc, y) => (acc += y.cost || 0), 0),
                depreciatedCost: selectedRows.reduce((acc, y) => (acc += y.depreciatedCost || 0), 0),
                taxLiability: selectedRows.reduce((acc, y) => (acc += y.taxLiability || 0), 0),
                estimatedFMV: selectedRows.reduce((acc, y) => (acc += y.estimatedFMV || 0), 0),
                priorYearReportedCost: selectedRows.reduce((acc, y) => (acc += y.priorYearReportedCost || 0), 0),
                priorYearDepreciatedValue: selectedRows.reduce((acc, y) => (acc += y.priorYearDepreciatedValue || 0), 0),
                reportedAssetCount: selectedRows.reduce((acc, y) => (acc += y.reportedAssetCount || 0), 0),
                notReportedAssetCount: selectedRows.reduce((acc, y) => (acc += y.notReportedAssetCount || 0), 0)
            };

            this._gridApi.setPinnedBottomRowData([this._gridApi.getPinnedBottomRow(0).data, selectedRow]);
        }
    }

    private _getSelectedRows(): ReturnFilingControlRow[] {
        const selectedRowsModel = this.gridTracker.getSelectedRowsModel();

        return this.data.returns.filter(x =>
            selectedRowsModel.selectAllRows && selectedRowsModel.selectedRows.indexOf(x.parcelId) === -1 ||
            !selectedRowsModel.selectAllRows && selectedRowsModel.selectedRows.indexOf(x.parcelId) !== -1);
    }

    private _updateTotal(event: FilterChangedEvent): void {
        if (!this.data) {
            return;
        }

        let reportableCost = 0;
        let cost = 0;
        let depreciatedCost = 0;
        let priorYearReportedCost = 0;
        let priorYearDepreciationValue = 0;

        this._gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
            const filingControl = rowNode.data as ReturnFilingControlRow;
            if (filingControl) {
                reportableCost += filingControl.reportableCost || 0;
                cost += filingControl.cost || 0;
                depreciatedCost += filingControl.depreciatedCost || 0;
                priorYearReportedCost += filingControl.priorYearReportedCost || 0;
                priorYearDepreciationValue += filingControl.priorYearDepreciatedValue || 0;
            }
        });

        const rowNode = this._gridApi.getPinnedBottomRow(0);
        if (rowNode) {
            const filingControl = rowNode.data as ReturnFilingControlRow;
            if (filingControl) {
                filingControl.reportableCost = reportableCost;
                filingControl.cost = cost;
                filingControl.depreciatedCost = depreciatedCost;
                filingControl.priorYearReportedCost = priorYearReportedCost;
                filingControl.priorYearDepreciatedValue = priorYearDepreciationValue;
            }

            this._gridApi.redrawRows({rowNodes: [rowNode]});
        }
    }

    private _getGridRowIds(skip, take): Promise<Compliance.QueryResultModel<number>> {
        const model: any = this._gridApi.getModel();
        const rows = model.rowsToDisplay.slice(skip, take + 1);
        const queryResultModel: Compliance.QueryResultModel<number> = {
            lastModifiedTimestamp: new Date(),
            totalRows: rows.length,
            totalValidRows: rows.length,
            data: rows.map((x) => {
                const row = x.data as ReturnFilingControlRow;
                return row && row.parcelId;
            })
        };

        return Promise.resolve(queryResultModel);
    }
}
