<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" style="margin-bottom: 0;">
        <div class="header-icon"><i class="fa fa-gear"></i></div>
        <h3>Settings</h3>
    </div>
    <div class="panel-flat-body">
        <div *ngIf="appealFormSettings && appealFormSettingLookups" class="form-horizontal">
            <div class="mb-3">
                <label class="col-md-2 control-label">Taxpayer Name:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.ownerName" class="form-select form-select-sm" (change)="saveSetting(1, false)" [disabled]="savingSettings">
                        <option *ngFor="let option of appealFormSettingLookups[1].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[1].exceptions && appealFormSettingLookups[1].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(1, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-md-2 control-label">Taxpayer Address:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.ownerAddress" class="form-select form-select-sm" (change)="saveSetting(2, false)" [disabled]="savingSettings">
                        <option *ngFor="let option of appealFormSettingLookups[2].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[2].exceptions && appealFormSettingLookups[2].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(2, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>
            <div class="mb-3" style="margin-top: 50px;">
                <label class="col-md-2 control-label">Agent Contact:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.agentContact"
                        (focus)="oldAgentContact = appealFormSettings.settings.agentContact"
                        class="form-select form-select-sm"
                        (change)="appealFormSettings.settings.agentContact == 32 ? null : saveSetting(3, false); appealFormSettings.settings.agentContactOtherValue = null"
                        [disabled]="savingSettings || (appealFormSettings.settings.agentContact == 32 && !appealFormSettings.settings.agentContactOtherValue)">
                        <option *ngFor="let option of appealFormSettingLookups[3].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[3].exceptions && appealFormSettingLookups[3].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(3, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
                <div *ngIf="appealFormSettings.settings.agentContact == 32" style="margin-top: 5px;">
                    <div class="col-md-6 offset-sm-2">
                        <user-picker [search]="search" [currentContactId]="appealFormSettings.settings.agentContactOtherValue" (contactChanged)="saveSetting(3, false, $event)"></user-picker>
                    </div>
                    <div class="col-md-4" *ngIf="!appealFormSettings.settings.agentContactOtherValue">
                        <button class="ace-btn btn-sm btn-primary btn-danger" (click)="appealFormSettings.settings.agentContact = oldAgentContact"><i class="fa fa-close"></i></button>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-md-2 control-label">Agent Address:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.agentAddress" class="form-select form-select-sm" (change)="saveSetting(4, false)" [disabled]="savingSettings">
                        <option *ngFor="let option of appealFormSettingLookups[4].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[4].exceptions && appealFormSettingLookups[4].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(4, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-md-2 control-label">Agent Phone:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.agentPhone" class="form-select form-select-sm" (change)="saveSetting(5, false)" [disabled]="savingSettings">
                        <option *ngFor="let option of appealFormSettingLookups[5].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[5].exceptions && appealFormSettingLookups[5].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(5, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>
            <div class="mb-3" style="margin-top: 50px;">
                <label class="col-md-2 control-label">Signer:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.signer"
                        (focus)="oldSigner = appealFormSettings.settings.signer"
                        class="form-select form-select-sm"
                        (change)="appealFormSettings.settings.signer == 61 ? null : saveSetting(6, false); appealFormSettings.settings.signerOtherValue = null"
                        [disabled]="savingSettings || (appealFormSettings.settings.signer == 61 && !appealFormSettings.settings.signerOtherValue)">
                        <option *ngFor="let option of appealFormSettingLookups[6].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[6].exceptions && appealFormSettingLookups[6].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(6, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
                <div *ngIf="appealFormSettings.settings.signer == 61">
                    <div class="col-md-6 offset-sm-2" style="margin-top: 5px;">
                        <user-picker [search]="search" [currentContactId]="appealFormSettings.settings.signerOtherValue" (contactChanged)="saveSetting(6, false, $event)"></user-picker>
                    </div>
                    <div class="col-md-4" *ngIf="!appealFormSettings.settings.signerOtherValue" style="margin-top: 5px;">
                        <button class="ace-btn btn-sm btn-primary btn-danger" (click)="appealFormSettings.settings.signer = oldSigner"><i class="fa fa-close"></i></button>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label class="col-md-2 control-label">Signer Type:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.signerType" class="form-select form-select-sm" (change)="saveSetting(7, false)" [disabled]="savingSettings">
                        <option *ngFor="let option of appealFormSettingLookups[7].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[7].exceptions && appealFormSettingLookups[7].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(7, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>

            <div class="mb-3" *ngIf="false">
                <label class="col-md-2 control-label">Apply Digital Signature:</label>
                <div class="col-md-6">
					<div class="checkbox">
						<label>
							<input type="checkbox" [checked]="appealFormSettings.settings.applyDigitalSignature === 1"  (change)="setFlagApplyDigitalSignature(9, false)" [disabled]="savingSettings" >
						</label>
					</div>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[9].exceptions && appealFormSettingLookups[9].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(9, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>

            <div class="mb-3" [hidden]="!appealFormSettings.isMultiAccount || appealFormRevisionIds.length < 2" style="margin-top: 50px;">
                <label class="col-md-2 control-label">Multiple Account Mode:</label>
                <div class="col-md-6">
                    <select [(ngModel)]="appealFormSettings.settings.multiAccount" class="form-select form-select-sm" (change)="saveSetting(8, false)" [disabled]="savingSettings">
                        <option *ngFor="let option of appealFormSettingLookups[8].options" [ngValue]="option.appealFormSettingsLookupId">{{option.name}}</option>
                    </select>
                </div>
                <div class="col-md-4">
                    <button *ngIf="appealFormSettingLookups[8].exceptions && appealFormSettingLookups[8].exceptions.length"
                        class="ace-btn btn-sm"
                        (click)="saveSetting(8, true)"
                        [disabled]="savingSettings">Exceptions Exist - Apply to All
                    </button>
                </div>
            </div>
        </div>

        <div style="text-align: right;">
            <span *ngIf="savingSettings" style="font-style: italic;">Saving Settings...</span>
        </div>

    </div>
</div>
