import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SupportInstanceRepository } from '../../Compliance/Repositories/supportInstance.repository';
import { BusyIndicatorMessageManager } from '../../Busy-Indicator';
import { lastValueFrom } from 'rxjs';
import { WeissmanDateTimeFormatPipe } from '../../UI-Lib/Pipes';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import {
    SupportInstanceRequestAccessComponent,
    SupportInstanceRequestAccessComponentParams
} from '../Support-Instance-Request-Access/supportInstanceRequestAccess.component';
import {
    SupportInstanceDeactivateInstancePendingComponent,
    SupportInstanceDeactivateInstancePendingComponentParams
} from '../Support-Instance-Deactivate-Instance-Pending/supportInstanceDeactivateInstancePending.component';
import {
    SupportInstanceDeactivateInstanceConfirmComponent,
    SupportInstanceDeactivateInstanceConfirmComponentParams
} from '../Support-Instance-Deactivate-Instance-Confirm/supportInstanceDeactivateInstanceConfirm.component';
import { ToastrService } from 'ngx-toastr';

interface SupportInstanceInfo extends Core.SupportInstanceInfoModel {
    isAccessTerminating: boolean;
    statusPopoverOpened: boolean;
}

@Component({
    selector: 'support-instance-list',
    templateUrl: './supportInstanceList.component.html',
    styleUrls: ['./supportInstanceList.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SupportInstanceListComponent implements OnInit{
    
    constructor(
        private _supportInstanceRepository: SupportInstanceRepository,
        private _datePipe: WeissmanDateTimeFormatPipe,
        private _restrictService: RestrictService,
        private _routerService: UpgradeNavigationServiceHandler,
        private readonly _modalService: WeissmanModalService,
        private readonly _toastr: ToastrService
    ){}

    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    instances: SupportInstanceInfo[];
    userHasDeactivateInstancePermissions: boolean;
    excludeInactive: boolean;

    async ngOnInit(): Promise<void> {
        if(!this._restrictService.isInRole(Roles.SUPPORT)) {
            this._routerService.go('unauthorizedAccess', {});
            return Promise.resolve();
        }

        this.excludeInactive = true;
        this.userHasDeactivateInstancePermissions = this._restrictService.isInRole(Roles.DEACTIVATEINSTANCE);

        await this.getSupportInstances();
    }

    refresh(){
        this.getSupportInstances();
    }

    async getSupportInstances(){
        const busyLoading = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyLoading);

        try {
            var result = await lastValueFrom(this._supportInstanceRepository.getList());

            if(this.excludeInactive){
                result = result.filter(x=>x.instanceActivationStatus != Core.InstanceActivationStatusEnum.ConfirmedInactive)
            }

            result.sort((a, b) => a.instanceName.localeCompare(b.instanceName));
            this.instances = result.map(x => x as SupportInstanceInfo);
        } finally {
            this.busyIndicatorMessageManager.remove(busyLoading);
        }
    }

    getStatus(instance: Core.SupportInstanceInfoModel): string {
        if (instance.instanceActivationStatus == Core.InstanceActivationStatusEnum.PendingInactive){
            return 'Pending Inactive';
        }
        else if (instance.instanceActivationStatus == Core.InstanceActivationStatusEnum.ConfirmedInactive){
            return 'Inactive';
        }
        else{
            //instance activation status is active or no status sent to frontend
            return instance.accessGrantedDate ? `Initiated ${this._datePipe.transform(instance.accessGrantedDate, false, 'central')}` : 'Ready for Request';
        }
    }

    showDeactivateInstanceButton(instance: SupportInstanceInfo): boolean {
        return this.userHasDeactivateInstancePermissions && instance.instanceActivationStatus != Core.InstanceActivationStatusEnum.ConfirmedInactive;
    }

    showAccessInstanceButton(instance: SupportInstanceInfo): boolean {
        return !instance.accessGrantedDate &&  instance.instanceActivationStatus != Core.InstanceActivationStatusEnum.ConfirmedInactive;
    }

    async terminateAccess(instance: SupportInstanceInfo): Promise<void> {
        const busyMsgId = 'terminating';
        this.busyIndicatorMessageManager.add('Terminating', busyMsgId);

        try {
            await lastValueFrom(this._supportInstanceRepository.terminateAccess(instance.instanceId));

            instance.isAccessTerminating = true;

            this._toastr.success(`You have initiated termination of your access to ${instance.instanceName}. Please log out and log back in to complete the process.`);
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }

        return Promise.resolve();
    }

    async requestAccess(instance: SupportInstanceInfo): Promise<void> {
        const params: SupportInstanceRequestAccessComponentParams = {
            instanceId: instance.instanceId
        };
        const result = await this._modalService.showAsync(SupportInstanceRequestAccessComponent, params, 'modal-md');

        if (!result) {
            return Promise.resolve();
        }

        const instanceInfo = this.instances.find(x => x.instanceId == result.instanceId);
        instanceInfo.accessGrantedDate = result.accessGrantedDate;

        return Promise.resolve();
    }

    async deactivateInstance(instance: SupportInstanceInfo): Promise<void> {
        if(instance.instanceActivationStatus == Core.InstanceActivationStatusEnum.PendingInactive){
            //step 2: user #2 confirms deactivation of instance
            const params: SupportInstanceDeactivateInstanceConfirmComponentParams = {
                instanceId: instance.instanceId,
                pendingReason: instance.deactivationReason,
                pendingUserName: instance.deactivationPendingUserName,
                pendingDateTime: instance.deactivationPendingDate.toLocaleDateString()
            }

            const result = await this._modalService.showAsync(SupportInstanceDeactivateInstanceConfirmComponent, params, 'modal-md');
            return Promise.resolve();
        }
        else{
            //step 1: user #1 begins deactivation process for instance
            const params: SupportInstanceDeactivateInstancePendingComponentParams = {
                instanceId: instance.instanceId
            }

            const result = await this._modalService.showAsync(SupportInstanceDeactivateInstancePendingComponent, params, 'modal-md');
            return Promise.resolve();
        }
    }

    updateStatusPopoverOpened(instance: SupportInstanceInfo, isMouseHovering: boolean) {
        instance.statusPopoverOpened = isMouseHovering && instance.instanceActivationStatus == Core.InstanceActivationStatusEnum.ConfirmedInactive;
    }
}
