import { NgModule } from '@angular/core';
import { BusyIndicatorModule } from '../Busy-Indicator';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { InstancePermissionPanelComponent } from './Instance/instancePermissionPanel.component';
import { PermissionService } from './permission.service';
import { ApplicationPermissionPanelComponent } from './Application/applicationPermissionPanel.component';
import { CompanyPermissionPanelComponent } from './Company/Panel/companyPermissionPanel.component';
import { CompanyPermissionUserPanelComponent } from './Company/Panel/companyPermissionUserPanel.component';
import { CompanySitePermissionsComponent } from './Company/Site/companySitePermissions.component';
import { CompanyPermissionAddComponent } from './Company/Add/companyPermissionAdd.component';
import { CompanyPermissionsGridActionCellRendererComponent } from './Company/Panel/agGridActionCellRenderer.component';
import { InstanceRightGridActionCellRendererComponent } from './Instance/agGridActionCellRenderer.component';
import { ApplicationPermissionGridActionCellRendererComponent } from './Application/agGridActionCellRenderer.component';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { CompanyPermissionsService } from './Company/companyPermissions.service';
import { ApplicationPermissionService } from './Application/applicationPermission.service';

@NgModule({
    imports: [
        WeissmanSharedModule,
        BusyIndicatorModule
    ],
    declarations: [
        InstancePermissionPanelComponent,
        ApplicationPermissionPanelComponent,
        CompanyPermissionPanelComponent,
        CompanyPermissionUserPanelComponent,
        CompanySitePermissionsComponent,
        CompanyPermissionAddComponent,
        CompanyPermissionsGridActionCellRendererComponent,
        InstanceRightGridActionCellRendererComponent,
        ApplicationPermissionGridActionCellRendererComponent
    ],
    providers: [
        PermissionService,
        CompanyPermissionsService,
        ApplicationPermissionService
    ],
    exports: [
        CompanyPermissionUserPanelComponent,
        InstancePermissionPanelComponent,
        ApplicationPermissionPanelComponent,
        CompanyPermissionPanelComponent
    ]
})
export class PermissionModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Component('companyPermissionUserPanel', CompanyPermissionUserPanelComponent);
        hybridAdapterUtility.downgradeNg2Provider('sd.Company.Permission.Service', CompanyPermissionsService);
        hybridAdapterUtility.downgradeNg2Provider('sd.Application.Permission.Service', ApplicationPermissionService);
    }
}
