import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'parcel-bulk-update-action',
    templateUrl: './parcelBulkUpdateAction.component.html',
    styles: ['select {width:120px}']
})
export class ParcelBulkUpdateActionComponent {
    constructor(
    ){
        this.possibleActions = [
            { name: 'No Change', value: Core.ParcelBulkUpdateFieldActionEnum.NoChange },
            { name: 'Change To', value: Core.ParcelBulkUpdateFieldActionEnum.ChangeTo }
        ];
    }

    @Input()
    set fieldAction(fieldAction: Core.ParcelBulkUpdateFieldActionEnum) {
        this.fieldActionChanged(fieldAction);
    }

    @Output() fieldActionChange = new EventEmitter<Core.ParcelBulkUpdateFieldActionEnum>();
    @Input() disabled: boolean;

    action: Core.ParcelBulkUpdateFieldActionEnum;
    possibleActions: Compliance.NameValuePair<Core.ParcelBulkUpdateFieldActionEnum>[];
    selectedAction: Core.ParcelBulkUpdateFieldActionEnum = Core.ParcelBulkUpdateFieldActionEnum.NoChange;

    public fieldActionChanged(action: Core.ParcelBulkUpdateFieldActionEnum): void {
        if (action || action === Core.ParcelBulkUpdateFieldActionEnum.NoChange) {
            this.selectedAction = action;
            this.fieldActionChange.next(action);
        } else {
            this.selectedAction = Core.ParcelBulkUpdateFieldActionEnum.NoChange;
        }
    }

    isSelected(action: Core.ParcelBulkUpdateFieldActionEnum): boolean{
        return action === Core.ParcelBulkUpdateFieldActionEnum.NoChange;
    }
}
