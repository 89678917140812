import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { ReassignModes } from '../../constants.new';
import { ReassignTaskRequestModel, ReassignUserTaskRequestModel, TaskService } from '../task.service.upgrade';

export interface ReassignTaskModalParams {
    taskIDs: number[];
    reassignMode: ReassignModes;
    searchTimestamp: Date;
    instanceId: number;
}

export interface ReassignTaskUserModel {
    taskIds: number[];
    searchTimestamp: Date;
}

@Component({
    selector: 'app-reassign-task-modal',
    templateUrl: './reassignTaskModal.component.html'
    })
export class ReassignTaskModalComponent implements OnInit, IWeissmanModalComponent<ReassignTaskModalParams, any> {
    constructor(private readonly _bsModalRef: BsModalRef,
                private readonly _featureFlagsService: FeatureFlagsService,
                private readonly _taskService: TaskService) {
    }

    params: ReassignTaskModalParams;
    result: () => Promise<any>;

    user: Core.UserTeamModel;
    entityId: number;
    entityType: string;
    entityIdsMultiselect: number[];
    reassignMode: ReassignModes;
    ReassignModes = ReassignModes;

    get title(): string {
        switch (this.reassignMode) {
            case ReassignModes.Default:
                return 'Reassign Task(s)';
            case ReassignModes.ChangeContact:
                return 'Change Contact(s)';
            default:
                throw new Error(`Unrecognized reassign mode ${  this.reassignMode}`);
        }
    }

    ngOnInit(): void {
        this.reassignMode = this.params.reassignMode;

        if(this._featureFlagsService.featureFlags.enableMultiUserPermissionsCheck){
            this.entityIdsMultiselect = this.params.taskIDs;
            this.entityType = 'Task';
        }
        else{
            this.entityId = this.params.instanceId;
            this.entityType = 'Instance';
        }
    }

    save(): void {
        this.result = () => {
            switch (this.reassignMode) {
                case ReassignModes.Default:
                    const reassignRequest: ReassignTaskRequestModel = {
                        ...structuredClone(this.user),
                        taskIds: this.params.taskIDs,
                        searchTimestamp: this.params.searchTimestamp
                    };
                    return this._taskService.reassignMany(reassignRequest);
                case ReassignModes.ChangeContact:
                    const changeRequest: ReassignUserTaskRequestModel = {
                        taskIds: this.params.taskIDs,
                        searchTimestamp: this.params.searchTimestamp,
                        userId: this.user.userID,
                        teamId: this.user.teamID
                    };
                    return this._taskService.changeContacts(changeRequest);
                default:
                    throw new Error(`Unrecognized reassign mode ${  this.reassignMode}`);
            }
        };
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    userChanged(user: Core.UserTeamModel): void {
        this.user = user;
    }
}
