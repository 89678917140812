import { Pipe, PipeTransform } from '@angular/core';

// TODO: Consider attempting to import moment and moment-typezone type definitions
import * as moment from 'moment-timezone';

function getMoment(inputDate: Date, timeZone: string): any {
    // I've changed this implementation as part of the date/time cleanup. Originally this type declared that it worked
    // on string inputs, but I believe that will never really happen. Instead of assuming things will work in that case,
    // for now I'm going to throw here; if this really does come up, we need to figure out how to really handle that case.
    if (typeof (inputDate) === 'string') {
        throw new Error('Invalid date: cannot convert string to date automatically');
    }
    let newMoment = moment(inputDate);
    switch (timeZone.toLowerCase())
    {
        case 'mangle':
            newMoment.add(inputDate.getTimezoneOffset(), 'minutes');
            break;
        case 'userlocal':
            newMoment = newMoment.local();
            break;
        case 'stored':
        case 'utc':
            newMoment = newMoment.utc();
            break;
        case 'central':
            newMoment = newMoment.tz('America/Chicago'); // IANA code for "Central" time
            break;
        default:
            throw new Error('Unrecognized time zone specification for date pipe');
    }

    return newMoment;
}

@Pipe({ name: 'weissmanDateTimeFormat' })
export class WeissmanDateTimeFormatPipe implements PipeTransform {
    // See weissman-datetime.component.ts for examples of valid inputs for timeZone
    transform(inputDate: Date, allowEmpty: boolean = false, timeZone: string = 'Stored'): string {
        if (allowEmpty && !inputDate) {
            return '';
        }

        return getMoment(inputDate, timeZone).format('M/D/Y h:mm a');
    }
}

// Export as function to use in non-DOM code
export const WeissmanDateFormat = (inputDate: Date, allowEmpty: boolean = false, timeZone: string = 'Stored'): string => {
    if (allowEmpty && !inputDate) {
        return '';
    }

    return getMoment(inputDate, timeZone).format('M/D/Y');
};

@Pipe({ name: 'weissmanDateFormat' })
export class WeissmanDateFormatPipe implements PipeTransform {
    // See weissman-datetime.component.ts for examples of valid inputs for timeZone
    transform(inputDate: Date, allowEmpty: boolean = false, timeZone: string = 'Stored'): string {
        return WeissmanDateFormat(inputDate, allowEmpty, timeZone);
    }
}

@Pipe({ name: 'weissmanMonthDayDateFormat' })
export class WeissmanMonthDayDateFormatPipe implements PipeTransform {
    // See weissman-datetime.component.ts for examples of valid inputs for timeZone
    transform(inputDate: Date, allowEmpty: boolean = false, timeZone: string = 'Stored'): string {
        if (allowEmpty && !inputDate) {
            return '';
        }

        return getMoment(inputDate, timeZone).format('M/D');
    }
}

@Pipe({ name: 'commentDateTimeFormat' })
export class CommentDateTimeFormat implements PipeTransform {
    // See weissman-datetime.component.ts for examples of valid inputs for timeZone
    transform(inputDate: Date): string {
        return getMoment(inputDate, 'central').format('h:mm a CT on M/D/Y');
    }
}

// Helper to prevent date/time issues across timezones
export function formatDateOutputToUTC(dateString: string | Date): Date {
    if (!dateString) { return null; }
    const d = new Date(dateString);
    return moment.tz({ y: d.getFullYear(), M: d.getMonth(), d: d.getDate(), h: 0, m: 0 }, 'UTC')
        .toDate();
}
