import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { Constants } from '../constants.new';
import { ForecastBudgetModeType, BudgetModeViewType, ForecastBudgetEditRequest, ForecastBudgetAltAssessmentView } from './forecasting.budgeting.model';

@Component({
    selector: 'forecasting-budgeting-bulk-update-modal',
    templateUrl: './forecasting.budgeting.bulk.update.modal.component.html',
})
export class ForecastingBudgetingBulkUpdateModalComponent implements OnInit {
    constructor(
        public constants: Constants,
        private bsModalRef: BsModalRef
    ) { }

    onClose: (result?: ForecastBudgetEditRequest) => void;
    selectedCount: number;
    yearBegin: number;
    yearEnd: number;
    selectedMode: ForecastBudgetModeType;
    selectedView: BudgetModeViewType;
    isFrozen: boolean = false;
    selectedAltAsmtView: ForecastBudgetAltAssessmentView;

    headerLabel: string;
    fmvValueLabel: string;
    yoyPercentLabel: string;
    fmvValueDisplay: string = '';
    yoyPercentDisplay: string = '';

    editReq: ForecastBudgetEditRequest = new ForecastBudgetEditRequest();
    availableEditActions: Compliance.NameValuePair<Core.ForecastBudgetEditActionEnum>[];
    selectableTaxYears: number[];

    commonEditActions: Compliance.NameValuePair<Core.ForecastBudgetEditActionEnum>[] =
    [
        { name: '--select an Action--', value: Core.ForecastBudgetEditActionEnum.None }
    ];
    totalFmvEditActions: Compliance.NameValuePair<Core.ForecastBudgetEditActionEnum>[] =
    [
        { name: 'Set Total FMV', value: Core.ForecastBudgetEditActionEnum.SetTotalFMV },
        { name: 'Set YoY % Total FMV', value: Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV },
        { name: 'Distribute Total FMV', value: Core.ForecastBudgetEditActionEnum.DistribTotalFMV }
    ];
    altFmvEditActions: Compliance.NameValuePair<Core.ForecastBudgetEditActionEnum>[] =
    [
        { name: 'Set Alt FMV', value: Core.ForecastBudgetEditActionEnum.SetAltFMV },
        { name: 'Set YoY % Alt FMV', value: Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV },
        { name: 'Distribute Alt FMV', value: Core.ForecastBudgetEditActionEnum.DistribAltFMV }
    ];
    budgetModeTaxYearSpecificEditActions: Compliance.NameValuePair<Core.ForecastBudgetEditActionEnum>[] =
    [
        { name: 'Freeze Budget', value: Core.ForecastBudgetEditActionEnum.Freeze },
        { name: 'Unfreeze Budget', value: Core.ForecastBudgetEditActionEnum.Unfreeze },
        { name: 'Create Missing Budget', value: Core.ForecastBudgetEditActionEnum.CreateMissingBudget },
        { name: 'Sync Budget', value: Core.ForecastBudgetEditActionEnum.SyncBudget }
    ];


    async ngOnInit() {
        const years = _.range(this.yearBegin,
                            this.yearEnd + 1);
        this.selectableTaxYears = _.chain(years)
            .reverse()
            .value();

        this.editReq.details.taxYear = this.yearEnd;
        this.availableEditActions = _.cloneDeep(this.commonEditActions);
        if ( this.selectedAltAsmtView !== ForecastBudgetAltAssessmentView.ShowOnlyAlternates ) {
            this.availableEditActions = _.concat(this.availableEditActions, _.cloneDeep(this.totalFmvEditActions));
        }
        if ( this.selectedAltAsmtView === ForecastBudgetAltAssessmentView.Show ||
             this.selectedAltAsmtView === ForecastBudgetAltAssessmentView.ShowOnlyAlternates ) {
            this.availableEditActions = _.concat(this.availableEditActions, _.cloneDeep(this.altFmvEditActions));
        }
        if ( this.selectedMode === 'forecast' ) {
            this.headerLabel = `Bulk Update ${this.selectedCount} Forecasting entries`;
        }
        else if ( this.selectedMode === 'budget' && this.selectedView === 'taxYear' ) {
            this.headerLabel = `Bulk Update ${this.selectedCount} Budgeting entries`;
            if ( !this.isFrozen ) {
                this.availableEditActions = _.concat(this.availableEditActions, _.cloneDeep(this.budgetModeTaxYearSpecificEditActions));
            }
        }
        this.editReq.details.editAction = Core.ForecastBudgetEditActionEnum.None;
        this.editReq.details.taxYear = this.yearEnd;

        this.yoyPercentDisplay = new Decimal(0).toFixed(4);
    }

    editActionChanged()
    {
        switch ( this.editReq.details.editAction )
        {
            case  Core.ForecastBudgetEditActionEnum.SetTotalFMV:
            case  Core.ForecastBudgetEditActionEnum.DistribTotalFMV:
                {
                    this.fmvValueLabel = 'Fair Market Value (Total):';
                    this.yoyPercentLabel = '';
                }
                break;
            case  Core.ForecastBudgetEditActionEnum.SetAltFMV:
            case  Core.ForecastBudgetEditActionEnum.DistribAltFMV:
                {
                    this.fmvValueLabel = 'Fair Market Value (Alt):';
                    this.yoyPercentLabel = '';
                }
                break;
            case  Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV:
                {
                    this.fmvValueLabel = '';
                    this.yoyPercentLabel = 'YoY % Change (Total):';
                }
                break;
            case  Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV:
                {
                    this.fmvValueLabel = '';
                    this.yoyPercentLabel = 'YoY % Change (Alt):';
                }
                break;

            case  Core.ForecastBudgetEditActionEnum.Freeze:
            case  Core.ForecastBudgetEditActionEnum.Unfreeze:
            case  Core.ForecastBudgetEditActionEnum.CreateMissingBudget:
            case  Core.ForecastBudgetEditActionEnum.SyncBudget:
            default:
                {
                    this.fmvValueLabel = '';
                    this.yoyPercentLabel = '';
                }
                break;
        }
    }

    isTaxYearEnabled() : boolean {
        return this.isFmvValueEnabled() || this.isYoYPercentEnabled();
    }

    isFmvValueEnabled() : boolean {
        return( this.editReq.details.editAction == Core.ForecastBudgetEditActionEnum.SetTotalFMV ||
                this.editReq.details.editAction == Core.ForecastBudgetEditActionEnum.SetAltFMV ||
                this.editReq.details.editAction == Core.ForecastBudgetEditActionEnum.DistribTotalFMV ||
                this.editReq.details.editAction == Core.ForecastBudgetEditActionEnum.DistribAltFMV );
    }

    isYoYPercentEnabled() : boolean {
        return( this.editReq.details.editAction == Core.ForecastBudgetEditActionEnum.SetYoyPctTotalFMV ||
                this.editReq.details.editAction == Core.ForecastBudgetEditActionEnum.SetYoyPctAltFMV );
    }

    fmvValueBlur(currentValue: string): void {
        if ( this._isValidNumericValue(this.fmvValueDisplay) )
        {
            this.fmvValueDisplay = new Decimal(this.fmvValueDisplay).round().toString();
        }
        else
        {
            this.fmvValueDisplay = '';
        }
    }

    yoyPercentBlur(currentValue: string): void {
        if ( !this._isValidNumericValue(this.yoyPercentDisplay) )
        {
            this.yoyPercentDisplay = '0.0';
        }
        this.yoyPercentDisplay = new Decimal(this.yoyPercentDisplay).toFixed(4);
    }


    isSaveable(): boolean {
        if ( this.editReq.details.editAction === Core.ForecastBudgetEditActionEnum.None )
        {
            return false;
        }

        if ( this.isTaxYearEnabled() )
        {
            return this.editReq.details.taxYear !== undefined &&
                   this.editReq.details.taxYear !== null &&
                   this.editReq.details.taxYear > 0;
        }

        let saveable = false;
        if ( this.isFmvValueEnabled() )
        {
            saveable = this._isValidNumericValue(this.fmvValueDisplay) &&
                        (new Decimal(this.fmvValueDisplay).toNumber()) >= 0;
        }
        else if ( this.isYoYPercentEnabled() )
        {
            saveable = this._isValidNumericValue(this.yoyPercentDisplay);
        }
        else {
            saveable = true;
        }
        return saveable;
    }


    async save(): Promise<void> {
        if ( this.isFmvValueEnabled() )
        {
            this.editReq.details.valueFMV = new Decimal(this.fmvValueDisplay).round().toNumber();
        }
        if ( this.isYoYPercentEnabled() )
        {
            this.editReq.details.yoyPercentFMV = new Decimal(this.yoyPercentDisplay).dividedBy(100).toNumber();
        }

        this.bsModalRef.hide();

        this.onClose(this.editReq);
    }

    cancel() {
        this.bsModalRef.hide();

        this.onClose();
    }

    private _isValidNumericValue(value: string): boolean {
        value = _.trim(value);

        if (this._isInvalidNumber(value))
            return false;

        if (isNaN(Number(value)))
            return false;

        return true;
    }


    private _isInvalidNumber(value: string): boolean {
        return value === undefined || value === null || value === '' || value === '.';
    }
}
