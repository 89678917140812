import { Component, OnInit } from '@angular/core';
import { IWeissmanModalComponent } from '../../Compliance/WeissmanModalService';
import { BusyIndicatorMessageManager, BusyIndicatorService } from '../../Busy-Indicator';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DescriptorService } from '../../Property-Characteristics/descriptor.service';
import { fill } from 'lodash';

export interface SiteDescriptorsSelectorParams {
    companyId: number;
    isEditMode: boolean;
    selectedDescriptors: number[];
}

@Component({
    selector: 'site-descriptors-list',
    templateUrl: 'siteDescriptorsList.component.html'
})
export class SiteDescriptorsListComponent implements OnInit, IWeissmanModalComponent<SiteDescriptorsSelectorParams, number[]> {
    constructor(
        private _busyIndicatorService: BusyIndicatorService,
        private readonly _descriptorService: DescriptorService,
        private readonly _modalRef: BsModalRef,
    ) {
    }

    _numberOfDescriptorsToSelect: number = 5;

    _numberWord = ['', 'One', 'Two', 'Three', 'Four', 'Five'];

    params: SiteDescriptorsSelectorParams;
    result: number[];

    isInitialized: boolean = false;
    busyIndicatorMessageManager = new BusyIndicatorMessageManager<string>();
    selectedDescriptors: number[];
    siteDescriptors: Compliance.NameValuePair<number>[];
    isEditMode: boolean = false;

    async ngOnInit(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        try {
            const availableDescriptorsCount = Math.max(this._numberOfDescriptorsToSelect, (this.params.selectedDescriptors ?? []).length);

            this.selectedDescriptors = fill(Array(availableDescriptorsCount), 3);
            (this.params.selectedDescriptors ?? []).forEach((x, i) => this.selectedDescriptors[i] = x);

            const allDescriptors = await this._descriptorService.getUsedBySite(this.params.companyId);
            this.siteDescriptors = allDescriptors
                .map(x => { return {name: x.name, value: x.descriptorID} as Compliance.NameValuePair<number>;})
                .sort((x, y) => x.name > y.name ? 1 : -1);

            this.isEditMode = this.params.isEditMode;
            this.isInitialized = true;
        }
        finally{
            await busyRef.hide();
        }
    }

    save(): void {
        this.result = this.selectedDescriptors;
        this._modalRef.hide();
    }

    cancel(): void {
        this._modalRef.hide();
    }

    numToWord(val: number): string {
        return this._numberWord[val];
    }

    onDescriptorChange(descriptorId: number, i: number) {
        this.selectedDescriptors[i] = descriptorId;
    }

    trackByIndex(i, item) : number {
        return i;
    }

    removeAllSelectedDescriptors(): void {
        this.result = [];
        this._modalRef.hide();
    }
}
