<div *ngIf="masterParcelId">
    <span class="title">Master Parcel:</span>
    <button class="ace-btn btn-link pointer ws-no-margin ws-no-padding ws-no-border" [disabled]="isDisabled" (click)="navigateToParcel(masterParcelId)" title="View {{ masterParcelAcctNum }}">
        {{ masterParcelAcctNum }}
    </button>
</div>
<div *ngIf="(!masterParcelId) && linkedParcelCount">
    <span class="title">Linked To:</span>
    <button class="ace-btn btn-link pointer ws-no-margin ws-no-padding ws-no-border" [disabled]="isDisabled" (click)="showParcels()" title="View parcels">
        {{ linkedParcelCount|number }} Parcel{{ linkedParcelCount > 1 ? 's' : ''}}
    </button>
</div>
