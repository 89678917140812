<div *ngIf="template" class="card panel-flat-color primary-panel flex-panel">
    <div class="panel-flat-header ws-flex-container-horizontal">
        <h3 class="ws-flex-auto ws-truncate">Valuation Template</h3>
        <div class="ws-flex-none header-button-container">
            <button type="button"
                    class="ace-btn btn-text has-icon btn-primary"
                    (click)="upload()"
                    *ngIf="canEdit"
                    [disabled]="!canEnterEditMode"
                    helpTooltip
                    helpContentId="app.upload"
                    position="bottom">
                <i class="fa fa-upload fa-align"></i>
            </button>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="!isExpanded" (click)="expandComponent()">
                <i class="fa fa-arrows-alt"
                   helpTooltip
                   helpContentId="app.expand"
                   position="bottom"></i>
            </button>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary" *ngIf="isExpanded" (click)="collapseComponent()">
                <i class="fa fa-compress"
                   helpTooltip
                   helpContentId="app.collapse"
                   position="bottom"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body ws-flex-container-vertical">
        <div *ngIf="!template.hasFile || !template.siteClassPrimaries.length"
             class="ws-stretch ws-flex-container-horizontal ws-flex-align-items-center ws-flex-justify-content-center">
            <h4 *ngIf="template.siteClassPrimaries.length">The valuation doesn't have a template</h4>
            <h4 *ngIf="!template.siteClassPrimaries.length">Please select a primary site class to enable template upload</h4>
        </div>
        <table class="table table-condensed table-hover table-borderless" *ngIf="template && template.siteClassPrimaries.length && template.hasFile">
            <tbody>
                <tr>
                    <td>
                        <span>{{ fileName }}</span>
                        <span *ngIf="fileChangedBy && fileChangeDate">(Last changed by {{fileChangedBy}} on {{fileChangeDate | date: 'MM/dd/yyyy h:mm a'}})</span>
                    </td>
                    <td>
                        <div class="float-end">
                            <button class="ace-btn has-icon btn-secondary"
                                    helpTooltip
                                    helpContentId="app.download"
                                    position="bottom"
                                    (click)="download()"
                                    [disabled]="!canEnterEditMode">
                                <i class="fa fa-download fa-align"></i>
                            </button>
                            <button *ngIf="canEdit"
                                    class="ace-btn has-icon btn-primary btn-danger"
                                    helpTooltip
                                    helpContentId="app.delete"
                                    position="bottom"
                                    (click)="delete()"
                                    [disabled]="!canEnterEditMode">
                                <i class="fa fa-trash fa-align"></i>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
