(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.directive('sdTaskSeries', sdTaskSeries);

	sdTaskSeries.$inject = [
		'$compile',
		'taskService',
		'$q',
		'$rootScope',
		'TaskActionTypes',
		'TaskTypes',
		'userInstanceService',
		'instanceRepository',
	];

	function sdTaskSeries($compile, taskService, $q, $rootScope, TaskActionTypes, TaskTypes, userInstanceService, instanceRepository) {
		// Usage:
		//
		// Creates:
		//
		var directive = {
			templateUrl: 'app/Task/_taskPopover.html',
			restrict: 'A',
			scope: {
				taskGroup: '=',
				chevronWidth: '=',
				padding: '=',
				updateData: '&',
				popoverLeftOrRight: '=',
				waitingOnServer: '='
			},
			link: function (scope, element, attrs) {
				var heights = {
					sm: 50, // one line
					md: 75, // two lines
					lg: 100, // three lines
					xl: 125 // four lines
				};

				var currentChevronPos = 0;
				var chevronWidth = scope.chevronWidth;
				var padding = scope.padding;
				var chevronDivot = heights.lg * 0.10;
				var svg, node, enteringNode, completeNode, currentNode;
				var completeText, completeChevron, completeCheck, completeDate;
				var verticalOffset = 0;
				var completeChevronHeight = heights.lg;

				scope.userPicked = userPicked;
				scope.instanceIdUserFilter = null;

				activate();

				function activate() {
					initSvg();
					defineBoxShadow();
					initCompleteChevron();

					element.removeAttr('sd-task-series');

					scope.$watch('taskGroup', updateTaskGroup, true)
				}

				function initSvg() {
					svg = d3.select(element[0])
						.append("svg")
						.attr("width", chevronWidth + (padding * 2))
						.attr('height', 0);
				}

				function defineBoxShadow() {
					var defs = svg.append("defs");

					// create filter with id #drop-shadow
					// height=130% so that the shadow is not clipped
					var filter = defs.append("filter")
						.attr("id", "drop-shadow")
						.attr("height", "130%");

					// SourceAlpha refers to opacity of graphic that this filter will be applied to
					// convolve that with a Gaussian with standard deviation 3 and store result
					// in blur
					filter.append("feGaussianBlur")
						.attr("in", "SourceAlpha")
						.attr("stdDeviation", 2)
						.attr("result", "blur");

					// translate output of Gaussian blur to the right and downwards with 2px
					// store result in offsetBlur
					filter.append("feOffset")
						.attr("in", "blur")
						.attr("dx", 2)
						.attr("dy", 2)
						.attr("result", "offsetBlur");

					// overlay original SourceGraphic over translated blurred opacity by using
					// feMerge filter. Order of specifying inputs is important!
					var feMerge = filter.append("feMerge");

					feMerge.append("feMergeNode")
						.attr("in", "offsetBlur")
					feMerge.append("feMergeNode")
						.attr("in", "SourceGraphic");
				}

				function initCompleteChevron() {
					var completeChevronPath = d3.svg.line()([
						[0, 0],
						[chevronWidth / 2, chevronDivot],
						[chevronWidth, 0],
						[chevronWidth, completeChevronHeight - chevronDivot],
						[0, completeChevronHeight - chevronDivot],
						[0, 0]
					])

					completeNode = svg.append('g')
						.attr('oncontextmenu', 'return false')
						.attr('fill-opacity', 1e-6)
						.attr('stroke-opacity', 1e-6);

					completeChevron = completeNode.append('path')
						.attr('d', completeChevronPath)
						.attr('stroke-width', 1)
						.attr('fill', '#eee')
						.style("filter", "url(#drop-shadow)")

					completeText = completeNode.append('text')
						.attr('y', completeChevronHeight / 2)
						.attr('dy', '0.35em')
						.attr('x', chevronWidth / 2)
						.attr('text-anchor', 'middle')
						.attr('class', 'chevron-text')
						.text('Tasks Complete!');

					completeCheck = completeNode.append('text')
						.attr('y', completeChevronHeight / 2)
						.attr('x', 10 + padding)
						.attr('dy', '0.35em')
						.style('font', 'var(--fa-font-regular)')
						.style('font-weight', '900')
						.style('font-size', '36')
						.attr('class', 'task-checked')

					completeDate = completeNode.append('text')
						.attr('y', completeChevronHeight * .42 + 20)
						.attr('dy', '0.35em')
						.attr('x', chevronWidth / 2)
						.attr('text-anchor', 'middle')
						.attr('class', 'chevron-text')
				}

				function updateTaskGroup() {
					// used to configure y value of chevrons of variable height
					currentChevronPos = 0;
					verticalOffset = (scope.taskGroup.maxHeight - scope.taskGroup.height) / scope.taskGroup.tasks.length;

					// set new svg height
					svg.transition()
						.attr("height", scope.taskGroup.maxHeight);

					// selection of all nodes that are bound to data
					node = svg.selectAll('g.task-node')
						.data(scope.taskGroup.tasks, function (d) {
							return d.taskID;
						});

					// add new nodes from new data
					appendEnteringNodeElements();

					// update existing nodes
					node.attr('fill-opacity', function (d) {
						return d.temp ? 1e-6 : 1;
					})
						.attr('stroke-opacity', function (d) {
							return d.temp ? 1e-6 : 1;
						})
						.transition()
						.attr('fill-opacity', 1)
						.attr('stroke-opacity', 1)
						.attr('transform', function (d, i) {
							var transform = "translate(" + padding + "," + (currentChevronPos + padding) + ")";
							currentChevronPos += getHeight(d);

							return transform;
						});

					updateChevrons();
					updateChevronText();

					if (!_.last(scope.taskGroup.tasks).pointsTo.length) {
						updateCompleteChevron();
					}

					// remove nodes no longer bound to data
					node.exit()
						.transition()
						.duration(1000)
						.attr('fill-opacity', 1e-6)
						.attr('stroke-opacity', 1e-6)
						.remove();

					$compile(element)(scope);
				}

				function appendEnteringNodeElements() {
					enteringNode = node.enter().append('g').attr('class', 'task-node');

					enteringNode.append('path').attr('class', 'task-chevron')
						.attr('stroke-width', 1)
						.style("filter", "url(#drop-shadow)")
						.attr('uib-popover-template', "'taskPopover.html'")
						.attr('popover-trigger', 'mouseenter')
						.attr('popover-placement', scope.popoverLeftOrRight)
						.attr('popover-append-to-body', 'true')

					enteringNode.append('text').attr('class', 'task-name chevron-text')
						.attr('dy', '0.35em')
						.attr('x', chevronWidth / 2)
						.attr('text-anchor', 'middle')

					enteringNode.append('text').attr('class', 'task-user chevron-text')
						.attr('dy', '0.35em')
						.attr('x', chevronWidth / 2)
						.attr('text-anchor', 'middle')

					enteringNode.append('text').attr('class', 'task-team chevron-text')
						.attr('dy', '0.35em')
						.attr('x', chevronWidth / 2)
						.attr('text-anchor', 'middle')

					enteringNode.append('text').attr('class', 'task-deliverable chevron-text')
						.attr('dy', '0.35em')
						.attr('x', chevronWidth / 2)
						.attr('text-anchor', 'middle')
						.style('font-size', '13')

					enteringNode.append('text').attr('class', 'task-checkbox')
						.attr('x', 5)
						.attr('y', 20)
						.attr('dy', '0.35em')
						.style('font', 'var(--fa-font-regular)')
						.style('font-weight', '900')
						.style('font-size', '22')
						.attr('tooltip-append-to-body', 'true')
						.text('\uf0c8')

					enteringNode.append('text').attr('class', 'task-checked')
						.attr('x', 5)
						.attr('y', 20)
						.attr('dy', '0.35em')
						.style('font', 'var(--fa-font-regular)')
						.style('font-weight', '900')
						.style('font-size', '24')
						.text('\uf046')

					enteringNode.append('text').attr('class', 'task-skipped')
						.attr('x', 5)
						.attr('y', 20)
						.attr('dy', '0.35em')
						.style('font', 'var(--fa-font-regular)')
						.style('font-weight', '900')
						.style('font-size', '24')
						.text('\uf147')
				}

				function updateChevrons() {
					var chevron = node.select('path.task-chevron')

					chevron.transition()
						.attr('d', function (d) {
							var specificChevronHeight = getHeight(d);

							return d3.svg.line()([
								[0, 0],
								[chevronWidth / 2, chevronDivot],
								[chevronWidth, 0],
								[chevronWidth, specificChevronHeight - chevronDivot],
								[chevronWidth / 2, specificChevronHeight],
								[0, specificChevronHeight - chevronDivot],
								[0, 0]
							])
						})
						.attr('stroke', function (d) {
							return d.completedDateTime ? 'var(--ace-success)' : 'gray';
						})
						.attr('fill', function (d) {
							return d.isReady ? 'lightblue' : '#eee';
						})

					chevron.on('mouseenter', function (d) {
						scope.hoveredTask = d;
						hideContextMenus();
						return false;
					})
						.attr('context-menu', function (d, i) {
							return 'getContextOptions(' + d.taskID + ',' + i + ')'
						});
				}

				function launchUserTeamPicker(task, currentIndex) {
					scope.editingTask = angular.copy(task);

					//determine instance scope filter to use on the user picker if needed for a consultant
					instanceRepository.getEntityInstanceId(scope.editingTask.entityTypeID, scope.editingTask.entityId).toPromise()
					.then(instanceId=>	{
						if(userInstanceService.isInstanceImplicit(instanceId)) {
							let scopeInstanceId = userInstanceService.getSelectedInstance().instanceId;
							scope.instanceIdUserFilter = scopeInstanceId;
						}
						else {
							scope.instanceIdUserFilter = null;
						}
					});

					currentNode = d3.select(node[0][currentIndex]);
					var extraHeight = 125 - getHeight(task)

					svg.transition()
						.attr('height', Number(svg.attr('height')) + extraHeight);

					currentNode.select('path.task-chevron')
						.transition()
						.attr('d', function (d) {
							var specificChevronHeight = getHeight(d) + extraHeight;

							return d3.svg.line()([
								[0, 0],
								[chevronWidth / 2, chevronDivot],
								[chevronWidth, 0],
								[chevronWidth, specificChevronHeight - chevronDivot],
								[chevronWidth / 2, specificChevronHeight],
								[0, specificChevronHeight - chevronDivot],
								[0, 0]
							])
						})

					currentNode.select('text.task-user')
						.attr('visibility', 'hidden')

					currentNode.select('text.task-team')
						.attr('visibility', 'hidden')

					currentNode.select('text.task-deliverable')
						.attr('visibility', 'hidden');

					node.transition()
						.attr('transform', function (d, i) {
							if (i > currentIndex) {
								var y = _.words(d3.select(this).attr('transform'))[2];


								return 'translate(' + padding + ',' + (Number(y) + extraHeight) + ')';
							} else {
								return d3.select(this).attr('transform')
							}
						});

					var y = _.words(completeNode.attr('transform'))[2];

					completeNode.transition()
						.attr('transform', 'translate(' + padding + ',' + (Number(y) + extraHeight) + ')');

					var picker = currentNode.append('g')
						.attr('class', 'picker')

					picker.append('text')
						.attr('y', 60)
						.attr('width', chevronWidth)
						.attr('height', 30)
						.attr('dy', '0.35em')
						.attr('x', 10)
						.text('Assigned To:')
					// .style('font-weight', 'bold')

					picker.append('text')
						.attr('y', 85)
						.attr('width', 30)
						.attr('height', 30)
						.attr('dy', '0.35em')
						.attr('x', 215)
						.text("\uf00d")
						.style('font', 'var(--fa-font-regular)')
						.style('font-weight', '900')
						.style('font-size', '24')
						.style('cursor', 'pointer')
						.attr('fill', 'red')
						.on('click', function () {
							revertCurrentNode();

							updateTaskGroup();
						})

					var foreignObject = picker.append('foreignObject')
						.attr('y', 70)
						.attr('width', 200)
						.attr('height', 30)
						.attr('dy', '0.35em')
						.attr('x', 10)

					var teamPickerDiv = foreignObject
						.append('xhtml:div')
						.style('display', 'none');

					teamPickerDiv.append('xhtml:sd-user-team-picker')
						.attr('picker-sm', 'true')
						.attr('user-id', 'editingTask.assignedUserID')
						.attr('team-id', 'editingTask.assignedTeamID')
						.attr('user', 'editingTask.assignedUser')
						.attr('team', 'editingTask.assignedTeam')
						.attr('entity-id-scope', '[editingTask.taskID]')
						.attr('entity-type-scope', '"task"')
						.attr('append-to-body', "true")
						.attr('close-fn', 'userPicked()')
						.attr('is-reassign', 'true')
						.attr('instance-id-scope', 'instanceIdUserFilter')


					teamPickerDiv.transition()
						.delay(500)
						.style('display', 'block')

					$compile(element)(scope);

				}

				function revertCurrentNode() {
					scope.editingTask = null;

					currentNode.select('g.picker')
						.remove();

					currentNode.select('text.task-user')
						.attr('visibility', 'visible')

					currentNode.select('text.task-team')
						.attr('visibility', 'visible')

					currentNode.select('text.task-deliverable')
						.attr('visibility', 'visible')
				}

				function userPicked() {
					scope.waitingOnServer = true;
					taskService.updateTask(scope.editingTask)
						.then(function (data) {
							revertCurrentNode();

							updateAllTasks(data);
						});
				}

				function updateChevronText() {
					node.select('text.task-name')
						.style('font-weight', function (d) {
							return d.isReady ? 'bold' : 'normal'
						})
						.text(function (d) {
							return d.name
						})
						.each(wrap)
						.attr('y', padding + 20 + (verticalOffset / 2))


					node.select('text.task-user')
						.text(function (d) {
							if (d.isReady) {
								return 'Assign: ' + d.assignedUser.firstName + ' ' + d.assignedUser.lastName;
							}
						})
						.each(wrap)
						.attr('y', padding + 40 + (verticalOffset / 2))
						.attr('fill', function (d) {
							return d.assignedUserIsOverridden ? 'red' : 'initial';
						})

					node.select('text.task-team')
						.text(function (d) {
							if (d.isReady) {
								return d.assignedTeam.name;
							}
						})
						.each(wrap)
						.attr('y', padding + 60 + (verticalOffset / 2))
						.attr('fill', function (d) {
							return d.assignedTeamIsOverridden ? 'red' : 'initial';
						})

					node.select('text.task-deliverable')
						.attr('y', function (d) {
							return d.isReady ? padding + 80 + (verticalOffset / 2) : padding + 40 + (verticalOffset / 2);
						})
						.text(function (d) {
							if (isDeliverable(d)) {
								var deliverableStr = '';
								var dateFormatStr = '';

								switch(d.taskTypeID) {
									case TaskTypes.INFORMALHEARING:
									case TaskTypes.FORMALHEARING:
									case TaskTypes.SUBMITEVIDENCE:
										dateFormatStr = 'M/D/YYYY h:mm a';
										break;
									default:
										dateFormatStr = 'M/D/YYYY';
								}

								deliverableStr += d.deliverableName ? d.deliverableName : 'Due';
								deliverableStr += ' ';
								deliverableStr += d.dueDate ? moment.utc(d.dueDate).format(dateFormatStr) : '?';

								return deliverableStr;
							}
						})
						.each(wrap);

					node.select('text.task-checkbox')
						.attr('visibility', function (d) {
							return d.isReady && d.taskAuthorizationInfo.completeTask ? 'visible' : 'hidden';
						})
						.style('cursor', function(d) {
							return d.taskType.systemCompletes ? 'not-allowed' : 'pointer';
						})
						.style('fill', function(d) {
							return d.taskType.systemCompletes ? 'lightgray' : 'white';
						})
						.attr('uib-tooltip', function(d) {
							return d.taskType.systemCompletes ? "Cannot Complete. Skip instead." : "";
						})
						.on('click', function (d) {
							if(d.taskType.systemCompletes) {
								return;
							}

							completeTask(d, this);
						});

					node.select('text.task-checked')
						.attr('visibility', function (d) {
							return d.completedDateTime && !d.skipped ? 'visible' : 'hidden';
						})

					node.select('text.task-skipped')
						.attr('visibility', function (d) {
							return d.skipped ? 'visible' : 'hidden';
						})
				}

				function wrap() {
					var self = d3.select(this),
						textLength = self.node().getComputedTextLength(),
						text = self.text(),

						// The task name should be shorter than the rest because it overlaps with the checkbox
						paddingMultiplyer = _.includes(self.attr('class'), 'task-name') ? 5 : 2;

					while (textLength > (chevronWidth - paddingMultiplyer * padding) && text.length > 0) {
						text = text.slice(0, -1);
						self.text(text + '...');
						textLength = self.node().getComputedTextLength();
					}
				}

				function hideContextMenus() {
					var dropdowns = angular.element('.angular-bootstrap-contextmenu .dropdown-menu, .dropdown.clearfix .dropdown-menu');
					dropdowns.css('display', 'none');
				}

				function updateCompleteChevron() {
					var lastTaskCompletedDateTime = _.last(scope.taskGroup.tasks).completedDateTime;
					var strokeColor = lastTaskCompletedDateTime ? 'var(--ace-success)' : 'gray';

					completeNode
						.transition()
						.attr('fill-opacity', 1)
						.attr('stroke-opacity', 1)
						.attr('transform', "translate(" + padding + "," + (currentChevronPos + padding) + ")")

					completeChevron.attr('stroke', strokeColor)

					if (lastTaskCompletedDateTime) {
						completeCheck.text('\uf00c')
						completeText.attr('y', completeChevronHeight * .42);
						completeDate.text(moment.utc(lastTaskCompletedDateTime).tz("America/Chicago").format('M/D/YYYY'));
						completeNode.style('font-weight', 'bold');
					} else {
						completeCheck.text('');
						completeText.attr('y', completeChevronHeight / 2);
						completeDate.text('');
						completeNode.style('font-weight', 'normal');
					}
				}

				scope.getContextOptions = function (taskID, index) {
					var rightClickMenuItems = [],
						task = {},
						deleteTaskMenu = [],
						reassignTaskMenu = [],
						rescindTaskMenu = [],
						skipTaskMenu = [];

					var deferred = $q.defer();

					// No menu if we are in edit mode
					if (scope.editingTask) {
						deferred.resolve([]);
					}

					// Load the task by taskID - so we have all variables needed
					task = _.find(scope.taskGroup.tasks, function (t) {
						return t.taskID === taskID;
					});

					// Add individual action functions
					deleteTaskMenu = ['Delete Task', function () { deleteTask(task) }];
					reassignTaskMenu = ['Reassign Task', function () { reassignTask(task, index) }];
					rescindTaskMenu = ['Rescind Task', function () { rescindTask(task) }];
					skipTaskMenu = ['Skip Task', function () { skipTask(task) }];

                    if (task.taskAuthorizationInfo.deleteTask && !task.taskType.systemDeletes === true) {
						rightClickMenuItems.push(deleteTaskMenu);
					}
                    if (task.taskAuthorizationInfo.reassignTask && !task.taskType.systemReassigns === true) {
						rightClickMenuItems.push(reassignTaskMenu);
					}
                    if (task.taskAuthorizationInfo.rescindTask && !task.taskType.systemRescinds === true) {
						rightClickMenuItems.push(rescindTaskMenu);
					}
					if (task.taskAuthorizationInfo.skipTask && !task.taskType.systemSkips === true) {
						rightClickMenuItems.push(skipTaskMenu);
					}

					taskService.getInsertOptions(task.entityId, task.entityTypeID, task.taskID)
						.then(function (data) {
							data.insertBefore = _.map(data.insertBefore, function (item) {
								return [item.name, function () { insertTaskBefore(task, item.taskTypeID) }];
							})

							data.insertAfter = _.map(data.insertAfter, function (item) {
								return [item.name, function () { insertTaskAfter(task, item.taskTypeID) }];
							})

							if (data.insertAfter.length && task.taskAuthorizationInfo.insertTaskAfter) {
								rightClickMenuItems.unshift(['Insert Task Below', data.insertAfter])
							}

							if (data.insertBefore.length && task.taskAuthorizationInfo.insertTaskBefore) {
								rightClickMenuItems.unshift(['Insert Task Above', data.insertBefore])
							}

							deferred.resolve(rightClickMenuItems);

						});

					return deferred.promise;
				}

				//Context Menu Functions
				function insertTaskBefore(task, newTaskTypeID) {
					scope.waitingOnServer = true;
					taskService.insertTask(task, newTaskTypeID, TaskActionTypes.INSERTNEWBEFORETHISTASK).then(function (data) {
						updateAllTasks(data)
						$rootScope.$broadcast('entityTaskUpdated');
					});
				}

				function insertTaskAfter(task, newTaskTypeID) {
					scope.waitingOnServer = true;
					taskService.insertTask(task, newTaskTypeID, TaskActionTypes.INSERTNEWAFTERTHISTASK).then(function (data) {
						updateAllTasks(data)
						$rootScope.$broadcast('entityTaskUpdated');
					});
				}

				function deleteTask(task) {
					if (confirm('Are you sure you wish to delete ' + task.name + '?')) {
						scope.waitingOnServer = true;
						taskService.deleteTask(task)
							.then(function (response) {
								updateAllTasks(response);
								$rootScope.$broadcast('entityTaskUpdated');
							});
					}
				}

				function reassignTask(task, index) {
					launchUserTeamPicker(task, index);

					//Added this in - if a reassign happens then we need to reload the activity panel
                    $rootScope.$broadcast('entityTaskUpdated');
				}

				function rescindTask(task) {
					if (confirm('Are you sure you wish to rescind the status of ' + task.name + '?')) {
						scope.waitingOnServer = true;
						taskService.rescindTask(task)
							.then(function (response) {
								updateAllTasks(response);
								$rootScope.$broadcast('entityTaskUpdated');
							});
					}
				}

				function skipTask(task) {
					scope.waitingOnServer = true;
					taskService.skipTask(task)
						.then(function (response) {
							updateAllTasks(response);
							$rootScope.$broadcast('entityTaskUpdated');
						});
				}

				function completeTask(task, clickedNode) {
					startSpinner(clickedNode, task);
					scope.waitingOnServer = true;

					taskService.completeTask(task)
						.then(function (response) {
							updateAllTasks(response);
							$rootScope.$broadcast('entityTaskUpdated');
						}).catch(function () {
							stopSpinner(clickedNode);
							scope.waitingOnServer = false;
						});
				}

				function getHeight(task) {
					return verticalOffset + (function () {
						if (task.isReady) {
							return isDeliverable(task) ? heights.xl : heights.lg;
						} else if (isDeliverable(task)) {
							return heights.md;
						} else {
							return heights.sm;
						}
					})()
				}

				function updateAllTasks(data) {
					scope.updateData({ data: taskService.splitIntoGroups(data.tasks) });
					scope.waitingOnServer = false;
					stopSpinner();
				}

				function isDeliverable(task) {
					return task.deliverableName || task.dueDate;
				}

				function startSpinner(clickedNode, task) {
					d3.select(clickedNode)
						.attr('visibility', 'hidden')

					d3.select(clickedNode.parentNode)
						.append('text')
						.attr('class', 'fa fa-spinner')
						.text('\uf110')
						.attr('y', 20)
						.attr('x', 5)
						.attr('dy', '0.35em')
						.style('font', 'var(--fa-font-regular)')
						.style('font-weight', '900')
						.style('font-size', '24')
					// .style('animation', 'fa-spin 1s infinite steps(8)')
				}

				function stopSpinner(clickedNode) {
					if (clickedNode) {
						d3.select(clickedNode)
							.attr('visibility', '')
					}
					d3.select('text.fa-spinner').remove();
				}

				scope.getLocalTimezone = function () {
					return moment.tz.guess();
				}
			}
		};

		return directive;
	}
})();
