<div class="parcel-list-panel card panel-flat-color secondary-panel flex-panel">
    <div class="panel-flat-header">
        <h3>Parcel List</h3>
        <ws-switch class="parcel-list-switch"
                   helpTooltip
                   helpContentId="parcel-list-panel.view-switch"
                   onLabel="Table"
                   offLabel="Tile"
                   [formControl]="contentTypeToggle"
                   (selectedOptionChanged)="onViewChanged($event)">
        </ws-switch>
        <div class="header-button-container" style="display: inline-block; margin-left: 20px; vertical-align: top;">
            <button class="ace-btn btn-text has-icon fa fa-plus-circle" *ngIf="hasSiteWritePermission" [disabled]="dataLoading || editMode"
                    (click)="showAddParcel()" title="Add Parcel"></button>
        </div>
        <div class="float-end header-button-container header-right ws-flex-container-horizontal ws-flex-align-items-center">
            <label class="checkbox" *ngIf="isExcludeInactiveParcelsCheckboxVisible">
                <input type="checkbox" name="excludeInactiveParcels" [formControl]="excludeInactiveParcels"> Exclude Inactive
            </label>
            <label class="tax-year-label">Assessed Tax Year: </label>
            <ws-select class="tax-year-select" [options]="availableTaxYears" [formControl]="selectedTaxYear" [isPrimitive]="true" [returnEntireOption]="true" size="sm" (selectedOptionChanged)="onTaxYearChanged($event)"></ws-select>

            <button type="button" class="ace-btn btn-text has-icon btn-primary m-0" (click)="edit()" *ngIf="!editMode && contentTypeToggle.value && !dataLoading && canEdit"><i class="fa fa-pencil"></i></button>
            <span *ngIf="editMode">
                <button type="button" class="ace-btn btn-text has-icon btn-primary btn-success m-0" [disabled]="dataLoading || !hasChanges" (click)="save()">
                    <i class="fa fa-save"></i>
                </button>
                <button type="button" class="ace-btn btn-text has-icon btn-primary btn-danger m-0" [disabled]="dataLoading" (click)="cancel()">
                    <i class="fa fa-close"></i>
                </button>
            </span>

            <button type="button" class="ace-btn btn-text has-icon btn-secondary m-0" *ngIf="!isExpanded" (click)="expandPanel()">
                <i class="fa fa-arrows-alt" title="Expand"></i>
            </button>
            <button type="button" class="ace-btn btn-text has-icon btn-secondary m-0" *ngIf="isExpanded" (click)="collapsePanel()">
                <i class="fa fa-compress" title="Collapse"></i>
            </button>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="panel-flat-body ws-flex-container-vertical" [ngStyle]="{ height: (!isExpanded) ? '410px' : '100%' }">

        <div class="ws-flex-align-self-center" style="margin-top: 160px" *ngIf="dataLoading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
        </div>

        <div *ngIf="!contentTypeToggle.value && parcelList && !dataLoading" style="display: flex; flex-wrap: wrap;">
            <parcel-tile *ngFor="let parcel of parcelList.tiles" [item]="parcel" (navigateToParcel)="navigateToParcel($event, parcel)" [stateAllowConsolidating]="stateAllowConsolidating" [ppReturnPreparationAllowed]="ppReturnPreparationAllowed"></parcel-tile>
        </div>

        <div *ngIf="contentTypeToggle.value || agGridReady"
             [hidden]="!contentTypeToggle.value || dataLoading"
             class="ws-stretch-height">
            <ws-ag-grid-angular [gridOptions]="gridOptions"
                                [companyId]="site.companyID"
                                [gridId]="gridId"
                                [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                                (gridReady)="onAgGridReady($event)"
                                (bulkUpdate)="bulkUpdate()">
            </ws-ag-grid-angular>
        </div>
    </div>
</div>
