import { Component, Input, ViewChild } from '@angular/core';
import { ContractTerm } from './contract-invoices.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import * as _ from 'lodash';
import { ContractServiceType } from '../../constants.new';

@Component({
    selector: 'choose-term-popover',
    templateUrl: './choose-term-popover.component.html'
})
export class ChooseTermPopoverComponent {
    @Input() selectedTerm: ContractTerm;
    @ViewChild('chooseTerm', { static: true }) public chooseTerm: PopoverDirective;
    termToEdit: ContractTerm;
    ContractServiceType = ContractServiceType;

    lookupTerm(): void {
        this.termToEdit = _.cloneDeep(this.selectedTerm);

        if(!this.termToEdit.contingencyPct) {
            this.termToEdit.contingencyCap = null;
        }

        this.chooseTerm.show();
    }

    saveTerm(): void {
        this.selectedTerm.contingencyPct = this.termToEdit.contingencyPct;
        this.selectedTerm.contingencyCap = this.termToEdit.contingencyCap;
        this.selectedTerm.flatFee = this.termToEdit.flatFee;
        this.selectedTerm.hourlyRate = this.termToEdit.hourlyRate;
    }
}