import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseRepository } from '../../Common/Data/base.repository';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../Account/userSettings.service';

@Injectable(
    { providedIn: 'root' }
)
export class IncomeStatementRepository extends BaseRepository {
    constructor(
        httpClient: HttpClient,
        private readonly _userSettingsService: UserSettingsService
    ) { super(httpClient); }

    getIncomeStatementList(companyId: number, searchParams: Core.IncomeStatementLineSearchModel): Observable<Compliance.QueryResultWithTotalsModel<Core.IncomeStatementLineSearchModel, Core.IncomeStatementLineSearchTotalsModel>> {
        return this.httpPost(`/api/incomeStatementLine/company/${companyId}/rows`, searchParams);
    }

    getIncomeStatementEndDates(companyId: number, siteId?: number): Observable<Date[]> {
        let url = `/api/incomeStatementLine/company/${companyId}/endDates`;

        if(siteId) {
            url += `?siteId=${siteId}`;
        }

        return this.httpGet(url);
    }

    startIncomeStatementLineExport(companyId: number, exportModel: Compliance.IncomeStatementLineExportModel): Observable<number> {
        return this.httpPost(`/api/incomeStatementLine/company/${companyId}/export`, exportModel);
    }

    getExport(companyId: number, longRunningProcessId: number): Observable<HttpResponse<Blob>> {
        const options = {
            observe: 'response',
            responseType: 'blob'
        };
        return this.httpGet(`/api/incomeStatementLine/company/${companyId}/export/${longRunningProcessId}`, options);
    }

    getIncomeStatementRowIds(companyId: number, searchParams: Core.IncomeStatementLineSearchModel): Observable<Compliance.QueryResultModel<number>> {
        return this.httpPost(`/api/incomeStatementLine/company/${companyId}/rowids`, searchParams);
    }

    deleteItem(incomeStatementLineId: number): Observable<void> {
        return this.httpDelete(`api/incomeStatementLine/${incomeStatementLineId}`);
    }

    startBulkDelete(companyId: number, model: Compliance.IncomeStatementLineBulkDeleteModel): Observable<number> {
        return this.httpPut(`/api/incomestatementline/company/${companyId}/startBulkDelete/`, model);
    }

    updateIsValidated(model: Core.IncomeStatementLineItemIsValidatedUpdateModel): Observable<Core.IncomeStatementLineItemIsValidatedUpdateResultModel> {
        return this.httpPut('/api/incomestatementline/updateIsValidated', model);
    }

    updateBalance(model: Core.IncomeStatementLineBalanceUpdateModel): Observable<number[]> {
        return this.httpPut(`/api/incomestatementline/updateBalance`, model);
    }

    bulkUpdate(companyId: number, model: Core.IncomeStatementLineBulkUpdateModel): Observable<boolean> {
        return this.httpPut(`/api/incomestatementline/company/${companyId}/bulkUpdate`, model);
    }
}
