import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { OrderAndTotalByOption, GenericEntity } from '../../report.manager.model';
import { ReportManagerService } from '../../report.manager.service';
import { Subscription } from 'rxjs';

import { cloneDeep, reject } from 'lodash/fp';
import * as _ from 'lodash';

@Component({
    selector: 'report-order-by',
    template: `
        <div class="form-group" style="margin-bottom: 0;">
            <label>Order <span *ngIf="isTotal">and Total </span> By:</label>
            <ws-picklist [options]="orderAndTotalByOptions"
                         [(ngModel)]="orderAndTotalBySelection"
                         (picklistChanged)="orderAndTotalByChanged()"
                         [returnEntireOption]="true"
                         labelProperty="name"
                         placeholder="Select an option...">
            </ws-picklist>
        </div>
    `
})
export class ReportOrderByComponent implements OnInit, OnDestroy {
    constructor(private readonly _reportManagerService: ReportManagerService) {}

    @Input() isTotal?: boolean = true;
    @Input() orderBy: number[] = [];

    @Output() orderByChange = new EventEmitter<number[]>();

    orderAndTotalBySelection: OrderAndTotalByOption[] = [];
    orderAndTotalByOptions: OrderAndTotalByOption[] = [];
    orderAndTotalByOptionsOriginal: OrderAndTotalByOption[];

    subscription: Subscription;

    async ngOnInit(): Promise<void> {
        this.subscription = this._reportManagerService.currentPropChars$.subscribe(propChars => {
            this.filterPropChars(propChars);
        });

        const orderByOptions = await this._reportManagerService.getOrderAndTotalByOptions();

        this.orderAndTotalByOptions = _.sortBy(orderByOptions, ['primarySortOrder', 'name']);

        this.orderAndTotalByOptionsOriginal = _.cloneDeep(this.orderAndTotalByOptions);

        if (this.orderBy.length) {
            this.orderAndTotalBySelection = this.orderBy.map(x => this.orderAndTotalByOptions.find(y => x === y.id));
        }
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    orderAndTotalByChanged(): void {
        this.orderBy = this.orderAndTotalBySelection.map(x => x.id);
        this.orderByChange.emit(this.orderBy);
    }

    filterPropChars(entityPropChars: GenericEntity[]): void {
        if(entityPropChars.length) {
            this.orderAndTotalByOptions = this.orderAndTotalByOptions.filter(option => {
                return entityPropChars.map(x => x.id).includes(option.id) || option.id < 0 || !option.id;
            });
        } else {
            this.orderAndTotalByOptions = _.flow([
                cloneDeep,
                reject(option => _.some(this.orderBy, {id: option.id}))
            ])(this.orderAndTotalByOptionsOriginal);
        }
    }
}
