import { Component, OnInit, ViewChild } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { Invoice, InvoiceRouteNames, InvoiceTypes, OverrideStatus } from './invoice.model';
import {InvoiceService} from './invoice.service';
import {TaskService} from '../../Task/task.service.upgrade';
import {Constants, EntityType, TaskType, ContractServiceType} from '../../constants.new';
import {MessageBoxButtons, MessageBoxService} from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { InvoiceRecipientLookupComponent } from '../../Common/Contracts-Invoices/invoice-recipient-lookup.component';
import { ToastrService } from 'ngx-toastr';
import {AttachmentModalData, AttachmentModalEntityData} from '../../Attachment/attachment.modal.model';
import {CommentModalData} from '../../Comments/comments.service';
import {NavigationService} from '../../Layout/navigation.service';
import { ActionViewPersistenceService } from '../../Task/ActionView/Action.View.Persistence.Service.upgrade';
import {RestrictService, Roles} from '../../Common/Permissions/restrict.service';
import * as _ from 'lodash';
import { FeatureFlagsService } from '../../Common/FeatureFlags/feature-flags-service';
import { ContractTerm, SiteContractTerm, TermItem } from 'src/app/Common/Contracts-Invoices/contract-invoices.model';
import { ContractsInvoicesService } from '../../Common/Contracts-Invoices/contract-invoices.service';

@Component({
    selector: 'invoice',
    templateUrl: './invoice.component.html'
})
export class ProcessInvoiceComponent implements OnInit {
    constructor(public invoiceService: InvoiceService,
                private navigationServiceHandler: UpgradeNavigationServiceHandler,
                private navigationService: NavigationService,
                private actionViewPersistenceService: ActionViewPersistenceService,
                private taskService: TaskService,
                private constants: Constants,
                private messageBox: MessageBoxService,
                private toastr: ToastrService,
                restrictService: RestrictService,
                featureFlagsService: FeatureFlagsService,
                private readonly _contractsInvoicesService: ContractsInvoicesService
        ) {
        this.canTransferToRIBS = restrictService.isInRole(Roles.FINANCIALANALYST);
        this.enableInvoiceUBR = featureFlagsService.featureFlags.enableInvoiceUBR;
        this.isRyanLegalServiceEnabled = featureFlagsService.featureFlags.enableInvoiceRLS;
    }

    @ViewChild('invoiceRecipientLookup') invoiceRecipientLookup: InvoiceRecipientLookupComponent;

    canTransferToRIBS: boolean;
    // Primary invoice object
    currentInvoice: Invoice;
    cachedInvoice: Invoice;

    //If there's a queue, this will be populated (aka from Action View)
    taskList: number[];
    taskIndex: number = 0;

    InvoiceTypes = InvoiceTypes;
    ContractServiceType = ContractServiceType;

    editMode: boolean = false;
    taskOnlyEditMode: boolean = false;
    isInvoiceCompleted: boolean = false;
    loading: boolean = true;
    callInProgress: boolean = false;

    attachmentData: AttachmentModalData;
    commentData: CommentModalData;

    companyUrl: string;
    siteUrl: string;

    completeText: string;
    closeText: string;
    currentState: any;
    navigationWarningText: string = 'If you continue you will lose your unsaved changes. Do you wish to continue?';

    enableInvoiceUBR: boolean;
    isRyanLegalServiceEnabled: boolean;

    canChangeContractType: boolean = false;
    isAppealSavingsInvoice: boolean = false;
    appealContractTypes: Compliance.NameValuePair<ContractServiceType>[] = [];
    companyAppealContractTerms: ContractTerm[] = [];
    currentContractTermDetailId: number;

    private _siteAppealContractTerms: SiteContractTerm[];
    private _currentContractTerm: ContractTerm;
    private _isContractTermOverridden: boolean = false;

    get contractTerm(): ContractTerm {
        return this._currentContractTerm;
    }

    get isContractTermOverridden(): boolean {
        return this._isContractTermOverridden;
    }

    async ngOnInit(): Promise<void> {
        await this.setupPage();
    }

    async setupPage(): Promise<void> {
        const state: any = this.navigationServiceHandler.getCurrentState();
        let invoiceId: string;

        this.currentState = state;

        if(state.name === InvoiceRouteNames.Company || state.name === InvoiceRouteNames.Site) {
            invoiceId = this.navigationServiceHandler.getQuerystringParam('invoiceID');
            await this.getInvoiceById(invoiceId);
        } else if(state.name === InvoiceRouteNames.Processing) {
            const draftId = this.navigationServiceHandler.getQuerystringParam('draftId');
            const taskString: string = sessionStorage[`InvoiceDrafts${  draftId}`];
            this.taskList = JSON.parse(taskString);
            this.editMode = true;
            this.navigationService.enableNavWarning(this.navigationWarningText);
            await this.getInvoiceForTask(this.taskList[this.taskIndex]);
        }

        this.isAppealSavingsInvoice = !this.currentInvoice.isAdHoc && (this.currentInvoice.contractServiceType === ContractServiceType.Appeal ||
            this.currentInvoice.contractServiceType === ContractServiceType.Litigation);

        if (this.isAppealSavingsInvoice) {
            this.appealContractTypes = Object
                .keys(this.constants.ContractServiceTypes)
                .map(key => { return { value: this.constants.ContractServiceTypes[key].id, name: this.constants.ContractServiceTypes[key].name }; })
                .filter(t => t.value === ContractServiceType.Appeal || t.value === ContractServiceType.Litigation);

            this._siteAppealContractTerms = await this._contractsInvoicesService.getContractTermsBySite(this.currentInvoice.siteId);

            const hasLitigationContractTypes = this._siteAppealContractTerms.some(x => x.serviceId === ContractServiceType.Litigation &&
                (!x.taxYear || x.taxYear === this.currentInvoice.annualYear));
            const hasAppealContractTypes = this._siteAppealContractTerms.some(x => x.serviceId === ContractServiceType.Appeal &&
                (!x.taxYear || x.taxYear === this.currentInvoice.annualYear));
            this.canChangeContractType = hasLitigationContractTypes && hasAppealContractTypes;

            const companyContractTerms = await this._contractsInvoicesService.getContractTermsByCompany(this.currentInvoice.companyId);
            const currentCompanyContractTerms = companyContractTerms.currentContractTerms?.length > 0
                ? companyContractTerms.currentContractTerms
                : companyContractTerms.topLevelCompanyContractTerms;
            this.companyAppealContractTerms = currentCompanyContractTerms
                .filter(x => x.contractServiceTypeId === this.currentInvoice.contractServiceType &&
                    (!x.taxYear || x.taxYear === this.currentInvoice.annualYear));
            this._calculateCurrentTermId();
        }

        this._currentContractTerm = {
            contingencyPct: this.currentInvoice?.contingencyPctOriginal,
            contingencyCap: this.currentInvoice?.contingencyCapOriginal,
            flatFee: this.currentInvoice?.fixedFeeOriginal
        } as ContractTerm;
    }

    async getInvoiceById(invoiceId: string): Promise<Invoice> {
        this.loading = true;
        const companyIDErrMsg = 'Company ID does not match Invoice';
        const siteIDErrMsg = 'Site ID does not match Invoice';
        const notFoundErrMsg = 'Invoice not found';

        try {
            const resultInvoice = await this.invoiceService.getInvoice(invoiceId);
            const companyIDParam = this.navigationServiceHandler.getQuerystringParam('companyID');
            const siteIDParam = this.navigationServiceHandler.getQuerystringParam('siteID');
            if (companyIDParam && +companyIDParam != resultInvoice.companyId) {
                throw new Error(companyIDErrMsg);
            }
            if (siteIDParam && +siteIDParam != resultInvoice.siteId) {
                throw new Error(siteIDErrMsg);
            }

            this.currentInvoice = resultInvoice;
            this.stashCurrentInvoice(resultInvoice);

            this.setButtonText();
            this.setAttachmentAndComments();
            this.isInvoiceCompleted = !this.currentInvoice.readyTaskId;

            this.companyUrl = this.navigationServiceHandler.getHrefFromState('company', { companyId: this.currentInvoice.companyId });
            this.siteUrl = this.navigationServiceHandler.getHrefFromState('site', { companyId: this.currentInvoice.companyId, siteId: this.currentInvoice.siteId });

            return this.currentInvoice;
        } catch (err: any) {
            if ([companyIDErrMsg, siteIDErrMsg, notFoundErrMsg].indexOf(err.message) >= 0) {
                this.toastr.error(err.message);
            }
            throw err;
        } finally {
            this.loading = false;
        }
    }

    async getInvoiceForTask(taskId: number): Promise<void> {
        this.loading = true;

        try {
            const resultInvoice = await this.invoiceService.getInvoiceForTask(taskId);
            this.currentInvoice =  resultInvoice;
            this.stashCurrentInvoice(resultInvoice);

            this.setButtonText();
            this.setAttachmentAndComments();

            this.companyUrl = this.navigationServiceHandler.getHrefFromState('company', {companyId: this.currentInvoice.companyId});
            this.siteUrl = this.navigationServiceHandler.getHrefFromState('site', {companyId: this.currentInvoice.companyId, siteId: this.currentInvoice.siteId});
        } finally {
            this.loading = false;
        }
    }

    setAttachmentAndComments(): void {
        this.attachmentData = new AttachmentModalData();
        this.commentData = new CommentModalData();

        this.commentData.entityID = this.currentInvoice.invoiceId;
        this.commentData.entityTypeID = EntityType.Invoice;
        this.commentData.description = 'Invoice';
        this.commentData.year = this.currentInvoice.annualYear.toString();
        this.commentData.canEdit = true;
        //TODO: Permission?

        this.attachmentData.entityData = new AttachmentModalEntityData();
        this.attachmentData.entityData.id = this.currentInvoice.invoiceId;
        this.attachmentData.entityData.typeId = EntityType.Invoice;
        this.attachmentData.entityData.name = 'invoice';
        // vvvv WK-9232 Invoice attachments can have no year
        // this.attachmentData.disableYears = true;
        // this.attachmentData.year = this.currentInvoice.annualYear;
        this.attachmentData.singleYear = true;
        //this.attachmentData.entityName = this.currentInvoice.invoiceNumber ? this.currentInvoice.invoiceNumber.toString() : '(draft)';
        this.attachmentData.entityType = 'Invoice';
        this.attachmentData.readOnly = !this.currentInvoice.readyTaskId;
    }

    setEditMode(): void {
        // Normally hitting "edit" means you can edit the invoice; however if it's completed, we still want an edit button
        // so the user can change tasks (WK-4287). Instead of trying to lock down parts of the interface in edit mode
        // when the invoice is complete, we'll use a single flag to indicate we're just allowing status edits
        if (!this.isInvoiceCompleted) {
            this.editMode = true;
            this.navigationService.enableNavWarning(this.navigationWarningText);

            //stash the current invoice in case of cancel
            this.cachedInvoice = _.cloneDeep(this.currentInvoice);
        }
        else {
            this.taskOnlyEditMode = true;
            this.navigationService.enableNavWarning(this.navigationWarningText);
        }
    }

    showCompleteButton(): boolean {
        return this.editMode && this.currentInvoice && (this.currentInvoice.readyTaskTypeId !== TaskType.TransferInvoiceToRIBS || this.canTransferToRIBS);
    }

    setButtonText(): void {
        switch(this.currentInvoice.readyTaskTypeId) {
            case TaskType.PrepareDraftInvoice:
                this.completeText = 'Complete Draft (Request Review)';
                break;
            case TaskType.ReviewDraftInvoice:
                this.completeText = `Complete Review (Request ${this.currentInvoice.isUBR ? 'UBR ' : ''}RIBS Transfer)`;
                break;
            case TaskType.TransferUBRToRIBS:
                this.completeText = 'Transfer UBR to RIBS (Request Relief of UBR)';
                break;
            case TaskType.RequestReliefOfUBR:
                this.completeText = 'Request Relief of UBR (Request RIBS Transfer)';
                break;
            case TaskType.TransferInvoiceToRIBS:
                this.completeText = 'Transfer to RIBS';
                break;
            case TaskType.AdjustInvoice:
                this.completeText = 'Complete Adjust Invoice';
                break;
        }

        if(this.taskList && this.taskList.length > 1 && (this.taskIndex < (this.taskList.length-1))) {
            this.completeText += ' and Show Next';
            this.closeText = 'Skip';
        } else {
            this.closeText = 'Close';
        }
    }

    cancelEdit(): void {
        if (this.taskOnlyEditMode) {
            this.taskOnlyEditMode = false;
        }
        else {
            //Warn users if there's data lost
            //Reset the currentInvoice from cache if necessary
            _.assign(this.currentInvoice, _.cloneDeep(this.cachedInvoice));

            // Reset the selected recipient
            this.invoiceRecipientLookup.setSelectedRecipient();

            //Clear cache version
            this.cachedInvoice = null;

            //Set edit mode false
            this.editMode = false;

            this._calculateCurrentTermId();
        }

        this.navigationService.disableNavWarning();
    }

    nextTask(): void {
        //TODO: If there are more tasks, get the next invoice
        console.log('Next task');
    }

    goToSite(): void {
        this.navigationServiceHandler.transitionTo('site', {companyId: this.currentInvoice.companyId, siteId: this.currentInvoice.siteId});
    }

    goToCompany(): void {
        const href: string = this.navigationServiceHandler.getHrefFromState('company', {companyId: this.currentInvoice.companyId});
        window.open(href, '_blank');
    }

    async launchTaskModal(): Promise<void> {
        // if not in edit mode just open the tasks modal
        if (!this.editMode) {
            if (this.taskOnlyEditMode) {
                // In this case, the invoice is complete; if the user opens the task modal and rescinds a task, it's likely that the page
                // was originally loaded with the assumption that the invoice was complete, and that is now not true. Just reload the data
                // in that case so we can rebuild the page under the correct assumption about the invoice's complete status
                this.taskService.launchTaskModal(this.currentInvoice.invoiceId, EntityType.Invoice, false, null, false).then(shouldReload => {
                    if (shouldReload) {
                        this.taskOnlyEditMode = false;
                        this.setupPage();
                    }
                });
            }
            else {
                await this.taskService.launchTaskModal(this.currentInvoice.invoiceId, EntityType.Invoice, true, null, true);
            }
            return;
        }

        try {
            // in edit mode, check to see if there have been any changes
            // if there have been changes save them otherwise keep current invoice
            await (this._hasChanges() ? this.save() : Promise.resolve(this.currentInvoice));

            // open tasks modal
            const hasChanges = await this.taskService.launchTaskModal(this.currentInvoice.invoiceId, EntityType.Invoice, false, null, false);
            // if any changes were made in the modal then reload the current invoice so we can get the updated status
            if (hasChanges) {
                await this.setupPage();
                // done reloading, if we're still in edit mode (no changes were previously saved) then exit edit mode
                if (this.editMode) {
                    this.cancelEdit();
                }
            }
        } catch (err) {
            this._rejectHandler(err);
        }
    }

    save(): Promise<Invoice> {
        const validationMessage: string = this._getValidationMessage();

        if(validationMessage) {
            this.toastr.error(validationMessage);

            return;
        }

        this.loading = true;
        return this.invoiceService.saveInvoice(this.currentInvoice).then((newInvoice: Invoice) => {
            const calcInvoice: Invoice = this.invoiceService.calculateInvoiceData(newInvoice);
            this.currentInvoice = calcInvoice;
            this.cachedInvoice = calcInvoice;

            this.editMode = false;
            this.navigationService.disableNavWarning();

            this.loading = false;
            this.toastr.success('Your invoice has successfully been saved', 'Success');

            this._calculateCurrentTermId();

            return Promise.resolve(this.currentInvoice);
        }).catch((error) => {
            this.loading = false;
            return Promise.reject(error);
        });
    }

    _getValidationMessage(): string {
        if( (this.currentInvoice.previousFMV || this.currentInvoice.previousFMV == 0)
            && (this.currentInvoice.invoiceFMV || this.currentInvoice.previousFMV == 0)
            && this.currentInvoice.previousFMV < this.currentInvoice.invoiceFMV) {
            return 'Previous FMV cannot be less than Invoice FMV!';
        }

        if(this.currentInvoice.feeAmountStatus == 'red'
            && (this.currentInvoice.feeAmount || this.currentInvoice.previousFMV == 0)
            && (this.currentInvoice.contingencyCap || this.currentInvoice.previousFMV == 0)
            && this.currentInvoice.feeAmount > this.currentInvoice.contingencyCap) {
            return 'Fee Amount cannot be greater than Contingency Cap!';
        }

        return '';
    }

    delete(): void {
        this.messageBox.confirm('Are you sure you wish to delete this invoice?').then(async () => {
            this.navigationService.disableNavWarning();
            this.loading = true;

            try {
                await this.invoiceService.deleteInvoice(this.currentInvoice.invoiceId);
                this.navigateBackHandler();
            } finally {
                this.loading = false;
            }
        });
    }

    complete(): void {
        if(this.currentInvoice.readyTaskTypeId == TaskType.TransferInvoiceToRIBS && !this.currentInvoice.thirdPartyApprovalDate) {
            this.toastr.error('Third Party Approval Date required for transfer to RIBS');

            return;
        }

        this.callInProgress = true;

        this.invoiceService.completeInvoice(this.currentInvoice).then((returnedInvoice: Invoice) => {
            this.currentInvoice = returnedInvoice;
            this.cachedInvoice = returnedInvoice;

            this.toastr.success('Invoice successfully completed', 'Success!');
            this.setButtonText();

            this.callInProgress = false;

            const state: any = this.navigationServiceHandler.getCurrentState();
            if(state.name === InvoiceRouteNames.Processing) {

                if(this.taskIndex < this.taskList.length-1) {
                    this.taskIndex++;
                    this.currentInvoice = null;
                    this.cachedInvoice = null;
                    this.getInvoiceForTask(this.taskList[this.taskIndex]);
                } else {
                    this.loading = true;
                    this.currentInvoice = null;
                    this.cachedInvoice = null;

                    this.messageBox.open({
                        title: 'SUCCESS',
                        message: 'You have successfully completed all invoices. Click ok to return',
                        buttons: MessageBoxButtons.OK}).then(() => {
                        this.navigationService.disableNavWarning();
                        this.navigateBackHandler();
                    });
                }
            } else {
                this.messageBox.open({
                    title: 'SUCCESS',
                    message: 'You have successfully completed your invoice. Click ok to return',
                    buttons: MessageBoxButtons.OK}).then(() => {
                    this.navigationService.disableNavWarning();
                    this.navigateBackHandler();
                });
            }
        }, (error:any) => {
            // If a 400 returns an array, they're validation errors
            if (error.status === 400 && _.isArray(error.data)) {
                this.toastr.warning(error.data.join(', '), 'Problem completing invoice');
            }

            this.callInProgress = false;
        });
    }

    skipInvoice(): void {
        const hasChangesPromise: Promise<void> = this._hasChanges() ? this.messageBox.confirm(this.navigationWarningText) : Promise.resolve();

        hasChangesPromise.then(() => {
            if(this.taskIndex < this.taskList.length-1) {
                this.taskIndex++;
                this.currentInvoice = null;
                this.cachedInvoice = null;
                this.toastr.info('', 'Skipping Invoice...');
                this.callInProgress = true;
                this.getInvoiceForTask(this.taskList[this.taskIndex]).then(() => this.callInProgress = false);
            } else {
                this.messageBox.open({
                    title: 'SUCCESS',
                    message: 'You have completed or skipped all invoices. Click ok to return',
                    buttons: MessageBoxButtons.OK}).then(() => {
                    this.navigationService.disableNavWarning();
                    this.navigateBackHandler();
                });
            }
        });
    }

    navigateBackHandler(): void {
        if(!this.currentState) {
            return;
        }

        if(this.currentState.name === InvoiceRouteNames.Site) {
            this.navigationServiceHandler.go('site', { companyId: this.currentInvoice.companyId, siteId: this.currentInvoice.siteId });
        } else if(this.currentState.name === InvoiceRouteNames.Company) {
            this.navigationServiceHandler.go('company', { companyId: this.currentInvoice.companyId });
        } else {
            this.navigationServiceHandler.go('actionview', this.actionViewPersistenceService.routeParams);
        }
    }

    onContractServiceTypeChange(): void {
        const term = this._siteAppealContractTerms.find(x => x.serviceId === +this.currentInvoice.contractServiceType &&
            (x.taxYear === this.currentInvoice.annualYear || !x.taxYear));
        this._updateInvoice(term.currentTermItem);
    }

    onContractTermChange(contractTermDetailId: number) {
        const contractTermDetail = this.companyAppealContractTerms
            .find(x => x.contractTermDetailId === +contractTermDetailId);
        this._updateInvoice(contractTermDetail);

        this._isContractTermOverridden = !contractTermDetail.siteDefault;
    }

    private _rejectHandler(error?: any) {
        return null;
     }

    private _hasChanges(): boolean {
        return this.editMode && !_.isEqual(this.currentInvoice, this.cachedInvoice);
    }

    private _updateInvoice(term: TermItem | ContractTerm) {
        this.currentInvoice.contingencyPctOriginal = term.contingencyPct;
        this.currentInvoice.contingencyPct = term.contingencyPct;
        this.currentInvoice.contingencyCapOriginal = term.contingencyCap;
        this.currentInvoice.contingencyCap = term.contingencyCap;
        this.currentInvoice.fixedFee = term.flatFee;
        this.currentInvoice.fixedFeeOriginal = term.flatFee;

        this._currentContractTerm = {
            contingencyPct: this.currentInvoice.contingencyPct,
            contingencyCap: this.currentInvoice.contingencyCap,
            flatFee: this.currentInvoice.fixedFee
        } as ContractTerm;

        this.currentInvoice.feeAmountStatus = OverrideStatus.Recalculated;
        this.currentInvoice = this.invoiceService.calculateInvoiceData(this.currentInvoice);
        this.currentInvoice.feeAmountStatus = OverrideStatus.Default;
        this.currentInvoice = {...this.currentInvoice};
    }

    private stashCurrentInvoice(invoice: Invoice) {
        invoice.invoiceRevenueShareCodes = _.map(invoice.invoiceRevenueShareCodes, x => {
            x.allocationPct = x.allocationPct || 0;
            return x;
        });

        this.cachedInvoice = _.cloneDeep(invoice);
    }

    private _calculateCurrentTermId() {
        const selectedContractTermDetail = this.companyAppealContractTerms
            .find(x => x.contractServiceTypeId === this.currentInvoice.contractServiceType &&
                (x.propertyTypeId && x.propertyTypeId === this.currentInvoice.propertyTypeID || !x.propertyTypeId) &&
                (x.taxYear === this.currentInvoice.annualYear || !x.taxYear) &&
                ((!x.contingencyPct && !this.currentInvoice.contingencyPctOriginal || x.contingencyPct === this.currentInvoice.contingencyPctOriginal) &&
                    (!x.contingencyCap && !this.currentInvoice.contingencyCapOriginal || x.contingencyCap === this.currentInvoice.contingencyCapOriginal) &&
                    (!x.flatFee && !this.currentInvoice.fixedFeeOriginal || x.flatFee === this.currentInvoice.fixedFeeOriginal)));
        if (selectedContractTermDetail) {
            this.currentContractTermDetailId = selectedContractTermDetail.contractTermDetailId;
        }

        this._isContractTermOverridden = !selectedContractTermDetail || !selectedContractTermDetail.siteDefault;
    }
}
