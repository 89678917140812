<div class="ws-flex-container-vertical ws-flex-auto" *ngIf="isInitialized">
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title page-title">
                User Groups
                <ag-grid-row-count [gridOptions]="gridOptions"></ag-grid-row-count>
            </h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item" *ngIf="hasEditPermission">
                    <button type="button"
                            class="ace-btn btn-sm btn-primary"
                            (click)="addNew()"
                            helpTooltip
                            helpContentId="filing-batch-list.new-filing-batch"
                            position="bottom">
                        Add New
                    </button>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button"
                            class="ace-btn btn-sm btn-secondary has-icon"
                            (click)="refresh()"
                            [disabled]="refreshing"
                            helpTooltip
                            helpContentId="app.refresh"
                            position="bottom">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="ws-flex-container-vertical ws-flex-auto">
        <ws-ag-grid-angular class="ag-theme-balham grid grid-cell-no-focus"
                         [gridOptions]="gridOptions"
                         (gridReady)="onAgGridReady($event)"
                         [gridId]="gridId">
        </ws-ag-grid-angular>
    </div>
</div>


