import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular/main';
import { AgGridMultiSelectTracker, AgGridMultiSelectHeaderRendererParams } from './agGridMultiSelectTracker';

@Component({
    selector: 'ag-grid-multi-select-header',
    template: `
                <span *ngIf="isPendingRangeSelection()" class="fa fa-spinner fa-spin"></span>
                <span
                    class="ag-header-select-all"
                    role="presentation"
                    title="Toggle selection for all rows"
                    *ngIf="!isPendingRangeSelection()"
                    (click)="toggleChecked($event)" >
                    <span role="presentation" [class]="getCheckboxClass(false)">
                        <span class="ag-icon" [class]="getCheckboxClass(true)"></span>
                    </span>
                </span>
              `
})
export class AgGridMultiSelectedHeaderRendererComponent implements IHeaderAngularComp {
    /**
     * SELECTED ALL ROWS | SELECTED INDIVIDUAL ROWS | HAS FILTERED ROWS | HAS SELECTED ALL FILTERED ROWS | STATE
     * true              | true                     | true              | true                           | checked
     * true              | true                     | true              | false                          | indeterminate
     * true              | true                     | false             | true                           | ERROR
     * true              | true                     | false             | false                          | indeterminate
     * true              | false                    | true              | true                           | checked (ERROR?)
     * true              | false                    | true              | false                          | ERROR
     * true              | false                    | false             | true                           | ERROR
     * true              | false                    | false             | false                          | checked
     * false             | true                     | true              | true                           | checked
     * false             | true                     | true              | false                          | unchecked
     * false             | true                     | false             | true                           | ERROR
     * false             | true                     | false             | false                          | indeterminate
     * false             | false                    | true              | true                           | checked
     * false             | false                    | true              | false                          | unchecked
     * false             | false                    | false             | true                           | ERROR
     * false             | false                    | false             | false                          | unchecked
     */

    private _params: AgGridMultiSelectHeaderRendererParams;
    private _tracker: AgGridMultiSelectTracker;
    private _rowId: number;

    agInit(params: AgGridMultiSelectHeaderRendererParams): void {
        this._params = params;
        this._tracker = params.tracker;
        this._rowId = params.value;
    }

    /**
     * Checks if the grid header component is checked. (see table for implementation logic)
     */
    isChecked(): boolean {
        const hasSelectedAllRows = this._tracker.getSelectedRowsModel().selectAllRows;
        const hasSelectedIndividualRows = this._tracker.getSelectedRowsModel().selectedRows.length > 0;
        const hasFilteredRows = this._tracker.hasFilteredRows();
        const hasSelectedAllFilteredRows = this._tracker.hasSelectedAllFilteredRows();
        if (hasSelectedAllRows && hasSelectedIndividualRows && hasFilteredRows && hasSelectedAllFilteredRows) return true;
        if (hasSelectedAllRows && !hasSelectedIndividualRows && !hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        if (hasSelectedAllRows && !hasSelectedIndividualRows && hasFilteredRows && hasSelectedAllFilteredRows) return true;
        if (!hasSelectedAllRows && hasSelectedIndividualRows && hasFilteredRows && hasSelectedAllFilteredRows) return true;
        if (!hasSelectedAllRows && !hasSelectedIndividualRows && hasFilteredRows && hasSelectedAllFilteredRows) return true;
        return false;
    }

    /**
     * Checks if the grid header component is unchecked. (see table for implementation logic)
     */
    isUnchecked(): boolean {
        const hasSelectedAllRows = this._tracker.getSelectedRowsModel().selectAllRows;
        const hasSelectedIndividualRows = this._tracker.getSelectedRowsModel().selectedRows.length > 0;
        const hasFilteredRows = this._tracker.hasFilteredRows();
        const hasSelectedAllFilteredRows = this._tracker.hasSelectedAllFilteredRows();
        if (!hasSelectedAllRows && hasSelectedIndividualRows && hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        if (!hasSelectedAllRows && !hasSelectedIndividualRows && hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        if (!hasSelectedAllRows && !hasSelectedIndividualRows && !hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        return false;
    }

    /**
     * Checks if the grid header component is indeterminate. (see table for implementation logic)
     */
    isIndeterminate(): boolean {
        const hasSelectedAllRows = this._tracker.getSelectedRowsModel().selectAllRows;
        const hasSelectedIndividualRows = this._tracker.getSelectedRowsModel().selectedRows.length > 0;
        const hasFilteredRows = this._tracker.hasFilteredRows();
        const hasSelectedAllFilteredRows = this._tracker.hasSelectedAllFilteredRows();
        if (hasSelectedAllRows && hasSelectedIndividualRows && hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        if (hasSelectedAllRows && hasSelectedIndividualRows && !hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        if (!hasSelectedAllRows && hasSelectedIndividualRows && !hasFilteredRows && !hasSelectedAllFilteredRows) return true;
        return false;
    }

    /**
     * Checks if the toggle button is enabled/disabled based on any ongoing activity in the grid.
     */
    isPendingRangeSelection(): boolean {
        const isGridPendingRangeSelection = this._tracker.isGridPendingRangeSelection();
        if (isGridPendingRangeSelection) return true;
        return false;
    }

    /**
     * Determines the class to be applied to the checkbox based on the state that is being tracked.
     * @param isForIconStyle Target icon classes.
     */
    getCheckboxClass(isForIconStyle: boolean): string {
        const isDisabled = this.isPendingRangeSelection() || this.isDisabled();

        let className = isForIconStyle ? 'ag-icon ag-icon-checkbox' : 'ag-checkbox';

        const isChecked = this.isChecked();
        if (isChecked) {
            className += isDisabled ? '-checked-readonly' : '-checked';
            return className;
        }

        const isUnchecked = this.isUnchecked();
        if (isUnchecked) {
            className += isDisabled ? '-unchecked-readonly' : '-unchecked';
            return className;
        }

        const isIndeterminate = this.isIndeterminate();
        if (isIndeterminate) {
            className += isDisabled ? '-indeterminate-readonly' : '-indeterminate';
            return className;
        }

        return className;
    }

    /**
     * Toggle checked status.
     * @param event Event.
     */
    toggleChecked(event: Event): void {
        if (!this.isDisabled()) {
            this._tracker.toggleAllChecked();

            if (this._params && this._params.updateAllRowValues) {
                this._params.updateAllRowValues(this._params, this.isChecked());
            }
        }
    }

    /**
     * Check if disabled
     */
    isDisabled(): boolean {
        return this._params && this._params.isCellRendererDisabledFn && this._params.isCellRendererDisabledFn(this._rowId, this._params.data);
    }
}
