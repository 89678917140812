<div class="modal-header">
    <h4 class="modal-title">Change History</h4>
    <span class="float-end">
        <button type="button" class="btn-close" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </span>
</div>
<div class="modal-body exclude-modal-styling" style="padding: 35px 15px 5px 15px;">
    <!-- No filter for now; it was originally included, but we determined it isn't really required (yet at least) -->
    <div style="display:none;">
        <label style="position: relative; top:-8px; margin-right: 4px;">Filter: </label>
        <div class="input-group" style="display:inline-block; width: 138px; margin-top: 12px;">
            <weissman-datetime [(inputDate)]="startDateTime"
                                [dateOnly]="true"
                                [timeZone]="'Central'"
                                (change)="getHistory()">
            </weissman-datetime>
        </div>
        <span style="margin: 0 6px; position: relative; top: -8px;">to</span>
        <div class="input-group" style="display:inline-block; width: 138px; margin-top: 12px;">
            <weissman-datetime [(inputDate)]="endDateTime"
                                [dateOnly]="true"
                                [timeZone]="'Central'"
                                [rangeEnd]="true"
                                (change)="getHistory()">
            </weissman-datetime>
        </div>
    </div>
    <div class="text-center" style="margin-top: 50px;" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-5x"></i>
    </div>
    <div *ngIf="!loading">
        <div style="max-height:600px;overflow-y:auto;">
            <table class="table table-condensed table-striped">
                <tr style="background-color: var(--ace-info); color: white; white-space: nowrap;">
                    <th>Date/Time *</th>
                    <th>User</th>
                    <th>Field Name</th>
                    <th class="text-end">Old Value</th>
                    <th></th>
                    <th>New Value</th>
                </tr>
                <tbody>
                    <ng-template let-operation ngFor [ngForOf]="operations">
                        <tr *ngIf="operation.operation == OperationType.Insert">
                            <td [attr.title]="isCalendarDisplay(operation.operationDate) ? displayDate(operation.operationDate) : undefined">
                                {{calendarDisplayDate(operation.operationDate)}}
                            </td>
                            <td>
                                <span *ngIf="operation.userId !== NullUserId"> {{operation.userFirstName}} {{operation.userLastName}}</span>
                                <span *ngIf="operation.userId === NullUserId"><em>System Update</em></span>
                            </td>
                            <td colspan="4">{{originalTitle}} Created</td>
                        </tr>
                        <tr *ngIf="operation.operation == OperationType.Snapshot">
                            <td colspan="6" class="text-center">(history not available before {{displayDate(operation.operationDate)}})</td>
                        </tr>
                        <tr *ngFor="let change of operation.changes; let cIdx = index;">
                            <td *ngIf="cIdx == 0" [attr.rowspan]="operation.changes.length"
                                [attr.title]="isCalendarDisplay(operation.operationDate) ? displayDate(operation.operationDate) : undefined" style="white-space: nowrap;">
                                {{calendarDisplayDate(operation.operationDate)}}
                            </td>
                            <td *ngIf="cIdx == 0" [attr.rowspan]="operation.changes.length" style="white-space: nowrap;">
                                <span *ngIf="operation.userId !== NullUserId"> {{operation.userFirstName}} {{operation.userLastName}}</span>
                                <span *ngIf="operation.userId === NullUserId"><em>System Update</em></span>
                            </td>
                            <td [hidden]="cIdx > 2 && !operation.expanded" style="white-space: nowrap;">
                                <span
                                    *ngIf="change.historyFieldType == HistoryFieldType.ClientServiceResponsibility && change.properties.fromDate"
                                    [title]="'from ' + change.properties.fromDate.toLocaleDateString()">
                                    {{change.fieldName}}
                                </span>
                                <span
                                    *ngIf="change.historyFieldType != HistoryFieldType.ClientServiceResponsibility || !change.properties.fromDate">
                                    {{change.fieldName}}
                                </span>
                            </td>
                            <td class="text-end" [hidden]="cIdx > 2 && !operation.expanded" style="word-break: break-word;">
                                <em *ngIf="(change.oldValue === null || change.oldValue === '') && change.newValue !== null">blank</em>
                                <span *ngIf="change.oldValue !== null && change.oldValue !== ''">
                                    <weissman-datetime *ngIf="change.historyFieldType == HistoryFieldType.DateTime" [(inputDate)]="change.oldValue" [readOnly]="true"></weissman-datetime>
                                    <weissman-datetime *ngIf="change.historyFieldType == HistoryFieldType.Date" [(inputDate)]="change.oldValue" [readOnly]="true" [dateOnly]="true"></weissman-datetime>
                                    <span *ngIf="change.historyFieldType != HistoryFieldType.DateTime && change.historyFieldType != HistoryFieldType.Date && change.historyFieldType != HistoryFieldType.TaxAuthority">
                                        {{getDisplayValue(change.oldValue, change.historyFieldType)}}
                                    </span>
                                    <span *ngIf="change.historyFieldType == HistoryFieldType.ClientServiceResponsibility && change.oldValueProperties.relatedEntityType"
                                          [title]="change.oldValueProperties.relatedEntityName">
                                        (from {{resolveCSRRelationship(change.oldValueProperties)}})
                                    </span>
                                    <span *ngIf="change.historyFieldType == HistoryFieldType.TaxAuthority">
                                        <span *ngIf="!change.expanded">
                                            <span>{{getDisplayValue(change.oldValue, change.historyFieldType)}}</span>
                                            <span class="anchor-style" (click)="change.expanded=true">(details...)</span>
                                        </span>
                                        <span *ngIf="change.expanded">
                                            <div style="white-space:pre">{{getExpandedDisplayValue(change.oldValue, change.historyFieldType)}}</div>
                                            <span class="anchor-style" (click)="change.expanded=false">(hide...)</span>
                                        </span>
                                    </span>
                                </span>
                            </td>
                            <td [hidden]="cIdx > 2 && !operation.expanded" style="text-align: center; padding: 0 10px; vertical-align: middle;"><i *ngIf="change.oldValue !== null || change.newValue !== null" class="fa fa-arrow-right"></i></td>
                            <td [hidden]="cIdx > 2 && !operation.expanded" style="word-break: break-word;">
                                <em *ngIf="(change.newValue === null || change.newValue === '') && change.oldValue !== null">blank</em>
                                <span *ngIf="change.newValue !== null && change.newValue !== ''">
                                    <weissman-datetime *ngIf="change.historyFieldType == HistoryFieldType.DateTime" [(inputDate)]="change.newValue" [readOnly]="true"></weissman-datetime>
                                    <weissman-datetime *ngIf="change.historyFieldType == HistoryFieldType.Date" [(inputDate)]="change.newValue" [readOnly]="true" [dateOnly]="true"></weissman-datetime>
                                    <span *ngIf="change.historyFieldType != HistoryFieldType.DateTime && change.historyFieldType != HistoryFieldType.Date && change.historyFieldType != HistoryFieldType.TaxAuthority">
                                        {{getDisplayValue(change.newValue, change.historyFieldType)}}
                                    </span>
                                    <span *ngIf="change.historyFieldType == HistoryFieldType.ClientServiceResponsibility && change.newValueProperties.relatedEntityType"
                                          [title]="change.newValueProperties.relatedEntityName">
                                        (from {{resolveCSRRelationship(change.newValueProperties)}})
                                    </span>
                                    <span *ngIf="change.historyFieldType == HistoryFieldType.TaxAuthority">
                                        <span *ngIf="!change.expanded">
                                            <span>{{getDisplayValue(change.newValue, change.historyFieldType)}}</span>
                                            <span class="anchor-style" (click)="change.expanded=true">(details...)</span>
                                        </span>
                                        <span *ngIf="change.expanded">
                                            <div style="white-space:pre">{{getExpandedDisplayValue(change.newValue, change.historyFieldType)}}</div>
                                            <span class="anchor-style" (click)="change.expanded=false">(hide...)</span>
                                        </span>
                                    </span>
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="operation.changes.length > 3 && !operation.expanded">
                            <td class="change-history-more-row" colspan="6" (click)="operation.expanded = true">
                                <i class="fa fa-ellipsis-h fa-lg"></i>
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </div>
        <hr style="margin-top:2px;margin-bottom:2px;" />
        <div class="text-muted" style="margin-left: 20px;">* All times CST</div>
    </div>
</div>
<div class="modal-footer">
    <div>
        <button class="ace-btn btn-secondary" (click)="closeModal()">Close</button>
    </div>
</div>
