<div class="d-flex">
    <div *ngIf="selectedFormType && !selectedFormIsSupplementalAppeal"
         class="d-flex align-items-center"
         title="When enabled, this form will be included by default in returns.">
        <label class="m-0 px-2" for="isPrimary">Primary:</label>
        <ws-switch id="isPrimary"
                   [onColor]="'var(--ace-success)'"
                   [onTextColor]="'#fff'"
                   [formControl]="isPrimary"
                   (selectedOptionChanged)="onIsPrimaryChange($event)">
        </ws-switch>
    </div>
    <div class="d-flex align-items-center" title="When enabled, this form will only be visible to users with permissions to view forms that are in development.">
        <label class="m-0 px-2" for="isInDevelopment">In Development:</label>
        <ws-switch id="isInDevelopment"
                   [onColor]="'var(--ace-success)'"
                   [onTextColor]="'#fff'"
                   [formControl]="isInDevelopment"
                   (selectedOptionChanged)="onIsInDevelopmentChange($event)">
        </ws-switch>
    </div>
</div>
