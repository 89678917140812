import { lastValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusyIndicatorConfig, IBusyIndicatorConfig } from './busyIndicatorConfig';
import { MessageModalService } from '../../UI-Lib/Message-Box/messageModal.service';
import { LongRunningProcessRepository } from '../../Compliance/Repositories';

export enum ProgressActions {
    Download,
    DownloadWithCancel,
    Cancel,
    Navigate
}

export interface ProgressIndicatorConfig extends IBusyIndicatorConfig {
    identifier: string;
    title?: string;
    message?: string;
    longRunningProcessId?: number;
    progressActions?: ProgressActions[];
    isComplete?: boolean;
    progressFailure?: boolean;
    isHidden?: boolean;
    process?: Compliance.LongRunningProcessModel,
    action?: (...args: any) => void;
    download?: (snack: ProgressIndicator) => Promise<void>;
    dismiss?: (...args: any) => void;
    cancel?: (snack: ProgressIndicator) => void;
    cancelled?: (...args: any) => void;
    navigate?: (snack: ProgressIndicator) => void;
    error?: (err: string) => void;
    complete?: (snack: ProgressIndicator) => void;
}

export class ProgressIndicator extends BusyIndicatorConfig implements ProgressIndicatorConfig {
    constructor(
        public snackConfig: ProgressIndicatorConfig,
        private _next: any,
        private readonly _messageModalService: MessageModalService,
        private readonly _longRunningProcessRepository: LongRunningProcessRepository) {
        super(snackConfig.identifier, snackConfig.title, snackConfig.message);

        // copy config options
        Object.assign(this, snackConfig);

        if (this.progressActions && this.progressActions.length) {
            this.downloadWithCancel = this.progressActions.includes(ProgressActions.DownloadWithCancel);
            this.canDownload = this.progressActions.includes(ProgressActions.Download) || this.downloadWithCancel;
            this.canCancel = this.progressActions.includes(ProgressActions.Cancel) || this.downloadWithCancel;
            this.canNavigate = this.progressActions.includes(ProgressActions.Navigate);
        }

        if (this.isComplete || this.progressFailure) {
            this.progress = 100;
        }
    }

    // from config
    identifier: string;
    title: string;
    message: string;
    longRunningProcessId: number;
    process: Compliance.LongRunningProcessModel;
    progressActions: ProgressActions[];
    action: (...args: any) => void;
    download: (snack: ProgressIndicator) => Promise<void>;
    dismiss: (...args: any) => void;
    cancel: (snack: ProgressIndicator) => void;
    cancelled: (...args: any) => void;
    navigate: (snack: ProgressIndicator) => void;
    error: (err: string) => void;
    complete: (...args: any) => void;

    // component states
    progress: number = 0;
    current: number = 0;
    total: number = 0;
    cancelling: boolean = false;
    progressFailure: boolean = false;
    canDownload: boolean = false;
    downloading: boolean = false;
    canCancel: boolean = false;
    canNavigate: boolean = false;
    downloadWithCancel: boolean = false;
    isComplete: boolean = false;
    isCancelled: boolean = false;

    // for service
    isOwner: boolean;
    isSnackBar: boolean = true;
    remove: () => void;

    private destroy$: Subject<void> = new Subject();
    private destroyRef$: Subject<void> = new Subject();

    setWebsocketListeners(
        statusChange: Observable<Compliance.LongRunningProcessStatusChangeModel>,
        progressChange: Observable<Compliance.LongRunningProcessProgressChangeModel>,
        acknowledged: Observable<{ longRunningProcessId: number, userId: string }>
    ): void {
        statusChange.pipe(takeUntil(this.destroy$))
            .subscribe(async status => {
                if (!(this.longRunningProcessId === status.longRunningProcessId && status.isCompleted)) {
                    return;
                }

                // Update these two process values if they are not set when the snack was originally created
                if (!this.process?.entityId && status?.entityId) {
                    this.process.entityId = status.entityId;
                }
                if (!this.process?.parameters) {
                    const longRunningProcessModel = await lastValueFrom(this._longRunningProcessRepository.get(status.longRunningProcessId));
                    this.process.parameters = longRunningProcessModel.parameters;
                }

                if (status.isCanceled) {
                    this.setCancelled();
                    return;
                }

                if (status.isError) {
                    this.setError(status.errorMessage);
                    return;
                }

                this.isComplete = true;

                if (this.current) {
                    this.progress = 100;
                }

                this._next();
                if (this.isOwner && this.canDownload) {
                    this.performDownload();
                } else if (this.canDownload) {
                    if (this.complete) {
                        this.complete(this);
                    }
                } else {
                    this.setComplete();
                }
            });

        progressChange.pipe(takeUntil(this.destroy$))
            .subscribe(progress => {
                if (this.longRunningProcessId !== progress.longRunningProcessId) {
                    return;
                }

                this.current = progress.current;
                this.total = progress.total;

                this.setProgressPercentage();
                this.updateMessage(progress.message);
            });

        acknowledged.pipe(takeUntil(this.destroy$))
            .subscribe(message => {
                if (this.longRunningProcessId !== message.longRunningProcessId || this.downloading) {
                    return;
                }

                this._remove(true);
            });
    }

    updateTitle(text: string): void {
        this.title = text;
        this._next();
    }

    updateMessage(text: string): void {
        this.message = text;
        this._next();
    }

    setProgressPercentage(): void {
        if (this.current && this.total) {
            this.progress = Math.round((this.current / (this.total)) * 100);
        }
        else {
            this.progress = 0;
        }

        this._next();
    }

    setComplete(): void {
        if (this.complete) {
            this.complete(this);
        }

        if (!this.isSnackBar) {
            this._remove(true);
        }
    }

    setCancelled(): void {
        this.isCancelled = true;
        if (this.cancelled) {
            this.cancelled();
        }

        this._remove();
    }

    async performCancel(): Promise<boolean> {
        if (this.cancelling || (this.canDownload && !this.downloadWithCancel) ? !this.isComplete : false) {
            return false;
        }

        try {
            await this._messageModalService.prompt('Are you sure you would like to cancel this process? Click the X in the upper right corner to close the snackbar message and continue process.', 'Confirm');
        } catch {
            return Promise.resolve(false);
        }

        if (this.cancel && !this.isComplete) {
            this.cancelling = true;
            this.updateTitle('Attempting to cancel');
            this.updateMessage(null);
            this.cancel(this);
        }

        if (this.isComplete) {
            this._remove(true);
        }

        return false;
    }

    performDismiss(): boolean {
        if (this.isComplete) {
            this._remove(true);
        }
        return false;
    }

    async performDownload(): Promise<boolean> {
        if (!this.isComplete) { return false; }

        if (this.cancelling) {
            this.setCancelled();
            return false;
        }

        if (this.download) {
            this.downloading = true;
            this.updateTitle('Downloading');
            this.updateMessage(null);
            try {
                await this.download(this);
            } catch(err) {
                this.downloading = false;
                this.setError('Download failed');
            } finally {
                this.updateMessage(null);
            }
        }
        if (this.complete) {
            this.complete(this);
        }
        this._remove(true);
        this.downloading = false;
        return false;
    }

    performNavigate(): boolean {
        if (!this.isComplete) { return false; }
        if (this.canNavigate && this.navigate) {
            this.navigate(this);
        }
        return false;
    }

    setError(error: string): void {
        this.progressFailure = true;
        this.isComplete = true;
        this.progress = 100;

        if (this.error) {
            this.error(error);
        }
        this._remove();
    }

    private _remove(instant: boolean = false): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.destroyRef$.next();
        this.destroyRef$.complete();
        if (instant) {
            this.remove();
        } else {
            setTimeout(() => this.remove(), 3000);
        }
    }

}
