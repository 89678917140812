(function () {
	'use strict';

	angular
		.module('weissmanApp')
		.controller('FiltersOutputsController', FiltersOutputsController);

	FiltersOutputsController.$inject = [
		'$scope',
		'$rootScope',
		'$uibModal',
		'plainEnglishService',
		'$stateParams',
		'ActionViewDisplayTypes',
		'SD.Restrict.Service',
		'actionViewService',
		'advancedSearchService',
		'actionViewPersistenceService',
		'toastr',
		'ActionViewCategoryTypes',
		'ActionViewGroupByTypes',
		'utils',
		'$state',
		'messageBoxService',
		'$q',
		'$timeout',
		'productAnalyticsService',
		'userInstanceService',
        'userSaveService'
	];

	function FiltersOutputsController() { weissNg1WrapFunc(ng1ControllerInit, arguments, this); }
    function ng1ControllerInit($scope, $rootScope, $uibModal, plainEnglishService, $stateParams, ActionViewDisplayTypes, restrictService, actionViewService, advancedSearchService, actionViewPersistenceService, toastr, ActionViewCategoryTypes, ActionViewGroupByTypes, utils, $state, messageBoxService, $q, $timeout, productAnalyticsService, userInstanceService, userSaveService) {

		var vm = this;

		var defaultColumns = [];
		var allFields = [];
		var searchSaved = false;

		vm.excludeInactive = false;
		vm.showOrs = false;
		vm.dataLoaded = false;

		vm.filterColumns = [];
		vm.masterFilter = {};
		vm.drilldownFilter = {};
		vm.actionView = {};
		vm.originalFilter = {};
		vm.invalidColumns = [];

		vm.getPlainEnglish = plainEnglishService.getForFilter;
		vm.saveSearchAs = saveSearchAs;
		vm.saveSearch = saveSearch;
		vm.areNoOrs = areNoOrs;
		vm.goToOverview = goToOverview;
		vm.goToDetail = goToDetail;
		vm.backToOverviewClick = backToOverviewClick;
		vm.hideSaveButton = hideSaveButton;
		vm.reloadActionView = reloadActionView;
		vm.favoriteSearchToggle = favoriteSearchToggle;
		vm.showFilter = showFilter;
		vm.thatAreCompleted = thatAreCompleted;
		vm.cancelAvSearch = cancelAvSearch;
		vm.hideRevokeActionViewButton = hideRevokeActionViewButton;
		vm.revokeActionViewShare = revokeActionViewShare;
		vm.hasAnyFiltersPopulated = hasAnyFiltersPopulated;
		vm.customChosen = customChosen;
		vm.defaultChosen = defaultChosen;
        vm.updateColumnFilters = updateColumnFilters;

		vm.detailedResults;
		vm.showOverview = false;
		vm.showDetail = false;
		vm.showFilterPage = false;
		vm.overviewLoading = false;

		vm.organizeBySelection = "1";
		//vm.groupBySelection = 0;

		vm.hasEditPermission = restrictService.isInRole(restrictService.Roles.SYSTEMSEARCHESEDIT);

		advancedSearchService.getAllFields()
			.then(function (result) {
				//console.log('we have advanced search fields', result);
				vm.allSearchFieldsLoaded = true;
				allFields = result;
			});

		activate();

		$scope.$on('deleteActionView', function (event, actionView) {
			var actionViewId = parseInt($stateParams.actionViewId);
			console.log(actionView);

			if (actionViewId === actionView.actionViewID) {
				$state.go('home');
			}
		})

		vm.launchCustomOutput = function () {
			var modalInstance = $uibModal.open({
				templateUrl: 'app/Task/ActionView/Filters/_customOutputModal.html',
				controller: 'CustomOutputModalController',
				controllerAs: 'vm',
				windowClass: 'show',
				backdropClass: 'show',
				resolve: {
					fromActionView: function () {
						return true
					},

					selectedTaskType: function () {
						return _.find(vm.taskTypes, { taskTypeID: vm.masterFilter.taskTypeID });
					},

					taskTypeName: function () {
						return vm.masterFilter.taskTypeName
					},

					systemColumns: function () {
						return _.union(vm.masterFilter.TaskOutputColumns, vm.masterFilter.EntityOutputColumns);
					},

					userColumns: function () {
						return _.union(vm.masterFilter.TaskOutputColumns, vm.masterFilter.EntityOutputColumns);
					}
				}
			});

			modalInstance.result
				.then(function (changedFilters) {
					var outputColumns = _.groupBy(angular.copy(changedFilters), function (filter) {
						var filterColumnId = filter.columnId || filter.advancedSearchFieldID || filter.id;
						return filterColumnId > 9999 && filterColumnId < 11000 ? 'TaskOutputColumns' : 'EntityOutputColumns';
					});

					outputColumns.TaskOutputColumns = outputColumns.TaskOutputColumns || [];
					outputColumns.EntityOutputColumns = outputColumns.EntityOutputColumns || [];

					var newFilterColumns = _.filter(angular.copy(outputColumns.EntityOutputColumns), function (column) {
						var columnId = column.columnId || column.advancedSearchFieldID || column.id;
						// We need to remove the document image preview column because it can't be filtered on
						return columnId !== 9002 && columnId !== 1430;
					});

					vm.filterColumns = _.map(newFilterColumns, function (column) {
						var columnId = column.columnId || column.advancedSearchFieldID || column.id;
						var existingColumn = _.find(vm.filterColumns, function (filter) {
							return (filter.columnId || filter.advancedSearchFieldID || filter.id) === columnId
						});

						return existingColumn || column
					});

					outputColumns.CustomOutput = true;

					_.assign(vm.masterFilter, outputColumns);

					var outputColumns = _.chain(outputColumns.TaskOutputColumns)
						.union(outputColumns.EntityOutputColumns)
						.cloneDeep()
						.value();

					vm.invalidColumns = advancedSearchService.getInvalidColumns(outputColumns)
				})
		}

		function areNoOrs() {
			return _.chain(vm.filterColumns)
				.map('or')
				.every(function (orArr) {
					if (orArr) {
						return orArr.length < 2;
					}
				})
				.value();
		}

		function saveSearchAs() {
			agreeToRunLongRunningQuery().then(function (result) {
				if (result) {
					setOrganizeAndGroupOptions();
					vm.filterToExecute = actionViewService.prepareActionViewForExecution(vm.filterColumns, vm.masterFilter);
					var modalInstance = $uibModal.open({
						templateUrl: 'app/Task/ActionView/_saveActionViewModal.html',
						controller: 'SaveActionViewModalController',
						controllerAs: 'vm',
						windowClass: 'show',
						backdropClass: 'show',
                        size: 'lg',
						resolve: {
							actionViewObj: function () {
								var actionView = {
									masterFilter: angular.copy(vm.filterToExecute)
								};

								actionView.masterFilter.showOrs = vm.showOrs;
								actionView.actionViewName = vm.actionView.actionViewName;

								return actionView;
							},
							hasEditPermission: function () {
								return vm.hasEditPermission;
							},
							filterColumns: function () {
								return angular.copy(vm.filterColumns);
							}
						}
					});

					modalInstance.result
					.then(function() {
                        userSaveService.updateList(1);
						searchSaved = true;
					})
				}
			});
		}

		function saveSearch() {
			agreeToRunLongRunningQuery().then(function (result) {
				if (result) {
					//var search = angular.copy(vm.search);
					setOrganizeAndGroupOptions();
					vm.filterToExecute = actionViewService.prepareActionViewForExecution(vm.filterColumns, vm.masterFilter);
					vm.actionView.masterFilter = angular.copy(vm.filterToExecute);
					vm.actionView.masterFilter.showOrs = vm.showOrs;
					if (vm.isSystemView) {
						if (confirm('Confirm Overwriting System Action View')) {
							actionViewService.updateSystemActionView(vm.actionView, angular.copy(vm.filterColumns))
								.then(function () {
									toastr.success('System Action View Saved!');
                                    userSaveService.updateList(1);
									searchSaved = true;
								});
						}
					} else {
						actionViewService.updateCustomActionView(vm.actionView, angular.copy(vm.filterColumns))
							.then(function (result) {
								toastr.success('User Action View Saved!')
								vm.actionView.rowVersion = result.rowVersion;
                                userSaveService.updateList(1);
								searchSaved = true;
							})
					}
				}
			});
		}

		function revokeActionViewShare() {
			messageBoxService.confirm('Sharing with other users will be revoked. Click OK to confirm.', 'Action View')
				.then(function () {
					actionViewService.revokeShare(vm.actionView)
						.then(function (result) {
							vm.actionView.isShared = result.actionViewSavedSearch.isShared;
							toastr.success('Share was revoked!')
						});
				});
		}


		function showFilter() {
            productAnalyticsService.logSequenceEvent('refine-action-view-filter-criteria', 'click-change');
			actionViewPersistenceService.detailResults = null;
			vm.detailedResults = null
			vm.showOverview = false;
			vm.showDetail = false;
			vm.showFilterPage = true;

            if(!vm.filterColumns || vm.filterColumns.length == 0) {
                vm.taskAssignmentApi.checkActionViewDefaultOutput().then(function (checkDefaultResult) {
                    vm.filterColumns = checkDefaultResult.filterColumns;
                });
            }

			cancelAvSearch();
		}

		function hasAnyFiltersPopulated() {
			var hasAnyFilters = false;

			_.forEach(vm.filterColumns, function (filter, index) {
				_.forEach(filter.or, function (or, index) {
					hasAnyFilters = or.value !== "" && or.value !== undefined;

					if (hasAnyFilters) {
						return false;
					}
				});

				if (hasAnyFilters) {
					return false;
				}
			});

			return hasAnyFilters;
		}


		function agreeToRunLongRunningQuery() {
			var showOptions = actionViewService.getTasksAssignmentReadyOptions().showOptions;

			var isTaskTypeAll = vm.masterFilter.taskTypeID === 0;
			var isShowAll = vm.masterFilter.ShowChoice === showOptions['allTasks'].id;
			var isDateRangeMoreThanWeek = !vm.masterFilter.OnlyUnscheduled &&
				((vm.masterFilter.DateTimeChoice === 1) ||
					(vm.masterFilter.DateTimeChoice === 2 && vm.masterFilter.DueWithinDays > 7) ||
					(vm.masterFilter.DateTimeChoice === 3 && moment(vm.masterFilter.DueDateTo).diff(moment(vm.masterFilter.DueDateFrom), 'days') > 7));

			var isLongRunningQuery = isTaskTypeAll && isShowAll && isDateRangeMoreThanWeek && !vm.hasAnyFiltersPopulated();

			if (isLongRunningQuery) {
				isLongRunningQuery = isLongRunningQuery &&
					(restrictService.isInRole(restrictService.Roles.RYANPRIVATEITEMSEDIT) ||
						restrictService.isInRole(restrictService.Roles.RYANPRIVATEITEMSVIEW));
			}

			var deferred = $q.defer();

			deferred.resolve(true);

			// WK-4799 asked us to remove this message. I left the code and structure in place in case we need to quickly revert
            // and don't want to dig through source control or cherry pick a reverted change. It's possible we want to show it
            // but less often, and after some discussion no one was completely confident about it.

		    // IMPORTANT: messageBoxService.confirm has changed since this was written; if we uncomment this, we should get rid
		    // of the .catch line; probably we need to convert to messageBoxService.open since the service no longer rejects on
            // cancel.

			/*if (isLongRunningQuery) {
				messageBoxService.confirm('The Action View maybe slow to execute and/or return too much data. Do you want to proceed?', 'Task View', MessageBoxResult.Cancel).then(function () {
					deferred.resolve(true);
				}).catch(function () {
					deferred.resolve(false);
				})
			}
			else {
				deferred.resolve(true);
			}*/

			return deferred.promise;
		}

		function goToOverview() {
			if (invalidFilters()) {
				return;
			}

			agreeToRunLongRunningQuery().then(function (result) {
				if (result) {
                    const event = { event: 'refine-action-view-filter-criteria' };
                    productAnalyticsService.initiateSequenceEvent(event, ['click-overview', 'click-change']);
                    productAnalyticsService.logEvent('click-action-view-overview');

					actionViewPersistenceService.clearSavedData();

					vm.masterFilter.DetailedResults = false;

					if ($stateParams.actionViewId !== undefined && Number($stateParams.displayType) && searchSaved) {
						searchSaved = false;
						vm.filterToExecute = angular.copy(vm.masterFilter);

						vm.filterToExecute.filters = _.map(vm.filterToExecute.filters, function (filter) {
							filter.resultColumnName = _.find(allFields, { 'advancedSearchFieldID': filter.columnId }).resultsColumnName;

							return filter;
						})

					} else {
						vm.filterToExecute = actionViewService.prepareActionViewForExecution(vm.filterColumns, vm.masterFilter);
					}

					//This is super hacky, but somewhere along the way we misused these various filters
					//For now this is the best way to keep track of the filter as it was from the
					//Action view criteria page. It is needed to reset the drilldown filter before
					//going to overview
					vm.originalFilter = angular.copy(vm.filterToExecute);
					vm.drilldownFilter = angular.copy(vm.originalFilter);

					vm.showOverview = true;
					vm.showFilterPage = false;
				}
			});
		}

		function cancelAvSearch() {
			actionViewService.cancelAvSearch();
			//toastr.info('', 'Action View request has been cancelled');
		}


		function backToOverviewClick(shouldCancelSearch) {
			if(shouldCancelSearch) {
				cancelAvSearch();
			}

            vm.showDetail = false;
            vm.showFilterPage = false;
            vm.showOverview = true;
            vm.overviewLoading = true;
            vm.detailedResults = null;
			actionViewPersistenceService.detailResults = {};
			actionViewPersistenceService.drilldownMode = false;
			vm.drilldownFilter = angular.copy(vm.originalFilter);
			vm.drilldownMode = false;
			if(actionViewPersistenceService.masterFilter && actionViewPersistenceService.externalDrilldown) {
			    vm.masterFilter = actionViewPersistenceService.masterFilter
            }
            actionViewPersistenceService.externalDrilldown = false;
            actionViewPersistenceService.externalDrilldownData = null;

		}

		function hideSaveButton() {
			var hideButton = (vm.isSystemView && !vm.hasEditPermission) || !vm.actionView.actionViewId || (!vm.isSystemView && !vm.actionView.isOwnedByUser);
			return hideButton;
		}

		function hideRevokeActionViewButton() {
			return !vm.actionView.isOwnedByUser || !vm.actionView.isShared;
		}

		function reloadActionView() {
			actionViewPersistenceService.clearSavedData();
			$state.go('actionview', $stateParams, { reload: true, inherit: false});
		}

		function goToDetail() {
			if (invalidFilters()) {
				return;
			}

			agreeToRunLongRunningQuery().then(function (result) {
				if (result) {
                    const event = { event: 'refine-action-view-filter-criteria' };
                    productAnalyticsService.initiateSequenceEvent(event, ['click-details-list', 'click-change']);
                    productAnalyticsService.logEvent('click-action-view-detailed-list');

					setOrganizeAndGroupOptions();

					vm.masterFilter.DetailedResults = true;

					actionViewPersistenceService.clearSavedData();

					//set up persistence service for return nub
					actionViewPersistenceService.filterColumns = angular.copy(vm.filterColumns);
					actionViewPersistenceService.filter = angular.copy(vm.masterFilter);
					actionViewPersistenceService.showOrs = vm.showOrs;
					actionViewPersistenceService.outputColumns = _.chain(angular.copy(vm.masterFilter))
						.map('columnId')
						.groupBy(function (id) {
							return id > 9999 && id < 11000 ? 'TaskOutputColumns' : 'EntityOutputColumns';
						})
						.value();

					vm.filterToExecute = actionViewService.prepareActionViewForExecution(vm.filterColumns, vm.masterFilter);
					vm.showDetail = true;
					vm.showFilterPage = false;
				}
			});
		}

		function favoriteSearchToggle(isFavorite) {
			actionViewService.toggleFavorite(vm.actionView.actionViewId, isFavorite)
				.then(function (results) {
					vm.actionView.isFavorite = isFavorite;
					$rootScope.$broadcast('updateActionViewMenu');
				});
		}

		function invalidFilters() {
			var showOptions = actionViewService.getTasksAssignmentReadyOptions().showOptions;

			// create an id mapping to fix a horrible id mismatch bug, this is a band-aid
			_.map(vm.masterFilter.TaskOutputColumns, function (item) {
				if (!item.id) {
					if (item.advancedSearchFieldID) {
						item.id = item.advancedSearchFieldID;
					}
				}
			});

			_.map(vm.masterFilter.EntityOutputColumns, function (item) {
				if (!item.id) {
					if (item.advancedSearchFieldID) {
						item.id = item.advancedSearchFieldID;
					}
				}
			});


			if (vm.masterFilter.ShowChoice === showOptions.individualsTasks.id && (!vm.masterFilter.ShowUserTeams[0] || !vm.masterFilter.ShowUserTeams[0].userID || !vm.masterFilter.ShowUserTeams[0].teamID)) {
				toastr.error('User required for Show', 'Error');
				return true;
			}

			if (vm.masterFilter.ShowChoice === showOptions.teamsTasks.id && (!vm.masterFilter.ShowTeams[0] || !vm.masterFilter.ShowTeams[0].teamID)) {
				toastr.error('Team required for Show', 'Error');
				return true;
			}

			if (vm.masterFilter.IncludeChoice === 4 && (!vm.masterFilter.IncludeUserTeams[0] || !vm.masterFilter.IncludeUserTeams[0].userID)) {
				toastr.error('User required for Include', 'Error');
				return true;
			}

			if (vm.masterFilter.IncludeChoice === 5 && (!vm.masterFilter.IncludeTeams[0] || !vm.masterFilter.IncludeTeams[0].teamID)) {
				toastr.error('Team required for Include', 'Error');
				return true;
			}

			if (vm.masterFilter.DateTimeChoice === 2 && !vm.masterFilter.DueWithinDays) {
				if (vm.masterFilter.DueWithinDays === undefined) {
					toastr.error('"Due Within Days" should be less than 365', 'Error');
				} else {
					toastr.error('"Due Within Days" is required', 'Error');
				}

				return true;
			}

			if (vm.masterFilter.UseLastActivityFilter) {
				if (vm.masterFilter.LastActivityFrom === undefined || vm.masterFilter.LastActivityTo === undefined) {
					toastr.error('"Last Activity" fields should be less than 365', 'Error');

					return true;
				}

				if (vm.masterFilter.LastActivityFrom === null && vm.masterFilter.LastActivityTo === null) {
					toastr.error('"Last Activity" fields should contain at least one value', 'Error');

					return true;
				}
			}

			if(vm.masterFilter.CompletedChoice == 1 && !vm.masterFilter.CompletedWithinDays) {
				if (vm.masterFilter.CompletedWithinDays === undefined) {
					toastr.error('"Completed Within Days" should be less than 365', 'Error');
				} else {
					toastr.error('"Completed Within Days" is required', 'Error');
				}

				return true;
			}

			return false;
		}

		function setOrganizeAndGroupOptions() {
			vm.masterFilter.OrganizeBy = parseInt(vm.organizeBySelection);
			vm.masterFilter.GroupBy = [];

			//TODO set up "pressing tasks" flag once there's an API for it
			if (!vm.masterFilter.ShowMostPressingTasks) {
				vm.masterFilter.GroupBy.push(ActionViewGroupByTypes.TASKTYPE.id);
			}
			if (vm.groupBySelection) {
				vm.masterFilter.GroupBy.push(vm.groupBySelection);
			}
		}

        function defaultChosen() {
            vm.taskAssignmentApi.populateColumns();
            vm.masterFilter.persistCustomColumns = false;
            productAnalyticsService.logEvent('click-action-view-default-output');
        }

        function updateColumnFilters(filterColumns) {
            vm.filterColumns = filterColumns;
        }

		function customChosen() {
			if (vm.masterFilter.persistCustomColumns === undefined) {
				vm.masterFilter.persistCustomColumns = true;
			}
            productAnalyticsService.logEvent('click-action-view-custom-output');
		}

		function thatAreCompleted() {
			return vm.masterFilter.ThatAreChoice === 4;
		}

		function activate() {
			// Promise is necessary for loading task assignment ready panel
			// after all appropriate data has loaded
			initializePageState(false)
				.then(function(isById) {
					vm.dataLoaded = true;

					if(isById) {
						waitForApi().then(function (){
							vm.taskAssignmentApi.checkActionViewDefaultOutput().then(function (checkDefaultResult) {
								vm.filterColumns = checkDefaultResult.filterColumns;
							});
						})
					}
				})

			vm.isSingleInstanceSelected = userInstanceService.isSingleInstanceSelected();
		}

		function setDisplayType(displayType) {
			switch (displayType) {
				case ActionViewDisplayTypes.FILTER:
					vm.showOverview = false;
					vm.showDetail = false;
					vm.showFilterPage = true;
					break;
				case ActionViewDisplayTypes.OVERVIEW:
					vm.filterToExecute = angular.copy(vm.masterFilter);
					vm.drilldownFilter = _.isEmpty(vm.originalFilter) ? angular.copy(vm.filterToExecute) : angular.copy(vm.originalFilter);
					vm.showOverview = true;
					vm.showDetail = false;
					vm.showFilterPage = false;
					break;
				case ActionViewDisplayTypes.DETAILED:
					vm.showOverview = false;
					vm.showDetail = true;
					vm.showFilterPage = false;
					break;
				default:
					vm.showOverview = false;
					vm.showDetail = false;
					vm.showFilterPage = true;
					break;
			}
		}

        // HACK: I was occasionally getting errors where vm.taskAssignmentApi was undefined where it was
        // needed; a proper fix would be to restructure the AV ui, but as a workaround for now use timeout
        // to wait until vm.taskAssignemntApi is available
		function waitForApi() {
		    var waitTime = 1;
		    var numTries = 0;
		    return $q(function (resolve) {
		        var callback = function () {
		            if (vm.taskAssignmentApi) {
		                console.log('vm.taskAssignmentApi available');
		                resolve();
		            }
		            else {
		                if (numTries > 3) {
		                    throw new Error('vm.taskAssignmentApi unavailable');
		                }
		                console.log('vm.taskAssignmentApi unavailable; trying again');
		                numTries++;
		                waitTime = 500;
		                $timeout(callback, 500);
		            }
		        };
                // Initially allow digest to finish and immediately allow execution to continue; if the value
                // isn't set yet, wait a half-second at a time until it is
		        $timeout(callback, 1);
		    });
		}

		function initializePageState() {
			var deferred = $q.defer();
			var displayType = parseInt($stateParams.displayType);
			var actionViewId = parseInt($stateParams.actionViewId);

			vm.isSystemView = parseInt($stateParams.actionViewType) === ActionViewCategoryTypes.SYSTEM;

			//special case - if we are coming to a drilldown from an external page, like dashboard
			if(actionViewPersistenceService.externalDrilldown) {
				vm.source = "externalDrilldown";
			    //This seemingly random variable is set to true so we can load the task assignment ready directive
                //This is important because it's where a lot of our business logic lives, and without this variable
                //it will not load and break the flow.
			    vm.dataLoaded = true;

			    waitForApi().then(function() {
                    vm.masterFilter = angular.copy(actionViewPersistenceService.masterFilter);
                    vm.originalFilter = angular.copy(actionViewPersistenceService.masterFilter);
                    vm.taskAssignmentApi.checkActionViewDefaultOutput().then(function (checkDefaultResult) {
                        vm.filterColumns = checkDefaultResult.filterColumns;
                        vm.taskAssignmentApi.refreshData();
                        setDisplayType(ActionViewDisplayTypes.OVERVIEW);
					});
                });


            }
			//if we are returning with a filter saved in a nub
			else if (!_.isEmpty(actionViewPersistenceService.masterFilter)) {
				vm.source = "nub";
				vm.isSavedSearch = (actionViewPersistenceService.routeParams.actionViewId !== '');

				//setup vars from service
				vm.masterFilter = angular.copy(actionViewPersistenceService.masterFilter);
				vm.filterToExecute = angular.copy(vm.masterFilter);

				vm.drilldownFilter = angular.copy(actionViewPersistenceService.drilldownFilter);

				if(vm.masterFilter.DetailedResults || (vm.drilldownFilter && vm.drilldownFilter.DetailedResults)) {
                    displayType = ActionViewDisplayTypes.DETAILED;
                    vm.detailedResults = actionViewPersistenceService.detailResults;
                } else {
				    displayType = ActionViewDisplayTypes.OVERVIEW;
                }

				vm.drilldownMode = actionViewPersistenceService.drilldownMode;
				vm.filterColumns = angular.copy(actionViewPersistenceService.filterColumns);
				//vm.outputColumnsIds = actionViewPersistenceService.outputColumns;
				vm.organizeBySelection = vm.masterFilter.OrganizeBy;
				vm.showOrs = actionViewPersistenceService.showOrs;
				vm.actionView.actionViewName = actionViewPersistenceService.actionViewName;

				//two groupings
				if (!vm.masterFilter.ShowMostPressingTasks && (vm.masterFilter.GroupBy && vm.masterFilter.GroupBy.length > 1)) {
					vm.groupBySelection = vm.masterFilter.GroupBy[1].toString();
				}
				//one grouping that isn't most pressing
				else if (vm.masterFilter.GroupBy && vm.masterFilter.GroupBy.length > 0 && vm.masterFilter.ShowMostPressingTasks) {
					vm.groupBySelection = angular.copy(vm.masterFilter.GroupBy[0].toString());
				}

				if (vm.detailedResults) {
					setDisplayType(displayType);
				} else if(!vm.masterFilter.DetailedResults) {
					goToOverview();
				} else {
					goToDetail();
				}

				deferred.resolve();

			} else if (actionViewId) {
				actionViewPersistenceService.clearSavedData();

				vm.source = "saved";

				//if we are accessing this by id
				actionViewService.getCustomActionViewById(parseInt($stateParams.actionViewId))
					.then(function (result) {
						var newCriteria = JSON.parse(result.criteria);

						vm.actionView = result;

                        utils.convertDateStringsToDates(newCriteria);
						vm.masterFilter = _.cloneDeep(newCriteria.masterFilter);

						vm.filterColumns = _.chain(newCriteria.filters)
							.map(function(filter) {
								filter.or = _.filter(filter.or, 'operator');
								return filter;
							})
							.filter(function(filter) {
								return filter.or.length;
							})
							.value();

						//vm.outputColumnsIds = newCriteria.outputColumns;
						vm.organizeBySelection = vm.masterFilter.OrganizeBy;
						vm.showOrs = vm.masterFilter.showOrs || false;

						if (displayType === 2) {
							vm.masterFilter.DetailedResults = true;
						}

						if (vm.masterFilter.GroupBy) {
							//two groupings
							if (!vm.masterFilter.ShowMostPressingTasks && vm.masterFilter.GroupBy.length > 1) {
								vm.groupBySelection = vm.masterFilter.GroupBy[1];
							}
							//one grouping
							else if (vm.masterFilter.GroupBy && vm.masterFilter.GroupBy.length > 0 && vm.masterFilter.ShowMostPressingTasks) {
								vm.groupBySelection = angular.copy(vm.masterFilter.GroupBy[0]);
							}
						}

						setDisplayType(displayType);

						var outputColumns = _.chain(vm.masterFilter.TaskOutputColumns)
							.union(vm.masterFilter.EntityOutputColumns)
							.cloneDeep()
							.value();

						vm.invalidColumns = advancedSearchService.getInvalidColumns(outputColumns)

						deferred.resolve(true);
					});
			} else {
				vm.source = "new";

				//if it's a new filter, create an empty object
				actionViewPersistenceService.clearSavedData();
				vm.masterFilter = {
					ShowChoice: 2,
					WhereChoice: 1,
					ThatAreChoice: 1,
					ShowUserTeams: [{}],
					ShowTeams: [{}],
					IncludeUnscheduled: true,
					IncludeUserTeams: [{}],
					IncludeTeams: [{}],
					output: 'default',
					DateTimeChoice: 2,
					DueWithinDays: 90,
					CompletedChoice: 1,
					CompletedWithinDays: 30,
					IncludeChoice: 1,
					ComposeDynamicSql: true,
					LastActivityTo: null,
					LastActivityFrom: null,
					includeSkipped: true
				};

				vm.organizeBySelection = 1;
				vm.groupBySelection = undefined;
				vm.showFilterPage = true;

				deferred.resolve();
			}

			return deferred.promise;
		}
	}
})();
