import { Component, OnInit } from '@angular/core';
import {
    ColGroupDef,
    ColumnApi,
    ColumnEventType,
    ColumnResizedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    RowNode
} from 'ag-grid-community';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { AgGridOptionsBuilder } from '../Compliance/AgGrid';
import { CompanyService } from '../Entity/Company/company.service';
import { AccrualsPageService } from './accruals.page.service';
import * as _ from 'lodash';
import {
    AccrualsColumnFiltersUI,
    AccrualsGridAvailableFiltersModelUI,
    AccrualsGridBillRecurTypeEnum,
    AccrualsGridSummarizeByEnum,
    AccrualsGridViewModeEnum,
    ColumnFiltersOptionsEnum,
    JournalOptionsEnum
} from './accruals.page.model';
import {
    MessageBoxButtons,
    MessageBoxResult,
    MessageBoxService
} from '../UI-Lib/Message-Box/messagebox.service.upgrade';
import { AgGridExportOptions, AgGridExportStartLRP } from '../Compliance/AgGrid/ToolPanel/models';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, lastValueFrom, Subject, takeUntil } from 'rxjs';
import { InstanceRights, RestrictService } from '../Common/Permissions/restrict.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccrualsBulkUpdateModalComponent } from './accruals.bulk.update.modal.component';
import { AttachmentModalData } from '../Attachment/attachment.modal.model';
import { CommentModalData } from '../Comments/comments.service';
import { InstanceRepository } from '../Entity/Instance/instance.repository';
import LongRunningProcessTypeEnum = Compliance.LongRunningProcessTypeEnum;
import { SnackBarService } from '../Busy-Indicator';
import { WebsocketListenerService } from 'src/app/Compliance/websocketListener.service';

@Component({
    selector: 'accruals-page',
    templateUrl: './accruals.page.component.html',
    styleUrls: ['./accruals.page.component.scss']
})
export class AccrualsPageComponent implements OnInit {
    constructor(private _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private _companyService: CompanyService,
        private _accrualsPageService: AccrualsPageService,
        private _messageBoxService: MessageBoxService,
        private _toastrService: ToastrService,
        private _restrictService: RestrictService,
        private _modalService: BsModalService,
        private _instanceRepository: InstanceRepository,
        private _snackBarService: SnackBarService,
        private _websocketListenerService: WebsocketListenerService
        ) { }

    parentCompanyId: number;
    parentCompany: Core.NameIdPair;
    isMaximized: boolean = false;
    isEditMode: boolean = false;
    hasEditPermission: boolean = false;
    isSetupInvalid: boolean = false;
    showApplyButton: boolean = true;
    loading: boolean = false;
    closingPeriod: boolean = false;
    creatingExportFile: boolean = false;
    gettingGrid: boolean = false;
    activeLongRunningProcessId: number = 0;

    lastClosedAccountingPeriodId: number = null;
    firstOpenAccountingPeriodId: number = null;

    gridApi: GridApi;
    gridOptions: GridOptions;
    columnApi: ColumnApi;
    columnSizing: ColumnEventType = 'sizeColumnsToFit';

    accountingPeriods: Core.AccrualsGridAccountingPeriodModel[];
    availableFilters: AccrualsGridAvailableFiltersModelUI;
    columnFilters: AccrualsColumnFiltersUI = new AccrualsColumnFiltersUI();

    searchModel: Core.AccrualsGridSearchModel = {} as Core.AccrualsGridSearchModel;
    resultsModel: Core.AccrualsGridResultsModel;

    isBulkUpdateVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    bulkUpdateInProgress: boolean = false;
    bulkUpdateCurrent: { index: number, entry: Core.AccrualsGridEntryModel };
    bulkUpdateTotals: { rowsChanged: number, billsChanged: number };
    selectionsLength: number;
    bulkUpdateCanceled: boolean = false;
    reselectGridNodes: boolean = false;

    percentVarianceThresholdDisplay: number;

    exportOptions: AgGridExportOptions = {
        start: async (columnsToReturn: Compliance.NameValuePair<string>[]): Promise<AgGridExportStartLRP> => {
            const request = {
                columnFilters: [],
                sortColumns: [],
                columnsToReturn: columnsToReturn,
                ...this.searchModel,
                ...this.columnFilters
            } as any;

            const longRunningProcessId = await this._accrualsPageService.exportToExcel(this.searchModel.tlCompanyId, request);
            return { longRunningProcessId, longRunningProcessTypeId: LongRunningProcessTypeEnum.ExportAccrualsGridToExcel };
        },
        canCancel: true,
        disabled: false
    };
    selectedIds: number[];

    readonly AccrualsGridViewModeEnum = AccrualsGridViewModeEnum;
    readonly AccrualsGridSummarizeByEnum = AccrualsGridSummarizeByEnum;
    readonly AccrualsGridBillRecurTypeEnum = AccrualsGridBillRecurTypeEnum;
    readonly ColumnFiltersOptionsEnum = ColumnFiltersOptionsEnum;
    readonly JournalOptionsEnum = JournalOptionsEnum;

    private readonly LARGE_BULK_UPDATE_THRESHOLD: number = 100;

    private _startAccountingPeriod: Core.AccrualsGridAccountingPeriodModel;
    private _endAccountingPeriod: Core.AccrualsGridAccountingPeriodModel;
    private _startAccountingPeriodId: number;
    private _endAccountingPeriodId: number;
    private _destroy$: Subject<void> = new Subject();

    get operationInProgress(): boolean {
        return this.loading || this.closingPeriod || this.creatingExportFile || this.gettingGrid;
    }

    get attachmentsModel(): AttachmentModalData {
        if (!this.startAccountingPeriod) {
            return null;
        }

        return {
            entityType: 'ACCOUNTINGPERIOD',
            entityName: this.startAccountingPeriod.name,
            entityData: {
                typeId: Core.EntityTypes.AccountingPeriod,
                id: this.startAccountingPeriod.accountingPeriodId,
                name: this.startAccountingPeriod.name
            },
            readOnly: !this.hasEditPermission
        } as AttachmentModalData;
    }

    get commentsModel(): CommentModalData {
        if (!this.startAccountingPeriod) {
            return null;
        }

        return {
            entityID: this.startAccountingPeriod.accountingPeriodId,
            entityTypeID: Core.EntityTypes.AccountingPeriod,
            description: this.startAccountingPeriod.name,
            canEdit: this.hasEditPermission
        } as CommentModalData;
    }

    get isBulkUpdateDone() {
        return this.bulkUpdateCurrent && this.bulkUpdateCurrent.index >= this.selectionsLength;
    }

    get isSummarizeByBills(): boolean {
        return this.searchModel.filters.summarizeBy === Core.AccrualsGridSummarizeByEnum.Bills;
    }

    get isExportable() {
        return this.startAccountingPeriod && this.resultsModel && this.resultsModel.isExportable;
    }

    get isExportTransmittable() {
        return this.startAccountingPeriod && this.resultsModel && this.resultsModel.isExportTransmittable;
    }

    get startAccountingPeriod() {
        return this._startAccountingPeriod;
    }
    set startAccountingPeriod(period) {
        this._startAccountingPeriod = period;
        this._startAccountingPeriodId = period?.accountingPeriodId;
    }

    get endAccountingPeriod() {
        return this._endAccountingPeriod;
    }
    set endAccountingPeriod(period) {
        this._endAccountingPeriod = period;
        this._endAccountingPeriodId = period?.accountingPeriodId;
    }

    async ngOnInit() {
        this.parentCompanyId = +this._upgradeNavigationServiceHandler.getQuerystringParam('companyId');
        await this._initValues();
    }

    async loadLongRunningStatus() {
        this.loading = true;

        try {
            const lrpStatus = await this._accrualsPageService.getLongRunningProcessStatus(this.searchModel.tlCompanyId);

            this.closingPeriod = lrpStatus.periodCloseInProgress;
            this.creatingExportFile = lrpStatus.exportInProgress;
            this.gettingGrid = lrpStatus.gridGetInProgress;

            if ( this.closingPeriod ) {
                this._toastrService.info('A Close/Re-open Accounting Period operation is in progress for this company.');
            }
            else if ( this.creatingExportFile ) {
                this._toastrService.info('A Create Export File operation is in progress for this company.');
            }
            else if ( this.gettingGrid ) {
                this._toastrService.info('A Grid display/refresh operation is in progress for this company.');
            }
            if ( this.closingPeriod || this.creatingExportFile || this.gettingGrid ) {
                this.activeLongRunningProcessId = lrpStatus.activeLongRunningProcessId;
                this.exportOptions.disabled = true;
                this.refreshBulkUpdateState();
                this._showGridSpinner();
            }
        } finally {
            this.loading = false;
        }
    }

    async loadFilters() {
        this.loading = true;

        try {
            await this._getAvailableFilters();

            this.parentCompany = _.find(this.availableFilters.companies, { id: this.parentCompanyId });

            // This was put here so that parentCompany could be set for the breadcrumb
            // when the invalid setup page is displayed.
            const validateSetupModel = await this._accrualsPageService.checkValidateSetup(this.searchModel.tlCompanyId);
            this.isSetupInvalid = validateSetupModel.noAccountingPeriods || validateSetupModel.incompleteGLAccounts;

            if ( this.isSetupInvalid )
            {
                this.loading = false;
                this.gridApi.showNoRowsOverlay();
                return;
            }

            this._setEntityFilters();

            await this._getAccountingPeriods();
            if (!this.accountingPeriods.length) {
                this.gridApi.showNoRowsOverlay();
                this._toastrService.info('Fiscal Years are not yet setup for this company!');
            }
        } finally {
            this.loading = false;
        }
    }

    toggleMaximize(isMaximized: boolean): void {
        this.isMaximized = isMaximized;
    }

    goToParentCompany(): void {
        this._upgradeNavigationServiceHandler.go('company', { companyId: this.parentCompany.id });
    }

    toggleEditMode(isEdit: boolean) {
        this.isEditMode = isEdit;
        this.refreshBulkUpdateState();
    }

    refreshBulkUpdateState() {
        const isBulkUpdateVisible = this.isEditMode && this.gridApi.getSelectedNodes().length > 0 && !this.operationInProgress;
        this.isBulkUpdateVisible$.next(isBulkUpdateVisible);
    }

    async singlePeriodSelected() {
        this.searchModel.mode = Core.AccrualsGridViewModeEnum.SinglePeriod;

        this.endAccountingPeriod = undefined;
        this.searchModel.filters.endAccountingPeriodId = this.searchModel.filters.startAccountingPeriodId;

        this.showApplyButton = true;
    }

    async periodRangeSelected() {
        this.searchModel.mode = Core.AccrualsGridViewModeEnum.PeriodRange;

        this._initSelectedAccountingPeriodEnd();

        this.showApplyButton = true;
    }

    async previewCloseSelected() {
        if (!this.firstOpenAccountingPeriodId) {
            // No First Open AP.  Prevew Close mode not valid.  Fallback to Single Period mode.
            await this.singlePeriodSelected();
            return;
        }

        this.searchModel.mode = Core.AccrualsGridViewModeEnum.PreviewClose;

        this._startAccountingPeriod = _.find(this.accountingPeriods, { accountingPeriodId: this.firstOpenAccountingPeriodId });

        this.endAccountingPeriod = undefined;
        this.searchModel.filters.endAccountingPeriodId = this.searchModel.filters.startAccountingPeriodId;

        this.showApplyButton = true;
    }

    async accountingPeriodChanged(): Promise<void> {
        this.selectedIds = _.map(this.gridApi.getSelectedRows(), row => this._getRowEntityId(row));

        if ( this.searchModel.mode === Core.AccrualsGridViewModeEnum.PreviewClose &&
             (!this.firstOpenAccountingPeriodId || this.startAccountingPeriod.accountingPeriodId !== this.firstOpenAccountingPeriodId) ) {
            // Selected an Accounting Period other than First Open in Preview Close mode.
            // Switch to Single Period mode.
            await this.singlePeriodSelected();
        }
        else {
            this.searchModel.filters.startAccountingPeriodId = this.startAccountingPeriod.accountingPeriodId;

            this.searchModel.filters.endAccountingPeriodId = this.searchModel.mode === Core.AccrualsGridViewModeEnum.PeriodRange
                ? this.endAccountingPeriod.accountingPeriodId
                : this.startAccountingPeriod.accountingPeriodId;
        }

        this.reselectGridNodes = true;
        this.showApplyButton = true;
    }

    async companiesSelected(eventTarget: EventTarget): Promise<void> {
        this.availableFilters.states = this.availableFilters.propertyTypes = [];
        this.searchModel.filters.companyIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this._setEntityFilters();

        this.showApplyButton = true;
    }

    async statesSelected(eventTarget: EventTarget): Promise<void> {
        this.searchModel.filters.stateIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this.showApplyButton = true;
    }

    async propertyTypesSelected(eventTarget: EventTarget): Promise<void> {
        this.searchModel.filters.propertyTypeIds = _.map((eventTarget as HTMLSelectElement).selectedOptions, (option: any) => Number(option.value));

        this.showApplyButton = true;
    }

    setColumns(): void {
        const gridColumnGroups: ColGroupDef[] = this._accrualsPageService.getGridColumns(this.searchModel, this.columnFilters, this.resultsModel) as ColGroupDef[];
        this.gridApi.setColumnDefs(gridColumnGroups);
        this._orderColumns(gridColumnGroups);
    }

    async handleLRPStatusChange(x: Compliance.LongRunningProcessStatusChangeModel): Promise<void> {

        const validProcessTypes = [Compliance.LongRunningProcessTypeEnum.CloseAccrualAccountingPeriod,
                                   Compliance.LongRunningProcessTypeEnum.ReopenAccrualAccountingPeriod,
                                   Compliance.LongRunningProcessTypeEnum.ExportAccrualDataToFile,
                                   Compliance.LongRunningProcessTypeEnum.AccrualsGridGet];
        if (!validProcessTypes.includes(x.processType) ||
            !(x.isCanceled || x.isError || x.isCompleted) ||
            x.entityId !== this.searchModel.tlCompanyId) {
            // Not an event we are interested in, or not for our TL Company.
            return;
        }

        console.log(`event.lrpid=${x.longRunningProcessId},  active.lrpid=${this.activeLongRunningProcessId}`);
        console.log(`event: Canceled=${x.isCanceled},  Completed=${x.isCompleted},  Error=${x.isError}`);
        console.log(`closingPeriod=${this.closingPeriod},  creatingExportFile=${this.creatingExportFile},  gettingGrid=${this.gettingGrid}`);
        if (this.activeLongRunningProcessId !== x.longRunningProcessId)
        {
            console.log('DROPPING LRP EVENT');
            return;
        }

        switch ( x.processType ) {
            case Compliance.LongRunningProcessTypeEnum.CloseAccrualAccountingPeriod:
                if ( this.closingPeriod ) {
                    if (x.isError && x.errorMessage) {
                        this._toastrService.error(x.errorMessage);
                    }
                    else {
                        this._toastrService.info('Accounting Period was successfully closed.');
                    }

                    this.closingPeriod = false;
                    this.activeLongRunningProcessId = 0;
                    this.exportOptions.disabled = false;
                    this.refreshBulkUpdateState();
                    await this._getAccountingPeriods();
                    this._hideGridSpinner();

                    if ( !x.isCanceled && !x.isError ) {
                        await this.startGridDisplay();
                    }
                }
                break;
            case Compliance.LongRunningProcessTypeEnum.ReopenAccrualAccountingPeriod:
                if ( this.closingPeriod ) {
                    if (x.isError && x.errorMessage) {
                        this._toastrService.error(x.errorMessage);
                    }
                    else {
                        this._toastrService.info('Accounting Period was successfully re-opened.');
                    }

                    this.closingPeriod = false;
                    this.activeLongRunningProcessId = 0;
                    this.exportOptions.disabled = false;
                    this.refreshBulkUpdateState();
                    await this._getAccountingPeriods();
                    this._hideGridSpinner();

                    if ( !x.isCanceled && !x.isError ) {
                        await this.startGridDisplay();
                    }
                }
                break;
            case Compliance.LongRunningProcessTypeEnum.ExportAccrualDataToFile:
                if ( this.creatingExportFile ) {
                    if (x.isError && x.errorMessage) {
                        this._toastrService.error(x.errorMessage);
                    }
                    else {
                        this._toastrService.info('Export File was successfully added as an attachment.');
                    }

                    this.creatingExportFile = false;
                    this.activeLongRunningProcessId = 0;
                    this.exportOptions.disabled = false;
                    this.refreshBulkUpdateState();
                    this._hideGridSpinner();
                }
                break;
            case Compliance.LongRunningProcessTypeEnum.AccrualsGridGet:
                if ( this.gettingGrid ) {
                    if (x.isError && x.errorMessage) {
                        this._toastrService.error(x.errorMessage);
                    }

                    if ( !x.isCanceled && !x.isError ) {
                        await this._snackBarService.removeById(x.longRunningProcessId);

                        await this.loadGridResults(x.longRunningProcessId);
                    }

                    this.gettingGrid = false;
                    this.activeLongRunningProcessId = 0;
                    this.exportOptions.disabled = false;
                    this.refreshBulkUpdateState();
                    this._hideGridSpinner();
                    if ( x.isCanceled || x.isError ) {
                        this.loading = false;
                    }
                }
                break;
        }
    }

    async startGridDisplay(): Promise<void> {
        if (this.gridApi) {
            // Check request against threshold for using LRP.
            // If request is under the threshold, the original API that returns the
            //     results is called, bypassing LRP.
            // If request is over the threshold, the API that retrieves the Grid via
            //     LRP is called.
            const thresholdResult = await this._accrualsPageService.checkAccrualsGridThreshold(this.searchModel.tlCompanyId, this.searchModel);
            console.log(`CheckThreshold: ParcelCount=${thresholdResult.parcelCount}, useLRP=${thresholdResult.useLongRunningProcess}`);
            const useLRP: boolean = thresholdResult.useLongRunningProcess;

            if (useLRP) {
                // Start long running process to retrieve Grid
                await this._startGettingGridData();
            }
            else {
                // Retrieve Grid without long running process, returning results directly.
                this._startLoadingGrid();

                try {
                    this.resultsModel = await this._accrualsPageService.getAccrualsGridData(this.searchModel.tlCompanyId, this.searchModel);
                    this.gridApi.setRowData(this.resultsModel.entries);

                    this._completeGridDisplay();
                } finally {
                    this._stopLoadingGrid();
                }
            }
        }
    }

    async onAgGridReady(event: GridReadyEvent): Promise<void> {
        this.gridApi = event.api;
        this.columnApi = event.columnApi;

        this.gridApi.hideOverlay();
        await this.loadFilters();
        await this.loadLongRunningStatus();
    }

    async refresh() {
        await this.loadFilters();
        await this.loadLongRunningStatus();
        await this.startGridDisplay();
    }

    async closeAccountingPeriod() {
        if ((await this._messageBoxService.open({
            message: 'Are you sure you want to Close this accounting period?',
            buttons: MessageBoxButtons.YesNo
        })) === MessageBoxResult.Yes) {
            this.closingPeriod = true;
            this.exportOptions.disabled = true;
            this.refreshBulkUpdateState();
            this._showGridSpinner();
            try {
                const accountingPeriodId = this.startAccountingPeriod.accountingPeriodId;
                const lrp = await this._accrualsPageService.closeAccountingPeriod(this.parentCompany.id, accountingPeriodId);
                this.activeLongRunningProcessId = lrp.longRunningProcessId;
                this._snackBarService.addById(lrp.longRunningProcessId, Compliance.LongRunningProcessTypeEnum.CloseAccrualAccountingPeriod);
            } catch (e) {
                if(e.error) {
                    this._toastrService.error(e.error.message);
                }
                this.closingPeriod = false;
                this.activeLongRunningProcessId = 0;
                this.exportOptions.disabled = false;
                this.refreshBulkUpdateState();
                this._hideGridSpinner();
            }
        }
    }

    async openAccountingPeriod() {
        if ((await this._messageBoxService.open({
            message: 'Are you sure you want to Re-open this accounting period?',
            buttons: MessageBoxButtons.YesNo
        })) === MessageBoxResult.Yes) {
            this.closingPeriod = true;
            this.exportOptions.disabled = true;
            this.refreshBulkUpdateState();
            this._showGridSpinner();
            try {
                const accountingPeriodId = this.startAccountingPeriod.accountingPeriodId;
                const lrp = await this._accrualsPageService.openAccountingPeriod(this.parentCompany.id, accountingPeriodId);
                this.activeLongRunningProcessId = lrp.longRunningProcessId;
                this._snackBarService.addById(lrp.longRunningProcessId, Compliance.LongRunningProcessTypeEnum.ReopenAccrualAccountingPeriod);
            } catch (e) {
                if(e.error) {
                    this._toastrService.error(e.error.message);
                }
                this.closingPeriod = false;
                this.activeLongRunningProcessId = 0;
                this.exportOptions.disabled = false;
                this.refreshBulkUpdateState();
                this._hideGridSpinner();
            }
        }
    }

    async createExportFile(): Promise<void> {
        this.creatingExportFile = true;
        this.exportOptions.disabled = true;
        this.refreshBulkUpdateState();
        this._showGridSpinner();

        try {
            const lrp = await this._accrualsPageService.createExportFile(this.searchModel.tlCompanyId, this.startAccountingPeriod.accountingPeriodId);
            this.activeLongRunningProcessId = lrp.longRunningProcessId;
            this._snackBarService.addById(lrp.longRunningProcessId, Compliance.LongRunningProcessTypeEnum.ExportAccrualDataToFile);
        } catch (e) {
            if(e.error) {
                this._toastrService.error(e.error.message);
            }
            this.creatingExportFile = false;
            this.activeLongRunningProcessId = 0;
            this.exportOptions.disabled = false;
            this._hideGridSpinner();
            this.refreshBulkUpdateState();
        }
    }

    async transmitFile(): Promise<void> {
        const lrp = await this._accrualsPageService.startTransmitFile(this.startAccountingPeriod.accountingPeriodId, this.searchModel.tlCompanyId);
        this._snackBarService.addById(lrp.longRunningProcessId, Compliance.LongRunningProcessTypeEnum.TransmitAccrual);
    }

    async bulkUpdate() {
        const unfilteredSelections: Core.AccrualsGridEntryModel[] = await this._gatherBulkUpdateSelections();
        if (unfilteredSelections.length === 0) {
            return;
        }

        // Create lookup object, indexed by Accounting Period name, of the
        // Closed Accounting Periods in the selected contiguous range.
        const closedAccountingPeriods: { [key:string]: number } = {};
        if ( this.startAccountingPeriod )
        {
            const startNdx = _.findIndex(this.accountingPeriods, { accountingPeriodId: this.startAccountingPeriod.accountingPeriodId });
            const endNdx = 1 + (this.endAccountingPeriod
                ? _.findIndex(this.accountingPeriods, { accountingPeriodId: this.endAccountingPeriod.accountingPeriodId })
                : startNdx);
            _.each(this.accountingPeriods.slice(startNdx, endNdx), (entry) => {
                if ( entry.isFrozen )
                {
                    closedAccountingPeriods[entry.name] = 0;
                }
                return true;
            });
        }

        // Filter out selections for Closed Accounting Periods, tallying
        // a count for each Accounting Period.
        const selections = _.filter(unfilteredSelections, (entry) => {
            if ( entry.accountingPeriod in closedAccountingPeriods )
            {
                closedAccountingPeriods[entry.accountingPeriod] += 1;
                return false;
            }
            return true;
        });
        if (selections.length === 0) {
            // All selections were for Closed accounting periods.
            this._toastrService.info('Unable to perform operation! All selections are for CLOSED Accounting Periods!');
            return;
        }
        // Show an info toast for each Closed Accounting Period that had 1 or more selections.
        _.each(closedAccountingPeriods, (count: number, apName: string) => {
            if ( count > 0 )
            {
                this._toastrService.info(`Warning: ${count} row updates will be skipped because Accounting Period '${apName}' is CLOSED`);
            }
            return true;
        });

        const initialState = {
            selectedCount: selections.length,
            showOverrideChangeReason: this.searchModel.filters.summarizeBy == Core.AccrualsGridSummarizeByEnum.EconomicUnit
        };

        const modalRef = this._modalService.show(AccrualsBulkUpdateModalComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });

        modalRef.content.onClose = async (request?: Core.AccrualsBulkUpdateRequestModel) => {
            if(!request) {
                return;
            }

            request.filters = this.searchModel.filters;

            this.bulkUpdateInProgress = true;
            this.bulkUpdateTotals = {rowsChanged: null, billsChanged: null};
            this.selectionsLength = selections.length;

            for (const [i, selection] of selections.entries()) {  // https://stackoverflow.com/questions/34348937/access-to-es6-array-element-index-inside-for-of-loop
                if(this.bulkUpdateCanceled) {
                    break;
                }

                this.bulkUpdateCurrent = {
                    index: i + 1,
                    entry: selection
                };

                const {
                    accountingPeriodId,
                    companyId,
                    siteId,
                    parcelId,
                    billClusterId,
                    economicUnitTypeId,
                    siteNumber,
                    siteCode,
                    stateId
                } = selection;

                request.selection = {
                    accountingPeriodId,
                    companyId,
                    siteId,
                    parcelId,
                    billClusterId,
                    economicUnitTypeId,
                    siteNumber,
                    siteCode,
                    stateId
                };

                const result = await this._accrualsPageService.applyBulkUpdateToEntry(this.searchModel.tlCompanyId, request);

                if(result.billsChanged != null) {
                    this.bulkUpdateTotals.billsChanged += result.billsChanged;
                }
                if(result.rowsChanged != null) {
                    this.bulkUpdateTotals.rowsChanged += result.rowsChanged;
                }
            }

            this.bulkUpdateCurrent.index++;
        };
    }

    getCurrentBulkUpdateIdentity(): string {
        let identity: string = '';

        if (this.bulkUpdateCurrent) {
            identity = `[${this.bulkUpdateCurrent.index} of ${this.selectionsLength}]     `;

            switch (this.searchModel.filters.summarizeBy) {
                case Core.AccrualsGridSummarizeByEnum.State:
                    identity += `State: ${this.bulkUpdateCurrent.entry.stateName}`;
                    break;
                case Core.AccrualsGridSummarizeByEnum.Company:
                    identity += `Company: ${this.bulkUpdateCurrent.entry.companyName}`;
                    break;
                case Core.AccrualsGridSummarizeByEnum.Site:
                    identity += `Site Name: ${this.bulkUpdateCurrent.entry.siteName}`;
                    break;
                case Core.AccrualsGridSummarizeByEnum.Parcel:
                    identity += `Site Name: ${this.bulkUpdateCurrent.entry.siteName}`;
                    identity += `    Parcel: ${this.bulkUpdateCurrent.entry.parcelAcctNum}`;
                    break;
                case Core.AccrualsGridSummarizeByEnum.Bills:
                    identity += `Site Name: ${this.bulkUpdateCurrent.entry.siteName}`;
                    identity += `    Parcel: ${this.bulkUpdateCurrent.entry.parcelAcctNum}`;
                    identity += `    Collector: ${this.bulkUpdateCurrent.entry.collectorAbbr}`;
                    break;
                case Core.AccrualsGridSummarizeByEnum.EconomicUnit:
                    switch (this.bulkUpdateCurrent.entry.economicUnitTypeId) {
                        case Core.AccrualEconomicUnitTypeEnum.SiteNumber:
                            identity += `Site Number: ${this.bulkUpdateCurrent.entry.siteNumber}`;
                            break;
                        case Core.AccrualEconomicUnitTypeEnum.SubsidiaryCompany:
                            identity += `Company: ${this.bulkUpdateCurrent.entry.companyName}`;
                            break;
                        case Core.AccrualEconomicUnitTypeEnum.SiteCode:
                            identity += `Site Code: ${this.bulkUpdateCurrent.entry.siteCode}`;
                            break;
                        case Core.AccrualEconomicUnitTypeEnum.Parcel:
                            identity += `Site Name: ${this.bulkUpdateCurrent.entry.siteName}`;
                            identity += `    Parcel: ${this.bulkUpdateCurrent.entry.parcelAcctNum}`;
                            break;
                        case Core.AccrualEconomicUnitTypeEnum.Bill:
                            identity += `Site Name: ${this.bulkUpdateCurrent.entry.siteName}`;
                            identity += `    Parcel: ${this.bulkUpdateCurrent.entry.parcelAcctNum}`;
                            identity += `    Collector: ${this.bulkUpdateCurrent.entry.collectorAbbr}`;
                            break;
                    }
                    break;
            }
        }
        return identity;
    }

    async endBulkUpdate() {
        this.bulkUpdateCanceled = false;
        this.bulkUpdateInProgress = false;

        await this.refresh();
        this.refreshBulkUpdateState();
    }

    calculatePercentVarianceThreshold(val: number) {
        if(val) {
            this.searchModel.filters.percentVarianceThreshold = new Decimal(val).dividedBy(100).toNumber();
            this.showApplyButton = true;
        }
    }

    private async _initValues() {
        this.gridOptions = new AgGridOptionsBuilder({
            suppressScrollOnNewData: true,
            suppressRowClickSelection: true,
            headerHeight: 45,
            groupHeaderHeight: 25,
            suppressMovableColumns: true,
            onGridColumnsChanged: () => {
                if(this.columnSizing == 'sizeColumnsToFit') {
                    this.gridApi.sizeColumnsToFit();
                } else if (this.columnSizing == 'autosizeColumns') {
                    this.columnApi.autoSizeAllColumns();
                }
            },
            onFilterChanged: () => {
                this._updateTotals();
            },
            onColumnResized: (e: ColumnResizedEvent) => {
                if(e.source != 'uiColumnDragged') {
                    this.columnSizing = e.source;
                }
            },
            onSelectionChanged: () => {
                this._updateTotals();
                this.refreshBulkUpdateState();
            }
        })
        .withContext(this)
        .withLoadingOverlay()
        .withSort()
        .withColumnResize()
        .withTextSelection()
        .withVerticalScrollBar()
        .withMultipleColumnSort()
        .withColumnPinning()
        .build();

        // this.gridOptions.defaultColDef.cellStyle = (cell) => cell.column.colId === cell.column.parent.displayedChildren[0].colId ? { 'border-left': '1px solid lightgray' } : null

        this.searchModel = {
            tlCompanyId: (await this._companyService.getTopCompanyForEntity(Core.EntityTypes.Company, this.parentCompanyId)).companyID,
            mode: Core.AccrualsGridViewModeEnum.SinglePeriod,
            filters: {
                startAccountingPeriodId: null,
                endAccountingPeriodId: null,
                companyIds: [],
                stateIds: [],
                propertyTypeIds: [],
                summarizeBy: Core.AccrualsGridSummarizeByEnum.Site,
                billRecurType: Core.AccrualsGridBillRecurTypeEnum.Both,
                enableChangeDetection: false
            },
            pagination: {}
        } as Core.AccrualsGridSearchModel;

        const instanceId = await lastValueFrom(this._instanceRepository.getEntityInstanceId('company', this.parentCompanyId));
        this.hasEditPermission = this._restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT, instanceId) &&
                                 await this._restrictService.hasCompanyPermission(this.parentCompanyId, Core.AccessRightsEnum.Write);

        this._websocketListenerService.longRunningProcessStatusChange$.pipe(takeUntil(this._destroy$)).subscribe(this.handleLRPStatusChange.bind(this));
    }

    private setOpenStatusByIndex(index: number): void {
        if (index === 0) {
            this.lastClosedAccountingPeriodId = null;
            this.firstOpenAccountingPeriodId = this.accountingPeriods[0].accountingPeriodId;
        }
        else if (index >= 0) {
            this.lastClosedAccountingPeriodId = this.accountingPeriods[index - 1].accountingPeriodId;
            this.firstOpenAccountingPeriodId = this.accountingPeriods[index].accountingPeriodId;
        }
        else if(this.accountingPeriods.length) {
            this.lastClosedAccountingPeriodId = this.accountingPeriods[this.accountingPeriods.length - 1].accountingPeriodId;
            this.firstOpenAccountingPeriodId = null;
        }
    }

    private async _getAccountingPeriods(): Promise<void> {
        const accountingPeriodsModel = await this._accrualsPageService.getAccountingPeriods(this.searchModel.tlCompanyId);
        this.accountingPeriods = accountingPeriodsModel.accountingPeriods;

        this.setOpenStatusByIndex(this.accountingPeriods.findIndex(ap => !ap.isFrozen));

        this.startAccountingPeriod = this._getStartAccountingPeriod();
        if (this.startAccountingPeriod) {
            this.searchModel.filters.startAccountingPeriodId = this.startAccountingPeriod.accountingPeriodId;

            if (this.searchModel.mode === Core.AccrualsGridViewModeEnum.PeriodRange) {
                if(this._endAccountingPeriodId) {
                    this.endAccountingPeriod = _.find(this.accountingPeriods, { accountingPeriodId: this._endAccountingPeriodId });
                } else {
                    this._initSelectedAccountingPeriodEnd();
                }
            }
            else {
                this.searchModel.filters.endAccountingPeriodId = this.searchModel.filters.startAccountingPeriodId;
            }
        }
    }

    private _getStartAccountingPeriod(): Core.AccrualsGridAccountingPeriodModel {
        if (this._startAccountingPeriodId) {
            return _.find(this.accountingPeriods, { accountingPeriodId: this._startAccountingPeriodId });
        }
        else if (this.firstOpenAccountingPeriodId) {
            return _.find(this.accountingPeriods, { accountingPeriodId: this.firstOpenAccountingPeriodId });
        }
        else {
            return _.last(this.accountingPeriods);
        }
    }

    private _initSelectedAccountingPeriodEnd() {
        this.endAccountingPeriod = _.chain(this.accountingPeriods)
            .reject('isFrozen')
            .find(period => period.startDate > this.startAccountingPeriod.startDate)
            .value();

        if(!this.endAccountingPeriod) {
            this.endAccountingPeriod = _.last(this.accountingPeriods);
            this.startAccountingPeriod = this.accountingPeriods[this.accountingPeriods.length - 2];
        }

        if (this.endAccountingPeriod) {
            this.searchModel.filters.endAccountingPeriodId = this.endAccountingPeriod.accountingPeriodId;
        }
    }

    private _getRowEntityId(row: Core.AccrualsGridEntryModel): number {
        switch (this.searchModel.filters.summarizeBy) {
            case Core.AccrualsGridSummarizeByEnum.Bills:
                return row.billClusterId;
            case Core.AccrualsGridSummarizeByEnum.Company:
                return row.companyId;
            case Core.AccrualsGridSummarizeByEnum.EconomicUnit:
                switch (row.economicUnitTypeId) {
                    case Core.AccrualEconomicUnitTypeEnum.SiteNumber:
                        return row.siteId;
                    case Core.AccrualEconomicUnitTypeEnum.SubsidiaryCompany:
                        return row.companyId;
                    case Core.AccrualEconomicUnitTypeEnum.SiteCode:
                        return row.billClusterId;
                    case Core.AccrualEconomicUnitTypeEnum.Parcel:
                        return row.parcelId;
                    case Core.AccrualEconomicUnitTypeEnum.Bill:
                        return row.billClusterId;
                }
            case Core.AccrualsGridSummarizeByEnum.Parcel:
                return row.parcelId;
            case Core.AccrualsGridSummarizeByEnum.Site:
                return row.siteId;
            case Core.AccrualsGridSummarizeByEnum.State:
                return row.stateId;
        }
    }

    private async _getAvailableFilters(): Promise<void> {
        this.availableFilters = await this._accrualsPageService.getAvailableFilters(this.parentCompanyId);
        this.availableFilters.companies = _.sortBy(this.availableFilters.companies, company => company.id == this.searchModel.tlCompanyId || company.name.toLowerCase());
    }

    private _setEntityFilters(): void {
        _.chain(this.availableFilters.companies)
            .filter(company => !this.searchModel.filters.companyIds.length || _.includes(this.searchModel.filters.companyIds, company.id))
            .forEach(company => {
                this.availableFilters.states = _.chain(this.availableFilters.states)
                    .unionWith(company.states, _.isEqual)
                    .sortBy('name')
                    .value();

                this.availableFilters.propertyTypes = _.unionWith(this.availableFilters.propertyTypes, company.propertyTypes, _.isEqual);
            })
            .value();

        this.searchModel.filters.stateIds = _.intersectionBy(this.searchModel.filters.stateIds, _.map(this.availableFilters.states, 'id'));
        this.searchModel.filters.propertyTypeIds = _.intersectionBy(this.searchModel.filters.propertyTypeIds, _.map(this.availableFilters.propertyTypes, 'id'));
    }

    private _orderColumns(gridColumnGroups: ColGroupDef[]): void {
        let startingIdx: number = 0;

        _.forEach(gridColumnGroups, (group, i) => {
            if (gridColumnGroups[i - 1]) {
                startingIdx += gridColumnGroups[i - 1].children.length;
            }

            const colIds: string[] = _.map(group.children, 'colId');
            this.columnApi.moveColumns(colIds, startingIdx);
        });
    }

    private async _startGettingGridData(): Promise<void> {
        this._startLoadingGrid();

        this.gettingGrid = true;
        this.exportOptions.disabled = true;
        this.refreshBulkUpdateState();
        this._showGridSpinner();
        try {
            const lrp = await this._accrualsPageService.startAccrualsGridGet(this.searchModel.tlCompanyId, this.searchModel);
            this.activeLongRunningProcessId = lrp.longRunningProcessId;
            this._snackBarService.addById(lrp.longRunningProcessId, Compliance.LongRunningProcessTypeEnum.AccrualsGridGet);
        } catch (e) {
            if(e.error) {
                this._toastrService.error(e.error.message);
            }
            this.gettingGrid = false;
            this.activeLongRunningProcessId = 0;
            this.exportOptions.disabled = false;
            this.refreshBulkUpdateState();
            this._stopLoadingGrid();
        }
    }

    private async loadGridResults(longRunningProcessId: number): Promise<void> {
        this._startLoadingGrid();

        try {
            this.resultsModel = await this._accrualsPageService.getAccrualsGridResults(this.searchModel.tlCompanyId, longRunningProcessId);
            this.gridApi.setRowData(this.resultsModel.entries);

            this._completeGridDisplay();
        } finally {
            this._stopLoadingGrid();
        }
    }

    private _completeGridDisplay(): void {
        this._updateTotals();

        this.setColumns();

        this.showApplyButton = false;

        if(this.reselectGridNodes) {
            this.gridApi.forEachNode(node => {
                if (_.includes(this.selectedIds, this._getRowEntityId(node.data))) {
                    node.setSelected(true);
                }
            });

            this.reselectGridNodes = false;
        }
    }

    //async loadData(): Promise<void> {
    //    this._startLoadingGrid();

    //    try {
    //        this.resultsModel = await this._accrualsPageService.getAccrualsGridData(this.searchModel.tlCompanyId, this.searchModel);
    //        this.gridApi.setRowData(this.resultsModel.entries);

    //        this._updateTotals();
    //    } finally {
    //        this._stopLoadingGrid();
    //    }
    //}

    private _startLoadingGrid(): void {
        this.loading = true;
        this._showGridSpinner();
    }

    private _stopLoadingGrid(): void {
        this.loading = false;
        this._hideGridSpinner();
    }

    private _showGridSpinner(): void {
        if ( this.gridApi ) {
            this.gridApi.showLoadingOverlay();
        }
    }

    private _hideGridSpinner(): void {
        if ( this.gridApi ) {
            this.gridApi.hideOverlay();
        }
    }

    private _updateTotals(): void {
        const totalRows = [];
        let isAtLeastOneRow: boolean = false;
        let areSelectedRows: boolean = false;

        const totalRow: Core.AccrualsGridEntryModel = {
            stateName: 'TOTAL',
            openTaxObligations: {},
            periodContributions: {},
            accrualBalances: {},
            journalImpacts: [],
            journalBalances: [],
            journalImpactsByType: [],
            journalBalancesByType: []
        } as Core.AccrualsGridEntryModel;
        const selectedRow: Core.AccrualsGridEntryModel = {
            stateName: 'SELECTED',
            openTaxObligations: {},
            periodContributions: {},
            accrualBalances: {},
            journalImpacts: [],
            journalBalances: [],
            journalImpactsByType: [],
            journalBalancesByType: []
        } as Core.AccrualsGridEntryModel;

        this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
            isAtLeastOneRow = true;
            const entry = rowNode.data as Core.AccrualsGridEntryModel;

            if(rowNode.isSelected()) {
                areSelectedRows = true;
                this._compileNestedTotals(entry, selectedRow);
            }

            this._compileNestedTotals(entry, totalRow);
        });

        if (isAtLeastOneRow) {
            totalRows.push(totalRow);
        }
        if (areSelectedRows) {
            totalRows.push(selectedRow);
        }

        this.gridApi.setPinnedBottomRowData(totalRows);
    }

    private _compileNestedTotals(entry: Core.AccrualsGridEntryModel, totalRow: Core.AccrualsGridEntryModel) {
        _.forEach(this._accrualsPageService.rowFields, (block: any) => {
            _.forEach(block.fields, field => {
                const entryValue = entry[block.category][field];

                if (entryValue == undefined || entryValue == null) {
                    return;
                }

                if (!totalRow[block.category][field]) {
                    totalRow[block.category][field] = 0;
                }

                totalRow[block.category][field] = new Decimal(totalRow[block.category][field]).add(entryValue).toNumber();
            });
        });

        _.forEach(this.resultsModel.glAccountsByType, (account, i) => {
            if (!totalRow.journalBalancesByType[i]) {
                totalRow.journalBalancesByType[i] = { amount: 0 };
            }

            if (!totalRow.journalImpactsByType[i]) {
                totalRow.journalImpactsByType[i] = { amount: 0 };
            }

            if (entry.journalBalancesByType[i] && entry.journalBalancesByType[i].amount != null && entry.journalBalancesByType[i].amount != undefined) {
                totalRow.journalBalancesByType[i].amount = new Decimal(totalRow.journalBalancesByType[i].amount).add(entry.journalBalancesByType[i].amount).toNumber();
            }

            if (entry.journalImpactsByType[i] && entry.journalImpactsByType[i].amount != null && entry.journalImpactsByType[i].amount != undefined) {
                totalRow.journalImpactsByType[i].amount = new Decimal(totalRow.journalImpactsByType[i].amount).add(entry.journalImpactsByType[i].amount).toNumber();
            }
        });

        _.forEach(this.resultsModel.glAccounts, (account, i) => {
            if (!totalRow.journalBalances[i]) {
                totalRow.journalBalances[i] = { amount: 0 };
            }

            if (!totalRow.journalImpacts[i]) {
                totalRow.journalImpacts[i] = { amount: 0 };
            }

            if (entry.journalBalances[i] && entry.journalBalances[i].amount != null && entry.journalBalances[i].amount != undefined) {
                totalRow.journalBalances[i].amount = new Decimal(totalRow.journalBalances[i].amount).add(entry.journalBalances[i].amount).toNumber();
            }

            if (entry.journalImpacts[i] && entry.journalImpacts[i].amount != null && entry.journalImpacts[i].amount != undefined) {
                totalRow.journalImpacts[i].amount = new Decimal(totalRow.journalImpacts[i].amount).add(entry.journalImpacts[i].amount).toNumber();
            }
        });
    }

    private async _gatherBulkUpdateSelections(): Promise<Core.AccrualsGridEntryModel[]> {

        // this.bulkUpdateEntityType = (this.filters.siteRollup ? "Site" : "Parcel");

        const selections: Core.AccrualsGridEntryModel[] = this.gridApi.getSelectedRows();

        // If Selected count is less than threshold for "an awful lot", just proceed.
        if (selections.length < this.LARGE_BULK_UPDATE_THRESHOLD) {
            return selections;
        }

        // Confirm with the user to Builk Update that many Tax Authorities.
        const answer: number = await this._messageBoxService.open({
            message: `Are you sure you want to Bulk Update ${selections.length} entries?`,
            buttons: MessageBoxButtons.OKCancel
        });

        if (answer === MessageBoxResult.OK) {
            return selections;
        }

        // Canceled Bulk Update, return no selections.
        console.log('Bulk Update confirmation canceled');
        return [];
    }
}
