import { Injectable } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {ClientServicesExceptionModalComponent} from './client-service-exception.modal';

//This is a separate service because if it was in the normal exception service,
//there's a circular dependency which causes DI to go berserk
@Injectable()
export class ClientServicesExceptionModalService {
    constructor(private modalService: BsModalService) { }

    exceptionsModalRef: BsModalRef;

    openExceptionsModal(entityId: number, entityTypeId: number, clientServiceIds: number[], propertyTypeIds: number[], isInactive: boolean): void {
        this.exceptionsModalRef = this.modalService.show(ClientServicesExceptionModalComponent, {class: 'responsibility-exceptions modal-lg'});
        const modalComponent: ClientServicesExceptionModalComponent = this.exceptionsModalRef.content;
        modalComponent.entityId = entityId;
        modalComponent.entityTypeId = entityTypeId;
        modalComponent.clientServiceIds = clientServiceIds;
        modalComponent.propertyTypeIds = propertyTypeIds;
        modalComponent.isInactive = isInactive;
        modalComponent.initModal();
    }
}