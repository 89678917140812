import { Component, OnInit, ViewChild } from '@angular/core';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { PropertyTypeService } from '../Common/Services/propertyType.service.upgrade';
import { Site } from './Site/Site.Model';
import { Constants } from '../constants.new';
import * as _ from 'lodash';
import { SiteClassService } from './Site/siteClass.service';
import { SiteMapService } from './site.map.service';
import { BusyIndicatorService } from '../Busy-Indicator';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SiteService } from './Site/Site.Service.upgrade';
import { TimerService } from '../UI-Lib/Utilities/timer.service';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

export interface MappableSite extends Core.MappableSite {
    markerOptions: google.maps.MarkerOptions;
    center: google.maps.LatLngLiteral;
}

@Component({
    selector: 'site-map',
    templateUrl: './site.map.component.html',
    styleUrls: ['./site.map.component.scss']
})
export class SiteMapComponent implements OnInit {
    constructor(private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _propertyTypeService: PropertyTypeService,
        private readonly _siteService: SiteService,
        private readonly _Constants: Constants,
        private readonly _siteClassService: SiteClassService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        public readonly siteMapService: SiteMapService,
        private readonly _timer: TimerService
    ){}

    @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
    @ViewChild(GoogleMap, { static: false }) map: GoogleMap;

    originalSite: Site;
    isOriginalSiteIncluded: boolean;
    windowHover: object = {};
    mapOptions: google.maps.MapOptions;
    markerOptions: google.maps.MarkerOptions;
    infoWindowOptions: google.maps.InfoWindowOptions;
    originalCenter: google.maps.LatLngLiteral;
    hoveredSiteId: number;

    propertyTypes = [];
    siteClasses = [];

    startingZoom: number = 16;
    zoom: number = this.startingZoom;

    viewportBounds: Core.GeoMapBounds;
    paddedBounds: Core.GeoMapBounds;

    filters: Core.SiteMappingFilters = {
        propertyTypeIds: [],
        siteClassPrimaryIds: [],
        companyIds: []
    };

    getNewSites$: Subject<void> = new Subject<void>();
    sitesToDisplay: MappableSite[] = [];
    siteMarkerInfoArr: Core.SiteMarkerInfo[] = [];

    debouncedGetSiteInfo;

    ngOnInit(): void {
        this.mapOptions = { styles: this.siteMapService.mapStyles };
        this.markerOptions = { animation: google.maps.Animation.DROP };
        this.infoWindowOptions = { maxWidth: 300 };

        this._getSite();
        this._getFilters();

        this.debouncedGetSiteInfo = _.debounce(this.getSiteInfo, 250);

        this.getNewSites$.pipe( debounceTime(500) )
            .subscribe(() => this._getNewSites() );
    }

    private async _getSite(): Promise<void> {
        const siteId = +this._upgradeNavigationServiceHandler.getQuerystringParam('siteId');
        const companyId = +this._upgradeNavigationServiceHandler.getQuerystringParam('companyId');

        this.originalSite = await this._siteService.load(siteId, companyId);
        this.originalCenter = {
            lat: this.originalSite?.address.latitude,
            lng: this.originalSite?.address.longitude
        };
    }

    private _getFilters(): void {
        this._propertyTypeService.get().then(propertyTypes => this.propertyTypes = propertyTypes);
        this._siteClassService.getPrimaries().then(siteClasses => this.siteClasses = _.sortBy(siteClasses, 'siteClassPrimaryDesc'));
    }

    private async _getNewSites(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading sites', delay: 0 });

        try {
            this.paddedBounds = this._getPaddedBounds();
            const filteredSites =  (await this.siteMapService.getSitesToMap({ bounds: this.paddedBounds,  filters: this.filters })).sites;

            this.isOriginalSiteIncluded = _.some(filteredSites, {siteId: this.originalSite.siteID});

            const filteredSitesNotOnMap = _.differenceBy(filteredSites, this.sitesToDisplay, 'siteId');
            const sitesToAdd = _.chain(filteredSitesNotOnMap)
                .reject({siteId: this.originalSite.siteID})
                .map((site: MappableSite) => {
                    let iconName: string = 'symbol_iter';

                    if(site.siteClassPrimaryID !== undefined) {
                        iconName = site.stackId ? 'condominium' : this._Constants.SiteClassIcons[site.siteClassPrimaryID];
                    }

                    site.markerOptions = { icon: `/images/google-maps-icons/${iconName}.png` };

                    site.center =  {
                        lat: site.latitude,
                        lng: site.longitude
                    };

                    return site;
                })
                .value();

            this.sitesToDisplay.push(...sitesToAdd);

            const sitesToRemove = _.differenceBy(this.sitesToDisplay, filteredSites, 'siteId');
            _.pullAllBy(this.sitesToDisplay, sitesToRemove, 'siteId');
        } finally {
            busyRef.hide();
        }

    }

    private _getPaddedBounds(): Core.GeoMapBounds {
        const startingOffset = 0.01;
        const totalOffset = startingOffset * Math.pow(2, this.startingZoom - this.zoom);

        return {
            northwestCorner: {
                latitude: this.viewportBounds.northwestCorner.latitude + totalOffset,
                longitude: this.viewportBounds.northwestCorner.longitude - totalOffset
            },
            southeastCorner: {
                latitude: this.viewportBounds.southeastCorner.latitude - totalOffset,
                longitude: this.viewportBounds.southeastCorner.longitude + totalOffset
            }
        };
    }

    propertyTypesSelected(eventTarget: EventTarget): void {
        const selectedOptions = (eventTarget as HTMLSelectElement).selectedOptions;
        this.filters.propertyTypeIds = _.map(selectedOptions, (option: any) => Number(option.value));
    }

    siteClassesSelected(eventTarget: EventTarget): void {
        const selectedOptions = (eventTarget as HTMLSelectElement).selectedOptions;
        this.filters.siteClassPrimaryIds = _.map(selectedOptions, (option: any) => Number(option.value));
    }

    boundsChanged(): void {
        const bounds = this.map.getBounds();
        const northeastCorner = bounds.getNorthEast();
        const southwestCorner = bounds.getSouthWest();

        this.viewportBounds = {
           northwestCorner: {
                latitude: northeastCorner.lat(),
                longitude: southwestCorner.lng()
            },
            southeastCorner: {
                latitude: southwestCorner.lat(),
                longitude: northeastCorner.lng()
            }
        };
    }

    zoomChanged(): void {
        this.zoom = this.map.getZoom();
    }

    mapIdle(): void {
        // need to figure out if we've moved beyond the bounds of the buffered viewport
        if(!this.paddedBounds  // initial call
            || this.viewportBounds.northwestCorner.latitude > this.paddedBounds.northwestCorner.latitude
            || this.viewportBounds.northwestCorner.longitude < this.paddedBounds.northwestCorner.longitude
            || this.viewportBounds.southeastCorner.latitude < this.paddedBounds.southeastCorner.latitude
            || this.viewportBounds.southeastCorner.longitude > this.paddedBounds.southeastCorner.longitude) {
                this.getNewSites$.next();
            }
    }

    navigateToCompany(): void {
        this._upgradeNavigationServiceHandler.go('company', {companyId: this.originalSite.companyID});
    }

    navigateToOriginalSite(): void {
        this._upgradeNavigationServiceHandler.go('siteRedirect', {entityID: this.originalSite.siteID});
    }

    async getSiteInfo(siteId: number, mapMarker: MapMarker, stackId?: number) {
        this.infoWindow.open(mapMarker);

        this.siteMarkerInfoArr = [];
        this.hoveredSiteId = siteId;

        let siteIds = [siteId];

        if(stackId) {
            siteIds = _.chain(this.sitesToDisplay)
                .filter({stackId: stackId})
                .map('siteId')
                .value();
        }

        const siteMarkerInfoGetResult = (await this.siteMapService.getSiteMarkerInfo(siteIds));
        this.siteMarkerInfoArr = _.sortBy(siteMarkerInfoGetResult.sites, 'siteName');
    }

    mouseOutOfPin(siteId: number): void {
        this._timer.setTimeout(() => {
            if(this.windowHover[siteId]) {
                return;
            }

            this.infoWindow.close();
            this.debouncedGetSiteInfo.cancel();
        }, 150);
    }

    limitToParentCompany(e): void {
        this.filters.companyIds = e.target.checked ? [this.originalSite.companyID] : [];
    }

    mouseEnterWindow(): void {
        this.windowHover[this.hoveredSiteId] = true;
    }

    mouseleaveWindow() {
       this.windowHover[this.hoveredSiteId] = false;
       this.infoWindow.close();
       this.debouncedGetSiteInfo.cancel();
    }
}
