import { Component, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AssetDescriptorFieldTypes } from '../Models/enums';
import * as _ from 'lodash';

interface AssetDescriptorEditComponentChanges extends SimpleChanges {
    descriptor: SimpleChange,
    isDisabled: SimpleChange
}

@Component({
    selector: 'asset-descriptor-edit',
    templateUrl: './assetDescriptorEdit.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: AssetDescriptorEditComponent,
        multi: true
    }]
})
export class AssetDescriptorEditComponent implements ControlValueAccessor {
    constructor() {
        this.dateChange = new EventEmitter();
    }

    @Input() descriptor: Compliance.CompanyAssetDescriptorMappingModel;
    @Input() isDisabled: boolean = false;
    @Input() isOverridden: boolean = false;
    @Output() dateChange: EventEmitter<Date>;

    pickList: string[];
    textValue: string;
    numberValue: number;
    dateValue: Date;
    booleanValue: boolean;

    fieldTypes = AssetDescriptorFieldTypes;

    private _onChangeFn: (val: any) => void;
    private _onTouchedFn: () => void;

    ngOnChanges(changes: AssetDescriptorEditComponentChanges): void {
        if (changes && changes.descriptor) {
            this._setModel();
        }
    }

    descriptorTypesEq(a: Core.DescriptorFieldTypes, b: AssetDescriptorFieldTypes): boolean {
        return (a as number) === (b as number);
    }

    registerOnChange(fn: (val: any) => void): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    writeValue(val: any): void {
        switch (this.descriptor.descriptor.fieldType) {
            case Core.DescriptorFieldTypes.Text:
                this.textValue = val;
                break;
            case Core.DescriptorFieldTypes.Number:
                this.numberValue = val;
                break;
            case Core.DescriptorFieldTypes.Date:
                if (!val) {
                    this.dateValue = null;
                } else {
                    this.dateValue = new Date(val);
                }
                break;
            case Core.DescriptorFieldTypes.YesNo:
                this.booleanValue = !!val;
                break;
            case Core.DescriptorFieldTypes.Currency:
                this.numberValue = val;
                break;
            case Core.DescriptorFieldTypes.Picklist:
                this.textValue = val;
                break;
        }
    }

    notifyTextChange(): void {
        this._onChangeFn && this._onChangeFn(this.textValue);
    }

    notifyNumberChange(): void {
        this._onChangeFn && this._onChangeFn(this.numberValue);
    }

    notifyDateChange(): void {
        this._onChangeFn && this._onChangeFn(this.dateValue);
        this.dateChange.emit(this.dateValue);
    }

    notifyBooleanChange(): void {
        const v = this.booleanValue ? 1 : 0;
        this._onChangeFn && this._onChangeFn(v);
    }

    getMaxLength(): number {
        let maxLength = 1000;
        if(this.descriptor && this.descriptor.descriptor && this.descriptor.descriptor.validation && this.descriptor.descriptor.validation.maxLength) {
            maxLength = this.descriptor.descriptor.validation.maxLength;
        }
        return maxLength;
    }

    private _setModel(): void {
        this.pickList = _.map(_.filter(this.descriptor && this.descriptor.pickList, y => !y.disabled), x => x.name);
    }
}
