
<div class="card panel-flat-color secondary-panel">
    <div class="panel-flat-header" style="display: flex; justify-content: space-between;">
        <h3>Assessment Classes</h3>
        <div class="header-button-container">
            <button class="ace-btn btn-text has-icon btn-primary"
                *ngIf="hasEditPermission && !assessor"
                (click)="launchClassModal()"
                [disabled]="serverAction">
                    <i class="fa fa-plus-circle"></i>
            </button>
        </div>
    </div>
    <div class="panel-flat-body">
        <loading-spinner [show]="serverAction" [size]="4" [hasOverlay]="true"></loading-spinner>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Assessment Classification</th>
                    <th>Parcel Prop Types</th>
                    <th>Component(s)</th>
                    <th>Ratio</th>
                    <th>Notes</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let ac of assessmentClasses">
                    <tr *ngIf="ac.isVisible" (mouseenter)="ac.isHovered = true" (mouseleave)="ac.isHovered = false">
                        <td>{{ac.name}}</td>
                        <td>
                            <div *ngFor="let propType of ac.propTypes">{{propType.abbr}}</div>
                        </td>
                        <td>
                            <div *ngFor="let propType of ac.propTypes">{{propType.componentsString}}</div>
                        </td>
                        <td>
                            <div *ngIf="ac.ratioSetAtAssessor && !assessor">Defined at Assessor</div>
                            <div *ngIf="!(ac.ratioSetAtAssessor && !assessor)">
                                <div *ngIf="ac.fixedRatio">
                                    <span *ngFor="let ratio of ac.assessmentClassRatios; let first=first;">
                                        <span *ngIf="first">{{ratio.assessmentRatio | percent:'1.0-3'}}</span>
                                    </span>
                                </div>
                                <div *ngIf="!ac.fixedRatio">
                                    <span *ngFor="let ratio of ac.assessmentClassRatios; let last=last">
                                        {{ratio.taxYear}}: {{ratio.assessmentRatio | percent:'1.0-3'}}<span *ngIf="!last">,</span>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td>
                            <i *ngIf="ac.description" class="fa fa-file-text" [popover]="ac.description" triggers="mouseenter:mouseleave"></i>
                        </td>
                        <td>
                            <div class="ws-button-row" *ngIf="hasEditPermission" [class.invisible]="!ac.isHovered">
                                <button class="ace-btn has-icon btn-primary m-0" (click)="launchClassModal(ac)">
                                    <i class="fa fa-pencil"></i>
                                </button>
                                <button class="ace-btn has-icon btn-primary btn-danger" style="margin: 0;">
                                    <i class="fa fa-trash" (click)="deleteClass(ac)"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div *ngIf="assessor" class="checkbox">
			<label>
				<input type="checkbox" [(ngModel)]="showStateClasses" (ngModelChange)="onShowUnusedChanged()"> Show unused state assessment classification(s)
			</label>
		</div>
    </div>
</div>
