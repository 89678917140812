<div class="ws-filter-container">
    <div class="ws-flex-container-horizontal ws-stretch-width">
        <select class="form-select form-select-sm" [ngModel]="filterValues[0].filterType" (ngModelChange)="setFilterType($event)">
            <option *ngFor="let option of selectFilterOptions" [ngValue]="option">{{option.displayName}}</option>
        </select>
        <div class="condition ws-flex-container-horizontal">
            <button class="ace-btn btn-sm"
                    [class.active]="filterConditionType === 1"
                    (click)="setConditionType(1)">And</button>
            <button class="ace-btn btn-sm"
                    [class.active]="filterConditionType === 2"
                    (click)="setConditionType(2)">Or</button>
        </div>
    </div>
    <ng-container *ngFor="let filter of filterValues; first as isFirst; last as isLast; index as i; trackBy: trackByFn">
        <div *ngIf="filterConditionType !== 0 && !isFirst"  class="condition-filter-type ws-flex-container-horizontal">
            <select class="form-select form-select-sm" [(ngModel)]="filter.filterType">
                <option *ngFor="let option of selectFilterOptions" [ngValue]="option">{{option.displayName}}</option>
            </select>
            <button class="ace-btn btn-primary btn-danger btn-sm"
                    helpTooltip
                    helpContentId="ag-grid.removeFilter"
                    position="right"
                    (click)="removeFilterValue(i)">
                <i class="fa fa-minus"></i>
            </button>
            <button *ngIf="filter.filterType && filter.filterType.hideFilterInput"
                    class="ace-btn btn-primary btn-sm"
                    helpTooltip
                    helpContentId="ag-grid.addFilter"
                    position="right"
                    (click)="addFilterValue()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <div *ngIf="!(filter.filterType && filter.filterType.hideFilterInput)"  class="ws-flex-container-horizontal">
            <div class="text-input ws-flex-container-horizontal">
                <input #input
                       [numbersOnly]="true"
                       type="text"
                       [placeholder]="((filter.filterType && filter.filterType.displayKey === 'inRange') ? 'From...' : 'Filter...')"
                       (ngModelChange)="onChange($event, i)"
                       [ngModel]="filter.filterValue"
                       class="form-control"
                       (keyup.enter)="enterPressed($event, filter.filterValue)"
                       [class.has-reset]="filter.filterToValue"/>
                <span class="reset-value clickable"
                      [class.showReset]="filter.filterValue"
                      helpTooltip
                      helpContentId="ag-grid.clearField"
                      position="right"
                      (click)="clearInput(i)">
                    <i class="fa fa-times"></i>
                </span>
            </div>
            <button *ngIf="filterConditionType !== 0 && isLast"
                    class="ace-btn btn-primary btn-sm"
                    helpTooltip
                    helpContentId="ag-grid.addFilter"
                    position="right"
                    [disabled]="!filter.filterValue"
                    (click)="addFilterValue()">
                <i class="fa fa-plus"></i>
            </button>
        </div>
        <div *ngIf="filter.filterType && filter.filterType.displayKey === 'inRange' && !(filter.filterType && filter.filterType.hideFilterInput)"
             class="text-input ws-flex-container-horizontal">
            <input #toValue
                   [numbersOnly]="true"
                   type="text"
                   placeholder="To..."
                   (ngModelChange)="onToValueChange($event, i)"
                   [ngModel]="filter.filterToValue"
                   class="form-control"
                   [class.has-reset]="filter.filterToValue"/>
            <span class="reset-value clickable"
                  [class.showReset]="filter.filterToValue"
                  helpTooltip
                  helpContentId="ag-grid.clearField"
                  position="right">
                <i class="fa fa-times"></i>
            </span>
        </div>
    </ng-container>
    <div class="ws-flex-container-horizontal">
        <button #applyButton
                class="ace-btn btn-primary btn-sm ws-stretch-width"
                helpTooltip
                helpContentId="ag-grid.applyFilter"
                position="bottom"
                (click)="applyNumberFilter()">Apply</button>
        <button class="ace-btn btn-primary btn-danger btn-sm"
                helpTooltip
                helpContentId="ag-grid.addFilter"
                position="right"
                (click)="clearFilter()">
            <i class="fa fa-times"></i>
        </button>
    </div>
</div>
