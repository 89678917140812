import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {PackageEmailTemplate} from '../../Processing/Transmittal/transmittal.models';


declare const $: any;

@Component({
    selector: 'custom-email-template-modal',
    templateUrl: './preview-email-template-modal.component.html'
})
export class PreviewEmailTemplateModalComponent implements OnInit {
    constructor(public modalRef: BsModalRef) {}

    template: PackageEmailTemplate;
    modules: any;

    ngOnInit() {
        this.modules = {
            toolbar: false
        };
    }
}