import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { WsSelectValueFormatter } from '../UI-Lib/Select/select.interface';

@Component({
    selector: 'show-tax-year',
    template: `
    <div class="ws-flex-container-horizontal ws-flex-align-items-center tax-rates-form-items mb-2">
        <label class="ws-flex-auto ws-no-margin">Show Tax Year:</label>
        <div class="ws-flex-auto">
            <ws-select [(ngModel)]="taxYearBegin"
                       (ngModelChange)="onTaxYearBeginChange()"
                       [options]="taxYearOptions"
                       [canSearch]="false"
                       [isPrimitive]="true">
            </ws-select>
        </div>
        <div>plus</div>
        <div class="ws-flex-auto">
            <ws-select [(ngModel)]="taxYearsPrior"
                       (ngModelChange)="onTaxYearsPriorChange()"
                       labelProperty="name"
                       valueProperty="value"
                       [canSearch]="false"
                       [options]="priorOptions">
            </ws-select>
        </div>
    </div>
    `
})
export class ShowTaxYearComponent implements OnInit {
    @Input() taxYearBegin: number = new Date().getFullYear();
    @Input() taxYearsPrior: number = 2;
    @Output() taxYearBeginChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() taxYearsPriorChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() showTaxYearChanged: EventEmitter<void> = new EventEmitter<void>();

    taxYearOptions: number[];
    priorOptions: Compliance.NameValuePair<number>[];
    priorFormatter: WsSelectValueFormatter = (value) => `${value} Prior`;

    ngOnInit() {
        const currentYear = new Date().getFullYear();
        this.taxYearOptions = _.range(currentYear + 5, currentYear - 11);
        this.priorOptions = [1, 2, 3, 4].map(n => ({ name: `${n} Prior`, value: n }));
    }

    onTaxYearBeginChange() {
        this.taxYearBeginChange.emit(this.taxYearBegin);
        this.showTaxYearChanged.emit();

    }

    onTaxYearsPriorChange() {
        this.taxYearsPriorChange.emit(this.taxYearsPrior);
        this.showTaxYearChanged.emit();
    }
}
