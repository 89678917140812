import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BusyIndicatorMessageManager } from '../../../Busy-Indicator';
import { ComplianceService } from '../compliance.service';

export interface ComplianceReturnAddOutputParameters {
    filingBatchId: number;
    companyId: number;
}

@Component({
    templateUrl: './return-add.component.html',
    selector: 'compliance-return-add'
})
export class ComplianceReturnAddComponent implements OnInit {
    constructor(
        private readonly _complianceService: ComplianceService,
        private readonly _bsModalRef: BsModalRef) {
    }

    busyIndicatorMessageManager: BusyIndicatorMessageManager<string> = new BusyIndicatorMessageManager<string>();
    filingId: number;
    result: ComplianceReturnAddOutputParameters;
    priorFilings: Compliance.FilingModel[];
    priorFiling: Compliance.FilingModel = null;
    isAmendment: boolean = false;
    isInitialized: boolean = false;
    priorReturnSelected: boolean;

    async ngOnInit(): Promise<void> {
        const busyMsgId = 'loading';
        this.busyIndicatorMessageManager.add('Loading', busyMsgId);

        try {
            // in case the filing batch has already been created close
            const filing = await this._complianceService.getFiling(this.filingId);
            if (filing.filingBatchId) {
                this.result = {
                    filingBatchId: filing.filingBatchId,
                    companyId: filing.topLevelCompanyId
                };
                this._bsModalRef.hide();
            }

            this.priorFilings = await this._complianceService.getAvailablePriorFilings(this.filingId);
            this.priorFiling = (this.priorFilings && this.priorFilings.length) ? this.priorFilings[0] : null;
            this.priorReturnSelected = this.priorFiling !== null;
            this.isAmendment = this.priorFiling ? this.priorFiling.isAmendment : false;
            this.isInitialized = true;
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    async save(): Promise<void> {
        const busyMsgId = 'saving';
        this.busyIndicatorMessageManager.add('Saving', busyMsgId);

        const updateModel: Compliance.FilingReturnUpdateModel = {
            filingId: this.filingId,
            priorReturnId: this.priorFiling && this.priorFiling.returnId,
            isAmendment: this.isAmendment
        };

        try {
            const filingBatch = await this._complianceService.createFilingBatch(updateModel);
            this.result = {
                filingBatchId: filingBatch.filingBatchId,
                companyId: filingBatch.companyId
            };

            this._bsModalRef.hide();
        } finally {
            this.busyIndicatorMessageManager.remove(busyMsgId);
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    priorReturnChanged(): void {
        this.priorReturnSelected = this.priorFiling !== null;

        if(!this.priorReturnSelected) {
            this.isAmendment = false;
        }
    }
}
