import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom } from 'rxjs';
import { ReturnSignActions } from '../../Models/enums';
import { GridOptions, GridReadyEvent, ColDef, GridApi } from 'ag-grid-community';
import { AgGridOptionsBuilder, AgGridColumns, AgGridFilterParams } from '../../../AgGrid';
import { ReturnService } from '../../return.service';
import { ReturnRepository } from '../../../Repositories';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';
import { AgGridLinkCellRendererComponent, AgGridLinkCellRendererParams } from '../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';
import { HelpService } from '../../../../UI-Lib/Help-Tooltip';
import { RETURN_SIGN_HELP } from './returnSignModal.component.help';
import { DecimalPipe } from '@angular/common';

export interface ReturnFormSignInfoRowModel extends Compliance.ReturnFormSignInfoModel {
    isError: boolean;
    isWarning: boolean;
    applicable: string;
    signerContactDisplay: string;
}

export interface ReturnSignModalResult {
    action: ReturnSignActions;
}

@Component({
    selector: 'return-sign-modal',
    templateUrl: './returnSignModal.component.html',
    styleUrls: ['./returnSignModal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ReturnSignModalComponent implements OnInit, IWeissmanModalComponent<void, ReturnSignModalResult> {
    constructor(
        private readonly _returnService: ReturnService,
        private readonly _returnRepository: ReturnRepository,
        private readonly _bsModalRef: BsModalRef,
        private readonly _helpService: HelpService,
        private readonly _decimalPipe: DecimalPipe) { }

    private _gridApi: GridApi;
    private _signInfos: ReturnFormSignInfoRowModel[];

    isValid: boolean = false;
    agGridReady: boolean = false;
    params: void;
    result: ReturnSignModalResult;
    gridId: System.Guid = "87B28C65-7868-495F-9C08-361D9477DD87";
    wetSignatureOverrideWarning: string = "";

    gridOptions: GridOptions = new AgGridOptionsBuilder()
        .withContext(this)
        .withLoadingOverlay()
        .withColumnResize()
        .withMultipleColumnSort()
        .withTextSelection()
        .build();

    async ngOnInit(): Promise<void> {
        this._helpService.setContent(RETURN_SIGN_HELP);

        const signInfos = await lastValueFrom(this._returnRepository.getReturnSignInfo(this._returnService.filingBatchId));

        this._signInfos = signInfos.map(x => ({
            ...{
                isError: this._getIsError(x.status),
                isWarning: this._getIsWarning(x.status),
                applicable: this._getApplicable(x.status),
                signerContactDisplay: x.signerContactName ? `${x.signerContactName} (${this._returnService.getRepresentationType(x.representationType)})` : ''
            } as ReturnFormSignInfoRowModel,
            ...x
        }));

        if (this._signInfos.find(x => x.status === Compliance.ReturnFormSignInfoStatusEnum.WetSignatureOverrideAllowed)) {
            this.wetSignatureOverrideWarning = "One or more jurisdictions require a wet signature.";
        }

        this.isValid = !this._signInfos.find(x => x.isError);
        this._setRowData();
    }

    onAgGridReady(event: GridReadyEvent): void {
        this._gridApi = event.api;

        const columns: ColDef[] = [
            {
                headerName: 'Parcel',
                field: 'parcelAcctNum',
                lockVisible: true,
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-parcel',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const signInfo = params.data as ReturnFormSignInfoRowModel;
                        if (!signInfo) {
                            return '';
                        }
                        return `#/parcel/${signInfo.parcelId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Form',
                field: 'formRevisionName',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Assessor',
                field: 'assessorName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-assessor',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const signInfo = params.data as ReturnFormSignInfoRowModel;
                        if (!signInfo) {
                            return '';
                        }
                        return `#/assessor/${signInfo.assessorId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Wet Signature',
                field: 'assessorWetSignatureRequired',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                valueFormatter: (params) => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    return signInfo && signInfo.assessorWetSignatureRequired ? 'Yes' : 'No';
                },
                filterValueGetter: params => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    return signInfo && signInfo.assessorWetSignatureRequired ? 'Yes' : 'No';
                },
                hide: true
            },
            {
                headerName: 'Signer',
                field: 'signerContactDisplay',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-contact',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const signInfo = params.data as ReturnFormSignInfoRowModel;
                        if (!signInfo) {
                            return '';
                        }
                        return `#/contacts/${signInfo.signerContactId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams
            },
            {
                headerName: 'Owner Signer',
                field: 'ownerContactName',
                width: AgGridColumns.textColumnWidth,
                cellRendererFramework: AgGridLinkCellRendererComponent,
                cellRendererParams: {
                    getHelpContentId: (params: AgGridLinkCellRendererParams) => 'app.view-contact',
                    newWindow: true,
                    getLink: (params: AgGridLinkCellRendererParams) => {
                        const signInfo = params.data as ReturnFormSignInfoRowModel;
                        if (!signInfo) {
                            return '';
                        }
                        return `#/contacts/${signInfo.ownerContactId}`;
                    }
                } as AgGridLinkCellRendererParams,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterWithBlankOptionsParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                hide: true
            },
            {
                headerName: '# Signature Fields',
                field: 'signatureFieldsCount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell',
                valueFormatter: (params) => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    if (!signInfo) {
                        return '';
                    }
                    return this._decimalPipe.transform(signInfo.signatureFieldsCount, '1.0-0');
                },
                filterValueGetter: params => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    if (!signInfo) {
                        return '';
                    }
                    return this._decimalPipe.transform(signInfo.signatureFieldsCount, '1.0-0');
                },
                hide: true
            },
            {
                headerName: '# Date Fields',
                field: 'dateFieldsCount',
                width: AgGridColumns.numericColumnWidth,
                filter: 'agNumberColumnFilter',
                filterParams: AgGridFilterParams.numberFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.numberFloatingFilterParams,
                cellClass: 'ag-numeric-cell',
                valueFormatter: (params) => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    if (!signInfo) {
                        return '';
                    }
                    return this._decimalPipe.transform(signInfo.dateFieldsCount, '1.0-0');
                },
                filterValueGetter: params => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    if (!signInfo) {
                        return '';
                    }
                    return this._decimalPipe.transform(signInfo.dateFieldsCount, '1.0-0');
                },
                hide: true
            },
            {
                headerName: 'Applicable',
                field: 'applicable',
                width: AgGridColumns.textColumnSmallWidth,
                filter: 'agTextColumnFilter',
                filterParams: AgGridFilterParams.textFilterParams,
                floatingFilterComponentParams: AgGridFilterParams.textFloatingFilterParams,
                cellClass: params => {
                    const signInfo = params.data as ReturnFormSignInfoRowModel;
                    return (signInfo && signInfo.isError) ? 'cell-validation-error' : (signInfo.isWarning ? 'cell-validation-warning' : null);
                }
            }
        ];

        const defaultSortModel = [
            {
                colId: 'parcelAcctNum',
                sort: 'asc'
            }
        ];

        this._gridApi.setColumnDefs(columns);
        this._gridApi.setSortModel(defaultSortModel);
        this.agGridReady = true;
        this._setRowData();
    }

    selectedActionChanged(action: ReturnSignActions): void {
        this._gridApi.redrawRows();
    }

    save(): void {
        this.result = {
            action: ReturnSignActions.Sign
        };

        this._bsModalRef.hide();
    }

    skip(): void {
        this.result = {
            action: ReturnSignActions.Skip
        };

        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _getApplicable(status: Compliance.ReturnFormSignInfoStatusEnum): string {
        switch (status) {
                case Compliance.ReturnFormSignInfoStatusEnum.NotApplicable: return 'No';
                case Compliance.ReturnFormSignInfoStatusEnum.Applicable: return 'Yes';
                case Compliance.ReturnFormSignInfoStatusEnum.DatesOnly: return 'Only Date Fields';
                case Compliance.ReturnFormSignInfoStatusEnum.InvalidSigner: return 'Invalid Signer';
                case Compliance.ReturnFormSignInfoStatusEnum.InvalidSignerSignature: return 'Missing Signature';
                case Compliance.ReturnFormSignInfoStatusEnum.NotAuthorizedSignerSignature: return 'Not Authorized';
                case Compliance.ReturnFormSignInfoStatusEnum.InvalidOwner: return 'Invalid Owner';
                case Compliance.ReturnFormSignInfoStatusEnum.InvalidOwnerSignature: return 'Missing Owner Signature';
                case Compliance.ReturnFormSignInfoStatusEnum.NotAuthorizedOwnerSignature: return 'Not Authorized Owner';
                case Compliance.ReturnFormSignInfoStatusEnum.WetSignatureOverrideAllowed: return 'Wet Signature Required';
                default: return '';
        }
    }

    private _getIsError(status: Compliance.ReturnFormSignInfoStatusEnum): boolean {
        switch (status) {
            case Compliance.ReturnFormSignInfoStatusEnum.InvalidSigner: return true;
            case Compliance.ReturnFormSignInfoStatusEnum.InvalidSignerSignature: return true;
            case Compliance.ReturnFormSignInfoStatusEnum.NotAuthorizedSignerSignature: return true;
            case Compliance.ReturnFormSignInfoStatusEnum.InvalidOwner: return true;
            case Compliance.ReturnFormSignInfoStatusEnum.InvalidOwnerSignature: return true;
            case Compliance.ReturnFormSignInfoStatusEnum.NotAuthorizedOwnerSignature: return true;
            default: return false;
        }
    }

    private _getIsWarning(status: Compliance.ReturnFormSignInfoStatusEnum): boolean {
        switch (status) {
            case Compliance.ReturnFormSignInfoStatusEnum.WetSignatureOverrideAllowed: return true;
            default: return false;
        }
    }

    private _setRowData(): void {
        if (!(this._gridApi && this._signInfos && this.agGridReady)) {
            return;
        }

        this._gridApi.setRowData(this._signInfos);
    }
}
