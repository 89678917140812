<div [hidden]="bulkUpdateInProgress" *ngIf="!isSetupInvalid" class="ws-flex-container-vertical ws-flex-auto accrual-page-height">
    <div class="entity-header">
        <ol class="breadcrumb float-start">
            <li class="breadcrumb-item">
                <a *ngIf="parentCompany" class="pointer" (click)="goToParentCompany()">{{parentCompany.name}}</a>
            </li>
            <!-- <li class="active" *ngIf="siteName">{{siteName}}</li> -->
        </ol>
        <div class="clearfix"></div>
    </div>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">Accruals</h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <div><small class="me-2">View:</small></div>
                    <div dropdown container="body" placement="bottom right" [isDisabled]="operationInProgress">
                        <button id="button-basic" dropdownToggle type="button"
                            class="ace-btn btn-link btn-sm dropdown-toggle" aria-controls="dropdown-basic">
                            {{ searchModel.mode === 2 ? 'Period Range' :
                                 (searchModel.mode === 3 ? 'Preview Close': 'Single Period') }}
                            <span class="caret"></span>
                        </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu"
                            aria-labelledby="button-basic">
                            <li role="menuitem">
                                <a class="pointer" (click)="singlePeriodSelected()">Single Period</a>
                            </li>
                            <li role="menuitem" [class.disabled]="accountingPeriods.length < 2">
                                <a class="pointer" (click)="periodRangeSelected()">Period Range</a>
                            </li>
                            <li role="menuitem" [class.disabled]="firstOpenAccountingPeriodId === null">
                                <a class="pointer" (click)="previewCloseSelected()">Preview Close</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ws-section__header__actions__item">
                    <button type="button" *ngIf="hasEditPermission && !isEditMode"
                        class="ace-btn btn-sm btn-primary has-icon" (click)="toggleEditMode(true)" title="Edit Mode">
                        <i class="fa fa-pencil"></i>
                    </button>
                    <button type="button" *ngIf="isEditMode" class="ace-btn btn-sm btn-primary btn-danger has-icon"
                        (click)="toggleEditMode(false)" title="Exit Edit Mode">
                        <i class="fa fa-close"></i>
                    </button>
                    <entity-attachments *ngIf="startAccountingPeriod" buttonClass="ace-btn btn-sm btn-primary" [isNestedModal]="false"
                        [(hasAttachments)]="startAccountingPeriod.hasAttachments" [modalData]="attachmentsModel">
                    </entity-attachments>
                    <entity-comments *ngIf="startAccountingPeriod" [(hasComments)]="startAccountingPeriod.hasComments" [isNestedModal]="false" buttonClass="ace-btn btn-sm btn-primary"
                        [modalData]="commentsModel">
                    </entity-comments>
                    <button type="button" class="ace-btn btn-sm btn-primary has-icon" (click)="refresh()" title="Refresh" [disabled]="operationInProgress">
                        <i class="fa fa-refresh"></i>
                    </button>
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" *ngIf="!isMaximized" (click)="toggleMaximize(true)">
                        <i class="fa fa-arrows-alt" title="Expand"></i>
                    </button>
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" *ngIf="isMaximized" (click)="toggleMaximize(false)">
                        <i class="fa fa-compress" title="Collapse"></i>
                    </button>
                </div>
            </div>
        </div>
        <div [class.remove-panel]="isMaximized" class="accounting-period-filters">
            <div *ngIf="startAccountingPeriod" class="ws-section__filters">
                <div class="ws-section__filters__filter-group">
                    <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                        <label class="ws-flex-none"><span *ngIf="searchModel.mode === 2">Starting</span> Accounting Period:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="startAccountingPeriod" (change)="accountingPeriodChanged()" [disabled]="operationInProgress">
                            <option *ngFor="let period of accountingPeriods" [ngValue]="period" [ngClass]="{ 'not-actual-no-hover': !period.isFrozen }" [disabled]="endAccountingPeriod && period.startDate >= endAccountingPeriod.startDate">
                                {{period.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="ws-section__filters__filter-group">
                    <label>Duration:</label>
                    {{startAccountingPeriod.periodDuration}}
                </div>
                <div class="ws-section__filters__filter-group">
                    <label>Date Range:</label>
                    <weissman-datetime [readOnly]="true" [(inputDate)]="startAccountingPeriod.startDate" [dateOnly]="true"
                        style="margin-right: 5px;">
                    </weissman-datetime>
                    - <weissman-datetime [readOnly]="true" [(inputDate)]="startAccountingPeriod.endDate" [dateOnly]="true"
                        style="margin-left: 5px;">
                    </weissman-datetime>
                </div>
                <div *ngIf="searchModel.mode !== 2 && startAccountingPeriod && startAccountingPeriod.isFrozen"
                    class="ws-section__filters__filter-group">
                    <span style="color:blue; font-size:18px; font-weight:bold;">CLOSED</span>
                </div>
            </div>
            <div *ngIf="searchModel.mode === 2 && endAccountingPeriod" class="ws-section__filters" style="margin-left: 45px;">
                <div class="ws-section__filters__filter-group">
                    <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                        <label class="ws-flex-none">Ending Accounting Period:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="endAccountingPeriod"
                            (change)="accountingPeriodChanged()" [disabled]="operationInProgress">
                            <option *ngFor="let period of accountingPeriods" [ngValue]="period" [disabled]="period.startDate <= startAccountingPeriod.startDate"
                                [ngClass]="{ 'not-actual-no-hover': !period.isFrozen }">
                                {{period.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="ws-section__filters__filter-group">
                    <label>Duration:</label>
                    {{endAccountingPeriod.periodDuration}}
                </div>
                <div class="ws-section__filters__filter-group">
                    <label>Date Range:</label>
                    <weissman-datetime [readOnly]="true" [(inputDate)]="endAccountingPeriod.startDate" [dateOnly]="true"
                        style="margin-right: 5px;">
                    </weissman-datetime>
                    - <weissman-datetime [readOnly]="true" [(inputDate)]="endAccountingPeriod.endDate" [dateOnly]="true"
                        style="margin-left: 5px;">
                    </weissman-datetime>
                </div>
            </div>
        </div>
        <div *ngIf="availableFilters && searchModel.filters" class="ws-section__filters data-filters" style="align-items: flex-start; flex-flow: row wrap; margin-top: 10px; justify-content: space-around;"
            [class.remove-panel]="isMaximized">
            <div *ngIf="availableFilters.companies?.length" class="ws-section__filters__filter-group" style="margin-bottom: 15px; padding-right: 0;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">Companies:</label>
                    <select multiple class="filter-select" (change)="companiesSelected($event.target)" [disabled]="operationInProgress">
                        <option *ngFor="let company of availableFilters.companies" [value]="company.id"
                            [selected]="searchModel.filters.companyIds.includes(company.id)"
                            [style.font-weight]="company.id === searchModel.tlCompanyId ? 'bold': 'normal'">
                            {{company.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="availableFilters.states?.length" class="ws-section__filters__filter-group" style="padding-right: 0;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">States:</label>
                    <select multiple class="filter-select" (change)="statesSelected($event.target)" [disabled]="operationInProgress">
                        <option *ngFor="let state of availableFilters.states" [value]="state.id"
                            [selected]="searchModel.filters.stateIds.includes(state.id)">{{state.name}}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="availableFilters.propertyTypes?.length" class="ws-section__filters__filter-group" style="padding-right: 0;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">Property types:</label>
                    <select multiple class="filter-select"
                        (change)="propertyTypesSelected($event.target)" [disabled]="operationInProgress">
                        <option *ngFor="let propertyType of availableFilters.propertyTypes" [value]="propertyType.id"
                            [selected]="searchModel.filters.propertyTypeIds.includes(propertyType.id)">
                            {{propertyType.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div style="display: flex;">
                <div class="ws-section__filters__filter-group summarize-by-filter">
                    <div class="ws-flex-container-horizontal ws-flex-align-items-center">
                        <label class="ws-flex-none">Summarize By:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="searchModel.filters.summarizeBy" (change)="showApplyButton = true" [disabled]="operationInProgress">
                            <option [ngValue]="AccrualsGridSummarizeByEnum.State">State</option>
                            <option [ngValue]="AccrualsGridSummarizeByEnum.Company">Company</option>
                            <option [ngValue]="AccrualsGridSummarizeByEnum.Site">Site</option>
                            <option [ngValue]="AccrualsGridSummarizeByEnum.Parcel">Parcel</option>
                            <option [ngValue]="AccrualsGridSummarizeByEnum.Bills">Bills</option>
                            <option [ngValue]="AccrualsGridSummarizeByEnum.EconomicUnit">Economic Unit</option>
                        </select>
                    </div>
                    <div *ngIf="isSummarizeByBills"
                        class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center"
                        style="margin-top: 12px;">
                        <label class="ws-flex-none">Bill Type:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="searchModel.filters.billRecurType" (change)="showApplyButton = true" [disabled]="operationInProgress">
                            <option [ngValue]="AccrualsGridBillRecurTypeEnum.Recurring">Recurring</option>
                            <option [ngValue]="AccrualsGridBillRecurTypeEnum.OneTime">One-Time</option>
                            <option [ngValue]="AccrualsGridBillRecurTypeEnum.Both">Both</option>
                        </select>
                    </div>
                </div>
                <div class="ws-section__filters__filter-group change-detection-filter">
                    <div class="checkbox" style="margin-top: 0;">
                        <label>
                            <input type="checkbox" [(ngModel)]="searchModel.filters.enableChangeDetection" (change)="showApplyButton = true"> Enable Change Detection
                        </label>
                    </div>
                    <div [hidden]="!searchModel.filters.enableChangeDetection">
                        <div>
                            <label>Variance Threshold:</label>
                        </div>
                        <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                            <input type="text"
                                class="form-control"
                                [disabled]="operationInProgress"
                                currencyMask
                                [options]="{ allowNegative: false, precision: 2, prefix: '$', suffix: '' }"
                                placeholder="$"
                                (ngModelChange)="showApplyButton = true"
                                [(ngModel)]="searchModel.filters.amountVarianceThreshold">
                            <input type="text"
                                class="form-control"
                                [disabled]="operationInProgress"
                                currencyMask
                                [options]="{ allowNegative: false, precision: 2, prefix: '', suffix: '%' }"
                                placeholder="%"
                                style="margin-left: 15px;"
                                (ngModelChange)="calculatePercentVarianceThreshold($event)"
                                [ngModel]="percentVarianceThresholdDisplay">
                        </div>
                    </div>
                </div>
                <div *ngIf="showApplyButton" class="ws-section__filters__filter-group" style="display: block;">
                    <button class="ace-btn btn-primary" [disabled]="operationInProgress || !this.accountingPeriods.length" (click)="startGridDisplay()">Apply</button>
                </div>
            </div>
        </div>
    </div>
    <ws-ag-grid-angular gridClass="accruals-page-grid"
                        rowSelection="multiple"
                        [gridOptions]="gridOptions"
                        (gridReady)="onAgGridReady($event)"
                        [gridId]="'1786B20B-C49D-43B0-B6F0-11FCA02A48B4'"
                        [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                        (bulkUpdate)="bulkUpdate()"
                        [exportOptions]="exportOptions">
    </ws-ag-grid-angular>
    <div class="lower-filters" [class.remove-panel]="isMaximized">
        <div [class.invisible]="!isEditMode || searchModel.mode === 2" style="display: flex; flex-direction: column; align-items: center;">
            <div>
                <button
                    *ngIf="startAccountingPeriod && startAccountingPeriod.accountingPeriodId === firstOpenAccountingPeriodId"
                    class="ace-btn btn-primary"
                    (click)="closeAccountingPeriod()"
                    [disabled]="operationInProgress || showApplyButton"
                >
                    Close Accounting Period
                </button>
                <button
                    *ngIf="startAccountingPeriod && startAccountingPeriod.accountingPeriodId === lastClosedAccountingPeriodId"
                    class="ace-btn btn-primary"
                    (click)="openAccountingPeriod()"
                    [disabled]="operationInProgress || showApplyButton"
                >
                    Re-open Accounting Period
                </button>
            </div>
            <div style="margin-top: 15px;">
                <button *ngIf="isExportable"
                    class="ace-btn btn-primary" (click)="createExportFile()" [disabled]="operationInProgress || showApplyButton">
                    Create Export File <i *ngIf="creatingExportFile" class="fa fa-pulse fa-spinner" style="margin-left: 5px;"></i>
                </button>
            </div>
            <div style="margin-top: 15px;">
                <button *ngIf="isExportTransmittable && startAccountingPeriod.hasAttachments"
                class="ace-btn btn-primary" (click)="transmitFile()" [disabled] = "operationInProgress || showApplyButton">
                Transmit Latest Export File
            </button>
            </div>
        </div>
        <div style="padding: 15px 0;">
            <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                <label class="ws-flex-none">Open Tax Obligations:</label>
                <select class="form-select form-select-sm" [(ngModel)]="columnFilters.openTaxObligations"
                    (change)="setColumns()" [disabled]="operationInProgress">
                    <option [ngValue]="ColumnFiltersOptionsEnum.Basics">Basics Only</option>
                    <option [ngValue]="ColumnFiltersOptionsEnum.All">Show All</option>
                    <option [ngValue]="ColumnFiltersOptionsEnum.Hide">Hide</option>
                </select>
            </div>
            <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                <label class="ws-flex-none">Period Contributions:</label>
                <select class="form-select form-select-sm" [(ngModel)]="columnFilters.periodContributions"
                    (change)="setColumns()" [disabled]="operationInProgress">
                    <option [ngValue]="ColumnFiltersOptionsEnum.Basics">Basics Only</option>
                    <option [ngValue]="ColumnFiltersOptionsEnum.All">Show All</option>
                    <option [ngValue]="ColumnFiltersOptionsEnum.Hide" [disabled]="columnFilters.changeDetection === ColumnFiltersOptionsEnum.All">Hide</option>
                </select>
            </div>
            <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                <label class="ws-flex-none">Period Accrual Balance:</label>
                <select class="form-select form-select-sm" [(ngModel)]="columnFilters.accrualBalances"
                    (change)="setColumns()" [disabled]="operationInProgress">
                    <option [ngValue]="ColumnFiltersOptionsEnum.All">Show</option>
                    <option [ngValue]="ColumnFiltersOptionsEnum.Hide">Hide</option>
                </select>
            </div>
            <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                <label class="ws-flex-none">Journal Impact:</label>
                <select class="form-select form-select-sm" [(ngModel)]="columnFilters.journalImpact"
                    (change)="setColumns()" [disabled]="operationInProgress">
                    <option [ngValue]="JournalOptionsEnum.Hide">Hide</option>
                    <option [ngValue]="JournalOptionsEnum.ShowByAccountType">Show By Account Type</option>
                    <option [ngValue]="JournalOptionsEnum.ShowByAccountNumber">Show By Account Number</option>
                </select>
            </div>
            <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                <label class="ws-flex-none">Journal Balances:</label>
                <select class="form-select form-select-sm" [(ngModel)]="columnFilters.journalBalances"
                    (change)="setColumns()" [disabled]="operationInProgress">
                    <option [ngValue]="JournalOptionsEnum.Hide">Hide</option>
                    <option [ngValue]="JournalOptionsEnum.ShowByAccountType">Show By Account Type</option>
                    <option [ngValue]="JournalOptionsEnum.ShowByAccountNumber">Show By Account Number</option>
                </select>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isSetupInvalid" class="ws-flex-container-vertical ws-flex-auto">
    <div class="entity-header">
        <ol class="breadcrumb float-start">
            <li class="breadcrumb-item">
                <a *ngIf="parentCompany" class="pointer" (click)="goToParentCompany()">{{parentCompany.name}}</a>
            </li>
            <!-- <li class="active" *ngIf="siteName">{{siteName}}</li> -->
        </ol>
        <div class="clearfix"></div>
    </div>
    <div class="ws-section">
        <h3 class="text-center">The Company has less than two (2) Fiscal Years of Accounting Periods configured</h3>
        <h3 class="text-center">OR</h3>
        <h3 class="text-center">does not have one (1) of each Accruals type G/L Accounts provisioned (Payable, Prepaid, Cash, Expense).</h3>
        <br>
        <h3 class="text-center">The Accruals Command Center cannot be accessed until this is corrected.</h3>
    </div>
</div>

<div *ngIf="bulkUpdateInProgress" class="container">
    <div [hidden]="bulkUpdateCanceled">
        <h3 *ngIf="!isBulkUpdateDone" class="text-center">Bulk Updating</h3>
        <h3 *ngIf="isBulkUpdateDone" class="text-center">Done</h3>
        <progressbar class="active"
            [class.progress-striped]="bulkUpdateCurrent.index < selectionsLength"
            [max]="selectionsLength" [value]="bulkUpdateCurrent.index" type="info">
        </progressbar>
        <h3 *ngIf="!isBulkUpdateDone">{{getCurrentBulkUpdateIdentity()}}</h3>
    </div>
    <div [hidden]="!bulkUpdateCanceled">
        <h3 class="text-center">Canceled</h3>
    </div>
    <div style="margin-top: 10px; font-size: 16px">
        <table class="bulk-update-results">
            <tr>
                <td>
                    <label>Rows Processed:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateCurrent.index - 1}}</td>
            </tr>
            <tr *ngIf="bulkUpdateTotals.rowsChanged !== null">
                <td>
                    <label>Rows Changed:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateTotals.rowsChanged}}</td>
            </tr>
            <tr *ngIf="bulkUpdateTotals.billsChanged !== null">
                <td>
                    <label>Bills Changed:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateTotals.billsChanged}}</td>
            </tr>

        </table>
    </div>
    <div class="text-center" style="margin-top: 20px;">
        <button *ngIf="bulkUpdateCanceled || isBulkUpdateDone" class="ace-btn btn-primary"
            (click)="endBulkUpdate()">OK</button>
        <button *ngIf="!bulkUpdateCanceled && !isBulkUpdateDone" class="ace-btn btn-primary btn-danger"
            (click)="bulkUpdateCanceled = true">Cancel</button>
    </div>
</div>
