<ng-template #text let-row="data" let-form="form">
    <div [formGroup]="form" [class.has-error]="row.formControl.errors?.textInvalid">
        <input type="text"
               autocomplete="off"
               class="form-control"
               [formControlName]="row.formControlName"
               [maxLength]="((row && row.validation && row.validation.maxLength) ? row.validation.maxLength : 100)" />
        <span *ngIf="row.formControl.errors?.textInvalid && (form.touched || form.dirty)" class="help-block">
            {{row.formControl.errors?.textInvalid}}
        </span>
    </div>
</ng-template>

<ng-template #number let-row="data" let-form="form">
    <div [formGroup]="form" [class.has-error]="row.formControl.errors?.numberInvalid">
        <input type="text"
               [numbersOnly]="true"
               [negativeAllowed]="!row.validation || (row.validation.minValue === null && row.validation.maxValue === null)"
               autocomplete="off"
               class="form-control"
               [formControlName]="row.formControlName"
               [maxLength]="row.validation.maxLength || 100" />
        <span *ngIf="row.formControl.errors?.numberInvalid && (form.touched || form.dirty)" class="help-block">
            {{row.formControl.errors?.numberInvalid}}
        </span>
    </div>
</ng-template>

<ng-template #currency let-row="data" let-form="form">
    <div [formGroup]="form">
        <ws-currency-input [formControlName]="row.formControlName"></ws-currency-input>
    </div>
</ng-template>

<ng-template #date let-row="data" let-form="form">
    <div [formGroup]="form" [class.has-error]="row.formControl.errors?.dateInvalid">
        <weissman-datetime class="ws-stretch-width"
                           [inputDate]="row.formControl.value"
                           (inputDateChange)="dateValueChange($event, row)"
                           [isDisabled]="row.formControl.disabled"
                           [dateOnly]="true">
        </weissman-datetime>
        <span *ngIf="row.formControl.errors?.dateInvalid && (form.touched || form.dirty)" class="help-block">
            {{row.formControl.errors?.dateInvalid}}
        </span>
    </div>
</ng-template>

<ng-template #picklist let-row="data" let-form="form">
    <div [formGroup]="form">
        <ws-select labelProperty="name"
                   valueProperty="value"
                   [options]="row.picklist"
                   [formControlName]="row.formControlName">
        </ws-select>
    </div>
</ng-template>

<ws-modal-wrapper [headerLabel]="header"
                  subHeaderLabel="Please enter the values for the fields you want updated. Blank fields will not be updated."
                  [disableSave]="!isFormValid"
                  [hideCancelX]="true"
                  (save)="save()"
                  (cancel)="cancel()">
    <loading-spinner [show]="isLoading" [size]="5"></loading-spinner>
    <form [formGroup]="bulkUpdateForm">
        <table class="table table-striped table-condensed table-borderless-body">
            <tbody>
            <tr *ngFor="let row of updateOptions">
                <td>{{row.name}}</td>
                <td>
                    <ws-bulk-update-action [requiredField]="row.isRequired" [hideField]="row.hiddenField" [removeOptionName]="row.removeOptionName" [(fieldAction)]="row.action" (fieldActionChange)="rowActionChanged(row)"></ws-bulk-update-action>
                </td>
                <td>
                    <ng-container *ngIf="row.template" [ngTemplateOutlet]="row.template" [ngTemplateOutletContext]="{ data: row, form: bulkUpdateForm }"></ng-container>
                </td>
            </tr>
            </tbody>
        </table>
    </form>
</ws-modal-wrapper>
