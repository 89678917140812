import { Injectable } from '@angular/core';
import { SDHttpService } from '../Common/Routing/sd-http.service';
import { UserService } from '../Account/user.service';
import { find } from 'lodash';
import { Team } from './team.model';
import { UserTeamMember } from '../Common/Models/common.model';

@Injectable()
export class TeamService {
    constructor(private http: SDHttpService, private readonly _userService: UserService) {}

    private _TEAM_URL = '/api/team';
	private _USER_TEAM_URL = '/api/userteam';

    getAllTeams(includeUsers: boolean): Promise<Team[]> {
        let shouldIncludeUsers = includeUsers;
        if (includeUsers === undefined) {
            shouldIncludeUsers = true;
        }
        return this.http.get(`${this._TEAM_URL}?includeUsers=${shouldIncludeUsers}`);
        //return this.http.get(this._TEAM_URL + '/all/instance/1?includeUsers=' + shouldIncludeUsers);
    }

    getAllTeamsForContact(includeUsers: boolean, instanceId: number, userId: System.Guid): Promise<Weissman.Model.Workflow.Team[]> {
        let shouldIncludeUsers = includeUsers;
        if (includeUsers === undefined) {
            shouldIncludeUsers = true;
        }
        return this.http.get(`${this._TEAM_URL}/available/${instanceId}/${userId}` + `?includeUsers=${shouldIncludeUsers}`);
        //return this.http.get(this._TEAM_URL + '/all/instance/1?includeUsers=' + shouldIncludeUsers);
    }

    editTeam(team: Team): Promise<Team> {
        return this.http.put(this._TEAM_URL, team);
    }

    deleteTeam(team: Team):Promise<void> {
        return this.http.delete(`${this._TEAM_URL}/${team.teamID}`);
    }

    createTeam(team: Team):  Promise<Team> {
        return this.http.post(this._TEAM_URL, team);
    }

    getMembersForTeam(teamID: number):Promise<UserTeamMember[]> {
        const params = {
            getFullInfo: true,
            includeCompanyRepresentative: true
        };
        return this.http.get(`${this._USER_TEAM_URL}/${teamID}/members`, { params });
    }

    getAllContactUserTeams(contactId: number, params: {
        getFullInfo: boolean;
        includeCompanyRepresentative: boolean
    }): Promise<Core.UserTeamModel[]> {
        return this.http.get(`${this._USER_TEAM_URL}/member/${contactId}`, { params });
    }

    getAssignableUserTeams(userId): Promise<Core.UserTeamModel[]> {
        return this.http.get(`${this._USER_TEAM_URL}/assignable`, {
            params: {
                getFullInfo: true,
                userId: userId
            }
        });
    }

    getAllAssignableUsers(isReassign: boolean, includeCompanyRepresentative: boolean, entityIdScope?: number, entityTypeScope?: string, instanceIdScope?: number, includeInactiveContacts?: boolean, entityIdsMultiselectScope?: number[], getDocumentIntakeUserTeamsOnly?: boolean): Promise<Core.UserTeamModel[]> {
        const params: any = {
            isAssignable: true,
            getFullInfo: true,
            includeCompanyRepresentative: includeCompanyRepresentative ?? false,
            instanceId: instanceIdScope ?? null, //because of undefined
            includeInactiveContacts: includeInactiveContacts ?? false,
            getDocumentIntakeUserTeamsOnly: getDocumentIntakeUserTeamsOnly ?? false
        };

        let entityIds:number[] = [];

        if(entityIdsMultiselectScope && entityIdsMultiselectScope.length > 0){
            entityIds = entityIdsMultiselectScope;
        }
        else if(entityIdScope) {
            if(Array.isArray(entityIdScope)) {
                entityIds = entityIdScope;
            }
            else {
                entityIds = [entityIdScope];
            }
        }

        const isForEntities = entityIds.length > 0 && !!entityTypeScope;

        if(isForEntities) {
            params.entityType =  entityTypeScope;
            return this.http.post(`${this._USER_TEAM_URL}/forentities`, entityIds, { params });
        }
        else{
            return this.http.get(this._USER_TEAM_URL, { params });
        }
    }

    getAllAssignableRyanUsers(includeCompanyRepresentative?: boolean, entityIdScope?: number, entityTypeScope?: string): Promise<Core.UserTeamModel[]> {
        includeCompanyRepresentative = includeCompanyRepresentative ?? false;

        const params = {
            isAssignable: true,
            getFullInfo: true,
            includeCompanyRepresentative: includeCompanyRepresentative,
            entityId: entityIdScope,
            entityType: entityTypeScope
        };

        //NOTE: if it is not a reassignment, the base this._USER_TEAM_URL is called and it MUST be single instance
        //For now will always call the forryanonly - otherwise the selected instance would be in effect if the base url is called and this method name does not make sense
        //const url = isReassign ? this._USER_TEAM_URL + '/forryanonly' : this._USER_TEAM_URL;
        const url = `${this._USER_TEAM_URL  }/forryanonly`;

        return this.http.get(url, { params });
    }

    getUserDefaultTeam(): Promise<Core.UserTeamModel> {
        const user = this._userService.getUser();

        const filter = {
            userId: user.id,
            getFullInfo: false
        };

        return this._getAllUserTeams(filter)
            .then((result) => {
                const defaultTeam = find(result, { isDefault: true }) as any;

                if (defaultTeam) {
                    return {
                        userID: user.id,
                        teamID: defaultTeam.teamID
                    } as unknown as Core.UserTeamModel;
                } else {
                    console.warn('user does not have default team for some reason');
                    return {} as Core.UserTeamModel;
                }

            });
    }

    hasLinkedEntities(userId: System.Guid, teamId: number): Promise<{hasLinkedEntities: boolean; message: string}> {
        const params = { userId, teamId };

        return this.http.get(`${this._USER_TEAM_URL  }/haslinkedentities`, { params });
    }

    saveTeamAssignmentTab(teams: Core.UserTeamModel[]): Promise<void> {
        return this.http.put(this._USER_TEAM_URL, teams);
    }

    private _getAllUserTeams(params: {userId: System.Guid, getFullInfo: boolean}): Promise<Core.UserTeamModel[]> {
        return this.http.get(this._USER_TEAM_URL, { params });
    }
}
