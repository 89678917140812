import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { EntityClientService, ComplianceDeliveryDetail } from '../../clientServices.model';
import { EntityTypeIds, EmptyGuid } from 'src/app/constants.new';
import { RestrictService, InstanceRights } from 'src/app/Common/Permissions/restrict.service';
import * as _ from 'lodash';
import { CompanyEntity } from 'src/app/Entity/entity.model';
import { TeamService } from 'src/app/Team/team.service';

interface DRUserType {
    name: string;
    prefix: string;
    required: boolean;
}

@Component({
    selector: 'delivery-details-compliance-returns',
    templateUrl: './deliveryDetailsComplianceReturns.component.html'
})
export class DeliveryDetailsComplianceReturnsComponent implements OnInit, OnChanges {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _teamService: TeamService
    ) {}

    @Input() editMode: boolean;
    @Input() entity: CompanyEntity;
    @Input() currentData: ComplianceDeliveryDetail;
    @Input() service: EntityClientService;
    @Input() ces: Core.ConsultingEngagementResponse[];
    @Input() entityInstanceId: number;
    @Input() isConsultantPerspective: boolean;
    @Input() userSearchInstanceId: number;

    @Output() currentDataChange: EventEmitter<any> = new EventEmitter();

    readonly DR_USERS: DRUserType[] = [
        {
            name: 'Batch Data Loader',
            prefix: 'batchDataLoader',
            required: false
        },
        {
            name: 'Batch Data Preparer',
            prefix: 'batchPreparer',
            required: true
        },
        {
            name: 'Batch Data Reviewer',
            prefix: 'batchReviewer',
            required: true
        },
        {
            name: 'Batch Approver',
            prefix: 'batchApprover',
            required: false
        },
        {
            name: 'Batch Processor',
            prefix: 'batchProcessor',
            required: true
        }
    ];

    EntityTypeIds = EntityTypeIds;
    hasCompliancePermission: boolean = false;
    users: Core.UserTeamModel[] = null;

    ngOnInit() {
        this.hasCompliancePermission = this._restrictService.hasInstanceRight(InstanceRights.COMPLIANCEFEATURESVIEW, this.entityInstanceId);

        this.currentData = this.currentData || {} as ComplianceDeliveryDetail;

        if (this.entity.typeId !== this.EntityTypeIds.COMPANY) {
            this.currentData.ppReturnPreparationAllowed = this.currentData.ppReturnPreparationAllowed || false;
        }
    }

    async ngOnChanges() {
        if (this.editMode && !this.users) {
            this.users = await this._teamService.getAllAssignableUsers(false, false, this.entity.id, this.entity.type, this.userSearchInstanceId);
        }
    }

    maybePopulateBatchUsers(): void {
        // if switching from off to on
        // and ALL data is blank
        // and compliance returns reponsibility set to real user
        // copy existing single user to all 5 users

        const ppReturnPreparationAllowed = this.entity.typeId === EntityTypeIds.COMPANY ? this.entity.ppReturnPreparationAllowed : this.currentData.ppReturnPreparationAllowed;

        if (ppReturnPreparationAllowed && _.every(this.DR_USERS, this._isUserEmpty.bind(this))) {
            const personalResponsibility = _.find(this.service.responsibilities, {propertyTypeID: Core.PropertyTypes.Personal});

            if (!personalResponsibility.assignee || !personalResponsibility.team)
                return;

            _.forEach(this.DR_USERS, drUserType => {
                if (personalResponsibility) {
                    this.currentData[`${drUserType.prefix  }User`] = _.cloneDeep(personalResponsibility.assignee);
                    this.currentData[`${drUserType.prefix  }Team`] = _.cloneDeep(personalResponsibility.team);
                    this.currentData[`${drUserType.prefix  }UserId`] = personalResponsibility.userID;
                    this.currentData[`${drUserType.prefix  }TeamId`] = personalResponsibility.teamID;
                }
                else {
                    this._removeUser(drUserType.prefix);
                }
            });

            this.currentDataChange.emit(this.currentData);
        }
    }

    userChanged(newUser: Core.UserTeamModel, userTypePrefix: string): void {
        if (!newUser) {
            this._removeUser(userTypePrefix);
        } else {
            this.currentData[`${userTypePrefix  }UserId`] = newUser.userID;
            this.currentData[`${userTypePrefix  }TeamId`] = newUser.teamID;

            this.currentData[`${userTypePrefix  }Team`] = {
                name: newUser.teamName,
                teamID: newUser.teamID
            } as Weissman.Model.Workflow.Team;
            this.currentData[`${userTypePrefix  }User`] = {
                efAction: null,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                userID: newUser.userID
            };
        }

        this.currentDataChange.emit(this.currentData);
    }

    getDisplayName(user: Core.ContactShortDTO, team: Weissman.Model.Workflow.Team): string {
        //consultant person assigned - show consulting company name if not consultant viewing
        if (team.instanceID !== this.entityInstanceId && this.isConsultantPerspective === false) {
            //look up consultant's name
            const consultantName = this.ces.find(ce => ce.instanceId === team.instanceID)?.name;
            return consultantName ? `Consultant- ${consultantName}` : '';
        }
        //client person assigned - show person's name
        else {
            return `${user.lastName}, ${user.firstName} (${team.name})`;
        }
    }

    private _removeUser(userTypePrefix: string): void {
        this.currentData[`${userTypePrefix  }User`] = {};
        this.currentData[`${userTypePrefix  }Team`] = {};

        if (userTypePrefix == 'batchDataLoader' || userTypePrefix == 'batchApprover') {
            this.currentData[`${userTypePrefix  }UserId`] = null;
            this.currentData[`${userTypePrefix  }TeamId`] = null;
        }
        else {
            this.currentData[`${userTypePrefix  }UserId`] = 0;
            this.currentData[`${userTypePrefix  }TeamId`] = 0;
        }
    }

    private _isUserEmpty(drUserType: DRUserType): boolean {
        if ((this.currentData[`${drUserType.prefix  }UserId`]) && (this.currentData[`${drUserType.prefix  }UserId`] != EmptyGuid))
            return false;
        if (this.currentData[`${drUserType.prefix  }TeamId`])
            return false;
        if (!_.isEmpty(this.currentData[`${drUserType.prefix  }User`]))
            return false;
        if (!_.isEmpty(this.currentData[`${drUserType.prefix  }Team`]))
            return false;

        // all properties are empty
        return true;
    }
}
