import { Component, OnInit } from '@angular/core';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { StateService } from '../../Common/States/States.Service';
import { AssessorCollectorService } from '../assessor.collector.service';
import * as _ from 'lodash';
import { Assessor } from '../assessor.model';
import { ToastrService } from 'ngx-toastr';
import { ContactPanelEntityData } from 'src/app/State/us.state.component';

@Component({
    selector: 'assessor',
    templateUrl: './assessor.component.html'
})
export class AssessorComponent implements OnInit {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _stateService: StateService,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _toastr: ToastrService
    ) { }

    breadcrumbs: BreadCrumb[] = [];
    state: Weissman.Model.Misc.State;
    assessor: Assessor;
    assessorForInfo: Assessor;
    entity: ContactPanelEntityData;

    isTaxRatesMaximized: boolean = false;

    async ngOnInit(): Promise<void> {
        await this._loadData();

        this.assessorForInfo = _.cloneDeep(this.assessor);

        this.entity = {
            id: this.assessor.assessorID,
            type: 'assessor',
            newAllowed: false,
            name: this.assessor.name
        };

        this.breadcrumbs = [
            {
                name: 'States',
                target: 'states',
                options: {}
            },
            {
                name: this.state.fullName,
                target: 'state',
                options: { stateId: this.state.stateID }
            },
            {
                name: 'Assessors',
                target: 'assessors',
                options: { stateId: this.state.stateID }
            }
        ];
    }

    assessorChanged(assessor): void {
        _.assign(this.assessor, assessor);
    }

    toggleTaxRatesMaximize(e: any): void {
        this.isTaxRatesMaximized = e as unknown as boolean;
    }

    private async _loadData(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });

        const stateIDErrMsg = 'State ID does not match assessor';
        try {
            const stateId = +this._upgradeNavigationServiceHandler.getQuerystringParam('stateId');
            const assessorId = +this._upgradeNavigationServiceHandler.getQuerystringParam('id');

            [this.state, this.assessor] = await Promise.all([
                this._stateService.getById(stateId),
                this._assessorCollectorService.getAssessor(assessorId)
            ]);

            if (this.assessor.stateID != stateId) {
                throw new Error(stateIDErrMsg);
            }
        }
        catch(e) {
            if(e.message === stateIDErrMsg) {
                this._toastr.error(e.message);
            }
        }
        finally {
            busyRef.hide();
        }
    }
}
