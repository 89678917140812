import { efAction } from '../constants.new';

export class Attachment {
    attachmentID: string;
    externalID: string;
    entityID: number;
    entityTypeID: number;
    fileName: string;
    fileExtension: string;
    description: string;
    year: number;
    attachmentCategoryID: number;
    attachmentCategory: string;
    attachmentTypeID: number;
    attachmentType: Core.AttachmentTypeModel;
    size: number;
    numberOfPages: number;
    metaData: string;
    entityName: string;
    parentEntityName: string;
    createdBy: string;
    createdByFirstName: string;
    createdByLastName: string;
    createdDate: Date;
    efAction: efAction;
    externalFileLocationId: number;
    relativeFilePath: string;
    rowVersion: string;
}

export interface AttachmentCategory {
    attachmentCategoryID: number;
    category: string;
    efAction: efAction;
}

export class AttachmentInfo {
    constructor(newEntityTypeID, newAttachmentID, newEntityID, newFileName, newFileExt, size) {
        this.entityTypeID = newEntityTypeID;
        this.attachmentID = newAttachmentID;
        this.entityID = newEntityID;
        this.fileName = newFileName;
        this.fileExtension = newFileExt;
        this.size = size;
    }

    entityTypeID: number;
    attachmentID: string;
    entityID: number;
    fileName: string;
    fileExtension: string;
    size: number;
}

// This enumeration should probably be defined in some sort of global constant system
// Also note, this is not all attachment types, just the ones we have special logic for in the UI
export enum AttachmentTypeID {
    AppealApplication = 1,
    AppealDecision = 2,
    AppealDocumentExchange = 3,
    Refund = 8,
    TaxBill = 9,
    TaxReceipt = 11,
    PPReturn = 23,
    ValueNotice = 27,
    HearingNotice = 28,
    MiscFiling = 49,
    AbatementApplication = 54,
    FreeportApplication = 55,
    PollutionControlApplication = 56,
    AppealConfirmation = 64
}

export interface MimeTypeExtension {
    type: string;
    exts: string[];
    previewSupported: boolean;
}
