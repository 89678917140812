import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HybridAdapterUtility } from '../../Hybrid/hybrid-adapter.utility';
import { AgGridModule } from 'ag-grid-angular';
import { WeissmanSharedModule } from '../../Common/weissman-shared.module';
import { SharedPipesModule } from '../../UI-Lib/Pipes/shared-pipes.module';
import { AgGridLoadingOverlayComponent } from '../../Common/AgGrid/agGridLoadingOverlay.component';
import { BusyIndicatorModule } from '../../Busy-Indicator';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AgGridExtensionsModule } from '../../Compliance/AgGrid/agGridExtensions.module';
import { QueryInfoListComponent } from './Query-Info-List/queryInfoList.component';
import { QueryInfoService } from './queryInfo.service';
import { QueryInfoListGridActionCellRendererComponent } from './Query-Info-List/agGridActionCellRenderer.component';
import { QueryInfoDetailsComponent } from './Query-Info-Details/queryInfoDetails.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        WeissmanSharedModule,
        SharedPipesModule,
        BusyIndicatorModule.forRoot(),
        AgGridExtensionsModule,
        AgGridModule.withComponents([
            // shared
            AgGridLoadingOverlayComponent,
            QueryInfoListGridActionCellRendererComponent
        ])
    ],
    declarations: [
        QueryInfoListComponent,
        QueryInfoListGridActionCellRendererComponent,
        QueryInfoDetailsComponent
    ],
    providers: [
        QueryInfoService
    ],
    exports: []
})
export class QueryInfoModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('queryInfoList', QueryInfoListComponent);
        hybridAdapterUtility.downgradeNg2Component('queryInfoDetails', QueryInfoDetailsComponent);
    }
}
