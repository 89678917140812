import { Component, Input, OnInit } from '@angular/core';
import { ReportDetail } from '../report.manager.model';
import { ReportManagerModalInputParameters } from '../reportManagerModal.component';
import { ReturnAssetFilter } from '../../../Compliance/Return/Return-Parts/Assets/Asset-Status-Selector/returnAssetStatusSelector.component';

@Component({
    selector: 'further-limit-panel-asset-detail',
    templateUrl: './further.limit.panel.asset.detail.component.html'
})
export class FurtherLimitPanelAssetDetailComponent implements OnInit{

    @Input() report: ReportDetail;
    @Input() modalParameters?: ReportManagerModalInputParameters;

    formRevisions: Compliance.ReturnFormRevisionModel[] = [];
    private _scheduleFilters: Compliance.FormRevisionScheduleModel[] = [
        {
            formRevisionScheduleId: Compliance.ReportScheduleFilterTypeEnum.AllReportableTaxable,
            name: 'All Reportable, Taxable'
        },
        {
            formRevisionScheduleId: Compliance.ReportScheduleFilterTypeEnum.AllReportableNotTaxable,
            name: 'All Reportable, Not Taxable'
        },
        {
            formRevisionScheduleId: Compliance.ReportScheduleFilterTypeEnum.AllReportableTaxableNotTaxable,
            name: 'All Reportable, Taxable and Not Taxable'
        },
        {
            formRevisionScheduleId: Compliance.ReportScheduleFilterTypeEnum.AllNotReportableSchedules,
            name: 'All Not Reportable Schedules'
        },
        {
            formRevisionScheduleId: Compliance.ReportScheduleFilterTypeEnum.OnlyReportableNotTaxableSchedule,
            name: 'Only Reportable/Not Taxable Schedule'
        }
    ] as Compliance.FormRevisionScheduleModel[];
    private _schedules: Compliance.FormRevisionScheduleModel[] = [];

    async ngOnInit(): Promise<void> {
        if (this.modalParameters) {
            if (this.modalParameters.formRevisions) {
                this.formRevisions = this.modalParameters.formRevisions;
            }

            if (this.modalParameters.schedules) {
                this.modalParameters.schedules.forEach(item => this._schedules.push(item));
            }
        }

        if (this.report.criteria.scheduleId) {
            const selectedSchedule = this._schedules.find(i => i.formRevisionScheduleId === this.report.criteria.scheduleId);
            this.report.criteria.scheduleName = selectedSchedule ? selectedSchedule.name : null;
        }

        if (!this.report.criteria.formRevisionId) {
            this.report.criteria.formRevisionId = null;
        }
    }

    get isSystemControlled(): boolean {
        return this.report.isSystemReport;
    }

    get schedules(): Compliance.FormRevisionScheduleModel[] {
        return this.report.criteria.formRevisionId
            ? this._schedules.filter(x => (this.report.criteria.formRevisionId && x.formRevisionId === this.report.criteria.formRevisionId))
            : this._scheduleFilters;
    }

    get priorReturnStatuses(): Compliance.ReturnAssetPriorReturnStatusEnum[]{
        return this.report.criteria.priorReturnStatuses;
    }

    set priorReturnStatuses(model: Compliance.ReturnAssetPriorReturnStatusEnum[]) {
        this.report.criteria.priorReturnStatuses = model;
    }

    get scheduleId(): number {
        return this.report.criteria.formRevisionId ? this.report.criteria.scheduleId : this.report.criteria.scheduleFilterType;
    }

    assignScheduleId(value: number) {
        let selectedSchedule: Compliance.FormRevisionScheduleModel;
        if (this.report.criteria.formRevisionId) {
            this.report.criteria.scheduleId = value;
            this.report.criteria.scheduleFilterType = Compliance.ReportScheduleFilterTypeEnum.Unrestricted;
            selectedSchedule = this._schedules.find(i => i.formRevisionScheduleId === value);
        } else {
            this.report.criteria.scheduleFilterType = value;
            this.report.criteria.scheduleId = null;
            selectedSchedule = this._scheduleFilters.find(i => i.formRevisionScheduleId === value);
        }
        this.report.criteria.scheduleName = selectedSchedule ? selectedSchedule.name : null;
    }

    onSelectedAssetStatusFilterChange(filter: ReturnAssetFilter): void{
        this.report.criteria.priorReturnStatusName = filter.name;
        this.report.criteria.priorReturnStatuses = filter.statuses;
    }

    onFormRevisionChange(revisionId: any): void {
        this.report.criteria.formRevisionId = revisionId;
        this.assignScheduleId(this.report.criteria.formRevisionId ? this.schedules[0].formRevisionScheduleId : this._scheduleFilters[0].formRevisionId);
    }
}
