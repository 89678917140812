<div class="ag-grid-tool-panel-wrapper">
    <div class="ag-grid-tool-panel" [hidden]="!isExpanded" *ngIf="gridReady && showConfigureLayout">
        <ag-grid-layout-specification [gridId]="gridId"
                                      [companyId]="companyId"
                                      [gridOptions]="gridOptions"
                                      [defaultColorizeHeaders]="defaultColorizeHeaders"
                                      [displayedColumnsChanged$]="displayedColumnsChanged$"
                                      (loadComplete)="specificationLoadComplete()"
                                      (specificationChanged)="specificationChanged($event)">
        </ag-grid-layout-specification>
        <ag-grid-tool-panel-columns [gridOptions]="gridOptions"
                                    [specification]="specification"
                                    [gridId]="gridId"
                                    [defaultColorizeHeaders]="defaultColorizeHeaders"
                                    [displayedColumnsChanged$]="displayedColumnsChanged$">
        </ag-grid-tool-panel-columns>
    </div>
    <div class="ag-grid-tool-panel-toggle ws-flex-container-vertical ws-flex-none">
        <div class="action-button-wrapper"
             helpTooltip
             [helpContentId]="configureLayoutHelpContentId"
             position="left">
            <button *ngIf="showConfigureLayout"
                    class="btn btn-xs btn-link"
                    [ngClass]="{'active': isExpanded }"
                    [disabled]="!isInitialized"
                    (click)="onExpandClick()">
                <div class="button-content">
                    <i class="fa fa-wrench"></i>
                </div>
            </button>
        </div>
        <div class="action-button-wrapper"
             helpTooltip
             [helpContentId]="filtersHelpContentId"
             position="left">
            <button class="btn btn-xs btn-link"
                    [ngClass]="{'active': isFilterVisible }"
                    [disabled]="!isInitialized"
                    (click)="onFilterClick()">
                <div class="button-content">
                    <i class="fa fa-filter"></i>
                </div>
            </button>
        </div>
        <div class="action-button-wrapper"
             helpTooltip
             helpContentId="ag-grid-tool-panel.clearFiltersHelpContentId"
             position="left">
            <button class="btn btn-xs btn-link"
                    [disabled]="(!isFiltered) || (!isInitialized)"
                    (click)="onClearFiltersClick()">
                <div class="button-content">
                    <i class="fa fa-undo"></i>
                </div>
            </button>
        </div>
        <div class="action-button-wrapper"
             clickOutside
             (clickOutside)="hideFileFormatSelection()">
            <div helpTooltip
                 [helpContentId]="exportHelpContentId"
                 [position]="((!disableExport && exportOptions && exportOptions.showFileFormatSelection) ? 'top' : 'left')">
                <button *ngIf="showExport"
                        class="btn btn-xs btn-link"
                        (click)="onExportClick()"
                        [disabled]="disableExport || !isInitialized">
                    <div class="button-content">
                        <i class="fa" [ngClass]="exportIcon"></i>
                    </div>
                </button>
            </div>
            <div class="action-submenu" [class.open]="showFileFormatSelection">
                <ul>
                    <li (click)="onExportOptionSelected(fileFormats.excel)"
                        helpTooltip
                        helpContentId="ag-grid-tool-panel.export-excel"
                        position="left"
                        *ngIf="showFileFormat(fileFormats.excel)">Excel</li>
                    <li (click)="onExportOptionSelected(fileFormats.tabDelimited)"
                        helpTooltip
                        helpContentId="ag-grid-tool-panel.export-tab-delimited"
                        position="left"
                        *ngIf="showFileFormat(fileFormats.tabDelimited)">Tab Delimited</li>
                    <li (click)="onExportOptionSelected(fileFormats.pdf)"
                        helpTooltip
                        helpContentId="ag-grid-tool-panel.export-pdf"
                        position="left"
                        *ngIf="showFileFormat(fileFormats.pdf)">PDF</li>
                    <li *ngFor="let t of exportOptions?.customExportFileFormats"
                        (click)="!t.isDisabled() && onCustomExportOptionSelected(t.value)"
                        position="left"
                        [ngClass]="{'disabled': t.isDisabled()}"
                        [title]="t.isDisabled() ? (t.disabledTooltip || '') : ''">{{t.name}}</li>
                </ul>
            </div>
        </div>
        <div class="action-button-wrapper"
             helpTooltip
             helpContentId="ag-grid-tool-panel.size-to-fit"
             position="left">
            <button *ngIf="showResizeColumns"
                    class="btn btn-xs btn-link"
                    [disabled]="!isInitialized"
                    (click)="sizeToFit()">
                <div class="button-content">
                    <i class="fa fa-arrows-h"></i>
                </div>
            </button>
        </div>
        <div class="action-button-wrapper"
             helpTooltip
             helpContentId="ag-grid-tool-panel.size-to-content"
             position="left">
            <button *ngIf="showResizeColumns"
                    class="btn btn-xs btn-link"
                    [disabled]="!isInitialized"
                    (click)="sizeToContent()">
                <div class="button-content">
                    <i class="fa fa-text-width"></i>
                </div>
            </button>
        </div>
        <div class="action-button-wrapper"
             helpTooltip
             [helpContentId]="bulkUpdateButtonHelpId"
             position="left">
            <div *ngIf="showBulkUpdate && showBulkUpdateLoading" class="ace-btn btn-xs">
                <div class="button-content">
                    <i class="fa fa-spinner fa-pulse"></i>
                </div>
            </div>
            <button *ngIf="showBulkUpdate && !showBulkUpdateLoading"
                    class="btn btn-xs btn-link"
                    [disabled]="!isInitialized || disableBulkUpdate"
                    (click)="onBulkUpdateClick()">
                <div class="button-content">
                    <i class="fa fa-edit"></i>
                </div>
            </button>
        </div>
        <div class="action-button-wrapper"
             helpTooltip
             helpContentId="ag-grid-tool-panel.bulk-delete"
             position="left">
            <button *ngIf="showBulkDelete"
                    class="btn btn-xs btn-link"
                    [disabled]="!isInitialized"
                    (click)="onBulkDeleteClick()">
                <div class="button-content">
                    <i class="fa fa-trash"></i>
                </div>
            </button>
        </div>
        <div *ngIf="additionalActions && additionalActions.length"
             class="action-button-wrapper"
             clickOutside
             (clickOutside)="hideAdditionalActions()">
            <div helpTooltip
                 helpContentId="ag-grid-tool-panel.additional-actions"
                 position="top">
                <button class="btn btn-xs btn-link"
                        [ngClass]="additionalActionsVisible ? 'highlight' : 'd-none'"
                        (click)="onAdditionalActionClick()">
                    <div class="button-content">
                        <i class="fa fa-tasks"></i>
                    </div>
                </button>
            </div>
            <div class="action-submenu" [class.open]="showAdditionalActions">
                <ul>
                    <li *ngFor="let action of additionalActions"
                        (click)="onAdditionalActionSelected(action)"
                        [class.disabled]="action.disabled()"
                        helpTooltip
                        [helpContentId]="action.helpContentId"
                        position="left">{{action.name}}</li>
                </ul>
            </div>
        </div>
        <div class="action-button-wrapper"
             [title]="button.getTooltipText()"
             *ngFor="let button of additionalButtons">
            <ng-container *ngIf="!button.isAsync">
                <button class="btn btn-xs btn-link"
                        [disabled]="!isInitialized || button.disabled()"
                        (click)="button.onClickCallback()">
                    <div class="button-content">
                        <i class="fa {{button.icon}}"></i>
                    </div>
                </button>
            </ng-container>
            <ng-container *ngIf="button.isAsync">
                <button class="btn btn-xs btn-link"
                        [disabled]="!isInitialized || button.disabled()"
                        (click)="button.onClickCallback()">
                    <div class="button-content">
                        <i class="fa {{button.isLoading() ? 'fa-spinner fa-pulse' : button.icon}}"></i>
                    </div>
                </button>
                <div class="action-submenu"
                     [class.open]="button.isMenuOpen"
                     clickOutside
                     (onClickOutside)="button.isMenuOpen = false;">
                    <ul>
                        <li *ngFor="let action of button.menuItems()"
                            (click)="button.isMenuOpen = false; action.onClick();">
                            {{action.name}}
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
</div>
