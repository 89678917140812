import { Pipe, PipeTransform } from '@angular/core';

import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

    transform(value: any[], order = '', column: string | string[] | any = ''): any[] {
        if (!value || order === '' || !order) { return value; } // no array

        if (value.length <= 1) {  // array with only one item
            return value;
        }

        if (!column || column === '') {
            if(order==='asc'){
                return value.sort();
            }
            else{
                return value.sort().reverse();
            }
        } // sort 1d array

        if (typeof column === 'string') {
            return orderBy(value, [column], [order]);
        } else if (Array.isArray(column)) {
            return orderBy(value, column, [order]);
        } else if (typeof column === 'function') {
            return value.sort((a, b) => {
                const colA = column(a);
                const colB = column(b);
                if (typeof colA === 'number') {
                    return `${colA}`.localeCompare(`${colB}`, undefined, { numeric: true });
                } else {
                    return colA.localeCompare(colB);
                }
            });
        }
    }
}
