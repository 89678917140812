import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { AnnualDetailYear } from '../Annual-Year/annual-year.model';
import { CommentModalData } from '../../Comments/comments.service';
import { AttachmentModalData, AttachmentModalEntityData } from '../../Attachment/attachment.modal.model';
import * as _ from 'lodash';
import { EntityType } from '../../constants.new';

@Injectable()
export class AnnualBudgetService {
    constructor(private http: HttpClient) {
    }


    // CRUD Operations
    getAnnualBudget(annualBudgetId: number): Promise<Core.AnnualBudgetDTO> {
        return lastValueFrom(this.http.get<Core.AnnualBudgetDTO>(`/api/AnnualBudget/${  annualBudgetId}`));
    }
    updateAnnualBudget(annualBudget: Core.AnnualBudgetDTO): Promise<Core.AnnualBudgetDTO> {
        return lastValueFrom(this.http.put<Core.AnnualBudgetDTO>('/api/AnnualBudget', annualBudget));
    }

    deleteAnnualBudget(annualBudgetId: number): Promise<void> {
        return lastValueFrom(this.http.delete<void>(`/api/AnnualBudget/${annualBudgetId}`));
    }

    addAnnualBudget(parcelId: number, year: number, companyBudgetId: number): Promise<Core.AnnualBudgetDTO> {
        return lastValueFrom(this.http.post<Core.AnnualBudgetDTO>('/api/AnnualBudget/', {
            parcelID: parcelId,
            taxYear: year,
            companyBudgetId: companyBudgetId
        }));
    }

    getAvailableBudgets(year: number, parcelId: number): Promise<Core.AvailableAnnualBudget[]> {
        return lastValueFrom(this.http.get<Core.AvailableAnnualBudget[]>(`api/AnnualBudget/Available/Parcel/${parcelId}/Year/${year}`));
    }

    getAvailableCompanyBudgets(parcelId: number): Promise<[Core.AvailableCompanyBudget]> {
        return lastValueFrom(this.http.get<[Core.AvailableCompanyBudget]>(`api/CompanyBudget/Available/${EntityType.Parcel}/${parcelId}?excludeFrozen=true`));
    }


    saveAnnualBudgetFromViewModel(viewModel: AnnualBudgetViewModel): Promise<Core.AnnualBudgetDTO> {
        return this.updateAnnualBudget(viewModel.model);
    }

    cancelBudgetEdit(viewModel: AnnualBudgetViewModel): void {
        viewModel.cancelEdit();
    }

    toggleAnnualBudgetEdit(viewModel: AnnualBudgetViewModel, editMode: boolean): void {
        if (editMode) {
            viewModel.beginEdit();
        }
    }

    async getAnnualBudgetViewModelByID(annualYearModel: AnnualDetailYear, annualBudgetId: number, parcelID: number, parcelAcctNum: string): Promise<AnnualBudgetViewModel> {
        let viewModel: AnnualBudgetViewModel;

        if(annualBudgetId > 0) {
            const annualBudget = await this.getAnnualBudget(annualBudgetId);
            viewModel = this.buildAnnualBudgetViewModel(annualBudget);
        } else {
            viewModel = new AnnualBudgetViewModel();
        }

        viewModel.parcelID = parcelID;
        viewModel.parcelAcctNum = parcelAcctNum;
        viewModel.annualYearModel = annualYearModel;

        return viewModel;
    }

    private buildAnnualBudgetViewModel(annualBudget: Core.AnnualBudgetDTO) {
        const viewModel = new AnnualBudgetViewModel();
        viewModel.model = annualBudget;

        return viewModel;
    }
}


export class AnnualBudgetViewModel {
    constructor() {
        // This will be set by the appeal component when loaded
        this.hasWritePermission = false;
    }

    model: Core.AnnualBudgetDTO;
    annualYearModel: AnnualDetailYear;
    parcelID: number;
    parcelAcctNum: string;
    commentModalData: CommentModalData;
    attachmentModalData: AttachmentModalData;
    hasWritePermission: boolean;
    private preEditModelBackup: Core.AnnualBudgetDTO;


    resetEdit(force: boolean = false): void {
        if (force || (this.model &&
                this.preEditModelBackup &&
                this.model.annualBudgetId !== this.preEditModelBackup.annualBudgetId)) {
            this.preEditModelBackup = _.cloneDeep(this.model);
            console.log(['Creating preEditModelBackup', this.preEditModelBackup]);
        }
    }

    cancelEdit(): void {
        if (this.model.annualBudgetId === this.preEditModelBackup.annualBudgetId) {
            _.assign(this.model, this.preEditModelBackup);
        }
        else {
            throw new Error(`Sanity check failed; attempted to restore backup with different annual budget Id than current. Current annual budget Id: ${
                this.model.annualBudgetId  }, backup annual budget Id: + ${  this.preEditModelBackup.annualBudgetId}`);
        }
    }

    beginEdit(): void {
        if (this.model) {
            this.preEditModelBackup = _.cloneDeep(this.model);
        }
        else {
            this.preEditModelBackup = undefined;
        }
    }

    assignFromExistingFiling(annualBudget: Core.AnnualBudgetDTO): void {
        if (!this.model) {
            throw new Error('Invalid attempt to load annualBudget from existing data when no current annualBudget is set');
        }

        if (this.model.annualBudgetId !== annualBudget.annualBudgetId) {
            throw new Error(`Invalid attempt to annualBudget annualBudget with annualBudgetId ${  annualBudget.annualBudgetId
                } into annualBudget with annualBudgetId ${  this.model.annualBudgetId}`);
        }

        _.assign(this.model, annualBudget);
    }

    setParcelDetails(parcelID: number, parcelAcctNum: string): void {
        this.parcelID = parcelID;
        this.parcelAcctNum = parcelAcctNum;
        this.updateModalData();
    }

    updateModalData(): void {
        const budget = this.model;

        if (budget) {
            // let filingType = _.find(this.filingTypes, {filingTypeId: budget.filingTypeId}) || {};
            const name = `${budget.budgetName  } Budget`;
            let description = name;
            if(budget.description) {
                description += ` - ${budget.description}`;
            }

            // comment data
            this.commentModalData = new CommentModalData();
            this.commentModalData.entityID = budget.annualBudgetId;
            this.commentModalData.entityTypeID = EntityType.Budget;
            this.commentModalData.canEdit = this.hasWritePermission;
            this.commentModalData.parcelID = this.parcelID;
            this.commentModalData.parcelAcctNum = this.parcelAcctNum;
            this.commentModalData.description = description;
            this.commentModalData.year = this.annualYearModel.annualYear1.toString();

            // attachment data
            this.attachmentModalData = new AttachmentModalData();
            this.attachmentModalData.belowParcelEntity = new AttachmentModalEntityData();
            this.attachmentModalData.belowParcelEntity.id = budget.annualBudgetId;
            this.attachmentModalData.belowParcelEntity.typeId = EntityType.Budget;
            this.attachmentModalData.belowParcelEntity.name = name;
            this.attachmentModalData.entityType = 'Budget';
            this.attachmentModalData.parentId = this.parcelID;
            this.attachmentModalData.parentType = 'Parcel';
            this.attachmentModalData.entityName = this.parcelAcctNum;
            this.attachmentModalData.entityDescription = description;
            this.attachmentModalData.year = this.annualYearModel.annualYear1;
            this.attachmentModalData.readOnly = this.hasWritePermission;
        }
    }

    validate(callback: (boolean, string) => void): void {
        callback(true, '');
    }
}
