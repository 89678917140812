<div [hidden]="bulkUpdateInProgress" class="ws-flex-container-vertical ws-flex-auto">
    <div class="entity-header">
        <ol class="breadcrumb float-start">
            <li class="breadcrumb-item">
                <a *ngIf="parentCompany" class="pointer" (click)="goToParentCompany()">{{parentCompany.name}}</a>
            </li>
            <!-- <li class="active" *ngIf="siteName">{{siteName}}</li> -->
        </ol>
        <div class="clearfix"></div>
    </div>
    <div class="ws-section">
        <div class="ws-section__header">
            <h4 class="ws-section__header__title">Forecasting and Budgeting</h4>
            <div class="ws-section__header__actions">
                <div class="ws-section__header__actions__item">
                    <label style="margin-bottom: 0; margin-right: 10px;">Mode:</label>
                    <label class="radio-inline">
                        <input type="radio" [(ngModel)]="forecastingBudgetingService.selectedMode" (change)="modeChanged()" value="forecast" [disabled]="!budgetsEnabled"> Forecast
                    </label>
                    <label class="radio-inline">
                        <input type="radio" [(ngModel)]="forecastingBudgetingService.selectedMode" (change)="modeChanged()" value="budget" [disabled]="!budgetsEnabled"> Budget
                    </label>
                </div>
                <!-- <div class="ws-section__header__actions__item" *ngIf="forecastingBudgetingService.selectedMode=== 'forecast'">
                    <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center" *ngIf="filters">
                        <label class="ws-flex-none" style="margin-bottom: 0; margin-right: 10px;">Tax Year:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="filters.yearEnd" (change)="taxYearsChanged()">
                            <option *ngFor="let year of taxYears" [ngValue]="year">{{year}}</option>
                        </select>
                    </div>
                </div>
                <div class="ws-section__header__actions__item" *ngIf="forecastingBudgetingService.selectedMode=== 'budget'">
                    <div><small>View:</small></div>
                    <div dropdown container="body" style="margin-left: 10px" placement="bottom right" [isDisabled]="true">
                        <a id="button-basic" dropdownToggle aria-controls="dropdown-basic" disabled  style="pointer-events: none;">
                            {{ forecastingBudgetingService.selectedView === 'taxYear' ? 'Tax Year Budget' : 'Accrual and Cash Budget' }}
                            <span class="caret"></span>
                        </a>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li role="menuitem">
                                <a class="pointer">Tax Year Budget</a>
                            </li>
                            <li role="menuitem">
                                <a class="pointer">Accrual and Cash Budget</a>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <div class="ws-section__header__actions__item">
                    <button type="button"
                        *ngIf="hasEditPermission && !forecastingBudgetingService.editMode"
                        class="ace-btn btn-sm btn-primary has-icon"
                        (click)="toggleEditMode(true)"
                        title="Edit Mode">
                            <i class="fa fa-pencil"></i>
                    </button>
                    <button type="button"
                        *ngIf="forecastingBudgetingService.editMode"
                        class="ace-btn btn-sm btn-primary btn-danger has-icon"
                        (click)="toggleEditMode(false)"
                        title="Exit Edit Mode"
                        [disabled]="forecastingBudgetingService.isEditingCell">
                            <i class="fa fa-close"></i>
                    </button>
                    <button type="button" class="ace-btn btn-sm btn-primary has-icon" (click)="refresh()" title="Refresh"
                            [disabled]="forecastingBudgetingService.selectedMode === 'budget' && !selectedBudget">
                        <i class="fa fa-refresh"></i>
                    </button>
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" *ngIf="!isMaximized" (click)="toggleMaximize(true)">
                        <i class="fa fa-arrows-alt" title="Expand"></i>
                    </button>
                    <button type="button" class="ace-btn btn-sm btn-secondary has-icon" *ngIf="isMaximized" (click)="toggleMaximize(false)">
                        <i class="fa fa-compress" title="Collapse"></i>
                    </button>
                </div>
            </div>
        </div>
        <div *ngIf="availableFilters && filters" class="ws-section__filters" style="align-items: baseline; justify-content: space-between; gap: 25px;" [class.remove-panel]="isMaximized">
            <div class="ws-section__filters__filter-group" style="flex: initial;">
                <div *ngIf="forecastingBudgetingService.selectedMode=== 'forecast'" style="display: flex; align-items: baseline; padding-top: 5px;">
                    <div *ngIf="filters">
                        <label>Tax Year:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="filters.yearEnd" style="width: auto; display: inline-block; margin-left: 5px;" (change)="taxYearsChanged()">
                            <option *ngFor="let year of taxYears" [ngValue]="year">{{year}}</option>
                        </select>
                    </div>
                </div>
                <div *ngIf="forecastingBudgetingService.selectedMode === 'budget'" style="display: flex; gap: 25px; align-items: baseline; padding-top: 5px; flex-wrap: wrap">
                    <div>
                        <label>Budget:</label>
                        <select class="form-select form-select-sm" [(ngModel)]="selectedBudget" style="width: auto; display: inline-block; margin-left: 5px;" (change)="selectedBudgetChanged()">
                            <option disabled [ngValue]="undefined">--- select ---</option>
                            <option *ngFor="let budget of availableBudgets" [ngValue]="budget">{{budget.budgetName}}</option>
                        </select>
                    </div>
                    <div *ngIf="selectedBudget">
                        <label>Basis:</label>
                        <select class="form-select form-select-sm"
                            [(ngModel)]="selectedBudget.budgetBasis"
                            style="width: auto; display: inline-block; margin-left: 5px;"
                            (change)="selectedBudgetBasisChanged()"
                            [disabled]="!forecastingBudgetingService.editMode || selectedBudget.isFrozen || savingBudget">
                                <option [ngValue]="CompanyBudgetBasisEnum.Accrual">Accrual</option>
                                <option [ngValue]="CompanyBudgetBasisEnum.Cash">Cash</option>
                                <option [ngValue]="CompanyBudgetBasisEnum.AccrualAndCash">Accrual and Cash</option>
                        </select>
                    </div>
                    <div *ngIf="selectedBudget">
                        <label>Fiscal Period:</label>
                        <p style="width: auto; display: inline-block; margin-left: 5px;">
                            {{selectedBudget.fiscalPeriodBegin | date:'MM/dd/yyyy':'utc'}} - {{selectedBudget.fiscalPeriodEnd | date:'MM/dd/yyyy':'utc'}}
                        </p>
                    </div>
                    <div *ngIf="selectedBudget" style="display: flex;" [style.align-items]="forecastingBudgetingService.editMode ? 'center' : 'initial'">
                        <label style="margin-right: 5px;">Date:</label>
                        <weissman-datetime [readOnly]="!forecastingBudgetingService.editMode" [(inputDate)]="selectedBudget.budgetDate" [dateOnly]="true" (change)="showBudgetApplyButton = true" [isDisabled]="savingBudget"></weissman-datetime>
                    </div>
                    <div *ngIf="showBudgetApplyButton">
                        <button class="ace-btn btn-sm btn-primary" (click)="saveCompanyBudget()">Apply</button>
                    </div>
                    <div [class.invisible]="!forecastingBudgetingService.editMode">
                        <button *ngIf="selectedBudget && !selectedBudget.isFrozen" class="ace-btn has-icon btn-primary btn-danger" (click)="deleteBudget()" [disabled]="savingBudget"><i class="fa fa-trash"></i></button>
                        <button class="ace-btn btn-sm btn-primary" [hidden]="!isTLCompany" (click)="addNewBudget()">New Budget</button>
                        <button *ngIf="selectedBudget"  [disabled]="!isFreezeUnfreezeAllowed() || savingBudget" class="ace-btn btn-primary btn-small" (click)="freezeUnfreezeBudget()">{{freezeButtonLabel}}</button>
                    </div>
                    <div *ngIf="selectedBudget && selectedBudget.isFrozen">
                        <span style="color:blue; font-size:18px; font-weight:bold;">FROZEN</span>
                    </div>
                </div>
            </div>
            <div [class.invisible]="!showFilterApplyButton" style="align-items: baseline; padding: 15px 15px 0 0;">
                <button class="ace-btn btn-primary" (click)="applyFilters()">Apply</button>
            </div>
        </div>
        <div *ngIf="availableFilters && filters" class="ws-section__filters" style="align-items: baseline;" [class.remove-panel]="isMaximized">
            <div *ngIf="availableFilters.companies.length" class="ws-section__filters__filter-group" style="padding-top:30px;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">Companies:</label>
                    <select multiple class="filter-select" (change)="companiesSelected($event.target)" >
                        <option *ngFor="let company of availableFilters.companies"
                            [value]="company.id"
                            [selected]="filters.companyIds.includes(company.id)"
                            [style.font-weight]="company.id === forecastingBudgetingService.tlCompany.companyID ? 'bold': 'normal'">
                                {{company.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="availableFilters.states.length" class="ws-section__filters__filter-group" style="padding-top:30px;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">States:</label>
                    <select multiple class="filter-select" (change)="statesSelected($event.target)" style="min-width: 60px; width: 60px;" >
                        <option *ngFor="let state of availableFilters.states" [value]="state.id" [selected]="filters.stateIds.includes(state.id)" >{{state.name}}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="availableFilters.propertyTypes.length" class="ws-section__filters__filter-group" style="padding-top:30px;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">Property types:</label>
                    <select multiple class="filter-select" (change)="propertyTypesSelected($event.target)" style="min-width: 150px; width: 150px;" >
                        <option *ngFor="let propertyType of availableFilters.propertyTypes" [value]="propertyType.id" [selected]="filters.propertyTypeIds.includes(propertyType.id)" >{{propertyType.name}}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="availableFilters.activityStatuses.length" class="ws-section__filters__filter-group" style="padding-top:30px;">
                <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;">
                    <label class="ws-flex-none">Activity Statuses:</label>
                    <select multiple class="filter-select" (change)="activityStatusesSelected($event.target)" style="min-width: 150px; width: 150px;" >
                        <option *ngFor="let activityStatus of availableFilters.activityStatuses" [value]="activityStatus.id" [selected]="filters.activityStatusIds.includes(activityStatus.id)" >{{activityStatus.name}}</option>
                    </select>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" style="padding-top:30px;">
                <div class="checkbox">
                    <label>
                        <input type="checkbox" [(ngModel)]="filters.siteRollup" (change)="filterChanged()"> Summarize by Sites
                    </label>
                </div>
            </div>
            <div class="ws-section__filters__filter-group" style="align-items: baseline; padding-top: 30px;">
                <div *ngIf="forecastGridSearchModel" class="filter-dropdowns">
                    <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                        <label class="ws-flex-none">Show YoY changes for:</label>
                        <select class="form-select form-select-sm"
                                [(ngModel)]="forecastGridSearchModel.showYoYChangesFor"
                                (change)="filterChanged()">
                            <option [ngValue]="ShowAssessmentsTaxesEnum.None">None</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.AssessmentsAndTaxes">Assessments and Taxes</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.Assessments">Assessments</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.Taxes">Taxes</option>
                        </select>
                    </div>
                    <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                        <label class="ws-flex-none">Show additional years:</label>
                        <select class="form-select form-select-sm"
                                [(ngModel)]="priorYears"
                                (change)="taxYearsChanged()">
                            <option *ngFor="let option of priorOptions" [ngValue]="option">{{ option }}</option>
                        </select>
                    </div>
                    <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                        <label class="ws-flex-none">Alternate Assessments:</label>
                        <select class="form-select form-select-sm"
                                [(ngModel)]="forecastGridSearchModel.alternateAssessmentView"
                                (change)="filterChanged()">
                                <option [ngValue]="ForecastBudgetAltAssessmentView.Hide">Hide</option>
                                <option [ngValue]="ForecastBudgetAltAssessmentView.Show">Show</option>
                                <option [ngValue]="ForecastBudgetAltAssessmentView.ShowOnlyAlternates">Show Only Alternates</option>
                        </select>
                    </div>
                    <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;" [class.invisible]="forecastGridSearchModel.mode !== ForecastBudgetScreenModeEnum.TaxYearBudget">
                        <label class="ws-flex-none">Show budget entries for:</label>
                        <select class="form-select form-select-sm"
                                [(ngModel)]="forecastGridSearchModel.showBudgetEntriesFor"
                                (change)="filterChanged()">
                            <option [ngValue]="ShowAssessmentsTaxesEnum.None">None</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.AssessmentsAndTaxes">Assessments and Taxes</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.Assessments">Assessments</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.Taxes">Taxes</option>
                        </select>
                    </div>
                    <div class="ws-flex-container-horizontal ws-flex-auto" style="align-items: baseline;" [class.invisible]="forecastGridSearchModel.mode === ForecastBudgetScreenModeEnum.Forecast">
                        <label class="ws-flex-none">Show budget variances:</label>
                        <select class="form-select form-select-sm"
                                [(ngModel)]="forecastGridSearchModel.showBudgetVariances"
                                (change)="filterChanged()">
                            <option [ngValue]="ShowAssessmentsTaxesEnum.None">None</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.AssessmentsAndTaxes">Assessments and Taxes</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.Assessments">Assessments</option>
                            <option [ngValue]="ShowAssessmentsTaxesEnum.Taxes">Taxes</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ws-flex-container-horizontal ws-stretch ws-overflow-auto">
        <ws-ag-grid-angular gridClass="forecasting-budgeting-grid"
                            rowSelection="multiple"
                            [gridId]="'C6CF13D2-702B-49BA-970D-1375127218C9'"
                            [gridOptions]="gridOptions"
                            [exportOptions]="exportOptions"
                            [isBulkUpdateVisible$]="isBulkUpdateVisible$"
                            (gridReady)="onAgGridReady($event)"
                            (selectionChanged)="onSelectionChanged($event)"
                            (bulkUpdate)="bulkUpdate()">
        </ws-ag-grid-angular>
    </div>
    <div *ngIf="forecastGridSearchModel"
        class="ws-flex-container-horizontal ws-overflow-auto"
        style="align-items: center;"
        [style.min-height]="forecastGridSearchModel.mode === ForecastBudgetScreenModeEnum.Forecast ? '125px': '140px'"
        [class.remove-panel]="isMaximized">
            <div style="display: flex; justify-content: space-around; width: 100%;">
                <div style="width: 430px;">
                    <div *ngIf="forecastingBudgetingService.editMode && forecastGridSearchModel.mode === ForecastBudgetScreenModeEnum.TaxYearBudget"
                        class="checkbox"
                        style="margin-top: 0;"
                        [class.disabled]="selectedBudget && selectedBudget.isFrozen"
                        [class.remove-panel]="isMaximized">
                            <label>
                                <input type="checkbox" [(ngModel)]="forecastingBudgetingService.autoSyncBudgetToLatest" [disabled]="selectedBudget && selectedBudget.isFrozen">
                                Automatically sync assessment and tax change to non-frozen budget
                            </label>
                    </div>
                    <div *ngIf="forecastingBudgetingService.selectedMode === 'forecast'">
                        <forecast-create-missing [numMissingTaxYears]="missingSyncData.numMissingTaxYears"
                                                [lrUpdateInProgress]="lrUpdateInProgress"
                                                [taxYear]="filters.yearEnd"
                                                [editMode]="forecastingBudgetingService.editMode"
                                                (createClick)="createMissingForecast()">
                        </forecast-create-missing>
                    </div>
                    <div *ngIf="forecastingBudgetingService.selectedMode === 'budget' && forecastingBudgetingService.selectedView === 'taxYear' && selectedBudget">
                        <budget-taxyear-create-missing [missingSyncData]="missingSyncData"
                                                    [lrUpdateInProgress]="lrUpdateInProgress"
                                                    [taxYear]="filters.yearEnd"
                                                    [editMode]="forecastingBudgetingService.editMode"
                                                    [isBudgetFrozen]="selectedBudget.isFrozen"
                                                    (createClick)="createMissingBudgetTaxYear()">
                        </budget-taxyear-create-missing>
                        <div style="margin-top: 5px;">
                            <budget-taxyear-sync-budgets [missingSyncData]="missingSyncData"
                                                        [lrUpdateInProgress]="lrUpdateInProgress"
                                                        [editMode]="forecastingBudgetingService.editMode"
                                                        [isBudgetFrozen]="selectedBudget.isFrozen"
                                                        (syncClick)="syncBudgets()">
                            </budget-taxyear-sync-budgets>
                        </div>
                    </div>
                </div>
                <!-- <div>
                    <div *ngIf="forecastGridSearchModel" class="filter-dropdowns">
                        <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                            <label class="ws-flex-none">Show YoY changes for:</label>
                            <select class="form-select form-select-sm"
                                    [(ngModel)]="forecastGridSearchModel.showYoYChangesFor"
                                    (change)="filterChanged()">
                                <option [ngValue]="ShowAssessmentsTaxesEnum.None">None</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.AssessmentsAndTaxes">Assessments and Taxes</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.Assessments">Assessments</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.Taxes">Taxes</option>
                            </select>
                        </div>
                        <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                            <label class="ws-flex-none">Show additional years:</label>
                            <select class="form-select form-select-sm"
                                    [(ngModel)]="priorYears"
                                    (change)="taxYearsChanged()">
                                <option *ngFor="let option of priorOptions" [ngValue]="option">{{ option }}</option>
                            </select>
                        </div>
                        <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center">
                            <label class="ws-flex-none">Alternate Assessments:</label>
                            <select class="form-select form-select-sm"
                                    [(ngModel)]="forecastGridSearchModel.alternateAssessmentView"
                                    (change)="filterChanged()">
                                    <option [ngValue]="ForecastBudgetAltAssessmentView.Hide">Hide</option>
                                    <option [ngValue]="ForecastBudgetAltAssessmentView.Show">Show</option>
                                    <option [ngValue]="ForecastBudgetAltAssessmentView.ShowOnlyAlternates">Show Only Alternates</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <div *ngIf="forecastGridSearchModel" class="filter-dropdowns">
                        <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center" [class.invisible]="forecastGridSearchModel.mode !== ForecastBudgetScreenModeEnum.TaxYearBudget">
                            <label class="ws-flex-none">Show budget entries for:</label>
                            <select class="form-select form-select-sm"
                                    [(ngModel)]="forecastGridSearchModel.showBudgetEntriesFor"
                                    (change)="filterChanged()">
                                <option [ngValue]="ShowAssessmentsTaxesEnum.None">None</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.AssessmentsAndTaxes">Assessments and Taxes</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.Assessments">Assessments</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.Taxes">Taxes</option>
                            </select>
                        </div>
                        <div class="ws-flex-container-horizontal ws-flex-auto ws-flex-align-items-center" [class.invisible]="forecastGridSearchModel.mode === ForecastBudgetScreenModeEnum.Forecast">
                            <label class="ws-flex-none">Show budget variances:</label>
                            <select class="form-select form-select-sm"
                                    [(ngModel)]="forecastGridSearchModel.showBudgetVariances"
                                    (change)="filterChanged()">
                                <option [ngValue]="ShowAssessmentsTaxesEnum.None">None</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.AssessmentsAndTaxes">Assessments and Taxes</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.Assessments">Assessments</option>
                                <option [ngValue]="ShowAssessmentsTaxesEnum.Taxes">Taxes</option>
                            </select>
                        </div>
                    </div>
                </div> -->
            </div>
    </div>
</div>
<div [hidden]="!bulkUpdateInProgress" class="container">
    <div [hidden]="bulkUpdateCanceled">
        <h3 *ngIf="!isBulkUpdateDone()" class="text-center">Bulk Updating {{bulkUpdateEntityType}}s</h3>
        <h3 *ngIf="isBulkUpdateDone()" class="text-center">Done</h3>
        <progressbar class="active" [class.progress-striped]="bulkUpdateProgress.currentIndex < bulkUpdateProgress.selections.length"
                     [max]="bulkUpdateProgress.selections.length"
                     [value]="bulkUpdateProgress.currentIndex"
                     type="info"></progressbar>
        <h3 *ngIf="!isBulkUpdateDone()">{{getCurrentBulkUpdateIdentity()}}</h3>
    </div>
    <div [hidden]="!bulkUpdateCanceled">
        <h3 class="text-center">Canceled</h3>
    </div>
    <div style="margin-top: 10px; font-size: 16px">
        <table class="bulk-update-results">
            <tr *ngIf="bulkUpdateProgress.missingTaxYearCount !== null">
                <td>
                    <label>Missing Parcel Tax Years:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.missingTaxYearCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.emptyAssessmentCount !== null">
                <td>
                    <label>Empty Parcel Assessments:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.emptyAssessmentCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.actualizedAssessmentCount !== null">
                <td>
                    <label>Actualized Parcel Assessments:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.actualizedAssessmentCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.parcelCount !== null">
                <td>
                    <label>Parcels Changed:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.parcelCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.siteCount !== null">
                <td>
                    <label>Sites Changed:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.siteCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.assessmentCount !== null">
                <td>
                    <label>Assessments Changed:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.assessmentCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.billCount !== null">
                <td>
                    <label>Bills Recalculated:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.billCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.paymentCount !== null">
                <td>
                    <label>Payments Recalculated:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.paymentCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.missingBudgetCount !== null">
                <td>
                    <label>Missing Budget Entries:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.missingBudgetCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.budgetFrozenCount !== null">
                <td>
                    <label>Budget Entries Frozen:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.budgetFrozenCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.budgetUnfrozenCount !== null">
                <td>
                    <label>Budget Entries Unfrozen:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.budgetUnfrozenCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.budgetCreatedCount !== null">
                <td>
                    <label>Budgets Entries Created:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.budgetCreatedCount}}</td>
            </tr>
            <tr *ngIf="bulkUpdateProgress.budgetSyncedCount !== null">
                <td>
                    <label>Budgets Entries Synced:</label>
                </td>
                <td></td>
                <td>{{bulkUpdateProgress.budgetSyncedCount}}</td>
            </tr>
        </table>
    </div>
    <div class="text-center" style="margin-top: 20px;">
        <button *ngIf="bulkUpdateCanceled || isBulkUpdateDone()" class="ace-btn btn-primary" (click)="endBulkUpdate()">OK</button>
        <button *ngIf="!bulkUpdateCanceled && !isBulkUpdateDone()" class="ace-btn btn-primary btn-danger" (click)="cancelBulkUpdate()">Cancel</button>
    </div>
</div>
