import { Component, Input, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from '../../Layout/navigation.service';
import { YearPickerMixin } from '../../UI-Lib/Mixins';
import { ContractsInvoicesService } from './contract-invoices.service';
import { ContractTerm, ContractTermData, Engagement } from './contract-invoices.model';
import { Constants, ContractServiceType } from '../../constants.new';
import { MessageBoxService, MessageBoxButtons, MessageBoxResult } from '../../UI-Lib/Message-Box/messagebox.service.upgrade';
import { ModalDirective, BsModalService } from 'ngx-bootstrap/modal';
import { EngagementNumberLookupModalComponent } from './engagement-number-lookup-modal.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Decimal } from 'decimal.js';
import { RestrictService, Roles } from '../Permissions/restrict.service';
import { TimerService } from '../../UI-Lib/Utilities/timer.service';
import { CommentsModalService } from '../../Comments/commentsModal.service';
import { FeatureFlagsService } from '../FeatureFlags/feature-flags-service';
//import * as _ from 'lodash';
declare const _: any;

@Component({
    selector: 'contract-terms-panel-company',
    templateUrl: './contract-terms-panel-company.component.html'
})
export class ContractTermsPanelCompanyComponent extends YearPickerMixin() implements OnInit, OnDestroy {
    constructor(private readonly _contractsInvoicesService: ContractsInvoicesService,
        private readonly _messageBoxService: MessageBoxService,
        private readonly _modalService: BsModalService,
        private readonly _toastr: ToastrService,
        private readonly _restrictService: RestrictService,
        private readonly _commentsModalService: CommentsModalService,
        private readonly _constants: Constants,
        private readonly _timer: TimerService,
        private readonly _featureFlagService: FeatureFlagsService,
                private readonly _navigationService: NavigationService) {
        super();
    }

    @Input() entity: { id: number, name: string, newAllowed: boolean, type: string };
    @Input() isTopLevel: boolean;
    @ViewChild('addTermYearModal', { static: true }) public addTermYearModal: ModalDirective;
    @ViewChild('configureFeeEscalatorModal', { static: true }) public configureFeeEscalatorModal: ModalDirective;

    isLoading: boolean = false;
    hasViewPermission: boolean = false;
    hasEditPermission: boolean = false;
    showBody: boolean = false;
    isEditMode: boolean = false;
    editLock: boolean;
    topLevelYears: number[] = [];
    subsidiaryYears: number[] = [];
    selectedYear: number;
    propertyTypes: any[];
    allData: ContractTermData;
    topLevelCompanyContractTerms: ContractTerm[];
    currentContractTerms: ContractTerm[];
    useTopLevelCompanyTerms: boolean = false;
    addingYear: boolean = false;
    savingFeeEscalator: boolean = false;
    yearsToAdd: { year: number, disabled: boolean }[];
    yearToAdd: number;
    rollForwardSiteOverrides: boolean;
    bsModalRef: BsModalRef;
    recentYears: number[];
    currentYear: number = new Date().getFullYear();
    services: { id: number, name: string, fullDisplayName: string }[];
    ContractServiceType = ContractServiceType;
    showFeeEscalator: boolean;
    newEscalatorServiceTypes: number[] = [];
    newFeeEscalator: number;

    private _destroy$: Subject<void> = new Subject();

    ngOnInit(): void {
        this.hasViewPermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSVIEW);
        this.hasEditPermission = this._restrictService.isInRole(Roles.RYANPRIVATEITEMSEDIT);
        this.services = _.chain(this._constants.ContractServiceTypes)
            .toArray()
            .sortBy('name')
            .value();

        if (!this._featureFlagService.featureFlags.enableAppealLitigation) {
            const litigationIndex = this.services.findIndex(x => x.id === ContractServiceType.Litigation);
            this.services.splice(litigationIndex, 1);
        }

        this._navigationService.globalEditMode$.pipe(takeUntil(this._destroy$)).subscribe(editMode => {
            this.editLock = editMode;
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    expandOrCollapse(): void {
        if(this.isEditMode) {
            return;
        }

        this.showBody = !this.showBody;

        if(this.showBody) {
            this.loadPanel();
        }
    }

    loadPanel(): void {
        this.propertyTypes = _.map(this._constants.PropertyTypeNames, (name: string, id: number) => {
            return {
                name: name,
                propertyTypeId: Number(id)
            };
        });

        this.recentYears = _.range(this.currentYear - 10, this.currentYear + 6).reverse() as number[];

        this.isLoading = true;
        this._contractsInvoicesService.getContractTermsByCompany(this.entity.id)
            .then((result: ContractTermData) => {
                this.populateContractTermData(result);
                this.isLoading = false;
            });
    }

    goToEditMode(): void {
        this.isEditMode = true;
        this._navigationService.enableNavWarning('You have unsaved changes. Are you sure you want to leave this page?');
    }

    addTerm(): void {
        const newTerm: ContractTerm = {
            contractTermDetailId: null,
            contractServiceTypeId: _.head(this.services).id,
            contractServiceTypeName: _.head(this.services).name,
            propertyTypeId: null,
            propertyTypeName: null,
            taxYear: this.getSelectedYear(this.subsidiaryYears),
            engagement: '',
            siteDefault: true,
            contingencyPct: null,
            contingencyCap: null,
            flatFee: null,
            hourlyRate: null,
            remarks: null,
            rowVersion: '',
            contractTermRowVersion: '',
            efAction: 'add'
        };

        this.currentContractTerms.push(newTerm);
    }

    //this.selectedYear = old
    //e = new
    yearChanged(): void {
        const oldVal = this.selectedYear;

        if (this.areTermsDirty()) {
            this._messageBoxService.open({
                message: 'Discard changes for current changes and switch year selection?',
                buttons: MessageBoxButtons.OKCancel
            })
                .then((result: number) => {
                    if (result === MessageBoxResult.OK) {
                        this.getDataForYear();
                    } else {
                        this._timer.setTimeout(() => {
                            this.selectedYear = oldVal;
                        });
                    }
                });
        } else {
            this._timer.setTimeout(() => {
                this.getDataForYear();
            });
        }

    }

    removeTerm(term: ContractTerm, index: number): void {
        if (term.efAction === 'add') {
            // We can't use the object with lodash for removal since an un-saved object is identical and
            // lodash purges them all with a _.remove
            _.pullAt(this.currentContractTerms, [index]);
        } else {
            term.efAction = 'delete';
        }
    }

    async rollForwardYear(): Promise<void> {
        if (await this.confirmAutoSave()) {
            this.launchAddYearModal();
        }
    }

    async configureFeeEscalator(): Promise<void> {
        if (await this.confirmAutoSave()) {
            this.launchConfigureFeeEscalatorModal();
        }
    }

    addYear(): void {
        this.addingYear = true;
        this._contractsInvoicesService.rollForwardYear(this.entity.id, this.selectedYear, this.yearToAdd, this.rollForwardSiteOverrides)
            .then((newYearData: ContractTerm[]) => {
                this.addingYear = false;
                this.allData.currentContractTerms = _.union(this.allData.currentContractTerms, newYearData) as ContractTerm[];

                this.populateYearsDropdown();
                this.selectedYear = this.yearToAdd;

                this.addTermYearModal.hide();
                this.getDataForYear();
                this.isEditMode = false;
                this._navigationService.disableNavWarning();
            }).catch(() => { this.addingYear = false; });
    }

    saveFeeEscalator(): Promise<void> {
        this.savingFeeEscalator = true;
        return this._contractsInvoicesService.saveFeeEscalator(this.entity.id, {
            fixedFeeEscalator: this.newFeeEscalator / 100,
            contractServiceTypes: this.newEscalatorServiceTypes,
            companyRowVersion: this.allData.companyRowVersion
        }).then(() => {
            this.configureFeeEscalatorModal.hide();
            this.isLoading = true;
            this._contractsInvoicesService.getContractTermsByCompany(this.entity.id).then((result: ContractTermData) => {
                this.populateContractTermData(result);
                this.isLoading = false;
            }).catch(() => { this.isLoading = false; });
            this.savingFeeEscalator = false;
        }).catch(err => { this.savingFeeEscalator = false; });
    }

    cancelEdit(): void {
        this.getDataForYear();

        this.isEditMode = false;
        this._navigationService.disableNavWarning();
    }

    savePanel(stayInEditMode?: boolean): Promise<void> {
        const errorMessage = this.panelIsInvalid();
        if (errorMessage) {
            this._toastr.error('Error!', errorMessage);
            return;
        }

        const saveBody: any = {
            useTopLevelCompanyTerms: this.useTopLevelCompanyTerms,
            ContractTerms: this.useTopLevelCompanyTerms ? this.topLevelCompanyContractTerms : this.getCurrentTermsForSave(),
            efAction: this.useTopLevelCompanyTerms !== this.allData.useTopLevelCompanyTerms ? 'update' : null,
            companyRowVersion: this.allData.companyRowVersion
        };

        this.isLoading = true;
        return this._contractsInvoicesService.shouldRequireConfirmationDialog(this.entity.id, saveBody)
            .then((requireConfirmationDialog: boolean) => {
                if (requireConfirmationDialog) {
                    return this._messageBoxService.open({
                        message: 'Overrides will be removed',
                        buttons: MessageBoxButtons.OKCancel
                    })
                        .then((result: number) => {
                            if (result === MessageBoxResult.OK) {
                                return this.saveData(saveBody, stayInEditMode);
                            }

                            this.cancelEdit();
                        });
                }

                return this.saveData(saveBody, stayInEditMode);
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    lookupEngagement(term: ContractTerm): void {
        this.bsModalRef = this._modalService.show(EngagementNumberLookupModalComponent, { class: 'modal-lg' });
        this.bsModalRef.content.title = 'Lookup Engagement Number';

        this.bsModalRef.content.onClose = (engagement: Engagement) => {
            term.engagement = engagement.engagementID;
        };
    }

    termsAreEditable(): boolean {
        return this.isEditMode && !this.useTopLevelCompanyTerms;
    }

    showRollForwardYearButton(): boolean {
        return this.termsAreEditable() && this.selectedYear !== 0 && _.some(this.currentContractTerms, (term: ContractTerm) => {
            return term.contractTermDetailId && term.efAction !== 'delete';
        }) as boolean;
    }

    useTopLevelCompanyChanged(): void {
        this.setSelectedYear();
        this.getDataForYear();
    }

    serviceTypeChanged(newServiceType: string, term: ContractTerm): void {
        if(Number(newServiceType) != ContractServiceType.Appeal && Number(newServiceType) != ContractServiceType.Litigation) {
            term.propertyTypeId = null;
            term.contingencyCap = null;
            term.contingencyPct = null;
        }

        if (Number(newServiceType) == ContractServiceType.Misc || Number(newServiceType) == ContractServiceType.Hourly ){
            term.flatFee = null;
        }
    }

    async showComments(term: ContractTerm, readOnly: boolean): Promise<void> {
        const newRemarks = await this._commentsModalService.openAddCommentModal({
            title: 'Contract Term Remarks',
            commentBody: term.remarks,
            allowBlank: true,
            readOnly: readOnly,
            entityTypeName: 'company',
            entityId: this.entity.id
        });

        if (!readOnly && newRemarks !== undefined) {
            term.remarks = newRemarks;
        }
    }

    getContractServiceTypename(serviceTypeId): string {
        return this.services
            .filter(s => s.id == serviceTypeId)
            .map(t => t.fullDisplayName || t.name)[0];
    }

    serviceTypeSelected(serviceTypeId: number): boolean {
        return this.newEscalatorServiceTypes.some(t => t == serviceTypeId);
    }

    checkedServiceType(serviceTypeId: number, checked: boolean): void {
        console.log('checked', checked);
        if (checked) {
            this.newEscalatorServiceTypes.push(serviceTypeId);
        }
        else {
            const index = this.newEscalatorServiceTypes.findIndex(t => t == serviceTypeId);
            if (!(index >= 0)) {
                // Sanity check
                throw new Error(`Array unexpectedly missing value: ${  serviceTypeId}`);
            }

            this.newEscalatorServiceTypes.splice(index, 1);
        }
    }

    //
    // Private functions
    //

    private async confirmAutoSave(): Promise<boolean> {
        if (this.isPanelDirty()) {
            const msgBoxResult = await this._messageBoxService.open({
                message: 'Current Contract Terms will be Saved',
                buttons: MessageBoxButtons.OKCancel
            });
            if (msgBoxResult === MessageBoxResult.OK) {
                await this.savePanel(true);
            }
            else {
                return false;
            }
        }

        return true;
    }

    private panelIsInvalid(): string {
        const missingTerms: boolean = _.some(this.currentContractTerms, (term: ContractTerm) => {
            return !term.contingencyPct && !term.flatFee && !term.hourlyRate && term.contractServiceTypeId !== ContractServiceType.Misc && term.contractServiceTypeId !== ContractServiceType.Hourly;
        }) as boolean;

        if (missingTerms) {
            return 'At least one term is missing fee details.';
        }

        const negativeAmounts = _.some(this.currentContractTerms, (term: ContractTerm) => {
            return [term.contingencyPct, term.contingencyCap, term.flatFee, term.hourlyRate].some(v => v < 0);
        });

        if (negativeAmounts) {
            return 'Amounts cannot be negative';
        }

        const missingYear = _.some(this.currentContractTerms, (term: ContractTerm) => {
            return term.taxYear === 0 || !term.taxYear;
        });

        if(missingYear) {
            return 'At least one term is missing a tax year.';
        }

        const invalidServiceYears: any = _.chain(this.currentContractTerms)
            .filter((term: ContractTerm) => {
                return (term.contractServiceTypeId == ContractServiceType.Misc || term.contractServiceTypeId == ContractServiceType.Hourly);
            })
            .groupBy((term: ContractTerm) => {
                return `${term.contractServiceTypeId  }-${ term.taxYear}`;
            })
            .some(grouping => grouping.length > 1)
            .value();

        if(invalidServiceYears) {
            return 'Only one Misc contract term can be created for a given year';
        }

        const percentInvalid: boolean = _.some(this.currentContractTerms, (term: ContractTerm) => {
            return term.contingencyPct < 0 || term.contingencyPct > 100;
        });

        return percentInvalid ? 'Contingency Fee must be between 0 and 100%' : '';

    }

    private getCurrentTermsForSave(): ContractTerm[] {
        return _.chain(this.currentContractTerms)
            .cloneDeep()
            .map((term: ContractTerm) => {
                if(term.contingencyPct) {
                    term.contingencyPct = new Decimal(term.contingencyPct).dividedBy(100).toNumber();
                }

                if (!term.efAction) {
                    const foundTerm: ContractTerm = _.find(this.allData.currentContractTerms, { contractTermDetailId: term.contractTermDetailId }) as ContractTerm;
                    term.efAction = !_.isEqual(foundTerm, term) ? 'update' : null;
                }

                return term;
            })
            .value() as ContractTerm[];
    }

    private saveData(saveBody: any, stayInEditMode: boolean): Promise<void> {
        return this._contractsInvoicesService.saveCompanyContractTerms(this.entity.id, saveBody)
            .then((result: ContractTermData) => {
                this.populateContractTermData(result);

                this.isLoading = false;
                this.isEditMode = !!stayInEditMode;
                if (!stayInEditMode) {
                    this._navigationService.disableNavWarning();
                }
            })
            .catch(() => {
                this.isLoading = false;
            });
    }

    private populateContractTermData(data: ContractTermData): void {
        this.allData = data;
        this.useTopLevelCompanyTerms = this.allData.useTopLevelCompanyTerms;

        this.populateYearsDropdown();
        this.setSelectedYear();
        this.getDataForYear();
    }

    private populateYearsDropdown(): void {
        this.topLevelYears = this.getYears(this.allData.topLevelCompanyContractTerms);
        this.subsidiaryYears = this.getYears(this.allData.currentContractTerms);
    }

    private setSelectedYear(): void {
        this.selectedYear = this.useTopLevelCompanyTerms ? this.getSelectedYear(this.topLevelYears) : this.getSelectedYear(this.subsidiaryYears);
    }

    private getYears(terms: ContractTerm[]): number[] {
        return _.chain(terms)
            .map('taxYear')
            .uniq()
            .sortBy()
            .reverse()
            .value() as number[];
    }

    private getSelectedYear(years: number[]): number {
        if (years.length) {
            const selectedYear: number = _.includes(years, this.selectedYear) ? this.selectedYear : null;
            const currentYear: number = _.includes(years, this.currentYear) ? this.currentYear : null;

            return selectedYear || currentYear || _.maxBy(years) as number;
        } else {
            return 0;
        }
    }

    private getDataForYear(): void {
        this.selectedYear = Number(this.selectedYear);

        this.topLevelCompanyContractTerms = this.prepareDataForView(this.allData.topLevelCompanyContractTerms);
        this.currentContractTerms = this.prepareDataForView(this.allData.currentContractTerms);
    }

    private prepareDataForView(contractTerms: ContractTerm[]): ContractTerm[] {
        return _.chain(contractTerms)
            .filter((term: ContractTerm) => {
                return this.selectedYear === 0 || term.taxYear == this.selectedYear;
            })
            .cloneDeep()
            .map((term: ContractTerm) => {
                if(term.contingencyPct) {
                    term.contingencyPct = new Decimal(term.contingencyPct).times(100).toNumber();
                }
                return term;
            })
            .orderBy('taxYear', 'desc')
            .value() as ContractTerm[];
    }

    private launchAddYearModal(): void {
        this.showFeeEscalator = this.allData.feeEscalator !== null && this.allData.escalatorServiceTypes.length > 0;
        this.yearsToAdd = this.taxYears.map((year: number) => {
                return {
                    year: year,
                    disabled: this.currentContractTerms.some(x => x.taxYear === year)
                };
            });

        const lastDisabledYearIndex: number = _.findLastIndex(this.yearsToAdd, 'disabled') as number;

        if (this.yearsToAdd[lastDisabledYearIndex - 1]) {
            this.yearToAdd = this.yearsToAdd[lastDisabledYearIndex - 1].year;
        } else {
            this.yearToAdd = _.maxBy(this.yearsToAdd, (option) => {
                return option.disabled ? 0 : option.year;
            }).year;
        }

        this.addTermYearModal.show();
    }

    private launchConfigureFeeEscalatorModal(): void {
        if (this.allData.feeEscalator) {
            this.newFeeEscalator = this.allData.feeEscalator * 100;
            this.newEscalatorServiceTypes = Array.prototype.slice.apply(this.allData.escalatorServiceTypes);
        }
        else {
            this.newFeeEscalator = 0;
            this.newEscalatorServiceTypes = Array.prototype.slice.apply(this.services.map(s => s.id));
        }
        this.configureFeeEscalatorModal.show();
    }

    private isPanelDirty(): boolean {
        return this.useTopLevelCompanyTerms !== this.allData.useTopLevelCompanyTerms || this.areTermsDirty();
    }

    private areTermsDirty(): boolean {
        return !this.useTopLevelCompanyTerms && this.aContractTermHasChanged();
    }

    private aContractTermHasChanged(): boolean {
        return _.some(this.currentContractTerms, (term: ContractTerm) => {
            if (term.efAction) {
                return true;
            }

            const copiedTerm: ContractTerm = _.cloneDeep(term) as ContractTerm;
            if(copiedTerm.contingencyPct) {
                copiedTerm.contingencyPct = new Decimal(copiedTerm.contingencyPct).dividedBy(100).toNumber();
            }
            const foundTerm: ContractTerm = _.find(this.allData.currentContractTerms, { contractTermDetailId: copiedTerm.contractTermDetailId }) as ContractTerm;

            return !_.isEqual(foundTerm, copiedTerm) as boolean;
        }) as boolean;
    }
}
