<ws-busy-indicator [message]="busyIndicatorMessageManager.message" *ngIf="busyIndicatorMessageManager.count() > 0"></ws-busy-indicator>

<p class="lead" *ngIf="awaitingPackageCreation">Awaiting Package Creation <span>...</span></p>
<table *ngIf="!awaitingPackageCreation" class="table table-striped packages-table">
    <thead>
        <tr>
            <th>Package #</th>
            <th>Created</th>
            <th>Responsible Party</th>
            <th>Cc/Bcc</th>
            <th *ngIf="isTransmittal">Transmittals</th>
            <th *ngIf="!isTransmittal">Feed</th>
            <th>Payment Count</th>
            <th>Special Instructions</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let package of paymentPackages"
            [style.font-style]="package.completedDateTime ? 'italic' : 'normal'"
            [style.color]="package.isRecalled ? '#a3a4b2' : 'var(--ace-neutral-800)'">
            <td>{{ getPackageNum(package) }}</td>
            <td>{{ package.completedDateTime ? (package.completedDateTime | weissmanDateFormat) : '' }}</td>
            <td>
                <div *ngFor="let recipient of package.paymentPackageRecipients">
                    <span *ngIf="recipient.recipientType === recipientTypeMap['responsible'] && package.feedType !== checkFeed"
                          [tooltip]="recipientAddress"
                          (mouseenter)="setHoveredRecipient(recipient)">
                        {{ recipient.fullName }}
                    </span>
                </div>
                <div *ngIf="package.feedType === checkFeed" class="text-muted">
                    Not Applicable
                </div>
            </td>
            <td>
               <table>
                    <tr>
                        <td>
                            <div *ngFor="let recipient of package.paymentPackageRecipients">
                                <div *ngIf="recipient.recipientType === recipientTypeMap['cc'] && package.feedType !== checkFeed"
                                        class="float-start"
                                        [tooltip]="recipientAddress"
                                        (mouseenter)="setHoveredRecipient(recipient)">
                                    <span>CC: </span>
                                    {{ recipient.fullName }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div *ngFor="let recipient of package.paymentPackageRecipients">
                                <div *ngIf="recipient.recipientType === recipientTypeMap['bcc'] && package.feedType !== checkFeed"
                                        class="float-start"
                                        [tooltip]="recipientAddress"
                                        (mouseenter)="setHoveredRecipient(recipient)">
                                    <span>BCC: </span>
                                    {{ recipient.fullName }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <div *ngIf="package.feedType === checkFeed" class="text-muted">
                    Not Applicable
                </div>
            </td>
            <td>
                <a *ngIf="package.feedType !== checkFeed" style="cursor: pointer;" (click)="navigateToBillPaymentPackages(package)">
                    {{isTransmittal ? 'Transmittal Link' : feedTextMap[package.feedType]}}
                </a>
                <div *ngIf="package.feedType === checkFeed" style="display: flex; gap: 5px;">
                    <a style="cursor: pointer;" [class.disabled]="isDownloadDisabled(package)" (click)="download(package)">
                        {{feedTextMap[package.feedType]}}
                    </a>
                    <loading-spinner [marginTop]="0" [class.invisible]="!package.isDownloading"></loading-spinner>
                </div>
            </td>
            <td>{{package.paymentCount}}</td>
            <td>{{isTransmittal ? package.specialInstructions : 'N/A'}}</td>
            <td>
                <button style="margin: 0;"
                    *ngIf="package.feedType !== checkFeed"
                    class="ace-btn has-icon btn-primary"
                    (click)="launchReviewModal(package)">
                    <i class="fa fa-file-export"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table>

<ng-template #recipientAddress>
    <address *ngIf="hoveredRecipient" style="text-align: left; margin: 0;">
        {{hoveredRecipient.companyName}}
        <br> {{hoveredRecipient.fullName}}
        <br> {{hoveredRecipient.title}}
        <div *ngIf="hoveredRecipient.address">
            {{hoveredRecipient.address.address1}}
            <br> {{hoveredRecipient.address.address2}}
            <br *ngIf="hoveredRecipient.address.address2"> {{hoveredRecipient.address.city}}<span *ngIf="hoveredRecipient.address.city">,</span> {{hoveredRecipient.address.abbr}} {{hoveredRecipient.address.zip}}
        </div>
    </address>
</ng-template>
