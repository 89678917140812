import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { HybridAdapterUtility } from '../Hybrid/hybrid-adapter.utility';
import { SiteModule } from './Site/site.module';
import { EntityService } from './entity.service';
import { AssessorDetailsComponent } from './Assessor-Details/assessorDetails.component';
import { WeissmanSharedModule } from '../Common/weissman-shared.module';
import { ParcelIconModule } from './Parcel/Icon/parcelIcon.module';
import { CompanyModule } from './Company/company.module';
import { TaxFeedSettingRepository } from './taxFeedSettingRepository';
import { MoveCopyEntityComponent } from './move.copy.entity.component';
import { MoveCopyEntityService } from './move.copy.entity.service';
import { SiteMapComponent } from './site.map.component';
import { SiteMapService } from './site.map.service';
import { SiteMapInfoWindowComponent } from './site.map.info.window.component';
import { AccountingPeriodsModalComponent } from './Company/Company-Settings/Accounting-Periods/accounting.periods.modal.component';
import { AccountingPeriodsService } from './Company/Company-Settings/Accounting-Periods/accounting.periods.service';

@NgModule({
    imports: [
        WeissmanSharedModule,
        GoogleMapsModule,
        SiteModule,
        CompanyModule
    ],
    declarations: [
        AssessorDetailsComponent,
        MoveCopyEntityComponent,
        SiteMapComponent,
        SiteMapInfoWindowComponent,
        AccountingPeriodsModalComponent
    ],
    providers: [
        EntityService,
        TaxFeedSettingRepository,
        MoveCopyEntityService,
        SiteMapService,
        AccountingPeriodsService
    ],
    exports: [
        MoveCopyEntityComponent,
        SiteMapComponent
    ]
})
export class EntityModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();

        hybridAdapterUtility.downgradeNg2Provider('ng2EntityService', EntityService);
        hybridAdapterUtility.downgradeNg2Component('moveCopyEntity', MoveCopyEntityComponent);
        hybridAdapterUtility.downgradeNg2Component('siteMap', SiteMapComponent);

        SiteModule.setupModule();
        ParcelIconModule.setupModule();
        CompanyModule.setupModule();
    }
}
