import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject } from 'rxjs';
import { BusyIndicatorService } from '../../../Busy-Indicator';
import { IWeissmanModalComponent } from '../../../Compliance/WeissmanModalService';
import { InstanceRepository } from '../instance.repository';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'add-instance-modal',
    templateUrl: './addInstanceModal.component.html'
})
export class AddInstanceModalComponent implements OnInit, IWeissmanModalComponent<any, any> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _instanceRepository: InstanceRepository,
        private _toastrService: ToastrService,
        private readonly _bsModalRef: BsModalRef) {
    }

    params: any;
    result: any;

    instanceForm: UntypedFormGroup;
    instanceTypes: Core.InstanceTypeModel[];

    private _destroy$: Subject<void> = new Subject();

    private _previousInstanceName: string = '';

    async ngOnInit(): Promise<void> {
        this.instanceForm = this._fb.group({
              instanceName: [null, Validators.required],
              instanceType: [null, Validators.required],
              companyName: [null, Validators.required],
              companyCode: [null, Validators.maxLength(50)],
              teamName: [null, Validators.required]
        });

        this.instanceForm.get('instanceName').valueChanges
            .pipe(takeUntil(this._destroy$))
            .pipe(distinctUntilChanged())
            .subscribe((value) => this._instanceNameChanged(value));

        this.instanceTypes = await this._instanceRepository.getInstanceTypes();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async addInstance(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Creating New Instance' });
        const { instanceName, instanceType, companyName, companyCode, teamName } = this.instanceForm.value;

        const model = {
            instanceName: instanceName,
            instanceTypeId: instanceType,
            companyName: companyName,
            companyCode: companyCode,
            teamName: teamName
        };

        const isNameInUse = await lastValueFrom(this._instanceRepository.isInstanceNameInUse({instanceName: model.instanceName, instanceId: -1}));
        if(isNameInUse == true) {
            await busyRef.hide();
            this._toastrService.error(`Instance name ${model.instanceName} is already in use`);
        }
        else {
            try {
                this.result = await lastValueFrom(this._instanceRepository.create(model));
                this._toastrService.success(`New Instance ${model.instanceName} was created successfully. You will need to logout and log back in to see it`);
            } finally {
                await busyRef.hide();
            }

            this.cancel();
        }
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _instanceNameChanged(value: string) {
        const currentTeamName = this.instanceForm.get('teamName').value;

        if (!currentTeamName || currentTeamName === `Team ${this._previousInstanceName}`) {
            this.instanceForm.get('teamName').setValue(value ? `Team ${value}` : '');
        }

        this._previousInstanceName = value;
    }
}
