import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom, Subject } from 'rxjs';
import { BusyIndicatorService } from '../../../../Busy-Indicator';
import { YearPickerMixin } from '../../../../UI-Lib/Mixins';
import { AllocationRepository } from '../../../Repositories';
import { IWeissmanModalComponent } from '../../../WeissmanModalService';

export interface AddAllocationModalParams {
    companyId: number;
}

@Component({
    selector: 'add-allocation-modal',
    templateUrl: './addAllocationModal.component.html'
})
export class AddAllocationModalComponent extends YearPickerMixin() implements OnInit, IWeissmanModalComponent<AddAllocationModalParams, Compliance.AllocationModel> {
    constructor(
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _fb: UntypedFormBuilder,
        private readonly _allocationRepository: AllocationRepository,
        private readonly _bsModalRef: BsModalRef) {
        super();
    }

    params: AddAllocationModalParams;
    result: Compliance.AllocationModel;

    allocationForm: UntypedFormGroup;

    private _destroy$: Subject<void> = new Subject();

    async ngOnInit(): Promise<void> {
        this.allocationForm = this._fb.group({
              taxYear: [new Date().getFullYear(), Validators.required],
              description: [null, Validators.required],
              parcelId: [null, Validators.required],
        });
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async addAllocation(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Adding Allocation' });
        const { taxYear, description, parcelId } = this.allocationForm.value;

        const model = {
            annualAssessmentId: -1
        } as Compliance.AllocationCreateModel;

        try {
            this.result = await lastValueFrom(this._allocationRepository.create(this.params.companyId, model));
        } finally {
            busyRef.hide();
        }

        this.cancel();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    //todo: do not use any
    getParcels = (searchParams: any) => {
        const { taxYear, description, parcelId } = this.allocationForm.value;
        searchParams.taxYear = taxYear;
        return lastValueFrom(this._allocationRepository.getAvailableParcels(searchParams));
    };
}
