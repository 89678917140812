<div>
    <table class="table table-responsive grid annual-details-table">
        <thead class="years">
            <tr>
                <th class="blank" colspan="2">
                </th>
                <th *ngFor="let year of years"
                    [class.expanded]="year.expanded"
                    [attr.data-sd-annual-year]="year.annualYear1">
                    {{year.annualYear1}}
                    <span class="float-end pointer" (click)="year.expanded = !year.expanded" *ngIf="year.annualGridDetails.length > 1" [attr.data-sd-expand-year-revisions] [attr.data-sd-annual-year]="year.annualYear1">
                        <i *ngIf="!year.expanded" class="fa fa-plus-circle fa-fw"></i>
                        <i *ngIf="year.expanded" class="fa fa-minus-circle fa-fw"></i>
                    </span>
                </th>
                <th *ngIf="years?.length === 10 && years[0].totalNumberofYears > 10" class="blank">
                </th>
            </tr>
        </thead>
        <tbody class="details">
            <tr *ngIf="filings.length || hasComplianceResponsibility">
                <td class="heading">
                    Comp
                </td>
                <td class="heading">
                    <div style="white-space: nowrap" *ngFor="let filingType of filings"> {{ filingType.filingTypeName }} </div>
                </td>
                <td class="clickable" *ngFor="let year of years" style="padding:0px">
                    <div class="cell-item comp-cell">
                        <table>
                            <tr>
                                <td class="revision" (click)="complianceClicked(year)" [attr.data-sd-annual-year]='year.annualYear1' [attr.data-sd-annual-year-comp-cell]>
                                    <div *ngFor="let filingType of filings"
                                         class="year-linked-cell"
                                         helpTooltip
                                         helpContentId="annual-details.phantom-year"
                                         position="top"
                                         [tooltipDelay]="0"
                                         [helpDisabled]="!isFromConsolidated(year, filingType)">
                                        <i *ngIf="isFromConsolidated(year, filingType)" class="year-linked fa fa-chain"></i>
                                        <span [innerHtml]="annualDetailsService.getComplianceDisplay(filingType.filingTypeId, year.complianceFilingTypeSummaries)"></span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="heading">
                    Asmts
                </td>
                <td class="heading">
                    <strong class="invisible">Revision:</strong>
                    <div *ngFor="let comp of annualDetailsService.nonCapComponents(components, componentTypes)">  {{ annualDetailsService.getComponentName(comp, componentTypes) }} </div>
                    <div class="sum-label">Total FMV:</div>
                    <div class="cap-component" *ngFor="let comp of annualDetailsService.capComponents(components, componentTypes)">{{annualDetailsService.getComponentName(comp, componentTypes) }}:</div>
                </td>
                <td class="clickable" [class.expanded]="year.expanded" *ngFor="let year of years" [style.cursor]="notAllowedIfConsolidated"> <!-- | orderBy: '-annualYear1'">-->
                    <div class="cell-item">
                        <table style="width: 100%">
                            <tr>
                                <td class="revision" [class.not-actual]="revision.status === 0 && !isConsolidatedParcel" *ngFor="let revision of year.annualGridDetails" [style.cursor]="notAllowedIfConsolidated"> <!-- | orderBy: '-revisionNum'">-->
                                    <div class="lines" (click)="revisionClicked(year, revision)" [attr.data-sd-annual-year-revision]='revision.revisionDesc' [attr.data-sd-annual-year]='year.annualYear1'>
                                        <div>
                                            <strong>{{revision.revisionDesc}}</strong>
                                        </div>
                                        <div *ngFor="let comp of annualDetailsService.nonCapComponents(components, componentTypes)" [attr.data-sd-assessment-component-name]='annualDetailsService.getComponentName(comp, componentTypes)' [attr.data-sd-annual-year]='year.annualYear1'><!-- | orderBy:compPos">-->
                                            {{ annualDetailsService.getDisplayAssessment(comp, revision.annualGridComponents)}}
                                        </div>
                                        <div class="sum-figure">{{ annualDetailsService.getTotalFMV(revision.annualGridComponents) | number:'1.0-0'}}</div><!-- data-sd-annual-year='{{year.annualYear1}}'>{{getTotalFMV(revision.annualGridComponents) | numeraljs:'0,0'}}</div>-->
                                        <div class="cap-component" *ngFor="let comp of annualDetailsService.capComponents(components, componentTypes)">{{ annualDetailsService.getDisplayAssessment(comp, revision.annualGridComponents)}}</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr class="appeals-row"><!-- data-sd-annual-year='{{year.annualYear1}}'>-->
                <td class="heading">
                    Appeals
                </td>
                <td class="heading">
                    <div>Resolution:</div>
                    <div>Savings:</div>
                </td>
                <td class="clickable" [class.expanded]="year.expanded" *ngFor="let year of years" [style.cursor]="notAllowedIfConsolidated"><!-- | orderBy: '-annualYear1'">-->
                    <div class="cell-item appeal-cell" [attr.data-sd-annual-year]='year.annualYear1'>
                        <table style="width: 100%;">
                            <tr *ngIf="year.expanded || year.annualGridDetails?.length < 2">
                                <td class="revision" [class.collapsed-appeal-info]="!year.expanded" [class.under-appeal]="revision.isUnderAppeal" [style.cursor]="notAllowedIfConsolidated" *ngFor="let revision of year.annualGridDetails" (click)="appealClicked(year, revision)">
                                    <div>
                                        <span *ngIf="revision.appealStatus !== 'Details'">{{revision.appealStatus}}</span>
                                        <span class="anchor-style" *ngIf="revision.appealStatus === 'Details'">{{revision.appealStatus}}</span>
                                    </div>
                                    <div [class.invisible]="!revision.savings">{{annualDetailsService.formatSavings(revision.savings) || '0'}}</div>
                                </td>
                            </tr>
                            <tr *ngIf="!year.expanded && year.annualGridDetails?.length >=2">
                                <td (click)="appealClicked(year)" class="revision" [class.under-appeal]="year.collapsedIsUnderAppeal" [style.cursor]="notAllowedIfConsolidated">
                                    <span *ngIf="year.collapsedAppealStatus !== 'Details'">{{year.collapsedAppealStatus}}</span>
                                    <span class="anchor-style" *ngIf="year.collapsedAppealStatus === 'Details'">{{year.collapsedAppealStatus}}</span>
                                    <div ng-class="{'invisible': !year.collapsedSavings}">{{annualDetailsService.formatSavings(year.collapsedSavings) || '0'}}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr>
                <td class="heading">
                    Taxes
                </td>
                <td class="heading">
                    <div>Total:</div>
                    <div>Eff. Rate:</div>
                </td>
                <td class="clickable" [class]="clickableTaxClass(year)" *ngFor="let year of years" style="padding:0px" [style.cursor]="notAllowedIfConsolidated"><!-- | orderBy: '-annualYear1'">-->
                    <div class="cell-item taxes-cell"><!-- data-sd-annual-year='{{year.annualYear1}}'>-->
                        <table style="margin-left: initial;">
                            <tr>
                                <td class="revision" (click)="billClicked(year)" [attr.data-sd-annual-year]='year.annualYear1' [attr.data-sd-annual-year-tax-bill-cell] [style.cursor]="notAllowedIfConsolidated"><!-- name="Parcel-{{parcel.parcelID}}-Year-{{year.annualYear1}}">-->
                                    <div>{{year.totalTaxes | currency: 'USD':'symbol'}}</div><!-- | numeraljs: '0,0.00'}}</div>-->
                                    <div>{{year.effectiveRate | number: '1.6-6'}}%</div><!--| numeraljs: '0.000000'}}%</div>-->
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
            <tr *ngIf="budgetsEnabled">
                <td class="heading">Budget</td>
                <td class="heading"></td>
                <td class="clickable" *ngFor="let year of years" style="padding:0px" [style.pointer-events]="year.latestBudgetId <= 0 && year.annualYear1 < 2019 ? 'none' : 'auto'" [style.cursor]="notAllowedIfConsolidated"><!-- | orderBy: '-annualYear1'">-->
                    <div class="cell-item taxes-cell"><!-- data-sd-annual-year='{{year.annualYear1}}'>-->
                        <table style="margin-left: initial;">
                            <tr>
                                <td class="revision" (click)="budgetClicked(year, year.latestBudgetId)" [attr.data-sd-annual-year]='year.annualYear1' [attr.data-sd-annual-year-budget-cell] [style.cursor]="notAllowedIfConsolidated"><!-- name="Parcel-{{parcel.parcelID}}-Year-{{year.annualYear1}}">-->
                                    <div *ngIf="year.latestBudgetId > 0">{{year.latestBudgetAmount | currency: 'USD':'symbol'}}</div><!-- | numeraljs: '0,0.00'}}</div>-->
                                    <div *ngIf="year.latestBudgetId <= 0">No Budget</div><!-- | numeraljs: '0,0.00'}}</div>-->
                                </td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <!--<div class="button-container" style="margin-top: 30px;">
        <button class="ace-btn btn-primary show-all-years" (click)="getAnnualGrid(true)" *ngIf="years?.length === 10">
            Show All Years
        </button>
    </div>-->
</div>
