import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IWeissmanModalComponent } from '../../WeissmanModalService';

interface FormErrorTitles {
    modalTitle: string;
    label: string;
    warning: string;
}

interface ValidateFormError {
    assessor: string;
    forms: string[];
    showForms: boolean;
    descriptor: string;
}

interface ValidateFormAggregate {
    label: string;
    warning: string;
    type: Compliance.ReturnValidateFormResultEnum;
    errors: ValidateFormError[];
}

export interface ReturnFormsErrorResult {
    errorTypes: Compliance.ReturnValidateFormResultEnum[];
    accepted: boolean;
}

@Component({
    selector: 'return-forms-error',
    templateUrl: './returnFormsError.component.html',
    styleUrls: ['./returnFormsError.component.scss']
})
export class ReturnFormsErrorComponent implements OnInit, IWeissmanModalComponent<Compliance.ReturnValidateFormsResultModel, ReturnFormsErrorResult> {
    constructor(private readonly _bsModalRef: BsModalRef) {
    }

    params: Compliance.ReturnValidateFormsResultModel;
    result: ReturnFormsErrorResult;
    modalTitle: string;
    showCancel: boolean;

    aggregate: {
        [key: number]: ValidateFormAggregate
    };
    errorTypes: Compliance.ReturnValidateFormResultEnum[];

    private _titlesByErrorType: { [key in Compliance.ReturnValidateFormResultEnum]?: FormErrorTitles } = {
        [Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified]: {
            modalTitle: 'One or more forms have not been certified.',
            label: 'The following forms have not been certified',
            warning: 'By clicking OK you acknowledge that these forms are not yet certified and subject to change. Reported filing values may be impacted.'
        },
        [Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable]: {
            modalTitle: 'One or more forms are not available.',
            label: 'Forms are not available for',
            warning: 'Remove filings or try again when the forms are available.'
        },
        [Compliance.ReturnValidateFormResultEnum.FormsAvailableButNoneArePrimary]: {
            modalTitle: 'Forms not automatically added.',
            label: 'Forms are not automatically added for',
            warning: 'Manually add forms to returns.'
        }
    };

    ngOnInit(): void {
        this.errorTypes = [...new Set(this.params.formErrors.map(p => p.errorType))].sort();
        const errorType = this.errorTypes.length === 1 && this.errorTypes[0];

        this.showCancel = errorType === Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified;

        this.modalTitle = this.errorTypes.length > 1
            ? 'Multiple errors have been discovered.'
            : this._titlesByErrorType[errorType].modalTitle;

        this.aggregate = this.errorTypes.reduce((acc, key) => {
            const grouped = this.params.formErrors.filter(x => x.errorType === key)
                .reduce((acc2, p) => {
                    const groupingValue = this._getErrorGroupingValue(p);
                    const displayValue = this._getErrorDisplayValue(p);
                    acc2[groupingValue] = new Set((acc2[groupingValue])
                        ? [...acc2[groupingValue], displayValue]
                        : [displayValue]);
                    return acc2;
                }, {});

            acc[key] = {
                label: this._titlesByErrorType[key].label,
                warning: this._titlesByErrorType[key].warning,
                type: key,
                errors: Object.keys(grouped).map((group) => ({
                    assessor: group,
                    forms: [...grouped[group]],
                    showForms: false,
                    descriptor: this._getListDescriptor(grouped[group].size, key)
                }))
            };
            return acc;
        }, {});
    }

    /**
     * Toggle the forms associated with the assessor
     * @param error
     */
    toggleParcels(error: ValidateFormError): void {
        error.showForms = !error.showForms;
    }

    /**
     * Accept and close the modal
     */
    accept(): void {
        this.result = {
            errorTypes: this.errorTypes,
            accepted: true
        };
        this._bsModalRef.hide();
    }

    cancel(): void {
        this._bsModalRef.hide();
    }

    private _getErrorGroupingValue(model: Compliance.ReturnValidateFormResultModel) {
        switch (model.errorType) {
            case Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified:
                return (model.formRevisionName) ? model.formRevisionName : 'Form not available';
            case Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable:
            case Compliance.ReturnValidateFormResultEnum.FormsAvailableButNoneArePrimary:
                return model.assessorName;
        }
    }

    private _getErrorDisplayValue(model: Compliance.ReturnValidateFormResultModel) {
        switch (model.errorType) {
            case Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified:
                return model.assessorName;
            case Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable:
                return (model.formRevisionName) ? model.formRevisionName : 'Form not available';
            case Compliance.ReturnValidateFormResultEnum.FormsAvailableButNoneArePrimary:
                return (model.formRevisionName) ? model.formRevisionName : 'Form not automatically added';
        }
    }

    private _getListDescriptor(length: number, errorType: Compliance.ReturnValidateFormResultEnum): string {
        switch (errorType) {
            case Compliance.ReturnValidateFormResultEnum.FormYearAssessorIsNotCertified:
                return `${length} Assessors Not Certified`;
            case Compliance.ReturnValidateFormResultEnum.OneOrMoreFormsAreNotAvailable:
            case Compliance.ReturnValidateFormResultEnum.FormsAvailableButNoneArePrimary:
                return `${length} + ${(length > 1 ? ' Forms' : ' Form')}`;
        }
    }
}
