
import {from as observableFrom,  Observable } from 'rxjs';

import {map, mergeMap} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ContactsUpgradeService } from '../../Contact/contacts.service.upgrade';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ExtendedModelBase } from '../../Compliance/Models/extendedModelBase';
import { UpgradeNavigationServiceHandler } from '../Routing/upgrade-navigation-handler.service';

export class ExtendedContactModelCore extends ExtendedModelBase<Core.ContactModel>  {
    constructor(model: Core.ContactModel) {
        super(model);
    }

    includeCompany: boolean;

    get displayName(): string {
        let nameStr = `${this.model.lastName}, ${this.model.firstName}`;
        if(this.includeCompany) {
            nameStr += ` (${(this.model as any).entityName})`;
        }
        return nameStr;
    }
}

@Component({
    selector: 'user-picker',
    template: `
    <input
        name="contact"
        type="text"
        placeholder="Filter contacts..."
        class="form-control"
        autocomplete="off"
        [(ngModel)]="contactFilter"
        container="body"
        [typeahead]="contacts$"
        [typeaheadWaitMs]="750"
        typeaheadOptionField="displayName"
        [typeaheadMinLength]="2"
        [typeaheadOptionsLimit]="100"
        [typeaheadScrollable]="true"
        (blur)="onContactBlur()"
        (typeaheadOnSelect)="onContactSelected($event)"
        [disabled]="isReadonly"/>
    `
})
export class UserPickerComponent implements OnInit{
    constructor(private _contactService: ContactsUpgradeService, private readonly _routerService: UpgradeNavigationServiceHandler){}

    @Input() currentContact: Core.ContactModel;
    @Input() isReadonly: boolean;
    @Input() currentContactId: number;
    @Input() includeBlank: boolean;
    @Input() search: any;
    @Input() includeCompany: boolean = false;
    @Input() usersOnly: boolean = false;

    @Output() contactChanged: EventEmitter<ExtendedContactModelCore> = new EventEmitter<ExtendedContactModelCore>();

    contactFilter: string = '';
    contacts$: Observable<ExtendedContactModelCore[]> = (Observable
        .create((observer: any) => { observer.next(this.contactFilter); }) as Observable<string>)
        .pipe(mergeMap((token) => this._filterContacts(token)));

    private _hasChanges: boolean;
    private _contactNoResult: boolean = false;

    async ngOnInit(): Promise<void> {
        if(this.currentContactId && (!this.currentContact || (!this.currentContact.lastName && !this.currentContact.firstName))) {
            this.currentContact = await this._contactService.getById(this.currentContactId);
        }

        this._setContactFilterToCurrentValue();
    }

    get contact(): Core.ContactModel {
        return this.currentContact;
    }

    set contact(value: Core.ContactModel) {
        const contactId: number = value ? value.contactID : null;

        if (this.currentContactId !== contactId) {
            const extendedContact = new ExtendedContactModelCore({
                ...value,
                ...{
                    contactID: contactId,
                    firstName: value ? value.firstName : '',
                    lastName: value ? value.lastName : ''
                }
            } as Core.ContactModel);

            this.contactChanged.emit(extendedContact);
        }

        this._hasChanges = true;
        this.currentContact = value;
        this.currentContactId = contactId;
    }

    get hasChanges(): boolean {
        return this._hasChanges;
    }

    onContactSelected(match: TypeaheadMatch): void {
        this.contact = (match.item as ExtendedContactModelCore).model;
        this.contactFilter = match.value;
        this._hasChanges = true;
    }

    onContactBlur(): void {
        if (this.contactFilter.trim() === '' || this._contactNoResult){
            this._setContactFilterToCurrentValue();
        }
    }

    get contactDisplay(): string {
        const contact = this.currentContact;

        return contact ? `${contact.lastName}, ${contact.firstName}` : '';
    }

    private _filterContacts(filter: string): Observable<ExtendedContactModelCore[]> {

        const result = <Array<any>>this.search.find(filter);
        return observableFrom(result).pipe(
            map(contacts => {
                if(this.usersOnly) {
                    contacts = contacts.filter(x => x.userID);
                }

                if (contacts && contacts.length === 0){
                    this._contactNoResult = true;
                }

                if(this.includeBlank) {
                    contacts.unshift(<Core.ContactModel>{contactID: -1, firstName: '[Blank]', lastName: ''});
                }

                return contacts.map(contact => {
                    const extendedContact = new ExtendedContactModelCore({
                        ...contact,
                        ...{
                            contactID: contact.contactID,
                            firstName: contact.firstName || '',
                            lastName: contact.lastName || '',
                            includeCompany: this.includeCompany
                        }
                    } as Core.ContactModel);

                    extendedContact.includeCompany = this.includeCompany;
                    return extendedContact;
                });
            }));
    }

    private _setContactFilterToCurrentValue(): void {
        const contact = this.currentContact;

        this.contactFilter = (contact && this.contactDisplay) || '';
    }
}
