<div class="card panel-flat-color primary-panel d-flex flex-grow-1 mb-0">
    <div class="panel-flat-header d-flex">
        <h3 class="mt-0 me-2">Results</h3>
        <ag-grid-row-count [gridTracker]="gridTracker" [gridOptions]="gridOptions" [cssClass]="null"></ag-grid-row-count>
        <div class="d-flex align-items-center ms-2">
            <div *ngIf="!loading && results"
                 class="btn-group buttongroup-first buttongroup-last"
                 helpTooltip
                 [tooltipText]="contextMenuTooltip"
                 [helpDisabled]="!contextMenuTooltip"
                 [class.open]="contextMenuOpen">
                <button type="button"
                        class="ace-btn btn-sm btn-primary dropdown-toggle my-0"
                        (click)="getMenuOptions()"
                        [disabled]="contextMenuDisabled">
                    <i class="fa" [ngClass]="{ 'fa-gear': !contextMenuLoading, 'fa-spinner fa-pulse': contextMenuLoading}"></i> Actions...
                </button>
                <ul class="dropdown-menu"
                    [class.show]="contextMenuOpen"
                    role="menu"
                    clickOutside
                    (clickOutside)="contextMenuOpen = false;">
                    <li role="menuitem" *ngFor="let action of contextMenuItems">
                        <a style="cursor: pointer;" class="dropdown-item" (click)="action.onClick()">{{action.name}}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="header-button-container d-flex align-items-center justify-content-end flex-grow-1">
            <label class="small pe-3"
                   *ngIf="!loading && results && hasErrors()">
                <input type="checkbox"
                       style="vertical-align: text-bottom"
                       [(ngModel)]="showValidRowsOnly.value"
                       (change)="filterResults()"> Show Valid Rows Only
            </label>
            <label class="small pe-3"
                   *ngIf="!loading && results && hasErrors()">
                <input type="checkbox"
                       style="vertical-align: text-bottom"
                       [(ngModel)]="showErrorsOnly.value"
                       (change)="filterResults()"> Show Errors Only
            </label>
            <button class="ace-btn btn-sm btn-primary m-0"
                    *ngIf="drilldownMode"
                    (click)="backToOverview.next(loading)">
                <i class="fa fa-arrow-left"></i> Back to Overview
            </button>
            <div *ngIf="results && !loading" class="d-inline-block">
                <button class="ace-btn btn-text has-icon btn-primary"
                        style="margin: 5px 5px 5px 0; height: 25px; width: 25px;"
                        (click)="getTableData(true)">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
    </div>
    <div class="panel-flat-body h-100">
        <div class="task-av-loader" *ngIf="!results || loading">
            <i class="fa fa-spinner fa-pulse fa-5x"></i>
            <span class="spinner-label-text">{{ loadingText }}</span>
        </div>
        <ws-ag-grid-angular [ngClass]="loading ? 'task-av-loading' : ''"
                            [gridId]="gridId"
                            [gridOptions]="gridOptions"
                            [exportOptions]="exportOptions"
                            [showConfigureLayout]="false"
                            (gridReady)="onAgGridReady($event)">
        </ws-ag-grid-angular>
    </div>
</div>
