import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PaymentPackagesComponent } from '../../Processing/Transmittal/payment-packages.component';
declare const _: any;

@Component({
    selector: 'payment-packages-modal',
    templateUrl: './payment-package-modal.component.html'
})
export class PaymentPackagesModalComponent {
    //   newAppeal: Appeal;
    //   @Input() assessments: AnnualDetailAssessment[];
    //   @Input() appealLevels: AppealLevel[];
    //   @Output('add') onAddAppeal: EventEmitter<Appeal>;

    @ViewChild('paymentPackagesModal', { static: true }) paymentPackageModal: ModalDirective;
    @ViewChild('paymentPackages', { static: true }) paymentPackages: PaymentPackagesComponent;
    paymentID: number;


    show(paymentID: number): void {
        this.paymentID = paymentID;
        this.paymentPackageModal.onHide.subscribe(() => {
            this.paymentPackages.closeChildModals();
        });
        this.paymentPackageModal.show();
    }

    showModalInit(): void {
        // this.newAppeal = new Appeal();

        // if (this.appealLevels && this.appealLevels.length > 0) {
        //     this.newAppeal.appealLevelID = this.appealLevels[0].appealLevelID;
        // }

        // if (this.assessments && this.assessments.length > 0) {
        //     this.newAppeal.annualAssessmentID = (_.minBy(this.assessments, 'revisionNum') as AnnualDetailAssessment).annualAssessmentID;
        // }
    }
}