import { NgModule } from '@angular/core';
import { WeissmanSharedModule } from 'src/app/Common/weissman-shared.module';
import { HybridAdapterUtility } from 'src/app/Hybrid/hybrid-adapter.utility';
import { VendorCodeService } from './vendorCode.service';
import { VendorCodeMappingComponent } from './vendorCodeMapping.component';

@NgModule({
    imports: [WeissmanSharedModule],
    exports: [],
    declarations: [VendorCodeMappingComponent],
    providers: [VendorCodeService],
})
export class VendorCodeModule {
    static setupModule(): void {
        const hybridAdapterUtility = new HybridAdapterUtility();
        hybridAdapterUtility.downgradeNg2Component('vendorCodeMapping', VendorCodeMappingComponent);
    }
}
