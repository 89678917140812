import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UserSettingsService } from '../Account/userSettings.service';
import { ProductAnalyticsService } from '../Common/Amplitude/productAnalytics.service';
import { NavigationService } from '../Layout/navigation.service';
import { UpgradeNavigationServiceHandler } from '../Common/Routing/upgrade-navigation-handler.service';
import { InstanceRights, RestrictService } from '../Common/Permissions/restrict.service';
import { AccountService } from '../User/account.service';

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
    constructor(
        private userSettingsService: UserSettingsService,
        private accountService: AccountService,
        private navigationService: NavigationService,
        private upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private restrictService: RestrictService,
        private _productAnalyticsService: ProductAnalyticsService
    ) {}

    userInfo: { isTeamMemberAndAssignable: boolean, firstName: string };
    defaultView: LandingViewTypes = LandingViewTypes.MyTasks;
    showActionView: boolean = false;
    existingDefaultViewSetting: Array<any>;
    hasInstancePrivatePermission: boolean = false;

    ngOnInit(): void {
        this.hasInstancePrivatePermission = this.restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSEDIT) || this.restrictService.hasInstanceRight(InstanceRights.PRIVATEITEMSVIEW);

        this.existingDefaultViewSetting = this.userSettingsService.getSettingsByGroup(16);
        if(this.existingDefaultViewSetting.length) {
            this.defaultView = +this.existingDefaultViewSetting[0].value;
        }

        this.userInfo = {
            isTeamMemberAndAssignable: this.accountService.userData.isTeamMemberAndAssignable,
            firstName: this.accountService.userData.firstName
        };

        this.defaultViewChange();
    }

    defaultViewChange(): void {
        // Convert to number
        this.defaultView = +this.defaultView;
        if(!this.hasInstancePrivatePermission && !this.userInfo.isTeamMemberAndAssignable) {
            this.defaultView = LandingViewTypes.None;
        }

        switch(this.defaultView) {
            case LandingViewTypes.None:
                this.showActionView = false;
                break;
            case LandingViewTypes.MyTasks:
                this.showActionView = true;
                break;
            case LandingViewTypes.MyTeamsTasks:
                if(this.hasInstancePrivatePermission) {
                    this.showActionView = true;
                } else {
                    this.defaultView = LandingViewTypes.None;
                    this.showActionView = false;
                }
                break;
        }

        //Save new or existing default view setting
        let settingUpdated = true;
        if(!this.existingDefaultViewSetting.length) {
            this.existingDefaultViewSetting.push({
                id: 0,
                name: 'Dashboard-Default-View',
                value: this.defaultView,
                groupId: 16,
                groupName: 'Dashboard-Default-View',
                folderId: 20,
                folderName: 'Dashboard-Default-View',
            });
        } else {
            if (_.isEqual(this.existingDefaultViewSetting[0].value, this.defaultView)) {
                settingUpdated = false;
            }
            else {
                this.existingDefaultViewSetting[0].value = this.defaultView;
            }
        }

        if (settingUpdated) {
            this.userSettingsService.save(this.existingDefaultViewSetting[0]).then(savedSetting => {
                this.existingDefaultViewSetting[0] = savedSetting;
            });
        }
    }

    openQuickSearch(resetFilter: boolean): void {
        this._productAnalyticsService.logEvent('initiate-quick-search', { initiateQuickSearch: 'home page' });
        this.navigationService.openQuickSearch(resetFilter, 'home');
    }

    goToStatePage(): void {
        this.upgradeNavigationServiceHandler.go('states', {});
    }

    goToDashboard(): void {
        this.upgradeNavigationServiceHandler.go('myDashboard', {});
    }
}

export enum LandingViewTypes {
    None = 0,
    MyTasks = 1,
    MyTeamsTasks = 2
}
