import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AgGridLinkCellRendererParams } from '../../../../AgGrid/CellRenderers/agGridLinkCellRenderer.component';

export interface ReturnPreviewFormOverridesListActionCellRendererParams extends AgGridLinkCellRendererParams {
    deleteOverride: (params: ReturnPreviewFormOverridesListActionCellRendererParams) => void;
    viewReturn: (params: ReturnPreviewFormOverridesListActionCellRendererParams) => void;
    isReadOnly: boolean;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `
        <button class="ace-btn btn-secondary has-icon grid-action-button"
            (click)="params.viewReturn(params)"
            helpTooltip
            helpContentId="app.view"
            position="bottom">
            <i class="fa fa-external-link"></i>
        </button>
        <button *ngIf="!params.isReadOnly"
            class="ace-btn btn-primary btn-danger has-icon grid-action-button"
            (click)="params.deleteOverride(params)"
            helpTooltip
            helpContentId="app.delete"
            position="bottom">
            <i class="fa fa-trash"></i>
        </button>
        `
})
export class ReturnPreviewFormOverridesListActionCellRendererComponent implements ICellRendererAngularComp {
    params: ReturnPreviewFormOverridesListActionCellRendererParams;

    agInit(params: ReturnPreviewFormOverridesListActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
