import { Injectable } from '@angular/core';
import { SDHttpService } from '../Routing/sd-http.service';

@Injectable(
    { providedIn: 'root' }
)
export class CustomeEmailTemplateService {

    constructor(private http: SDHttpService) { }

    getEmailTemplateFields() {
        return this.http.get('/api/PaymentPackage/EmailTemplateFields');
    }
}
