import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AddressDetailsModalComponent } from './address.details.modal.component';
import { EntityAddress } from './address.model';

@Injectable(
    {providedIn: 'root'}
)
export class AddressDetailsModalLaunchService {
    constructor(
        private modalService: BsModalService
    ) { }

    customTemplateModalRef: BsModalRef;

    openAddressDetailsModal(entityID: number, entityTypeID: number, entityAddresses: EntityAddress[], isAbleToBeEdited: boolean, justSelect?: boolean, checkDeliverability?: boolean): Promise<EntityAddress[]> {
        const promise = new Promise<EntityAddress[]>((resolve) => {
            const initialState = {
                entityID: entityID,
                entityTypeID: entityTypeID,
                entityAddresses: entityAddresses,
                isAbleToBeEdited: isAbleToBeEdited,
                justSelect: justSelect,
                checkDeliverability: checkDeliverability
            };

            const modalRef = this.modalService.show(AddressDetailsModalComponent, {initialState, class: 'modal-change-history'});

            modalRef.content.onClose = (x: EntityAddress[]) => resolve(x);
        });

        return promise;
    }
}
