<button class="ace-btn btn-sm btn-primary float-end" [hidden]="!editState.editMode" (click)="addBill()"><i class="fa fa-plus"></i> Add Bill</button>
<div *ngIf="viewModel.model.length === 0" class="text-center" style="margin-top: 30px;">
    <div class="lead" *ngIf="currentYear">
        There are presently no tax bills for {{currentYear.annualYear1}}
    </div>
    <div class="lead" *ngIf="!currentYear">
        There are presently no tax bills for this year.
    </div>
    <button class="ace-btn btn-primary" *ngIf="editState.hasWritePermission" (click)="addBill()">New Bill</button>
</div>
<tabset *ngIf="viewModel.model && viewModel.model.length > 0" justify="start">
  <tab *ngFor="let tab of viewModel.model; let i = index;"
    (selectTab)="switchTabs(viewModel, i)"
    (disabled)="tab.disabled"
    [active]="i === viewModel.currentTabIndex">
      <ng-template tabHeading>
        <i class="fa {{tab.icon}}" style="margin-right: 5px;"></i><span>{{tab.title || tab.collector.abbr || tab.collector.name}} </span>
      </ng-template>
      <div style="display: flex; justify-content: space-between; position: relative;">
        <bills
          *ngIf="!tab.refund"
          [editState]="editState"
          [viewModel]="getBillVM(tab)"
          [showExtraFields]="tab.showExtraFields"
          [gridNavigationHandler]="gridNavigationHandler"
          [taxesViewModel]="viewModel"
          [stateId]="tab.collector.stateID"
          (AnnualDetailsSaveAllowedChanged)="annualDetailsSaveAllowedChanged($event)"
          (saveBillCluster)="saveBillCluster($event)"
        >
        </bills>

        <refund *ngIf="tab.refund" [editState]="editState" [viewModel]="getRefundVM(tab)" [taxesViewModel]="viewModel" (refundDeleted)="reloadVM()"></refund>

        <a *ngIf="tab.isAccrualsAvailable && !tab.allPaymentsExcludedFromAccrual"
            (click)="launchAccrualDetailModal(tab)" class="pointer" style="position: absolute; top: 5px;">Accrual Detail</a>
      </div>
  </tab>
</tabset>

<div *ngIf="isAddBillModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #addBillModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add One-time Bill or Refund</h4>
      </div>
      <div class="modal-body">
          <div style="text-align: center;">
            <div class="radio" style="display: inline-block;">
              <label>
                <input type="radio" [(ngModel)]="billOrRefund.type" value="bill">
                One Time Bill
              </label>
            </div>
            <div class="radio" style="display: inline-block; margin-left: 15px;">
              <label>
                <input type="radio" [(ngModel)]="billOrRefund.type" value="refund">
                Refund
              </label>
            </div>
          </div>
        <div style="margin-top: 15px;">
            <div *ngIf="hasCompanyCollectors" style="text-align:center;">
                <div>
                    <input type="radio" id="collectorTypeChoiceState" name="collectorTypeChoice" [value]="true" [(ngModel)]="isStateCollectorsShown" (change)="getCollectorList()" [disabled]="!parcelCollectors.length">
                    <label for="collectorTypeChoiceState" style="margin-right: 24px;">Governmental Collectors</label>
                    <input type="radio" id="collectorTypeChoiceCompany" name="collectorTypeChoice" [value]="false" [(ngModel)]="isStateCollectorsShown" (change)="getCollectorList()">
                    <label for="collectorTypeChoiceCompany">Company Collectors</label>
                </div>
            </div>
          <label>Collector:</label>
          <select *ngIf="isStateCollectorsShown" class="form-select form-select-sm" [(ngModel)]="billOrRefund.collectorID">
             <option *ngFor="let collector of parcelCollectors" [ngValue]="collector.collectorID">{{collector.collector.abbr}}</option>
          </select>
          <select *ngIf="!isStateCollectorsShown" class="form-select form-select-sm" [(ngModel)]="billOrRefund.collectorID">
              <option *ngFor="let collector of companyCollectors" [ngValue]="collector.collectorID">{{collector.abbr}}</option>
          </select>

        </div>
      </div>
      <div class="modal-footer">
        <button class="ace-btn btn-primary" (click)="saveBillOrRefund()" [disabled]="waitingOnServer">Add</button>
        <button class="ace-btn btn-secondary" (click)="cancelAddBillOrRefund()">Cancel</button>
      </div>
    </div>
  </div>
</div>
