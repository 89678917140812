import { Component, OnInit } from '@angular/core';
import { BreadCrumb } from '../../UI-Lib/Bread-Crumb/breadCrumbs.component';
import { BusyIndicatorService } from '../../Busy-Indicator';
import { UpgradeNavigationServiceHandler } from '../../Common/Routing/upgrade-navigation-handler.service';
import { StateService } from '../../Common/States/States.Service';
import { AssessorCollectorService } from '../assessor.collector.service';
import { ItemsByLetter } from '../alphabetizedList.component';
import { WeissmanModalService } from '../../Compliance/WeissmanModalService';
import { AddCollectorModalComponent, AddCollectorModalParams } from '../Add/Collector/addCollectorModal.component';
import { RestrictService, Roles } from '../../Common/Permissions/restrict.service';
import { Collector } from '../collector.model';
import { CompanyService } from '../../Entity/Company/company.service';

import * as _ from 'lodash';

@Component({
    selector: 'collector-list',
    template: `
        <div class="ws-flex-container-vertical ws-flex-auto ws-stretch" *ngIf="initialized">
            <div class="ws-flex-container-horizontal ws-flex-none">
                <div class="ws-flex-auto ws-truncate">
                    <ws-bread-crumbs [breadcrumbs]="breadcrumbs" currentPageTitle="Collectors"></ws-bread-crumbs>
                </div>
            </div>

            <div class="ws-section ws-flex-none">
                <div class="ws-section__header">
                    <h4 class="ws-section__header__title">{{collectorName}} Collectors</h4>
                    <div class="ws-section__header__actions">
                        <div class="ws-section__header__actions__item">
                            <div *ngIf="!viewOnly" class="action-button-wrapper">
                                <button type="button"
                                        class="ace-btn btn-sm btn-secondary has-icon"
                                        (click)="launchAddModal()">
                                    <i class="fa fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <alphabetized-list *ngIf="collectorsByLetters"
                               (letterSelected)="onLetterSelected($event)"
                               [startingLetter]="startingLetter"
                               [itemsByLetters]="collectorsByLetters">
            </alphabetized-list>
        </div>
    `
})
export class CollectorListComponent implements OnInit {
    constructor(
        private readonly _restrictService: RestrictService,
        private readonly _busyIndicatorService: BusyIndicatorService,
        private readonly _upgradeNavigationServiceHandler: UpgradeNavigationServiceHandler,
        private readonly _assessorCollectorService: AssessorCollectorService,
        private readonly _modalService: WeissmanModalService,
        private readonly _stateService: StateService,
        private readonly _companyService: CompanyService
    ) { }

    collectorName: string;
    state: Weissman.Model.Misc.State;
    breadcrumbs: BreadCrumb[] = [];
    collectorsByLetters: ItemsByLetter[];
    companyId: number;
    stateId: number;
    isCompanyCollector: boolean;
    startingLetter: string;
    viewOnly: boolean;
    initialized: boolean;

    async ngOnInit(): Promise<void> {

        this.startingLetter = this._upgradeNavigationServiceHandler.getQuerystringParam('letter');
        this.companyId = +this._upgradeNavigationServiceHandler.getQuerystringParam('companyID');

        if (this.companyId) {
            this.isCompanyCollector = !!this.companyId;
        } else {
            this.stateId = +this._upgradeNavigationServiceHandler.getQuerystringParam('stateId');
        }

        this.viewOnly = this._restrictService.isNotInRoles([Roles.COLLECTOREDIT]) && !this.isCompanyCollector;

        await this._loadData();
    }

    onLetterSelected(letter: string): void {
        this._assessorCollectorService.selectedCollectorLetter = letter;
        if (this.isCompanyCollector) {
            const params = { companyID: this.companyId, letter };
            this._upgradeNavigationServiceHandler.go('companyCollectors.letter', params);
        } else {
            const params = { stateId: this.state.stateID, letter };
            this._upgradeNavigationServiceHandler.go('collectors.letter', params);
        }
    }

    async launchAddModal(): Promise<void> {
        const params: AddCollectorModalParams = {
            state: this.state,
            companyID: this.companyId,
            companyName: this.collectorName,
            isCompanyCollector: this.isCompanyCollector
        };

        const result = await this._modalService.showAsync(AddCollectorModalComponent, params, 'modal-md');

        if (result) {
            let target, options;
            if (this.isCompanyCollector) {
                target = 'companyCollector';
                options = { companyID: this.companyId, collectorID: result.collectorID };
            } else {
                target = 'collector';
                options = { stateId: this.stateId, stateCollectorId: result.collectorID };
            }
            this._upgradeNavigationServiceHandler.go(target, options);
        }
    }

    private async _loadData(): Promise<void> {
        const busyRef = this._busyIndicatorService.show({ message: 'Loading' });
        try {
            let collectorList: Collector[];
            this.breadcrumbs = [
                {
                    name: 'States',
                    target: 'states',
                    options: { }
                },
            ];

            if (this.isCompanyCollector) {
                const companyName = await this._companyService.getCompanyName(this.companyId);
                this.collectorName = companyName;

                this.breadcrumbs.push({
                    name: companyName,
                    target: 'company',
                    options: { companyId: this.companyId }
                });

                collectorList = await this._assessorCollectorService.getCompanyCollectorList(this.companyId);
            } else {
                this.state = await this._stateService.getById(this.stateId);
                this.collectorName = this.state.fullName;

                this.breadcrumbs.push({
                    name: this.state.fullName,
                    target: 'state',
                    options: { stateId: this.state.stateID }
                });

                collectorList = await this._assessorCollectorService.getStateCollectorList(this.stateId);
            }

            this.collectorsByLetters = _.chain(collectorList)
                .groupBy(x => {
                    const firstChar = x.abbr.charAt(0);
                    return isNaN(+firstChar) ? firstChar.toUpperCase() : '#';
                })
                .map((collectors, letter) => {
                    const items = _.map(collectors, (x: Collector) => {
                        let sref;
                        if (this.isCompanyCollector) {
                            sref = { target: 'companyCollector', options: { companyID: this.companyId, collectorID: x.collectorID }};
                        } else {
                            sref = { target: 'collector', options: { stateId: this.stateId, stateCollectorId: x.collectorID }};
                        }
                        return {
                            name: x.abbr,
                            sref
                        };
                    });

                    return { letter, items };
                })
                .sortBy('letter')
                .value();

        } finally {
            this.initialized = true;
            busyRef.hide();
        }
    }

}

