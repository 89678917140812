import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AnnualDetailAssessment } from '../Annual-Year/annual-year.model';
import { Appeal, AppealLevel } from '../Appeals/appeal.model';
import { AppealService } from '../Appeals/appeal.service';

declare const _: any;

@Component({
    selector: 'add-appeal-modal',
    templateUrl: './add-appeal.component.html'
})
export class AddAppealModalComponent {
    constructor(private appealService: AppealService) {
        this.addAppeal = new EventEmitter();
    }
    @Input() assessments: AnnualDetailAssessment[];
    @Input() appealLevels: AppealLevel[];
    @Output() addAppeal: EventEmitter<Appeal>;
    @ViewChild('addAppealModal', { static: true }) addAppealModal: ModalDirective;

    newAppeal: Appeal;
    loading: boolean;


    show(): void {
        this.addAppealModal.show();
    }

    showModalInit(): void {
        this.newAppeal = new Appeal();

        if (this.appealLevels && this.appealLevels.length > 0) {
            this.newAppeal.appealLevelID = this.appealLevels[0].appealLevelID;
        }

        if (this.assessments && this.assessments.length > 0) {
            this.newAppeal.annualAssessmentID = (_.minBy(this.assessments, 'revisionNum') as AnnualDetailAssessment).annualAssessmentID;
        }
    }

    async save(): Promise<void> {
        this.loading = true;

        try {
            const appeal = await this.appealService.createAppeal(this.newAppeal);
            this.addAppeal.emit(appeal);
            this.addAppealModal.hide();
        } finally {
            this.loading = false;
        }
    }
}

