import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface DatabaseProcessListGridActionCellRendererParams extends ICellRendererParams {
    killProcess: (params: DatabaseProcessListGridActionCellRendererParams) => void;
}

@Component({
    selector: 'grid-action-cell',
    template:
        `<button type="button"
            *ngIf="params && params.data"
            class="ace-btn btn-primary btn-danger has-icon grid-action-button"
            (click)="params.killProcess(params)"
            helpTooltip
            helpContentId="database-process-list.kill-process"
            position="bottom">
            <i class="fa fa-trash"></i>
        </button>`
})
export class DatabaseProcessListGridActionCellRendererComponent implements ICellRendererAngularComp {
    params: DatabaseProcessListGridActionCellRendererParams;

    agInit(params: DatabaseProcessListGridActionCellRendererParams): void {
        this.params = params;
    }

    refresh(): boolean {
        return false; // tells grid to handle refreshing
    }
}
